import k from "i18n/keys";
import { TFunction } from "i18next";

export const getLocaleString = <T extends string | string[] | null | undefined>(
    t: TFunction<"translation", undefined>,
    locale: T,
    returnKeyIfNotFound = true,
): T extends string ? string : T extends string[] ? string[] : undefined => {
    if (locale) {
        if (Array.isArray(locale)) {
            const localeString = locale.reduce<string[]>((acc, curr) => {
                if (curr in k) {
                    const newString = t(k[curr as keyof typeof k]);

                    acc.push(newString);
                } else if (returnKeyIfNotFound) {
                    acc.push(curr as any);
                }

                return acc;
            }, []);

            if (localeString.length === 0) {
                return undefined as any;
            }

            return localeString as any;
        }

        if (typeof locale === "string") {
            if (locale in k) {
                const localeString = t(k[locale as keyof typeof k]);

                return localeString as any;
            } else if (returnKeyIfNotFound) {
                return locale as any;
            }
        }
    }

    return undefined as any;
};
