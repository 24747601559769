import { useMemo } from "react";

export interface IListedContentProps {
    className?: string;
    contentRef?: React.RefObject<HTMLDivElement>;

    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

const ModalContentListedContent: React.FC<
    React.PropsWithChildren<IListedContentProps>
> = (props) => {
    const { className, contentRef } = props;

    const contentClassName = useMemo(() => {
        const result = ["m-m--m-c--listed--content"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, []);

    return (
        <div
            className={contentClassName}
            ref={contentRef}
            onScroll={props.onScroll}
        >
            {props.children}
        </div>
    );
};

export default ModalContentListedContent;
