import { IUserPositionItem } from "components/positions/api/IUserPositionItem";

export const PositionsListSort = (list: IUserPositionItem[]) =>
    list
        .sort((a, b) => {
            if (Boolean(a.isCurrent) !== Boolean(b.isCurrent)) {
                return a.isCurrent ? 1 : -1;
            }

            if (
                a.startDate &&
                (a.isCurrent || a.endDate) &&
                b.startDate &&
                (b.isCurrent || b.endDate)
            ) {
                const durationA =
                    a.startDate.valueOf() -
                    (a.isCurrent
                        ? new Date(Date.now()).valueOf()
                        : a.endDate?.valueOf() ?? 0);

                const durationB =
                    b.startDate.valueOf() -
                    (b.isCurrent
                        ? new Date(Date.now()).valueOf()
                        : b.endDate?.valueOf() ?? 0);

                return durationA - durationB;
            }

            return 0;
        })
        .reverse();
