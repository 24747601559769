import "./TeamIcon.scss";

import React from "react";
import Icon from "./assets/team-icon.svg";

const TeamIcon: React.FC = (props) => {
    return <Icon className="team-icon" />;
};

export default TeamIcon;
