import ValidationLabel from "./ValidationLabel";
import "./ValidationWrapper.scss";

interface IProps {
    errors?: React.ReactNode;
    testId?: string;
    absolute?: boolean;
}

const ValidationWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
    errors,
    testId,
    children,
    absolute,
}) => {
    return (
        <div className={"validation-wrapper" + (absolute ? " absolute" : "")}>
            {children}
            <ValidationLabel darkColor errors={errors} testId={testId} />
        </div>
    );
};

export default ValidationWrapper;
