import "./CreateOptionLabel.scss";

import React from "react";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

interface IProps {
    inputValue: string;
}

const CreateOptionLabel: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { inputValue } = props;

    const { t } = useTranslation();

    return (
        <div className="app-select-dropdown__create-option">
            <FiPlus />

            {inputValue ? (
                <React.Fragment>
                    <strong>{t(k.CREATE)}</strong> "{inputValue}"
                </React.Fragment>
            ) : (
                <strong>{t(k.CREATE)}</strong>
            )}
        </div>
    );
};

export default CreateOptionLabel;
