import IValidationMessages from "common/viewModels/IValidationMessages";
import { FormulaItemValidationErrorType } from "../api/FormulaItemValidationErrorType";
import i18n from "i18next";

export const setValidationErrors = (
    validationMessages: IValidationMessages,
    ids: string[],
    i18next: typeof i18n,
) => {
    const { _error, ...rest } = validationMessages;

    const errorKeys = Object.keys(rest);

    const result: IValidationMessages = {};

    errorKeys.forEach((errorKey) => {
        const idsWithError = validationMessages[errorKey] as string[];

        if (i18next.exists(errorKey)) {
            let correctIdsWithError: string[] = [];

            switch (errorKey) {
                case FormulaItemValidationErrorType.MISSING_OPERATOR.toString():
                case FormulaItemValidationErrorType.MISSING_OPERAND.toString():
                case FormulaItemValidationErrorType.MISSING_ITEMS.toString():
                    correctIdsWithError = idsWithError?.map(
                        (id) => ids[ids.indexOf(id) - 1],
                    );

                    break;

                default:
                    correctIdsWithError = idsWithError;
                    break;
            }

            correctIdsWithError.forEach((id) => {
                if (result[id]) {
                    (result[id] as string[]).push(i18next.t(errorKey));
                } else {
                    result[id] = [i18next.t(errorKey)];
                }
            });
        } else {
            idsWithError.forEach((errorMessage) => {
                if (result[errorKey]) {
                    (result[errorKey] as string[]).push(errorMessage);
                } else {
                    result[errorKey] = [errorMessage];
                }
            });
        }
    });

    return result;
};

export const sanitizeText = (text: string) => {
    const sanitizedText = text.replace(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        "",
    );

    return sanitizedText;
};
