import "./TaskCheckboxIcon.scss";

import React from "react";
import { FiCheckSquare } from "react-icons/fi";

const TaskCheckboxIcon: React.FC = () => {
    return <FiCheckSquare className="icon-taskcheckbox" />;
};

export default TaskCheckboxIcon;
