import { useMutation, useQuery, useQueryClient } from "react-query";

import axios, { isAxiosError, HttpStatusCode } from "axios";
import { IMenuOption } from "common/components/layout/utils/MenuPaths";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import { CUSTOM_LIST_ITEM_OPTIONS } from "components/custom-list-item-page/api/hooks";
import { ITableList } from "http/ITableList";
import ITableSet from "http/ITableSet";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

import { ISharedPropertiesTableSet } from "../../common/shared-properties/api/ISharedPropertiesTableSet";
import { ICustomListOptionDto } from "./ICustomListOptionDto";
import { ICustomListPermissionsDto } from "./ICustomListPermissionsDto";
import ICustomListRowDTO from "./ICustomListRowDTO";

const baseUrl = "/api/custom-list";

const LIST_QUERY_KEY = "custom-list-list";
const OPTIONS_QUERY_KEY = "custom-list-options";

export const useCustomListNamesByIds = (customListIds?: string[]) => {
    return useQuery(
        ["custom-list-names-by-ids", customListIds],
        async (context) => {
            const params = customListIds
                ?.map((id) => {
                    return `ids=${id}`;
                })
                .join("&");

            const url = `${baseUrl}/custom-list-names-by-ids?${params}`;

            const response = await axios.get<
                Record<string, string | undefined>
            >(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: customListIds !== undefined && customListIds.length > 0,
        },
    );
};

async function fetchOptions(args: { signal?: AbortSignal }) {
    const url = `${baseUrl}/options`;

    const response = await axios.get<ITableSet<ICustomListOptionDto>>(url, {
        signal: args.signal,
    });

    const menuOptions = response.data.ids.reduce<IMenuOption[]>(
        (acc, parentId) => {
            const value = response.data.values[parentId];

            if (value) {
                const result: IMenuOption = {
                    value: value.id,

                    label: value.name,

                    linkTo: `/custom-list-item/${value.path}`,

                    noTranslation: true,
                };

                acc.push(result);
            }

            return acc;
        },
        [],
    );

    return { menuOptions, values: response.data };
}

export const useCustomListOptionsForMenu = (enabled: boolean) => {
    return useQuery([OPTIONS_QUERY_KEY], fetchOptions, {
        enabled,
    });
};

export const useCustomListOptions = () => {
    return useQuery([OPTIONS_QUERY_KEY], fetchOptions);
};

export const useCustomListPermissions = (id?: string) => {
    return useQuery(
        ["custom-list-permissions", id],
        async (context) => {
            const url = `${baseUrl}/permissions/${id}`;

            const response = await axios.get<ICustomListPermissionsDto>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: Boolean(id) },
    );
};

const OPTIONS_BY_PATH_QUERY_KEY = "custom-list-option-by-path";

export const useCustomListOptionByPath = (path?: string) => {
    return useQuery(
        [OPTIONS_BY_PATH_QUERY_KEY, path],
        async (context) => {
            const url = `${baseUrl}/option-by-path/${path}`;

            try {
                const response = await axios.get<ICustomListOptionDto>(url, {
                    signal: context.signal,
                });

                return response.data;
            } catch (e) {
                if (
                    isAxiosError(e) &&
                    e.response?.status === HttpStatusCode.NotFound
                ) {
                    return null;
                }
            }
        },
        { enabled: Boolean(path) },
    );
};

export const useCustomListOptionById = (id?: string) => {
    return useQuery(
        ["custom-list-option-by-id", id],
        async (context) => {
            const url = `${baseUrl}/option-by-id/${id}`;

            try {
                const response = await axios.get<ICustomListOptionDto>(url, {
                    signal: context.signal,
                });

                return response.data;
            } catch (e) {
                if (
                    isAxiosError(e) &&
                    e.response?.status === HttpStatusCode.NotFound
                ) {
                    return null;
                }
            }
        },
        { enabled: Boolean(id) },
    );
};

const CUSTOM_LIST_RELATED_LISTS = "CUSTOM_LIST_RELATED_LISTS";

export const useRelatedCustomLists = (parentIds: string[] | undefined) => {
    return useQuery(
        [CUSTOM_LIST_RELATED_LISTS, parentIds],
        async (context) => {
            const params = parentIds
                ?.map((id) => {
                    return `parentIds=${id}`;
                })
                .join("&");

            const url = `${baseUrl}/related-custom-lists?${params}`;

            const response = await axios.get<IDictionary<string[]>>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: parentIds !== undefined && parentIds.length > 0,
        },
    );
};

const CUSTOM_LIST_PROPERTIES_BY_ID = "custom-list-properties-by-id";

export const usePropertiesByCustomListId = (customListId?: string) => {
    return useQuery(
        [CUSTOM_LIST_PROPERTIES_BY_ID, customListId],
        async (context) => {
            const url = `${baseUrl}/properties-by-id/${customListId}`;

            const response = await axios.get<ISharedPropertiesTableSet>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: Boolean(customListId) },
    );
};

export const useCustomListList = () => {
    return useQuery([LIST_QUERY_KEY], async (context) => {
        const url = `${baseUrl}/list`;

        const response = await axios.get<ITableSet<ICustomListOptionDto>>(url, {
            signal: context.signal,
        });

        return response.data;
    });
};

export const useDeleteCustomListMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { parentId: string }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.parentId}`);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([OPTIONS_QUERY_KEY]);
                queryClient.invalidateQueries([LIST_QUERY_KEY]);
                queryClient.invalidateQueries([CUSTOM_LIST_RELATED_LISTS]);
            },
        },
    );
};

export const useArchiveCustomListMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { id: string; request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries([OPTIONS_QUERY_KEY]);
                queryClient.invalidateQueries([
                    CUSTOM_LIST_BY_ID,
                    variables.id,
                ]);

                queryClient.invalidateQueries([LIST_QUERY_KEY]);

                queryClient.invalidateQueries([CUSTOM_LIST_RELATED_LISTS]);
            },
        },
    );
};

export const useSaveCustomListMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { isCreate: boolean; value: ICustomListRowDTO }) => {
            try {
                let result: ICustomListRowDTO;
                const { isCreate, value } = variables;

                const url = baseUrl;

                if (isCreate) {
                    const response = await axios.post<ICustomListRowDTO>(
                        url,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<ICustomListRowDTO>(
                        url,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<ICustomListRowDTO>(result);
            } catch (error) {
                return ExecutionResult.Failed<ICustomListRowDTO>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([OPTIONS_QUERY_KEY]);
                queryClient.removeQueries([CUSTOM_LIST_ITEM_OPTIONS]);
                queryClient.invalidateQueries([LIST_QUERY_KEY]);
                queryClient.invalidateQueries([CUSTOM_LIST_RELATED_LISTS]);
                queryClient.invalidateQueries([OPTIONS_BY_PATH_QUERY_KEY]);
            },
        },
    );
};

const CUSTOM_LIST_BY_ID = "custom-list-by-id";

export const useCustomListById = (id?: string) => {
    const url = `${baseUrl}/id/${id}`;

    return useQuery(
        [CUSTOM_LIST_BY_ID, id],
        async (context) => {
            const response = await axios.get<ICustomListRowDTO>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: Boolean(id),
        },
    );
};

const CUSTOM_LIST_ACCESS_LIMITED_PROPERTY_EXISTS =
    "custom-list-access-limited-property-exists";

export const useAccessLimitedPropertyExists = (customListId: string) => {
    return useQuery(
        [CUSTOM_LIST_ACCESS_LIMITED_PROPERTY_EXISTS, customListId],
        async (context) => {
            const url = `${baseUrl}/access-limited-property-exists/${customListId}`;

            const response = await axios.get<boolean>(url, {
                signal: context.signal,
            });

            return response.data;
        },
    );
};
