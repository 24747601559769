import "./MultiselectValueBadge.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import classnames from "classnames";

interface IProps {
    id?: string;

    type?: "checkbox";
    checked?: boolean;
    isArchived?: boolean;
    isDeleted?: boolean;

    ellipsis?: boolean;

    title?: string;

    onClick?: (id?: string) => void;
}

const MultiselectValueBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        id,
        type,
        checked,
        isArchived,
        isDeleted,
        ellipsis,
        title,
        children,
    } = props;

    const { t } = useTranslation();

    const className = classnames("multiselect-value-badge", {
        "multiselect-value-badge--link": !!props.onClick,
        "multiselect-value-badge--deleted": isDeleted,
        "multiselect-value-badge--archived": isArchived,
        "multiselect-value-badge--ellipsis": ellipsis,
    });

    const handleClick = (e: React.UIEvent) => {
        e.stopPropagation();

        if (props.onClick) {
            props.onClick(id);
        }
    };

    const defaultTitle =
        title ??
        (ellipsis && typeof children === "string" ? children : undefined);

    const specialTitle = isDeleted
        ? t(k.DELETED)
        : isArchived
          ? t(k.ARCHIVED)
          : undefined;

    const finalTitle =
        specialTitle ??
        "" + (specialTitle && defaultTitle ? " | " : "") + defaultTitle ??
        "";

    return (
        <div
            className={className}
            title={finalTitle || undefined}
            onClick={props.onClick ? handleClick : undefined}
        >
            {type === "checkbox" && (
                <input
                    className="multiselect-value-badge--checkbox"
                    type="checkbox"
                    checked={checked}
                    readOnly={true}
                />
            )}
            <div className="multiselect-value-badge--label">{children}</div>
        </div>
    );
};

export default MultiselectValueBadge;
