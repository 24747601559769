import "./PreviousYearButton.scss";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { ArrowLeftIcon } from "common/components/icons";

const PreviousYearButton = (props: { onClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <div
            className="previous-year-button"
            title={t(k.PREVIOUS_YEAR)}
            onClick={props.onClick}
        >
            <ArrowLeftIcon />
        </div>
    );
};

export default PreviousYearButton;
