import "./Actions.scss";
import ChecklistDetailsContext from "../../../../../checklistContext";
import { FC, useContext } from "react";
import CancelButton from "./CancelButton/CancelButton";
import SaveButton from "./SaveButton/SaveButton";

interface Props {}

const Actions: FC<Props> = () => {
    const { editMode } = useContext(ChecklistDetailsContext);
    return editMode ? (
        <div className="checklist__listed-content__action-buttons">
            <CancelButton />

            <SaveButton />
        </div>
    ) : null;
};

export default Actions;
