import React from "react";

import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";
import { TableCell } from "common/components/table";

interface IProps {
    reportDetails: IChecklistReportResponse;

    columnsCountByStepInstanceId: Record<string, number | undefined> | null;
}

export const LevelOneReportDetailsHeader = (props: IProps) => {
    const { reportDetails, columnsCountByStepInstanceId } = props;

    return (
        <React.Fragment>
            {reportDetails.headerLevelOneStepInstanceIds.map(
                (stepInstanceId) => (
                    <TableCell
                        key={stepInstanceId}
                        isHighlighted
                        colSpan={
                            columnsCountByStepInstanceId?.[stepInstanceId] ??
                            reportDetails.colSpanByStepInstanceId[
                                stepInstanceId
                            ]
                        }
                        className="top-level-header"
                    >
                        {reportDetails.stepInstancesById[stepInstanceId]?.label}
                    </TableCell>
                ),
            )}
        </React.Fragment>
    );
};
