import { AiOutlineLoading } from "react-icons/ai";
import "./LoadingIcon.scss";

interface IProps {}

const LoadingIcon = (props: IProps) => {
    return (
        <div className="loading-icon">
            <AiOutlineLoading />
        </div>
    );
};

export default LoadingIcon;
