export function tryParseJSONObject(
    jsonString: string,
    defaultValue: "" | object,
) {
    if (jsonString === "") {
        return defaultValue;
    }

    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o as object;
        }
    } catch (e) {}

    return defaultValue;
}

export const tryParseJSON = <RT>(jsonString: string) => {
    if (jsonString === "") {
        return null;
    }

    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o as RT;
        }
    } catch (e) {}

    return null;
};
