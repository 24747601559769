import { useContext } from "react";

import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import CustomerFormModalById from "components/customer-page/components/CustomerFormModalById/CustomerFormModalById";

interface IProps {
    modalItem: IModalListItem;
}

const CustomerFormModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean, path?: string) => {
        modalManagerContext.onCloseModal({
            callId,
            onSave,
            navigateTo: path,
        });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <CustomerFormModalById
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
            id={id}
        />
    );
};

export default CustomerFormModal;
