import "./SwitchLocale.scss";

import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import {
    currentLocaleId,
    languageToLocale,
    setLocale,
    LocaleId,
} from "AppLocale";
import { IAppState } from "store/IAppState";
import { updateLocale } from "store/auth/actions";
import { gbFlag, svFlag } from "./components/flags";

interface IOwnProps {
    transparent?: boolean;
    onlyIcon?: boolean;
    showSelected?: boolean;
}

interface IStateProps {
    isAuthenticated: boolean;

    profileLocaleId?: LocaleId;
}

interface IDispatchProps {
    updateLocale: (localeId: LocaleId) => void;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

const SwitchLocale: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const {
        transparent,
        onlyIcon,
        showSelected = onlyIcon,
        isAuthenticated,
        profileLocaleId,
    } = props;

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (currentLocaleId === undefined) {
            const localeId = languageToLocale(i18n.language);

            setLocale(profileLocaleId ?? localeId);

            if (isAuthenticated && profileLocaleId === undefined) {
                props.updateLocale(localeId);
            }
        }
    }, [i18n.language, currentLocaleId, isAuthenticated, profileLocaleId]);

    const menuOptions: IMenuOption[] = useMemo(() => {
        const result: IMenuOption[] = [
            {
                id: LocaleId.En,
                label: (
                    <React.Fragment>
                        {gbFlag}
                        {t(k.ENGLISH)}
                    </React.Fragment>
                ),
                selected: currentLocaleId === LocaleId.En,
            },
            {
                id: LocaleId.Sv,
                label: (
                    <React.Fragment>
                        {svFlag}
                        {t(k.SWEDISH)}
                    </React.Fragment>
                ),
                selected: currentLocaleId === LocaleId.Sv,
            },
        ];

        return result;
    }, [currentLocaleId, isAuthenticated]);

    const languageMenuOptions: IMenuOption[] = useMemo(() => {
        return menuOptions.filter((x) => x.id !== currentLocaleId);
    }, [currentLocaleId]);

    const handleMenuSelect = (selectedId: string) => {
        const newlocaleId = selectedId as LocaleId;

        setLocale(newlocaleId);

        if (isAuthenticated) {
            props.updateLocale(newlocaleId);
        }
    };

    const className = useMemo(() => {
        let result = ["locale--switch"];

        if (onlyIcon) {
            result.push("locale--switch--only-icon");
        }

        if (transparent) {
            result.push("locale--switch--transparent");
        }

        if (showSelected) {
            result.push("locale--switch--show-selected");
        }

        return result.join(" ");
    }, [onlyIcon, showSelected, transparent]);

    return (
        <div className={className}>
            <MenuButton
                onSelect={handleMenuSelect}
                options={showSelected ? menuOptions : languageMenuOptions}
                icon={
                    onlyIcon &&
                    (currentLocaleId === LocaleId.Sv ? svFlag : gbFlag)
                }
                label={
                    onlyIcon ? undefined : (
                        <React.Fragment>
                            {currentLocaleId === LocaleId.Sv ? svFlag : gbFlag}

                            {currentLocaleId === LocaleId.Sv
                                ? t(k.SWEDISH)
                                : t(k.ENGLISH)}
                        </React.Fragment>
                    )
                }
                alignEnd={false}
                drop="up"
            />
        </div>
    );
};

const mapToProps = (state: IAppState): IStateProps => {
    return {
        isAuthenticated: state.authViewState.isAuthenticated,
        profileLocaleId: state.authViewState.profile?.localeId,
    };
};

const actions = {
    updateLocale,
};

export default connect(mapToProps, actions)(SwitchLocale);
