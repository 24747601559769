import "./ActionsEditableList.scss";

import React, { PropsWithChildren } from "react";
import { IInputActionProps } from "./IInputActionProps";
import InputActionTextField from "./inputActions/InputActionTextField";
import InputActionTaskList from "./inputActions/InputActionTaskList";

import { ActivityType } from "models/enums/ActivityType";

// TODO: remove this component when implemented all input actions
const EmptyComponent: React.FC = () => null;

const componentsLookup: Record<ActivityType, any> = {
    [ActivityType.Value]: EmptyComponent,
    [ActivityType.Tasklist]: InputActionTaskList,
    [ActivityType.Photo]: EmptyComponent,
    [ActivityType.Text]: InputActionTextField,
    [ActivityType.Selection]: EmptyComponent,
    [ActivityType.Customers]: EmptyComponent,
    [ActivityType.Suppliers]: EmptyComponent,
    [ActivityType.Items]: EmptyComponent,
    [ActivityType.Barcodes]: EmptyComponent,
    [ActivityType.DateTime]: EmptyComponent,
    [ActivityType.CustomList]: EmptyComponent,
    [ActivityType.GenerateBarcode]: EmptyComponent,
    [ActivityType.Hyperlink]: EmptyComponent,
    [ActivityType.Users]: EmptyComponent,
    [ActivityType.Positions]: EmptyComponent,
    [ActivityType.Competencies]: EmptyComponent,
};

const InputActionComponent = (props: PropsWithChildren<IInputActionProps>) => {
    const Component = componentsLookup[props.actionType];

    return <Component {...props} />;
};

export default InputActionComponent;
