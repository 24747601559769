import "./IsBlockingStep.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

import k from "i18n/keys";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import Tooltip from "common/components/tooltip/Tooltip";

interface IProps {
    value?: boolean;
    isDisabled?: boolean;
    testId?: string;
    onClick: (value: boolean) => void;
}

const IsBlockingStep: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, isDisabled } = props;

    const { t } = useTranslation();

    const handleChange = (e: IInputChangeEvent<boolean>) => {
        props.onClick(e.value);
    };

    return (
        <div className="step-is-blocking">
            <Checkbox
                testId={props.testId}
                value={value ?? false}
                label={t(k.BLOCK_FOLLOWING_STEPS)}
                disabled={isDisabled}
                onChange={handleChange}
            />

            <Tooltip
                id="isBlockingTooltip"
                message={t(k.BLOCK_FOLLOWING_STEPS_TOOLTIP)}
            >
                <FiInfo />
            </Tooltip>
        </div>
    );
};

export default IsBlockingStep;
