import "./AccordionLeft.scss";

import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

import AccordionButton from "../buttons/accordion-button/AccordionButton";

interface IProps {
    open?: boolean;
    listenOpen?: boolean;

    className?: string;
    headerClassName?: string;

    header: React.ReactNode;
    toggleOnIcon?: boolean;

    onClickHeader?: () => void;
    onToggle?: (open: boolean, event: React.MouseEvent<HTMLDivElement>) => void;
}

const AccordionLeft: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { open, header, toggleOnIcon, listenOpen } = props;

    const [wholeOpen, setWholeOpen] = useState(open);

    useEffect(() => {
        if (listenOpen) {
            setWholeOpen(open);
        }
    }, [open]);

    const toggleCollapse = (event: React.MouseEvent<HTMLDivElement>) => {
        if (props.onToggle) {
            props.onToggle(!wholeOpen, event);
        }

        setWholeOpen((x) => !x);
    };

    return (
        <div
            className={`app-accordion-left__wrapper${
                props.className ? " " + props.className : ""
            }`}
        >
            <div
                className={`app-accordion ${toggleOnIcon ? "" : "pointer"}`}
                onClick={toggleOnIcon ? undefined : toggleCollapse}
            >
                <div
                    className={`app-accordion--toggle ${
                        toggleOnIcon ? "pointer" : ""
                    }`}
                    onClick={toggleOnIcon ? toggleCollapse : undefined}
                >
                    <AccordionButton isOpen={wholeOpen} />
                </div>
                <div
                    className={`app-accordion--header ${props.headerClassName}`}
                    onClick={props.onClickHeader}
                >
                    {header}
                </div>
            </div>

            <Collapse in={wholeOpen}>
                <div>{props.children}</div>
            </Collapse>
        </div>
    );
};

export default AccordionLeft;
