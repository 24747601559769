import React from "react";
import { Modal as ModalDialog } from "react-bootstrap";

import { useModalContext } from "./Context";

const Title: React.FC<React.PropsWithChildren> = (props) => {
    const context = useModalContext();

    return props.children ? (
        <ModalDialog.Header closeButton>
            <ModalDialog.Title>{props.children}</ModalDialog.Title>
        </ModalDialog.Header>
    ) : null;
};

export default Title;
