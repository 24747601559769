import "./EquipmentIcon.scss";

import React from "react";
import Icon from "./assets/equipment-icon.svg";

const EquipmentIcon: React.FC = (props) => {
    return <Icon className="equipment-icon" />;
};

export default EquipmentIcon;
