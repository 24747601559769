import "../RecurringChecklistForm.scss";

import { DayOfWeek, DayOfWeek as DoW } from "models/enums/DayOfWeek";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

interface IProps {
    workdays: DayOfWeek[];

    errors: IValidationMessages;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;
}

const RecurringChecklistDayOfWeek = (props: IProps) => {
    const { workdays = [], errors } = props;
    const { t } = useTranslation();

    const handleChange = (e: IInputChangeEvent<boolean>) => {
        if (e.id) {
            const day = parseInt(e.id) as DoW;

            if (workdays.includes(day) && !e.value) {
                props.onChange(
                    workdays.filter((x) => x !== day),
                    "workdays",
                );
            } else if (e.value) {
                props.onChange(workdays.concat(day), "workdays");
            }
        }
    };
    return (
        <div className="recurring-checklist--body--day-week">
            <div className="r-c--body--day-week--title">
                <span>{t(k.ON_THESE_DAYS)}</span>
                <div className="text-field--helper-text">
                    <ValidationLabel
                        errors={errors.workdays && t(errors.workdays)}
                    />
                </div>
            </div>

            <div className="r-c--body--day-week--list">
                <Checkbox
                    id="1"
                    value={workdays.includes(DoW.Monday)}
                    label={t(k.MONDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="2"
                    value={workdays.includes(DoW.Tuesday)}
                    label={t(k.TUESDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="3"
                    value={workdays.includes(DoW.Wednesday)}
                    label={t(k.WEDNESDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="4"
                    value={workdays.includes(DoW.Thursday)}
                    label={t(k.THURSDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="5"
                    value={workdays.includes(DoW.Friday)}
                    label={t(k.FRIDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="6"
                    value={workdays.includes(DoW.Saturday)}
                    label={t(k.SATURDAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id="0"
                    value={workdays.includes(DoW.Sunday)}
                    label={t(k.SUNDAY)}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default RecurringChecklistDayOfWeek;
