import {
    PageNavigationActionTypes,
    RECEIVE_PAGE_CHANGE,
    RECEIVE_DEVIATION_CHANGE,
} from "./types";

export const onPageStateChange = (
    hasChanges?: boolean,
): PageNavigationActionTypes => {
    return {
        type: RECEIVE_PAGE_CHANGE,
        payload: hasChanges,
    };
};

export const onDeviationStateChange = (
    hasChanges?: boolean,
): PageNavigationActionTypes => {
    return {
        type: RECEIVE_DEVIATION_CHANGE,
        payload: hasChanges,
    };
};
