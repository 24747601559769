export enum CognitGPTPromptType {
    None,
    ORA_RiskScenario,
    ORA_PotentialConsequences,
    ORA_RiskReduction,
    ORA_RiskScenario_RewriteExisting,
    ORA_PotentialConsequences_RewriteExisting,
    ORA_RiskReduction_RewriteExisting,
}

export enum CognitGPTAdditionalInfoKey {
    RiskScenario = "riskScenario",
    RiskConsequences = "riskConsequences",
    RiskReduction = "riskReduction",
}
