import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./common/components/layout/Layout";
import ModalAttachmentManager from "./components/common/modals/ModalAttachmentManager";
import ModalConfirmManager from "./components/common/modals/ModalConfirmManager";

import { syncProfile } from "./store/auth/actions";
import {
    loadUiSettings,
    updateLayoutEnvironment,
} from "store/uiSettings/actions";
import { IAppState } from "./store/IAppState";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";

import { ELayoutEnvironment } from "store/uiSettings/reducer";
import ImagePreviewManager from "components/common/modals/ImagePreviewManager";
import ModalBarcodePreviewManager from "components/common/modals/ModalBarcodePreviewManager";
import ModalBarcodeScannerManager from "components/common/modals/ModalBarcodeScannerManager";
import SetValidationLocale from "i18n/components/SetValidationLocale";

import { AppRoutes } from "routes/Routes";

import ModalManager from "common/components/modal-manager/ModalManager";
import { ToastContainer } from "react-toastify";

import * as microsoftTeams from "@microsoft/teams-js";
import { useCanAccessKpi } from "common/components/modal-manager-content/kpi-form/api/hooks";
import { isDevelopmentEnvironment } from "config";

import { useLocation, useNavigate } from "react-router-dom";

const App: React.FC = () => {
    const location = useLocation();
    const isAuthenticated = useSelector((appState: IAppState) => {
        const { isAuthenticated } = appState.authViewState;

        return isAuthenticated;
    });

    const appFeatures = useSelector((appState: IAppState) => {
        const { profile } = appState.authViewState;

        return profile?.appFeatures;
    });

    const layoutEnvironment = useSelector((appState: IAppState) => {
        const { layoutEnvironment } = appState.uiSettingsViewState;

        return layoutEnvironment;
    });

    const { data: canAccessKpi } = useCanAccessKpi(undefined, isAuthenticated);

    const setHasPageChanges = useHasPageChanges();

    const dispatch = useDispatch<any>();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(syncProfile());

        dispatch(loadUiSettings());

        microsoftTeams.app
            .initialize()
            .then(() => {
                if (layoutEnvironment !== ELayoutEnvironment.TEAMS) {
                    dispatch(updateLayoutEnvironment(ELayoutEnvironment.TEAMS));
                }
            })
            .catch((err) => {});

        if (isDevelopmentEnvironment() && /^(\/t\/)/.test(location.pathname)) {
            dispatch(updateLayoutEnvironment(ELayoutEnvironment.TEAMS));
            navigate(location.pathname.replace(/^(\/t\/)/, "/"));
        }
    }, []);

    useEffect(() => {
        setHasPageChanges(false);
    }, [location, isAuthenticated]);

    SetValidationLocale();

    if (isAuthenticated) {
        return (
            <ModalManager>
                <ToastContainer />
                <Layout>
                    <AppRoutes
                        layoutEnvironment={layoutEnvironment}
                        appFeatures={appFeatures}
                        canAccessKpi={canAccessKpi}
                    />
                </Layout>
                <ModalConfirmManager />
                <ImagePreviewManager />
                <ModalAttachmentManager />
                <ModalBarcodePreviewManager />
                <ModalBarcodeScannerManager />
            </ModalManager>
        );
    }

    return (
        <AppRoutes
            layoutEnvironment={layoutEnvironment}
            appFeatures={appFeatures}
        />
    );
};

export default App;
