import { PropsWithChildren } from "react";

interface IProps {
    enabled?: boolean;
    className?: string;
}

const Wrapper = ({
    children,
    enabled = true,
    className,
}: PropsWithChildren<IProps>) => {
    if (enabled) {
        return <div className={className}>{children}</div>;
    }

    return <>{children}</>;
};

export default Wrapper;
