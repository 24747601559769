import React from "react";
import { Modal as ModalDialog } from "react-bootstrap";

import { useModalContext } from "./Context";

const Body: React.FC<React.PropsWithChildren<{ className?: string }>> = (
    props,
) => {
    const { className } = props;

    const context = useModalContext();

    return props.children ? (
        <ModalDialog.Body className={className}>
            {props.children}
        </ModalDialog.Body>
    ) : null;
};

export default Body;
