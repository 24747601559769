import "./YearRow.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "common/components/table";
import { MonthsOrder } from "models/enums/Months";
import { MonthsKeys } from "i18n/keySets/MonthKeys";

export interface IYearRowProps {
    year: number;

    hideHeader?: boolean;

    previous?: React.ReactNode;
    next?: React.ReactNode;
    children?: React.ReactNode;
}

const YearRow = (props: IYearRowProps) => {
    const { year, hideHeader, previous, next } = props;

    const { t } = useTranslation();

    return (
        <div className="year-row">
            {previous}
            <div className="year-row--year">
                <span>{year}</span>
            </div>

            <Table wrapperClassName="year-row--months">
                {hideHeader ? undefined : (
                    <TableHeader>
                        <TableRow>
                            {MonthsOrder.map((x) => (
                                <TableCell key={x}>
                                    {t(MonthsKeys[x])}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                )}
                <TableBody>{props.children}</TableBody>
            </Table>

            {next}
        </div>
    );
};

export default YearRow;
