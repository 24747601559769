import { PropsWithChildren } from "react";
import Tooltip, { TooltipProps } from "./Tooltip";

interface IProps extends TooltipProps {
    showTooltip?: boolean;
}

const TooltipWrapper = (props: PropsWithChildren<IProps>) => {
    const {
        id,
        placement,
        message,
        variant,
        tooltipClassName,
        truncate,
        showTooltip,
    } = props;

    return showTooltip ? (
        <Tooltip
            id={id}
            placement={placement}
            message={message}
            variant={variant}
            tooltipClassName={tooltipClassName}
            truncate={truncate}
        >
            {props.children}
        </Tooltip>
    ) : (
        <>{props.children}</>
    );
};

export default TooltipWrapper;
