import "./Preview.scss";

import React from "react";
import { v4 as uuidv4 } from "uuid";

import { IValueLabelItem } from "common/IValueLabelItem";
import { useCustomListBasicListOptions } from "components/custom-list-item-page/api/hooks";
import { IBasicPropertyPreviewProps } from "./BasicPropertyPreview";
import SortableBasicListValues from "../SortableBasicListValues";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";

interface IOwnProps extends IBasicPropertyPreviewProps {
    label: React.ReactNode;
}

const ManageBasicList: React.FC<React.PropsWithChildren<IOwnProps>> = (
    props,
) => {
    const { value, disabled, errors, label } = props;

    const options = value.options ?? [];

    const isCreate = value.propertyCustomListId === undefined;

    const { data: items, isLoading } = useCustomListBasicListOptions(
        value.propertyCustomListId,
    );

    const userDefinedTasks = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.userDefinedTasks ===
            true
        );
    });

    const createNewItem = () => {
        const newItem: IValueLabelItem<string, string> = {
            value: uuidv4(),
            label: "",
        };

        return newItem;
    };

    React.useEffect(() => {
        if (isCreate) {
            if (userDefinedTasks) {
                props.onChange([], true);
            } else {
                props.onChange([createNewItem()], true);
            }
        } else if (items && value.options === null) {
            props.onChange(items, true);
        }
    }, [isCreate, items, userDefinedTasks]);

    const handleAddNew = () => {
        props.onChange(options.concat(createNewItem()), false);
    };

    const handleChangeOptions = (values: IValueLabelItem<string, string>[]) => {
        var initArray = userDefinedTasks ? [] : [createNewItem()];
        props.onChange(values.length === 0 ? initArray : values, false);
    };

    return (
        <fieldset>
            <legend>{label}</legend>

            <AnimatedSpinner position="center" isVisible={isLoading} />

            <SortableBasicListValues
                disabled={disabled}
                values={options}
                errors={errors}
                onAdd={handleAddNew}
                onChange={handleChangeOptions}
            />
        </fieldset>
    );
};

export default ManageBasicList;
