import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import InputWrapper from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useKpiOptions } from "common/components/modal-manager-content/kpi-form/api/hooks";

interface IProps {
    excludeId?: string;
    label?: React.ReactNode;
    boldLabel?: boolean;
    closeMenuOnSelect?: boolean;
    isSingle?: boolean;
    selectedIds?: string[];

    onChange: (ids: string[]) => void;
}

export const KpiMultiselect = (props: IProps) => {
    const {
        label,
        boldLabel,
        closeMenuOnSelect,
        isSingle,
        selectedIds,
        excludeId,
    } = props;

    const { data: allOptions } = useKpiOptions(excludeId);

    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string>[]
    >([]);

    useEffect(() => {
        if (allOptions && selectedIds) {
            var newSelectedOptions = allOptions?.filter((x) =>
                selectedIds.includes(x.value),
            );

            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [allOptions, selectedIds, excludeId]);

    const handleOnChange = (options: any) => {
        if (isSingle) {
            const newOption = options as IValueLabelItem<string, string>;
            if (newOption) {
                setSelectedOptions([newOption]);
                props.onChange([newOption.value]);
                return;
            } else {
                setSelectedOptions([]);
                props.onChange([]);
                return;
            }
        } else {
            const newOptions = options as IValueLabelItem<string, string>[];

            setSelectedOptions(newOptions);

            props.onChange(newOptions.map((x) => x.value));
        }
    };

    return (
        <InputWrapper
            wrapperLabel={label ?? t(k.NAV_NODE_KPI)}
            boldLabel={boldLabel}
        >
            <SelectDropdown
                value={selectedOptions}
                isMulti={!Boolean(isSingle)}
                isSearchable
                isClearable
                placeholder={t(k.ALL)}
                options={allOptions}
                testId="form.KpiDropdownInput"
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};
