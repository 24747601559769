import { Dispatch } from "redux";
import { ELayoutEnvironment } from "./reducer";
import { UiSettingService } from "./service";
import {
    CHANGE_LAYOUT_ENVIRONMENT,
    CHANGE_MENU_IS_EXPANDED,
    UiSettingsActionTypes,
} from "./types";

export const loadUiSettings = () => {
    const request = (isExpanded: boolean): UiSettingsActionTypes => {
        return {
            type: CHANGE_MENU_IS_EXPANDED,
            payload: isExpanded,
        };
    };

    const isExpanded = new UiSettingService().getMenuSettings();

    return request(isExpanded);
};

export const updateMenuSettings = (isExpanded: boolean) => {
    const request = (): UiSettingsActionTypes => {
        return {
            type: CHANGE_MENU_IS_EXPANDED,
            payload: isExpanded,
        };
    };

    return async (dispatch: Dispatch) => {
        dispatch(request());

        new UiSettingService().updateMenuSettings(isExpanded);
    };
};

export const updateLayoutEnvironment = (
    layoutEnvironment: ELayoutEnvironment,
): UiSettingsActionTypes => {
    return {
        type: CHANGE_LAYOUT_ENVIRONMENT,
        payload: layoutEnvironment,
    };
};
