import "./StepListItem.scss";

import React from "react";

import IStep from "models/IStep";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { useTranslation } from "react-i18next";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";

interface IProps {
    step?: IStep;
    index?: number;
    selected: boolean;

    onSelected: (id: string) => void;
}

const StepListItem: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { step, index, selected } = props;

    const { t } = useTranslation();

    if (!step) {
        return null;
    }

    const onClick = () => props.onSelected(step.id);

    const stepName =
        (step.name && _improvementStepTitles(step.name, true, true)) ??
        step.name;

    return (
        <div
            className={`steps-list-item ${selected ? "selected" : ""}`}
            onClick={onClick}
        >
            <div className="steps-list-item--column">
                <div className="steps-list-item--title">
                    {index}. {getLocaleString(t, stepName)}
                </div>
            </div>
        </div>
    );
};

export default StepListItem;
