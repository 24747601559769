import "./Datetime.scss";

import React from "react";
import * as Popper from "@popperjs/core";

import { IWithDebounce, IInputProps } from "../IInputProps";
import DatePicker from "common/components/date-picker/DatePicker";
import { CalendarIcon } from "common/components/icons";

export interface IDateTimeProps extends IWithDebounce {
    showTimeSelect?: boolean;

    timeIntervals?: number;
    dateFormat?: "P" | "Pp" | string;
    minDate?: Date | null;
    calendarClassName?: string;
    shouldCloseOnSelect?: boolean;

    placement?: Popper.Placement;
}

type Props<V> = IDateTimeProps & IInputProps<Date>;

const Datetime = <V,>(props: Props<V>) => {
    const { id, name, disabled, preview, value, hideIcon } = props;

    const handleChange = (date: Date) => {
        if (props.onChange) {
            props.onChange({
                id,
                name,
                value: date,
            });
        }
    };

    const isDisabled = disabled || preview;

    const dateValue = React.useMemo(() => {
        try {
            if (value) {
                return value instanceof Date ? value : new Date(String(value));
            }
        } catch (e) {}
    }, [value]);

    return (
        <div
            className={`datetime-wrapper${
                props.wrapperClassName ? " " + props.wrapperClassName : ""
            }`}
        >
            <DatePicker
                showTimeSelect
                timeIntervals={15}
                {...props}
                id={id}
                value={dateValue}
                disabled={isDisabled}
                icon={
                    hideIcon ? undefined : (
                        <div className="icon-field">
                            <CalendarIcon />
                        </div>
                    )
                }
                onChange={handleChange}
            />
        </div>
    );
};

export default Datetime;
