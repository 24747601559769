import "./ProgressBar.scss";

import { useMemo } from "react";
import ProgressBar, { IProgressBarProps } from "./ProgressBar";
import { LocaleId } from "AppLocale";
import { useTranslation } from "react-i18next";

const ProgressBarPercentValue = (props: IProgressBarProps) => {
    const { i18n } = useTranslation();

    const percentageFormatter = useMemo(() => {
        return new Intl.NumberFormat(i18n.language as LocaleId, {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
    }, [i18n.language]);

    const percentValue = (props.now ?? 0) / (props.max ?? 100);

    return (
        <ProgressBar
            {...props}
            content={percentageFormatter.format(percentValue)}
        />
    );
};

export default ProgressBarPercentValue;
