import { useEffect, useRef } from "react";
import usePressHold from "common/components/press-hold/usePressHold";
import { ReactMouseOrTouchEvent } from "../useResizableColumns";
import pressHoldIndicator from "common/components/press-hold/pressHoldIndicator";

interface IResizeHandleProps {
    startResizing?: (event: ReactMouseOrTouchEvent) => void;
    stopResizing?: () => void;
}

const TableResizeHandle = ({
    startResizing,
    stopResizing,
}: IResizeHandleProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const { handlePressEnd, handlePressStart } = usePressHold(
        pressHoldIndicator({
            onLongPress: startResizing,
            onPressEnd: stopResizing,
        }),
    );

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener(
                "touchstart",
                (e) => handlePressStart(e as unknown as ReactMouseOrTouchEvent),
                {
                    passive: false,
                },
            );
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("touchstart", (e) =>
                    handlePressStart(e as unknown as ReactMouseOrTouchEvent),
                );
            }
        };
    }, [ref]);

    return (
        <div
            ref={ref}
            onMouseDown={startResizing}
            onMouseUp={stopResizing}
            onTouchEnd={handlePressEnd}
            className="column-resize-handle"
        />
    );
};

export default TableResizeHandle;
