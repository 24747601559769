import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ListedContent from "common/components/listed-content/ListedContent";
import { SidePanelHeader, SidePanelDetails } from "./components/SidePanel";
import Content from "./components/Content/Content";
import { useImprovementFormData } from "./hooks";
import NewImprovementDetailsContext, {
    NewImprovementContextType,
} from "./newImprovementContext";
import {
    IModalFor,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import { useListedContentData } from "common/components/listed-content/hooks";
import IImprovementFilter from "components/improvements/api/IImprovementFIlter";

import "./NewImprovementListedContent.scss";
import { IAppState } from "store/IAppState";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";

export interface INewImprovementDetailsProps {
    id: string;
    checkCanSeeAll: boolean;
    syncData: boolean;
    filter?: IImprovementFilter;
    modalForDetails?: IModalFor;

    onShowModal: (payload: IShowModalPayload) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

const NewImprovementListedContent: FC<INewImprovementDetailsProps> = (
    props,
) => {
    const { t } = useTranslation();

    const roles = useSelector((appState: IAppState) => {
        return appState.authViewState.roles;
    });

    const profile = useSelector((appState: IAppState) => {
        return appState.authViewState.profile;
    });

    const lastFinalizedStepInstanceId = useSelector((appState: IAppState) => {
        return appState.stepInstanceViewState.lastFinalizedStepInstanceId;
    });

    const improvementFormData: NewImprovementContextType = {
        ...useImprovementFormData(props),
        roles,
        profile,
        lastFinalizedStepInstanceId,
        ...useListedContentData(),
    };

    const {
        contentRef,
        listedContentRef,
        improvementForm,
        isLoadingImprovementForm,
        isLoadingSteps,
        initialSection,
        mobileView,
        sidePanelRef,
        editedImprovement,
    } = improvementFormData;

    const handleClose = () => {
        props.onClose();
    };

    const isLoading = isLoadingImprovementForm && isLoadingSteps;

    if (improvementForm === undefined && !isLoadingImprovementForm) {
        return (
            <NotAvailableModal
                onClose={props.onClose}
                message={t(k.NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    return (
        <CurrentScrolledSection
            contentRef={contentRef}
            wrapperRef={listedContentRef}
            mobileView={mobileView}
            initialSection={initialSection}
            children={(scrollToTarget, currentSection) => (
                <ListedContent
                    className="new-improvement__listed-content"
                    listedContentRef={listedContentRef}
                >
                    {isLoading ? (
                        <AnimatedSpinner
                            isVisible={isLoading}
                            position="center"
                            aligned="center"
                        />
                    ) : (
                        <NewImprovementDetailsContext.Provider
                            value={{
                                ...improvementFormData,
                                scrollToTarget,
                                currentSection,
                            }}
                        >
                            <ListedContent.SidePanel
                                sidePanelRef={sidePanelRef}
                                context={NewImprovementDetailsContext}
                            >
                                <SidePanelHeader />

                                <SidePanelDetails />
                            </ListedContent.SidePanel>
                            <ListedContent.Content contentRef={contentRef}>
                                <Content />
                            </ListedContent.Content>
                        </NewImprovementDetailsContext.Provider>
                    )}
                </ListedContent>
            )}
        />
    );
};

export default NewImprovementListedContent;
