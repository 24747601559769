import { Dispatch, useEffect, useState } from "react";
import { GroupBase, InputActionMeta, OptionsOrGroups } from "react-select";
import { OptionType } from "../SelectDropdownTypes";

export interface ICollapsibleGroupStateItem {
    userOpened: boolean;
    searchOpened: boolean;
}

export interface ICollapsibleGroupState {
    [key: string | number]: ICollapsibleGroupStateItem;
}

type withStateReturn = [
    ICollapsibleGroupState,
    Dispatch<React.SetStateAction<ICollapsibleGroupState>>,
    (inputValue: string, actionMeta: InputActionMeta) => void,
];
type withouStateReturn = [
    undefined,
    undefined,
    (inputValue: string, actionMeta: InputActionMeta) => void,
];

const collapsibleGroupsState = <D extends boolean, Option extends OptionType>(
    useCollapsableGroups: D,
    options?: OptionsOrGroups<Option, GroupBase<Option>>,
    openedByDefault?: boolean,
    onInputChange?: (inputValue: string, actionMeta: InputActionMeta) => void,
) => {
    if (useCollapsableGroups !== true) {
        return [undefined, undefined, onInputChange] as withouStateReturn;
    }

    const [state, setState] = useState<ICollapsibleGroupState>({});

    useEffect(() => {
        if (options) {
            const newState = options.reduce((acc, group) => {
                if ("options" in group) {
                    acc[group.value] = {
                        userOpened: !!openedByDefault,
                        searchOpened: false,
                    };
                }
                return acc;
            }, {} as ICollapsibleGroupState);

            setState(newState);
        }
    }, [options]);

    const handleOnChange = (
        inputValue: string,
        actionMeta: InputActionMeta,
    ) => {
        if (onInputChange) {
            onInputChange(inputValue, actionMeta);
        }

        if (
            actionMeta.action === "input-change" &&
            options &&
            Object.keys(state).length > 0
        ) {
            if (!inputValue || inputValue.length === 0) {
                setState((prev) => ({
                    ...prev,
                    ...Object.keys(prev).reduce((acc, key) => {
                        acc[key] = { ...prev[key], searchOpened: false };
                        return acc;
                    }, {} as ICollapsibleGroupState),
                }));
            } else {
                const newState = { ...state };

                const matchingGroupIds = getMatchingGroupIds(
                    inputValue,
                    options,
                );

                matchingGroupIds.forEach((groupId) => {
                    newState[groupId].searchOpened = true;
                });

                setState(newState);
            }
        }
    };

    return [state, setState, handleOnChange] as withStateReturn;
};

export default collapsibleGroupsState;

const getMatchingGroupIds = <Option extends OptionType>(
    inputValue: string,
    options: OptionsOrGroups<Option, GroupBase<Option>>,
) => {
    const matchingGroupIds = new Set<string | number>();
    const searchValue = inputValue.toLowerCase();

    options.forEach((group) => {
        if (group.label.toLowerCase().includes(searchValue)) {
            matchingGroupIds.add(group.value);
        }

        if ("options" in group && group.options) {
            group.options.forEach((option) => {
                if (option.label.toLowerCase().includes(searchValue)) {
                    matchingGroupIds.add(group.value);
                }
            });
        }
    });

    return Array.from(matchingGroupIds);
};
