import "./Forms.scss";

export interface IFormCategoryProps {
    title: React.ReactNode;
}

const FormCategory: React.FC<React.PropsWithChildren<IFormCategoryProps>> = (
    props,
) => {
    return (
        <div className="forms--category">
            <div className="forms--category--title">{props.title}</div>
            {props.children}
        </div>
    );
};

export default FormCategory;
