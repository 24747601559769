import "./InfoIcon.scss";

import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import classnames from "classnames";

interface IProps {
    disabled?: boolean;
    size?: "small";
    backgroundVariant?: "dark" | "gray";
    style?: React.CSSProperties;
    inheritColor?: boolean;
}

const InfoIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const isDark = props.backgroundVariant === "dark";
    const isGray = props.backgroundVariant === "gray";

    const classes = classnames({
        "icon-info": true,
        "icon-info--small": props.size === "small",
        disabled: props.disabled,
        "icon-info--dark-bg": isDark,
        "icon-info--gray-bg": isGray,
        "icon-info--inherit-color": props.inheritColor,
    });

    return <AiOutlineInfoCircle style={props.style} className={classes} />;
};

export default InfoIcon;
