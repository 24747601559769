import "./MobileMenuHeader.scss";

import React from "react";

import MenuNode from "./MenuNode";
import MobileMenuToggle from "./MobileMenuToggle";
import MobileOverflow from "common/components/utils/MobileOverflow";

interface IProps {
    show?: boolean;
    isClosing?: boolean;

    logoPath: string;

    onNavigateTo: (to: string) => void;
    onToggle: () => void;
}

const MobileMenuHeader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { show, isClosing, logoPath } = props;

    return (
        <div className="mobile-menu-header__wrapper">
            <MobileOverflow active={show} />

            <MenuNode.Link
                className="menu-section--logo"
                to="/"
                onSelect={props.onNavigateTo}
            >
                <img src={logoPath} alt="Logo" />
            </MenuNode.Link>

            <MobileMenuToggle
                show={show}
                isClosing={isClosing}
                onToggle={props.onToggle}
            />
        </div>
    );
};

export default MobileMenuHeader;
