import "./SelectTemplate.scss";

import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import TemplateBox from "./components/TemplateBox";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { getPublishedTemplates } from "components/templates/api/hooks";

import ModalContent from "common/components/modal-content/ModalContent";

import AnimatedSpinner from "../spinners/AnimatedSpinner";
import IWorkflow from "models/IWorkflow";

interface IProps {
    publishedTemplates?: IWorkflow[];

    isLoading: boolean;

    headerKey?: string;
    searchKey?: string;

    extraOptions?: React.ReactNode;

    onSelect: (templateVersionId: string) => void;
    onClose: () => void;
}

const SelectTemplate: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        publishedTemplates,
        isLoading,
        headerKey = k.SELECT_TEMPLATE,
        searchKey = k.SEARCH,
        extraOptions,
    } = props;

    const { t } = useTranslation();

    const [searchKeys, setSearchKeys] = useState("");

    const handleChange = (e: IInputChangeEvent<string>) => {
        setSearchKeys(e.value);
    };

    const templates = useMemo(() => {
        if (!searchKeys) {
            return publishedTemplates;
        } else {
            return publishedTemplates?.filter(
                (x) =>
                    x.name.toLowerCase().includes(searchKeys.toLowerCase()) ||
                    x.description
                        .toLowerCase()
                        .includes(searchKeys.toLowerCase()),
            );
        }
    }, [searchKeys, publishedTemplates]);

    return (
        <ModalContent>
            <ModalContent.Header
                onClose={props.onClose}
                title={t(k[headerKey as keyof typeof k])}
                className="select-template--header"
            />

            <ModalContent.Body>
                <div className="select-template--wrapper">
                    {extraOptions}
                    <div className="select-template--options">
                        <TextfieldComplex
                            testId="quickRunSearchInput"
                            size="large"
                            hideIcon
                            value={searchKeys}
                            placeholder={t(k[searchKey as keyof typeof k])}
                            bold
                            onChange={handleChange}
                        />
                    </div>
                    {isLoading ? (
                        <AnimatedSpinner
                            isVisible={isLoading}
                            position="center"
                            aligned="center"
                        />
                    ) : templates ? (
                        <div className="select-template--list">
                            {templates.map((t) => {
                                return (
                                    <TemplateBox
                                        key={t.id}
                                        id={t.workflowVersionId}
                                        name={t.name}
                                        description={t.description}
                                        onSelect={props.onSelect}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div>
                            {t(
                                k.NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE,
                            )}
                        </div>
                    )}
                </div>
            </ModalContent.Body>
        </ModalContent>
    );
};

export default SelectTemplate;
