import React from "react";

import { useTranslation } from "react-i18next";

import IconButton from "common/components/buttons/icon-button/IconButton";
import { UndoIcon } from "common/components/icons";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";
import IStep from "models/IStep";

import "./EditableListItems.scss";

interface IProps {
    step?: IStep;
    isDisabled?: boolean;
    errors?: IValidationMessages;
    index: number;
    onChange: (stepId: string, value: string) => void;
    onDelete: (stepId: string) => void;
}

const EditableStepListItem: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { step, isDisabled, errors, index } = props;

    if (!step) {
        return null;
    }

    const { t } = useTranslation();

    const handleOnChange = (e: IInputChangeEvent<string>) => {
        props.onChange(step.id, e.value);
    };

    const handleOnDelete = () => {
        props.onDelete(step.id);
    };

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div className="editable-list-items--wrapper" onClick={handleOnClick}>
            <InputComponent
                id={step.id}
                testId={`step-name-input-${index}`}
                wrapperLabelTestId={`step-name-label-${index}`}
                inputType={EActivityType.Textfield}
                wrapperClassName="editable-list-items--title"
                hideIcon
                value={step.name ?? ""}
                size="large"
                disabled={isDisabled || step.isDeleted || step.isLocal !== true}
                invalid={!!errors?.[`step.${step.id}.name`]}
                errors={getLocaleString(t, errors?.[`step.${step.id}.name`])}
                onChange={handleOnChange}
            />
            <IconButton
                testId={`step-delete-btn-${index}`}
                className="editable-list-items--delete"
                disabled={isDisabled}
                onClick={handleOnDelete}
            >
                {step.isDeleted ? <UndoIcon /> : <TrashIcon />}
            </IconButton>
        </div>
    );
};

export default EditableStepListItem;
