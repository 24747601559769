import { RefObject, createRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";
import { ModalTypes } from "common/components/modal-manager/api/IModalManager";
import showErrorToast from "common/utils/errors/toastErrors";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    scrollToTopErrorById,
    getIdsFromValidationMessages,
} from "components/common/validation/ScrollToError";
import {
    useDraftStepsByTemplateVersionId,
    useStepDataById,
    useSharedSteps,
} from "components/steps/api/hooks";
import { copyById } from "components/templates/api/actions";
import {
    useArchiveTemplateByVersionId,
    useCanEditTemplate,
    useDeleteTemplateByVersionId,
    useDeleteTemplateDraftByVersionId,
    useMutateTemplate,
    useTemplateDraftByVersionId,
    useTemplateUpgrade,
    useUploadedFilesByIds,
} from "components/templates/api/hooks";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink, {
    createTableSetParentLink,
    setStateTableSetWithParentLink,
} from "http/ITableSetWithParentLink";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import IActivityRequest from "http/requests/IActivityRequest";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IStepRequest from "http/requests/IStepRequest";
import IWorkflowRequest from "http/requests/IWorkflowRequest";
import k from "i18n/keys";
import IActivity from "models/IActivity";
import IAttachment from "models/IAttachment";
import IStep from "models/IStep";
import IWorkflow from "models/IWorkflow";
import IInputValueDTO from "models/dto/IInputValueDTO";
import ITemplateStepsListDTO from "models/dto/ITemplateStepsListDTO";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { v4 as uuidv4 } from "uuid";

import { ITemplateFormProps } from "../TemplateForm";
import UpgradeTemplateInfo from "../components/UpgradeTemplateInfo";
import ITemplateFormData from "./ITemplateFormData";

interface ITemplateForm extends IWorkflow {
    replacedIds?: Record<string, string>;
}

const getInitialTemplate: ITemplateForm = {
    id: uuidv4(),
    workflowVersionId: uuidv4(),
    name: "",
    description: "",
    index: 0,
    isDraft: false,
    isDefault: false,
    stepIds: [],
    addedStepIds: [],
    deletedStepIds: [],
    changedStepIds: [],
    replacedIds: {},
    hasPublishedVersion: false,
    hasNewVersion: false,
    version: 0,
    isArchived: false,
};

const getInitialStep = (id = uuidv4(), name = "", index = 0): IStep => ({
    id,
    stepVersionId: uuidv4(),
    name,
    description: "",
    isLocal: true,
    isDraft: true,
    index,
    teamIds: [],
    equipmentIds: [],
    hasPublishedVersion: false,
    hasNewVersion: false,
    isTeamEveryone: true,
    version: 0,
    isAdded: true,
});

const initializeSteps = (
    createNew?: boolean,
    name?: string,
): ITableSet<IStep> => {
    if (createNew) {
        const newId = uuidv4();
        return {
            ids: [newId],
            values: {
                [newId]: getInitialStep(newId, name, 0),
            },
        };
    }
    return {
        values: {},
        ids: [],
    };
};

export const useTemplateFormData = (
    props: ITemplateFormProps,
): ITemplateFormData => {
    const {
        id,
        createNew,
        onHaveChanges,
        onUpdateModal,
        haveChanges,
        syncData,
    } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isSaving, setIsSaving] = useState(false);

    const [errors, setErrors] = useState<IValidationMessages>();

    const { data: sharedSteps } = useSharedSteps();

    useEffect(() => {
        const errorValues = Object.values(errors ?? {});

        if (errorValues.some((value) => value !== undefined) === false) {
            setErrors(undefined);
        }
    }, [errors]);

    const [stepRefs, setStepRefs] =
        useState<IDictionary<RefObject<HTMLDivElement>>>();

    const { data: canEditTemplate, isLoading: canEditTemplateLoading } =
        useCanEditTemplate(id);

    const {
        data: templateData,
        isLoading: isLoadingTemplate,
        isError: isErrorTemplate,
    } = useTemplateDraftByVersionId(id, syncData);

    const [template, setTemplate] = useState<ITemplateForm>(getInitialTemplate);

    useEffect(() => {
        if (templateData) {
            setTemplate(templateData);
        }
    }, [templateData]);

    const { data: stepsList, isLoading: isLoadingStepsList } =
        useDraftStepsByTemplateVersionId(id, undefined, syncData);

    const [steps, setSteps] = useState<ITableSet<IStep>>(initializeSteps());

    const [activities, setActivities] = useState<
        ITableSetWithParentLink<IActivity, ITemplateStepsListDTO>
    >(createTableSetParentLink("activityIdsByStepId"));

    const [photos, setPhotos] = useState<Record<string, IInputValueDTO>>({});

    const [attachments, setAttachments] = useState<
        ITableSetWithParentLink<IAttachment, ITemplateStepsListDTO>
    >(createTableSetParentLink("attachmentsIdsByActivityId"));

    const [activityInputs, setActivityInputs] = useState<
        ITableSetWithParentLink<IActivityInputListItem, ITemplateStepsListDTO>
    >(createTableSetParentLink("activityInputsIdsByActivityId"));

    const templateMutation = useMutateTemplate();
    const templateDeleteMutation = useDeleteTemplateByVersionId();
    const templateDraftDeleteMutation = useDeleteTemplateDraftByVersionId();
    const templateArchiveMutation = useArchiveTemplateByVersionId();

    const setAllStates = () => {
        if (templateData && stepsList) {
            setSteps({
                values: stepsList.steps,
                ids: templateData.stepIds,
            });

            setActivities({
                values: stepsList.activities,
                ids: Object.keys(stepsList.activities),
                parents: {
                    activityIdsByStepId: stepsList.activityIdsByStepId,
                },
            });

            setAttachments({
                values: stepsList.attachments,
                ids: Object.keys(stepsList.attachments),
                parents: {
                    attachmentsIdsByActivityId:
                        stepsList.attachmentsIdsByActivityId,
                },
            });

            setActivityInputs({
                values: stepsList.activityInputs,
                ids: Object.keys(stepsList.activityInputs),
                parents: {
                    activityInputsIdsByActivityId:
                        stepsList.activityInputsIdsByActivityId,
                },
            });
        }
    };

    useEffect(() => {
        if (templateData && stepsList) {
            setAllStates();
        }
    }, [
        templateData,
        stepsList?.steps,
        stepsList?.activities,
        stepsList?.activityInputs,
        stepsList?.attachments,
    ]);

    useEffect(() => {
        if (steps?.ids) {
            setStepRefs(
                steps.ids.reduce<{
                    [key: string]: React.RefObject<HTMLDivElement>;
                }>((prev, cur) => {
                    return { ...prev, [cur]: createRef() };
                }, {}),
            );
        }
    }, [steps?.ids]);

    const getDeletedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && step.isDeleted;
        });
    };

    const getChangedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && step.isChanged;
        });
    };

    const getAddedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && step.isAdded;
        });
    };

    const getAddedOrChangedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && (step.isAdded || step.isChanged) && step.isLocal;
        });
    };

    const handleOnSave = async (draft?: boolean) => {
        const stepRequests = getAddedOrChangedSteps().reduce<IStepRequest[]>(
            (acc, stepId) => {
                const step = steps.values[stepId];

                if (step) {
                    const stepActivities =
                        activities.parents?.activityIdsByStepId?.[
                            stepId
                        ]?.reduce<IActivity[]>((acc, id) => {
                            const item = activities.values[id];
                            if (item && !item.isDeleted) {
                                acc.push(item);
                            }
                            return acc;
                        }, []) ?? [];

                    const {
                        id,
                        stepVersionId,
                        name,
                        description,

                        teamIds,
                        equipmentIds,
                        isTeamEveryone,
                        isBlocking,
                    } = step;

                    const stepRequest: IStepRequest = {
                        id,
                        stepVersionId,
                        name,
                        description,

                        isTeamEveryone,
                        isBlocking,

                        activities: stepActivities.map((activity) => {
                            const activityAttachments =
                                attachments.parents?.attachmentsIdsByActivityId?.[
                                    activity.id
                                ]?.reduce<IAttachment[]>((acc, id) => {
                                    const item = attachments.values[id];
                                    if (item && !item.isDeleted) {
                                        acc.push(item);
                                    }
                                    return acc;
                                }, []) ?? [];

                            const activityList =
                                activityInputs.parents?.activityInputsIdsByActivityId?.[
                                    activity.id
                                ]?.reduce<IActivityInputListItem[]>(
                                    (acc, id) => {
                                        const item = activityInputs.values[id];
                                        if (item) {
                                            acc.push(item);
                                        }
                                        return acc;
                                    },
                                    [],
                                ) ?? [];

                            const result: IActivityRequest = {
                                ...activity,
                                attachments: activityAttachments,
                                photo: photos[activity.id],
                                activityList,
                            };

                            return result;
                        }),

                        teamIds,
                        equipmentIds,
                        workflowId: createNew ? undefined : template.id,
                        isLocal: true,
                        saveAsDraft: true,
                    };

                    acc.push(stepRequest);
                }

                return acc;
            },
            [],
        );

        const workflowRequest: IWorkflowRequest = {
            id: template.id,
            workflowVersionId: template.workflowVersionId,
            saveAsDraft: draft ?? false,
            name: template.name,
            description: template.description,
            index: template.index,
            selectedStepIds: steps.ids,
            deletedStepIds: getDeletedSteps(),
            replacedIds: {},
            stepRequests,
        };

        if (!createNew && !draft) {
            const upgradeConfirmSettings: IShowConfirmArgs = {
                title: t(k.UPGRADE_CHECKLISTS_TITLE),
                message: t(k.DO_YOU_WANT_TO_UPGRADE_EXISTING_CHECKLISTS),
                showCancel: true,
                yesLabel: "Yes",
                noLabel: "No",
            };

            const upgradeConfirmResponse = await showConfirmNoThunk(
                dispatch,
                upgradeConfirmSettings,
            );

            if (upgradeConfirmResponse === undefined) {
                return;
            }

            if (upgradeConfirmResponse) {
                const upgradeResponse = await useTemplateUpgrade(
                    template.workflowVersionId,
                );

                if (upgradeResponse.requiresConfirmation) {
                    const upgradeInfoSettings: IShowConfirmArgs = {
                        title: t(k.UPGRADE_CHECKLISTS_TITLE),
                        message: (
                            <UpgradeTemplateInfo
                                upgradeResponseInfo={upgradeResponse}
                            />
                        ),
                        showCancel: true,
                        yesLabel: t(k.YES),
                        noLabel: t(k.DONT_UPGRADE),
                    };

                    const upgradeInfoResponse = await showConfirmNoThunk(
                        dispatch,
                        upgradeInfoSettings,
                    );

                    if (upgradeInfoResponse !== undefined) {
                        workflowRequest.shouldUpgrade = upgradeInfoResponse;
                    } else {
                        return;
                    }
                }
            }
        }

        setIsSaving(true);

        const result = await templateMutation.mutateAsync({
            request: workflowRequest,
            isCreate: createNew ?? false,
            invalidateSteps: true,
        });

        setIsSaving(false);

        if (result.Succeeded) {
            onHaveChanges(false);

            setErrors(undefined);

            if (draft) {
                toast.dismiss();

                toast.success(t(k.DRAFT_SAVED), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                if (createNew && result.Data?.workflowVersionId) {
                    onUpdateModal({
                        newId: result.Data.workflowVersionId,
                        newType: ModalTypes.template_form,
                        onSave: true,
                    });
                }
            } else {
                props.onClose(true);
            }
        } else {
            setErrors(result.Messages);

            scrollToTopErrorById(
                getIdsFromValidationMessages(result.Messages),
                "center",
            );
        }
    };

    const handleOnArchive = async () => {
        if (template) {
            const archiveRequest: IArchiveRequest = {
                id: template.workflowVersionId,
                isArchive: !template.isArchived,
            };

            const result = await templateArchiveMutation.mutateAsync({
                request: archiveRequest,
            });

            if (result.Succeeded) {
                onHaveChanges(false);
            }
        }
    };

    const handleOnReset = async () => {
        if (templateData && stepsList) {
            setTemplate(templateData);
            setAllStates();
        } else {
            setSteps({
                values: {},
                ids: [],
            });
            setTemplate(getInitialTemplate);
            setActivities(createTableSetParentLink("activityIdsByStepId"));
            setAttachments(
                createTableSetParentLink("attachmentsIdsByActivityId"),
            );
            setActivityInputs(
                createTableSetParentLink("activityInputsIdsByActivityId"),
            );
        }

        onHaveChanges(false);
    };

    const handleOnDelete = async (draft?: boolean) => {
        if (template?.id) {
            if (draft) {
                const result = await templateDraftDeleteMutation.mutateAsync({
                    versionId: template.workflowVersionId,
                });

                if (result.Succeeded) {
                    setErrors(undefined);
                }
            } else {
                const result = await templateDeleteMutation.mutateAsync({
                    versionId: template.workflowVersionId,
                });

                if (result.Succeeded) {
                    props.onClose(true, true);
                }
            }
        }
    };

    const handleOnAddOrReplaceSharedStep = async (
        newStepId: string,
        oldStepId?: string,
    ) => {
        const stepData = await useStepDataById(newStepId);

        if (stepData) {
            setSteps((prev) => {
                const stepIds = steps.ids;

                let newStepIds = stepIds.concat(newStepId);

                if (oldStepId) {
                    const oldIndex = stepIds.findIndex((x) => x === oldStepId);

                    if (oldIndex >= 0) {
                        newStepIds = [...stepIds];

                        newStepIds[oldIndex] = newStepId;
                    }

                    if (prev.values[oldStepId]?.isDeleted) {
                        stepData.step.isDeleted = true;
                    }

                    if (
                        (template?.hasPublishedVersion ?? false) &&
                        getAddedSteps().includes(newStepId) === false
                    ) {
                        stepData.step.isChanged = true;
                    }
                } else {
                    if (template?.hasPublishedVersion ?? false) {
                        stepData.step.isAdded = true;
                    }
                }

                return {
                    ...prev,
                    ids: newStepIds,
                    values: {
                        ...prev.values,
                        [newStepId]: stepData.step,
                    },
                };
            });

            setStateTableSetWithParentLink(
                setActivities,
                stepData.activities,
                "activityIdsByStepId",
                { [newStepId]: stepData.activityIds },
            );

            setStateTableSetWithParentLink(
                setAttachments,
                stepData.attachments,
                "attachmentsIdsByActivityId",
                stepData.attachmentsIdsByActivityId,
            );

            setStateTableSetWithParentLink(
                setActivityInputs,
                stepData.activityList,
                "activityInputsIdsByActivityId",
                stepData.activityListIdsByActivityId,
            );

            if (oldStepId) {
                setTemplate((prev) => {
                    return {
                        ...prev,
                        replacedIds: {
                            ...prev.replacedIds,
                            [oldStepId]: newStepId,
                        },
                    };
                });
            }

            onHaveChanges(true);
        }
    };

    const handleOnCopy = async () => {
        try {
            const result = await copyById(template.workflowVersionId);

            onUpdateModal({
                newId: result.value.workflowVersionId,
                newType: ModalTypes.template_form,
            });
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                showErrorToast(t, error.response?.data.errors._error);
            }
        }
    };

    const handleOnCopyStep = async (stepId: string) => {
        const stepToCopy = steps.values[stepId];
        const stepIndex = steps.ids.findIndex((x) => x === stepId);

        if (stepToCopy) {
            const newStepId = uuidv4();

            const newStep = {
                ...stepToCopy,
                id: newStepId,
                name: `${stepToCopy.name} (${t(k.COPY_A)})`,
                isChanged: false,
                isAdded: true,
            };

            setSteps((prev) => ({
                ...prev,
                ids: [
                    ...prev.ids.slice(0, stepIndex + 1),
                    newStepId,
                    ...prev.ids.slice(stepIndex + 1),
                ],
                values: { ...prev.values, [newStepId]: newStep },
            }));

            const oldActivityIds =
                activities.parents?.activityIdsByStepId?.[stepId];

            if (oldActivityIds) {
                const { newActivities, oldToNewActivityIds } =
                    oldActivityIds.reduce<{
                        newActivities: {
                            ids: string[];
                            values: Record<string, IActivity>;
                        };
                        oldToNewActivityIds: Record<string, string>;
                    }>(
                        (acc, oldId) => {
                            const activity = activities.values[oldId];
                            const newId = uuidv4();

                            if (activity) {
                                acc.newActivities.ids.push(newId);
                                acc.newActivities.values[newId] = {
                                    ...activity,
                                    id: newId,
                                    stepId: newStepId,
                                    versionId: uuidv4(),
                                    uploadedFileId: undefined,
                                };
                                acc.oldToNewActivityIds[oldId] = newId;
                            }

                            return acc;
                        },
                        {
                            newActivities: { ids: [], values: {} },
                            oldToNewActivityIds: {},
                        },
                    );

                setActivities((prev) => ({
                    ...prev,
                    ids: [...prev.ids, ...newActivities.ids],
                    values: {
                        ...prev.values,
                        ...newActivities.values,
                    },
                    parents: {
                        ...prev.parents,
                        activityIdsByStepId: {
                            ...prev.parents?.activityIdsByStepId,
                            [newStepId]: newActivities.ids,
                        },
                    },
                }));

                const newAttachmentsResult = oldActivityIds.reduce<{
                    ids: string[];
                    values: Record<string, IAttachment>;
                    parent: Record<string, string[]>;
                }>(
                    (result, activityId) => {
                        const attachmentIds =
                            attachments.parents?.attachmentsIdsByActivityId?.[
                                activityId
                            ];

                        if (attachmentIds) {
                            const newAttachments = attachmentIds.reduce<{
                                ids: string[];
                                values: Record<string, IAttachment>;
                            }>(
                                (acc, oldAttachmentId) => {
                                    const attachment =
                                        attachments.values[oldAttachmentId];

                                    const newId = uuidv4();

                                    if (attachment) {
                                        acc.ids.push(newId);
                                        acc.values[newId] = {
                                            ...attachment,
                                            id: newId,
                                            activityId,
                                        };
                                    }

                                    return acc;
                                },
                                { ids: [], values: {} },
                            );

                            result.ids.push(...newAttachments.ids);
                            result.values = {
                                ...result.values,
                                ...newAttachments.values,
                            };
                            result.parent[oldToNewActivityIds[activityId]] =
                                newAttachments.ids;
                        }

                        return result;
                    },
                    { ids: [], values: {}, parent: {} },
                );

                setAttachments((prev) => ({
                    ...prev,
                    ids: [...prev.ids, ...newAttachmentsResult.ids],
                    values: {
                        ...prev.values,
                        ...newAttachmentsResult.values,
                    },
                    parents: {
                        ...prev.parents,
                        attachmentsIdsByActivityId: {
                            ...prev.parents?.attachmentsIdsByActivityId,
                            ...newAttachmentsResult.parent,
                        },
                    },
                }));

                const newActivityInputResult = oldActivityIds.reduce<{
                    ids: string[];
                    values: Record<string, IActivityInputListItem>;
                    parent: Record<string, string[]>;
                }>(
                    (result, activityId) => {
                        const activityInputsIds =
                            activityInputs.parents
                                ?.activityInputsIdsByActivityId?.[activityId];

                        if (activityInputsIds) {
                            const newActivityInputs = activityInputsIds.reduce<{
                                ids: string[];
                                values: Record<string, IActivityInputListItem>;
                            }>(
                                (acc, oldActivityInputId) => {
                                    const activityInput =
                                        activityInputs.values[
                                            oldActivityInputId
                                        ];

                                    const newId = uuidv4();

                                    if (activityInput) {
                                        acc.ids.push(newId);
                                        acc.values[newId] = {
                                            ...activityInput,
                                            id: newId,
                                            activityId,
                                        };
                                    }

                                    return acc;
                                },
                                { ids: [], values: {} },
                            );

                            result.ids.push(...newActivityInputs.ids);
                            result.values = {
                                ...result.values,
                                ...newActivityInputs.values,
                            };
                            result.parent[oldToNewActivityIds[activityId]] =
                                newActivityInputs.ids;
                        }

                        return result;
                    },
                    { ids: [], values: {}, parent: {} },
                );

                setActivityInputs((prev) => ({
                    ...prev,
                    ids: [...prev.ids, ...newActivityInputResult.ids],
                    values: {
                        ...prev.values,
                        ...newActivityInputResult.values,
                    },
                    parents: {
                        ...prev.parents,
                        activityInputsIdsByActivityId: {
                            ...prev.parents?.activityInputsIdsByActivityId,
                            ...newActivityInputResult.parent,
                        },
                    },
                }));

                const { activityIds, fileIds } = oldActivityIds.reduce<{
                    activityIds: string[];
                    fileIds: string[];
                }>(
                    (acc, id) => {
                        const activity = activities.values[id];
                        if (activity?.uploadedFileId) {
                            acc.activityIds.push(activity.id);
                            acc.fileIds.push(activity.uploadedFileId);
                        }
                        return acc;
                    },
                    { activityIds: [], fileIds: [] },
                );

                if (activityIds.length && fileIds.length) {
                    const allPhotos = await useUploadedFilesByIds(fileIds);

                    const newPhotos = activityIds.reduce<
                        Record<string, IInputValueDTO>
                    >((acc, id) => {
                        const photo = allPhotos.find(
                            (x) =>
                                x.id === activities.values[id]?.uploadedFileId,
                        );

                        if (photo) {
                            acc[oldToNewActivityIds[id]] = {
                                value: "data:image/png;base64," + photo.content,
                                isDeleted: false,
                            };
                        }

                        return acc;
                    }, {});

                    setPhotos((prev) => ({
                        ...prev,
                        ...newPhotos,
                    }));
                }

                const activitiesWithPhotos = oldActivityIds.filter(
                    (x) => photos[x] !== undefined,
                );

                if (activitiesWithPhotos.length) {
                    setPhotos((prev) => ({
                        ...prev,
                        ...activitiesWithPhotos.reduce<
                            Record<string, IInputValueDTO>
                        >((acc, id) => {
                            acc[oldToNewActivityIds[id]] = photos[id];
                            return acc;
                        }, photos),
                    }));
                }
            }

            onHaveChanges(true);
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const initialSection = steps && steps.ids[0];

    const isLoading = isLoadingTemplate && isLoadingStepsList;
    return {
        createNew,

        isLoading,
        isLoadingTemplate,
        isErrorTemplate,
        isLoadingSteps: isLoadingStepsList,
        isSaving,

        errors,
        setErrors,

        sectionRefs: stepRefs,

        canEditTemplate,
        canEditTemplateLoading,

        sharedSteps,

        template,
        setTemplate,

        isArchived: template.isArchived,

        steps,
        setSteps,

        activities,
        setActivities,

        attachments,
        setAttachments,

        activityInputs,
        setActivityInputs,

        photos,
        setPhotos,

        initialSection,

        haveChanges,

        onHaveChanges,

        handleOnSave,

        handleOnClose,

        handleOnArchive,

        handleOnDelete,

        handleOnReset,

        handleOnAddOrReplaceSharedStep,

        handleOnCopy,

        handleOnCopyStep,
    };
};
