import { FC, useContext } from "react";
import ChecklistDetailsContext from "../../../../../../checklistContext";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Button from "common/components/buttons/Button";

interface Props {}

const CancelButton: FC<Props> = () => {
    const {
        setEditedChecklistValues,
        onHaveChanges,
        setErrors,
        setUsersAffected,
        setEditedSteps,
        setEditMode,
    } = useContext(ChecklistDetailsContext);
    const { t } = useTranslation();

    const handleCancelEdit = async () => {
        setEditMode(false);
        setEditedChecklistValues(undefined);
        setEditedSteps(undefined);
        setUsersAffected(undefined);
        setErrors(undefined);
        onHaveChanges(false);
    };
    return (
        <Button
            variant="white"
            transparent
            size="small"
            onClick={handleCancelEdit}
        >
            {t(k.CANCEL)}
        </Button>
    );
};

export default CancelButton;
