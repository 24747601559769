import React, { useContext } from "react";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { HistoryIcon } from "common/components/icons";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import ChecklistDetailsContext from "components/checklists/components/ChecklistListedContent/checklistContext";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";

interface IButtonProps {
    id?: string;
    type: HistoryOwnerEnum;
    showAll?: boolean;
}

const HistoryButton: React.FC<React.PropsWithChildren<IButtonProps>> = (
    props,
) => {
    const { id, showAll = true, type } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleShowHistory = () => {
        const payload: IShowModalPayload = {
            id: id,
            type: ModalTypes.history,
            callerAddress: location.pathname,

            options: {
                historyOwnerType: type,
                showAll,
            },
        };

        modalManagerContext.onShowModal(payload);
    };

    return (
        <IconButton
            onClick={handleShowHistory}
            background="dark"
            testId="historyBtn"
            className="history"
        >
            <HistoryIcon size={24} />
        </IconButton>
    );
};

export default HistoryButton;
