import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "axios";

import IChecklistReportDTO from "../api/IChecklistReportDTO";
import IChecklistReportFilter from "../api/IChecklistReportFilter";
import { IChecklistReportValueSumFilter } from "./IChecklistReportValueSumFilter";
import {
    IInitialFetchOptions,
    useInitialFetch,
} from "common/utils/query/useInitialFetch";
import { convertToDate } from "common/utils/time";
import IDictionary from "common/viewModels/IDictionary";
import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";
import ITableSet from "http/ITableSet";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";

const baseUrl = "/api/workflowRun";

const SyncIntervalMs = 5000;

async function getSearchTemplateList(
    filter: IChecklistReportFilter,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/template-list`;

    const response = await axios.post<string[]>(url, filter, {
        signal,
    });

    return response.data;
}

export const useSearchTemplateListQuery = (
    { enabled, syncData }: IInitialFetchOptions,
    filter: IChecklistReportFilter,
) =>
    useInitialFetch(
        ["useSearchTemplateList", filter],
        ({ signal }) => getSearchTemplateList(filter, signal),
        { enabled, syncData },
    );

export const useOnlyStoppableChecklistsTotalCount = (
    parentFilter: IChecklistReportFilter,
    templateVersionIds?: string[],
) => {
    const client = useQueryClient();

    return useQuery(
        ["useTotalChecklistsCountToPause", parentFilter, templateVersionIds],
        () => {
            if (templateVersionIds) {
                if (
                    templateVersionIds === undefined ||
                    templateVersionIds.length === 0
                ) {
                    return client.getQueryData<number>([
                        "report-totalOnlyStoppableChecklistsCount",
                        parentFilter,
                    ]);
                } else if (templateVersionIds.length === 1) {
                    return client.getQueryData<number>([
                        "report-totalOnlyStoppableChecklistsCount",
                        parentFilter,
                        templateVersionIds[0],
                    ]);
                } else {
                    if (parentFilter.splitSearchResult) {
                        let result = 0;

                        for (const id of templateVersionIds) {
                            const resultById = client.getQueryData<number>([
                                "report-totalOnlyStoppableChecklistsCount",
                                parentFilter,
                                id,
                            ]);

                            if (resultById !== undefined) {
                                result += resultById;
                            }
                        }

                        return result;
                    } else {
                        return client.getQueryData<number>([
                            "report-totalOnlyStoppableChecklistsCount",
                            parentFilter,
                            templateVersionIds,
                        ]);
                    }
                }
            }
        },
    );
};

async function getChecklistReportItems(
    filter?: IChecklistReportFilter,
    signal?: AbortSignal,
) {
    if (filter) {
        const url = `${baseUrl}/report`;

        const response = await axios.post<IChecklistReportDTO>(url, filter, {
            signal,
        });

        return response.data;
    }
}

export async function getCalculateValueSums(
    filter: IChecklistReportValueSumFilter,
    signal?: AbortSignal,
) {
    if (filter.hasValueColumn) {
        const url = `${baseUrl}/calc-value-sum`;

        const response = await axios.post<ITableSet<number | undefined>>(
            url,
            filter,
            {
                signal,
            },
        );

        return response.data;
    }
}

export const useChecklistReportItemsQuery = (
    { enabled, syncData }: IInitialFetchOptions,
    parentFilter: IChecklistReportFilter,
    filter?: IChecklistReportFilter,
) => {
    const client = useQueryClient();

    return useInitialFetch(
        ["useChecklistReportItems", filter],

        ({ signal }) => getChecklistReportItems(filter, signal),

        { enabled, syncData },
        {
            refetchInterval: SyncIntervalMs,

            onSettled: (result) => {
                if (
                    filter?.templateVersionIds === undefined ||
                    filter.templateVersionIds.length === 0
                ) {
                    client.setQueryData(
                        [
                            "report-totalOnlyStoppableChecklistsCount",
                            parentFilter,
                        ],
                        result?.totalOnlyStoppableChecklistsCount,
                    );
                } else if (filter.templateVersionIds.length === 1) {
                    client.setQueryData(
                        [
                            "report-totalOnlyStoppableChecklistsCount",
                            parentFilter,
                            filter?.templateVersionIds[0],
                        ],
                        result?.totalOnlyStoppableChecklistsCount,
                    );
                } else {
                    client.setQueryData(
                        [
                            "report-totalOnlyStoppableChecklistsCount",
                            parentFilter,
                            filter?.templateVersionIds,
                        ],
                        result?.totalOnlyStoppableChecklistsCount,
                    );
                }

                client.invalidateQueries([
                    "useTotalChecklistsCountToPause",
                    parentFilter,
                ]);
            },
        },
    );
};

async function pauseAll(filter: IChecklistReportFilter) {
    const url = `${baseUrl}/pause-all`;

    const response = await axios.post<number>(url, filter);

    return response.data;
}

export const usePauseAllMutation = () => {
    return useMutation((filter: IChecklistReportFilter) => pauseAll(filter));
};

export const useDeleteByIdChecklistMutation = () => {
    return useMutation(async (checklistId: string) => {
        try {
            await axios.delete(`${baseUrl}/${checklistId}`);

            return true;
        } catch (ex) {}

        return false;
    });
};

export const useArchiveChecklistMutation = () => {
    return useMutation(async (variables: IArchiveRequest) => {
        try {
            const response = await axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables,
            );

            const result = response.data;

            result.archivedAt = convertToDate(result.archivedAt);

            return result;
        } catch (error) {}
    });
};

export const usePauseChecklistMutation = () => {
    return useMutation(async (variables: IPauseRequest) => {
        try {
            const response = await axios.post<IPauseResponse>(
                `${baseUrl}/pause`,
                variables,
            );

            const result = response.data;

            result.pausedAt = convertToDate(result.pausedAt);

            return result;
        } catch (error) {}
    });
};

async function getChecklistReportDetailsByIds(
    checklistIds?: string[],
    signal?: AbortSignal,
) {
    if (checklistIds && checklistIds.length > 0) {
        const url = `${baseUrl}/report-details`;

        const response = await axios.post<IChecklistReportResponse>(
            url,
            checklistIds,
            { signal },
        );

        return response.data;
    }
}

export const useChecklistReportDetailsQuery = (
    { enabled, syncData }: IInitialFetchOptions,
    uniqueKeys: IDictionary<string>,
    checklistIds?: string[],
) =>
    useInitialFetch(
        ["useChecklistReportDetails", uniqueKeys],
        ({ signal }) => getChecklistReportDetailsByIds(checklistIds, signal),
        { enabled, syncData },
        { refetchInterval: SyncIntervalMs },
    );
