import "./SplitLayout.scss";

import React from "react";

export interface TabData {
    id: number;
    label: string;
    component: React.ReactNode;
    tips: string[];
}

export interface Props {
    tabs: React.ReactNode;
    content: React.ReactNode;
    tips: React.ReactNode;

    contentRef: React.RefObject<HTMLDivElement>;
}

const SplitLayout: React.FC<Props> = (props) => {
    const { tabs, content, tips, contentRef } = props;

    return (
        <div className="split-layout">
            <div className="tabs">{tabs}</div>
            <div className="content">
                <div ref={contentRef} className="tab-content">
                    {content}
                </div>
            </div>
            <div className="tips">{tips}</div>
        </div>
    );
};

export default SplitLayout;
