import "./Checkbox.scss";

import React, { useMemo } from "react";

import { IWithDebounce, IInputProps } from "../IInputProps";

import classnames from "classnames";
export interface ICheckboxProps extends IWithDebounce {
    partial?: boolean;
    bold?: boolean;
    variant?: "success" | "disabled";
}

type Props<V> = ICheckboxProps & IInputProps<V>;

const Checkbox = <V,>(props: Props<V>) => {
    const {
        id,
        name,
        disabled,
        preview,
        invalid,
        label,
        value,
        parentId,
        bold,
        variant,
        wrapperClassName,
        partial,
    } = props;

    const isDisabled = disabled || preview;

    const handleChange = () => {
        if (props.onChange && !isDisabled) {
            const isChecked = !value;

            props.onChange({
                id,
                name,
                parentId,
                value: isChecked as V,
            });
        }
    };

    const className = classnames("checkbox-cn", wrapperClassName, {
        checked: value,
        "checkbox--invalid": invalid,
        "checkbox--disabled": isDisabled,
        "checkbox--bold": bold,
        partial: partial,
    });

    const checkClassName = classnames("checkmark", variant);

    return (
        <label className={className}>
            <span className={checkClassName}></span>
            {label && <span className="checkbox-text">{label}</span>}
            <input
                id={id}
                type="checkbox"
                disabled={isDisabled}
                checked={value as boolean}
                onChange={handleChange}
            />
        </label>
    );
};

export default Checkbox;
