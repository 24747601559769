import "./FormulaInputField.scss";

import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ITableSet from "http/ITableSet";
import {
    EFormulaCategory,
    IFormulaItem,
    formulaEndsWithManySpacesRegEx,
    formulaEndsWithSpacesRegEx,
    formulaNumberRegEx,
    formulaOperatorRegEx,
    formulaParenthesisRegEx,
    formulaVariableRegEx,
    getCategoryByValue,
    getFormulaItemTypeByValue,
    getUnsetItem,
    splitString,
    validVariableRegEx,
} from "../../../utility/FormulaTools";
import InputWrapper from "common/components/input-components/InputWrapper";

import { FormulaItemType } from "../../../api/FormulaItemType";
import { Button } from "common/components/buttons";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { sanitizeText } from "../../../utility/FormulaValidationTools";
import TooltipWrapper from "common/components/tooltip/TooltipWrapper";
import { toast } from "react-toastify";
import MakeToastMessage from "common/components/toast/MakeToastMessage";
import { AppLocale, LocaleId } from "AppLocale";

interface IProps {
    items: ITableSet<IFormulaItem>;
    focusedInput?: string;
    errors: IValidationMessages;
    allErrors?: IValidationMessages;

    onValidate: () => void;
    onChange: (id: string, item: IFormulaItem) => void;
    onDeleteItem: (id: string) => void;
    onInitItems: (items: IFormulaItem[]) => void;
    onFocusChange: (inputId?: string) => void;
    onPasteFormula: (formula: string) => void;
    onClearFormula: () => void;
}

const MAX_PASTE_LENGTH = 1000;

const borderColor = {
    [EFormulaCategory.Unset]: "",
    [EFormulaCategory.Variable]: "variable-color",
    [EFormulaCategory.StaticNumber]: "static-number-color",
    [EFormulaCategory.Operator]: "operator-color",
    [EFormulaCategory.Parenthesis]: "parenthesis-color",
};

const FormulaInputField = (props: React.PropsWithRef<IProps>) => {
    const { items, focusedInput, errors, allErrors } = props;

    const { t } = useTranslation();

    const [inputRefs, setInputRefs] = useState<
        Record<string, React.RefObject<HTMLInputElement>>
    >({});

    const [inputMarkerLocation, setInputMarkerLocation] = useState<number>(0);

    const [inputToFocus, setInputToFocus] = useState<string | undefined>(
        focusedInput,
    );

    useEffect(() => {
        if (items.ids) {
            const refs = items.ids.reduce<
                Record<string, React.RefObject<HTMLInputElement>>
            >((acc, id) => {
                acc[id] = React.createRef<HTMLInputElement>();
                return acc;
            }, {});
            setInputRefs(refs);
        }
    }, [items.ids]);

    useEffect(() => {
        if (inputToFocus) {
            const input = inputRefs[inputToFocus]?.current;

            if (input) {
                input.focus();

                setInputMarkerLocation(input.value.length);

                setInputToFocus(undefined);
            }
        }
    }, [inputToFocus, inputRefs]);

    const handleOnDeleteAndFocus = (id: string) => {
        const index = items.ids.indexOf(id);
        const currentInput = inputRefs[id]?.current;
        if (
            currentInput &&
            currentInput.selectionStart === inputMarkerLocation
        ) {
            if (items.ids.length > 1 && index !== -1) {
                const nextIndex = index > 0 ? index - 1 : index;

                const nextInput = inputRefs[items.ids[nextIndex]]?.current;

                if (nextInput) {
                    nextInput.focus();

                    setInputMarkerLocation(nextInput.value.length);
                }
            }

            props.onDeleteItem(id);
        }
    };

    const handleOnChangeFocusRight = (id: string) => {
        const index = items.ids.indexOf(id);

        const currentInput = inputRefs[id]?.current;

        if (
            currentInput &&
            currentInput.selectionStart === inputMarkerLocation
        ) {
            if (index >= 0) {
                const nextInput = inputRefs[items.ids[index + 1]]?.current;

                if (nextInput) {
                    nextInput.focus();
                    nextInput.setSelectionRange(0, 0);

                    setInputMarkerLocation(0);
                }
            }
        }
    };

    const handleOnChangeFocusLeft = (id: string) => {
        const index = items.ids.indexOf(id);

        const currentInput = inputRefs[id]?.current;

        if (
            currentInput &&
            currentInput.selectionStart === inputMarkerLocation
        ) {
            if (index > 0) {
                const nextInput = inputRefs[items.ids[index - 1]]?.current;

                if (nextInput) {
                    nextInput.focus();

                    setInputMarkerLocation(nextInput.value.length);
                }
            }
        }
    };

    const unfocusInput = (id: string) => {
        const input = inputRefs[id]?.current;
        if (input) {
            input.blur();
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.currentTarget;
        const item = items.values[id];

        const target = e.target;

        if (item) {
            const lengthDiff = value.length - item.value.length;

            const startPost = Math.max(
                (target.selectionStart ?? 0) - lengthDiff,
                0,
            );
            const endPost = Math.max(
                (target.selectionEnd ?? 0) - lengthDiff,
                0,
            );

            if (value.length > MAX_PASTE_LENGTH) {
                target.value = item.value;
                target.setSelectionRange(startPost, endPost);

                toast.error(
                    MakeToastMessage(t(k.PASTE_FAILED), t(k.FORMULA_TOO_LONG)),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        autoClose: 2000,
                    },
                );

                return;
            }

            switch (item.category) {
                case EFormulaCategory.Variable:
                    const varMatch = value.match(formulaVariableRegEx);
                    const matchedVariable =
                        varMatch && varMatch.length === 1 && varMatch[0];

                    if (
                        (matchedVariable &&
                            matchedVariable !== item.value &&
                            !matchedVariable.match(
                                formulaEndsWithManySpacesRegEx,
                            )) ||
                        value.length === 0
                    ) {
                        props.onChange(id, {
                            ...item,
                            value,
                        });
                    } else {
                        target.value = item.value;
                        target.setSelectionRange(startPost, endPost);
                    }

                    break;

                case EFormulaCategory.Operator:
                    const opMatch = value.match(formulaOperatorRegEx);
                    const matchedOperator =
                        opMatch && opMatch.length === 1 && opMatch[0];

                    const numMatchOp = value.match(formulaNumberRegEx());
                    const matchedNumberOp =
                        numMatchOp && numMatchOp.length === 1 && numMatchOp[0];

                    if (
                        (matchedOperator && matchedOperator !== item.value) ||
                        value.length === 0
                    ) {
                        props.onChange(id, {
                            ...item,
                            type: getFormulaItemTypeByValue(value),
                            value,
                        });
                    } else if (matchedNumberOp) {
                        props.onChange(id, {
                            ...item,
                            value,
                            category: EFormulaCategory.StaticNumber,
                            type: FormulaItemType.Constant,
                        });

                        unfocusInput(id);
                        setInputToFocus(id);
                    } else {
                        target.value = item.value;
                        target.setSelectionRange(startPost, endPost);
                    }

                    break;

                case EFormulaCategory.StaticNumber:
                    const numMatch = value.match(formulaNumberRegEx());
                    const matchedNumber =
                        numMatch && numMatch.length === 1 && numMatch[0];

                    if (
                        (matchedNumber && matchedNumber !== item.value) ||
                        value.length === 0
                    ) {
                        props.onChange(id, { ...item, value });
                    } else {
                        target.value = item.value;
                        target.setSelectionRange(startPost, endPost);
                    }

                    break;

                case EFormulaCategory.Parenthesis:
                    const paraMatch = value.match(formulaParenthesisRegEx);
                    const matchedParenthesis =
                        paraMatch && paraMatch.length === 1 && paraMatch[0];

                    if (
                        (matchedParenthesis &&
                            matchedParenthesis !== item.value) ||
                        value.length === 0
                    ) {
                        props.onChange(id, { ...item, value });
                    } else {
                        target.value = item.value;
                        target.setSelectionRange(startPost, endPost);
                    }

                    break;

                case EFormulaCategory.Unset:
                    const trimmedValue = value.trim();

                    if (trimmedValue.length > 0) {
                        const matchedFormula = splitString(trimmedValue);

                        if (matchedFormula && matchedFormula.length > 0) {
                            let lastId = id;

                            const firstFormula = matchedFormula.shift();
                            const items = [];
                            if (firstFormula) {
                                items.push({
                                    ...item,
                                    value: firstFormula,
                                    type: getFormulaItemTypeByValue(
                                        firstFormula,
                                    ),
                                    category: getCategoryByValue(firstFormula),
                                });
                            }

                            if (matchedFormula.length > 0) {
                                matchedFormula.forEach((formula) => {
                                    const newItem = getUnsetItem();

                                    items.push({
                                        ...newItem,
                                        value: formula,
                                        type: getFormulaItemTypeByValue(
                                            formula,
                                        ),
                                        category: getCategoryByValue(formula),
                                    });

                                    lastId = newItem.id;
                                });
                            }

                            props.onInitItems(items);

                            unfocusInput(id);
                            setInputToFocus(lastId);
                        }

                        break;
                    }

                    target.value = item.value;
                    target.setSelectionRange(startPost, endPost);

                    break;
            }
        }
    };

    const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { id, value } = e.currentTarget;
        const inputElement = e.currentTarget;

        const item = items.values[id];

        if (item) {
            setInputMarkerLocation(inputElement.selectionStart ?? 0);

            const isAtBeginning = inputElement.selectionStart === 0;
            const isAtEnd = inputElement.selectionStart === value.length;

            switch (e.key) {
                case "Enter":
                    handleOnChangeFocusRight(id);

                    break;

                case "Backspace":
                    if (isAtBeginning) {
                        if (
                            item.category !== EFormulaCategory.Unset &&
                            value.length === 0
                        ) {
                            handleOnDeleteAndFocus(id);
                        } else {
                            handleOnChangeFocusLeft(id);
                        }
                    }

                    break;

                case "Delete":
                    if (isAtEnd) {
                        if (
                            item.category !== EFormulaCategory.Unset &&
                            value.length === 0
                        ) {
                            handleOnDeleteAndFocus(id);
                        } else {
                            handleOnChangeFocusRight(id);
                        }
                    }

                    break;

                case "ArrowRight":
                    if (isAtEnd) {
                        handleOnChangeFocusRight(id);
                    }

                    break;

                case "ArrowLeft":
                    if (isAtBeginning) {
                        handleOnChangeFocusLeft(id);
                    }

                    break;

                case " ":
                    if (isAtEnd) {
                        handleOnChangeFocusRight(id);

                        break;
                    }

                    const jumpToEnd = value
                        .slice(inputMarkerLocation)
                        .match(formulaEndsWithSpacesRegEx);

                    if (jumpToEnd) {
                        inputElement.setSelectionRange(
                            value.length,
                            value.length,
                        );

                        setInputMarkerLocation(value.length);
                    }

                    break;
            }
        }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onFocusChange(e.currentTarget.id);

        const inputElement = e.currentTarget;

        setTimeout(() => {
            const location = inputElement.selectionStart;

            if (location) {
                setInputMarkerLocation(location);
            }
        }, 0);
    };

    const handleOnPasteFormula = async () => {
        try {
            const text = await navigator.clipboard.readText();

            if (text.length > MAX_PASTE_LENGTH) {
                throw new Error(t(k.FORMULA_TOO_LONG));
            }

            const sanitizedText = sanitizeText(text);
            props.onPasteFormula(sanitizedText);
        } catch (error) {
            const errorMessage =
                error instanceof Error ? error.message : undefined;

            toast.error(MakeToastMessage(t(k.PASTE_FAILED), errorMessage), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
            });
        }
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { id, value } = e.currentTarget;
        const inputElement = e.currentTarget;

        const item = items.values[id];
        if (item && item.category === EFormulaCategory.Variable) {
            const varMatch = value.match(validVariableRegEx);
            if (varMatch && varMatch.length === 1) {
                inputElement.value = varMatch[0];

                props.onChange(id, {
                    ...item,
                    value: varMatch[0],
                });
            }
        }
        if (
            Object.values(inputRefs).some(
                (inputRef) => inputRef.current !== document.activeElement,
            )
        ) {
            props.onFocusChange();
        }
    };

    return (
        <InputWrapper
            id="formulaItems"
            wrapperLabel={t(k.FORMULA_EXPRESSION)}
            inputClassName="formula-input"
            required
            boldLabel
            fullWidth
            htmlFor="nope"
            titleErrors={
                allErrors?.formulaItems && (
                    <ValidationLabel
                        errors={t(k[allErrors.formulaItems as keyof typeof k])}
                    />
                )
            }
        >
            <div className="formula-input--field">
                {items.ids.map((id, index) => {
                    const isLast = index === items.ids.length - 1;

                    return (
                        <div
                            className={`formula-input--field--item ${
                                borderColor[
                                    items.values[id]?.category ??
                                        EFormulaCategory.Unset
                                ]
                            } ${errors[id] ? "color-invalid" : ""}`}
                            key={index}
                        >
                            {items.values[id]?.category ===
                                EFormulaCategory.Variable && <>"</>}

                            <TooltipWrapper
                                id={id}
                                message={(errors[id] as string[])?.join("\n")}
                                showTooltip={!!errors[id]}
                            >
                                <GrowingInputUsingSpan
                                    inputRef={inputRefs[id] ?? null}
                                    id={id}
                                    category={
                                        items.values[id]?.category ===
                                        EFormulaCategory.StaticNumber
                                            ? "number"
                                            : "text"
                                    }
                                    value={items.values[id]?.value}
                                    onChange={handleOnChange}
                                    onKeyUp={handleOnKeyUp}
                                    onFocus={handleOnFocus}
                                    onBlur={handleOnBlur}
                                    lastInputMaxWidth
                                    isLastInput={isLast}
                                />
                            </TooltipWrapper>

                            {items.values[id]?.category ===
                                EFormulaCategory.Variable && <>"</>}
                        </div>
                    );
                })}
            </div>
            <div className="formula-input--buttons">
                <Button
                    noMinWidth
                    size="small"
                    variant="blue"
                    transparent
                    onClick={props.onValidate}
                >
                    {t(k.VALIDATE)}
                </Button>
                <Button
                    noMinWidth
                    size="small"
                    variant="blue"
                    transparent
                    onClick={handleOnPasteFormula}
                >
                    {t(k.PASTE)}
                </Button>
                <Button
                    noMinWidth
                    size="small"
                    variant="blue"
                    transparent
                    onClick={props.onClearFormula}
                >
                    {t(k.CLEAR)}
                </Button>
            </div>
        </InputWrapper>
    );
};

interface IGrowingInputProps {
    inputRef?: React.RefObject<HTMLInputElement> | null;
    id?: string;
    category?: React.HTMLInputTypeAttribute;
    lastInputMaxWidth?: boolean;
    isLastInput?: boolean;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const GrowingInputUsingSpan: React.FC<IGrowingInputProps> = (props) => {
    const { id, value, lastInputMaxWidth, isLastInput } = props;

    const isMaxWidth = lastInputMaxWidth && isLastInput;

    const inputRef =
        props.inputRef !== undefined
            ? props.inputRef
            : useRef<HTMLInputElement>(null);
    const sizerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (sizerRef.current && inputRef?.current) {
            if (isMaxWidth) {
                inputRef.current.style.width = "100%";
            } else {
                inputRef.current.style.width = `${sizerRef.current.scrollWidth}px`;
            }
        }
    }, [value, inputRef, sizerRef]);

    return (
        <div
            className={`auto-width-input ${
                isMaxWidth ? "auto-width-input--last" : ""
            }`}
        >
            <input
                type="text"
                id={id}
                data-item-id={id}
                ref={inputRef}
                className={`auto-width-input--input`}
                value={value}
                title={value}
                onChange={props.onChange}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            <div
                ref={sizerRef}
                aria-hidden="true"
                className="auto-width-input--sizer"
            >
                {value ?? ""}
            </div>
        </div>
    );
};

export default FormulaInputField;
