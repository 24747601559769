import k from "i18n/keys";

import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import IImprovementFilter from "components/improvements/api/IImprovementFIlter";
import { IMeasurementRequest } from "components/measurement/api/IMeasurementRequest";
import { ETransactionStatus } from "models/enums/TransactionStatus";
import {
    IClientGoalPageFilter,
    IServerGoalPageFilter,
} from "components/goals-page/api/IGoalPageFilters";

export const ModalTypes = {
    checklist: "checklist",
    template: "template",
    recurrence: "recurrence",
    select_checklist_template: "select_checklist_template",
    select_improvement_form: "select_improvement_form",
    process_step: "process_step",
    improvement: "improvement",
    multiple_improvements: "multiple_improvements",
    improvement_form: "improvement_form",
    custom_list: "custom_list",
    custom_list_form: "custom_list_form",
    risk_form: "risk_form",
    team_form: "team_form",
    user_form: "user_form",
    history: "history",
    kpi_form: "kpi_form",
    kpi_details: "kpi_details",
    template_form: "template_form",
    customer_form: "customer_form",
    contacts_form: "contacts_form",
    checklist_report: "checklist_report",
    goal_form: "goal_form",
    measurement_form: "measurement_form",
    measurement_progress_form: "measurement_progress_form",
} as const;

const ModalTypeList = Object.values(ModalTypes);

export type TModalTypes = (typeof ModalTypeList)[number];

export const KeyForModalType = {
    [ModalTypes.checklist]: k.CHECKLIST,
    [ModalTypes.template]: k.TEMPLATE,
    [ModalTypes.recurrence]: k.RECURRING_CHECKLIST,
    [ModalTypes.select_checklist_template]: k.SELECT_CHECKLIST_TEMPLATE,
    [ModalTypes.select_improvement_form]: k.SELECT_IMPROVEMENT_FORM,
    [ModalTypes.process_step]: k.PROCESS_STEP,
    [ModalTypes.improvement]: k.IMPROVEMENT,
    [ModalTypes.improvement_form]: k.IMPROVEMENT_FORM,
    [ModalTypes.multiple_improvements]: k.IMPROVEMENTS,
    [ModalTypes.custom_list]: k.CUSTOM_LIST,
    [ModalTypes.custom_list_form]: k.CUSTOM_LIST,
    [ModalTypes.risk_form]: k.RISKS,
    [ModalTypes.team_form]: k.TEAMS,
    [ModalTypes.user_form]: k.USERS,
    [ModalTypes.history]: k.HISTORY,
    [ModalTypes.kpi_form]: k.KPI,
    [ModalTypes.kpi_details]: k.KPI,
    [ModalTypes.template_form]: k.TEMPLATE,
    [ModalTypes.customer_form]: k.CUSTOMER,
    [ModalTypes.contacts_form]: k.CONTACTS,
    [ModalTypes.checklist_report]: k.CHECKLISTS,
    [ModalTypes.goal_form]: k.GOAL,
    [ModalTypes.measurement_form]: k.MEASUREMENT,
    [ModalTypes.measurement_progress_form]: k.PROGRESS,
};

export enum EShowState {
    all = "all",
    active = "active",
    closed = "closed",
}

export enum EChecklistReportState {
    active = "active",
    finalized = "finalized",
}

export interface IModalCallId {
    callId: string;
    id: string;
    type: TModalTypes | string;
}

export interface IModalOptions {
    createNew?: boolean;
    showAll?: boolean;
    edit?: boolean;
    impFilter?: IImprovementFilter;
    parentId?: string;
    goalServerFilter?: IServerGoalPageFilter;
    goalClientFilter?: IClientGoalPageFilter;
    historyOwnerType?: HistoryOwnerEnum;
    checklistReportState?: EChecklistReportState;

    sourceCustomListItemId?: string;

    [key: string]: any;
}

export interface IModalFor {
    type: TModalTypes | string;
    id: string;
}

export enum EModalCallBackTypes {
    Id = "id",
    Measurement = "measurement",
}

export interface IModalCallBackTypes {
    [EModalCallBackTypes.Id]: string;
    [EModalCallBackTypes.Measurement]: IMeasurementRequest;
}

export interface IModalCallbacks<CBT extends keyof IModalCallBackTypes> {
    onSave?: (value: IModalCallBackTypes[CBT]) => void;
}

interface IModalItem<CBT = keyof IModalCallBackTypes> {
    id?: string;
    modalFor?: IModalFor;
    type: TModalTypes | string;
    customList?: { id: string; path: string };
    options?: IModalOptions;
    callbacks?: CBT extends keyof IModalCallBackTypes
        ? IModalCallbacks<CBT>
        : undefined;
    transactionStatus?: ETransactionStatus;
    hidden?: boolean;
}

export interface IShowModalPayload<CBT = keyof IModalCallBackTypes>
    extends IModalItem<CBT> {
    callerAddress: string;
}

export interface IModalHaveChangesPayload {
    callId: string;
    haveChanges: boolean;
}

export interface ICloseModalPayload {
    callId: string;
    onSave?: boolean;
    extraById?: string[];
    navigateTo?: string;
}

export interface IUpdateModalPayload {
    callId?: string;
    newId: string;
    newType: TModalTypes;
    newOptions?: IModalOptions;
    onSave?: boolean;
}

export interface IOnChangeRecurrencePayload {
    callId: string;
    newId: string;
}

export interface IModalRenderer {
    [key: string]: JSX.Element;
}

export interface IModalListItem<CBT = keyof IModalCallBackTypes>
    extends IModalItem<CBT> {
    callId: string;
}

export interface IIdModalListItems {
    [key: string]: IModalListItem | undefined;
}

export interface IIdModalHaveChanges {
    [key: string]: boolean;
}

export interface IActiveTransactionsPayload {
    callId?: string;
    transactionStatus: ETransactionStatus;
    error?: IActiveTransactionsErrorPayload;
}

export interface IActiveTransactionsErrorPayload {
    modalTitle?: string;
    message?: string[];
}

export interface IActiveTransactionItem {
    status: ETransactionStatus;
    errors?: string[];
}
