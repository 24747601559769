import React from "react";

import Dropdown, { IDropdownProps } from "./Dropdown";
import { IInputProps } from "../IInputProps";
import { ESharedOptionsType, useSharedOptions } from "./api/hooks";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";

type Props = IDropdownProps & IInputProps<string>;

const CompetencyDropdown = (props: Props) => {
    const { data: sharedOptions, refetch } = useSharedOptions(
        false,
        ESharedOptionsType.competency,
        props.selectedIds,
    );

    React.useEffect(() => {
        refetch();
    }, []);

    return (
        <Dropdown
            {...props}
            options={props.options ?? sharedOptions}
            icon={<CompetencyIcon />}
        />
    );
};

export default CompetencyDropdown;
