import React, { useMemo } from "react";

import {
    useTableContext,
    TableRowContainerContext,
    ITableRowContext,
} from "./Context";
import TableToolbarComponent from "./TableToolbarComponent";

type TableHeaderType = React.FC<React.PropsWithChildren> & {
    Toolbar: React.FC<React.PropsWithChildren<{ colSpan?: number }>>;
};

const TableHeaderComponent: TableHeaderType = (props) => {
    const context = useTableContext();

    const contextValue = useMemo(() => {
        const result: ITableRowContext = {
            isHeader: true,
            isLoading: context?.isLoading,
            canExpandRow: context?.canExpandRow,
            columnsCount: context?.columnsCount,
            isHeaderVerticalAlign: context?.isHeaderVerticalAlign,
            columnWidths: context?.columnWidths,
            resizingKey: context?.resizingKey,
            startResizing: context?.startResizing,
            stopResizing: context?.stopResizing,
            registerCellRef: context?.registerCellRef,
            registerColumnHeaderName: context?.registerColumnHeaderName,
            columnsHeaders: context?.columnsHeaders,
        };

        return result;
    }, [
        context?.isLoading,
        context?.canExpandRow,
        context?.columnsCount,
        context?.columnWidths,
        context?.resizingKey,
    ]);

    return props.children ? (
        <TableRowContainerContext.Provider value={contextValue}>
            <thead>{props.children}</thead>
        </TableRowContainerContext.Provider>
    ) : null;
};

TableHeaderComponent.Toolbar = TableToolbarComponent;

export default TableHeaderComponent;
