import "./SelectButton.scss";

import ButtonGroup from "../ButtonGroup";
import Button, { IButtonProps } from "../Button";
import React from "react";

export interface ISelectButtonOptions {
    id: string;
    label: React.ReactNode;
    buttonProps?: IButtonProps;
}

interface IProps {
    id?: string;

    buttons?: ISelectButtonOptions[];

    selected?: string[];

    multi?: boolean;

    disabled?: boolean;

    connected?: boolean;

    onSelect: (id: string, multi?: boolean) => void;

    dataTestId?: string;
}

const SelectButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, buttons, selected, multi, disabled, connected, dataTestId } =
        props;

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const newId = e.currentTarget.id;

        if (newId) {
            props.onSelect(newId, multi);
        }
    };
    return (
        <div className={`select-button${connected ? " connected" : ""}`}>
            {buttons?.map((x, index) => {
                const isConnected = connected
                    ? index === 0
                        ? " first-btn"
                        : index === buttons.length - 1
                          ? " last-btn"
                          : " mid-btn"
                    : "";

                return (
                    <Button
                        {...x.buttonProps}
                        key={x.id}
                        id={x.id}
                        transparent={!selected?.includes(x.id)}
                        disabled={disabled}
                        className={x.buttonProps?.className ?? "" + isConnected}
                        onClick={handleOnClick}
                        testId={`${dataTestId}-${id}-btn`}
                    >
                        {x.label}
                    </Button>
                );
            })}
        </div>
    );
};

export default SelectButton;
