import "./RenderFormula.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import {
    SortEnd,
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import {
    EFormulaCategory,
    FormulaTypeLocale,
    IFormulaItem,
} from "../../../utility/FormulaTools";
import {
    FormulaNumber,
    FormulaOperator,
    FormulaVariable,
    FormulaParenthesis,
} from "./RenderFormulaItems";
import AddFormulaElements from "./AddFormulaElements";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { Button } from "common/components/buttons";
import IconButton from "common/components/buttons/icon-button/IconButton";
import ITableSet from "http/ITableSet";
import { RefObject, createRef, useEffect, useMemo, useState } from "react";
import IDictionary from "common/viewModels/IDictionary";
import TooltipWrapper from "common/components/tooltip/TooltipWrapper";
import IValidationMessages from "common/viewModels/IValidationMessages";

interface IDragTitleProps {
    title: string;
}

const DragTitle = SortableHandle<IDragTitleProps>((props: IDragTitleProps) => (
    <div className="render-formula--item--drag-title">{props.title}</div>
));

interface ISortableItemProps {
    item: IFormulaItem;
    isSelected: boolean;
    errorMessages?: string[];
    itemRef: RefObject<HTMLDivElement>;

    onChangeInputValue?: (e: IInputChangeEvent<string>) => void;
    onChangeDropdownValue?: (selected: string) => void;

    onAddDataSource?: () => void;

    onDelete?: () => void;
}

const borderColor = {
    [EFormulaCategory.Unset]: "",
    [EFormulaCategory.Variable]: "render-formula--item--variable-color",
    [EFormulaCategory.StaticNumber]:
        "render-formula--item--static-number-color",
    [EFormulaCategory.Operator]: "render-formula--item--operator-color",
    [EFormulaCategory.Parenthesis]: "render-formula--item--parenthesis-color",
};

export const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        const { item, isSelected, itemRef, errorMessages } = props;

        const { t } = useTranslation();

        const itemTitle = FormulaTypeLocale[item.category];

        const setMinWidth = [
            EFormulaCategory.Variable,
            EFormulaCategory.StaticNumber,
        ].includes(item.category);

        const setMaxWidth = [EFormulaCategory.StaticNumber].includes(
            item.category,
        );

        const isInvalid = errorMessages && errorMessages?.length > 0;

        const className = useMemo(() => {
            const result = ["render-formula--item"];

            if (setMinWidth) {
                result.push("min-width");
            }

            if (setMaxWidth) {
                result.push("max-width");
            }

            if (isSelected) {
                result.push("item-selected");
            }

            if (isInvalid) {
                result.push("item-error");
            }

            result.push(borderColor[item.category]);

            return result.join(" ");
        }, [isSelected, item.id, setMaxWidth, setMinWidth, errorMessages]);

        return (
            <div className={className} ref={itemRef}>
                <TooltipWrapper
                    id={item.id}
                    message={errorMessages?.join("\n")}
                    showTooltip={isInvalid}
                >
                    <div className="render-formula--item--title">
                        <DragTitle title={t(itemTitle)} />
                        <IconButton
                            className="render-formula--item--delete"
                            circle
                            onClick={props.onDelete}
                        >
                            <TrashIcon />
                        </IconButton>
                    </div>
                </TooltipWrapper>
                <div className="render-formula--item--content">
                    {item.category === EFormulaCategory.Variable && (
                        <FormulaVariable
                            item={item}
                            onChangeValue={props.onChangeInputValue}
                            onAddDataSource={props.onAddDataSource}
                        />
                    )}
                    {item.category === EFormulaCategory.Operator && (
                        <FormulaOperator
                            item={item}
                            onSelectOperator={props.onChangeDropdownValue}
                        />
                    )}
                    {item.category === EFormulaCategory.StaticNumber && (
                        <FormulaNumber
                            item={item}
                            onChangeValue={props.onChangeInputValue}
                        />
                    )}
                    {item.category === EFormulaCategory.Parenthesis && (
                        <FormulaParenthesis
                            item={item}
                            onSelectOperator={props.onChangeDropdownValue}
                        />
                    )}
                </div>
            </div>
        );
    },
);

const Container = SortableContainer<{
    children: React.ReactNode;
    className?: string;
}>((props: { children: React.ReactNode; className?: string }) => (
    <div className="render-formula--container">{props.children}</div>
));

interface IProps extends SortableContainerProps {
    items: ITableSet<IFormulaItem>;
    errors: IValidationMessages;
    focusedInput: string;
    onAddElement: (type: EFormulaCategory) => void;
    onRemoveElement: (id: string) => void;

    onFormulaMove: (sortEnd: SortEnd) => void;
    onChangeValue: (id: string, value: string) => void;
    onAddDataSource: (id: string) => void;
}

const RenderFormula: React.FC<IProps> = (props) => {
    const { items, errors, focusedInput } = props;
    const { t } = useTranslation();

    const [itemRefs, setItemRefs] = useState<
        IDictionary<RefObject<HTMLDivElement>>
    >({});

    useEffect(() => {
        const refs = items.ids.reduce<IDictionary<RefObject<HTMLDivElement>>>(
            (acc, id) => {
                if (items.values[id]?.category !== EFormulaCategory.Unset) {
                    acc[id] = createRef<HTMLDivElement>();
                }

                return acc;
            },
            {},
        );

        setItemRefs(refs);
    }, [items.ids]);

    const handleAddFirstElement = () =>
        props.onAddElement(EFormulaCategory.Variable);

    useEffect(() => {
        if (itemRefs[focusedInput]) {
            scrollToComponent(itemRefs[focusedInput]);
        }
    }, [focusedInput, itemRefs]);

    const scrollToComponent = (componentRef: RefObject<HTMLDivElement>) => {
        if (componentRef.current) {
            const element = componentRef.current;

            const scrollOptions: ScrollIntoViewOptions = {
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            };

            element.scrollIntoView(scrollOptions);
        }
    };

    return (
        <div className="render-formula">
            <AddFormulaElements onAddElement={props.onAddElement} />

            {items.ids.length > 1 ? (
                <div
                    className="render-formula--scroll-fix"
                    id="formula-container"
                >
                    <Container
                        onSortEnd={props.onFormulaMove}
                        useDragHandle
                        axis="x"
                        lockAxis="x"
                        helperClass="render-formula--item--helper"
                    >
                        {items.ids.map((id, index) => {
                            const item = items.values[id];

                            const invalidItem = errors[id];

                            if (
                                !item ||
                                item.category === EFormulaCategory.Unset
                            ) {
                                if (invalidItem) {
                                    return (
                                        <TooltipWrapper
                                            id={id}
                                            message={[
                                                ...(invalidItem as string[]),
                                            ]}
                                            showTooltip
                                        >
                                            <div className="unset-item-error" />
                                        </TooltipWrapper>
                                    );
                                } else {
                                    return null;
                                }
                            }

                            const handleOnChangeInputValue = (
                                e: IInputChangeEvent<string>,
                            ) => {
                                props.onChangeValue(id, e.value.toString());
                            };
                            const handleOnChangeDropdownValue = (
                                selected: string,
                            ) => props.onChangeValue(id, selected);

                            const handleRemoveElement = () =>
                                props.onRemoveElement(id);

                            const handleOnAddDataSource = () =>
                                props.onAddDataSource(id);

                            return (
                                <SortableItem
                                    key={index}
                                    errorMessages={
                                        invalidItem
                                            ? [...(invalidItem as string[])]
                                            : []
                                    }
                                    itemRef={itemRefs[id]}
                                    index={index}
                                    item={item}
                                    isSelected={props.focusedInput === item.id}
                                    onChangeInputValue={
                                        handleOnChangeInputValue
                                    }
                                    onChangeDropdownValue={
                                        handleOnChangeDropdownValue
                                    }
                                    onDelete={handleRemoveElement}
                                    onAddDataSource={handleOnAddDataSource}
                                />
                            );
                        })}
                    </Container>
                </div>
            ) : (
                <div className="render-formula--empty">
                    <Button transparent onClick={handleAddFirstElement}>
                        {t(k.ADD_ELEMENTS)}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RenderFormula;
