import "./FilterDropdown.scss";

import React from "react";
import { Dropdown } from "react-overlays";
import { FilterToggle } from "./components/FilterToggle";
import { FilterMenu } from "./components/FilterMenu";

interface IProps {
    isActive: boolean;
    isEdited: boolean;
    testId?: string;

    commandClassName?: string;
    commandDescription?: React.ReactNode;

    onFilter: (isReset?: boolean) => void;
    onToggle: (open: boolean) => void;
}

export const FilterDropdown = (props: React.PropsWithChildren<IProps>) => {
    const { isActive, isEdited, testId, commandClassName, commandDescription } =
        props;

    const [open, setOpen] = React.useState(false);

    const handleToggle = (
        nextShow: boolean,
        e?: Event | React.SyntheticEvent<Element, Event>,
    ) => {
        e?.stopPropagation();

        setOpen(nextShow);

        props.onToggle(nextShow);
    };

    return (
        <Dropdown show={open} onToggle={handleToggle} alignEnd={true}>
            <div className="app-table--header-filter">
                <FilterToggle isActive={isActive} testId={testId} />
                <FilterMenu
                    testId={testId}
                    isActive={isActive}
                    isEdited={isEdited}
                    commandClassName={commandClassName}
                    commandDescription={commandDescription}
                    onFilter={props.onFilter}
                >
                    {props.children}
                </FilterMenu>
            </div>
        </Dropdown>
    );
};
