import "./ApiKey.scss";

import React, { useMemo } from "react";
import { GiUsbKey } from "react-icons/gi";

interface IProps {
    isActive?: boolean;
    className?: string;
    title?: string;
}

const ApiKeyIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const className = useMemo(() => {
        const result: string[] = ["icon-apikey"];

        if (props.isActive) {
            result.push("icon-apikey--active");
        }

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [props.className, props.isActive]);

    return <GiUsbKey title={props.title} className={className} />;
};

export default ApiKeyIcon;
