import { ActivityType } from "models/enums/ActivityType";
import { IListData, IListItem } from "./AddPropertyButton";

import {
    BarcodeIcon,
    CalendarIcon,
    CameraIcon,
    CustomerIcon,
    DropdownIcon,
    ItemIcon,
    LinkIcon,
    SupplierIcon,
    TaskCheckboxIcon,
    TextfieldIcon,
} from "common/components/icons";

import k from "i18n/keys";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";

const BasicActivityList: IListItem<ActivityType>[] = [
    {
        type: ActivityType.Value,
        icon: <TextfieldIcon />,
        title: k.ADD_TEXTFIELD,
        subTitle: "Single line of text",
    },
    {
        type: ActivityType.Text,
        icon: <TextfieldIcon isMultiline />,
        title: k.ADD_MULTI_LINE_TEXTFIELD,
        subTitle: "Multi line of text",
    },
    {
        type: ActivityType.Tasklist,
        icon: <TaskCheckboxIcon />,
        title: k.ADD_CHECKBOX,
        subTitle: "",
    },
    {
        type: ActivityType.DateTime,
        icon: <CalendarIcon />,
        title: k.ADD_DATE_TIME,
        subTitle: "",
    },
    {
        type: ActivityType.Photo,
        icon: <CameraIcon />,
        title: k.PHOTO,
        subTitle: "",
    },
    {
        type: ActivityType.Selection,
        icon: <DropdownIcon />,
        title: k.ADD_DROPDOWN,
        subTitle: "",
    },
    {
        type: ActivityType.Customers,
        icon: <CustomerIcon />,
        title: k.ADD_CUSTOMER_DROPDOWN,
        subTitle: "",
        appFeature: "customers",
    },
    {
        type: ActivityType.Suppliers,
        icon: <SupplierIcon />,
        title: k.ADD_SUPPLIER_DROPDOWN,
        subTitle: "",
        appFeature: "suppliers",
    },
    {
        type: ActivityType.Items,
        icon: <ItemIcon />,
        title: k.ADD_ITEM_DROPDOWN,
        subTitle: "",
        appFeature: "itemCatalog",
    },
    {
        type: ActivityType.Barcodes,
        icon: <BarcodeIcon />,
        title: k.BARCODES,
        subTitle: "",
        appFeature: "barcodeActivity",
    },
    {
        type: ActivityType.GenerateBarcode,
        icon: <BarcodeIcon />,
        title: k.GENERATE_BARCODE,
        subTitle: "",
        appFeature: "generateBarcodeActivity",
    },
    {
        type: ActivityType.Hyperlink,
        icon: <LinkIcon />,
        title: k.HYPERLINK,
        subTitle: "",
    },
    {
        type: ActivityType.Users,
        icon: <UserIcon />,
        title: k.USERS,
        subTitle: "",
    },
    {
        type: ActivityType.Positions,
        icon: <PositionIcon />,
        title: k.POSITIONS,
        subTitle: "",
    },
    {
        type: ActivityType.Competencies,
        icon: <CompetencyIcon />,
        title: k.COMPETENCIES,
        subTitle: "",
    },
];

const ActivityListData: IListData<ActivityType> = {
    items: BasicActivityList,
    sectionTitle: k.ACTIVITIES,
};

export default ActivityListData;
