import { useTranslation } from "react-i18next";

import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import ListedContent from "common/components/listed-content/ListedContent";
import { useListedContentData } from "common/components/listed-content/hooks";
import ModalContent from "common/components/modal-content/ModalContent";
import {
    IShowModalPayload,
    IUpdateModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import k from "i18n/keys";

import Content from "./components/Content/Content";
import { SidePanelDetails, SidePanelList } from "./components/SidePanel";
import SidePanelHeader from "./components/SidePanel/SidePanelHeader/SidePanelHeader";
import TemplateFormContext, {
    TemplateFormContextType,
} from "./context/TemplateFormContext";
import { useTemplateFormData } from "./context/TemplateFormData";

import "./TemplateForm.scss";

export interface ITemplateFormProps {
    id?: string;
    createNew?: boolean;

    haveChanges?: boolean;

    syncData: boolean;

    onHaveChanges: (haveChanges: boolean) => void;
    onShowModal: (payload: IShowModalPayload) => void;
    onUpdateModal: (payload: IUpdateModalPayload) => void;
    onClose: (onSave?: boolean, closePreview?: boolean) => void;
}

const TemplateForm: React.FC<ITemplateFormProps> = (props) => {
    const templateData: TemplateFormContextType = {
        ...useListedContentData(),
        ...useTemplateFormData(props),
    };

    const { t } = useTranslation();

    const {
        listedContentRef,
        contentRef,
        sidePanelRef,
        isLoading,
        isLoadingTemplate,
        isErrorTemplate,
        isLoadingSteps,

        initialSection,

        mobileView,

        handleOnClose,

        canEditTemplate,
        canEditTemplateLoading,
    } = templateData;

    if (canEditTemplateLoading) {
        return <AnimatedSpinner isVisible position="center" aligned="center" />;
    }
    if (
        props.id &&
        ((!isLoadingTemplate && isErrorTemplate) || !canEditTemplate)
    ) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(k.NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    return (
        <ModalContent>
            <CurrentScrolledSection
                contentRef={contentRef}
                wrapperRef={listedContentRef}
                mobileView={mobileView}
                initialSection={initialSection}
                children={(scrollToTarget, currentSection) => (
                    <ListedContent
                        testId="template-form"
                        className="template-form template-form__listed-content"
                        listedContentRef={listedContentRef}
                    >
                        {isLoading ? (
                            <AnimatedSpinner
                                isVisible={isLoading}
                                position="center"
                                aligned="center"
                                testId="template-form-content--loading"
                            />
                        ) : (
                            <TemplateFormContext.Provider
                                value={{
                                    ...templateData,
                                    currentSection,
                                    scrollToTarget,
                                }}
                            >
                                <ListedContent.SidePanel
                                    sidePanelRef={sidePanelRef}
                                    context={TemplateFormContext}
                                    testId="template-sidepanel"
                                >
                                    <SidePanelHeader />

                                    <SidePanelDetails />

                                    <SidePanelList />
                                </ListedContent.SidePanel>
                                <ListedContent.Content contentRef={contentRef}>
                                    {isLoadingSteps ? (
                                        <AnimatedSpinner
                                            isVisible={isLoadingSteps}
                                            position="center"
                                            aligned="center"
                                            testId="template-form-content--loading"
                                        />
                                    ) : (
                                        <Content />
                                    )}
                                </ListedContent.Content>
                            </TemplateFormContext.Provider>
                        )}
                    </ListedContent>
                )}
            />
        </ModalContent>
    );
};

export default TemplateForm;
