import { Action } from "redux";

import { IProfileDTO } from "../../models/dto/IProfileDTO";

export const CHECK_LOCAL_TOKEN = "CHECK_LOCAL_TOKEN";

export const RECEIVE_LOGIN = "RECEIVE_LOGIN";

export const REQUEST_LOGOUT = "REQUEST_LOGOUT";

export interface IAuthCheckLocalToken extends Action<typeof CHECK_LOCAL_TOKEN> {
    payload?: IProfileDTO;
}

export interface IAuthReceiveLoginAction extends Action<typeof RECEIVE_LOGIN> {
    payload: IProfileDTO;
}

export interface IAuthRequestLogoutAction
    extends Action<typeof REQUEST_LOGOUT> {
    payload?: { force?: boolean; serverAuthFailed?: boolean };
}

export type AuthActionTypes =
    | IAuthCheckLocalToken
    | IAuthReceiveLoginAction
    | IAuthRequestLogoutAction;
