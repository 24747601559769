import { Action } from "redux";

import IAttachmentFormData from "../../common/viewModels/IAttachmentFormData";
import { AttachmentType } from "../../models/enums/AttachmentType";

export const SET_ATTACHMENT_IS_LOADING = "SET_ATTACHMENT_IS_LOADING";

export const SHOW_ATTACHMENT_FORM = "SHOW_ATTACHMENT_FORM";

export const HIDE_ATTACHMENT_FORM = "HIDE_ATTACHMENT_FORM";

export const RECEIVE_CHECKLIST_ACTIVITIES_ATTACHMENTS =
    "RECEIVE_CHECKLIST_ACTIVITIES_ATTACHMENTS";

interface IAttachmentSetIsLoadingAction
    extends Action<typeof SET_ATTACHMENT_IS_LOADING> {
    payload: boolean;
}

interface IAttachmentShowFormAction
    extends Action<typeof SHOW_ATTACHMENT_FORM> {
    payload: AttachmentType;
}

interface IAttachmentHideFormAction
    extends Action<typeof HIDE_ATTACHMENT_FORM> {
    payload?: IAttachmentFormData;
}

export type AttachmentActionTypes =
    | IAttachmentSetIsLoadingAction
    | IAttachmentShowFormAction
    | IAttachmentHideFormAction;
