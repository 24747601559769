import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import React from "react";

interface IProps {
    value: IValueLabelItemWithState;

    ids: string[];
}

const RenderIfNotArchived: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { value, ids } = props;

    const isArchivedOrDeleted = value.isArchived || value.isDeleted;

    if (
        isArchivedOrDeleted === false ||
        (isArchivedOrDeleted && ids.includes(value.value))
    ) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
};

export default RenderIfNotArchived;
