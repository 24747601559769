import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { useCustomListBasicListOptions } from "components/custom-list-item-page/api/hooks";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";

const CustomListItemDropdownSelector = (
    props: ISharedPropertyInputProps<string>,
) => {
    const { t } = useTranslation();

    const {
        customListItemId,
        values,
        errors,
        disabled,
        showArchivedOrDeleted,
        cannotEditAccessLimited,
        userId,
    } = props;

    const {
        parentId,
        propertyCustomListId,
        isRequired,
        label,
        allowMultiple,
        type,
        userAccessLimited,
    } = props.property;

    const { data: options } = useCustomListBasicListOptions(
        propertyCustomListId,
        customListItemId ?? undefined,
        showArchivedOrDeleted ?? undefined,
    );

    const handleSelectMany = (e: IInputChangeEvent<string>) => {
        if (allowMultiple) {
            const nextValues = e.selectedIds;

            props.onChange(parentId, nextValues ?? []);
        } else {
            const nextValue = e.value;

            props.onChange(parentId, nextValue ? [nextValue] : []);
        }
    };

    const inputType: EActivityType =
        type === CustomListPropertyEnum.Customers
            ? EActivityType.CustomerDropdown
            : type === CustomListPropertyEnum.Suppliers
              ? EActivityType.SupplierDropdown
              : type == CustomListPropertyEnum.Items
                ? EActivityType.ItemDropdown
                : type === CustomListPropertyEnum.Users
                  ? EActivityType.UserDropdown
                  : type === CustomListPropertyEnum.Positions
                    ? EActivityType.PositionDropdown
                    : type === CustomListPropertyEnum.Competencies
                      ? EActivityType.CompetencyDropdown
                      : EActivityType.Dropdown;

    const userCannotEditAccessLimitedProp =
        userAccessLimited && cannotEditAccessLimited;

    return (
        <InputComponent
            inputType={inputType}
            id={`form.${parentId}`}
            value={(values && values[0]) ?? ""}
            selectedIds={values ?? undefined}
            isClearable
            isSearchable
            bold
            placeholder={t(k.SELECT)}
            options={options}
            isMulti={allowMultiple}
            invalid={Boolean(errors[parentId])}
            onChange={handleSelectMany}
            disabled={disabled || userCannotEditAccessLimitedProp}
            helperText={<ValidationLabel errors={errors[parentId]} />}
            hideIcon
            wrapperLabel={label}
            required={isRequired}
        />
    );
};

export default CustomListItemDropdownSelector;
