import "./DatePicker.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ValidationLabel from "components/common/validation/ValidationLabel";
import { default as DatePickerComponent } from "react-datepicker";

import * as Popper from "@popperjs/core";

export interface IDatePickerProps {
    id?: string;
    name?: string;
    testId?: string;

    value?: Date | null;

    showTimeSelect?: boolean;

    timeIntervals?: number;

    disabled?: boolean;
    inline?: boolean;

    placeholder?: string;
    shouldCloseOnSelect?: boolean;

    dateFormat?: "P" | "Pp" | string;

    labelContent?: React.ReactNode;

    icon?: React.ReactNode;

    bold?: boolean;
    size?: "large";

    background?: "dark";

    minDate?: Date | null;

    invalid?: boolean;
    error?: string | string[] | null;

    highlightDates?: Array<HighlightDates | Date>;

    calendarClassName?: string;

    placement?: Popper.Placement;

    onChange: (date: Date, name?: string) => void;
}

export interface HighlightDates {
    [className: string]: Date[];
}

const DatePicker: React.FC<React.PropsWithChildren<IDatePickerProps>> = (
    props,
) => {
    const {
        id,
        name,
        testId,
        value,
        showTimeSelect,
        timeIntervals,
        disabled,
        inline,
        placeholder,
        dateFormat,
        labelContent,
        icon,
        bold,
        background,
        minDate,
        invalid,
        error,
        shouldCloseOnSelect,
        highlightDates,
        calendarClassName,
        placement,
    } = props;

    const { t } = useTranslation();

    const wrapperClass = useMemo(() => {
        const result = ["text-field date-picker"];

        if (bold) {
            result.push("text-field--bold");
        }

        return result.join(" ");
    }, [bold]);

    const boxClass = useMemo(() => {
        const result = ["text-field--box"];

        if (background) {
            result.push(`background-${background}`);
        }

        if (disabled) {
            result.push("disabled");
        }

        if (error || invalid) {
            result.push("invalid");
        }

        return result.join(" ");
    }, [background, disabled, error, invalid]);

    const handleChange = (date: Date) => {
        props.onChange(date, name);
    };

    const isLarge = props.size === "large";

    let newDateFormat = dateFormat;
    if (newDateFormat === undefined) {
        newDateFormat = showTimeSelect ? "Pp" : "P";
    }

    return (
        <label className={wrapperClass}>
            {labelContent && (
                <div className="text-field--label">{labelContent}</div>
            )}

            <div className="input">
                {icon && <div className="icon-field">{icon}</div>}

                <div
                    className={boxClass}
                    data-testid={testId}
                    title={value ? undefined : placeholder}
                >
                    <DatePickerComponent
                        id={id}
                        selected={value}
                        minDate={minDate}
                        showTimeSelect={showTimeSelect}
                        timeIntervals={timeIntervals}
                        dateFormat={newDateFormat}
                        shouldCloseOnSelect={shouldCloseOnSelect}
                        autoComplete="off"
                        inline={inline}
                        disabled={disabled}
                        onChange={handleChange}
                        placeholderText={placeholder}
                        highlightDates={highlightDates}
                        calendarClassName={calendarClassName}
                        className={
                            isLarge
                                ? `input-field input-field--large`
                                : "input-field"
                        }
                        popperPlacement={placement}
                    />
                </div>
            </div>

            {error && (
                <div className="text-field--helper-text">
                    <ValidationLabel
                        errors={t(k[error as keyof typeof k]) ?? error}
                    />
                </div>
            )}
        </label>
    );
};

export default DatePicker;
