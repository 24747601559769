import { IValueLabelItem } from "common/IValueLabelItem";
import i18n from "i18next";
import k from "i18n/keys";

export enum ClosureReasonEnum {
    None,
    ResolvedFully,
    ResolvedPartially,
    NoActionsTaken,
    NotActionable,
    Postponed,
}

export const closureReasonItems: Record<
    ClosureReasonEnum,
    IValueLabelItem<ClosureReasonEnum, string, string>
> = {
    [ClosureReasonEnum.None]: {
        value: ClosureReasonEnum.None,
        label: "None",
        param: "none",
    },
    [ClosureReasonEnum.ResolvedFully]: {
        value: ClosureReasonEnum.ResolvedFully,
        label: k.RESOLVED_FULLY,
        param: "resolvedFully",
    },
    [ClosureReasonEnum.ResolvedPartially]: {
        value: ClosureReasonEnum.ResolvedPartially,
        label: k.RESOLVED_PARTIALLY,
        param: "resolvedPartially",
    },
    [ClosureReasonEnum.NoActionsTaken]: {
        value: ClosureReasonEnum.NoActionsTaken,
        label: k.NO_ACTIONS_TAKEN,
        param: "noActionsTaken",
    },
    [ClosureReasonEnum.NotActionable]: {
        value: ClosureReasonEnum.NotActionable,
        label: k.NOT_ACTIONABLE,
        param: "notActionable",
    },
    [ClosureReasonEnum.Postponed]: {
        value: ClosureReasonEnum.Postponed,
        label: k.POSTPONED,
        param: "postponed",
    },
};

export const closureReasonOptions: Array<
    IValueLabelItem<ClosureReasonEnum, string, string>
> = [
    closureReasonItems[ClosureReasonEnum.None],
    closureReasonItems[ClosureReasonEnum.ResolvedFully],
    closureReasonItems[ClosureReasonEnum.ResolvedPartially],
    closureReasonItems[ClosureReasonEnum.NoActionsTaken],
    closureReasonItems[ClosureReasonEnum.NotActionable],
    closureReasonItems[ClosureReasonEnum.Postponed],
];

export const getClosureReasonOptions = (
    i18next: typeof i18n,
): IValueLabelItem<string, string, ClosureReasonEnum>[] => [
    {
        value: String(ClosureReasonEnum.ResolvedFully),
        label: i18next.t(k.RESOLVED_FULLY),
        param: ClosureReasonEnum.ResolvedFully,
    },
    {
        value: String(ClosureReasonEnum.ResolvedPartially),
        label: i18next.t(k.RESOLVED_PARTIALLY),
        param: ClosureReasonEnum.ResolvedPartially,
    },
    {
        value: String(ClosureReasonEnum.NoActionsTaken),
        label: i18next.t(k.NO_ACTIONS_TAKEN),
        param: ClosureReasonEnum.NoActionsTaken,
    },
    {
        value: String(ClosureReasonEnum.NotActionable),
        label: i18next.t(k.NOT_ACTIONABLE),
        param: ClosureReasonEnum.NotActionable,
    },
    {
        value: String(ClosureReasonEnum.Postponed),
        label: i18next.t(k.POSTPONED),
        param: ClosureReasonEnum.Postponed,
    },
];

export const closureReasonsStatusStrings: Set<string> = new Set(
    Object.keys(ClosureReasonEnum).filter((key) => isNaN(Number(key))),
);

export const closureReasonsStatusStringsWithoutNone: Set<string> = new Set(
    Array.from(closureReasonsStatusStrings).filter(
        (status) => status !== "None",
    ),
);

export const closureReasonsStatusWithoutNone = Object.keys(ClosureReasonEnum)
    .filter((key) => isNaN(Number(key)))
    .filter(
        (key) =>
            ClosureReasonEnum[key as keyof typeof ClosureReasonEnum] !==
            ClosureReasonEnum.None,
    )
    .map((key) => ClosureReasonEnum[key as keyof typeof ClosureReasonEnum]);
