import "./SidePanelDetails.scss";

import { useContext } from "react";

import TemplatePreviewContext from "components/templates/components/TemplateListedContent/Preview/context/TemplatePreviewContext";
import ListedContent from "common/components/listed-content/ListedContent";

const SidePanelDetails: React.FC = (props) => {
    const { template: selectedTemplate } = useContext(TemplatePreviewContext);
    return (
        <ListedContent.SidePanel.Details
            context={TemplatePreviewContext}
            description={selectedTemplate?.description}
        >
            <div className="side-panel__details__title">
                {selectedTemplate?.name}
            </div>
        </ListedContent.SidePanel.Details>
    );
};

export default SidePanelDetails;
