import React from "react";
import { FaPaperclip, FaRegFileAlt, FaUndo } from "react-icons/fa";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import IAttachmentFormData from "../../../common/viewModels/IAttachmentFormData";
import { AttachmentType } from "../../../models/enums/AttachmentType";
import IAttachment from "../../../models/IAttachment";
import { showAttachmentForm } from "../../../store/attachments/actions";
import SortableListWithHandle from "../sortableLists/SortableListWithHandle";
import Button from "common/components/buttons/Button";
import i18n from "i18next";
import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { DownloadFileLink } from "../download-file-link/DownloadFileLink";

interface IProps {
    attachments: IAttachment[];

    itemClassName?: string;

    showAttachmentForm: (
        type: AttachmentType,
    ) => Promise<IAttachmentFormData | undefined>;

    onSave?: (attachments: IAttachment[]) => void;
}

interface IState {
    selectedTypeId: AttachmentType;
}

const AttachmentName = ({ attachment }: { attachment: IAttachment }) => {
    return attachment.isDeleted ? (
        <del>{attachment.name}</del>
    ) : (
        <React.Fragment>{attachment.name}</React.Fragment>
    );
};

class SortableAttachmentList extends React.Component<IProps, IState> {
    public readonly state: IState = {
        selectedTypeId: AttachmentType.None,
    };

    private options: IValueLabelItem<string, string>[] = [
        { value: String(AttachmentType.None), label: i18n.t(k.ADD_ATTACHMENT) },
        { value: String(AttachmentType.Link), label: i18n.t(k.LINK) },
        { value: String(AttachmentType.File), label: i18n.t(k.FILE) },
    ];

    public onSelectForm = async (e: IInputChangeEvent<string>) => {
        const selectedType = e.value;

        const type = Number(selectedType);

        this.setState({
            selectedTypeId: type,
        });

        const result = await this.props.showAttachmentForm(type);

        this.setState({
            selectedTypeId: AttachmentType.None,
        });

        if (result && this.props.onSave) {
            const attachmentName = result.extension
                ? `${result.name}.${result.extension}`
                : result.name;

            const attachment: IAttachment = {
                id: uuidv4(),
                isNew: true,
                attachmentType: type,
                name: attachmentName,
                content: result.content,
                index: this.props.attachments.length + 1,
            };

            let attachments: IAttachment[];

            if (this.props.attachments.find((x) => x.id === attachment.id)) {
                attachments = this.props.attachments.map((x) => {
                    if (x.id === attachment.id) {
                        return attachment;
                    }

                    return x;
                });
            } else {
                attachments = this.props.attachments.concat(attachment);
            }

            this.props.onSave(attachments);
        }
    };

    public handleChangeDelete = (id: string, isDeleted?: boolean) => () => {
        if (this.props.onSave) {
            this.props.onSave(
                this.props.attachments.map((x) => {
                    if (x.id !== id) {
                        return x;
                    }

                    return {
                        ...x,
                        isDeleted,
                    };
                }),
            );
        }
    };

    public render() {
        const { attachments, itemClassName } = this.props;

        const { selectedTypeId } = this.state;

        const hasAttachments = attachments.length > 0;

        const DeleteButton = ({ attachment }: { attachment: IAttachment }) =>
            attachment.isDeleted ? (
                <Button
                    size="small"
                    variant="gray"
                    onClick={this.handleChangeDelete(attachment.id)}
                    className="ml-auto"
                >
                    <FaUndo />
                    {i18n.t(k.UNDO)}
                </Button>
            ) : (
                <Button
                    size="small"
                    variant="danger"
                    onClick={this.handleChangeDelete(attachment.id, true)}
                    className="ml-auto"
                >
                    {i18n.t(k.DELETE)}
                </Button>
            );

        return (
            <div className="attachment-list" data-testid="attachment-list">
                <InputComponent
                    inputType={EActivityType.Dropdown}
                    wrapperLabel={i18n.t(k.ATTACHMENTS)}
                    value={String(selectedTypeId)}
                    options={this.options}
                    onChange={this.onSelectForm}
                />

                {hasAttachments && (
                    <div className="mt-4">
                        <SortableListWithHandle
                            className={`align-items-center sortable-attachment-item${
                                itemClassName ? ` ${itemClassName}` : ""
                            }`}
                            items={attachments}
                            onSorted={this.props.onSave}
                            render={(x) => {
                                if (x.attachmentType === AttachmentType.File) {
                                    if (x.isNew || x.sourceId) {
                                        return (
                                            <React.Fragment>
                                                <FaRegFileAlt />

                                                <AttachmentName
                                                    attachment={x}
                                                />

                                                <DeleteButton attachment={x} />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <React.Fragment>
                                            <FaRegFileAlt />
                                            <DownloadFileLink
                                                id={x.id}
                                                name={x.name}
                                            >
                                                <AttachmentName
                                                    attachment={x}
                                                />
                                            </DownloadFileLink>

                                            <DeleteButton attachment={x} />
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <React.Fragment>
                                        <FaPaperclip />
                                        <a
                                            href={`${x.content}`}
                                            target="_blank"
                                        >
                                            <AttachmentName attachment={x} />
                                        </a>

                                        <DeleteButton attachment={x} />
                                    </React.Fragment>
                                );
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const actions = {
    showAttachmentForm,
};

export default connect(null, actions)(SortableAttachmentList);
