import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import { createContext } from "react";
import { IChecklistData } from "./models/IChecklistData";

export type ChecklistContextType = IChecklistData & ListedContentContextType;

const initialContextValue: ChecklistContextType = {
    editMode: false,
    setEditMode: () => null,

    setActivityInstances: () => null,
    setActivityInstanceIdsBySet: () => null,
    editedChecklistValues: {},
    setEditedChecklistValues: () => null,
    setNumberOfRemovedSteps: () => null,
    onClose: () => null,
    onHaveChanges: () => null,
    setErrors: () => null,
    onShowModal: () => null,
    setUsersAffected: () => null,
    setEditedSteps: () => null,
    lastFinalizedStepInstanceId: "",
    isChecklistInvisible: false,
    setIsChecklistInvisible: () => null,

    mobileView: false,

    checkCanSeeAll: false,
};

const ChecklistDetailsContext =
    createContext<ChecklistContextType>(initialContextValue);

export default ChecklistDetailsContext;
