export class UiSettingService {
    public getMenuSettings() {
        const valueAsString = localStorage.getItem("ui-settings");

        if (valueAsString) {
            const value = JSON.parse(valueAsString);

            if (value) {
                return value.isExpanded as boolean;
            }
        }

        return true;
    }

    public updateMenuSettings(isExpanded: boolean) {
        const valueAsString = JSON.stringify({
            isExpanded,
        });

        localStorage.setItem("ui-settings", valueAsString);
    }
}
