import "./UnfinalizeConfirmationModal.scss";

import k from "i18n/keys";

import { useTranslation } from "react-i18next";
import { EActivityType } from "../input-components/EActivityType";
import InputComponent from "../input-components/InputComponent";
import { IInputChangeEvent } from "../input-components/IInputProps";
import Modal from "../modals/Modal";
import { Button, ButtonGroup } from "../buttons";
import React from "react";

interface IProps {
    message?: string;
    showModal: boolean;
    stepName?: string;

    onHide: () => void;
    onConfirm: () => void;
    onChangeMessage?: (message: string) => void;
}

const prewrittenReasons = [
    k.FINALIZED_BY_MISTAKE,
    k.ACTIVITIES_WERENT_COMPLETED,
    k.NEED_REWORK,
];

const UnfinalizeConfirmationModal = (props: IProps) => {
    const { message, showModal, stepName } = props;
    const { t } = useTranslation();

    const handleOnChangeMessage = (e: IInputChangeEvent<string>) => {
        props.onChangeMessage?.(e.value);
    };

    return (
        <Modal
            show={showModal}
            onHide={props.onHide}
            mode="above-all"
            className="unfinalize-confirmation-modal"
        >
            <Modal.Title>
                {t(k.UNFINALIZE)} {stepName ?? t(k.STEP)}
            </Modal.Title>
            <Modal.Body className="modal-content--body">
                <div>{t(k.UNFINALIZE_STEP_CONFIRMATION_MESSAGE)}</div>
                <InputComponent
                    inputType={EActivityType.Textfield}
                    boldLabel
                    hideIcon
                    value={message ?? ""}
                    onChange={handleOnChangeMessage}
                    multiline
                    minRows={5}
                    testId="goal-form--description"
                    placeholder={t(k.ENTER_HERE)}
                />
                <div className="prewritten-reasons">
                    <span>{t(k.PREWRITTEN_REASONS)}:</span>&nbsp;
                    {prewrittenReasons.map((reason, index) => {
                        const isLast = index === prewrittenReasons.length - 1;
                        return (
                            <span key={index}>
                                <span
                                    className="preview-link"
                                    onClick={() =>
                                        props.onChangeMessage?.(t(reason))
                                    }
                                >
                                    {t(reason)}
                                </span>
                                {!isLast && ", "}
                            </span>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-content--footer">
                <Button variant="danger" transparent onClick={props.onHide}>
                    {t(k.CANCEL)}
                </Button>

                <Button variant="success" onClick={props.onConfirm}>
                    {t(k.UNFINALIZE)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnfinalizeConfirmationModal;
