import { IWithDebounce, IInputProps } from "../IInputProps";
import BarcodeField from "./BarcodeField";

type Props<V> = IInputProps<string> & IWithDebounce;

const GenerateBarcodeField = <V,>(props: Props<V>) => {
    return <BarcodeField {...props} isGenerate />;
};

export default GenerateBarcodeField;
