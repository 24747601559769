import "./ChecklistReportWrapper.scss";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ChecklistsTable from "components/template-checklist-report/components/report-table/ChecklistsTable";
import { ITemplateChecklistReportFilterState } from "components/template-checklist-report/api/ITemplateChecklistReportFilterState";
import ModalContent from "common/components/modal-content/ModalContent";
import { EChecklistReportState } from "common/components/modal-manager/api/IModalManager";

interface IProps {
    checklistReportState?: EChecklistReportState;
    customListItemIds?: string[];

    enableSync?: boolean;

    onClose: () => void;
}

const finalizedState: ITemplateChecklistReportFilterState = {
    done: true,
};

const activeState: ITemplateChecklistReportFilterState = {
    active: true,
};

export const ChecklistReportWrapper = (props: IProps) => {
    const { checklistReportState, enableSync } = props;
    const { t } = useTranslation();

    let filterState: ITemplateChecklistReportFilterState | undefined;

    if (checklistReportState !== undefined) {
        if (checklistReportState === EChecklistReportState.finalized) {
            filterState = finalizedState;
        } else if (checklistReportState === EChecklistReportState.active) {
            filterState = activeState;
        }
    }

    return (
        <ModalContent className="custom-lists-by-custom-list-item__modal">
            <ModalContent.Header
                onClose={props.onClose}
                title={t(k.CHECKLISTS)}
            />
            <ModalContent.Body>
                <ChecklistsTable
                    filterState={filterState}
                    customListItemIds={props.customListItemIds}
                    noRedirectOnTemplateChange
                    enableSync={enableSync}
                />
            </ModalContent.Body>
        </ModalContent>
    );
};
