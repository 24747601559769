import { FC, useContext, useEffect } from "react";
import ChecklistDetailsContext from "../../../../../checklistContext";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import DatePicker from "common/components/date-picker/DatePicker";
import "./DateRange.scss";
import IEditValues from "common/components/listed-content/models/IEditValues";
interface Props {}

type DateFieldType = {
    name: string;
    value?: Date;
};

const DateRange: FC<Props> = () => {
    const {
        editMode,
        editedChecklistValues,
        setEditedChecklistValues,
        errors,
        setErrors,
        checklist,
    } = useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const startDate = editedChecklistValues?.startDate ?? checklist?.startDate;
    const endDate = editedChecklistValues?.endDate ?? checklist?.endDate;

    const validate = ({ startDate, endDate }: IEditValues) =>
        (startDate?.getTime() ?? 0) < (endDate?.getTime() ?? 0);

    const onChange = (name: string) => (value: Date) => {
        setEditedChecklistValues((state) => ({ ...state, [name]: value }));
        if (
            !validate({ ...checklist, ...editedChecklistValues, [name]: value })
        ) {
            setErrors((state) => ({
                ...state,
                [name]:
                    name === "endDate"
                        ? t(k.DATE_END_AFTER_START)
                        : t(k.DATE_START_BEFORE_END),
            }));
        } else if (errors?.startDate || errors?.endDate) {
            setErrors((state) => {
                const newState = undefined;
                return newState;
            });
        }
    };

    return (
        <div className="date-range">
            {editMode ? (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={onChange("startDate")}
                        labelContent={t(k.START_DATE_TIME)}
                        size="large"
                        showTimeSelect
                        background="dark"
                        error={errors?.["startDate"]}
                        placement="bottom-start"
                    />
                    <DatePicker
                        value={endDate}
                        onChange={onChange("endDate")}
                        labelContent={t(k.END_DATE_TIME)}
                        size="large"
                        showTimeSelect
                        background="dark"
                        error={errors?.["endDate"]}
                        placement={"bottom-end"}
                    />
                </>
            ) : (
                <>
                    <div className="date-range__input">
                        {t(k.START)}{" "}
                        <span>
                            <FormattedDate date={startDate} />
                        </span>
                    </div>
                    <div className="date-range__input">
                        {t(k.DUE)}{" "}
                        <span>
                            <FormattedDate date={endDate} />
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default DateRange;
