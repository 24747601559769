import "./ProcessChartIcon.scss";

import React, { useMemo } from "react";
import Icon from "./assets/process-chart-icon.svg";

interface IProps {
    variant?: "white";
}

const baseClassName = "process-chart-icon";

const ProcessChartIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { variant } = props;
    const className = useMemo(() => {
        const result = [baseClassName];
        if (variant) {
            result.push(`${baseClassName}--${variant}`);
        }
        return result.join(" ");
    }, [variant]);
    return <Icon className={className} />;
};

export default ProcessChartIcon;
