import "./UndoIcon.scss";

import React from "react";

import Icon from "./assets/undo-icon.svg";

const UndoIcon: React.FC = (props) => {
    return <Icon className="undo-icon" />;
};

export default UndoIcon;
