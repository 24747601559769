import React from "react";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";
import { IActivityProps } from "./IActivityProps";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityUserDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<UserIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.USERS)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityUserDropdown;
