import "./SidebarButton.scss";

import React from "react";
import { FaBars } from "react-icons/fa";

interface IProps {
    onClick: () => void;
}

const SidebarButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className="sidebar-button__wrapper" onClick={props.onClick}>
            <FaBars />
        </div>
    );
};

export default SidebarButton;
