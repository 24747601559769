import AccordionButton from "common/components/buttons/accordion-button/AccordionButton";
import {
    components,
    GroupBase,
    GroupHeadingProps,
    GroupProps,
} from "react-select";
import { OptionType } from "../SelectDropdownTypes";

const CollapsibleGroup = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>({
    children,
    ...props
}: GroupProps<Option, IsMulti, Group>) => {
    const id = props.data.value;

    const state = props.selectProps.groupState?.[id];
    const isOpen = state?.userOpened || state?.searchOpened;

    return <components.Group {...props}>{isOpen && children}</components.Group>;
};

export default CollapsibleGroup;

export const CollapsibleGroupHeading = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>({
    children,
    ...props
}: GroupHeadingProps<Option, IsMulti, Group>) => {
    const id = props.data.value;

    const handleOnClick = () => {
        props.selectProps.setGroupState?.((prev) => ({
            ...prev,
            [id]: {
                userOpened: prev[id] ? !prev[id].userOpened : true,
                searchOpened: false,
            },
        }));
    };

    const state = props.selectProps.groupState?.[id];
    const isOpen = state?.userOpened || state?.searchOpened;

    return (
        <components.GroupHeading {...props} onClick={handleOnClick}>
            <div className="app-select-dropdown--group-heading collapsible-header">
                {children}
                <AccordionButton isOpen={isOpen} />
            </div>
        </components.GroupHeading>
    );
};
