import React from "react";

import k from "i18n/keys";

import { IActivityProps } from "./IActivityProps";
import { ItemIcon } from "common/components/icons";
import { useTranslation } from "react-i18next";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityItemDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<ItemIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.ADD_ITEM_DROPDOWN)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityItemDropdown;
