import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";

import {
    getTemplateMenuActions,
    MenuCommands,
} from "common/components/toggle-state/MenuCommands";

import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

import MenuButton from "common/components/buttons/menu-button/MenuButton";
import { KebabIcon } from "common/components/icons";

import ImprovementFormContext from "../../../context/ImprovementFormContext";
import ArchiveTemplateMessage from "common/components/messages/ArchiveTemplateMessage";
import UnarchiveTemplateMessage from "common/components/messages/UnarchiveTemplateMessage";
import { IAppState } from "store/IAppState";
import DeleteTemplateMessage from "common/components/messages/DeleteTemplateMessage";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface IProps {}

const Menu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        improvementForm: template,
        createNew,
        handleOnArchive,
        handleOnDelete,
        haveChanges,
        handleOnReset,
        handleOnCopy,
    } = useContext(ImprovementFormContext);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const isDraft =
        template && template.version > (template.lastPublishedVersion ?? 0);

    const canDeleteDraft =
        template?.hasPublishedVersion === false ||
        (template?.hasPublishedVersion && isDraft);

    const handleMenuOnSelect = (selectedId: string) => {
        switch (selectedId as MenuCommands) {
            case MenuCommands.DeleteDraft:
                handleDeleteTemplateDraft();
                break;
            case MenuCommands.Reset:
                handleResetTemplate();
                break;
            case MenuCommands.Copy:
                handleCopyTemplate();
                break;
            case MenuCommands.Archive:
                handleArchiveTemplate();
                break;
            case MenuCommands.UndoArchive:
                handleUnArchiveTemplate();
                break;
            case MenuCommands.Delete:
                handleDeleteTemplate();
                break;
        }
    };

    const handleCopyTemplate = async () => {
        const args: IShowConfirmArgs = {
            message: haveChanges
                ? t(k.YOU_ARE_NOT_ABLE_TO_COPY_UNTIL_PUBLISH)
                : t(k.ARE_YOU_SURE),
            showOnlyClose: haveChanges,
            yesLabel: t(k.COPY_MESSAGE),
        };

        if ((await showConfirmNoThunk(dispatch, args)) && !haveChanges) {
            handleOnCopy();
        }
    };

    const handleDeleteTemplateDraft = async () => {
        if (await showConfirmNoThunk(dispatch, t(k.ARE_YOU_SURE))) {
            handleOnDelete(true);
        }
    };

    const handleResetTemplate = async () => {
        if (await showConfirmNoThunk(dispatch, t(k.ARE_YOU_SURE_RESET))) {
            handleOnReset();
        }
    };

    const handleArchiveTemplate = async () => {
        if (await showConfirmNoThunk(dispatch, <ArchiveTemplateMessage />)) {
            handleOnArchive();
        }
    };

    const handleUnArchiveTemplate = async () => {
        if (await showConfirmNoThunk(dispatch, <UnarchiveTemplateMessage />)) {
            handleOnArchive();
        }
    };

    const handleDeleteTemplate = async () => {
        if (await showConfirmNoThunk(dispatch, <DeleteTemplateMessage />)) {
            handleOnDelete();
        }
    };

    const menuOptions: IMenuOption[] = useMemo(() => {
        return getTemplateMenuActions({
            createNew,
            isArchived: template?.isArchived,
            isDraft,
            canDeleteDraft,
            isChanged: haveChanges,
        });
    }, [template, haveChanges]);

    return (
        <MenuButton
            icon={<KebabIcon variant="white" />}
            background="dark"
            onSelect={handleMenuOnSelect}
            options={menuOptions}
            testId="menu-button"
        />
    );
};

export default Menu;
