import { createContext } from "react";

import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import ITemplatePreviewData from "./ITemplatePreviewData";

export type TemplatePreviewContextType = ITemplatePreviewData &
    ListedContentContextType;

const initialContextValue: TemplatePreviewContextType = {
    isLoading: false,
    isSaving: false,
    mobileView: false,
    setErrors: () => null,
    handleClickEdit: () => null,
    handleCreateRecurrence: () => null,
    handleOnClose: (hideQuickRunForm?: boolean) => null,
    handleRunChecklist: () => null,
};

const TemplatePreviewContext =
    createContext<TemplatePreviewContextType>(initialContextValue);

export default TemplatePreviewContext;
