import { createContext, useContext } from "react";

import {
    IActiveTransactionsPayload,
    ICloseModalPayload,
    IIdModalHaveChanges,
    IIdModalListItems,
    IModalHaveChangesPayload,
    IOnChangeRecurrencePayload,
    IShowModalPayload,
    IUpdateModalPayload,
    ModalTypes,
    TModalTypes,
} from "./api/IModalManager";
import IDictionary from "common/viewModels/IDictionary";

export interface IModalManagerState {
    readonly modalList: IIdModalListItems;
    readonly initialCaller?: string;
    readonly order: string[];
    readonly activeModal?: string;
    readonly haveChangesList: IIdModalHaveChanges;
    readonly haveOpenModals: boolean;
    readonly modalTypes: IDictionary<TModalTypes>;
    readonly navigateAfterClose?: string;
    readonly ignoreNextNavigation?: boolean;
}

export const initialState: IModalManagerState = {
    modalList: {},
    initialCaller: undefined,
    order: [],
    activeModal: undefined,
    haveChangesList: {},
    haveOpenModals: false,
    modalTypes: ModalTypes,
    navigateAfterClose: undefined,
};

export interface IModalManagerContext {
    readonly state: IModalManagerState;
    onShowModal: (payload: IShowModalPayload) => void;
    onCloseModal: (payload: ICloseModalPayload) => void;
    onCloseModalNoCheck: (payload: ICloseModalPayload) => void;
    onModalHaveChanges: (payload: IModalHaveChangesPayload) => void;
    onUpdateModal: (payload: IUpdateModalPayload) => void;
    onChangeRecurrence: (payload: IOnChangeRecurrencePayload) => void;
    onUpdateActiveTransactions: (payload: IActiveTransactionsPayload) => void;
    resetInitialState: () => void;
}

export const ModalManagerContext = createContext<IModalManagerContext>({
    state: initialState,
    onShowModal: (payload: IShowModalPayload) => null,
    onCloseModal: (payload: ICloseModalPayload) => null,
    onCloseModalNoCheck: (payload: ICloseModalPayload) => null,
    onModalHaveChanges: (payload: IModalHaveChangesPayload) => null,
    onUpdateModal: (payload: IUpdateModalPayload) => null,
    onChangeRecurrence: (payload: IOnChangeRecurrencePayload) => null,
    onUpdateActiveTransactions: (payload: IActiveTransactionsPayload) => null,
    resetInitialState: () => null,
});

export const useModalMangerContext = () => useContext(ModalManagerContext);
