import IValidationMessages from "common/viewModels/IValidationMessages";
import * as yup from "yup";

export const errorToMessageReducer = (
    err: yup.ValidationError,
): IValidationMessages => {
    return err.inner.reduce((prev, cur) => {
        if (cur.path) {
            return { ...prev, [cur.path]: cur.message };
        } else {
            return prev;
        }
    }, {});
};
