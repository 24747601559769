import React, { useEffect } from "react";
import { useHasPageChanges } from "./redux/useHasPageChanges";

interface IProps {
    hasChanges?: boolean;
}

const PageChangeDispatcher: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const hasChanges = props.hasChanges;
    const setHasPageChanges = useHasPageChanges();

    useEffect(() => {
        setHasPageChanges(hasChanges ?? false);
    }, [hasChanges]);

    return null;
};

export default PageChangeDispatcher;
