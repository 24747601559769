import React, { useContext } from "react";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { HistoryIcon } from "common/components/icons";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import ChecklistDetailsContext from "components/checklists/components/ChecklistListedContent/checklistContext";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";

interface IButtonProps {}

const HistoryButton: React.FC<React.PropsWithChildren<IButtonProps>> = (
    props,
) => {
    const { selectedChecklistId, checkCanSeeAll } = useContext(
        ChecklistDetailsContext,
    );
    const modalManagerContext = useContext(ModalManagerContext);

    const handleShowHistory = () => {
        if (selectedChecklistId) {
            const payload: IShowModalPayload = {
                id: selectedChecklistId,
                type: ModalTypes.history,
                callerAddress: location.pathname,
                modalFor: {
                    id: selectedChecklistId,
                    type: ModalTypes.checklist,
                },
                options: {
                    historyOwnerType: HistoryOwnerEnum.Checklist,
                    showAll: true,
                },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    return (
        <IconButton
            onClick={handleShowHistory}
            background="dark"
            testId="historyBtn"
        >
            <HistoryIcon size={24} />
        </IconButton>
    );
};

export default HistoryButton;
