import "./RiskManagementIcon.scss";

import React from "react";

import Icon from "./assets/risk-management-icon.svg";

const RiskManagementIcon: React.FC = (props) => {
    return <Icon className="risk-management-icon" />;
};

export default RiskManagementIcon;
