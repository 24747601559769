import { Reducer } from "redux";

import {
    PageNavigationActionTypes,
    RECEIVE_PAGE_CHANGE,
    RECEIVE_DEVIATION_CHANGE,
} from "./types";

export interface IPageNavigationState {
    readonly hasPageChange: boolean;
    readonly hasDeviationChange: boolean;
}

const initialState: IPageNavigationState = {
    hasPageChange: false,
    hasDeviationChange: false,
};

export const PageNavigationReducer: Reducer<
    IPageNavigationState,
    PageNavigationActionTypes
> = (state = initialState, action): IPageNavigationState => {
    switch (action.type) {
        case RECEIVE_PAGE_CHANGE: {
            return {
                ...state,
                hasPageChange: action.payload ?? false,
            };
        }

        case RECEIVE_DEVIATION_CHANGE: {
            return {
                ...state,
                hasDeviationChange: action.payload ?? false,
            };
        }

        default:
            return state;
    }
};
