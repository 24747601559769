import "../RecurringChecklistForm.scss";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import RecurringChecklistIntervalField from "./RecurringChecklistIntervalField";
import { WorkflowScheduleInterval as WSI } from "components/recurring-checklist/api/WorkflowScheduleIntervals";
import RecurringChecklistAddWeekly from "./RecurringChecklistAddWeekly";
import RecurringChecklistAddMonthly from "./RecurringChecklistAddMonthly";
import RecurringChecklistAddYearly from "./RecurringChecklistAddYearly";
import { IValueLabelItem } from "common/IValueLabelItem";
import ModalContent from "common/components/modal-content/ModalContent";

interface IProps {
    recurringData: IRecurringChecklist;
    template: IValueLabelItem<string, string, string>;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: () => void;
    onPublish: () => void;
    onChangeRecurrence: (id: string) => void;
}

const RecurringChecklistAdd = (props: IProps) => {
    const { recurringData, template } = props;

    const { t } = useTranslation();

    const [WSIselected, setWSISelected] = useState<WSI>(
        recurringData.intervalType,
    );

    const handleSelectInterval = (id: WSI) => {
        setWSISelected(id);
    };

    return (
        <ModalContent>
            <ModalContent.Header
                className="reccuring-checklist--header"
                onClose={props.onClose}
                title={
                    <>
                        {t(k.CREATE_RECURRENCE_OF)} {template.label}
                    </>
                }
            />

            <RecurringChecklistIntervalField
                value={WSIselected}
                onSelect={handleSelectInterval}
            />
            <RecurringChecklistAddWeekly
                selected={WSIselected === WSI.Weekly}
                recurringData={recurringData}
                template={template}
                onHaveChanges={props.onHaveChanges}
                onPublish={props.onPublish}
                onChangeRecurrence={props.onChangeRecurrence}
            />
            <RecurringChecklistAddMonthly
                selected={WSIselected === WSI.Monthly}
                recurringData={recurringData}
                template={template}
                onHaveChanges={props.onHaveChanges}
                onPublish={props.onPublish}
                onChangeRecurrence={props.onChangeRecurrence}
            />
            <RecurringChecklistAddYearly
                selected={WSIselected === WSI.Yearly}
                recurringData={recurringData}
                template={template}
                onHaveChanges={props.onHaveChanges}
                onPublish={props.onPublish}
                onChangeRecurrence={props.onChangeRecurrence}
            />
        </ModalContent>
    );
};

export default RecurringChecklistAdd;
