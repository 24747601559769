import i18n from "i18next";
import React from "react";
import { Form, FormControl } from "react-bootstrap";
import { FaCloudUploadAlt } from "react-icons/fa";

import { getFileContent } from "../../../common/utils/fileUtils";
import IAttachmentFormData from "../../../common/viewModels/IAttachmentFormData";
import IValidationMessages from "../../../common/viewModels/IValidationMessages";
import k from "../../../i18n/keys";
import ValidationLabel from "../validation/ValidationLabel";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import Modal from "common/components/modals/Modal";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";

interface IProps {
    isLoading: boolean;

    errors: IValidationMessages;

    onClose: (isSave: boolean, data: IAttachmentFormData) => void;
}

interface IState extends IAttachmentFormData {
    [key: string]: any;

    fullName?: string;
    extension?: string;
}

class AttachmentFile extends React.Component<IProps, IState> {
    public state: IState = {
        name: "",
        content: "",
    };

    public handleClose = (isSave: boolean) => () => {
        const data: IAttachmentFormData = {
            name: this.state.name,
            content: this.state.content,
            extension: this.state.extension,
        };

        this.props.onClose(isSave, data);
    };

    public handleChange = (event: IInputChangeEvent<string>) => {
        const { name, value } = event;

        if (name) {
            this.setState({
                [name]: value,
            });
        }
    };

    public handleFileChange = (event: any) => {
        const file =
            event.target.files.length > 0 ? event.target.files[0] : undefined;

        if (file) {
            this.readFile(file);
        }
    };

    public readFile = (file: File) => {
        let fileName = file.name;
        let extension: string | undefined;

        const fileNameParts = fileName.split(".");

        if (fileNameParts.length > 1) {
            extension = fileNameParts.pop();
            extension = extension ? extension.toLowerCase() : extension;

            fileName = fileNameParts.join(".");
        }

        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const fileContent = getFileContent(fileReader.result as string);

            this.setState({
                fullName: file.name,
                extension,
                name: fileName,
                content: fileContent,
            });
        };

        fileReader.onerror = () => {
            this.setState({
                fullName: undefined,
                extension: undefined,
                name: "",
                content: "",
            });
        };

        fileReader.readAsDataURL(file);
    };

    public render() {
        const { isLoading, errors } = this.props;

        const { name, content, fullName } = this.state;

        const selectFileTitle = content
            ? `${i18n.t(k.REPLACE_FILE)}`
            : `${i18n.t(k.SELECT_FILE)}`;

        return (
            <Modal show onHide={this.handleClose(false)}>
                <Modal.Title>{i18n.t(k.NEW_FILE)}</Modal.Title>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="form.File">
                            <FormControl
                                as="label"
                                htmlFor="file-attachment-input"
                                className="btn btn-primary"
                            >
                                <input
                                    className="d-none"
                                    id="file-attachment-input"
                                    type="file"
                                    name="content"
                                    onChange={this.handleFileChange}
                                />

                                {selectFileTitle}

                                <FaCloudUploadAlt size={24} className="ml-1" />
                            </FormControl>

                            {fullName}

                            <ValidationLabel errors={errors.Content} />
                        </Form.Group>

                        <InputComponent
                            testId="attachment-file-name"
                            inputType={EActivityType.Textfield}
                            wrapperLabel={i18n.t(k.NAME)}
                            placeholder={i18n.t(k.ENTER_NAME)}
                            value={name}
                            name="name"
                            onChange={this.handleChange}
                            invalid={!!errors.Name}
                            hideIcon
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            disabled={isLoading}
                            onClick={this.handleClose(true)}
                        >
                            {isLoading ? i18n.t(k.SAVING) : i18n.t(k.SAVE)}
                        </Button>

                        <Button
                            variant="gray"
                            onClick={this.handleClose(false)}
                        >
                            {i18n.t(k.CANCEL)}
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AttachmentFile;
