import "./DateRange.scss";

import { useContext, memo } from "react";
import ImprovementsDetailsContext from "../../../../../improvementContext";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ActionAt from "common/components/action-and-date/ActionAndDate";

const DateRange = () => {
    const { t } = useTranslation();
    const { improvement, lastUpdatedAt } = useContext(
        ImprovementsDetailsContext,
    );

    return improvement?.createdAt ? (
        <div className="date-range">
            <ActionAt action={t(k.CREATED_AT)} date={improvement.createdAt} />
            {lastUpdatedAt && (
                <ActionAt action={t(k.UPDATED_AT)} date={lastUpdatedAt} />
            )}
        </div>
    ) : null;
};

export default memo(DateRange);
