import { useMutation, useQuery, useQueryClient } from "react-query";
import { IGoalRequest } from "./IGoalRequest";
import axios from "axios";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { IGoalDTO } from "./IGoalDTO";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { IGoalFilter } from "./IGoalFilter";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import { ISortRequest } from "http/requests/ISortRequest";
import { IGoalStatusRequest } from "./IGoalStatusRequest";
import { IGoalStatusCommentRequest } from "./IGoalStatusCommentRequest";
import { IServerGoalPageFilter } from "./IGoalPageFilters";
import {
    IInitialFetchOptions,
    useInitialFetch,
} from "common/utils/query/useInitialFetch";
import { GoalAccessRoleEnum } from "./EGoalAccessRole";

const baseUrl = "/api/goal";

const goalKeys = {
    all: ["goals"] as const,
    getTableSet: (filter?: IServerGoalPageFilter) =>
        [...goalKeys.all, "goal-table-set", filter] as const,
    getModifiableList: (id?: string) =>
        [...goalKeys.all, "goal-modifiable", id] as const,
    goalById: (id?: string) => [...goalKeys.all, "goal-byId", id] as const,
    getByIds: (filter?: IGoalFilter) =>
        [...goalKeys.all, "goals-byIds", filter] as const,
    getAccessRole: (id?: string) =>
        [`goal-access-role${id ? "-" + id : ""}`, id] as const,
    getViewAsAccessRole: (id?: string, filter?: IServerGoalPageFilter) =>
        [
            `goal-view-as-access-role${id ? "-" + id : ""}`,
            JSON.stringify(filter),
        ] as const,
};

export const useGoalTableSet = (
    { enabled, syncData }: IInitialFetchOptions,
    filter: IServerGoalPageFilter,
) =>
    useInitialFetch(
        goalKeys.getTableSet(filter),
        async () => {
            const url = `${baseUrl}/table-set`;
            const response = await axios.post<
                ITableSetWithOptions<IGoalDTO, string>
            >(url, filter);

            return response.data;
        },
        { enabled, syncData },
    );

export const useGoalModifiableList = (id?: string, enabled?: boolean) => {
    const url = id
        ? `${baseUrl}/modifiable-list/${id}`
        : `${baseUrl}/modifiable-list`;

    return useQuery(
        goalKeys.getModifiableList(id),
        async () => {
            const response = await axios.get<IGoalDTO[]>(url);

            return response.data;
        },
        { enabled },
    );
};

export const useSortMeasurementsMutation = () => {
    const url = `${baseUrl}/sortMeasurements`;

    return useMutation(async (variables: { request: ISortRequest }) => {
        try {
            const response = await axios.post<string>(url, variables.request);

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

export const useSortGoalsMutation = (sortSub?: boolean) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { request: ISortRequest }) => {
            const url = sortSub
                ? `${baseUrl}/sortSubgoals`
                : `${baseUrl}/sortGoals`;

            try {
                const response = await axios.post<string>(
                    url,
                    variables.request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(goalKeys.all);
            },
        },
    );
};

export const useSaveGoalMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (variables: { isCreate: boolean; value: IGoalRequest }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/${value.id}`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(goalKeys.goalById(data.Data));
            },
        },
    );
};

export const useGoalViewAsAccessRole = (
    goalId?: string,
    filter?: IServerGoalPageFilter,
) => {
    const url = goalId
        ? `${baseUrl}/access-role/${goalId}`
        : `${baseUrl}/access-role`;
    return useQuery(
        goalKeys.getViewAsAccessRole(goalId, filter),
        async (context) => {
            const response = await axios.post<GoalAccessRoleEnum>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled:
                Object.values(filter ?? {}).some(
                    (value) => value !== undefined,
                ) && filter?.viewAs !== undefined,
        },
    );
};

export const useGoalAccessRole = (goalId?: string) => {
    const url = `${baseUrl}/access-role/${goalId}`;
    return useQuery(
        goalKeys.getAccessRole(goalId),
        async (context) => {
            const response = await axios.get<GoalAccessRoleEnum>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: !!goalId },
    );
};

export const useDeleteGoalMutation = () => {
    return useMutation(async (id?: string) => {
        try {
            const response = await axios.delete<string>(`${baseUrl}/${id}`);

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

export const useArchiveGoalMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (request: IArchiveRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/archive`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(goalKeys.goalById(data.Data));
            },
        },
    );
};

export const useGoalListByIds = (
    filter?: IGoalFilter,
    keepPreviousData?: boolean,
) => {
    const url = `${baseUrl}/list-by-ids`;

    return useQuery(
        goalKeys.getByIds(filter),
        async (context) => {
            const response = await axios.post<IGoalDTO[]>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },
        { keepPreviousData },
    );
};

export const useGoalById = (id?: string, keepPreviousData?: boolean) => {
    const url = `${baseUrl}/${id}`;
    return useQuery(
        goalKeys.goalById(id),
        async () => {
            const response = await axios.get<IGoalDTO>(url);

            return response.data;
        },
        {
            enabled: id !== undefined && id !== "",
            keepPreviousData,
        },
    );
};

export const useChangeGoalStatusMutation = () => {
    return useMutation(async (request: IGoalStatusRequest) => {
        try {
            const response = await axios.post<string>(
                `${baseUrl}/changeStatus`,
                request,
            );

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

export const useChangeGoalStatusCommentMutation = () => {
    return useMutation(async (request: IGoalStatusCommentRequest) => {
        try {
            const response = await axios.post<string>(
                `${baseUrl}/changeStatusComment`,
                request,
            );

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};
