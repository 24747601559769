import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Button from "common/components/buttons/Button";
import { ArrowLeftIcon, KebabIcon } from "common/components/icons";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";

export interface ISidePanelHeadProps {
    className?: string;

    menuOptions?: IMenuOption[];

    extraButtons?: React.ReactNode;

    onClose?: () => void;

    onMenuSelect?: (selectedId: string) => void;
}

const ModalContentListedSidePanelHead: React.FC<
    React.PropsWithChildren<ISidePanelHeadProps>
> = (props) => {
    const { className, menuOptions, extraButtons } = props;

    const { t } = useTranslation();

    const headClassName = useMemo(() => {
        const result = ["m-m--m-c--listed--side-panel--head"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [className]);
    return (
        <div className={headClassName}>
            {props.children ?? (
                <>
                    {Boolean(props.onClose) && (
                        <Button
                            testId="closeModalContentBtn"
                            variant="white"
                            transparent
                            onClick={props.onClose}
                        >
                            <ArrowLeftIcon />
                            {t(k.CLOSE)}
                        </Button>
                    )}
                    <div className="right-side">
                        {extraButtons}
                        {Boolean(menuOptions) && (
                            <MenuButton
                                icon={<KebabIcon variant="white" />}
                                background="dark"
                                onSelect={props.onMenuSelect}
                                options={menuOptions}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ModalContentListedSidePanelHead;
