import { useContext } from "react";

import NewImprovementContext from "../../../../../newImprovementContext";

const Title = () => {
    const { improvementForm } = useContext(NewImprovementContext);

    return (
        <div className="side-panel__details__title">
            {improvementForm?.name}
        </div>
    );
};

export default Title;
