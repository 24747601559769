import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Modal from "common/components/modals/Modal";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    position: IValueLabelItemWithState<string>;
    onClose: () => void;
}

const PositionModal = (props: React.PropsWithRef<IProps>) => {
    const { position } = props;

    const { t } = useTranslation();
    return (
        <Modal show noFooter onHide={props.onClose}>
            <Modal.Title>{position.label}</Modal.Title>
            <Modal.Body>
                {position.param ?? t(k.NO_DESCRIPTION_PROVIDED)}
            </Modal.Body>
        </Modal>
    );
};

export default PositionModal;
