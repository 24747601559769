import i18n from "i18next";

import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";

export enum ImpactGradingEnum {
    None,
    VeryLow,
    Low,
    Moderate,
    High,
    VeryHigh,
}

export const ImpactGradingOrder = [
    ImpactGradingEnum.VeryLow,
    ImpactGradingEnum.Low,
    ImpactGradingEnum.Moderate,
    ImpactGradingEnum.High,
    ImpactGradingEnum.VeryHigh,
];

export const getImpactGradingOptions = (
    i18next: typeof i18n,
): IValueLabelItem<string, string, ImpactGradingEnum>[] => [
    {
        value: String(ImpactGradingEnum.VeryLow),
        label: i18next.t(k.IMPACT_SCORE1_LABEL),
        param: ImpactGradingEnum.VeryLow,
    },
    {
        value: String(ImpactGradingEnum.Low),
        label: i18next.t(k.IMPACT_SCORE2_LABEL),
        param: ImpactGradingEnum.Low,
    },
    {
        value: String(ImpactGradingEnum.Moderate),
        label: i18next.t(k.IMPACT_SCORE3_LABEL),
        param: ImpactGradingEnum.Moderate,
    },
    {
        value: String(ImpactGradingEnum.High),
        label: i18next.t(k.IMPACT_SCORE4_LABEL),
        param: ImpactGradingEnum.High,
    },
    {
        value: String(ImpactGradingEnum.VeryHigh),
        label: i18next.t(k.IMPACT_SCORE5_LABEL),
        param: ImpactGradingEnum.VeryHigh,
    },
];

export const getImpactGradingTypeByValue = {
    [String(ImpactGradingEnum.VeryLow)]: ImpactGradingEnum.VeryLow,
    [String(ImpactGradingEnum.Low)]: ImpactGradingEnum.Low,
    [String(ImpactGradingEnum.Moderate)]: ImpactGradingEnum.Moderate,
    [String(ImpactGradingEnum.High)]: ImpactGradingEnum.High,
    [String(ImpactGradingEnum.VeryHigh)]: ImpactGradingEnum.VeryHigh,
};
