import { IValueLabelItem } from "common/IValueLabelItem";
import { FC } from "react";
import SelectedUsersAvatarList from "../select-users/SelectedUsersAvatarList";
import { useTranslation } from "react-i18next";
import "./SelectedUsersWithInfo.scss";
import ActionAndUser from "../action-and-user/ActionAndUser";
import k from "i18n/keys";
import Spinner from "react-bootstrap/Spinner";

interface IProps {
    users: IValueLabelItem<string, string, any>[] | undefined;
    labelPrefixTranslationKey: string;
    selectUsers?: React.ReactNode;
    tooltipMessage?: React.ReactNode;
    className?: string;
    isLoading?: boolean;
}

const SelectedUsersWithInfo: FC<IProps> = (props) => {
    const {
        users,
        labelPrefixTranslationKey,
        tooltipMessage,
        className,
        isLoading,
    } = props;
    const { t } = useTranslation();

    return users && users.length === 1 ? (
        <>
            {/* organized like this due to the nature of grid layout */}
            <span>{t(labelPrefixTranslationKey)}</span>
            {isLoading ? (
                <Spinner
                    animation="border"
                    size="sm"
                    as="span"
                    className="selected-users-with-info selected-users-with-info--loading"
                />
            ) : (
                <div className="selected-users-with-info--single">
                    <ActionAndUser
                        user={users[0].label}
                        className={"action-and-user__label-white"}
                    />
                    {props.selectUsers}
                </div>
            )}
        </>
    ) : (
        <>
            <span>{t(labelPrefixTranslationKey)}</span>
            {isLoading ? (
                <Spinner
                    animation="border"
                    size="sm"
                    as="span"
                    className="selected-users-with-info selected-users-with-info--loading"
                />
            ) : (
                <div className="selected-users-with-info">
                    {users?.length == 0 ? (
                        <span>{t(k.NONE)}</span>
                    ) : (
                        <SelectedUsersAvatarList
                            noTooltipLabel
                            maxShownAvatars={5}
                            size={24}
                            users={users ?? []}
                        />
                    )}

                    {props.selectUsers}
                </div>
            )}
            {/*
            //this part is commented out because we are not sure what we want to say here
            {tooltipMessage && (
                <div className="selected-users-with-info--tooltip">
                    <Tooltip
                        id="selected-users-with-info-tooltip"
                        message={tooltipMessage}
                        alignSelf="center"
                        placement="auto-end"
                        tooltipClassName="selected-users-with-info-tooltip--info"
                    >
                        <AiOutlineInfoCircle />
                    </Tooltip>
                </div>
            )} */}
        </>
    );
};

export default SelectedUsersWithInfo;
