import RiskForm from "components/operational-risk-analysis-page/components/RiskForm";

import { useContext } from "react";

import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const RiskFormModal: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        modalItem: { callId, id, options, modalFor },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <RiskForm
            id={id}
            riskFor={modalFor}
            createNew={options?.createNew}
            syncData={modalManagerContext.state.activeModal === callId}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
        />
    );
};

export default RiskFormModal;
