import React from "react";
import { useTranslation } from "react-i18next";
import { IValueLabelItem } from "common/IValueLabelItem";
interface IProps {
    allSelectedUsers: IValueLabelItem<string, string, any>[] | undefined;

    titleTranslationKey: string;
}

const ViewTemplateConfigurationUsersTooltipMessage: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const { allSelectedUsers, titleTranslationKey } = props;

    const { t } = useTranslation();

    return (
        <>
            <span>{t(titleTranslationKey)}</span>

            <div>
                {allSelectedUsers && allSelectedUsers?.length > 0 && (
                    <>
                        {allSelectedUsers.map((user, index) => (
                            <React.Fragment key={user.value}>
                                {index > 0 && ", "}
                                {user.label}
                            </React.Fragment>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default ViewTemplateConfigurationUsersTooltipMessage;
