import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IInputWrapperProps } from "common/components/input-components/InputWrapper";
import { IValueLabelItem } from "common/IValueLabelItem";
import { TableCell } from "common/components/table";
import { usePublishedUserOptions } from "components/users/api/hooks";

interface IProps extends IInputWrapperProps {
    closeMenuOnSelect?: boolean;
    defaultSelectedIds?: string[];
    selectedIds?: string[];
    isLoading?: boolean;
    open?: boolean;

    onShow: (newState: boolean) => void;
    onChange: (ids: string[]) => void;
}

export const TableImprovementInvolvedFilter = (props: IProps) => {
    const {
        closeMenuOnSelect,
        selectedIds,
        defaultSelectedIds,
        isLoading,
        open,
    } = props;

    const { data: allOptions } = usePublishedUserOptions(true);

    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string>[]
    >([]);

    useEffect(() => {
        if (selectedIds) {
            const newSelectedOptions = allOptions?.options?.filter((x) =>
                selectedIds.includes(x.value),
            );
            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [allOptions, selectedIds]);

    const handleOnChange = (ids: string[]) => {
        const newOptions = allOptions?.options.filter((x) =>
            ids.includes(x.value),
        );

        setSelectedOptions(newOptions ?? []);

        props.onChange(ids);
    };

    return (
        <TableCell.Filtering
            isLoading={isLoading}
            menuOptions={allOptions?.options}
            onFilter={handleOnChange}
            open={open}
            onShow={props.onShow}
        />
    );
};
