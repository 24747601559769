import "./KebabIcon.scss";

import React from "react";

import Icon from "./assets/kebab-icon.svg";
import { useMemo } from "react";

interface IProps {
    variant?: "white" | "blue" | "dark-blue";
}

const KebabIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { variant } = props;
    const className = useMemo(() => {
        const result = ["kebab-icon"];
        if (variant) {
            result.push(`kebab-icon--${variant}`);
        }
        return result.join(" ");
    }, [variant]);
    return <Icon className={className} />;
};

export default KebabIcon;
