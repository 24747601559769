import "./SortableActivityInstanceSet.scss";
import React, { useEffect } from "react";
import { FaBars } from "react-icons/fa";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    SortEnd,
} from "react-sortable-hoc";
import { arrayMoveTo } from "common/utils/arrayUtils";
import { IActivityInstanceSetProps } from "./IActivityInstanceSetProps";
import IActivityInstance from "models/IActivityInstance";
import ActivityInstanceCardById from "common/components/activity-instance-card/ActivityInstanceCardById";
import { IActivityInstanceSetDto } from "models/IActivityInstanceSetDto";
import IStepAvailableUsersDTO from "models/dto/IStepAvailableUsersDTO";

const DragHandle = SortableHandle(() => (
    <div className="sortable-activity-instance--handle">
        <FaBars />
    </div>
));
interface ISortableItemProps {
    activityInstance?: IActivityInstance;
    isEditMode?: boolean;
    order?: number;
    activityInstanceSet?: IActivityInstanceSetDto;
    preview?: boolean;
    availableUsers?: IStepAvailableUsersDTO;
    isImprovement?: boolean;
    onDraftEnd?: (isSave?: boolean) => void;

    onConfirmFinalize: () => void;
    invalidByActivityInstanceId: Record<string, boolean | undefined>;
    onNewImprovement: (forName?: string) => void;
}

const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        const {
            activityInstance,
            order,
            activityInstanceSet,
            preview,
            availableUsers,
            isImprovement,
            invalidByActivityInstanceId,
        } = props;

        if (!activityInstance || !activityInstanceSet) {
            return null;
        }

        const { completed, acceptMultiple } = activityInstanceSet;
        const { isUserDefined, edit, isCreate } = activityInstance;

        const isDraggable = isUserDefined && edit && !isCreate;

        return (
            <div
                className="sortable-activity-instance-set--item"
                data-testid={`tasklist-item-${order}`}
            >
                {isDraggable && <DragHandle />}

                <ActivityInstanceCardById
                    key={activityInstance.id}
                    id={activityInstance.id}
                    isFirst={order === 0}
                    isSetCompleted={completed}
                    acceptMultiple={acceptMultiple}
                    preview={preview}
                    availableUsers={availableUsers}
                    onConfirmFinalize={props.onConfirmFinalize}
                    onNewImprovement={props.onNewImprovement}
                    isImprovement={isImprovement}
                    invalidByActivityInstanceId={invalidByActivityInstanceId}
                    onDraftEnd={props.onDraftEnd}
                />
            </div>
        );
    },
);

const SortableList = SortableContainer<{ children: React.ReactNode }>(
    (props: { children: React.ReactNode }) => {
        return (
            <div className="sortable-activity-instance-set">
                {props.children}
            </div>
        );
    },
);

const SortableActivityInstanceSet = (props: IActivityInstanceSetProps) => {
    const {
        activityInstanceSet,
        userDefinedTasks,
        activityInstances,
        activityInstanceIdsList: idsList,
        preview,
        availableUsers,
        isImprovement,
        invalidByActivityInstanceId,
    } = props;

    if (activityInstanceSet) {
        let activityInstanceIdsList = idsList?.[activityInstanceSet.id] ?? [];

        const { length } = activityInstanceIdsList ?? [];

        useEffect(() => {
            props.onChangeList?.(
                activityInstanceSet?.id,
                activityInstanceIdsList,
            );
        }, [length, userDefinedTasks]);

        const onSortEndHandler = (params: SortEnd) => {
            const sortedItems = arrayMoveTo(
                activityInstanceIdsList,
                params.oldIndex,
                params.newIndex,
            );
            props.onChangeList?.(activityInstanceSet.id, sortedItems);
        };

        return (
            <SortableList
                useDragHandle
                useWindowAsScrollContainer
                onSortEnd={onSortEndHandler}
                lockAxis="y"
                lockToContainerEdges
                helperClass="sortable-activity-instance-set--item--dragging"
            >
                {activityInstanceIdsList?.map((activityInstanceId, index) => (
                    <SortableItem
                        disabled={
                            !activityInstances?.values?.[activityInstanceId]
                                ?.isUserDefined
                        }
                        isEditMode={
                            activityInstances?.values?.[activityInstanceId]
                                ?.isUserDefined
                        }
                        key={activityInstanceId}
                        order={index}
                        index={index}
                        activityInstance={
                            activityInstances?.values?.[activityInstanceId]
                        }
                        activityInstanceSet={activityInstanceSet}
                        preview={preview}
                        availableUsers={availableUsers}
                        onNewImprovement={props.onNewImprovement}
                        isImprovement={isImprovement}
                        invalidByActivityInstanceId={
                            invalidByActivityInstanceId
                        }
                        onConfirmFinalize={props.onConfirmFinalize}
                        onDraftEnd={props.onDraftEnd}
                    />
                ))}
            </SortableList>
        );
    }
    return null;
};

export default SortableActivityInstanceSet;
