import React from "react";
import { useDispatch } from "react-redux";
import { CellProps, Column } from "react-datasheet-grid";
import { AiOutlinePicture } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { Button } from "common/components/buttons";
import WebCamModal from "common/components/web-cam-modal/WebCamModal";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { showImage } from "store/images/actions";

function addPhotoComponent(props: CellProps<string | null>) {
    const { rowData, setRowData } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isWebCamVisible, setIsWebCamVisible] = React.useState(false);

    const handleAdd = () => {
        setIsWebCamVisible(true);
    };

    const handlePreview = () => {
        if (rowData) {
            dispatch(showImage(rowData));
        }
    };

    const handleOnWebCamChange = (e: IInputChangeEvent<string>) => {
        setIsWebCamVisible(false);

        setRowData(e.value);
    };

    const handleOnWebCamClose = () => {
        setIsWebCamVisible(false);
    };

    return (
        <React.Fragment>
            <WebCamModal
                show={isWebCamVisible}
                onChange={handleOnWebCamChange}
                onClose={handleOnWebCamClose}
            />
            {rowData && (
                <React.Fragment>
                    <Button
                        size="small"
                        onClick={handlePreview}
                        icon={<AiOutlinePicture />}
                        variant="success"
                    >
                        {t(k.PREVIEW)}
                    </Button>
                    &nbsp;
                </React.Fragment>
            )}
            <Button size="small" onClick={handleAdd}>
                {rowData ? t(k.REPLACE) : t(k.ADD_PHOTO)}
            </Button>
        </React.Fragment>
    );
}

const AddPhotoComponent = React.memo(
    addPhotoComponent,
) as typeof addPhotoComponent;

export const addPhotoColumn = (): Column<string | null> => ({
    component: AddPhotoComponent,
    deleteValue: () => null,
    copyValue: ({ rowData }) => rowData,
    pasteValue: ({ value }) => value,
    isCellEmpty: ({ rowData }) => rowData === null || rowData === undefined,
});
