import { Months } from "models/enums/Months";
import k from "i18n/keys";

export const MonthsKeysLong = {
    [Months.January]: k.JANUARY,
    [Months.February]: k.FEBRUARY,
    [Months.March]: k.MARCH,
    [Months.April]: k.APRIL,
    [Months.May]: k.MAY,
    [Months.June]: k.JUNE,
    [Months.July]: k.JULY,
    [Months.August]: k.AUGUST,
    [Months.September]: k.SEPTEMBER,
    [Months.October]: k.OCTOBER,
    [Months.November]: k.NOVEMBER,
    [Months.December]: k.DECEMBER,
};

export const MonthsKeys = {
    [Months.January]: k.JAN,
    [Months.February]: k.FEB,
    [Months.March]: k.MAR,
    [Months.April]: k.APR,
    [Months.May]: k.MAY,
    [Months.June]: k.JUN,
    [Months.July]: k.JUL,
    [Months.August]: k.AUG,
    [Months.September]: k.SEP,
    [Months.October]: k.OCT,
    [Months.November]: k.NOV,
    [Months.December]: k.DEC,
};
