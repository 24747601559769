import { useMemo } from "react";
import ModalContentListedContent, {
    IListedContentProps,
} from "./ModalContentListedContent";
import ModalContentListedSidePanel, {
    ListedSidePanelType,
} from "./ModalContentListedSidePanel";

interface IProps {
    className?: string;
    bodyRef?: React.RefObject<HTMLDivElement>;

    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

export type ModalContentListedType = React.FC<
    React.PropsWithChildren<IProps>
> & {
    Content: React.FC<IListedContentProps>;
    SidePanel: ListedSidePanelType;
};

const ModalContentListed: ModalContentListedType = (props) => {
    const { className, bodyRef } = props;

    const listedBodyClassName = useMemo(() => {
        const result = ["modal-manager--modal-content--listed"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, []);

    return (
        <div
            className={listedBodyClassName}
            ref={bodyRef}
            onScroll={props.onScroll}
        >
            {props.children}
        </div>
    );
};

ModalContentListed.Content = ModalContentListedContent;
ModalContentListed.SidePanel = ModalContentListedSidePanel;

export default ModalContentListed;
