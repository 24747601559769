import "./GenericPropertiesForm.scss";

import React, { ReactElement, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import AddPropertyButton, { IListData } from "./components/AddPropertyButton";

import { SortEnd, SortEvent, SortStart } from "react-sortable-hoc";
import { Container, SortableItem } from "../sortableLists/SortableList";
import { IAddActivityParam } from "components/steps/api/IAddActivityParam";

interface IProps<L> {
    title?: React.ReactNode;
    testId?: string;
    itemIds?: string[];
    renderTemplate: (itemId: string, index: number) => ReactElement;

    listData: IListData<L>[];
    buttonTitle?: string;

    sortDisabled?: boolean;
    addDisabled?: boolean;

    onSorted: (params: SortEnd, listId?: undefined) => void;

    itemClassName?: string;
    useDragHandle?: boolean;
    hideOrder?: boolean;

    noWrapper?: boolean;

    onAddItem: (item: IAddActivityParam<L>, index: number) => void;
}

const GenericPropertiesForm = <L,>(
    props: React.PropsWithChildren<IProps<L>>,
) => {
    const {
        title,
        testId,
        itemIds,
        listData,
        buttonTitle,
        sortDisabled,
        addDisabled,
        noWrapper,
    } = props;

    const { t } = useTranslation();

    const [selectedPropertyButtonIndex, setSelectedPropertyButtonIndex] =
        useState<number>();

    const handleOnSortStart = (params: SortStart, event: SortEvent) => {
        if (event instanceof MouseEvent) {
            document.body.classList.add("grabbing-sort-item");
        }
    };

    const handleOnSortEnd = (params: SortEnd) => {
        props.onSorted(params);

        document.body.classList.remove("grabbing-sort-item");
    };

    const result = (
        <>
            {(!itemIds || itemIds.length === 0) && !addDisabled && (
                <AddPropertyButton
                    index={0}
                    listData={listData}
                    buttonTitle={buttonTitle ?? k.ADD_PROPERTY}
                    isDisabled={
                        selectedPropertyButtonIndex !== undefined &&
                        selectedPropertyButtonIndex !== 0
                    }
                    isBlur={
                        selectedPropertyButtonIndex !== undefined &&
                        selectedPropertyButtonIndex !== 0
                    }
                    showList={setSelectedPropertyButtonIndex}
                    invalid={false}
                    onSelectProperty={props.onAddItem}
                />
            )}
            {itemIds && (
                <Container
                    lockAxis="y"
                    useDragHandle
                    helperClass="generic-properties-form--item--dragging"
                    className="generic-properties-form--list"
                    onSortStart={handleOnSortStart}
                    onSortEnd={handleOnSortEnd}
                >
                    {itemIds.map((itemId, index) => (
                        <React.Fragment key={itemId}>
                            <SortableItem
                                index={index}
                                itemDisabled={sortDisabled}
                                useHandle={!sortDisabled}
                                className={props.itemClassName}
                            >
                                {props.renderTemplate(itemId, index)}
                            </SortableItem>
                            {!addDisabled && (
                                <AddPropertyButton
                                    listData={listData}
                                    buttonTitle={buttonTitle ?? k.ADD_PROPERTY}
                                    index={index}
                                    isDisabled={
                                        selectedPropertyButtonIndex !==
                                            undefined &&
                                        selectedPropertyButtonIndex !== index
                                    }
                                    isBlur={
                                        selectedPropertyButtonIndex !==
                                            undefined &&
                                        selectedPropertyButtonIndex !== index
                                    }
                                    showList={setSelectedPropertyButtonIndex}
                                    invalid={false}
                                    onSelectProperty={props.onAddItem}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Container>
            )}
        </>
    );

    if (noWrapper) {
        return result;
    }

    return (
        <div className="generic-properties-form" data-testid={testId}>
            <label className="generic-properties-form--title">
                {title ?? t(k.PROPERTIES)}
            </label>
            {result}
        </div>
    );
};

export default GenericPropertiesForm;
