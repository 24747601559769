import React from "react";

import { useTranslation } from "react-i18next";

import { getLocaleString } from "i18n/components/GetLocaleString";
import IStep from "models/IStep";

import { EParentType } from "./ListItems/ListItems";

import "./StepListDropdownValue.scss";

interface IProps {
    step?: IStep;
    name?: string;
    index?: number;
    isCurrent?: boolean;
    parentType?: EParentType;
}
const StepListDropdownValue: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { step, isCurrent = false, index, parentType, name } = props;
    const { t } = useTranslation();

    if (!step && !name) {
        return null;
    }

    const hasIndex = index !== undefined ? `${index}. ` : "";
    return (
        <div className={`steps-list-dropdown${isCurrent ? " selected" : ""}`}>
            <div className="steps-list-dropdown--column">
                {hasIndex}
                {getLocaleString(t, step?.name ?? name)}
            </div>
        </div>
    );
};

export default StepListDropdownValue;
