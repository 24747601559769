import { useMemo } from "react";
import { format, isSameDay } from "date-fns";

interface IProps {
    startDate?: Date | null;
    endDate?: Date | null;
}

const DATE_FORMAT = "MMM dd, yyyy";

export const DateRangeDescription = (props: IProps) => {
    const { startDate, endDate } = props;

    const startDateFormatted = useMemo(() => {
        return startDate ? format(startDate, DATE_FORMAT) : undefined;
    }, [startDate]);

    const endDateFormatted = useMemo(() => {
        if (startDate && endDate && isSameDay(startDate, endDate)) {
            return undefined;
        }

        return endDate ? format(endDate, DATE_FORMAT) : undefined;
    }, [endDate, startDate]);

    return (
        <strong>
            {startDateFormatted}
            {endDateFormatted && " - "}
            {endDateFormatted}
        </strong>
    );
};
