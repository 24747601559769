import {
    IImprovementSchema,
    improvementSchema,
} from "common/components/submit-improvement-card/ValidateImprovement";
import * as yup from "yup";

// find all required inputs and create a schema for validation
export const createValidationSchema = ({
    impactGradingsFeature,
    impactedProcessesFeature,
    isEmptyImpactList,
}: IImprovementSchema) => {
    const schema = improvementSchema({
        impactGradingsFeature,
        impactedProcessesFeature,
        isEmptyImpactList,
    });

    return yup.object({ ...schema });
};
