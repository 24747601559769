import { useMemo } from "react";

import {
    AdministratorMenuOptions,
    HRMenuOptions,
    IMenuOption,
    MarketingSalesMenuOptions,
    OtherResourcesMenuOptions,
    PerformanceManagementMenuOptions,
    ReportsMenuOptions,
    RiskManagementMenuOptions,
    SeniorManagementMenuOptions,
    SourcingPurchaseMenuOptions,
    TemplateMenuOptions,
} from "./MenuPaths";
import { useCanAccessKpi } from "common/components/modal-manager-content/kpi-form/api/hooks";
import { EPages } from "components/roles/api/EPages";
import { Permissions } from "components/roles/api/Permissions";
import {
    IAvailableAppFeatureProperties,
    IUserRoles,
} from "models/dto/IProfileDTO";

function isAdminOrInRole(
    roles: IUserRoles,
    page: EPages,
    allowAccess?: boolean,
) {
    if (allowAccess === undefined) {
        if (roles.ADMINISTRATOR) {
            return true;
        }
    } else if (allowAccess) {
        return true;
    }

    for (const role of Permissions[page].allRoles) {
        if (role in roles) {
            return true;
        }
    }

    return false;
}

const useNavMenuOptions = (
    appFeatures: IAvailableAppFeatureProperties,
    roles: IUserRoles,
) => {
    const { data: canAccessKpi } = useCanAccessKpi();

    const performanceManagementMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = PerformanceManagementMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "kpi":
                    result =
                        (appFeatures.kpi &&
                            isAdminOrInRole(roles, EPages.KPI, canAccessKpi)) ??
                        false;

                    break;

                case "goals":
                    result =
                        (appFeatures.goals &&
                            isAdminOrInRole(roles, EPages.GOALS)) ??
                        false;

                    break;

                case undefined:
                    result = true;

                    break;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles, canAccessKpi]);

    const templateMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = TemplateMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "sharedTemplateSteps":
                    result = appFeatures.sharedSteps ?? false;

                    break;

                case "improvementForms":
                    result =
                        (roles.ADMINISTRATOR || roles.DEVIATION_MANAGER) ??
                        false;

                    break;

                case undefined:
                    result = true;

                    break;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    const seniorManagementMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = SeniorManagementMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "legislations":
                    result =
                        (appFeatures.legislations &&
                            isAdminOrInRole(roles, EPages.LEGISLATIONS)) ??
                        false;

                    break;

                case "impactGradings":
                    result =
                        (appFeatures.impactGradings &&
                            isAdminOrInRole(roles, EPages.IMPACT_GRADINGS)) ??
                        false;

                    break;

                case "stakeholders":
                    result =
                        (appFeatures.stakeholders &&
                            isAdminOrInRole(roles, EPages.STAKEHOLDERS)) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    const marketingSalesMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = MarketingSalesMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "customers":
                    result = appFeatures.customers ?? false;

                    break;

                case "customerRequirements":
                    result =
                        (appFeatures.customerRequirements &&
                            isAdminOrInRole(
                                roles,
                                EPages.CUSTOMER_REQUIREMENTS,
                            )) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures]);

    const sourcingPurchaseMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = SourcingPurchaseMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "suppliers":
                    result =
                        (appFeatures.suppliers &&
                            isAdminOrInRole(roles, EPages.SUPPLIERS)) ??
                        false;

                    break;

                case "purchaseOrders":
                    result =
                        (appFeatures.purchaseOrders &&
                            isAdminOrInRole(roles, EPages.PURCHASE_ORDERS)) ??
                        false;

                    break;

                case "itemCatalog":
                    result =
                        (appFeatures.itemCatalog &&
                            isAdminOrInRole(roles, EPages.ITEMS)) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures]);

    const hRMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = HRMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "users":
                    result = isAdminOrInRole(roles, EPages.USER) ?? false;

                    break;
                case "positions":
                    result = isAdminOrInRole(roles, EPages.POSITION) ?? false;

                    break;
                case "competencies":
                    result =
                        (appFeatures.competencies &&
                            isAdminOrInRole(roles, EPages.COMPETENCIES)) ??
                        false;

                    break;

                case "teams":
                    result =
                        (appFeatures.teams &&
                            isAdminOrInRole(roles, EPages.TEAMS)) ??
                        false;

                    break;

                case "competencyMatrix":
                    result =
                        (appFeatures.competencyMatrixes &&
                            isAdminOrInRole(roles, EPages.CM)) ??
                        false;

                    break;

                case "staffAppraisals":
                    result =
                        (appFeatures.staffAppraisals &&
                            isAdminOrInRole(roles, EPages.SA)) ??
                        false;

                    break;

                case "trainingPlans":
                    result =
                        (appFeatures.trainingPlans &&
                            isAdminOrInRole(roles, EPages.TRAINING_PLANS)) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    const otherMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = OtherResourcesMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "equipment":
                    result =
                        (appFeatures.equipment &&
                            isAdminOrInRole(roles, EPages.EQUIPMENT)) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    const riskManagementMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = RiskManagementMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "operationalRiskAnalysis":
                    result =
                        (appFeatures.operationalRiskAnalysis &&
                            isAdminOrInRole(roles, EPages.ORA)) ??
                        false;

                    break;

                case "environmentalAspects":
                    result =
                        (appFeatures.environmentalAspects &&
                            isAdminOrInRole(
                                roles,
                                EPages.ENVIRONMENT_ASPECTS,
                            )) ??
                        false;

                    break;

                case undefined:
                    result = true;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    const administratorMenuOptions = useMemo(() => {
        const options = AdministratorMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "itemCatalog":
                    result =
                        ((appFeatures.kpi && canAccessKpi) ||
                            appFeatures.goals ||
                            appFeatures.itemCatalog) ??
                        false;

                    break;

                case "purchaseOrders":
                    result = appFeatures.purchaseOrders ?? false;

                    break;

                case "customerIndustries":
                    result = appFeatures.customerRequirements ?? false;

                    break;

                case "webhooks":
                    result = appFeatures.webhooks ?? false;

                    break;

                case "sso":
                    result = appFeatures.sso ?? false;

                    break;

                case "tenantSettings":
                    result = true;

                    break;

                case "pages":
                    result = appFeatures.pages ?? false;

                    break;

                case undefined:
                    result = true;
                    break;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, canAccessKpi]);

    const reportsMenuOptions: Array<IMenuOption> = useMemo(() => {
        const options = ReportsMenuOptions.filter((x) => {
            let result = false;

            switch (x.param) {
                case "checklistsReport":
                case "checklists-report-search":
                    result =
                        (appFeatures.checklistReport &&
                            isAdminOrInRole(roles, EPages.CHECKLIST_REPORT)) ??
                        false;

                    break;

                case "improvementDashboard":
                    result =
                        (appFeatures.improvementDashboard &&
                            isAdminOrInRole(
                                roles,
                                EPages.IMPROVEMENT_DASHBOARD,
                            )) ??
                        false;

                    break;

                default:
                    result = true;
                    break;
            }

            return result;
        });

        return options as unknown as Array<IMenuOption>;
    }, [appFeatures, roles]);

    return {
        templateMenuOptions,
        seniorManagementMenuOptions,
        marketingSalesMenuOptions,
        sourcingPurchaseMenuOptions,
        hRMenuOptions,
        otherMenuOptions,
        riskManagementMenuOptions,
        administratorMenuOptions,
        reportsMenuOptions,
        performanceManagementMenuOptions,
    };
};

export default useNavMenuOptions;
