import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import { createContext } from "react";
import { IImprovementData } from "./models/IImprovementData";

export type ImprovementContextType = IImprovementData &
    ListedContentContextType;

const initialContextValue: ImprovementContextType = {
    editMode: false,
    setEditMode: () => null,
    setEditedImprovement: () => null,
    setActivityInstances: () => null,
    setActivityInstanceIdsBySet: () => null,
    setLastUpdatedAt: () => null,
    editedActivityValues: {},
    setEditedActivityValues: () => null,
    onClose: () => null,
    onHaveChanges: () => null,
    setErrors: () => null,
    onShowModal: () => null,
    setUsersAffected: () => null,
    setUsersToBeNotified: () => null,
    setEditedSteps: () => null,
    setInvolvedUsers: () => null,
    mobileView: false,
    checkCanSeeAll: false,
};

const ImprovementsDetailsContext =
    createContext<ImprovementContextType>(initialContextValue);

export default ImprovementsDetailsContext;
