import "./SingleValueWithClick.scss";

import React from "react";
import { components, GroupBase, SingleValueProps } from "react-select";
import classNames from "classnames";
import { OptionType } from "../SelectDropdownTypes";

export const SingleValueWithClick = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: SingleValueProps<Option, IsMulti, Group>,
) => {
    const handlePreventDefault = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const isPreviewable =
        !!props.selectProps.onPreview && props.data?.param !== false;

    const className = classNames(
        "single-value-with-click",
        "app-select-dropdown__single-value",
        props.className,
        {
            "preview-value": isPreviewable,
            "pointer-events-all": isPreviewable,
            "preview-link": isPreviewable,
        },
    );

    const handleClick = () => {
        const data = props.data as OptionType | undefined;

        if (data?.value && props.selectProps.onPreview) {
            props.selectProps.onPreview(data.value as string);
        }
    };

    return (
        <components.SingleValue
            {...props}
            innerProps={{
                ...props.innerProps,
                className,
                onMouseDown: isPreviewable ? handlePreventDefault : undefined,
                onClick: isPreviewable ? handleClick : undefined,
            }}
        />
    );
};
