import { useEffect, useRef } from "react";

/*
from https://github.com/jmlweb/isMounted
*/

const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

export default useIsMounted;
