import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { TableCell, TableRow } from "common/components/table";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { WorksheetStatusBadge } from "common/components/badges/worksheet-status-badge";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { MenuCommands } from "common/components/toggle-state/MenuCommands";
import StepsDone from "./StepsDone";
import StepInstanceStatusBadge from "common/components/badges/step-instance-status-badge/StepInstanceStatusBadge";
import SeparateTablesCell from "./SeparateTablesCell";
import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";
import {
    ApiKeyIcon,
    ScheduleIcon,
    UpdateAvailableIcon,
} from "common/components/icons";
import IChecklistReportRow from "../../api/IChecklistReportRow";
import ReportTableCell from "./ReportTableCell";
import IconButton from "common/components/buttons/icon-button/IconButton";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import TextOverflow from "common/components/text-overflow/TextOverflow";
import { ITableMatrix } from "../report-table-details/api/TableMatrix";
import { ReportTableMatrixDetailRow } from "../report-table-details/ReportTableMatrixDetailRow";

interface IProps {
    value: IChecklistReportRow;

    reportDetails: IChecklistReportResponse | null;
    tableMatrix: ITableMatrix | null;

    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;

    isAdministrator?: boolean;

    onSelectAction: (id: string, command: MenuCommands) => void;
}

const ReportTableRow: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, reportDetails, selectedCustomListItemsNames, tableMatrix } =
        props;

    const checklistId = value.id;

    const { t } = useTranslation();

    const nameCellHasTags = value.apiKeyId ?? value.scheduleId;

    const handlePreviewDeviations = () => {
        props.onSelectAction(checklistId, MenuCommands.PreviewDeviations);
    };

    const handleSelect = (command: MenuCommands) => {
        props.onSelectAction(checklistId, command);
    };

    const handlePreviewClick = () => {
        handleSelect(MenuCommands.Preview);
    };

    const handleOnBarcodeSearch = (barcode: string) => {
        props.onSelectAction(barcode, MenuCommands.BarcodeSearch);
    };

    const handleShowRecurringChecklistModal = () => {
        handleSelect(MenuCommands.PreviewRecurrence);
    };

    const hasNotCompletedDeviations =
        value.totalNotCompletedImprovementsCount > 0;

    const hasCompletedDeviations = value.totalCompletedImprovementsCount > 0;

    const rowSpan = tableMatrix?.rowSpans[checklistId];

    return (
        <React.Fragment>
            <TableRow
                isArchived={value.status === ChecklistDynamicStatus.Archived}
            >
                {nameCellHasTags ? (
                    <TableCell
                        className={
                            value.isTitleMatch
                                ? "table-cell__sticky table-cell__match-cell"
                                : "table-cell__sticky"
                        }
                        zIndex={1}
                        rowSpan={rowSpan}
                        onClick={handlePreviewClick}
                    >
                        <TextOverflow
                            className="preview-link"
                            ellipsis
                            title={value.title}
                        >
                            {value.title}
                        </TextOverflow>

                        <TableCell.Tags>
                            {value.apiKeyId && <ApiKeyIcon isActive={true} />}
                            {value.scheduleId && (
                                <IconButton
                                    tooltip={value.scheduleName}
                                    onClick={handleShowRecurringChecklistModal}
                                    testId="calendarChecklistReportBtn"
                                >
                                    <ScheduleIcon isActive={true} />
                                </IconButton>
                            )}
                        </TableCell.Tags>
                    </TableCell>
                ) : (
                    <TableCell
                        className={
                            value.isTitleMatch
                                ? "table-cell__sticky table-cell__match-cell"
                                : "table-cell__sticky"
                        }
                        zIndex={1}
                        rowSpan={rowSpan}
                        onClick={handlePreviewClick}
                    >
                        <TextOverflow
                            className="preview-link"
                            ellipsis
                            title={value.title}
                        >
                            {value.title}
                        </TextOverflow>
                    </TableCell>
                )}

                {value.hasSearchResult && (
                    <TableCell rowSpan={rowSpan}>
                        {value.totalMatchCount > 0 ? (
                            <div
                                className="preview-link"
                                onClick={handlePreviewClick}
                            >
                                {value.totalMatchCount}{" "}
                                {value.totalMatchCount === 1
                                    ? t(k.MATCHING_VALUE_)
                                    : t(k.MATCHING_VALUES_)}
                            </div>
                        ) : (
                            <React.Fragment>
                                {"0 "}
                                {t(k.MATCHING_VALUES_)}
                            </React.Fragment>
                        )}
                    </TableCell>
                )}

                {reportDetails ? undefined : (
                    <TableCell rowSpan={rowSpan}>
                        {value.templateName}
                    </TableCell>
                )}

                <TableCell useOverflow rowSpan={rowSpan}>
                    <FormattedDate
                        date={value.startDate}
                        splitDateTime
                        useFlexWrapper
                    />
                </TableCell>

                <TableCell useOverflow rowSpan={rowSpan}>
                    <FormattedDate
                        date={value.endDate}
                        splitDateTime
                        useFlexWrapper
                    />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    V.{value.version}
                    {value.hasNewVersion && (
                        <TableCell.Tags>
                            <div
                                title={t(k.UPDATE_AVAILABLE)}
                                className="table-cell__tag"
                            >
                                <UpdateAvailableIcon />
                            </div>
                        </TableCell.Tags>
                    )}
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    <WorksheetStatusBadge
                        status={value.status}
                        completedAt={value.completedAt}
                    />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    <StepsDone value={value} />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    {(hasNotCompletedDeviations || hasCompletedDeviations) && (
                        <span
                            className="preview-link"
                            onClick={handlePreviewDeviations}
                        >
                            {hasNotCompletedDeviations && (
                                <React.Fragment>
                                    {value.totalNotCompletedImprovementsCount}{" "}
                                    {t(k.OPEN)}
                                </React.Fragment>
                            )}

                            {hasNotCompletedDeviations &&
                                hasCompletedDeviations && (
                                    <React.Fragment>{", "}</React.Fragment>
                                )}

                            {hasCompletedDeviations && (
                                <React.Fragment>
                                    {value.totalCompletedImprovementsCount}{" "}
                                    {t(k.COMPLETED)}
                                </React.Fragment>
                            )}
                        </span>
                    )}
                </TableCell>

                {reportDetails && tableMatrix && (
                    <ReportTableMatrixDetailRow
                        columns={tableMatrix.columns}
                        row={tableMatrix.primaryRowsByChecklistId[checklistId]}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames
                        }
                        highlightedActivityInstanceIds={
                            value.activityInstanceIds
                        }
                        onBarcodeSearch={handleOnBarcodeSearch}
                    />
                )}

                {tableMatrix
                    ? undefined
                    : reportDetails?.headerLevelOneStepInstanceIds.map(
                          (headerStepInstanceId, headerStepInstanceIndex) => {
                              const headerLevelTwoColumns =
                                  reportDetails
                                      .headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId[
                                      headerStepInstanceId
                                  ];

                              const cellValue =
                                  reportDetails.valuesByChecklistId[
                                      checklistId
                                  ];

                              if (cellValue === undefined) {
                                  return (
                                      <React.Fragment
                                          key={headerStepInstanceId}
                                      >
                                          <SeparateTablesCell />

                                          <TableCell isHighlighted>
                                              <ReportTableCell.NotAvailableMessage />
                                          </TableCell>

                                          {headerLevelTwoColumns.map((x) => (
                                              <TableCell key={x.id}>
                                                  <ReportTableCell.NotAvailableMessage />
                                              </TableCell>
                                          ))}
                                      </React.Fragment>
                                  );
                              }

                              const valuesByActivityVersionId =
                                  cellValue
                                      .valuesByActivityVersionIdByHeaderLevelOneStepInstanceId[
                                      headerStepInstanceId
                                  ];

                              const stepInstanceId =
                                  cellValue.stepInstanceIds[
                                      headerStepInstanceIndex
                                  ];

                              const stepInstance = stepInstanceId
                                  ? reportDetails.stepInstancesById[
                                        stepInstanceId
                                    ]
                                  : undefined;

                              return (
                                  <React.Fragment key={headerStepInstanceId}>
                                      {headerStepInstanceIndex === 0 && (
                                          <SeparateTablesCell />
                                      )}

                                      <TableCell isHighlighted>
                                          {stepInstance ? (
                                              <StepInstanceStatusBadge
                                                  status={stepInstance.param}
                                              />
                                          ) : (
                                              <ReportTableCell.NotAvailableMessage />
                                          )}
                                      </TableCell>

                                      {headerLevelTwoColumns.map(
                                          ({ id, columnKeyId }) => (
                                              <ReportTableCell
                                                  key={id}
                                                  value={
                                                      valuesByActivityVersionId[
                                                          columnKeyId
                                                      ] ?? null
                                                  }
                                                  selectedCustomListItemsNames={
                                                      selectedCustomListItemsNames
                                                  }
                                                  highlightedActivityInstanceIds={
                                                      value.activityInstanceIds
                                                  }
                                                  isTopLevel={true}
                                                  onBarcodeSearch={
                                                      handleOnBarcodeSearch
                                                  }
                                              />
                                          ),
                                      )}
                                  </React.Fragment>
                              );
                          },
                      )}
            </TableRow>

            {reportDetails &&
                tableMatrix?.secondaryRowsByChecklistId[checklistId]?.map(
                    (row, index) => (
                        <TableRow key={index}>
                            <ReportTableMatrixDetailRow
                                columns={tableMatrix.columns}
                                row={row}
                                selectedCustomListItemsNames={
                                    selectedCustomListItemsNames
                                }
                                highlightedActivityInstanceIds={
                                    value.activityInstanceIds
                                }
                                onBarcodeSearch={handleOnBarcodeSearch}
                            />
                        </TableRow>
                    ),
                )}
        </React.Fragment>
    );
};

export default ReportTableRow;
