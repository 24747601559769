import { FC, useContext } from "react";
import ImprovementsDetailsContext from "../../../../../improvementContext";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ActionAndUser from "common/components/action-and-user/ActionAndUser";

interface IProps {}

const ReportedBy: FC<IProps> = (props) => {
    const { improvement } = useContext(ImprovementsDetailsContext);

    const { data: publishedUsers } = usePublishedUserOptions();

    const { t } = useTranslation();

    const reportedByUser = improvement?.createdBy
        ? publishedUsers?.values[improvement.createdBy]
        : undefined;

    return reportedByUser ? (
        <div>
            <span>{t(k.REPORTED_BY)}</span>

            <div className="selected-user-with-info">
                <ActionAndUser
                    user={reportedByUser.label}
                    className={"action-and-user__label-white"}
                />
            </div>
        </div>
    ) : null;
};

export default ReportedBy;
