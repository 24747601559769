import "./DateRangeFilter.scss";

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay, isSameDay } from "date-fns";

import { ActivityType } from "models/enums/ActivityType";
import { FilterDropdown } from "common/components/table/components/filtering/filter-dropdown/FilterDropdown";
import { IFilterProps } from "../api/IFilterProps";
import { DateRangeDescription } from "../filter-dropdown/components/DateRangeDescription";

interface IProps extends IFilterProps {}

export const DateRangeFilter = (props: IProps) => {
    const { value, testId } = props;

    const [isOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(value?.startDate !== undefined);
    const [isEdited, setIsEdited] = useState(false);

    const [startFilterValue, setStartFilterValue] = useState<Date | null>(
        value?.startDate ?? new Date(),
    );

    const [endFilterValue, setEndFilterValue] = useState<Date | null>(
        value?.endDate ?? new Date(),
    );

    React.useEffect(() => {
        if (!isOpen) {
            setStartFilterValue(value?.startDate ?? null);
            setEndFilterValue(value?.endDate ?? null);
        }
    }, [isOpen]);

    const handleReset = () => {
        setStartFilterValue(null);
        setEndFilterValue(null);

        setIsEdited(false);

        setIsActive(false);

        props.onFilter({
            type: ActivityType.DateTime,

            isReset: true,
        });
    };

    const tryHandleReset = (
        startValue?: Date | null,
        endValue?: Date | null,
    ) => {
        if (!startValue && !endValue) {
            setIsEdited(false);
        }
    };

    const handleFilter = (isReset?: boolean) => {
        if (isReset) {
            handleReset();
        } else {
            const startDate = startFilterValue
                ? startOfDay(startFilterValue)
                : undefined;

            const endDate = endFilterValue
                ? endOfDay(endFilterValue)
                : startFilterValue
                  ? endOfDay(startFilterValue)
                  : undefined;

            const isEmpty = !startDate && !endDate;

            setIsEdited(!isEmpty);

            setIsActive(!isEmpty);

            props.onFilter({
                type: ActivityType.DateTime,

                startDate,
                endDate,

                isReset: isEmpty,
            });
        }
    };

    const handleChangeDate = (dateValue: [Date | null, Date | null]) => {
        const [start, end] = dateValue;

        setStartFilterValue(start);

        const sameDay = start && end ? isSameDay(start, end) : false;

        setEndFilterValue(sameDay ? null : end);

        setIsEdited(true);

        tryHandleReset(start, end);
    };

    return (
        <FilterDropdown
            isEdited={isEdited}
            isActive={isActive}
            testId={testId}
            commandClassName="date-range-filter__commands"
            commandDescription={
                <DateRangeDescription
                    startDate={startFilterValue}
                    endDate={endFilterValue}
                />
            }
            onFilter={handleFilter}
            onToggle={setIsOpen}
        >
            <DatePicker
                openToDate={endFilterValue ?? startFilterValue ?? undefined}
                selected={startFilterValue}
                onChange={handleChangeDate}
                monthsShown={2}
                startDate={startFilterValue}
                endDate={endFilterValue}
                selectsRange
                inline
            />
        </FilterDropdown>
    );
};
