import React from "react";

interface IModalContext {}

export const ModalContext = React.createContext<IModalContext | undefined>(
    undefined,
);

export const useModalContext = () => {
    const context = React.useContext(ModalContext);
    if (!context) {
        throw new Error(
            `This component must be used within a <Modal> component`,
        );
    }
    return context;
};
