import { TableCell } from "common/components/table";
import { IChecklistReportValueSumFilter } from "components/template-checklist-report/api/IChecklistReportValueSumFilter";
import ITableSet from "http/ITableSet";

interface IReportTableSumCellsProps {
    totalColSpan: number;

    sumFilter: IChecklistReportValueSumFilter;

    sumOfValues: ITableSet<number | undefined> | null;
}

export const ReportTableSumCells = (props: IReportTableSumCellsProps) => {
    const { totalColSpan, sumFilter, sumOfValues } = props;

    return sumFilter.hasValueColumn ? (
        sumFilter.columns.map((x) => (
            <TableCell key={x.id}>{sumOfValues?.values[x.id]}</TableCell>
        ))
    ) : (
        <TableCell key="sum-cell_empty" colSpan={totalColSpan}>
            &nbsp;
        </TableCell>
    );
};
