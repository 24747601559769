import { useMutation, useQuery } from "react-query";

import axios from "axios";

import ExecutionResult from "common/viewModels/ExecutionResult";
import { IAssignUserToInputActionRequest } from "./IAssignUserToInputActionRequest";
import { IInputActionDTO } from "./IInputActionDTO";
import IDictionary from "common/viewModels/IDictionary";
import { IValueLabelItem } from "common/IValueLabelItem";

const baseUrl = "/api/inputAction";

export const useAssignUserToInputActionMutation = () => {
    return useMutation(async (request: IAssignUserToInputActionRequest) => {
        try {
            const response = await axios.post<string>(
                `${baseUrl}/assign-user`,
                request,
            );

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

export const useSaveInputActionMutation = () => {
    return useMutation(
        async (variables: { value: IInputActionDTO; isCreate?: boolean }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/${value.id}`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    );
};

export const useCompleteActionInputTaskMutation = () => {
    return useMutation(async (id?: string) => {
        try {
            const response = await axios.post<string>(
                `${baseUrl}/complete-task/${id}`,
            );

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

export const useDeleteInputActionMutation = () => {
    return useMutation(async (id?: string) => {
        try {
            const response = await axios.delete<string>(`${baseUrl}/${id}`);

            return ExecutionResult.Result<string>(response.data);
        } catch (error) {
            return ExecutionResult.Failed<string>(error);
        }
    });
};

const GET_INPUT_ACTION_AND_SET_LABELS_BY_IDS =
    "GET_INPUT_ACTION_AND_SET_LABELS_BY_IDS";

export const useInputActionNamesByIds = (ids: string[]) => {
    return useQuery(
        [GET_INPUT_ACTION_AND_SET_LABELS_BY_IDS, ids],
        async () => {
            const url = `${baseUrl}/labels-by-ids`;

            const response = await axios.post<
                IDictionary<
                    IValueLabelItem<
                        string,
                        string | undefined,
                        string | undefined
                    >
                >
            >(url, ids);

            return response.data;
        },
        {
            enabled: ids.length > 0,
        },
    );
};
