import React from "react";
import {
    DataSheetGrid,
    Column,
    keyColumn,
    textColumn,
    DataSheetGridRef,
} from "react-datasheet-grid";
import { useQueries } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    selectColumn,
    selectManyColumn,
    generateBarcodeColumn,
    addPhotoColumn,
    addCheckboxColumn,
    addDateTimeColumn,
} from "common/components/datasheet-grid";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { ImportImprovementRow } from "./ImportImprovementRow";
import { fetchCustomListItemOptions } from "components/custom-list-item-page/api/hooks";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import { useStepsByTemplateVersionId } from "components/steps/api/hooks";
import { ActivityType } from "models/enums/ActivityType";
import { getImpactGradingOptions } from "components/impact-grading-page/api/ImpactGradingEnum";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useUsersForImport } from "components/users/api/hooks";

interface IProps {
    templateVersionId: string;

    invalidColumnIdsByRowId: Map<string, Set<string>>;

    impactGradings: IValueLabelItem[] | null;
    allProcessChartOptions: IValueLabelItem[] | null;

    onChange: (data: ImportImprovementRow[]) => void;
}

function getInitialRow() {
    const result: ImportImprovementRow = {
        id: uuidv4(),
        deviationMessage: "",
        impactList: null,
        discovered: null,
        originated: null,
        createdAt: null,
        createdByUser: null,
    };

    return result;
}

const defaultData = Array(5)
    .fill({})
    .map(() => getInitialRow());

function getTitle(label: React.ReactNode, isRequired: boolean) {
    const title = (
        <>
            {label}
            &nbsp;
            {isRequired && <span className="text--danger">*</span>}
        </>
    );

    return title;
}

export const ImportGrid = React.forwardRef<DataSheetGridRef, IProps>(
    (props, ref) => {
        const {
            invalidColumnIdsByRowId,
            templateVersionId,
            impactGradings,
            allProcessChartOptions,
        } = props;

        const { t, i18n } = useTranslation();

        const [data, setData] =
            React.useState<ImportImprovementRow[]>(defaultData);

        const handleChange = (value: ImportImprovementRow[]) => {
            setData(value);

            props.onChange(value);
        };

        const { data: userOptionsForImport, isLoading: isUserOptionsLoading } =
            useUsersForImport();

        const { data: improvementFormSteps, isLoading: isLoadingSteps } =
            useStepsByTemplateVersionId(templateVersionId);

        const customListPropertyCustomListIds = React.useMemo(() => {
            const activities = Object.values(
                improvementFormSteps?.activities ?? {},
            ).filter(
                (x) => x.type == ActivityType.CustomList && x.customListId,
            );

            const result = activities.map((x) => ({
                id: x.customListId as string,
            }));

            return result.map((x) => ({
                queryKey: `import-customlist-items-${x.id}`,
                queryFn: () =>
                    fetchCustomListItemOptions({ customListId: x.id }, false),
            }));
        }, [improvementFormSteps]);

        const customListPropertyCustomListOptions = useQueries(
            customListPropertyCustomListIds,
        );

        const { data: customerOptions, isLoading: isLoadingCustomers } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Customers,
                ),
                ESharedOptionsType.customer,
            );

        const { data: supplierOptions, isLoading: isLoadingSuppliers } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Suppliers,
                ),
                ESharedOptionsType.supplier,
            );

        const { data: itemOptions, isLoading: isLoadingItems } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Items,
                ),
                ESharedOptionsType.item,
            );

        const { data: userOptions, isLoading: isLoadingUsers } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Users,
                ),
                ESharedOptionsType.user,
            );

        const { data: positionOptions, isLoading: isLoadingPositions } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Positions,
                ),
                ESharedOptionsType.position,
            );

        const { data: competencyOptions, isLoading: isLoadingCompetencies } =
            useSharedOptions(
                Object.values(improvementFormSteps?.activities ?? {}).some(
                    (x) => x?.type === ActivityType.Competencies,
                ),
                ESharedOptionsType.competency,
            );

        const columns = React.useMemo(() => {
            const result: Column<ImportImprovementRow>[] = [];

            result.push({
                ...keyColumn("deviationMessage", textColumn),
                title: getTitle(t(k.DESCRIPTION), true),
                minWidth: 150,
            });

            const impactGradingOptionValues = getImpactGradingOptions(i18n);

            impactGradings?.forEach((item) => {
                const impactGradingColumnOptions: IValueLabelItem[] =
                    impactGradingOptionValues.map((x) => ({
                        value: x.value,
                        label: x.value,
                    }));

                result.push({
                    ...keyColumn(
                        item.value,
                        selectColumn({
                            choices: impactGradingColumnOptions,
                            mode: "try-paste",
                        }),
                    ),
                    title: item.label,
                    minWidth: 150,
                });
            });

            result.push({
                ...keyColumn(
                    "discovered",
                    selectColumn({
                        choices: allProcessChartOptions ?? [],
                        mode: "impacted-processes",
                    }),
                ),
                title: getTitle(t(k.DISCOVERED_IN), true),
                minWidth: 150,
            });

            result.push({
                ...keyColumn(
                    "originated",
                    selectColumn({
                        choices: allProcessChartOptions ?? [],
                        mode: "impacted-processes",
                    }),
                ),
                title: getTitle(t(k.ORIGINATED_IN), true),
                minWidth: 150,
            });

            const stepIds: string[] = Object.keys(
                improvementFormSteps?.steps ?? {},
            );

            for (const stepId of stepIds) {
                const activityIds: string[] =
                    improvementFormSteps?.activityIdsByStepId[stepId] ?? [];

                for (const currActivityId of activityIds) {
                    const activity =
                        improvementFormSteps?.activities[currActivityId];

                    if (!activity) {
                        continue;
                    }

                    const {
                        id: activityId,
                        type,
                        label,
                        isRequired,
                        customListId,
                    } = activity;

                    const title = getTitle(label, isRequired);

                    switch (type) {
                        case ActivityType.Tasklist:
                            const tasklistOptions =
                                improvementFormSteps
                                    ?.activityInputsIdsByActivityId[
                                    activityId
                                ] ?? [];

                            tasklistOptions.forEach((tasklistOptionId) => {
                                const activityInput =
                                    improvementFormSteps.activityInputs[
                                        tasklistOptionId
                                    ];

                                if (activityInput) {
                                    result.push({
                                        ...keyColumn(
                                            activityInput.id,
                                            addCheckboxColumn,
                                        ),
                                        title: getTitle(
                                            `${label}: ${activityInput.label}`,
                                            activityInput.isRequired,
                                        ),
                                        minWidth: 150,
                                    });
                                }
                            });

                            break;

                        case ActivityType.CustomList:
                        case ActivityType.Selection:
                            const isCustomList =
                                type === ActivityType.CustomList;
                            const isSelection = type === ActivityType.Selection;

                            const customListOptions =
                                customListPropertyCustomListOptions.find(
                                    (x) =>
                                        x.data?.args.customListId ===
                                        customListId,
                                )?.data?.data;

                            const options = isCustomList
                                ? customListOptions
                                : activity.options;

                            if (isCustomList) {
                                result.push({
                                    ...keyColumn(
                                        activityId,
                                        selectManyColumn({
                                            choices: options ?? [],
                                        }),
                                    ),
                                    title,
                                    minWidth: 150,
                                });
                            } else if (isSelection) {
                                result.push({
                                    ...keyColumn(
                                        activityId,
                                        selectColumn({
                                            choices: options ?? [],
                                        }),
                                    ),
                                    title,
                                    minWidth: 150,
                                });
                            }

                            break;

                        case ActivityType.Customers:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: customerOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        case ActivityType.Suppliers:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: supplierOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        case ActivityType.Items:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: itemOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        case ActivityType.GenerateBarcode:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    generateBarcodeColumn(),
                                ),
                                title,
                                minWidth: 180,
                            });

                            break;

                        case ActivityType.Photo:
                            result.push({
                                ...keyColumn(activityId, addPhotoColumn()),
                                title,
                                minWidth: 200,
                            });

                            break;
                        case ActivityType.DateTime:
                            result.push({
                                ...keyColumn(activityId, addDateTimeColumn),
                                title,
                                minWidth: 190,
                            });

                            break;

                        case ActivityType.Users:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: userOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        case ActivityType.Positions:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: positionOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        case ActivityType.Competencies:
                            result.push({
                                ...keyColumn(
                                    activityId,
                                    selectManyColumn({
                                        choices: competencyOptions ?? [],
                                    }),
                                ),
                                title,
                                minWidth: 150,
                            });

                            break;

                        default:
                            result.push({
                                ...keyColumn(activityId, textColumn),
                                title,
                                minWidth: 150,
                            });

                            break;
                    }
                }
            }

            result.push({
                ...keyColumn(
                    "createdByUser",
                    selectColumn({ choices: userOptionsForImport ?? [] }),
                ),
                title: t(k.CREATED_BY),
                minWidth: 150,
            });

            result.push({
                ...keyColumn("createdAt", addDateTimeColumn),
                title: t(k.CREATED_AT),
                minWidth: 190,
            });

            return result;
        }, [
            i18n.language,
            userOptionsForImport,
            improvementFormSteps,
            customerOptions,
            supplierOptions,
            itemOptions,
            impactGradings,
            allProcessChartOptions,
            customListPropertyCustomListOptions,
        ]);

        if (
            isUserOptionsLoading ||
            isLoadingSteps ||
            isLoadingCustomers ||
            isLoadingSuppliers ||
            isLoadingItems ||
            impactGradings === null ||
            allProcessChartOptions === null ||
            customListPropertyCustomListOptions.some((x) => x.isLoading)
        ) {
            return <AnimatedSpinner position="center" isVisible />;
        }

        return (
            <DataSheetGrid
                ref={ref}
                value={data}
                height={600}
                headerRowHeight={80}
                autoAddRow
                onChange={handleChange}
                createRow={() => getInitialRow()}
                duplicateRow={({ rowData }) => ({
                    ...rowData,
                    id: uuidv4(),
                })}
                rowClassName={({ rowData }) => {
                    if (invalidColumnIdsByRowId.has(rowData.id)) {
                        return "row-invalid";
                    }
                }}
                cellClassName={(args) => {
                    const { columnId } = args;

                    const rowData = args.rowData as { id: string } | undefined;

                    if (columnId && rowData) {
                        const isInvalid = invalidColumnIdsByRowId
                            .get(rowData.id)
                            ?.has(columnId);

                        if (isInvalid) {
                            return "dsg-cell--cell_invalid";
                        }
                    }
                }}
                columns={columns}
            />
        );
    },
);
