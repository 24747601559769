import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";

type EnumDictionary = {
    [key in CustomListPropertyEnum]?: boolean;
};

const isPropertyByDefaultShownInList: EnumDictionary = {
    [CustomListPropertyEnum.Value]: true,
    [CustomListPropertyEnum.Text]: true,
    [CustomListPropertyEnum.Tasklist]: true,
    [CustomListPropertyEnum.DateTime]: true,
    [CustomListPropertyEnum.Barcodes]: true,
    [CustomListPropertyEnum.GenerateBarcode]: true,
    [CustomListPropertyEnum.Hyperlink]: true,
};

const isSortablePropertiesDisabled: EnumDictionary = {
    [CustomListPropertyEnum.None]: true,
    [CustomListPropertyEnum.Tasklist]: true,
    [CustomListPropertyEnum.Photo]: true,
    [CustomListPropertyEnum.Selection]: true,
    [CustomListPropertyEnum.Customers]: true,
    [CustomListPropertyEnum.Suppliers]: true,
    [CustomListPropertyEnum.Items]: true,
    [CustomListPropertyEnum.Barcodes]: true,
    [CustomListPropertyEnum.GenerateBarcode]: true,
    [CustomListPropertyEnum.Hyperlink]: true,
    [CustomListPropertyEnum.Users]: true,
    [CustomListPropertyEnum.Positions]: true,
    [CustomListPropertyEnum.Competencies]: true,
};

const isFilterablePropertiesDisabled: EnumDictionary = {
    [CustomListPropertyEnum.None]: true,
    [CustomListPropertyEnum.Photo]: true,
    [CustomListPropertyEnum.Value]: true,
    [CustomListPropertyEnum.Text]: true,
    [CustomListPropertyEnum.Hyperlink]: true,
};

const isAllowMultipleDisabled: EnumDictionary = {
    [CustomListPropertyEnum.None]: true,
    [CustomListPropertyEnum.Value]: true,
    [CustomListPropertyEnum.Text]: true,
    [CustomListPropertyEnum.Tasklist]: true,
    [CustomListPropertyEnum.DateTime]: true,
    [CustomListPropertyEnum.Photo]: true,
    [CustomListPropertyEnum.Selection]: true,
    [CustomListPropertyEnum.Barcodes]: true,
    [CustomListPropertyEnum.GenerateBarcode]: true,
    [CustomListPropertyEnum.Hyperlink]: true,
};

export {
    isPropertyByDefaultShownInList,
    isSortablePropertiesDisabled,
    isFilterablePropertiesDisabled,
    isAllowMultipleDisabled,
};
