import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { LocaleId } from "AppLocale";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { errorToMessageReducer } from "common/components/validation/yupValidationErrorUtili";
import IDictionary from "common/viewModels/IDictionary";
import {
    scrollToTopErrorById,
    getIdsFromValidationMessages,
} from "components/common/validation/ScrollToError";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import IImprovementFilter from "components/improvements/api/IImprovementFIlter";
import { useCreateImprovementMutation } from "components/improvements/api/hooks";
import NewImprovementContext from "components/improvements/components/NewImprovementListedContent/newImprovementContext";
import IImprovementRequest from "http/requests/IImprovementRequest";
import k from "i18n/keys";
import { IAppState } from "store/IAppState";
import * as yup from "yup";

import { InputValueType } from "../activity-instance-card/ActivityInstanceCardById";
import {
    ModalTypes,
    IShowModalPayload,
} from "../modal-manager/api/IModalManager";
import { DefaultToastOptions } from "../toast/DefaultToast";
import {
    createCustomValidationSchema,
    getInvalidActivities,
} from "./SubmitImprovement.utils";
import SubmitImprovementCard from "./SubmitImprovementCard";

import "./SubmitImprovement.scss";

interface IProps {
    onClose: (onSave?: boolean) => void;
}

function getRequest(
    inputValues: IDictionary<InputValueType>,
    filter: IImprovementFilter | undefined,
    improvement: IImprovementRequest,
    impactGradingsFeature: boolean,
    impactedProcessesFeature: boolean,
) {
    const result: IImprovementRequest = {
        ...improvement,
        impactList: impactGradingsFeature ? improvement.impactList : undefined,
        impactedProcesses: impactedProcessesFeature
            ? improvement.impactedProcesses
            : undefined,
        activityInstanceSetId: filter?.activity,
        taskId: filter?.actionSet,

        values: inputValues,
    };

    return result;
}

const SubmitImprovement: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        editedActivityValues,
        filter,
        editedImprovement,
        steps,
        activities,
        activityIdsByStepId,
        activityInputs,
        activityInputsIdsByActivityIds,
        errors,
        usersToBeNotified,
        setErrors,
    } = useContext(NewImprovementContext);
    const { i18n } = useTranslation();

    const { data: impactAreas } = useImpactGradingOptions(
        i18n.language as LocaleId,
    );

    const impactGradingsFeature = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.impactGradings === true
        );
    });

    const impactedProcessesFeature = useSelector(
        (appState: IAppState) =>
            (appState.authViewState.profile?.appFeatures?.processChart &&
                appState.authViewState.profile?.appFeatures
                    ?.assignImpactedProcessesToImprovement) === true,
    );

    const { t } = useTranslation();

    const modalManagerContext = useContext(ModalManagerContext);

    const location = useLocation();

    const stepId = steps?.ids[0];

    const [invalidActivities, setInvalidActivities] = useState<
        Array<IValueLabelItem<number, string, number>>
    >([]);

    const createImprovementMutatation = useCreateImprovementMutation();

    const validateRequestOnClient = async (
        stepId: string,
        values: IDictionary<InputValueType>,
        data: IImprovementRequest,
    ) => {
        const validationSchema = createCustomValidationSchema({
            stepId,
            activities,
            activityIdsByStepId,
            activityInputs,
            activityInputsIdsByActivityIds,
            impactGradingsFeature,
            impactedProcessesFeature,
            impactAreas,
        });

        try {
            const dataForValidation = Object.entries(values).reduce(
                (acc, [k, v]) => ({
                    ...acc,
                    [k]: v.selectedIds ? "1" : v.value,
                }),
                {},
            );
            const allData = { ...dataForValidation, ...data };

            await validationSchema.validate(allData, { abortEarly: false });

            setErrors({});

            setInvalidActivities([]);

            return true;
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const errorItems = errorToMessageReducer(err);

                setErrors(errorItems);

                const list = getInvalidActivities(
                    errorItems,
                    stepId,
                    activities,
                    activityIdsByStepId,
                    activityInputs,
                    activityInputsIdsByActivityIds,
                );

                setInvalidActivities(list);

                scrollToTopErrorById(getIdsFromValidationMessages(errorItems));
            }
        }

        return false;
    };

    const showRegisteredImprovement = (id?: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.improvement,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnSubmitClick = async () => {
        if (stepId && editedActivityValues && editedImprovement) {
            const data = getRequest(
                editedActivityValues,
                filter,
                editedImprovement,
                impactGradingsFeature,
                impactedProcessesFeature,
            );

            if (
                (await validateRequestOnClient(
                    stepId,
                    editedActivityValues,
                    data,
                )) === false
            ) {
                return;
            }

            const result = await createImprovementMutatation.mutateAsync({
                request: data,
            });

            if (result.Succeeded) {
                setErrors({});

                setInvalidActivities([]);

                toast.dismiss();

                toast.success(
                    t(k.IMPROVEMENT_REGISTERED),
                    DefaultToastOptions({
                        onClick: () =>
                            showRegisteredImprovement(result.Data?.id),
                    }),
                );

                props.onClose(true);
            } else {
                setErrors(result.Messages);

                const list = getInvalidActivities(
                    result.Messages,
                    stepId,
                    activities,
                    activityIdsByStepId,
                    activityInputs,
                    activityInputsIdsByActivityIds,
                );

                setInvalidActivities(list);

                scrollToTopErrorById(
                    getIdsFromValidationMessages(result.Messages),
                );
            }
        }
    };

    const invalid = errors ? Object.keys(errors).length > 0 : false;

    return stepId ? (
        <div className="submit-improvement no-print">
            <SubmitImprovementCard
                invalid={invalid}
                invalidActivities={invalidActivities}
                isSaving={createImprovementMutatation.isLoading}
                onClick={handleOnSubmitClick}
                usersToBeNotified={usersToBeNotified}
            />
        </div>
    ) : null;
};

export default SubmitImprovement;
