import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Modal from "../Modal";
import { IModalComposition } from "../IModalComposition";
import Button, { ButtonVariantTypes } from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";

interface IProps {
    confirmTitle?: string;
    confirmBody?: React.ReactNode;

    confirmBtnVariant?: ButtonVariantTypes;

    confirmBtnLabel?: string;
    confirmCancelBtnLabel?: string;

    onClose: (value: boolean) => void;
}

const Confirm: React.FC<IProps> & Omit<IModalComposition, "Header"> = (
    props,
) => {
    const { confirmBtnVariant } = props;

    const { t } = useTranslation();

    const handleHide = () => {
        props.onClose(false);
    };

    const handleConfirm = () => {
        props.onClose(true);
    };

    const confirmTitle = props.confirmTitle ?? t(k.PLEASE_CONFIRM);

    const confirmBody = props.confirmBody ?? t(k.ARE_YOU_SURE);

    const confirmBtnLabel = props.confirmBtnLabel ?? t(k.CONFIRM);

    const confirmCancelBtnLabel = props.confirmCancelBtnLabel ?? t(k.CANCEL);

    return (
        <Modal show onHide={handleHide} mode="above-all">
            <Modal.Title>{confirmTitle}</Modal.Title>
            <Modal.Body>{confirmBody}</Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant={confirmBtnVariant} onClick={handleConfirm}>
                        {confirmBtnLabel}
                    </Button>

                    <Button variant="gray" onClick={handleHide}>
                        {confirmCancelBtnLabel}
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

Confirm.Title = Modal.Title;
Confirm.Body = Modal.Body;
Confirm.Footer = Modal.Footer;

export default Confirm;
