import React from "react";
import "./QuickTips.scss";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface QuickTipsProps {
    tips: string[];
}

const QuickTips: React.FC<QuickTipsProps> = (props) => {
    const { tips } = props;

    const { t } = useTranslation();
    return (
        <div className="quick-tips">
            <h3 className="quick-tips__title">💡 {t(k.TIPS)}!</h3>
            <ul className="quick-tips__list">
                {tips.map((tip, index) => (
                    <React.Fragment key={index}>
                        <li className="quick-tips__item">{t(tip)}</li>
                        {index !== tips.length - 1 && (
                            <li className="quick-tips__divider" />
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default QuickTips;
