export function mergeStringArraysAndDistinct(
    ...arrays: (string[] | undefined)[]
): string[] {
    const mergedSet = new Set<string>();
    arrays.forEach((array) => {
        if (array) {
            array.forEach((item) => mergedSet.add(item));
        }
    });
    return Array.from(mergedSet);
}
