import "./TextOverflow.scss";

import { PropsWithChildren } from "react";

import classnames from "classnames";

interface IProps {
    className?: string;
    ellipsis?: boolean;
    multiLine?: boolean;
    title?: string;
}

const TextOverflow = ({
    children,
    className,
    ellipsis,
    multiLine,
    title,
}: PropsWithChildren<IProps>) => {
    const _className = classnames("text-overflow", className, {
        ellipsis: ellipsis,
        "multi-line": multiLine,
    });

    const _title =
        title ??
        (ellipsis && typeof children === "string" ? children : undefined);
    return (
        <div title={_title} className={_className}>
            {children}
        </div>
    );
};

export default TextOverflow;
