import "./TooltipMessage.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface ITooltipMessageProps {
    timeUnitKey: string;
    year: number;
    value: React.ReactNode;
    target: React.ReactNode;
}

const TooltipMessage = (props: ITooltipMessageProps) => {
    const { timeUnitKey, year, value, target } = props;

    const { t } = useTranslation();

    return (
        <div className="tooltip-message">
            <div>
                <span className="label">{t(k.VALUE)}:</span> {value}
            </div>
            <div>
                <span className="label">{t(k.TARGET)}:</span> {target}
            </div>
            <div>
                <span className="label">{t(k.DATE)}:</span> {t(timeUnitKey)}{" "}
                {year}
            </div>
        </div>
    );
};

export default TooltipMessage;
