import "./MeasurementStatusBadge.scss";

import k from "i18n/keys";

import React from "react";
import { useTranslation } from "react-i18next";
import Badge from "../Badge";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";
import { measurementStatusLocales } from "components/measurement/utils/MeasurementTools";
import { getLocaleString } from "i18n/components/GetLocaleString";
import DraftBadge from "../draft-badge/DraftBadge";
import { DraftIcon } from "common/components/icons";

interface IProps {
    hideOnTrack?: boolean;
    status: EMeasurementGrading;
    className?: string;
    info?: React.ReactNode;
}

const MeasurementStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status, hideOnTrack, className, info } = props;

    const { t } = useTranslation();

    return status !== EMeasurementGrading.OnTrack || !hideOnTrack ? (
        <Badge
            pill
            className={`measurement-status measurement-status--${
                status + 1
            }  ${className}`}
            rightSideContent={
                info && <span className="measurement-status--info">{info}</span>
            }
            leftSideContent={
                status === EMeasurementGrading.Draft && <DraftIcon />
            }
        >
            {getLocaleString(t, measurementStatusLocales[status])}
        </Badge>
    ) : null;
};

export default MeasurementStatusBadge;
