import paginator from "paginator";

import { IPaginationSettings } from "./IPaginationSettings";

export interface IPagingCalculationResults {
    has_previous_page: boolean;
    previous_page: number;

    has_next_page: boolean;
    next_page: number;

    total_pages: number;

    range_pages_count: number;
    first_page: number;
    last_page: number;

    first_result: number;
    last_result: number;
}

export const calculatePagination = (
    data: IPaginationSettings,
    rangeCount?: number,
): IPagingCalculationResults => {
    const { activePage, pageSize, totalCount } = data;

    const rangePagesCount = rangeCount ?? 5;

    const paginationInfo = new paginator(pageSize, rangePagesCount).build(
        totalCount,
        activePage,
    );

    const total_pages = paginationInfo.total_pages as number;

    let first_result = paginationInfo.first_result as number;
    let last_result = paginationInfo.last_result as number;

    if (total_pages > 0) {
        first_result = first_result + 1;

        last_result = last_result + 1;
    }

    const result: IPagingCalculationResults = {
        has_previous_page: paginationInfo.has_previous_page as boolean,
        previous_page: paginationInfo.previous_page as number,

        has_next_page: paginationInfo.has_next_page as boolean,
        next_page: paginationInfo.next_page as number,

        total_pages,

        range_pages_count: rangePagesCount,
        first_page: paginationInfo.first_page as number,
        last_page: paginationInfo.last_page as number,

        first_result,
        last_result,
    };

    return result;
};
