import "./SourcingPurchaseIcon.scss";

import React from "react";

import Icon from "./assets/sourcing-purchase-icon.svg";

const SourcingPurchaseIcon: React.FC = (props) => {
    return <Icon className="sourcing-purchase-icon" />;
};

export default SourcingPurchaseIcon;
