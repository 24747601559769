import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { ActivityType } from "models/enums/ActivityType";
import Confirm from "../modals/confirm/Confirm";

interface IProps {
    type: ActivityType;

    onClose: (value: boolean) => void;
}

const DeleteInputValueConfirmModal = (props: IProps) => {
    const { type } = props;

    const { t } = useTranslation();

    const isBarcode =
        type === ActivityType.Barcodes || type === ActivityType.GenerateBarcode;

    return isBarcode ? (
        <Confirm
            confirmTitle={t(k.DELETE_THIS_BARCODE_QUESTION)}
            confirmBody={
                <React.Fragment>
                    {type === ActivityType.Barcodes
                        ? t(k.DELETE_THIS_BARCODE_MESSAGE_LINE1)
                        : t(k.DELETE_THIS_GENERATED_BARCODE_MESSAGE_LINE1)}
                    <br />
                    {t(k.DELETE_THIS_BARCODE_MESSAGE_LINE2)}
                </React.Fragment>
            }
            confirmBtnVariant="danger"
            confirmBtnLabel={t(k.DELETE)}
            onClose={props.onClose}
        />
    ) : (
        <Confirm onClose={props.onClose} />
    );
};

export default DeleteInputValueConfirmModal;
