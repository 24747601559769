import React, { ComponentType } from "react";

// https://stackoverflow.com/a/69411742

export const lazyMinLoadTime = <T extends ComponentType<any>>(
    factory: () => Promise<{ default: T }>,
    minLoadTimeMs = 500,
) =>
    React.lazy(() =>
        Promise.all([
            factory(),
            new Promise((resolve) => setTimeout(resolve, minLoadTimeMs)),
        ]).then(([moduleExports]) => moduleExports),
    );
