import "./WebCamModal.scss";

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";

import k from "i18n/keys";
import Modal from "common/components/modals/Modal";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import UploadFile from "../upload/UploadFile";
import { IInputChangeEvent } from "../input-components/IInputProps";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import { ELayoutEnvironment } from "store/uiSettings/reducer";
import Tooltip from "../tooltip/Tooltip";

interface IProps {
    show?: boolean;

    onChange: (e: IInputChangeEvent<string>) => void;
    onClose: () => void;
}

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
};

async function readFromClipboardAsync(
    onChange: (e: IInputChangeEvent<string>) => void,
) {
    if (!("clipboard" in navigator) || !("read" in navigator.clipboard)) {
        window.alert(
            "navigator.clipboard.read is not available in your browser",
        );

        return;
    }

    let clipboardItems: ClipboardItems | undefined;

    try {
        clipboardItems = await navigator.clipboard.read();
    } catch (e) {
        window.alert(e);

        return;
    }

    for (let item of clipboardItems) {
        if (item.types.length > 0) {
            for (let isImage of item.types) {
                if (isImage.includes("image")) {
                    const i = await item.getType("image/png");

                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const file = reader.result?.toString();

                        onChange({
                            value: file ?? "",
                            param: "screenshot.png",
                        });
                    };

                    reader.readAsDataURL(i);
                }
            }
        }
    }
}

const WebCamModal = (props: IProps) => {
    const { show } = props;

    const { t } = useTranslation();

    const layoutEnvironment = useSelector((appState: IAppState) => {
        const { layoutEnvironment } = appState.uiSettingsViewState;

        return layoutEnvironment;
    });

    const [isDisabled, setIsDisabled] = React.useState(true);

    let isClosing = useRef(false);

    const readFromClipboard = () => {
        readFromClipboardAsync(props.onChange);
    };

    useEffect(() => {
        if (show) {
            window.addEventListener("paste", readFromClipboard);

            setIsDisabled(true);

            isClosing.current = false;
        } else {
            window.removeEventListener("paste", readFromClipboard);
        }

        return () => {
            window.removeEventListener("paste", readFromClipboard);
        };
    }, [show]);

    const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);

    const handleCapturePhoto = () => {
        if (webcamRef.current) {
            const screenshot = webcamRef.current.getScreenshot();

            if (screenshot) {
                props.onChange({
                    value: screenshot,
                    param: "screenshot.jpg",
                });
            }
        }
    };

    const onUploadFile = (content: string, fileName: string) => {
        props.onChange({
            value: content,
            param: fileName,
        });
    };

    const handleClose = () => {
        isClosing.current = true;

        props.onClose();
    };

    const onUserMedia = () => {
        setTimeout(() => {
            if (!isClosing.current) {
                setIsDisabled(false);
            }
        }, 300);
    };

    const isTeamsAppEnvironment =
        layoutEnvironment === ELayoutEnvironment.TEAMS;

    return show ? (
        <Modal show onHide={handleClose} size={"xl"} testId="web-cam-modal">
            <Modal.Title>{t(k.PHOTO_PREVIEW)}</Modal.Title>

            <Modal.Body>
                <Webcam
                    className="webcam-preview"
                    audio={false}
                    minScreenshotWidth={1280}
                    minScreenshotHeight={1080}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    ref={webcamRef}
                    onUserMedia={onUserMedia}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Tooltip
                        id={`pasteButtonTooltip`}
                        message={
                            isTeamsAppEnvironment
                                ? t(
                                      k.OPEN_AMPLIFLOW_IN_BROWSER_TO_PASTE_FROM_CLIPBOARD,
                                  )
                                : t(k.PASTE_FROM_CLIPBOARD)
                        }
                    >
                        <Button
                            testId="paste-btn"
                            disabled={isTeamsAppEnvironment}
                            onClick={readFromClipboard}
                        >
                            {t(k.PASTE)}
                        </Button>
                    </Tooltip>

                    <Button
                        testId="capture-photo-btn"
                        disabled={isDisabled}
                        onClick={handleCapturePhoto}
                    >
                        {t(k.CAPTURE_PHOTO)}
                    </Button>

                    <UploadFile
                        testId="upload-file-btn"
                        className="btn btn__blue"
                        onLoad={onUploadFile}
                        onlyImages={true}
                    >
                        {t(k.UPLOAD_PHOTO)}
                    </UploadFile>

                    <Button
                        testId="cancel-btn"
                        variant="gray"
                        onClick={handleClose}
                    >
                        {t(k.CANCEL)}
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    ) : null;
};

export default WebCamModal;
