import "./CopyCommand.scss";

import React, { useRef } from "react";

interface IProps {
    text: string;
}

export interface ICopyCommand {
    copy: () => void;
}

export default React.forwardRef<ICopyCommand, IProps>((props, ref) => {
    const { text } = props;

    const copyCommandSupported = document.queryCommandSupported("copy");

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    React.useImperativeHandle(ref, () => ({
        copy() {
            textAreaRef.current?.select();

            document.execCommand("copy");
        },
    }));

    if (copyCommandSupported) {
        return (
            <textarea
                className="copy-command--hidden-value"
                ref={textAreaRef}
                value={text}
                readOnly
            />
        );
    }

    return null;
});
