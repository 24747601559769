import React from "react";

import Badge from "common/components/badges/Badge";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";

interface IProps {
    id?: string;
    isRequired?: boolean;
    hasError?: boolean;
}

const ActivityDetails: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { hasError, isRequired, id } = props;
    const { t } = useTranslation();
    if (isRequired || hasError) {
        return (
            <div className="step-activity-details" id={id}>
                {isRequired && <Badge variant="warning">{t(k.REQUIRED)}</Badge>}

                {hasError && <Badge variant="danger">{t(k.ERROR)}</Badge>}
            </div>
        );
    } else {
        return null;
    }
};

export default ActivityDetails;
