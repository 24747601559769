import { Action } from "redux";

export const SHOW_IMAGE_PREVIEW = "SHOW_IMAGE_PREVIEW";
export const SHOW_IMAGE_PREVIEW_BY_ID = "SHOW_IMAGE_PREVIEW_BY_ID";
export const HIDE_IMAGE_PREVIEW = "HIDE_IMAGE_PREVIEW";

interface IShowAction extends Action<typeof SHOW_IMAGE_PREVIEW> {
    payload: string;
}

interface IShowByIdAction extends Action<typeof SHOW_IMAGE_PREVIEW_BY_ID> {
    payload: string;
}

interface IHideAction extends Action<typeof HIDE_IMAGE_PREVIEW> {}

export type ImagesActionTypes = IShowAction | IShowByIdAction | IHideAction;
