import { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    IInputChangeEvent,
    IInputProps,
} from "common/components/input-components/IInputProps";
import IActivityInstanceRequest from "http/requests/IActivityInstanceRequest";

import { SelectUsersByActivityInstanceId } from "common/components/select-users";
import IStepAvailableUsersDTO from "models/dto/IStepAvailableUsersDTO";
import { ActivityType } from "models/enums/ActivityType";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import {
    useChecklistById,
    useStepInstancesByChecklistId,
} from "components/checklists/api/hooks";

import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import ActivityInstanceCard from "./ActivityInstanceCard";
import omit from "lodash/omit";
import without from "lodash/without";
import {
    onMutationActivityInstanceAddOrUpdateOnError,
    onMutationActivityInstanceAddOrUpdateOnMutate,
    onMutationActivityInstanceAddOrUpdateOnSuccess,
    onMutationActivityInstanceDeleteError,
    onMutationActivityInstanceDeleteMutate,
} from "./api/mutationHandlers";
import IDictionary from "common/viewModels/IDictionary";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import ChecklistDetailsContext from "components/checklists/components/ChecklistListedContent/checklistContext";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import { IImpactedProcessesDto } from "components/improvements/api/IImpactedProcessesDto";
import {
    addActivityInstanceAsync,
    deleteActivityInstanceById,
    saveActivityInstanceAsync,
    updateActivityInstanceAsync,
} from "./api/mutations";
import IValidationMessages from "common/viewModels/IValidationMessages";
import IUserDefinedActivityInstanceRequest from "http/requests/IUserDefinedActivityInstanceRequest";
import IActivityInstance from "models/IActivityInstance";

interface IProps {
    id: string;
    isFirst?: boolean;
    isSetCompleted?: boolean;
    acceptMultiple?: boolean;
    multiSelectEnabled?: boolean;
    preview?: boolean;
    isHighlighted?: boolean;
    availableUsers?: IStepAvailableUsersDTO;
    isImprovement?: boolean;
    invalidByActivityInstanceId: Record<string, boolean | undefined>;

    onDraftEnd?: (isSave?: boolean) => void;

    onConfirmFinalize: () => void;
    onNewImprovement: (forName?: string) => void;
}

export type InputValueType = Pick<
    IInputProps<string>,
    "value" | "selectedIds" | "completed"
> & {
    type?: ActivityType;
    updatedAt?: Date;
    impactList?: IDictionary<ImpactGradingEnum>;
    impactedProcesses?: IImpactedProcessesDto;
};

const ActivityInstanceCardById = (props: IProps) => {
    const {
        id,
        isFirst,
        isSetCompleted,
        acceptMultiple,
        preview,
        availableUsers,
        isHighlighted,
        isImprovement,
        invalidByActivityInstanceId,
        multiSelectEnabled,
    } = props;

    const {
        checklist,
        improvement,
        activityInstances,
        stepInstances,
        activityInstanceSets,
        activityInstanceIdsBySet,
        setActivityInstances,
        setActivityInstanceIdsBySet,
        checkCanSeeAll,
        usersAffected,
        errors,
        setErrors,
        refetchActivities,
        profile,
    } = isImprovement
        ? useContext(ImprovementsDetailsContext)
        : useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const activityInstance = activityInstances?.values[id];
    const isCreate = activityInstance?.isCreate;

    const activityInstanceSet = activityInstance?.activityInstanceSetId
        ? activityInstanceSets?.values[activityInstance.activityInstanceSetId]
        : undefined;
    const stepInstance = activityInstanceSet?.stepInstanceId
        ? stepInstances?.values[activityInstanceSet.stepInstanceId]
        : undefined;

    const [instance, setInstance] = useState<IActivityInstance>();

    const queryClient = useQueryClient();

    const addActivityInstanceMutation = useMutation(addActivityInstanceAsync, {
        onMutate(variables) {
            return onMutationActivityInstanceAddOrUpdateOnMutate(
                queryClient,
                instance,
                variables,
            );
        },
        onError(_, variables, context) {
            onMutationActivityInstanceAddOrUpdateOnError(
                queryClient,
                isCreate ?? false,
                variables,
                context,
            );
        },
        onSuccess: (data, variables, context) => {
            onMutationActivityInstanceAddOrUpdateOnSuccess(
                queryClient,
                isCreate ?? false,
                variables,
                data.data,
                context,
            );
        },
    });

    const updateActivityInstanceMutation = useMutation(
        updateActivityInstanceAsync,
        {
            onMutate(variables) {
                return onMutationActivityInstanceAddOrUpdateOnMutate(
                    queryClient,
                    instance,
                    variables,
                );
            },
            onError(_, variables, context) {
                onMutationActivityInstanceAddOrUpdateOnError(
                    queryClient,
                    isCreate ?? false,
                    variables,
                    context,
                );
            },
            onSuccess: (data, variables, context) => {
                onMutationActivityInstanceAddOrUpdateOnSuccess(
                    queryClient,
                    isCreate ?? false,
                    variables,
                    //data.data,
                    undefined,
                    context,
                );
            },
        },
    );

    const deleteActivityInstanceMutation = useMutation(
        deleteActivityInstanceById,
        {
            onMutate: (id) => {
                return onMutationActivityInstanceDeleteMutate(
                    queryClient,
                    id,
                    isImprovement ?? false,
                    isImprovement ? improvement?.id : checklist?.id,
                );
            },
            onError: (_, id, context) => {
                onMutationActivityInstanceDeleteError(
                    queryClient,
                    isImprovement ?? false,
                    isImprovement ? improvement?.id : checklist?.id,
                    context,
                );
            },
        },
    );

    const saveActivityInstanceMutation = useMutation(
        saveActivityInstanceAsync,
        {
            onError: (error: AxiosError<{ errors: IValidationMessages }>) => {
                if (
                    error.response?.data &&
                    typeof error.response.data === "object"
                ) {
                    setErrors((prev) => ({
                        ...prev,
                        [id]: error.response?.data.errors._error,
                    }));
                } else {
                    setErrors((prev) => ({
                        ...prev,
                        [id]: String(error.response?.data),
                    }));
                }
            },
        },
    );

    const { refetch: refetchStepInstancesByChecklistId } =
        useStepInstancesByChecklistId(false, checkCanSeeAll, checklist?.id);

    const { refetch: refetchChecklistById } = useChecklistById(
        false,
        checkCanSeeAll,
        checklist?.id ?? "",
    );

    const [values, setValues] = useState<InputValueType>({
        value: activityInstance?.value ?? "",
        selectedIds: activityInstance?.selectedIds,
        completed: activityInstance?.completed,
        updatedAt: activityInstance?.updatedAt,
    });

    const updatedAt = activityInstance?.updatedAt;
    const timestamp = updatedAt?.getTime();

    useEffect(() => {
        if (
            activityInstance &&
            activityInstance.updatedAt &&
            (values.updatedAt === undefined ||
                activityInstance.updatedAt > values.updatedAt)
        ) {
            setValues({
                value: activityInstance.value ?? "",
                selectedIds: activityInstance.selectedIds,
                updatedAt: activityInstance.updatedAt,
                completed: activityInstance.completed,
            });
        }
    }, [timestamp]);

    const [askToFinalizeOnBlur, setAskToFinalizeOnBlur] = useState(false);

    const handleAddActivityInstance = (
        tempId?: string,
        newLabel?: string,
        isCanceled?: boolean,
    ) => {
        if (activityInstance === undefined) {
            return;
        }

        const nextLabel = newLabel?.trim() ?? activityInstance.label;

        if (nextLabel) {
            const nextActivityInstance: IActivityInstance = {
                ...activityInstance,
                label: nextLabel,
                edit: false,
                isCreate: false,
            };

            setActivityInstances?.((prevState) =>
                prevState
                    ? {
                          ...prevState,
                          values: {
                              ...prevState?.values,
                              [id]: {
                                  ...nextActivityInstance,
                              },
                          },
                      }
                    : prevState,
            );

            setInstance({ ...nextActivityInstance, isCreate });

            const request: IUserDefinedActivityInstanceRequest = {
                id: nextActivityInstance.id,
                activityInstanceSetId:
                    nextActivityInstance.activityInstanceSetId,
                label: nextActivityInstance.label,
                parentId: isImprovement ? improvement?.id : checklist?.id,
                isImprovement: isImprovement,
                activityInstanceIdsBySet:
                    activityInstanceIdsBySet?.[
                        activityInstance.activityInstanceSetId
                    ],
            };

            if (isCreate) {
                addActivityInstanceMutation.mutate(request);
            } else {
                updateActivityInstanceMutation.mutate(request);
            }

            setErrors((prev) => omit(prev, id));

            if (isCanceled || !isCreate) {
                props.onDraftEnd?.(false);
            } else {
                props.onDraftEnd?.(true);
            }
        } else {
            setErrors((prev) => ({
                ...prev,
                [id]: "Label is required.",
            }));
        }
    };

    const handleCancelAddActivityInstance = (remove?: boolean) => {
        if (!activityInstance) {
            return;
        }

        const shouldRemove = remove || (activityInstance.edit && isCreate);

        setActivityInstances?.((prevState) =>
            prevState
                ? {
                      ids: shouldRemove
                          ? prevState?.ids.filter((item) => item !== id)
                          : prevState.ids,
                      values: shouldRemove
                          ? omit(prevState?.values, [id])
                          : {
                                ...prevState?.values,
                                [id]: {
                                    ...prevState.values[id],
                                    edit: !prevState.values?.[id]?.edit,
                                },
                            },
                  }
                : prevState,
        );

        setActivityInstanceIdsBySet?.((prevState) => ({
            ...prevState,
            [activityInstance.activityInstanceSetId]: shouldRemove
                ? without(
                      prevState?.[activityInstance.activityInstanceSetId],
                      id,
                  )
                : [
                      ...(prevState?.[activityInstance.activityInstanceSetId] ||
                          []),
                  ],
        }));

        setErrors((prev) => omit(prev, id));

        if (!shouldRemove) {
            handleAddActivityInstance(undefined, undefined, true); // update order
        }

        props.onDraftEnd?.(false);
    };

    const handleDeleteAddActivityInstance = () => {
        if (!activityInstance) {
            return;
        }

        handleCancelAddActivityInstance(true);

        deleteActivityInstanceMutation.mutate(activityInstance.id);
    };

    const handleOnEditClick = (editValue?: boolean) => {
        setActivityInstances?.((prevState) => {
            if (prevState) {
                const activityInstance = prevState.values[id];

                if (activityInstance) {
                    return {
                        ...prevState,
                        values: {
                            ...prevState.values,
                            [id]: {
                                ...activityInstance,
                                edit: editValue ?? activityInstance.edit,
                            },
                        },
                    };
                }
            }

            return prevState;
        });
    };

    const handleChange = (e: IInputChangeEvent<string>) => {
        setValues((prev) => ({
            ...prev,
            value: e.value,
            selectedIds: e.selectedIds,
        }));

        setAskToFinalizeOnBlur(false);

        if (e.invalid) {
            updateErrors(t(k.INVALID));

            return;
        } else {
            updateErrors(undefined);
        }

        const isReset: boolean =
            Boolean(e.value) === false && (e.selectedIds?.length ?? 0) === 0;

        const activityInstanceRequest: IActivityInstanceRequest = {
            value: e.value,
            selectedIds: e.selectedIds,
            fileName: e.param,
            isReset,
            activityInstanceId: id,
            type: isImprovement
                ? TaskOriginType.Improvement
                : TaskOriginType.Checklist,
        };

        saveActivityInstanceMutation.mutate(activityInstanceRequest, {
            onSettled: (data) => {
                if (data) {
                    setValues((prev) => ({
                        ...prev,

                        value: data.updatedValue?.value ?? "",
                        selectedIds: data.updatedValue?.selectedIds,
                        completed: data.updatedValue?.completed,
                        updatedAt: data.updatedValue?.updatedAt,
                    }));

                    if (!isImprovement) {
                        refetchStepInstancesByChecklistId();
                    }

                    if (data.stepCompleted && !isImprovement) {
                        if (e.withDelay) {
                            setAskToFinalizeOnBlur(true);
                        } else {
                            props.onConfirmFinalize();
                        }
                    }

                    refetchActivities?.();
                } else if (!isImprovement) {
                    refetchChecklistById();
                }
            },
        });
    };

    const updateErrors = (newError?: string) => {
        setErrors((prev) => {
            const result = {
                ...prev,
                [id]: newError,
            };

            if (activityInstance?.activityInstanceSetId) {
                delete result[activityInstance.activityInstanceSetId];
            }

            return result;
        });
    };

    const handleOnBlur = () => {
        if (askToFinalizeOnBlur) {
            props.onConfirmFinalize();

            setAskToFinalizeOnBlur(false);
        }
    };

    const handleUndo = () => {
        setValues((prev) => ({
            ...prev,

            value: "",
            selectedIds: [],
            completed: undefined,
        }));

        const workRequest: IActivityInstanceRequest = {
            isReset: true,
            activityInstanceId: id,
            type: isImprovement
                ? TaskOriginType.Improvement
                : TaskOriginType.Checklist,
        };

        saveActivityInstanceMutation.mutateAsync(workRequest, {
            onSettled: (data) => {
                if (data) {
                    setValues((prev) => ({
                        ...prev,

                        value: data.updatedValue?.value ?? "",
                        selectedIds: data.updatedValue?.selectedIds,
                        completed: data.updatedValue?.completed,
                        updatedAt: data.updatedValue?.updatedAt,
                    }));

                    if (!isImprovement) {
                        refetchStepInstancesByChecklistId();
                    }
                } else if (!isImprovement) {
                    refetchChecklistById();
                }

                refetchActivities?.();
            },
        });
    };

    const handleNewDeviationClick = () => {
        if (!activityInstance) {
            return;
        }
        props.onNewImprovement(
            activityInstance.type === ActivityType.Tasklist
                ? activityInstance.label
                : undefined,
        );
    };

    const isStopped = checklist?.status === ChecklistDynamicStatus.Stopped;
    const isArchived = checklist?.status === ChecklistDynamicStatus.Archived;

    // TO DO
    const isFinialized = isImprovement
        ? false
        : stepInstance?.status === StepInstanceStatus.Finalized;
    const isBlocked = stepInstance?.status === StepInstanceStatus.Blocked;

    const disabled =
        preview ||
        isStopped ||
        isArchived ||
        isBlocked ||
        isFinialized ||
        (acceptMultiple && isSetCompleted);

    const hideSelectUsers =
        preview ||
        isStopped ||
        isArchived ||
        isFinialized ||
        (isImprovement && activityInstance?.type !== ActivityType.Tasklist) ||
        (acceptMultiple && isSetCompleted);

    const activityInstanceErrors =
        errors?.[id] ??
        (activityInstance && errors?.[activityInstance.activityInstanceSetId]);
    const shouldPreventClickEventPropagation =
        activityInstance?.type === ActivityType.Text;

    return activityInstance ? (
        <ActivityInstanceCard
            id={id}
            isFirst={isFirst}
            acceptMultiple={acceptMultiple}
            multiSelectEnabled={multiSelectEnabled}
            hasImage={activityInstance.hasImage}
            photoId={activityInstance.uploadedFileId}
            value={values.value}
            selectedIds={values.selectedIds}
            onChange={handleChange}
            onBlur={handleOnBlur}
            type={activityInstance.type}
            invalid={
                invalidByActivityInstanceId[id] ??
                Boolean(activityInstanceErrors)
            }
            error={activityInstanceErrors}
            disabled={disabled}
            isFinalized={isFinialized}
            label={activityInstance.label}
            textLabel={activityInstance.label}
            placeholder={activityInstance.placeholder}
            options={activityInstance.options}
            isSetCompleted={isSetCompleted}
            completed={values.completed}
            completedBy={activityInstance.updatedBy}
            completedAt={values.updatedAt}
            required={activityInstance.isRequired}
            isHighlighted={isHighlighted}
            assignedUsers={activityInstance.assignedUsers}
            usersAffected={usersAffected && usersAffected[id]}
            onUndo={handleUndo}
            onNewDeviationClick={handleNewDeviationClick}
            edit={activityInstance.edit}
            onAddNewActivity={handleAddActivityInstance}
            onCancelAddActivityInstance={handleCancelAddActivityInstance}
            onDeleteActivityInstance={handleDeleteAddActivityInstance}
            isUserDefined={
                activityInstance.isUserDefined &&
                profile?.appFeatures?.userDefinedTasks
            }
            isCreate={isCreate}
            onEditClick={handleOnEditClick}
            isLoading={
                isCreate &&
                (addActivityInstanceMutation.isLoading ||
                    addActivityInstanceMutation.isSuccess)
            }
            assignUsers={
                hideSelectUsers ? undefined : (
                    <SelectUsersByActivityInstanceId
                        checklistId={checklist?.id}
                        improvementId={improvement?.id}
                        checkIfAdmin={checkCanSeeAll}
                        activityInstanceId={activityInstance.id}
                        selectedUserIds={activityInstance.assignedUserIds}
                        availableUsers={availableUsers}
                        disabled={disabled}
                        isImprovement={isImprovement}
                        shouldPreventClickEventPropagation={
                            shouldPreventClickEventPropagation
                        }
                    />
                )
            }
            originType={
                isImprovement ? TaskOriginType.Improvement : TaskOriginType.None
            }
        />
    ) : null;
};

export default ActivityInstanceCardById;
