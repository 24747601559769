import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ChecklistDetailsContext from "../../../../../checklistContext";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import "./Title.scss";

interface Props {}

const Title: FC<Props> = () => {
    const {
        editedChecklistValues,
        setEditedChecklistValues,
        onHaveChanges,
        checklist,
        editMode,
    } = useContext(ChecklistDetailsContext);
    const { t } = useTranslation();

    const handleEditValues = (e: IInputChangeEvent<string>) => {
        setEditedChecklistValues((state) => ({ ...state, title: e.value }));

        onHaveChanges(true);
    };

    return editMode ? (
        <TextfieldComplex
            id="title"
            value={editedChecklistValues?.title ?? checklist?.title ?? ""}
            onChange={handleEditValues}
            innerLabelContent={t(k.NAME)}
            hideIcon
            size="large"
            bold
            background="dark"
        />
    ) : (
        <div className="side-panel__details__title">{checklist?.title}</div>
    );
};

export default Title;
