import ListingMultipleImprovementsById from "common/components/modal-manager-content/list-multiple-improvements-by-id/ListMultipleImprovementsById";
import { useContext } from "react";
import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const ListingMultipleImprovementsModal: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const {
        modalItem: { callId, modalFor, options },
    } = props;

    const id = props.modalItem.id ?? modalFor?.id;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = () => {
        modalManagerContext.onCloseModal({ callId });
    };

    return (
        <ListingMultipleImprovementsById
            id={id}
            onClose={handleOnClose}
            name={options ? options.name : ""}
            showImprovementsForDetails={options?.impFilter}
        />
    );
};

export default ListingMultipleImprovementsModal;
