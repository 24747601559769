import "./UploadCloudIcon.scss";

import { FiUploadCloud } from "react-icons/fi";

interface IProps {
    disabled?: boolean;
}

const UploadCloudIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <FiUploadCloud
            className={
                props.disabled
                    ? "icon-upload-cloud icon-disabled"
                    : "icon-upload-cloud"
            }
        />
    );
};

export default UploadCloudIcon;
