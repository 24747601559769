import { FC } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import "./CompletedAtTime.scss";
import FormattedDate from "../formattedDate/FormattedDate";

interface IProps {
    completedAt?: Date;
}

const CompletedAtTime: FC<IProps> = (props) => {
    const { completedAt } = props;

    const { t } = useTranslation();

    return completedAt ? (
        <div className="completed-at">
            <span className="completed-at--label">{t(k.COMPLETED_AT)}:</span>
            <div className="completed-at--wrapper">
                <FormattedDate date={completedAt} />
            </div>
        </div>
    ) : null;
};

export default CompletedAtTime;
