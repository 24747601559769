import IconButton from "common/components/buttons/icon-button/IconButton";
import { EditIcon } from "common/components/icons";
import React, { useContext } from "react";
import ChecklistDetailsContext from "../../../../../checklistContext";

interface IEditButtonProps {}

const EditButton: React.FC<IEditButtonProps> = (props) => {
    const { setEditMode, editMode, canEdit } = useContext(
        ChecklistDetailsContext,
    );

    const onClick = () => {
        setEditMode(true);
    };

    return (
        <IconButton
            onClick={onClick}
            background="dark"
            disabled={editMode || !canEdit}
            testId="editBtn"
        >
            <EditIcon variant="white" />
        </IconButton>
    );
};

export default EditButton;
