import { PropsWithRef, ReactNode } from "react";

import "./StepCard.scss";

interface IProps {
    id?: string;
    testId?: string;

    stepRef?: React.RefObject<HTMLDivElement>;

    invalid?: boolean;
    dataId?: string;

    titleContent: ReactNode;
    headerDetailsContent?: ReactNode;
    bodyContent?: ReactNode;
    footerContent?: ReactNode;
}

const StepCardWrapper = (props: PropsWithRef<IProps>) => {
    const {
        id,
        testId,
        dataId,
        invalid,
        titleContent,
        headerDetailsContent,
        bodyContent,
        footerContent,
    } = props;
    return (
        <div
            className={"step-card--wrapper" + (invalid ? " invalid" : "")}
            ref={props.stepRef}
            data-id={dataId ?? id}
            data-testid={testId}
            id={id}
        >
            <div className="step-card--header">
                <div className="step-card--header-title">{titleContent}</div>
                {headerDetailsContent && (
                    <div className="step-card--header-details">
                        {headerDetailsContent}
                    </div>
                )}
            </div>
            <div
                className={
                    "step-card--body" + (footerContent ? " with-footer" : "")
                }
            >
                {bodyContent}
            </div>
            {footerContent && (
                <div className="step-card--footer">{footerContent}</div>
            )}
        </div>
    );
};

export default StepCardWrapper;
