import "./SidePanel.scss";

import React, {
    FC,
    RefObject,
    useState,
    useRef,
    useEffect,
    useContext,
    Context,
} from "react";

import {
    Header,
    Details,
    List,
    IHeaderInputProps,
    IDetailsInputProps,
    IListInputProps,
} from "./index";

import { ListedContentContextType } from "../../ListedContentContext";

import classNames from "classnames";

interface ISidePanelProps<C extends ListedContentContextType> {
    context: Context<C>;
    sidePanelRef?: RefObject<HTMLDivElement>;
    className?: string;
    testId?: string;
}

export type ISidePanelInputProps<C extends ListedContentContextType> = FC<
    React.PropsWithChildren<ISidePanelProps<C>>
> & {
    Header: FC<React.PropsWithChildren<IHeaderInputProps>>;
    Details: FC<React.PropsWithChildren<IDetailsInputProps<C>>>;
    List: FC<React.PropsWithChildren<IListInputProps<C>>>;
};

const SidePanel = <C extends ListedContentContextType>(
    props: React.PropsWithChildren<ISidePanelProps<C>>,
) => {
    const { context, children, className, testId } = props;
    const { mobileView, sidePanelRef, listedContentRef } = useContext(context);

    const [hasScrollbar, setHasScrollbar] = useState<boolean>();

    const [refsLoaded, setRefsLoaded] = useState(false);

    const ref = mobileView ? listedContentRef : sidePanelRef;

    useEffect(() => {
        if (!refsLoaded && ref?.current) {
            setRefsLoaded(true);
        }
    });

    useEffect(() => {
        if (refsLoaded) {
            setRefsLoaded(false);
        }
    }, [mobileView]);

    useEffect(() => {
        const checkForScrollbar = () => {
            const current = ref?.current;
            if (current) {
                const hasScroll = current.scrollHeight > current.clientHeight;
                setHasScrollbar(hasScroll);
            }
        };

        const updateStickyPositions = () => {
            const current = ref?.current;
            if (current) {
                let accumulatedHeight = 0;

                const stickyElements = current.querySelectorAll<HTMLElement>(
                    ".side-panel--sticky",
                );

                stickyElements.forEach((element, index) => {
                    element.style.top = `${accumulatedHeight}px`;

                    accumulatedHeight += Math.floor(
                        element.getBoundingClientRect().height,
                    );
                });
            }
        };

        checkForScrollbar();
        updateStickyPositions();

        const resizeObserver = new ResizeObserver((entries) => {
            checkForScrollbar();
            updateStickyPositions();
        });

        const currentElement = ref?.current;

        if (currentElement) {
            resizeObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                resizeObserver.unobserve(currentElement);
            }
        };
    }, [refsLoaded]);

    if (mobileView) {
        return <>{children}</>;
    }

    const classes = classNames("listed-content__side-panel", {
        [`${className}`]: !!className,
        "content-loaded": hasScrollbar !== undefined,
        "has-scrollbar": hasScrollbar,
    });

    return (
        <aside ref={ref} className={classes} data-testid={testId}>
            {children}
        </aside>
    );
};

SidePanel.Header = Header;
SidePanel.Details = Details;
SidePanel.List = List;

export default SidePanel;
