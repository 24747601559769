import React from "react";
import { matchPath, NavLink, PathPattern } from "react-router-dom";

import IMenuNodeIsActive from "./IMenuNodeIsActive";

export interface IMenuNodeLinkProps extends IMenuNodeIsActive {}

const isMatchingPaths = (patterns: PathPattern<string>[]) => {
    return (
        patterns.find((pattern) => matchPath(pattern, location.pathname)) !==
        undefined
    );
};

const MenuNodeLink: React.FC<React.PropsWithChildren<IMenuNodeLinkProps>> = (
    props,
) => {
    const { className, to, exact, paths, menuOptions } = props;

    const handleClick = (e: any) => {
        e.preventDefault();

        if (props.onSelect) {
            props.onSelect(to ?? "/");
        }
    };

    const getClassName = () => {
        let isActive = false;

        if (paths) {
            isActive = isMatchingPaths(
                paths.map((x) => ({
                    path: x,
                    end: false,
                })),
            );
        }

        if (isActive === false && menuOptions) {
            isActive = isMatchingPaths(
                menuOptions.map((x) => ({
                    path: x.value,
                    end: false,
                })),
            );
        }

        if (isActive) {
            return className ? `${className} active` : "active";
        }

        return className;
    };

    const shouldCheckIfActive = Boolean(menuOptions) || Boolean(paths);

    if (shouldCheckIfActive) {
        return (
            <NavLink
                className={getClassName}
                to={to ?? "/"}
                onClick={handleClick}
            >
                {props.children}
            </NavLink>
        );
    }

    return (
        <NavLink
            end={exact}
            className={className}
            to={to ?? "/"}
            onClick={handleClick}
        >
            {props.children}
        </NavLink>
    );
};

export default MenuNodeLink;
