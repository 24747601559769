import "./TabButton.scss";

import React, { useMemo } from "react";

interface IProps<T> {
    id: T;
    selected: boolean;

    label?: string;
    icon?: React.ReactNode;
    variant?: "";
    disabled?: boolean;

    onClick: (id: T) => void;
}

const TabButton = <T,>(props: IProps<T>) => {
    const { id, label, icon, variant, disabled, selected } = props;

    const handleOnClick = () => {
        if (!disabled) {
            props.onClick(id);
        }
    };

    const className = useMemo(() => {
        const result = ["tab-button"];

        if (selected) {
            result.push("tab-button--selected");
        }

        if (disabled) {
            result.push("tab-button--disabled");
        }

        return result.join(" ");
    }, [selected, disabled]);

    return (
        <div className={className} onClick={handleOnClick}>
            {icon}
            {label}
        </div>
    );
};

export default TabButton;
