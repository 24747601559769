import "./Photofield.scss";

import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IInputProps, IInputChangeEvent, IWithDebounce } from "../IInputProps";
import UploadFile from "common/components/upload/UploadFile";
import ImagePreview from "common/components/images/image-preview/ImagePreview";
import ImageCard from "common/components/images/image-preview/ImageCard";
import WebCamModal from "common/components/web-cam-modal/WebCamModal";
import { PhotoIcon } from "common/components/icons";
import UploadCloudIcon from "common/components/icons/icon-upload-cloud/UploadCloudIcon";
import { showImage, showImageById } from "store/images/actions";
import PreviewIcon from "common/components/icons/icon-preview/PreviewIcon";

type Props<V> = IInputProps<string> & IWithDebounce;

const Photofield = <V,>(props: Props<V>) => {
    const {
        id,
        photoId,
        disabled,
        preview,
        invalid,
        value,
        hasImage,
        wrapperClassName,
        completedAt,
    } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isWebCamVisible, setIsWebCamVisible] = useState(false);

    const isDisabled = disabled || preview;

    const imgId = hasImage && photoId ? photoId : undefined;

    const showPreview = value || imgId;

    const handleOnLoad = (content: string, fileName: string) => {
        props.onChange?.({
            id,
            value: content,
            param: fileName,
        });
    };

    const handleShowWebCamModal = () => {
        setIsWebCamVisible(true);
    };

    const handleOnWebCamChange = (e: IInputChangeEvent<string>) => {
        setIsWebCamVisible(false);

        props.onChange?.({
            ...e,
            id,
        });
    };

    const handleOnWebCamClose = () => {
        setIsWebCamVisible(false);
    };

    const handleOnShowPreview = () => {
        if (value) {
            dispatch<any>(showImage(value));
        } else if (imgId) {
            dispatch<any>(showImageById(imgId));
        }
    };

    const className = useMemo(() => {
        const result = ["photo-field__wrapper"];

        if (invalid) {
            result.push("photo-field__wrapper--invalid");
        }

        if (wrapperClassName) {
            result.push(wrapperClassName);
        }

        return result.join(" ");
    }, [invalid, wrapperClassName]);

    return (
        <div className={className}>
            {isDisabled ? (
                showPreview ? (
                    <ImagePreview
                        value={value}
                        imgId={imgId}
                        completedAt={completedAt}
                    />
                ) : (
                    <div className="photo-field__input">
                        <PhotoIcon disabled={true} />
                    </div>
                )
            ) : (
                <React.Fragment>
                    <WebCamModal
                        show={isWebCamVisible}
                        onChange={handleOnWebCamChange}
                        onClose={handleOnWebCamClose}
                    />

                    {showPreview ? (
                        <div className="photo-field--image-card-selector photo-field--large">
                            <ImageCard
                                value={value}
                                imgId={imgId}
                                completedAt={completedAt}
                            >
                                <div
                                    data-testid="photo-field-preview-btn"
                                    className="photo-field__input photo-field--large photo-field__input-active"
                                    onClick={handleOnShowPreview}
                                >
                                    <PreviewIcon />
                                    {t(k.PREVIEW)}
                                </div>
                                <div
                                    data-testid="photo-field-replace-btn"
                                    className="photo-field__input photo-field--large photo-field__input-active"
                                    onClick={handleShowWebCamModal}
                                >
                                    <UploadCloudIcon />
                                    {t(k.REPLACE)}
                                </div>
                            </ImageCard>

                            {props.clearControl && (
                                <div className="photo-field--clear">
                                    {props.clearControl}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            data-testid="photo-field-add-btn"
                            className="photo-field__input photo-field--large photo-field__input-active"
                            onClick={handleShowWebCamModal}
                        >
                            <PhotoIcon />
                        </div>
                    )}

                    <div className="photo-field--image-card-selector photo-field--small">
                        <UploadFile
                            className={
                                showPreview ? undefined : "photo-field__input"
                            }
                            onLoad={handleOnLoad}
                            onlyImages={true}
                        >
                            {showPreview ? (
                                <ImageCard
                                    value={value}
                                    imgId={imgId}
                                    completedAt={completedAt}
                                />
                            ) : (
                                <PhotoIcon />
                            )}
                        </UploadFile>

                        {showPreview && props.clearControl && (
                            <div className="photo-field--clear">
                                {props.clearControl}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default Photofield;
