import "./SortableBasicListValues.scss";

import React from "react";
import { SortEnd } from "react-sortable-hoc";
import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { SortableList, SortableListItem } from "./components/SortableList";
import { arrayMoveTo } from "common/utils/arrayUtils";
import Button from "common/components/buttons/Button";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValidationMessages from "common/viewModels/IValidationMessages";

interface IProps {
    disabled: boolean;

    values: IValueLabelItem<string, string>[];

    errors: IValidationMessages;

    onAdd: () => void;

    onChange: (values: IValueLabelItem<string, string>[]) => void;
}

const SortableBasicListValues: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { disabled, values, errors } = props;

    const { t } = useTranslation();

    const handleDelete = (id: string) => {
        props.onChange(values.filter((x) => x.value !== id));
    };

    const handleChange = (id: string, label: string) => {
        props.onChange(
            values.map((x) => (x.value === id ? { ...x, label } : x)),
        );
    };

    const handleListSorted = (params: SortEnd) => {
        props.onChange(arrayMoveTo(values, params.oldIndex, params.newIndex));
    };

    return (
        <React.Fragment>
            {values.length > 0 ? (
                <SortableList useDragHandle onSortEnd={handleListSorted}>
                    {values.map((item, i) => (
                        <SortableListItem
                            key={item.value}
                            index={i}
                            disabled={disabled}
                            isDisabled={disabled}
                            value={item}
                            errors={errors}
                            onDelete={handleDelete}
                            onChange={handleChange}
                        />
                    ))}
                </SortableList>
            ) : (
                <p className="sortable-basic-list--empty">
                    {t(
                        k.NO_TASKS_ADDED_YET_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN,
                    )}
                </p>
            )}

            <div className="sortable-basic-list__buttons">
                <Button
                    transparent
                    size="small"
                    disabled={disabled}
                    onClick={props.onAdd}
                    noMinWidth
                    icon={<FiPlusCircle size="22" />}
                    testId="custom-list-addTaskBtn"
                >
                    {t(k.ADD_NEW)}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default SortableBasicListValues;
