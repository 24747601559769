import { GroupBase, Options, OptionsOrGroups } from "react-select";
import { Accessors } from "react-select/dist/declarations/src/useCreatable";
import { OptionType } from "./SelectDropdownTypes";

const compareOption = <Option extends OptionType>(
    inputValue = "",
    option: Option,
    accessors: Accessors<Option>,
) => {
    const candidate = String(inputValue).toLowerCase();
    const optionValue = String(accessors.getOptionValue(option)).toLowerCase();
    const optionLabel = String(accessors.getOptionLabel(option)).toLowerCase();
    return optionValue === candidate || optionLabel === candidate;
};

export const isValidNewOption = <
    Option extends OptionType,
    Group extends GroupBase<Option>,
>(
    inputValue: string,
    selectValue: Options<Option>,
    selectOptions: OptionsOrGroups<Option, Group>,
    accessors: Accessors<Option>,
) =>
    !(
        !inputValue ||
        selectValue.some((option) =>
            compareOption(inputValue, option, accessors),
        ) ||
        selectOptions.some((option) =>
            compareOption(inputValue, option as Option, accessors),
        )
    );
