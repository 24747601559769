import ListItems, {
    EParentType,
} from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ListItemsDropdown from "common/components/listed-content/components/SidePanel/List/components/ListItemsDropdown/ListItemsDropdown";
import TemplatePreviewContext from "../../../context/TemplatePreviewContext";

const SidePanelList: React.FC = (props) => {
    const { mobileView } = useContext(TemplatePreviewContext);

    const { t } = useTranslation();

    return (
        <>
            <div className="side-panel__list__title">
                {t(k.CHECKLIST_STEPS)}
            </div>
            {mobileView ? (
                <ListItemsDropdown
                    context={TemplatePreviewContext}
                    parentType={EParentType.template}
                />
            ) : (
                <ListItems
                    context={TemplatePreviewContext}
                    parentType={EParentType.template}
                />
            )}
        </>
    );
};

export default SidePanelList;
