import React, { useMemo } from "react";

import { Pagination as PaginationComponent } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    BsChevronDoubleLeft,
    BsChevronRight,
    BsChevronLeft,
    BsChevronDoubleRight,
    BsThreeDots,
} from "react-icons/bs";

import k from "i18n/keys";

import AnimatedSpinner from "../../../components/common/spinners/AnimatedSpinner";
import { IPaginationSettings } from "./IPaginationSettings";
import { calculatePagination, IPagingCalculationResults } from "./utils";

import "./Pagination.scss";

interface IProps extends IPaginationSettings {
    isBusy?: boolean;

    showSpinner?: boolean;
    hideFooter?: boolean;

    onChange: (pageNumber: number) => void;
}

const Pagination: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const totalCount = props.totalCount ?? 0;
    const { t } = useTranslation();

    const { isBusy, showSpinner, hideFooter, activePage } = props;

    const paginationCalculations = useMemo(() => {
        return calculatePagination(props);
    }, [props]);

    const pagingItems = useMemo(() => {
        return buildPages(
            paginationCalculations,
            activePage,
            props.onChange,
            isBusy,
        );
    }, [paginationCalculations, activePage, props.onChange, isBusy]);

    if (totalCount <= props.pageSize) {
        return null;
    }

    return (
        <div className="app-pagination__wrapper">
            {showSpinner && (
                <AnimatedSpinner
                    isVisible={isBusy}
                    position="center"
                    className="no-print"
                />
            )}

            <PaginationComponent className="app-pagination--body">
                {pagingItems}
            </PaginationComponent>

            {hideFooter
                ? undefined
                : totalCount > 0 && (
                      <div className="app-pagination--footer">
                          {t(k.SHOWING_RECORDS)}{" "}
                          {paginationCalculations.first_result}-
                          {paginationCalculations.last_result} {t(k.OUT_OF_)}{" "}
                          {totalCount}
                      </div>
                  )}
        </div>
    );
};

export default Pagination;

const buildPages = (
    paginationCalculations: IPagingCalculationResults,
    activePage: number,
    onChange: (pageNumber: number) => void,
    isBusy?: boolean,
) => {
    const pages: any[] = [];

    const handleChange = (pageNumber: number) => (e: any) => {
        if (pageNumber !== activePage) {
            onChange(pageNumber);
        }
    };

    const handleRangeChange = (goPrevious: boolean) => (e: any) => {
        if (goPrevious) {
            onChange(
                Math.max(
                    1,
                    activePage - paginationCalculations.range_pages_count,
                ),
            );
        } else {
            onChange(
                Math.min(
                    paginationCalculations.total_pages,
                    activePage + paginationCalculations.range_pages_count,
                ),
            );
        }
    };

    pages.push(
        <PaginationComponent.First
            key="first"
            className="page-item--shevrons"
            disabled={activePage === 1 || isBusy}
            onClick={handleChange(1)}
        >
            <BsChevronDoubleLeft />
        </PaginationComponent.First>,
    );

    pages.push(
        <PaginationComponent.Prev
            key={"prev" + paginationCalculations.previous_page}
            className="page-item--shevrons"
            disabled={!paginationCalculations.has_previous_page || isBusy}
            onClick={handleChange(paginationCalculations.previous_page)}
        >
            <BsChevronLeft />
        </PaginationComponent.Prev>,
    );

    if (
        paginationCalculations.total_pages >
            paginationCalculations.range_pages_count &&
        paginationCalculations.first_page > 2
    ) {
        pages.push(
            <PaginationComponent.Ellipsis
                key={"prev-range"}
                className="page-item--more"
                onClick={handleRangeChange(true)}
                disabled={isBusy}
            >
                <BsThreeDots />
            </PaginationComponent.Ellipsis>,
        );
    }

    for (
        let i = paginationCalculations.first_page;
        i <= paginationCalculations.last_page;
        i++
    ) {
        pages.push(
            <PaginationComponent.Item
                key={i}
                active={i === activePage}
                onClick={handleChange(i)}
                disabled={isBusy}
            >
                {i}
            </PaginationComponent.Item>,
        );
    }

    if (
        paginationCalculations.total_pages >
            paginationCalculations.range_pages_count &&
        paginationCalculations.last_page <
            paginationCalculations.total_pages - 1
    ) {
        pages.push(
            <PaginationComponent.Ellipsis
                key={"next-range"}
                className="page-item--more"
                onClick={handleRangeChange(false)}
                disabled={isBusy}
            >
                <BsThreeDots />
            </PaginationComponent.Ellipsis>,
        );
    }

    pages.push(
        <PaginationComponent.Next
            key={"next" + paginationCalculations.next_page}
            className="page-item--shevrons"
            disabled={!paginationCalculations.has_next_page || isBusy}
            onClick={handleChange(paginationCalculations.next_page)}
        >
            <BsChevronRight />
        </PaginationComponent.Next>,
    );

    pages.push(
        <PaginationComponent.Last
            key={"last"}
            className="page-item--shevrons"
            disabled={
                activePage === paginationCalculations.total_pages || isBusy
            }
            onClick={handleChange(paginationCalculations.total_pages)}
        >
            <BsChevronDoubleRight />
        </PaginationComponent.Last>,
    );

    return pages;
};
