import { useDropdownMenu } from "react-overlays";

import DropdownTreeMenu, {
    IDropdownTreeMenuProps,
} from "common/components/input-components/dropdown-tree/custom-version/DropdownTreeMenu";

const FilterTreeMenu = (props: IDropdownTreeMenuProps) => {
    const dropdownMenuOptions = useDropdownMenu({
        flip: true,
        offset: [0, 8],
    });
    return (
        <div {...dropdownMenuOptions[0]}>
            <DropdownTreeMenu
                {...props}
                showMenu={dropdownMenuOptions[1].show}
            />
        </div>
    );
};

export default FilterTreeMenu;
