import React from "react";
import Form from "./Form";

interface IProps {
    id?: string;
    customerId?: string;

    haveChanges?: boolean;
    createNew?: boolean;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean, navigateTo?: string) => void;
}

const TableForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { customerId, haveChanges } = props;

    return (
        <Form
            id={props.id}
            createNew={props.createNew}
            customerId={customerId}
            haveChanges={haveChanges}
            onHaveChanges={props.onHaveChanges}
            onClose={props.onClose}
        />
    );
};

export default TableForm;
