import "./Confirm.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IShowConfirmArgs } from "store/confirms/actions";
import Modal from "common/components/modals/Modal";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";

interface IProps {
    onHide: (confirm?: boolean) => void;

    settings: IShowConfirmArgs;
}

const Confirm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { settings } = props;

    const { t } = useTranslation();

    const {
        message,
        showCancel,
        yesLabel,
        noLabel,
        yesButtonVariant,
        titleVariant,
        showOnlyClose,
    } = settings;

    const onHideHandler = () => {
        props.onHide(undefined);
    };

    const onYesHandler = () => {
        props.onHide(true);
    };

    const onNoHandler = () => {
        props.onHide(false);
    };
    const onCloseHandler = () => {
        props.onHide(true);
    };

    const title = props.settings.title ?? t(k.PLEASE_CONFIRM);

    const noMessage = noLabel ?? t(k.CANCEL);

    const yesMessage = yesLabel ?? t(k.CONFIRM);

    return (
        <Modal
            wrapperClassName="confirm-modal"
            className={settings.modalClass ?? ""}
            show
            mode="above-all"
            onHide={onHideHandler}
            variant={titleVariant}
            testId="confirmModal"
        >
            <Modal.Title>{title}</Modal.Title>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                {showOnlyClose ? (
                    <Button
                        testId="confirmCloseBtn"
                        variant="bordered"
                        onClick={onCloseHandler}
                    >
                        {t(k.CLOSE)}
                    </Button>
                ) : (
                    <ButtonGroup spaceBetween={showCancel ? undefined : true}>
                        {showCancel && (
                            <Button
                                testId="confirmCancelBtn"
                                variant="gray"
                                onClick={onHideHandler}
                            >
                                {t(k.CANCEL)}
                            </Button>
                        )}

                        <Button
                            testId="confirmNoBtn"
                            variant="bordered"
                            onClick={onNoHandler}
                        >
                            {noMessage}
                        </Button>

                        <Button
                            testId="confirmYesBtn"
                            variant={yesButtonVariant}
                            onClick={onYesHandler}
                        >
                            {yesMessage}
                        </Button>
                    </ButtonGroup>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default Confirm;
