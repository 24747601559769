import "./BlockedMessage.scss";

import React, { useContext } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import IStepInstance from "models/IStepInstance";

import { useStepInstancesByChecklistId } from "components/checklists/api/hooks";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { ModalTypes } from "../modal-manager/api/IModalManager";

interface IProps {
    stepInstance: IStepInstance;
}

const BlockedMessage: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { stepInstance } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const callId = `${ModalTypes.checklist}_${stepInstance.workflowRunId}`;

    const checkIfAdmin =
        modalManagerContext.state.modalList[callId]?.options?.showAll ?? false;

    const { data: stepInstances } = useStepInstancesByChecklistId(
        (stepInstance.isBlocked ?? false) &&
            stepInstance.blockedById !== undefined,
        checkIfAdmin,
        stepInstance.workflowRunId,
    );

    const blockingStepInstance = React.useMemo(() => {
        if (stepInstances && stepInstance.blockedById)
            return stepInstances.values[stepInstance.blockedById];
    }, [stepInstances]);

    const { t } = useTranslation();

    if (stepInstance.isBlocked) {
        return (
            <React.Fragment>
                <div className="step-instance-blocked-message--is-blocked">
                    <AiOutlineStop />

                    {stepInstance.isBlockingStepAvailable ? (
                        <React.Fragment>
                            {t(k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP)}

                            {blockingStepInstance && (
                                <React.Fragment>
                                    : {blockingStepInstance.name}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {t(
                                k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP_YOU_DONT_HAVE_PERMISSION_TO_SEE,
                            )}
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {stepInstance.isBlocking && (
                <div className="step-instance-blocked-message--is-blocking">
                    <AiOutlineStop />

                    {t(
                        k.THIS_STEP_IS_BLOCKING_ALL_FOLLOWING_STEPS_UNTIL_IT_HAS_BEEN_FINALIZED,
                    )}
                </div>
            )}

            {/* <div className="step-instance-blocked-message--divider"></div> */}
        </React.Fragment>
    );
};

export default BlockedMessage;
