export enum EPages {
    ORA = "ORA",
    SA = "SA",
    CM = "CM",
    POSITION = "POSITION",
    USER = "USER",
    KPI = "KPI",
    CUSTOMERS = "CUSTOMERS",
    IMPROVEMENT_DASHBOARD = "IMPROVEMENT_DASHBOARD",
    CHECKLIST_REPORT = "CHECKLIST_REPORT",
    STAKEHOLDERS = "STAKEHOLDERS",
    LEGISLATIONS = "LEGISLATIONS",
    IMPACT_GRADINGS = "IMPACT_GRADINGS",
    CUSTOMER_REQUIREMENTS = "CUSTOMER_REQUIREMENTS",
    SUPPLIERS = "SUPPLIERS",
    PURCHASE_ORDERS = "PURCHASE_ORDERS",
    ITEMS = "ITEMS",
    TEAMS = "TEAMS",
    TRAINING_PLANS = "TRAINING_PLANS",
    EQUIPMENT = "EQUIPMENT",
    ENVIRONMENT_ASPECTS = "ENVIRONMENT_ASPECTS",
    COMPETENCIES = "COMPETENCIES",
    GOALS = "GOALS",
    CUSTOM_LIST = "CUSTOM_LIST",
}
