import { ImprovementProcessChartImpactType } from "components/process-chart/api/improvements/ImprovementProcessChartImpactType";
import { ProcessChartImprovementType } from "components/process-chart/api/improvements/ProcessChartImprovementType";

export enum EImprovementView {
    activityInstanceSetId = "activity",
    kpiId = "kpi",
    allForRun = "checklist",
    allForTemplate = "template",
    processChart = "pchart",
    processChartImprovementImpactType = "impactType",
    actionSet = "actionSet",
}

interface IImprovementFilter {
    [EImprovementView.activityInstanceSetId]?: string;
    [EImprovementView.allForRun]?: boolean;
    [EImprovementView.allForTemplate]?: boolean;
    [EImprovementView.processChart]?: ProcessChartImprovementType;
    [EImprovementView.processChartImprovementImpactType]?: ImprovementProcessChartImpactType;
    [EImprovementView.actionSet]?: string;
    [EImprovementView.kpiId]?: string;
    forName?: string;
    active?: boolean;
    closed?: boolean;
    drafts?: boolean;
    startDate?: Date;
    endDate?: Date;
}

export default IImprovementFilter;
