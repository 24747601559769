import "./ProgressBar.scss";
import { ProgressBar as Bar } from "react-bootstrap";
import ProgressCircle from "./components/ProgressCircle";

export enum Position {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
    Center = "center",
}

export interface IProgressBarProps {
    now?: number;
    min?: number;
    max?: number;
    type?: "bar" | "circle";
    content?: React.ReactNode;
    contentPos?: Position;
    barInMiddle?: boolean;
}

const ProgressBar = (props: IProgressBarProps) => {
    const {
        min = 0,
        max = 100,
        now = 0,
        type = "bar",
        content,
        contentPos = Position.Right,
        barInMiddle,
    } = props;

    const current = now < min ? min : now > max ? max : now;

    return (
        <div
            className={`progress-bar--wrapper${
                content ? ` position-${contentPos}` : ""
            }${barInMiddle ? " bar-in-middle" : ""}`}
        >
            {type === "bar" && (
                <Bar
                    now={current}
                    min={min}
                    max={max}
                    className="progress-bar"
                />
            )}
            {type === "circle" && <ProgressCircle now={current} max={max} />}
            {content && <div className="progress-bar--content">{content}</div>}
        </div>
    );
};

export default ProgressBar;
