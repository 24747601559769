import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import InputWrapper from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useProcessOptions } from "components/process-chart/pages/api/hooks";

interface IProps {
    label?: React.ReactNode;
    boldLabel?: boolean;
    closeMenuOnSelect?: boolean;
    fullWidth?: boolean;

    selectedIds?: string[];
    showOrphaned?: boolean;

    onChange: (showOrphaned: boolean, values: string[]) => void;
}

export const ProcessChartProcessMultiselect = (props: IProps) => {
    const {
        label,
        boldLabel,
        closeMenuOnSelect,
        selectedIds,
        showOrphaned,
        fullWidth,
    } = props;

    const { t, i18n } = useTranslation();

    const processOptionsResult = useProcessOptions();

    const allOptions = React.useMemo(() => {
        const otherNotSure: IValueLabelItem<string, string> = {
            value: "",
            label: t(k.OTHER_NOT_SURE),
        };

        return (processOptionsResult.data ?? []).concat(otherNotSure);
    }, [processOptionsResult.data, i18n.language]);

    useEffect(() => {
        if (selectedIds) {
            const newSelectedOptions = allOptions?.filter((x) => {
                if (x.value === "") {
                    return showOrphaned;
                }

                return selectedIds.includes(x.value);
            });

            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [allOptions, selectedIds, showOrphaned]);

    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string>[]
    >([]);

    const handleOnChange = (options: any) => {
        const newOptions = options as IValueLabelItem<string, string>[];

        setSelectedOptions(newOptions);

        const showOrphaned = newOptions.some((x) => x.value === "");

        const newValues = newOptions
            .filter((x) => x.value !== "")
            .map((x) => x.value);

        props.onChange(showOrphaned, newValues);
    };

    return (
        <InputWrapper
            wrapperLabel={label ?? t(k.PROCESSES)}
            boldLabel={boldLabel}
            fullWidth={fullWidth}
        >
            <SelectDropdown
                value={selectedOptions}
                isMulti
                isSearchable
                isClearable
                placeholder={t(k.ALL)}
                options={allOptions}
                testId="form.ProcessDropdownInput"
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};
