import "./ImprovementSummary.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import WarningIcon from "../icons/icon-warning/WarningIcon";

interface IProps {
    completedCount?: number;
    notCompletedCount?: number;

    onClick: (onlyNotCompleted: boolean) => void;
}

const ImprovementSummary: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { completedCount = 0, notCompletedCount = 0 } = props;

    const { t } = useTranslation();

    const hasNotCompletedImprovements = notCompletedCount > 0;
    const hasCompletedImprovements = completedCount > 0;

    const hasBothCompletedAndNotCompletedImprovements =
        hasNotCompletedImprovements && hasCompletedImprovements;

    const handleCompletedClick = () => {
        props.onClick(false);
    };

    const handleNotCompletedClick = () => {
        props.onClick(true);
    };

    if (!hasNotCompletedImprovements && !hasCompletedImprovements) {
        return null;
    }

    return (
        <div className="improvement-summary no-print">
            <div>
                <WarningIcon />
                <div>
                    {hasBothCompletedAndNotCompletedImprovements ? (
                        <React.Fragment>
                            {t(k.THIS_ACTIVITY_HAS)}{" "}
                            <span onClick={handleNotCompletedClick}>
                                {notCompletedCount}{" "}
                                {notCompletedCount > 1 ? t(k.OPENs) : t(k.OPEN)}
                            </span>{" "}
                            &{" "}
                            <span onClick={handleCompletedClick}>
                                {completedCount}{" "}
                                {completedCount > 1
                                    ? t(k.CLOSEDs)
                                    : t(k.CLOSED)}
                            </span>{" "}
                            {completedCount > 1
                                ? t(k.IMPROVEMENTS)
                                : t(k.IMPROVEMENT)}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {hasCompletedImprovements && (
                                <React.Fragment>
                                    {t(k.THIS_ACTIVITY_HAS)}{" "}
                                    <span onClick={handleCompletedClick}>
                                        {completedCount}{" "}
                                        {completedCount > 1
                                            ? t(k.CLOSEDs)
                                            : t(k.CLOSED)}
                                    </span>{" "}
                                    {completedCount > 1
                                        ? t(k.IMPROVEMENTS)
                                        : t(k.IMPROVEMENT)}
                                </React.Fragment>
                            )}

                            {hasNotCompletedImprovements && (
                                <React.Fragment>
                                    {t(k.THIS_ACTIVITY_HAS)}{" "}
                                    <span onClick={handleNotCompletedClick}>
                                        {notCompletedCount}{" "}
                                        {notCompletedCount > 1
                                            ? t(k.OPENs)
                                            : t(k.OPEN)}
                                    </span>{" "}
                                    {notCompletedCount > 1
                                        ? t(k.IMPROVEMENTS)
                                        : t(k.IMPROVEMENT)}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImprovementSummary;
