import axios from "axios";

import IWorkCommentRequest from "http/requests/IWorkCommentRequest";
import IFinalizeStepInstanceRequest from "http/requests/IFinalizeStepInstanceRequest";
import IFinalizeStepInstanceDTO from "models/dto/IFinalizeStepInstanceDTO";
import IWorkCommentResponse from "http/responses/IWorkCommentResponse";
import IChecklistCommentFilter from "http/filters/IChecklistCommentFilter";
import IChecklistComment from "models/IChecklistComment";
import ICheckRemoveTeamRequest from "http/requests/ICheckRemoveTeamRequest";
import ICheckRemoveTeamResponse from "http/responses/ICheckRemoveTeamResponse";

export class StepInstanceService {
    private readonly url = "/api/stepinstance";

    public async createComment(
        data: IWorkCommentRequest,
    ): Promise<IWorkCommentResponse> {
        const url = `${this.url}/comment`;

        const response = await axios.post<IWorkCommentResponse>(url, data);

        response.data.comment.createdAt = new Date(
            response.data.comment.createdAt,
        );

        return response.data;
    }

    public async getComments(filter: IChecklistCommentFilter) {
        const url = `${this.url}/comments`;

        const response = await axios.get<IChecklistComment[]>(url, {
            params: filter,
        });

        const result = response.data;

        for (const item of result) {
            item.createdAt = new Date(item.createdAt);
        }

        return result;
    }
    
    public async checkRemoveTeam(data: ICheckRemoveTeamRequest) {
        const url = `${this.url}/check-remove-team`;

        const response = await axios.post<ICheckRemoveTeamResponse>(url, data);

        return response.data;
    }
}
