import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import ActivityPhoto from "common/components/input-components-steps/ActivityPhoto";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import IInputValueDTO from "models/dto/IInputValueDTO";

interface IProps {
    id: string;
    readOnly?: boolean;
    isHidden?: boolean;

    photoId?: string;

    value?: IInputValueDTO;

    label?: string;
    testId?: string;

    onChange?: (e: IInputChangeEvent<string>) => void;
}

const ActivityPhotoInput = (props: IProps) => {
    const { id, photoId, value, isHidden, readOnly, label } = props;

    const { t } = useTranslation();

    if (isHidden) {
        return null;
    }

    const completed = value?.isDeleted === false || !!photoId;

    return (
        <ActivityPhoto
            testId={props.testId}
            id={id}
            photoId={photoId}
            value={value?.value ?? ""}
            label={label ?? t(k.PHOTO)}
            disabled={readOnly ?? false}
            completed={completed}
            onChange={props.onChange}
        />
    );
};

export default ActivityPhotoInput;
