export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export const MonthsOrder = [
    Months.January,
    Months.February,
    Months.March,
    Months.April,
    Months.May,
    Months.June,
    Months.July,
    Months.August,
    Months.September,
    Months.October,
    Months.November,
    Months.December,
];
