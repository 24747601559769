import "./KpiThreshold.scss";

import { PropsWithChildren, useMemo, useState } from "react";

import MultiRangeSlider from "./MultiRange";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import KpiThresholdLegend from "./KpiThresholdLegend";

interface IProps {
    values: { min: number; max: number };

    highIsGood?: boolean;
    onChange: (value: { min?: number; max?: number }) => void;
}

const KpiThresholdRange = (props: PropsWithChildren<IProps>) => {
    const { t } = useTranslation();
    const { values, highIsGood } = props;

    const hideYellow = useMemo(() => {
        return values.max - values.min === 0;
    }, [values]);

    return (
        <div className="kpi-threshold--range">
            <MultiRangeSlider
                values={values}
                onChange={props.onChange}
                highIsGood={highIsGood}
            />
            <KpiThresholdLegend
                values={values}
                highIsGood={highIsGood}
                hideYellow={hideYellow}
            />
        </div>
    );
};

export default KpiThresholdRange;
