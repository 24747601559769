import "./ValidationLabel.scss";

import React from "react";

interface IProps {
    id?: string;
    darkColor?: boolean;
    errors?: string[] | string | React.ReactNode;
    testId?: string;
}

const ValidationLabel = (props: React.PropsWithChildren<IProps>) => {
    const { errors, darkColor, testId = "validation-label", id } = props;

    return errors ? (
        <div
            id={id}
            className={"validation-label" + (darkColor ? " dark-color" : "")}
            data-testid={testId}
        >
            {errors}
        </div>
    ) : null;
};

export default ValidationLabel;
