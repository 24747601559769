import "./UsernameLogout.scss";

import React from "react";
import { VscSignOut } from "react-icons/vsc";
import { LinkWrapper } from "components/common/link-wrapper";

interface IProps {
    username?: string;
    onLogout?: () => void;
}

const UsernameLogout: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { username, onLogout } = props;
    return (
        <div className="username-logout">
            <LinkWrapper to="/profile">
                <span className="preview-link username-logout--username">
                    {username}
                </span>
            </LinkWrapper>
            <div className="username-logout--logout" onClick={onLogout}>
                <VscSignOut />
            </div>
        </div>
    );
};

export default UsernameLogout;
