import "./ColumnFilteringComponent.scss";

import React from "react";
import { Dropdown } from "react-overlays";

import { IDropdownTreeData } from "common/components/input-components/dropdown-tree/custom-version/IDropdownTreeData";
import FilterTreeMenu from "./components/FilterTreeMenu";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import { FilterToggle } from "../filter-dropdown/components/FilterToggle";

export interface IColumnFilteringTreeProps {
    open?: boolean;
    isActive?: boolean;
    isLoading?: boolean;
    hideAllOption?: boolean;
    useApply?: boolean;
    useSearch?: boolean;

    data?: ITableSetWithOptions<IDropdownTreeData, string>;

    testId?: string;

    onFilter?: (ids: string[]) => void;

    onShow?: (newState: boolean) => void;
}

const ColumnFilteringTreeComponent = (props: IColumnFilteringTreeProps) => {
    const {
        open,
        isActive,
        isLoading,
        hideAllOption,
        useApply,
        useSearch,
        data,
        testId,
    } = props;

    const handleToggle = (
        nextShow: boolean,
        e?: Event | React.SyntheticEvent<Element, Event>,
    ) => {
        e?.stopPropagation();

        if (props.onShow) {
            props.onShow(nextShow);
        }
    };

    const handleOnChange = (ids: string[]) => {
        props.onFilter?.(ids);

        if (useApply) {
            props.onShow?.(false);
        }
    };

    return (
        <Dropdown show={open} onToggle={handleToggle} alignEnd={true}>
            <div className="app-table--cell-filter">
                <FilterToggle isActive={isActive ?? false} testId={testId} />
                {data && (
                    <FilterTreeMenu
                        showSearch={useSearch}
                        useApply
                        data={data}
                        onChange={handleOnChange}
                    />
                )}
            </div>
        </Dropdown>
    );
};

export default ColumnFilteringTreeComponent;
