import { useContext } from "react";
import TemplatePreview from "components/templates/components/TemplateListedContent/Preview/TemplatePreview";
import { ModalTypes, IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { usePublishedUserOptions } from "components/users/api/hooks";

interface IProps {
    modalItem: IModalListItem;
}

const TemplatePreviewModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    usePublishedUserOptions();

    const handleOnClose = (hideQuickRun?: boolean) => {
        if (hideQuickRun) {
            modalManagerContext.onCloseModal({
                callId,
                extraById: [ModalTypes.select_checklist_template],
            });
        } else {
            modalManagerContext.onCloseModal({ callId });
        }
    };

    return (
        <TemplatePreview
            id={id ?? ""}
            syncData={modalManagerContext.state.activeModal === callId}
            sourceCustomListItemId={options?.sourceCustomListItemId}
            onClose={handleOnClose}
            onShowModal={modalManagerContext.onShowModal}
        />
    );
};

export default TemplatePreviewModal;
