import { Action } from "redux";
interface IProcessChartActionBase<T extends string> extends Action<T> {
    [key: string]: any;
}
export const PROCESS_CHART_SHOW_PUBLISHED_VIEW =
    "TOGGLE_PROCESS_CHART_PUBLISHED_VIEW";

export const PROCESS_CHART_REMOVE_NODE_BY_ID =
    "PROCESS_CHART_REMOVE_NODE_BY_ID";

interface IProcessChartShowPublishedViewAction
    extends IProcessChartActionBase<typeof PROCESS_CHART_SHOW_PUBLISHED_VIEW> {
    payload: boolean;
}

interface IProcessChartRemoveNodeByIdAction
    extends IProcessChartActionBase<typeof PROCESS_CHART_REMOVE_NODE_BY_ID> {
    payload: string[];
}

export type ProcessChartActionTypes =
    | IProcessChartShowPublishedViewAction
    | IProcessChartRemoveNodeByIdAction;
