import { useQuery } from "react-query";

import axios from "axios";

import { ISharedOptionRequest } from "./ISharedOptionRequest";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

export enum ESharedOptionsType {
    "customer" = "customer",
    "supplier" = "supplier",
    "item" = "item",
    "user" = "user",
    "position" = "position",
    "competency" = "competency",
    "team" = "team",
    "equipment" = "equipment",
}

export const useSharedOptions = (
    enabled: boolean,
    type?: ESharedOptionsType,
    selectedIds?: string[],
) => {
    const url = `/api/${type}/shared`;

    return useQuery(
        ["shared-options", type, selectedIds ?? []],
        async (context) => {
            if (type) {
                const request: ISharedOptionRequest = {
                    ids: selectedIds ?? [],
                };

                const response = await axios.post<IValueLabelItemWithState[]>(
                    url,
                    request,
                    {
                        signal: context.signal,
                    },
                );

                return response.data;
            }
        },
        { enabled, keepPreviousData: true },
    );
};
