import { FC, RefObject, useState } from "react";
import {
    Content,
    IContentInputProps,
    SidePanel,
    ISidePanelInputProps,
} from "./components";
import "./ListedContent.scss";
import { ListedContentContextType } from "./ListedContentContext";

interface IListedProps {
    testId?: string;
    className?: string;
    listedContentRef?: RefObject<HTMLDivElement>;
    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

export type ListedContentType<C extends ListedContentContextType> = FC<
    React.PropsWithChildren<IListedProps>
> & {
    Content: FC<React.PropsWithChildren<IContentInputProps>>;
    SidePanel: ISidePanelInputProps<C>;
};

const ListedContent = (props: React.PropsWithChildren<IListedProps>) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const onScroll = (e: any) => {
        setIsScrolled(e.target.scrollTop > 0);
    };

    return (
        <div
            data-testid={props.testId}
            className={`listed-content ${props.className} ${
                isScrolled ? "is-scrolled" : ""
            }`}
            onScroll={onScroll}
            ref={props.listedContentRef}
        >
            {props.children}
        </div>
    );
};

ListedContent.Content = Content;
ListedContent.SidePanel = SidePanel;
ListedContent.defaultProps = {
    className: "",
};

export default ListedContent;
