import "./ColumnFilteringComponent.scss";

import React from "react";
import { Dropdown } from "react-overlays";

import { FilterCheckboxMenu } from "./components/FilterCheckboxMenu";
import { IValueLabelItem } from "common/IValueLabelItem";
import { FilterToggle } from "../filter-dropdown/components/FilterToggle";

export interface IColumnFilteringProps {
    open?: boolean;
    isLoading?: boolean;
    hideAll?: boolean;
    hideSearch?: boolean;

    menuOptions?: IValueLabelItem<string, string>[];
    selectedIds?: string[];

    testId?: string;

    onFilter: (ids: string[]) => void;

    onShow?: (newState: boolean) => void;
}

const ColumnFilteringComponent = (props: IColumnFilteringProps) => {
    const {
        isLoading,
        hideAll,
        hideSearch,
        menuOptions,
        selectedIds,
        open,
        testId,
    } = props;

    const [isActive, setIsActive] = React.useState(
        selectedIds !== undefined && selectedIds.length > 0 ? true : false,
    );

    const [isResetMenu, setIsResetMenu] = React.useState(false);

    const handleToggle = (
        nextShow: boolean,
        e?: Event | React.SyntheticEvent<Element, Event>,
    ) => {
        e?.stopPropagation();

        if (props.onShow) {
            props.onShow(nextShow);
        }

        setIsResetMenu(nextShow === false);
    };

    const handleFilter = (ids: string[]) => {
        setIsActive(ids.length > 0);

        props.onFilter(ids);
    };

    return (
        <Dropdown show={open} onToggle={handleToggle} alignEnd={true}>
            <div className="app-table--cell-filter">
                <FilterToggle isActive={isActive} testId={testId} />
                <FilterCheckboxMenu
                    testId={testId ? `${testId}Menu` : undefined}
                    isLoading={isLoading}
                    isReset={isResetMenu}
                    hideAll={hideAll}
                    hideSearch={hideSearch}
                    options={menuOptions}
                    initialSelectedIds={selectedIds}
                    onFilter={handleFilter}
                />
            </div>
        </Dropdown>
    );
};

export default ColumnFilteringComponent;
