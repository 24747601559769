import "./DraftIcon.scss";

import Icon from "./assets/draft-icon.svg";

interface IProps {}

const DraftIcon = (props: IProps) => {
    return <Icon className="draft-icon" />;
};

export default DraftIcon;
