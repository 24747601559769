import {
    PropsWithChildren,
    ReactNode,
    createContext,
    useEffect,
    useState,
} from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import TeamsAppNavBar from "./TeamsAppNavBar";

import { IValueLabelItem } from "common/IValueLabelItem";
import { IProfileDTO } from "models/dto/IProfileDTO";
import { useLocation } from "react-router-dom";

const excludeList = ["process", "subprocess"];

export interface ITeamsAppContext {
    navBar: IValueLabelItem<string, string>[];
    setNavBar: (navBar: IValueLabelItem<string, string>[]) => void;
}

export const TeamsAppContext = createContext<ITeamsAppContext>({
    navBar: [],
    setNavBar: () => {},
});

export interface ITeamsAppLayoutProps {
    profile?: IProfileDTO;
    localeSwitch: ReactNode;
    onSignOut: () => void;
}

const TeamsAppLayout = (props: PropsWithChildren<ITeamsAppLayoutProps>) => {
    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const location = useLocation();

    const [navBar, setNavBar] = useState<IValueLabelItem<string, string>[]>([]);

    useEffect(() => {
        const pathName = location.pathname.slice(1).split("/");

        if (!excludeList.some((item) => pathName.includes(item))) {
            const result = pathName.reduce<IValueLabelItem<string, string>[]>(
                (acc, value) => {
                    if (value === "") {
                        return acc;
                    }

                    const label = value.replace(/-/g, " ");

                    const toKey = `NAV_NODE_${value
                        .toUpperCase()
                        .replace(/-/g, "_")}`;

                    const keyedName = t(k[toKey as keyof typeof k]);

                    if (keyedName) {
                        acc.push({
                            value,
                            label: keyedName,
                        });
                    } else {
                        acc.push({
                            value,
                            label,
                        });
                    }

                    return acc;
                },
                [],
            );

            setNavBar(result);
        }
    }, [location, localeId]);

    return (
        <TeamsAppContext.Provider value={{ navBar, setNavBar }}>
            <TeamsAppNavBar {...props} navBar={navBar} />
            <div className="main main-teams-app">{props.children}</div>
        </TeamsAppContext.Provider>
    );
};

export default TeamsAppLayout;
