import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { ITableMatrix, TableMatrix } from "./api/TableMatrix";
import { fetchExpandedColumnValuesAsync } from "./api/hooks";
import { HeaderTableCell } from "./header-table-cell/HeaderTableCell";
import { IHeaderTableCellProps } from "./header-table-cell/IHeaderTableCellProps";
import { TableCell } from "common/components/table";
import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";

interface IProps
    extends Pick<IHeaderTableCellProps, "onFilter" | "customListFilters"> {
    reportDetails: IChecklistReportResponse;

    checklistIds: string[];

    tableMatrix: ITableMatrix | null;

    onExpand: (value: ITableMatrix) => void;
}

export const LevelTwoReportDetailsHeader = (props: IProps) => {
    const { customListFilters, checklistIds, tableMatrix } = props;

    const {
        headerLevelOneStepInstanceIds,
        headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId,
    } = props.reportDetails;

    const [tableMatrixInstance, setTableMatrixInstance] =
        React.useState<TableMatrix>();

    React.useEffect(() => {
        setTableMatrixInstance(new TableMatrix());
    }, [checklistIds]);

    const { t } = useTranslation();

    const handleExpand = async (mustExpand: boolean, id: string) => {
        tableMatrixInstance?.setReportDetails(
            checklistIds,
            props.reportDetails,
        );

        if (tableMatrixInstance) {
            if (mustExpand) {
                const request = tableMatrixInstance.getRequest(id);

                try {
                    if (request) {
                        const expandColumnResponse =
                            await fetchExpandedColumnValuesAsync(request);

                        tableMatrixInstance.expand(id, expandColumnResponse);
                    }
                } catch {}
            } else {
                tableMatrixInstance.collapse(id);
            }

            props.onExpand({
                ...tableMatrixInstance,
            });
        }
    };

    if (tableMatrix) {
        return (
            <React.Fragment>
                {tableMatrix.columns.map((column) => {
                    if (column?.isStatusColumn) {
                        return (
                            <TableCell key={column.id} isHighlighted>
                                {t(k.STATUS)}
                            </TableCell>
                        );
                    }

                    return (
                        <HeaderTableCell
                            key={column?.id}
                            column={column ?? null}
                            customListFilters={customListFilters}
                            onFilter={props.onFilter}
                            onExpand={handleExpand}
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {headerLevelOneStepInstanceIds.map((stepInstanceId) => (
                <React.Fragment key={stepInstanceId}>
                    <TableCell isHighlighted>{t(k.STATUS)}</TableCell>

                    {headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId[
                        stepInstanceId
                    ].map((column) => (
                        <HeaderTableCell
                            key={column.id}
                            column={column}
                            customListFilters={customListFilters}
                            onFilter={props.onFilter}
                            onExpand={handleExpand}
                        />
                    ))}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};
