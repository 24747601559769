import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IMultiselectDropdownProps } from "./IMultiselectDropdownProps";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { getPublishedTemplateList } from "store/workflows/actions";

interface IOwnProps extends IMultiselectDropdownProps {
    onFetch?: (options: Array<IValueLabelItem<string, string>>) => void;
}

interface IDispatchProps {
    getPublishedTemplateList: () => Promise<
        Array<IValueLabelItem<string, string>>
    >;
}

type Props = IOwnProps & IDispatchProps;

const TemplateMultiselect: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { values, label, uniqueId } = props;

    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<
        Array<IValueLabelItem<string, string>>
    >([]);

    const [selectedOptions, setSelectedOptions] = useState<
        Array<IValueLabelItem<string, string>>
    >([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await props.getPublishedTemplateList();

        setAllOptions(result);

        if (values) {
            const newSelectedOptions = result.filter((x) =>
                values.includes(x.value),
            );

            setSelectedOptions(newSelectedOptions);
        }

        if (props.onFetch) {
            props.onFetch(result);
        }
    };

    const handleOnChange = (options: any) => {
        let newValues: Array<IValueLabelItem<string, string>> = [];

        if (options) {
            const selectedValues = options as Array<
                IValueLabelItem<string, string>
            >;

            newValues = selectedValues;
        }

        setSelectedOptions(newValues);

        props.onChange(
            newValues.map((x) => x.value),
            newValues,
        );
    };

    const controlId = uniqueId || "form.selectTemplates";

    return (
        <div className="flex-column__wrapper">
            {label && <label htmlFor={controlId}>{label}</label>}

            <SelectDropdown
                inputId={controlId}
                value={selectedOptions}
                isMulti
                isSearchable
                isClearable
                placeholder={t(k.ALL)}
                options={allOptions}
                closeMenuOnSelect={false}
                onChange={handleOnChange}
            />
        </div>
    );
};

const actions = {
    getPublishedTemplateList,
};

export default connect(null, actions)(TemplateMultiselect);
