import "./AccordionButton.scss";

import React from "react";
import { BiChevronDown } from "react-icons/bi";

interface IProps {
    isOpen?: boolean;
}

const AccordionButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <BiChevronDown
            className={
                props.isOpen ? "accordion-button active" : "accordion-button"
            }
        />
    );
};

export default AccordionButton;
