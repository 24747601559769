import "./ParentGoalList.scss";

import { PropsWithRef } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IParentGoalDTO } from "components/goals-page/api/IParentGoalDTO";

import SelectedUsersAvatarList from "../select-users/SelectedUsersAvatarList";
import { useUserListByIds } from "components/users/api/hooks";

interface IProps {
    parentGoals: IParentGoalDTO[];
    disabled?: boolean;
    onClick?: (id: string) => void;
}

const ParentGoalList = (props: PropsWithRef<IProps>) => {
    const { parentGoals, disabled } = props;

    const { t } = useTranslation();

    const userIds = parentGoals.map((goal) => goal.responsibleUsersIds).flat();

    const { data: usersData, isLoading: isLoadingUsers } =
        useUserListByIds(userIds);

    const handleOnClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        const id = event.currentTarget.id;
        if (id) {
            props.onClick?.(id);
        }
    };

    const singleParent = parentGoals?.length === 1;

    return (
        <div
            className={`parent-goal-list${singleParent ? " singleParent" : ""}`}
        >
            <div className="parent-goal-list--title">
                {singleParent ? t(k.PARENT_GOAL) + ":" : t(k.PARENT_GOALS)}
            </div>
            {parentGoals?.map((goal) => (
                <div className="parent-goal-list--item" key={goal.id}>
                    <SelectedUsersAvatarList
                        maxShownAvatars={3}
                        size={18}
                        users={
                            usersData?.filter((x) =>
                                goal.responsibleUsersIds.includes(x.value),
                            ) ?? []
                        }
                    />
                    <span
                        id={goal.id}
                        className={disabled ? undefined : "preview-link"}
                        onClick={handleOnClick}
                    >
                        {goal.name}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default ParentGoalList;
