import "./CommentList.scss";

import React from "react";
import { RiChat3Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import CommentListItem from "./CommentListItem";
import Pagination from "../pagination/Pagination";
import { IPaginationSettings } from "../pagination/IPaginationSettings";
import IChecklistComment from "models/IChecklistComment";

interface IProps {
    comments?: IChecklistComment[];

    pagination: IPaginationSettings;

    onChangePage: (pageNumber: number) => void;
}

const CommentList: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { comments, pagination } = props;

    const { t } = useTranslation();

    const hasComments = (pagination.totalCount ?? 0) > 0;

    return hasComments ? (
        <div className="comment-list">
            <div className="comment-list-amount">
                <RiChat3Line />

                {pagination.totalCount === 1 ? t(k.COMMENT) : t(k.COMMENTS)}

                <div className="amount">{pagination.totalCount}</div>
            </div>

            {comments && (
                <div className="comment-list-wrap">
                    {comments.map((comment) => (
                        <CommentListItem key={comment.id} {...comment} />
                    ))}
                </div>
            )}

            <Pagination
                showSpinner={true}
                hideFooter={true}
                activePage={pagination.activePage}
                pageSize={pagination.pageSize}
                totalCount={pagination.totalCount}
                onChange={props.onChangePage}
            />
        </div>
    ) : null;
};

export default CommentList;
