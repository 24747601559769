import React, { useMemo } from "react";
import { IoClose } from "react-icons/io5";

export interface IModalContentHeaderProps {
    className?: string;
    title?: React.ReactNode;
    actions?: React.ReactNode;
    onClose?: () => void;
}

const ModalContentHeader: React.FC<
    React.PropsWithChildren<IModalContentHeaderProps>
> = (props) => {
    const { className, title, actions } = props;

    const headerClassName = useMemo(() => {
        const result = ["modal-manager--modal-content--header"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [className]);

    return (
        <div className={headerClassName}>
            {props.children ?? (
                <>
                    {Boolean(title) && (
                        <div
                            className="modal-manager--modal-content--header--title"
                            data-testid="modal-content--header--title"
                        >
                            {title}
                        </div>
                    )}

                    {actions}

                    {Boolean(props.onClose) && (
                        <div
                            className="modal-manager--modal-content--header--close"
                            data-testid="modal-content--header--close-button"
                        >
                            <IoClose onClick={() => props.onClose?.()} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ModalContentHeader;
