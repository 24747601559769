import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import ModalContent from "common/components/modal-content/ModalContent";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";
import { usePublishedTeamOptions } from "components/teams/api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import { IAssignCustomListRoleToUserOrTeamRequest } from "common/components/permission-settings/api/IAssignCustomListRoleToUserOrTeamRequest";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { IAppState } from "store/IAppState";
import PermissionSettings from "common/components/permission-settings/components/PermissionSettings";
import { IValueLabelItem } from "common/IValueLabelItem";
import {
    useAssignCustomListRolePermissionsMutation,
    useUserAndTeamRolesByCustomListId,
} from "./api/hooks";
import { ISelectedPermissionAssignee } from "./api/ISelectedPermissionAssignee";
import { CustomListRoleEnum } from "./api/CustomListRoleEnum";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import HistoryButton from "components/history/components/HistoryButton";
import { useAccessLimitedPropertyExists } from "components/custom-list-page/api/hooks";

const roleInfo = {
    Owner: {
        title: k.OWNER,
        info: k.CUSTOM_LIST_ITEM_OWNER_INFO,
    },
    Editor: {
        title: k.EDITOR,
        info: k.CUSTOM_LIST_ITEM_EDITOR_INFO,
    },
    Viewer: {
        title: k.VIEWER,
        info: k.CUSTOM_LIST_ITEM_VIEWER_INFO,
    },
    User: {
        title: k.USER,
        info: k.CUSTOM_LIST_ITEM_USER_INFO,
    },
};

interface IProps {
    customListId: string;
    customListParentId?: string;

    onClose: () => void;
}

type CustomListRoleEnumKeysType = keyof typeof CustomListRoleEnum;

const roles: CustomListRoleEnumKeysType[] = [
    "Owner",
    "Editor",
    "Viewer",
    "User",
];

export const CustomListPermissionSettingsModal = (props: IProps) => {
    const { customListId, customListParentId } = props;

    const hasPageChange = useSelector(
        (appState: IAppState) => appState.pageNavigationViewState.hasPageChange,
    );

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const hasPageChanges = useHasPageChanges();

    const savePermissionMutation = useAssignCustomListRolePermissionsMutation();

    const { data: teamOptions } = usePublishedTeamOptions();

    const { data: userOptions } = usePublishedUserOptions();

    const { data: roleData } = useUserAndTeamRolesByCustomListId(customListId);

    const { data: hasLimitAccessProperty } =
        useAccessLimitedPropertyExists(customListId);

    const [isSaving, setIsSaving] = useState(false);

    const [selected, setSelected] =
        useState<
            Record<CustomListRoleEnumKeysType, ISelectedPermissionAssignee>
        >();

    const [newSelected, setNewSelected] = useState<
        Partial<
            Record<
                CustomListRoleEnumKeysType,
                | ITableSetWithValueLabelOptions<string | null, "user" | "team">
                | undefined
            >
        >
    >({});

    useEffect(() => {
        if (roleData) {
            setSelected(roleData);

            const nextNewSelected = roles.reduce<
                Partial<
                    Record<
                        CustomListRoleEnumKeysType,
                        ITableSetWithValueLabelOptions<
                            string | null,
                            "user" | "team"
                        >
                    >
                >
            >((acc, role) => {
                acc[role] = { ids: [], values: {}, options: [] };

                return acc;
            }, {});

            setNewSelected(nextNewSelected);
        }
    }, [roleData]);

    const handleChangeNewSelected = (
        newItem: IValueLabelItem<string, string | null, "user" | "team">,
        roleId: string,
    ) => {
        const roleKey: CustomListRoleEnumKeysType =
            roleId as CustomListRoleEnumKeysType;

        setNewSelected((prev) => {
            const prevByRoleId = prev[roleKey];

            const prevValues = prevByRoleId?.values ?? {};

            if (newItem.param === undefined) {
                delete prevByRoleId?.values[newItem.value];

                return {
                    ...prev,
                    [roleKey]: {
                        ids:
                            prevByRoleId?.ids.filter(
                                (x) => x !== newItem.value,
                            ) ?? [],
                        values: prevValues,
                        options:
                            prevByRoleId?.options.filter(
                                (x) => x.value !== newItem.value,
                            ) ?? [],
                    },
                };
            } else {
                const newValues = {
                    ...prevValues,
                    [newItem.value]: {
                        label: newItem.label,
                        param: newItem.param,
                    },
                };

                if (prevByRoleId?.ids.includes(newItem.value)) {
                    const options = prevByRoleId.ids.reduce<
                        Array<
                            IValueLabelItem<
                                string,
                                string | null,
                                "user" | "team"
                            >
                        >
                    >((acc, id) => {
                        const item = newValues[id];

                        if (item) {
                            acc.push({
                                ...item,
                                value: id,
                            });
                        }

                        return acc;
                    }, []);

                    return {
                        ...prev,
                        [roleKey]: {
                            ...prevByRoleId,
                            values: newValues,
                            options,
                        },
                    };
                } else {
                    return {
                        ...prev,
                        [roleKey]: {
                            ids: prevByRoleId?.ids.concat(newItem.value) ?? [
                                newItem.value,
                            ],
                            values: newValues,
                            options: prevByRoleId?.options.concat(newItem) ?? [
                                newItem,
                            ],
                        },
                    };
                }
            }
        });
    };

    const handleOnSave = async () => {
        setIsSaving(true);

        const list: Array<{
            role: CustomListRoleEnumKeysType;
            isUser: boolean;
            selectedId: string;
        }> = [];

        if (newSelected) {
            Object.keys(newSelected)
                .map((x) => x as CustomListRoleEnumKeysType)
                .forEach((x) => {
                    newSelected[x]?.options.forEach((item) => {
                        if (item.label !== null) {
                            list.push({
                                role: x,
                                isUser: item.param === "user",
                                selectedId: item.label,
                            });
                        }
                    });
                });
        }

        if (selected) {
            Object.keys(selected)
                .map((x) => x as CustomListRoleEnumKeysType)
                .forEach((x) => {
                    selected[x].itemList.forEach((item) => {
                        if (item.markedRemove !== true) {
                            list.push({
                                role: x,
                                isUser: item.param === "user",
                                selectedId: item.value,
                            });
                        }
                    });
                });
        }

        const request = roles.reduce<IAssignCustomListRoleToUserOrTeamRequest>(
            (acc, role) => {
                const values = {
                    itemList: list
                        .filter(
                            (x) =>
                                x.role === role &&
                                x.selectedId !== "isEveryone",
                        )
                        .map((x) => ({
                            isUser: x.isUser,
                            selectedId: x.selectedId,
                        })),

                    isEveryone:
                        (selected && selected[role]?.isEveryone) ??
                        list.some(
                            (x) =>
                                x.role === role &&
                                x.selectedId === "isEveryone",
                        ),
                };

                acc.values[CustomListRoleEnum[role]] = values;

                return acc;
            },
            {
                customListId: customListId,
                values: {},
            },
        );

        const result = await savePermissionMutation.mutateAsync(request);

        setIsSaving(false);

        if (result.Succeeded) {
            hasPageChanges(false);

            setSelected(undefined);
            setNewSelected({});

            props.onClose();
        }
    };

    const handleOnClose = async () => {
        if (hasPageChange) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };
            if (await showConfirmNoThunk(dispatch, args)) {
                hasPageChanges(false);

                props.onClose();
            }
        } else {
            props.onClose();
        }
    };

    return (
        <ModalContent.Backdrop onClickBackdrop={handleOnClose}>
            <ModalContent maxWidth="sm">
                <ModalContent.Header
                    title={t(k.PERMISSION_SETTINGS)}
                    onClose={handleOnClose}
                    actions={
                        customListParentId && (
                            <HistoryButton
                                id={customListParentId}
                                type={
                                    HistoryOwnerEnum.CustomListItemsPermissions
                                }
                            />
                        )
                    }
                />

                <ModalContent.Body>
                    {selected &&
                        roles.map((role) => {
                            const { title, info } = roleInfo[role] || {
                                title: role,
                                info: undefined,
                            };

                            const disabled =
                                role === "User" && !hasLimitAccessProperty;

                            return (
                                <PermissionSettings
                                    key={role}
                                    testId={`custom-list-p-s-${role}`}
                                    roleId={role}
                                    title={getLocaleString(t, title)}
                                    disabled={disabled}
                                    disabledInfo={t(
                                        k.CUSTOM_LIST_ITEM_USER_DISABLED_INFO,
                                    )}
                                    info={getLocaleString(t, info)}
                                    selected={selected[role]}
                                    setSelected={setSelected as any}
                                    newSelected={newSelected[role]}
                                    changeNewSelected={handleChangeNewSelected}
                                    teamOptions={teamOptions?.options}
                                    userOptions={userOptions?.options}
                                />
                            );
                        })}
                </ModalContent.Body>

                <ModalContent.Footer>
                    <ButtonGroup spaceBetween>
                        <Button
                            variant="danger"
                            transparent
                            onClick={handleOnClose}
                            disabled={isSaving}
                            testId="permission-settings-cancel-btn"
                        >
                            {t(k.CANCEL)}
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleOnSave}
                            isBusy={isSaving}
                            disabled={isSaving}
                            testId="permission-settings-save-btn"
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ButtonGroup>
                </ModalContent.Footer>
            </ModalContent>
        </ModalContent.Backdrop>
    );
};
