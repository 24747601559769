import "./Positions.scss";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IUserPositionItem } from "components/positions/api/IUserPositionItem";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import InputWrapper from "common/components/input-components/InputWrapper";
import PositionsDateView from "./PositionDateView";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { CheckmarkIcon, EditIcon } from "common/components/icons";
import UndoButton from "common/components/buttons/undo-button/UndoButton";

import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { MdReplay } from "react-icons/md";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

import { SaveHandler } from "components/users/components/UserForm";

import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import TooltipWrapper from "../tooltip/TooltipWrapper";
import classNames from "classnames";

interface IProps {
    item: IUserPositionItem;
    positionOptions?: IValueLabelItemWithState<string, string, string>[];
    canEdit?: boolean;
    error?: string | string[] | null;
    onSave?: (item: IUserPositionItem) => void;
    onDelete?: (id: string, undo?: boolean) => void;
    onChangeEditMode?: (id: string, newMode: boolean) => void;
    onShowPosition?: (position: IValueLabelItemWithState<string>) => void;
    onHaveChanges?: (haveChanges: boolean) => void;

    registerChildSaveHandler?: (
        itemId: string,
        saveHandler: SaveHandler,
    ) => void;
    unregisterChildSaveHandler?: (itemId: string) => void;
}

const PositionsItem = (props: React.PropsWithChildren<IProps>) => {
    const { canEdit, error, positionOptions } = props;

    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(
        canEdit ? props.item.isEditMode : false,
    );

    const [item, setItem] = useState<IUserPositionItem>(props.item);

    const [errors, setErrors] = useState<IValidationMessages>();

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    useEffect(() => {
        const getItem = () => {
            return item;
        };

        props.registerChildSaveHandler?.(item.id, getItem);

        return () => {
            props.unregisterChildSaveHandler?.(item.id);
        };
    }, [item]);

    const handleOnChange = <V,>(e: IInputChangeEvent<V>) => {
        const name = e.name;
        if (name) {
            setItem((prev) => ({ ...prev, [name]: e.value }));

            if (errors && errors[name]) {
                delete errors[name];
            }
        }

        props.onHaveChanges?.(true);
    };

    const handleOnEdit = () => {
        setEditMode(true);

        if (props.onChangeEditMode) {
            props.onChangeEditMode(item.id, true);
        }
    };

    const handleOnRevert = () => {
        if (props.item.positionId && props.item.startDate) {
            setEditMode(false);

            setItem(props.item);

            if (props.onChangeEditMode) {
                props.onChangeEditMode(item.id, false);
            }
        } else {
            if (props.onDelete) {
                props.onDelete(item.id);
            }
        }
    };

    const handleOnDelete = () => {
        if (props.onDelete) {
            props.onDelete(item.id, item.isDelete);
        }
    };

    const handleOnSave = () => {
        if (item.positionId && item.startDate) {
            setEditMode(false);

            if (props.onSave) {
                props.onSave(
                    item.endDate || item.isCurrent
                        ? item
                        : { ...item, isCurrent: true },
                );
            }
        } else {
            let error: { [key: string]: string } = {};

            if (Boolean(item.positionId) === false) {
                error["positionId"] = "REQUIRED";
            }

            if (Boolean(item.startDate) === false) {
                error["startDate"] = "REQUIRED";
            }

            setErrors((prev) => ({ ...prev, ...error }));
        }
    };

    const handleShowPosition = () => {
        if (props.onShowPosition && positionOptions && item.positionId) {
            const position = positionOptions.find(
                (x) => x.value === item.positionId,
            );

            if (position) {
                props.onShowPosition(position);
            }
        }
    };

    const archivedIds = useMemo(() => {
        if (positionOptions) {
            const archived = positionOptions.filter((x) => x.isArchived);

            return archived.map((x) => x.value);
        }
    }, [positionOptions]);

    const positionLabel = useMemo(() => {
        if (positionOptions && item.positionId) {
            const position = positionOptions.find(
                (x) => x.value === item.positionId,
            );

            const positionLabelClassName = classNames("positions-item__label", {
                "positions-item__label--crossed-out":
                    position?.isArchived || position?.isDeleted,
            });

            const tooltipMessage = (
                <>
                    {position?.isArchived && (
                        <div>
                            {t(position?.isDeleted ? k.DELETED : k.ARCHIVED)}
                        </div>
                    )}
                    <div>{position?.param}</div>
                </>
            );

            if (position && position.label) {
                return (
                    <TooltipWrapper
                        id={position.value}
                        message={tooltipMessage}
                        showTooltip={!!position.param || position.isArchived}
                        truncate
                    >
                        <div
                            className={positionLabelClassName}
                            onClick={handleShowPosition}
                        >
                            {position.label}
                        </div>
                    </TooltipWrapper>
                );
            }
        }

        return "";
    }, [positionOptions, item.positionId]);

    return (
        <div className={`positions-item${error ? " invalid" : ""}`}>
            {editMode ? (
                <div className="positions-item--edit">
                    <div className="positions-item--row">
                        <InputComponent
                            testId="positionsItem_position_input"
                            inputType={EActivityType.Dropdown}
                            value={item.positionId ?? ""}
                            options={positionOptions}
                            name="positionId"
                            filterIds={archivedIds}
                            className="positions-item--position-id"
                            invalid={Boolean(errors && errors["positionId"])}
                            errors={errors && errors["positionId"]}
                            hideIcon
                            onChange={handleOnChange}
                        />

                        <IconButton
                            testId="positionsItem_save_btn"
                            className="positions-item--save-btn"
                            variant="success"
                            border
                            onClick={handleOnSave}
                        >
                            <CheckmarkIcon />
                        </IconButton>

                        <IconButton
                            testId="positionsItem_undo_btn"
                            className="positions-item--undo-btn"
                            onClick={handleOnRevert}
                        >
                            <UndoButton />
                        </IconButton>
                    </div>

                    <div className="positions-item--row">
                        <InputComponent
                            testId="positionsItem_startDate_input"
                            inputType={EActivityType.DateTime}
                            value={item.startDate}
                            name="startDate"
                            placeholder={t(k.START_DATE)}
                            hideIcon
                            shouldCloseOnSelect
                            showTimeSelect={false}
                            dateFormat="MMMM d, y"
                            invalid={Boolean(errors && errors["startDate"])}
                            errors={errors && errors["startDate"]}
                            onChange={handleOnChange}
                        />

                        <InputComponent
                            testId="positionsItem_endDate_input"
                            inputType={EActivityType.DateTime}
                            value={item.isCurrent ? null : item?.endDate}
                            name="endDate"
                            placeholder={
                                item.isCurrent ? t(k.PRESENT) : t(k.END_DATE)
                            }
                            disabled={item?.isCurrent}
                            hideIcon
                            shouldCloseOnSelect
                            showTimeSelect={false}
                            dateFormat="MMMM d, y"
                            onChange={handleOnChange}
                        />
                    </div>

                    <div className="positions-item--row">
                        <InputComponent
                            testId="positionsItem_isCurrent_btn"
                            inputType={EActivityType.Checkbox}
                            value={item.isCurrent ?? false}
                            label={t(k.CURRENT_POSITION)}
                            name="isCurrent"
                            hideIcon
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
            ) : (
                <div className="positions-item--preview">
                    <InputWrapper wrapperLabel={positionLabel} boldLabel>
                        <PositionsDateView
                            startDate={item.startDate}
                            endDate={
                                item.isCurrent
                                    ? undefined
                                    : item.endDate
                                      ? item.endDate
                                      : undefined
                            }
                        />
                    </InputWrapper>

                    {canEdit && (
                        <div className="positions-item--btns">
                            <IconButton
                                testId="positionsItem_edit_btn"
                                disabled={item.isDelete}
                                onClick={handleOnEdit}
                            >
                                <EditIcon variant="gray" />
                            </IconButton>
                            <IconButton
                                onClick={handleOnDelete}
                                testId="positionsItem_remove_btn"
                            >
                                {item.isDelete ? <MdReplay /> : <TrashIcon />}
                            </IconButton>
                        </div>
                    )}
                </div>
            )}

            {error && (
                <div className="positions-item--pos-error">
                    <ValidationLabel
                        errors={
                            typeof error === "string"
                                ? t(k[error as keyof typeof k]) ?? error
                                : error.map((x) => (
                                      <div key={x}>
                                          {t(k[x as keyof typeof k]) ?? error}
                                      </div>
                                  ))
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default PositionsItem;
