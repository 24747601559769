import "./TeamsList.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";

import k from "i18n/keys";

import Badge from "../badges/Badge";
import RemoveButton from "../buttons/remove-button/RemoveButton";
import DropdownButton from "../dropdown/DropdownButton";
import { IDropdownOption } from "../dropdown/api/IDropdownOption";
import { TeamIcon } from "../icons";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "../input-components/dropdown/api/hooks";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    isTeamEveryone?: boolean;
    icon?: React.ReactNode | null;

    teamIds?: string[];

    editMode?: boolean;

    clearable?: boolean;

    onChange?: (
        team: IValueLabelItem<string, string>,
        remove?: boolean,
    ) => void;
}

const TEAM_EVERYONE = "teamEveryone";

const TeamsListEditable: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        isTeamEveryone,
        icon,
        editMode,
        teamIds,
        clearable = false,
    } = props;

    const { t, i18n } = useTranslation();

    const { data } = useSharedOptions(true, ESharedOptionsType.team, teamIds);

    const selectedTeams = useMemo(() => {
        let result: IValueLabelItemWithState[] = isTeamEveryone
            ? [{ value: TEAM_EVERYONE, label: t(k.EVERYONE) }]
            : [];

        if (teamIds) {
            result = result.concat(
                teamIds.reduce<IValueLabelItemWithState[]>((acc, id) => {
                    const team = data?.find((x) => x.value === id);

                    if (team) {
                        acc.push({
                            value: id,
                            label: team.label,
                            isArchived: team.isArchived ?? false,
                            isDeleted: team.isDeleted ?? false,
                        });
                    }

                    return acc;
                }, []),
            );
        }

        return result;
    }, [teamIds, isTeamEveryone, data]);

    const canRemoveItem = selectedTeams.length > 1 || clearable;

    const options = useMemo(() => {
        if (data) {
            let result: IDropdownOption[] = [
                {
                    value: TEAM_EVERYONE,
                    label: t(k.EVERYONE),
                    selected: isTeamEveryone,
                    disabled: isTeamEveryone && !canRemoveItem,
                },
            ];

            result = result.concat(
                data.reduce<IDropdownOption[]>((acc, x) => {
                    acc.push({
                        value: x.value,
                        label: x.label,
                        selected: teamIds?.includes(x.value),
                        disabled:
                            !isTeamEveryone &&
                            !canRemoveItem &&
                            teamIds?.includes(x.value),
                    });

                    return acc;
                }, []),
            );

            return result;
        }
    }, [teamIds, data, isTeamEveryone, canRemoveItem, i18n.language]);

    const onAdd = (id: string) => {
        const team = data?.find((x) => x.value === id);

        if (props.onChange) {
            if (team) {
                props.onChange(team);
            } else if (id === TEAM_EVERYONE) {
                props.onChange({ value: id, label: t(k.EVERYONE) });
            }
        }
    };

    const handleClickRemove = (e: React.UIEvent, id?: string) => {
        if (id) {
            onRemove(id);
        }
    };

    const onRemove = (id: string) => {
        const team = data?.find((x) => x.value === id);

        if (props.onChange) {
            if (team) {
                props.onChange(team, true);
            } else if (id === TEAM_EVERYONE) {
                props.onChange({ value: id, label: t(k.EVERYONE) }, true);
            }
        }
    };

    const onChange = (id: string) => {
        if (id === TEAM_EVERYONE) {
            isTeamEveryone ? onRemove(id) : onAdd(id);
        }

        const selectedTempTeam = selectedTeams?.find((x) => x.value === id);

        selectedTempTeam ? onRemove(id) : onAdd(id);
    };

    return (
        <div className="teams-list__wrapper">
            {icon !== null && (icon ?? <TeamIcon />)}

            <div className="teams-list--badges">
                {selectedTeams?.map((x) => (
                    <Badge key={x.value} pill variant={"info"}>
                        {x?.label}
                        {x.isArchived ? (
                            x.isDeleted ? (
                                <>&nbsp; ({t(k.DELETED)})</>
                            ) : (
                                <>&nbsp; ({t(k.ARCHIVED)})</>
                            )
                        ) : null}
                        {editMode && canRemoveItem && (
                            <RemoveButton
                                id={x.value}
                                onClick={handleClickRemove}
                            />
                        )}
                    </Badge>
                ))}
                {editMode && (
                    <DropdownButton
                        options={options}
                        buttonVariant="badge"
                        icon={
                            <div className="teams-list--add-icon">
                                <FiPlusCircle /> {t(k.ADD_TEAM)}
                            </div>
                        }
                        onChange={onChange}
                    />
                )}
            </div>
        </div>
    );
};

export default TeamsListEditable;
