import "./InputComponent.scss";
import React from "react";
import { IInputProps } from "./IInputProps";
import Checkbox, { ICheckboxProps } from "./checkbox/Checkbox";
import Datetime, { IDateTimeProps } from "./datetime/Datetime";
import CustomListDropdown, {
    ICustomListDropdownProps,
} from "./dropdown/CustomListDropdown";
import TextfieldComplex, {
    ITextfieldComplexProps,
} from "./textfield/TextfieldComplex";
import BarcodeField, { IBarcodeProps } from "./barcode/BarcodeField";
import ItemDropdown from "./dropdown/ItemDropdown";
import SupplierDropdown from "./dropdown/SupplierDropdown";
import CustomerDropdown from "./dropdown/CustomerDropdown";
import Dropdown, { IDropdownProps } from "./dropdown/Dropdown";
import Photofield from "./photofield/Photofield";
import Textarea from "./textarea/Textarea";
import Tasklist, { ITasklistProps } from "./checkbox/Tasklist";
import InputWrapper, { IInputWrapperProps } from "./InputWrapper";
import { EActivityType } from "./EActivityType";
import GenerateBarcodeField from "./barcode/GenerateBarcodeField";
import ValidationWrapper from "components/common/validation/ValidationWrapper";
import HyperlinkField from "./textfield/HyperlinkField";
import UserDropdown from "./dropdown/UserDropdown";
import PositionDropdown from "./dropdown/PositionDropdown";
import CompetencyDropdown from "./dropdown/CompetencyDropdown";

export type InputProps<
    V,
    L,
    P,
    T extends keyof inputTypePropTypes<V, L, P>,
> = inputTypePropTypes<V, L, P>[T] &
    IInputProps<V, P> &
    IInputWrapperProps & {
        inputType: T;
        beforeInput?: React.ReactNode;
        afterInput?: React.ReactNode;
        errors?: React.ReactNode;
    };

interface ComponentWithProps<V, P> {
    (props: IInputProps<V, P>): JSX.Element;
}

const componentsLookup: Record<EActivityType, any> = {
    [EActivityType.Textfield]: TextfieldComplex,
    [EActivityType.MultiLineTextfield]: Textarea,
    [EActivityType.Photo]: Photofield,
    [EActivityType.Dropdown]: Dropdown,
    [EActivityType.CustomerDropdown]: CustomerDropdown,
    [EActivityType.SupplierDropdown]: SupplierDropdown,
    [EActivityType.ItemDropdown]: ItemDropdown,
    [EActivityType.Barcode]: BarcodeField,
    [EActivityType.GenerateBarcode]: GenerateBarcodeField,
    [EActivityType.DateTime]: Datetime,
    [EActivityType.Checkbox]: Checkbox,
    [EActivityType.Tasklist]: Tasklist,
    [EActivityType.CustomList]: CustomListDropdown,
    [EActivityType.Hyperlink]: HyperlinkField,
    [EActivityType.UserDropdown]: UserDropdown,
    [EActivityType.PositionDropdown]: PositionDropdown,
    [EActivityType.CompetencyDropdown]: CompetencyDropdown,
};

const InputComponent = <V, L, P, T extends keyof inputTypePropTypes<V, L, P>>(
    props: InputProps<V, L, P, T>,
) => {
    const {
        htmlFor,
        inputType,
        wrapperIcon: icon,
        noIconResize,
        wrapperLabel,
        boldLabel,
        wrapperClassName,
        noMinWidth,
        fullWidth,
        titleErrors,
        errors,
        beforeInput,
        afterInput,
        wrapperInputTestId: inputTestId,
        wrapperLabelTestId,
        inputDirection,
        ...rest
    } = props;

    const Component: ComponentWithProps<V, P> = componentsLookup[inputType];

    return (
        <InputWrapper
            wrapperInputTestId={inputTestId}
            htmlFor={htmlFor ?? props.id}
            wrapperLabel={wrapperLabel}
            wrapperLabelTestId={wrapperLabelTestId}
            boldLabel={boldLabel}
            noIconResize={noIconResize}
            fullWidth={fullWidth}
            wrapperIcon={icon}
            required={props.required}
            noMinWidth={noMinWidth}
            className={wrapperClassName}
            inputDirection={inputDirection}
            titleErrors={titleErrors}
        >
            {beforeInput}

            <ValidationWrapper errors={errors}>
                <Component {...rest} />
            </ValidationWrapper>

            {afterInput}
        </InputWrapper>
    );
};

export default InputComponent;

interface inputTypePropTypes<V, L, P> {
    [EActivityType.Textfield]: ITextfieldComplexProps;
    [EActivityType.Checkbox]: ICheckboxProps;
    [EActivityType.Tasklist]: ITasklistProps;
    [EActivityType.Photo]: {};
    [EActivityType.MultiLineTextfield]: ITextfieldComplexProps;
    [EActivityType.Dropdown]: IDropdownProps<V, L, P>;
    [EActivityType.CustomerDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.SupplierDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.ItemDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.Barcode]: IBarcodeProps;
    [EActivityType.GenerateBarcode]: {};
    [EActivityType.DateTime]: IDateTimeProps;
    [EActivityType.Hyperlink]: ITextfieldComplexProps;
    [EActivityType.UserDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.PositionDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.CompetencyDropdown]: IDropdownProps<V, L, P>;
    [EActivityType.CustomList]: ICustomListDropdownProps<V, L, P>;
}
