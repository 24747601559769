import React, { useContext } from "react";
import CompleteStepCard from "./components/complete-step-card/CompleteStepCard";
import { useCompleteImprovementStepMutation } from "components/improvements/api/hooks";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import ICompleteImprovementStepRequest from "http/requests/ICompleteImprovementStepRequest";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";
import CompletedStepCard from "./components/completed-step-card/CompletedStepCard";
import "./CompleteStep.scss";

const FIXED_NUMBER_OF_STEPS = 4;
interface IProps {
    stepInstanceId?: string;
    disabled?: boolean;
    preview?: boolean;
    name?: string;
}

const CompleteStep: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { stepInstanceId, preview, disabled = preview, name } = props;

    const { stepInstances, setErrors, improvement, errors } = useContext(
        ImprovementsDetailsContext,
    );

    const completeImprovementStepMutation =
        useCompleteImprovementStepMutation();

    const handleOnSubmitClick = async (closureReason?: ClosureReasonEnum) => {
        if (stepInstanceId) {
            const request: ICompleteImprovementStepRequest = {
                stepInstanceId,
                closureReason: closureReason ?? ClosureReasonEnum.None,
            };
            const result = await completeImprovementStepMutation.mutateAsync({
                request,
            });

            if (result.Succeeded) {
                setErrors({});
            } else {
                setErrors(result.Messages);
            }
        }
    };

    const stepInstance = stepInstanceId
        ? stepInstances?.values[stepInstanceId]
        : undefined;

    const isCompleted = stepInstance?.status === StepInstanceStatus.Finalized;

    const isLastStep =
        stepInstances?.ids.indexOf(stepInstanceId ?? "") ===
        FIXED_NUMBER_OF_STEPS - 1;

    return (
        <div className="no-print complete-step">
            {isCompleted ? (
                <CompletedStepCard
                    isLastStep={isLastStep}
                    closureReason={improvement?.closureReason}
                    stepInstanceId={props.stepInstanceId}
                    isSaving={completeImprovementStepMutation.isLoading}
                    name={name}
                    onClick={handleOnSubmitClick}
                />
            ) : (
                <CompleteStepCard
                    isLastStep={isLastStep || preview}
                    isCompleted={isCompleted}
                    isSaving={completeImprovementStepMutation.isLoading}
                    disabled={disabled}
                    errors={errors}
                    name={name}
                    onClick={handleOnSubmitClick}
                />
            )}
        </div>
    );
};

export default CompleteStep;
