import { FC, useContext } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ChecklistDetailsContext, {
    ChecklistContextType,
} from "../../../checklistContext";

import ListItemsDropdown from "common/components/listed-content/components/SidePanel/List/components/ListItemsDropdown/ListItemsDropdown";
import ListItems, {
    EParentType,
} from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import ListedContent from "common/components/listed-content/ListedContent";

interface Props {}

const SidePanelList: FC<Props> = () => {
    const { mobileView } = useContext(ChecklistDetailsContext);
    const { t } = useTranslation();

    return (
        <ListedContent.SidePanel.List
            context={ChecklistDetailsContext}
            title={t(k.CHECKLIST_STEPS)}
        >
            {mobileView ? (
                <ListItemsDropdown<ChecklistContextType>
                    context={ChecklistDetailsContext}
                    parentType={EParentType.checklist}
                />
            ) : (
                <ListItems<ChecklistContextType>
                    context={ChecklistDetailsContext}
                    parentType={EParentType.checklist}
                />
            )}
        </ListedContent.SidePanel.List>
    );
};

export default SidePanelList;
