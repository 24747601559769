import { FC, useContext } from "react";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import WorksheetCreatedBy from "common/components/worksheet-createdby/WorksheetCreatedBy";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { useLocation } from "react-router-dom";
import ChecklistDetailsContext from "../../../../../checklistContext";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import "./StartedBy.scss";

interface IStartedByProps {}

const StartedBy: FC<IStartedByProps> = () => {
    const { checklist } = useContext(ChecklistDetailsContext);

    const { onShowModal } = useContext(ModalManagerContext);

    const location = useLocation();
    const { t } = useTranslation();

    const handlePreviewRecurrence = () => {
        if (checklist && checklist.scheduleId) {
            const payload: IShowModalPayload = {
                id: checklist.scheduleId,
                type: ModalTypes.recurrence,
                callerAddress: location.pathname,
            };
            onShowModal(payload);
        }
    };

    return checklist ? (
        <div className="started-by">
            <span className="started-by__label">{t(k.STARTED_BY)}</span>
            <WorksheetCreatedBy
                value={checklist}
                onShowRecurrence={handlePreviewRecurrence}
            />
        </div>
    ) : null;
};

export default StartedBy;
