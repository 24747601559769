import React, { useLayoutEffect } from "react";
import { CellProps } from "react-datasheet-grid";
import Select, { GroupBase, SelectInstance } from "react-select";

import { IValueLabelItem } from "common/IValueLabelItem";
import { SelectOptions } from "../../api/SelectOptions";
import { buildStyles } from "../../styles";

function selectComponent(
    props: CellProps<IValueLabelItem | null, SelectOptions>,
) {
    const { active, rowData, setRowData, focus, stopEditing, columnData } =
        props;

    const ref =
        React.useRef<
            SelectInstance<IValueLabelItem, false, GroupBase<IValueLabelItem>>
        >(null);

    useLayoutEffect(() => {
        if (focus) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }
    }, [focus]);

    const isImpactedProcessMode = columnData.mode === "impacted-processes";

    const styles = React.useMemo(
        () =>
            buildStyles({
                active,
                focus,
                isImpactedProcess: isImpactedProcessMode,
            }),
        [active, focus, isImpactedProcessMode],
    );

    return (
        <Select
            ref={ref}
            styles={styles}
            isDisabled={columnData.disabled}
            value={rowData ?? null}
            menuPortalTarget={document.body}
            menuIsOpen={focus}
            onChange={(choice) => {
                if (choice === null) return;

                if ("value" in choice) {
                    setRowData(choice);
                }
            }}
            onMenuClose={() => stopEditing({ nextRow: false })}
            options={columnData.choices}
        />
    );
}

export const SelectComponent = React.memo(
    selectComponent,
) as typeof selectComponent;
