import "./AddPropertyButton.scss";

import React, { useRef, useState } from "react";
import Button from "common/components/buttons/Button";
import CloseSvg from "./assets/close.svg";
import AddSvg from "./assets/add.svg";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import PropertyList from "components/common/custom-list-property-button/components/PropertyList";

import PropertyListItem, {
    IPropertyListItemProps,
} from "components/common/custom-list-property-button/components/PropertyListItem";
import { IAvailableAppFeatureProperties } from "models/dto/IProfileDTO";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import { IAddActivityParam } from "components/steps/api/IAddActivityParam";
import { getLocaleString } from "i18n/components/GetLocaleString";
import Tooltip from "common/components/tooltip/Tooltip";
import { ActivityType, activityTypeStrings } from "models/enums/ActivityType";

export interface IListItem<T>
    extends Omit<IPropertyListItemProps<T>, "onSelect"> {
    appFeature?: keyof IAvailableAppFeatureProperties;
}

export interface IListData<T> {
    items: IListItem<T>[];
    sectionTitle: string;
}

interface IProps<T> {
    listData: IListData<T>[];

    index: number;
    isDisabled: boolean;
    isBlur: boolean;
    testId?: number;
    invalid: boolean;

    buttonTitle: string;

    showList: (index: number | undefined) => void;

    onSelectProperty: (item: IAddActivityParam<T>, index: number) => void;
}

const AddPropertyButton = <T,>(props: React.PropsWithChildren<IProps<T>>) => {
    const {
        listData,
        testId,
        index,
        isDisabled,
        isBlur,
        invalid,
        buttonTitle,
    } = props;

    const appFeatures = useSelector(
        (state: IAppState) => state.authViewState.profile?.appFeatures,
    );

    const { t } = useTranslation();

    const [isListVisible, setIsListVisible] = useState(false);

    const sectionRef = useRef<HTMLDivElement>(null);

    const invokeShowList = (show: boolean) => {
        if (show && sectionRef.current) {
            setTimeout(
                () =>
                    sectionRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    }),
                50,
            );
        }

        props.showList(show ? index : undefined);
    };

    const handleShowList = () => {
        invokeShowList(!isListVisible);

        setIsListVisible((prev) => !prev);
    };

    const handleOnSelect = () => {
        invokeShowList(false);

        setIsListVisible(false);
    };

    const handleSelectProperty = (type: T, title: string, id?: string) => {
        props.onSelectProperty({ type, label: title, id }, index);

        handleOnSelect();
    };

    return (
        <div
            className={
                isBlur
                    ? "property-button__wrapper no-opacity property-button__wrapper--blur"
                    : isListVisible
                      ? "property-button__wrapper no-opacity"
                      : "property-button__wrapper"
            }
            ref={sectionRef}
            data-testid={testId}
        >
            <div className={`property-button${invalid ? " invalid" : ""}`}>
                <hr />
                <Button
                    variant={invalid ? "danger" : "blue"}
                    transparent
                    disabled={isDisabled}
                    onClick={handleShowList}
                    icon={isListVisible ? <CloseSvg /> : <AddSvg />}
                    testId={`add-property-btn-${index}`}
                >
                    {t(k[buttonTitle as keyof typeof k])}
                </Button>
                <hr />
            </div>

            {isListVisible && (
                <div className="property-button__list">
                    {listData.map((list) => (
                        <PropertyList
                            key={list.sectionTitle}
                            title={getLocaleString(t, list.sectionTitle)}
                        >
                            {list.items.map((item) => {
                                if (
                                    item.appFeature
                                        ? appFeatures?.[item.appFeature]
                                        : true
                                ) {
                                    const title = k[
                                        item.title as keyof typeof k
                                    ]
                                        ? t(k[item.title as keyof typeof k])
                                        : item.title;

                                    const subTitle = k[
                                        item.subTitle as keyof typeof k
                                    ]
                                        ? t(k[item.subTitle as keyof typeof k])
                                        : item.subTitle;

                                    const testIdName =
                                        activityTypeStrings[
                                            item.type as keyof typeof activityTypeStrings
                                        ];
                                    return (
                                        <Tooltip
                                            key={
                                                item.id ?? (item.type as string)
                                            }
                                            id={
                                                item.id ?? (item.type as string)
                                            }
                                            message={
                                                <>
                                                    <span>{title}</span>
                                                    <br />
                                                    <span>{subTitle}</span>
                                                </>
                                            }
                                            variant="no-flex"
                                        >
                                            <PropertyListItem
                                                key={
                                                    item.id ??
                                                    (item.type as string)
                                                }
                                                type={item.type}
                                                id={item.id}
                                                testId={`property-btn-${testIdName}`}
                                                icon={item.icon}
                                                title={title}
                                                subTitle={subTitle}
                                                onSelect={handleSelectProperty}
                                            />
                                        </Tooltip>
                                    );
                                }
                            })}
                        </PropertyList>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AddPropertyButton;
