import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import IOperationalRiskAnalysisFilter from "./IOperationalRiskAnalysisFilter";
import IOperationalRiskAnalysisRowDTO from "./IOperationalRiskAnalysisRowDTO";
import { IValueLabelItem } from "common/IValueLabelItem";

import ITableSet from "http/ITableSet";

import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { convertToDate } from "common/utils/time";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import IDictionary from "common/viewModels/IDictionary";
import { IProcessStepRiskDTO } from "components/process-chart/process-step/api/IProcessStepRiskDTO";
import IProcessStepRiskFilter from "components/process-chart/process-step/api/IProcessStepRiskFilter";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

const baseUrl = "/api/operational-risk-analysis";

async function fetchOptions(
    url: string,
    localeId: string,
    signal?: AbortSignal,
) {
    const response = await axios.get<IValueLabelItem<string, string>[]>(url, {
        signal,
        params: { localeId },
    });

    const values = response.data.reduce<
        IDictionary<IValueLabelItem<string, string>>
    >((map, val) => {
        map[val.value] = val;

        return map;
    }, {});

    const result: ITableSetWithValueLabelOptions<string, undefined, string> = {
        ids: response.data.map((x) => x.value),
        values,
        options: response.data,
    };

    return result;
}

export const useYesNoSelectionOptions = (localeId: string) => {
    const url = `${baseUrl}/yes-no-selection-options`;

    return useQuery(["yes-no-selection-options", localeId], (context) =>
        fetchOptions(url, localeId, context.signal),
    );
};

export const useOccurenceOptions = (localeId: string) => {
    const url = `${baseUrl}/occurence-options`;

    return useQuery(["occurence-options", localeId], (context) =>
        fetchOptions(url, localeId, context.signal),
    );
};

export const useProbabilityOptions = (localeId: string) => {
    const url = `${baseUrl}/probability-options`;

    return useQuery(["probability-options", localeId], (context) =>
        fetchOptions(url, localeId, context.signal),
    );
};

export const useRiskStatusOptions = (localeId: string) => {
    const url = `${baseUrl}/risk-status-options`;

    return useQuery(["risk-status-options", localeId], (context) =>
        fetchOptions(url, localeId, context.signal),
    );
};

function formatDates(value: IOperationalRiskAnalysisRowDTO) {
    value.date = convertToDate(value.date);

    return value;
}

const LIST_QUERY_KEY = "operational-risk-analysis-list";
const LIST_ITEM_QUERY_KEY = "operational-risk-analysis-list_item";

export const useOperationalRiskAnalysisList = (
    filter: IOperationalRiskAnalysisFilter,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/list`;

    return useQuery(
        [LIST_QUERY_KEY, filter],
        async (context) => {
            const response = await axios.post<
                ITableSet<IOperationalRiskAnalysisRowDTO>
            >(url, filter, {
                signal: context.signal,
            });

            for (const id of response.data.ids) {
                const item = response.data.values[id];

                if (item) {
                    response.data.values[id] = formatDates(item);
                }
            }

            return response.data;
        },
        { enabled: Boolean(enabled) },
    );
};

export const useOperationalRiskAnalysisGetById = (
    enabled: boolean,
    id?: string,
    localeId?: string,
) => {
    const url = `${baseUrl}/by-id-locale/${id}/${localeId}`;

    return useQuery(
        [LIST_ITEM_QUERY_KEY, id],
        async (context) => {
            const response = await axios.post<IOperationalRiskAnalysisRowDTO>(
                url,
                {
                    signal: context.signal,
                },
            );

            return formatDates(response.data);
        },
        {
            enabled: enabled && Boolean(id) && Boolean(localeId),
        },
    );
};

const RISKS_PROCESS_CHART = "risks-for-process-chart";
const RISKS_PROCESS_STEP_KEY = "risks-by-process-step-id";

export const useRisksByProcessStepId = (
    filter: IProcessStepRiskFilter,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/list-by-process-step-id`;

    return useQuery(
        [RISKS_PROCESS_CHART, RISKS_PROCESS_STEP_KEY, filter],
        async (context) => {
            const response = await axios.post<IProcessStepRiskDTO[]>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
        { enabled: Boolean(enabled) && Boolean(filter.id) },
    );
};

const RISKS_SUB_PROCESS_KEY = "risks-by-sub-process-id";

export const useRisksBySubProcessId = (
    id?: string,

    enabled?: boolean,
) => {
    const url = `${baseUrl}/list-by-sub-process-id/${id}`;

    return useQuery(
        [RISKS_PROCESS_CHART, RISKS_SUB_PROCESS_KEY, id],
        async (context) => {
            const response = await axios.post<
                IValueLabelItemWithState<number>[]
            >(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: Boolean(enabled) && Boolean(id) },
    );
};

const RISKS_PROCESS_KEY = "risks-by-process-id";

export const useRisksByProcessId = (
    id?: string,

    enabled?: boolean,
) => {
    const url = `${baseUrl}/list-by-process-id/${id}`;

    return useQuery(
        [RISKS_PROCESS_CHART, RISKS_PROCESS_KEY, id],
        async (context) => {
            const response = await axios.post<
                IValueLabelItemWithState<number>[]
            >(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: Boolean(enabled) && Boolean(id) },
    );
};

export const useDeleteOperationalRiskAnalysisMutation = () => {
    return useMutation(async (variables: { id: string }) => {
        try {
            await axios.delete(`${baseUrl}/${variables.id}`);

            return true;
        } catch (ex) {}

        return false;
    });
};

export const useArchiveOperationalRiskAnalysisMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { request: IArchiveRequest }) => {
            const result = await axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            );

            return Boolean(result.data.archivedAt);
        },
        {
            onSuccess: (_, variables) => {
                queryClient.removeQueries([
                    LIST_ITEM_QUERY_KEY,
                    variables.request.id,
                ]);
            },
        },
    );
};

export const useSaveOperationalRiskAnalysisMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: {
            isCreate: boolean;
            value: IOperationalRiskAnalysisRowDTO;
            filter: IOperationalRiskAnalysisFilter;
        }) => {
            try {
                let result: IOperationalRiskAnalysisRowDTO;

                const { isCreate, value } = variables;

                if (isCreate) {
                    const response =
                        await axios.post<IOperationalRiskAnalysisRowDTO>(
                            baseUrl,
                            value,
                        );

                    result = formatDates(response.data);
                } else {
                    const response =
                        await axios.put<IOperationalRiskAnalysisRowDTO>(
                            baseUrl,
                            value,
                        );

                    result = formatDates(response.data);
                }

                return ExecutionResult.Result<IOperationalRiskAnalysisRowDTO>(
                    result,
                );
            } catch (error) {
                return ExecutionResult.Failed<IOperationalRiskAnalysisRowDTO>(
                    error,
                );
            }
        },
        {
            onSuccess: (data, variables) => {
                const newValue = data.Data;

                if (newValue) {
                    if (variables.isCreate) {
                        queryClient.setQueryData<
                            ITableSet<IOperationalRiskAnalysisRowDTO>
                        >([LIST_QUERY_KEY, variables.filter], (prev) =>
                            prev
                                ? {
                                      ...prev,

                                      ids: [newValue.id].concat(prev.ids),

                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },
                                  }
                                : { ids: [], values: {} },
                        );
                    } else {
                        queryClient.removeQueries([
                            LIST_ITEM_QUERY_KEY,
                            newValue.id,
                        ]);

                        queryClient.setQueryData<
                            ITableSet<IOperationalRiskAnalysisRowDTO>
                        >([LIST_QUERY_KEY, variables.filter], (prev) =>
                            prev
                                ? {
                                      ...prev,
                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },
                                  }
                                : { ids: [], values: {} },
                        );
                    }

                    queryClient.invalidateQueries([RISKS_PROCESS_CHART]);
                }
            },
        },
    );
};

export async function importOperationalRiskAnalysisMutation(
    data: IOperationalRiskAnalysisRowDTO[],
) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
