import { Context, useContext } from "react";

import { StylesConfig } from "react-select";
import { ActionMeta, OnChangeValue } from "react-select";

import { current } from "@reduxjs/toolkit";
import { IValueLabelItem } from "common/IValueLabelItem";
import ICurrentSectionData from "common/components/current-scrolled-section/models/ICurrentSectionData";
import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import IStepItemListing from "common/components/listed-content/models/IStepItemListing";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import { CONFIGURATION_STEP_ID } from "components/improvement-forms/components/ImprovementForm/context/ImprovementFormData";
import k from "i18n/keys";

import { EParentType } from "../ListItems/ListItems";
import StepInstanceListDropdownValue from "../StepInstanceListDropdownValue";
import StepListDropdownValue from "../StepListDropdownValue";

import "./ListItemDropdown.scss";

interface IProps<
    T extends IStepItemListing & ListedContentContextType & ICurrentSectionData,
> {
    context: Context<T>;
    parentType?: EParentType;
}

const singleSelectDropdownStyle: StylesConfig<OptionType, boolean> = {
    singleValue: (styles) => ({
        ...styles,
        position: "relative",
        top: "0",
        width: "100%",
        transform: "none",
        whiteSpace: "break-spaces",
    }),
};

const ListItemsDropdown = <
    T extends IStepItemListing & ListedContentContextType & ICurrentSectionData,
>(
    props: IProps<T>,
) => {
    const {
        stepInstances,
        steps,
        listItemDropdownRef,
        sectionRefs,
        currentSection,
        scrollToTarget,
    } = useContext(props.context);

    const onSelect = (
        value: OnChangeValue<
            IValueLabelItem<string | number, string | JSX.Element>,
            boolean
        >,
        meta: ActionMeta<
            IValueLabelItem<string | number, string | JSX.Element>
        >,
    ) => {
        const step = value as IValueLabelItem<string, JSX.Element>;

        if (sectionRefs && scrollToTarget) {
            scrollToTarget(step.value, sectionRefs);
        }
    };

    const additionalDropdownValues = [
        {
            value: CONFIGURATION_STEP_ID,
            label: (
                <StepListDropdownValue
                    name={k.CONFIGURATION}
                    isCurrent={CONFIGURATION_STEP_ID === currentSection}
                    parentType={EParentType.improvement}
                />
            ),
        } as IValueLabelItem<string, JSX.Element>,
    ];

    return (
        <div
            className="list-items-dropdown side-panel--sticky"
            ref={listItemDropdownRef}
        >
            <>
                {stepInstances && (
                    <SelectDropdown
                        value={
                            currentSection
                                ? ({
                                      value: currentSection,
                                      label: (
                                          <StepInstanceListDropdownValue
                                              stepInstance={
                                                  stepInstances.values[
                                                      currentSection
                                                  ]
                                              }
                                              index={
                                                  stepInstances.ids.indexOf(
                                                      currentSection,
                                                  ) + 1
                                              }
                                              isCurrent
                                              parentType={props.parentType}
                                          />
                                      ),
                                  } as IValueLabelItem<string, JSX.Element>)
                                : undefined
                        }
                        options={
                            stepInstances.ids.map((x, index) => {
                                return {
                                    value: x,
                                    label: (
                                        <StepInstanceListDropdownValue
                                            stepInstance={
                                                stepInstances.values[x]
                                            }
                                            index={index + 1}
                                            isCurrent={x === currentSection}
                                            parentType={props.parentType}
                                        />
                                    ),
                                } as IValueLabelItem<string, JSX.Element>;
                            }) as IValueLabelItem<string, JSX.Element>[]
                        }
                        onChange={onSelect}
                        isSearchable={false}
                        styles={singleSelectDropdownStyle}
                    />
                )}
                {steps && (
                    <SelectDropdown
                        value={
                            currentSection
                                ? currentSection === CONFIGURATION_STEP_ID
                                    ? additionalDropdownValues[0]
                                    : ({
                                          value: currentSection,
                                          label: (
                                              <StepListDropdownValue
                                                  step={
                                                      steps.values[
                                                          currentSection
                                                      ]
                                                  }
                                                  index={
                                                      steps.ids.indexOf(
                                                          currentSection,
                                                      ) + 1
                                                  }
                                                  isCurrent
                                                  parentType={props.parentType}
                                              />
                                          ),
                                      } as IValueLabelItem<string, JSX.Element>)
                                : undefined
                        }
                        options={
                            EParentType.improvement
                                ? additionalDropdownValues.concat(
                                      steps.ids.map((x, index) => {
                                          return {
                                              value: x,
                                              label: (
                                                  <StepListDropdownValue
                                                      step={steps.values[x]}
                                                      index={index + 1}
                                                      isCurrent={
                                                          x === currentSection
                                                      }
                                                      parentType={
                                                          props.parentType
                                                      }
                                                  />
                                              ),
                                          } as IValueLabelItem<
                                              string,
                                              JSX.Element
                                          >;
                                      }) as IValueLabelItem<
                                          string,
                                          JSX.Element
                                      >[],
                                  )
                                : (steps.ids.map((x, index) => {
                                      return {
                                          value: x,
                                          label: (
                                              <StepListDropdownValue
                                                  step={steps.values[x]}
                                                  index={index + 1}
                                                  isCurrent={
                                                      x === currentSection
                                                  }
                                                  parentType={props.parentType}
                                              />
                                          ),
                                      } as IValueLabelItem<string, JSX.Element>;
                                  }) as IValueLabelItem<string, JSX.Element>[])
                        }
                        onChange={onSelect}
                        isSearchable={false}
                        styles={singleSelectDropdownStyle}
                    />
                )}
            </>
        </div>
    );
};

export default ListItemsDropdown;
