import "./ActivityTitlePreview.scss";

import { ActivityType } from "models/enums/ActivityType";
import IActivity from "models/IActivity";
import ActivityDetails from "../ActivityDetails";

interface IProps {
    id?: string;
    className?: string;
    order: string;
    defaultLabel: string;
    hasError: boolean;
    activity: IActivity;
}

export const ActivityTitlePreview = (props: IProps) => {
    const { className, order, defaultLabel, hasError, activity, id } = props;

    return (
        <div className={`activity-title--preview ${className ?? ""}`}>
            {order}{" "}
            {activity.label.length === 0 ? (
                <i>{defaultLabel}</i>
            ) : (
                activity.label
            )}
            <ActivityDetails
                id={id}
                isRequired={
                    activity.isRequired &&
                    activity.type !== ActivityType.Tasklist
                }
                hasError={hasError}
            />
        </div>
    );
};
