import React, { useMemo } from "react";

import {
    useTableContext,
    TableRowContainerContext,
    ITableRowContext,
} from "./Context";

interface IProps {
    innerRef?: React.LegacyRef<HTMLTableSectionElement>;
}

const TableBodyComponent: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const context = useTableContext();

    const contextValue = useMemo(() => {
        const result: ITableRowContext = {
            isLoading: context?.isLoading,
            canExpandRow: context?.canExpandRow,
            columnsCount: context?.columnsCount,
            isHeaderVerticalAlign: context?.isHeaderVerticalAlign,
            columnWidths: context?.columnWidths,
            resizingKey: context?.resizingKey,
            startResizing: context?.startResizing,
            stopResizing: context?.stopResizing,
            columnsHeaders: context?.columnsHeaders,
        };

        return result;
    }, [
        context?.isLoading,
        context?.canExpandRow,
        context?.columnsCount,
        context?.columnWidths,
        context?.resizingKey,
        context?.columnsHeaders,
    ]);

    const count = React.Children.count(props.children);

    return count > 0 ? (
        <TableRowContainerContext.Provider value={contextValue}>
            <tbody className="app-table--body" ref={props.innerRef}>
                {props.children}
            </tbody>
        </TableRowContainerContext.Provider>
    ) : null;
};

export default TableBodyComponent;
