import "./ActionTags.scss";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import DeleteActivityInstanceButton from "common/components/activity-instance-card/components/DeleteActivityInstanceButton/DeleteActivityInstanceButton";
import EditActivityInstanceButton from "common/components/activity-instance-card/components/EditActivityInstanceButton/EditActivityInstanceButton";
import AvatarCompletedBy from "common/components/avatar/AvatarCompletedBy";
import DeviationButton from "common/components/buttons/deviation-button/DeviationButton";
import SelectUsersDropdown from "common/components/select-users/SelectUsersDropdown";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import { usePublishedUserOptions } from "components/users/api/hooks";

interface IProps {
    editMode?: boolean;
    isCompleted?: boolean;
    completedBy?: string;
    disabled?: boolean;
    assignedUserIds?: string[];
    completedAt?: Date;
    onDelete?: (id?: string) => void;
    onAssignUser: (id: string) => void;
    onEdit?: (edit?: boolean) => void;
    onNewImprovement?: () => void;
}

const ActionTags = (props: IProps) => {
    const {
        completedBy,
        completedAt,
        isCompleted,
        assignedUserIds,
        editMode,
        disabled,
    } = props;

    const { data: publishedUsers } = usePublishedUserOptions();

    const selectedUsers = useMemo(
        () =>
            publishedUsers?.options?.filter((user) =>
                assignedUserIds?.includes(user.value),
            ),
        [publishedUsers, assignedUserIds],
    );

    const showAvatars = !isCompleted;
    const showCompleted = isCompleted;
    const showUserDropdown = !isCompleted && !disabled;
    const showDeviationBtn = !editMode && !disabled;
    const showDeleteBtn = !disabled;

    return (
        <div className="input-actions-tags">
            {showAvatars && (
                <SelectedUsersAvatarList
                    users={selectedUsers ?? []}
                    usersAffected={undefined}
                    maxShownAvatars={3}
                />
            )}
            {showCompleted && (
                <AvatarCompletedBy
                    completedBy={completedBy}
                    completedAt={completedAt}
                />
            )}

            {showUserDropdown && (
                <SelectUsersDropdown
                    onSelect={props.onAssignUser}
                    selectedUserIds={assignedUserIds ?? []}
                    users={publishedUsers?.options ?? []}
                />
            )}
            {showDeviationBtn && (
                <DeviationButton
                    onClick={props.onNewImprovement}
                    className="no-print"
                />
            )}
            {showDeleteBtn && (
                <DeleteActivityInstanceButton onClick={props.onDelete} />
            )}
        </div>
    );
};

export default ActionTags;
