import { attr, fk, Model, ModelWithFields, TableState } from "redux-orm";

import IAttachmentDTO from "../dto/IAttachmentDTO";
import IAttachment from "../IAttachment";

class Attachment extends Model<IAttachment> {
    public static modelName = "Attachment";

    public static fields = {
        id: attr(),
        attachmentType: attr(),
        name: attr(),
        index: attr(),
        content: attr(),
        activity: fk("Activity", "attachments"),
    };

    public static parseDTO(data: IAttachmentDTO): ModelWithFields<IAttachment> {
        return this.upsert(data);
    }
}

export type AttachmentOrmModelType = typeof Attachment;

export type AttachmentOrmState = TableState<IAttachment>;

export default Attachment;
