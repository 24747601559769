import IconButton from "common/components/buttons/icon-button/IconButton";
import { EditIcon } from "common/components/icons";
import React from "react";

interface IEditButtonProps {
    disabled: boolean;
    onClick?: (edit?: boolean, id?: string) => void;
    id?: string;
}

const EditActivityInstanceButton: React.FC<IEditButtonProps> = (props) => {
    const { disabled, onClick, id } = props;

    const handleOnClick = () => {
        onClick?.(!disabled, id);
    };

    return (
        <IconButton
            onClick={handleOnClick}
            background="dark"
            disabled={disabled}
            testId="editActivityInstanceBtn"
        >
            <EditIcon variant="blue" />
        </IconButton>
    );
};

export default EditActivityInstanceButton;
