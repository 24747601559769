import "../value/ValueFilter.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { FilterDropdown } from "common/components/table/components/filtering/filter-dropdown/FilterDropdown";
import Textfield from "common/components/input-components/textfield/Textfield";
import { ActivityType } from "models/enums/ActivityType";
import React from "react";
import { isStringEmpty } from "../utils";
import { ValueActivityFilterType } from "../api/ValueActivityFilterType";
import { IFilterProps } from "../api/IFilterProps";

interface IProps extends IFilterProps {}

function getInitialValue() {
    const result: ValueActivityFilterType = {
        searchKeyword: "",
    };

    return result;
}

export const TextFilter = (props: IProps) => {
    const { value, testId } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(value?.valueFilter !== undefined);
    const [isEdited, setIsEdited] = useState(false);

    const [filterValue, setFilterValue] = useState<ValueActivityFilterType>(
        value?.valueFilter ?? getInitialValue(),
    );

    React.useEffect(() => {
        if (!isOpen) {
            setFilterValue(value?.valueFilter ?? getInitialValue());
        }
    }, [isOpen]);

    const handleReset = () => {
        setFilterValue(getInitialValue());

        setIsEdited(false);

        setIsActive(false);

        props.onFilter({
            type: ActivityType.Text,

            isReset: true,
        });
    };

    const tryHandleReset = (value: string) => {
        const isEmpty = isStringEmpty(value);

        if (isEmpty) {
            setIsEdited(false);
        }
    };

    const handleFilter = (isReset?: boolean) => {
        if (isReset) {
            handleReset();
        } else {
            const isEmpty = isStringEmpty(filterValue.searchKeyword);

            setIsEdited(!isEmpty);

            setIsActive(!isEmpty);

            const nextValue: ValueActivityFilterType = {
                searchKeyword: (filterValue.searchKeyword ?? "").trim(),
            };

            props.onFilter({
                type: ActivityType.Text,

                valueFilter: nextValue,

                isReset: isEmpty,
            });
        }
    };

    const handleChange = (value: string) => {
        setFilterValue((prev) => ({ ...prev, searchKeyword: value }));

        setIsEdited(true);

        tryHandleReset(value);
    };

    return (
        <FilterDropdown
            isEdited={isEdited}
            isActive={isActive}
            testId={testId}
            onFilter={handleFilter}
            onToggle={setIsOpen}
        >
            <div className="text-filter">
                <Textfield
                    value={filterValue.searchKeyword ?? ""}
                    labelContent={t(k.FILTER_ALL_WHICH_CONTAINS)}
                    placeholder={t(k.SEARCH)}
                    size="large"
                    name="searchKeyword"
                    onChange={handleChange}
                />
            </div>
        </FilterDropdown>
    );
};
