import ModalContent from "common/components/modal-content/ModalContent";
import { ModalTypes, IModalListItem } from "../api/IModalManager";
import ChecklistDetailsModal from "./modals/ChecklistDetailsModal";
import ImprovementsModal from "./modals/ImprovementsModal";
import SelectChecklistTemplateModal from "./modals/SelectChecklistTemplateModal";
import RecurringChecklistModal from "./modals/RecurringChecklistModal";
import TemplatePreviewModal from "./modals/TemplatePreviewModal";
import SelectImprovementFormModal from "./modals/SelectImprovementFormModal";
import ListingMultipleImprovementsModal from "./modals/ListingMultipleImprovementsModal";
import CustomListItemModal from "./modals/CustomListItemModal";
import CustomListFormModal from "./modals/CustomListFormModal";
import RiskFormModal from "./modals/RiskFormModal";
import TeamFormModal from "./modals/TeamFromModal";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import UserFormModal from "./modals/UserFormModal";
import HistoryModal from "./modals/HistoryModal";
import KpiFormModal from "./modals/KpiFormModal";
import TemplateFormModal from "./modals/TemplateFormModal";
import CustomerFormModal from "./modals/CustomerFormModal";
import ContactsFormModal from "./modals/ContactsFormModal";
import ChecklistReportModal from "./modals/ChecklistsReportModal";
import KpiDetailsModal from "./modals/KpiDetailsModal";
import GoalFormModal from "./modals/GoalFormModal";
import MeasurementFormModal from "./modals/MeasurementFormModal";
import MeasurementProgressFormModal from "./modals/MeasurementProgressFormModal";
import ImprovementFormModal from "./modals/ImprovementFormModal";
import ProcessStepModalPortal from "./modals/ProcessStepModalPortal";

interface IProps {
    modalItem: IModalListItem;
}

export const ModalByType: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    let modalComponent: any;

    const { t } = useTranslation();

    switch (props.modalItem.type) {
        case ModalTypes.checklist:
            modalComponent = <ChecklistDetailsModal {...props} />;
            break;

        case ModalTypes.template:
            modalComponent = <TemplatePreviewModal {...props} />;
            break;

        case ModalTypes.recurrence:
            modalComponent = <RecurringChecklistModal {...props} />;
            break;

        case ModalTypes.improvement:
            modalComponent = <ImprovementsModal {...props} />;
            break;

        case ModalTypes.process_step:
            modalComponent = <ProcessStepModalPortal {...props} />;
            break;

        case ModalTypes.select_checklist_template:
            modalComponent = <SelectChecklistTemplateModal {...props} />;
            break;

        case ModalTypes.select_improvement_form:
            modalComponent = <SelectImprovementFormModal {...props} />;
            break;

        case ModalTypes.multiple_improvements:
            modalComponent = <ListingMultipleImprovementsModal {...props} />;
            break;

        case ModalTypes.improvement_form:
            modalComponent = <ImprovementFormModal {...props} />;
            break;

        case ModalTypes.custom_list_form:
            modalComponent = <CustomListFormModal {...props} />;
            break;

        case ModalTypes.custom_list:
            modalComponent = <CustomListItemModal {...props} />;
            break;

        case ModalTypes.risk_form:
            modalComponent = <RiskFormModal {...props} />;
            break;

        case ModalTypes.team_form:
            modalComponent = <TeamFormModal {...props} />;
            break;

        case ModalTypes.user_form:
            modalComponent = <UserFormModal {...props} />;
            break;

        case ModalTypes.history:
            modalComponent = <HistoryModal {...props} />;
            break;

        case ModalTypes.kpi_form:
            modalComponent = <KpiFormModal {...props} />;
            break;

        case ModalTypes.kpi_details:
            modalComponent = <KpiDetailsModal {...props} />;
            break;

        case ModalTypes.customer_form:
            modalComponent = <CustomerFormModal {...props} />;
            break;

        case ModalTypes.contacts_form:
            modalComponent = <ContactsFormModal {...props} />;
            break;

        case ModalTypes.template_form:
            modalComponent = <TemplateFormModal {...props} />;
            break;

        case ModalTypes.checklist_report:
            modalComponent = <ChecklistReportModal {...props} />;
            break;

        case ModalTypes.goal_form:
            modalComponent = <GoalFormModal {...props} />;
            break;

        case ModalTypes.measurement_form:
            modalComponent = <MeasurementFormModal {...props} />;
            break;

        case ModalTypes.measurement_progress_form:
            modalComponent = <MeasurementProgressFormModal {...props} />;
            break;

        default:
            modalComponent = <div>{t(k.NOT_FOUND)}</div>;
    }

    return modalComponent;
};
