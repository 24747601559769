import "./CollapsableItem.scss";

import { PropsWithChildren, useEffect, useState } from "react";

interface IProps {
    label: string;
    collapsed?: boolean;
    collapseUpward?: boolean;
    hideWhenOpen?: boolean;

    onToggle?: (isCollapsed: boolean) => void;
}

const CollapsibleItem = (props: PropsWithChildren<IProps>) => {
    const {
        label,
        children,
        collapsed = true,
        collapseUpward = false,
        hideWhenOpen = false,
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    useEffect(() => {
        if (collapsed !== undefined) {
            setIsCollapsed(collapsed);
        }
    }, [collapsed]);

    const toggleCollapse = () => {
        props.onToggle?.(isCollapsed);
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="collapsable-item">
            {!(hideWhenOpen && !isCollapsed) && (
                <div
                    className={`collapsable-item--label${
                        collapseUpward ? " collapse-up" : ""
                    }`}
                    onClick={toggleCollapse}
                >
                    {label}
                </div>
            )}
            <div
                className={`collapsable-item--content${
                    isCollapsed ? " collapsed" : ""
                }`}
            >
                {children}
            </div>
        </div>
    );
};

export default CollapsibleItem;
