import "./FormattedDate.scss";

import React from "react";
import { format } from "date-fns";
import { AppLocale } from "AppLocale";

import { convertToDate } from "common/utils/time";

const DATE_FORMAT_NAME_MONTH = "d MMMM yyyy";
const DATE_FORMAT_NAME_MONTH_SHORT = "d MMM yyyy";
const DATE_FORMAT = "P";
const DATE_AND_TIME_FORMAT = "P p";
const DATE_AND_TIME_FORMAT_MONTH_SHORT = "d MMM yyyy H:mm";
const DATE_AND_TIME_FORMAT_12_HOUR = "d MMM yyyy, hh.mm a";
const TIME_FORMAT = "p";

interface IFormatDateProps {
    date?: Date;
    onlyDate?: boolean;
    showMonthName?: boolean;
    shortMonthName?: boolean;
    twelveHourTime?: boolean;
}

export const formatDate = ({
    date,
    onlyDate,
    showMonthName,
    shortMonthName,
    twelveHourTime,
}: IFormatDateProps) => {
    if (!date) return;

    if (!onlyDate) {
        if (showMonthName && shortMonthName) {
            return format(
                date,
                twelveHourTime
                    ? DATE_AND_TIME_FORMAT_12_HOUR
                    : DATE_AND_TIME_FORMAT_MONTH_SHORT,
                AppLocale,
            );
        }

        return format(date, DATE_AND_TIME_FORMAT, AppLocale);
    }

    if (!showMonthName) {
        return format(date, DATE_FORMAT, AppLocale);
    }

    return format(
        date,
        shortMonthName ? DATE_FORMAT_NAME_MONTH_SHORT : DATE_FORMAT_NAME_MONTH,
        AppLocale,
    );
};

interface IFormatDateAndSplitProps extends IFormatDateProps {
    splitDateTime?: boolean;
    useFlexWrapper?: boolean;
}

export const formatDateAndSplit = ({
    date,
    onlyDate,
    showMonthName,
    shortMonthName,
    twelveHourTime,
    splitDateTime,
}: IFormatDateAndSplitProps) => {
    if (!date) return;

    if (splitDateTime && !onlyDate) {
        const dateFormat = showMonthName
            ? shortMonthName
                ? DATE_FORMAT_NAME_MONTH_SHORT
                : DATE_FORMAT_NAME_MONTH
            : DATE_FORMAT;

        const formattedDate = format(date, dateFormat, AppLocale);

        const formattedTime = format(date, TIME_FORMAT, AppLocale);

        return { formattedDate, formattedTime };
    }

    return formatDate({
        date,
        onlyDate,
        showMonthName,
        shortMonthName,
        twelveHourTime,
    });
};

export const formatDateWithTypeCheck = ({
    date,
    ...rest
}: IFormatDateAndSplitProps) =>
    formatDateAndSplit({
        ...rest,
        date: date instanceof Date ? date : convertToDate(date),
    });

const FormattedDate: React.FC<
    React.PropsWithChildren<IFormatDateAndSplitProps>
> = (props) => {
    const result = formatDateWithTypeCheck(props);

    if (typeof result === "object" && result !== null && props.splitDateTime) {
        if (props.useFlexWrapper) {
            return (
                <div className="formatted-date">
                    <div className="formatted-date__date">
                        {result.formattedDate}
                    </div>
                    <div className="formatted-date__time">
                        {result.formattedTime}
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="formatted-date__date">
                    {result.formattedDate}
                </div>
                <div className="formatted-date__time">
                    {result.formattedTime}
                </div>
            </>
        );
    } else if (typeof result === "string") {
        return <>{result}</>;
    }

    return null;
};

export default FormattedDate;
