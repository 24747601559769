import { MonthsKeysLong } from "i18n/keySets/MonthKeys";
import { Months } from "models/enums/Months";

export const DEFAULT_DELAY_INTERVAL_MS = 1000;

export const convertToDate = (
    value: string | Date | undefined,
): Date | undefined => {
    return value ? new Date(value) : undefined;
};

export const convertToDateStrict = (value: string | Date): Date => {
    return new Date(value);
};

export const getTimeZone = (): string | undefined => {
    try {
        const ianaTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return ianaTimeZone;
    } catch (error) {}

    return undefined;
};

export const getEndOfDay = (date: Date) => {
    const d = new Date(date.getTime());

    d.setHours(23, 59, 59, 999);

    return d;
};

export const getStartOfDay = (date: Date) => {
    const d = new Date(date.getTime());

    d.setHours(0, 0, 0, 0);

    return d;
};

export const getStartOfNextDay = (date: Date | undefined) => {
    if (date) {
        const d = new Date(date.getTime() + 24 * 60 * 60 * 1000);

        d.setHours(0, 0, 0, 0);

        return d;
    }
};

export const getStartOfPrevDay = (date: Date | undefined) => {
    if (date) {
        const d = new Date(date.getTime() - 24 * 60 * 60 * 1000);

        d.setHours(0, 0, 0, 0);

        return d;
    }
};

export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
};

export const getMinutesBetweenDates = (startDate?: Date, endDate?: Date) => {
    if (startDate === undefined || endDate === undefined) {
        return undefined;
    }

    const diff = endDate.getTime() - startDate.getTime();

    return Math.abs(Math.round(diff / 60000));
};

export const getMonthAndYearAsTranslationKeysArray = (date?: Date) => {
    if (!date) {
        return ["", ""];
    }
    return [
        `${MonthsKeysLong[date.getMonth() as Months]}`,
        `${date.getFullYear()}`,
    ] as const;
};
