import i18n from "i18next";

import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";

export enum ItemCategoryType {
    Unit,
    Type,
}

export const getItemCategoryTypeOptions = (): Array<
    IValueLabelItem<number, string>
> => {
    return [
        { value: ItemCategoryType.Unit, label: i18n.t(k.ITEM_UNIT) },
        { value: ItemCategoryType.Type, label: i18n.t(k.ITEM_TYPE) },
    ];
};
