import "./ActivityInstanceCard.scss";

import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { ActivityType } from "models/enums/ActivityType";
import {
    IInputChangeEvent,
    IInputProps,
} from "../input-components/IInputProps";
import ActivityInstanceInput from "../input-components/ActivityInstanceInput";
import AvatarCompletedBy from "../avatar/AvatarCompletedBy";
import { RequiredStarIcon } from "../icons";
import DeviationButton from "../buttons/deviation-button/DeviationButton";
import DeleteInputValueConfirmModal from "../input-components/DeleteInputValueConfirmModal";
import UndoButton from "../buttons/undo-button/UndoButton";
import IconButton from "../buttons/icon-button/IconButton";
import TrashIcon from "../icons/icon-trash/TrashIcon";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import SelectedUsersAvatarList from "../select-users/SelectedUsersAvatarList";
import Editable from "./components/Editable/Editable";
import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import EditActivityInstanceButton from "./components/EditActivityInstanceButton/EditActivityInstanceButton";
import DeleteActivityInstanceButton from "./components/DeleteActivityInstanceButton/DeleteActivityInstanceButton";
import { IDropdownProps } from "../input-components/dropdown/Dropdown";
import { dropdownTypesWithSelectedIds } from "components/improvement-forms/components/ImprovementForm/utils";

export interface IActivityInstanceCardInputProps
    extends IInputProps<string>,
        IDropdownProps {
    type: ActivityType;

    isFinalized?: boolean;

    completed?: boolean;
    completedBy?: string;
    edit?: boolean;
    isUserDefined?: boolean;
    isCreate?: boolean;

    assignUsers?: React.ReactNode;
    assignedUsers?: IValueLabelItemWithState[];

    usersAffected?: string[];

    isFirst?: boolean;
    isHighlighted?: boolean;

    isSetCompleted?: boolean;
    acceptMultiple?: boolean;
    multiSelectEnabled?: boolean;
    isClearable?: boolean;

    isLoading?: boolean;
    removeTags?: boolean;

    onUndo?: () => void;
    onNewDeviationClick?: () => void;
    onAddNewActivity?: (id?: string, newLabel?: string) => void;
    onCancelAddActivityInstance?: () => void;
    onEditClick?: (edit?: boolean) => void;
    onDeleteActivityInstance?: () => void;
}

const ActivityInstanceCard = (props: IActivityInstanceCardInputProps) => {
    const {
        type,
        completed,
        required,
        isSetCompleted,
        isHighlighted,
        edit,
        isCreate,
        isLoading,
        multiSelectEnabled,
        removeTags,
    } = props;

    const [confirmVisible, setConfirmVisible] = useState(false);

    const { t } = useTranslation();

    const isTypeWithSelectedIds = React.useRef(
        dropdownTypesWithSelectedIds.has(type),
    ).current;

    const showRequiredNextToInput = type === ActivityType.Tasklist;

    const wrapTags =
        type !== ActivityType.Tasklist &&
        type !== ActivityType.Photo &&
        type !== ActivityType.Hyperlink;

    const isFinalized = props.isFinalized ?? false;

    const isPreview = isFinalized && completed;

    const handleUndo = () => {
        if (
            type === ActivityType.Text ||
            type === ActivityType.Barcodes ||
            type === ActivityType.GenerateBarcode ||
            type === ActivityType.Photo
        ) {
            setConfirmVisible(true);
        } else {
            props.onUndo?.();
        }
    };

    const handleUndoConfirmClose = (confirmValue: boolean) => {
        setConfirmVisible(false);

        if (confirmValue) {
            props.onUndo?.();
        }
    };

    const handleOnLabelChange = (id?: string, newLabel?: string) => {
        props.onAddNewActivity?.(undefined, newLabel);
    };

    const handleOnChange = (e: IInputChangeEvent<string, any>) => {
        if (isTypeWithSelectedIds) {
            props.onChange?.({
                id: e.id,
                name: e.name,
                selectedIds: multiSelectEnabled ? e.selectedIds : [e.value],
                value: "",
            });
        } else {
            props.onChange?.(e);
        }
    };

    const isNew = edit && isCreate;

    const className = isLoading
        ? "activity-instance-input activity-instance-input--loading"
        : "activity-instance-input";

    const isNotPlaceholderForSelection =
        type === ActivityType.Value || type === ActivityType.Text;

    const defaultPlaceholder = isNotPlaceholderForSelection
        ? t(k.ENTER_HERE)
        : t(k.SELECT);

    return (
        <div className={className}>
            {confirmVisible && (
                <DeleteInputValueConfirmModal
                    type={type}
                    onClose={handleUndoConfirmClose}
                />
            )}

            <div
                className={
                    isHighlighted
                        ? "activity-instance-input__wrapper highlighted"
                        : "activity-instance-input__wrapper"
                }
            >
                {edit ? (
                    <Editable
                        {...props}
                        onLabelChange={handleOnLabelChange}
                        onCancelAddActivityInstance={
                            props.onCancelAddActivityInstance
                        }
                        withCancel={isNew}
                    />
                ) : (
                    <>
                        <div
                            className={
                                wrapTags
                                    ? "activity-instance-input wrap-tags"
                                    : "activity-instance-input"
                            }
                        >
                            {isLoading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                />
                            )}

                            <ActivityInstanceInput
                                {...props}
                                hideIcon
                                onChange={handleOnChange}
                                isMulti={multiSelectEnabled}
                                value={
                                    isTypeWithSelectedIds
                                        ? props.selectedIds?.[0] ?? ""
                                        : props.value
                                }
                                preview={isPreview}
                                placeholder={
                                    type === ActivityType.Selection
                                        ? defaultPlaceholder
                                        : props.placeholder ??
                                          defaultPlaceholder
                                }
                                clearControl={
                                    (type === ActivityType.Barcodes ||
                                        type === ActivityType.GenerateBarcode ||
                                        type === ActivityType.Photo) &&
                                    !isSetCompleted ? (
                                        <IconButton
                                            circle={true}
                                            onClick={handleUndo}
                                        >
                                            <TrashIcon dotIcon={true} />
                                        </IconButton>
                                    ) : undefined
                                }
                                postInputContent={
                                    type === ActivityType.Text && (
                                        <ActivityInstanceTags
                                            type={type}
                                            disabled={props.disabled ?? false}
                                            acceptMultiple={
                                                props.acceptMultiple ?? false
                                            }
                                            isNotImprovement={
                                                props.originType !==
                                                TaskOriginType.Improvement
                                            }
                                            isNotGoal={
                                                props.originType !==
                                                TaskOriginType.Goal
                                            }
                                            isFinalized={isFinalized}
                                            isUserDefined={
                                                props.isUserDefined ?? false
                                            }
                                            edit={edit ?? false}
                                            completed={completed ?? false}
                                            completedBy={props.completedBy}
                                            completedAt={props.completedAt}
                                            assignUsers={props.assignUsers}
                                            assignedUsers={
                                                props.assignedUsers ?? []
                                            }
                                            usersAffected={
                                                props.usersAffected ?? []
                                            }
                                            onEditClick={props.onEditClick}
                                            onNewImprovementClick={
                                                props.onNewDeviationClick
                                            }
                                            onDeleteClick={
                                                props.onDeleteActivityInstance
                                            }
                                            onUndoClick={
                                                props.onUndo
                                                    ? handleUndo
                                                    : undefined
                                            }
                                        />
                                    )
                                }
                            />

                            {showRequiredNextToInput && required && (
                                <div className="activity-instance-input--icon-required">
                                    <RequiredStarIcon />
                                </div>
                            )}
                        </div>
                        {type !== ActivityType.Text && !removeTags && (
                            <ActivityInstanceTags
                                type={type}
                                disabled={props.disabled ?? false}
                                acceptMultiple={props.acceptMultiple ?? false}
                                isNotImprovement={
                                    props.originType !==
                                    TaskOriginType.Improvement
                                }
                                isNotGoal={
                                    props.originType !== TaskOriginType.Goal
                                }
                                isFinalized={isFinalized}
                                isUserDefined={props.isUserDefined ?? false}
                                edit={edit ?? false}
                                completed={completed ?? false}
                                completedBy={props.completedBy}
                                completedAt={props.completedAt}
                                assignUsers={props.assignUsers}
                                assignedUsers={props.assignedUsers ?? []}
                                usersAffected={props.usersAffected ?? []}
                                onEditClick={props.onEditClick}
                                onNewImprovementClick={
                                    props.onNewDeviationClick
                                }
                                onDeleteClick={props.onDeleteActivityInstance}
                                onUndoClick={
                                    props.onUndo ? handleUndo : undefined
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

interface IActivityInstanceTagsProps {
    type: ActivityType;

    disabled: boolean;
    acceptMultiple: boolean;
    isNotImprovement: boolean;
    isNotGoal?: boolean;

    isFinalized: boolean;
    isUserDefined: boolean;

    edit: boolean;

    completed: boolean;
    completedBy?: string;
    completedAt?: Date;

    assignUsers: React.ReactNode;
    assignedUsers: IValueLabelItemWithState[];
    usersAffected: string[];

    onEditClick?: (edit?: boolean) => void;
    onNewImprovementClick?: () => void;
    onDeleteClick?: () => void;
    onUndoClick?: () => void;
}

const ActivityInstanceTags = (props: IActivityInstanceTagsProps) => {
    const {
        type,
        disabled,
        acceptMultiple,
        isNotImprovement,
        isFinalized,
        isUserDefined,
        edit,
        completed,
        completedBy,
        completedAt,
        assignedUsers,
        usersAffected,
        assignUsers,
        isNotGoal,
    } = props;

    const showEdit = isUserDefined && !isFinalized;
    const showDelete = isUserDefined && !isFinalized;

    const showDeviationButton =
        !disabled &&
        !isFinalized &&
        (!acceptMultiple || !completed) &&
        isNotImprovement &&
        isNotGoal;

    const showUndoButton =
        type !== ActivityType.Barcodes &&
        type !== ActivityType.GenerateBarcode &&
        type !== ActivityType.Photo &&
        type !== ActivityType.Tasklist &&
        completed &&
        !disabled &&
        !isFinalized;

    const showCompleted =
        isNotImprovement || type === ActivityType.Tasklist
            ? completed
            : undefined;

    return (
        <div className="activity-instance-input--tags">
            {showEdit && (
                <EditActivityInstanceButton
                    disabled={edit ?? false}
                    onClick={props.onEditClick}
                />
            )}

            {completed
                ? undefined
                : assignedUsers && (
                      <SelectedUsersAvatarList
                          users={assignedUsers}
                          usersAffected={usersAffected}
                          maxShownAvatars={3}
                          size={24}
                      />
                  )}

            {completed ? undefined : assignUsers}

            {showUndoButton && props.onUndoClick && (
                <UndoButton onClick={props.onUndoClick} />
            )}

            {showCompleted && (
                <AvatarCompletedBy
                    completedBy={completedBy}
                    completedAt={completedAt}
                />
            )}

            {showDeviationButton && (
                <DeviationButton
                    onClick={props.onNewImprovementClick}
                    className="no-print"
                />
            )}

            {showDelete && (
                <DeleteActivityInstanceButton onClick={props.onDeleteClick} />
            )}
        </div>
    );
};

export default ActivityInstanceCard;
