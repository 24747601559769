import { Action } from "redux";

export const SET_QRSEARCH_IS_LOADING = "SET_QRSEARCH_IS_LOADING";

export const SHOW_QRSEARCH_FORM = "SHOW_QRSEARCH_FORM";

export const HIDE_QRSEARCH_FORM = "HIDE_QRSEARCH_FORM";

export const SELECT_QRSEARCH_WORKFLOW = "SELECT_QRSEARCH_WORKFLOW";

interface IQRSearchSetIsLoadingAction
    extends Action<typeof SET_QRSEARCH_IS_LOADING> {
    payload: boolean;
}

interface IQRSearchShowFormAction extends Action<typeof SHOW_QRSEARCH_FORM> {}

interface IQRSearchHideFormAction extends Action<typeof HIDE_QRSEARCH_FORM> {}

interface IQRSearchSelectWorkflowAction
    extends Action<typeof SELECT_QRSEARCH_WORKFLOW> {
    payload?: string;
}

export type QRSearchActionTypes =
    | IQRSearchSetIsLoadingAction
    | IQRSearchShowFormAction
    | IQRSearchHideFormAction
    | IQRSearchSelectWorkflowAction;
