import "./DeviationsIcon.scss";

import React from "react";
import Icon from "./assets/deviations-icon.svg";
import IconTrans from "./assets/deviations-icon-trans.svg";
import IconGray from "./assets/improvements-icon-gray.svg";
import { ImprovementBadgeColor } from "components/process-chart/components/improvements/ImprovementBadge";

interface IProps {
    badgeColor?: ImprovementBadgeColor;
}

const DeviationsIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    if (props.badgeColor === ImprovementBadgeColor.White) {
        return <IconTrans className="deviations-icon-trans" />;
    } else if (props.badgeColor === ImprovementBadgeColor.Gray) {
        return <IconGray />;
    } else {
        return <Icon className="deviations-icon" />;
    }
};

export default DeviationsIcon;
