import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { useImprovementFormOptions } from "components/improvement-forms/api/hooks";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { TableCell } from "common/components/table";

interface IProps extends IInputWrapperProps {
    closeMenuOnSelect?: boolean;
    defaultSelectedIds?: string[];
    selectedIds?: string[];
    isLoading?: boolean;
    open?: boolean;

    onShow: (newState: boolean) => void;
    onChange: (ids: string[]) => void;
}

export const TableImprovementFormFilter = (props: IProps) => {
    const {
        closeMenuOnSelect,
        selectedIds,
        defaultSelectedIds,
        isLoading,
        open,
    } = props;

    const { data: allOptions } = useImprovementFormOptions(
        true,
        defaultSelectedIds,
    );

    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string>[]
    >([]);

    useEffect(() => {
        if (selectedIds) {
            const newSelectedOptions = allOptions?.filter((x) =>
                selectedIds.includes(x.value),
            );
            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [allOptions, selectedIds]);

    const handleOnChange = (ids: string[]) => {
        const newOptions = allOptions?.filter((x) => ids.includes(x.value));

        setSelectedOptions(newOptions ?? []);

        props.onChange(ids);
    };

    return (
        <TableCell.Filtering
            isLoading={isLoading}
            menuOptions={allOptions}
            onFilter={handleOnChange}
            open={open}
            onShow={props.onShow}
        />
    );
};
