import "./IconWithMessage.scss";

import React from "react";

import Tooltip from "common/components/tooltip/Tooltip";
import InfoIcon from "common/components/icons/icon-info/InfoIcon";
import WarningIcon from "common/components/icons/icon-warning/WarningIcon";
import { Placement } from "react-bootstrap/esm/Overlay";

interface IProps {
    id: string;

    iconDisabled?: boolean;

    message: React.ReactNode;

    iconType?: "info" | "warning";

    darkBackground?: boolean;
    placement?: Placement;

    iconSize?: number;

    inheritColor?: boolean;
}

const IconWithMessage: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        id,
        message,
        iconDisabled,
        iconType = "info",
        darkBackground,
        placement,
        iconSize,
        inheritColor,
    } = props;

    const iconStyle = iconSize
        ? {
              fontSize: iconSize,
              minWidth: iconSize,
          }
        : undefined;

    return (
        <div className="icon-with-message">
            <Tooltip
                id={id}
                message={message}
                variant="inline"
                placement={placement}
            >
                {iconType === "info" && (
                    <InfoIcon
                        style={iconStyle}
                        disabled={iconDisabled}
                        backgroundVariant={darkBackground ? "dark" : undefined}
                        inheritColor={inheritColor}
                    />
                )}
                {iconType === "warning" && (
                    <WarningIcon
                        style={iconStyle}
                        disabled={iconDisabled}
                        darkBackground={darkBackground}
                        inheritColor={inheritColor}
                    />
                )}
            </Tooltip>
        </div>
    );
};

export default IconWithMessage;
