import "./HeaderFilters.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Textfield from "common/components/input-components/textfield/Textfield";
import ICustomListItemFilter from "../api/ICustomListItemFilter";
import IChanged from "common/IChanged";
import { DEFAULT_DELAY_INTERVAL_MS } from "common/utils/time";
import { SearchIcon } from "common/components/icons";

import {} from "../api/hooks";

interface IProps {
    value: ICustomListItemFilter;

    onChange: (value: IChanged<ICustomListItemFilter>) => void;
}

const HeaderFilters: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation();

    const customListId = props.value.customListId;

    const [value, setValue] = useState(props.value);

    React.useEffect(() => {
        setValue(props.value);
    }, [customListId]);

    React.useEffect(() => {
        if (value === props.value) {
            return;
        }

        const timer = setTimeout(() => {
            const isChanged = Boolean(value.searchKeyword);

            const nextValue: IChanged<ICustomListItemFilter> = {
                value: {
                    ...value,
                    page: 1,
                },
                isChanged,
            };

            props.onChange(nextValue);
        }, DEFAULT_DELAY_INTERVAL_MS);

        return () => clearTimeout(timer);
    }, [value]);

    const handleChange = (value: string, id?: string, name?: string) => {
        if (name) {
            setValue((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    return (
        <div className="custom-list-item-table__toolbar">
            <div className="search-filter--icon_cell">
                <label>&nbsp;</label>

                <div className="filter__icon-cell">
                    <label
                        className="search-filter__wrapper"
                        htmlFor="filter.searchKeyword"
                    >
                        <SearchIcon />
                    </label>
                </div>
            </div>

            <div className="multiselect-filter__cell">
                <label htmlFor="filter.searchKeyword">{t(k.SEARCH)}</label>
                <div>
                    <Textfield
                        id="filter.searchKeyword"
                        name="searchKeyword"
                        value={value.searchKeyword ?? ""}
                        placeholder={t(k.SEARCH)}
                        size="large"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default HeaderFilters;
