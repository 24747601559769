import React from "react";

import {
    ESharedOptionsType,
    useSharedOptions,
} from "../input-components/dropdown/api/hooks";
import TeamsList from "./TeamsList";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    isTeamEveryone?: boolean;
    icon?: React.ReactNode | null;
    haveWordWrap?: boolean;
    showTitle?: boolean;
    fullWidth?: boolean;

    teamIds?: string[];

    testId?: string;
}

const TeamsListById: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { teamIds, ...rest } = props;

    const { data } = useSharedOptions(true, ESharedOptionsType.team, teamIds);

    if (!data) {
        return null;
    }
    const teams: IValueLabelItemWithState[] | undefined = teamIds?.map((id) => {
        const team = data.find((team) => team.value === id);
        return {
            value: id,
            label: team?.label ?? "",
            isArchived: team?.isArchived ?? false,
            isDeleted: team?.isDeleted ?? false,
        };
    });

    return <TeamsList {...rest} teams={teams} />;
};

export default TeamsListById;
