import { createContext } from "react";

import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import ITemplateFormData from "./ITemplateFormData";

export type TemplateFormContextType = ITemplateFormData &
    ListedContentContextType;

const initialContextValue: TemplateFormContextType = {
    isLoading: false,
    isSaving: false,
    mobileView: false,

    setErrors: () => null,
    setTemplate: () => null,

    onHaveChanges: () => null,

    handleOnSave: () => null,
    handleOnClose: () => null,
    handleOnDelete: () => null,
    handleOnArchive: () => null,
    handleOnReset: () => null,
    handleOnAddOrReplaceSharedStep: () => null,
    handleOnCopy: () => null,
    handleOnCopyStep: () => null,
};

const TemplateFormContext =
    createContext<TemplateFormContextType>(initialContextValue);

export default TemplateFormContext;
