import "./ModalStyle.scss";

import React from "react";
import ModalItem from "./ModalItem";
import { IIdModalListItems, IModalRenderer } from "../api/IModalManager";
import { ModalByType } from "./ModalByType";

interface IProps {
    modalList: IIdModalListItems;
    order: string[];
    haveOpenModals: boolean;

    onBackgroundClick: () => void;
}

const ModalRenderer: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { modalList, order, haveOpenModals } = props;

    if (order.length === 0) {
        return null;
    }

    const modalItems = order.reduce<{
        items: React.ReactNode[];
        index: number;
        length: number;
    }>(
        (acc, id) => {
            const modalItem = modalList[id];

            if (!modalItem) {
                return acc;
            }

            acc.items.push(
                <ModalItem
                    layerIndex={acc.index}
                    lastIndex={acc.length - 1}
                    key={id}
                    itemHidden={modalItem.hidden}
                    onBackgroundClick={props.onBackgroundClick}
                >
                    <ModalByType modalItem={modalItem} />
                </ModalItem>,
            );

            acc.index = modalItem.hidden ? acc.index : acc.index + 1;

            return acc;
        },
        {
            items: [],
            index: 0,
            length: order.filter((id) => !modalList[id]?.hidden).length,
        },
    ).items;

    return (
        <div className="modal-manager">
            {haveOpenModals && (
                <div
                    className="modal-manager--backdrop"
                    onClick={props.onBackgroundClick}
                />
            )}
            <div className="modal-manager--list">{modalItems}</div>
        </div>
    );
};

export default ModalRenderer;
