import axios from "axios";
import { useQuery } from "react-query";

import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import ITemplateFilter from "components/templates/api/ITemplateFilter";
import IPaginatedList from "http/IPaginatedList";
import ITemplateRowDTO from "components/templates/api/ITemplateRowDTO";
import ITemplateConfigurationDTO from "./ITemplateConfigurationDTO";
export const TEMPLATE_CONFIG_BY_VERSION_ID =
    "template-configuration-by-version-id";
export const TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID =
    "template-draft-configuration-by-version-id";

const keys = {
    options: "improvement-form-options-list" as const,
    list: "improvement-form-list" as const,
    templateConfigurationById: TEMPLATE_CONFIG_BY_VERSION_ID,
    templateDraftConfigurationById: TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID,
};

const baseUrl = "/api/template";

export const useImprovementFormOptions = (enabled: boolean, ids?: string[]) => {
    return useQuery(
        [keys.options],
        async (context) => {
            let url = `${baseUrl}/improvement-form-options`;

            if (ids) {
                const params = ids
                    ?.map((id) => {
                        return `ids=${id}`;
                    })
                    .join("&");

                url = `${url}?${params}`;
            }

            const response = await axios.get<IValueLabelItemWithState[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled },
    );
};

export const useGetAllImprovementsFiltered = (
    filter: ITemplateFilter,
    enabled = true,
) => {
    return useQuery(
        [keys.list, filter],
        async () => {
            const response = await axios.post<IPaginatedList<ITemplateRowDTO>>(
                `${baseUrl}/list`,
                filter,
            );

            return response.data;
        },
        { enabled },
    );
};

export const useGetTemplateConfiguration = (templateVersionId?: string) => {
    const url = `${baseUrl}/configuration/${templateVersionId}`;

    return useQuery(
        [keys.templateConfigurationById, templateVersionId],
        async () => {
            const response = await axios.get<ITemplateConfigurationDTO>(url);

            return response.data;
        },
        { enabled: !!templateVersionId },
    );
};

export const useGetTemplateDraftConfiguration = (
    templateVersionId?: string,
) => {
    const url = `${baseUrl}/draft/configuration/${templateVersionId}`;

    return useQuery(
        [keys.templateDraftConfigurationById, templateVersionId],
        async () => {
            const response = await axios.get<ITemplateConfigurationDTO>(url);

            return response.data;
        },
        { enabled: !!templateVersionId },
    );
};
