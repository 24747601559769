import IImprovementInvolvedUserRequest from "http/requests/IImprovementInvolvedUserRequest";

export function filterDictionaryByValue<T>(
    dict: { [key: string]: T },
    predicate: (value: T) => boolean,
): { [key: string]: T } {
    const entries = Object.entries(dict);

    const filteredEntries = entries.filter(([key, value]) => predicate(value));

    return Object.fromEntries(filteredEntries);
}
