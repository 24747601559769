import "./ColumnSortingComponent.scss";

import React from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

export interface IColumnSortingProps {
    isActive?: boolean;

    sortAsc?: boolean;

    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ColumnSortingComponent = (props: IColumnSortingProps) => {
    const { isActive, sortAsc } = props;

    return (
        <div className="app-table--cell-sorting" onClick={props.onClick}>
            <BiChevronUp className={isActive && !sortAsc ? "hidden" : "up"} />

            <BiChevronDown
                className={isActive && sortAsc ? "hidden" : "down"}
            />
        </div>
    );
};

export default ColumnSortingComponent;
