import Button from "common/components/buttons/Button";
import { ArrowLeftIcon } from "common/components/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ChecklistDetailsContext from "../../../../../checklistContext";
import "./CloseButton.scss";

interface ICloseButtonProps {}

const CloseButton: React.FC<ICloseButtonProps> = (props) => {
    const { t } = useTranslation();

    const { onClose } = useContext(ChecklistDetailsContext);

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Button
            testId="closeModalContentBtn"
            variant="white"
            transparent
            onClick={handleOnClose}
            className="close-btn"
        >
            <ArrowLeftIcon />

            {t(k.CLOSE)}
        </Button>
    );
};

export default CloseButton;
