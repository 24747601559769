import { FC } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ListedContent from "common/components/listed-content/ListedContent";
import {
    SidePanelHeader,
    SidePanelDetails,
    SidePanelList,
} from "./components/SidePanel";
import Content from "./components/Content/Content";
import { useChecklistData } from "./hooks";
import ChecklistDetailsContext, {
    ChecklistContextType,
} from "./checklistContext";
import { IShowModalPayload } from "common/components/modal-manager/api/IModalManager";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import ModalContent from "common/components/modal-content/ModalContent";
import Button from "common/components/buttons/Button";
import { useListedContentData } from "common/components/listed-content/hooks";
import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import "./ChecklistListedContent.scss";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";

export interface IChecklistDetailsProps {
    id: string;
    checkCanSeeAll: boolean;
    syncData: boolean;

    initialStepInstanceId?: string;

    onShowModal: (payload: IShowModalPayload) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

const ChecklistListedContent: FC<IChecklistDetailsProps> = (props) => {
    const { t } = useTranslation();

    const checklistData: ChecklistContextType = {
        ...useChecklistData(props),
        ...useListedContentData(),
    };

    const {
        listedContentRef,
        contentRef,
        checklist,
        isLoading,
        isLoadingChecklist,
        isLoadingActivityInstances,
        isChecklistInvisible,
        initialSection,
        mobileView,
    } = checklistData;

    const handleClose = () => {
        props.onClose();
    };

    if (
        isChecklistInvisible ||
        (checklist === undefined && !isLoadingChecklist)
    ) {
        return (
            <NotAvailableModal
                onClose={handleClose}
                message={t(k.NO_AVAILABLE_CHECKLIST_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    return (
        <CurrentScrolledSection
            contentRef={contentRef}
            wrapperRef={listedContentRef}
            initialSection={initialSection}
            mobileView={mobileView}
            children={(scrollToTarget, currentSection) => (
                <ListedContent
                    className="checklist__listed-content"
                    listedContentRef={listedContentRef}
                >
                    {isLoading ? (
                        <AnimatedSpinner
                            isVisible={isLoading}
                            position="center"
                            aligned="center"
                        />
                    ) : (
                        <ChecklistDetailsContext.Provider
                            value={{
                                ...checklistData,
                                scrollToTarget,
                                currentSection,
                            }}
                        >
                            <ListedContent.SidePanel
                                context={ChecklistDetailsContext}
                                className="TestClass"
                            >
                                <SidePanelHeader />

                                <SidePanelDetails />

                                <SidePanelList />
                            </ListedContent.SidePanel>

                            <ListedContent.Content contentRef={contentRef}>
                                <Content />
                            </ListedContent.Content>
                        </ChecklistDetailsContext.Provider>
                    )}
                </ListedContent>
            )}
        />
    );
};

export default ChecklistListedContent;
