import "./CheckmarkIcon.scss";

import React from "react";
import { FiCheck } from "react-icons/fi";

const CheckmarkIcon: React.FC = (props) => {
    return <FiCheck className="icon-checkmark" />;
};

export default CheckmarkIcon;
