import "./EquipmentsList.scss";

import React, { useMemo } from "react";

import Badge from "../badges/Badge";
import { EquipmentIcon } from "../icons";
import { useEquipmentOptions } from "components/equipment/api/hooks";

interface IProps {
    equipmentIds: string[];
}

const EquipmentsList: React.FC<IProps> = (props) => {
    const { equipmentIds } = props;

    const { data: equipment } = useEquipmentOptions(equipmentIds.length === 0);

    const items = useMemo(() => {
        return equipment?.filter((x) => equipmentIds.includes(x.value));
    }, [equipment, equipmentIds]);

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <div className="equipments-list--wrapper">
            <EquipmentIcon />
            <div className="equipments-list--badges">
                {items.map((item) => {
                    return (
                        <Badge pill variant="secondary" key={item.value}>
                            {item.label}
                        </Badge>
                    );
                })}
            </div>
        </div>
    );
};

export default EquipmentsList;
