import "./SetFormat.scss";

import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import InputWrapper from "common/components/input-components/InputWrapper";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import RadioButton from "common/components/input-components/radioButton/RadioButton";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import ItemTypeModalForm from "components/item-types/components/ItemTypeModalForm";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { OnChangeValue } from "react-select";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import { usePublishedUnitsList } from "components/item-types/api/hooks";
import IItemTypeRowDTO from "components/item-types/api/IItemTypeRowDTO";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { ItemCategoryType } from "components/item-types/api/ItemCategoryType";
import { IKpiFormats } from "../../api/IKpiFormats";

interface IProps {
    errors?: IValidationMessages;

    kpiFormats: IKpiFormats;
    overIsGood: boolean;

    onChangeFormats: (value: IKpiFormats) => void;
    onChangeOverIsGood: (value: boolean) => void;
}

export const SetFormat = (props: IProps) => {
    const { errors, overIsGood, kpiFormats } = props;

    const { t } = useTranslation();

    const [isTypeFormVisible, setIsTypeFormVisible] = useState(false);

    const { data: unitsData } = usePublishedUnitsList();

    const onChangeDecimalPlaces = (e: IInputChangeEvent<string>) => {
        const nextValue = parseInt(e.value);

        const isValid = isNaN(nextValue) ? false : true;

        props.onChangeFormats({
            ...kpiFormats,
            decimalPlaces: isValid ? nextValue : undefined,
        });
    };

    const handleChangeKpiUnit = (value: OnChangeValue<OptionType, boolean>) => {
        if (value) {
            if ("value" in value) {
                var selectedUnit = unitsData?.find(
                    (x) => x.value === value.value,
                );

                if (selectedUnit && selectedUnit.param !== undefined) {
                    props.onChangeFormats({
                        ...kpiFormats,
                        unit: {
                            id: selectedUnit.value,
                            name: selectedUnit.label,
                            type: selectedUnit.param,
                            isArchived: false,
                        },
                    });
                }
            }
        } else {
            props.onChangeFormats({ ...kpiFormats, unit: undefined });
        }
    };

    const handleOnCreateKpiUnit = (e: { label: string }) => {
        if (e.label) {
            props.onChangeFormats({
                ...kpiFormats,
                unit: {
                    id: uuidv4(),
                    name: e.label,
                    type: ItemCategoryType.Unit,
                    isArchived: false,
                },
            });
        }

        setIsTypeFormVisible(true);
    };

    const handleOnSaveKpiUnit = (value?: IItemTypeRowDTO) => {
        if (value) {
            props.onChangeFormats({ ...kpiFormats, unit: value });
        }

        setIsTypeFormVisible(false);
    };

    const handleSetOverTargetIsGood = () => {
        props.onChangeOverIsGood(true);
    };

    const handleSetUnderTargetIsGood = () => {
        props.onChangeOverIsGood(false);
    };

    return (
        <div className="set-kpi-formats">
            {isTypeFormVisible && (
                <ItemTypeModalForm
                    disabledDropdown
                    onSave={handleOnSaveKpiUnit}
                    value={kpiFormats.unit}
                    isCreate
                />
            )}
            <InputWrapper
                wrapperLabel={t(k.KPI_UNIT)}
                boldLabel
                className="unit"
            >
                <SelectDropdown
                    id="unit"
                    value={unitsData?.find(
                        (item) => item.value === kpiFormats.unit?.id,
                    )}
                    isClearable
                    isSearchable
                    bold
                    placeholder={t(k.START_TYPING_TO_SELECT_OR_CREATE)}
                    options={unitsData}
                    onChange={handleChangeKpiUnit}
                    onCreate={handleOnCreateKpiUnit}
                />
            </InputWrapper>

            <InputComponent
                boldLabel
                hideIcon
                wrapperClassName="set-kpi-formats--decimal-places"
                wrapperLabel={t(k.NUMBER_OF_DECIMAL_PLACES)}
                inputType={EActivityType.Textfield}
                type="number"
                value={String(kpiFormats.decimalPlaces ?? "")}
                onChange={onChangeDecimalPlaces}
                helperText={
                    errors?.["DECIMAL_PLACES_INVALID_NUMBER"] && (
                        <ValidationLabel
                            errors={t(k.DECIMAL_PLACES_INVALID_NUMBER)}
                        />
                    )
                }
            />

            <InputWrapper
                wrapperLabel={t(k.TARGET_PREFERENCE)}
                boldLabel
                noMinWidth
                inputClassName="set-kpi-formats--radio-buttons"
            >
                <RadioButton
                    radioSize="medium"
                    value={overIsGood}
                    label={t(k.OVER_TARGET_IS_GOOD)}
                    onChange={handleSetOverTargetIsGood}
                />
                <RadioButton
                    radioSize="medium"
                    value={!overIsGood}
                    label={t(k.UNDER_TARGET_IS_GOOD)}
                    onChange={handleSetUnderTargetIsGood}
                />
            </InputWrapper>
        </div>
    );
};
