import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import Button from "common/components/buttons/Button";

interface IProps {
    checklist?: IChecklistEntityDto;

    onShowRecurrence: () => void;
}

const PausedMessage: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { checklist } = props;

    const { t } = useTranslation();

    const isPaused = checklist?.status === ChecklistDynamicStatus.Stopped;

    if (checklist === undefined || isPaused === false) {
        return null;
    }

    if (checklist.status === ChecklistDynamicStatus.Archived) {
        return (
            <>
                <div className="details-col">
                    {t(k.ARCHIVED_BY)}:{" "}
                    <strong>{checklist.archivedByName}</strong>{" "}
                    <FormattedDate date={checklist.archivedAt} />
                </div>
            </>
        );
    }

    if (checklist.isPausedBySchedule) {
        return (
            <>
                <div className="details-col">
                    <Button onClick={props.onShowRecurrence} variant="link">
                        {t(k.SCHEDULE_PAUSED_BY)}
                    </Button>
                </div>
                <div className="details-col">
                    <FormattedDate date={checklist.pausedAt} />
                </div>
                <div className="details-col">
                    <strong>{checklist.pausedByName}</strong>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="details-col">
                {t(k.PAUSED_BY)}: <strong>{checklist.pausedByName}</strong>{" "}
                <FormattedDate date={checklist.pausedAt} />
            </div>
        </>
    );
};

export default PausedMessage;
