import "./SortableGoalsTable.scss";

import k from "i18n/keys";

import InfoCard from "../InfoCard";

import { useTranslation } from "react-i18next";
import { usePublishedUserOptionsExtended } from "components/users/api/hooks";
import Button from "common/components/buttons/Button";
import { ButtonGroup } from "common/components/buttons";
import { useGoalModifiableList } from "../../api/hooks";
import DropdownButton from "common/components/dropdown/DropdownButton";
import { useContext, useMemo } from "react";
import { IDropdownOption } from "common/components/dropdown/api/IDropdownOption";
import { IGoalDTO } from "../../api/IGoalDTO";

import { FiPlus } from "react-icons/fi";
import { ArrowDownIcon } from "common/components/icons";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";

import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import SortableList from "components/common/sortableLists/SortableList";
import { SortEnd } from "react-sortable-hoc";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import SortableGoalTableRow from "./SortableGoalTableRow";

interface IProps {
    goal: IGoalDTO;
    editMode?: boolean;
    disabled?: boolean;
    subGoalsIds?: string[];
    goalsTableSet?: ITableSetWithOptions<IGoalDTO, string>;
    isLoadingTableSet?: boolean;
    createNew?: boolean;
    syncData?: boolean;
    canCreate?: boolean;
    onViewGoal?: (id: string) => void;
    onCreateSubGoal?: () => void;
    onSorted?: (params: SortEnd, listId?: string) => void;
    onAddExistingGoal?: (id: string) => void;
    onRemoveSubGoal?: (id: string) => void;
}

export const SortableGoalsTable = (props: IProps) => {
    const {
        subGoalsIds,
        editMode,
        goal,
        createNew,
        syncData,
        goalsTableSet,
        isLoadingTableSet = false,
        disabled,
        canCreate,
    } = props;

    const { t, i18n } = useTranslation();

    const { data: assignableGoals } = useGoalModifiableList(
        createNew ? undefined : goal.id,
        syncData,
    );
    const modalManagerContext = useContext(ModalManagerContext);

    const subGoals = subGoalsIds?.reduce((acc, id) => {
        const item = goalsTableSet?.values[id];

        if (item) {
            acc.push(item);
        }
        return acc;
    }, [] as IGoalDTO[]);

    const handleViewMeasurement = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.measurement_form,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const options = useMemo<
        IDropdownOption<string, string, undefined>[]
    >(() => {
        return (
            assignableGoals?.reduce<
                IDropdownOption<string, string, undefined>[]
            >((acc, item) => {
                if (
                    goal.id !== item.id &&
                    goalsTableSet?.ids.includes(item.id)
                ) {
                    acc.push({
                        value: item.id,
                        label: item.name ?? "",
                        selected: goal.subGoalsIds?.includes(item.id),
                    });
                }
                return acc;
            }, []) ?? []
        );
    }, [assignableGoals, goal]);

    const handleAddExistingGoal = (id: string) => {
        props.onAddExistingGoal?.(id);
    };

    const { data: usersData, isLoading: isLoadingGoalsUsers } =
        usePublishedUserOptionsExtended();

    return (
        <InfoCard
            label={t(k.SUB_GOALS)}
            childrenClassName="sortable-goals-table"
        >
            {isLoadingTableSet || isLoadingGoalsUsers ? (
                <AnimatedSpinner isVisible aligned="center" position="center" />
            ) : subGoals && subGoals.length > 0 ? (
                <>
                    <SortableList
                        distance={5}
                        disabled={disabled}
                        hideOrder
                        lockAxis="y"
                        className="sortable-goals-table--list"
                        itemClassName="list-item"
                        helperClass="sortable-goals-table-sortable-helper"
                        itemIds={subGoalsIds ?? []}
                        onSorted={props.onSorted}
                        render={(itemId, index) => {
                            const item = subGoals.find((o) => o.id === itemId);

                            if (!item) return;

                            return (
                                <SortableGoalTableRow
                                    key={item.id}
                                    id={item.id}
                                    retreats={[]}
                                    goals={goalsTableSet}
                                    editMode={editMode}
                                    usersData={usersData}
                                    onViewGoal={props.onViewGoal}
                                    onRemoveSubGoal={props.onRemoveSubGoal}
                                    onViewMeasurement={handleViewMeasurement}
                                    isLast={index === subGoals.length - 1}
                                    level={0}
                                    nameWidth={380}
                                />
                            );
                        }}
                    />

                    {editMode && canCreate && !disabled && (
                        <ButtonGroup className="add-goal-buttons">
                            <Button
                                icon={<FiPlus />}
                                transparent
                                onClick={props.onCreateSubGoal}
                            >
                                {t(k.NEW_SUB_GOAL)}
                            </Button>
                            <DropdownButton
                                options={options}
                                onChange={handleAddExistingGoal}
                                className="dropdown-button"
                                icon={
                                    <Button
                                        icon={<ArrowDownIcon />}
                                        transparent
                                    >
                                        {t(k.ADD_EXISTING_GOAL)}
                                    </Button>
                                }
                            />
                        </ButtonGroup>
                    )}
                </>
            ) : (
                <div className="sortable-goals-table--empty-table">
                    <div className="title">{t(k.NO_SUB_GOALS_ADDED_YET)}</div>
                    <div className="description">
                        <div>{t(k.YOU_CAN_ADD_SUBGOALS_FOR_ANY_GOAL)}</div>
                        <div>
                            {t(k.CREATE_A_NEW_ONE_OR_ADD_ANY_EXISTING_GOAL)}
                        </div>
                    </div>
                    {editMode && canCreate && !disabled && (
                        <ButtonGroup>
                            <Button
                                icon={<FiPlus />}
                                transparent
                                onClick={props.onCreateSubGoal}
                            >
                                {t(k.NEW_SUB_GOAL)}
                            </Button>
                            <DropdownButton
                                options={options}
                                onChange={handleAddExistingGoal}
                                className="dropdown-button"
                                icon={
                                    <Button
                                        icon={<ArrowDownIcon />}
                                        transparent
                                    >
                                        {t(k.ADD_EXISTING_GOAL)}
                                    </Button>
                                }
                            />
                        </ButtonGroup>
                    )}
                </div>
            )}
        </InfoCard>
    );
};

export default SortableGoalsTable;
