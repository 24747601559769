import React, { useContext } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ImprovementsDetailsContext from "../../../../../improvementContext";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { IShowConfirmArgs, showConfirm } from "store/confirms/actions";
import { useDeleteImprovementMutation } from "../../../../../../../api/hooks";
import DeleteDeviationMessage from "components/improvements/messages/DeleteDeviationMessage";
import IconButton from "common/components/buttons/icon-button/IconButton";
interface IButtonProps {}

interface IDispatchProps {
    showConfirm: (
        message: React.ReactNode | IShowConfirmArgs,
    ) => Promise<boolean | undefined>;
}

type IProps = IButtonProps & IDispatchProps;

const DeleteButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation();
    const { selectedId, onClose } = useContext(ImprovementsDetailsContext);

    const mutateDeleteById = useDeleteImprovementMutation();

    const onDelete = async (id: string) => {
        if (selectedId) {
            const result = await mutateDeleteById.mutateAsync({
                id: selectedId,
            });
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleDeleteStep = async () => {
        if (
            (await props.showConfirm(<DeleteDeviationMessage />)) &&
            selectedId
        ) {
            await onDelete(selectedId);

            handleClose();
        }
    };

    return (
        <IconButton
            onClick={handleDeleteStep}
            background="dark"
            testId="deleteModalContentBtn"
        >
            <TrashIcon />
        </IconButton>
    );
};

const actions = {
    showConfirm,
};

export default connect(null, actions)(DeleteButton);
