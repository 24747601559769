import React from "react";
import { connect } from "react-redux";

import ExecutionResult from "../../../common/viewModels/ExecutionResult";
import IAttachmentFormData from "../../../common/viewModels/IAttachmentFormData";
import IValidationMessages from "../../../common/viewModels/IValidationMessages";
import { AttachmentType } from "../../../models/enums/AttachmentType";
import IAttachmentRequest from "../../../http/requests/IAttachmentRequest";
import {
    hideAttachmentForm,
    validate,
} from "../../../store/attachments/actions";
import { IAppState } from "../../../store/IAppState";
import AttachmentFile from "./AttachmentFile";
import AttachmentLink from "./AttachmentLink";

interface IProps {
    selectedAttachmentType: AttachmentType;
    isLoading: boolean;

    hideAttachmentForm: (data?: IAttachmentFormData) => void;
    validate: (request: IAttachmentRequest) => Promise<ExecutionResult>;
}

interface IState {
    errors: IValidationMessages;
}

class ModalAttachmentManager extends React.Component<IProps, IState> {
    public readonly state: IState = {
        errors: {},
    };

    public handleOnClose = async (
        isSave: boolean,
        data: IAttachmentFormData,
    ) => {
        this.setState({
            errors: {},
        });

        if (isSave) {
            const request: IAttachmentRequest = {
                ...data,
                attachmentType: this.props.selectedAttachmentType,
            };

            const result = await this.props.validate(request);
            if (!result.Succeeded) {
                this.setState({
                    errors: result.Messages,
                });
            } else {
                this.props.hideAttachmentForm(data);
            }
        } else {
            this.props.hideAttachmentForm();
        }
    };

    public render() {
        const { selectedAttachmentType, isLoading } = this.props;

        const { errors } = this.state;

        return (
            <React.Fragment>
                {selectedAttachmentType === AttachmentType.Link && (
                    <AttachmentLink
                        isLoading={isLoading}
                        errors={errors}
                        onClose={this.handleOnClose}
                    />
                )}

                {selectedAttachmentType === AttachmentType.File && (
                    <AttachmentFile
                        isLoading={isLoading}
                        errors={errors}
                        onClose={this.handleOnClose}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapToProps = (appState: IAppState) => {
    return {
        selectedAttachmentType: appState.attachmentViewState.selectedType,
        isLoading: appState.attachmentViewState.isLoading,
    };
};

const actions = {
    hideAttachmentForm,
    validate,
};

export default connect(mapToProps, actions)(ModalAttachmentManager);
