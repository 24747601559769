import "./Editable.scss";

import React, { useState } from "react";
import { IInputProps } from "../../../input-components/IInputProps";
import Textfield from "common/components/input-components/textfield/Textfield";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Button from "common/components/buttons/Button";
import { IoClose } from "react-icons/io5";

export interface IActivityInstanceCardInputProps extends IInputProps<string> {
    withCancel?: boolean;
    onLabelChange: (id: string, newLabel?: string) => void;
    onCancelAddActivityInstance?: (value?: boolean, id?: string) => void;
}

const Editable: React.FC<IActivityInstanceCardInputProps> = (props) => {
    const { onLabelChange, id, textLabel, onCancelAddActivityInstance, error } =
        props;

    const [label, setLabel] = useState(textLabel || "");

    const { t } = useTranslation();

    const onValueChange = (value: string) => {
        setLabel(value);
    };

    const onSaveBtnClick = () => {
        if (id) {
            onLabelChange(id, label);
        }
    };

    const onCancelBtnClick = () => {
        onCancelAddActivityInstance?.(false, id);
    };

    const value = label.trim() === "" ? label.trim() : label;

    return (
        <div className="activity-instance-input__editable">
            <Textfield
                id={`field-${id}`}
                value={value}
                placeholder={t(k.ENTER_HERE)}
                onChange={onValueChange}
                size="large"
                invalid={Boolean(error)}
                onEnter={onSaveBtnClick}
                autoFocus
                multiline
                minRows={1}
            />

            <Button
                onClick={onCancelBtnClick}
                className="activity-instance-input__editable__cancelBtn"
                variant="danger"
                noMinWidth
                transparent
                testId="cancelSaveActivityInstanceBtn"
            >
                <IoClose color="red" className="remove-button--icon" />
            </Button>

            <Button
                className="activity-instance-input__editable__saveBtn"
                onClick={onSaveBtnClick}
                variant="success"
                noMinWidth
                testId="saveActivityInstanceBtn"
            >
                {t(k.SAVE)}
            </Button>
        </div>
    );
};

export default Editable;
