import "./ToggleView.scss";

import React from "react";

import { MdViewComfy, MdViewList } from "react-icons/md";
import IconButton from "common/components/buttons/icon-button/IconButton";

interface IProps {
    disabled: boolean;

    split: boolean;

    onChange: (split: boolean) => void;
}

const ToggleView: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { disabled, split } = props;

    const handleSelectSingleView = () => {
        props.onChange(false);
    };

    const handleSelectMultiView = () => {
        props.onChange(true);
    };

    return (
        <div
            className={split ? "toggle-view toggle-view--split" : "toggle-view"}
        >
            <IconButton disabled={disabled} onClick={handleSelectSingleView}>
                <MdViewList />
            </IconButton>

            <IconButton disabled={disabled} onClick={handleSelectMultiView}>
                <MdViewComfy />
            </IconButton>
        </div>
    );
};

export default ToggleView;
