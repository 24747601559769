import { Action, AnyAction, Reducer } from "redux";

export function createReducer<S, A extends AnyAction>(
    initialState: S,
    actionsMap: A,
): Reducer<S, A> {
    return (state = initialState, action): S => {
        const handler = actionsMap[action.type];

        return handler ? handler(state, action) : state;
    };
}

export function reduceReducers<S, A extends AnyAction>(
    ...reducers: Array<Reducer<S, A>>
): Reducer<S | undefined, A> {
    return (prevState, value) => {
        return reducers.reduce(
            (newState, reducer) => reducer(newState, value),
            prevState,
        );
    };
}
