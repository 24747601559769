import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TableCell } from "common/components/table";
import {
    allChecklistStatuses,
    ChecklistDynamicStatus,
} from "models/enums/ChecklistDynamicStatus";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    isAdministrator: boolean;

    values: ChecklistDynamicStatus[];

    onShow: (newState: boolean) => void;
    onChange: (values: ChecklistDynamicStatus[]) => void;
}

export const ChecklistStatusFilter = (props: IProps) => {
    const { isAdministrator, values } = props;

    const { t, i18n } = useTranslation();

    const [allOptions, setAllOptions] = useState<
        Array<IValueLabelItem<string, string>>
    >([]);

    const [selectedIds, setSelectedIds] = useState(
        values.map((id) => String(id)),
    );

    useEffect(() => {
        fetchData();
    }, [i18n.language]);

    const fetchData = () => {
        const result = allChecklistStatuses
            .filter(
                (x) =>
                    x.id !== ChecklistDynamicStatus.None &&
                    (isAdministrator ||
                        x.id !== ChecklistDynamicStatus.Archived),
            )
            .map((x) => {
                const option: IValueLabelItem<string, string> = {
                    value: String(x.id),
                    label: t(x.label),
                };

                return option;
            });

        setAllOptions(result);
    };

    const handleFilter = (optionIds: string[]) => {
        setSelectedIds(optionIds);

        props.onChange(optionIds.map((id) => Number(id)));
    };

    return (
        <TableCell.Filtering
            hideAll
            hideSearch
            testId="filterMenu.status"
            menuOptions={allOptions}
            selectedIds={selectedIds}
            onShow={props.onShow}
            onFilter={handleFilter}
        />
    );
};
