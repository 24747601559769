import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Textfield from "common/components/input-components/textfield/Textfield";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

interface IProps {
    name: string;

    placeholder: string;

    errors: IValidationMessages;

    onChange: (value: string) => void;

    onBlur?: (id?: string, name?: string) => void;
}

const RecurringChecklistName: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { name, placeholder, errors } = props;

    const { t } = useTranslation();

    return (
        <div className="recurring-checklist--body--title">
            <Textfield
                id="name"
                labelContent={t(k.DESCRIPTIVE_RECURRANCE_NAME)}
                value={name}
                placeholder={placeholder}
                onChange={props.onChange}
                onBlur={props.onBlur}
                invalid={!!errors.name}
                helperText={
                    <ValidationLabel errors={errors.name && t(errors.name)} />
                }
            />
        </div>
    );
};

export default RecurringChecklistName;
