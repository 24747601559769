import { Reducer } from "redux";

import {
    RECEIVE_GET_PUBLISHED_WORKFLOWS,
    RECEIVE_SAVE_WORKFLOW,
    REQUEST_GET_PUBLISHED_WORKFLOWS,
    SET_WORKFLOW_IS_LOADING,
    WorkflowActionTypes,
    REQUEST_GET_WORKFLOW_ALL,
    RECEIVE_GET_WORKFLOW_ALL,
    RECEIVE_GET_WORKFLOW_DRAFT,
    REQUEST_GET_WORKFLOW_DRAFT,
    REQUEST_DELETE_WORKFLOW_DRAFT,
    RECEIVE_DELETE_WORKFLOW_DRAFT,
    FAILURE_DELETE_WORKFLOW_DRAFT,
    REQUEST_DELETE_WORKFLOW,
    RECEIVE_DELETE_WORKFLOW,
    FAILURE_DELETE_WORKFLOW,
} from "./types";

export interface IWorkflowState {
    readonly isLoading: boolean;
    readonly isDeleting: boolean;
    readonly isLatestVersionLoading: boolean;
    readonly isSelectedLoading: boolean;
}

const initialState: IWorkflowState = {
    isLoading: false,
    isDeleting: false,
    isLatestVersionLoading: false,
    isSelectedLoading: false,
};

export const WorkflowReducer: Reducer = (
    state: IWorkflowState = initialState,
    action: WorkflowActionTypes,
): IWorkflowState => {
    switch (action.type) {
        case SET_WORKFLOW_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case REQUEST_GET_WORKFLOW_ALL: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case RECEIVE_GET_WORKFLOW_ALL: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case REQUEST_GET_WORKFLOW_DRAFT: {
            return {
                ...state,
                isLatestVersionLoading: true,
            };
        }

        case RECEIVE_GET_WORKFLOW_DRAFT: {
            return {
                ...state,
                isLatestVersionLoading: false,
            };
        }

        case REQUEST_GET_PUBLISHED_WORKFLOWS: {
            return {
                ...state,
                isLatestVersionLoading: false,
            };
        }

        case RECEIVE_GET_PUBLISHED_WORKFLOWS: {
            return {
                ...state,
                isLatestVersionLoading: true,
            };
        }

        case RECEIVE_SAVE_WORKFLOW: {
            return initialState;
        }

        case REQUEST_DELETE_WORKFLOW_DRAFT: {
            return {
                ...state,
                isDeleting: true,
            };
        }

        case RECEIVE_DELETE_WORKFLOW_DRAFT: {
            return {
                ...state,
                isDeleting: false,
            };
        }

        case FAILURE_DELETE_WORKFLOW_DRAFT: {
            return {
                ...state,
                isDeleting: false,
            };
        }

        case REQUEST_DELETE_WORKFLOW: {
            return {
                ...state,
                isDeleting: true,
            };
        }

        case RECEIVE_DELETE_WORKFLOW: {
            return {
                ...state,
                isDeleting: false,
            };
        }

        case FAILURE_DELETE_WORKFLOW: {
            return {
                ...state,
                isDeleting: false,
            };
        }

        default:
            return state;
    }
};
