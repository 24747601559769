import i18n from "i18next";

import k from "i18n/keys";
import { IOption } from "common/IOption";

export enum WorkflowScheduleInterval {
    Weekly,
    Monthly,
    Yearly,
}

export const getWorkflowScheduleIntervals = (): IOption[] => {
    const allIntervals: IOption[] = [
        {
            id: WorkflowScheduleInterval.Weekly,
            label: i18n.t(k.WEEKLY),
        },
        {
            id: WorkflowScheduleInterval.Monthly,
            label: i18n.t(k.MONTHLY),
        },
        {
            id: WorkflowScheduleInterval.Yearly,
            label: i18n.t(k.YEARLY),
        },
    ];

    return allIntervals;
};
