import "./Page404.scss";

import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IAppState } from "../../store/IAppState";
import GoToLoginPage from "./GoToLoginPage";
import AnimatedSpinner from "../../components/common/spinners/AnimatedSpinner";
import { usePageTitle } from "routes/hooks";

interface IStateProps {
    hasCheckedLocalToken: boolean;
    isAuthenticated: boolean;
}

type Props = IStateProps;

const Page404: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { hasCheckedLocalToken, isAuthenticated } = props;

    const { t } = useTranslation();

    usePageTitle("404 Error");

    if (!hasCheckedLocalToken) {
        return (
            <div className="p404-wrapper">
                <AnimatedSpinner isVisible={true} position="center" />
            </div>
        );
    }

    if (isAuthenticated === false) {
        return <GoToLoginPage />;
    }

    return (
        <div className="p404-message-container">
            <h1>{t(k.PAGE_NOT_FOUND)}</h1>
            <p>
                {t(k.GO_TO)} <NavLink to="/">{t(k.HOME_)}</NavLink> {t(k.PAGE_)}
            </p>
        </div>
    );
};

const mapToState = (state: IAppState): IStateProps => {
    return {
        hasCheckedLocalToken: state.authViewState.hasCheckedLocalToken,
        isAuthenticated: state.authViewState.isAuthenticated,
    };
};

export default connect(mapToState)(Page404);
