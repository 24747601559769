import React, { useLayoutEffect } from "react";
import { CellProps } from "react-datasheet-grid";
import Select, { GroupBase, SelectInstance } from "react-select";

import {
    ISelectGroupedOption,
    SelectGroupedOptions,
} from "../../api/SelectGroupedOptions";
import { buildStyles } from "../../styles";

function selectGroupedSingleComponent(
    props: CellProps<string | null, SelectGroupedOptions<false>>,
) {
    const { active, rowData, setRowData, focus, stopEditing, columnData } =
        props;

    const ref =
        React.useRef<
            SelectInstance<
                ISelectGroupedOption,
                false,
                GroupBase<ISelectGroupedOption>
            >
        >(null);

    useLayoutEffect(() => {
        if (focus) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }
    }, [focus]);

    const styles = React.useMemo(
        () => buildStyles<ISelectGroupedOption, false>({ active, focus }),
        [active, focus],
    );

    return (
        <Select
            ref={ref}
            styles={styles}
            isDisabled={columnData.disabled}
            value={
                columnData.choices
                    .flatMap((x) => x.options ?? [])
                    .find(({ value }) => rowData === value) ?? null
            }
            menuPortalTarget={document.body}
            menuIsOpen={focus}
            onChange={(choice) => {
                if (choice === null) return;

                setRowData(choice.value);
            }}
            onMenuClose={() => stopEditing({ nextRow: false })}
            options={columnData.choices}
        />
    );
}

export const SelectGroupedSingleComponent = React.memo(
    selectGroupedSingleComponent,
) as typeof selectGroupedSingleComponent;
