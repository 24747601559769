import "./SortableGoalTableRow.scss";

import k from "i18n/keys";

import FormattedDate from "common/components/formattedDate/FormattedDate";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import IUserDTO from "components/users/api/IUserDTO";
import { useTranslation } from "react-i18next";
import { IGoalDTO } from "components/goals-page/api/IGoalDTO";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import GoalStatusBadgeWithTooltip from "common/components/badges/goal-status-badge-with-tooltip/GoalStatusBadgeWithTooltip";
import Badge from "common/components/badges/Badge";
import { useState } from "react";

import ProgressBarPercentValue from "common/components/progress-bar/ProgressBarPercentValue";
import { Position } from "common/components/progress-bar/ProgressBar";
import MeasurementsList from "components/measurement/components/MeasurementsList";
import ITableSet from "http/ITableSet";
import { IMeasurementDTO } from "components/measurement/api/IMeasurementDTO";
import { FiMinus, FiPlus } from "react-icons/fi";

import { CloseIcon } from "common/components/icons";

import { ITableRowWithChildren } from "../GoalsTable/GoalTable";
import TableRowRetreats from "../GoalsTable/TableRowRetreats";
import TeamsListById from "common/components/team/TeamsListById";
import ImpactGrading from "common/components/impact-gradings/ImpactGrading";

interface IProps {
    id: string;
    parentId?: string;
    retreats?: boolean[];
    isLast?: boolean;
    goals?: ITableSetWithOptions<IGoalDTO, string>;
    usersData?: ITableSetWithOptions<IUserDTO, IUserDTO>;
    level?: number;
    nameWidth?: number;
    editMode?: boolean;
    onViewMeasurement: (id: string) => void;
    onViewGoal?: (id: string, parentId?: string) => void;
    onToggleRow?: (row: ITableRowWithChildren, close?: boolean) => void;
    onRemoveSubGoal?: (id: string) => void;
}

const SortableGoalTableRow = (props: IProps) => {
    const {
        id,
        parentId,
        goals,
        usersData,
        level = 0,
        isLast,
        retreats = [],
        nameWidth,
        editMode,
    } = props;

    const { t } = useTranslation();

    const goal = goals?.values[id];
    const hasSubGoals = goal?.subGoalsIds && goal.subGoalsIds.length > 0;
    const hasMeasurements = goal?.measurements && goal.measurements.length > 0;
    const subGoalsIds = goal?.subGoalsIds ?? [];
    const measurements = goal?.measurements ?? [];
    const measurementsTableSet = measurements.reduce<
        ITableSet<IMeasurementDTO>
    >(
        (acc, measurement) => {
            acc.values[measurement.id] = measurement;
            acc.ids.push(measurement.id);

            return acc;
        },
        { ids: [], values: {} },
    );
    const [isOpened, setIsOpened] = useState(false);

    const onViewGoal = () => {
        const tempGoal = goals?.values[id];
        if (tempGoal) {
            props.onViewGoal?.(tempGoal.id);
        }
    };

    const onViewSubGoal = (subGoalId: string, parentId?: string) => {
        if (parentId) {
            props.onViewGoal?.(subGoalId, parentId);
        } else {
            const tempGoal = goals?.values[id];

            if (tempGoal) {
                props.onViewGoal?.(subGoalId, tempGoal.id);
            }
        }
    };

    const userDataPerGoal = goal?.responsibleUsersIds?.reduce<IUserDTO[]>(
        (acc, userId) => {
            const user = usersData?.values[userId];

            if (user) {
                acc.push(user);
            }

            return acc;
        },
        [],
    );

    const handleOpenAccordion = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        setIsOpened(!isOpened);

        const rowId = parentId ? `${parentId}_${id}` : id;
        const row: ITableRowWithChildren = {
            id: rowId,
            level: level + 1,
            childIds: subGoalsIds.map((subId) => `${rowId}_${subId}`),
        };

        props.onToggleRow?.(row, isOpened);
    };

    const handleOnRemoveSubGoal = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        if (goal) {
            props.onRemoveSubGoal?.(goal.id);
        }
    };

    return (
        <>
            <div className="sortable-goal-table-row" data-level={level}>
                <div className="sortable-goal-table-row--retreats">
                    <TableRowRetreats
                        retreats={retreats}
                        level={level}
                        isLast={isLast}
                    />
                    <div
                        className="accordion-container"
                        onClick={handleOpenAccordion}
                    >
                        {(hasSubGoals || hasMeasurements) && (
                            <>{isOpened ? <FiMinus /> : <FiPlus />}</>
                        )}
                    </div>
                </div>
                <div
                    className="sortable-goal-table-row--content"
                    style={{ marginLeft: level * 30, paddingLeft: 30 }}
                >
                    <div
                        className="sortable-goal-table-row--cells"
                        onClick={onViewGoal}
                    >
                        <div className="cell name-cell">
                            <div className="name">{goal?.name}</div>
                            <div className="meta">
                                {userDataPerGoal &&
                                userDataPerGoal.length > 0 ? (
                                    <SelectedUsersAvatarList
                                        maxShownAvatars={2}
                                        size={24}
                                        users={userDataPerGoal}
                                    />
                                ) : (
                                    t(k.NO_USERS_ASSIGNED_TO_THIS_GOAL)
                                )}

                                {goal?.archivedAt ? (
                                    <Badge pill variant="archived">
                                        {t(k.ARCHIVED)}
                                    </Badge>
                                ) : (
                                    <GoalStatusBadgeWithTooltip
                                        id={id}
                                        hideOnTrack
                                        status={goal?.status}
                                        message={goal?.statusComment}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="cell progress-cell">
                            <ProgressBarPercentValue
                                max={1}
                                now={goal?.progress}
                                contentPos={Position.Bottom}
                                barInMiddle
                            />
                        </div>
                        <div className="cell date-cell">
                            {goal?.startDate && goal?.endDate && (
                                <>
                                    <FormattedDate
                                        onlyDate
                                        showMonthName
                                        shortMonthName
                                        date={goal?.startDate}
                                    />
                                    {" -"}
                                    <br />
                                    <FormattedDate
                                        onlyDate
                                        showMonthName
                                        shortMonthName
                                        date={goal?.endDate}
                                    />
                                </>
                            )}
                        </div>
                        <div className="cell impact-cell">
                            <ImpactGrading impactList={goal?.impactGradings} />
                        </div>
                        <div className="cell involved-cell">
                            {((goal?.involvedTeamsIds &&
                                goal.involvedTeamsIds.length > 0) ||
                                goal?.isTeamEveryone) && (
                                <TeamsListById
                                    icon={null}
                                    showTitle
                                    fullWidth
                                    haveWordWrap
                                    teamIds={goal?.involvedTeamsIds}
                                    isTeamEveryone={goal?.isTeamEveryone}
                                />
                            )}
                        </div>
                        <div className="cell tasks-cell">
                            {goal?.taskCount ? (
                                <div className="assigned-tasks">
                                    {goal?.taskCount}{" "}
                                    {t(k.TASKS_ASSIGNED_TO_YOU)}
                                </div>
                            ) : null}
                        </div>
                        {editMode && (
                            <div className="cell remove-subgoal-cell">
                                <div onClick={handleOnRemoveSubGoal}>
                                    <CloseIcon />
                                </div>
                            </div>
                        )}
                    </div>
                    {isOpened && hasMeasurements && (
                        <div className="sortable-goal-table-row--measurements">
                            <MeasurementsList
                                measurements={measurementsTableSet}
                                onEditMeasurement={props.onViewMeasurement}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isOpened && hasSubGoals && (
                <>
                    {subGoalsIds.map((subId, index) => (
                        <SortableGoalTableRow
                            key={subId}
                            id={subId}
                            parentId={parentId ? `${parentId}_${id}` : id}
                            goals={goals}
                            retreats={[
                                ...retreats,
                                index !== subGoalsIds.length - 1,
                            ]}
                            usersData={usersData}
                            nameWidth={nameWidth}
                            onViewGoal={onViewSubGoal}
                            onViewMeasurement={props.onViewMeasurement}
                            onToggleRow={props.onToggleRow}
                            level={level + 1}
                            isLast={index === subGoalsIds.length - 1}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default SortableGoalTableRow;
