import "./CloseIcon.scss";

import React, { useMemo } from "react";
import Icon from "./assets/close-icon.svg";
import CircleIcon from "./assets/close-circle-icon.svg";

interface ICloseIconProps {
    circle?: boolean;
    disabled?: boolean;
    className?: string;
}

const CloseIcon: React.FC<ICloseIconProps> = (props) => {
    const { circle, disabled } = props;

    let className = "close-icon";

    if (disabled) {
        className += " close-icon--disabled";
    }
    if (props.className) {
        className += " " + props.className;
    }

    return circle ? (
        <CircleIcon className={className} />
    ) : (
        <Icon className={className} />
    );
};

export default CloseIcon;
