import "./MenuButton.scss";

import React, { useMemo } from "react";
import { Dropdown } from "react-overlays";
import { DropDirection } from "react-overlays/DropdownContext";

import { IMenuProps } from "./components/IMenuProps";
import MenuList from "./components/MenuList";
import Toggle from "./components/Toggle";

interface IProps extends IMenuProps {
    id?: string;
    disabled?: boolean;
    alignEnd?: boolean;
    drop?: DropDirection;
    className?: string;
    label?: React.ReactNode;
    icon?: React.ReactNode;
    background?: "dark";
    buttonVariant?: "badge";
}

const MenuButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        id,
        testId,
        options,
        className,
        label,
        icon,
        header,
        multiSelect,
        showCheckbox,
        disabled,
        alignEnd,
        drop,
        background,
        buttonVariant,
    } = props;

    const handleDropdownToggle = (nextShow: boolean, e?: any) => {};

    const wrapperClassName = useMemo(() => {
        const result: string[] = ["menu-button__wrapper"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [className]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const toggleClassName = useMemo(() => {
        const result: string[] = [];

        if (label) {
            result.push("toggle-with-label");
        } else if (icon) {
            result.push("toggle-without-label icon-bordered");
        }

        if (background) {
            result.push(`background-${background}`);
        }

        if (buttonVariant) {
            result.push(`variant-${buttonVariant}`);
        }

        return result.join(" ");
    }, [icon, label, background, buttonVariant]);

    return (
        <div className="menu-button" onClick={handleClick}>
            <Dropdown
                onToggle={handleDropdownToggle}
                alignEnd={alignEnd ?? true}
                drop={drop}
            >
                <div className={wrapperClassName}>
                    <Toggle
                        id={id}
                        testId={testId}
                        disabled={disabled}
                        className={toggleClassName}
                        label={label}
                        icon={icon}
                    />

                    <MenuList
                        header={header}
                        multiSelect={multiSelect}
                        showCheckbox={showCheckbox}
                        options={options}
                        onSelect={props.onSelect}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export default MenuButton;
