import { Column } from "react-datasheet-grid";

import { SelectManyComponent } from "./components/SelectManyComponent";
import { SelectOptions } from "../api/SelectOptions";

export const selectManyColumn = (
    options: SelectOptions,
): Column<string[] | null, SelectOptions> => ({
    component: SelectManyComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,

    copyValue: ({ rowData }) => {
        const foundOptions = options.choices.filter((choice) =>
            rowData?.includes(choice.value),
        );

        if (foundOptions.length === 0) {
            return null;
        }

        return foundOptions
            .map((x) => (x.label.includes(",") ? `"${x.label}"` : x.label))
            .join(",");
    },

    pasteValue: ({ value }) => {
        const labels = value
            .toLowerCase()
            .split(",")
            .map((x) =>
                x.startsWith('"') && x.endsWith('"')
                    ? x.trim()
                    : `"${x.trim()}"`,
            );

        const foundOptions = options.choices.filter(({ label }) => {
            const currentLabel =
                label.startsWith('"') && label.endsWith('"')
                    ? label
                    : `"${label}"`;

            return labels.includes(currentLabel.toLowerCase());
        });

        if (foundOptions.length === 0) {
            return null;
        }

        return foundOptions.map((x) => x.value);
    },
});
