export enum HistoryOwnerEnum {
    None = 0,
    Improvement,
    Checklist,
    Kpi,
    Goal,
    Measurement,
    MeasurementProgress,
    Task,
    CM,
    KpiPermissions,
    GoalsPermissions,
    ChecklistReportsPermissions,
    ImprovementDashboardPermissions,
    StakeholdersPermissions,
    LegislationsPermissions,
    ImpactGradingsPermissions,
    CustomersPermissions,
    CustomerRequirementsPermissions,
    SuppliersPermissions,
    PurchaseOrdersPermissions,
    ItemsPermissions,
    UsersPermissions,
    PositionsPermissions,
    TeamsPermissions,
    CompetenciesPermissions,
    CompetencyMatrixPermissions,
    StaffAppraisalsPermissions,
    TrainingPlansPermissions,
    EquipmentsPermissions,
    ORAPermissions,
    EnvironmentalAspectsPermissions,
    CustomListItemsPermissions,
}
