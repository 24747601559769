import { StylesConfig } from "react-select";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ESelectedProcessType } from "common/components/input-components/dropdown-tree/ESelectedProcessType";

export const buildStyles = <
    Option = IValueLabelItem,
    IsMulti extends boolean = boolean,
>({
    active,
    focus,
    isImpactedProcess,
}: {
    active: boolean;
    focus: boolean;
    isImpactedProcess?: boolean;
}) => {
    const result: StylesConfig<Option, IsMulti> = {
        container: (provided) => ({
            ...provided,
            flex: 1,
            alignSelf: "stretch",
            pointerEvents: focus ? undefined : "none",
        }),
        control: (provided) => ({
            ...provided,
            height: "100%",
            border: "none",
            boxShadow: "none",
            background: "none",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            opacity: 0,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
            alignItems: "flex-start",
        }),
        placeholder: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: "20001",
        }),

        menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
        }),

        option: (base, props) => {
            const { data } = props as any;

            if (data.isRequired || isImpactedProcess) {
                const result = { ...base };

                if (data.isRequired) {
                    result.color = "#ff2929 !important";
                }

                if (isImpactedProcess) {
                    const level = data.level as ESelectedProcessType;

                    if (level === ESelectedProcessType.process) {
                        result.fontWeight = "bold";
                        result.textDecoration = "underline";
                    } else {
                        result["::first-letter"] = {
                            fontWeight: "bold",
                        };

                        result.paddingLeft = `${Number(level) * 30}px`;

                        if (level === ESelectedProcessType.process_step) {
                            result.fontStyle = "italic";
                        }
                    }
                }

                return result;
            }

            return base;
        },
    };

    return result;
};
