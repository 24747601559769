import "./UserTeamBadges.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Badge from "common/components/badges/Badge";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import Wrapper from "common/components/wrapper/Wrapper";

interface IProps {
    teamIds: string[];
    textEllipsis?: boolean;

    selectedIds?: Record<string, boolean>;
    wrapper?: boolean;
}

const UserTeamBadges = ({
    teamIds,
    selectedIds,
    textEllipsis,
    wrapper = true,
}: IProps) => {
    const { data: publishedTeams } = useSharedOptions(
        true,
        ESharedOptionsType.team,
        teamIds,
    );

    const { t } = useTranslation();

    return (
        <Wrapper
            enabled={wrapper}
            className={`user-team-badges${textEllipsis ? " ellipsed" : ""}`}
        >
            {teamIds.map((id) => {
                const team = publishedTeams?.find((team) => team.value === id);

                if (team) {
                    return (
                        <Badge
                            key={id}
                            variant={selectedIds?.[id] ? "warning" : "info"}
                            pill
                            textEllipsis
                        >
                            {team.label}
                            {team.isArchived ? (
                                team.isDeleted ? (
                                    <>&nbsp; ({t(k.DELETED)})</>
                                ) : (
                                    <>&nbsp; ({t(k.ARCHIVED)})</>
                                )
                            ) : null}
                        </Badge>
                    );
                }
            })}
        </Wrapper>
    );
};

export default UserTeamBadges;
