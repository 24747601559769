import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IKpiFilter from "components/kpi-page/api/IKpiFilter";
import YearMultiselect from "components/common/multiselect-dropdowns/YearMultiselect";
import { useKpiYearOptionsById } from "common/components/modal-manager-content/kpi-form/api/hooks";
import "./DetailsFilters.scss";

interface IProps {
    value: IKpiFilter;

    onChange: (value: IKpiFilter) => void;
}

const DetailsFilters: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(props.value);

    const { data: allYears } = useKpiYearOptionsById();

    useEffect(() => {
        if (value === props.value) {
            return;
        }
        props.onChange(value);
    }, [value, allYears]);

    const handleChangeYears = (value: string[]) => {
        setValue((prev) => ({
            ...prev,
            years: value,
        }));
    };

    return (
        <div className="kpi-details-filters">
            <YearMultiselect
                label={t(k.YEAR_S)}
                selectedYears={value.years}
                allYears={allYears}
                onChange={handleChangeYears}
                placeholder={k.CURRENT_AND_PREVIOUS_YEAR}
                closeMenuOnSelect
            />
        </div>
    );
};

export default DetailsFilters;
