import "./TitleWithValidation.scss";

import { useMemo } from "react";

import { getLocaleString } from "i18n/components/GetLocaleString";

import ValidationLabel from "./ValidationLabel";

interface IProps {
    id?: string;
    htmlFor?: string;
    title?: React.ReactNode;
    required?: boolean;
    className?: string;
    bold?: boolean;
    size?: "title-sm";
    errors?: React.ReactNode;
    testId?: string;
    validationTestId?: string;
}

const TitleWithValidation: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { title, errors, required, testId, validationTestId, htmlFor, id } =
        props;

    const className = useMemo(() => {
        const result = ["title-with-validation"];

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [props.className]);

    const titleClassName = useMemo(() => {
        const result = ["title-with-validation--title"];

        if (props.bold) {
            result.push("title-bold");
        }

        if (props.size) {
            result.push(props.size);
        }

        return result.join(" ");
    }, [props.bold, props.size]);

    if (!title && !errors) {
        return null;
    }

    return (
        <div className={className} data-testid={testId}>
            {title && (
                <label htmlFor={htmlFor} className={titleClassName}>
                    {title}
                </label>
            )}
            {required && (
                <div className="title-with-validation--required">*</div>
            )}
            {errors && (
                <div className="title-with-validation--validation">
                    <ValidationLabel
                        id={id}
                        darkColor
                        errors={errors}
                        testId={validationTestId}
                    />
                </div>
            )}
        </div>
    );
};

export default TitleWithValidation;
