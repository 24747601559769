import { Action } from "redux";

import IStepDTO from "../../models/dto/IStepDTO";
import IStepListDTO from "../../models/dto/IStepListDTO";

// ActionTypes
export const SET_STEP_IS_LOADING = "SET_STEP_IS_LOADING";

export const REQUEST_GET_STEP_ALL = "REQUEST_GET_STEP_ALL";
export const RECEIVE_GET_STEP_ALL = "RECEIVE_GET_STEP_ALL";

export const REQUEST_GET_PUBLISHED_STEPS = "REQUEST_GET_PUBLISHED_STEPS";
export const RECEIVE_GET_PUBLISHED_STEPS = "RECEIVE_GET_PUBLISHED_STEPS";

export const REQUEST_GET_STEP_BY_STEP_ID = "REQUEST_GET_STEP_BY_STEP_ID";
export const RECEIVE_GET_STEP_BY_STEP_ID = "RECEIVE_GET_STEP_BY_STEP_ID";

export const REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID =
    "REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID";
export const RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID =
    "RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID";

export const REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID =
    "REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID";
export const RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID =
    "RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID";

export const REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_ID =
    "REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_ID";
export const RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_ID =
    "RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_ID";

export const REQUEST_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS =
    "REQUEST_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS";
export const RECEIVE_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS =
    "RECEIVE_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS";

export const REQUEST_GET_STEP_DRAFT = "REQUEST_GET_STEP_DRAFT";
export const RECEIVE_GET_STEP_DRAFT = "RECEIVE_GET_STEP_DRAFT";

export const RECEIVE_SAVE_STEP = "RECEIVE_SAVE_STEP";

export const RECEIVE_DELETE_STEP = "RECEIVE_DELETE_STEP";

export const REQUEST_DELETE_STEP_DRAFT = "REQUEST_DELETE_STEP_DRAFT";
export const RECEIVE_DELETE_STEP_DRAFT = "RECEIVE_DELETE_STEP_DRAFT";
export const FAILURE_DELETE_STEP_DRAFT = "FAILURE_DELETE_STEP_DRAFT";

// Actions
interface IStepSetIsLoadingAction extends Action<typeof SET_STEP_IS_LOADING> {
    payload: boolean;
}

interface IStepReceiveGetAllAction extends Action<typeof RECEIVE_GET_STEP_ALL> {
    payload: IStepListDTO;
}

interface IStepRequestGetAllAction
    extends Action<typeof REQUEST_GET_STEP_ALL> {}

interface IStepRequestGetStepByStepIdAction
    extends Action<typeof REQUEST_GET_STEP_BY_STEP_ID> {}

interface IStepReceiveGetStepByStepIdAction
    extends Action<typeof RECEIVE_GET_STEP_BY_STEP_ID> {
    payload: IStepListDTO;
}

interface IStepRequestGetDetailsByStepVersionIdAction
    extends Action<typeof REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID> {}

interface IStepReceiveGetDetailsByStepVersionIdAction
    extends Action<typeof RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID> {
    payload: IStepListDTO;
}

interface IStepRequestGetSelectedStepsByWorkflowVersionIdAction
    extends Action<typeof REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID> {
    payload?: boolean;
}

interface IStepReceiveGetSelectedStepsByWorkflowVersionIdAction
    extends Action<typeof RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_VERSION_ID> {
    payload?: IStepListDTO;
}

interface IStepRequestGetSelectedStepsByWorkflowIdAction
    extends Action<typeof REQUEST_GET_SELECTED_STEPS_BY_WORKFLOW_ID> {}

interface IStepReceiveGetSelectedStepsByWorkflowIdAction
    extends Action<typeof RECEIVE_GET_SELECTED_STEPS_BY_WORKFLOW_ID> {
    payload: IStepListDTO;
}

interface IStepRequestGetSelectedStepsForAllWorkflowsAction
    extends Action<typeof REQUEST_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS> {}

interface IStepReceiveGetSelectedStepsForAllWorkflowsAction
    extends Action<typeof RECEIVE_GET_SELECTED_STEPS_FOR_ALL_WORKFLOWS> {
    payload: IStepListDTO;
}

interface IStepRequestGetDraftAction
    extends Action<typeof REQUEST_GET_STEP_DRAFT> {}

interface IStepReceiveGetDraftAction
    extends Action<typeof RECEIVE_GET_STEP_DRAFT> {
    payload: IStepListDTO;
}

interface IStepReceiveSaveAction extends Action<typeof RECEIVE_SAVE_STEP> {
    payload: IStepDTO;
}

interface IStepReceiveDeleteAction extends Action<typeof RECEIVE_DELETE_STEP> {
    stepVersionId: string;
}

interface IStepRequestGetPublishedStepsAction
    extends Action<typeof REQUEST_GET_PUBLISHED_STEPS> {}

interface IStepReceiveGetPublishedStepsAction
    extends Action<typeof RECEIVE_GET_PUBLISHED_STEPS> {
    payload: IStepListDTO;
}

interface IStepRequestDeleteDraftAction
    extends Action<typeof REQUEST_DELETE_STEP_DRAFT> {}

interface IStepReceiveDeleteDraftAction
    extends Action<typeof RECEIVE_DELETE_STEP_DRAFT> {
    stepVersionId: string;
}

interface IStepFailureDeleteDraftAction
    extends Action<typeof FAILURE_DELETE_STEP_DRAFT> {}

export type StepActionTypes =
    | IStepSetIsLoadingAction
    | IStepReceiveGetAllAction
    | IStepRequestGetAllAction
    | IStepRequestGetStepByStepIdAction
    | IStepReceiveGetStepByStepIdAction
    | IStepRequestGetDetailsByStepVersionIdAction
    | IStepReceiveGetDetailsByStepVersionIdAction
    | IStepReceiveGetSelectedStepsByWorkflowVersionIdAction
    | IStepRequestGetSelectedStepsByWorkflowVersionIdAction
    | IStepRequestGetSelectedStepsByWorkflowIdAction
    | IStepReceiveGetSelectedStepsByWorkflowIdAction
    | IStepRequestGetSelectedStepsForAllWorkflowsAction
    | IStepReceiveGetSelectedStepsForAllWorkflowsAction
    | IStepReceiveSaveAction
    | IStepReceiveDeleteAction
    | IStepRequestGetDraftAction
    | IStepReceiveGetDraftAction
    | IStepRequestGetPublishedStepsAction
    | IStepReceiveGetPublishedStepsAction
    | IStepRequestDeleteDraftAction
    | IStepReceiveDeleteDraftAction
    | IStepFailureDeleteDraftAction;
