import { IModalOptions } from "../api/IModalManager";

interface IModalOptionsObject {
    [key: string]:
        | IModalOptionTypes
        | IModalOptionTypes[]
        | IModalOptionsObject;
}

type IModalOptionTypes = string | boolean | number | undefined | null;

export const ModalOptionsStringify = (options: IModalOptions): string => {
    const stringifyObject = (obj: IModalOptionsObject) => {
        const tempResult: string[] = [];

        for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
                tempResult.push(`${key}:[${value.join(",")}]`);
            } else if (typeof value === "object" && value !== null) {
                const subOptions = stringifyObject(value);

                tempResult.push(`${key}:(${subOptions})`);
            } else if (typeof value === "boolean" && value === true) {
                tempResult.push(key);
            } else {
                tempResult.push(`${key}:${value}`);
            }
        }

        return tempResult.join(",");
    };

    const result = stringifyObject(options);

    return result;
};

/*
impFilter:(pchart:1,drafts,arrayObject:[1,5,6,24],impactType:1,impFilter:(impFilter:(pchart:1,drafts,impactType:1),pchart:1,drafts,impactType:1,arrayObject:[1,5,6,24])),name:Management
impFilter:(activity:1184338c-cd13-4c71-bf00-efa9387732fe,active,closed:false),name:Checklist_6546b627b52f
*/

//Not working due to lookbehind not being supported in all browsers, looking at you Apple...
//const splitRegEx = /(?<!\([^()]*),(?![^()]*\))/;

const splitAtTopLevelComma = (options: string): string[] => {
    let depth = 0;
    let result: string[] = [];
    let lastIndex = 0;
    let arrayDepth = 0;

    options.split("").forEach((char, index) => {
        switch (char) {
            case "(":
                depth++;
                break;
            case ")":
                depth--;
                break;
            case "[":
                arrayDepth++;
                break;
            case "]":
                arrayDepth--;
                break;
            case ",":
                if (depth === 0 && arrayDepth === 0) {
                    result.push(options.slice(lastIndex, index).trim());
                    lastIndex = index + 1;
                }
                break;
        }
    });

    const lastSegment = options.slice(lastIndex).trim();
    if (lastSegment !== "") {
        result.push(lastSegment);
    }

    return result;
};

const isNumeric = (value: string): boolean => {
    return /^-?\d+(\.\d+)?$/.test(value);
};

const splitList = (list: string[]): IModalOptionsObject => {
    const tempResult: IModalOptionsObject = {};

    list.forEach((option) => {
        const indexOfColon = option.indexOf(":");
        if (indexOfColon === -1) {
            tempResult[option.trim()] = true;
        } else {
            let key = option.substring(0, indexOfColon).trim();
            let value = option.substring(indexOfColon + 1).trim();

            if (value.startsWith("(") && value.endsWith(")")) {
                value = value.slice(1, -1);
            }

            if (value.startsWith("[") && value.endsWith("]")) {
                value = value.slice(1, -1);
                const valueArray = splitAtTopLevelComma(value);
                tempResult[key] = valueArray.map((val) => {
                    const result = parseFloat(val);
                    return isNaN(result) ? val : result;
                });
            } else {
                const subOptions = splitAtTopLevelComma(value);

                if (subOptions.length > 1 || value.startsWith("(")) {
                    tempResult[key] = splitList(subOptions);
                } else {
                    switch (true) {
                        case isNumeric(value):
                            tempResult[key] = parseFloat(value);
                            break;
                        case value === "undefined":
                            tempResult[key] = undefined;
                            break;
                        case value === "null":
                            tempResult[key] = null;
                            break;
                        default:
                            tempResult[key] = value;
                    }
                }
            }
        }
    });

    return tempResult;
};

const matchingParentheses = (options: string): boolean => {
    const numberOfOpen = (options.match(/\(/g) || []).length;
    const numberOfClosed = (options.match(/\)/g) || []).length;
    return numberOfOpen === numberOfClosed;
};

export const ModalOptionsObjectify = (options: string): IModalOptionsObject => {
    if (!options || !matchingParentheses(options)) {
        throw new Error("Invalid options string");
    }
    const list = splitAtTopLevelComma(options);
    return splitList(list);
};
