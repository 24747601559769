import React, { useContext } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { TableCell, TableRow } from "common/components/table";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { ISharedPropertiesTableSet } from "components/common/shared-properties/api/ISharedPropertiesTableSet";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { showImage } from "store/images/actions";
import Barcode from "common/components/barcode/Barcode";
import IBarcodePreviewRequest from "store/barcode/IBarcodePreviewRequest";
import { showBarcodePreview } from "store/barcode/actions";
import { MultiselectValueBadge } from "common/components/badges";
import IDictionary from "common/viewModels/IDictionary";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { useCachedCustomListItemById } from "../api/hooks";
import StartChecklistButton from "components/common/shared-properties/components/inputs/StartChecklistButton";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    EChecklistReportState,
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { useLocation } from "react-router-dom";
import Link from "common/components/links/Link";
import TagsContainer from "common/components/table/components/TagsContainer";
import DateByUser from "common/components/date-by-user/DateByUser";
import TextOverflow from "common/components/text-overflow/TextOverflow";

interface IOwnProps {
    id: string;

    defaultSelectedProperties: ISharedPropertiesTableSet | null;
    selectedCustomListItemsNames: Record<
        string,
        IValueLabelItemWithState | undefined
    > | null;
    tasklistOptionsById: IDictionary<IValueLabelItemWithState[]> | null;

    isNewItem?: boolean;
    excludeMutualPropertyParentId: string | null;

    canRunChecklistsWithIdsHasAnyValues: boolean;
    canRunChecklistsWithIds?: IDictionary<string[]>;

    hasActiveChecklists: boolean;
    activeChecklists?: Record<string, number | undefined>;
    hasFinalizedChecklists: boolean;
    finalizedChecklists?: Record<string, number | undefined>;

    onSelectRow?: (id: string) => void;

    onPreviewCustomListItem?: (id: string) => void;
}

interface IDispatchProps {
    showImage: (value: string) => void;
    showBarcodePreview: (value: IBarcodePreviewRequest) => void;
}

type Props = IOwnProps & IDispatchProps;

const Row: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const {
        id,
        defaultSelectedProperties,
        selectedCustomListItemsNames,
        tasklistOptionsById,
        isNewItem,
        canRunChecklistsWithIdsHasAnyValues,
        canRunChecklistsWithIds,
        excludeMutualPropertyParentId,
        hasActiveChecklists,
        activeChecklists,
        hasFinalizedChecklists,
        finalizedChecklists,
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const { t } = useTranslation();

    const location = useLocation();

    const { data: value } = useCachedCustomListItemById(id);

    if (value === undefined) {
        return null;
    }

    const handleClick = () => {
        if (props.onSelectRow) {
            props.onSelectRow(value.id);
        }
    };

    const handlePreviewCustomListItem = (id?: string) => {
        if (id && props.onPreviewCustomListItem) {
            props.onPreviewCustomListItem(id);
        }
    };

    const handlePreviewPhoto = (
        e: React.MouseEvent<HTMLSpanElement> & {
            target: { dataset: { id?: string } };
        },
    ) => {
        e.stopPropagation();

        if (e.target.dataset.id) {
            const parentId = e.target.dataset.id;

            const savedValues = value.values[parentId];

            if (savedValues && savedValues.length > 0) {
                props.showImage(savedValues[0]);
            }
        }
    };

    const handleBarcodePreview = (value: string) => {
        const request: IBarcodePreviewRequest = {
            value,
        };

        props.showBarcodePreview(request);
    };

    const handleShowChecklists = (
        e: React.UIEvent,
        checklistReportState: EChecklistReportState,
    ) => {
        e.stopPropagation();

        const payload: IShowModalPayload = {
            type: ModalTypes.checklist_report,
            modalFor: {
                type: ModalTypes.custom_list,
                id: value.id,
            },
            callerAddress: location.pathname,
            options: { checklistReportState },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowActiveChecklists = (e: React.UIEvent) =>
        handleShowChecklists(e, EChecklistReportState.active);

    const handleShowFinalizedChecklists = (e: React.UIEvent) =>
        handleShowChecklists(e, EChecklistReportState.finalized);

    return (
        <TableRow
            isNew={isNewItem}
            isArchived={value.isArchived}
            isDeleted={value.isDeleted}
            onClick={handleClick}
        >
            {defaultSelectedProperties?.visiblePropertyIds.map((propertyId) => {
                const property = defaultSelectedProperties.values[propertyId];

                if (!property) {
                    return null;
                }

                const { parentId, propertyCustomListId, mutualProperty } =
                    property;

                if (
                    excludeMutualPropertyParentId &&
                    mutualProperty?.parentId ===
                        excludeMutualPropertyParentId &&
                    defaultSelectedProperties.isAnyNotCustomListProperty
                ) {
                    return null;
                }

                const savedValues = value.values[parentId] ?? [];
                const savedValuesSet = new Set(savedValues);

                const notCustomListNames =
                    value.notCustomListNames &&
                    value.notCustomListNames[parentId];

                const isNotCustomListReference =
                    property.type === CustomListPropertyEnum.Customers ||
                    property.type === CustomListPropertyEnum.Suppliers ||
                    property.type === CustomListPropertyEnum.Items ||
                    property.type === CustomListPropertyEnum.Users ||
                    property.type === CustomListPropertyEnum.Positions ||
                    property.type === CustomListPropertyEnum.Competencies;

                const isCustomListReference =
                    property.type === CustomListPropertyEnum.None ||
                    property.type === CustomListPropertyEnum.Selection;

                const tasklistOptionsList = (
                    tasklistOptionsById && propertyCustomListId
                        ? tasklistOptionsById[propertyCustomListId]
                        : undefined
                )?.filter((x) =>
                    x.isArchived || x.isDeleted
                        ? savedValuesSet.has(x.value)
                        : true,
                );

                const isBarcode =
                    property.type === CustomListPropertyEnum.Barcodes ||
                    property.type === CustomListPropertyEnum.GenerateBarcode;

                const isHyperlink =
                    property.type === CustomListPropertyEnum.Hyperlink;

                return property.type === CustomListPropertyEnum.DateTime ? (
                    <TableCell key={`${value.id}-${propertyId}`} useOverflow>
                        {value.dateValues[parentId]?.map((date, i) => (
                            <React.Fragment key={i}>
                                {i > 0 && ", "}
                                <FormattedDate
                                    date={date}
                                    splitDateTime
                                    useFlexWrapper
                                />
                            </React.Fragment>
                        ))}
                    </TableCell>
                ) : isBarcode ? (
                    <TableCell key={`${value.id}-${propertyId}`} useOverflow>
                        {savedValues.map((barcode, i) => (
                            <Barcode
                                key={i}
                                value={barcode}
                                onClick={handleBarcodePreview}
                            />
                        ))}
                    </TableCell>
                ) : property.type === CustomListPropertyEnum.Tasklist ? (
                    <TableCell key={`${value.id}-${propertyId}`}>
                        <TagsContainer overflow vertical>
                            {tasklistOptionsList?.map((option, i) => (
                                <MultiselectValueBadge
                                    key={i}
                                    type="checkbox"
                                    checked={savedValues.includes(option.value)}
                                    isArchived={option.isArchived}
                                    isDeleted={option.isDeleted}
                                    ellipsis
                                >
                                    {option.label}
                                </MultiselectValueBadge>
                            ))}
                        </TagsContainer>
                    </TableCell>
                ) : property.type === CustomListPropertyEnum.Photo ? (
                    <TableCell key={`${value.id}-${propertyId}`}>
                        {savedValues.length > 0 ? (
                            <span
                                className="preview-link"
                                data-id={parentId}
                                onClick={handlePreviewPhoto}
                            >
                                {t(k.PHOTO_PREVIEW)}
                            </span>
                        ) : undefined}
                    </TableCell>
                ) : isCustomListReference ? (
                    <TableCell key={`${value.id}-${propertyId}`} useOverflow>
                        {savedValues.map((id, i) => (
                            <MultiselectValueBadge
                                key={i}
                                ellipsis
                                title={
                                    selectedCustomListItemsNames?.[id]?.label
                                }
                                isArchived={
                                    selectedCustomListItemsNames
                                        ? selectedCustomListItemsNames[id]
                                              ?.isArchived
                                        : undefined
                                }
                                isDeleted={
                                    selectedCustomListItemsNames
                                        ? selectedCustomListItemsNames[id]
                                              ?.isDeleted
                                        : undefined
                                }
                                id={
                                    property.type ===
                                    CustomListPropertyEnum.None
                                        ? id
                                        : undefined
                                }
                                onClick={
                                    property.type ===
                                    CustomListPropertyEnum.None
                                        ? handlePreviewCustomListItem
                                        : undefined
                                }
                            >
                                {selectedCustomListItemsNames?.[id]?.label}
                            </MultiselectValueBadge>
                        ))}
                    </TableCell>
                ) : isNotCustomListReference ? (
                    <TableCell key={`${value.id}-${propertyId}`}>
                        <TagsContainer overflow>
                            {notCustomListNames?.map((label, i) => (
                                <MultiselectValueBadge
                                    key={i}
                                    ellipsis
                                    title={label}
                                >
                                    {label}
                                </MultiselectValueBadge>
                            ))}
                        </TagsContainer>
                    </TableCell>
                ) : isHyperlink ? (
                    <TableCell key={`${value.id}-${propertyId}`} useEllipsis>
                        {savedValues.map((label, i) => (
                            <Link key={i} to={label}>
                                {label}
                            </Link>
                        ))}
                    </TableCell>
                ) : (
                    <TableCell key={`${value.id}-${propertyId}`}>
                        {savedValues.map((label, i) => (
                            <TextOverflow
                                key={`${value.id}-${propertyId}-${i}`}
                                ellipsis
                                multiLine
                            >
                                {label}
                            </TextOverflow>
                        ))}
                    </TableCell>
                );
            })}

            <TableCell>
                {value.createdAt && value.createdBy && (
                    <DateByUser date={value.createdAt} user={value.createdBy} />
                )}
            </TableCell>

            <TableCell>
                {value.updatedAt && value.updatedBy && (
                    <DateByUser date={value.updatedAt} user={value.updatedBy} />
                )}
            </TableCell>

            {canRunChecklistsWithIdsHasAnyValues && (
                <TableCell>
                    {canRunChecklistsWithIds &&
                        canRunChecklistsWithIds[value.id] && (
                            <StartChecklistButton
                                isArchived={value.isArchived ?? false}
                                size="small"
                                customListItemId={value.id}
                                templateVersionId={
                                    canRunChecklistsWithIds[value.id][0]
                                }
                            >
                                {t(k.START_THIS)}
                            </StartChecklistButton>
                        )}
                </TableCell>
            )}

            {hasActiveChecklists &&
                (activeChecklists?.[value.id] ? (
                    <TableCell onClick={handleShowActiveChecklists}>
                        <div className="preview-link">
                            {activeChecklists?.[value.id]} {t(k.ACTIVE_L)}
                        </div>
                    </TableCell>
                ) : (
                    <TableCell>
                        <div>0 {t(k.ACTIVE_L)}</div>
                    </TableCell>
                ))}

            {hasFinalizedChecklists &&
                (finalizedChecklists?.[value.id] ? (
                    <TableCell onClick={handleShowFinalizedChecklists}>
                        <div className="preview-link">
                            {finalizedChecklists?.[value.id]} {t(k.FINALIZED_L)}
                        </div>
                    </TableCell>
                ) : (
                    <TableCell>
                        <div>0 {t(k.FINALIZED_L)}</div>
                    </TableCell>
                ))}
        </TableRow>
    );
};

const actions: IDispatchProps = {
    showImage,
    showBarcodePreview,
};

export default connect(null, actions)(Row);
