import { Reducer } from "redux";

import {
    HIDE_QRSEARCH_FORM,
    QRSearchActionTypes,
    SELECT_QRSEARCH_WORKFLOW,
    SET_QRSEARCH_IS_LOADING,
    SHOW_QRSEARCH_FORM,
} from "./types";
import { IChecklistEntityDto } from "../../models/IChecklistEntityDto";

export interface IQRSearchState {
    readonly isLoading: boolean;
    readonly isFormVisible: boolean;
    readonly workflowId?: string;
    readonly workflowRun?: IChecklistEntityDto;
}

const initialState: IQRSearchState = {
    isFormVisible: false,
    isLoading: false,
};

export const QRSearchReducer: Reducer<IQRSearchState, QRSearchActionTypes> = (
    state = initialState,
    action,
): IQRSearchState => {
    switch (action.type) {
        case SET_QRSEARCH_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case SHOW_QRSEARCH_FORM:
            return {
                ...state,
                isFormVisible: true,
            };

        case HIDE_QRSEARCH_FORM:
            return {
                ...state,
                isFormVisible: false,
            };

        case SELECT_QRSEARCH_WORKFLOW:
            return {
                ...state,
                workflowId: action.payload,
            };

        default:
            return state;
    }
};
