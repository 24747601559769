import "./ItemIcon.scss";

import React from "react";
import { BiBookContent } from "react-icons/bi";

const ItemIcon: React.FC = () => {
    return <BiBookContent className="icon-item" />;
};

export default ItemIcon;
