import { Action } from "redux";

import IError from "../../common/viewModels/notifications/IError";

export const SHOW_ERROR = "SHOW_ERROR";

export const HIDE_ERROR = "HIDE_ERROR";

export interface IErrorShowAction extends Action<typeof SHOW_ERROR> {
    payload: IError;
}

export interface IErrorHideAction extends Action<typeof HIDE_ERROR> {
    id: string;
}

export type NotificationActionTypes = IErrorShowAction | IErrorHideAction;
