import SelectChecklistTemplate from "components/templates/components/SelectChecklistTemplate";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
    ModalTypes,
    IModalListItem,
    IShowModalPayload,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const SelectChecklistTemplateModal: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const {
        modalItem: { callId, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const location = useLocation();

    const handleOnClose = () => {
        modalManagerContext.onCloseModal({ callId });
    };

    const handleOnSelect = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.template,
            callerAddress: location.pathname,
        };

        if (options?.sourceCustomListItemId) {
            payload.options = {
                sourceCustomListItemId: options?.sourceCustomListItemId,
            };
        }

        modalManagerContext.onShowModal(payload);
    };

    return (
        <SelectChecklistTemplate
            customListItemId={options?.sourceCustomListItemId}
            onClose={handleOnClose}
            onSelect={handleOnSelect}
        />
    );
};

export default SelectChecklistTemplateModal;
