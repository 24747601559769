import { Dispatch } from "redux";

import { globalStore } from "store";
import IBarcodePreviewRequest from "./IBarcodePreviewRequest";
import {
    BarcodesActionTypes,
    HIDE_BARCODE_PREVIEW,
    HIDE_BARCODE_SCANNER,
    SHOW_BARCODE_PREVIEW,
    SHOW_BARCODE_SCANNER,
} from "./types";

export const showBarcodePreview = (
    value: IBarcodePreviewRequest,
): BarcodesActionTypes => {
    return {
        type: SHOW_BARCODE_PREVIEW,
        payload: value,
    };
};

export const hidePreview = (): BarcodesActionTypes => {
    return {
        type: HIDE_BARCODE_PREVIEW,
    };
};

export const showScanner = () => {
    const request = (): BarcodesActionTypes => {
        return {
            type: SHOW_BARCODE_SCANNER,
        };
    };

    return async (dispatch: Dispatch) => {
        dispatch(request());

        return new Promise<string | undefined>((resolve) => {
            const handleStateChange = () => {
                const newState = globalStore.getState();

                if (newState.barcodeViewState.isScanningBarcodeDone) {
                    resolve(newState.barcodeViewState.scannedBarcode);

                    unsubscribe();
                }
            };

            const unsubscribe = globalStore.subscribe(handleStateChange);
        });
    };
};

export const hideScanner = (barcode?: string): BarcodesActionTypes => {
    return {
        type: HIDE_BARCODE_SCANNER,
        payload: barcode,
    };
};
