import "./ManualDataSources.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { IFormulaItemDto } from "common/components/modal-manager-content/kpi-form/api/IFormulaItemDto";
import InputWrapper from "common/components/input-components/InputWrapper";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import {
    useManualDataSourcePerFormulaItemByKpiId,
    useManualDataSourcesByKpiId,
} from "common/components/modal-manager-content/kpi-form/api/hooks";
import ManualDataSource from "./components/ManualDataSource/ManualDataSource";
import useComponentActions from "common/components/react-custom-hooks/useComponentActions";
interface IProps {
    formulaItems: IFormulaItemDto[];
    kpiId?: string;
    canEdit?: boolean;
    currentlyEditedIds: string[];

    onHaveChanges: (manualDataSourceId: string, hasChanges: boolean) => void;
    registerAction: ReturnType<typeof useComponentActions>["registerAction"];
}
const ManualDataSources: React.FC<IProps> = (props) => {
    const { formulaItems, kpiId, canEdit, currentlyEditedIds } = props;

    const { t } = useTranslation();

    const {
        data: variableManualDataSourceIds,
        isLoading: isLoadingManualDataSourceIds,
    } = useManualDataSourcePerFormulaItemByKpiId(kpiId);

    const { data: manualDataSources, isLoading: isLoadingManualDataSources } =
        useManualDataSourcesByKpiId(kpiId);

    const isLoading =
        isLoadingManualDataSourceIds || isLoadingManualDataSources;

    return (
        <div className="kpi-variable-data-by-years">
            <div className="tables">
                {isLoading ? (
                    <AnimatedSpinner
                        isVisible={isLoading}
                        position="center"
                        aligned="center"
                    />
                ) : variableManualDataSourceIds &&
                  Object.keys(variableManualDataSourceIds).length > 0 ? (
                    <InputWrapper
                        boldLabel
                        inputClassName="kpi-variable-data-by-years--body"
                        wrapperLabel={t(k.VARIABLES_WITH_MANUAL_DATA_SOURCE)}
                        labelClassName="kpi-variable-data-by-years--label"
                    >
                        {Object.keys(variableManualDataSourceIds).map(
                            (variableId) => {
                                const manualDataSource =
                                    manualDataSources?.find(
                                        (manualDataSource) =>
                                            manualDataSource?.id ===
                                            variableManualDataSourceIds?.[
                                                variableId
                                            ],
                                    );
                                const formulaItem = formulaItems.find(
                                    (item) => item?.variable?.id === variableId,
                                );
                                const manualDataSourceId =
                                    variableManualDataSourceIds[variableId];

                                return (
                                    <ManualDataSource
                                        key={variableId}
                                        kpiId={kpiId}
                                        variableName={
                                            formulaItem?.variable?.name
                                        }
                                        manualDataSourceId={manualDataSourceId}
                                        formulaItemId={formulaItem?.id}
                                        manualDataSource={manualDataSource}
                                        canEdit={canEdit}
                                        hasChanges={currentlyEditedIds.includes(
                                            manualDataSourceId,
                                        )}
                                        onHasChanges={props.onHaveChanges}
                                        registerAction={props.registerAction}
                                    />
                                );
                            },
                        )}
                    </InputWrapper>
                ) : null}
            </div>
        </div>
    );
};

export default ManualDataSources;
