import "./Accordion.scss";

import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

import AccordionButton from "../buttons/accordion-button/AccordionButton";

interface IProps {
    open?: boolean;

    header: React.ReactNode;

    className?: string;

    manualToggle?: boolean;

    icon?: React.ReactNode;

    onToggle?: (open: boolean) => void;
}

const Accordion: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { open, header, icon } = props;

    const [wholeOpen, setWholeOpen] = useState(open ?? false);

    useEffect(() => {
        if (open !== undefined) {
            setWholeOpen(open);
        }
    }, [open]);

    const toggleCollapse = () => {
        if (props.onToggle) {
            props.onToggle(!wholeOpen);
        }

        setWholeOpen((x) => !x);
    };

    return (
        <div
            className={`app-accordion__wrapper${
                props.className ? " " + props.className : ""
            }`}
        >
            <div
                className="app-accordion"
                onClick={props.manualToggle ? undefined : toggleCollapse}
            >
                {header}
                <div className="app-accordion--toggle no-print">
                    {icon ?? <AccordionButton isOpen={wholeOpen} />}
                </div>
            </div>

            <Collapse in={wholeOpen}>
                <div>{props.children}</div>
            </Collapse>
        </div>
    );
};

export default Accordion;
