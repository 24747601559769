import React from "react";

import { IFilterProps } from "../api/IFilterProps";
import { ActivityType } from "models/enums/ActivityType";
import {
    useCustomListBasicListOptions,
    useCustomListItemOptions,
} from "components/custom-list-item-page/api/hooks";
import { sharedOptionTypeByChecklistActivityType } from "./utils";
import { useSharedOptions } from "common/components/input-components/dropdown/api/hooks";
import ColumnFilteringComponent from "./ColumnFilteringComponent";

interface IProps extends IFilterProps {}

export const CheckboxListFilter = (props: IProps) => {
    const { value } = props;

    const propertyType = value?.type;

    const sharedOptionType = propertyType
        ? sharedOptionTypeByChecklistActivityType[propertyType]
        : undefined;

    const [isVisible, setIsVisible] = React.useState(false);

    const selectedCustomListId = isVisible
        ? props.customListId ?? undefined
        : undefined;

    const isBasicList =
        propertyType === ActivityType.Tasklist ||
        propertyType === ActivityType.Selection;

    const basicCustomListResult = useCustomListBasicListOptions(
        isBasicList ? selectedCustomListId : undefined,
    );

    const notBasicCustomListResult = useCustomListItemOptions(
        isBasicList ? undefined : selectedCustomListId,
    );

    const sharedOptionsData = useSharedOptions(false, sharedOptionType);

    React.useEffect(() => {
        if (sharedOptionType) {
            sharedOptionsData.refetch();
        }
    }, [sharedOptionType]);

    const handleFilter = (newIds: string[]) => {
        if (propertyType !== undefined) {
            props.onFilter({
                type: propertyType,
                ids: newIds,
                isReset: newIds.length === 0,
            });
        }
    };

    const isLoading =
        basicCustomListResult.isLoading ||
        notBasicCustomListResult.isLoading ||
        sharedOptionsData.isLoading;

    const menuOptions =
        notBasicCustomListResult.data ??
        basicCustomListResult.data ??
        sharedOptionsData.data;

    return (
        <ColumnFilteringComponent
            isLoading={isLoading}
            menuOptions={menuOptions}
            selectedIds={value?.ids}
            onShow={setIsVisible}
            onFilter={handleFilter}
        />
    );
};
