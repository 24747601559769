import ModalContent from "common/components/modal-content/ModalContent";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import HistoryItem from "./HistoryItem";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { useEffect, useState } from "react";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import IHistoryDTO from "components/history/api/IHistoryDTO";
import { formatDate } from "common/components/formattedDate/FormattedDate";
import { HistoryOwnerEnum } from "./api/HistoryOwnerEnum";
import {
    useActivityNames,
    useHistoryById,
    useStepInstanceNames,
    useKpiTargetValuesByKpiId,
    useKpiManualDataSourceValuesByKpiId,
} from "./api/hooks";
import { IImpactGradingOption } from "components/impact-grading-page/api/IImpactGradingOption";
import "./History.scss";
import { renderItemText } from "./api/History.utils";
import { usePublishedTeamOptions } from "components/teams/api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import {
    useFormulaItemNames,
    useKpiOptions,
} from "common/components/modal-manager-content/kpi-form/api/hooks";
import { useProcessOptions } from "components/process-chart/pages/api/hooks";
import { useImprovementFormOptions } from "components/improvement-forms/api/hooks";
import { usePublishedUnitsList } from "components/item-types/api/hooks";
import { useInputActionNamesByIds } from "components/common/actions/api/hooks";
import { ICompetencyOption } from "components/competency-page/api/ICompetencyOption";

interface IProps {
    id?: string;
    type?: HistoryOwnerEnum;
    impactAreas?: IImpactGradingOption[];
    competencies?: Record<string, ICompetencyOption | undefined>;
    showAll?: boolean;
    onClose: () => void;
}

const History: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, type, showAll, impactAreas, competencies } = props;
    const { t } = useTranslation();

    const { data, isLoading: isLoadingHistory } = useHistoryById(
        id,
        type,
        showAll,
    );

    const stepInstanceNames = useStepInstanceNames(
        type === HistoryOwnerEnum.Kpi ? undefined : id,
        type,
        showAll,
    ); // disable when kpi

    const { aisNames, aiNames, aiSetIdsByAiIds } = useActivityNames(
        type === HistoryOwnerEnum.Kpi ? undefined : id,
        type,
        showAll,
    );

    const { data: formulaItemNames } = useFormulaItemNames(
        id ?? "",
        type === HistoryOwnerEnum.Kpi,
    );

    const { targetValues, isLoading: isLoadingTargetValues } =
        useKpiTargetValuesByKpiId(id ?? "", type === HistoryOwnerEnum.Kpi);

    const { data: teams, isLoading: isLoadingTeams } =
        usePublishedTeamOptions();

    const { data: users, isLoading: isLoadingUsers } =
        usePublishedUserOptions();

    const { data: kpiOptions, isLoading: isLoadingKpis } = useKpiOptions(
        undefined,
        type === HistoryOwnerEnum.Kpi,
    );

    const { data: processOptions, isLoading: isLoadingProcesses } =
        useProcessOptions(type === HistoryOwnerEnum.Kpi);

    const {
        data: improvementFormOptions,
        isLoading: isLoadingImprovementForms,
    } = useImprovementFormOptions(type === HistoryOwnerEnum.Kpi);

    const { manualValues, isLoading: isLoadingManualValues } =
        useKpiManualDataSourceValuesByKpiId(
            id ?? "",
            type === HistoryOwnerEnum.Kpi,
        );

    const { data: unitValues, isLoading: isLoadingUnits } =
        usePublishedUnitsList();

    const [filteredData, setFilteredData] = useState<IHistoryDTO[]>([]);

    const [inputActionIds, setInputActionIds] = useState<string[]>([]);

    const { data: inputActionNames } = useInputActionNamesByIds(inputActionIds);

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (data) {
            setFilteredData(data);
        }
    }, [data]);

    useEffect(() => {
        if (filteredData.length > 0) {
            const taskIds = filteredData
                .filter((x) => x.ownerType === HistoryOwnerEnum.Task)
                .map((x) => x.ownerId);

            const uniqueTaskIds = [...new Set(taskIds)];

            setInputActionIds(uniqueTaskIds);
        }
    }, [filteredData]);

    const filterData = (query: string) => {
        if (!data) return;

        const getRowSearchableText = (item: IHistoryDTO) =>
            [
                t(item.what),
                item.nameReferenceId
                    ? impactAreas?.find((x) => x.value === item.nameReferenceId)
                          ?.label ?? competencies?.[item.nameReferenceId]?.label
                    : "",
                renderItemText(
                    item,
                    t,
                    stepInstanceNames,
                    aisNames,
                    aiNames,
                    aiSetIdsByAiIds,
                    item.ownerType ?? type,
                    targetValues,
                    manualValues,
                    teams?.values,
                    users?.values,
                    formulaItemNames,
                    kpiOptions,
                    processOptions,
                    improvementFormOptions,
                    unitValues,
                    inputActionNames,
                ),
                formatDate({ date: item.createdAt }) || "",
                item.createdBy || "",
            ].join(" ");

        const newFilteredData = data.filter((item) =>
            getRowSearchableText(item)
                .toLowerCase()
                .includes(query.toLowerCase()),
        );

        setFilteredData(newFilteredData);
    };

    const handleChangeSearchKeywordFilter = (e: IInputChangeEvent<string>) => {
        filterData(e.value);
        setSearchValue(e.value);
    };

    const isLoading =
        isLoadingHistory ||
        isLoadingTargetValues ||
        isLoadingTeams ||
        isLoadingUsers ||
        isLoadingKpis ||
        isLoadingProcesses ||
        isLoadingImprovementForms ||
        isLoadingManualValues ||
        isLoadingUnits;

    return (
        <>
            <ModalContent.Header
                onClose={props.onClose}
                title={<div>{t(k.HISTORY)}</div>}
            />
            <ModalContent.Body>
                {isLoading ? (
                    <AnimatedSpinner
                        isVisible={isLoading}
                        position="center"
                        aligned="center"
                    />
                ) : (
                    <>
                        <InputComponent
                            inputType={EActivityType.Textfield}
                            id="filter.searchBy"
                            name="searchBy"
                            value={searchValue}
                            placeholder={t(k.SEARCH)}
                            size="large"
                            testId="historySearchId"
                            hideIcon
                            onChange={handleChangeSearchKeywordFilter}
                        />
                        {filteredData && filteredData.length > 0 ? (
                            filteredData.map((item) => {
                                return (
                                    <HistoryItem
                                        key={item.id}
                                        item={item}
                                        helperNameText={
                                            item.nameReferenceId
                                                ? impactAreas?.find(
                                                      (x) =>
                                                          x.value ===
                                                          item.nameReferenceId,
                                                  )?.label ??
                                                  competencies?.[
                                                      item.nameReferenceId
                                                  ]?.label
                                                : undefined
                                        }
                                        details={renderItemText(
                                            item,
                                            t,
                                            stepInstanceNames,
                                            aisNames,
                                            aiNames,
                                            aiSetIdsByAiIds,
                                            item.ownerType ?? type,
                                            targetValues,
                                            manualValues,
                                            teams?.values,
                                            users?.values,
                                            formulaItemNames,
                                            kpiOptions,
                                            processOptions,
                                            improvementFormOptions,
                                            unitValues,
                                            inputActionNames,
                                        )}
                                        onClose={props.onClose}
                                    />
                                );
                            })
                        ) : (
                            <div className="history--nothing-found">
                                {t(k.NOTHING_FOUND)}
                            </div>
                        )}
                    </>
                )}
            </ModalContent.Body>
        </>
    );
};

export default History;
