import "./DropdownIcon.scss";

import React from "react";
import { IoHelp } from "react-icons/io5";

const DropdownIcon: React.FC = () => {
    return <IoHelp className="icon-dropdown" />;
};

export default DropdownIcon;
