import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    allChecklistStatuses,
    ChecklistDynamicStatus,
} from "models/enums/ChecklistDynamicStatus";
import { IRecurringChecklistListItem } from "../api/IRecurringChecklistListItem";

interface IProps {
    items: IRecurringChecklistListItem[];
}

interface IReducerValue {
    id: number;
    index: number;
    count: number;
    value: React.ReactNode;
}

const SummaryCompletedChecklists: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { items } = props;

    const isNew = true;

    const { t } = useTranslation();

    const [list, lastListItem] = React.useMemo(() => {
        let reducer = allChecklistStatuses.reduce<IReducerValue[]>(
            (accumulator, { id: status }) => {
                const count = items.filter((x) => x.status === status).length;

                switch (status) {
                    case ChecklistDynamicStatus.Finalized:
                        accumulator.push({
                            id: status,
                            index: 0,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.COMPLETED_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong>{" "}
                                    {count === 1
                                        ? t(k.CHECKLIST_)
                                        : t(k.CHECKLISTS_)}{" "}
                                    {t(k.HAVE_BEEN_COMPLETED)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.Behind:
                        accumulator.push({
                            id: status,
                            index: 1,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.THAT_)}{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.BEHIND_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong>{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.BEHIND_)} {t(k.SCHEDULE_)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.Blocked:
                        accumulator.push({
                            id: status,
                            index: 2,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.THAT_)}{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.BLOCKED_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong>{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.BLOCKED_)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.Stopped:
                        accumulator.push({
                            id: status,
                            index: 3,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.THAT_)}{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.STOPPED_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.HAVE_BEEN_)}{" "}
                                    {t(k.STOPPED_)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.Archived:
                        accumulator.push({
                            id: status,
                            index: 4,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.THAT_)}{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.ARCHIVED_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.HAVE_BEEN_)}{" "}
                                    {t(k.ARCHIVED_)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.OnTrack:
                        accumulator.push({
                            id: status,
                            index: 6,
                            count,
                            value: isNew ? (
                                <React.Fragment>
                                    <strong>{count}</strong> {t(k.THAT_)}{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.ON_TRACK_)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{count}</strong>{" "}
                                    {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                    {t(k.ON_TRACK_)}
                                </React.Fragment>
                            ),
                        });

                        break;

                    case ChecklistDynamicStatus.Scheduled:
                        if (isNew) {
                            accumulator.push({
                                id: status,
                                index: 7,
                                count,
                                value: (
                                    <React.Fragment>
                                        <strong>{count}</strong> {t(k.THAT_)}{" "}
                                        {count === 1 ? t(k.IS_) : t(k.ARE_)}{" "}
                                        {t(k.SCHEDULED_)}
                                    </React.Fragment>
                                ),
                            });
                        }

                        break;
                }

                return accumulator;
            },
            [],
        );

        reducer = reducer
            .filter((x) => x.index === 0 || x.count > 0)
            .sort((a, b) => a.index - b.index);

        let lastElement: IReducerValue | undefined;

        if (reducer.length > 1) {
            lastElement = reducer.pop();
        }

        return [reducer, lastElement];
    }, [items]);

    if (isNew) {
        return (
            <React.Fragment>
                {t(k.IT_WILL_HAVE)}
                {list.map((x, i) => (
                    <React.Fragment key={x.id}>
                        {" "}
                        {x.value}
                        {i === list.length - 1 ? "" : ","}
                    </React.Fragment>
                ))}
                {lastListItem ? (
                    <React.Fragment>
                        {" "}
                        {t(k.AND_)} {lastListItem.value}.
                    </React.Fragment>
                ) : (
                    "."
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {t(k.SO_FAR)}
            {list.map((x, i) => (
                <React.Fragment key={x.id}>
                    {" "}
                    {x.value}
                    {i === list.length - 1 ? "" : ","}
                </React.Fragment>
            ))}
            {lastListItem ? (
                <React.Fragment>
                    {" "}
                    {t(k.AND_)} {lastListItem.value}.
                </React.Fragment>
            ) : (
                "."
            )}
        </React.Fragment>
    );
};

export default SummaryCompletedChecklists;
