import "./TaskSection.scss";

import React from "react";

interface IProps {
    title: React.ReactNode;

    tagsProps?: React.ReactNode;

    className?: string;
}

const TaskSection: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { title, tagsProps, className, children } = props;

    return (
        <div className={className ? `task ${className}` : "task"}>
            <div className="task-top">
                <div className="task-title">
                    <div className="task-desc">{title}</div>
                </div>

                {tagsProps && <div className="task-tags">{tagsProps}</div>}
            </div>
            {children && <div className="task-content">{children}</div>}
        </div>
    );
};

export default TaskSection;
