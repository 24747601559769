import {
    SelectedDynamicValues,
    SelectedValues,
} from "../configurationStep.hooks";
import { Dispatch, SetStateAction } from "react";
import { v4 as uuidv4 } from "uuid";
import k from "i18n/keys";
import { TemplateResponsibleType } from "components/improvement-forms/api/TemplateResponsibleType";
import ITemplateConfigurationDTO from "components/improvement-forms/api/ITemplateConfigurationDTO";

export function findMaxIndexValueInDictionary(
    dict: SelectedDynamicValues | undefined,
): number {
    const values = Object.values(dict ?? {});
    if (values.length === 0) {
        return 0;
    }

    return values.reduce((max, item) => {
        return item.index > max ? item.index : max;
    }, values[0].index);
}

export function getKeysOrderedByIndex(dict: SelectedDynamicValues): string[] {
    return Object.keys(dict).sort((a, b) => dict[a].index - dict[b].index);
}

export const handleAddUserRowUtil = (
    selectedValues: SelectedDynamicValues | undefined,
    setSelectedValues: Dispatch<
        SetStateAction<SelectedDynamicValues | undefined>
    >,
) => {
    let newSetId = uuidv4();

    //find item that has the largest index value
    let maxIndex = findMaxIndexValueInDictionary(selectedValues);

    setSelectedValues((prevValues) => ({
        ...prevValues,
        [newSetId]: { index: maxIndex + 1, shouldBeNotified: false, users: [] },
    }));
};

export const handleChangeNotifyUserSetUtil = (
    selectedDynamicValues: SelectedDynamicValues | undefined,
    row: string,
    value: boolean,
    setSelectedDynamicValues: Dispatch<
        SetStateAction<SelectedDynamicValues | undefined>
    >,
) => {
    if (Object.keys(selectedDynamicValues ?? {}).indexOf(row) !== -1) {
        setSelectedDynamicValues((prevValues) => {
            if (!prevValues) return prevValues;

            return {
                ...prevValues,
                [row]: {
                    ...prevValues[row],
                    shouldBeNotified: value,
                },
            };
        });
    }
};
export function handleChangeUsersByUserSetsUtil(
    selectedDynamicValues: SelectedDynamicValues | undefined,
    row: string,
    value: string[],
    setSelectedDynamicValues: Dispatch<
        SetStateAction<SelectedDynamicValues | undefined>
    >,
) {
    if (Object.keys(selectedDynamicValues ?? {}).indexOf(row) !== -1) {
        setSelectedDynamicValues((prevValues) => {
            if (!prevValues) return prevValues;

            return {
                ...prevValues,
                [row]: {
                    ...prevValues[row],
                    users: value,
                },
            };
        });
    }
}
export const handleClickUtil = (
    selectedValues: SelectedValues | undefined,
    row: string,
    column: string,
    value: boolean,
    setSelectedValues: Dispatch<SetStateAction<SelectedValues | undefined>>,
) => {
    setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };

        if (column === k.RESPONSIBLE || column === k.INVOLVED) {
            //cannot notify if there will be no responsible or involved
            if (
                !(
                    Boolean(selectedValues?.[k.RESPONSIBLE]?.[row]?.value) &&
                    Boolean(selectedValues?.[k.INVOLVED]?.[row]?.value)
                )
            ) {
                updatedValues[k.NOTIFY] = {
                    ...(prevValues?.[k.NOTIFY] || {}),
                    [row]: {
                        ...prevValues?.[k.NOTIFY]?.[row],
                        disabled: !value,
                        value:
                            prevValues?.[k.NOTIFY]?.[row]?.value && !value
                                ? false
                                : prevValues?.[k.NOTIFY]?.[row]?.value,
                    },
                };
            }
        }

        updatedValues[column] = {
            ...(prevValues?.[column] || {}),
            [row]: {
                ...prevValues?.[column]?.[row],
                value,
            },
        };

        return updatedValues;
    });
};

export const handleDeleteRowUtil = (
    id: string,
    selectedValues: SelectedDynamicValues | undefined,
    setSelectedValues: Dispatch<
        SetStateAction<SelectedDynamicValues | undefined>
    >,
) => {
    setSelectedValues((prevValues = {}) => ({
        ...prevValues,
        [id]: {
            ...prevValues[id],
            isDeleted: !prevValues[id].isDeleted,
        },
    }));
};

export const updateConfiguration = (
    selectedValues: SelectedValues,
    selectedDynamicValues: SelectedDynamicValues | undefined,
    templateVersionId: string | undefined,
) => {
    let newConfiguration: ITemplateConfigurationDTO = {
        templateVersionId,
        responsibleTypes: [],
        involvedTypes: [],
        notifyTypes: [],
        involvedUserSets: [],
    };

    const processSelectedValues = (
        type: string,
        targetArray: TemplateResponsibleType[],
    ) => {
        selectedValues[type] &&
            Object.keys(selectedValues[type])?.forEach((key) => {
                if (
                    selectedValues[type][key] &&
                    selectedValues[type][key].value
                ) {
                    if (key === k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS) {
                        targetArray.push(
                            TemplateResponsibleType.DiscoveredInProcessOwner,
                        );
                    } else if (
                        key === k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS
                    ) {
                        targetArray.push(
                            TemplateResponsibleType.OriginatedInProcessOwner,
                        );
                    } else if (key === k.REPORTING_USERS_MANAGER) {
                        targetArray.push(
                            TemplateResponsibleType.ManagerOfReportingUser,
                        );
                    }
                }
            });
    };

    processSelectedValues(k.RESPONSIBLE, newConfiguration.responsibleTypes);
    processSelectedValues(k.INVOLVED, newConfiguration.involvedTypes);
    processSelectedValues(k.NOTIFY, newConfiguration.notifyTypes);

    newConfiguration.involvedUserSets = selectedDynamicValues
        ? Object.keys(selectedDynamicValues).map((key) => ({
              id: key,
              ...selectedDynamicValues[key],
          }))
        : [];

    return newConfiguration;
};
