import "./Barcode.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import ErrorBoundary from "common/utils/errors/ErrorBoundary";
import WarningIcon from "common/components/icons/icon-warning/WarningIcon";
import BarcodeEan13 from "./components/BarcodeEan13";
import IBarcodeProps from "./IBarcodeProps";

const Barcode: React.FC<IBarcodeProps> = (props) => {
    const { t } = useTranslation();

    const handleClick = (e: React.UIEvent) => {
        e.stopPropagation();

        if (props.onClick) {
            props.onClick(props.value);
        }
    };

    if (props.value === "") {
        return null;
    }

    return (
        <ErrorBoundary
            fallback={
                <div className="barcode-preview--error">
                    <WarningIcon />
                    <span>
                        {t(k.INVALID_BARCODE)}
                        <br />({props.value})
                    </span>
                </div>
            }
        >
            <div
                className={
                    props.isBlured
                        ? "barcode-preview barcode-preview--blured"
                        : "barcode-preview"
                }
                onClick={handleClick}
            >
                <BarcodeEan13 {...props} />
            </div>
        </ErrorBoundary>
    );
};

export default Barcode;
