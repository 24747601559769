import { Reducer } from "redux";

import IBarcodePreviewRequest from "./IBarcodePreviewRequest";

import {
    BarcodesActionTypes,
    HIDE_BARCODE_PREVIEW,
    HIDE_BARCODE_SCANNER,
    SHOW_BARCODE_PREVIEW,
    SHOW_BARCODE_SCANNER,
} from "./types";

export interface IBarcodeState {
    readonly isPreviewVisible: boolean;

    readonly previewRequest?: IBarcodePreviewRequest;

    readonly isScanningBarcodeDone: boolean;
    readonly isScannerVisible: boolean;
    readonly scannedBarcode?: string;
}

const initialState: IBarcodeState = {
    isPreviewVisible: false,

    isScanningBarcodeDone: false,
    isScannerVisible: false,
};

export const BarcodeReducer: Reducer<IBarcodeState, BarcodesActionTypes> = (
    state = initialState,
    action,
): IBarcodeState => {
    switch (action.type) {
        case SHOW_BARCODE_PREVIEW: {
            return {
                ...state,
                isPreviewVisible: true,
                previewRequest: action.payload,
            };
        }

        case HIDE_BARCODE_PREVIEW: {
            return {
                ...state,
                isPreviewVisible: false,
                previewRequest: undefined,
            };
        }

        case SHOW_BARCODE_SCANNER: {
            return {
                ...state,
                isScanningBarcodeDone: false,
                isScannerVisible: true,
                scannedBarcode: undefined,
            };
        }

        case HIDE_BARCODE_SCANNER: {
            return {
                ...state,
                isScanningBarcodeDone: true,
                isScannerVisible: false,
                scannedBarcode: action.payload,
            };
        }

        default:
            return state;
    }
};
