import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    BarcodeIcon,
    CalendarIcon,
    CameraIcon,
    CustomerIcon,
    DropdownIcon,
    ItemIcon,
    LinkIcon,
    SupplierIcon,
    TaskCheckboxIcon,
    TextfieldIcon,
} from "common/components/icons";
import PropertyListItem from "./PropertyListItem";

import { IAppState } from "store/IAppState";
import { useSelector } from "react-redux";
import { ActivityType } from "models/enums/ActivityType";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";

export interface IBasicActivityListProps {
    onSelect: (type: ActivityType, title: string) => void;
}

const BasicActivityList: React.FC<
    React.PropsWithChildren<IBasicActivityListProps>
> = (props) => {
    const appFeatures = useSelector(
        (state: IAppState) => state.authViewState.profile?.appFeatures,
    );

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PropertyListItem
                type={ActivityType.Value}
                icon={<TextfieldIcon />}
                title={t(k.ADD_TEXTFIELD)}
                subTitle="Single line of text"
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={ActivityType.Text}
                icon={<TextfieldIcon isMultiline />}
                title={t(k.ADD_MULTI_LINE_TEXTFIELD)}
                subTitle="Multi line of text"
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={ActivityType.Tasklist}
                icon={<TaskCheckboxIcon />}
                title={t(k.ADD_CHECKBOX)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={ActivityType.DateTime}
                icon={<CalendarIcon />}
                title={t(k.ADD_DATE_TIME)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={ActivityType.Photo}
                icon={<CameraIcon />}
                title={t(k.PHOTO)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={ActivityType.Selection}
                icon={<DropdownIcon />}
                title={t(k.ADD_DROPDOWN)}
                subTitle=""
                onSelect={props.onSelect}
            />

            {appFeatures?.customers && (
                <PropertyListItem
                    type={ActivityType.Customers}
                    icon={<CustomerIcon />}
                    title={t(k.ADD_CUSTOMER_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.suppliers && (
                <PropertyListItem
                    type={ActivityType.Suppliers}
                    icon={<SupplierIcon />}
                    title={t(k.ADD_SUPPLIER_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.itemCatalog && (
                <PropertyListItem
                    type={ActivityType.Items}
                    icon={<ItemIcon />}
                    title={t(k.ADD_ITEM_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.barcodeActivity && (
                <PropertyListItem
                    type={ActivityType.Barcodes}
                    icon={<BarcodeIcon />}
                    title={t(k.BARCODES)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.generateBarcodeActivity && (
                <PropertyListItem
                    type={ActivityType.GenerateBarcode}
                    icon={<BarcodeIcon />}
                    title={t(k.GENERATE_BARCODE)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            <PropertyListItem
                type={ActivityType.Hyperlink}
                icon={<LinkIcon />}
                title={t(k.HYPERLINK)}
                subTitle=""
                onSelect={props.onSelect}
            />

            <PropertyListItem
                type={ActivityType.Users}
                icon={<UserIcon />}
                title={t(k.USERS)}
                subTitle=""
                onSelect={props.onSelect}
            />

            <PropertyListItem
                type={ActivityType.Positions}
                icon={<PositionIcon />}
                title={t(k.POSITIONS)}
                subTitle=""
                onSelect={props.onSelect}
            />

            {appFeatures?.competencies && (
                <PropertyListItem
                    type={ActivityType.Competencies}
                    icon={<CompetencyIcon />}
                    title={t(k.COMPETENCIES)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}
        </React.Fragment>
    );
};

export default BasicActivityList;
