import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ActionMeta } from "react-select";

import { LocaleId } from "AppLocale";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ICognitGPTResponse } from "common/api/ICognitGPTResponse";
import Accordion from "common/components/accordion/Accordion";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import AIButton from "common/components/buttons/ai-button/AIButton";
import CognitGPTButton from "common/components/buttons/cognit-gpt-button/CognitGPTButton";
import SelectButton, {
    ISelectButtonOptions,
} from "common/components/buttons/select-button/SelectButton";
import DatePicker from "common/components/date-picker/DatePicker";
import Textfield from "common/components/input-components/textfield/Textfield";
import ModalContent from "common/components/modal-content/ModalContent";
import {
    ModalTypes,
    IModalFor,
    TModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import {
    CognitGPTAdditionalInfoKey,
    CognitGPTPromptType,
} from "common/utils/cognitGPTUtils";
import useCognitGPTHub from "common/utils/hooks/signalR-hooks/useCognitGPTHub";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    scrollToTopErrorById,
    getIdsFromValidationMessages,
} from "components/common/validation/ScrollToError";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { getImpactGradingOptions, ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import ImpactGradingCard from "components/impact-grading-page/components/inputs/ImpactGradingCard";
import { useProcessStepOptions } from "components/process-chart/subprocess/api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import k from "i18n/keys";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmDispatch } from "store/confirms/actions";
import { v4 as uuidv4 } from "uuid";

import IOperationalRiskAnalysisRowDTO from "../api/IOperationalRiskAnalysisRowDTO";
import {
    useArchiveOperationalRiskAnalysisMutation,
    useDeleteOperationalRiskAnalysisMutation,
    useOccurenceOptions,
    useOperationalRiskAnalysisGetById,
    useProbabilityOptions,
    useRiskStatusOptions,
    useSaveOperationalRiskAnalysisMutation,
    useYesNoSelectionOptions,
} from "../api/hooks";
import { riskKeyByPromptType } from "../utils/cognitUtils";
import ArchiveMessage from "./messages/ArchiveMessage";
import DeleteMessage from "./messages/DeleteMessage";
import UnarchiveMessage from "./messages/UnarchiveMessage";

import "./RiskForm.scss";
import ActivityInstanceCard from "common/components/activity-instance-card/ActivityInstanceCard";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import { ActivityType } from "models/enums/ActivityType";

interface IProps {
    id?: string;

    riskFor?: IModalFor;

    createNew?: boolean;

    syncData?: boolean;

    onHaveChanges: (haveChanges: boolean) => void;

    onClose: (onSave?: boolean) => void;
}

function getInitialState(riskFor?: { [key in TModalTypes]?: string }) {
    const result: IOperationalRiskAnalysisRowDTO = {
        id: uuidv4(),
        processStepIds: riskFor?.process_step ? [riskFor?.process_step] : [],
        riskScenario: "",
        potentialConsequences: "",
        comments: "",
        commentsProbability: "",
        riskReduction: "",
        isArchived: false,
        isHighlighted: false,
        impact: ImpactGradingEnum.None,
    };

    return result;
}

const RiskForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, createNew = false, syncData = true, riskFor } = props;

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const { language: localeId } = i18n;

    const canEdit = useSelector((appState: IAppState) => {
        const {
            ADMINISTRATOR: isAdministrator,
            ORA_EDITOR: isORAEditor,
            ORA_OWNER: isORAOwner,
        } = appState.authViewState.roles;

        const canEdit = isAdministrator || isORAEditor || isORAOwner;

        return canEdit;
    });

    const canDelete = useSelector((appState: IAppState) => {
        const { ADMINISTRATOR: isAdministrator, ORA_OWNER: isORAOwner } =
            appState.authViewState.roles;

        return isAdministrator || isORAOwner;
    });

    const canUseAI = useSelector(
        (appState: IAppState) =>
            appState.authViewState.profile?.appFeatures?.ai ?? false,
    );

    const { data: dataById } = useOperationalRiskAnalysisGetById(
        !createNew && syncData,
        id,
        localeId,
    );

    const [errors, setErrors] = useState<IValidationMessages>({});

    const saveMutation = useSaveOperationalRiskAnalysisMutation();

    const archiveMutation = useArchiveOperationalRiskAnalysisMutation();
    const deleteMutation = useDeleteOperationalRiskAnalysisMutation();

    const isSaving = saveMutation.isLoading;

    const [item, setItem] = useState(
        createNew
            ? getInitialState(
                  riskFor?.type ? { [riskFor?.type]: riskFor?.id } : undefined,
              )
            : undefined,
    );

    const { response, gptErrors, gptLoading, onGenerateText, cancelStream } =
        useCognitGPTHub(canUseAI);

    const hasProcessChartFeatureEnabled = useSelector((appState: IAppState) => {
        return appState.authViewState.profile?.appFeatures?.processChart;
    });

    const hasImpactGradingsFeatureEnabled = useSelector(
        (appState: IAppState) =>
            appState.authViewState.profile?.appFeatures?.impactGradings,
    );

    const impactList = useMemo(
        () => getImpactGradingOptions(i18n),
        [i18n.language],
    );

    const receiveMessageHandler = (response: ICognitGPTResponse) => {
        const name = riskKeyByPromptType[response.promptType];
        if (name) {
            setItem((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        [name]: response.text,
                    };
                }
                return prev;
            });
        }
    };

    useEffect(() => {
        receiveMessageHandler(response);
    }, [response]);

    React.useEffect(() => {
        if (item === undefined && dataById) {
            setItem(dataById);
        }
    }, [dataById]);

    const { data: impactAreas, isLoading: impactAreasLoading } =
        useImpactGradingOptions(
            localeId as LocaleId,
            hasImpactGradingsFeatureEnabled,
        );

    const { data: processStepOptions } = useProcessStepOptions();

    const { data: yesNoSelectionOptions } = useYesNoSelectionOptions(localeId);

    const { data: occurenceOptions } = useOccurenceOptions(localeId);

    const { data: probabilityOptions } = useProbabilityOptions(localeId);

    const { data: userOptions } = usePublishedUserOptions();

    const { data: riskStatusOptions } = useRiskStatusOptions(localeId);

    const useDefaultImpact =
        !hasImpactGradingsFeatureEnabled ||
        (impactAreasLoading === false &&
            impactAreas &&
            impactAreas.length === 0);

    const handleArchive = async () => {
        if (id) {
            if (await showConfirmDispatch(dispatch, <ArchiveMessage />)) {
                setItem((prev) => prev && { ...prev, isArchived: true });

                const result = await archiveMutation.mutateAsync({
                    request: { id, isArchive: true },
                });

                if (result === false) {
                    setItem((prev) => prev && { ...prev, isArchived: false });
                }
            }
        }
    };

    const handleUnarchive = async () => {
        if (id) {
            if (await showConfirmDispatch(dispatch, <UnarchiveMessage />)) {
                setItem((prev) => prev && { ...prev, isArchived: false });

                const result = await archiveMutation.mutateAsync({
                    request: { id, isArchive: false },
                });

                if (result) {
                    setItem((prev) => prev && { ...prev, isArchived: true });
                }
            }
        }
    };

    const handleDelete = async () => {
        if (id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <DeleteMessage />,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
            };

            if (await showConfirmDispatch(dispatch, confirmMsg)) {
                const result = await deleteMutation.mutateAsync({
                    id,
                });

                if (result) {
                    props.onClose(true);
                }
            }
        }
    };

    const handleChange = (value: string, id?: string, name?: string) => {
        if (name) {
            props.onHaveChanges(true);

            setItem(
                (prev) =>
                    prev && {
                        ...prev,

                        [name]: value,
                    },
            );
        }
    };

    const handleChangeNumber = (value: string, id?: string, name?: string) => {
        if (name) {
            props.onHaveChanges(true);

            const val = parseFloat(value);

            setItem(
                (prev) =>
                    prev && {
                        ...prev,

                        [name]: isNaN(val) ? undefined : val,

                        updatedRiskScore:
                            name === "estimatedProbabilityReduction"
                                ? calculateUpdatedRiskScore(
                                      prev.riskScore,
                                      isNaN(val) ? undefined : val,
                                  )
                                : prev.updatedRiskScore,
                    },
            );
        }
    };

    const handleChangeDate = (value: Date, name?: string) => {
        if (name) {
            props.onHaveChanges(true);

            setItem(
                (prev) =>
                    prev && {
                        ...prev,

                        ...prev,
                        [name]: value,
                    },
            );
        }
    };

    const updateCalculatedProperties = (
        list?: IDictionary<ImpactGradingEnum>,
        nextTotalImpactRating?: number,
        probabilityId?: string,
        occurenceId?: string,
        impact?: ImpactGradingEnum,
    ) => {
        const probability = probabilityId
            ? probabilityOptions?.values?.[probabilityId]?.param
            : undefined;

        const occurence = occurenceId
            ? occurenceOptions?.values?.[occurenceId]?.param
            : undefined;

        const nextRiskScore =
            nextTotalImpactRating !== undefined &&
            probability !== undefined &&
            occurence !== undefined
                ? nextTotalImpactRating * Math.max(probability, occurence)
                : undefined;

        props.onHaveChanges(true);

        setItem(
            (prev) =>
                prev && {
                    ...prev,
                    impact: impact,
                    impactList: list,
                    totalImpactRating: nextTotalImpactRating,
                    probabilityId,
                    occurenceId,
                    riskScore: nextRiskScore,
                    updatedRiskScore: calculateUpdatedRiskScore(
                        nextRiskScore,
                        prev.estimatedProbabilityReduction,
                    ),
                },
        );
    };

    const calculateUpdatedRiskScore = (
        riskScore?: number,
        estimatedProbabilityReduction?: number,
    ) => {
        const result =
            riskScore !== undefined &&
            estimatedProbabilityReduction !== undefined
                ? (riskScore * (100 - estimatedProbabilityReduction)) / 100.0
                : undefined;

        return result;
    };

    const handleSelectSingle = (e: any, meta: ActionMeta<any>) => {
        const name = meta.name;

        if (name) {
            const option = e as IValueLabelItem<string, string>;

            const newValue = option ? option.value : undefined;

            props.onHaveChanges(true);

            switch (name) {
                case "occurenceId":
                    updateCalculatedProperties(
                        item?.impactList,
                        item?.totalImpactRating,
                        item?.probabilityId,
                        newValue,
                        item?.impact,
                    );
                    break;
                case "probabilityId":
                    updateCalculatedProperties(
                        item?.impactList,
                        item?.totalImpactRating,
                        newValue,
                        item?.occurenceId,
                        item?.impact,
                    );

                    break;

                default:
                    setItem(
                        (prev) =>
                            prev && {
                                ...prev,

                                [name]: newValue,
                            },
                    );
                    break;
            }
        }
    };

    const handleSelectMany = (e: any, meta: ActionMeta<any>) => {
        const name = meta.name;

        if (name) {
            const values: Array<IValueLabelItem<string, string>> =
                (e as Array<IValueLabelItem<string, string>>) ?? [];

            props.onHaveChanges(true);

            switch (name) {
                case "processStepIds":
                    setItem(
                        (prev) =>
                            prev && {
                                ...prev,

                                [name]: values.map((x) => x.value),
                            },
                    );

                    break;

                case "subjectAreaList":
                    setItem(
                        (prev) =>
                            prev && {
                                ...prev,

                                [name]: values,
                            },
                    );

                    break;
            }
        }
    };

    const handleRealistic = (id: string) => {
        props.onHaveChanges(true);

        setItem(
            (prev) =>
                prev && {
                    ...prev,
                    realisticId: id,
                },
        );
    };

    const handleIsHighlighted = (id: string) => {
        props.onHaveChanges(true);

        setItem(
            (prev) =>
                prev && {
                    ...prev,
                    isHighlighted: id === "1" ? true : false,
                },
        );
    };

    const handleSave = async () => {
        if (item === undefined) {
            return;
        }

        const result = await saveMutation.mutateAsync({
            isCreate: createNew,
            value: {
                ...item,
            },
            filter: { localeId: localeId },
        });

        if (result.Succeeded) {
            props.onClose(true);
        } else {
            setErrors(result.Messages);
            scrollToTopErrorById(getIdsFromValidationMessages(result.Messages));
        }
    };

    const handleChangeImpactGradingValue = (
        list: IDictionary<ImpactGradingEnum>,
        impactMax: ImpactGradingEnum,
    ) => {
        updateCalculatedProperties(
            list,
            Number(impactMax),
            item?.probabilityId,
            item?.occurenceId,
            impactMax,
        );
    };

    const handleOnChangeImpactItem = (e: IInputChangeEvent<string>) => {
        const newImpact = parseInt(e.value);
        updateCalculatedProperties(
            item?.impactList,
            isNaN(newImpact) ? undefined : newImpact,
            item?.probabilityId,
            item?.occurenceId,
            newImpact,
        );
    };

    const handleClose = () => {
        props.onClose();
    };

    const realisticButtonProps = React.useMemo(() => {
        if (yesNoSelectionOptions) {
            const result = yesNoSelectionOptions.ids.map((x) => {
                const item: ISelectButtonOptions = {
                    id: x,
                    label: yesNoSelectionOptions.values?.[x]?.label,
                    buttonProps: { variant: "dark-blue", noMinWidth: true },
                };
                return item;
            });

            return result;
        }
    }, [yesNoSelectionOptions]);

    const highlightedButtonProps: ISelectButtonOptions[] = [
        {
            id: "1",
            label: t(k.YES),
            buttonProps: { variant: "dark-blue", noMinWidth: true },
        },
        {
            id: "0",
            label: t(k.NO),
            buttonProps: { variant: "dark-blue", noMinWidth: true },
        },
    ];

    const isDisabled = item?.isArchived || isSaving || canEdit === false;

    return (
        <ModalContent>
            <ModalContent.Header
                onClose={handleClose}
                title={
                    createNew ? t(k.ADD_OPERATIONAL_RISK_ANALYSIS) : t(k.EDIT)
                }
            />

            <ModalContent.Body>
                <div className="operational-risk-analysis-form--body">
                    {hasProcessChartFeatureEnabled && (
                        <Accordion
                            className="ora-form--accordion"
                            header={t(k.PROCESS)}
                            open
                        >
                            <label
                                htmlFor="form.AffectedProcessStepIds"
                                className="text-field"
                            >
                                <div className="text-field--label">
                                    {t(k.AFFECTED_PROCESS_STEPS)}
                                </div>
                                <SelectDropdown
                                    id={"AffectedProcessStepIds"}
                                    inputId="form.AffectedProcessStepIds"
                                    name="processStepIds"
                                    value={processStepOptions?.options.filter(
                                        (x) =>
                                            item?.processStepIds.includes(
                                                x.value,
                                            ),
                                    )}
                                    invalid={Boolean(
                                        errors.AffectedProcessStepIds,
                                    )}
                                    isClearable
                                    isSearchable
                                    bold
                                    placeholder={t(k.SELECT)}
                                    options={processStepOptions?.options}
                                    isDisabled={
                                        isDisabled ||
                                        riskFor?.type ===
                                            ModalTypes.process_step
                                    }
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={handleSelectMany}
                                />

                                {errors && (
                                    <ValidationLabel
                                        errors={errors.AffectedProcessStepIds}
                                    />
                                )}
                            </label>
                        </Accordion>
                    )}

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.INFORMATION)}
                        open
                    >
                        <Textfield
                            id="RiskScenario"
                            name="riskScenario"
                            value={item?.riskScenario ?? ""}
                            invalid={Boolean(errors.RiskScenario)}
                            placeholder={t(k.WHAT_IF)}
                            isBusy={
                                gptLoading ===
                                    CognitGPTPromptType.ORA_RiskScenario ||
                                gptLoading ===
                                    CognitGPTPromptType.ORA_RiskScenario_RewriteExisting
                            }
                            size="large"
                            labelContent={t(k.RISK_SCENARIO)}
                            disabled={isDisabled}
                            onChange={handleChange}
                            postInputContent={
                                <CognitGPTButton
                                    canUseAI={canUseAI}
                                    promptType={
                                        CognitGPTPromptType.ORA_RiskScenario
                                    }
                                    additionalInfo={{
                                        ...(item?.riskScenario && {
                                            [CognitGPTAdditionalInfoKey.RiskScenario]:
                                                item.riskScenario,
                                        }),
                                    }}
                                    text={item?.riskScenario}
                                    rephrasePromptType={
                                        CognitGPTPromptType.ORA_RiskScenario_RewriteExisting
                                    }
                                    gptLoading={gptLoading}
                                    isDisabled={isDisabled}
                                    gptErrors={gptErrors}
                                    onGenerateText={onGenerateText}
                                    onCancelStreamingMessage={cancelStream}
                                />
                            }
                            helperText={
                                <Textfield.Error>
                                    {errors.RiskScenario}
                                </Textfield.Error>
                            }
                            multiline={true}
                            minRows={5}
                        />

                        <Textfield
                            id="PotentialConsequences"
                            name="potentialConsequences"
                            value={item?.potentialConsequences ?? ""}
                            isBusy={
                                gptLoading ===
                                    CognitGPTPromptType.ORA_PotentialConsequences ||
                                gptLoading ===
                                    CognitGPTPromptType.ORA_PotentialConsequences_RewriteExisting
                            }
                            invalid={Boolean(errors.PotentialConsequences)}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            labelContent={t(k.POTENTIAL_CONSEQUENCES)}
                            disabled={isDisabled}
                            onChange={handleChange}
                            helperText={
                                <Textfield.Error>
                                    {errors.PotentialConsequences}
                                </Textfield.Error>
                            }
                            postInputContent={
                                <CognitGPTButton
                                    canUseAI={canUseAI}
                                    text={item?.potentialConsequences}
                                    promptType={
                                        CognitGPTPromptType.ORA_PotentialConsequences
                                    }
                                    additionalInfo={{
                                        ...(item?.riskScenario && {
                                            [CognitGPTAdditionalInfoKey.RiskScenario]:
                                                item.riskScenario,
                                        }),
                                        ...(item?.potentialConsequences && {
                                            [CognitGPTAdditionalInfoKey.RiskConsequences]:
                                                item.potentialConsequences,
                                        }),
                                    }}
                                    rephrasePromptType={
                                        CognitGPTPromptType.ORA_PotentialConsequences_RewriteExisting
                                    }
                                    gptLoading={gptLoading}
                                    isDisabled={isDisabled}
                                    gptErrors={gptErrors}
                                    onGenerateText={onGenerateText}
                                    onCancelStreamingMessage={cancelStream}
                                />
                            }
                            multiline={true}
                            minRows={3}
                        />

                        <label
                            htmlFor="form.RealisticId"
                            className="text-field"
                        >
                            <div className="text-field--label">
                                {t(k.REALISTIC)}
                            </div>

                            <SelectButton
                                id="form.RealisticId"
                                buttons={realisticButtonProps}
                                selected={
                                    item?.realisticId
                                        ? [item.realisticId]
                                        : undefined
                                }
                                disabled={isDisabled}
                                onSelect={handleRealistic}
                            />

                            {errors && (
                                <ValidationLabel
                                    errors={errors.RealisticName}
                                />
                            )}
                        </label>
                    </Accordion>

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.IMPACT_GRADE)}
                        open={!Boolean(riskFor)}
                    >
                        {item && (
                            <>
                                {useDefaultImpact ? (
                                    <div className="impact-grading">
                                        <div className="text-field--label">
                                            {t(k.IMPACT)}
                                        </div>
                                        <ActivityInstanceCard
                                            removeTags
                                            type={ActivityType.Selection}
                                            id={`impactGradings`}
                                            value={String(item.impact)}
                                            options={impactList}
                                            label={t(k.IMPACT)}
                                            completed={
                                                item.impact !==
                                                ImpactGradingEnum.None
                                            }
                                            required
                                            onChange={handleOnChangeImpactItem}
                                            originType={TaskOriginType.Goal}
                                            invalid={
                                                !!errors?.[`impactGradings`]
                                            }
                                        />
                                    </div>
                                ) : (
                                    <ImpactGradingCard
                                        impactId="impactList"
                                        invalid={Boolean(errors.impactList)}
                                        required={false}
                                        impactList={item?.impactList ?? null}
                                        impactAreas={impactAreas ?? []}
                                        disabled={isDisabled}
                                        onChange={
                                            handleChangeImpactGradingValue
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Accordion>

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.COMMENTS)}
                        open={!Boolean(riskFor)}
                    >
                        <Textfield
                            id="Comments"
                            name="comments"
                            value={item?.comments ?? ""}
                            invalid={Boolean(errors.Comments)}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            labelContent={t(k.COMMENTS)}
                            disabled={isDisabled}
                            onChange={handleChange}
                            helperText={
                                <Textfield.Error>
                                    {errors.Comments}
                                </Textfield.Error>
                            }
                            multiline={true}
                            minRows={3}
                        />
                    </Accordion>

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.RISK_SCORE)}
                        open={!Boolean(riskFor)}
                    >
                        <label
                            htmlFor="form.OccurenceId"
                            className="text-field"
                        >
                            <div className="text-field--label">
                                {t(k.OCCURENCE)}
                            </div>
                            <SelectDropdown
                                id={"OccurenceName"}
                                inputId="form.OccurenceId"
                                name="occurenceId"
                                value={
                                    item?.occurenceId
                                        ? occurenceOptions?.values[
                                              item.occurenceId
                                          ]
                                        : undefined
                                }
                                invalid={Boolean(errors.OccurenceName)}
                                isClearable
                                isSearchable
                                bold
                                placeholder={t(k.SELECT)}
                                options={occurenceOptions?.options}
                                isDisabled={isDisabled}
                                onChange={handleSelectSingle}
                            />

                            {errors && (
                                <ValidationLabel
                                    errors={errors.OccurenceName}
                                />
                            )}
                        </label>
                        <label
                            htmlFor="form.ProbabilityId"
                            className="text-field"
                        >
                            <div className="text-field--label">
                                {t(k.PROBABILITY)}
                            </div>
                            <SelectDropdown
                                id={"ProbabilityName"}
                                inputId="form.ProbabilityId"
                                name="probabilityId"
                                value={
                                    item?.probabilityId
                                        ? probabilityOptions?.values[
                                              item.probabilityId
                                          ]
                                        : undefined
                                }
                                invalid={Boolean(errors.ProbabilityName)}
                                isClearable
                                isSearchable
                                bold
                                placeholder={t(k.SELECT)}
                                options={probabilityOptions?.options}
                                isDisabled={isDisabled}
                                onChange={handleSelectSingle}
                            />

                            {errors && (
                                <ValidationLabel
                                    errors={errors.ProbabilityName}
                                />
                            )}
                        </label>

                        <Textfield
                            id="CommentsProbability"
                            name="commentsProbability"
                            value={item?.commentsProbability ?? ""}
                            invalid={Boolean(errors.CommentsProbability)}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            labelContent={t(k.COMMENTS_PROBABILITY)}
                            disabled={isDisabled}
                            onChange={handleChange}
                            helperText={
                                <Textfield.Error>
                                    {errors.CommentsProbability}
                                </Textfield.Error>
                            }
                            multiline={true}
                            minRows={3}
                        />

                        <Textfield
                            id="RiskScore"
                            name="riskScore"
                            value={String(item?.riskScore ?? "")}
                            invalid={Boolean(errors.RiskScore)}
                            placeholder={
                                (item?.riskScore ?? "") === ""
                                    ? t(k.MISSING_DATA_TO_CALCULATE)
                                    : t(k.RISK_SCORE)
                            }
                            size="large"
                            labelContent={t(k.RISK_SCORE)}
                            readOnly
                            wrapperClassName={
                                (item?.riskScore ?? "") === ""
                                    ? "risk-score-missing"
                                    : (item?.riskScore ?? 0) >= 15
                                      ? "risk-score-xl"
                                      : (item?.riskScore ?? 0) >= 7
                                        ? "risk-score-md"
                                        : "risk-score-sm"
                            }
                            helperText={
                                <Textfield.Error>
                                    {errors.RiskScore}
                                </Textfield.Error>
                            }
                        />
                    </Accordion>

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.RISK_REDUCTION)}
                        open={!Boolean(riskFor)}
                    >
                        <Textfield
                            id="RiskReduction"
                            name="riskReduction"
                            value={item?.riskReduction ?? ""}
                            invalid={Boolean(errors.RiskReduction)}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            isBusy={
                                gptLoading ===
                                    CognitGPTPromptType.ORA_RiskReduction ||
                                gptLoading ===
                                    CognitGPTPromptType.ORA_RiskReduction_RewriteExisting
                            }
                            labelContent={t(k.RISK_REDUCTION)}
                            disabled={isDisabled}
                            onChange={handleChange}
                            helperText={
                                <Textfield.Error>
                                    {errors.RiskReduction}
                                </Textfield.Error>
                            }
                            postInputContent={
                                <CognitGPTButton
                                    canUseAI={canUseAI}
                                    promptType={
                                        CognitGPTPromptType.ORA_RiskReduction
                                    }
                                    additionalInfo={{
                                        ...(item?.riskScenario && {
                                            [CognitGPTAdditionalInfoKey.RiskScenario]:
                                                item.riskScenario,
                                        }),
                                        ...(item?.potentialConsequences && {
                                            [CognitGPTAdditionalInfoKey.RiskConsequences]:
                                                item.potentialConsequences,
                                        }),
                                        ...(item?.riskReduction && {
                                            [CognitGPTAdditionalInfoKey.RiskReduction]:
                                                item.riskReduction,
                                        }),
                                    }}
                                    text={item?.riskReduction}
                                    rephrasePromptType={
                                        CognitGPTPromptType.ORA_RiskReduction_RewriteExisting
                                    }
                                    gptLoading={gptLoading}
                                    isDisabled={isDisabled}
                                    gptErrors={gptErrors}
                                    onGenerateText={onGenerateText}
                                    onCancelStreamingMessage={cancelStream}
                                />
                            }
                            multiline={true}
                            minRows={5}
                        />
                        <label
                            htmlFor="form.ResponsibleId"
                            className="text-field"
                        >
                            <div className="text-field--label">
                                {t(k.RESPONSIBLE)}
                            </div>
                            <SelectDropdown
                                id={"ResponsibleName"}
                                inputId="form.ResponsibleId"
                                name="responsibleId"
                                value={
                                    item?.responsibleId
                                        ? userOptions?.values[
                                              item.responsibleId
                                          ]
                                        : undefined
                                }
                                invalid={Boolean(errors.ResponsibleName)}
                                isClearable
                                isSearchable
                                bold
                                placeholder={t(k.SELECT)}
                                options={userOptions?.options}
                                isDisabled={isDisabled}
                                onChange={handleSelectSingle}
                            />

                            {errors && (
                                <ValidationLabel
                                    errors={errors.ResponsibleName}
                                />
                            )}
                        </label>

                        <DatePicker
                            id="Date"
                            name="date"
                            value={item?.date}
                            invalid={Boolean(errors.Date)}
                            error={errors.Date}
                            placeholder={t(k.SELECT)}
                            size="large"
                            labelContent={t(k.DATE)}
                            disabled={isDisabled}
                            onChange={handleChangeDate}
                        />

                        <Textfield
                            id="EstimatedProbabilityReduction"
                            name="estimatedProbabilityReduction"
                            value={String(
                                item?.estimatedProbabilityReduction ?? "",
                            )}
                            invalid={Boolean(
                                errors.EstimatedProbabilityReduction,
                            )}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            labelContent={t(k.ESTIMATED_PROBABILITY_REDUCTION)}
                            postInputContent="%"
                            disabled={isDisabled}
                            onChange={handleChangeNumber}
                            type="number"
                            helperText={
                                <Textfield.Error>
                                    {errors.EstimatedProbabilityReduction}
                                </Textfield.Error>
                            }
                        />

                        <Textfield
                            id="UpdatedRiskScore"
                            name="updatedRiskScore"
                            value={String(item?.updatedRiskScore ?? "")}
                            invalid={Boolean(errors.UpdatedRiskScore)}
                            placeholder={
                                (item?.updatedRiskScore ?? "") === ""
                                    ? t(k.MISSING_DATA_TO_CALCULATE)
                                    : t(k.UPDATED_RISK_SCORE)
                            }
                            size="large"
                            labelContent={t(k.UPDATED_RISK_SCORE)}
                            readOnly
                            wrapperClassName={
                                (item?.updatedRiskScore ?? "") === ""
                                    ? "risk-score-missing"
                                    : (item?.updatedRiskScore ?? 0) >= 15
                                      ? "risk-score-xl"
                                      : (item?.updatedRiskScore ?? 0) >= 7
                                        ? "risk-score-md"
                                        : "risk-score-sm"
                            }
                            helperText={
                                <Textfield.Error>
                                    {errors.UpdatedRiskScore}
                                </Textfield.Error>
                            }
                        />
                    </Accordion>

                    <Accordion
                        className="ora-form--accordion"
                        header={t(k.STATUS)}
                        open={!Boolean(riskFor)}
                    >
                        <label htmlFor="form.StatusId" className="text-field">
                            <div className="text-field--label">
                                {t(k.STATUS)}
                            </div>
                            <SelectDropdown
                                id={"StatusName"}
                                inputId="form.StatusId"
                                name="statusId"
                                value={
                                    item?.statusId
                                        ? riskStatusOptions?.values[
                                              item.statusId
                                          ]
                                        : undefined
                                }
                                invalid={Boolean(errors.StatusName)}
                                isClearable
                                isSearchable
                                bold
                                placeholder={t(k.SELECT)}
                                options={riskStatusOptions?.options}
                                isDisabled={isDisabled}
                                onChange={handleSelectSingle}
                            />

                            {errors && (
                                <ValidationLabel errors={errors.StatusName} />
                            )}
                        </label>
                    </Accordion>
                    {hasProcessChartFeatureEnabled && (
                        <Accordion
                            className="ora-form--accordion"
                            header={t(k.HIGHLIGHTED_RISK)}
                            open={!Boolean(riskFor)}
                        >
                            <label
                                htmlFor="form.IsHighlighted"
                                className="text-field"
                            >
                                <div className="text-field--label">
                                    {t(k.SHOW_IN_PROCESS_CHART)}
                                </div>

                                <SelectButton
                                    id="form.IsHighlighted"
                                    buttons={highlightedButtonProps}
                                    selected={
                                        item?.isHighlighted ? ["1"] : ["0"]
                                    }
                                    disabled={isDisabled}
                                    onSelect={handleIsHighlighted}
                                />

                                {errors && (
                                    <ValidationLabel
                                        errors={errors.IsHighlighted}
                                    />
                                )}
                            </label>
                        </Accordion>
                    )}
                </div>
            </ModalContent.Body>

            <ModalContent.Footer>
                <ButtonGroup className="operational-risk-analysis-form--footer">
                    <Button
                        variant="danger"
                        transparent
                        onClick={handleClose}
                        disabled={isSaving}
                    >
                        {t(k.CANCEL)}
                    </Button>

                    {canEdit &&
                        (createNew ? (
                            <Button
                                variant="success"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {t(k.SAVE)}
                            </Button>
                        ) : item?.isArchived ? (
                            <>
                                <Button
                                    variant="warning"
                                    onClick={handleUnarchive}
                                    disabled={isSaving}
                                >
                                    {t(k.UN_ARCHIVE)}
                                </Button>

                                {canDelete && (
                                    <Button
                                        variant="danger"
                                        onClick={handleDelete}
                                        disabled={isSaving}
                                    >
                                        {t(k.DELETE)}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="gray"
                                    transparent
                                    onClick={handleArchive}
                                    disabled={isSaving}
                                >
                                    {t(k.ARCHIVE)}
                                </Button>

                                <Button
                                    variant="success"
                                    onClick={handleSave}
                                    disabled={isSaving}
                                >
                                    {t(k.SAVE)}
                                </Button>
                            </>
                        ))}
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default RiskForm;
