import { FC } from "react";
import { HistoryButton, CloseButton, DeleteButton } from "./components";

import ListedContent from "common/components/listed-content/ListedContent";

interface ISidePanelHeaderInputProps {}
const SidePanelHeader: FC<ISidePanelHeaderInputProps> = () => {
    return (
        <ListedContent.SidePanel.Header>
            <CloseButton />
            <div className="side-panel-header__buttons">
                <HistoryButton />
                <DeleteButton />
            </div>
        </ListedContent.SidePanel.Header>
    );
};

export default SidePanelHeader;
