import SelectImprovementTemplate from "components/improvements/components/SelectImprovementTemplate";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
    ModalTypes,
    IModalListItem,
    IShowModalPayload,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const SelectImprovementFormModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, options, modalFor },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const location = useLocation();

    const handleOnClose = () => {
        modalManagerContext.onCloseModal({ callId });
    };

    const handleOnSelect = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.improvement,
            callerAddress: location.pathname,
            options: {
                ...(options?.sourceCustomListItemId && {
                    sourceCustomListItemId: options.sourceCustomListItemId,
                }),
                ...(options?.impFilter && { impFilter: options.impFilter }),
                createNew: true,
            },
            modalFor: modalFor,
        };

        modalManagerContext.onShowModal(payload);
    };

    return (
        <SelectImprovementTemplate
            customListItemId={options?.sourceCustomListItemId}
            onClose={handleOnClose}
            onSelect={handleOnSelect}
        />
    );
};

export default SelectImprovementFormModal;
