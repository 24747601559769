import "./SeniorManagementIcon.scss";

import React from "react";
import Icon from "./assets/senior-management-icon.svg";

const SeniorManagementIcon: React.FC = (props) => {
    return <Icon className="senior-management-icon" />;
};

export default SeniorManagementIcon;
