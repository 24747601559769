import "./GoalStatusBadgeRow.scss";

import k from "i18n/keys";

import React from "react";
import { DraftIcon } from "common/components/icons";
import { useTranslation } from "react-i18next";
import { EGoalGrading } from "components/goals-page/api/EGoalGrading";

interface IProps {
    status: EGoalGrading;
    disabled?: boolean;
    hideDraft?: boolean;
    onClick?: (status: EGoalGrading) => void;
}

const GoalStatusBadgeRow: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status, disabled, hideDraft } = props;

    const { t } = useTranslation();

    return (
        <div className="goal-status-badge-row">
            <div
                className={`status-button on-track${
                    status === EGoalGrading.OnTrack ? " active" : ""
                }${disabled ? " disabled" : ""}`}
                onClick={() =>
                    !disabled && props.onClick?.(EGoalGrading.OnTrack)
                }
            >
                {t(k.ON_TRACK)}
            </div>
            <div
                className={`status-button at-risk${
                    status === EGoalGrading.AtRisk ? " active" : ""
                }${disabled ? " disabled" : ""}`}
                onClick={() =>
                    !disabled && props.onClick?.(EGoalGrading.AtRisk)
                }
            >
                {t(k.AT_RISK)}
            </div>
            <div
                className={`status-button blocked${
                    status === EGoalGrading.Blocked ? " active" : ""
                }${disabled ? " disabled" : ""}`}
                onClick={() =>
                    !disabled && props.onClick?.(EGoalGrading.Blocked)
                }
            >
                {t(k.BLOCKED)}
            </div>
            <div
                className={`status-button completed${
                    status === EGoalGrading.Completed ? " active" : ""
                }${disabled ? " disabled" : ""}`}
                onClick={() =>
                    !disabled && props.onClick?.(EGoalGrading.Completed)
                }
            >
                {t(k.COMPLETED)}
            </div>
            {!hideDraft && (
                <div
                    className={`status-button draft${
                        status === EGoalGrading.Draft ? " active" : ""
                    }${disabled ? " disabled" : ""}`}
                    onClick={() =>
                        !disabled && props.onClick?.(EGoalGrading.Draft)
                    }
                >
                    <DraftIcon />
                    {t(k.DRAFT)}
                </div>
            )}
        </div>
    );
};

export default GoalStatusBadgeRow;
