import { useState, useCallback, useRef, useEffect } from "react";
import { tryParseJSON } from "utils/json";

export const defaultMinColumnWidth = 125;

export const defaultMinColumnWidthVertical = 50;

export type ReactMouseOrTouchEvent =
    | React.MouseEvent<HTMLElement>
    | React.TouchEvent<HTMLElement>;
type MouseOrTouchEvent = MouseEvent | TouchEvent;
interface IColumnWidths {
    [key: number | string]: { width: number; minWidth?: number };
}

type ColumnWidths = {
    resizeVersion?: number;
    widths: IColumnWidths;
};

interface ColumnRefs {
    [key: number | string]: HTMLTableCellElement;
}

interface ResizingState {
    isResizing: boolean;
    currentColumnKey: number | string | null;
    initialX: number;
    initialWidth: number;
}

const currentResizeVersion = 2;

const useResizableColumns = (
    enabled: boolean,
    tableUniqueName?: string,
    baseMinColumnWidth = defaultMinColumnWidth,
) => {
    let localStoredWidths: IColumnWidths | undefined = undefined;

    if (!!tableUniqueName) {
        const storedWidths = localStorage.getItem(tableUniqueName);
        const parsedStoredWidths = storedWidths
            ? tryParseJSON<ColumnWidths>(storedWidths)
            : null;

        localStoredWidths =
            parsedStoredWidths?.resizeVersion === currentResizeVersion
                ? parsedStoredWidths.widths
                : {};
    }

    const [columnWidths, setColumnWidths] = useState<IColumnWidths>({});

    const cellRefs = useRef<ColumnRefs>({});
    const resizingState = useRef<ResizingState>({
        isResizing: false,
        currentColumnKey: null,
        initialX: 0,
        initialWidth: 0,
    });

    const registerCellRef = useCallback(
        (
            cell: HTMLTableCellElement,
            key: number | string,
            minWidth?: number,
        ) => {
            if (enabled) {
                const localStoredWidth = localStoredWidths?.[key]?.width;

                cellRefs.current[key] = cell;

                const computedStyle = window.getComputedStyle(cell);
                const effectiveMinWidth = parseFloat(computedStyle.minWidth);

                setColumnWidths((prev) => {
                    const prevWidth = prev[key];

                    if (prevWidth) {
                        return {
                            ...prev,
                            [key]: {
                                ...prevWidth,
                                width: Math.max(
                                    cell.offsetWidth,
                                    prevWidth.minWidth ?? 0,
                                ),
                            },
                        };
                    }
                    const _minWidth =
                        Math.max(minWidth ?? 0, effectiveMinWidth ?? 0) ||
                        baseMinColumnWidth;

                    return {
                        ...prev,
                        [key]: {
                            width: Math.max(
                                localStoredWidth ?? cell.offsetWidth,
                                _minWidth,
                            ),
                            minWidth: _minWidth,
                        },
                    };
                });
            }
        },
        [enabled],
    );

    const handleMove = useCallback(
        (event: MouseOrTouchEvent) => {
            if (enabled) {
                const { isResizing, currentColumnKey, initialX, initialWidth } =
                    resizingState.current;

                if (!isResizing || currentColumnKey === null) return;

                const isTouch = "touches" in event;

                const clientX = isTouch
                    ? event.touches[0].clientX
                    : event.clientX;

                const deltaX = clientX - initialX;
                const newWidth = initialWidth + deltaX;

                setColumnWidths((prev) => {
                    const minWidth =
                        prev[currentColumnKey]?.minWidth ?? baseMinColumnWidth;

                    if (newWidth >= minWidth) {
                        return {
                            ...prev,
                            [currentColumnKey]: {
                                ...prev?.[currentColumnKey],
                                width: newWidth,
                            },
                        };
                    }

                    return prev;
                });
            }
        },
        [enabled],
    );

    const stopResizing = useCallback(() => {
        if (enabled && resizingState.current.isResizing) {
            const cellIndex = resizingState.current.currentColumnKey;
            const cell =
                cellIndex !== null ? cellRefs.current[cellIndex] : null;

            if (!!cell && cellIndex !== null) {
                registerCellRef(cell, cellIndex);
            }

            resizingState.current.isResizing = false;

            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mouseup", stopResizing);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("touchend", stopResizing);
        }
    }, [enabled, handleMove]);

    const startResizing = useCallback(
        (event: ReactMouseOrTouchEvent, columnKey: number | string) => {
            if (enabled) {
                const isTouch = "touches" in event;

                event.preventDefault();

                resizingState.current = {
                    isResizing: true,
                    currentColumnKey: columnKey,
                    initialX: isTouch
                        ? event.touches[0].clientX
                        : event.clientX,
                    initialWidth:
                        cellRefs.current?.[columnKey].clientWidth ?? 0,
                };

                if (isTouch) {
                    window.addEventListener("touchmove", handleMove);
                    window.addEventListener("touchend", stopResizing, {
                        passive: false,
                    });
                } else {
                    window.addEventListener("mousemove", handleMove);
                    window.addEventListener("mouseup", stopResizing);
                }
            }
        },
        [enabled, handleMove, stopResizing],
    );

    useEffect(() => {
        if (enabled && !!tableUniqueName && !!columnWidths) {
            const onlyWidths = Object.keys(columnWidths).reduce((acc, key) => {
                acc[key] = { width: columnWidths[key].width };
                return acc;
            }, {} as IColumnWidths);

            const widthsAndVersion = {
                resizeVersion: currentResizeVersion,
                widths: onlyWidths,
            };

            const widthsAsString = JSON.stringify(widthsAndVersion);
            localStorage.setItem(tableUniqueName, widthsAsString);
        }
    }, [columnWidths, enabled, tableUniqueName]);

    useEffect(() => {
        if (!enabled) {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mouseup", stopResizing);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("touchend", stopResizing);
        }

        return () => {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mouseup", stopResizing);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("touchend", stopResizing);
        };
    }, [enabled]);

    return {
        columnWidths,
        startResizing,
        stopResizing,
        registerCellRef,
    };
};

export default useResizableColumns;
