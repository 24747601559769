import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import i18n from "i18next";

import k from "i18n/keys";

import Page404 from "./components/Page404";
import PrivateContent from "./components/PrivateContent";
import { lazyMinLoadTime } from "common/utils/lazyMinLoadTime";
import {
    administratorOrMapperRoles,
    administratorRoles,
    checklistReportRoles,
    editEquipmentRoles,
    editPurchaseOrdersRoles,
    editSuppliersRoles,
    improvementDashboardRoles,
    improvementFormRoles,
    viewCMRoles,
    viewCompetenciesRoles,
    viewCustomerRequirementsRoles,
    viewEnvironmentAspectsRoles,
    viewEquipmentRoles,
    viewGoalsRoles,
    viewImpactGradingsRoles,
    viewItemsRoles,
    viewLegislationsRoles,
    viewORARoles,
    viewPositionRoles,
    viewPurchaseOrdersRoles,
    viewSARoles,
    viewStakeholderRoles,
    viewSuppliersRoles,
    viewTeamsRoles,
    viewTrainingPlansRoles,
    viewUserRoles,
} from "components/roles/api/AccessRoles";
import { TRoles } from "components/roles/api/Roles";
import { IAvailableAppFeatureProperties } from "models/dto/IProfileDTO";
import LazyLoadingFallback from "routes/components/LazyLoadingFallback";
import { ELayoutEnvironment } from "store/uiSettings/reducer";

const ImprovementFormsPage = lazyMinLoadTime(
    () => import("components/improvement-forms/ImprovementFormsPage"),
);

const Login = lazyMinLoadTime(() => import("components/auth/Login"));

const Register = lazyMinLoadTime(() => import("components/auth/Register"));

const FirstLoginAfterRegistration = lazyMinLoadTime(
    () => import("components/auth/FirstLoginAfterRegistration"),
);

const CompetencyPage = lazyMinLoadTime(
    () => import("components/competency-page"),
);

const ApiKeys = lazyMinLoadTime(() => import("components/api-keys"));

const ImprovementsPage = lazyMinLoadTime(
    () => import("components/improvements/ImprovementsPage"),
);

const Equipment = lazyMinLoadTime(() => import("components/equipment"));
const AddEquipment = lazyMinLoadTime(
    () => import("components/equipment/AddEquipment"),
);
const EditEquipment = lazyMinLoadTime(
    () => import("components/equipment/EditEquipment"),
);
const ViewEquipment = lazyMinLoadTime(
    () => import("components/equipment/ViewEquipment"),
);

const SharedSteps = lazyMinLoadTime(
    () => import("components/steps/SharedSteps"),
);
const AddStep = lazyMinLoadTime(() => import("components/steps/AddStep"));
const EditStep = lazyMinLoadTime(() => import("components/steps/EditStep"));
const ViewStep = lazyMinLoadTime(() => import("components/steps/ViewStep"));

const Checklists = lazyMinLoadTime(
    () => import("components/checklists/Checklists"),
);

const Workflows = lazyMinLoadTime(
    () => import("components/templates/Workflows"),
);

const TasksPage = lazyMinLoadTime(
    () => import("components/tasks-page/TasksPage"),
);

const Goals = lazyMinLoadTime(() => import("components/goals-page"));

const ViewTeam = lazyMinLoadTime(() => import("components/teams/ViewTeam"));

const Team = lazyMinLoadTime(() => import("components/teams"));

const TemplateChecklistReport = lazyMinLoadTime(
    () => import("components/template-checklist-report"),
);

const Items = lazyMinLoadTime(() => import("components/items"));

const ItemTypes = lazyMinLoadTime(() => import("components/item-types"));

const PurchaseOrderLineStatus = lazyMinLoadTime(
    () => import("components/purchase-order-line-status"),
);

const Customers = lazyMinLoadTime(() => import("components/customer-page"));

const ViewCustomer = lazyMinLoadTime(
    () => import("components/customer-page/ViewCustomer"),
);

const Suppliers = lazyMinLoadTime(() => import("components/suppliers"));
const AddSupplier = lazyMinLoadTime(
    () => import("components/suppliers/AddSupplier"),
);
const EditSupplier = lazyMinLoadTime(
    () => import("components/suppliers/EditSupplier"),
);
const ViewSupplier = lazyMinLoadTime(
    () => import("components/suppliers/ViewSupplier"),
);

const ProfilePage = lazyMinLoadTime(() => import("components/profile-page"));

const Users = lazyMinLoadTime(() => import("components/users"));

const PurchaseOrders = lazyMinLoadTime(
    () => import("components/purchase-order"),
);

const AddPurchaseOrder = lazyMinLoadTime(
    () => import("components/purchase-order/AddPurchaseOrder"),
);

const EditPurchaseOrder = lazyMinLoadTime(
    () => import("components/purchase-order/EditPurchaseOrder"),
);

const ViewPurchaseOrder = lazyMinLoadTime(
    () => import("components/purchase-order/ViewPurchaseOrder"),
);

const TeamsApp = lazyMinLoadTime(() => import("components/teams-app"));

const ProcessOverviewPage = lazyMinLoadTime(
    () => import("components/process-chart/process-overview"),
);

const ProcessPageWrapper = lazyMinLoadTime(
    () => import("components/process-chart/process"),
);

const SubProcessPageWrapper = lazyMinLoadTime(
    () => import("components/process-chart/subprocess"),
);

const ChecklistReportSearch = lazyMinLoadTime(
    () => import("components/template-checklist-report-search"),
);

const StakeholderPage = lazyMinLoadTime(
    () => import("components/stakeholder-page"),
);

const LegislationPage = lazyMinLoadTime(
    () => import("components/legislation-page"),
);

const EnvironmentalAspectPage = lazyMinLoadTime(
    () => import("components/environmental-aspect-page"),
);

const CustomerRequirementPage = lazyMinLoadTime(
    () => import("components/customer-requirement-page"),
);

const CompetencyMatrixPage = lazyMinLoadTime(
    () => import("components/competency-matrix-page"),
);

const CustomerIndustryPage = lazyMinLoadTime(
    () => import("components/customer-industry-page"),
);

const TrainingPlanPage = lazyMinLoadTime(
    () => import("components/training-plan-page"),
);

const StaffAppraisalPage = lazyMinLoadTime(
    () => import("components/staff-appraisal-page"),
);

const ImpactGradingPage = lazyMinLoadTime(
    () => import("components/impact-grading-page"),
);

const OperationalRiskAnalysisPage = lazyMinLoadTime(
    () => import("components/operational-risk-analysis-page"),
);

const CustomListItemPage = lazyMinLoadTime(
    () => import("components/custom-list-item-page"),
);

const ImprovementDashboardPage = lazyMinLoadTime(
    () => import("components/improvement-dashboard"),
);

const WebhookPage = lazyMinLoadTime(() => import("components/webhook-page"));

const WebhookEventPage = lazyMinLoadTime(
    () => import("components/webhook-event-page"),
);

const Positions = lazyMinLoadTime(() => import("components/positions"));

const KpiPage = lazyMinLoadTime(() => import("components/kpi-page"));

const TenantSettingsPage = lazyMinLoadTime(
    () => import("components/tenant-settings-page/TenantSettingsPage"),
);

const PagesPage = lazyMinLoadTime(() => import("components/pages"));

const APage = lazyMinLoadTime(() => import("components/pages/Page"));

interface IRolesProps {
    roles: TRoles[];
}

interface IProps {
    appFeatures?: IAvailableAppFeatureProperties;
    layoutEnvironment: ELayoutEnvironment;
    canAccessKpi?: boolean;
}

export const AppRoutes: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <React.Suspense fallback={<LazyLoadingFallback />}>
        <Routes>
            <Route
                path="/workflows/*"
                element={<Navigate to="/templates/*" />}
            />

            <Route path="/workflows" element={<Navigate to="/templates" />} />

            <Route path="/teamsapp/*" element={<Navigate to="/*" />} />

            <Route path="/teamsapp" element={<Navigate to="/" />} />

            {props.layoutEnvironment === ELayoutEnvironment.TEAMS ? (
                <Route
                    path="/"
                    element={
                        <PrivateContent
                            component={TeamsApp}
                            title={i18n.t(k.DASHBOARD)}
                        />
                    }
                />
            ) : (
                <Route
                    path="/"
                    element={
                        <PrivateContent
                            component={Checklists}
                            title={i18n.t(k.NAV_NODE_CHECKLISTS)}
                        />
                    }
                />
            )}

            <Route path="/login" element={<Login />} />

            <Route path="/register" element={<Register />} />

            <Route
                path="/first-login/:userId"
                element={<FirstLoginAfterRegistration />}
            />

            {props.appFeatures?.improvementDashboard && (
                <Route
                    path="/improvement-dashboard"
                    element={
                        <PrivateContent
                            component={ImprovementDashboardPage}
                            roles={improvementDashboardRoles}
                            title={i18n.t(k.NAV_NODE_IMPROVEMENT_DASHBOARD)}
                        />
                    }
                />
            )}

            {props.appFeatures?.processChart && (
                <Route
                    path="/processchart"
                    element={
                        <PrivateContent
                            component={ProcessOverviewPage}
                            title="Process Chart"
                        />
                    }
                />
            )}

            {props.appFeatures?.processChart && (
                <Route
                    path="/process/:id"
                    element={
                        <PrivateContent
                            component={ProcessPageWrapper}
                            title="Process"
                        />
                    }
                />
            )}

            {props.appFeatures?.processChart && (
                <Route
                    path="/subprocess/:id"
                    element={
                        <PrivateContent
                            component={SubProcessPageWrapper}
                            title="Process Chart"
                        />
                    }
                />
            )}

            {props.appFeatures?.sharedSteps && (
                <Route path="/steps/*" element={<StepRoutes />} />
            )}

            <Route
                path="/checklists"
                element={
                    <PrivateContent
                        component={Checklists}
                        title={i18n.t(k.NAV_NODE_CHECKLISTS)}
                    />
                }
            />

            <Route
                path="/improvement-forms/*"
                element={
                    <ImprovementFormsRoutes roles={improvementFormRoles} />
                }
            />

            {props.appFeatures?.equipment && (
                <Route
                    path="/equipment/*"
                    element={
                        <EquipmentRoutes
                            viewRoles={viewEquipmentRoles}
                            editRoles={editEquipmentRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.teams && (
                <Route
                    path="/teams/*"
                    element={<TeamRoutes roles={viewTeamsRoles} />}
                />
            )}

            {props.appFeatures?.tasks && (
                <Route
                    path="/tasks"
                    element={
                        <PrivateContent
                            component={TasksPage}
                            title={i18n.t(k.NAV_NODE_TASKS)}
                        />
                    }
                />
            )}

            {props.appFeatures?.goals && (
                <Route
                    path="/goals"
                    element={
                        <PrivateContent
                            component={Goals}
                            roles={viewGoalsRoles}
                            title={i18n.t(k.NAV_NODE_GOALS)}
                        />
                    }
                />
            )}

            <Route
                path="/templates/*"
                element={<TemplateRoutes roles={administratorOrMapperRoles} />}
            />

            <Route path="/improvements/*" element={<ImprovementsRoutes />} />

            {props.appFeatures?.checklistReport && (
                <Route
                    path="/checklists-report/*"
                    element={
                        <TemplateChecklistsReportRoutes
                            roles={checklistReportRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.checklistReport && (
                <Route
                    path="/checklists-search"
                    element={
                        <PrivateContent
                            component={ChecklistReportSearch}
                            title={i18n.t(k.NAV_NODE_CHECKLISTS_SEARCH)}
                            roles={checklistReportRoles}
                        />
                    }
                />
            )}

            <Route
                path="/users"
                element={
                    <PrivateContent
                        component={Users}
                        roles={viewUserRoles}
                        title={i18n.t(k.NAV_NODE_USERS)}
                    />
                }
            />

            {props.appFeatures?.itemCatalog && (
                <Route
                    path="/items"
                    element={
                        <PrivateContent
                            component={Items}
                            title={i18n.t(k.NAV_NODE_ITEMS)}
                            roles={viewItemsRoles}
                        />
                    }
                />
            )}

            {((props.appFeatures?.kpi && props.canAccessKpi) ||
                props.appFeatures?.goals ||
                props.appFeatures?.itemCatalog) && (
                <Route
                    path="/item-types"
                    element={
                        <PrivateContent
                            component={ItemTypes}
                            title={i18n.t(k.NAV_NODE_MANAGE_TYPE_AND_UNIT)}
                            roles={administratorRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.purchaseOrders && (
                <Route
                    path="/purchase-order-line-status"
                    element={
                        <PrivateContent
                            component={PurchaseOrderLineStatus}
                            roles={administratorRoles}
                            title={i18n.t(
                                k.NAV_NODE_MANAGE_PURCHASE_ORDER_LINE_STATUS,
                            )}
                        />
                    }
                />
            )}

            {props.appFeatures?.purchaseOrders && (
                <Route
                    path="/purchase-orders/*"
                    element={
                        <PurchaseOrderRoutes
                            viewRoles={viewPurchaseOrdersRoles}
                            editRoles={editPurchaseOrdersRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.customers && (
                <Route path="/customers/*" element={<CustomerRoutes />} />
            )}

            {props.appFeatures?.suppliers && (
                <Route
                    path="/suppliers/*"
                    element={
                        <SupplierRoutes
                            viewRoles={viewSuppliersRoles}
                            editRoles={editSuppliersRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.stakeholders && (
                <Route
                    path="/stakeholders"
                    element={
                        <PrivateContent
                            component={StakeholderPage}
                            title={i18n.t(k.NAV_NODE_STAKEHOLDERS)}
                            roles={viewStakeholderRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.legislations && (
                <Route
                    path="/legislations"
                    element={
                        <PrivateContent
                            component={LegislationPage}
                            title={i18n.t(k.LEGISLATION_PAGE_TITLE)}
                            roles={viewLegislationsRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.environmentalAspects && (
                <Route
                    path="/environmental-aspects"
                    element={
                        <PrivateContent
                            component={EnvironmentalAspectPage}
                            title={i18n.t(k.ENVIRONMENTAL_ASPECTS_PAGE_TITLE)}
                            roles={viewEnvironmentAspectsRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.customerRequirements && (
                <Route
                    path="/customer-requirements"
                    element={
                        <PrivateContent
                            component={CustomerRequirementPage}
                            title={i18n.t(k.CUSTOMER_REQUIREMENT_PAGE_TITLE)}
                            roles={viewCustomerRequirementsRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.competencyMatrixes && (
                <Route
                    path="/competency-matrix"
                    element={
                        <PrivateContent
                            component={CompetencyMatrixPage}
                            roles={viewCMRoles}
                            title={i18n.t(k.COMPETENCY_MATRIX)}
                        />
                    }
                />
            )}

            {props.appFeatures?.trainingPlans && (
                <Route
                    path="/training-plans"
                    element={
                        <PrivateContent
                            component={TrainingPlanPage}
                            title={i18n.t(k.TRAINING_PLAN_PAGE_TITLE)}
                            roles={viewTrainingPlansRoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.staffAppraisals && (
                <Route
                    path="/staff-appraisals/:id?"
                    element={
                        <PrivateContent
                            component={StaffAppraisalPage}
                            roles={viewSARoles}
                            title={i18n.t(k.STAFF_APPRAISAL_PAGE_TITLE)}
                        />
                    }
                />
            )}

            {props.appFeatures?.impactGradings && (
                <>
                    <Route
                        path="/severity-gradings"
                        element={<Navigate to="/impact-gradings" />}
                    />
                    <Route
                        path="/impact-gradings"
                        element={
                            <PrivateContent
                                component={ImpactGradingPage}
                                roles={viewImpactGradingsRoles}
                                title={i18n.t(k.IMPACT_GRADING_PAGE_TITLE)}
                            />
                        }
                    />
                </>
            )}

            {props.appFeatures?.operationalRiskAnalysis && (
                <Route
                    path="/operational-risk-analysis"
                    element={
                        <PrivateContent
                            component={OperationalRiskAnalysisPage}
                            title={i18n.t(
                                k.OPERATIONAL_RISK_ANALYSIS_PAGE_TITLE,
                            )}
                            roles={viewORARoles}
                        />
                    }
                />
            )}

            {props.appFeatures?.competencies && (
                <Route
                    path="/competencies"
                    element={
                        <PrivateContent
                            component={CompetencyPage}
                            roles={viewCompetenciesRoles}
                            title={i18n.t(k.COMPETENCY_PAGE_TITLE)}
                        />
                    }
                />
            )}

            {props.appFeatures?.customLists && (
                <Route
                    path="/custom-list-item/:path"
                    element={
                        <PrivateContent
                            component={CustomListItemPage}
                            title={i18n.t(k.CUSTOM_LIST_PAGE_TITLE)}
                        />
                    }
                />
            )}

            <Route
                path="/positions"
                element={
                    <PrivateContent
                        component={Positions}
                        roles={viewPositionRoles}
                        title={i18n.t(k.POSITIONS_PAGE_TITLE)}
                    />
                }
            />
            {props.appFeatures?.kpi && props.canAccessKpi && (
                <Route path="/kpi/*" element={<KpiRoutes />} />
            )}

            <Route
                path="/profile"
                element={
                    <PrivateContent
                        component={ProfilePage}
                        title={i18n.t(k.PROFILE)}
                    />
                }
            />

            <Route
                path="/customer-industries"
                element={
                    <PrivateContent
                        component={CustomerIndustryPage}
                        roles={administratorRoles}
                        title={i18n.t(k.CUSTOMER_INDUSTRY_PAGE_TITLE)}
                    />
                }
            />

            <Route
                path="/api-keys"
                element={
                    <PrivateContent
                        component={ApiKeys}
                        roles={administratorRoles}
                        title={i18n.t(k.API_KEYS)}
                    />
                }
            />

            {props.appFeatures?.webhooks && (
                <React.Fragment>
                    <Route path="/webhooks/*" element={<WebhookRoutes />} />

                    <Route
                        path="/webhook-events"
                        element={
                            <PrivateContent
                                component={WebhookEventPage}
                                roles={administratorOrMapperRoles}
                                title={i18n.t(k.WEBHOOK_EVENT_PAGE_TITLE)}
                            />
                        }
                    />
                </React.Fragment>
            )}

            <Route
                path="/tenant-settings"
                element={<Navigate to="/tenant-settings/general" />}
            />
            <Route
                path="/tenant-settings/:tabId"
                element={
                    <PrivateContent
                        component={TenantSettingsPage}
                        roles={administratorRoles}
                        title={i18n.t(k.TENANT_SETTINGS)}
                    />
                }
            />

            {props.appFeatures?.pages && (
                <Route path="/pages/*" element={<PageRoutes />} />
            )}

            <Route path="*" element={<Page404 />} />
        </Routes>
    </React.Suspense>
);

const StepRoutes = () => (
    <Routes>
        <Route
            path="add"
            element={<PrivateContent component={AddStep} title="Add Step" />}
        />

        <Route
            path="edit/:id"
            element={<PrivateContent component={EditStep} title="Edit Step" />}
        />

        <Route
            path=":id"
            element={<PrivateContent component={ViewStep} title="View Step" />}
        />

        <Route
            index
            element={<PrivateContent component={SharedSteps} title="Steps" />}
        />
    </Routes>
);

const ImprovementsRoutes = () => (
    <Routes>
        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ImprovementsPage}
                    title={i18n.t(k.NAV_NODE_IMPROVEMENTS)}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={ImprovementsPage}
                    title={i18n.t(k.NAV_NODE_IMPROVEMENTS)}
                />
            }
        />
    </Routes>
);

const TemplateChecklistsReportRoutes = (props: IRolesProps) => (
    <Routes>
        <Route
            path=":id"
            element={
                <PrivateContent
                    component={TemplateChecklistReport}
                    title={i18n.t(k.NAV_NODE_CHECKLISTS_REPORT)}
                    roles={props.roles}
                />
            }
        />
        <Route
            index
            element={
                <PrivateContent
                    component={TemplateChecklistReport}
                    title={i18n.t(k.NAV_NODE_CHECKLISTS_REPORT)}
                    roles={props.roles}
                />
            }
        />
    </Routes>
);

const ImprovementFormsRoutes = (props: IRolesProps) => (
    <Routes>
        <Route
            index
            element={
                <PrivateContent
                    component={ImprovementFormsPage}
                    title={i18n.t(k.NAV_NODE_IMPROVEMENTS)}
                    roles={props.roles}
                />
            }
        />
    </Routes>
);

const EquipmentRoutes = (props: {
    viewRoles: TRoles[];
    editRoles: TRoles[];
}) => (
    <Routes>
        <Route
            path="add"
            element={
                <PrivateContent
                    component={AddEquipment}
                    title="Add Equipment"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path="edit/:id"
            element={
                <PrivateContent
                    component={EditEquipment}
                    title="Edit Equipment"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ViewEquipment}
                    title="Equipment Details"
                    roles={props.viewRoles}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={Equipment}
                    title="Equipment"
                    roles={props.viewRoles}
                />
            }
        />
    </Routes>
);

const TeamRoutes = (props: IRolesProps) => (
    <Routes>
        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ViewTeam}
                    title={i18n.t(k.TEAM_DETAILS)}
                    roles={props.roles}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={Team}
                    title={i18n.t(k.NAV_NODE_TEAMS)}
                    roles={props.roles}
                />
            }
        />
    </Routes>
);

const CustomerRoutes = () => (
    <Routes>
        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ViewCustomer}
                    title="Customer Details"
                />
            }
        />

        <Route
            index
            element={<PrivateContent component={Customers} title="Customers" />}
        />
    </Routes>
);

const SupplierRoutes = (props: {
    viewRoles: TRoles[];
    editRoles: TRoles[];
}) => (
    <Routes>
        <Route
            path="add"
            element={
                <PrivateContent
                    component={AddSupplier}
                    title="Add New Supplier"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path="edit/:id"
            element={
                <PrivateContent
                    component={EditSupplier}
                    title="Edit Supplier"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ViewSupplier}
                    title="Supplier Details"
                    roles={props.viewRoles}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={Suppliers}
                    title={i18n.t(k.NAV_NODE_SUPPLIERS)}
                    roles={props.viewRoles}
                />
            }
        />
    </Routes>
);

const PurchaseOrderRoutes = (props: {
    viewRoles: TRoles[];
    editRoles: TRoles[];
}) => (
    <Routes>
        <Route
            path="add"
            element={
                <PrivateContent
                    component={AddPurchaseOrder}
                    title="Add New Purchase Order"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path="edit/:id"
            element={
                <PrivateContent
                    component={EditPurchaseOrder}
                    title="Edit Purchase Order"
                    roles={props.editRoles}
                />
            }
        />

        <Route
            path=":id"
            element={
                <PrivateContent
                    component={ViewPurchaseOrder}
                    title="Purchase Order Details"
                    roles={props.viewRoles}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={PurchaseOrders}
                    title={i18n.t(k.NAV_NODE_PURCHASE_ORDERS)}
                    roles={props.viewRoles}
                />
            }
        />
    </Routes>
);

const TemplateRoutes = (props: IRolesProps) => (
    <Routes>
        <Route
            index
            element={
                <PrivateContent
                    component={Workflows}
                    title={i18n.t(k.NAV_NODE_TEMPLATES)}
                />
            }
        />
    </Routes>
);

const WebhookRoutes = () => (
    <Routes>
        <Route
            path=":id"
            element={
                <PrivateContent
                    component={WebhookPage}
                    roles={administratorOrMapperRoles}
                    title={i18n.t(k.WEBHOOK_PAGE_TITLE)}
                />
            }
        />

        <Route
            index
            element={
                <PrivateContent
                    component={WebhookPage}
                    roles={administratorOrMapperRoles}
                    title={i18n.t(k.WEBHOOK_PAGE_TITLE)}
                />
            }
        />
    </Routes>
);

const KpiRoutes = () => (
    <Routes>
        <Route
            index
            element={
                <PrivateContent
                    component={KpiPage}
                    title={i18n.t(k.KPI_PAGE_TITLE)}
                />
            }
        />
    </Routes>
);

const PageRoutes = () => (
    <Routes>
        <Route
            path=":pageId/:mode"
            element={
                <PrivateContent component={APage} title={i18n.t(k.PAGES)} />
            }
        />
        <Route
            path=":pageId"
            element={
                <PrivateContent component={APage} title={i18n.t(k.PAGES)} />
            }
        />
        <Route
            index
            element={
                <PrivateContent component={PagesPage} title={i18n.t(k.PAGES)} />
            }
        />
    </Routes>
);
