import "./UserList.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import { useGetUsers } from "components/users/api/hooks";
interface IProps {
    userIds?: string[];

    size?: number;

    testId?: string;

    showArchived?: boolean;
}

const UserList: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { userIds, size, testId, showArchived } = props;

    const { data: usersData } = useGetUsers(
        showArchived ? { selectedIds: userIds } : {},
    );

    const { t } = useTranslation();

    const noneSelected = (userIds?.length ?? 0) === 0;

    const selectedUsers = useMemo(() => {
        return noneSelected
            ? []
            : usersData?.filter(
                  (x) =>
                      userIds?.includes(x.value) &&
                      (showArchived ? true : !x.isArchived),
              );
    }, [userIds, usersData, showArchived]);

    return (
        <div className="user-list--wrapper">
            {noneSelected || !selectedUsers ? (
                t(k.NONE_SELECTED_P)
            ) : (
                <SelectedUsersAvatarList
                    size={size}
                    users={selectedUsers}
                    noTooltipLabel
                    testId={testId}
                />
            )}
        </div>
    );
};

export default UserList;
