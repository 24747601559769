import { Reducer } from "redux";

import IError from "../../common/viewModels/notifications/IError";
import { SHOW_ERROR, NotificationActionTypes, HIDE_ERROR } from "./types";

export interface INotificationState {
    readonly errors: IError[];
}

const initialState: INotificationState = {
    errors: [],
};

export const NotificationReducer: Reducer<
    INotificationState,
    NotificationActionTypes
> = (state = initialState, action): INotificationState => {
    switch (action.type) {
        case SHOW_ERROR: {
            if (action.payload.messages.length > 0) {
                return {
                    ...state,
                    errors: [...state.errors, action.payload],
                };
            }

            return state;
        }

        case HIDE_ERROR: {
            return {
                ...state,
                errors: state.errors.filter((x) => x.id !== action.id),
            };
        }

        default:
            return state;
    }
};
