import {
    CognitGPTAdditionalInfoKey,
    CognitGPTPromptType,
} from "common/utils/cognitGPTUtils";
import IDictionary from "common/viewModels/IDictionary";

import AIButton from "../ai-button/AIButton";

interface IProps {
    canUseAI: boolean;
    promptType: CognitGPTPromptType;
    rephrasePromptType?: CognitGPTPromptType;
    gptLoading?: CognitGPTPromptType;
    isDisabled?: boolean;
    text?: string;
    additionalInfo?: Partial<Record<CognitGPTAdditionalInfoKey, string>>;
    gptErrors: Record<CognitGPTPromptType, string | undefined>;
    onGenerateText: (
        promptType: CognitGPTPromptType,
        additionalInfo?: IDictionary<string>,
    ) => void;
    onCancelStreamingMessage?: () => void;
}

const CognitGPTButton = (props: IProps) => {
    const {
        canUseAI,
        gptLoading,
        isDisabled,
        gptErrors,
        text,
        additionalInfo,
    } = props;

    const promptType = text
        ? (props.rephrasePromptType ?? props.promptType)
        : props.promptType;

    const isAIButtonDisabled =
        isDisabled ||
        (!!gptLoading &&
            gptLoading !== props.promptType &&
            gptLoading !== props.rephrasePromptType);

    const handleOnClick = () => {
        if (
            gptLoading !== props.promptType &&
            gptLoading !== props.rephrasePromptType
        ) {
            props.onGenerateText(promptType, additionalInfo);
        } else {
            props.onCancelStreamingMessage?.();
        }
    };

    return (
        canUseAI && (
            <AIButton
                disabled={isAIButtonDisabled}
                error={gptErrors[promptType]}
                onClick={handleOnClick}
            />
        )
    );
};

export default CognitGPTButton;
