import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const ArchiveMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <span>{t(k.ARCHIVE_THIS_KPI_LINE_1)}</span>
            <br />
            <br />
            <span>{t(k.ARCHIVE_THIS_KPI_LINE_2)}</span>
        </>
    );
};

export default ArchiveMessage;
