import "./ImagePreview.scss";

import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import ImageCard from "./ImageCard";
import { showImage, showImageById } from "store/images/actions";

interface IOwnProps {
    value?: string;

    imgId?: string;
    completedAt?: Date;
}

interface IDispatchProps {
    showPreview: (value: string) => void;
    showPreviewById: (imgId: string) => void;
}

type Props = IOwnProps & IDispatchProps;

const ImagePreview: React.FC<React.PropsWithChildren<Props>> & {
    Renderer: React.FC<React.PropsWithChildren<Props>>;
} = (props) => {
    const { value, imgId, completedAt } = props;

    const { t } = useTranslation();

    const showModalPreview = () => {
        if (value) {
            props.showPreview(value);
        } else if (imgId) {
            props.showPreviewById(imgId);
        }
    };

    const imgSrc = value || imgId;

    if (!imgSrc) {
        return null;
    }

    return (
        <div className="image-preview__wrapper">
            <ImageCard
                value={value}
                imgId={imgId}
                completedAt={completedAt}
                onClick={showModalPreview}
            >
                <span>{t(k.CLICK_TO_PREVIEW)}</span>
            </ImageCard>
        </div>
    );
};

ImagePreview.Renderer = ImagePreview;

const actions: IDispatchProps = {
    showPreview: showImage,
    showPreviewById: showImageById,
};

export default connect(null, actions)(ImagePreview);
