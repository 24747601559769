import "./History.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IHistoryDTO from "components/history/api/IHistoryDTO";
import FormattedDate from "common/components/formattedDate/FormattedDate";

interface IProps {
    item: IHistoryDTO;
    helperNameText?: string;
    details?: string;
    onClose: () => void;
}

const HistoryItem: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { item, helperNameText, details } = props;
    const { t } = useTranslation();

    return (
        <div className="history--item-row">
            <div className="history--item-row--description">
                <i>
                    {t(item.what)} {helperNameText && `"${helperNameText}"`}
                </i>{" "}
                {details}
            </div>

            <div className="history--item-row--user">
                <FormattedDate date={item.createdAt} /> {t(k.BY_)}
                <strong> {item.createdBy}</strong>
            </div>
        </div>
    );
};

export default HistoryItem;
