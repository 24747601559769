import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { MultiselectValueBadge } from "common/components/badges";
import ImprovementImpactBadge from "common/components/badges/improvement-impact-badge/ImprovementImpactBadge";
import ImprovementStatusBadge from "common/components/badges/improvement-status-badge/ImprovementStatusBadge";
import DateByUser from "common/components/date-by-user/DateByUser";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import LinkIcon from "common/components/icons/icon-link/LinkIcon";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import { TableCell, TableRow } from "common/components/table";
import { TableCellComponentType } from "common/components/table/components/TableCellComponent";
import { ProcessChartImprovementType } from "components/process-chart/api/improvements/ProcessChartImprovementType";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import IDeviation from "models/IDeviation";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";

interface IImpactedProcessCellProps {
    index?: number;
    impactedProcess?: IValueLabelItem<string, ProcessChartImprovementType>;
    processChartItemNames: Record<
        string,
        IValueLabelItemWithState<string> | undefined
    >;
}

const ImpactedProcessCell: React.FC<IImpactedProcessCellProps> &
    Pick<TableCellComponentType, "isTableCell"> = (
    props: IImpactedProcessCellProps,
) => {
    const { impactedProcess, processChartItemNames } = props;

    const { t } = useTranslation();

    const handleStopPropagation = (e: React.UIEvent) => {
        e.stopPropagation();
    };

    if (impactedProcess) {
        const { value: chartItemId, label: chartItemType } = impactedProcess;

        const chartItem = processChartItemNames[chartItemId];

        if (chartItem) {
            if (chartItem.isDeleted) {
                return (
                    <TableCell>
                        <MultiselectValueBadge isDeleted>
                            {chartItem.label}
                        </MultiselectValueBadge>
                    </TableCell>
                );
            }

            return (
                <TableCell
                    onClick={handleStopPropagation}
                    pointerCursorForClickable={false}
                    useOverflow
                >
                    {chartItemType ===
                    ProcessChartImprovementType.ProcessStep ? (
                        <a
                            target="_blank"
                            href={`/subprocess/${chartItem.param}?modal=process_step&id=${chartItem.value}`}
                            className="improvements--table--row-cell--link"
                        >
                            {chartItem.label}
                            <LinkIcon />
                        </a>
                    ) : (
                        <a
                            target="_blank"
                            href={`/${
                                chartItemType ===
                                ProcessChartImprovementType.Process
                                    ? "process"
                                    : "subprocess"
                            }/${chartItem.value}`}
                            className="improvements--table--row-cell--link"
                        >
                            {chartItem.label}
                            <LinkIcon />
                        </a>
                    )}
                </TableCell>
            );
        }
    }

    return <TableCell>{t(k.UNKNOWN)}</TableCell>;
};
ImpactedProcessCell.isTableCell = true;

interface IProps {
    index?: number;
    value: IDeviation;
    processChartItemNames: Record<
        string,
        IValueLabelItemWithState<string> | undefined
    >;
    showImpactedProcesses: boolean;
    publishedUsers?:
        | ITableSetWithValueLabelOptions<string, undefined, string>
        | undefined;
    onSelectAction: (id: string) => void;
}

const ImprovementTableRow: React.FC<IProps> = (props) => {
    const {
        value,
        showImpactedProcesses,
        processChartItemNames,
        publishedUsers,
    } = props;

    const { t } = useTranslation();

    const handleOnClick = () => {
        props.onSelectAction(value.id);
    };

    const selectedResponsibleUsers = useMemo(
        () =>
            publishedUsers?.options?.filter((user) =>
                value.responsibleUserIds?.includes(user.value),
            ),
        [publishedUsers, value.responsibleUserIds],
    );

    const selectedInvolvedUsers = useMemo(
        () =>
            publishedUsers?.options?.filter((user) =>
                value.involvedUserIds?.includes(user.value),
            ),
        [publishedUsers, value.involvedUserIds],
    );

    return (
        <TableRow className="improvements--table--row" onClick={handleOnClick}>
            <TableCell useOverflow useEllipsis>
                {value.message}
            </TableCell>

            <TableCell useEllipsis>{value.formName}</TableCell>

            <TableCell>
                <ImprovementImpactBadge impact={value.impact} />
            </TableCell>

            <TableCell>
                <ImprovementStatusBadge
                    status={value.dynamicStatus}
                    closureReason={
                        value.dynamicStatus === DynamicImprovementStatus.Closed
                            ? value.closureReason
                            : undefined
                    }
                />
            </TableCell>

            {showImpactedProcesses && (
                <TableCell>
                    <SelectedUsersAvatarList
                        users={selectedResponsibleUsers ?? []}
                        usersAffected={undefined}
                        maxShownAvatars={3}
                    />
                </TableCell>
            )}

            {showImpactedProcesses && (
                <TableCell>
                    <SelectedUsersAvatarList
                        users={selectedInvolvedUsers ?? []}
                        usersAffected={undefined}
                        maxShownAvatars={3}
                        noUsersText={t(k.NONE)}
                    />
                </TableCell>
            )}

            {showImpactedProcesses && (
                <ImpactedProcessCell
                    impactedProcess={value.impactedProcessDiscovered}
                    processChartItemNames={processChartItemNames}
                />
            )}

            {showImpactedProcesses && (
                <ImpactedProcessCell
                    impactedProcess={value.impactedProcessOriginated}
                    processChartItemNames={processChartItemNames}
                />
            )}

            <TableCell>
                {value.createdAt && value.createdBy && (
                    <DateByUser
                        date={value.createdAt}
                        user={value.createdBy.label}
                    />
                )}
            </TableCell>
            <TableCell>
                {value.updatedAt && value.updatedBy && (
                    <DateByUser
                        date={value.updatedAt}
                        user={value.updatedBy.label}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default ImprovementTableRow;
