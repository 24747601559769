import { Reducer } from "redux";

import { reduceReducers } from "../common/utils/reducerUtils";
import { StepOrmReducer } from "./steps/ormReducer";
import { WorkflowOrmReducer } from "./workflows/ormReducer";

export const AllEntitiesReducer: Reducer<any, any> = reduceReducers(
    WorkflowOrmReducer,
    StepOrmReducer,
);
