import { components, GroupBase, InputProps } from "react-select";
import { OptionType } from "../SelectDropdownTypes";

export const SelectCustomInput = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: InputProps<Option, IsMulti, Group>,
) => (
    <components.Input
        data-testid={props.selectProps.inputDataTestId}
        {...props}
    />
);
