import k from "./keys";

export default {
    [k.A_CHECKLIST_CANT_BE_UPGRADED_IF_IT_HAS]: `A checklist can't be upgraded if it has`,
    [k.A_FIXED_DATE]: `A Fixed date`,
    [k.ABOVE_OR_EQUAL_TO]: `Above or equal to`,
    [k.ABOVE_OR_EQUAL]: `Above or equal`,
    [k.ABOVE]: `Above`,
    [k.ACCEPT_A_SINGLE_BARCODE]: `Accept a single barcode`,
    [k.ACCEPT_MULTIPLE_BARCODES]: `Accept multiple barcodes`,
    [k.ACCEPT_MULTIPLE_OPTIONS]: `Accept multiple options`,
    [k.ACCEPT_SINGLE_OPTION]: `Accept a single option`,
    [k.ACCESS_LIMITING_DESCRIPTION]: `Limits access for users. Users can only see the Custom Lists where they, or someone they manage, have been assigned the role of User`,
    [k.ACCESS_LIMITING]: `Access Limiting`,
    [k.ACT]: `Act`,
    [k.ACTING]: `Acting`,
    [k.ACTION_PLAN]: `Action Plan`,
    [k.ACTION]: `Action`,
    [k.ACTIONS]: `Actions`,
    [k.ACTIVATE_USER_LINE_1]: `If you activate the user they will be able to log in and access all their data again.`,
    [k.ACTIVATE_USER_TITLE]: `Activate this user?`,
    [k.ACTIVATE]: `Activate`,
    [k.ACTIVE_CHECKLISTS]: `Active checklists`,
    [k.ACTIVE_L]: `active`,
    [k.ACTIVE]: `Active`,
    [k.ACTIVITIES_]: `activities`,
    [k.ACTIVITIES_EXISTS_THAT_ARE_NOT_DONE]: `Activities exist that are not done`,
    [k.ACTIVITIES_REQUIRED]: `You need at least one activity to create a step`,
    [k.ACTIVITIES_WERENT_COMPLETED]: `Activities weren't completed`,
    [k.ACTIVITIES]: `Activities`,
    [k.ACTIVITY_HEADER]: `Activity header`,
    [k.ACTIVITY_MUST_BE_COMPLETED_TO_FINALIZE_STEP]: `Activity must be completed to finalize the step`,
    [k.ACTIVITY]: `Activity`,
    [k.ADD_ABSOLUTE_PROGRESS]: `Absolute progress (new total)`,
    [k.ADD_ACTIVITIES]: `Add activities`,
    [k.ADD_ATTACHMENT]: `Add attachment`,
    [k.ADD_BARCODES_LABEL]: `Label for Barcodes`,
    [k.ADD_CHECKBOX_LABEL]: `Label for Tasklist`,
    [k.ADD_CHECKBOX]: `Tasklist`,
    [k.ADD_COMMENT_TO_PEER]: `Add comment to peer`,
    [k.ADD_COMMENT]: `Add comment`,
    [k.ADD_COMPETENCY_MATRIX]: `Add Competency Matrix`,
    [k.ADD_COMPETENCY]: `Add Competency`,
    [k.ADD_CONTACT]: `New Contact`,
    [k.ADD_CUSTOM_LIST]: `Add New Custom List`,
    [k.ADD_CUSTOMER_DROPDOWN_LABEL]: `Label for Customers`,
    [k.ADD_CUSTOMER_DROPDOWN]: `Customers`,
    [k.ADD_CUSTOMER_INDUSTRY]: `Add Customer Industry`,
    [k.ADD_CUSTOMER_REQUIREMENT]: `Add Customer Requirement`,
    [k.ADD_CUSTOMER]: `Add Customer`,
    [k.ADD_DATA_SOURCE]: `Add data source`,
    [k.ADD_DATE_TIME]: `Date time`,
    [k.ADD_DROPDOWN_LABEL]: `Label for Selection`,
    [k.ADD_DROPDOWN]: `Selection`,
    [k.ADD_ELEMENTS]: `Add elements`,
    [k.ADD_ENVIRONMENTAL_ASPECT]: `Add Environmental Aspect`,
    [k.ADD_EQUIPMENT]: `New Equipment`,
    [k.ADD_EXISTING_GOAL]: `Add Existing Goal`,
    [k.ADD_FROM_LIBRARY]: `Add from library`,
    [k.ADD_GOAL]: `Add goal`,
    [k.ADD_IMPACT_GRADING_AREA]: `New Area`,
    [k.ADD_INCREMENTAL_PROGRESS]: `Incremental progress`,
    [k.ADD_ITEM_DROPDOWN_LABEL]: `Label for Items`,
    [k.ADD_ITEM_DROPDOWN]: `Items`,
    [k.ADD_ITEM]: `New Item`,
    [k.ADD_KPI]: `Add KPI`,
    [k.ADD_LEGISLATION]: `Add Legislation`,
    [k.ADD_LOCAL_STEP]: `Add Step`,
    [k.ADD_MEASUREMENT]: `Add Measurement`,
    [k.ADD_MEASUREMENTS_TO_MEASURE_PROGRESS]: `Add some measurements to measure progress`,
    [k.ADD_MULTI_LINE_TEXTFIELD_LABEL]: `Label for Text`,
    [k.ADD_MULTI_LINE_TEXTFIELD]: `Text`,
    [k.ADD_NEW_CUSTOM_LIST]: `Add New Custom List`,
    [k.ADD_NEW_CUSTOM_LIST_DESCRIPTION]: `You can create a list of data with a wide array of different properties. For example, you can create a customized list to manage all of your company Branches and link that to a custom list with your Buildings. To create a new custom list, click the button on the right.`,
    [k.ADD_NEW_CUSTOMER_SUBTITLE]: `You can add a new customer from here`,
    [k.ADD_NEW_CUSTOMER]: `New Customer`,
    [k.ADD_NEW_EQUIPMENT_SUBTITLE]: `You can add a new equipment from here`,
    [k.ADD_NEW_EQUIPMENT]: `New Equipment`,
    [k.ADD_NEW_PROCESS]: `Add New Process`,
    [k.ADD_NEW_PURCHASE_ORDER_SUBTITLE]: `Add a new purchase order from here`,
    [k.ADD_NEW_ROW]: `Add New Row`,
    [k.ADD_NEW_SUPPLIER_SUBTITLE]: `You can add a new supplier from here`,
    [k.ADD_NEW_SUPPLIER]: `New Supplier`,
    [k.ADD_NEW_TASK_SUBTITLE]: `You can add a new task here`,
    [k.ADD_NEW_TASK]: `New Task`,
    [k.ADD_NEW_TEAM_SUBTITLE]: `You can add a new team from here`,
    [k.ADD_NEW_TEAM]: `New Team`,
    [k.ADD_NEW_USER_SUBTITLE]: `You can add a new user from here`,
    [k.ADD_NEW_USER]: `New User`,
    [k.ADD_NEW]: `Add New`,
    [k.ADD_NOTES]: `Add Notes`,
    [k.ADD_OPERATIONAL_RISK_ANALYSIS]: `Add Operational Risk Analysis`,
    [k.ADD_PHOTO_LABEL]: `Label for Photo`,
    [k.ADD_PHOTO]: `Add photo`,
    [k.ADD_POSITION]: `Add Position`,
    [k.ADD_PROGRESS]: `Add Progress`,
    [k.ADD_PROPERTY]: `Add Property`,
    [k.ADD_PURCHASE_ORDER_LINE]: `Add PO Line Status`,
    [k.ADD_PURCHASE_ORDER]: `New Purchase Order`,
    [k.ADD_RECURRING_CHECKLIST]: `New Recurring Checklist`,
    [k.ADD_STAFF_APPRAISAL]: `Add Staff Appraisal`,
    [k.ADD_STAKEHOLDER]: `Add Stakeholder`,
    [k.ADD_STATIC_ACTION_PLAN]: `Add Static Action Plan`,
    [k.ADD_STEP]: `Add Step`,
    [k.ADD_SUPPLIER_DROPDOWN_LABEL]: `Label for Suppliers`,
    [k.ADD_SUPPLIER_DROPDOWN]: `Suppliers`,
    [k.ADD_SUPPLIER]: `New Supplier`,
    [k.ADD_TASK_LIST]: `Add Task List`,
    [k.ADD_TASK]: `Add Task`,
    [k.ADD_TEAM]: `Add Team`,
    [k.ADD_TEMPLATE]: `New Template`,
    [k.ADD_TEXTFIELD_LABEL]: `Label for Value`,
    [k.ADD_TEXTFIELD]: `Value`,
    [k.ADD_TRAINING_PLAN]: `Add Training Plan`,
    [k.ADD_USER]: `New User`,
    [k.ADD]: `Add`,
    [k.ADDED_AS_INVOLVED_TO]: `Added as involved to`,
    [k.ADDED]: `Added`,
    [k.ADDRESS_BILING]: `Billing Address`,
    [k.ADDRESS_SHIP_VISIT]: `Shipping / Visit Address`,
    [k.ADDRESS_SHIP]: `Shipping Address`,
    [k.ADDRESS_VISIT]: `Visit Address`,
    [k.ADDRESS]: `Address`,
    [k.ADMINISTRATOR]: `Administrator`,
    [k.ADMINISTRATORS]: `Administrators`,
    [k.ADVANCED_CONFIG]: `Advanced Config`,
    [k.AFFECTED_PROCESS_STEPS]: `Affected process step(s)`,
    [k.AFFECTED_SUB_PROCESS]: `Affected sub process`,
    [k.AFTER]: `After`,
    [k.AI]: `AI`,
    [k.AI_API_KEY_DESCRIPTION_PART1]: `Enter the api key that AmpliFlow will utilize for AI integration. Don't have an API key? Contact`,
    [k.AI_FEATURE_DESCRIPTION_PART1]: `AmpliFlow AI can help you to auto fill information like improvement description, risk analysis and more. To learn more and enroll in the beta please contact`,
    [k.AI_FEATURE_DESCRIPTION_PART2]: `today.`,
    [k.AI_FEATURES_BETA]: `AI Features (Beta)`,
    [k.AID_NEEDS]: `Aid needs`,
    [k.ALERT]: `Alert`,
    [k.ALL_]: `all`,
    [k.ALL_CATEGORIES]: `All Categories`,
    [k.ALL_IMPROVEMENTS_DESCRIPTION]: `A KPI that will use all improvements in the system as it’s data source.`,
    [k.ALL_IMPROVEMENTS]: `All Improvements`,
    [k.ALL_USERS_IN_SYSTEM]: `All users in the system`,
    [k.ALL]: `All`,
    [k.ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING]: `Allow create user on SSO signin if the user is missing`,
    [k.ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING_DESCRIPTION]: `Should new users be automatically created when they try to sign in via SSO for the first time or not? Toggle this to green to allow automatic creation and disable if you want to manually create users 1 by 1 in AmpliFlow before they can sign in.`,
    [k.ALLOW_MULTI_VALUE]: `Allow multiple`,
    [k.ALLOW_USERS_TO_ADD_AND_ASSIGN_OWN_TASKS_IN_RUNNING_CHECKLISTS]: `Allow users to add and assign own tasks in running checklists`,
    [k.AMOUNT]: `Amount`,
    [k.AN_ERROR_OCCURED]: `An error occured in`,
    [k.ANALYSING]: `Analysing`,
    [k.ANALYSIS]: `Analysis`,
    [k.ANALYZE]: `Analyze`,
    [k.AND_]: `and`,
    [k.AND_REPEAT_IT_ON_THE_SAME_WEEKDAY_EVERY]: `And repeat it on the same weekday every`,
    [k.AND]: `And`,
    [k.ANOTHER_KPI]: `Another KPI`,
    [k.ANSWERS]: `Answers`,
    [k.API_ENDPOINTS]: `API Endpoints`,
    [k.API_KEY_CHECKLIST]: `API Key checklist`,
    [k.API_KEY]: `API Key`,
    [k.API_KEYS_SUBTITLE]: `Here are all the API keys available in the system`,
    [k.API_KEYS]: `API Keys`,
    [k.API_KEYS_DESCRIPTION]: `Manage API keys and view all the endpoints that are used to integrate Ampliflow with third-party solutions.`,
    [k.APPLICABLE_TO_OUR_ORGANIZATION]: `Applicable to our organization?`,
    [k.APPLY]: `Apply`,
    [k.APPRAISING_MANAGER]: `Appraising manager`,
    [k.APR]: `Apr`,
    [k.APRIL]: `April`,
    [k.ARCHIVE_IMPACT_GRADING_1]: `If you archive this impact area it will be unavailable for future impact gradings.`,
    [k.ARCHIVE_IMPACT_GRADING_2]: `If already used anywhere in the system it will no longer be visible but the data will not be removed.`,
    [k.ARCHIVE_IMPACT_GRADING_3]: `You can un-archive the impact area at anytime. Doing so will restore visibility of previous usage cases.`,
    [k.ARCHIVE_MESSAGE_GENERIC_1]: `If you archive this, it will be no longer available for use anywhere in the system. You can always unarchive it from the "Action dropdown". `,
    [k.ARCHIVE_MESSAGE_GENERIC_2]: `Archiving does not delete the data permanently. You can delete it permanently, only after archiving`,
    [k.ARCHIVE_POSITION_1]: `If you archive this position it will be unavailable for assignment to users.`,
    [k.ARCHIVE_POSITION_2]: `If already used anywhere in the system, it will be still be visible.`,
    [k.ARCHIVE_POSITION_3]: `You can un-archive the position at anytime.`,
    [k.ARCHIVE_PURCHASE_ORDER_MESSAGE_1]: `If you archive this purchase order, it will be no longer available to use anywhere in the system. You can always unarchive it from the "Action" dropdown menu`,
    [k.ARCHIVE_PURCHASE_ORDER_MESSAGE_2]: `Archiving doesn't delete the data permanently. You can delete it permanently only after archiving`,
    [k.ARCHIVE_PURCHASE_ORDER_TITLE]: `Archive this purchase order?`,
    [k.ARCHIVE_THIS_AREA]: `Archive this area`,
    [k.ARCHIVE_THIS_GOAL]: `Archive this goal?`,
    [k.ARCHIVE_THIS_KPI_LINE_1]: `If you archive this KPI, the targets cannot be updated anymore. Data will be still available for reporting.`,
    [k.ARCHIVE_THIS_KPI_LINE_2]: `You can unarchive it anytime.  Are you sure you want to archive this KPI?`,
    [k.ARCHIVE_THIS_KPI]: `Archive this KPI?`,
    [k.ARCHIVE_THIS_MEASUREMENT]: `Archive this measurement?`,
    [k.ARCHIVE_THIS_POSITION]: `Archive this position`,
    [k.ARCHIVE_THIS_PROGRESS_VALUE]: `Archive this progress value?`,
    [k.ARCHIVE_THIS_TEAM]: `Archive this team?`,
    [k.ARCHIVE]: `Archive`,
    [k.ARCHIVED_]: `archived`,
    [k.ARCHIVED_BY]: `Archived by`,
    [k.ARCHIVED_CUSTOM_LISTS]: `Archived Custom Lists`,
    [k.ARCHIVED_P]: `Archived`,
    [k.ARCHIVED_POSITION]: `Archived position`,
    [k.ARCHIVED]: `Archived`,
    [k.ARE_]: `are`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_CONTENT]: `Only an administrator will be able to restore the improvement form from the archive`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_INFO]: `Use this option if you want the improvement form to be removed from everyone's work list. For example if it was started by accident`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM]: `Are your sure you want to archive this improvement form?`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN_CONTENT]: `Only an administrator will be able to restore the checklist from the archive`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN_INFO]: `Use this option if you want the checklist to be removed from everyone's work list. For example if it was started by accident`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN]: `Are your sure you want to archive this checklist?`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_CONTENT]: `Only an administrator will be able to restore the schedule from the archive`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_INFO]: `Use this option if you want the schedule to be removed from everyone's work list. For example if it was started by accident`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE]: `Are your sure you want to archive this schedule?`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_CONTENT]: `Only an administrator will be able to restore the Template from the archive`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_INFO]: `Use this option if you want the Template to be removed from everyone's work list. For example if it was started by accident`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE]: `Are your sure you want to archive this Template?`,
    [k.ARE_YOU_SURE_RESET]: `Are you sure you want to Reset?`,
    [k.ARE_YOU_SURE_RESUME_RUN_CONTENT]: `The checklist will return to the Ready state for everyone that has access to work on it`,
    [k.ARE_YOU_SURE_RESUME_RUN_INFO]: `Don't worry, if you resume it by mistake you can easily just stop it again`,
    [k.ARE_YOU_SURE_RESUME_RUN]: `Do you want to resume this checklist?`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE_CONTENT]: `The schedule will return to the Ready state for everyone that has access to work on it`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE_INFO]: `Don't worry, if you resume it by mistake you can easily just pause it again`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE]: `Do you want to resume this schedule?`,
    [k.ARE_YOU_SURE_STOP_RUN_CONTENT]: `No one will be able to work on this checklist while it is stopped`,
    [k.ARE_YOU_SURE_STOP_RUN_INFO]: `But: Anyone, including you, can resume it at any point in time`,
    [k.ARE_YOU_SURE_STOP_RUN]: `Are you sure you want to stop this checklist until someone resumes it?`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE_CONTENT]: `No one will be able to work on this schedule while it is stopped`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE_INFO]: `But: Anyone, including you, can resume it at any point in time`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE]: `Are you sure you want to stop this schedule until someone resumes it?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_CONTENT]: `When you un-archive an improvement form it will be restored and show up for users`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_INFO]: `Users can continue working on it. This action is limited to administrators`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM]: `Do you want to un-archive this improvement form?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN_CONTENT]: `When you un-archive a sheet it will be restored and show up for users in the Stopped state`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN_INFO]: `Users can then choose to resume the checklist and continue working on it. This action is limited to administrators`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN]: `Do you want to un-archive this checklist?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_CONTENT]: `When you un-archive a schedule it will be restored and show up for users in the Paused state`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_INFO]: `Users can then choose to resume and continue working on it. This action is limited to administrators`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE]: `Do you want to un-archive this schedule?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_CONTENT]: `When you un-archive a Template it will be restored and show up for users`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_INFO]: `Users can continue working on it. This action is limited to administrators`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE]: `Do you want to un-archive this Template?`,
    [k.ARE_YOU_SURE_UNSAVED_CHANGES_INFO]: `There are un-saved changes. Going back will loose these changes`,
    [k.ARE_YOU_SURE_UNSAVED_CHANGES]: `Are you sure you want to cancel?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_EQUIPMENT]: `Are your sure you want to archive this equipment`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_TEAM]: `Are your sure you want to archive this team?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CHECKLIST]: `Are you really sure you want delete this checklist and all of it's information?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_IMPROVEMENT]: `Are you really sure you want delete this improvement and all of its information?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RECURRING_CHECKLIST]: `Are you really sure you want delete this recurrence and all of it's checklists?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TEMPLATE]: `When you delete a template it will be deleted from the system and won't be available for reporting.\r\n\nAre your sure you want to delete this Template?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DISABLE_THIS_FEATURE]: `Are you sure you want to disable this feature?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DISABLE]: `Are you sure you want to disable`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ENABLE_THIS_FEATURE]: `Are you sure you want to enable this feature?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_LEAVE]: `Are you sure you want to leave`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_PROCEED]: `Are you sure you want to proceed?`,
    [k.ARE_YOU_SURE]: `Are you sure?`,
    [k.AREA_WILL_BE_REMOVED]: `Area will be removed`,
    [k.AS_]: `as`,
    [k.ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT_FEATURE_DESCRIPTION]: `This feature allows users to attach data regarding the origin and discovery of improvements to specific process nodes.`,
    [k.ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT]: `Assign Impacted Process to Improvement`,
    [k.ASSIGNED_TO]: `Assigned to`,
    [k.ASSIGNED]: `Assigned`,
    [k.ASSIGNEE]: `Assignee`,
    [k.ASSIGNMENT]: `Assignment`,
    [k.AT_A_GLANCE]: `At a glance`,
    [k.AT_LEAST_ONE_ACTIVITY_IS_REQUIRED]: `At least one activity is required`,
    [k.AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION]: `At least one involved user is required in additional involved users configuration.`,
    [k.AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION]: `In order to notify users you must add at least 1 user in the involved users configuration.`,
    [k.AT_LEAST_ONE_PROP_VISIBLE]: `At least one property must be shown in list`,
    [k.AT_LEAST_ONE_RESPONSIBLE_USER_TYPE_REQUIRED]: `At least one responsible user type is required`,
    [k.AT_RISK]: `At Risk`,
    [k.ATTACHMENTS]: `Attachments`,
    [k.AUG]: `Aug`,
    [k.AUGUST]: `August`,
    [k.AVAILABLE_USERS]: `Available users`,
    [k.BACK_TO_CHECKLISTS]: `Back to Checklists`,
    [k.BACK_TO_CUSTOMER_LIST]: `Back to Customer List`,
    [k.BACK_TO_EQUIPMENT_LIST]: `Back to Equipment List`,
    [k.BACK_TO_PREVIOUS_PAGE]: `Back to Previous Page`,
    [k.BACK_TO_PURCHASE_ORDER_LIST]: `Back to Purchase Order List`,
    [k.BACK_TO_SUPPLIER_LIST]: `Back to Supplier List`,
    [k.BACK_TO_TEAM_LIST]: `Back to Teams List`,
    [k.BACK_TO_USER_LIST]: `Back to User List`,
    [k.BACK]: `Back`,
    [k.BARCODE_ACTIVITY_DESCRIPTION]: `Enabling this feature allows you to easily add the ability to scan and generate barcodes within Custom Lists, Staff Appraisals, Improvements, and Checklists.`,
    [k.BARCODE_ACTIVITY]: `Barcode Activity`,
    [k.BARCODE]: `Barcode`,
    [k.BARCODES]: `Barcodes`,
    [k.BEHIND_]: `behind`,
    [k.BEHIND]: `Behind`,
    [k.BELOW_IS_A_CALENDAR_FOR_THE_SCHEDULE]: `Below is a calendar for the schedule`,
    [k.BELOW_OR_EQUAL_TO]: `Below or equal to`,
    [k.BELOW_OR_EQUAL]: `Below or equal`,
    [k.BELOW]: `Below`,
    [k.BETWEEN]: `Between`,
    [k.BILLING_COUNTRY]: `Billing Country`,
    [k.BILLING_STATE_OR_PROVINCE]: `Billing State/Province`,
    [k.BIO]: `Bio`,
    [k.BLOCK_FOLLOWING_STEPS_TOOLTIP]: `User won't be able to complete other steps after this, if this is marked as blocking`,
    [k.BLOCK_FOLLOWING_STEPS]: `Block following steps until this step is finalized`,
    [k.BLOCKED_]: `blocked`,
    [k.BLOCKED_P]: `Blocked`,
    [k.BLOCKED]: `Blocked`,
    [k.BLOCKING]: `Blocking`,
    [k.BLOCKING_STEPS]: `Blocking steps`,
    [k.BOTH]: `Both`,
    [k.BOUGHT_IN]: `Bought In`,
    [k.BROWSE_AND_IMPORT_CLOUD_TEMPLATES]: `Browse and import ready-made templates`,
    [k.BULK_IMPORT_FEATURE_DESCRIPTION]: `Import bulk data quickly and efficiently. For example, you can bulk-import your existing Users, Teams, Stakeholders, and more.`,
    [k.BUSINESS_NAME]: `Business Name`,
    [k.BY_]: `by`,
    [k.CAN_NOT_ARCHIVE]: `Can not Archive`,
    [k.CAN_VIEW]: `Can View`,
    [k.CANCEL]: `Cancel`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_FIRST]: `This can not be archived as it is currently used in below procedure(s)`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_SECOND]: `Please remove the team from the procedure(s) first and try archiving again`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_FIRST]: `This can not be archived as it is currently used in below step(s)`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_SECOND]: `Please remove the team from the step(s) first and try archiving again`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_FIRST]: `This can not be archived as it is currently used in below template(s)`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_SECOND]: `Please remove the team from the template(s) first and try archiving again`,
    [k.CANNOT_ASSIGN_ARCHIVED_USER_POSITION]: `Cannot assign archived position to the user.`,
    [k.CANNOT_DELETE_THE_POSITION]: `Cannot delete the position`,
    [k.CANNOT_DELETE_THE_TEAM]: `Cannot delete the team`,
    [k.CANNOT_EDIT_ARCHIVED_USER_POSITION]: `This position is archived and cannot be edited.`,
    [k.CANNOT_UNARCHIVE_CHECKLIST_SCHEDULE_ARCHIVED]: `Cannot unarchive the Checklist since its Schedule was archived`,
    [k.CANNOT_UNARCHIVE_CHECKLIST_TEMPLATE_ARCHIVED]: `Cannot unarchive the Checklist since its Template was archived`,
    [k.CANT_BE_UPGRADED]: `can't be upgraded`,
    [k.CAPTURE_PHOTO]: `Capture photo`,
    [k.CATEGORY]: `Category`,
    [k.CHANGE_END_DATE_FOR_THE_SCHEDULE]: `Change end date for the schedule`,
    [k.CHANGE_TENANT_NAME_MESSAGE]: `Please note that changing the name will make existing links invalid. Ensure that you provide the new URL to the affected users. Users accessing the old URL (e.g., through bookmarks) will not be automatically redirected.\n\nAre you sure you want to continue?`,
    [k.CHANGE_TENANT_NAME]: `Change Name?`,
    [k.CHANGED_TEAMS_IN_STEPS]: `Changed teams in steps`,
    [k.CHANGED]: `Changed`,
    [k.CHANGES_APPLY_AFTER_SAVE]: `Changes apply after you save`,
    [k.CHANGING_SUPPLIER_CONFIRM_MESSAGE]: `When the supplier is changed the items will reset`,
    [k.CHECKBOX]: `checkbox`,
    [k.CHECKLIST_]: `checklist`,
    [k.CHECKLIST_ARCHIVED]: `Checklist archived`,
    [k.CHECKLIST_DELETE_DESCRIPTION]: `Deleting a checklist is the last action you take when something has gone wrong, if you want to keep a record of the checklist for any reason whatsoever you should keep it in the 'archived' state`,
    [k.CHECKLIST_DETAILS]: `Checklist Details`,
    [k.CHECKLIST_DURATION]: `Checklist duration`,
    [k.CHECKLIST_HAS_BEEN_MODIFIED_AND_DOES_NOT_MATCH_SCHEDULE]: `Checklist has been modified and does not match schedule`,
    [k.CHECKLIST_PAUSED]: `Checklist paused`,
    [k.CHECKLIST_PREVIEW]: `Checklist Preview`,
    [k.CHECKLIST_REPORT_FEATURE_DESCRIPTION]: `View detailed reports of your checklists with advanced filtering.`,
    [k.CHECKLIST_REPORT_OWNER_INFO]: `Can see all Checklist reports.\nCan assign Checklist Report permissions.`,
    [k.CHECKLIST_REPORT_OWNER]: `Checklist Report - Owner`,
    [k.CHECKLIST_REPORT_SUBTITLE]: `Search and sort checklists and their reported values`,
    [k.CHECKLIST_REPORT_VIEWER_INFO]: `Can see all Checklist reports.`,
    [k.CHECKLIST_REPORT_VIEWER]: `Checklist Report - Viewer`,
    [k.CHECKLIST_RESUMED]: `Checklist resumed`,
    [k.CHECKLIST_STARTED]: `Checklist started`,
    [k.CHECKLIST_STEPS]: `Checklist Steps`,
    [k.CHECKLIST_STOPPED]: `Checklist stopped`,
    [k.CHECKLIST_TEMPLATES]: `Checklist Templates`,
    [k.CHECKLIST_UNARCHIVED]: `Checklist unarchived`,
    [k.CHECKLIST_UNPAUSED]: `Checklist unpaused`,
    [k.CHECKLIST]: `Checklist`,
    [k.CHECKLISTS_]: `checklists`,
    [k.CHECKLISTS_RUN]: `Checklist(s) run`,
    [k.CHECKLISTS_SEARCH_SUBTITLE]: `Scan a barcode or enter a query to search for checklists`,
    [k.CHECKLISTS_SEARCH]: `Checklist Search`,
    [k.CHECKLISTS_SUBTITLE]: `All Checklists available to you`,
    [k.CHECKLISTS_WILL_BE_UPGRADED_AND]: `Checklists will be upgraded and`,
    [k.CHECKLISTS]: `Checklists`,
    [k.CHOOSE_IMPACTED_AREAS]: `Choose impacted areas`,
    [k.CHOOSE_IMPACTED_PROCESSES]: `Choose impacted processes`,
    [k.CHOOSE_REASON_FOR_CLOSURE]: `Choose reason for closure`,
    [k.CHOOSE]: `Choose...`,
    [k.CITY]: `City`,
    [k.CLEAR]: `Clear`,
    [k.CLICK_HERE]: `Click here`,
    [k.CLICK_THE_BUTTON_BELLOW_TO_MARK_THIS_STEP_AS_COMPLETED]: `Click the button below to mark this step as completed`,
    [k.CLICK_TO_ADD_LABEL]: `Click to add label`,
    [k.CLICK_TO_PREVIEW]: `Click to preview`,
    [k.CLICK_TO_SHOW_MORE]: `Click to show more`,
    [k.CLONE_MESSAGE]: `Clone`,
    [k.CLOSE_IMPROVEMENT]: `Close Improvement`,
    [k.CLOSE_POSITION_BEFORE_SAVE]: `Close position before you save`,
    [k.CLOSE_WITH_SELECTED_REASON]: `Close with selected reason`,
    [k.CLOSE]: `Close`,
    [k.CLOSED]: `Closed`,
    [k.CLOSEDs]: `Closed`,
    [k.CLOSURE_REASON_REQUIRED]: `Closure reason is required`,
    [k.CLOSURE_REASON]: `Closure reason`,
    [k.CLOSURE]: `Closure`,
    [k.CLOUD_LIBRARY]: `Cloud Library`,
    [k.CLOUD_LIBRARY_FEATURE_DESCRIPTION]: `Allows you to import pre-made checklist templates published by AmpliFlow on the Templates page.`,
    [k.CLOUD_TEMPLATE_LIBRARY]: `Cloud Template Library`,
    [k.CM_CREATED]: `Competency Matrix created`,
    [k.CM_EDITOR_INFO]: `Can see all competency matrices, add new competency matrices, edit, delete, archive and unarchive existing competency matrices.`,
    [k.CM_EDITOR]: `Competency Matrix - Editor`,
    [k.CM_OWNER_INFO]: `Can see all competency matrices, add or import new competency matrices, edit, delete, archive and unarchive existing competency matrices.\nCan assign Competency Matrix permissions.`,
    [k.CM_OWNER]: `Competency Matrix - Owner`,
    [k.CM_USER_INFO]: `Can view, add, edit and archive their own competency matrices, as well for their direct reporting users.`,
    [k.CM_USER]: `Competency Matrix - User`,
    [k.CM_VIEWER_INFO]: `Can see all competency matrices.`,
    [k.CM_VIEWER]: `Competency Matrix - Viewer`,
    [k.COGNIT_GPT_API_KEY_IS_ACTIVE]: `Cognit GPT API key is active`,
    [k.COGNIT_GPT_PAGE_TITLE]: `Cognit GPT Settings`,
    [k.COLOR]: `Color`,
    [k.COMES_IN]: `Comes In`,
    [k.COMMENT_LEFT_BY]: `Comment left by`,
    [k.COMMENT]: `Comment`,
    [k.COMMENTS_PROBABILITY]: `Comments probability`,
    [k.COMMENTS]: `Comments`,
    [k.COMPANY_INDUSTRY_DESCRIPTION]: `Describe the industry or industries your company belongs to. AmpliFlow will use this to when creating tailor made content throughout the platform.`,
    [k.COMPANY_INDUSTRY_EXAMPLE]: `Example using Swedish SNI:\n62020 - Datakonsultverksamhet (Computer Consultancy Activities)\n70220 - Konsultverksamhet avseende företags organisation (Business and Other Management Consultancy Activities)`,
    [k.COMPANY_INDUSTRY]: `Company Industry`,
    [k.COMPANY_NAME_DESCRIPTION]: `The name used for everyday references to your company. For example: instead of "Dunder Mifflin Paper Company, Inc.," simply use "Dunder Mifflin."`,
    [k.COMPANY_NAME_EXAMPLE]: `Example: AmpliFlow`,
    [k.COMPANY_NAME]: `Company Name`,
    [k.COMPANY_OVERVIEW_DESCRIPTION]: `A detailed description of your company and what it does. Remember: The more descriptive you are, the more Ampliflow can enhance your experience.`,
    [k.COMPANY_OVERVIEW_EXAMPLE]: `Example: AmpliFlow streamlines ISO certification with an all-in-one IT platform for ISO 9001, 14001, 45001, and 27001 compliance. It contains 30+ management tools, reduces documentation, and simplifies processes.`,
    [k.COMPANY_OVERVIEW]: `Company Overview`,
    [k.COMPETENCIES_EDITOR_INFO]: `Can see all competencies, add new competencies, edit, delete, archive and unarchive existing competencies.`,
    [k.COMPETENCIES_EDITOR]: `Competencies - Editor`,
    [k.COMPETENCIES_FEATURE_DESCRIPTION]: `This feature enables management of employee competencies.`,
    [k.COMPETENCIES_OWNER_INFO]: `Can see all competencies, add or import new competencies, edit, delete, archive and unarchive existing competencies.\nCan assign Competencies permissions.`,
    [k.COMPETENCIES_OWNER]: `Competencies - Owner`,
    [k.COMPETENCIES_VIEWER_INFO]: `Can see all competencies.`,
    [k.COMPETENCIES_VIEWER]: `Competencies - Viewer`,
    [k.COMPETENCIES]: `Competencies`,
    [k.COMPETENCY_MATRIX_FEATURE_DESCRIPTION]: `The competency matrix helps you manage and track your team's skills. It identifies gaps, assesses current competencies, and aids in developing training plans. Regular updates ensure your team's skills stay aligned with your organizational goals.`,
    [k.COMPETENCY_MATRIX_PAGE_SUBTITLE]: `Here are all the competency matrix available in the system`,
    [k.COMPETENCY_MATRIX]: `Competency Matrix`,
    [k.COMPETENCY_PAGE_TITLE]: `Competencies`,
    [k.COMPETENCY_POSITION]: `Which positions uses this competency`,
    [k.COMPETENCY_TEAM]: `Which teams uses this competency`,
    [k.COMPETENCY]: `Competency`,
    [k.COMPLETE_THE_ACTIVITIES]: `Complete the activities`,
    [k.COMPLETE_THE]: `Complete the`,
    [k.COMPLETED_]: `completed`,
    [k.COMPLETED_A]: k.COMPLETED,
    [k.COMPLETED_ACTIVITIES]: `Completed activities`,
    [k.COMPLETED_AT]: `Completed at`,
    [k.COMPLETED_BY]: `Completed by`,
    [k.COMPLETED_COURSES]: `Completed Courses`,
    [k.COMPLETED_P_]: k.COMPLETED_,
    [k.COMPLETED_P]: k.COMPLETED,
    [k.COMPLETED]: `Completed`,
    [k.CONFIGURATION]: `Configuration`,
    [k.CONFIGURE_THIS_LIST]: `Configure This List`,
    [k.CONFIRM_FINALIZE]: `All activities are done. Do you want to finalize?`,
    [k.CONFIRM_NEW_PASSWORD]: `Confirm new password`,
    [k.CONFIRM]: `Confirm`,
    [k.CONTACT_DETAILS]: `Contact Details`,
    [k.CONTACTS]: `Contacts`,
    [k.COPIED]: `Copied!`,
    [k.COPY_A]: `Copy`,
    [k.COPY_MESSAGE]: `Copy`,
    [k.COPY_RESPONSE]: `Copy Response`,
    [k.COPY_STEP_MESSAGE]: `Do you want to copy this step and all its activities?`,
    [k.COPY_WITH_METADATA]: `Copy with metadata`,
    [k.COUNTRY]: `Country`,
    [k.COURSES]: `Courses`,
    [k.CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new checklist or try different filters/search keywords`,
    [k.CREATE_A_NEW_CONTACT_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new contact or try different filters/search keywords`,
    [k.CREATE_A_NEW_CUSTOMER_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new customer or try different filters/search keywords`,
    [k.CREATE_A_NEW_EQUIPMENT_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new equipment or try different filters/search keywords`,
    [k.CREATE_A_NEW_GOAL_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new goal or try different filters/search keywords`,
    [k.CREATE_A_NEW_IMPROVEMENT_FORM_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new improvement form or try different filters/search keywords`,
    [k.CREATE_A_NEW_KPI_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new KPI or try different filters/search keywords`,
    [k.CREATE_A_NEW_ONE_OR_ADD_ANY_EXISTING_GOAL]: `Create a new one or add any existing goal.`,
    [k.CREATE_A_NEW_PURCHASE_ORDER_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new purchase order or try different filters/search keywords`,
    [k.CREATE_A_NEW_SUPPLIER_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Create a new supplier or try different filters/search keywords`,
    [k.CREATE_A_NEW_TASK_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new task or try different filters/search keywords`,
    [k.CREATE_A_NEW_TEAM_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new team or try different filters/search keywords`,
    [k.CREATE_A_NEW_TEMPLATE_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new Template or try different filters/search keywords`,
    [k.CREATE_A_NEW_USER_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new user or try different filters/search keywords`,
    [k.CREATE_A_NEW_WORKSHEET_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Create a new worksheets or try different filters/search keywords`,
    [k.CREATE_A_SCHEDULE_FOR]: `Create a schedule for`,
    [k.CREATE_KPI]: `Create KPI`,
    [k.CREATE_NEW_STEP]: `Add step`,
    [k.CREATE_RECURRENCE_OF]: `Create Recurrence of`,
    [k.CREATE_RECURRING_CHECKLIST]: `Create Recurrance`,
    [k.CREATE_RUN_TRACK_IMPROVE]: `Create, Run, Track, Improve`,
    [k.CREATE]: `Create`,
    [k.CREATED_AT]: `Created At`,
    [k.CREATED_BY]: `Created By`,
    [k.CREATED]: `Created`,
    [k.CURRENT_AND_PREVIOUS_YEAR]: `Current and previous year`,
    [k.CURRENT_PASSWORD_MESSAGE]: `Only required if you want to change your password`,
    [k.CURRENT_PASSWORD]: `Current Password`,
    [k.CURRENT_POSITION]: `Current Position`,
    [k.CURRENT_VALUE_AT_DATE]: `Current value (at selected date)`,
    [k.CURRENT_VALUE]: `Current Value`,
    [k.CURRENTLY_THERE_ARE]: `Currently there are`,
    [k.CUSTOM_KPI]: `Custom KPI`,
    [k.CUSTOM_LIST_PERMISSION_DESCRIPTION]: `Here is the list of users and teams that have different types of access to this custom list. You can see individual users as circles with their initials and team names in a pill shape. You can also change permission settings by using the button on the side.`,
    [k.CUSTOM_LIST_ITEM_EDITOR]: `Editor`,
    [k.CUSTOM_LIST_ITEM_EDITOR_INFO]: `Can see all custom lists, add new custom lists, edit, delete, archive and unarchive existing custom lists.`,
    [k.CUSTOM_LIST_ITEM_OWNER]: `Owner`,
    [k.CUSTOM_LIST_ITEM_OWNER_INFO]: `Can see all custom lists, add or import new custom lists, edit, delete, archive and unarchive existing custom lists.\nCan assign Custom List permissions.`,
    [k.CUSTOM_LIST_ITEM_USER]: `User`,
    [k.CUSTOM_LIST_ITEM_USER_DISABLED_INFO]: `Not available since there are no User properties in the custom list with "Access Limiting" checked.`,
    [k.CUSTOM_LIST_ITEM_USER_INFO]: `Can view, add, edit, archive, and delete Custom Lists marked as "Access Limiting" that they have been granted permission to, as a user or manager to an authorized user.`,
    [k.CUSTOM_LIST_ITEM_VIEWER]: `Viewer`,
    [k.CUSTOM_LIST_ITEM_VIEWER_INFO]: `Can see all custom lists.`,
    [k.CUSTOM_LIST_OPTIONS_IN_CHECKLIST_WARNING]: `Users will be able to see all options in the checklist. But they will only be able to preview the Custom List Item if they have the correct permissions.`,
    [k.CUSTOM_LIST_PAGE_SUBTITLE]: `Manage custom lists available system-wide`,
    [k.CUSTOM_LIST_PAGE_TITLE]: `Custom Lists`,
    [k.CUSTOM_LIST]: `Custom list`,
    [k.CUSTOM_LISTS_FEATURE_DESCRIPTION]: `Enabling this feature enables you to create a customizable list with various properties. It is more like a custom database for your company. For example, you can create a customized list to manage all of your company branches or your customer contracts. You can even create lists that depend on other lists.`,
    [k.CUSTOM_LISTS]: `Custom Lists`,
    [k.CUSTOM_PROPERTIES]: `Custom Properties`,
    [k.CUSTOMER_DETAILS_SUBTITLE]: `View details and contacts of the selected customer`,
    [k.CUSTOMER_DETAILS]: `Customer Details`,
    [k.CUSTOMER_ID]: `CustomerID`,
    [k.CUSTOMER_INDUSTRY_PAGE_SUBTITLE]: `Here are all the customer industries available in the system`,
    [k.CUSTOMER_INDUSTRY_PAGE_TITLE]: `Customer Industry`,
    [k.CUSTOMER_LABEL]: `Customer label`,
    [k.CUSTOMER_NEEDS_EXPECTATIONS]: `Customer Needs and Expectations`,
    [k.CUSTOMER_PLACEHOLDER]: `Customer placeholder`,
    [k.CUSTOMER_REQUIREMENT_PAGE_SUBTITLE]: `Here are all the customer requirements available in the system`,
    [k.CUSTOMER_REQUIREMENT_PAGE_TITLE]: `Customer Requirements`,
    [k.CUSTOMER_REQUIREMENTS_COMMENT_PLACEHOLDER]: `Clarifications e.g. which subject area you have selected if you selected 'Other'`,
    [k.CUSTOMER_REQUIREMENTS_EDITOR_INFO]: `Can see all customer requirements, add new customer requirements, edit, delete, archive and unarchive existing customer requirements.`,
    [k.CUSTOMER_REQUIREMENTS_EDITOR]: `Customer Requirements - Editor`,
    [k.CUSTOMER_REQUIREMENTS_FEATURE_DESCRIPTION]: `Customer requirements can be managed using this feature.`,
    [k.CUSTOMER_REQUIREMENTS_OWNER_INFO]: `Can see all customer requirements, add or import new customer requirements, edit, delete, archive and unarchive existing customer requirements.\nCan assign Customer Requirements permissions.`,
    [k.CUSTOMER_REQUIREMENTS_OWNER]: `Customer Requirements - Owner`,
    [k.CUSTOMER_REQUIREMENTS_VIEWER_INFO]: `Can see all customer requirements.`,
    [k.CUSTOMER_REQUIREMENTS_VIEWER]: `Customer Requirements - Viewer`,
    [k.CUSTOMER_REQUIREMENTS]: `Customer requirements`,
    [k.CUSTOMER_SATISFACTION]: `Customer Satisfaction`,
    [k.CUSTOMER]: `Customer`,
    [k.CUSTOMERS_EDITOR_INFO]: `Can add new customers, create contacts, edit, delete, archive and unarchive existing customers and contacts.`,
    [k.CUSTOMERS_EDITOR]: `Customers - Editor`,
    [k.CUSTOMERS_FEATURE_DESCRIPTION]: `Effortlessly manage your customers with this feature. Streamline managing customers' business and contact-related information. This feature helps you maintain up-to-date records, improve customer relationships, and boost overall satisfaction.`,
    [k.CUSTOMERS_OWNER_INFO]: `Can add or import new customers, create contacts, edit, delete, archive and unarchive existing customers and contacts.\nCan assign Customers permissions.`,
    [k.CUSTOMERS_OWNER]: `Customers - Owner`,
    [k.CUSTOMERS_SUBTITLE]: `Here are all the customers available in the system`,
    [k.CUSTOMERS_VIEWER_INFO]: `No functionality added yet...`,
    [k.CUSTOMERS_VIEWER]: `Customers - Viewer`,
    [k.CUSTOMIZE_THRESHOLD_VALUES]: `Customize threshold values`,
    [k.D_AYS]: `d`,
    [k.DASHBOARD]: `Dashboard`,
    [k.DATA_SOURCE_NAME]: `Data source name`,
    [k.DATA_SOURCE_REQUIRED]: `Data source is required`,
    [k.DATA_SOURCE_TIP1]: `Select the data sources that will be used to track the fulfillment of the targets. You can choose from user entered data, improvement database, or existing KPIs as data sources.`,
    [k.DATA_SOURCE]: `Data source`,
    [k.DATA_WILL_BE_FILTERABLE_LIST]: `Data will be filterable in list view`,
    [k.DATA_WILL_BE_SHOWN_LIST]: `Data will be shown in list view`,
    [k.DATA_WILL_BE_SORTABLE_LIST]: `Data will be sortable in list view`,
    [k.DATASOURCE_KPI]: `Data source KPI`,
    [k.DATASOURCE_TYPE]: `Data source type`,
    [k.DATE_COMPLETED]: `Date completed`,
    [k.DATE_END_AFTER_START]: `Due date must be after Start date`,
    [k.DATE_EVALUATION]: `Date evaluation`,
    [k.DATE_OF_ADDITION]: `Date of addition`,
    [k.DATE_PRESETS]: `Date Presets`,
    [k.DATE_START_BEFORE_END]: `Start date must be before Due date`,
    [k.DATE]: `Date`,
    [k.DATE]: `Date`,
    [k.DAY_]: `day`,
    [k.DAY_S_]: `day(s)`,
    [k.DAYS_]: `days`,
    [k.DAYS]: `Days`,
    [k.DEADLINE]: `Deadline`,
    [k.DEC]: `Dec`,
    [k.DECEMBER]: `December`,
    [k.DECIMAL_PLACES_INVALID_NUMBER]: `Decimal places must be greater than or equal to 0`,
    [k.DECIMAL_PLACES]: `Decimal places`,
    [k.DECIMAL]: `Decimal`,
    [k.DECISION_POINT]: `Decision point`,
    [k.DEFAULT_IMPROVEMENT]: `Default Improvement`,
    [k.DEFAULT_PROCESS_ALERT_MESSAGE]: `To configure a default process, you must have at least one process within the system. Currently, you don’t have any processes. Click the button on the right to add a process, then return here to select the default process.`,
    [k.DEFAULT_PROCESS_DESCRIPTION]: `Improvements with selected 'Other/Not sure' in their impacted processes fields would be rolled up under the selected process.`,
    [k.DEFAULT_PROCESS_DESCRIPTION_2]: `Improvements that are reported on active checklists of checklist templates that are added to Process Steps will be visible on their respective parent nodes, Subprocesses and Processes. Those that are reported independently will become orphaned and roll up under the selected process.`,
    [k.DEFAULT_PROCESS]: `Default Process`,
    [k.DELETE_DRAFT]: `Delete draft`,
    [k.DELETE_IMPACT_GRADING_1]: `If you delete this impact area, it will immediately be unavailable. If already used anywhere in the system, it won't be available for further gradings.`,
    [k.DELETE_IMPACT_GRADING_2]: ` You can not restore a deleted impact area.`,
    [k.DELETE_IMPROVEMENT_CONFIRM_TITLE]: `Delete this improvement?`,
    [k.DELETE_IMPROVEMENT_PERMANENTLY_MESSAGE]: `If you delete this improvement, it will be deleted permanently from the system. You can't recover a deleted improvement`,
    [k.DELETE_POSITION_1]: `If you delete this position, it will immediately be unavailable.`,
    [k.DELETE_POSITION_2]: `You cannot restore a deleted position.`,
    [k.DELETE_PURCHASE_ORDER_MESSAGE_1]: `If you delete this purchase order, it will be deleted permanently from the system. You can't recover a deleted purchase order`,
    [k.DELETE_PURCHASE_ORDER_MESSAGE_2]: `Are you really sure you want delete this purchase order and all of its information?`,
    [k.DELETE_TEAM_1]: `If you delete this team, it will immediately be unavailable.`,
    [k.DELETE_TEAM_2]: `You cannot restore a deleted team.`,
    [k.DELETE_THESE_NOTES]: `Delete these notes?`,
    [k.DELETE_THIS_BARCODE_MESSAGE_LINE1]: `If you delete this barcode you need to rescan it.`,
    [k.DELETE_THIS_BARCODE_MESSAGE_LINE2]: `Are you sure you want to remove it?`,
    [k.DELETE_THIS_BARCODE_QUESTION]: `Delete this barcode?`,
    [k.DELETE_THIS_GENERATED_BARCODE_MESSAGE_LINE1]: `If you delete this barcode you can generate it later.`,
    [k.DELETE_THIS_GOAL]: `Delete this goal?`,
    [k.DELETE_THIS_KPI_LINE_1]: `If you delete this KPI, it will not be available in the system anymore. Data will not be available for reporting.`,
    [k.DELETE_THIS_KPI_LINE_2]: `You can't recover a deleted KPI.  Are you sure you want to delete this KPI?`,
    [k.DELETE_THIS_KPI]: `Delete this KPI?`,
    [k.DELETE_THIS_MEASUREMENT]: `Delete this measurement?`,
    [k.DELETE_THIS_PROGRESS_VALUE]: `Delete this progress value?`,
    [k.DELETE_THIS_TASK_LIST]: `Delete this task list?`,
    [k.DELETE_THIS_TASK]: `Delete this task?`,
    [k.DELETE_THIS_TEMPLATE]: `Delete this template?`,
    [k.DELETE]: `Delete`,
    [k.DELETED]: `Deleted`,
    [k.DELETING_A_RECURRENCE_WILL1]: `Deleting a recurring checklist will`,
    [k.DELETING_A_RECURRENCE_WILL2]: `this recurrence and all the checklists that was created by it, including all of their`,
    [k.DELETING_A_RUN_WILL]: `Deleting a run will`,
    [k.DESCRIBE_IMPOVEMENT_HERE]: `Describe improvement here`,
    [k.DESCRIPTION_PHOTO]: `Description photo`,
    [k.DESCRIPTION]: `Description`,
    [k.DESCRIPTIVE_RECURRANCE_NAME]: `Give your recurrance a descriptive title`,
    [k.DETAILS]: `Details`,
    [k.DEVIATION_MANAGER]: `Deviation manager`,
    [k.DEVIATION_MANAGERS]: `Deviation managers`,
    [k.DIRECT_FINANCIAL_CONSEQUENCES_PERCENT]: `Direct Financial Consequences %`,
    [k.DIRECT_FINANCIAL_CONSEQUENCES]: `Direct Financial Consequences`,
    [k.DIRECT_REPORTS]: `Direct Reports`,
    [k.DISABLE_FEATURE_CONFIRM_MESSAGE]: `Disabling this feature will make all the data added to it unavailable everywhere in the system, including other features where they are used. Data will still be available for reporting.`,
    [k.DISABLE_USER_LINE_1]: `Disabled user won't be able to log in and user's data will no longer be available to use anywhere in the system`,
    [k.DISABLE_USER_TITLE]: `Disable this user?`,
    [k.DISABLE]: `Disable`,
    [k.DISABLED_USER]: `Disabled user`,
    [k.DISABLED_WHILE_EDITING]: `Disabled while editing`,
    [k.DISABLED]: `Disabled`,
    [k.DISCOVERED_IN_PROCESS_FILTER]: `Discovered in process filter`,
    [k.DISCOVERED_IN_T]: `<strong>Discovered</strong> in`,
    [k.DISCOVERED_IN]: `Discovered in`,
    [k.DISMISS]: `Dismiss`,
    [k.DO_WE_MEET_THE_REQUIREMENT]: `Do we meet the requirement?`,
    [k.DO_WORK_THE_RIGHT_WAY_EVERY_TIME]: `Do work the right way. Every time`,
    [k.DO_YOU_WANT_TO_FINALIZE_THIS_STEP]: `When a step is finalized it will prevent other users from making future modifications`,
    [k.DO_YOU_WANT_TO_PROCEED]: `Do you want to proceed?`,
    [k.DO_YOU_WANT_TO_UN_ARCHIVE_THIS_EQUIPMENT]: `Do you want to un-archive this equipment`,
    [k.DO_YOU_WANT_TO_UN_ARCHIVE_THIS_TEAM]: `Do you want to un-archive this team?`,
    [k.DO_YOU_WANT_TO_UPGRADE_EXISTING_CHECKLISTS]: `Do you want to upgrade existing checklists to the new version?`,
    [k.DOES_NOT_EXIST_OR_NO_PERMISSION]: `Item does not exist or you don't have permission to view it`,
    [k.DONE_EARLIER]: `Finalized Earlier`,
    [k.DONE_WITH_THIS_STEP]: `Done with this step?`,
    [k.DONE]: `Done`,
    [k.DONT_UPGRADE]: `Don’t upgrade`,
    [k.DOWNLOAD_GENERATED_APP_PACKAGE]: `Download generated app package`,
    [k.DOWNLOAD_GENERATED_APP_PACKAGE_DESCRIPTION]: `Generate a Microsoft Teams app package that IT can upload to your Microsoft Teams environment.`,
    [k.DRAFT_SAVED]: `Draft saved`,
    [k.DRAFT]: `Draft`,
    [k.DUE_DATE]: `Due date`,
    [k.DUE_IN]: `Due in`,
    [k.DUE_ON]: `Is due on`,
    [k.DUE]: `Due`,
    [k.DURATION]: `Duration`,
    [k.EACH_ESTIMATED_TO_TAKE]: `Each estimated to take`,
    [k.EDIT_CHECKLIST]: `Edit Checklist`,
    [k.EDIT_CUSTOM_LIST]: `Edit Custom List`,
    [k.EDIT_CUSTOMER_CONTACT]: `Edit Customer Contact`,
    [k.EDIT_CUSTOMER_SUBTITLE]: `You can edit customer from here`,
    [k.EDIT_CUSTOMER]: `Edit Customer`,
    [k.EDIT_DATA_SOURCE]: `Edit Data Source`,
    [k.EDIT_EQUIPMENT_SUBTITLE]: `You can edit equipment from here`,
    [k.EDIT_EQUIPMENT]: `Edit equipment`,
    [k.EDIT_GOAL]: `Edit Goal`,
    [k.EDIT_IMPROVEMENT_DRAFT]: `Edit Improvement Draft`,
    [k.EDIT_KPI]: `Edit KPI`,
    [k.EDIT_LOCAL_STEP]: `Edit Step`,
    [k.EDIT_MEASUREMENT]: `Edit Measurement`,
    [k.EDIT_PERMISSION_SETTINGS]: `Edit Permission Settings`,
    [k.EDIT_POSITION]: `Edit Position`,
    [k.EDIT_PURCHASE_ORDER_SUBTITLE]: `Edit purchase order from here`,
    [k.EDIT_PURCHASE_ORDER]: `Edit Purchase Order`,
    [k.EDIT_RECURRING_CHECKLIST]: `Edit Recurring Checklist`,
    [k.EDIT_STEP_DRAFT]: `Edit Step Draft`,
    [k.EDIT_SUB_GOAL]: `Edit Sub Goal`,
    [k.EDIT_SUPPLIER_SUBTITLE]: `You can edit supplier from here`,
    [k.EDIT_SUPPLIER]: `Edit Supplier`,
    [k.EDIT_TARGETS_FOR_THIS_KPI]: `Edit targets for this KPI`,
    [k.EDIT_TEAM_SUBTITLE]: `You can edit teams from here`,
    [k.EDIT_TEAM]: `Edit team`,
    [k.EDIT_TEMPLATE_DRAFT]: `Edit Template Draft`,
    [k.EDIT_TEMPLATE]: `Edit Template`,
    [k.EDIT_USER_SUBTITLE]: `You can edit user from here`,
    [k.EDIT_USER]: `Edit user`,
    [k.EDIT]: `Edit`,
    [k.EDITOR]: `Editor`,
    [k.EMAIL_ADDRESS]: `Email address`,
    [k.EMAIL_PLACEHOLDER]: `user@email.com`,
    [k.EMAIL]: `E-mail`,
    [k.EMPTY_ACTIVE_CHECKLIST_MESSAGE]: `Your active checklists have all been completed. Take a moment to celebrate!`,
    [k.EMPTY_BLOCKED_CHECKLIST_MESSAGE]: `No blocked checklists found. Keep up the good work!`,
    [k.EMPTY_FIELD]: `Empty field`,
    [k.EMPTY_FINALIZED_CHECKLIST_MESSAGE]: `You don't have any finalized checklists right now. Finalized checklists will appear here.`,
    [k.EMPTY_IMPACT_GRADING_MESSAGE]: `No impact grading areas added yet. Click the button below to add one.`,
    [k.EMPTY_STOPPED_CHECKLIST_MESSAGE]: `No stopped checklists found. You're making great progress!`,
    [k.ENABLE_THIS_FEATURE]: `Enable this feature`,
    [k.ENABLE]: `Enable`,
    [k.ENABLED]: `Enabled`,
    [k.END_AFTER_START]: `End Date should be after Start Date`,
    [k.END_DATE_TIME]: `Due`,
    [k.END_DATE]: `End date`,
    [k.END]: `End`,
    [k.ENDING_ON_]: `ending on`,
    [k.ENDING_ON]: `Ending on`,
    [k.ENGLISH_NAME]: `English name`,
    [k.ENGLISH]: `English`,
    [k.ENHANCEMENT]: `Enhancement`,
    [k.ENTER_HERE]: `Enter Here`,
    [k.ENTER_NAME]: `Enter name`,
    [k.ENTER_NUMBER]: `Enter number`,
    [k.ENTRA_TENANT_ID]: `Entra Tenant ID`,
    [k.ENTRA_TENANT_ID_DESCRIPTION]: `Entra tenant ID is the unique identifier for your organization in Entra. SSO (Single Sign-On) uses this ID to verify your organization during login, allowing users to seamlessly access Ampliflow without re-entering credentials.`,
    [k.ENVIRONMENT_ASPECTS_EDITOR_INFO]: `Can see all environment aspects, add new environment aspects, edit, delete, archive and unarchive existing environment aspects.`,
    [k.ENVIRONMENT_ASPECTS_EDITOR]: `Environment Aspects - Editor`,
    [k.ENVIRONMENT_ASPECTS_OWNER_INFO]: `Can see all environment aspects, add or import new environment aspects, edit, delete, archive and unarchive existing environment aspects.\nCan assign Environment Aspects permissions.`,
    [k.ENVIRONMENT_ASPECTS_OWNER]: `Environment Aspects - Owner`,
    [k.ENVIRONMENT_ASPECTS_VIEWER_INFO]: `Can see all environment aspects.`,
    [k.ENVIRONMENT_ASPECTS_VIEWER]: `Environment Aspects - Viewer`,
    [k.ENVIRONMENT]: `Environment`,
    [k.ENVIRONMENTAL_AREA]: `Environmental Area`,
    [k.ENVIRONMENTAL_ASPECTS_FEATURE_DESCRIPTION]: `This feature enables management of company Environmental Aspects.`,
    [k.ENVIRONMENTAL_ASPECTS_NAME]: `Environmental Aspect`,
    [k.ENVIRONMENTAL_ASPECTS_PAGE_SUBTITLE]: `Here are all the environmental aspects available in the system`,
    [k.ENVIRONMENTAL_ASPECTS_PAGE_TITLE]: `Environmental Aspects`,
    [k.ENVIRONMENTAL_ASPECTS_RELATED_QUESTIONS]: `Environmental Aspect Related Questions`,
    [k.ENVIRONMENTAL_IMPACT]: `Environmental impact neg/pos?`,
    [k.ENVIRONMENTAL_TARGET]: `Environmental Targets`,
    [k.EQUAL]: `Equal`,
    [k.EQUIPMENT_DETAILS_SUBTITLE]: `View details of the selected equipment`,
    [k.EQUIPMENT_DETAILS]: `Equipment Details`,
    [k.EQUIPMENT_EDITOR_INFO]: `Can see all equipments, add new equipments, edit, delete, archive and unarchive existing equipments.`,
    [k.EQUIPMENT_EDITOR]: `Equipment - Editor`,
    [k.EQUIPMENT_FEATURE_DESCRIPTION]: `With this feature enabled, you can manage and keep track of your company equipments.`,
    [k.EQUIPMENT_OWNER_INFO]: `Can see all equipments, add or import new equipments, edit, delete, archive and unarchive existing equipments.\nCan assign Equipment permissions.`,
    [k.EQUIPMENT_OWNER]: `Equipment - Owner`,
    [k.EQUIPMENT_REQUIRED_TO_COMPLETE_STEP]: `Equipment required to complete step`,
    [k.EQUIPMENT_SUBTITLE]: `Here are all the equipment available in the system`,
    [k.EQUIPMENT_VIEWER_INFO]: `Can see all equipments.`,
    [k.EQUIPMENT_VIEWER]: `Equipment - Viewer`,
    [k.EQUIPMENT]: `Equipment`,
    [k.EQUIPMENTS]: `Equipments`,
    [k.ERROR_EMPTY_API_KEY]: `Error: API key cannot be empty`,
    [k.ERROR_POSITION_IN_USE_BY_COMPETENCIES]: `Position is in use by competencies`,
    [k.ERROR_POSITION_IN_USE_BY_USERS]: `Position is in use by users`,
    [k.ERROR_POSITION_NOT_ARCHIVED]: `Position is not archived`,
    [k.ERROR]: `Error`,
    [k.EST_DELIVERY]: `Est. Delivery`,
    [k.ESTIMATED_PROBABILITY_REDUCTION]: `Estimated probability reduction`,
    [k.EVALUATION_DATE]: `Evaluation Date`,
    [k.EVALUATION]: `Evaluation`,
    [k.EVENT_DETAILS]: `Event Details`,
    [k.EVERY_]: `every`,
    [k.EVERY]: `Every`,
    [k.EVERYONE]: `Everyone`,
    [k.EXAMPLES_AVAILABILITY]: `Examples Availability`,
    [k.EXAMPLES_CONFIDENTIALITY]: `Examples Confidentiality`,
    [k.EXAMPLES_DELIVERY_OF_PRODUCT]: `Examples Delivery of Product`,
    [k.EXAMPLES_DELIVERY_OF_SERVICES]: `Examples Delivery of Services`,
    [k.EXAMPLES_DIRECT_FINANCIAL_CONSEQUENCES]: `Examples Direct Financial Consequences`,
    [k.EXAMPLES_ENVIRONMENT]: `Examples Environment`,
    [k.EXAMPLES_HEALTH_SAFETY]: `Examples Health & Safety`,
    [k.EXAMPLES_INDIRECT_FINANCIAL_IMPACT]: `Examples Indirect Financial Impact`,
    [k.EXAMPLES_INTEGRITY]: `Examples Integrity`,
    [k.EXAMPLES_MEDIA_ATTENTION]: `Examples Media Attention`,
    [k.EXAMPLES_PRODUCT_FUNCTIONALITY]: `Examples Product Functionality`,
    [k.EXAMPLES_PRODUCT_SAFETY]: `Examples Product Safety`,
    [k.EXPECTED_ABOVE_OR_EQUAL]: ` expected to be above or equal to `,
    [k.EXPECTED_ABOVE]: ` expected to be above `,
    [k.EXPECTED_BELOW_OR_EQUAL]: ` expected to be below or equal to `,
    [k.EXPECTED_BELOW]: ` expected to be below `,
    [k.BETA]: `Beta`,
    [k.EXPECTED_BETWEEN]: ` expected to be between `,
    [k.EXPIRED]: `Expired`,
    [k.EXPORT_TO_EXCEL]: `Export to Excel`,
    [k.EXTERNAL_ID]: `External ID`,
    [k.FAILED_MESSAGE]: `Failed`,
    [k.FEATURE]: `Feature`,
    [k.FEATURES_AND_PERRMISSIONS]: `Features and Permissions`,
    [k.FEB]: `Feb`,
    [k.FEBRUARY]: `February`,
    [k.FILE]: `File`,
    [k.FILES_LINKS]: `Files and links`,
    [k.FILTER_ALL_WHICH_CONTAINS]: `Filter all which contains`,
    [k.FILTER_BY_DISCOVERED_IN_PROCESS]: `Filter by discovered in process`,
    [k.FILTER_BY_DUE_DATE]: `Filter by due date`,
    [k.FILTER_BY_ID_UNCHECK_TO_SHOW_ALL]: `Filter by ID (uncheck to show all)`,
    [k.FILTER_BY_IMPACT]: `Filter by impact`,
    [k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT_DESCRIPTION]: `All the improvement description containing these words will be filtered. \nUse comma for multiple words.`,
    [k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT]: `Filter by description contains text`,
    [k.FILTER_BY_IMPROVEMENT_FORM]: `Filter by improvement form`,
    [k.FILTER_BY_IMPROVEMENT_IMPACT_MATRIX_GRADE_IS_HIGHER_THAN]: `Filter by improvement impact matrix grade is higher than`,
    [k.FILTER_BY_IMPROVEMENT_REPORTED_BY_MEMBER_IN_TEAM]: `Filter by improvement reported by member in team`,
    [k.FILTER_BY_ORIGINATED_IN_PROCESS]: `Filter by originated in process`,
    [k.FILTER_BY_POSITION]: `Filter by position`,
    [k.FILTER_BY_RESPONSIBLE]: `Filter by responsible`,
    [k.FILTER_BY_ROLE]: `Filter by role`,
    [k.FILTER_BY_START_DATE]: `Filter by start date`,
    [k.FILTER_BY_STATUS]: `Filter by status`,
    [k.FILTER_BY_TEAMS]: `Filter by teams`,
    [k.FILTER_BY_TEMPLATES]: `Filter by templates`,
    [k.FILTER]: `Filter`,
    [k.FILTERABLE]: `Filterable`,
    [k.FINALIZE_NOTICE]: `Finalize this step to lock it from future modifications and move on to the next one`,
    [k.FINALIZE_QUESTION]: `Do you still want to finalize this step? This will prohibit future modifications of the step`,
    [k.FINALIZE]: `Finalize`,
    [k.FINALIZED_AND_RUNNING]: `Finalized and running`,
    [k.FINALIZED_BY_MISTAKE]: `Finalized by mistake`,
    [k.FINALIZED_BY]: `Finalized by`,
    [k.FINALIZED_CHECKLISTS]: `Finalized checklists`,
    [k.FINALIZED_L]: `finalized`,
    [k.FINALIZED_P]: `Finalized`,
    [k.FINALIZED_TODAY]: `Finalized Today`,
    [k.FINALIZED]: `Finalized`,
    [k.FIRST_]: `first`,
    [k.FIRST_CHECKLIST]: `First checklist`,
    [k.FIRST]: `First`,
    [k.FISCAL_YEAR_CONFIG_DESCRIPTION]: `Control the date range displayed for KPIs (doesn't affect calculations). Choose your company's fiscal year start month (default: January). Changes apply immediately after saving for all users.`,
    [k.FISCAL_YEAR_CONFIG]: `Fiscal Year Config`,
    [k.FIT_CONTENT]: `Fit Content`,
    [k.FOR_]: `for`,
    [k.FOR_IT_AND_DEVELOPERS]: `For IT & Developers`,
    [k.FORM_ERRORS]: `Form Errors`,
    [k.FORM_INFORMATION]: `Form Information`,
    [k.FORM_NAME]: `Form Name`,
    [k.FORM]: `Form`,
    [k.FORMULA_CANNOT_END_WITH_OPERATOR]: `Formula cannot end with an operator`,
    [k.FORMULA_CANNOT_START_WITH_OPERATOR]: `Formula cannot start with an operator`,
    [k.FORMULA_EXPRESSION]: `Formula Expression`,
    [k.FORMULA_ITEM]: `Formula Item`,
    [k.FORMULA_LIBRARY]: `Formula Library`,
    [k.FORMULA_TOO_LONG]: `Formula is too long`,
    [k.FORMULA_VARIABLES]: `Formula Variables`,
    [k.FORMULA]: `Formula`,
    [k.FOURTH_]: `fourth`,
    [k.FOURTH]: `Fourth`,
    [k.FRIDAY]: `Friday`,
    [k.FROM_]: `from`,
    [k.FROM_CLOUD_LIBRARY]: `From Cloud Library`,
    [k.FROM]: `From`,
    [k.FUTURE_DATE]: `Future date`,
    [k.GENERAL]: `General`,
    [k.GENERATE_APP_PACKAGE]: `Generate app package`,
    [k.GENERATE_BARCODE]: `Generate Barcode`,
    [k.GENERATE_PAYLOAD]: `Generate Payload`,
    [k.GEOGRAPHICALLY_LINKED_PLACEHOLDER]: `Country, province or city`,
    [k.GEOGRAPHICALLY_LINKED]: `Geographically linked`,
    [k.GO_TO]: `Go to`,
    [k.GOAL_ARCHIVED]: `Goal archived`,
    [k.GOAL_CREATED]: `Goal created`,
    [k.GOAL_DELETED]: `Goal deleted`,
    [k.GOAL_SAVED]: `Goal saved`,
    [k.GOAL_UNARCHIVED]: `Goal unarchived`,
    [k.GOAL]: `Goal`,
    [k.GOAL]: `Goal`,
    [k.GOALS_EDITOR_INFO]: `Can see all goals and measurements, add new goals, edit, delete, archive and unarchive existing goals and measurements.`,
    [k.GOALS_EDITOR]: `Goals - Editor`,
    [k.GOALS_FEATURE_DESCRIPTION]: `This feature enables management of company goals.`,
    [k.GOALS_OWNER_INFO]: `Have full access to the goals feature. Can see all goals and measurements, add new goals and measurements, edit, delete, archive and unarchive existing goals and measurements.\nCan assign Goals permissions.`,
    [k.GOALS_OWNER]: `Goals - Owner`,
    [k.GOALS_PAGE_SUBTITLE]: `Track and manage goals here`,
    [k.GOALS_PAGE_TITLE]: `Goals`,
    [k.GOALS_USER_INFO]: `Can see goals and measurements they are responsible for or involved in. Can edit, archive and unarchive goals and measurements they are responsible for.`,
    [k.GOALS_USER]: `Goals - User`,
    [k.GREEN]: `Green`,
    [k.GROUP_BY]: `Group by`,
    [k.GUIDANCE]: `Guidance`,
    [k.H_OURS]: `h`,
    [k.HAVE_BEEN_]: `have been`,
    [k.HAVE_BEEN_COMPLETED]: `Have been completed`,
    [k.HAVE_BEEN]: `Have been`,
    [k.HEALTH_SAFETY]: `Health & Safety`,
    [k.HELP_AND_SUPPORT]: `Help & Support`,
    [k.HIDE_LIST]: `Hide list`,
    [k.HIGHLIGHTED_RISK]: `Highlighted risk`,
    [k.HISTORY]: `History`,
    [k.HOME_]: `home`,
    [k.HOME_TITLE]: `AmpliFlow`,
    [k.HOME]: `Home`,
    [k.HOURS]: `Hours`,
    [k.HOW_LONG_DOES_IT_TAKE_TO_COMPLETE_1_CHECKLIST]: `How long does it take to complete 1 checklist?`,
    [k.HOW_WE_ARE_AFFECTED_BY_THIS_LEGISLATION]: `How we are affected by this legislation`,
    [k.HOW_WE_MEET_THIS_LEGISLATION]: `How we meet this legislation`,
    [k.HOW_WOULD_YOU_LIKE_RUNS_TO_REPEAT]: `How would you like runs to repeat?`,
    [k.HYPERLINK]: `Hyperlink`,
    [k.I_AGREE_TO_THE]: `I agree to the`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_AREA]: `I understand the consequences, delete area`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_POSITION]: `I understand the consequences, delete position`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_TEAM]: `I understand the consequences, delete team`,
    [k.ICONS]: `Icons`,
    [k.ID_NUMBER]: `Id Number`,
    [k.ID]: `ID`,
    [k.IDENTIFIED_RISKS]: `Identified Risks`,
    [k.IDENTIFIED_TARGETS]: `Identified Targets`,
    [k.IF_YOU_CANCEL_YOU_WILL_LOSE_THE_CHANGES_YOU_JUST_MADE]: `If you cancel you will lose the changes you just made`,
    [k.IF_YOU_WANT_TO_STOP_ALL_SCHEDULED_CHECKLISTS_YOU_SET_THE_NEW_END_DATE_BEFORE]: `If you want to stop all scheduled checklists you set the new end date before`,
    [k.IMPACT_CLASSIFICATION]: `Impact Classification`,
    [k.IMPACT_GRADE]: `Impact Grade`,
    [k.IMPACT_GRADING_PAGE_SUBTITLE]: `Here are all the impact gradings available in the system`,
    [k.IMPACT_GRADING_PAGE_TITLE]: `Impact Gradings`,
    [k.IMPACT_GRADINGS_EDITOR_INFO]: `Can see all impact gradings, add new impact gradings, edit, delete, archive and unarchive existing impact gradings.`,
    [k.IMPACT_GRADINGS_EDITOR]: `Impact Gradings - Editor`,
    [k.IMPACT_GRADINGS_FEATURE_DESCRIPTION]: `This feature allows users to customize and manage the impact gradings. These gradings can be used in improvements and goals and operational risk analysis.`,
    [k.IMPACT_GRADINGS_OWNER_INFO]: `Can see all impact gradings, add or import new impact gradings, edit, delete, archive and unarchive existing impact gradings.\n Can assign Impact Gradings permissions.`,
    [k.IMPACT_GRADINGS_OWNER]: `Impact Gradings - Owner`,
    [k.IMPACT_GRADINGS_VIEWER_INFO]: `Can see all impact gradings.`,
    [k.IMPACT_GRADINGS_VIEWER]: `Impact Gradings - Viewer`,
    [k.IMPACT_RATING]: `Impact Rating`,
    [k.IMPACT_SCORE1_LABEL]: `1 - Very Low`,
    [k.IMPACT_SCORE2_LABEL]: `2 - Low`,
    [k.IMPACT_SCORE3_LABEL]: `3 - Moderate`,
    [k.IMPACT_SCORE4_LABEL]: `4 - High`,
    [k.IMPACT_SCORE5_LABEL]: `5 - Very High`,
    [k.IMPACT]: `Impact`,
    [k.IMPACTED_AREA]: `Impacted area`,
    [k.IMPACTED_AREAS]: `Impacted areas`,
    [k.IMPACTED_PROCESS_DISCOVERED]: `Impacted process: Discovered`,
    [k.IMPACTED_PROCESS_ORIGINATED]: `Impacted process: Originated`,
    [k.IMPACTED_PROCESSES]: `Impacted processes`,
    [k.IMPORT_FROM_EXCEL_SUCCESS_MESSAGE_WITH_AMOUNT]: `Imported {{amount}} record(s)!`,
    [k.IMPORT_TEMPLATE_FROM_CLOUD]: `Import Templates from Cloud Library`,
    [k.IMPORT]: `Import`,
    [k.IMPORTANT_ACTIVITIES]: `Important Activities`,
    [k.IMPORTED]: `Imported`,
    [k.IMPROVEMENT_CLOSED_WITH_REASON]: `Improvement closed with reason`,
    [k.IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER]: `Added as involved to all improvements of form`,
    [k.IMPROVEMENT_DASHBOARD_FEATURE_DESCRIPTION]: `Ampliflow offers an informative analytics dashboard for improvements. You can analyze reported improvements by form, impact, impacted areas, status, and more.`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACT]: `Improvements by impact`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_AREA]: `Improvements by impacted area`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_PROCESS]: `Improvements by impacted process`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPROVEMENT_FORM]: `Improvements by improvement form`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_STATUS]: `Improvements by status`,
    [k.IMPROVEMENT_DASHBOARD_OWNER_INFO]: `Can see improvement dashboard.\nCan assign Improvement Dashboard permissions.`,
    [k.IMPROVEMENT_DASHBOARD_OWNER]: `Improvement Dashboard - Owner`,
    [k.IMPROVEMENT_DASHBOARD_PAGE_LABEL]: `Improvement Dashboard`,
    [k.IMPROVEMENT_DASHBOARD_PAGE_SUBHEADING]: `Analytics for improvements - all data in UTC timezone`,
    [k.IMPROVEMENT_DASHBOARD_VIEWER_INFO]: `Can see improvement dashboard.`,
    [k.IMPROVEMENT_DASHBOARD_VIEWER]: `Improvement Dashboard - Viewer`,
    [k.IMPROVEMENT_DASHBOARD]: `Improvement Dashboard`,
    [k.IMPROVEMENT_DATA]: `Improvement data`,
    [k.IMPROVEMENT_DETAILS_ACTIVITY_IN_STEP]: `Activity in step`,
    [k.IMPROVEMENT_DETAILS_IMPACTED_TEMPLATES]: `Impacted Templates`,
    [k.IMPROVEMENT_DETAILS_REPORTED_FROM]: `Reported from`,
    [k.IMPROVEMENT_DETAILS_SCHEDULE]: `Recurring checklist`,
    [k.IMPROVEMENT_DETAILS_STEP]: `Step`,
    [k.IMPROVEMENT_DISCOVERED_IN_T]: `Improvement was <strong>discovered</strong> in`,
    [k.IMPROVEMENT_FORM_DETAILS]: `Improvement form details`,
    [k.IMPROVEMENT_FORM_FILTER]: `Improvement form filter`,
    [k.IMPROVEMENT_FORM]: `Improvement form`,
    [k.IMPROVEMENT_IMPACT_FILTER]: `Improvement impact filter`,
    [k.IMPROVEMENT_KPI]: `Improvement KPI`,
    [k.IMPROVEMENT_LEFT_BY]: `Improvement left by`,
    [k.IMPROVEMENT_ORIGINATED_IN_T]: `Improvement <strong>originated</strong> in`,
    [k.IMPROVEMENT_REGISTERED]: `Improvement registered!`,
    [k.IMPROVEMENT_STATUS_FILTER]: `Improvement status filter`,
    [k.IMPROVEMENT_STATUS]: `Status`,
    [k.IMPROVEMENT_STEPS]: `Improvement Steps`,
    [k.IMPROVEMENT]: `Improvement`,
    [k.IMPROVEMENTS_FORMS_PAGE_SUBTITLE]: `All improvement forms available to the company`,
    [k.IMPROVEMENTS_PAGE_LABEL]: `Improvements you have the right to see are listed here.`,
    [k.IMPROVEMENTS_REPORTED_TO_AN_ACTIVITY]: `Improvements reported to an activity`,
    [k.IMPROVEMENTS]: `Improvements`,
    [k.IN_]: `in`,
    [k.IN_PROGRESS]: `In Progress`,
    [k.IN_TOTAL_]: `in total`,
    [k.INBOX]: `Inbox`,
    [k.INDIRECT_FINANCIAL_IMPACT_PERCENT]: `Indirect Financial Impact %`,
    [k.INDIRECT_FINANCIAL_IMPACT]: `Indirect Financial Impact`,
    [k.INDUSTRY_RELEVANT]: `Industry relevant?`,
    [k.INDUSTRY]: `Industry`,
    [k.INFO]: `Info`,
    [k.INFORMATION_SECURITY_AVAILABILITY]: `Information Security - Availability`,
    [k.INFORMATION_SECURITY_CONFIDENTIALITY]: `Information Security - Confidentiality`,
    [k.INFORMATION_SECURITY_INTEGRITY]: `Information Security - Integrity`,
    [k.INFORMATION]: `Information`,
    [k.INPUT_FROM]: `Input from`,
    [k.INPUTS]: `Input(s)`,
    [k.INTERNAL_ID]: `Internal ID`,
    [k.INVALID_BARCODE]: `Invalid Barcode`,
    [k.INVALID_CREDENTIALS]: `Invalid credentials`,
    [k.INVALID_FIELDS]: `Invalid inputs in form`,
    [k.INVALID_FUTURE_USER_POSITION_DATES]: `Cannot assign a position with dates set in the future.`,
    [k.INVALID_OVERLAPPING_USER_POSITION_DATES]: `Cannot assign the same position with overlapping dates.`,
    [k.INVALID_URL]: `Invalid URL`,
    [k.INVALID_USER_POSITION_DATES]: `Cannot assign the position with the start date after the end date.`,
    [k.INVALID_VARIABLE]: `Invalid variable`,
    [k.INVALID]: `Invalid`,
    [k.INVOICING]: `Invoicing`,
    [k.INVOLVED_TEAMS]: `Involved Team(s)`,
    [k.INVOLVED_USERS_HEADER_TOOLTIP]: `Changing this will not update existing improvements where involved has been changed manually by users.`,
    [k.INVOLVED_USERS_TOOLTIP]: `When a new improvement is reported, selected users will get an email notification.`,
    [k.INVOLVED_USERS]: `Involved User(s)`,
    [k.INVOLVED_USERS]: `Involved User(s)`,
    [k.INVOLVED]: `Involved`,
    [k.INVOLVEMENT]: `Involvement`,
    [k.IS_]: `is`,
    [k.IS_DEFAULT_IMPROVEMENT_STEP]: `Is Default Improvement?`,
    [k.IS_KEY_ACTIVITY]: `This is a key activity`,
    [k.IS_PRIMARY_CONTACT]: `Is Primary Contact`,
    [k.IS_PRIMARY]: `Is Primary`,
    [k.IS_USER_ROLE]: `User role`,
    [k.IT_AND_DEV]: `IT & Dev`,
    [k.IT_CONTAINS]: `It contains`,
    [k.IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED]: `It seems that not all required activities have been completed.\r\nComplete those activities and try submitting again.`,
    [k.IT_WILL_HAVE]: `It will have`,
    [k.ITEM_CATALOG_SUBTITLE]: `Here are all the items available in the system`,
    [k.ITEM_CATALOG]: `Item Catalog`,
    [k.ITEM_LABEL]: `Item label`,
    [k.ITEM_PLACEHOLDER]: `Item placeholder`,
    [k.ITEM_TYPE_NOT_PROVIDED]: `Item type not provided`,
    [k.ITEM_TYPE]: `Item Type`,
    [k.ITEM_UNIT]: `Item Unit`,
    [k.ITEM]: `Item`,
    [k.ITEMS_EDITOR_INFO]: `Can see all items, add new items, create suppliers, edit, delete, archive and unarchive existing items.`,
    [k.ITEMS_EDITOR]: `Items - Editor`,
    [k.ITEMS_FEATURE_DESCRIPTION]: `This feature enables management of company items.`,
    [k.ITEMS_OWNER_INFO]: `Can see all items, add new or import items, create suppliers, edit, delete, archive and unarchive existing items.\nCan assign Items permissions.`,
    [k.ITEMS_OWNER]: `Items - Owner`,
    [k.ITEMS_VIEWER_INFO]: `Can see all items.`,
    [k.ITEMS_VIEWER]: `Items - Viewer`,
    [k.ITEMS]: `items`,
    [k.ITS_EMPTY_HERE]: `It's Empty Here!`,
    [k.JAN]: `Jan`,
    [k.JANUARY]: `January`,
    [k.JSON_PAYLOAD_PREVIEW_DESCRIPTION]: `Webhooks are always tied to the latest version of the template and step, rather than to a specific version`,
    [k.JSON_PAYLOAD_PREVIEW]: `JSON Payload Preview`,
    [k.JUL]: `Jul`,
    [k.JULY]: `July`,
    [k.JUN]: `Jun`,
    [k.JUNE]: `June`,
    [k.JUST_NOW]: `Just now`,
    [k.KEY_ACTIVITIES_TOOLTIP]: `Key activities are also shown on the parent process page`,
    [k.KEY_ACTIVITIES]: `Key Activities`,
    [k.KPI_CANNOT_REFERENCE_ITSELF]: `KPI cannot reference itself`,
    [k.KPI_CONTRIBUTOR_INFO]: `Can see all KPIs, edit existing KPIs.`,
    [k.KPI_CONTRIBUTOR]: `KPI - Contributor`,
    [k.KPI_CREATED]: `KPI created`,
    [k.KPI_DATA_SOURCE_ID_REQUIRED]: `Must select a KPI source.`,
    [k.KPI_DESCRIPTION]: `KPI Description`,
    [k.KPI_DETAILS_TIP1]: `In this step, enter the name and a good description for your KPI that will help others easily understand why the KPI exists.`,
    [k.KPI_DETAILS]: `KPI Details`,
    [k.KPI_EDITOR_INFO]: `Can see all KPIs, add new KPIs, edit, archive and unarchive existing KPIs.`,
    [k.KPI_EDITOR]: `KPI - Editor`,
    [k.KPI_FEATURE_DESCRIPTION]: `With this feature enabled, you can keep track of your KPIs.`,
    [k.KPI_FORMULA_TIP1]: `Variables are the building blocks of your KPI. Make sure to select the right variables and double-check that they are accurate and up-to-date.`,
    [k.KPI_FORMULA_TIP2]: `Arithmetic operations allow you to combine variables and perform calculations to get your KPI. Make sure to use the correct operations and follow the order of operations to ensure accurate results.`,
    [k.KPI_FORMULA_TIP3]: `Static numbers and parentheses allow you to adjust the KPI formula to your needs. Make sure to use them correctly and double-check your formula before saving.`,
    [k.KPI_FORMULA]: `KPI Formula`,
    [k.KPI_MANUAL_DATA_SOURCE_INFO]: `Input the values for this manual data source, per month, per year.`,
    [k.KPI_NAME_REQUIRED]: `KPI name is required`,
    [k.KPI_NAME]: `KPI Name`,
    [k.KPI_OWNER_INFO]: `Can see all KPIs, add new KPIs, edit, delete, archive and unarchive existing KPIs.\nCan assign KPI permissions.`,
    [k.KPI_OWNER]: `KPI - Owner`,
    [k.KPI_PAGE_SUBTITLE]: `All KPIs available in the system`,
    [k.KPI_PAGE_TITLE]: `KPIs`,
    [k.KPI_SAVED]: `KPI saved`,
    [k.KPI_TARGETS_INFO]: `The target value for the KPI against the formula. Adding a target will automatically apply the same to all the following months and continue for the years after until a new target is set.`,
    [k.KPI_TARGETS]: `Target values`,
    [k.KPI_UNIT]: `KPI Unit`,
    [k.KPI_UPDATED]: `KPI updated`,
    [k.KPI_VIEWER_INFO]: `Can see all KPIs.`,
    [k.KPI_VIEWER]: `KPI - Viewer`,
    [k.KPI]: `KPI`,
    [k.LABEL_]: `label`,
    [k.LABEL]: `Label`,
    [k.LANGUAGE]: `Language`,
    [k.LAST_]: `last`,
    [k.LAST_DAY_]: `last day`,
    [k.LAST_MODIFIED]: `Last modified`,
    [k.LAST_MONTH]: `Last month`,
    [k.LAST_WEEK]: `Last week`,
    [k.LAST]: `Last`,
    [k.LEAD_TIME_TARGET]: `Lead time target`,
    [k.LEGISLATION_PAGE_SUBTITLE]: `Here are all the legislations available in the system`,
    [k.LEGISLATION_PAGE_TITLE]: `Legislations`,
    [k.LEGISLATION_TITLE]: `Legislation Title`,
    [k.LEGISLATIONS_EDITOR_INFO]: `Can see all legislations, add new legislations, edit, delete, archive and unarchive existing legislations.`,
    [k.LEGISLATIONS_EDITOR]: `Legislations - Editor`,
    [k.LEGISLATIONS_FEATURE_DESCRIPTION]: `This feature enables management of company Legislations.`,
    [k.LEGISLATIONS_OWNER_INFO]: `Can see all legislations, add or import new legislations, edit, delete, archive and unarchive existing legislations.\nCan assign Legislations permissions.`,
    [k.LEGISLATIONS_OWNER]: `Legislations - Owner`,
    [k.LEGISLATIONS_VIEWER_INFO]: `Can see all legislations.`,
    [k.LEGISLATIONS_VIEWER]: `Legislations - Viewer`,
    [k.LETS_ADD_SOME_CONTACT]: `Let's add some contact`,
    [k.LEVEL_OF_EDUCATION]: `Level of education`,
    [k.LEVEL_OF_INTEREST]: `Level of interest`,
    [k.LINK_LABEL]: `Link label`,
    [k.LINK_PLACEHOLDER]: `https://example.com`,
    [k.LINK_SUB_PROCESS]: `Link Sub Process`,
    [k.LINK_TO_IN_DEPTH_INFORMATION]: `Link to in-depth information`,
    [k.LINK_TO_LEGISLATION]: `Link to legislation`,
    [k.LINK]: `Link`,
    [k.LINKS]: `Links`,
    [k.LOAD_EARLIER]: `Load earlier`,
    [k.LOADING]: `Loading...`,
    [k.LOG_EXECUTED]: `Log executed`,
    [k.LOGIN_]: `login`,
    [k.LOGIN_PLEASE_WAIT]: `Login, please wait`,
    [k.LOGIN_QUICK_TIP_DESCRIPTION]: `You can sort checklists by status on the overview report`,
    [k.LOGIN_QUICK_TIP]: `💡 Quick Tip:`,
    [k.LOGIN_TO]: `Login to`,
    [k.LOGIN]: `Login`,
    [k.LOGOUT_ALL_USERS_DESCRIPTION]: `Using this option you can log out all users of this tenant. Especially useful for security. All users will need to log in again.`,
    [k.LOGOUT_ALL_USERS]: `Logout All Users`,
    [k.LOGOUT_CONFIRMATION]: `Do you want to sign out?`,
    [k.M_INUTES]: `m`,
    [k.MAIN_TASK]: `Main task`,
    [k.MAKE_PRIMARY]: `Make Primary`,
    [k.MANAGE_CUSTOM_LISTS]: `Manage Custom Lists`,
    [k.MANAGE_CUSTOMER_INDUSTRIES]: `Customer Industries`,
    [k.MANAGE_PROCESS_CHARTS]: `Process Chart Settings`,
    [k.MANAGE_PURCHASE_ORDER_LINE_STATUS_SUBTITLE]: `Here are all the PO line statuses available in the system`,
    [k.MANAGE_SSO]: `Manage SSO`,
    [k.MANAGE_API_KEYS]: `Manage API Keys`,
    [k.MANAGE_TYPE_UNIT_SUBTITLE]: `Here are all the item types and item units available in the system`,
    [k.MANAGE_WEBHOOKS]: `Manage Webhooks`,
    [k.MANAGED_BY_THESE_PROCESS]: `Managed by these process(es)`,
    [k.MANAGEMENT]: `Management`,
    [k.MANAGER]: `Manager`,
    [k.MANAGING_CUSTOM_LISTS_DESCRIPTION]: `Enabling this feature enables you to create a customizable list with various properties. It is more like a custom database for your company. For example, you can create a customized list to manage all of your company branches or your customer contracts. You can even create lists that depend on other lists.`,
    [k.MANAGING_CUSTOM_LISTS]: `Managing Custom Lists`,
    [k.MANAGING_SSO_CONFIG_DESCRIPTION]: `You can configure Single Sign-On (SSO) settings on the dedicated configuration page. To access this page, click the button located on the right or select the "SSO" tab at the top of the page.`,
    [k.MANAGING_SSO_CONFIG]: `Managing SSO Config`,
    [k.MANAGING_WEBHOOKS_AND_EVENTS_DESCRIPTION]: `Webhooks allow Ampliflow to send real-time data to your specified URL whenever certain events occur in your tenant. This enables seamless integration with external systems, automating workflows and instantly updating your applications with relevant information.`,
    [k.MANAGING_WEBHOOKS_AND_EVENTS]: `Managing Webhooks & Events`,
    [k.MANUAL_DATA_SOURCE_DESCRIPTION]: `You've set this as a Manual Data Source.\nThis means someone responsible for this KPI needs to manually input data values. After saving, open the KPI to enter the data.\nDon't forget, you need to do this every month for each year you're tracking.`,
    [k.MANUAL_VALUE]: `Manual Value`,
    [k.MANUAL]: `Manual`,
    [k.MAPPER]: `Mapper`,
    [k.MAR]: `Mar`,
    [k.MARCH]: `March`,
    [k.MARK_AS_ANALYZED]: `Mark as analyzed`,
    [k.MARK_AS_COMPLETED]: `Mark As Completed`,
    [k.MARK_AS_DONE]: `Mark as done`,
    [k.MARK_AS_INCOMPLETE]: `Mark as incomplete`,
    [k.MARK_AS_NOT_ANALYZED]: `Mark as not analyzed`,
    [k.MARK_AS_NOT_DONE]: `Mark as not done`,
    [k.MARK_AS_NOT_REVIEWED]: `Mark as not reviewed`,
    [k.MARK_AS_REVIEWED]: `Mark as reviewed`,
    [k.MARKED_AS_COMPLETE]: `Marked as complete`,
    [k.MARKED_AS_INCOMPLETE]: `Marked as incomplete`,
    [k.MARKED_FOR_REMOVAL]: `Marked for removal`,
    [k.MARKERS_OF_PROGRESS_LINE]: `Markers of Progress Line`,
    [k.MATCHING_CHECKLISTS_]: `matching checklists`,
    [k.MATCHING_VALUE_]: `matching value`,
    [k.MATCHING_VALUES_]: `matching values`,
    [k.MAY]: `May`,
    [k.ME]: `Me`,
    [k.MEASUREMENT_ARCHIVED]: `Measurement archived`,
    [k.MEASUREMENT_CREATED]: `Measurement created`,
    [k.MEASUREMENT_INDEX]: `Measurement Index`,
    [k.MEASUREMENT_UNARCHIVED]: `Measurement unarchived`,
    [k.MEASUREMENT]: `Measurement`,
    [k.MEASUREMENTS]: `Measurements`,
    [k.MEDIA_ATTENTION]: `Media Attention`,
    [k.MESSAGE]: `Message`,
    [k.MICROSOFT_TEAMS_APP_FEATURE_DESCRIPTION]: `This feature enables integration of Ampliflow with Microsoft Teams app.`,
    [k.MIN_ONE]: `Min 1`,
    [k.MIN_UTES]: `Min.`,
    [k.MIN_ZERO]: `Min 0`,
    [k.MINUTES]: `Minutes`,
    [k.MISSING_CLOSING_PARENTHESIS]: `Missing closing parenthesis`,
    [k.MISSING_DATA_TO_CALCULATE]: `Missing data to calculate...`,
    [k.MISSING_ITEMS]: `Missing items`,
    [k.MISSING_OPENING_PARENTHESIS]: `Missing opening parenthesis`,
    [k.MISSING_OPERAND]: `Operand is missing`,
    [k.MISSING_OPERATOR]: `Operator is missing`,
    [k.MISSING_PARENTHESIS]: `Parenthesis is missing`,
    [k.MODIFIED]: `Modified`,
    [k.MONDAY]: `Monday`,
    [k.MONTH_S_]: `month(s)`,
    [k.MONTH_S]: `Month(s)`,
    [k.MONTHLY]: `Monthly`,
    [k.MONTHS_]: `months`,
    [k.MORE]: `More`,
    [k.MOTIVATION]: `Motivation`,
    [k.MOVE_ON_TO_THE_NEXT_STEP]: `Do you want to finalize this step and move on to the next?`,
    [k.MY_CHECKLISTS]: `My Checklists`,
    [k.MY_IMPROVEMENTS]: `My Improvements`,
    [k.MY_TASKS]: `My Tasks`,
    [k.NAME_CHECKLIST]: `Give your checklist a unique name`,
    [k.NAME]: `Name`,
    [k.NAV_BUTTON_REGISTER_IMPROVEMENT]: `Register Improvement`,
    [k.NAV_BUTTON_RUN_CHECKLIST]: `Run Checklist`,
    [k.NAV_NODE_ADMINISTRATOR_SETTINGS]: `Settings`,
    [k.NAV_NODE_ADMINISTRATOR]: `Administrator`,
    [k.NAV_NODE_ADVANCED_FEATURES]: `Advanced Features`,
    [k.NAV_NODE_ANALYZE]: `Analyze`,
    [k.NAV_NODE_BULK_IMPORT]: `Bulk Import`,
    [k.NAV_NODE_CHECKLIST_TEMPLATE]: `Checklist Templates`,
    [k.NAV_NODE_CHECKLISTS_REPORT]: `Checklist Report`,
    [k.NAV_NODE_CHECKLISTS_SEARCH]: `Checklist Search`,
    [k.NAV_NODE_CHECKLISTS]: `Checklists`,
    [k.NAV_NODE_COMPETENCY_MATRIX]: `Competency Matrix`,
    [k.NAV_NODE_CUSTOM_LISTS]: `Custom Lists`,
    [k.NAV_NODE_CUSTOMERS]: `Customers`,
    [k.NAV_NODE_DATA_MANAGEMENT]: `Data Management`,
    [k.NAV_NODE_EQUIPMENT]: `Equipment`,
    [k.NAV_NODE_GOALS]: `Goals`,
    [k.NAV_NODE_HR]: `HR`,
    [k.NAV_NODE_HUMAN_RESOURCES]: `Human Resources`,
    [k.NAV_NODE_IMPACT_GRADINGS]: `Impact Gradings`,
    [k.NAV_NODE_IMPROVEMENT_DASHBOARD]: `Improvement Dashboard`,
    [k.NAV_NODE_IMPROVEMENT_FORMS]: `Improvement Forms`,
    [k.NAV_NODE_IMPROVEMENT_SETUP]: `Improvement Setup`,
    [k.NAV_NODE_IMPROVEMENTS]: `Improvements`,
    [k.NAV_NODE_ITEMS]: `Items`,
    [k.NAV_NODE_KPI]: `KPI`,
    [k.NAV_NODE_LEGISLATIONS]: `Legislations`,
    [k.NAV_NODE_LISTS]: `Lists`,
    [k.NAV_NODE_LOG_OUT]: `Sign Out`,
    [k.NAV_NODE_MANAGE_PURCHASE_ORDER_LINE_STATUS]: `PO Line Statuses`,
    [k.NAV_NODE_MANAGE_TYPE_AND_UNIT]: `Types & Units`,
    [k.NAV_NODE_MAP]: `Templates`,
    [k.NAV_NODE_MARKETING_SALES]: `Marketing & Sales`,
    [k.NAV_NODE_MICROSOFT_TEAMS_APP]: `Microsoft Teams App`,
    [k.NAV_NODE_OPERATIONAL_MANAGEMENT]: `Operational Management`,
    [k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS_GRAPH]: `Operational Risk Analysis Graph`,
    [k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS]: `Operational Risk Analysis`,
    [k.NAV_NODE_OTHER_RESOURCES]: `Other Resources`,
    [k.NAV_NODE_PAGES]: `Pages`,
    [k.NAV_NODE_PERFORMANCE_MANAGEMENT]: `Performance Management`,
    [k.NAV_NODE_POSITIONS]: `Positions`,
    [k.NAV_NODE_PROCESSCHART]: `Process Chart`,
    [k.NAV_NODE_PROCUREMENT_AND_SALES]: `Procurement and Sales`,
    [k.NAV_NODE_PURCHASE_ORDERS]: `Purchase Orders`,
    [k.NAV_NODE_RECURRING_CHECKLIST]: `Recurring Checklists`,
    [k.NAV_NODE_REPORTS]: `Reports`,
    [k.NAV_NODE_RISK_COMPLIANCE_MANAGEMENT]: `Risk and Compliance Management`,
    [k.NAV_NODE_RISK_MANAGEMENT]: `Risk Management`,
    [k.NAV_NODE_SCHEDULING]: `Scheduling`,
    [k.NAV_NODE_SENIOR_MANAGEMENT]: `Senior Management`,
    [k.NAV_NODE_SHARED_TEMPLATE_STEPS]: `Shared Template Steps`,
    [k.NAV_NODE_SINGLE_SIGN_ON]: `Single Sign On (SSO)`,
    [k.NAV_NODE_SOURCING_PURCHASE]: `Sourcing & Purchase`,
    [k.NAV_NODE_STAKEHOLDERS]: `Stakeholders`,
    [k.NAV_NODE_SUPPLIERS]: `Suppliers`,
    [k.NAV_NODE_TENANT_SETTINGS]: `Settings`,
    [k.NAV_NODE_TASKS]: `Tasks`,
    [k.NAV_NODE_TEAMS]: `Teams`,
    [k.NAV_NODE_TEMPLATES]: `Templates`,
    [k.NAV_NODE_TRACEABILITY_REPORT]: `Traceability Report`,
    [k.NAV_NODE_USERS]: `Users`,
    [k.NEED_ATTENTION]: `Need Attention`,
    [k.NEED_FOR_TRAINING]: `Need for training`,
    [k.NEED_REWORK]: `Need re-work`,
    [k.NEW_]: `new`,
    [k.NEW_API_KEY]: `New API Key`,
    [k.NEW_CUSTOM_LIST]: `New Custom List`,
    [k.NEW_CUSTOMER_CONTACT]: `New Customer Contact`,
    [k.NEW_CUSTOMER]: `New Customer`,
    [k.NEW_EQUIPMENT]: `New Equipment`,
    [k.NEW_FILE]: `New file`,
    [k.NEW_GOAL]: `New Goal`,
    [k.NEW_IMPROVEMENT_FORM]: `New Improvement Form`,
    [k.NEW_IMPROVEMENT_FORM]: `New Improvement Form`,
    [k.NEW_IMPROVEMENT]: `New Improvement`,
    [k.NEW_ITEM]: `New Item`,
    [k.NEW_LINK]: `New link`,
    [k.NEW_MEASUREMENT]: `New Measurement`,
    [k.NEW_PAGE]: `New Page`,
    [k.NEW_PASSWORD]: `New password`,
    [k.NEW_PURCHASE_ORDER]: `New Purchase Order`,
    [k.NEW_RECURRING_CHECKLIST]: `New Recurring Checklist`,
    [k.NEW_STATUS]: `New Status`,
    [k.NEW_STEP_VERSION_AVAILABLE]: `New step versions available`,
    [k.NEW_STEP]: `New step`,
    [k.NEW_SUB_GOAL]: `New Sub Goal`,
    [k.NEW_SUPPLIER]: `New Supplier`,
    [k.NEW_TASK_ADDED]: `New task added`,
    [k.NEW_TASK]: `New Task`,
    [k.NEW_TEAM]: `New Team`,
    [k.NEW_TEMPLATE]: `New Template`,
    [k.NEW_TYPE_UNIT]: `New Type/Unit`,
    [k.NEW_USER]: `New User`,
    [k.NEW_VERSION_AVAILABLE_UPDATING_IN_30_SECONDS]: `New version available. Updating in 30 seconds...`,
    [k.NEW_WEBHOOK]: `New Webhook`,
    [k.NEW]: `New`,
    [k.NEXT_CALIBRATION_DATE]: `Next Calibration Date`,
    [k.NEXT_CALIBRATION]: `Next calibration`,
    [k.NEXT_MONTH]: `Next month`,
    [k.NEXT_WEEK]: `Next week`,
    [k.NEXT_YEAR]: `Next year`,
    [k.NEXT]: `Next`,
    [k.NO_ACTION_PLAN_ADDED_YET]: `No action plan added yet`,
    [k.NO_ACTIONS_TAKEN]: `No actions taken`,
    [k.NO_ACTIVITES_IN_THIS_IMPROVEMENT_FORM_YET]: `No activities in this improvement form yet`,
    [k.NO_ACTIVITES_IN_THIS_STEP_YET]: `No activities in this Step yet`,
    [k.NO_AVAILABLE_CHECKLIST_FOUND_FOR_YOUR_USER_ROLE]: `No available checklist found for your account/user role`,
    [k.NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE]: `No available checklists found for your account/user role`,
    [k.NO_AVAILABLE_CUSTOMLIST_FOUND_FOR_YOUR_USER_ROLE]: `No available custom list found for your account/user role`,
    [k.NO_AVAILABLE_CUSTOMLISTITEM_FOUND_FOR_YOUR_USER_ROLE]: `No available custom list item found for your account/user role`,
    [k.NO_AVAILABLE_GOALS_FOUND_FOR_YOUR_USER_ROLE]: `No available goals found for your account/user role.`,
    [k.NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE]: `No available improvement found for your account/user role`,
    [k.NO_AVAILABLE_IMPROVEMENTS_FOUND_FOR_YOUR_USER_ROLE]: `No available improvements found for your account/user role`,
    [k.NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE]: `No available KPIs found for your account/user role`,
    [k.NO_AVAILABLE_MEASUREMENTS_FOUND_FOR_YOUR_USER_ROLE]: `No available measurements found for your account/user role`,
    [k.NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE]: `No available Templates found for your account/user role`,
    [k.NO_AVAILABLE_WORKSHEETS_FOUND_FOR_YOUR_USER_ROLE]: `No available worksheets found for your account/user role`,
    [k.NO_BIO_AVAILABLE]: `No bio available`,
    [k.NO_CHECKLIST_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW]: `No checklist level tasks are added for the activity.\r\nYou can add your own tasks by clicking the button bellow.`,
    [k.NO_CHECKLIST_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY]: `No checklist level tasks were added for the activity.`,
    [k.NO_CHECKLIST_OR_NOT_EXIST]: `The checklist you try to access does not exist or you don't have access to it.`,
    [k.NO_CHECKLIST_TO_SHOW]: `No checklists to show! There are no active checklists where you are assigned to a step. If this is wrong, please register an improvement`,
    [k.NO_COMMENT_PROVIDED]: `No comment provided`,
    [k.NO_COMPLETED_COURSES]: `No completed courses`,
    [k.NO_CONTACTS_AVAILABLE_FOR_THIS_CUSTOMER]: `No contacts available for this customer`,
    [k.NO_CONTACTS_AVAILABLE_FOR_THIS_SUPPLIER]: `No contacts available for this supplier`,
    [k.NO_CUSTOMERS_AVAILABLE]: `No customers available`,
    [k.NO_DESCRIPTION_PROVIDED]: `No description provided`,
    [k.NO_DEVIATION_MESSAGE_SUBMITTED]: `No deviation message submitted`,
    [k.NO_EQUIPMENT_AVAILABLE]: `No Equipment Available`,
    [k.NO_EQUIPMENT_TO_SHOW]: `No equipment to show...`,
    [k.NO_EQUIPMENT]: `No equipment`,
    [k.NO_GOAL_AVAILABLE]: `No goals available.`,
    [k.NO_IMPROVEMENT_FORM_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_IMPROVEMENTS]: `No improvement form level tasks are added for the activity.\r\nUsers will be able to add their own tasks here in improvements.`,
    [k.NO_IMPROVEMENT_FORMS_SUBTITLE]: `Click the button below to add one.`,
    [k.NO_IMPROVEMENT_FORMS_TITLE]: `No improvement forms added yet.`,
    [k.NO_IMPROVEMENT_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW]: `No improvement level tasks are added for the activity.\r\nYou can add your own tasks by clicking the button bellow.`,
    [k.NO_IMPROVEMENT_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY]: `No improvement form level tasks were added for the activity.`,
    [k.NO_IMPROVEMENTS_FOUND]: `No Improvements Found`,
    [k.NO_IMPROVEMENTS_TO_SHOW]: `No improvements to show...`,
    [k.NO_ITEMS_AVAILABLE]: `No items available`,
    [k.NO_KPI_AVAILABLE]: `No KPIs available`,
    [k.NO_KPI_UNIT_PROVIDED]: `No KPI unit provided`,
    [k.NO_LABEL]: `No label`,
    [k.NO_MATCHES]: `No matches`,
    [k.NO_MEASUREMENTS_ADDED_YET]: `No measurements added yet.`,
    [k.NO_MORE_RUNS_TO_LOAD]: `No more checklists to load`,
    [k.NO_NOTES_PROVIDED]: `No notes provided`,
    [k.NO_ONE_ASSIGNED]: `No one assigned`,
    [k.NO_ONE_CAN_VIEW]: `No one can view`,
    [k.NO_ONE_HAS_ACCESS_ALERT_MESSAGE]: `Right now, only you and other users with the administrator role can access this feature. Click on the 'Edit Permission Settings' button to provide access to other users and teams.`,
    [k.NO_ONE_HAS_ACCESS_TO_CUSTOM_LIST_ALERT_MESSAGE]: `Right now, only you and other users with the administrator role can access this custom list. Click on the 'Edit Permission Settings' button to provide access to other users and teams.`,
    [k.NO_ONE_IS_HERE]: `No One is Here`,
    [k.NO_PERMISSION_TO_EDIT_TEMPLATE]: `You have no permissions to edit this template`,
    [k.NO_PHOTO]: `[No Photo]`,
    [k.NO_PO_LINE_STATUSES_AVAILABLE]: `No PO Line Statuses available`,
    [k.NO_PROCESS_AVAILABLE_RIGHT_NOW]: `No process available right now.`,
    [k.NO_PROGRESS_TO_SHOW]: `No progress to show`,
    [k.NO_PURCHASE_ORDERS_AVAILABLE]: `No purchase orders available`,
    [k.NO_RECURRING_CHECKLISTS_TO_SHOW]: `No recurring checklists to show...`,
    [k.NO_RECURRING_OR_NOT_EXIST]: `The  recurring checklist you try to access does not exist or you don't have access to it.`,
    [k.NO_RESULTS]: `No results`,
    [k.NO_RISK_UNPUBLISHED_PROCESS_STEP]: `Cannot add risks to unpublished process steps.`,
    [k.NO_RUNS_FINALIZED]: `There are no finalized steps for this checklist yet...`,
    [k.NO_SCHEDULED_COURSES]: `No scheduled courses`,
    [k.NO_STATUS]: `No status`,
    [k.NO_STEP]: `No steps`,
    [k.NO_STEPS_TO_SHOW]: `No steps assigned...`,
    [k.NO_STEPS]: `No steps in this template yet`,
    [k.NO_SUB_GOALS_ADDED_YET]: `No sub goals added yet.`,
    [k.NO_SUMMARY_AVAILABLE]: `No summary available...`,
    [k.NO_SUPPLIERS_AVAILABLE]: `No suppliers available`,
    [k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN]: `No tasks added yet, please add your own.`,
    [k.NO_TASKS_ADDED_YET_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN]: `No tasks added yet.\r\nUsers will be able to add their own.`,
    [k.NO_TASKS_AVAILABLE]: `No Tasks Available`,
    [k.NO_TEAM_AVAILABLE]: `No Team Available`,
    [k.NO_TEAM_ROLES]: `No roles from teams`,
    [k.NO_TEAM_TO_SHOW]: `No teams to show...`,
    [k.NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS]: `No template level tasks are added for the activity.\r\nUsers will be able to add their own tasks here in running checklists.`,
    [k.NO_TEMPLATES_ASSIGNED]: `Team not assigned to any template`,
    [k.NO_TEMPLATES_SUBTITLE1]: `Don't worry! We have tons of ready to use templates available in the library.`,
    [k.NO_TEMPLATES_SUBTITLE2]: `You can import them with just one click`,
    [k.NO_TEMPLATES_TITLE]: `No templates available here!`,
    [k.NO_TITLE]: `No title`,
    [k.NO_UNIT]: `No unit`,
    [k.NO_USERS_ASSIGNED_TO_THIS_GOAL]: `No users assigned to this goal`,
    [k.NO_USERS_ASSIGNED_TO_THIS_MEASUREMENT]: `No users assigned to this measurement`,
    [k.NO_USERS_ASSIGNED]: `No users assigned to this team`,
    [k.NO_USERS_AVAILABLE]: `No users available`,
    [k.NO_USERS_OR_TEAMS_HAVE_THIS_ROLE_ASSIGNED_TO_THEM_YET]: `No users or teams have this role assigned to them yet.`,
    [k.NO_USERS_TO_SHOW]: `No users to show...`,
    [k.NO_VALUE]: `[No Value]`,
    [k.NO_ZERO_DURATION]: `Duration cannot be zero`,
    [k.NO]: `No`,
    [k.NONE_ASSIGNED]: `None assigned`,
    [k.NONE_SELECTED_P]: `None selected`,
    [k.NONE_SELECTED]: `None selected`,
    [k.NONE]: `None`,
    [k.NOT_ACTIONABLE]: `Not Actionable`,
    [k.NOT_APPLICABLE]: `Not Applicable`,
    [k.NOT_ARCHIVED]: `Not archived`,
    [k.NOT_AVAILABLE_MESSAGE]: `n/a`,
    [k.NOT_AVAILABLE]: `Not available`,
    [k.NOT_COMPLETED]: `Not completed`,
    [k.NOT_FOUND]: `Not Found`,
    [k.NOT_OK]: `Not OK`,
    [k.NOT_SPECIFIED]: `Not specified`,
    [k.NOTE]: `Note`,
    [k.NOTES]: `Notes`,
    [k.NOTHING_FOUND]: `Nothing found`,
    [k.NOTIFICATION]: `Notification`,
    [k.NOTIFY]: `Notify`,
    [k.NOV]: `Nov`,
    [k.NOVEMBER]: `November`,
    [k.NUMBER_OF_DECIMAL_PLACES]: `Number of decimal places`,
    [k.NUMBER_OF_EMPLOYEES_DESCRIPTION]: `The number of employees in your company in different locations.`,
    [k.NUMBER_OF_EMPLOYEES_EXAMPLE]: `Example: Sweden 25, USA 40, Norway 2`,
    [k.NUMBER_OF_EMPLOYEES]: `Number of Employees`,
    [k.NUMBER_OF_IMPROVEMENTS_BROKEN_DOWN_BY_STATUS]: `Number of improvements broken down by status`,
    [k.NUMBER_OF_RUNS]: `Number of checklists`,
    [k.NUMBERS_SHOULD_BE_GREATER_THAN_ZERO]: `Numbers should be greater than 0`,
    [k.OBSOLETE]: `Obsolete`,
    [k.OCCURENCE]: `Occurence`,
    [k.OCT]: `Oct`,
    [k.OCTOBER]: `October`,
    [k.OF_THE_MONTH]: `of the month`,
    [k.OF]: `of`,
    [k.OK]: `OK`,
    [k.OLD_VERSION]: `Old version`,
    [k.ON_A_FIXED_DATE]: `On a fixed date`,
    [k.ON_THESE_DAYS]: `On these day(s)`,
    [k.ON_THESE_MONTHS]: `On these month(s)`,
    [k.ON_TRACK_]: `on track`,
    [k.ON_TRACK]: `On Track`,
    [k.ON]: `On`,
    [k.OPEN_AMPLIFLOW_IN_BROWSER_TO_PASTE_FROM_CLIPBOARD]: `Open Ampliflow in browser to paste from clipboard`,
    [k.OPEN_IMPROVEMENTS_TABLE_HEADER]: `Open Improv.`,
    [k.OPEN]: `Open`,
    [k.OPENs]: `Open`,
    [k.OPERATIONAL_RISK_ANALYSIS_FEATURE_DESCRIPTION]: `This feature enables management of operational risks and their analysis.`,
    [k.OPERATIONAL_RISK_ANALYSIS_GRAPH_FEATURE_DESCRIPTION]: `This feature enables users to view the Operational Risk Analysis data in an interactive graph form.`,
    [k.OPERATIONAL_RISK_ANALYSIS_GRAPH]: `Operational Risk Analysis Graph`,
    [k.OPERATIONAL_RISK_ANALYSIS_PAGE_TITLE]: `Operational Risk Analysis`,
    [k.OPERATORS]: `Operators`,
    [k.OPTIONAL_PLACEHOLDER]: `Optional placeholder for input`,
    [k.OPTIONAL]: `Optional`,
    [k.OPTIONS]: `Options`,
    [k.OR_ENTER_BARCODE_HERE]: `Or enter barcode here`,
    [k.OR]: `OR`,
    [k.ORA_EDITOR_INFO]: `Can see all operational risk analyses, add new operational risk analyses, edit, archive and unarchive existing operational risk analyses.`,
    [k.ORA_EDITOR]: `Operational Risk Analysis - Editor`,
    [k.ORA_OWNER_INFO]: `Can see all operational risk analyses, add new or import operational risk analyses, edit, delete, archive and unarchive existing operational risk analyses.\nCan assign Operational Risk Analysis permissions.`,
    [k.ORA_OWNER]: `Operational Risk Analysis - Owner`,
    [k.ORA_VIEWER_INFO]: `Can see all operational risk analyses.`,
    [k.ORA_VIEWER]: `Operational Risk Analysis - Viewer`,
    [k.ORIGIN]: `Origin`,
    [k.ORIGINATED_IN_PROCESS_FILTER]: `Originated in process filter`,
    [k.ORIGINATED_IN_T]: `<strong>Originated</strong> in`,
    [k.ORIGINATED_IN]: `Originated in`,
    [k.OTHER_NOT_SURE]: `Other / Not sure`,
    [k.OTHER_USERS]: `Other users`,
    [k.OTHER]: `Other`,
    [k.OUR_KNOWLEDGE_GURU]: `Our knowledge guru`,
    [k.OUT_OF_]: `out of`,
    [k.OUTPUT_TO]: `Output to`,
    [k.OUTPUTS]: `Output(s)`,
    [k.OVER_TARGET_IS_GOOD]: `Over target is good`,
    [k.OVER_THRESHOLD]: `Over threshold`,
    [k.OVERALL_TOTALS_FOR_THE_FORM]: `Overall totals for the form`,
    [k.OVERVIEW]: `Overview`,
    [k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS]: `Owner for selected <strong>discovered in</strong> process`,
    [k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS]: `Owner for selected <strong>originated in</strong> process`,
    [k.OWNER]: `Owner`,
    [k.PAGE_]: `page`,
    [k.PAGE_NOT_FOUND]: `Page not found`,
    [k.PAGE]: `Page`,
    [k.PAGES]: `Pages`,
    [k.PARENT_GOAL]: `Parent Goal`,
    [k.PARENT_GOALS]: `Parent Goals`,
    [k.PARENTHESES]: `Parentheses`,
    [k.PART_OF_SCHEDULE]: `Part of schedule`,
    [k.PASSWORD_PLACEHOLDER]: `************`,
    [k.PASSWORD]: `Password`,
    [k.PASTE_FAILED]: `Paste failed`,
    [k.PASTE_FROM_CLIPBOARD]: `Paste from clipboard`,
    [k.PASTE]: `Paste`,
    [k.PAUSE]: `Pause`,
    [k.PAUSED_BY]: `paused by`,
    [k.PAUSED]: `Paused`,
    [k.PAYLOAD]: `Payload`,
    [k.PEOPLE_TO_BE_TRAINED]: `People to be trained`,
    [k.PERCENTAGE_OF_QUANTITY_TARGET]: `% of Quantity target`,
    [k.PERCENTAGE]: `Percentage`,
    [k.PERFORMANCE_EVALUATION]: `Performance evaluation`,
    [k.PERMANENTLY_REMOVE]: `PERMANENTLY REMOVE`,
    [k.PERMISSION_DESCRIPTION]: `Here is the list of users and teams that have different types of access to this feature. You can see individual users as circles with their initials and team names in a pill shape. You can also change permission settings by using the button on the side.`,
    [k.PERMISSION_SETTINGS_FOR]: `Permission Settings For`,
    [k.PERMISSION_SETTINGS]: `Permission settings`,
    [k.PERSONAL_AMBITION]: `Personal ambition`,
    [k.PHONE_MOBILE]: `Phone (Mobile)`,
    [k.PHONE]: `Phone`,
    [k.PHOTO_PREVIEW]: `Photo preview`,
    [k.PHOTO]: `Photo`,
    [k.PLACEHOLDER_]: `placeholder`,
    [k.PLACEHOLDER_FOR_INPUT_FIELD]: `Placeholder for Input field`,
    [k.PLACEHOLDER]: `Placeholder`,
    [k.PLANNED_FOR]: `Planned for`,
    [k.PLANNED_SCHEDULE_FOR]: `Planned schedule for`,
    [k.PLANNER]: `Planner`,
    [k.PLEASE_CONFIRM]: `Please confirm`,
    [k.PLEASE_FORWARD_LOGIN_DETAILS_TO_USER_AFTER_SAVING]: `Please forward login details to user after saving`,
    [k.PLEASE_REMOVE_THE_POSITION_FROM_THE_COMPETENCIES_FIRST_AND_TRY_AGAIN]: `Please remove the position from the competencies first and try again.`,
    [k.PLEASE_REMOVE_THE_POSITION_FROM_THE_USERS_FIRST_AND_TRY_AGAIN]: `Please remove the position from the users first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_CHECKLISTS_FIRST_AND_TRY_AGAIN]: `Please remove the team from the checklists first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_GOAL_FIRST_AND_TRY_AGAIN]: `Please remove the team from the goal first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_KPI_FIRST_AND_TRY_AGAIN]: `Please remove the team from the KPI first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_PROCESS_FIRST_AND_TRY_AGAIN]: `Please remove the team from the process first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_STEP_FIRST_AND_TRY_AGAIN]: `Please remove the team from the step first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_SUBPROCESS_FIRST_AND_TRY_AGAIN]: `Please remove the team from the subprocess first and try again.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_TEMPLATE_FIRST_AND_TRY_AGAIN]: `Please remove the team from the template first and try again.`,
    [k.POSITION_CANNOT_BE_DELETED_1]: `The position cannot be deleted as it is currently used by these users:`,
    [k.POSITION_CANNOT_BE_DELETED_2]: `The position cannot be deleted as it is currently used by these competencies:`,
    [k.POSITION_DELETED]: `Position deleted!`,
    [k.POSITION_EDITOR_INFO]: `Can see all positions, add new positions, edit, delete, archive and unarchive existing positions.`,
    [k.POSITION_EDITOR]: `Position - Editor`,
    [k.POSITION_IS_USED_FOR_COMPETENCIES]: `Position is used by these competencies:`,
    [k.POSITION_IS_USED_FOR_USERS]: `Position is used by these users:`,
    [k.POSITION_OWNER_INFO]: `Can see all positions, add new or import positions, edit, delete, archive and unarchive existing positions.\nCan assign Position permissions.`,
    [k.POSITION_OWNER]: `Position - Owner`,
    [k.POSITION_VIEWER_INFO]: `Can see all positions.`,
    [k.POSITION_VIEWER]: `Position - Viewer`,
    [k.POSITION_WILL_BE_REMOVED]: `Position will be removed`,
    [k.POSITION]: `Position`,
    [k.POSITIONS_PAGE_SUBTITLE]: `Here are all the Positions available in the system`,
    [k.POSITIONS_PAGE_TITLE]: `Positions`,
    [k.POSITIONS]: `Positions`,
    [k.POSTAL_CODE]: `Postal Code`,
    [k.POSTPONED]: `Postponed`,
    [k.POTENTIAL_CONSEQUENCES]: `Potential consequences`,
    [k.PRESENT]: `Present`,
    [k.PREVIEW]: `Preview`,
    [k.PREVIOUS_YEAR]: `Previous year`,
    [k.PREWRITTEN_REASONS]: `Pre-written reasons`,
    [k.PRIMARY_CONTACT_PHONE]: `Phone (Primary Contact)`,
    [k.PRINT]: `Print`,
    [k.PRIORITY]: `Priority`,
    [k.PRIVACY_POLICY]: `privacy policy`,
    [k.PROBABILITY_REDUCTION]: `Probability reduction`,
    [k.PROBABILITY]: `Probability`,
    [k.PROCESS_CHART]: `Process Chart`,
    [k.PROCESS_CHERT_FEATURE_DESCRIPTION]: `Visualize and optimize your processes with ease, enhancing operational efficiency.`,
    [k.PROCESS_STEP]: `Process Step`,
    [k.PROCESS]: `Process`,
    [k.PROCESSES]: `Processes`,
    [k.PROFESSIONAL_EXPERIENCE]: `Professional experience`,
    [k.PROFILE_SAVED]: `Profile saved!`,
    [k.PROFILE]: `Profile`,
    [k.PROGRESS_ARCHIVED]: `Progress archived`,
    [k.PROGRESS_DATE]: `Progress date`,
    [k.PROGRESS_OVER_TIME]: `Progress over time`,
    [k.PROGRESS_UNARCHIVED]: `Progress unarchived`,
    [k.PROGRESS_VALUE_CREATED]: `Progress value created`,
    [k.PROGRESS_VALUE_DELETED]: `Progress value deleted`,
    [k.PROGRESS_VALUE]: `Progress value`,
    [k.PROGRESS]: `Progress`,
    [k.PROMILLE]: `Promille`,
    [k.PROPERTIES]: `Properties`,
    [k.PROPERTY_REQUIRED]: `At least one property is required`,
    [k.PROPERTY_WILL_BE_VISIBLE_IN_PARENT_LIST]: `Property will be visible in parent list`,
    [k.PUBLISH]: `Publish`,
    [k.PUBLISHED_SHARED_STEPS]: `Published Shared Steps`,
    [k.PUBLISHED_TEMPLATES]: `Published Templates`,
    [k.PUBLISHED_WORKFLOWS]: `Published Templates`,
    [k.PUBLISHED]: `Published`,
    [k.PURCHASE_ORDER_ROW_STATUS_QUICK_INFORMATION]: `You can define unique names for each of your item statuses. Then you can use them when creating purchase orders.`,
    [k.PURCHASE_ORDERS_EDITOR_INFO]: `Can see all purchase orders, suppliers and items, add new purchase orders, create suppliers and items, edit, delete, archive and unarchive existing purchase orders.`,
    [k.PURCHASE_ORDERS_EDITOR]: `Purchase Orders - Editor`,
    [k.PURCHASE_ORDERS_FEATURE_DESCRIPTION]: `With this feature enabled, you can manage purchase orders of your company.`,
    [k.PURCHASE_ORDERS_OWNER_INFO]: `Can see all purchase orders, suppliers and items, add new purchase orders, create suppliers and items, edit, delete, archive and unarchive existing purchase orders.\nCan assign Purchase Orders permissions.`,
    [k.PURCHASE_ORDERS_OWNER]: `Purchase Orders - Owner`,
    [k.PURCHASE_ORDERS_SUBTITLE]: `Here are all the purchase orders available in the system`,
    [k.PURCHASE_ORDERS_VIEWER_INFO]: `Can see all purchase orders, suppliers and items.`,
    [k.PURCHASE_ORDERS_VIEWER]: `Purchase Orders - Viewer`,
    [k.PURPOSE]: `Purpose`,
    [k.QTY]: `Qty`,
    [k.QUALITY_DELIVERY_OF_PRODUCT]: `Quality - Delivery of Product`,
    [k.QUALITY_DELIVERY_OF_SERVICES]: `Quality - Delivery of Services`,
    [k.QUALITY_PRODUCT_FUNCTIONALITY]: `Quality - Product Functionality`,
    [k.QUALITY_PRODUCT_SAFETY]: `Quality - Product Safety`,
    [k.QUANTITY_TARGET]: `Quantity target`,
    [k.QUARTER_S]: `Quarter(s)`,
    [k.QUARTERLY]: `Quarterly`,
    [k.QUICK_INFORMATION]: `Quick Information`,
    [k.QUICK_RUN_NAME_PLACEHOLDER]: `Ex: Control for July`,
    [k.RATING]: `Rating`,
    [k.RE_OPEN]: `Re-open`,
    [k.READ_LESS]: `Read less...`,
    [k.READ_MORE]: `Read more...`,
    [k.READER]: `Reader`,
    [k.READY_P]: `Active`,
    [k.READY]: `Active`,
    [k.REALISTIC]: `Realistic?`,
    [k.RECORD]: `Record`,
    [k.RECUR_EVERY]: `Recur every`,
    [k.RECURRENCE_SHOULD_HAPPEN]: `Recurrence should happen`,
    [k.RECURRENCE_SUMMARY1]: `Schedule checklists on every `,
    [k.RECURRENCE_SUMMARY2]: `, starting `,
    [k.RECURRENCE_SUMMARY3]: ` and ending on `,
    [k.RECURRENCE_SUMMARY4]: `.\nIt contains `,
    [k.RECURRENCE_SUMMARY5]: ` checklists.\nEach estimated to take `,
    [k.RECURRENCE_SUMMARY6]: ` days, `,
    [k.RECURRENCE_SUMMARY7]: ` hours and `,
    [k.RECURRENCE_SUMMARY8]: ` minutes to complete.`,
    [k.RECURRING_CHECKLIST_DELETE_DESCRIPTION]: `Deleting a recurrence is the last action you take when something has gone wrong, if you want to keep a record of the recurrenc and it's checklists for any reason whatsoever you should keep it in the 'archived' state`,
    [k.RECURRING_CHECKLIST_DETAILS_SUBTITLE]: `Information about the recurring checklist`,
    [k.RECURRING_CHECKLIST_DETAILS]: `Recurring Checklist Details`,
    [k.RECURRING_CHECKLIST_PAGE_SUBTITLE]: `Recurring checklists repeat at a set interval. Expand each to see their runs`,
    [k.RECURRING_CHECKLIST]: `Recurring checklist`,
    [k.RED]: `Red`,
    [k.REDIRECT]: `Redirect`,
    [k.REDIRECTING_TO_HOME_PAGE]: `Redirecting to home page`,
    [k.REDUDANT_OPERATOR]: `Redudant operator`,
    [k.REF_PERSON]: `Ref. Person`,
    [k.REFERENCE_LINK]: `Reference Link`,
    [k.REFERENCE]: `Reference`,
    [k.REFRESH]: `Refresh`,
    [k.REGISTER_AND_REVIEW]: `Register and Review`,
    [k.REGISTER_IMPROVEMENT_FOR]: `Register improvement for`,
    [k.REGISTER_IMPROVEMENT]: `Register improvement`,
    [k.REGISTER_NEW_IMPROVEMENT]: `New Improvement`,
    [k.REGISTER]: `Register`,
    [k.REGISTERED]: `Registered`,
    [k.REGULAR_IMPROVEMENTS]: `Regular Improvement(s)`,
    [k.REJECTED]: `Rejected`,
    [k.RELEVANT_FOR_SUBPROCESS_ES]: `Relevant for subprocess(es)`,
    [k.REMEMBER_ME]: `Remember me`,
    [k.REMOVE_FROM_TEMPLATE]: `Remove from Template`,
    [k.REMOVE_SELF_STEP_INSTANCE_BODY1]: `If you remove team `,
    [k.REMOVE_SELF_STEP_INSTANCE_BODY2]: ` you will no longer be a part of this step.\n\nIf this is the last step you are assigned to this checklist will no longer show up on the checklist page.\n\nContact an user with mapper permission if you want add yourself again.`,
    [k.REMOVE_SELF_STEP_INSTANCE_TITLE]: `Remove yourself?`,
    [k.REMOVE_SELF_STEP_INSTANCE_YES]: `I understand the consequences, remove me`,
    [k.REMOVE]: `Remove`,
    [k.REMOVED_AS_INVOLVED_FROM]: `Removed as involved from`,
    [k.REMOVED]: `Removed`,
    [k.REORDER]: `Reorder`,
    [k.REPEAT_UNTIL]: `Repeat until`,
    [k.REPETITION_OF_OPERATORS]: `Repetition of operators`,
    [k.REPLACE_FILE]: `Replace File`,
    [k.REPLACE]: `Replace`,
    [k.REPORT_NEW_IMPROVEMENT_OR_TRY_DIFFERENT_SEARCH_KEYWORDS]: `Report new improvement or try different filters/search keywords`,
    [k.REPORTED_BY]: `Reported By`,
    [k.REPORTED_FROM]: `Reported From`,
    [k.REPORTED_IMPROVEMENT]: `Reported improvement`,
    [k.REPORTED_IMPROVEMENTS]: `Reported improvements`,
    [k.REPORTED]: `Reported`,
    [k.REPORTING_USERS_MANAGER]: `Reporting user's manager`,
    [k.REQUEST_TRANSLATION_MESSAGE]: `Hi! I would love AmpliFlow to support: `,
    [k.REQUEST_TRANSLATION]: `Request Translation`,
    [k.REQUIRED]: `Required`,
    [k.RESET_WONT_BE_SAVE_UNTIL_YOU_SAVE_SUB_PROCESS_PAGE]: `The Reset wont be saved until you save the sub process`,
    [k.RESET_ZOOM]: `Reset Zoom`,
    [k.RESET]: `Reset`,
    [k.RESOLVED_FULLY]: `Resolved fully`,
    [k.RESOLVED_PARTIALLY]: `Resolved partially`,
    [k.RESOURCES]: `Resources`,
    [k.RESPONSE_DETAILS]: `Response Details`,
    [k.RESPONSIBLE_MANAGER]: `Responsible Manager`,
    [k.RESPONSIBLE_USERS]: `Responsible User(s)`,
    [k.RESPONSIBLE]: `Responsible`,
    [k.RESTORE]: `Restore`,
    [k.RESTRICTED]: `Restricted`,
    [k.RESULT]: `Result`,
    [k.RESULTS]: `Results`,
    [k.RESUME]: `Resume`,
    [k.RETRY_MESSAGE]: `Retry`,
    [k.RETURN_TO]: `Return to`,
    [k.REVIEW_AND_SAVE_TIP1]: `Review your KPI and make any necessary changes. Once you are satisfied with your KPI, click on 'Save KPI' to save it and exit the wizard.`,
    [k.REVIEW_AND_SAVE]: `Review and Save`,
    [k.REVIEW_REPORTED_INFORMATION]: `Review reported information`,
    [k.REVIEW]: `Review`,
    [k.RISK_CLASSIFICATION_ENVIRONMENT]: `Risk classification environment`,
    [k.RISK_NO]: `Risk No.`,
    [k.RISK_REDUCTION]: `Risk reduction`,
    [k.RISK_SCENARIO]: `Risk scenario`,
    [k.RISK_SCORE]: `Risk score`,
    [k.RISKS]: `Risks`,
    [k.ROLE_FROM_TEAM]: `Team role(s) from`,
    [k.ROLE]: `Role`,
    [k.ROLES]: `Roles`,
    [k.RUN_EVERY]: `Start every`,
    [k.RUN_ID]: `Checklist ID`,
    [k.RUN_TEMPLATE_ON]: `Create checklist for`,
    [k.RUN]: `Start`,
    [k.RUNS_DONE]: `checklists finalized`,
    [k.RUNS_OF_THE_TEMPLATE]: `checklists started based on this template`,
    [k.SA_EDITOR_INFO]: `Can view, add and edit all appraisals.`,
    [k.SA_EDITOR]: `Staff Appraisals - Editor`,
    [k.SA_OWNER_INFO]: `Can view, add and edit all appraisals\nCan assign Staff Appraisal permissions.`,
    [k.SA_OWNER]: `Staff Appraisals - Owner`,
    [k.SA_USER_INFO]: `Can view, add, edit and archive their own appraisals, as well for their direct reporting users.\nThey can also view, edit and archive Appraisals where they are marked as Appraising manager.`,
    [k.SA_USER]: `Staff Appraisals - User`,
    [k.SALES_]: `sales`,
    [k.SATURDAY]: `Saturday`,
    [k.SAVE_ACTIVITY]: `Save activity`,
    [k.SAVE_AND_CLOSE]: `Save and close`,
    [k.SAVE_AND_PUBLISH]: `Save and publish`,
    [k.SAVE_AS_DRAFT]: `Save as draft`,
    [k.SAVE_DRAFT]: `Save draft`,
    [k.SAVE_FAILED]: `Save failed`,
    [k.SAVE]: `Save`,
    [k.SAVED_VALUES]: `Saved Values`,
    [k.SAVING]: `Saving`,
    [k.SCAN_BARCODE]: `Scan Barcode`,
    [k.SCAN_OR_ENTER_BARCODE]: `Scan or enter barcode`,
    [k.SCHEDULE_]: `schedule`,
    [k.SCHEDULE_COMMENT]: `Schedule comment`,
    [k.SCHEDULE_ENDS]: `Schedule ends`,
    [k.SCHEDULE_INFORMATION]: `Schedule information`,
    [k.SCHEDULE_OPTIONAL_COMMENT_MESSAGE]: `Optionally leave a comment for the schedule which can make it easier to identify it when viewing multiple recurring checklists`,
    [k.SCHEDULE_PAUSED_BY]: `Schedule paused by`,
    [k.SCHEDULE_RUNS_ON]: `Schedule checklists on`,
    [k.SCHEDULE_START]: `Schedule start`,
    [k.SCHEDULE_TYPE]: `Schedule type`,
    [k.SCHEDULE]: `Schedule`,
    [k.SCHEDULED_]: `scheduled`,
    [k.SCHEDULED_COURSES]: `Scheduled Courses`,
    [k.SCHEDULED]: `Scheduled`,
    [k.SCOPE_OF_MANAGEMENT]: `Scope of management system`,
    [k.SCORE_EXPLANATION]: `Score Explanation`,
    [k.SEARCH_CHECKLIST_TEMPLATE]: `Search checklist templates`,
    [k.SEARCH_CHECKLIST_TITLE]: `Search checklist titles`,
    [k.SEARCH_HERE]: `Search Here`,
    [k.SEARCH_IMPROVEMENT_FORM]: `Search improvement forms`,
    [k.SEARCH_MESSAGE]: `Search in message`,
    [k.SEARCH_NAME_AND_STATUS]: `Search name, status`,
    [k.SEARCH_NAME_OR_TEMPLATE]: `Checklist or template name`,
    [k.SEARCH_TEXTFIELDS]: `Search within text fields`,
    [k.SEARCH]: `Search`,
    [k.SECOND_]: `second`,
    [k.SECOND]: `Second`,
    [k.SEE_ALL]: `See all`,
    [k.SELECT_A_SUPPLIER_FIRST]: `Select a supplier first!`,
    [k.SELECT_BASE_FOR_KPI]: `Select what to make KPI for`,
    [k.SELECT_CHECKLIST_TEMPLATE]: `Select Checklist Template`,
    [k.SELECT_FILE]: `Select File`,
    [k.SELECT_FROM_LIBRARY]: `Select from library`,
    [k.SELECT_IMPROVEMENT_FORM]: `Select Improvement Form`,
    [k.SELECT_ITEM_TO_VIEW_THIS_FIELD]: `Select item to view this field`,
    [k.SELECT_KPI_TYPE]: `Select type of KPI`,
    [k.SELECT_KPI]: `Select KPI`,
    [k.SELECT_LATEST_VERSION]: `Select latest version`,
    [k.SELECT_STEPS_FOR_TEMPLATE]: `Create new step or Add an existing one from the library`,
    [k.SELECT_TEAMS]: `Select Teams`,
    [k.SELECT_TEMPLATE]: `Select template`,
    [k.SELECT_USER_TO_SHOW_PROFILE]: `Select user to show their profile`,
    [k.SELECT_USERS]: `Select Users`,
    [k.SELECT]: `Select`,
    [k.SELECTED_SCHEDULE_PREVIEW]: `Selected schedule preview`,
    [k.SELECTED_WEEK_DAYS_]: `selected week days`,
    [k.SEND_TEST_REQUEST]: `Send Test Request`,
    [k.SEND]: `Send`,
    [k.SEP]: `Sep`,
    [k.SEPTEMBER]: `September`,
    [k.SET_RESPONSIBLE_TIP1]: `Add users and teams who will be responsible for this KPI regardless of the general KPI permissions. You can remove them anytime.`,
    [k.SET_RESPONSIBLE]: `Set Responsible`,
    [k.SET_TARGETS_TIP1]: `Enter the target values for each month. Specify if being over the target is considered good or bad.`,
    [k.SET_TARGETS_TIP2]: `When you enter an value for one month, it will be repeated by default for coming months and years.  You can always update the value manually.`,
    [k.SET_TARGETS]: `Set Targets`,
    [k.SETTINGS]: `Settings`,
    [k.SHARED_STEP]: `Shared Step`,
    [k.SHARED_TEMPLATE_STEPS_ONCE_FEATURE_DESCRIPTION]: `Save commonly used steps and add them to multiple checklist templates.`,
    [k.SHARED]: `Shared`,
    [k.SHIPPING_COUNTRY]: `Shipping Country`,
    [k.SHIPPING_STATE_OR_PROVINCE]: `Shipping State/Province`,
    [k.SHIPPING]: `Shipping`,
    [k.SHOW_ALL]: `Show all`,
    [k.SHOW_ARCHIVED]: `Show archived`,
    [k.SHOW_COMPLETED]: `Show completed tasks`,
    [k.SHOW_DRAFT]: `Show draft`,
    [k.SHOW_DRAFTS]: `Show drafts`,
    [k.SHOW_IN_LIST]: `Show in list view`,
    [k.SHOW_IN_PROCESS_CHART]: `Show in process chart`,
    [k.SHOW_LIST]: `Show list`,
    [k.SHOW_PUBLISHED]: `Show published`,
    [k.SHOW_REFERENCED_PROPERTY_IN_PARENT_LIST]: `Show referenced property in parent list`,
    [k.SHOW_SIDEBAR]: `Show sidebar`,
    [k.SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED]: `Showing all except archived or scheduled`,
    [k.SHOWING_ALL_EXCEPT_SCHEDULED]: `Showing all except scheduled`,
    [k.SHOWING_IMPROVEMENTS_FOR]: `Showing improvements for`,
    [k.SHOWING_RECORDS]: `Showing records`,
    [k.SHOWING]: `Showing`,
    [k.SIGNIFICANT_ENVIRONMENTAL_ASPECT]: `Significant Environmental Aspect?`,
    [k.SINGLE_AD_HOC_CHECKLIST]: `Single ad-hoc checklist`,
    [k.SO_FAR]: `So far`,
    [k.SORTABLE]: `Sortable`,
    [k.SPECIFIC_CUSTOMER_S_WHO_REQUIRE_THIS]: `Specific customer(s) who require this`,
    [k.SPLIT]: `Split`,
    [k.SSO_FEATURE_DESCRIPTION]: `Enabling this feature enables you to enable SSO, so that users can log in to AmpliFlow with their existing credentials.`,
    [k.SSO_PAGE_TITLE]: `SSO Settings`,
    [k.SSO]: `SSO`,
    [k.STAFF_APPRAISAL_FEATURE_DESCRIPTION]: `This feature enables management of staff appraisals.`,
    [k.STAFF_APPRAISAL_PAGE_SUBTITLE]: `Here are all the staff appraisals available in the system`,
    [k.STAFF_APPRAISAL_PAGE_TITLE]: `Staff Appraisals`,
    [k.STAKEHOLDERS_EDITOR_INFO]: `Can see all stakeholders, add new stakeholders, edit, delete, archive and unarchive existing stakeholders.`,
    [k.STAKEHOLDERS_EDITOR]: `Stakeholders - Editor`,
    [k.STAKEHOLDERS_FEATURE_DESCRIPTION]: `With this feature enabled, you can manage identified stakeholders for each management standard relevant to your company.`,
    [k.STAKEHOLDERS_OWNER_INFO]: `Can see all stakeholders, add or import new stakeholders, edit, delete, archive and unarchive existing stakeholders.\n Can assign Stakeholders permissions.`,
    [k.STAKEHOLDERS_OWNER]: `Stakeholders - Owner`,
    [k.STAKEHOLDERS_SUBTITLE]: `Identified stakeholders for each management standard that are relevant for us`,
    [k.STAKEHOLDERS_VIEWER_INFO]: `Can see all stakeholders.`,
    [k.STAKEHOLDERS_VIEWER]: `Stakeholders - Viewer`,
    [k.STAKEHOLDERS]: `Stakeholders`,
    [k.STANDARD]: `Standard`,
    [k.START_CHECKLIST]: `Start Checklist`,
    [k.START_DATE_GREATER_THAN_END_DATE]: `Start date is greater than end date`,
    [k.START_DATE_TIME]: `Start`,
    [k.START_DATE]: `Start date`,
    [k.START_FIRST_RUN_AT_HINT_MESSAGE]: `* You can change the start after the schedule is published`,
    [k.START_FIRST_RUN_AT]: `Start first checklist at`,
    [k.START_MY_FREE_TRAIL]: `Start My Free Trial`,
    [k.START_THIS]: `Start This`,
    [k.START_TYPING_TO_SELECT_OR_CREATE]: `Start typing to select/create`,
    [k.START_VALUE]: `Start value`,
    [k.START_YOUR_FREE_14DAY_TRAIL]: `Start your free 14-day trial`,
    [k.START]: `Start`,
    [k.STARTED_AT]: `Started at`,
    [k.STARTED_BY]: `Started by`,
    [k.STARTED]: `Started`,
    [k.STARTING_]: `starting...`,
    [k.STARTING]: `Starting...`,
    [k.STARTS_AT]: `Starts at`,
    [k.STATE_OR_PROVINCE]: `State/Province`,
    [k.STATIC_ACTION_PLAN_INDEX]: `Static action plan index`,
    [k.STATIC_ACTION_PLAN_LABEL]: `Static action plan label`,
    [k.STATIC_ACTION_PLAN]: `Static action plan`,
    [k.STATIC_NUMBER_VALUE_IS_REQUIRED]: `Static number value is required.`,
    [k.STATIC_NUMBERS]: `Static numbers`,
    [k.STATUS_COMMENT]: `Status comment`,
    [k.STATUS_LABEL]: `Status`,
    [k.STATUS_NAME]: `Status Name`,
    [k.STATUS]: `Status`,
    [k.STEP_CONFIGURATION]: `Step configuration`,
    [k.STEP_DELETED]: `Step deleted`,
    [k.STEP_DETAILS]: `Step details`,
    [k.STEP_DRAFT_SAVED]: `Step draft saved`,
    [k.STEP_FINALIZED_BY]: `This step was finalized by`,
    [k.STEP_HISTORY]: `Step history`,
    [k.STEP_NAME_REQUIRED]: `Step name is required`,
    [k.STEP]: `Step`,
    [k.STEPS_DONE]: `Steps Finalized`,
    [k.STEPS_REQUIRED]: `At least one step is required`,
    [k.STEPS_SUBTITLE]: `Shared Steps are steps available for you to re-use in multiple Templates across the company`,
    [k.STEPS]: `Steps`,
    [k.STOP_EDITING]: `Stop editing`,
    [k.STOP_MATCHING_CHECKLISTS_LINE1]: `If you click stop, all the matching checklists for the search term/filters will be stopped immediatelly.`,
    [k.STOP_MATCHING_CHECKLISTS_LINE2]: `Are you really sure you want to stop all matching checklists of this query?`,
    [k.STOP_MATCHING_CHECKLISTS_QUESTION]: `Stop Matching Checklists?`,
    [k.STOP_MATCHING_CHECKLISTS]: `Stop Matching Checklists`,
    [k.STOP_MULTIPLE_CHECKLISTS_AT_ONCE]: `Stop multiple checklists at once`,
    [k.STOP_MULTIPLE_CHECKLISTS_AT_ONCE_FEATURE_DESCRIPTION]: `Allow users to stop multiple checklists simultaneously in the checklist report for better handling of critical events that impacts multiple active checklists.`,
    [k.STOP]: `Stop`,
    [k.STOPPED_]: `stopped`,
    [k.STOPPED_BY]: `Stopped by`,
    [k.STOPPED_IN_THE_LAST_7_DAYS]: `Stopped in the past 7 days`,
    [k.STOPPED_P]: `Stopped`,
    [k.STOPPED]: `Stopped`,
    [k.SUB_GOAL_DELETED]: `Sub Goal deleted`,
    [k.SUB_GOAL]: `Sub Goal`,
    [k.SUB_GOALS]: `Sub Goals`,
    [k.SUB_PROCESS_MARKED_DELETE]: `This sub process or containing processes is marked as deleted and can't be edited.`,
    [k.SUB_PROCESS_NOT_FOUND]: `The sub process could not be found`,
    [k.SUB_PROCESS]: `Sub Process`,
    [k.SUBJECT_AREA]: `Subject Area`,
    [k.SUBMIT_IMPOVEMENT]: `Submit Improvement`,
    [k.SUBMIT_NOTICE_WITH_USER_TO_NOTIFY]: `Submit the improvement to save and send a notification to `,
    [k.SUBMIT_NOTICE]: `Submit the improvement to save.`,
    [k.SUBMIT]: `Submit`,
    [k.SUCCESS_MESSAGE]: `Success`,
    [k.SUGGESTED_ENVIRONMENTAL_TARGET]: `Suggested Environmental Targets`,
    [k.SUMMARY_OF_HOW_WE_MEET_THE_REQUIREMENT]: `Summary of how we meet the requirement`,
    [k.SUMMARY_OF_LEGISLATION]: `Summary of Legislation`,
    [k.SUMMARY_OF_THE_REQUIREMENT_AND_OR_MOTIVATION_WHY_IT_IS_NOT_RELEVANT_TO_US]: `Summary of the requirement and/or motivation why it is not relevant to us`,
    [k.SUMMARY]: `Summary`,
    [k.SUNDAY]: `Sunday`,
    [k.SUPPLIER_DETAILS_SUBTITLE]: `View details and contacts of the selected supplier`,
    [k.SUPPLIER_DETAILS]: `Supplier Details`,
    [k.SUPPLIER_ID]: `SupplierID`,
    [k.SUPPLIER_LABEL]: `Supplier label`,
    [k.SUPPLIER_PLACEHOLDER]: `Supplier placeholder`,
    [k.SUPPLIER]: `Supplier`,
    [k.SUPPLIERS_EDITOR_INFO]: `Can see all suppliers and contacts, add new suppliers and contacts, edit, delete, archive and unarchive existing suppliers and contacts.`,
    [k.SUPPLIERS_EDITOR]: `Suppliers - Editor`,
    [k.SUPPLIERS_FEATURE_DESCRIPTION]: `With this feature enabled, you can keep track of your suppliers and your contacts.`,
    [k.SUPPLIERS_OWNER_INFO]: `Can see all suppliers and contacts, add or import new suppliers and contacts, edit, delete, archive and unarchive existing suppliers and contacts.\n Can assign Suppliers permissions.`,
    [k.SUPPLIERS_OWNER]: `Suppliers - Owner`,
    [k.SUPPLIERS_SUBTITLE]: `Here are all the suppliers available in the system`,
    [k.SUPPLIERS_VIEWER_INFO]: `Can see all suppliers and contacts.`,
    [k.SUPPLIERS_VIEWER]: `Suppliers - Viewer`,
    [k.SWEDISH_NAME]: `Swedish name`,
    [k.SWEDISH]: `Swedish`,
    [k.SYSTEM_PROPERTIES_MESSAGE]: `These are required system properties which can't be modified`,
    [k.SYSTEM_PROPERTIES]: `System Properties`,
    [k.SYSTEM]: `System`,
    [k.TAKE_PICTURE]: `Take Picture`,
    [k.TARGET_PREFERENCE]: `Target preference`,
    [k.TARGET_UNIT]: `Target unit`,
    [k.TARGET_URL]: `Target URL`,
    [k.TARGET_VALUE]: `Target value`,
    [k.TARGET]: `Target`,
    [k.TARGETS]: `Targets`,
    [k.TASK_COMPLETED]: `Task completed`,
    [k.TASK_CREATED]: `Task created`,
    [k.TASK_INDEX]: `Task index`,
    [k.TASK_LABEL]: `Task label`,
    [k.TASK_LIST_INDEX]: `Task list index`,
    [k.TASK_LIST_LABEL]: `Task list label`,
    [k.TASK_LIST]: `Task list`,
    [k.TASK_SUBTITLE]: `Tasks that are assigned to you.`,
    [k.TASK]: `Task`,
    [k.TASKS_ASSIGNED_TO_YOU]: `Task(s) assigned to you`,
    [k.TASKS_FEATURE_DESCRIPTION]: `This feature gives users access to the Tasks page where they can view and manage tasks assigned to them. Users will see tasks from Improvements, checklists, and goals.`,
    [k.TASKS]: `Tasks`,
    [k.TEAM_CAN_VIEW]: `Team Can View`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_CHECKLISTS]: `The team cannot be deleted as it is currently used by these checklists:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_GOALS]: `The team cannot be deleted as it is currently used by these goals:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_KPIS]: `The team cannot be deleted as it is currently used by these KPIs:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_MEASUREMENTS]: `The team cannot be deleted as it is currently used by these measurements:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_PROCESSES]: `The team cannot be deleted as it is currently used by these processes:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_STEPS]: `The team cannot be deleted as it is currently used by these steps:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_SUBPROCESSES]: `The team cannot be deleted as it is currently used by these sub processes:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_TEMPLATES]: `The team cannot be deleted as it is currently used by these templates:`,
    [k.TEAM_DELETED]: `Team deleted`,
    [k.TEAM_DETAILS_SUBTITLE]: `View details of the selected teams`,
    [k.TEAM_DETAILS]: `Team Details`,
    [k.TEAM_IS_USED_BY_THESE_CHECKLISTS]: `The team is used by these checklists:`,
    [k.TEAM_IS_USED_BY_THESE_GOALS]: `The team is used by these goals:`,
    [k.TEAM_IS_USED_BY_THESE_KPIS]: `The team is used by these KPIs:`,
    [k.TEAM_IS_USED_BY_THESE_MEASUREMENTS]: `The team is used by these measurements:`,
    [k.TEAM_IS_USED_BY_THESE_PROCESSES]: `The team is used by these processes:`,
    [k.TEAM_IS_USED_BY_THESE_STEPS]: `The team is used by these steps:`,
    [k.TEAM_IS_USED_BY_THESE_SUBPROCESSES]: `The team is used by these sub processes:`,
    [k.TEAM_IS_USED_BY_THESE_TEMPLATES]: `The team is used by these templates:`,
    [k.TEAM_REQUIRED]: `At least one team is required`,
    [k.TEAM_ROLES]: `Team Roles`,
    [k.TEAM_SUBTITLE]: `Here are all the teams available in the system`,
    [k.TEAM]: `Team`,
    [k.TEAMS_EDITOR_INFO]: `Can see all teams, add new teams, edit, delete, archive and unarchive existing teams.`,
    [k.TEAMS_EDITOR]: `Teams - Editor`,
    [k.TEAMS_OWNER_INFO]: `Can see all teams, add new or import teams, edit, delete, archive and unarchive existing teams.\nCan assign Teams permissions.`,
    [k.TEAMS_OWNER]: `Teams - Owner`,
    [k.TEAMS_VIEWER_INFO]: `Can see all non-archived teams.`,
    [k.TEAMS_VIEWER]: `Teams - Viewer`,
    [k.TEAMS_WHO_CAN_UPDATE_TARGETS]: `Teams who can update targets`,
    [k.TEAMS_WHO_WILL_WORK_ON_THIS_STEP]: `Teams who will work on this step`,
    [k.TEAM_WILL_BE_REMOVED]: `Team will be removed`,
    [k.TEAMS]: `Teams`,
    [k.TEMPLATE_DELETE_DESCRIPTION]: `Deleting a template is the last action you take when something has gone wrong, if you want to keep a record of the checklist for any reason whatsoever you should keep it in the 'archived' state`,
    [k.TEMPLATE_DELETED]: `Template deleted`,
    [k.TEMPLATE_DETAILS]: `Template Details`,
    [k.TEMPLATE_FORM_ERRORS]: `Template errors`,
    [k.TEMPLATE_INFORMATION]: `Template Information`,
    [k.TEMPLATE_LANGUAGE]: `Template Language`,
    [k.TEMPLATE_NAME_REQUIRED]: `Template name is required`,
    [k.TEMPLATE_NAME]: `Template Name`,
    [k.TEMPLATE_TO_SCHEDULE]: `Template to schedule`,
    [k.TEMPLATE]: `Template`,
    [k.TEMPLATES_SUBTITLE]: `Every documented template in your company`,
    [k.TEMPLATES]: `Templates`,
    [k.TENANT_NAME_ALREADY_EXISTS]: `Address already exists`,
    [k.TENANT_NAME_DESCRIPTION_NOTE]: `Note: If you change this address then the old address will stop working for all users immediately.`,
    [k.TENANT_NAME_DESCRIPTION]: `This address is unique to your organisation. When using a web browser, anyone from your company can access your instance of AmpliFlow by typing this URL directly.`, //Webbplatsadressen är unik för din organisation. Detta är adressen du och dina medarbetare använder för att komma åt AmpliFlow.
    [k.TENANT_NAME]: `Web Address`,
    [k.TENANT_SETTINGS]: `Settings`,
    [k.TENANT_SETTINGS_PAGE_SUBTITLE]: `Configuration option for the tenant`,
    [k.TEST_REQUEST]: `Test Request`,
    [k.TEXT_FILTER]: `Text Filter`,
    [k.TEXT]: `Text`,
    [k.THAT_]: `that`,
    [k.THAT]: `That`,
    [k.THE_]: `the`,
    [k.THE_USER_WONT_BE_NOTIFIED_ABOUT_THEIR_ACCOUNT_INFORMATION]: `The user won't be notified about changes to their account`,
    [k.THERE_ARE_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_LEAVE]: `There are unsaved changes. Are you sure you want to leave?`,
    [k.THERE_ARE_UNSAVED_CHANGES]: `There are unsaved changes`,
    [k.THERE_IS_NO_UNDO]: `There is no undo for this action!`,
    [k.THIRD_]: `third`,
    [k.THIRD]: `Third`,
    [k.THIS_ACTIVITY_HAS]: `This activity has`,
    [k.THIS_IMPOVEMENT_IS_CLOSED]: `This improvement is closed.`,
    [k.THIS_IS_CONTENT_OF_MOBILE_SIDEBAR]: `This is the content of mobile sidebar`,
    [k.THIS_MONTH]: `This month`,
    [k.THIS_REPORT_WAS_GENERATED_BY]: `This report was generated by`,
    [k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP_YOU_DONT_HAVE_PERMISSION_TO_SEE]: `This step is blocked by preceding step you don't have permission to see`,
    [k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP]: `This step is blocked by preceding step`,
    [k.THIS_STEP_IS_BLOCKING_ALL_FOLLOWING_STEPS_UNTIL_IT_HAS_BEEN_FINALIZED]: `This step is blocking all following steps until it has been finalized`,
    [k.THIS_STEP_IS_COMPLETED]: `This step is completed.`,
    [k.THIS_STEP_IS_FINALIZED]: `This step is finalized.`,
    [k.THIS_WEEK]: `This week`,
    [k.THRESHOLD_TYPE]: `Threshold type`,
    [k.THRESHOLD]: `Threshold`,
    [k.THURSDAY]: `Thursday`,
    [k.TIME]: `Time`,
    [k.TIMEFRAME_DESCRIPTION]: `Time period in which this measurement needs to be achieved.`,
    [k.TIMEFRAME]: `Timeframe`,
    [k.TIPS]: `Tips`,
    [k.TITLE]: `Title`,
    [k.TO_]: `to`,
    [k.TO_ADD_NEW_ONE_]: `to add a new one.`,
    [k.TO_COMPLETE]: `to complete`,
    [k.TO_START_CLICK_ON_ADD_NEW_BUTTON]: `To Start Click On Add New Button`,
    [k.TO]: `To,`,
    [k.TODAY]: `Today`,
    [k.TOMORROW]: `Tomorrow`,
    [k.TOS]: `TOS`,
    [k.TOTAL_IMPACT_RATING]: `Total Impact Rating`,
    [k.TOTAL_VALUES_FOR_ALL]: `Total values for all`,
    [k.TOTAL]: `Total`,
    [k.TRAINING_ACTIVITY]: `Training activity`,
    [k.TRAINING_PLAN_FEATURE_DESCRIPTION]: `This feature enables management of training plans.`,
    [k.TRAINING_PLAN_PAGE_SUBTITLE]: `Here are all the training plans available in the system`,
    [k.TRAINING_PLAN_PAGE_TITLE]: `Training Plans`,
    [k.TRAINING_PLANS_EDITOR_INFO]: `Can see all training plans, add new training plans, edit, delete, archive and unarchive existing training plans.`,
    [k.TRAINING_PLANS_EDITOR]: `Training Plans - Editor`,
    [k.TRAINING_PLANS_OWNER_INFO]: `Can see all training plans, add or import new training plans, edit, delete, archive and unarchive existing training plans.\nCan assign Training Plans permissions.`,
    [k.TRAINING_PLANS_OWNER]: `Training Plans - Owner`,
    [k.TRAINING_PLANS_VIEWER_INFO]: `Can see all training plans.`,
    [k.TRAINING_PLANS_VIEWER]: `Training Plans - Viewer`,
    [k.TRY_ALL_FEATURES_FOR_FREE_NO_CREDIT_CARD_REQUIRED]: `Try all features for free. No credit card required`,
    [k.TRY_DIFFERENT_FILTER_KEYWORDS]: `Try different filters/search keywords`,
    [k.TUESDAY]: `Tuesday`,
    [k.TYPE_OF_INTEREST]: `Type of interest`,
    [k.TYPE_OF_LEGISLATION]: `Type of Legislation`,
    [k.TYPE]: `Type`,
    [k.UN_ARCHIVE_IMPACT_GRADING]: `If you unarchive a impact area then it will be restored and all improvements and risks graded by the area will have their grade for the unarchived area restored as well.`,
    [k.UN_ARCHIVE_POSITION]: `If you un-archive this position it will be available for assignment to users.`,
    [k.UN_ARCHIVE]: `Un-Archive`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY1]: ` from team `,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY2]: ` are assigned to activities in this step. If you remove the team, these assignments will be removed.`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_TITLE]: `Assignments will be removed`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_YES]: `I understand the consequences, remove team`,
    [k.UNARCHIVE_THIS_AREA]: `Unarchive this area?`,
    [k.UNARCHIVE_THIS_GOAL]: `Unarchive this goal?`,
    [k.UNARCHIVE_THIS_KPI]: `Unarchive this KPI?`,
    [k.UNARCHIVE_THIS_MEASUREMENT]: `Unarchive this measurement?`,
    [k.UNARCHIVE_THIS_POSITION]: `Unarchive this position?`,
    [k.UNARCHIVE_THIS_PROGRESS_VALUE]: `Unarchive this progress value?`,
    [k.UNASSIGNED]: `Unassigned`,
    [k.UNCATEGORIESED]: `Uncategorised`,
    [k.UNDER_TARGET_IS_GOOD]: `Under target is good`,
    [k.UNDER_THRESHOLD]: `Under threshold`,
    [k.UNDO]: `Undo`,
    [k.UNFINALIZATION_OF_STEPS]: `Unfinalization of steps`,
    [k.UNFINALIZATION_OF_STEPS_FEATURE_DESCRIPTION]: `Allow users to unfinalize a step. An explanation of the reason will be required during the reversal process.`,
    [k.UNFINALIZE_STEP_CONFIRMATION_MESSAGE]: `Please enter your reason for unlocking. Explaining the reason will help others to understand why this step was unlocked. The reason written here will be saved in history and available to everyone who have access to this improvement.`,
    [k.UNFINALIZE]: `Unfinalize`,
    [k.UNFINALIZED_STEP_WITH_REASON]: `Unfinalized step with reason`,
    [k.UNIT]: `Unit`,
    [k.UNKNOWN]: `Unknown`,
    [k.UNTIL]: `Until`,
    [k.UPCOMING_STEPS]: `Upcoming steps`,
    [k.UPDATE_AVAILABLE]: `Update Available`,
    [k.UPDATE_END_DATE]: `Update end date`,
    [k.UPDATE_PROGRESS]: `Update progress`,
    [k.UPDATE_SUCCESSEDED]: `Update Successeded`,
    [k.UPDATE_VALUE]: `Update value`,
    [k.UPDATED_AT]: `Updated At`,
    [k.UPDATED_BY]: `Updated By`,
    [k.UPDATED_PROBABILITY]: `Updated probability`,
    [k.UPDATED_RISK_SCORE]: `Updated risk score`,
    [k.UPDATED]: `Updated`,
    [k.UPDATES]: `Updates`,
    [k.UPGRADE_CHECKLISTS_TITLE]: `Upgrade checklists?`,
    [k.UPLOAD_PHOTO]: `Upload photo`,
    [k.UPLOAD]: `Upload`,
    [k.UPLOADED_FILES_FOR_THE_RUN]: `Uploaded files and photos`,
    [k.URL]: `Url`,
    [k.USE_SHIPPING_ADDRESS]: `Use Shipping address`,
    [k.USE]: `Use`,
    [k.USER_ALLOW_COMPLETE_OTHES_TASKS]: `User won't be able to complete other steps after this, if this is marked as blocking.`,
    [k.USER_ALLOW_SELECT_MULTI]: `User will be able to input/select multiple values`,
    [k.USER_CAN_VIEW]: `User Can View`,
    [k.USER_DEFINED]: `User defined`,
    [k.USER_DETAILS_SUBTITLE]: `View details of the selected users`,
    [k.USER_DETAILS]: `User Details`,
    [k.USER_EDITOR_INFO]: `Can see all users, add new users, edit, delete, disable and activate existing users.`,
    [k.USER_EDITOR]: `User - Editor`,
    [k.USER_EMAIL]: `User e-mail`,
    [k.USER_ID]: `User ID`,
    [k.USER_NAME]: `User name`,
    [k.USER_OWNER_INFO]: `Can see all users, add or import new users, edit, delete, disable and activate existing users.\nCan assign User permissions.`,
    [k.USER_OWNER]: `User - Owner`,
    [k.USER_ROLES]: `User roles`,
    [k.USER_TASKS_FEATURE_DESCRIPTION]: `This feature enables users to add new tasks to any improvements, checklists, custom lists, and staff appraisals that they have access to that already contain a task list. User-defined tasks will include functionalities equivalent to those of the predefined checklist tasks, including options to assign tasks to other users and report improvements.`,
    [k.USER_TASKS]: `User tasks`,
    [k.USER_VIEWER_INFO]: `Can see all users.`,
    [k.USER_VIEWER]: `User - Viewer`,
    [k.USER]: `User`,
    [k.USERS_AND_TEAMS_WITH_ACCESS]: `Users and teams with access`,
    [k.USERS_ASSIGNED_TO_AN_ACTIVITY]: `Users assigned to an activity`,
    [k.USERS_CANNOT_BE_NOTIFIED_AND_NOT_NOTIFIED_AT_THE_SAME_TIME]: `Users cannot be notified and not notified at the same time`,
    [k.USERS_IN_THE_SYSTEM]: `Users in the system`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_SUB_PROCESS]: `Users responsible for the selected discovered in sub process`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS_STEP]: `Users responsible for the selected originated in process step`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS]: `Users responsible for the selected originated in process`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_SUB_PROCESS]: `Users responsible for the selected originated in sub process`,
    [k.USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS_STEP]: `Users responsible for the selected discovered in process step`,
    [k.USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS]: `Users responsible for the selected discovered in process`,
    [k.USERS_SUBTITLE]: `Here are all the users available in the system`,
    [k.USERS_WHO_CAN_UPDATE_TARGETS]: `Users who can update targets`,
    [k.USERS]: `Users`,
    [k.VALIDATE]: `Validate`,
    [k.VALUE_IS_BETWEEN_THRESHOLDS_OF]: `Value is between thresholds`,
    [k.VALUE_IS_BETWEEN]: `Value is between`,
    [k.VALUE_IS_OVER_THE_THRESHOLD_OF]: `Value is over the threshold`,
    [k.VALUE_IS_UNDER_THE_THRESHOLD_OF]: `Value is under the threshold`,
    [k.VALUE]: `Value`,
    [k.VALUES_COMMA_SEPERATED_LIKE_A_B_C]: `List the selectable options seperated by comas (like "one, two, three")`,
    [k.VARIABLE_NAME_REQUIRED]: `Variable name is required`,
    [k.VARIABLES_WITH_MANUAL_DATA_SOURCE]: `Variables with manual data source`,
    [k.VARIABLES]: `Variables`,
    [k.VAT_NUMBER]: `VAT Number`,
    [k.VERIFY_AND_CLOSE]: `Verify and close`,
    [k.VERIFY]: `Verify`,
    [k.VERIFYING]: `Verifying`,
    [k.VERSION]: `Version`,
    [k.VIEW_ALL_PROGRESSES]: `View all progresses`,
    [k.VIEW_ALL]: `View all`,
    [k.VIEW_AS_INFO]: `With View as, you can choose to see how other users or teams sees this page`,
    [k.VIEW_AS]: `View as`,
    [k.VIEW_BARCODE]: `View Barcode`,
    [k.VIEW_CHECKLISTS_THAT_CANT_BE_UPGRADED]: `View checklists that can't be upgraded`,
    [k.VIEW_GOAL]: `View Goal`,
    [k.VIEW_KPI]: `View KPI`,
    [k.VIEW_MEASUREMENT]: `View Measurement`,
    [k.VIEW_PHOTO]: `View Photo`,
    [k.VIEW_PURCHASE_ORDER_DETAILS_SUBTITLE]: `View details of the selected purchase order`,
    [k.VIEW_PURCHASE_ORDER_DETAILS]: `View Purchase Order Details`,
    [k.VIEW_SUB_GOAL]: `View Sub Goal`,
    [k.VIEW]: `View`,
    [k.VIEWER]: `Viewer`,
    [k.VISIT]: `Visit`,
    [k.VISUAL_SCHEDULE]: `Visual Schedule`,
    [k.WAITING_MESSAGE]: `Waiting...`,
    [k.WANT_TO_CLOSE_THIS_IMPROVEMENT]: `Want to close this improvement?`,
    [k.WEBHOOK_ADDED_MESSAGE]: `New webhook added successfully`,
    [k.WEBHOOK_DELETED]: `Webhook deleted`,
    [k.WEBHOOK_DETAILS]: `Webhook Details`,
    [k.WEBHOOK_DISABLED_MESSAGE]: `Webhook turned off`,
    [k.WEBHOOK_ENABLED_MESSAGE]: `Webhook turned on`,
    [k.WEBHOOK_ENABLED]: `Webhook Enabled`,
    [k.WEBHOOK_EVENT_PAGE_SUBTITLE]: `Here are all the webhook events available in the system`,
    [k.WEBHOOK_EVENT_PAGE_TITLE]: `Webhook Events`,
    [k.WEBHOOK_EVENTS]: `Webhook Events`,
    [k.WEBHOOK_FEATURE_DESCRIPTION]: `Enabling this feature allows integration of AmpliFlow with third-party systems using webhooks.`,
    [k.WEBHOOK_PAGE_SUBTITLE]: `Here are all the webhooks available in the system`,
    [k.WEBHOOK_PAGE_TITLE]: `Webhooks`,
    [k.WEBHOOK_TITLE]: `Webhook title`,
    [k.WEBHOOK_UPDATED_MESSAGE]: `Webhook updated successfully`,
    [k.WEBHOOKS_FOR_THIS_STEP]: `Webhooks for this step`,
    [k.WEBSITE]: `Website`,
    [k.WEDNESDAY]: `Wednesday`,
    [k.WEEK_S]: `Week(s)`,
    [k.WEEKLY]: `Weekly`,
    [k.WEEKS_]: `weeks`,
    [k.WELCOME_BACK]: `Welcome Back`,
    [k.WELL_BEING]: `Well-being`,
    [k.WHAT_IF]: `What if...`,
    [k.WHAT_IS_THE_IMPACT_FOR]: `What is the impact for`,
    [k.WHAT]: `What`,
    [k.WHEN_A_NEW_SUPPLIER_IS_CREATED_SELECTED_ITEMS_WILL_RESET_MESSAGE]: `When a new supplier is created - selected items will reset`,
    [k.WHEN_YOU_UN_ARCHIVE_A_EQUIPMENT_IT_WILL_BE_RESTORED_AND_SHOWN_UP_FOR_USER]: `When you un-archive a equipment it will be restored and show up for users`,
    [k.WHEN_YOU_UN_ARCHIVE_A_TEAM_IT_WILL_BE_RESTORED_AND_SHOW_UP_FOR_USERS]: `When you un-archive a team it will be restored and show up for users`,
    [k.WHICH_ARE_SCHEDULED_AND_HAVE_NOT_STARTED_YET]: `which are scheduled and have not started yet`,
    [k.WHO]: `Who`,
    [k.WHOLE_NUMBER]: `Whole number`,
    [k.WHY_GOAL_AT_RISK]: `Why the goal is at risk`,
    [k.WHY_GOAL_BLOCKED]: `Why the goal is blocked`,
    [k.WHY_MEASUREMENT_AT_RISK]: `Why the measurement is at risk`,
    [k.WHY_MEASUREMENT_BLOCKED]: `Why the measurement is blocked`,
    [k.WHY_NOT_ADD_SOME]: `Why not add some?`,
    [k.WHY]: `Why`,
    [k.WILL_BE_CREATED_OR_UNARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS]: `will be created or unarchived with following breakdown of checklist status`,
    [k.WILL_BE_REMOVED_OR_ARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS]: `will be removed or archived with following breakdown of checklist status`,
    [k.WILL_MAKE_THE_SCHEDULE_CONTAIN]: `will make the schedule contain`,
    [k.WORKDAY]: `workday-`,
    [k.WOULD_YOU_LIKE_TO_PROCEED_WITH_THE_UPGRADE]: `Would you like to proceed with the upgrade?`,
    [k.WRITE_A_COMMENT_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Write a comment...`,
    [k.WRITE_A_DESCRIPTION_FOR_THIS_IMPROVEMENT_FORM]: `Write a description for this improvement form...`,
    [k.WRITE_A_DESCRIPTION_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Write a description for this step...`,
    [k.WRITE_A_SUMMARY_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Write a summary for this template...`,
    [k.WWW_AMPLIFLOW_COM]: `www.AmpliFlow.com`,
    [k.YEAR_S_]: `year(s)`,
    [k.YEAR_S]: `Year(s)`,
    [k.YEAR]: `Year`,
    [k.YEARLY]: `Yearly`,
    [k.YELLOW]: `Yellow`,
    [k.YES_CONTINUE]: `Yes, continue`,
    [k.YES]: `Yes`,
    [k.YESTERDAY]: `Yesterday`,
    [k.YOU_ARE_NOT_ABLE_TO_COPY_UNTIL_PUBLISH]: `You can only copy published template.`,
    [k.YOU_CAN_ADD_STATIC_OR_TASK_LIST_ACTION_PLAN]: `You can add static information or a task list.`,
    [k.YOU_CAN_ADD_SUBGOALS_FOR_ANY_GOAL]: `You can add subgoals for any goal.`,
    [k.YOU_CAN_ADD_TWO_TYPES_OF_ACTION_PLAN]: `You can add two types of action plan.`,
    [k.YOU_SHOULD_BE_PART_OF_THIS_TEAM_TO_SEE_THE_CHECKLISTS]: `You should be part of this team to see theChecklist(s)`,
    [k.YOUR_ROLES]: `Your Roles`,
    [k.YOUR_TEAMS]: `Your Teams`,
};
