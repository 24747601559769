import "./CustomerIcon.scss";

import React from "react";
import { RiVipCrown2Fill } from "react-icons/ri";

const CustomerIcon: React.FC = () => {
    return <RiVipCrown2Fill className="icon-customer" />;
};

export default CustomerIcon;
