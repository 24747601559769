import React from "react";

import PropertyListItem from "./PropertyListItem";
import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import ITableSet from "http/ITableSet";
import { ICustomListOptionDto } from "components/custom-list-page/api/ICustomListOptionDto";
import Tooltip from "common/components/tooltip/Tooltip";

export interface ICustomListPropertyListProps {
    properties: ITableSet<ICustomListOptionDto>;

    onSelect: (id: string, title: string) => void;
}

const CustomListPropertyList: React.FC<ICustomListPropertyListProps> = (
    props,
) => {
    const { properties } = props;

    return (
        <React.Fragment>
            {properties.ids.map((parentId) => {
                const values = properties.values[parentId];

                if (values) {
                    return (
                        <Tooltip
                            key={parentId}
                            id={parentId}
                            message={
                                <>
                                    <span>{values.name}</span>
                                    <br />
                                    <span>{values.description}</span>
                                </>
                            }
                            variant="no-flex"
                        >
                            <PropertyListItem
                                key={parentId}
                                type={values.id}
                                icon={<CustomListIcon showBackground />}
                                title={values.name}
                                subTitle={values.description}
                                onSelect={props.onSelect}
                            />
                        </Tooltip>
                    );
                }
            })}
        </React.Fragment>
    );
};

export default CustomListPropertyList;
