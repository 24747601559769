import "./MeasurementStatusComment.scss";

import { PropsWithRef, RefObject, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { BiBlock } from "react-icons/bi";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";
import { Button } from "../buttons";
import { FiCheck } from "react-icons/fi";

interface IProps {
    status?: EMeasurementGrading;
    comment?: string;
    editMode?: boolean;
    canEdit?: boolean;
    inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
    onChangeStatusComment?: (e: IInputActionChangeEvent<string>) => void;
    onSaveStatusComment?: () => void;
}

const MeasurementStatusComment = (props: PropsWithRef<IProps>) => {
    const { status, editMode, canEdit, comment, inputRef } = props;

    const [isFocused, setIsFocused] = useState(false);

    const { t } = useTranslation();

    const hidden =
        status === EMeasurementGrading.OnTrack ||
        status === EMeasurementGrading.Completed ||
        status === EMeasurementGrading.Draft;

    const handleOnFocus = () => {
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        setIsFocused(false);

        if (!editMode) {
            props.onSaveStatusComment?.();
        }
    };

    const isBlocked = status === EMeasurementGrading.Blocked;

    return (
        <div
            className={
                "measurement-status-comment" +
                (isBlocked ? " blocked" : " at-risk") +
                (hidden ? " hidden" : "")
            }
        >
            {status === EMeasurementGrading.Blocked && (
                <>
                    <div className="measurement-status-comment--icon">
                        <BiBlock />
                    </div>
                    <div className="measurement-status-comment--title">
                        {t(k.WHY_MEASUREMENT_BLOCKED)}:
                    </div>
                </>
            )}
            {status === EMeasurementGrading.AtRisk && (
                <div className="measurement-status-comment--title">
                    {t(k.WHY_MEASUREMENT_AT_RISK)}:
                </div>
            )}
            <ActionTextField
                id="measurement-status-comment"
                className={`status-comment-input${
                    !isFocused && !editMode ? " inactive" : ""
                }${isBlocked ? " blocked" : " at-risk"}`}
                inputRef={inputRef}
                placeholder={t(k.NO_COMMENT_PROVIDED)}
                testId="measurement-status-comment"
                name="comment"
                fullWidth
                editMode={editMode}
                disabled={!canEdit}
                value={comment ?? ""}
                onChange={props.onChangeStatusComment}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
            />
            {!editMode && isFocused && (
                <Button
                    transparent
                    size="small"
                    variant="white"
                    className="dummy-btn"
                >
                    <FiCheck />
                </Button>
            )}
        </div>
    );
};

export default MeasurementStatusComment;
