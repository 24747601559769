export enum CustomListPropertyEnum {
    None,
    Value,
    Text,
    Tasklist,
    DateTime,
    Photo,
    Selection,
    Customers,
    Suppliers,
    Items,
    Barcodes,
    GenerateBarcode,
    Hyperlink,
    Users,
    Positions,
    Competencies,
}
