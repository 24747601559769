import "./SortableList.scss";

import React from "react";
import { FaBars } from "react-icons/fa";
import {
    SortableContainer,
    SortableElement,
    SortableElementProps,
    SortableHandle,
} from "react-sortable-hoc";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Textfield from "common/components/input-components/textfield/Textfield";
import { DeleteIcon } from "common/components/icons";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValidationMessages from "common/viewModels/IValidationMessages";

export const SortableList = SortableContainer<{ children: React.ReactNode }>(
    (props: { children: React.ReactNode }) => <div>{props.children}</div>,
);

interface ISortableListItemProps extends SortableElementProps {
    value: IValueLabelItem<string, string>;
    errors: IValidationMessages;

    isDisabled: boolean;

    onDelete: (id: string) => void;
    onChange: (id: string, label: string) => void;
}

export const SortableListItem = SortableElement<ISortableListItemProps>(
    (props: ISortableListItemProps) => {
        const { value, isDisabled, errors } = props;

        const id = value.value;

        const { t } = useTranslation();

        const handleDelete = () => {
            if (isDisabled) {
                return;
            }

            props.onDelete(id);
        };

        const handleChange = (label: string) => {
            if (isDisabled) {
                return;
            }

            props.onChange(id, label);
        };

        return (
            <div className="sortable-list-item">
                <div className="sortable-list-item__drag-handle">
                    <DragHandle />
                </div>

                <div className="sortable-list-item__input">
                    <Textfield
                        id={`form.${id}`}
                        value={value.label}
                        placeholder={t(k.LABEL)}
                        size="large"
                        bold
                        autoCompleteOff
                        disabled={isDisabled}
                        invalid={Boolean(errors[id])}
                        helperText={
                            <Textfield.Error>{errors[id]}</Textfield.Error>
                        }
                        onChange={handleChange}
                    />
                </div>

                <button
                    className="btn-delete"
                    disabled={isDisabled}
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </button>
            </div>
        );
    },
);

const DragHandle = SortableHandle(() => <FaBars />);
