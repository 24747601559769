import "./PageWrapper.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { useSelector } from "react-redux";

import k from "i18n/keys";

import { IMenuOption } from "../buttons/menu-button/IMenuOption";
import { CustomListPermissionSettingsModal } from "../permission-settings/CustomListPermissionSettingsModal";
import Button from "common/components/buttons/Button";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import { KebabIcon } from "common/components/icons";
import { PermissionSettingsModal } from "common/components/permission-settings/PermissionSettingsModal";
import { useCustomListPermissions } from "components/custom-list-page/api/hooks";
import { EPages } from "components/roles/api/EPages";
import { Permissions } from "components/roles/api/Permissions";
import { IAppState } from "store/IAppState";

interface IProps {
    className?: string;

    title?: React.ReactNode;
    spaceBetween?: boolean;

    rightOfTitle?: React.ReactNode;
    leftOfTitle?: React.ReactNode;
    subtitle?: React.ReactNode;

    backToDisabled?: boolean;
    backTo?: string;
    backLabelContent?: React.ReactNode;

    rightSideContent?: React.ReactNode;

    permissionForPage?: EPages;
    permissionsForCustomListId?: string;
    historyForCustomListId?: string;

    menuOptions?: IMenuOption[];

    onMenuSelect?: (selectedId: string) => void;
}

const PERMISSION_SETTINGS = "permission_settings";

const PageWrapper: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        className,
        title,
        rightOfTitle,
        leftOfTitle,
        subtitle,
        backTo,
        backToDisabled,
        backLabelContent,
        spaceBetween,
        rightSideContent,
        permissionForPage,
        permissionsForCustomListId,
        historyForCustomListId,
    } = props;

    const { t, i18n } = useTranslation();

    const { data: customListPermissions } = useCustomListPermissions(
        permissionsForCustomListId,
    );

    const isRoleAllowSetPermissions = useSelector((appState: IAppState) => {
        if (
            permissionForPage === undefined ||
            permissionForPage === EPages.CUSTOM_LIST
        ) {
            return false;
        }

        if (appState.authViewState.roles.ADMINISTRATOR ?? false) {
            return true;
        }

        const roles = Permissions[permissionForPage].allowSetPermissionsRoles;

        for (const role of roles) {
            if (role in appState.authViewState.roles) {
                return true;
            }
        }

        return false;
    });

    const canSetPermissions =
        permissionForPage === EPages.CUSTOM_LIST
            ? customListPermissions?.isOwner
            : isRoleAllowSetPermissions;

    const [showPermissionSettings, setShowPermissionSettings] =
        React.useState(false);

    const menuOptionsWithPermission = React.useMemo(() => {
        const permissionOptions: IMenuOption = {
            id: PERMISSION_SETTINGS,
            label: t(k.PERMISSION_SETTINGS),
        };

        return (props.menuOptions ?? []).concat(permissionOptions);
    }, [i18n.language, props.menuOptions]);

    const handleOnMenuSelect = (selectedId: string) => {
        if (selectedId === PERMISSION_SETTINGS) {
            setShowPermissionSettings(true);
        } else {
            props.onMenuSelect?.(selectedId);
        }
    };

    const handleClosePermissionSettings = () => {
        setShowPermissionSettings(false);
    };

    const showMenu =
        (props.menuOptions && props.menuOptions.length > 0) ||
        canSetPermissions;

    return (
        <React.Fragment>
            <div
                className={
                    className ? `page-wrapper ${className}` : "page-wrapper"
                }
            >
                <div className="page-wrapper--header">
                    <div className="page-wrapper--left-side">
                        {title === undefined ? undefined : (
                            <div
                                className={
                                    spaceBetween
                                        ? "page--title space-between"
                                        : "page--title"
                                }
                            >
                                {leftOfTitle}
                                <h1>{title}</h1>
                                {rightOfTitle}
                            </div>
                        )}

                        {subtitle && <h3 className="subtitle">{subtitle}</h3>}

                        {backTo && (
                            <div className="page-wrapper--navigation">
                                <Button
                                    to={backTo}
                                    testId="backBtn"
                                    disabled={backToDisabled}
                                    size="small"
                                    transparent
                                    icon={<BiChevronLeft />}
                                >
                                    {backLabelContent ??
                                        t(k.BACK_TO_PREVIOUS_PAGE)}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="page-wrapper--right-side">
                        {rightSideContent}

                        {showMenu && (
                            <MenuButton
                                icon={<KebabIcon variant="blue" />}
                                onSelect={handleOnMenuSelect}
                                options={
                                    canSetPermissions
                                        ? menuOptionsWithPermission
                                        : props.menuOptions
                                }
                                testId="page-wrapper__menu-btn"
                            />
                        )}
                    </div>
                </div>
                {props.children}
            </div>

            {showPermissionSettings && permissionForPage && (
                <React.Fragment>
                    {permissionForPage !== EPages.CUSTOM_LIST &&
                        isRoleAllowSetPermissions && (
                            <PermissionSettingsModal
                                permissionForPage={permissionForPage}
                                onClose={handleClosePermissionSettings}
                            />
                        )}

                    {permissionForPage === EPages.CUSTOM_LIST &&
                        customListPermissions?.isOwner &&
                        permissionsForCustomListId && (
                            <CustomListPermissionSettingsModal
                                customListId={permissionsForCustomListId}
                                customListParentId={historyForCustomListId}
                                onClose={handleClosePermissionSettings}
                            />
                        )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PageWrapper;
