import k from "i18n/keys";
import i18n from "i18next";
import Fuse, { FuseResult } from "fuse.js";

import { EGoalGrading } from "../api/EGoalGrading";
import { IGoalDTO } from "../api/IGoalDTO";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import { ITableRowWithChildren } from "../components/GoalsTable/GoalTable";
import { GoalAccessRoleEnum } from "../api/EGoalAccessRole";
import _ from "lodash";
import { EGoalFilterStatus } from "../api/EGoalFilterStatus";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";
import ITableSet from "http/ITableSet";
import { IMeasurementDTO } from "components/measurement/api/IMeasurementDTO";

interface SearchableItem {
    type: "goal" | "measurement";
    id: string;
    parentId?: string;
    name: string;
}

export const goalStatuses = {
    [EGoalGrading.OnTrack]: k.ON_TRACK,
    [EGoalGrading.Behind]: k.BEHIND,
    [EGoalGrading.AtRisk]: k.AT_RISK,
    [EGoalGrading.Blocked]: k.BLOCKED,
    [EGoalGrading.Completed]: k.COMPLETED,
    [EGoalGrading.Draft]: k.DRAFT,
    [EGoalFilterStatus.Archived]: k.ARCHIVED,
};

export const decomposeGoalAccessRole = (accessRole?: GoalAccessRoleEnum) => {
    let canView = undefined;
    let canEditInPreview = undefined;
    let canEdit = undefined;
    let canCreate = undefined;
    let canEverything = undefined;

    switch (accessRole) {
        case GoalAccessRoleEnum.CanEverything:
            canEverything = true;
        case GoalAccessRoleEnum.CanCreate:
            canCreate = true;
        case GoalAccessRoleEnum.CanEdit:
            canEdit = true;
        case GoalAccessRoleEnum.CanEditInPreview:
            canEditInPreview = true;
        case GoalAccessRoleEnum.CanView:
            canView = true;
            break;
    }

    return { canView, canEditInPreview, canEdit, canCreate, canEverything };
};

export const goalFilterStatusOptions = (i18next: typeof i18n) => [
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.OnTrack]),
        value: EGoalFilterStatus.OnTrack,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.Behind]),
        value: EGoalFilterStatus.Behind,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.AtRisk]),
        value: EGoalFilterStatus.AtRisk,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.Blocked]),
        value: EGoalFilterStatus.Blocked,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.Completed]),
        value: EGoalFilterStatus.Completed,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.Draft]),
        value: EGoalFilterStatus.Draft,
    },
    {
        label: i18next.t(goalStatuses[EGoalFilterStatus.Archived]),
        value: EGoalFilterStatus.Archived,
    },
];

export const allGoalFilterStatuses = [
    EGoalFilterStatus.OnTrack,
    EGoalFilterStatus.Behind,
    EGoalFilterStatus.AtRisk,
    EGoalFilterStatus.Blocked,
    EGoalFilterStatus.Completed,
    EGoalFilterStatus.Draft,
    EGoalFilterStatus.Archived,
];

export const goalFilterStatusesWithoutArchived = [
    EGoalFilterStatus.OnTrack,
    EGoalFilterStatus.Behind,
    EGoalFilterStatus.AtRisk,
    EGoalFilterStatus.Blocked,
    EGoalFilterStatus.Completed,
    EGoalFilterStatus.Draft,
];

export function goalStatusToGoalFilterStatus(
    status?: EGoalGrading,
    isArchived?: boolean,
) {
    if (isArchived) {
        return EGoalFilterStatus.Archived;
    }

    switch (status) {
        case EGoalGrading.OnTrack:
            return EGoalFilterStatus.OnTrack;
        case EGoalGrading.Behind:
            return EGoalFilterStatus.Behind;
        case EGoalGrading.AtRisk:
            return EGoalFilterStatus.AtRisk;
        case EGoalGrading.Blocked:
            return EGoalFilterStatus.Blocked;
        case EGoalGrading.Completed:
            return EGoalFilterStatus.Completed;
        case EGoalGrading.Draft:
            return EGoalFilterStatus.Draft;
    }
}

export function measurementStatusToGoalFilterStatus(
    status?: EMeasurementGrading,
    isArchived?: boolean,
) {
    if (isArchived) {
        return EGoalFilterStatus.Archived;
    }

    switch (status) {
        case EMeasurementGrading.OnTrack:
            return EGoalFilterStatus.OnTrack;
        case EMeasurementGrading.Behind:
            return EGoalFilterStatus.Behind;
        case EMeasurementGrading.AtRisk:
            return EGoalFilterStatus.AtRisk;
        case EMeasurementGrading.Blocked:
            return EGoalFilterStatus.Blocked;
        case EMeasurementGrading.Completed:
            return EGoalFilterStatus.Completed;
        case EMeasurementGrading.Draft:
            return EGoalFilterStatus.Draft;
    }
}

export function filterGoalsByStatus(
    goals: ITableSetWithOptions<IGoalDTO, string>,
    statuses?: EGoalFilterStatus[],
) {
    if (statuses === undefined || statuses.length === 0) {
        return {
            filteredGoals: goals,
        };
    }

    const filteredIds = goals.ids.filter((id) => {
        const goal = goals.values[id];

        if (!goal) return false;

        const goalStatus = goalStatusToGoalFilterStatus(
            goal.status,
            !!goal.archivedAt,
        );

        if (goalStatus === undefined) return false;

        return statuses.includes(goalStatus);
    });

    const filteredGoals: ITableSetWithOptions<IGoalDTO, string> = {
        ids: filteredIds,
        options: [],
        values: {},
    };

    filteredGoals.ids.forEach((id) => {
        const goal = _.cloneDeep(goals.values[id]);

        if (goal) {
            const goalMeasurements = goal.measurements?.filter(
                (measurement) => {
                    const measurementStatus =
                        measurementStatusToGoalFilterStatus(
                            measurement.status,
                            !!measurement.archivedAt,
                        );

                    if (measurementStatus === undefined) return false;

                    return statuses.includes(measurementStatus);
                },
            );

            goal.measurements = goalMeasurements;

            filteredGoals.values[id] = goal;
        }
    });

    filteredGoals.ids.forEach((id) => {
        const goal = filteredGoals.values[id];
        if (
            !goal?.parentGoalsIds?.length ||
            !goal?.parentGoalsIds.some(
                (parentId) => filteredGoals.values[parentId],
            )
        ) {
            filteredGoals.options.push(id);
        }
    });

    return { filteredGoals };
}

export function filterMeasurementsByStatus(
    measurements: ITableSet<IMeasurementDTO>,
    statuses?: EGoalFilterStatus[],
) {
    if (statuses === undefined || statuses.length === 0) {
        return {
            filteredMeasurements: measurements,
        };
    }

    const filteredIds = measurements.ids.filter((id) => {
        const measurement = measurements.values[id];

        if (!measurement) return false;

        const measurementStatus = measurementStatusToGoalFilterStatus(
            measurement.status,
            !!measurement.archivedAt,
        );

        if (measurementStatus === undefined) return false;

        return statuses.includes(measurementStatus);
    });

    const filteredMeasurements: ITableSet<IMeasurementDTO> = {
        ids: filteredIds,
        values: {},
    };

    filteredMeasurements.ids.forEach((id) => {
        const measurement = _.cloneDeep(measurements.values[id]);

        if (measurement) {
            filteredMeasurements.values[id] = measurement;
        }
    });

    return { filteredMeasurements };
}

export function filterGoalsByKeywordSearch(
    goals: ITableSetWithOptions<IGoalDTO, string>,
    keyword?: string,
) {
    if (!keyword || keyword.trim() === "") {
        return {
            filteredGoals: goals,
            openRows: [],
            matchedSubstrings: {},
            wasModified: false,
        };
    }

    const searchableItems: SearchableItem[] = [];
    Object.values(goals.values).forEach((goal) => {
        if (!goal) return;
        searchableItems.push({
            type: "goal",
            id: goal?.id,
            name: goal?.name,
        });

        goal?.measurements?.forEach((measurement) => {
            if (!measurement.name) return;
            searchableItems.push({
                type: "measurement",
                id: measurement.id,
                parentId: goal.id,
                name: measurement.name,
            });
        });
    });

    const fuse = new Fuse(searchableItems, {
        keys: ["name"],
        includeScore: true,
        includeMatches: true,
        ignoreFieldNorm: true,
        ignoreLocation: true,
        findAllMatches: true,
        threshold: 0.3,
    });

    const results = fuse.search(keyword);
    const matchedSubstrings = processMatches(results);

    const matchedIds = results.reduce<string[]>((acc, { item }) => {
        if (item.parentId) {
            acc.push(item.parentId);
        } else {
            acc.push(item.id);
        }
        return acc;
    }, []);

    let filteredIds = getFilteredKeywordSearchDataIds(goals, matchedIds);

    const filteredGoals: ITableSetWithOptions<IGoalDTO, string> = {
        ids: filteredIds,
        options: [],
        values: {},
    };

    filteredGoals.ids.forEach((id) => {
        const goal = goals.values[id];

        const goalCopy = _.cloneDeep(goal);

        if (goalCopy && matchedIds.includes(id)) {
            goalCopy.isMatching = true;
        }

        filteredGoals.values[id] = goalCopy;
    });

    filteredGoals.ids.forEach((id) => {
        const goal = filteredGoals.values[id];
        if (
            !goal?.parentGoalsIds?.length ||
            !goal?.parentGoalsIds.some(
                (parentId) => filteredGoals.values[parentId],
            )
        ) {
            filteredGoals.options.push(id);
        }
    });

    const openRows = getExpandedGoalsIds(filteredGoals, matchedIds);

    return { filteredGoals, openRows, matchedSubstrings, wasModified: true };
}

export function sortGoalsOptionByIndex(
    goals: ITableSetWithOptions<IGoalDTO, string>,
) {
    const sortedOptions = goals.options.sort((a, b) => {
        const goalA = goals.values[a];
        const goalB = goals.values[b];

        return (goalA?.index ?? 0) - (goalB?.index ?? 0);
    });

    return { ...goals, options: sortedOptions };
}

export const processMatches = (results: FuseResult<{ id: string; name: string } | undefined>[]) => {
    return results.reduce<Record<string, string | undefined>>((acc, result) => {
        const itemId = result.item?.id;

        if (itemId) {
            result.matches?.forEach((match) => {
                let longestMatch = match.indices[0];
                let maxLength = longestMatch[1] - longestMatch[0];

                for (let i = 1; i < match.indices.length; i++) {
                    const currentLength =
                        match.indices[i][1] - match.indices[i][0];
                    if (currentLength > maxLength) {
                        longestMatch = match.indices[i];
                        maxLength = currentLength;
                    }
                }

                const startIndex = longestMatch[0];
                const endIndex = longestMatch[1];
                const matchedSubString = match.value?.substring(
                    startIndex,
                    endIndex + 1,
                );

                acc[itemId] = matchedSubString;
            });
        }
        return acc;
    }, {});
};

const getExpandedGoalsIds = (
    goals: ITableSetWithOptions<IGoalDTO, string>,
    matchingIds: string[],
) => {
    const fullPathIds: ITableRowWithChildren[] = [];

    const buildFullPath = (
        goalId: string,
        goals: ITableSetWithOptions<IGoalDTO, string>,
        matchingIds: string[],
        level = 0,
        path = "",
    ) => {
        const goal = goals?.values[goalId];
        if (!goal) return;

        const newPath = path ? `${path}_${goalId}` : goalId;

        const row: ITableRowWithChildren = {
            id: newPath,
            level: level,
            childIds: [],
        };

        let childMatches = false;

        for (const childId of goal.subGoalsIds ?? []) {
            row.childIds?.push(childId);

            if (
                satisfyKeywordSearch(childId, matchingIds) ||
                buildFullPath(childId, goals, matchingIds, level + 1, newPath)
            ) {
                childMatches = true;
            }
        }

        if (childMatches) {
            fullPathIds.push(row);
        }

        return childMatches;
    };

    for (const goalId of goals.options) {
        buildFullPath(goalId, goals, matchingIds);
    }

    return fullPathIds;
};

const getFilteredKeywordSearchDataIds = (
    goals: ITableSetWithOptions<IGoalDTO, string>,
    matchingIds: string[],
) => {
    const goalParentsIdsByKeyword: Set<string> = new Set();
    const goalIdsMatchKeyword: Set<string> = new Set();

    for (const goalId of goals.ids) {
        if (satisfyKeywordSearch(goalId, matchingIds)) {
            goalIdsMatchKeyword.add(goalId);

            if (goalParentsIdsByKeyword.add(goalId)) {
                addChildrenAsChecked(goalId, goals, goalParentsIdsByKeyword);
            }
        }
    }

    const filteredIds = Array.from(goalParentsIdsByKeyword);

    return filteredIds;
};

const satisfyKeywordSearch = (goalId: string, matchingIds: string[]) => {
    return matchingIds.includes(goalId);
};

const addChildrenAsChecked = (
    goalId: string,
    goals: ITableSetWithOptions<IGoalDTO, string>,
    checkedGoalIds: Set<string>,
) => {
    const currentGoal = goals.values[goalId];

    if (currentGoal && currentGoal.subGoalsIds) {
        for (const subGoalId of currentGoal.subGoalsIds) {
            if (checkedGoalIds.has(subGoalId)) {
                continue;
            }

            checkedGoalIds.add(subGoalId);

            addChildrenAsChecked(subGoalId, goals, checkedGoalIds);
        }
    }
};
