import "./LabelWithError.scss";

import React from "react";

import { FiAlertOctagon } from "react-icons/fi";

interface IProps {
    label: React.ReactNode;

    error?: string | string[] | null;
}

const LabelWithError: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { label, error } = props;

    return (
        <React.Fragment>
            {label}

            {error && (
                <span className="label-with-error--invalid-message">
                    <FiAlertOctagon />

                    {error}
                </span>
            )}
        </React.Fragment>
    );
};

export default LabelWithError;
