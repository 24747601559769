import ModalContent from "common/components/modal-content/ModalContent";
import { IModalListItem } from "../../api/IModalManager";

interface IProcessStepModalPortalProps {
    modalItem: IModalListItem;
}

const ProcessStepModalPortal = (props: IProcessStepModalPortalProps) => {
    return (
        <ModalContent
                    className="modal-manager--modal-content--portal"
                    id={props.modalItem.callId}
                    isPortal
                />
    );
};

export default ProcessStepModalPortal;