import "../RecurringChecklistForm.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IRecurringChecklist from "../api/IRecurringChecklist";
import { format } from "date-fns";
import { AppLocale } from "AppLocale";
import { IRecurringChecklistDuration } from "../api/IRecurringChecklistDuration";
import IWorkflowScheduleCalculatedDatesDTO from "../api/IWorkflowScheduleCalculatedDatesDTO";

interface IProps {
    intervalName?: string;
    interval?: number;

    calculatedResult?: IWorkflowScheduleCalculatedDatesDTO;

    duration?: IRecurringChecklistDuration;
}

const RecurringChecklistSummary = (props: IProps) => {
    const { intervalName, interval, duration, calculatedResult } = props;

    const { startDate, endDate, numberOfRuns } = calculatedResult ?? {};

    const { days, hours, minutes } = duration ?? {};

    const { t } = useTranslation();

    return (
        <div className="recurring-checklist--body--sum">
            <div className="r-c--body--sum--title">{t(k.SUMMARY)}</div>
            <div className="r-c--body--sum--row">
                {t(k.RECURRENCE_SUMMARY1)}
                <strong>
                    {interval ?? "1"} {intervalName}
                </strong>
                {t(k.RECURRENCE_SUMMARY2)}
                <strong>
                    {startDate ? format(startDate, "Pp", AppLocale) : "-"}
                </strong>
                {t(k.RECURRENCE_SUMMARY3)}
                <strong>
                    {endDate ? format(endDate, "P", AppLocale) : "-"}
                </strong>
                {t(k.RECURRENCE_SUMMARY4)}
                <strong>{numberOfRuns ?? "0"}</strong>
                {t(k.RECURRENCE_SUMMARY5)}
                <strong>{days ?? "0"}</strong>
                {t(k.RECURRENCE_SUMMARY6)}
                <strong>{hours ?? "12"}</strong>
                {t(k.RECURRENCE_SUMMARY7)}
                <strong>{minutes ?? "0"}</strong>
                {t(k.RECURRENCE_SUMMARY8)}
            </div>
        </div>
    );
};

export default RecurringChecklistSummary;
