import { ORM } from "redux-orm";

import Activity, { ActivityOrmModelType } from "../models/orm/Activity";
import Attachment, { AttachmentOrmModelType } from "../models/orm/Attachment";
import Step, {
    StepOrmModelType,
    LatestVersionStep,
    LatestVersionStepOrmModelType,
    SelectedStep,
    SelectedStepOrmModelType,
} from "../models/orm/Step";
import Workflow, {
    WorkflowOrmModelType,
    LatestVersionWorkflowOrmModelType,
    SelectedWorkflowOrmModelType,
    LatestVersionWorkflow,
    SelectedWorkflow,
} from "../models/orm/Workflow";
import { IORMState } from "./IORMState";

interface IAllEntitiesModels {
    Attachment: AttachmentOrmModelType;
    Activity: ActivityOrmModelType;

    Step: StepOrmModelType;
    LatestVersionStep: LatestVersionStepOrmModelType;
    SelectedStep: SelectedStepOrmModelType;

    Workflow: WorkflowOrmModelType;
    LatestVersionWorkflow: LatestVersionWorkflowOrmModelType;
    SelectedWorkflow: SelectedWorkflowOrmModelType;
}

const orm = new ORM<IORMState>({
    stateSelector: (state: IORMState) => state,
});

orm.register<IAllEntitiesModels>(
    Attachment,
    Activity,

    Step,
    LatestVersionStep,
    SelectedStep,

    Workflow,
    LatestVersionWorkflow,
    SelectedWorkflow,
);

export const initialOrmState: IORMState = orm.getEmptyState();

export default orm;
