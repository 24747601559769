import React from "react";
import { useBarcode } from "next-barcode";

import IBarcodeProps from "../IBarcodeProps";

const BarcodeEan13: React.FC<IBarcodeProps> = (props) => {
    const { value, hideBarcodeLabel } = props;

    const { inputRef } = useBarcode({
        value,
        options: {
            format: "EAN13",
            // flat: true,
            background: "transparent",
            height: 30,
            fontSize: 12,
            margin: 0,
            displayValue: hideBarcodeLabel ? false : true,
        },
    });

    return (
        <svg
            className={props.onClick ? "cursor-pointer" : undefined}
            ref={inputRef}
        />
    );
};

export default BarcodeEan13;
