export enum DeviationSortBy {
    None,
    Message,
    Created,
    Updated,
    Impact,
    Status,
    Form,
    Responsible,
    Involved,
}
