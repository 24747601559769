import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const ArchiveContactMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return <React.Fragment>{t(k.ARE_YOU_SURE)}</React.Fragment>;
};

export default ArchiveContactMessage;
