export enum FormulaItemType {
    None = 0,
    OpenParenthesis,
    ClosedParenthesis,
    Plus,
    Minus,
    Multiply,
    Divide,
    Modulo,
    Power,
    Constant,
    Variable,
}
