import "./ModalBarcodeScannerManager.scss";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { BrowserMultiFormatReader, BrowserCodeReader } from "@zxing/browser";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IAppState } from "store/IAppState";
import Modal from "common/components/modals/Modal";
import { hideScanner } from "store/barcode/actions";
import { IValueLabelItem } from "common/IValueLabelItem";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

interface IStateProps {
    show?: boolean;
}

interface IDispatchProps {
    hide: (barcode?: string) => void;
}

type Props = IStateProps & IDispatchProps;

type VideoConstraintType = { deviceId?: string; facingMode?: "environment" };

const initialVideoConstraints: VideoConstraintType = {
    facingMode: "environment",
};

const ModalBarcodeScannerManager: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { show } = props;

    const [hasError, setHasError] = useState(false);

    const [devices, setDevices] = useState<
        Array<IValueLabelItem<string, string>>
    >([]);

    const [selectedDevice, setSelectedDevice] =
        useState<IValueLabelItem<string, string>>();

    const [videoConstraints, setVideoConstraints] = useState(
        initialVideoConstraints,
    );

    const { t } = useTranslation();

    const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

    useEffect(() => {
        if (show) {
            setDevices([]);

            setHasError(false);
        }
    }, [show]);

    const getDevices = async () => {
        if (devices.length === 0) {
            const videoInputDevices =
                await BrowserCodeReader.listVideoInputDevices();

            return videoInputDevices.map((x) => {
                const result: IValueLabelItem<string, string> = {
                    value: x.deviceId,
                    label: x.label,
                };

                return result;
            });
        }

        return devices;
    };

    const configSelectedVideoDevice = (
        mediaStream: MediaStream,
        deviceList: Array<IValueLabelItem<string, string>>,
    ) => {
        if (
            deviceList.length > 1 &&
            videoConstraints === initialVideoConstraints
        ) {
            const tracks = mediaStream.getVideoTracks();

            if (tracks.length > 0) {
                const settings = tracks[0].getSettings();

                const newSelectedDevice = deviceList?.find(
                    (x) => x.value === settings.deviceId,
                );

                if (newSelectedDevice) {
                    setSelectedDevice(newSelectedDevice);
                } else if (deviceList && deviceList.length > 0) {
                    setSelectedDevice(deviceList[0]);
                }
            }
        }
    };

    const onUserMedia = (mediaStream: MediaStream) => {
        getDevices().then((x) => {
            setDevices(x);

            configSelectedVideoDevice(mediaStream, x);
        });

        setHasError(false);

        const codeReader = new BrowserMultiFormatReader();

        codeReader
            .decodeOnceFromStream(mediaStream)
            .then((result) => {
                props.hide(result.getText());
            })
            .catch((error) => {
                setHasError(true);
            });
    };

    const handleChangeDevice = (e: any) => {
        setHasError(false);

        if (e) {
            const option = e as IValueLabelItem<string, string>;

            setSelectedDevice(option);

            setVideoConstraints({
                deviceId: option.value,
            });
        }
    };

    return show ? (
        <Modal show onHide={props.hide}>
            <Modal.Title>{t(k.SCAN_BARCODE)}</Modal.Title>

            <Modal.Body>
                {devices && devices.length > 1 && (
                    <div className="barcode-scanner__webcam">
                        <SelectDropdown
                            value={selectedDevice}
                            isSearchable
                            options={devices}
                            onChange={handleChangeDevice}
                        />
                    </div>
                )}

                {hasError ? (
                    <div className="barcode-scanner__result">
                        <h3>Error!</h3>
                    </div>
                ) : (
                    <Webcam
                        className="barcode-scanner"
                        audio={false}
                        videoConstraints={videoConstraints}
                        ref={webcamRef}
                        onUserMedia={onUserMedia}
                    />
                )}
            </Modal.Body>
        </Modal>
    ) : null;
};

const mapStateToProps = (state: IAppState): IStateProps => {
    const { isScannerVisible: isVisible } = state.barcodeViewState;

    return {
        show: isVisible,
    };
};

const actions: IDispatchProps = {
    hide: hideScanner,
};

export default connect(mapStateToProps, actions)(ModalBarcodeScannerManager);
