import "./TemplateBox.scss";

import React, { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Button from "common/components/buttons/Button";
import { BsCardChecklist } from "react-icons/bs";

const lineHeight = 20;
interface IProps {
    id: string;
    name?: string;
    description?: string;

    onSelect: (id: string) => void;
}

const TemplateBox: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, name, description } = props;

    const { t } = useTranslation();

    const titleRef = useRef<HTMLDivElement>(null);
    const titleCur = titleRef.current;
    const descRef = useRef<HTMLDivElement>(null);
    const descCur = descRef.current;

    const handleOnSelect = () => {
        props.onSelect(id);
    };

    const titleRows = useMemo(() => {
        if (titleCur) {
            return titleCur.clientHeight < titleCur.scrollHeight
                ? 2
                : titleCur.clientHeight > lineHeight
                  ? 1
                  : 0;
        }
    }, [titleCur]);

    const descRows = useMemo(() => {
        if (descCur) {
            return descCur.clientHeight < descCur.scrollHeight
                ? 2
                : descCur.clientHeight > lineHeight * 4
                  ? 1
                  : 0;
        }
    }, [descCur]);

    const descStyle: React.CSSProperties = {
        maxHeight: titleRows ? "80px" : "100px",
        WebkitLineClamp: titleRows ? 4 : 5,
        lineClamp: titleRows ? 4 : 5,
    };

    return (
        <div className="select-template-box">
            <div
                ref={titleRef}
                className="select-template-box--title"
                title={titleRows === 2 ? name : undefined}
            >
                {name}
            </div>

            <div
                ref={descRef}
                style={descStyle}
                className="select-template-box--desc"
                title={descRows === 2 ? description : undefined}
            >
                {description}
            </div>

            <div className="select-template-box--actions">
                <Button
                    onClick={handleOnSelect}
                    variant="success"
                    icon={<BsCardChecklist />}
                    noMinWidth
                >
                    {t(k.SELECT)}
                </Button>
            </div>
        </div>
    );
};

export default TemplateBox;
