import { useCallback, useRef } from "react";

type ActionMap = {
    [key: string]: (...args: any[]) => any;
};

const useComponentActions = <T extends ActionMap>() => {
    const actionRefs = useRef<T>({} as T);

    const registerAction = useCallback(
        <K extends keyof T>(id: K, action: T[K]) => {
            actionRefs.current[id] = action;
        },
        [],
    );

    const performAction = useCallback(
        <K extends keyof T>(id: K, ...args: Parameters<T[K]>) => {
            const action = actionRefs.current[id];
            if (action) {
                return action(...args);
            }
        },
        [],
    );

    return { registerAction, performAction };
};

export default useComponentActions;
