import React from "react";

import IconBg from "../icon-bg/IconBg";
import Icon from "./assets/custom-list.svg";

interface IProps {
    showBackground?: boolean;

    className?: string;
}

const CustomListIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { showBackground, className } = props;

    return (
        <IconBg show={showBackground}>
            <Icon className={className} />
        </IconBg>
    );
};

export default CustomListIcon;
