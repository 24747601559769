import "./ReportTableCell.scss";

import React, { MouseEvent } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { showImageById, showImage } from "store/images/actions";
import { ActivityType } from "models/enums/ActivityType";
import { TableCell } from "common/components/table";
import { MultiselectValueBadge } from "common/components/badges";
import { ITemplateReportActivity } from "components/template-checklist-report/api/ITemplateReportActivity";
import Barcode from "common/components/barcode/Barcode";
import IBarcodePreviewRequest from "store/barcode/IBarcodePreviewRequest";
import { showBarcodePreview } from "store/barcode/actions";
import IDictionary from "common/viewModels/IDictionary";
import { SearchIcon } from "common/components/icons";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { convertToDate } from "common/utils/time";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import Link from "common/components/links/Link";

interface IValueWrapperProps {
    isHighlighted?: boolean;
    children?: React.ReactNode;
}

const ValueWrapper = ({ isHighlighted, children }: IValueWrapperProps) => (
    <div className={isHighlighted ? "table-cell__match-cell" : undefined}>
        {children}
    </div>
);

const NotAvailable = () => <span className="text--muted">N/A</span>;

interface IOwnProps {
    value: ITemplateReportActivity | null;
    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;

    highlightedActivityInstanceIds: IDictionary<boolean>;

    isTopLevel: boolean;

    isCellExpanded?: boolean;

    rowSpan?: number;

    onBarcodeSearch: (barcode: string) => void;
}

interface IDispatchProps {
    showImagePreviewById: (imgId: string) => void;
    showImagePreviewByValue: (value: string) => void;
    showBarcodePreview: (value: IBarcodePreviewRequest) => void;
}

type Props = IOwnProps & IDispatchProps;

type ReportTableCellType = React.FC<Props> & {
    NotAvailableMessage: React.FC;
};

const ReportTableCell: ReportTableCellType = (props) => {
    const {
        value,
        highlightedActivityInstanceIds,
        selectedCustomListItemsNames,
        isTopLevel,
        rowSpan,
        isCellExpanded,
    } = props;

    const { t } = useTranslation();

    const modalManagerContext = React.useContext(ModalManagerContext);

    if (value === null) {
        if (isTopLevel) {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                >
                    <NotAvailable />
                </TableCell>
            );
        }

        return (
            <TableCell
                className={
                    isCellExpanded ? "report-table-cell__expanded" : undefined
                }
                rowSpan={rowSpan}
            ></TableCell>
        );
    }

    const handlePreviewPhoto = (
        e: MouseEvent<HTMLSpanElement> & {
            target: { dataset: { id?: string } };
        },
    ) => {
        const pictureId = e.target.dataset.id;

        if (pictureId) {
            if (isTopLevel) {
                props.showImagePreviewById(pictureId);
            } else {
                const image = value.values?.find((x) => x.value === pictureId);

                if (image?.label) {
                    props.showImagePreviewByValue(image.label);
                }
            }
        }
    };

    const handlePreviewCustomListItem = (id?: string) => {
        if (id) {
            const payload: IShowModalPayload = {
                id,
                type: ModalTypes.custom_list,
                callerAddress: location.pathname,
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    const handleBarcodePreview = (value: string) => {
        const request: IBarcodePreviewRequest = {
            value,
        };

        props.showBarcodePreview(request);
    };

    const handleOnBarcodeSearch = (
        e: MouseEvent<HTMLDivElement> & {
            target: { dataset: { barcode?: string } };
        },
    ) => {
        const { barcode } = e.target.dataset;

        if (barcode) {
            props.onBarcodeSearch(barcode);
        }
    };

    switch (value.type) {
        case ActivityType.Photo: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <ValueWrapper
                            key={index}
                            isHighlighted={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                            }
                        >
                            {x.isCompleted ? (
                                <span
                                    className="preview-link"
                                    data-id={x.value}
                                    onClick={handlePreviewPhoto}
                                >
                                    {t(k.PHOTO_PREVIEW)}
                                </span>
                            ) : undefined}
                        </ValueWrapper>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.GenerateBarcode:
        case ActivityType.Barcodes: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <div
                            key={index}
                            className="report-table-cell__barcode-wrapper"
                        >
                            <ValueWrapper
                                isHighlighted={
                                    highlightedActivityInstanceIds[
                                        x.activityInstanceId
                                    ]
                                }
                            >
                                {x.label && (
                                    <Barcode
                                        value={x.label}
                                        onClick={handleBarcodePreview}
                                    />
                                )}
                            </ValueWrapper>

                            {x.label && (
                                <div
                                    className="report-table-cell__search"
                                    data-barcode={x.label}
                                    onClick={handleOnBarcodeSearch}
                                >
                                    <SearchIcon />
                                </div>
                            )}
                        </div>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.Tasklist:
        case ActivityType.Customers:
        case ActivityType.Suppliers:
        case ActivityType.Items:
        case ActivityType.Users:
        case ActivityType.Positions:
        case ActivityType.Competencies: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <ValueWrapper
                            key={index}
                            isHighlighted={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                            }
                        >
                            <MultiselectValueBadge
                                type={
                                    value.type === ActivityType.Tasklist
                                        ? "checkbox"
                                        : undefined
                                }
                                checked={x.isCompleted}
                                isArchived={x.isArchived}
                                isDeleted={x.isDeleted}
                            >
                                {x.label}
                            </MultiselectValueBadge>
                        </ValueWrapper>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.CustomList: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => {
                        const customListItem =
                            selectedCustomListItemsNames?.[x.value];

                        if (customListItem) {
                            return (
                                <ValueWrapper
                                    key={index}
                                    isHighlighted={
                                        highlightedActivityInstanceIds[
                                            x.activityInstanceId
                                        ]
                                    }
                                >
                                    <MultiselectValueBadge
                                        id={customListItem.value}
                                        isArchived={customListItem.isArchived}
                                        isDeleted={customListItem.isDeleted}
                                        onClick={handlePreviewCustomListItem}
                                    >
                                        <span className="preview-link">
                                            {customListItem.label}
                                        </span>
                                    </MultiselectValueBadge>
                                </ValueWrapper>
                            );
                        }
                    })}
                </TableCell>
            );
        }

        case ActivityType.DateTime: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <span key={index}>
                            <FormattedDate
                                date={convertToDate(x.label ?? undefined)}
                            />
                        </span>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.Hyperlink: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <span key={index}>
                            {x.label && <Link to={x.label}>{x.label}</Link>}
                        </span>
                    ))}
                </TableCell>
            );
        }

        default: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <ValueWrapper
                            key={index}
                            isHighlighted={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                            }
                        >
                            <div
                                className="table-cell__truncate"
                                title={x.label ?? undefined}
                            >
                                {x.label}
                            </div>
                        </ValueWrapper>
                    ))}
                </TableCell>
            );
        }
    }
};

ReportTableCell.NotAvailableMessage = NotAvailable;

const actions: IDispatchProps = {
    showImagePreviewById: showImageById,
    showImagePreviewByValue: showImage,
    showBarcodePreview,
};

export default connect(null, actions)(ReportTableCell);
