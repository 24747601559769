import { useContext } from "react";

import { useTranslation } from "react-i18next";

import ListedContent from "common/components/listed-content/ListedContent";
import ListItems, {
    EParentType,
} from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import ListItemsDropdown from "common/components/listed-content/components/SidePanel/List/components/ListItemsDropdown/ListItemsDropdown";
import StepListDropdownValue from "common/components/listed-content/components/SidePanel/List/components/StepListDropdownValue";
import TitleWithValidation from "components/common/validation/TitleWithValidation";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";

import ImprovementFormContext from "../../../context/ImprovementFormContext";
import { CONFIGURATION_STEP_ID } from "../../../context/ImprovementFormData";
import SidePanelConfigurationList from "../SidePanelConfigurationList/SidePanelConfigurationList";

import "./SidePanelList.scss";

const stepsReq = "steps.required";

const SidePanelList: React.FC = (props) => {
    const { mobileView, configuration, errors, currentSection } = useContext(
        ImprovementFormContext,
    );

    const { t } = useTranslation();

    return (
        <ListedContent.SidePanel.List
            context={ImprovementFormContext}
            data-testid="template-step-list"
        >
            {mobileView ? undefined : <SidePanelConfigurationList />}
            <TitleWithValidation
                className="side-panel__list__title"
                title={t(k.STEPS)}
                bold
                errors={getLocaleString(t, errors?.[stepsReq])}
                validationTestId="step-list-validation"
            />
            {mobileView ? (
                <ListItemsDropdown
                    context={ImprovementFormContext}
                    parentType={EParentType.improvement}
                />
            ) : (
                <ListItems
                    context={ImprovementFormContext}
                    parentType={EParentType.improvement}
                />
            )}
        </ListedContent.SidePanel.List>
    );
};

export default SidePanelList;
