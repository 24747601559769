import "./StepListDropdownValue.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IStepInstance from "models/IStepInstance";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { StepInstanceCompletionType } from "models/enums/StepInstanceCompletionType";
import NumberOfMax from "common/components/number-of-max/NumberOfMax";
import { EParentType } from "./ListItems/ListItems";
import { getLocaleString } from "i18n/components/GetLocaleString";

interface IProps {
    stepInstance?: IStepInstance;

    index?: number;
    isCurrent?: boolean;
    parentType?: EParentType;
}

const StepInstanceListDropdownValue: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const { stepInstance, isCurrent = false, parentType, index } = props;

    if (!stepInstance) {
        return null;
    }

    const {
        name,
        completed: completedState,
        completedActivitiesCount: completedAct = 0,
        totalActivitiesCount: totalAct = 0,
    } = stepInstance;

    const { t } = useTranslation();

    const isChecklist = parentType === EParentType.checklist;

    return (
        <div className={`steps-list-dropdown${isCurrent ? " selected" : ""}`}>
            <div className="steps-list-dropdown--column">
                {isChecklist && (
                    <Checkbox
                        value={completedState === StepInstanceCompletionType.Ok}
                        disabled
                        variant="success"
                    />
                )}
                {index}. {getLocaleString(t, stepInstance.name)}
            </div>

            {isChecklist && (
                <div className="steps-list-dropdown--column">
                    <NumberOfMax
                        variant={
                            completedAct === 0
                                ? undefined
                                : completedAct < totalAct
                                  ? "draft"
                                  : "success"
                        }
                        value={completedAct}
                        max={totalAct}
                    />
                </div>
            )}
        </div>
    );
};

export default StepInstanceListDropdownValue;
