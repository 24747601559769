import "./AdressBarButton.scss";
import React from "react";
import { LinkWrapper } from "components/common/link-wrapper";

interface IProps {
    onClick?: () => void;
    to?: string;
}
const AdressBarButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <LinkWrapper to={props.to}>
            <div
                className={`adress-bar-button${props.to ? " clickable" : ""}`}
                onClick={props.onClick}
            >
                {props.children}
            </div>
        </LinkWrapper>
    );
};

export default AdressBarButton;
