import "./KpiThreshold.scss";

import { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface IProps {
    values: { min: number; max: number };
    highIsGood?: boolean;
    hideYellow?: boolean;
}

const KpiThresholdLegend = (props: PropsWithChildren<IProps>) => {
    const { values, highIsGood, hideYellow } = props;

    const { t } = useTranslation();

    return (
        <div
            className={`kpi-threshold--legend${
                highIsGood ? " swap-direction" : ""
            }`}
        >
            <div>
                <span className={highIsGood ? "span-red" : "span-green"}>
                    {highIsGood ? t(k.RED) : t(k.GREEN)}
                </span>
                {hideYellow && highIsGood
                    ? t(k.EXPECTED_BELOW)
                    : t(k.EXPECTED_BELOW_OR_EQUAL)}
                <span>{values.min}</span>
                <br />
            </div>
            {!hideYellow && (
                <div>
                    <span className="span-yellow">{t(k.YELLOW)}</span>
                    {t(k.EXPECTED_BETWEEN)}
                    <span>{values.min}</span> {t(k.AND_)}{" "}
                    <span>{values.max}</span>
                    <br />
                </div>
            )}
            <div>
                <span className={highIsGood ? "span-green" : "span-red"}>
                    {highIsGood ? t(k.GREEN) : t(k.RED)}
                </span>
                {hideYellow && !highIsGood
                    ? t(k.EXPECTED_ABOVE)
                    : t(k.EXPECTED_ABOVE_OR_EQUAL)}
                <span>{values.max}</span>
                <br />
            </div>
        </div>
    );
};

export default KpiThresholdLegend;
