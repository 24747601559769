import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import english from "./english";
import swedish from "./swedish";
import { LocaleId } from "AppLocale";
import { isDevelopmentEnvironment } from "config";

i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        debug: isDevelopmentEnvironment(),
        fallbackLng: LocaleId.En,

        resources: {
            [LocaleId.En]: { translation: english },
            [LocaleId.Sv]: { translation: swedish },
        },
    });

export default i18next;
