import "./RemoveButton.scss";

import React from "react";
import { IoClose } from "react-icons/io5";
import IconButton from "../icon-button/IconButton";

interface IProps {
    id?: string;

    mode?: "red";

    onClick: (e: React.UIEvent, id?: string) => void;
}

const RemoveButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const handleClick = (e: React.UIEvent) => props.onClick(e, props.id);

    return (
        <IconButton onClick={handleClick}>
            <IoClose
                className={`remove-button--icon ${
                    props.mode ? props.mode : ""
                }`}
            />
        </IconButton>
    );
};

export default RemoveButton;
