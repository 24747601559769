import "react-calendar-timeline/lib/Timeline.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datasheet-grid/dist/style.css";
import "react-toggle/style.css";
import "./index.scss";

import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Store } from "redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { QueryClient, QueryClientProvider } from "react-query";

import "./i18n/init";
import App from "./App";
import { configureErrorHandler } from "./common/utils/errors/handlers";
import configureAppStore from "./store";
import { IAppState } from "./store/IAppState";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "monitoring/AppInsights.js";
import { initializePendo } from "./monitoring/pendo";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { History } from "@remix-run/router";
import { msalConfig } from "./msalConfig";
import { initializeSentry } from "monitoring/sentryConfig";
import { configureVitePWA } from "monitoring/vitePWAConfig";

declare global {
    // tslint:disable-next-line: interface-name
    interface Window {
        devToolsExtension: any;
        initialReduxState: any;

        showSaveFilePicker: any;
        Cypress?: any;
    }
}

const history = createBrowserHistory({ window }) as any as History;

const msalInstance = new PublicClientApplication(msalConfig);

interface IProps {
    store: Store<IAppState>;
}

const store = configureAppStore();

const queryClient = new QueryClient();

const Root = (props: IProps) => {
    initializeSentry();
    initializePendo();
    configureVitePWA();

    return (
        <Provider store={props.store}>
            <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClient}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <HistoryRouter history={history}>
                            <App />
                        </HistoryRouter>
                    </AppInsightsContext.Provider>
                </QueryClientProvider>
            </MsalProvider>
        </Provider>
    );
};

configureErrorHandler(store, msalInstance);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root store={store} />);
