import {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

export interface IInitialFetchOptions {
    enabled?: boolean;
    syncData?: boolean;
}

export const useInitialFetch = <TData, TError>(
    queryKeys: QueryKey,
    queryFn: QueryFunction<TData>,
    { enabled = true, syncData = true }: IInitialFetchOptions,
    options?: Omit<
        UseQueryOptions<TData, TError>,
        "enabled" | "refetchOnMount"
    >,
): UseQueryResult<TData, TError> =>
    useQuery<TData, TError>([...queryKeys], queryFn, {
        ...(options ?? {}),
        enabled: syncData ? enabled : false,
        keepPreviousData: syncData ? options?.keepPreviousData : true,
        refetchOnMount: "always",
        refetchInterval: syncData ? options?.refetchInterval : false,
    });
