import "./SelectUsersAvatarListEditable.scss";
import { IValueLabelItem } from "common/IValueLabelItem";
import SelectUsersDropdown from "./SelectUsersDropdown";
import SelectedUsersAvatarList from "./SelectedUsersAvatarList";

interface IProps {
    maxShownAvatars?: number;
    selectedUserIds?: string[];
    editMode: boolean;
    allUsers?: IValueLabelItem<string, string, any>[];
    onChangeUser: (userId: string) => void;
}

const SelectUsersAvatarListEditable = (props: IProps) => {
    const { selectedUserIds, allUsers, editMode, maxShownAvatars } = props;

    const selectedUsers = allUsers?.filter((user) =>
        selectedUserIds?.includes(user.value),
    );

    return (
        <div className="select-users-avatar-list-editable">
            <SelectedUsersAvatarList
                size={24}
                maxShownAvatars={maxShownAvatars}
                users={selectedUsers ?? []}
            />
            {editMode && (
                <SelectUsersDropdown
                    users={allUsers ?? []}
                    selectedUserIds={selectedUserIds ?? []}
                    onSelect={props.onChangeUser}
                />
            )}
        </div>
    );
};

export default SelectUsersAvatarListEditable;
