import "./DeviationButton.scss";

import React, { useMemo } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

interface IProps {
    className?: string;

    hasDeviations?: boolean;

    onClick?: () => void;
}

const DeviationButton = (props: IProps) => {
    const { hasDeviations } = props;

    const hasClickHandler = Boolean(props.onClick);

    const className = useMemo(() => {
        const result: string[] = ["deviation-button__wrapper"];

        if (hasClickHandler === false) {
            result.push("deviation-button--not-clickable");
        }

        if (hasDeviations) {
            result.push("deviation-button--active");
        }

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [hasDeviations, hasClickHandler, props.className]);

    const handleClick = (e: React.UIEvent) => {
        e.stopPropagation();

        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className={className} onClick={handleClick}>
            <FaExclamationTriangle />
        </div>
    );
};

export default DeviationButton;
