import axios from "axios";
import { convertToDateStrict } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { CUSTOM_LIST_ITEM_OPTIONS } from "components/custom-list-item-page/api/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ISavedSharedPropertyInstanceValueTableList } from "./ISavedSharedPropertyInstanceValueTableList";
import { ISharedPropertiesTableSet } from "./ISharedPropertiesTableSet";

import { ISharedPropertyRequest } from "./ISharedPropertyRequest";
import { IUserDefinedCustomListItemValueRequest } from "./IUserDefinedCustomListItemValueRequest";
import { SharedPropertyOwnerEnum } from "./SharedPropertyOwnerEnum";

const baseUrl = "/api/shared-properties";
const customListUrl = "/api/custom-list";

const SHARED_PROPERTIES_GENERATE_BARCODE_QUERY_KEY =
    "SHARED_PROPERTIES_GENERATE_BARCODE_QUERY_KEY";

export const useGenerateBarcodeCode = (enabled: boolean) => {
    return useQuery(
        [SHARED_PROPERTIES_GENERATE_BARCODE_QUERY_KEY],
        async (args) => {
            const url = `${baseUrl}/barcode`;

            const response = await axios.get<string>(url, {
                signal: args.signal,
            });

            return String(response.data);
        },
        { enabled },
    );
};

export const SHARED_PROPERTIES_QUERY_KEY = "SHARED_PROPERTIES_QUERY_KEY";

export const useSharedProperties = (type: SharedPropertyOwnerEnum) => {
    return useQuery([SHARED_PROPERTIES_QUERY_KEY, type], async (args) => {
        const url = `${baseUrl}/list/${type}`;

        const response = await axios.get<ISharedPropertiesTableSet>(url, {
            signal: args.signal,
        });

        return response.data;
    });
};

export const SHARED_PROPERTIES_INSTANCE_VALUES_QUERY_KEY =
    "SHARED_PROPERTIES_INSTANCE_VALUES_QUERY_KEY";

export const useSharedPropertyInstanceValues = (
    type: SharedPropertyOwnerEnum,
    ids: string[] | undefined,
) => {
    return useQuery(
        [SHARED_PROPERTIES_INSTANCE_VALUES_QUERY_KEY, type],
        async (args) => {
            const url = `${baseUrl}/values-by-ids/${type}`;

            const data = ids
                ?.map((id) => {
                    return `ids=${id}`;
                })
                .join("&");

            const response =
                await axios.post<ISavedSharedPropertyInstanceValueTableList>(
                    url,
                    data,
                    {
                        signal: args.signal,
                    },
                );

            const result = response.data;

            for (const id in result.data) {
                const value = result.data[id];

                if (value) {
                    for (const dateValueKey in value.dateValues) {
                        const dateValues = value.dateValues[dateValueKey] ?? [];

                        for (let i = 0; i < dateValues.length; i++) {
                            dateValues[i] = convertToDateStrict(dateValues[i]);
                        }
                    }
                }
            }

            return result;
        },
        { enabled: ids !== undefined && ids.length > 0 },
    );
};

export const useSaveSharedProperties = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (request: ISharedPropertyRequest) => {
            try {
                const url = `${baseUrl}/save`;

                await axios.post(url, request);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                if (data.Succeeded) {
                    queryClient.removeQueries([CUSTOM_LIST_ITEM_OPTIONS]);

                    queryClient.invalidateQueries([
                        SHARED_PROPERTIES_QUERY_KEY,
                        variables.type,
                    ]);
                }
            },
        },
    );
};
