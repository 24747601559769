import "./Select.scss";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";

import { orderMultiselectOptions } from "./utils";

import IDictionary from "common/viewModels/IDictionary";

import { usePublishedRoles } from "components/roles/api/hooks";
import { IValueParamItem } from "common/IValueParamItem";
import { IValueLabelItem } from "common/IValueLabelItem";
import { IMultiselectOption } from "common/IMultiselectOption";

interface IProps extends IInputWrapperProps {
    id: string;
    selectedRoles?: IValueParamItem<string, boolean>[] | string[];
    hideUserRole?: boolean;

    placeholder?: string;
    multi?: boolean;

    testId?: string;
    wrapperInputTestId?: string;

    onChange: (roleIds: string[]) => void;
}

const SelectRoles: React.FC<IProps> = (props) => {
    const {
        id,
        selectedRoles,
        placeholder,
        multi,
        className,
        wrapperIcon: icon,
        wrapperLabel: label,
        testId,
        wrapperInputTestId: inputTestId,
        boldLabel,
        hideUserRole,
    } = props;

    const { t, i18n } = useTranslation();

    const { language: localeId } = i18n;

    const { data: publishedRoles } = usePublishedRoles(true, !hideUserRole);

    const allOptions = useMemo(() => {
        if (publishedRoles) {
            const ids = publishedRoles.ids;

            const values = ids.reduce<IDictionary<string>>((prev, id) => {
                const key = publishedRoles.values[id];

                if (key) {
                    if (i18n.exists(key)) {
                        prev[id] = t(k[key as keyof typeof k]);
                    } else {
                        prev[id] = key;
                    }
                }

                return prev;
            }, {});

            const options: IValueLabelItem<string, string>[] = ids.map((id) => {
                return {
                    value: id,
                    label: values[id],
                };
            });

            return {
                ids,
                values,
                options,
            };
        }
    }, [publishedRoles, localeId]);

    const selectedOptions = useMemo(() => {
        if (allOptions && selectedRoles) {
            if (typeof selectedRoles[0] === "string") {
                const asString = selectedRoles as string[];

                const result: IMultiselectOption[] = asString.map((x) => ({
                    value: x,
                    label: allOptions.values[x],
                }));

                return result;
            } else {
                const asValueParam = selectedRoles as IValueParamItem<
                    string,
                    boolean
                >[];

                const result: IMultiselectOption[] = asValueParam.map((x) => ({
                    value: x.value,
                    label: allOptions.values[x.value],
                    isFixed: x.param,
                }));

                return orderMultiselectOptions(result);
            }
        }
    }, [selectedRoles, publishedRoles, localeId]);

    const handleOnChange = (options: any) => {
        let newValues = [] as string[];

        if (options) {
            const selectedValues = options as IMultiselectOption[];

            newValues = selectedValues.map((x) => {
                return x.value;
            });
        }

        props.onChange(newValues);
    };

    return (
        <InputWrapper
            htmlFor={id}
            className={`select-roles${className ? " " + className : ""}`}
            wrapperIcon={icon}
            wrapperLabel={label ?? t(k.ROLES)}
            boldLabel={boldLabel}
            wrapperInputTestId={inputTestId}
        >
            <SelectDropdown
                inputId={id}
                value={selectedOptions}
                isMulti={multi}
                isSearchable
                isClearable
                placeholder={placeholder ?? t(k.SELECT)}
                options={allOptions?.options}
                testId={testId}
                closeMenuOnSelect={multi !== true}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};

export default SelectRoles;
