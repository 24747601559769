import React, { useContext } from "react";

import CompleteStep from "common/components/complete-step/CompleteStep";
import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import RegistrationStep from "components/improvements/components/RegistrationStep/RegistrationStep";
import EditableStep from "components/steps/components/editable-step/EditableStep";

import ImprovementFormContext from "../../context/ImprovementFormContext";
import ConfigurationStep from "./components/ConfigurationStep/ConfigurationStep";

import "./Content.scss";

const Content: React.FC = (props) => {
    const { steps, sectionRefs, errors, onHaveChanges, isArchived } =
        useContext(ImprovementFormContext);

    return (
        <>
            <ConfigurationStep />
            {steps &&
                sectionRefs &&
                steps.ids.map((id, index) => {
                    const step = steps.values[id];
                    const stepRef = sectionRefs[id];

                    if (step && stepRef) {
                        return (
                            <EditableStep
                                key={id}
                                stepRef={stepRef}
                                index={index + 1}
                                step={step}
                                isArchived={isArchived}
                                errors={errors}
                                parentType={EParentType.improvement}
                                headerDetailsContent={
                                    index === 0 ? (
                                        <RegistrationStep editable={false} />
                                    ) : null
                                }
                                footerContent={
                                    index === 3 ? (
                                        <CompleteStep
                                            preview
                                            name={step.name}
                                        />
                                    ) : (
                                        <CompleteStep
                                            disabled
                                            name={step.name}
                                        />
                                    )
                                }
                                onHaveChanges={onHaveChanges}
                            />
                        );
                    }
                })}
        </>
    );
};

export default Content;
