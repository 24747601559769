import "./MeasurementsList.scss";

import k from "i18n/keys";

import { usePublishedUserOptionsExtended } from "components/users/api/hooks";
import { useTranslation } from "react-i18next";
import InfoCard from "components/goals-page/components/InfoCard";

import { IMeasurementDTO } from "../api/IMeasurementDTO";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import ITableSet from "http/ITableSet";

import MeasurementCard from "./MeasurementCard";

interface IProps {
    matchedSearchTexts?: Record<string, string | undefined>;
    measurements?: ITableSet<IMeasurementDTO>;
    isLoading?: boolean;
    onEditMeasurement?: (id: string, openInEditMode?: boolean) => void;
}

const MeasurementsList = (props: IProps) => {
    const { measurements, isLoading, matchedSearchTexts } = props;

    const { data: users, isLoading: isLoadingMeasurementsUsers } =
        usePublishedUserOptionsExtended();

    return (
        <div className="measurements-list">
            {isLoading || isLoadingMeasurementsUsers ? (
                <AnimatedSpinner isVisible aligned="center" position="center" />
            ) : (
                measurements &&
                measurements.ids?.length > 0 && (
                    <div className="measurements-list--cards">
                        {measurements.ids.map((id) => {
                            const item = measurements.values[id];
                            if (!item) {
                                return null;
                            }

                            const handleEditMeasurement = () => {
                                props.onEditMeasurement?.(
                                    item.id,
                                    item.isLocal,
                                );
                            };

                            return (
                                <MeasurementCard
                                    key={id}
                                    matchedSearchText={matchedSearchTexts?.[id]}
                                    measurement={item}
                                    usersData={users}
                                    onClick={handleEditMeasurement}
                                />
                            );
                        })}
                    </div>
                )
            )}
        </div>
    );
};

export default MeasurementsList;
