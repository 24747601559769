import "./StepInstanceStatusBadge.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Badge from "../Badge";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";

interface IProps {
    status?: StepInstanceStatus;
}

const StepInstanceStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status } = props;

    const { t } = useTranslation();

    if (status === StepInstanceStatus.Finalized) {
        return (
            <Badge className="step-instace-status-badge step-instace-status-badge--finalized">
                {t(k.FINALIZED)}
            </Badge>
        );
    }

    if (status === StepInstanceStatus.Completed) {
        return (
            <Badge className="step-instace-status-badge step-instace-status-badge--completed">
                {t(k.COMPLETED)}
            </Badge>
        );
    }

    if (status === StepInstanceStatus.Blocked) {
        return (
            <Badge className="step-instace-status-badge step-instace-status-badge--blocked">
                {t(k.BLOCKED)}
            </Badge>
        );
    }

    if (status === StepInstanceStatus.IsBlocking) {
        return (
            <Badge className="step-instace-status-badge step-instace-status-badge--blocking">
                {t(k.BLOCKING)}
            </Badge>
        );
    }

    return null;
};

export default StepInstanceStatusBadge;
