import React, { useContext } from "react";
import ImprovementContext from "../../improvementContext";
import StepInstanceCardById from "common/components/step-instance-card/StepInstanceCardById";
import RegistrationStep from "components/improvements/components/RegistrationStep/RegistrationStep";

interface IProps {}

const Content: React.FC<React.PropsWithChildren<IProps>> = () => {
    const { lastFinalizedStepInstanceId, stepInstances } =
        useContext(ImprovementContext);

    return (
        <>
            {stepInstances?.ids.map((id, index) => (
                <StepInstanceCardById
                    key={
                        lastFinalizedStepInstanceId
                            ? `${id}-${lastFinalizedStepInstanceId}`
                            : id
                    }
                    id={id}
                    orderIndex={index + 1}
                    disableComments={true}
                    disableFinalize={true}
                    disableTeams={true}
                    isImprovement={true}
                    registrationStep={
                        index === 0 ? (
                            <RegistrationStep
                                editable
                                context={ImprovementContext}
                            />
                        ) : undefined
                    }
                />
            ))}
        </>
    );
};

export default Content;
