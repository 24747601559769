import { IRecurringChecklistDuration } from "../api/IRecurringChecklistDuration";

const isDurationZero = (duration?: IRecurringChecklistDuration) => {
    if (duration === undefined) {
        return false;
    }
    if (
        (duration.days ?? 0) === 0 &&
        duration.hours &&
        duration.hours === 0 &&
        (duration.minutes ?? 0) === 0
    ) {
        return true;
    }
    return false;
};

export default isDurationZero;
