import React from "react";

import { IActivityProps } from "./IActivityProps";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

const ActivityGenerateBarcodeField: React.FC<IActivityProps> = (props) => {
    const { typeField } = props;

    const { t } = useTranslation();

    const typeList: React.ReactElement = <React.Fragment />;

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.GENERATE_BARCODE)}`,
    });
};

export default ActivityGenerateBarcodeField;
