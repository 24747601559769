import UserForm from "components/users/components/UserForm";
import { useContext } from "react";
import {
    IModalListItem,
    IShowModalPayload,
    ModalTypes,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const UserFormModal: React.FC<IProps> = (props) => {
    const { id, callId, options } = props.modalItem;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean, newId?: string) => {
        if (onSave && newId) {
            const payload: IShowModalPayload = {
                id: newId,
                type: ModalTypes.user_form,
                callerAddress: "",
            };
            modalManagerContext.onShowModal(payload);
        }
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <UserForm
            id={id}
            createNew={options?.createNew}
            edit={options?.edit}
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            onHaveChanges={handleOnHaveChanges}
            onCloseModal={handleOnClose}
        />
    );
};

export default UserFormModal;
