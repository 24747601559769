export enum HistoryActionType {
    None = 0,
    UPDATED = 1,
    ADDED = 2,
    REMOVED = 3,
    DELETED = 4,
    ASSIGNED = 5,
    UNASSIGNED = 6,
    FINALIZED = 7,
    UNFINALIZED = 8,
}
