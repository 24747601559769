import { useEffect, useState } from "react";

const RETRY_DELAY = 100;
const SLOW_DOWN_ATTEMPT = 10;
const SLOW_DOWN_DELAY = 1000;
const MAX_ATTEMPTS = SLOW_DOWN_ATTEMPT + 10;

export enum PortalStatus {
    Loading = "loading",
    Loaded = "loaded",
    Error = "error",
}

interface IProps {
    targetId: string;
    callerId: string;
}

export const usePortalLoaded = ({ targetId, callerId }: IProps) => {
    const [portalStatus, setPortalStatus] = useState<PortalStatus>();

    useEffect(() => {
        let attempts = 0;

        const checkElementLoaded = () => {
            const targetElement = document.getElementById(targetId);
            if (targetElement && targetElement.childNodes.length > 0) {
                const hasCallerId = Array.from(targetElement.childNodes).some(
                    (node: ChildNode) => "id" in node && node.id === callerId,
                );

                if (hasCallerId) {
                    setPortalStatus(PortalStatus.Loaded);
                    return;
                }
            }

            if (attempts < MAX_ATTEMPTS) {
                setTimeout(
                    checkElementLoaded,
                    attempts >= SLOW_DOWN_ATTEMPT
                        ? SLOW_DOWN_DELAY
                        : RETRY_DELAY,
                );
            }

            if (attempts === MAX_ATTEMPTS) {
                setPortalStatus(PortalStatus.Error);
            } else {
                attempts++;
            }

        };

        checkElementLoaded();

        return () => { };
    }, [targetId, callerId]);

    return portalStatus;
};
