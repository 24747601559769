import "./TableToolbarComponent.scss";

import React from "react";

import { useTableRowContainerContext } from "./Context";

interface IProps {
    colSpan?: number;
}

const TableToolbarComponent: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const context = useTableRowContainerContext();

    let { colSpan } = props;

    if (colSpan === undefined) {
        colSpan = context?.canExpandRow
            ? 1 + (context.columnsCount ?? 0)
            : context?.columnsCount;
    }

    return (
        <tr>
            <th className="app-table--toolbar" colSpan={colSpan}>
                {props.children}
            </th>
        </tr>
    );
};

export default TableToolbarComponent;
