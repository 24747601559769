import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
    TemplateResponsibleType,
    getTemplateResponsibleTypeOptions,
} from "components/improvement-forms/api/TemplateResponsibleType";
import k from "i18n/keys";
import i18n from "i18next";
import { v4 as uuidv4 } from "uuid";
import ITemplateConfigurationDTO from "components/improvement-forms/api/ITemplateConfigurationDTO";

export interface SelectedDynamicValueOption {
    shouldBeNotified: boolean;
    index: number;
    users: string[];
    isDeleted?: boolean;
}

export interface SelectedDynamicValues {
    [id: string]: SelectedDynamicValueOption;
}
interface SelectedValueOption {
    value?: boolean;
    disabled?: boolean;
}

export interface SelectedValues {
    [category: string]: {
        [key: string]: SelectedValueOption;
    };
}

export const useSelectedValuesInitialization = (
    i18next: typeof i18n,
    configuration?: ITemplateConfigurationDTO,
    hasChanges?: boolean,
): [
    SelectedValues | undefined,
    Dispatch<SetStateAction<SelectedValues | undefined>>,
] => {
    const [selectedValues, setSelectedValues] = useState<
        SelectedValues | undefined
    >(undefined);

    const options = useMemo(
        () => getTemplateResponsibleTypeOptions(i18next),
        [i18next.language],
    );
    useEffect(() => {
        if (!configuration || hasChanges) return;

        let newSelectedValues: Record<
            string,
            Record<string, { value?: boolean; disabled?: boolean }>
        > = {};

        configuration.responsibleTypes.forEach((responsibleType) => {
            if (
                responsibleType ===
                TemplateResponsibleType.DiscoveredInProcessOwner
            ) {
                newSelectedValues[k.RESPONSIBLE] = {
                    ...newSelectedValues[k.RESPONSIBLE],
                    [k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS]: {
                        value: true,
                        disabled: false,
                    },
                };
            } else if (
                responsibleType ===
                TemplateResponsibleType.OriginatedInProcessOwner
            ) {
                newSelectedValues[k.RESPONSIBLE] = {
                    ...newSelectedValues[k.RESPONSIBLE],
                    [k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS]: {
                        value: true,
                        disabled: false,
                    },
                };
            } else if (
                responsibleType ===
                TemplateResponsibleType.ManagerOfReportingUser
            ) {
                newSelectedValues[k.RESPONSIBLE] = {
                    ...newSelectedValues[k.RESPONSIBLE],
                    [k.REPORTING_USERS_MANAGER]: {
                        value: true,
                        disabled: false,
                    },
                };
            }
        });

        configuration.involvedTypes.forEach((involvedType) => {
            if (
                involvedType ===
                TemplateResponsibleType.DiscoveredInProcessOwner
            ) {
                newSelectedValues[k.INVOLVED] = {
                    ...newSelectedValues[k.INVOLVED],
                    [k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS]: {
                        value: true,
                        disabled: false,
                    },
                };
            } else if (
                involvedType ===
                TemplateResponsibleType.OriginatedInProcessOwner
            ) {
                newSelectedValues[k.INVOLVED] = {
                    ...newSelectedValues[k.INVOLVED],
                    [k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS]: {
                        value: true,
                        disabled: false,
                    },
                };
            } else if (
                involvedType === TemplateResponsibleType.ManagerOfReportingUser
            ) {
                newSelectedValues[k.INVOLVED] = {
                    ...newSelectedValues[k.INVOLVED],
                    [k.REPORTING_USERS_MANAGER]: {
                        value: true,
                        disabled: false,
                    },
                };
            }
        });

        options.forEach((option) => {
            if (
                option.param !== undefined &&
                !configuration.responsibleTypes.includes(option.param) &&
                !configuration.involvedTypes.includes(option.param)
            ) {
                newSelectedValues[k.NOTIFY] = {
                    ...newSelectedValues[k.NOTIFY],
                    [option.label]: {
                        disabled: true,
                    },
                };
            }
        });

        configuration.notifyTypes.forEach((notifyType) => {
            if (
                notifyType === TemplateResponsibleType.DiscoveredInProcessOwner
            ) {
                newSelectedValues[k.NOTIFY] = {
                    ...newSelectedValues[k.NOTIFY],
                    [k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS]: {
                        ...newSelectedValues[k.NOTIFY]?.[
                            k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS
                        ],
                        value: true,
                    },
                };
            } else if (
                notifyType === TemplateResponsibleType.OriginatedInProcessOwner
            ) {
                newSelectedValues[k.NOTIFY] = {
                    ...newSelectedValues[k.NOTIFY],
                    [k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS]: {
                        ...newSelectedValues[k.NOTIFY]?.[
                            k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS
                        ],
                        value: true,
                    },
                };
            } else if (
                notifyType === TemplateResponsibleType.ManagerOfReportingUser
            ) {
                newSelectedValues[k.NOTIFY] = {
                    ...newSelectedValues[k.NOTIFY],
                    [k.REPORTING_USERS_MANAGER]: {
                        ...newSelectedValues[k.NOTIFY]?.[
                            k.REPORTING_USERS_MANAGER
                        ],
                        value: true,
                    },
                };
            }
        });
        setSelectedValues(newSelectedValues);
    }, [configuration]);

    return [selectedValues, setSelectedValues];
};

export const useSelectedDynamicRowValuesInitialization = (
    i18next: typeof i18n,
    configuration?: ITemplateConfigurationDTO,
    hasChanges?: boolean,
): [
    SelectedDynamicValues | undefined,
    Dispatch<SetStateAction<SelectedDynamicValues | undefined>>,
] => {
    const [selectedDynamicValues, setSelectedDynamicValues] = useState<
        SelectedDynamicValues | undefined
    >(undefined);

    useEffect(() => {
        if (!configuration || hasChanges) return;

        let newSelectedValues: Record<string, SelectedDynamicValueOption> = {};

        configuration.involvedUserSets?.length > 0
            ? configuration?.involvedUserSets?.forEach((setId) => {
                  newSelectedValues[setId.id] = {
                      shouldBeNotified: setId.shouldBeNotified,
                      index: setId.index,
                      users: setId.users,
                  };
              })
            : (newSelectedValues = {
                  [uuidv4()]: {
                      shouldBeNotified: false,
                      index: 0,
                      users: [],
                  },
              });

        setSelectedDynamicValues(newSelectedValues);
    }, [configuration]);

    return [selectedDynamicValues, setSelectedDynamicValues];
};
