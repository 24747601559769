import * as Sentry from "@sentry/browser";
import { isOnTestAmpliflowHost } from "config";

const guidPattern =
    /\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\b/gi;

const nonSensitiveKeys = new Set([
    "createdAt",
    "updatedAt",
    "modifiedAt",
    "id",
    "archivedAt",
]);

function isNonSensitiveData(value: string, key: string) {
    const isGuid = guidPattern.test(value);
    return isGuid || nonSensitiveKeys.has(key);
}

function scrubHttpData(data: string): string {
    try {
        const jsonData: Record<string, any> = JSON.parse(data);
        const scrubbedData: Record<string, any> = scrubObject(jsonData);
        return JSON.stringify(scrubbedData);
    } catch (e) {
        return "*****";
    }
}

function scrubObject(obj: Record<string, any>): Record<string, any> {
    Object.keys(obj).forEach((key) => {
        if (
            typeof obj[key] !== "object" &&
            !isNonSensitiveData(obj[key], key)
        ) {
            obj[key] = "*****";
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
            scrubObject(obj[key]);
        }
    });
    return obj;
}

function scrubEvent(event: Sentry.Event, hint?: Sentry.EventHint) {
    if (event.user) {
        delete event.user;
    }
    if (event.request?.data) {
        delete event.request.data;
    }
    if (event.request?.query_string) {
        delete event.request.query_string;
    }

    return event;
}

function scrubBreadcrumb(
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint,
) {
    if (breadcrumb.category === "xhr") {
        const data = {
            requestBody: hint?.xhr?.__sentry_xhr_v3__?.body
                ? scrubHttpData(hint?.xhr.__sentry_xhr_v3__.body)
                : undefined,
            response: hint?.xhr?.response
                ? scrubHttpData(hint?.xhr.response)
                : undefined,
            responseUrl: hint?.xhr?.responseURL,
        };
        return { ...breadcrumb, data };
    }
    return breadcrumb;
}

export function initializeSentry() {
    if (process.env.NODE_ENV === "production" && isOnTestAmpliflowHost()) {
        Sentry.init({
            dsn: "https://a06268b7a59e47b3a0faed9419d1d693@o4506672403709952.ingest.sentry.io/4506705926160384",
            environment: isOnTestAmpliflowHost() ? "testing" : "production",
            integrations: [new Sentry.BrowserTracing()],
            sendDefaultPii: false,
            beforeBreadcrumb(breadcrumb, hint) {
                let newBreadcrumb = scrubBreadcrumb(breadcrumb, hint);
                return newBreadcrumb;
            },
            beforeSend: (event, hint) => {
                let newEvent = scrubEvent(event);
                return newEvent;
            },
            beforeSendTransaction(transaction, hint) {
                let newTransaction = scrubEvent(transaction);
                return newTransaction;
            },
            // Performance Monitoring
            tracesSampleRate: 0.1,
        });
    }
}
