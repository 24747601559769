import "./LazyLoadingFallback.scss";

import i18n from "i18next";

import k from "i18n/keys";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";

const LazyLoadingFallback = () => (
    <div className="lazy-loading__fallback">
        <AnimatedSpinner isVisible={true} position="center" />

        <div className="lazy-loading__fallback--text">{i18n.t(k.LOADING)}</div>
    </div>
);

export default LazyLoadingFallback;
