import ModalContent from "common/components/modal-content/ModalContent";
import ImprovementListedContent from "components/improvements/components/ImprovementListedContent/ImprovementListedContent";
import NewImprovementListedContent from "components/improvements/components/NewImprovementListedContent/NewImprovementListedContent";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { useContext } from "react";

import { ModalTypes, IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const ImprovementsModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, modalFor, options },
    } = props;

    const id = props.modalItem.id ?? modalFor?.id;

    const modalManagerContext = useContext(ModalManagerContext);

    usePublishedUserOptions();

    const handleOnClose = (onSave?: boolean) => {
        if (onSave) {
            const extraCallId = modalFor
                ? `${ModalTypes.select_improvement_form}_${modalFor.type}_${modalFor.id}`
                : "";
            modalManagerContext.onCloseModal({
                callId,
                extraById: [extraCallId, ModalTypes.select_improvement_form],
                onSave: true,
            });
        } else {
            modalManagerContext.onCloseModal({ callId });
        }
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return options?.createNew ? (
        <ModalContent>
            <NewImprovementListedContent
                onShowModal={modalManagerContext.onShowModal}
                onHaveChanges={handleOnHaveChanges}
                onClose={handleOnClose}
                filter={options?.impFilter}
                id={id ?? ""}
                modalForDetails={modalFor}
                checkCanSeeAll={options?.showAll ?? false}
                syncData={modalManagerContext.state.activeModal === callId}
            />
        </ModalContent>
    ) : (
        <ModalContent>
            <ImprovementListedContent
                onShowModal={modalManagerContext.onShowModal}
                onHaveChanges={handleOnHaveChanges}
                onClose={handleOnClose}
                id={id ?? ""}
                checkCanSeeAll={options?.showAll ?? false}
                syncData={modalManagerContext.state.activeModal === callId}
            />
        </ModalContent>
    );
};

export default ImprovementsModal;
