import { useContext } from "react";

import { EModalCallBackTypes, IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import MeasurementProgressForm from "components/measurement/measurement-progress-value/MeasurementProgressForm";

interface IProps {
    modalItem: IModalListItem<EModalCallBackTypes.Id>;
}

const MeasurementProgressFormModal = (props: React.PropsWithoutRef<IProps>) => {
    const {
        modalItem: { id, callId, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <MeasurementProgressForm
            id={id}
            isChanged={modalManagerContext.state.haveChangesList[callId]}
            createNew={options?.createNew}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
            measurementId={options?.parentId}
            onSave={props.modalItem.callbacks?.onSave}
        />
    );
};

export default MeasurementProgressFormModal;
