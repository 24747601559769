import "../RecurringChecklistForm.scss";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import Button from "common/components/buttons/Button";
import { IValueLabelItem } from "common/IValueLabelItem";
import RecurringChecklistTimelinePreview from "./RecurringChecklistTimelinePreview";
import RecurringChecklistSummary from "./RecurringChecklistSummary";
import {
    buildExistingTimelineItems,
    useCalculateMonthlyRecurringChecklists,
    useCalculateWeeklyRecurringChecklists,
    useCalculateYearlyRecurringChecklists,
    useDefaultRecurrenceName,
    useRecurringChecklistGroupsByTemplateVersionId,
    useRecurringChecklistItemsByTemplateVersionId,
    useUpdatePublishedRecurringChecklistsMutation,
} from "../api/hooks";
import { WorkflowScheduleInterval } from "../api/WorkflowScheduleIntervals";
import IValidationMessages from "common/viewModels/IValidationMessages";
import RecurringChecklistEditPublished from "./RecurringChecklistEditPublished";
import { IAppState } from "store/IAppState";
import RecurringChecklistEditStatusButtons from "./RecurringChecklistEditStatusButtons";
import { IRecurringChecklistUpdatePublishedRequest } from "../api/IRecurringChecklistUpdatePublishedRequest";
import { TimelineGroup, TimelineItem } from "react-calendar-timeline";
import { AppLocale } from "AppLocale";
import { IRecurringChecklistListItem } from "../api/IRecurringChecklistListItem";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import ModalContent from "common/components/modal-content/ModalContent";

interface IProps {
    recurringData: IRecurringChecklist;
    template: IValueLabelItem<string, string, string>;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
    onChangeRecurrence: (id: string) => void;
}

const RecurringChecklistEditView: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { template } = props;

    const auth = useSelector((state: IAppState) => state.authViewState);

    const canEdit = auth.roles.ADMINISTRATOR || auth.roles.PLANNER;

    const { label: templateTitle, param: templateVersionId } = template;

    const {
        id,
        name,
        intervalType,
        intervalNumber,
        duration,
        startDate,
        endDate,
    } = props.recurringData;

    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [request, setRequest] =
        useState<IRecurringChecklistUpdatePublishedRequest>({
            id,
            name,
            endDate,
        });

    useEffect(() => {
        if (editMode && id !== request.id) {
            setEditMode(false);
        }
    }, [id]);

    useEffect(() => {
        if (editMode) {
            setRequest({
                id,
                name,
                endDate,
            });
        }
    }, [editMode]);

    const placeholder = useDefaultRecurrenceName(
        AppLocale,
        templateTitle,
        startDate,
        request.endDate,
    );

    const updatePublished = useUpdatePublishedRecurringChecklistsMutation();

    const {
        data: recurringChecklistCalculationsWeekly,
        isLoading: isLoadingCalculationsWeekly,
    } = useCalculateWeeklyRecurringChecklists(
        props.recurringData,
        intervalType === WorkflowScheduleInterval.Weekly,
    );

    const {
        data: recurringChecklistCalculationsMonthly,
        isLoading: isLoadingCalculationsMonthly,
    } = useCalculateMonthlyRecurringChecklists(
        props.recurringData,
        intervalType === WorkflowScheduleInterval.Monthly,
    );

    const {
        data: recurringChecklistCalculationsYearly,
        isLoading: isLoadingCalculationsYearly,
    } = useCalculateYearlyRecurringChecklists(
        props.recurringData,
        intervalType === WorkflowScheduleInterval.Yearly,
    );

    const recurringChecklistCalculations =
        intervalType === WorkflowScheduleInterval.Weekly
            ? recurringChecklistCalculationsWeekly
            : intervalType === WorkflowScheduleInterval.Monthly
              ? recurringChecklistCalculationsMonthly
              : intervalType === WorkflowScheduleInterval.Yearly
                ? recurringChecklistCalculationsYearly
                : undefined;

    const onCancelChange = () => {
        setEditMode(false);

        props.onHaveChanges(false);
    };

    const onShowEditMode = () => {
        setEditMode(true);
    };

    const onPublishChanges = async () => {
        if (templateVersionId) {
            const result = await updatePublished.mutateAsync({
                request,
                templateVersionId,
            });

            if (result.Succeeded) {
                props.onClose(true);
            } else {
                setErrors(result.Messages);
            }
        }
    };

    const handleOnChange = <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => {
        setRequest((prev) => ({
            ...prev,
            [id]: value,
        }));

        props.onHaveChanges(true);
    };

    const [items, setItems] =
        useState<TimelineItem<{ tempItemMode?: "added" | "removed" }>[]>();

    const { data: recurrenceGroups = [], isLoading: isLoadingGroups } =
        useRecurringChecklistGroupsByTemplateVersionId(templateVersionId);

    const { data: recurrenceItems = {}, isLoading: isLoadingItems } =
        useRecurringChecklistItemsByTemplateVersionId(templateVersionId);

    useEffect(() => {
        const group = recurrenceGroups.find((x) => x.id === id);

        if (group) {
            const list = recurrenceItems[id];

            setItems(list ? buildExistingTimelineItems(id, list) : undefined);
        } else {
            setItems(undefined);
        }
    }, [id, recurrenceGroups, recurrenceItems]);

    const handleAddRemoveDates = (
        existing: IRecurringChecklistListItem[],
        added: IRecurringChecklistListItem[],
        removed: IRecurringChecklistListItem[],
    ) => {
        const result = buildExistingTimelineItems(id, existing);

        if (added.length > 0) {
            added.reduce((map, x) => {
                map.push({
                    id: x.id,
                    group: id,
                    start_time: x.start,
                    end_time: x.end,
                    title: x.title,
                    canMove: false,
                    canResize: false,
                    tempItemMode: "added",
                });

                return map;
            }, result);
        } else if (removed.length > 0) {
            removed.reduce((map, x) => {
                map.push({
                    id: x.id,
                    group: id,
                    start_time: x.start,
                    end_time: x.end,
                    title: x.title,
                    canMove: false,
                    canResize: false,
                    tempItemMode: "removed",
                });

                return map;
            }, result);
        }

        setItems(result);
    };

    const group = React.useMemo(() => {
        if (editMode === false) {
            return undefined;
        }

        let name = request.name ?? "";

        if (request.name === undefined || request.name === "") {
            name = placeholder;
        }

        const result: TimelineGroup<{ name: string; selected: boolean }> = {
            id,
            selected: true,
            name,
            title: name,
        };

        return result;
    }, [id, editMode, AppLocale, request]);

    const intervalName =
        intervalType === WorkflowScheduleInterval.Monthly
            ? t(k.MONTHLY)
            : intervalType === WorkflowScheduleInterval.Yearly
              ? t(k.YEARLY)
              : t(k.WEEKLY);

    return (
        <ModalContent>
            <ModalContent.Header
                onClose={props.onClose}
                title={
                    <div className="recurring-checklist--title">
                        {name}
                        <div className="recurring-checklist--title--sub">
                            {templateTitle}
                        </div>
                    </div>
                }
            />

            <ModalContent.Body className="recurring-checklist--body">
                <div className="recurring-checklist--body--content">
                    <AnimatedSpinner
                        isVisible={isLoadingGroups || isLoadingItems}
                        position="center"
                    />

                    {editMode && endDate && startDate && (
                        <RecurringChecklistEditPublished
                            selectedRecurringChecklistId={id}
                            template={template}
                            existingChecklists={recurrenceItems[id] ?? []}
                            name={request.name ?? ""}
                            placeholder={placeholder}
                            startDate={startDate}
                            endDate={endDate}
                            errors={errors}
                            onChange={handleOnChange}
                            onAddRemoveDates={handleAddRemoveDates}
                        />
                    )}

                    <RecurringChecklistTimelinePreview
                        type={intervalType}
                        selectedRecurringChecklistId={id}
                        templateVersionId={templateVersionId ?? null}
                        startDate={startDate}
                        firstGroup={editMode ? group : undefined}
                        firstGroupItems={editMode ? items : undefined}
                        onChangeRecurrence={props.onChangeRecurrence}
                    />

                    <RecurringChecklistSummary
                        intervalName={intervalName}
                        interval={intervalNumber}
                        duration={duration}
                        calculatedResult={recurringChecklistCalculations?.Data}
                    />
                </div>
            </ModalContent.Body>
            <ModalContent.Footer>
                {canEdit && (
                    <div className="recurring-checklist--foot">
                        {editMode ? (
                            <>
                                <Button
                                    onClick={onCancelChange}
                                    variant="gray"
                                    disabled={updatePublished.isLoading}
                                    isBusy={updatePublished.isLoading}
                                >
                                    {t(k.CANCEL)}
                                </Button>
                                <Button
                                    onClick={onPublishChanges}
                                    variant="success"
                                    disabled={updatePublished.isLoading}
                                    isBusy={updatePublished.isLoading}
                                >
                                    {t(k.SAVE_AND_PUBLISH)}
                                </Button>
                            </>
                        ) : (
                            <>
                                <RecurringChecklistEditStatusButtons
                                    workflowScheduleId={id}
                                    templateVersionId={
                                        templateVersionId ?? null
                                    }
                                    isPaused={props.recurringData.isPaused}
                                    isArchived={props.recurringData.isArchived}
                                    onClose={props.onClose}
                                />

                                <Button
                                    onClick={onShowEditMode}
                                    disabled={props.recurringData.isArchived}
                                >
                                    {t(k.EDIT)}
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default RecurringChecklistEditView;
