import "./StepListItem.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IStepInstance from "models/IStepInstance";
import { StepInstanceCompletionType } from "models/enums/StepInstanceCompletionType";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import NumberOfMax from "common/components/number-of-max/NumberOfMax";
import OpenImprovements from "common/components/open-improvements/OpenImprovements";
import { EParentType } from "./ListItems/ListItems";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";

interface IProps {
    stepInstance?: IStepInstance;
    index?: number;
    selected: boolean;
    parentType?: EParentType;

    onSelected: (id: string) => void;
}

const StepInstanceListItem: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { stepInstance, index, selected, parentType } = props;

    if (!stepInstance) {
        return null;
    }

    const {
        id,
        name,
        completed: completedState,
        completedActivitiesCount: completedAct = 0,
        totalActivitiesCount: totalAct = 0,
        notCompletedImprovementsCount: openImprovements = 0,
    } = stepInstance;

    const { t } = useTranslation();

    const isChecklist = parentType === EParentType.checklist;

    const onClick = () => props.onSelected(id);

    const stepName =
        (parentType === EParentType.improvement &&
            name &&
            _improvementStepTitles(name, false, true)) ||
        name;

    return (
        <div
            className={`steps-list-item ${selected ? "selected" : ""}`}
            onClick={onClick}
        >
            <div className="steps-list-item--column">
                <Checkbox
                    value={completedState === StepInstanceCompletionType.Ok}
                    disabled
                    variant="success"
                />

                <div className="steps-list-item--title">
                    {index}. {getLocaleString(t, stepName)}
                </div>
            </div>

            {isChecklist && (
                <div className="steps-list-item--column">
                    {!!openImprovements && (
                        <OpenImprovements value={openImprovements} />
                    )}

                    <NumberOfMax
                        variant={
                            completedAct === 0
                                ? undefined
                                : completedAct < totalAct
                                  ? "draft"
                                  : "success"
                        }
                        value={completedAct}
                        max={totalAct}
                    />
                </div>
            )}
        </div>
    );
};

export default StepInstanceListItem;
