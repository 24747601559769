import { v4 as uuidv4 } from "uuid";

import { IChecklistReportExpandableColumnRequest } from "./IChecklistReportExpandableColumnRequest";
import { IChecklistReportExpandableColumnResponse } from "./IChecklistReportExpandableColumnResponse";
import { IValueLabelItem } from "common/IValueLabelItem";
import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";
import {
    ChecklistReportValueColumnType,
    IChecklistReportValueSumFilter,
} from "components/template-checklist-report/api/IChecklistReportValueSumFilter";
import {
    ITemplateReportActivity,
    ITemplateReportActivityTitle,
} from "components/template-checklist-report/api/ITemplateReportActivity";
import { TemplateReportValuesByColumnKeyIdType } from "components/template-checklist-report/api/TemplateReportValuesByColumnKeyIdType";
import { ActivityType } from "models/enums/ActivityType";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";

type TableMatrixCellType = {
    isHidden?: boolean;
    rowSpan?: number;
    isCellExpanded?: boolean;

    value?: ITemplateReportActivity;
    stepInstance?: IValueLabelItem<string, string, StepInstanceStatus>;
};

export type TableMatrixRowType = {
    cellValues: TableMatrixCellType[];
};

type ColumnsArrayType = Array<ITemplateReportActivityTitle | undefined>;

export interface ITableMatrix {
    columns: ColumnsArrayType;

    valueColumns: Pick<
        IChecklistReportValueSumFilter,
        "hasValueColumn" | "columns"
    >;

    rowSpans: Record<string, number | undefined>;

    primaryRowsByChecklistId: Record<string, TableMatrixRowType>;
    secondaryRowsByChecklistId: Record<
        string,
        TableMatrixRowType[] | undefined
    >;

    columnsCountByStepInstanceId: Record<string, number | undefined>;

    allUniqueSelectedCustomListItemIds?: Array<{ key: string; value?: Date }>;
}

export class TableMatrix implements ITableMatrix {
    columns: ColumnsArrayType = [];

    valueColumns: Pick<
        IChecklistReportValueSumFilter,
        "hasValueColumn" | "columns"
    > = {
        hasValueColumn: false,
        columns: [],
    };

    rowSpans: Record<string, number | undefined> = {};

    primaryRowsByChecklistId: Record<string, TableMatrixRowType> = {};
    secondaryRowsByChecklistId: Record<
        string,
        TableMatrixRowType[] | undefined
    > = {};

    columnsCountByStepInstanceId: Record<string, number | undefined> = {};

    allUniqueSelectedCustomListItemIds?: Array<{ key: string; value?: Date }>;

    private reportDetails?: IChecklistReportResponse;
    private checklistIds?: string[];

    setReportDetails = (
        checklistIds: string[],
        reportDetails: IChecklistReportResponse,
    ) => {
        if (
            this.checklistIds === undefined ||
            this.reportDetails === undefined
        ) {
            this.checklistIds = checklistIds;
            this.reportDetails = reportDetails;

            this.columns = this.getInitialColumns(this.reportDetails);
            this.primaryRowsByChecklistId = this.getInitialValues();
        }
    };

    private updateUniqueSelectedCustomListItemIds(
        newUniqueSelectedCustomListItemIds: Array<{
            key: string;
            value?: Date;
        }>,
    ) {
        let allUniqueIds: typeof newUniqueSelectedCustomListItemIds;

        if (this.allUniqueSelectedCustomListItemIds) {
            allUniqueIds = newUniqueSelectedCustomListItemIds.concat(
                this.allUniqueSelectedCustomListItemIds,
            );
        } else {
            allUniqueIds = newUniqueSelectedCustomListItemIds.concat(
                this.reportDetails?.uniqueSelectedCustomListItemIds ?? [],
            );
        }

        const uniqueByKeyMap = new Map(allUniqueIds.map((x) => [x.key, x]));

        this.allUniqueSelectedCustomListItemIds = Array.from(
            uniqueByKeyMap.values(),
        );
    }

    private getStepInstanceCellValue(
        checklistId: string,
        headerStepInstanceIndex: number,
    ) {
        const stepInstanceId =
            this.reportDetails?.valuesByChecklistId[checklistId]
                ?.stepInstanceIds[headerStepInstanceIndex];

        const result: TableMatrixCellType = {};

        if (stepInstanceId) {
            result.stepInstance =
                this.reportDetails?.stepInstancesById[stepInstanceId];
        }

        return result;
    }

    private getInitialValues() {
        const result: Record<string, TableMatrixRowType> = {};

        const headerLevelOneStepInstanceIds =
            this.reportDetails?.headerLevelOneStepInstanceIds ?? [];

        const headerLevelTwoColumns =
            this.reportDetails
                ?.headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId ?? {};

        this.checklistIds?.reduce<typeof result>((acc, checklistId) => {
            const cellValues: TableMatrixCellType[] = [];

            for (
                let headerStepInstanceIndex = 0;
                headerStepInstanceIndex < headerLevelOneStepInstanceIds.length;
                headerStepInstanceIndex++
            ) {
                cellValues.push(
                    this.getStepInstanceCellValue(
                        checklistId,
                        headerStepInstanceIndex,
                    ),
                );

                const headerStepInstanceId =
                    headerLevelOneStepInstanceIds[headerStepInstanceIndex];

                for (const { columnKeyId } of headerLevelTwoColumns[
                    headerStepInstanceId
                ]) {
                    const valuesByColumnKeyId =
                        this.reportDetails?.valuesByChecklistId[checklistId]
                            ?.valuesByActivityVersionIdByHeaderLevelOneStepInstanceId[
                            headerStepInstanceId
                        ];

                    const cellValue: TableMatrixCellType = {
                        value: valuesByColumnKeyId?.[columnKeyId],
                    };

                    cellValues.push(cellValue);
                }
            }

            const rowValues: TableMatrixRowType = {
                cellValues,
            };

            acc[checklistId] = rowValues;

            return acc;
        }, result);

        return result;
    }

    private getNewRowCellValues(
        newColumns: ITemplateReportActivityTitle[],
        rowSpan: number,
        columnValuesByColumnKeyId?: TemplateReportValuesByColumnKeyIdType,
    ) {
        const addedCellValues = newColumns.reduce<TableMatrixCellType[]>(
            (acc, column) => {
                const cellValue: TableMatrixCellType = {
                    value: columnValuesByColumnKeyId?.[column.columnKeyId],
                    isHidden: false,
                };

                if (rowSpan > 1) {
                    cellValue.rowSpan = rowSpan;
                }

                acc.push(cellValue);

                return acc;
            },
            [],
        );

        return addedCellValues;
    }

    private findLeastCommonMultiple(arr: number[]) {
        arr = arr.sort((a, b) => a - b);

        let num = arr[0];

        for (let i = 1; i < arr.length; i++) {
            let j = 1;

            while (num % arr[i] !== 0) {
                j = j + 1;

                num = j * arr[0];
            }

            arr[0] = num;
        }

        return num;
    }

    private updateValuesOnExpand(
        columnIndex: number,
        response: IChecklistReportExpandableColumnResponse,
    ) {
        const checklistIds = this.checklistIds ?? [];

        const result: Record<string, TableMatrixRowType[]> = {};

        for (const checklistId of checklistIds) {
            const rows = this.getAllRowsByChecklistId(checklistId);

            const allRowIds = response.rowIdsByChecklistId[checklistId] ?? [];

            const maxRowIdsCount = Math.max(
                ...allRowIds.map((x) => (x.length === 0 ? 1 : x.length)),
            );

            const expandableColumnRowSpan =
                rows[0].cellValues[columnIndex].rowSpan ?? 1;

            const leastCommonMultiple = this.findLeastCommonMultiple([
                maxRowIdsCount,
                expandableColumnRowSpan,
            ]);

            const prevTotalRowsCount = rows.length;

            const nextTotalRowsCount = leastCommonMultiple * allRowIds.length;

            const shouldAddNewRows = nextTotalRowsCount > prevTotalRowsCount;

            if (shouldAddNewRows) {
                const diff = nextTotalRowsCount - prevTotalRowsCount;

                for (let i = 0; i < diff; i++) {
                    const newRow = this.getNewEmptyRow(checklistId);

                    rows.push(newRow);
                }

                this.rowSpans[checklistId] = rows.length;

                const columnsCount =
                    this.primaryRowsByChecklistId[checklistId].cellValues
                        .length;

                const rowSpanMutliplier =
                    leastCommonMultiple / expandableColumnRowSpan;

                for (let i = 0; i < prevTotalRowsCount; i++) {
                    const rowIndex = prevTotalRowsCount - 1 - i;

                    for (let j = 0; j < columnsCount; j++) {
                        const cell = rows[rowIndex].cellValues[j];

                        if (cell.isHidden) {
                            continue;
                        }

                        const prevRowSpan = cell.rowSpan ?? 1;

                        const nextRowSpan = prevRowSpan * rowSpanMutliplier;

                        cell.rowSpan =
                            nextRowSpan > 1 ? nextRowSpan : undefined;

                        if (rowIndex > 0) {
                            // relocate cell values

                            const newRowIndex = rowSpanMutliplier * rowIndex;

                            const emptyCell = rows[newRowIndex].cellValues[j];

                            rows[newRowIndex].cellValues[j] = cell;

                            rows[rowIndex].cellValues[j] = emptyCell;
                        }
                    }
                }
            }

            // add empty cells to the right of the expanded column

            for (const row of rows) {
                row.cellValues[columnIndex].isCellExpanded = true;

                const emptyCellValues =
                    response.columns.map<TableMatrixCellType>(() => ({
                        isHidden: true,
                    }));

                row.cellValues.splice(columnIndex + 1, 0, ...emptyCellValues);
            }

            // assign actual new cell values

            const rowIdsWithMaxWidth: Array<string | null>[] = [];

            for (let i = 0; i < allRowIds.length; i++) {
                const emptyRowIdsDiffCount =
                    maxRowIdsCount > allRowIds[i].length
                        ? maxRowIdsCount - allRowIds[i].length
                        : 0;

                const rowIds = Array.from(Array(emptyRowIdsDiffCount).keys())
                    .map<string | null>((x) => null)
                    .concat(allRowIds[i].map<string | null>((id) => id));

                rowIdsWithMaxWidth.push(rowIds);
            }

            let rowIndex: number = 0;

            const rowSpan = leastCommonMultiple / maxRowIdsCount;

            for (const rowIds of rowIdsWithMaxWidth) {
                for (const rowId of rowIds) {
                    const columnValuesByColumnKeyId = rowId
                        ? response.columnValuesByRowId[rowId]
                        : undefined;

                    const addedCellValues = this.getNewRowCellValues(
                        response.columns,
                        rowSpan,
                        columnValuesByColumnKeyId,
                    );

                    const row = rows[rowIndex];

                    row.cellValues.splice(
                        columnIndex + 1,
                        addedCellValues.length,
                    );

                    row.cellValues.splice(
                        columnIndex + 1,
                        0,
                        ...addedCellValues,
                    );

                    rowIndex += rowSpan;
                }
            }

            result[checklistId] = rows;
        }

        this.bindRows(result);
    }

    private getNewEmptyRow(checklistId: string) {
        const row = {
            checklistId,
            cellValues: this.columns.map<TableMatrixCellType>(() => ({
                isHidden: true,
            })),
        };

        return row;
    }

    private updateColumnsCountByStepInstanceId() {
        this.columnsCountByStepInstanceId = this.columns.reduce<
            Record<string, number | undefined>
        >((acc, column) => {
            const { stepInstanceId } = column?.expandInfo ?? {};

            if (stepInstanceId) {
                acc[stepInstanceId] = (acc[stepInstanceId] ?? 0) + 1;
            }

            return acc;
        }, {});
    }

    private getStatusColumn(expandInfo: {
        level: number;
        stepInstanceId: string;
        isTopLevel?: boolean;
    }) {
        const result: ITemplateReportActivityTitle = {
            id: uuidv4(),
            title: "",
            type: ActivityType.Value,
            columnKeyId: "",
            isStatusColumn: true,

            expandInfo,
        };

        return result;
    }

    expand = (
        id: string,
        response: IChecklistReportExpandableColumnResponse | null,
    ) => {
        const columnIndex = this.columns.findIndex((x) => x?.id === id);

        const column = this.columns[columnIndex];

        const addedColumns =
            response?.columns.map((item) => {
                const newColumn: ITemplateReportActivityTitle = {
                    ...item,

                    expandInfo: {
                        level: (column?.expandInfo?.level ?? 0) + 1,
                        parentCustomListId: column?.customListId,
                        stepInstanceId:
                            column?.expandInfo?.stepInstanceId ?? "",

                        columnKeyIdsPath: (
                            column?.expandInfo?.columnKeyIdsPath ?? []
                        ).concat(column?.columnKeyId ?? ""),
                    },
                };

                return newColumn;
            }) ?? [];

        if (column?.expandInfo) {
            this.columns.splice(columnIndex + 1, 0, ...addedColumns);

            this.valueColumns = this.getValueColumns(this.columns);

            column.expandInfo.isExpanded = true;
            column.expandInfo.level = column.expandInfo.level + 1;

            this.updateColumnsCountByStepInstanceId();

            if (response) {
                this.updateValuesOnExpand(columnIndex, response);

                this.updateUniqueSelectedCustomListItemIds(
                    response.uniqueSelectedCustomListItemIds ?? [],
                );
            }
        }
    };

    collapse = (id: string) => {
        const columnIndex = this.columns.findIndex((x) => x?.id === id);

        const column = this.columns[columnIndex];

        const { level: columnLevel } = column?.expandInfo ?? {
            level: 0,
        };

        const stopIndex = this.columns.findIndex((x, index) => {
            const { level, isExpanded } = x?.expandInfo ?? { level: 0 };

            return (
                index > columnIndex &&
                (level < columnLevel || (level === columnLevel && isExpanded))
            );
        });

        const columnsLength = this.columns.length;

        const deleteCount =
            (stopIndex === -1 ? columnsLength - 1 : stopIndex - 1) -
            columnIndex;

        if (column?.expandInfo) {
            this.columns.splice(columnIndex + 1, deleteCount);

            this.valueColumns = this.getValueColumns(this.columns);

            column.expandInfo.isExpanded = false;
            column.expandInfo.level = column.expandInfo.level - 1;

            this.updateColumnsCountByStepInstanceId();

            this.tryResetRows(columnIndex, deleteCount);
        }
    };

    private tryResetRows(columnIndex: number, deleteCount: number) {
        const anyExpandedColumn = this.columns.some(
            (x) => x?.expandInfo?.isExpanded,
        );

        if (anyExpandedColumn) {
            for (const checklistId of this.checklistIds ?? []) {
                const rows = this.getAllRowsByChecklistId(checklistId);

                for (const row of rows) {
                    if (columnIndex < row.cellValues.length) {
                        row.cellValues[columnIndex].isCellExpanded = false;
                    }

                    row.cellValues.splice(columnIndex + 1, deleteCount);
                }
            }
        } else {
            this.columns = this.getInitialColumns(this.reportDetails ?? null);

            this.rowSpans = {};

            this.primaryRowsByChecklistId = this.getInitialValues();

            this.secondaryRowsByChecklistId = {};

            this.allUniqueSelectedCustomListItemIds = undefined;
        }
    }

    private getAllRowsByChecklistId(checklistId: string) {
        const primaryRows = this.primaryRowsByChecklistId[checklistId];

        const secondaryRows =
            this.secondaryRowsByChecklistId[checklistId] ?? [];

        return [primaryRows].concat(secondaryRows);
    }

    private bindRows(
        rowsByChecklistId: Record<string, TableMatrixRowType[] | undefined>,
    ) {
        const checklistIds = this.checklistIds ?? [];

        for (const checklistId of checklistIds) {
            const rows = rowsByChecklistId[checklistId];

            if (rows && rows.length > 0) {
                this.primaryRowsByChecklistId[checklistId] = rows[0];

                this.secondaryRowsByChecklistId[checklistId] = rows.slice(1);
            }
        }
    }

    /*

    getColumnsLabelsByType = (
        type: ActivityType,
        rowsByChecklistId: Record<string, TableMatrixRowType[] | undefined>,
    ) => {
        const checklistIds = this.checklistIds ?? [];

        // result has same length as this.columns
        const result = Array.from<string[] | undefined>(
            new Array(this.columns.length),
        );

        this.columns.reduce((acc, column, colIndex) => {
            if (column && !column.isStatusColumn && column.type === type) {
                let columnLabels: string[] = [];

                for (const checklistId of checklistIds) {
                    const labels = rowsByChecklistId[checklistId]?.flatMap(
                        (row) =>
                            row?.cellValues[colIndex].value?.values
                                ?.map((x) => (x.label ? x.label : ""))
                                .filter((x) => x.trim() !== "") ?? [],
                    );

                    columnLabels = columnLabels.concat(labels ?? []);
                }

                if (columnLabels.length > 0) {
                    acc[colIndex] = columnLabels;
                }
            }

            return acc;
        }, result);

        return result;
    };

    */

    getRequest = (id: string) => {
        const columnIndex = this.columns.findIndex((x) => x?.id === id);

        const column = this.columns[columnIndex];

        const { customListId, expandInfo } = column ?? {};

        if (customListId) {
            const { isTopLevel, parentCustomListId } = expandInfo ?? {};

            const rowIdsByChecklistId: Record<string, Array<string[]>> = {};

            this.checklistIds?.reduce<typeof rowIdsByChecklistId>(
                (acc, checklistId) => {
                    const rows = this.getAllRowsByChecklistId(checklistId);

                    const selectedIds: Array<string[]> = [];

                    for (const row of rows) {
                        const cellValue = row.cellValues[columnIndex];

                        if (cellValue.isHidden) {
                            continue;
                        }

                        const selectedCustomListItemIds =
                            cellValue.value?.values?.map((x) => x.value) ?? [];

                        selectedIds.push(selectedCustomListItemIds);
                    }

                    acc[checklistId] = selectedIds;

                    return acc;
                },
                rowIdsByChecklistId,
            );

            const request: IChecklistReportExpandableColumnRequest = {
                customListId,
                rowIdsByChecklistId,
                parentCustomListId: isTopLevel ? undefined : parentCustomListId,
            };

            return request;
        }
    };

    private getInitialColumns(reportDetails: IChecklistReportResponse | null) {
        const result =
            reportDetails?.headerLevelOneStepInstanceIds.flatMap(
                (stepInstanceId) => {
                    const columns =
                        reportDetails?.headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId?.[
                            stepInstanceId
                        ].map((activity) => {
                            const result: ITemplateReportActivityTitle = {
                                ...activity,

                                expandInfo: {
                                    level: 0,
                                    isTopLevel: true,
                                    stepInstanceId,
                                },
                            };

                            return result;
                        }) ?? [];

                    const statusColumn = this.getStatusColumn({
                        level: 0,
                        isTopLevel: true,
                        stepInstanceId,
                    });

                    return [statusColumn].concat(columns);
                },
            ) ?? [];

        return result;
    }

    private getValueColumns = (columns: ColumnsArrayType) => {
        const result = columns.reduce<
            Pick<IChecklistReportValueSumFilter, "hasValueColumn" | "columns">
        >(
            (acc, el) => {
                if (el) {
                    const newItem: ChecklistReportValueColumnType = {
                        id: el.id,
                        type: el.type,
                        isStatusColumn: el.isStatusColumn ?? false,
                        columnKeyId: null,
                        path: null,
                        isActivity: el.expandInfo?.isTopLevel ?? false,
                    };

                    acc.columns.push(newItem);

                    if (
                        el.type === ActivityType.Value &&
                        newItem.isStatusColumn === false
                    ) {
                        acc.hasValueColumn = true;

                        newItem.columnKeyId = el.columnKeyId;
                        newItem.path = el.expandInfo?.columnKeyIdsPath ?? null;
                    }
                }

                return acc;
            },
            {
                hasValueColumn: false,
                columns: [],
            },
        );

        return result;
    };

    getValueColumnsFromReportDetails = (
        reportDetails: IChecklistReportResponse,
    ) => {
        const columns = this.getInitialColumns(reportDetails);

        return this.getValueColumns(columns);
    };
}
