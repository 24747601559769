import "./NumberOfMax.scss";

import React, { useMemo } from "react";

interface IProps {
    value: number;
    max: number;
    variant?: "draft" | "success";
}

const NumberOfMax: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, max, variant } = props;

    const classNames = useMemo(() => {
        const result: string[] = ["number-of-max"];

        if (variant) {
            result.push(variant);
        }

        return result.join(" ");
    }, [variant]);

    const output = `${value} / ${max}`;

    return <div className={classNames}>{output}</div>;
};

export default NumberOfMax;
