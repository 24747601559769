import axios from "axios";
import { useQuery } from "react-query";

import { IValueLabelItem } from "common/IValueLabelItem";
import IDictionary from "common/viewModels/IDictionary";
import ITableSet from "http/ITableSet";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";

const baseUrl = "/api/role";

const roleKeys = {
    published: (addUserRole: boolean) =>
        ["published-roles", addUserRole] as const,
    rolesByTeamIds: (teamIds?: string[]) =>
        ["roles-by-team-ids", teamIds] as const,
};

export const usePublishedRoles = (enabled = true, addUserRole = true) => {
    const url = `${baseUrl}/published`;

    return useQuery(
        roleKeys.published(addUserRole),
        async (context) => {
            const response = await axios.get<IDictionary<string>>(url, {
                signal: context.signal,
            });

            if (addUserRole) {
                response.data["USER"] = "USER";
            }
            const ids = Object.keys(response.data);

            const options: IValueLabelItem<string, string>[] = ids.map((x) => ({
                value: x,
                label: response.data[x],
            }));

            const result: ITableSetWithValueLabelOptions<string> = {
                ids,
                values: response.data,
                options,
            };

            return result;
        },
        {
            enabled,
            isDataEqual(oldData, newData) {
                if (oldData && newData) {
                    if (addUserRole) {
                        return (
                            JSON.stringify(oldData.values) ===
                            JSON.stringify({
                                ...newData.values,
                                ["USER"]: "USER",
                            })
                        );
                    } else {
                        return (
                            JSON.stringify(oldData.values) ===
                            JSON.stringify(newData.values)
                        );
                    }
                }
                return false;
            },
        },
    );
};

export const useRolesByTeamIds = (teamIds?: string[]) => {
    const url = `${baseUrl}/roles-by-team-ids`;

    return useQuery(
        roleKeys.rolesByTeamIds(teamIds),
        async (context) => {
            const response = await axios.post<ITableSet<string[]>>(
                url,
                teamIds,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
        { enabled: Boolean(teamIds) },
    );
};
