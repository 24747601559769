import "./Tasklist.scss";

import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import RenderIfNotArchived from "components/common/shared-properties/components/inputs/RenderIfNotArchived";
import { PropsWithChildren } from "react";
import { IInputChangeEvent, IInputProps } from "../IInputProps";
import Checkbox, { ICheckboxProps } from "./Checkbox";
import Spinner from "react-bootstrap/esm/Spinner";
import EditActivityInstanceButton from "common/components/activity-instance-card/components/EditActivityInstanceButton/EditActivityInstanceButton";
import DeleteActivityInstanceButton from "common/components/activity-instance-card/components/DeleteActivityInstanceButton/DeleteActivityInstanceButton";
import Editable from "common/components/activity-instance-card/components/Editable/Editable";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

export interface ITasklistProps extends ICheckboxProps {
    tasklist?: IValueLabelItemWithState[];
    onEditTaskClick?: (id: string) => void;
    onDeleteTask?: (id: string) => void;
    onLabelChange?: (id: string, newLabel?: string) => void;
}

type Props<V> = ITasklistProps & IInputProps<V>;

const Tasklist = <V,>(props: PropsWithChildren<Props<V>>) => {
    const {
        id,
        tasklist,
        disabled,
        helperText,
        wrapperClassName,
        isLoading,
        show,
    } = props;

    const value = props.value as string[];

    const { t } = useTranslation();

    const userDefinedTasks = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.userDefinedTasks ===
            true
        );
    });

    const handleChange = (e: IInputChangeEvent<boolean>) => {
        if (props.onChange && e.name) {
            const newValue = e.value
                ? value.concat(e.name)
                : value.filter((x) => x !== e.name);

            props.onChange({
                ...(e as IInputChangeEvent<V>),
                value: newValue as V,
            });
        }
    };

    const onEditClick = (isEditMode?: boolean, id?: string) => {
        if (id) {
            props.onEditTaskClick?.(id);
        }
    };

    const onDeleteTask = (id?: string) => {
        if (id) {
            props.onDeleteTask?.(id);
        }
    };

    const handleLabelChange = (id?: string, newLabel?: string) => {
        if (id) {
            props.onLabelChange?.(id, newLabel);
        }
    };

    const emptyTasklist = (tasklist && tasklist.length > 0) || !show;
    return (
        <>
            <div
                className={`tasklist--wrapper${
                    wrapperClassName ? " " + wrapperClassName : ""
                }`}
            >
                {emptyTasklist ? (
                    tasklist?.map((x, i) => (
                        <RenderIfNotArchived
                            key={x.value}
                            value={x}
                            ids={value}
                        >
                            <>
                                {x.isInEditMode ? (
                                    <Editable
                                        id={x.value}
                                        textLabel={x.label}
                                        value={x.value}
                                        onLabelChange={handleLabelChange}
                                        onCancelAddActivityInstance={
                                            onEditClick
                                        }
                                    />
                                ) : (
                                    <div className="tasklist--task-wrapper">
                                        <span>
                                            {x.isLoading && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                />
                                            )}
                                            <Checkbox
                                                id={i === 0 ? id : undefined}
                                                name={x.value}
                                                value={value.includes(x.value)}
                                                label={x.label}
                                                disabled={
                                                    disabled || x.isLoading
                                                }
                                                invalid={
                                                    x.isArchived || x.isDeleted
                                                }
                                                onChange={handleChange}
                                            />
                                        </span>

                                        {x.isUserDefined &&
                                            userDefinedTasks && (
                                                <div className="checkbox--tags">
                                                    <EditActivityInstanceButton
                                                        disabled={
                                                            (x.isInEditMode ||
                                                                disabled) ??
                                                            false
                                                        }
                                                        id={x.value}
                                                        onClick={onEditClick}
                                                    />
                                                    <DeleteActivityInstanceButton
                                                        onClick={onDeleteTask}
                                                        id={x.value}
                                                        disabled={
                                                            x.isInEditMode ||
                                                            disabled
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                            </>
                        </RenderIfNotArchived>
                    ))
                ) : isLoading ? (
                    <Spinner animation="border" size="sm" variant="dark" />
                ) : (
                    <p className="tasklist--empty">
                        {t(k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN)}
                    </p>
                )}
                {helperText && (
                    <div className="tasklist--helper-text">{helperText}</div>
                )}
            </div>
        </>
    );
};

export default Tasklist;
