import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import EditableStep from "components/steps/components/editable-step/EditableStep";
import NoStepsPlaceholder from "components/templates/components/NoStepsPlaceholder";
import k from "i18n/keys";
//import EditableStep from "./components/EditableStep";
import IStep from "models/IStep";
import { v4 as uuidv4 } from "uuid";

import TemplateFormContext from "../../context/TemplateFormContext";

import "./Content.scss";

const stepsReq = "steps.required";

const Content: React.FC = (props) => {
    const {
        steps,
        sectionRefs,
        setSteps,
        onHaveChanges,
        isArchived,
        errors,
        setErrors,
        handleOnCopyStep,
    } = useContext(TemplateFormContext);

    const { t } = useTranslation();

    const handleOnAddStep = () => {
        if (setSteps && steps) {
            const newStep: IStep = {
                id: uuidv4(),
                stepVersionId: uuidv4(),
                name: t(k.NEW_STEP),
                description: "",
                isLocal: true,
                isDraft: true,
                index: steps.ids.length,
                teamIds: [],
                equipmentIds: [],
                hasPublishedVersion: false,
                hasNewVersion: false,
                isTeamEveryone: true,
                version: 0,
                isAdded: true,
            };

            setSteps((prev) => ({
                ...prev,
                ids: [...prev.ids, newStep.id],
                values: {
                    ...prev.values,
                    [newStep.id]: newStep,
                },
            }));

            if (errors?.[stepsReq]) {
                setErrors((prev) => ({
                    ...prev,
                    [stepsReq]: undefined,
                }));
            }

            onHaveChanges(true);
        }
    };

    return (
        <>
            {steps && sectionRefs && steps.ids.length ? (
                steps.ids.map((id, index) => {
                    const step = steps.values[id];
                    const stepRef = sectionRefs[id];

                    if (step && stepRef && setSteps && setErrors) {
                        return (
                            <EditableStep
                                onCopyStep={handleOnCopyStep}
                                key={id}
                                stepRef={stepRef}
                                index={index + 1}
                                step={step}
                                errors={errors}
                                isArchived={isArchived}
                                parentType={EParentType.template}
                                setSteps={setSteps}
                                setErrors={setErrors}
                                onHaveChanges={onHaveChanges}
                            />
                        );
                    }
                })
            ) : (
                <NoStepsPlaceholder
                    onAddStep={handleOnAddStep}
                    disabled={isArchived}
                />
            )}
        </>
    );
};

export default Content;
