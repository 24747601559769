import React, { useLayoutEffect } from "react";
import { CellProps } from "react-datasheet-grid";
import Select, { GroupBase, SelectInstance } from "react-select";

import { IValueLabelItem } from "common/IValueLabelItem";
import { SelectOptions } from "../../api/SelectOptions";
import { buildStyles } from "../../styles";

function isArray(items: any): items is IValueLabelItem[] {
    return Array.isArray(items);
}

function selectManyComponent(props: CellProps<string[] | null, SelectOptions>) {
    const { active, rowData, setRowData, focus, stopEditing, columnData } =
        props;

    const ref =
        React.useRef<
            SelectInstance<IValueLabelItem, true, GroupBase<IValueLabelItem>>
        >(null);

    useLayoutEffect(() => {
        if (focus) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }
    }, [focus]);

    const styles = React.useMemo(
        () => buildStyles({ active, focus }),
        [active, focus],
    );

    return (
        <Select
            ref={ref}
            isMulti
            styles={styles}
            isDisabled={columnData.disabled}
            value={columnData.choices.filter(({ value }) =>
                rowData?.includes(value),
            )}
            menuPortalTarget={document.body}
            menuIsOpen={focus}
            closeMenuOnSelect={false}
            onChange={(choices) => {
                if (choices === null) return;

                if (isArray(choices)) {
                    setRowData(choices.map((x) => x.value));
                }
            }}
            onMenuClose={() => stopEditing({ nextRow: false })}
            options={columnData.choices}
        />
    );
}

export const SelectManyComponent = React.memo(
    selectManyComponent,
) as typeof selectManyComponent;
