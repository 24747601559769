import React, { ReactElement } from "react";

export const getLastRowCellsCount = (children?: React.ReactNode): number => {
    if (children) {
        const rows = React.Children.toArray(children).filter(
            (x) => x !== undefined,
        );

        if (rows && rows.length > 0) {
            const lastRow = rows[rows.length - 1] as ReactElement;

            const lastRowChildren = React.Children.toArray(
                lastRow.props?.children,
            );

            const notUndefinedChildren = lastRowChildren.filter(
                (x) => x !== undefined,
            );

            return React.Children.count(notUndefinedChildren);
        }
    }

    return 0;
};
