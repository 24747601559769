import "./PropertyListItem.scss";

import React from "react";
import AddSvg from "../assets/add.svg";
import { IconBg } from "common/components/icons";

export interface IPropertyListItemProps<T> {
    type: T;

    id?: string;
    testId?: string;
    icon: React.ReactNode;
    title: string;
    subTitle?: string;

    onSelect: (type: T, title: string, id?: string) => void;
}

const PropertyListItem = <T,>(props: IPropertyListItemProps<T>) => {
    const { type, testId, icon, title, subTitle, id } = props;

    const handleClick = () => {
        props.onSelect(type, title, id);
    };

    return (
        <div
            data-testid={testId ?? `property-btn-${type}`}
            className="property-list-item"
            onClick={handleClick}
        >
            <div className="property-list-item--icon">
                <IconBg show={true}>{icon}</IconBg>
            </div>

            <div className="property-list-item--content">
                <div className="property-list-item--content--title">
                    {title}
                </div>
                {!!subTitle && <span>{subTitle}</span>}
            </div>
            <div className="property-list-item--plus">
                <AddSvg />
            </div>
        </div>
    );
};

export default PropertyListItem;
