import React from "react";

import Dropdown, { IDropdownProps } from "./Dropdown";
import { IInputProps } from "../IInputProps";
import { ESharedOptionsType, useSharedOptions } from "./api/hooks";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";

type Props = IDropdownProps & IInputProps<string>;

const PositionDropdown = (props: Props) => {
    const { data: sharedOptions, refetch } = useSharedOptions(
        false,
        ESharedOptionsType.position,
        props.selectedIds,
    );

    React.useEffect(() => {
        refetch();
    }, []);

    return (
        <Dropdown
            {...props}
            options={props.options ?? sharedOptions}
            icon={<PositionIcon />}
        />
    );
};

export default PositionDropdown;
