import React, { useMemo } from "react";

import { IDaysRange } from "models/dto/IDaysRange";
import { TimelineGroup, TimelineItem } from "react-calendar-timeline";
import RecurringChecklistTimelinePreview from "./RecurringChecklistTimelinePreview";
import { WorkflowScheduleInterval } from "../api/WorkflowScheduleIntervals";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    type: WorkflowScheduleInterval;
    dates: IDaysRange[];
    id: string;
    title: string;

    template: IValueLabelItem<string, string, string>;

    startDate?: Date;

    onChangeRecurrence: (id: string) => void;
}

const RecurringChecklistTimeline: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { type, dates, id, title, startDate, template } = props;

    const { label: templateTitle, param: templateVersionId } = template;

    const group = useMemo(() => {
        const result: TimelineGroup<{ name: string; selected: boolean }> = {
            id,
            selected: true,
            name: title,
            title,
        };

        return result;
    }, [id, title]);

    const items = useMemo(() => {
        if (dates.length > 0) {
            const result: TimelineItem<{
                tempItemMode?: "added" | "removed";
            }>[] = dates.map((x, i) => ({
                id: `${i + 1}`,
                group: id,
                start_time: x.start,
                end_time: x.end,
                title: `${templateTitle} ${i + 1}`,
                canMove: false,
                canResize: false,
                tempItemMode: "added",
            }));

            return result;
        }
    }, [dates, id, templateTitle]);

    return (
        <RecurringChecklistTimelinePreview
            type={type}
            templateVersionId={templateVersionId ?? null}
            startDate={startDate}
            firstGroup={group}
            firstGroupItems={items}
            onChangeRecurrence={props.onChangeRecurrence}
        />
    );
};

export default RecurringChecklistTimeline;
