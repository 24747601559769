import "./ImprovementListedContent.scss";

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import ListedContent from "common/components/listed-content/ListedContent";
import {
    SidePanelHeader,
    SidePanelDetails,
    SidePanelList,
} from "./components/SidePanel";
import Content from "./components/Content/Content";
import { useImprovementData } from "./hooks";
import ImprovementDetailsContext from "./improvementContext";
import { IShowModalPayload } from "common/components/modal-manager/api/IModalManager";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import { useListedContentData } from "common/components/listed-content/hooks";
import { IAppState } from "store/IAppState";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";

export interface IImprovementDetailsProps {
    id: string;
    checkCanSeeAll: boolean;
    syncData: boolean;

    initialStepInstanceId?: string;

    onShowModal: (payload: IShowModalPayload) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

const ImprovementListedContent: React.FC<IImprovementDetailsProps> = (
    props,
) => {
    const { t } = useTranslation();

    const roles = useSelector((appState: IAppState) => {
        return appState.authViewState.roles;
    });

    const profile = useSelector((appState: IAppState) => {
        return appState.authViewState.profile;
    });

    const lastFinalizedStepInstanceId = useSelector((appState: IAppState) => {
        return appState.stepInstanceViewState.lastFinalizedStepInstanceId;
    });

    const improvementData = {
        ...useImprovementData(props),
        roles,
        profile,
        lastFinalizedStepInstanceId,
        ...useListedContentData(),
    };

    const {
        contentRef,
        listedContentRef,
        sidePanelRef,
        improvement,
        isLoading,
        isLoadingImprovement,
        isLoadingActivityInstances,
        isLoadingStepInstances,
        initialSection,
        mobileView,
    } = improvementData;

    const handleOnClose = () => {
        props.onClose();
    };

    if (!isLoadingImprovement) {
        if (improvement === undefined)
            return (
                <NotAvailableModal
                    onClose={handleOnClose}
                    message={t(
                        k.NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE,
                    )}
                />
            );
    }

    const isLoadingData =
        isLoadingImprovement ||
        (improvement !== undefined &&
            (isLoadingActivityInstances || isLoadingStepInstances));

    return (
        <CurrentScrolledSection
            contentRef={contentRef}
            wrapperRef={listedContentRef}
            mobileView={mobileView}
            initialSection={initialSection}
            children={(scrollToTarget, currentSection) => (
                <ListedContent
                    className="improvement__listed-content"
                    listedContentRef={listedContentRef}
                >
                    {isLoadingData ? (
                        <AnimatedSpinner
                            isVisible={isLoadingData}
                            position="center"
                            aligned="center"
                        />
                    ) : (
                        <ImprovementDetailsContext.Provider
                            value={{
                                ...improvementData,
                                scrollToTarget,
                                currentSection,
                            }}
                        >
                            <ListedContent.SidePanel
                                sidePanelRef={sidePanelRef}
                                context={ImprovementDetailsContext}
                            >
                                <SidePanelHeader />

                                <SidePanelDetails />

                                <SidePanelList />
                            </ListedContent.SidePanel>

                            <ListedContent.Content contentRef={contentRef}>
                                {isLoadingData ? (
                                    <AnimatedSpinner
                                        isVisible={isLoadingData}
                                        position="center"
                                        aligned="center"
                                    />
                                ) : (
                                    <Content />
                                )}
                            </ListedContent.Content>
                        </ImprovementDetailsContext.Provider>
                    )}
                </ListedContent>
            )}
        />
    );
};

export default ImprovementListedContent;
