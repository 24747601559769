import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { IDropdownTreeData } from "common/components/input-components/dropdown-tree/custom-version/IDropdownTreeData";
import ColumnFilteringTreeComponent from "common/components/table/components/filtering/custom-list/ColumnFilteringTreeComponent";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import {
    DynamicImprovementStatus,
    dynamicImprovementStatusStringsWithoutNone,
    getTreeDynamicStatusTableSetOptions,
} from "models/enums/DynamicImprovementStatus";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";

interface ITableImprovementStatusFilterProps {
    currentStatus?: DynamicImprovementStatus[];
    currentClosureReasons?: ClosureReasonEnum[];
    isOpen: boolean;
    onShow: (newState: boolean) => void;
    onChange: ({
        status,
        closureReasons,
    }: {
        status: DynamicImprovementStatus[];
        closureReasons: ClosureReasonEnum[];
    }) => void;
}

const TableImprovementStatusFilter = (
    props: ITableImprovementStatusFilterProps,
) => {
    const { currentStatus, currentClosureReasons, isOpen } = props;

    const { i18n } = useTranslation();

    const [statusTreeData, setStatusTreeData] = useState<
        ITableSetWithOptions<IDropdownTreeData, string>
    >(getTreeDynamicStatusTableSetOptions(i18n));

    useEffect(() => {
        setStatusTreeData(
            getTreeDynamicStatusTableSetOptions(
                i18n,
                currentStatus,
                currentClosureReasons,
            ),
        );
    }, [i18n.language, currentStatus, currentClosureReasons]);

    const handleOnChangeStatusFilter = (ids: string[]) => {
        let newStatus: DynamicImprovementStatus[] = [];
        let newClosureReasons: ClosureReasonEnum[] = [];

        ids.forEach((id) => {
            const item = statusTreeData?.values[id];

            if (item) {
                if (
                    dynamicImprovementStatusStringsWithoutNone.has(item.value)
                ) {
                    newStatus.push(item.param);
                } else {
                    newClosureReasons.push(item.param);
                }
            }
        });

        props.onChange({
            status: newStatus,
            closureReasons: newClosureReasons,
        });

        props.onShow(false);
    };

    return (
        <ColumnFilteringTreeComponent
            useApply={false}
            isActive={
                !!currentStatus?.length || !!currentClosureReasons?.length
            }
            data={statusTreeData}
            open={isOpen}
            useSearch
            onShow={props.onShow}
            onFilter={handleOnChangeStatusFilter}
        />
    );
};

export default TableImprovementStatusFilter;
