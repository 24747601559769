import k from "i18n/keys";
import { ActivityType } from "models/enums/ActivityType";

export const markCompleteStepButtonText: Record<string, string> = {
    [k.REVIEW]: k.MARK_AS_REVIEWED,
    [k.ANALYZE]: k.MARK_AS_ANALYZED,
    [k.ACT]: k.MARK_AS_DONE,
    [k.VERIFY]: k.CLOSE_WITH_SELECTED_REASON,
};

export const markIncompleteStepButtonText: Record<string, string> = {
    [k.REVIEW]: k.MARK_AS_NOT_REVIEWED,
    [k.ANALYZE]: k.MARK_AS_NOT_ANALYZED,
    [k.ACT]: k.MARK_AS_NOT_DONE,
    [k.VERIFY]: k.RE_OPEN,
};

export const multiSelectEnabledTypes = new Set([
    ActivityType.Customers,
    ActivityType.Suppliers,
    ActivityType.Items,
    ActivityType.Users,
    ActivityType.Positions,
    ActivityType.Competencies,
]);

export const dropdownTypesWithSelectedIds = new Set([
    ...multiSelectEnabledTypes,
    ActivityType.CustomList,
]);

export const _improvementStepTitles = (
    stepName: string,
    form: boolean,
    sideList: boolean,
): string | string[] => {
    if (stepName === k.REVIEW) {
        if (form) {
            return sideList
                ? [k.REGISTER, " & ", k.REVIEW]
                : k.REGISTER_AND_REVIEW;
        } else {
            return sideList ? [k.REVIEW] : k.REVIEW_REPORTED_INFORMATION;
        }
    } else if (stepName === k.VERIFY) {
        return sideList ? [k.VERIFY, " & ", k.CLOSE] : k.VERIFY_AND_CLOSE;
    }

    return stepName;
};
