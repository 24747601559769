import k from "i18n/keys";
import { useTranslation } from "react-i18next";
import { IActivityProps } from "./IActivityProps";
import ValidationLabel from "../validation/ValidationLabel";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { getValue } from "common/utils/inputUtils";
import Checkbox from "common/components/input-components/checkbox/Checkbox";

interface IProps {
    icon: React.ReactNode;
    label: string;
    placeholder: string;
    typeLabel: string;
    hasMultiSelectOptions?: boolean;
}

type Props = IActivityProps & IProps;

const ActivityDropdownField: React.FC<Props> = (props) => {
    const {
        activity,
        onChange,
        typeField,
        isEditMode,
        errors,
        icon,
        label,
        placeholder,
        hasMultiSelectOptions,
        typeLabel,
    } = props;

    const { t } = useTranslation();

    const handleOnChangeAcceptMultiple = (
        event: IInputChangeEvent<boolean, any>,
    ) => {
        const { name, value, id } = event;

        onChange({
            id,
            name,
            value,
        });
    };

    const typeList: React.ReactElement = (
        <div className="step-activity-options">
            {isEditMode && (
                <div className="step-activity-options--label">{label}</div>
            )}
            <div className="step-activity-options--field">
                <div className="icon">{icon}</div>
                <TextfieldComplex
                    hideIcon
                    id="placeholder"
                    name="placeholder"
                    onChange={onChange}
                    placeholder={placeholder}
                    value={activity.placeholder ?? ""}
                    disabled={!isEditMode}
                    invalid={Boolean(
                        errors?.[`Activity.${activity.id}.Options`],
                    )}
                    helperText={
                        <ValidationLabel
                            errors={errors?.[`Activity.${activity.id}.Options`]}
                        />
                    }
                />
            </div>
            {hasMultiSelectOptions && (
                <>
                    <div className="step-activity-options--label">
                        {t(k.OPTIONS)}
                    </div>
                    <div className="step-activity-options--field">
                        <Checkbox
                            id="multiSelectEnabled"
                            name="multiSelectEnabled"
                            value={!!activity.multiSelectEnabled}
                            label={t(k.ACCEPT_MULTIPLE_OPTIONS)}
                            onChange={handleOnChangeAcceptMultiple}
                        />
                    </div>
                </>
            )}
        </div>
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${typeLabel}`,
    });
};

export default ActivityDropdownField;
