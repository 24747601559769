import "./MobileMenuToggle.scss";

import React from "react";

interface IProps {
    show?: boolean;
    isClosing?: boolean;

    onToggle: () => void;
}

const MobileMenuToggle: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { show, isClosing } = props;

    const className =
        show && (isClosing ?? false) === false
            ? "mobile-toggle-btn on"
            : "mobile-toggle-btn";

    return (
        <div className="mobile-menu-toggle__wrapper" onClick={props.onToggle}>
            <span className={className}>
                <span></span>
            </span>
        </div>
    );
};

export default MobileMenuToggle;
