import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import React from "react";

interface IDeleteButtonProps {
    onClick?: (id?: string) => void;
    id?: string;
    disabled?: boolean;
}

const DeleteActivityInstanceButton: React.FC<IDeleteButtonProps> = (props) => {
    const { onClick, id, disabled } = props;

    const handleOnClick = () => {
        onClick?.(id);
    };

    return (
        <IconButton
            onClick={handleOnClick}
            background="dark"
            testId="deleteActivityInstanceBtn"
            disabled={disabled}
        >
            <TrashIcon />
        </IconButton>
    );
};

export default DeleteActivityInstanceButton;
