import "./TemplatePreview.scss";

import { useListedContentData } from "common/components/listed-content/hooks";
import ListedContent from "common/components/listed-content/ListedContent";
import Content from "./components/Content/Content";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";

import TemplatePreviewContext, {
    TemplatePreviewContextType,
} from "./context/TemplatePreviewContext";
import ModalContent from "common/components/modal-content/ModalContent";
import Button from "common/components/buttons/Button";
import { ArrowLeftIcon, CalendarIcon, EditIcon } from "common/components/icons";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { SidePanelDetails, SidePanelList } from "./components/SidePanel";
import { IAppState } from "store/IAppState";
import { IoPlayCircle } from "react-icons/io5";

import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import { IShowModalPayload } from "common/components/modal-manager/api/IModalManager";
import { useTemplatePreviewData } from "./context/TemplatePreviewData";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";

export interface ITemplatePreviewProps {
    id: string;
    sourceCustomListItemId?: string;
    syncData: boolean;

    onShowModal: (payload: IShowModalPayload) => void;
    onClose: (hideQuickRunForm?: boolean) => void;
}

const TemplatePreview: React.FC<ITemplatePreviewProps> = (props) => {
    const { sourceCustomListItemId } = props;

    const { t } = useTranslation();

    const profile = useSelector((appState: IAppState) => {
        const { profile } = appState.authViewState;

        return profile;
    });

    const canCreateRecurrence = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return roles.ADMINISTRATOR || roles.PLANNER;
    });

    const templateData: TemplatePreviewContextType = {
        ...useListedContentData(),
        ...useTemplatePreviewData(props),

        profile,
        canCreateRecurrence,

        sourceCustomListItemId,
    };

    const {
        template,
        listedContentRef,
        contentRef,
        sidePanelRef,
        isLoading,
        isLoadingTemplate,
        isLoadingSteps,
        isSaving,
        canEditTemplate,
        initialSection,
        handleClickEdit,
        handleRunChecklist,
        handleCreateRecurrence,
        handleOnClose,
        mobileView,
    } = templateData;

    if (isLoadingTemplate) {
        <AnimatedSpinner isVisible position="center" aligned="center" />;
    }
    if (!isLoadingTemplate && !template) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(k.NO_TEMPLATES_TITLE)}
            />
        );
    }

    return (
        <ModalContent>
            <ModalContent.Header className="template-preview__header">
                <div className="details-col">
                    <Button
                        onClick={handleOnClose}
                        transparent
                        icon={<ArrowLeftIcon className="icon-color-blue" />}
                        noMinWidth
                        iconOnlyMobile
                    >
                        {t(k.BACK)}
                    </Button>
                </div>
                <div className="details-col">
                    <h1 className="template-preview__header__title">
                        {t(k.CHECKLIST_PREVIEW)}
                        {template?.isDraft && " - " + t(k.DRAFT)}
                    </h1>
                </div>
                <div className="details-col">
                    {canCreateRecurrence &&
                        sourceCustomListItemId === undefined && (
                            <Button
                                onClick={handleCreateRecurrence}
                                variant="dark-blue"
                                icon={<CalendarIcon variant="white" />}
                                disabled={isSaving || template?.isDraft}
                                noMinWidth
                                iconOnlyMobile
                            >
                                {t(k.CREATE_RECURRING_CHECKLIST)}
                            </Button>
                        )}
                    {canEditTemplate && (
                        <Button
                            onClick={handleClickEdit}
                            variant="blue"
                            icon={<EditIcon variant="white" />}
                            disabled={isSaving}
                            noMinWidth
                            iconOnlyMobile
                            testId="editTemplateBtn"
                        >
                            {t(k.EDIT)}
                        </Button>
                    )}
                    <Button
                        onClick={handleRunChecklist}
                        variant="success"
                        disabled={isSaving || template?.isDraft}
                        icon={<IoPlayCircle className="icon-color-white" />}
                        noMinWidth
                        iconOnlyMobile
                        testId="startChecklistTemplatePreviewBtn"
                    >
                        {t(k.NAV_BUTTON_RUN_CHECKLIST)}
                    </Button>
                </div>
            </ModalContent.Header>
            <CurrentScrolledSection
                contentRef={contentRef}
                wrapperRef={listedContentRef}
                mobileView={mobileView}
                initialSection={initialSection}
                children={(scrollToTarget, currentSection) => (
                    <ListedContent
                        className="template-preview__listed-content"
                        listedContentRef={listedContentRef}
                    >
                        {isLoading ? (
                            <AnimatedSpinner
                                isVisible={isLoading}
                                position="center"
                                aligned="center"
                            />
                        ) : (
                            <TemplatePreviewContext.Provider
                                value={{
                                    ...templateData,
                                    currentSection,
                                    scrollToTarget,
                                }}
                            >
                                <ListedContent.SidePanel
                                    sidePanelRef={sidePanelRef}
                                    context={TemplatePreviewContext}
                                >
                                    <SidePanelDetails />

                                    <SidePanelList />
                                </ListedContent.SidePanel>
                                <ListedContent.Content contentRef={contentRef}>
                                    <Content />
                                </ListedContent.Content>
                            </TemplatePreviewContext.Provider>
                        )}
                    </ListedContent>
                )}
            />
        </ModalContent>
    );
};

export default TemplatePreview;
