import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";

interface IProps {
    selectedYears?: string[];
    allYears?: number[] | undefined;

    label?: React.ReactNode;
    boldLabel?: boolean;
    closeMenuOnSelect?: boolean;
    fullWidth?: boolean;
    placeholder?: string;

    onChange: (values: string[]) => void;
}

const YearMultiselect: React.FC<IProps> = (props) => {
    const {
        selectedYears,
        label,
        boldLabel,
        closeMenuOnSelect,
        fullWidth,
        placeholder,
    } = props;

    const { t } = useTranslation();

    const allOptions = React.useMemo(() => {
        return props.allYears?.reduce<IValueLabelItem<string, string>[]>(
            (acc, year) => {
                const value = year.toString();

                const item: IValueLabelItem<string, string> = {
                    value,
                    label: value,
                };

                acc.push(item);

                return acc;
            },
            [],
        );
    }, [props.allYears]);

    const handleOnChange = (e: IInputChangeEvent<string>) => {
        props.onChange(e.selectedIds ?? []);
    };

    return (
        <InputComponent
            inputType={EActivityType.Dropdown}
            wrapperLabel={label ?? t(k.YEAR)}
            boldLabel={boldLabel}
            fullWidth={fullWidth}
            value=""
            selectedIds={selectedYears}
            isMulti
            isSearchable
            isClearable
            placeholder={t(placeholder ?? k.ALL)}
            options={allOptions}
            testId="filter.selectYears"
            closeMenuOnSelect={closeMenuOnSelect}
            onChange={handleOnChange}
            withDebounce
        />
    );
};

export default YearMultiselect;
