import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { ELayoutEnvironment } from "store/uiSettings/reducer";
import { IAppState } from "store/IAppState";

interface IProps {
    value?: string;

    imgId?: string;

    timestamp?: number;
}

function buildImgSrc(
    isWithinTeams: boolean,
    tenantId?: string,
    imgId?: string,
    timestamp?: number,
) {
    if (!imgId) {
        return;
    }

    const hostUrl = `/api/picture/${imgId}`;
    const queryParams: string[] = [];

    if (isWithinTeams && tenantId) {
        queryParams.push(`tenantId=${tenantId}`);
    }

    if (timestamp) {
        queryParams.push(`t=${timestamp}`);
    }

    if (queryParams.length > 0) {
        return `${hostUrl}?${queryParams.join("&")}`;
    }

    return hostUrl;
}

const ImageComponent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, imgId, timestamp } = props;

    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

    const imgRef = useRef<HTMLImageElement>(null);

    const layoutEnvironment = useSelector(
        (appState: IAppState) => appState.uiSettingsViewState.layoutEnvironment,
    );

    const tenantId = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.tenantId,
    );

    const srcById = useMemo(
        () =>
            buildImgSrc(
                layoutEnvironment === ELayoutEnvironment.TEAMS,
                tenantId,
                imgId,
                timestamp,
            ),
        [layoutEnvironment, tenantId, imgId, timestamp],
    );

    const src = value && value.length > 0 ? value : srcById;

    useEffect(() => {
        if (src) {
            if (isLoading === undefined && imgRef.current?.complete === false) {
                setIsLoading(true);
            }
        } else {
            setIsLoading(undefined);
        }
    }, [src, imgRef]);

    const handleOnLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            <AnimatedSpinner
                isVisible={isLoading}
                position="center"
                aligned="center"
            />

            <img
                ref={imgRef}
                src={src}
                onLoad={handleOnLoad}
                onError={handleOnLoad}
            />
        </>
    );
};

export default ImageComponent;
