import "./ExpandCollapseButton.scss";

import IconCollapse from "./assets/collapse.svg";
import IconExpand from "./assets/expand.svg";

interface IProps {
    isExpanded: boolean;

    onExpand: (mustExpand: boolean) => void;
}

export const ExpandCollapseButton = (props: IProps) => {
    const { isExpanded } = props;

    const Component = isExpanded ? IconCollapse : IconExpand;

    const handleClick = () => {
        props.onExpand(!isExpanded);
    };

    return (
        <Component className="expand-collapse-button" onClick={handleClick} />
    );
};
