import "./ImpactGradingInput.scss";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IImpactGradingOption } from "components/impact-grading-page/api/IImpactGradingOption";
import {
    getImpactGradingOptions,
    ImpactGradingEnum,
} from "components/impact-grading-page/api/ImpactGradingEnum";
import Button from "common/components/buttons/Button";
import Tooltip from "common/components/tooltip/Tooltip";

const ImpactGradingButton: React.FC<{
    value: ImpactGradingEnum;
    isSelected: boolean;
    disabled?: boolean;
    message: string;
    hideTooltip: boolean;
    onClick: (value: ImpactGradingEnum) => void;
}> = (props) => {
    const { value, isSelected, message, hideTooltip, disabled } = props;

    const handleClick = () => {
        props.onClick(value);
    };

    const className = useMemo(() => {
        const result = [`impact-grading-input__control-${value}`];

        if (disabled) {
            result.push("impact-grading-input__control-disabled");
        }

        if (isSelected) {
            result.push("impact-grading-input__control-selected");
        }

        return result.join(" ");
    }, [value, disabled, isSelected]);

    if (hideTooltip) {
        return (
            <div className="d-flex">
                <Button
                    className={className}
                    noMinWidth
                    transparent
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {value + ". " + message}
                </Button>
            </div>
        );
    }

    return (
        <Tooltip id={`tooltip-${value}`} message={message}>
            <Button
                className={className}
                noMinWidth
                transparent
                disabled={disabled}
                onClick={handleClick}
            >
                {value}
            </Button>
        </Tooltip>
    );
};

interface IProps {
    impactArea: IImpactGradingOption;

    value: ImpactGradingEnum;

    disabled?: boolean;

    onClick: (impactAreaId: string, value: ImpactGradingEnum) => void;
}

const ImpactGradingInput: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { impactArea, value, disabled } = props;

    const { i18n } = useTranslation();

    const options = React.useMemo(
        () => getImpactGradingOptions(i18n),
        [i18n.language],
    );

    const handleClick = (nextValue: ImpactGradingEnum) => {
        props.onClick(impactArea.value, nextValue);
    };

    return (
        <React.Fragment>
            <div className="impact-grading-input__wrapper impact-grading-input__wrapper--desktop">
                {options.map(
                    (x) =>
                        x.param && (
                            <ImpactGradingButton
                                key={x.value}
                                value={x.param}
                                isSelected={x.param === value}
                                message={impactArea[`score${x.param}`] ?? ""}
                                hideTooltip={
                                    impactArea[`score${x.param}`] ? false : true
                                }
                                disabled={disabled}
                                onClick={handleClick}
                            />
                        ),
                )}
            </div>

            <div className="impact-grading-input__wrapper impact-grading-input__wrapper--mobile">
                {options.map(
                    (x) =>
                        x.param && (
                            <ImpactGradingButton
                                key={x.value}
                                value={x.param}
                                isSelected={x.param === value}
                                message={impactArea[`score${x.param}`] ?? ""}
                                hideTooltip
                                disabled={disabled}
                                onClick={handleClick}
                            />
                        ),
                )}
            </div>
        </React.Fragment>
    );
};

export default ImpactGradingInput;
