import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import ModalContent from "common/components/modal-content/ModalContent";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";
import {
    IAssignRoleToUserOrTeamRequest,
    IAssignRoleToUserOrTeamRequestItem,
} from "common/components/permission-settings/api/IAssignRoleToUserOrTeamRequest";
import PermissionSettings from "common/components/permission-settings/components/PermissionSettings";
import IDictionary from "common/viewModels/IDictionary";
import HistoryButton from "components/history/components/HistoryButton";
import { EPages } from "components/roles/api/EPages";
import { usePublishedTeamOptions } from "components/teams/api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import k from "i18n/keys";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

import CustomModal from "../modal-content/CustomModal";
import { ISelectedPermissionAssignee } from "./api/ISelectedPermissionAssignee";
import {
    usePermissionRoles,
    useUserAndTeamRolesByRoleIds,
    useSaveRolePermissionsMutation,
} from "./api/hooks";
import { ePageToHistoryOwnerMap } from "./utils/utility";

interface IProps<T extends EPages> {
    permissionForPage: T;

    onClose: () => void;
}

const pageFeatureNames = {
    [EPages.ORA]: k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS,
    [EPages.SA]: k.STAFF_APPRAISAL_PAGE_TITLE,
    [EPages.CM]: k.COMPETENCY_MATRIX,
    [EPages.POSITION]: k.NAV_NODE_POSITIONS,
    [EPages.USER]: k.NAV_NODE_USERS,
    [EPages.KPI]: k.NAV_NODE_KPI,
    [EPages.CUSTOMERS]: k.NAV_NODE_CUSTOMERS,
    [EPages.IMPROVEMENT_DASHBOARD]: k.NAV_NODE_IMPROVEMENT_DASHBOARD,
    [EPages.CHECKLIST_REPORT]: k.NAV_NODE_CHECKLISTS_REPORT,
    [EPages.STAKEHOLDERS]: k.NAV_NODE_STAKEHOLDERS,
    [EPages.LEGISLATIONS]: k.NAV_NODE_LEGISLATIONS,
    [EPages.IMPACT_GRADINGS]: k.NAV_NODE_IMPACT_GRADINGS,
    [EPages.CUSTOMER_REQUIREMENTS]: k.CUSTOMER_REQUIREMENT_PAGE_TITLE,
    [EPages.SUPPLIERS]: k.NAV_NODE_SUPPLIERS,
    [EPages.PURCHASE_ORDERS]: k.NAV_NODE_PURCHASE_ORDERS,
    [EPages.ITEMS]: k.NAV_NODE_ITEMS,
    [EPages.TEAMS]: k.NAV_NODE_TEAMS,
    [EPages.TRAINING_PLANS]: k.TRAINING_PLAN_PAGE_TITLE,
    [EPages.EQUIPMENT]: k.NAV_NODE_EQUIPMENT,
    [EPages.ENVIRONMENT_ASPECTS]: k.NAV_NODE_ENVIRONMENTAL_ASPECTS,
    [EPages.COMPETENCIES]: k.COMPETENCIES,
    [EPages.GOALS]: k.NAV_NODE_GOALS,
    [EPages.CUSTOM_LIST]: k.NAV_NODE_CUSTOM_LISTS,
};

export const PermissionSettingsModal = <T extends EPages>(props: IProps<T>) => {
    const { permissionForPage } = props;

    const hasPageChange = useSelector(
        (appState: IAppState) => appState.pageNavigationViewState.hasPageChange,
    );

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const hasPageChanges = useHasPageChanges();

    const savePermissionMutation = useSaveRolePermissionsMutation();

    const { data: teamOptions } = usePublishedTeamOptions();

    const { data: userOptions } = usePublishedUserOptions();

    const { data: roles } = usePermissionRoles(permissionForPage);

    const { data: roleData } = useUserAndTeamRolesByRoleIds(roles?.ids);

    const [isSaving, setIsSaving] = useState(false);

    const [selected, setSelected] =
        useState<IDictionary<ISelectedPermissionAssignee>>();

    useEffect(() => {
        if (roleData && roles) {
            setSelected(roleData);

            setNewSelected(
                roles.ids.reduce<
                    IDictionary<
                        ITableSetWithValueLabelOptions<
                            string | null,
                            "user" | "team"
                        >
                    >
                >((acc, roleId) => {
                    if (roleId) {
                        acc[roleId] = { ids: [], values: {}, options: [] };
                    }
                    return acc;
                }, {}),
            );
        }
    }, [roleData]);

    const [newSelected, setNewSelected] = useState<
        Record<
            string,
            | ITableSetWithValueLabelOptions<string | null, "user" | "team">
            | undefined
        >
    >({});

    const handleChangeNewSelected = (
        newItem: IValueLabelItem<string, string | null, "user" | "team">,
        roleId: string,
    ) => {
        setNewSelected((prev) => {
            const prevByRoleId = prev[roleId];

            const prevValues = prevByRoleId?.values ?? {};

            if (newItem.param === undefined) {
                delete prevByRoleId?.values[newItem.value];

                return {
                    ...prev,
                    [roleId]: {
                        ids:
                            prevByRoleId?.ids.filter(
                                (x) => x !== newItem.value,
                            ) ?? [],
                        values: prevValues,
                        options:
                            prevByRoleId?.options.filter(
                                (x) => x.value !== newItem.value,
                            ) ?? [],
                    },
                };
            } else {
                const newValues = {
                    ...prevValues,
                    [newItem.value]: {
                        label: newItem.label,
                        param: newItem.param,
                    },
                };

                if (prevByRoleId?.ids.includes(newItem.value)) {
                    const options = prevByRoleId.ids.reduce<
                        Array<
                            IValueLabelItem<
                                string,
                                string | null,
                                "user" | "team"
                            >
                        >
                    >((acc, id) => {
                        const item = newValues[id];

                        if (item) {
                            acc.push({
                                ...item,
                                value: id,
                            });
                        }

                        return acc;
                    }, []);

                    return {
                        ...prev,
                        [roleId]: {
                            ...prevByRoleId,
                            values: newValues,
                            options,
                        },
                    };
                } else {
                    return {
                        ...prev,
                        [roleId]: {
                            ids: prevByRoleId?.ids.concat(newItem.value) ?? [
                                newItem.value,
                            ],
                            values: newValues,
                            options: prevByRoleId?.options.concat(newItem) ?? [
                                newItem,
                            ],
                        },
                    };
                }
            }
        });
    };

    const handleOnSave = async () => {
        if (roles) {
            setIsSaving(true);

            let list: IAssignRoleToUserOrTeamRequestItem[] = [];

            if (newSelected) {
                Object.keys(newSelected).forEach((x) => {
                    newSelected[x]?.options.forEach((item) => {
                        if (item.label !== null) {
                            list.push({
                                roleId: x,
                                isUser: item.param === "user",
                                selectedId: item.label,
                            });
                        }
                    });
                });
            }

            if (selected) {
                Object.keys(selected).forEach((x) => {
                    selected[x].itemList.forEach((item) => {
                        if (item.markedRemove !== true) {
                            list.push({
                                roleId: x,
                                isUser: item.param === "user",
                                selectedId: item.value,
                            });
                        }
                    });
                });
            }

            const request = roles.ids.reduce<
                Record<string, IAssignRoleToUserOrTeamRequest>
            >((acc, roleId) => {
                if (roleId) {
                    acc[roleId] = {
                        itemList: list.filter(
                            (x) =>
                                x.roleId === roleId &&
                                x.selectedId !== "isEveryone",
                        ),
                        isEveryone:
                            (selected && selected[roleId]?.isEveryone) ??
                            list.some(
                                (x) =>
                                    x.roleId === roleId &&
                                    x.selectedId === "isEveryone",
                            ),
                    };
                }

                return acc;
            }, {});

            const result = await savePermissionMutation.mutateAsync(request);

            setIsSaving(false);

            if (result.Succeeded) {
                hasPageChanges(false);

                setSelected(undefined);
                setNewSelected({});

                props.onClose();
            }
        }
    };

    const handleOnClose = async () => {
        if (hasPageChange) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };
            if (await showConfirmNoThunk(dispatch, args)) {
                hasPageChanges(false);

                props.onClose();
            }
        } else {
            props.onClose();
        }
    };

    return (
        <CustomModal onBackgroundClick={handleOnClose}>
            <ModalContent maxWidth="sm">
                <ModalContent.Header
                    title={`${t(k.PERMISSION_SETTINGS_FOR)} ${t(pageFeatureNames[permissionForPage])}`}
                    onClose={handleOnClose}
                    actions={
                        !!ePageToHistoryOwnerMap[permissionForPage] && (
                            <HistoryButton
                                type={ePageToHistoryOwnerMap[permissionForPage]}
                            />
                        )
                    }
                />

                <ModalContent.Body>
                    {selected &&
                        roles?.ids.map((id) => {
                            const role = roles.values[id];

                            if (role) {
                                let title = role;

                                if (i18n.exists(role)) {
                                    title = t(k[role as keyof typeof k]);

                                    const keyElements = title.split("-");

                                    if (keyElements.length > 1) {
                                        title =
                                            keyElements[
                                                keyElements.length - 1
                                            ].trim();
                                    }
                                }

                                return (
                                    <PermissionSettings
                                        key={id}
                                        testId={`${permissionForPage}-p-s-${role}`}
                                        roleId={id}
                                        title={title}
                                        info={t(
                                            k[`${role}_INFO` as keyof typeof k],
                                        )}
                                        selected={selected[id]}
                                        setSelected={setSelected as any}
                                        newSelected={newSelected[id]}
                                        changeNewSelected={
                                            handleChangeNewSelected
                                        }
                                        teamOptions={teamOptions?.options}
                                        userOptions={userOptions?.options}
                                    />
                                );
                            }
                        })}
                </ModalContent.Body>

                <ModalContent.Footer>
                    <ButtonGroup spaceBetween>
                        <Button
                            variant="danger"
                            transparent
                            onClick={handleOnClose}
                            disabled={isSaving}
                            testId="permission-settings-cancel-btn"
                        >
                            {t(k.CANCEL)}
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleOnSave}
                            isBusy={isSaving}
                            disabled={isSaving}
                            testId="permission-settings-save-btn"
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ButtonGroup>
                </ModalContent.Footer>
            </ModalContent>
        </CustomModal>
    );
};
