// import "../RecurringChecklistForm.scss";
import "./RecurringChecklistEditPublished.scss";

import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import { HighlightDates } from "common/components/date-picker/DatePicker";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { calculateAddedRunDates } from "../api/hooks";
import { getStartOfDay, getStartOfNextDay } from "common/utils/time";
import { IRecurringChecklistListItem } from "../api/IRecurringChecklistListItem";
import ChecklistStatusList from "./ChecklistStatusList";
import { IDaysRange } from "models/dto/IDaysRange";
import { IRecurringChecklistCalculateDatesByIdRequest } from "../api/IRecurringChecklistCalculateDatesByIdRequest";
import SummaryCompletedChecklists from "./SummaryCompletedChecklists";
import RecurringChecklistName from "./RecurringChecklistName";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    selectedRecurringChecklistId: string;
    template: IValueLabelItem<string, string, string>;
    existingChecklists: IRecurringChecklistListItem[];

    name: string;
    placeholder: string;

    startDate: Date;
    endDate: Date;

    errors: IValidationMessages;

    onAddRemoveDates: (
        existing: IRecurringChecklistListItem[],
        added: IRecurringChecklistListItem[],
        removed: IRecurringChecklistListItem[],
    ) => void;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;
}

const RecurringChecklistEditPublished: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const {
        selectedRecurringChecklistId,
        template,
        existingChecklists,
        name,
        placeholder,
        startDate,
        endDate,
        errors,
    } = props;

    const { label: templateTitle } = template;

    const { t } = useTranslation();

    const handleEndDateUpdate = (
        date: Date,
        newDays: IDaysRange[] | undefined = undefined,
    ) => {
        let expectedRunsValues: IRecurringChecklistListItem[] =
            existingChecklists;
        let addedRunsValues: IRecurringChecklistListItem[] =
            addedChecklists.length === 0 ? addedChecklists : [];
        let removedRunsValues: IRecurringChecklistListItem[] =
            removedChecklists.length === 0 ? removedChecklists : [];

        if (newDays) {
            const now = new Date().valueOf();

            const convertedNewDaysToRuns = newDays.map((x, i) => {
                const item: IRecurringChecklistListItem = {
                    id: `${i + 1}`,
                    title: `${templateTitle} ${i + 1}`,
                    start: x.start,
                    end: x.end,
                    status: ChecklistDynamicStatus.Scheduled,
                    anyActivityValue: false,
                };

                if (now > x.end) {
                    item.status = ChecklistDynamicStatus.Behind;
                } else if (now >= x.start && now <= x.end) {
                    item.status = ChecklistDynamicStatus.OnTrack;
                }

                return item;
            });

            expectedRunsValues = existingChecklists.concat(
                convertedNewDaysToRuns,
            );
            addedRunsValues = convertedNewDaysToRuns;

            props.onAddRemoveDates(existingChecklists, addedRunsValues, []);
        } else {
            const nextDay = getStartOfNextDay(date)?.valueOf();

            const onlyExistingRunsBeforeNewEndDate = existingChecklists.filter(
                (x) => nextDay && x.start && x.start < nextDay,
            );

            const skippedRuns = existingChecklists.filter(
                (x) => nextDay && x.start && x.start >= nextDay,
            );

            expectedRunsValues = onlyExistingRunsBeforeNewEndDate;
            removedRunsValues = skippedRuns;

            props.onAddRemoveDates(
                onlyExistingRunsBeforeNewEndDate,
                [],
                removedRunsValues,
            );
        }

        changeEndDateHandler(date);

        setExpectedChecklists(expectedRunsValues);

        setAddedChecklists(addedRunsValues);

        setRemovedChecklists(removedRunsValues);

        setCalculationErrors([]);

        setNoChanges(
            addedRunsValues.length === 0 && removedRunsValues.length === 0,
        );
    };

    const changeEndDateHandler = (value: Date | null) => {
        setNewEndDate(value);

        if (value) {
            props.onChange(value, "endDate");
        } else {
            props.onChange(undefined, "endDate");
        }
    };

    const handleChangeDate = async (date: Date | null) => {
        if (date === null) {
            return;
        }

        const selectedEndDate = getStartOfDay(date);

        if (
            selectedEndDate <= getStartOfDay(endDate) &&
            startDate &&
            selectedEndDate >= getStartOfDay(startDate)
        ) {
            handleEndDateUpdate(selectedEndDate);
        } else {
            changeEndDateHandler(selectedEndDate);

            const request: IRecurringChecklistCalculateDatesByIdRequest = {
                id: selectedRecurringChecklistId,
                endDate: selectedEndDate,
            };

            const result = await calculateAddedRunDates(request);

            if (result.Succeeded) {
                const dayAfterOriginalEndDay =
                    getStartOfNextDay(endDate)?.valueOf();

                const addedDays = (result.Data ?? []).filter(
                    (x) =>
                        dayAfterOriginalEndDay &&
                        x.start >= dayAfterOriginalEndDay,
                );

                handleEndDateUpdate(selectedEndDate, addedDays);
            } else {
                const newErrors: Array<string[] | string> = [];

                for (const key of Object.keys(result.Messages)) {
                    const errorMsg = result.Messages[key];

                    if (errorMsg) {
                        newErrors.push(errorMsg);
                    }
                }

                setCalculationErrors(newErrors);
            }
        }
    };

    const handleChangeTitle = (value: string) => {
        props.onChange(value, "name");
    };

    const [expectedChecklists, setExpectedChecklists] = useState<
        IRecurringChecklistListItem[]
    >([]);

    const [addedChecklists, setAddedChecklists] = useState<
        IRecurringChecklistListItem[]
    >([]);

    const [removedChecklists, setRemovedChecklists] = useState<
        IRecurringChecklistListItem[]
    >([]);

    const [calculationErrors, setCalculationErrors] = useState<
        Array<string[] | string>
    >([]);

    const [noChanges, setNoChanges] = useState(false);

    const [newEndDate, setNewEndDate] = useState<Date | null>(null);

    useEffect(() => {
        setAddedChecklists([]);

        setRemovedChecklists([]);

        setNewEndDate(null);
    }, [selectedRecurringChecklistId]);

    const highlightDates = useMemo(() => {
        const dates: HighlightDates[] = [
            {
                "change-end-date-calendar-existing-date":
                    existingChecklists.map((x) => new Date(x.start)),
            },
            {
                "change-end-date-calendar-added-date": addedChecklists.map(
                    (x) => new Date(x.start),
                ),
            },
            {
                "change-end-date-calendar-removed-date": removedChecklists.map(
                    (x) => new Date(x.start),
                ),
            },
        ];

        return dates;
    }, [existingChecklists, addedChecklists, removedChecklists]);

    const minDate = useMemo(() => {
        const sortedModifiedChecklist = [...existingChecklists]
            .filter((x) => x.anyActivityValue)
            .sort((a, b) => b.start - a.start);

        if (sortedModifiedChecklist.length > 0) {
            return getStartOfNextDay(
                new Date(
                    Math.max(
                        sortedModifiedChecklist[0].start,
                        startDate.valueOf(),
                    ),
                ),
            );
        }

        return getStartOfNextDay(startDate);
    }, [startDate, existingChecklists]);

    const firstScheduledChecklistDate = useMemo(() => {
        const firstScheduled = existingChecklists.find(
            (x) => x.status === ChecklistDynamicStatus.Scheduled,
        );

        if (firstScheduled) {
            return new Date(firstScheduled.start);
        }
    }, [existingChecklists]);

    const scheduledChecklistCount = useMemo(() => {
        return existingChecklists.filter(
            (x) => x.status === ChecklistDynamicStatus.Scheduled,
        ).length;
    }, [existingChecklists]);

    const expectedChecklistCount = expectedChecklists.length;

    return (
        <React.Fragment>
            <div className="recurring-checklist--body--edit">
                <div className="text-field--label">{t(k.UPDATE_END_DATE)}</div>

                <div className="r-c--body--edit--date">
                    <DatePicker
                        inline={true}
                        calendarClassName="change-schedule-end-date-calendar"
                        selected={newEndDate ?? endDate}
                        minDate={minDate}
                        onChange={handleChangeDate}
                        highlightDates={highlightDates}
                        shouldCloseOnSelect={false}
                        dateFormat="P"
                        autoComplete="off"
                    />
                    <div className="r-c--body--edit--info">
                        {noChanges ? (
                            <div>
                                {t(k.CURRENTLY_THERE_ARE)}{" "}
                                <strong>{scheduledChecklistCount}</strong>{" "}
                                {t(
                                    k.WHICH_ARE_SCHEDULED_AND_HAVE_NOT_STARTED_YET,
                                )}
                                .
                            </div>
                        ) : (
                            newEndDate && (
                                <React.Fragment>
                                    <p>
                                        {t(k.ENDING_ON)}{" "}
                                        <strong>
                                            <FormattedDate
                                                onlyDate={true}
                                                date={newEndDate}
                                            />
                                        </strong>{" "}
                                        {t(k.WILL_MAKE_THE_SCHEDULE_CONTAIN)}{" "}
                                        <strong>
                                            {expectedChecklistCount}
                                        </strong>{" "}
                                        {expectedChecklistCount === 1
                                            ? t(k.CHECKLIST_)
                                            : t(k.CHECKLISTS_)}{" "}
                                        {t(k.IN_TOTAL_)}.
                                    </p>

                                    <p>
                                        <SummaryCompletedChecklists
                                            items={expectedChecklists}
                                        />
                                    </p>

                                    {addedChecklists.length > 0 && (
                                        <div>
                                            <strong>
                                                {addedChecklists.length}
                                            </strong>{" "}
                                            {addedChecklists.length === 1
                                                ? t(k.CHECKLIST_)
                                                : t(k.CHECKLISTS_)}{" "}
                                            {t(
                                                k.WILL_BE_CREATED_OR_UNARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS,
                                            )}
                                            :
                                            <ChecklistStatusList
                                                items={addedChecklists}
                                            />
                                        </div>
                                    )}

                                    {removedChecklists.length > 0 && (
                                        <div>
                                            <strong>
                                                {removedChecklists.length}
                                            </strong>{" "}
                                            {removedChecklists.length === 1
                                                ? t(k.CHECKLIST_)
                                                : t(k.CHECKLISTS_)}{" "}
                                            {t(
                                                k.WILL_BE_REMOVED_OR_ARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS,
                                            )}
                                            :
                                            <ChecklistStatusList
                                                items={removedChecklists}
                                            />
                                        </div>
                                    )}

                                    <ValidationLabel errors={errors?.EndDate} />
                                </React.Fragment>
                            )
                        )}
                    </div>
                </div>
                <div className="r-c--body--edit--notes">
                    {calculationErrors.length > 0 && (
                        <ul>
                            {calculationErrors.map((x, i) => (
                                <li key={i}>{x}</li>
                            ))}
                        </ul>
                    )}

                    {firstScheduledChecklistDate && (
                        <div>
                            {t(
                                k.IF_YOU_WANT_TO_STOP_ALL_SCHEDULED_CHECKLISTS_YOU_SET_THE_NEW_END_DATE_BEFORE,
                            )}{" "}
                            <strong>
                                <FormattedDate
                                    onlyDate={true}
                                    date={firstScheduledChecklistDate}
                                />
                            </strong>
                            .
                        </div>
                    )}
                </div>
            </div>

            <RecurringChecklistName
                name={name}
                placeholder={placeholder}
                errors={errors}
                onChange={handleChangeTitle}
            />
        </React.Fragment>
    );
};

export default RecurringChecklistEditPublished;
