import "./ErrorByIdList.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { ErrorByIdListItemMessage } from "./ErrorByIdListItemMessage";

interface IProps<T> {
    errors: IValidationMessages | null;

    labelTranslationKeys: Partial<Record<keyof T, keyof typeof k | string>>;

    errorsByIds: Array<{ key: string; values: string[]; index: number }> | null;

    onClick: (args: { key: string; name: string; index: number }) => void;
}

export const ErrorByIdList = <T,>(props: IProps<T>) => {
    const { errors, errorsByIds, labelTranslationKeys } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {errors &&
                errorsByIds &&
                errorsByIds.length > 0 &&
                errorsByIds.map((errorById) => (
                    <div key={errorById.key}>
                        <strong>
                            {t(k.RECORD)} #{errorById.index + 1}
                        </strong>

                        <ul className="error-by-id-list">
                            {errorById.values.map((errorByIdValue) => {
                                const labelTranslationKey =
                                    errorByIdValue in labelTranslationKeys
                                        ? (labelTranslationKeys[
                                              errorByIdValue as keyof T
                                          ] as keyof typeof k)
                                        : (errorByIdValue as keyof typeof k);

                                const key = `${errorById.key}.${errorByIdValue}`;

                                return (
                                    <li key={key}>
                                        <ErrorByIdListItemMessage
                                            errorById={errorById}
                                            errorByIdName={errorByIdValue}
                                            errorKey={key}
                                            label={
                                                labelTranslationKey in k
                                                    ? t(k[labelTranslationKey])
                                                    : labelTranslationKey
                                            }
                                            errors={errors}
                                            onClick={props.onClick}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
        </React.Fragment>
    );
};
