import React from "react";

interface IProps {
    testId?: string;
    id?: string;

    className?: string;

    onlyImages?: boolean;

    onLoad: (content: string, fileName: string) => void;
}

const UploadFile: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const readFile = (file: File) => {
        const fileName = file.name;

        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const fileContent = fileReader.result as string;

            props.onLoad(fileContent, fileName);
        };

        fileReader.onerror = () => {
            alert("Upload file error");
        };

        fileReader.readAsDataURL(file);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            readFile(e.target.files[0]);
        }

        e.target.value = "";
    };

    const { children, id, className, onlyImages } = props;

    let accept: string | undefined;

    if (onlyImages) {
        accept = "image/*";
    }

    return (
        <label className={className}>
            {children}

            <input
                data-testid={props.testId}
                id={id}
                className="d-none"
                onChange={handleFileChange}
                type="file"
                name="content"
                accept={accept}
            />
        </label>
    );
};

export default UploadFile;
