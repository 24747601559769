import React from "react";

import WarningIcon from "common/components/icons/icon-warning/WarningIcon";
import Tooltip from "common/components/tooltip/Tooltip";

interface IProps {
    id: string;

    variant?: "enabled";

    message: React.ReactNode;
}

const WarningIconWithMessage: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { id, message } = props;

    const enabled = props.variant === "enabled";

    return (
        <Tooltip id={id} message={message} variant="inline">
            <WarningIcon disabled={enabled ? undefined : true} />
        </Tooltip>
    );
};

export default WarningIconWithMessage;
