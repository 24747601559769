import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IShowConfirmArgs } from "store/confirms/actions";

const DisableMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {t(k.DISABLE_USER_LINE_1)}
            <br />
        </React.Fragment>
    );
};

const buildConfirmDisableMessage = (
    title: React.ReactNode,
    yesLabel: string,
) => {
    const result: IShowConfirmArgs = {
        message: <DisableMessage />,
        title,
        yesButtonVariant: "danger",
        yesLabel,
    };

    return result;
};

export default buildConfirmDisableMessage;
