import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    allChecklistStatuses,
    ChecklistDynamicStatus,
} from "models/enums/ChecklistDynamicStatus";
import { IRecurringChecklistListItem } from "../api/IRecurringChecklistListItem";

interface IProps {
    items: IRecurringChecklistListItem[];
}

const ChecklistStatusList: React.FC<IProps> = (props) => {
    const { items } = props;

    const { t, i18n } = useTranslation();

    const labelByStatusKeys = React.useMemo(() => {
        return allChecklistStatuses.reduce<Record<number, string>>(
            (acc, { id: status }) => {
                let key: string | undefined;

                switch (status) {
                    case ChecklistDynamicStatus.Behind:
                        key = k.BEHIND_;
                        break;

                    case ChecklistDynamicStatus.OnTrack:
                        key = k.ON_TRACK_;
                        break;

                    case ChecklistDynamicStatus.Finalized:
                        key = k.FINALIZED_L;
                        break;

                    case ChecklistDynamicStatus.Blocked:
                        key = k.BLOCKED_;
                        break;

                    case ChecklistDynamicStatus.Scheduled:
                        key = k.SCHEDULED_;
                        break;

                    case ChecklistDynamicStatus.Stopped:
                        key = k.STOPPED_;
                        break;

                    case ChecklistDynamicStatus.Archived:
                        key = k.ARCHIVED_;
                        break;
                }

                if (key !== undefined) {
                    acc[status] = key;
                }

                return acc;
            },
            {},
        );
    }, [i18n.language]);

    if (items.length === 0) {
        return null;
    }

    return (
        <ul className="m-0">
            {allChecklistStatuses.map(({ id: status }) => {
                const count = items.filter((x) => x.status === status).length;

                if (count > 0) {
                    return (
                        <li key={status}>
                            <strong>{count}</strong>{" "}
                            {t(labelByStatusKeys[status])}
                        </li>
                    );
                }
            })}
        </ul>
    );
};

export default ChecklistStatusList;
