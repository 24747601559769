import React, { useContext } from "react";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { useTranslation } from "react-i18next";
import ChecklistDetailsContext from "../../../../../checklistContext";
import k from "i18n/keys";
import { PrintIcon } from "common/components/icons";
import { printElements } from "common/utils/printUtils";

interface IPrintButtonProps {}

const PrintButton: React.FC<IPrintButtonProps> = (props) => {
    const { t } = useTranslation();

    const { editMode, contentRef, detailsRef } = useContext(
        ChecklistDetailsContext,
    );

    const onClick = () => {
        printElements([detailsRef, contentRef]);
    };

    return (
        <IconButton
            onClick={onClick}
            background="dark"
            disabled={editMode}
            tooltip={editMode ? t(k.DISABLED_WHILE_EDITING) : t(k.PRINT)}
            testId="print-button"
        >
            <PrintIcon variant="white" />
        </IconButton>
    );
};

export default PrintButton;
