import "../RecurringChecklistForm.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import IRecurringChecklist, {
    ERecurringChecklistWeek,
} from "components/recurring-checklist/api/IRecurringChecklist";
import { DayOfWeek } from "models/enums/DayOfWeek";
import Dropdown from "common/components/input-components/dropdown/Dropdown";
import {
    IGroupedValueLabelItem,
    IValueLabelItem,
} from "common/IValueLabelItem";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

interface IProps {
    isDayOfMonthUpdated: boolean;
    dayOfMonth?: number;
    recurringWeek?: ERecurringChecklistWeek;
    weekday?: DayOfWeek;
    errors: IValidationMessages;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;
}

const RecurringChecklistDayOfMonth = (props: IProps) => {
    const { isDayOfMonthUpdated, dayOfMonth, recurringWeek, weekday, errors } =
        props;

    const { t, i18n } = useTranslation();

    const handleOnFirstOnDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.target.value === "1";

        if (nextValue !== isDayOfMonthUpdated) {
            props.onChange(nextValue, "isDayOfMonth");
        }
    };

    const handleDayOfMonth = (e: IInputChangeEvent<string>) => {
        const val = parseInt(e.value);

        props.onChange(isNaN(val) ? undefined : val, "dayOfMonth");

        if (isDayOfMonthUpdated ? false : true) {
            props.onChange(true, "isDayOfMonth");
        }
    };

    const handleRecurringWeekChange = (e: IInputChangeEvent<string>) => {
        const val = parseInt(e.value);

        if (isNaN(val)) {
            props.onChange(undefined, "recurringWeek");
        } else {
            const day = val as ERecurringChecklistWeek;

            props.onChange(day, "recurringWeek");
        }

        if (isDayOfMonthUpdated) {
            props.onChange(false, "isDayOfMonth");
        }
    };

    const handleOnDay = (e: IInputChangeEvent<string>) => {
        const val = parseInt(e.value);

        if (isNaN(val)) {
            props.onChange(undefined, "weekday");
        } else {
            const day = val as DayOfWeek;

            props.onChange(day, "weekday");
        }

        if (isDayOfMonthUpdated) {
            props.onChange(false, "isDayOfMonth");
        }
    };

    const firstLastOptions = React.useMemo(
        () => getFirstLastOptions(t),
        [i18n.language],
    );

    const dayOptions: IValueLabelItem<string, string>[] = React.useMemo(
        () => getDayOptions(t),
        [i18n.language],
    );

    const dayOfMonthOptions: IValueLabelItem<string, string>[] = React.useMemo(
        () => getDayOfMonthOptions(t),
        [i18n.language],
    );

    return (
        <div className="recurring-checklist--body--day-month">
            <div className="r-c--body--day-month--column">
                <input
                    type="radio"
                    value=""
                    checked={isDayOfMonthUpdated ? false : true}
                    onChange={handleOnFirstOnDate}
                />
                <span>{t(k.ON)}</span>
                <Dropdown
                    value={recurringWeek?.toString() ?? ""}
                    options={firstLastOptions}
                    onChange={handleRecurringWeekChange}
                    invalid={!!errors.recurringWeek}
                    helperText={
                        <ValidationLabel
                            errors={
                                errors.recurringWeek && t(errors.recurringWeek)
                            }
                        />
                    }
                />
                <Dropdown
                    value={weekday?.toString() ?? ""}
                    options={dayOptions}
                    invalid={!!errors.workdays || !!errors.weekday}
                    onChange={handleOnDay}
                    helperText={
                        <ValidationLabel
                            errors={
                                (errors.workdays && t(errors.workdays)) ||
                                (errors.weekday && t(errors.weekday))
                            }
                        />
                    }
                />
                <span>{t(k.OF_THE_MONTH)}</span>
            </div>
            <div className="r-c--body--day-month--column">
                <input
                    type="radio"
                    value="1"
                    checked={isDayOfMonthUpdated ?? false}
                    onChange={handleOnFirstOnDate}
                />
                <span>{t(k.ON)}</span>
                <Dropdown
                    value={String(dayOfMonth ?? "")}
                    options={dayOfMonthOptions}
                    invalid={!!errors.dayOfMonth}
                    onChange={handleDayOfMonth}
                    helperText={
                        <ValidationLabel
                            errors={errors.dayOfMonth && t(errors.dayOfMonth)}
                        />
                    }
                />
                <span>{t(k.OF_THE_MONTH)}</span>
            </div>
        </div>
    );
};

export default RecurringChecklistDayOfMonth;

function getDayOfMonthOptions(
    t: (key: string) => string,
): IValueLabelItem<string, string>[] {
    const result: Array<IGroupedValueLabelItem<string, string>> = [
        {
            value: "0",
            label: t(k.LAST_DAY_),
        },
    ];

    const daysOptions: IGroupedValueLabelItem<string, string> = {
        value: "",
        label: t(k.DAYS),
        options: [],
    };

    for (let i = 1; i < 32; i++) {
        const value = i.toString();

        daysOptions.options?.push({
            value,
            label: `${t(k.DAY_)} ${value}`,
        });
    }

    result.push(daysOptions);

    return result;
}

function getDayOptions(
    t: (key: string) => string,
): IValueLabelItem<string, string>[] {
    return [
        { value: DayOfWeek.Sunday.toString(), label: t(k.SUNDAY) },
        { value: DayOfWeek.Monday.toString(), label: t(k.MONDAY) },
        { value: DayOfWeek.Tuesday.toString(), label: t(k.TUESDAY) },
        { value: DayOfWeek.Wednesday.toString(), label: t(k.WEDNESDAY) },
        { value: DayOfWeek.Thursday.toString(), label: t(k.THURSDAY) },
        { value: DayOfWeek.Friday.toString(), label: t(k.FRIDAY) },
        { value: DayOfWeek.Saturday.toString(), label: t(k.SATURDAY) },
    ];
}

function getFirstLastOptions(
    t: (key: string) => string,
): IValueLabelItem<string, string>[] {
    return [
        { value: ERecurringChecklistWeek.First.toString(), label: t(k.FIRST) },
        {
            value: ERecurringChecklistWeek.Second.toString(),
            label: t(k.SECOND),
        },
        { value: ERecurringChecklistWeek.Third.toString(), label: t(k.THIRD) },
        {
            value: ERecurringChecklistWeek.Fourth.toString(),
            label: t(k.FOURTH),
        },
        { value: ERecurringChecklistWeek.Last.toString(), label: t(k.LAST) },
    ];
}
