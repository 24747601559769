import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import k from "../../../i18n/keys";
import { IMultiselectDropdownProps } from "./IMultiselectDropdownProps";
import { IMultiselectOption } from "../../../common/IMultiselectOption";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import InputWrapper from "common/components/input-components/InputWrapper";
import ValidationWrapper from "../validation/ValidationWrapper";
import { ESharedOptionsType, useSharedOptions } from "common/components/input-components/dropdown/api/hooks";

interface IProps extends Omit<IMultiselectDropdownProps, "onChange"> {
    id?: string;
    isEveryoneFixed?: boolean;

    isEveryoneSelected?: boolean;

    excludeEveryoneOption?: boolean;

    testId?: string;

    required?: boolean;

    onChange: (values: string[], isTeamEveryoneSelected: boolean) => void;
}

const TeamsMultiselectWithWrapper: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        id,
        values,
        label,
        uniqueId,
        error,
        isEveryoneFixed,
        isEveryoneSelected,
        isDisabled,
        menuPlacement,
        testId,
        required,
        placeholder,
        excludeEveryoneOption,
    } = props;

    const { t, i18n } = useTranslation();

    const { data: items } = useSharedOptions(true, ESharedOptionsType.team, values);

    const allOptions = useMemo(() => {
        const result: Array<IMultiselectOption<boolean>> = items ?? [];

        const everyoneOption: IMultiselectOption<boolean> = {
            value: "1",
            label: t(k.EVERYONE),
            isFixed: isEveryoneFixed,
            param: true,
        };

        return excludeEveryoneOption ? result : [everyoneOption].concat(result);
    }, [i18n.language, isEveryoneFixed, items]);

    const selectedOptions = useMemo(() => {
        return values
            ? allOptions.filter(
                  (x) =>
                      values.includes(x.value) ||
                      (isEveryoneSelected && x.param) ||
                      x.isFixed,
              )
            : allOptions.filter((x) => x.isFixed);
    }, [isEveryoneSelected, values, allOptions]);

    const handleOnChange = (options: any) => {
        let isEveryoneNextSelected: boolean = false;
        let newValues: string[] = [];

        if (options) {
            const selectedValues = options as Array<
                IMultiselectOption<boolean>
            >;

            isEveryoneNextSelected = selectedValues.some((x) => x.param);

            newValues = selectedValues
                .filter((x) => !x.param && !x.isFixed)
                .map((x) => x.value);
        }

        props.onChange(newValues, isEveryoneNextSelected);
    };

    const controlLabel = label ?? t(k.TEAMS);

    const controlId = uniqueId || "form.selectTeams";

    const isClearable = useMemo(() => {
        return selectedOptions.some((v) => !v.isFixed);
    }, [selectedOptions]);

    return (
        <InputWrapper wrapperLabel={controlLabel} required={required} boldLabel>
            <ValidationWrapper errors={error}>
                <SelectDropdown
                    id={id ?? "teamMultiselectDropdown"}
                    inputId={controlId}
                    value={selectedOptions}
                    isMulti
                    isSearchable
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    invalid={!!error}
                    placeholder={placeholder}
                    options={allOptions}
                    closeMenuOnSelect={false}
                    onChange={handleOnChange}
                    menuPlacement={menuPlacement}
                    testId={testId}
                />
            </ValidationWrapper>
        </InputWrapper>
    );
};

export default TeamsMultiselectWithWrapper;
