import "./GoalStatusBadge.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import Badge from "../Badge";
import { EGoalGrading } from "components/goals-page/api/EGoalGrading";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { goalStatuses } from "components/goals-page/utils/GoalTools";
import DraftBadge from "../draft-badge/DraftBadge";
import { DraftIcon } from "common/components/icons";

interface IProps {
    status: EGoalGrading;
    hideOnTrack?: boolean;
    className?: string;
    info?: React.ReactNode;
}

const GoalStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { status, hideOnTrack, className, info } = props;

    const { t } = useTranslation();

    return status !== EGoalGrading.OnTrack || !hideOnTrack ? (
        <Badge
            pill
            className={`goal-status goal-status--${status + 1} ${className}`}
            rightSideContent={
                info && <span className="goal-status--info">{info}</span>
            }
            leftSideContent={status === EGoalGrading.Draft && <DraftIcon />}
        >
            {getLocaleString(t, goalStatuses[status])}
        </Badge>
    ) : null;
};

export default GoalStatusBadge;
