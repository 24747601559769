import "./AvatarCompletedBy.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Avatar from "./Avatar";
import FormattedDate from "../formattedDate/FormattedDate";

interface IProps {
    completedBy?: string;
    size?: number;
    completedAt?: Date;
}

const AvatarCompletedBy: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { completedBy, size } = props;
    const { t } = useTranslation();

    const completedAt = <FormattedDate date={props.completedAt} />;

    return completedBy ? (
        <div className="avatar-completed-by__wrapper">
            <Avatar
                name={completedBy}
                className="no-print"
                tooltipMessage={
                    <React.Fragment>
                        {t(k.COMPLETED_BY)}{" "}
                        <strong className="d-block">{completedBy}</strong>
                        <span className="d-block">{completedAt}</span>
                    </React.Fragment>
                }
                size={size}
            />

            <div className="avatar-completed-by--for-print">
                <span>{t(k.COMPLETED_BY)}</span>
                <strong>{completedBy}</strong>
                <span>{completedAt}</span>
            </div>
        </div>
    ) : null;
};

export default AvatarCompletedBy;
