import React from "react";
import { useDropdownMenu } from "react-overlays";

import { FilterMenuCommands } from "./FilterMenuCommands";

interface IProps {
    isActive: boolean;
    isEdited: boolean;
    testId?: string;

    commandClassName?: string;
    commandDescription?: React.ReactNode;

    onFilter: (isReset?: boolean) => void;
}

export const FilterMenu = (props: React.PropsWithChildren<IProps>) => {
    const { isActive, isEdited, testId, commandClassName, commandDescription } =
        props;

    const dropdownMenuOptions = useDropdownMenu({
        flip: true,
        offset: [0, 8],
    });

    const handleHide = (e: React.SyntheticEvent) => {
        if (dropdownMenuOptions[1].toggle) {
            dropdownMenuOptions[1].toggle(false, e);
        }
    };

    const handleFilter = (e: React.SyntheticEvent, isReset?: boolean) => {
        props.onFilter(isReset);

        handleHide(e);
    };

    return (
        <div
            {...dropdownMenuOptions[0]}
            className={
                dropdownMenuOptions[1].show
                    ? "app-table--filter-dropdown__menu show"
                    : "app-table--filter-dropdown__menu"
            }
            data-testid={testId ? `${testId}Menu` : undefined}
        >
            {props.children}

            <FilterMenuCommands
                isActive={isActive}
                isEdited={isEdited}
                testId={testId}
                className={commandClassName}
                description={commandDescription}
                onCancel={handleHide}
                onFilter={handleFilter}
            />
        </div>
    );
};
