import { Action } from "redux";
import { ELayoutEnvironment } from "./reducer";

export const CHANGE_MENU_IS_EXPANDED = "CHANGE_MENU_IS_EXPANDED";
export const CHANGE_LAYOUT_ENVIRONMENT = "CHANGE_TARGET_RENDERER";

interface IChangeMenuIsExpanded extends Action<typeof CHANGE_MENU_IS_EXPANDED> {
    payload: boolean;
}

interface IChangeLayoutEnvironment
    extends Action<typeof CHANGE_LAYOUT_ENVIRONMENT> {
    payload: ELayoutEnvironment;
}

export type UiSettingsActionTypes =
    | IChangeMenuIsExpanded
    | IChangeLayoutEnvironment;
