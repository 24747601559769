import "./ImprovementImpactBadge.scss";
import k from "i18n/keys";

import React from "react";
import { useTranslation } from "react-i18next";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import Badge from "../Badge";

interface IProps {
    impact: ImpactGradingEnum;
}

const ImprovementImpactBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { impact: id } = props;

    const { t, i18n } = useTranslation();

    const allImpacts = React.useMemo(() => {
        return {
            [ImpactGradingEnum.VeryLow]: t(k.IMPACT_SCORE1_LABEL),
            [ImpactGradingEnum.Low]: t(k.IMPACT_SCORE2_LABEL),
            [ImpactGradingEnum.Moderate]: t(k.IMPACT_SCORE3_LABEL),
            [ImpactGradingEnum.High]: t(k.IMPACT_SCORE4_LABEL),
            [ImpactGradingEnum.VeryHigh]: t(k.IMPACT_SCORE5_LABEL),
        };
    }, [i18n.language]);

    const label = id === ImpactGradingEnum.None ? null : allImpacts[id];

    return id === ImpactGradingEnum.None ? null : (
        <Badge pill className={`impact-badge impact--${id}`}>
            {label}
        </Badge>
    );
};

export default ImprovementImpactBadge;
