import { IValueLabelItem } from "common/IValueLabelItem";
import { ThunkActionReturnsPromise, ThunkActionReturnsVoid } from "../../common/types";
import IWorkflowDTO from "../../models/dto/IWorkflowDTO";
import { IWorkflowState } from "./reducer";
import { WorkflowService } from "./service";
import {
    WorkflowActionTypes,
    RECEIVE_GET_WORKFLOW_DRAFT,
    REQUEST_GET_WORKFLOW_DRAFT,
} from "./types";

export const getPublishedTemplateList: ThunkActionReturnsPromise<
    Array<IValueLabelItem<string, string>>,
    IWorkflowState,
    WorkflowActionTypes
> = () => {
    return async () => {
        return await new WorkflowService().getPublishedTemplateList();
    };
};

export const getDraftById: ThunkActionReturnsVoid<
    IWorkflowState,
    WorkflowActionTypes
> = (workflowVersionId?: string) => {
    const request = (): WorkflowActionTypes => {
        return {
            type: REQUEST_GET_WORKFLOW_DRAFT,
        };
    };

    const success = (list: IWorkflowDTO): WorkflowActionTypes => {
        return {
            type: RECEIVE_GET_WORKFLOW_DRAFT,
            payload: list,
        };
    };

    return async (dispatch) => {
        if (workflowVersionId) {
            dispatch(request());

            const list = await new WorkflowService().getDraftById(
                workflowVersionId,
            );

            dispatch(success(list));
        }
    };
};
