import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { useImprovementFormOptions } from "components/improvement-forms/api/hooks";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps extends IInputWrapperProps {
    closeMenuOnSelect?: boolean;
    defaultSelectedIds?: string[];
    selectedIds?: string[];

    onChange: (ids: string[]) => void;
}

export const ImprovementFormMultiselect = (props: IProps) => {
    const { closeMenuOnSelect, selectedIds, defaultSelectedIds } = props;

    const { data: allOptions } = useImprovementFormOptions(
        true,
        defaultSelectedIds,
    );

    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string>[]
    >([]);

    useEffect(() => {
        if (selectedIds) {
            const newSelectedOptions = allOptions?.filter((x) =>
                selectedIds.includes(x.value),
            );
            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [allOptions, selectedIds]);

    const handleOnChange = (options: any) => {
        const newOptions = options as IValueLabelItem<string, string>[];

        setSelectedOptions(newOptions);

        props.onChange(newOptions.map((x) => x.value));
    };

    return (
        <InputWrapper
            {...props}
            wrapperLabel={props.wrapperLabel ?? t(k.NAV_NODE_IMPROVEMENT_FORMS)}
        >
            <SelectDropdown
                value={selectedOptions}
                isMulti
                isSearchable
                isClearable
                placeholder={t(k.ALL)}
                options={allOptions}
                testId="form.ImprovementsFormDropdownInput"
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};
