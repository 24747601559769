import "./InputAction.scss";

import TitleWithValidation from "components/common/validation/TitleWithValidation";
import ValidationWrapper from "components/common/validation/ValidationWrapper";
import classnames from "classnames";

export interface IInputActionWrapperProps {
    htmlFor?: string;

    wrapperClassName?: string;

    label?: React.ReactNode;
    labelClassName?: string;
    boldLabel?: boolean;

    required?: boolean;

    icon?: React.ReactNode;
    iconInLine?: boolean;
    noIconResize?: boolean;

    inputClassName?: string;
    inputDirection?: "horizontal" | "vertical";

    noMinWidth?: boolean;
    fullWidth?: boolean;

    titleError?: React.ReactNode;
    error?: React.ReactNode;

    wrapperTestId?: string;
    labelTestId?: string;
    iconTestId?: string;
    inputTestId?: string;
    validationTestId?: string;
}

const InputActionWrapper: React.FC<
    React.PropsWithChildren<IInputActionWrapperProps>
> = (props) => {
    const {
        htmlFor,
        wrapperClassName,
        label,
        labelClassName,
        boldLabel,
        required,
        icon,
        iconInLine,
        noIconResize,
        inputClassName,
        inputDirection,
        noMinWidth,
        fullWidth,
        titleError,
        error,
        wrapperTestId,
        labelTestId,
        iconTestId,
        inputTestId,
        validationTestId,
    } = props;

    const wrapperClass = classnames("input-action--wrapper", wrapperClassName, {
        "no-min-width": noMinWidth,
        "full-width": fullWidth,
        "bold-label": boldLabel,
        "icon-in-line": iconInLine,
        [`input--${inputDirection}`]: inputDirection,
    });

    const iconClass = classnames("input-action--wrapper--icon", {
        "no-resize": noIconResize,
    });

    const inputClass = classnames(
        "input-action--wrapper--input",
        inputClassName,
    );

    const labelTextClass = classnames(
        "input-action--wrapper--label",
        labelClassName,
    );

    return (
        <div className={wrapperClass} data-testid={wrapperTestId}>
            {icon && (
                <div className={iconClass} data-testid={iconTestId}>
                    {icon}
                </div>
            )}

            {label && (
                <TitleWithValidation
                    htmlFor={htmlFor}
                    testId={labelTestId}
                    className={labelTextClass}
                    title={label}
                    bold={boldLabel}
                    size="title-sm"
                    required={required}
                    errors={titleError}
                />
            )}
            <div className={inputClass} data-testid={inputTestId}>
                <ValidationWrapper
                    errors={error}
                    testId={validationTestId}
                    absolute
                >
                    {props.children}
                </ValidationWrapper>
            </div>
        </div>
    );
};

export default InputActionWrapper;
