import k from "./keys";

export default {
    [k.A_CHECKLIST_CANT_BE_UPGRADED_IF_IT_HAS]: `En checklista kan inte uppgraderas om den har`,
    [k.A_FIXED_DATE]: `Fast datum`,
    [k.ABOVE_OR_EQUAL_TO]: `Över eller lika med`,
    [k.ABOVE_OR_EQUAL]: `Över eller lika med`,
    [k.ABOVE]: `Över`,
    [k.ACCEPT_A_SINGLE_BARCODE]: `Accepterar en streckkod`,
    [k.ACCEPT_MULTIPLE_BARCODES]: `Accepterar flera streckkoder`,
    [k.ACCEPT_MULTIPLE_OPTIONS]: `Acceptera flera`,
    [k.ACCEPT_SINGLE_OPTION]: `Acceptera en`,
    [k.ACCESS_LIMITING_DESCRIPTION]: `Begränsar access för användare. Användare ser bara de anpassade listor där de, eller en person de är chef för, tilldelats rollen Användare`,
    [k.ACCESS_LIMITING]: `Åtkomstbegränsning`,
    [k.ACT]: `Åtgärda`,
    [k.ACTING]: `Åtgärdas`,
    [k.ACTION_PLAN]: `Åtgärdsplan`,
    [k.ACTION]: `Åtgärd`,
    [k.ACTIONS]: `Åtgärder`,
    [k.ACTIVATE_USER_LINE_1]: `Om du aktiverar användaren kommer de att kunna logga in och komma åt all sin data igen.`,
    [k.ACTIVATE_USER_TITLE]: `Vill du aktivera den här användaren?`,
    [k.ACTIVE_CHECKLISTS]: `Aktiva checklistor`,
    [k.ACTIVE_L]: `aktiv`,
    [k.ACTIVE]: `Aktiv`,
    [k.ACTIVITIES_]: `aktiviteter`,
    [k.ACTIVITIES_EXISTS_THAT_ARE_NOT_DONE]: `Det finns aktiviteter som inte är klara`,
    [k.ACTIVITIES_REQUIRED]: `Du behöver minst en aktivitet för att skapa ett steg`,
    [k.ACTIVITIES_WERENT_COMPLETED]: `Aktiviteter slutfördes inte`,
    [k.ACTIVITIES]: `Aktiviteter`,
    [k.ACTIVITY_HEADER]: `Uppgiftslistans rubrik`,
    [k.ACTIVITY_MUST_BE_COMPLETED_TO_FINALIZE_STEP]: `Aktiviteten måste slutföras för att steget ska få låsas`,
    [k.ACTIVITY]: `Aktivitet`,
    [k.ADD_ABSOLUTE_PROGRESS]: `Absolut värde (ny total)`,
    [k.ADD_ACTIVITIES]: `Lägg till aktiviteter`,
    [k.ADD_ATTACHMENT]: `Lägg till bilaga`,
    [k.ADD_BARCODES_LABEL]: `Etikett för Streckkoder`,
    [k.ADD_CHECKBOX_LABEL]: `Etiket för Uppgiftslista`,
    [k.ADD_CHECKBOX]: `Uppgiftslista`,
    [k.ADD_COMMENT_TO_PEER]: `Lägg till kommentar till peer`,
    [k.ADD_COMMENT]: `Lägg till kommentar`,
    [k.ADD_COMPETENCY_MATRIX]: `Lägg till Kompetensmatris`,
    [k.ADD_COMPETENCY]: `Lägg till kompetens`,
    [k.ADD_CONTACT]: `Ny Kontakt`,
    [k.ADD_CUSTOM_LIST]: `Lägg till en ny anpassad lista`,
    [k.ADD_CUSTOMER_DROPDOWN_LABEL]: `Etikett för Kunder`,
    [k.ADD_CUSTOMER_DROPDOWN]: `Kunder`,
    [k.ADD_CUSTOMER_INDUSTRY]: `Lägg till Kundbransch`,
    [k.ADD_CUSTOMER_REQUIREMENT]: `Lägg till Kundkrav`,
    [k.ADD_CUSTOMER]: `Ny kund`,
    [k.ADD_DATA_SOURCE]: `Lägg till datakälla`,
    [k.ADD_DATE_TIME]: `Datum och tid`,
    [k.ADD_DROPDOWN_LABEL]: `Etikett för Alternativ`,
    [k.ADD_DROPDOWN]: `Alternativ`,
    [k.ADD_ELEMENTS]: `Lägg till element`,
    [k.ADD_ENVIRONMENTAL_ASPECT]: `Lägg till Miljöaspekt`,
    [k.ADD_EQUIPMENT]: `Ny Utrustning`,
    [k.ADD_EXISTING_GOAL]: `Lägg Till Befintligt Mål`,
    [k.ADD_FROM_LIBRARY]: `Hämta från biblioteket`,
    [k.ADD_GOAL]: `Lägg till mål`,
    [k.ADD_IMPACT_GRADING_AREA]: `Ny Gradering`,
    [k.ADD_INCREMENTAL_PROGRESS]: `Inkrementellt värde`,
    [k.ADD_ITEM_DROPDOWN_LABEL]: `Etikett för Artikel`,
    [k.ADD_ITEM_DROPDOWN]: `Artikel`,
    [k.ADD_ITEM]: `Ny Artikel`,
    [k.ADD_KPI]: `Lägg till KPI`,
    [k.ADD_LEGISLATION]: `Lägg till Lagstiftning`,
    [k.ADD_LOCAL_STEP]: `Lägg till steg`,
    [k.ADD_MEASUREMENT]: `Lägg till mätvärde`,
    [k.ADD_MEASUREMENTS_TO_MEASURE_PROGRESS]: `Lägg till några mätvärden för att mäta framsteg`,
    [k.ADD_MULTI_LINE_TEXTFIELD_LABEL]: `Etikett för Text`,
    [k.ADD_MULTI_LINE_TEXTFIELD]: `Text`,
    [k.ADD_NEW_CUSTOM_LIST]: `Lägg till ny anpassad lista`,
    [k.ADD_NEW_CUSTOM_LIST_DESCRIPTION]: `Du kan skapa en lista med data med ett brett utbud av olika egenskaper. Du kan till exempel skapa en anpassad lista för att hantera alla dina företagsfilialer och länka den till en anpassad lista med dina byggnader. För att skapa en ny anpassad lista, klicka på knappen till höger.`,
    [k.ADD_NEW_CUSTOMER_SUBTITLE]: `Du kan lägga till en ny kund härifrån`,
    [k.ADD_NEW_CUSTOMER]: `Ny Kund`,
    [k.ADD_NEW_EQUIPMENT_SUBTITLE]: `Du kan lägga till en ny utrustning härifrån`,
    [k.ADD_NEW_EQUIPMENT]: `Ny Utrustning`,
    [k.ADD_NEW_PROCESS]: `Lägg till ny process`,
    [k.ADD_NEW_PURCHASE_ORDER_SUBTITLE]: `Lägg till en ny inköpsorder härifrån`,
    [k.ADD_NEW_ROW]: `Lägg till ny rad`,
    [k.ADD_NEW_SUPPLIER_SUBTITLE]: `Du kan lägga till en ny leverantör härifrån`,
    [k.ADD_NEW_SUPPLIER]: `Ny Leverantör`,
    [k.ADD_NEW_TASK_SUBTITLE]: `Du kan lägga till en ny uppgift härifrån`,
    [k.ADD_NEW_TASK]: `Ny Uppgift`,
    [k.ADD_NEW_TEAM_SUBTITLE]: `Du kan lägga till nytt team härifrån`,
    [k.ADD_NEW_TEAM]: `Nytt Team`,
    [k.ADD_NEW_USER_SUBTITLE]: `Du kan lägga till en ny användare härifrån`,
    [k.ADD_NEW_USER]: `Ny Användare`,
    [k.ADD_NEW]: `Lägg till ny`,
    [k.ADD_NOTES]: `Lägg till anteckningar`,
    [k.ADD_OPERATIONAL_RISK_ANALYSIS]: `Lägg till risk`,
    [k.ADD_PHOTO_LABEL]: `Etikett för Foto`,
    [k.ADD_PHOTO]: `Lägg till Foto`,
    [k.ADD_POSITION]: `Lägg till Befattning`,
    [k.ADD_PROGRESS]: `Lägg till framsteg`,
    [k.ADD_PROPERTY]: `Lägg till Egenskap`,
    [k.ADD_PURCHASE_ORDER_LINE]: `Skapa PO Rad Status`,
    [k.ADD_PURCHASE_ORDER]: `Lägg till inköpsorder`,
    [k.ADD_RECURRING_CHECKLIST]: `Lägg till upprepande checklista`,
    [k.ADD_STAFF_APPRAISAL]: `Lägg till Medarbetarsamtal`,
    [k.ADD_STAKEHOLDER]: `Lägg till intressent`,
    [k.ADD_STATIC_ACTION_PLAN]: `Lägg till statisk åtgärdsplan`,
    [k.ADD_STEP]: `Lägg till steg`,
    [k.ADD_SUPPLIER_DROPDOWN_LABEL]: `Etikett för Leverantörer`,
    [k.ADD_SUPPLIER_DROPDOWN]: `Leverantörer`,
    [k.ADD_SUPPLIER]: `Ny Leverantör`,
    [k.ADD_TASK_LIST]: `Lägg till uppgiftslista`,
    [k.ADD_TASK]: `Lägg till uppgift`,
    [k.ADD_TEAM]: `Lägg till Team`,
    [k.ADD_TEMPLATE]: `Ny mall`,
    [k.ADD_TEXTFIELD_LABEL]: `Etikett för Värde`,
    [k.ADD_TEXTFIELD]: `Värde`,
    [k.ADD_TRAINING_PLAN]: `Lägg till Utbildningsplan`,
    [k.ADD_USER]: `Ny Användare`,
    [k.ADD]: `Lägg till`,
    [k.ADDED_AS_INVOLVED_TO]: `Tillagd som involverad i`,
    [k.ADDED]: `Tillagd`,
    [k.ADDRESS_BILING]: `Fakturaaddress`,
    [k.ADDRESS_SHIP_VISIT]: `Leverans- och besöksadress`,
    [k.ADDRESS_SHIP]: `Leveransadress`,
    [k.ADDRESS_VISIT]: `Besöksaddress`,
    [k.ADDRESS]: `Adress`,
    [k.ADMINISTRATOR]: `Administratör`,
    [k.ADMINISTRATORS]: `Administratörer`,
    [k.ADVANCED_CONFIG]: `Avancerad konfiguration`,
    [k.AFFECTED_PROCESS_STEPS]: `Påverkade processteg`,
    [k.AFFECTED_SUB_PROCESS]: `Påverkade delprocesser`,
    [k.AFTER]: `Efter`,
    [k.AI_API_KEY_DESCRIPTION_PART1]: `Ange api-nyckeln som AmpliFlow kommer att använda för AI-integrering. Har du ingen API-nyckel? Kontakta`,
    [k.AI_FEATURE_DESCRIPTION_PART1]: `AmpliFlow AI kan hjälpa dig att automatiskt fylla i information som förbättringsbeskrivning, riskanalys och mer. För att lära dig mer och registrera dig för betan, kontakta`,
    [k.AI_FEATURE_DESCRIPTION_PART2]: `idag.`,
    [k.AI_FEATURES_BETA]: `AI-funktioner (beta)`,
    [k.AID_NEEDS]: `Hjälpmedelsbehov`,
    [k.AI]: `AI`,
    [k.ALERT]: `Alert`,
    [k.ALL_]: `alla`,
    [k.ALL_CATEGORIES]: `Alla Kategorier`,
    [k.ALL_IMPROVEMENTS_DESCRIPTION]: `En KPI som kommer använda alla avvikelser i systemet som datakälla.`,
    [k.ALL_IMPROVEMENTS]: `Alla Avvikelser`,
    [k.ALL_USERS_IN_SYSTEM]: `Alla användare i systemet`,
    [k.ALL]: `Alla`,
    [k.ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING]: `Tillåt att skapa användare vid SSO-inloggning om användaren saknas`,
    [k.ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING_DESCRIPTION]: `Ska nya användare skapas automatiskt när de försöker logga in via SSO för första gången eller inte? Växla detta till grönt för att tillåta automatiskt skapande och inaktivera om du vill skapa användare manuellt 1 och 1 i AmpliFlow innan de kan logga in.`,
    [k.ALLOW_MULTI_VALUE]: `Tillåt flera val`,
    [k.ALLOW_USERS_TO_ADD_AND_ASSIGN_OWN_TASKS_IN_RUNNING_CHECKLISTS]: `--------`,
    [k.AMOUNT]: `Antal`,
    [k.AN_ERROR_OCCURED]: `Ett fel uppstod i`,
    [k.ANALYSING]: `Analyseras`,
    [k.ANALYSIS]: `Analys`,
    [k.ANALYZE]: `Analysera`,
    [k.AND_]: `och`,
    [k.AND_REPEAT_IT_ON_THE_SAME_WEEKDAY_EVERY]: `Och upprepa det på samma vardag varje`,
    [k.AND]: `Och`,
    [k.ANOTHER_KPI]: `En annan KPI`,
    [k.ANSWERS]: `Svar`,
    [k.API_ENDPOINTS]: `API-ändpunkter`,
    [k.API_KEY_CHECKLIST]: `API-nyckellista`,
    [k.API_KEY]: `API-nyckel`,
    [k.API_KEYS_SUBTITLE]: `Här är alla tillgängliga API-nycklar i systemet`,
    [k.API_KEYS]: `API-nycklar`,
    [k.API_KEYS_DESCRIPTION]: `Hantera API-nycklar och visa alla slutpunkter som används för att integrera Ampliflow med tredjepartslösningar.`,
    [k.APPLICABLE_TO_OUR_ORGANIZATION]: `Gällande för vår organisation?`,
    [k.APPLY]: `Tillämpa`,
    [k.APPRAISING_MANAGER]: `Utvärderande chef`,
    [k.APR]: `Apr`,
    [k.APRIL]: `April`,
    [k.ARCHIVE_IMPACT_GRADING_1]: `Om du arkiverar denna gradering kommer den inte att vara tillgänglig för framtida graderingar men existerande kommer fortsätta existera.`,
    [k.ARCHIVE_IMPACT_GRADING_2]: `Om den redan används någonstans i systemet kommer den inte längre vara synligt men data är inte bortagen.`,
    [k.ARCHIVE_IMPACT_GRADING_3]: `Du kan när som helst återställa arkiveringen. Använding som gömdes vid arkivering kommer då att bli synlig igen.`,
    [k.ARCHIVE_MESSAGE_GENERIC_1]: `Om du arkiverar kommer den inte längre vara tillgänglig att användas någonstans i systemet. Du kan alltid återställa arkivering från "Åtgärd" menyn`,
    [k.ARCHIVE_MESSAGE_GENERIC_2]: `Arkivering tar inte bort data permanent. Efter arkivering kan du välja ta bort den permanent`,
    [k.ARCHIVE_POSITION_1]: `Om du arkiverar den här befattningen så kommer inte längre kunna tilldelas till användare.`,
    [k.ARCHIVE_POSITION_2]: `Om den redan används någonstans i systemet så kommer den fortfarande vara synlig.`,
    [k.ARCHIVE_POSITION_3]: `Du kan återställa befattningen när som helst.`,
    [k.ARCHIVE_PURCHASE_ORDER_MESSAGE_1]: `Om du arkiverar den här inköpsordern är den inte längre tillgänglig att användas någonstans i systemet. Du kan alltid återställa arkivering från "Åtgärd" menyn`,
    [k.ARCHIVE_PURCHASE_ORDER_MESSAGE_2]: `Arkivering tar inte bort data permanent. Efter arkivering kan du välja ta bort den permanent`,
    [k.ARCHIVE_PURCHASE_ORDER_TITLE]: `Arkivera denna inköpsorder?`,
    [k.ARCHIVE_THIS_AREA]: `Arkivera detta område`,
    [k.ARCHIVE_THIS_GOAL]: `Arkivera detta mål?`,
    [k.ARCHIVE_THIS_KPI_LINE_1]: `Om du arkiverar denna KPI kan målen inte längre uppdateras. Data kommer fortfarande att vara tillgängliga för rapportering.`,
    [k.ARCHIVE_THIS_KPI_LINE_2]: `Du kan avarkivera den när som helst. Är du säker på att du vill arkivera denna KPI?`,
    [k.ARCHIVE_THIS_KPI]: `Arkivera denna KPI?`,
    [k.ARCHIVE_THIS_MEASUREMENT]: `Arkivera detta mätvärde?`,
    [k.ARCHIVE_THIS_POSITION]: `Arkivera denna befattning`,
    [k.ARCHIVE_THIS_PROGRESS_VALUE]: `Arkivera detta värde?`,
    [k.ARCHIVE_THIS_TEAM]: `Arkivera detta team?`,
    [k.ARCHIVE]: `Arkivera`,
    [k.ARCHIVED_]: `arkiverad`,
    [k.ARCHIVED_BY]: `Arkiverat av`,
    [k.ARCHIVED_CUSTOM_LISTS]: `Arkiverade anpassade listor`,
    [k.ARCHIVED_P]: `Arkiverade`,
    [k.ARCHIVED_POSITION]: `Arkiverad befattning`,
    [k.ARCHIVED]: `Arkiverad`,
    [k.ARE_]: `är`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_CONTENT]: `Endast en administratör kan återställa förbättringsformuläret från arkivet`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_INFO]: `Använd det här alternativet om du vill att förbättringsformuläret ska tas bort från allas arbetslista. Till exempel om det startades av misstag`,
    [k.ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM]: `Är du säker på att du vill arkivera detta förbättringsformulär?`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN_CONTENT]: `Endast en administratör kommer att kunna återställa checklistan från arkivet`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN_INFO]: `Använd det här alternativet om du vill att checklistan ska tas bort från allas arbetslista. Till exempel om det startades av en slump`,
    [k.ARE_YOU_SURE_ARCHIVE_RUN]: `Vill du arkivera den här checklistan?`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_CONTENT]: `Endast en administratör kommer att kunna återställa schemat från arkivet`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_INFO]: `Använd det här alternativet om du vill att schemat ska tas bort från allas arbetslista. Till exempel om det startades av en slump`,
    [k.ARE_YOU_SURE_ARCHIVE_SCHEDULE]: `Är din säker på att du vill arkivera det här schemat?`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_CONTENT]: `Endast en administratör kommer att kunna återställa mallen från arkivet`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_INFO]: `Använd det här alternativet om du vill att mallen ska tas bort från allas arbetslista. Till exempel om det startades av en slump`,
    [k.ARE_YOU_SURE_ARCHIVE_TEMPLATE]: `Är din säker på att du vill arkivera den här mallen?`,
    [k.ARE_YOU_SURE_RESET]: `Är du säker på att du vill återställa?`,
    [k.ARE_YOU_SURE_RESUME_RUN_CONTENT]: `Checklistan kommer att starta igen för alla som har åtkomst att arbeta med den`,
    [k.ARE_YOU_SURE_RESUME_RUN_INFO]: `Oroa dig inte, om du återupptar det av misstag kan du enkelt bara stoppa det igen`,
    [k.ARE_YOU_SURE_RESUME_RUN]: `Vill du återuppta det här checklistan?`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE_CONTENT]: `Schemat kommer att aktiveras för alla som har tillgång till arbete med det`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE_INFO]: `Oroa dig inte, om du återupptar det av misstag kan du enkelt bara pausa det igen`,
    [k.ARE_YOU_SURE_RESUME_SCHEDULE]: `Vill du återuppta det här schemat?`,
    [k.ARE_YOU_SURE_STOP_RUN_CONTENT]: `Ingen kommer att kunna arbeta med checklistan medan den är stoppad`,
    [k.ARE_YOU_SURE_STOP_RUN_INFO]: `Vem som helst, inklusive dig, kan när som helst återuppta checklistan`,
    [k.ARE_YOU_SURE_STOP_RUN]: `Vill du stoppa den här checklistan?`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE_CONTENT]: `Ingen kommer att kunna arbeta med detta schema under tiden det är stoppat`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE_INFO]: `Men: Vem som helst, inklusive du, kan återuppta den när som helst`,
    [k.ARE_YOU_SURE_STOP_SCHEDULE]: `Vill du stoppa det här schemat tills att någon återupptar det?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_CONTENT]: `När du tar bort ett förbättringsformulär från arkivet kommer det att återställas och visas för användarna`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_INFO]: `Användare kan fortsätta arbeta på det. Den här åtgärden är begränsad till administratörer`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM]: `Vill du ta bort detta förbättringsformulär från arkivet?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN_CONTENT]: `När du återställer en checklista kommer den att återställas och visas upp för användare i tillståndet Stoppad`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN_INFO]: `Användarna kan sedan välja att att återuppta checklistan och fortsätta arbeta med den. Den här åtgärden är begränsad till administratörer`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_RUN]: `Vill du återställa den här checklistan från arkivet?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_CONTENT]: `När du återställer ett schema det kommer det att återställas och visas upp för användare i läget Pausad`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_INFO]: `Användarna kan sedan välja att återuppta och fortsätta arbeta med det. Den här åtgärden är begränsad till administratörer`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE]: `Vill du återställa detta schema?`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_CONTENT]: `När du återställer en mall det kommer det att återställas och visas för användare igen`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_INFO]: `Användare kan fortsätta arbeta på det. Den här åtgärden är begränsad till administratörer`,
    [k.ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE]: `Vill du återställa den här mallen?`,
    [k.ARE_YOU_SURE_UNSAVED_CHANGES_INFO]: `Det finns ej sparade ändringar. Om du avbryter kan de gå förlorade`,
    [k.ARE_YOU_SURE_UNSAVED_CHANGES]: `Är du säker på att du vill avbryta?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_EQUIPMENT]: `Är din säker på att du vill arkivera denna utrustning`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_TEAM]: `Är du säker på att du vill arkivera det här teamet?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CHECKLIST]: `Är du verkligen säker på att du vill ta bort den här checklistan och all dess information?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_IMPROVEMENT]: `Är du verkligen säker på att du vill radera den här avvikelsen och all dess information?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RECURRING_CHECKLIST]: `Är du verkligen säker på att du vill ta bort den här upprepande checklistan och all dess checklistor?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TEMPLATE]: `Är du verkligen säker på att du vill ta bort den här mallen?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DISABLE_THIS_FEATURE]: `Är du säker på att du vill inaktivera den här funktionen?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_ENABLE_THIS_FEATURE]: `Är du säker på att du vill aktivera den här funktionen?`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_LEAVE]: `Är du säker på att du vill lämna`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_PROCEED]: `Är du säker på att du vill fortsätta?`,
    [k.ARE_YOU_SURE]: `Är du säker?`,
    [k.AREA_WILL_BE_REMOVED]: `Området kommer att avlägsnas`,
    [k.AS_]: `som`,
    [k.ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT_FEATURE_DESCRIPTION]: `Denna funktion tillåter användare att bifoga data om ursprunget och upptäckten av förbättringar till specifika processnoder.`,
    [k.ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT]: `Tilldela påverkade processer till förbättring`,
    [k.ASSIGNED_TO]: `Tilldelat till`,
    [k.ASSIGNED]: `Tilldelat`,
    [k.ASSIGNEE]: `Tilldelade`,
    [k.ASSIGNMENT]: `Uppdrag`,
    [k.AT_A_GLANCE]: `Överblick`,
    [k.AT_LEAST_ONE_ACTIVITY_IS_REQUIRED]: `Minst en aktivitet krävs`,
    [k.AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION]: `Minst en involverad användare krävs i ytterligare involverade användares konfiguration.`,
    [k.AT_LEAST_ONE_PROP_VISIBLE]: `Minst en egenskap måste visas i listvyn`,
    [k.AT_LEAST_ONE_RESPONSIBLE_USER_TYPE_REQUIRED]: `Minst en ansvarig användartyp krävs`,
    [k.AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION]: `För att kunna notifiera delaktiga användare måste du lägga till minst 1 användare som delaktig.`,
    [k.AT_RISK]: `I Riskzonen`,
    [k.ATTACHMENTS]: `Bilagor`,
    [k.AUG]: `Aug`,
    [k.AUGUST]: `Augusti`,
    [k.AVAILABLE_USERS]: `Tillgängliga användare`,
    [k.BACK_TO_CHECKLISTS]: `Tillbaka till Checklistor`,
    [k.BACK_TO_CUSTOMER_LIST]: `Tillbaka till kundlistan`,
    [k.BACK_TO_EQUIPMENT_LIST]: `Tillbaka till utrustningslistan`,
    [k.BACK_TO_PREVIOUS_PAGE]: `Tillbaka till föregående sida`,
    [k.BACK_TO_PURCHASE_ORDER_LIST]: `Tillbaka till inköpsorderlistan`,
    [k.BACK_TO_SUPPLIER_LIST]: `Tillbaka till leverantörslistan`,
    [k.BACK_TO_TEAM_LIST]: `Tillbaka till teamlistan`,
    [k.BACK_TO_USER_LIST]: `Tillbaka till användarlistan`,
    [k.BACK]: `Tillbaka`,
    [k.BARCODE_ACTIVITY_DESCRIPTION]: `Genom att aktivera den här funktionen kan du enkelt lägga till möjligheten att skanna och generera streckkoder inom anpassade listor, medarbetarsamtal, förbättringar och checklistor.`,
    [k.BARCODE_ACTIVITY]: `Streckkoder`,
    [k.BARCODE]: `Streckkod`,
    [k.BARCODES]: `Streckkoder`,
    [k.BEHIND_]: `försenad`,
    [k.BEHIND]: `Försenad`,
    [k.BELOW_IS_A_CALENDAR_FOR_THE_SCHEDULE]: `Nedan finns en kalender för schemat`,
    [k.BELOW_OR_EQUAL_TO]: `Under eller lika med`,
    [k.BELOW_OR_EQUAL]: `Under eller lika med`,
    [k.BELOW]: `Under`,
    [k.BETWEEN]: `Mellan`,
    [k.BILLING_COUNTRY]: `Faktureringsland`,
    [k.BILLING_STATE_OR_PROVINCE]: `Faktureringsstat/provins`,
    [k.BIO]: `Bio`,
    [k.BLOCK_FOLLOWING_STEPS_TOOLTIP]: `Användaren kommer inte att kunna slutföra andra steg efter detta, om detta är markerat som blockerande`,
    [k.BLOCK_FOLLOWING_STEPS]: `Blockera följande steg tills det här steget är slutfört`,
    [k.BLOCKED_]: `blockerad`,
    [k.BLOCKED_P]: `Blockerade`,
    [k.BLOCKED]: `Blockerad`,
    [k.BLOCKING]: `Blockerar`,
    [k.BLOCKING_STEPS]: `Blockerande steg`,
    [k.BOTH]: `Båda`,
    [k.BOUGHT_IN]: `Köpte in`,
    [k.BROWSE_AND_IMPORT_CLOUD_TEMPLATES]: `Bläddra bland och importera färdiga mallar`,
    [k.BULK_IMPORT_FEATURE_DESCRIPTION]: `Importera massdata snabbt och effektivt. Du kan till exempel massimportera dina befintliga användare, team, intressenter och mer.`,
    [k.BUSINESS_NAME]: `Företagsnamn`,
    [k.BY_]: `av`,
    [k.CAN_NOT_ARCHIVE]: `Kan inte arkivera`,
    [k.CAN_VIEW]: `Kan se`,
    [k.CANCEL]: `Avbryt`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_FIRST]: `Team kan inte arkiveras då de är tillagda i dessa checklistor`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_SECOND]: `För att arkivera teamet måste du ta bort dem från checklistorna och sedan arkivera igen`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_FIRST]: `Team kan inte arkiveras då de är tillagda i dessa steg`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_SECOND]: `För att arkivera teamet måste du ta bort dem från stegen och sedan arkivera igen`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_FIRST]: `Team kan inte arkiveras då de är tillagda i dessa mallar`,
    [k.CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_SECOND]: `För att arkivera teamet måste du ta bort dem från mallarna och sedan arkivera igen`,
    [k.CANNOT_ASSIGN_ARCHIVED_USER_POSITION]: `Kan inte tilldela arkiverade befattningar.`,
    [k.CANNOT_DELETE_THE_POSITION]: `Kan inte ta bort befattningen`,
    [k.CANNOT_DELETE_THE_TEAM]: `Kan inte ta bort teamet`,
    [k.CANNOT_EDIT_ARCHIVED_USER_POSITION]: `Denna befattning är arkiverad och kan inte redigeras.`,
    [k.CANNOT_UNARCHIVE_CHECKLIST_SCHEDULE_ARCHIVED]: `Checklistan kunde inte återställas för att dess schema är arkiverad`,
    [k.CANNOT_UNARCHIVE_CHECKLIST_TEMPLATE_ARCHIVED]: `Checklistan kunde inte återställas för att dess mall är arkiverad`,
    [k.CANT_BE_UPGRADED]: `kan inte uppgraderas.`,
    [k.CAPTURE_PHOTO]: `Ta foto`,
    [k.CATEGORY]: `Kategori`,
    [k.CHANGE_END_DATE_FOR_THE_SCHEDULE]: `Ändra slutdatum för schemat`,
    [k.CHANGE_TENANT_NAME_MESSAGE]: `Observera att om du ändrar namn kommer befintliga adresser inte att fungera. Se till att du tillhandahåller nya webbadresser till de berörda användarna. Användare som kommer åt den gamla webbadressen (t.ex. genom bokmärken) kommer inte att omdirigeras automatiskt.\n\nÄr du säker på att du vill fortsätta?`,
    [k.CHANGE_TENANT_NAME]: `Ändra namn?`,
    [k.CHANGED_TEAMS_IN_STEPS]: `Ändrad team-tilldelning på steg`,
    [k.CHANGED]: `Ändrad`,
    [k.CHANGES_APPLY_AFTER_SAVE]: `Ändringen kommer i effekt efter att du sparat`,
    [k.CHANGING_SUPPLIER_CONFIRM_MESSAGE]: `När leverantören byts återställs varorna`,
    [k.CHECKBOX]: `Kryssrutan`,
    [k.CHECKLIST_]: `checklista`,
    [k.CHECKLIST_ARCHIVED]: `Checklista arkiverad`,
    [k.CHECKLIST_DELETE_DESCRIPTION]: `Att ta bort en checklista är den sista åtgärden du gör när något har gått fel, om du vill spara checklistans historik av bör du behålla den i 'arkiverat' tillstånd`,
    [k.CHECKLIST_DETAILS]: `Checklistdetaljer`,
    [k.CHECKLIST_DURATION]: `Checklistans tidsåtgång`,
    [k.CHECKLIST_HAS_BEEN_MODIFIED_AND_DOES_NOT_MATCH_SCHEDULE]: `Checklistan har modifierats och matchar inte schemat`,
    [k.CHECKLIST_PAUSED]: `Checklista pausad`,
    [k.CHECKLIST_PREVIEW]: `Förhandsvisning av Checklista`,
    [k.CHECKLIST_REPORT_FEATURE_DESCRIPTION]: `Visa detaljerade rapporter om dina checklistor med avancerad filtrering.`,
    [k.CHECKLIST_REPORT_OWNER_INFO]: `Rapport om Checklista - Ägare\nKan se alla checklisterapporter.\nKan tilldela behörigheter för checklisterapporter.`,
    [k.CHECKLIST_REPORT_OWNER]: `Rapport om Checklista - Ägare`,
    [k.CHECKLIST_REPORT_SUBTITLE]: `Sök och sortera bland checklistor och dess rapporterade värden`,
    [k.CHECKLIST_REPORT_VIEWER_INFO]: `Rapport om Checklista - Läsare\nKan se alla checklisterapporter.`,
    [k.CHECKLIST_REPORT_VIEWER]: `Rapport om Checklista - Läsare`,
    [k.CHECKLIST_RESUMED]: `Checklistan återupptas`,
    [k.CHECKLIST_STARTED]: `Checklista startade`,
    [k.CHECKLIST_STEPS]: `Checkliststeg`,
    [k.CHECKLIST_STOPPED]: `Checklistan stoppas`,
    [k.CHECKLIST_TEMPLATES]: `Checklistmallar`,
    [k.CHECKLIST_UNARCHIVED]: `Checklista har tagits ur arkiv`,
    [k.CHECKLIST_UNPAUSED]: `Checklista återupptagen`,
    [k.CHECKLIST]: `Checklista`,
    [k.CHECKLISTS_]: `checklistor`,
    [k.CHECKLISTS_RUN]: `Checklist(s) körning(ar)`,
    [k.CHECKLISTS_SEARCH_SUBTITLE]: `Skanna en streckkod eller ange en fråga för att söka efter checklistor`,
    [k.CHECKLISTS_SEARCH]: `Sök Checklistor`,
    [k.CHECKLISTS_SUBTITLE]: `Alla tillgängliga Checklistor`,
    [k.CHECKLISTS_WILL_BE_UPGRADED_AND]: `checklistor kommer att uppgraderas.`,
    [k.CHECKLISTS]: `Checklistor`,
    [k.CHOOSE_IMPACTED_AREAS]: `Välj påverkade områden`,
    [k.CHOOSE_IMPACTED_PROCESSES]: `Välj påverkade processer`,
    [k.CHOOSE_REASON_FOR_CLOSURE]: `Välj anledning till avslut`,
    [k.CHOOSE]: `Välja...`,
    [k.CITY]: `Stad`,
    [k.CLEAR]: `Rensa`,
    [k.CLICK_HERE]: `Klicka här`,
    [k.CLICK_THE_BUTTON_BELLOW_TO_MARK_THIS_STEP_AS_COMPLETED]: `Klicka på knappen nedan för att markera detta steg som slutfört`,
    [k.CLICK_TO_ADD_LABEL]: `Klicka för att lägga till etikett`,
    [k.CLICK_TO_PREVIEW]: `Klicka för att förhandsgranska`,
    [k.CLICK_TO_SHOW_MORE]: `Klicka för att visa mer`,
    [k.CLONE_MESSAGE]: `Klon`,
    [k.CLOSE_IMPROVEMENT]: `Stäng avvikelse`,
    [k.CLOSE_POSITION_BEFORE_SAVE]: `Stäng befattningen innan du sparar`,
    [k.CLOSE_WITH_SELECTED_REASON]: `Markera som stängd med vald anledning`,
    [k.CLOSE]: `Stäng`,
    [k.CLOSED]: `Stängd`,
    [k.CLOSEDs]: `Stängda`,
    [k.CLOSURE_REASON_REQUIRED]: `Anledning till avslut krävs`,
    [k.CLOSURE_REASON]: `Anledning till avslut`,
    [k.CLOSURE]: `Avsluting`,
    [k.CLOUD_LIBRARY]: `Molnbibliotek`,
    [k.CLOUD_LIBRARY_FEATURE_DESCRIPTION]: `Låter dig importera förgjorda checklistmallar publicerade av AmpliFlow på sidan Mallar.`,
    [k.CLOUD_TEMPLATE_LIBRARY]: `Molnbiblioteket`,
    [k.CM_CREATED]: `Kompetensmatris skapad`,
    [k.CM_EDITOR_INFO]: `Kompetensmatris - Redigerare\nKan se alla kompetensmatriser, lägga till nya kompetensmatriser, redigera, ta bort, arkivera och avarkivera befintliga kompetensmatriser.`,
    [k.CM_EDITOR]: `Kompetensmatris - Redigerare`,
    [k.CM_OWNER_INFO]: `Kompetensmatris - Ägare\nKan se alla kompetensmatriser, lägga till eller importera nya kompetensmatriser, redigera, ta bort, arkivera och avarkivera befintliga kompetensmatriser.\nKan tilldela behörigheter för kompetensmatriser.`,
    [k.CM_OWNER]: `Kompetensmatris - Ägare`,
    [k.CM_USER_INFO]: `Kan visa, lägga till, redigera och arkivera sina egna kompetensmatriser, samt för sina direktrapporterande användare.`,
    [k.CM_USER]: `Kompetensmatris - Användare`,
    [k.CM_VIEWER_INFO]: `Kompetensmatris - Läsare\nKan se alla kompetensmatriser.`,
    [k.CM_VIEWER]: `Kompetensmatris - Läsare`,
    [k.COGNIT_GPT_API_KEY_IS_ACTIVE]: `Cognit GPT API-nyckel är aktiv`,
    [k.COGNIT_GPT_PAGE_TITLE]: `Cognit GPT Hantera`,
    [k.COLOR]: `Färg`,
    [k.COMES_IN]: `Kommer i`,
    [k.COMMENT_LEFT_BY]: `Kommentar lämnad av`,
    [k.COMMENT]: `Kommentar`,
    [k.COMMENTS_PROBABILITY]: `Kommentarer sannolikhet`,
    [k.COMMENTS]: `Kommentarer`,
    [k.COMPANY_INDUSTRY_DESCRIPTION]: `Beskriv den eller de branscher ditt företag tillhör. AmpliFlow kommer att använda detta för att skapa skräddarsytt innehåll på hela plattformen.`,
    [k.COMPANY_INDUSTRY_EXAMPLE]: `Exempel med svensk SNI:\n62020 - Datakonsultverksamhet (Datorkonsultverksamhet)\n70220 - Konsultverksamhet avseende företags organisation (Affärs- och annan managementkonsultverksamhet)`,
    [k.COMPANY_INDUSTRY]: `Bransch`,
    [k.COMPANY_NAME_DESCRIPTION]: `Namnet som används för dagliga referenser till ditt företag. Till exempel: istället för "Dunder Mifflin Paper Company, Inc.," använd helt enkelt "Dunder Mifflin."`,
    [k.COMPANY_NAME_EXAMPLE]: `Exempel: AmpliFlow`,
    [k.COMPANY_NAME]: `Företagsnamn`,
    [k.COMPANY_OVERVIEW_DESCRIPTION]: `En detaljerad beskrivning av ditt företag och vad det gör. Kom ihåg: Ju mer beskrivande du är, desto mer kan Ampliflow förbättra din upplevelse.`,
    [k.COMPANY_OVERVIEW_EXAMPLE]: `Exempel: AmpliFlow effektiviserar ISO-certifieringen med en allt-i-ett IT-plattform för efterlevnad av ISO 9001, 14001, 45001 och 27001. Den innehåller 30+ hanteringsverktyg, minskar dokumentationen och förenklar processer.`,
    [k.COMPANY_OVERVIEW]: `Företagsöversikt`,
    [k.COMPETENCIES_EDITOR_INFO]: `Kompetenser - Redigerare\nKan se alla kompetenser, lägga till nya kompetenser, redigera, ta bort, arkivera och avarkivera befintliga kompetenser.`,
    [k.COMPETENCIES_EDITOR]: `Kompetenser - Redigerare`,
    [k.COMPETENCIES_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av anställdas kompetens.`,
    [k.COMPETENCIES_OWNER_INFO]: `Kompetenser - Ägare\nKan se alla kompetenser, lägga till eller importera nya kompetenser, redigera, ta bort, arkivera och avarkivera befintliga kompetenser.\nKan tilldela behörigheter för kompetenser.`,
    [k.COMPETENCIES_OWNER]: `Kompetenser - Ägare`,
    [k.COMPETENCIES_VIEWER_INFO]: `Kompetenser - Läsare\nKan se alla kompetenser.`,
    [k.COMPETENCIES_VIEWER]: `Kompetenser - Läsare`,
    [k.COMPETENCIES]: `Kompetenser`,
    [k.COMPETENCY_MATRIX_FEATURE_DESCRIPTION]: `Kompetensmatrisen hjälper dig att hantera och spåra ditt teams färdigheter. Den identifierar luckor, bedömer nuvarande kompetenser och hjälper till att utveckla utbildningsplaner. Regelbundna uppdateringar säkerställer att ditt teams kompetens förblir i linje med dina organisationsmål.`,
    [k.COMPETENCY_MATRIX_PAGE_SUBTITLE]: `Här är alla kompetensmatriser som finns tillgängliga i systemet`,
    [k.COMPETENCY_MATRIX]: `Kompetensmatris`,
    [k.COMPETENCY_PAGE_TITLE]: `Kompetenser`,
    [k.COMPETENCY_POSITION]: `Vilka befattningar använder denna kompetens`,
    [k.COMPETENCY_TEAM]: `Vilka team använder denna kompetens`,
    [k.COMPETENCY]: `Kompetens`,
    [k.COMPLETE_THE_ACTIVITIES]: `Slutföra aktiviteterna`,
    [k.COMPLETE_THE]: `Slutförd den`,
    [k.COMPLETED_]: `slutförd`,
    [k.COMPLETED_A_]: `slutförde`,
    [k.COMPLETED_ACTIVITIES]: `Färdigmarkerade aktiviteter`,
    [k.COMPLETED_AT]: `Slutförd den`,
    [k.COMPLETED_BY]: `Fullgjord av`,
    [k.COMPLETED_COURSES]: `Avslutade kurser`,
    [k.COMPLETED_P_]: `slutförda`,
    [k.COMPLETED_P]: `Slutförda`,
    [k.COMPLETED]: `Slutförd`,
    [k.CONFIGURATION]: `Konfiguration`,
    [k.CONFIGURE_THIS_LIST]: `Konfigurera denna lista`,
    [k.CONFIRM_FINALIZE]: `Allt är klart. Vill du låsa detta steg?`,
    [k.CONFIRM_NEW_PASSWORD]: `Bekräfta nytt lösenord`,
    [k.CONFIRM]: `Bekräfta`,
    [k.CONTACT_DETAILS]: `Kontaktdetaljer`,
    [k.CONTACTS]: `Kontakter`,
    [k.COPIED]: `Kopierad!`,
    [k.COPY_A]: `Kopia`,
    [k.COPY_MESSAGE]: `Kopiera`,
    [k.COPY_RESPONSE]: `Kopiera svar`,
    [k.COPY_STEP_MESSAGE]: `Vill du kopiera detta steg och alla dess aktiviteter?`, //`Do you want to copy this step and all its activities?`,
    [k.COPY_WITH_METADATA]: `Kopiera med metadata`,
    [k.COUNTRY]: `Land`,
    [k.COURSES]: `Kurser`,
    [k.CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa en ny checklista eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_CONTACT_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny kontakt eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_CUSTOMER_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny kund eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_EQUIPMENT_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny utrustning eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_GOAL_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa ett nytt mål eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_IMPROVEMENT_FORM_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa ett nytt förbättringsformulär eller prova olika filter/sökord.`,
    [k.CREATE_A_NEW_KPI_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa en ny KPI eller prova olika filter/sökord.`,
    [k.CREATE_A_NEW_ONE_OR_ADD_ANY_EXISTING_GOAL]: `Skapa ett nytt eller lägg till ett befintligt mål.`,
    [k.CREATE_A_NEW_PURCHASE_ORDER_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa en ny inköpsorder eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_SUPPLIER_OR_TRY_DIFFERENT_FILTER_KEYWORDS]: `Skapa en ny leverantör eller prova andra filter / sökord`,
    [k.CREATE_A_NEW_TASK_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny uppgift eller prova andra filter / sökord`,
    [k.CREATE_A_NEW_TEAM_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa ett nytt team eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_TEMPLATE_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny procedur eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_USER_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny användare eller prova olika filter / sökord`,
    [k.CREATE_A_NEW_WORKSHEET_OR_TRY_DIFFERENT_FILETER_KEYWORDS]: `Skapa en ny checklista eller prova olika filter / sökord`,
    [k.CREATE_A_SCHEDULE_FOR]: `Skapa ett schema för`,
    [k.CREATE_KPI]: `Skapa KPI`,
    [k.CREATE_NEW_STEP]: `Lägg till steg`,
    [k.CREATE_RECURRENCE_OF]: `Skapa Upprepning av `,
    [k.CREATE_RECURRING_CHECKLIST]: `Skapa Upprepning`,
    [k.CREATE_RUN_TRACK_IMPROVE]: `Skapa, Kör, Spåra, Förbättra`,
    [k.CREATE]: `Skapa`,
    [k.CREATED_AT]: `Skapad vid`,
    [k.CREATED_BY]: `Skapad Av`,
    [k.CREATED]: `Skapad`,
    [k.CURRENT_AND_PREVIOUS_YEAR]: `Nuvarande och föregående år`,
    [k.CURRENT_PASSWORD_MESSAGE]: `Krävs endast om du vill ändra ditt lösenord`,
    [k.CURRENT_PASSWORD]: `Aktuellt lösenord`,
    [k.CURRENT_POSITION]: `Nuvarande Befattning`,
    [k.CURRENT_VALUE_AT_DATE]: `Nuvarande värde (vid valt datum)`,
    [k.CURRENT_VALUE]: `Nuvarande värde`,
    [k.CURRENTLY_THERE_ARE]: `För närvarande finns`,
    [k.CUSTOM_KPI]: `Anpassat KPI`,
    [k.CUSTOM_LIST_PERMISSION_DESCRIPTION]: `Här är listan över användare och team som har olika typer av åtkomst till den här anpassade listan. Du kan se enskilda användare som cirklar med deras initialer och lagnamn i en tablettform. Du kan också ändra behörighetsinställningar genom att använda knappen på sidan.`,
    [k.CUSTOM_LIST_ITEM_EDITOR]: `Redigerare`,
    [k.CUSTOM_LIST_ITEM_EDITOR_INFO]: `Anpassade listor - Redigerare\nKan se alla anpassade listor, lägga till nya anpassade listor, redigera, ta bort, arkivera och avarkivera befintliga anpassade listor.`,
    [k.CUSTOM_LIST_ITEM_OWNER]: `Ägare`,
    [k.CUSTOM_LIST_ITEM_OWNER_INFO]: `Anpassade listor - Ägare\nKan se alla anpassade listor, lägga till eller importera nya anpassade listor, redigera, ta bort, arkivera och avarkivera befintliga anpassade listor.\nKan tilldela behörigheter för anpassade listor.`,
    [k.CUSTOM_LIST_ITEM_USER]: `Användare`,
    [k.CUSTOM_LIST_ITEM_USER_DISABLED_INFO]: `Ej tillgängligt eftersom det inte finns några användaregenskaper i den anpassade listan med "Åtkomstbegränsning" markerad.`,
    [k.CUSTOM_LIST_ITEM_USER_INFO]: `Kan se, lägga till, redigera, arkivera och ta bort anpassade listor markerade med "Åtkomstbegränsning" som de har tilldelats behörighet till, som användare eller chef till behörig användare.`,
    [k.CUSTOM_LIST_ITEM_VIEWER]: `Läsare`,
    [k.CUSTOM_LIST_ITEM_VIEWER_INFO]: `Anpassade listor - Läsare\nKan se alla anpassade listor.`,
    [k.CUSTOM_LIST_OPTIONS_IN_CHECKLIST_WARNING]: `Användare kommer att kunna se alla alternative för den anpassade listan. De kommer dock inte kunna granska själva listan.`,
    [k.CUSTOM_LIST_PAGE_SUBTITLE]: `Hantera anpassade listor som är tillgängliga i hela systemet`,
    [k.CUSTOM_LIST_PAGE_TITLE]: `Anpassade listor`,
    [k.CUSTOM_LIST]: `Anpassad lista`,
    [k.CUSTOM_LISTS]: `Anpassade listor`,
    [k.CUSTOM_LISTS_FEATURE_DESCRIPTION]: `Genom att aktivera den här funktionen kan du skapa en anpassningsbar lista med olika egenskaper. Det är mer som en anpassad databas för ditt företag. Du kan till exempel skapa en anpassad lista för att hantera alla dina företagsfilialer eller dina kundkontrakt. Du kan till och med skapa listor som är beroende av andra listor.`,
    [k.CUSTOM_PROPERTIES]: `Egenskaper`,
    [k.CUSTOMER_DETAILS_SUBTITLE]: `Visa detaljer och kundkontakter för den valda kunden`,
    [k.CUSTOMER_DETAILS]: `Kunddetaljer`,
    [k.CUSTOMER_ID]: `KundID`,
    [k.CUSTOMER_INDUSTRY_PAGE_SUBTITLE]: `Här är alla kundbranscher som finns tillgängliga i systemet`,
    [k.CUSTOMER_INDUSTRY_PAGE_TITLE]: `Kundbranscher`,
    [k.CUSTOMER_LABEL]: `Kundetikett`,
    [k.CUSTOMER_NEEDS_EXPECTATIONS]: `Kundens behov och förväntningar`,
    [k.CUSTOMER_PLACEHOLDER]: `Kundens platshållare`,
    [k.CUSTOMER_REQUIREMENT_PAGE_SUBTITLE]: `Här är alla kundkrav som finns tillgängliga i systemet`,
    [k.CUSTOMER_REQUIREMENT_PAGE_TITLE]: `Kundkrav`,
    [k.CUSTOMER_REQUIREMENTS_COMMENT_PLACEHOLDER]: `Eventuella förtydliganden`,
    [k.CUSTOMER_REQUIREMENTS_EDITOR_INFO]: `Kundkrav - Redigerare\nKan se alla kundkrav, lägga till nya kundkrav, redigera, ta bort, arkivera och avarkivera befintliga kundkrav.`,
    [k.CUSTOMER_REQUIREMENTS_EDITOR]: `Kundkrav - Redigerare`,
    [k.CUSTOMER_REQUIREMENTS_FEATURE_DESCRIPTION]: `Kundkrav kan hanteras med den här funktionen.`,
    [k.CUSTOMER_REQUIREMENTS_OWNER_INFO]: `Kundkrav - Ägare\nKan se alla kundkrav, lägga till eller importera nya kundkrav, redigera, ta bort, arkivera och avarkivera befintliga kundkrav.\nKan tilldela behörigheter för kundkrav.`,
    [k.CUSTOMER_REQUIREMENTS_OWNER]: `Kundkrav - Ägare`,
    [k.CUSTOMER_REQUIREMENTS_VIEWER_INFO]: `Kundkrav - Läsare\nKan se alla kundkrav.`,
    [k.CUSTOMER_REQUIREMENTS_VIEWER]: `Kundkrav - Läsare`,
    [k.CUSTOMER_REQUIREMENTS]: `Kundkrav`,
    [k.CUSTOMER_SATISFACTION]: `Kundnöjdhet`,
    [k.CUSTOMER]: `Kund`,
    [k.CUSTOMERS_EDITOR_INFO]: `Kunder - Redigerare\nKan lägga till nya kunder, skapa kontakter, redigera, ta bort, arkivera och avarkivera befintliga kunder och kontakter.`,
    [k.CUSTOMERS_EDITOR]: `Kunder - Redigerare`,
    [k.CUSTOMERS_FEATURE_DESCRIPTION]: `Hantera dina kunder utan problem med den här funktionen. Effektivisera hanteringen av kunders affärs- och kontaktinformation. Den här funktionen hjälper dig att hålla uppdaterade register, förbättra kundrelationer och öka den övergripande tillfredsställelsen.`,
    [k.CUSTOMERS_OWNER_INFO]: `Kunder - Ägare\nKan lägga till eller importera nya kunder, skapa kontakter, redigera, ta bort, arkivera och avarkivera befintliga kunder.\nKan tilldela behörigheter för kunder och kontakter.`,
    [k.CUSTOMERS_OWNER]: `Kunder - Ägare`,
    [k.CUSTOMERS_SUBTITLE]: `Här är alla kunder som finns tillgängliga i systemet`,
    [k.CUSTOMERS_VIEWER_INFO]: `Kunder - Läsare\nIngen funktionalitet har lagts till ännu...`,
    [k.CUSTOMERS_VIEWER]: `Kunder - Läsare`,
    [k.CUSTOMIZE_THRESHOLD_VALUES]: `Anpassa tröskelvärden`,
    [k.D_AYS]: `d`,
    [k.DASHBOARD]: `Dashboard`,
    [k.DATA_SOURCE_NAME]: `Datakällans namn`,
    [k.DATA_SOURCE_REQUIRED]: `Datakälla krävs`,
    [k.DATA_SOURCE_TIP1]: `Välj de datakällor som ska användas för att spåra uppfyllelsen av målen. Du kan välja mellan användarinmatade data, förbättringsdatabas eller befintliga KPI:er som datakällor.`,
    [k.DATA_SOURCE]: `Datakälla`,
    [k.DATA_WILL_BE_FILTERABLE_LIST]: `Värden kommer att vara filteringsbara i listvyn`,
    [k.DATA_WILL_BE_SHOWN_LIST]: `Värden kommer att visas i listvyn`,
    [k.DATA_WILL_BE_SORTABLE_LIST]: `Värden kommer att vara sorterbara i listvyn`,
    [k.DATASOURCE_KPI]: `Datakällans KPI`,
    [k.DATASOURCE_TYPE]: `Datakällans typ`,
    [k.DATE_COMPLETED]: `Datum den avklarades`,
    [k.DATE_END_AFTER_START]: `Slutdatum måste vara efter Startdatum`,
    [k.DATE_EVALUATION]: `Datum utvärdering`,
    [k.DATE_OF_ADDITION]: `Datum för tillägg`,
    [k.DATE_PRESETS]: `DatumförHantera`,
    [k.DATE_START_BEFORE_END]: `Startdatum måste vara före Slutdatum`,
    [k.DATE]: `Datum`,
    [k.DATE]: `Datum`,
    [k.DAY_]: `dag`,
    [k.DAY_S_]: `dag(ar)`,
    [k.DAYS_]: `dagar`,
    [k.DAYS]: `Dagar`,
    [k.DEADLINE]: `Deadline`,
    [k.DEC]: `Dec`,
    [k.DECEMBER]: `December`,
    [k.DECIMAL_PLACES_INVALID_NUMBER]: `Decimaler måste vara större än eller lika med 0`,
    [k.DECIMAL_PLACES]: `Decimaler`,
    [k.DECIMAL]: `Decimal`,
    [k.DECISION_POINT]: `Beslutspunkt`,
    [k.DEFAULT_IMPROVEMENT]: `Förvalt avvikelseformulär`,
    [k.DEFAULT_PROCESS]: `Förvalt process`,
    [k.DEFAULT_PROCESS_ALERT_MESSAGE]: `För att konfigurera en standardprocess måste du ha minst en process i systemet. För närvarande har du inga processer. Klicka på knappen till höger för att lägga till en process och återvänd sedan hit för att välja standardprocess.`,
    [k.DEFAULT_PROCESS_DESCRIPTION]: `Förbättringar med utvalda "Övrigt/Osäker" i deras påverkade processfält skulle rullas upp under den valda processen.`,
    [k.DEFAULT_PROCESS_DESCRIPTION_2]: `Förbättringar som rapporteras på aktiva checklistor av checklistmallar som läggs till i Process Steps kommer att synas på deras respektive överordnade noder, Subprocesser och Processer. De som rapporteras oberoende kommer att bli föräldralösa och rullas upp under den valda processen.`,
    [k.DELETE_DRAFT]: `Ta bort utkast`,
    [k.DELETE_IMPACT_GRADING_1]: `Om du ta bort denna gradering kommer den omedelbart att bli otillgänglig. Om den redan används någonstans i systemet, kommer den inte att vara tillgänglig för ytterligare gradering.`,
    [k.DELETE_IMPACT_GRADING_2]: `Du kan inte återställa en borttagen gradering.`,
    [k.DELETE_IMPROVEMENT_CONFIRM_TITLE]: `Ta bort denna avvikelse?`,
    [k.DELETE_IMPROVEMENT_PERMANENTLY_MESSAGE]: `Om du tar bort denna avvikelse kommer den att raderas permanent från systemet. Du kan inte återställa en borttagen avvikelse`,
    [k.DELETE_POSITION_1]: `Om du tar bort den här positionen blir den omedelbart otillgänglig.`,
    [k.DELETE_POSITION_2]: `Du kan inte återställa en raderad position.`,
    [k.DELETE_PURCHASE_ORDER_MESSAGE_1]: `Om du tar bort denna inköpsorder kommer den att raderas permanent från systemet. Du kan inte återställa en borttagen inköpsorder`,
    [k.DELETE_PURCHASE_ORDER_MESSAGE_2]: `Är du verkligen säker på att du vill ta bort denna inköpsorder och all dess information?`,
    [k.DELETE_TEAM_1]: `Om du tar bort detta team blir det omedelbart otillgängligt.`,
    [k.DELETE_TEAM_2]: `Du kan inte återställa ett raderat team.`,
    [k.DELETE_THESE_NOTES]: `Ta bort dessa anteckningar?`,
    [k.DELETE_THIS_BARCODE_MESSAGE_LINE1]: `Om du tar bort den här streckkoden måste du skanna den igen.`,
    [k.DELETE_THIS_BARCODE_MESSAGE_LINE2]: `Är du säker på att du vill ta bort den?`,
    [k.DELETE_THIS_BARCODE_QUESTION]: `Ta bort denna streckkod?`,
    [k.DELETE_THIS_GENERATED_BARCODE_MESSAGE_LINE1]: `Om du tar bort denna streckkod kan du generera den senare.`,
    [k.DELETE_THIS_GOAL]: `Ta bort detta mål?`,
    [k.DELETE_THIS_KPI_LINE_1]: `Om du tar bort denna KPI kommer den inte att vara tillgänglig i systemet längre. Data kommer inte att vara tillgängliga för rapportering.`,
    [k.DELETE_THIS_KPI_LINE_2]: `Du kan inte återställa en raderad KPI. Är du säker på att du vill ta bort denna KPI?`,
    [k.DELETE_THIS_KPI]: `Ta bort denna KPI?`,
    [k.DELETE_THIS_MEASUREMENT]: `Ta bort detta mätvärde?`,
    [k.DELETE_THIS_PROGRESS_VALUE]: `Ta bort detta värde?`,
    [k.DELETE_THIS_TASK_LIST]: `Ta bort denna uppgiftslista?`,
    [k.DELETE_THIS_TASK]: `Ta bort denna uppgift?`,
    [k.DELETE_THIS_TEMPLATE]: `Vill du ta bort den här mallen?`,
    [k.DELETE]: `Ta bort`,
    [k.DELETED]: `Borttagen`,
    [k.DELETING_A_RECURRENCE_WILL1]: `Att ta bort en upprepande checklista kommer`,
    [k.DELETING_A_RECURRENCE_WILL2]: `den här upprepningen och alla checklistor som den skapade, inkluderat alla deras`,
    [k.DELETING_A_RUN_WILL]: `Att ta bort en checklista kommer`,
    [k.DESCRIBE_IMPOVEMENT_HERE]: `Beskriv avvikelsen här`,
    [k.DESCRIPTION_PHOTO]: `Beskrivningsbild`,
    [k.DESCRIPTION]: `Beskrivning`,
    [k.DESCRIPTIVE_RECURRANCE_NAME]: `Ge din upprepning en beskrivande titel`,
    [k.DETAILS]: `Detaljer`,
    [k.DEVIATION_MANAGER]: `Deviation manager`,
    [k.DEVIATION_MANAGERS]: `Deviation managers`,
    [k.DIRECT_FINANCIAL_CONSEQUENCES_PERCENT]: `Direkta ekonomiska konsekvenser %`,
    [k.DIRECT_FINANCIAL_CONSEQUENCES]: `Direkta ekonomiska konsekvenser`,
    [k.DIRECT_REPORTS]: `Rapporterar till mig`,
    [k.DISABLE_FEATURE_CONFIRM_MESSAGE]: `Om du inaktiverar den här funktionen blir all data som läggs till den otillgänglig överallt i systemet, inklusive andra funktioner där de används. Data kommer fortfarande att finnas tillgängliga för rapportering.`,
    [k.DISABLE_USER_LINE_1]: `Inaktiverade användare kommer inte att kunna logga in och användardata kommer inte längre att vara tillgängliga för någonstans i systemet`,
    [k.DISABLE_USER_TITLE]: `Inaktivera den här användaren?`,
    [k.DISABLE]: `Inaktivera`,
    [k.DISABLED_USER]: `Inaktiverad användare`,
    [k.DISABLED_WHILE_EDITING]: `Inaktiverad när du editerar`,
    [k.DISABLED]: `Inaktiverad`,
    [k.DISCOVERED_IN_PROCESS_FILTER]: `Upptäcktes i process filter`,
    [k.DISCOVERED_IN_T]: `<strong>Upptäcktes</strong> i`,
    [k.DISCOVERED_IN]: `Upptäcktes i`,
    [k.DISMISS]: `Avfärda`,
    [k.DO_WE_MEET_THE_REQUIREMENT]: `Uppfyller vi kravet?`,
    [k.DO_WORK_THE_RIGHT_WAY_EVERY_TIME]: `Gör arbete på rätt sätt. Varje gång`,
    [k.DO_YOU_WANT_TO_FINALIZE_THIS_STEP]: `Att låsa ett steg innebär att andra inte kommer att kunna ändra det i framtiden`,
    [k.DO_YOU_WANT_TO_PROCEED]: `Vill du gå vidare?`,
    [k.DO_YOU_WANT_TO_UN_ARCHIVE_THIS_EQUIPMENT]: `Vill du återställa denna utrustning`,
    [k.DO_YOU_WANT_TO_UN_ARCHIVE_THIS_TEAM]: `Vill du återställa detta team?`,
    [k.DO_YOU_WANT_TO_UPGRADE_EXISTING_CHECKLISTS]: `Vill du uppgradera befintliga checklistor till den nya versionen?`,
    [k.DOES_NOT_EXIST_OR_NO_PERMISSION]: `Det du försöker att nå finns inte eller så har du inte behörighet att se den.`,
    [k.DONE_EARLIER]: `Slutförda tidigare`,
    [k.DONE_WITH_THIS_STEP]: `Klar med detta steg?`,
    [k.DONE]: `Klar`,
    [k.DONT_UPGRADE]: `Uppgradera inte`,
    [k.DOWNLOAD_GENERATED_APP_PACKAGE]: `Ladda ner genererad app-paket`,
    [k.DOWNLOAD_GENERATED_APP_PACKAGE_DESCRIPTION]: `Skapa ett Microsoft Teams-apppaket som IT kan ladda upp till din Microsoft Teams-miljö.`,
    [k.DRAFT_SAVED]: `Utkast sparat`,
    [k.DRAFT]: `Utkast`,
    [k.DUE_DATE]: `Förfallodatum`,
    [k.DUE_IN]: `Förfaller om`,
    [k.DUE_ON]: `Förfaller den`,
    [k.DUE]: `Förfallodatum`,
    [k.DURATION]: `Varaktighet`,
    [k.EACH_ESTIMATED_TO_TAKE]: `Varje beräknas ta`,
    [k.EDIT_CHECKLIST]: `Redigera checklista`,
    [k.EDIT_CUSTOM_LIST]: `Redigera anpassad lista`,
    [k.EDIT_CUSTOMER_CONTACT]: `Redigera kundkontakt`,
    [k.EDIT_CUSTOMER_SUBTITLE]: `Du kan redigera kunden härifrån`,
    [k.EDIT_CUSTOMER]: `Redigera Kund`,
    [k.EDIT_DATA_SOURCE]: `Redigera datakälla`,
    [k.EDIT_EQUIPMENT_SUBTITLE]: `Du kan redigera utrustningen härifrån`,
    [k.EDIT_EQUIPMENT]: `Redigera utrustning`,
    [k.EDIT_GOAL]: `Redigera mål`,
    [k.EDIT_IMPROVEMENT_DRAFT]: `Redigera avvikelseutkast`,
    [k.EDIT_KPI]: `Redigera KPI`,
    [k.EDIT_LOCAL_STEP]: `Redigera steg`,
    [k.EDIT_MEASUREMENT]: `Redigera mätvärde`,
    [k.EDIT_PERMISSION_SETTINGS]: `Ändra behörighetsinställningar`,
    [k.EDIT_POSITION]: `Redigera befattning`,
    [k.EDIT_PURCHASE_ORDER_SUBTITLE]: `Du kan redigera inköpsordern härifrån`,
    [k.EDIT_PURCHASE_ORDER]: `Redigera inköpsorder`,
    [k.EDIT_RECURRING_CHECKLIST]: `Redigera upprepande checklista`,
    [k.EDIT_STEP_DRAFT]: `Redigera stegutkast`,
    [k.EDIT_SUB_GOAL]: `Redigera delmål`,
    [k.EDIT_SUPPLIER_SUBTITLE]: `Du kan redigera leverantören härifrån`,
    [k.EDIT_SUPPLIER]: `Redigera leverantör`,
    [k.EDIT_TARGETS_FOR_THIS_KPI]: `Redigera mål för denna KPI`,
    [k.EDIT_TEAM_SUBTITLE]: `Du kan redigera teamet härifrån`,
    [k.EDIT_TEAM]: `Redigera team`,
    [k.EDIT_TEMPLATE_DRAFT]: `Redigera mallutkast`,
    [k.EDIT_TEMPLATE]: `Redigera mall`,
    [k.EDIT_USER_SUBTITLE]: `Du kan redigera användaren härifrån`,
    [k.EDIT_USER]: `Redigera användare`,
    [k.EDIT]: `Redigera`,
    [k.EDITOR]: `Redigerare`,
    [k.EMAIL_ADDRESS]: `Email address`,
    [k.EMAIL_PLACEHOLDER]: `user@epost.se`,
    [k.EMAIL]: `E-post`,
    [k.EMPTY_ACTIVE_CHECKLIST_MESSAGE]: `Alla dina aktiva checklistor har slutförts. Ta en stund att fira!`,
    [k.EMPTY_BLOCKED_CHECKLIST_MESSAGE]: `Inga blockerade checklistor hittades. Fortsätt så!`,
    [k.EMPTY_FIELD]: `Tomt fält`,
    [k.EMPTY_FINALIZED_CHECKLIST_MESSAGE]: `Du har inga färdiga checklistor just nu. Slutförda checklistor kommer att visas här.`,
    [k.EMPTY_IMPACT_GRADING_MESSAGE]: `Inga påverkansgraderingar har lagts till ännu. Klicka på knappen nedan för att lägga till en.`,
    [k.EMPTY_STOPPED_CHECKLIST_MESSAGE]: `Inga stoppade checklistor hittades. Du gör stora framsteg!`,
    [k.ENABLE]: `Aktivera`,
    [k.ENABLE_THIS_FEATURE]: `Aktivera denna funktion`,
    [k.ENABLED]: `Aktiverad`,
    [k.END_AFTER_START]: `Förfallodatum ska vara efter startdatum`,
    [k.END_DATE_TIME]: `Förfallodatum`,
    [k.END_DATE]: `Slutdatum`,
    [k.END]: `Slutet`,
    [k.ENDING_ON_]: `förfallodatum den`,
    [k.ENDING_ON]: `Förfallodatum den`,
    [k.ENGLISH_NAME]: `Engelskt namn`,
    [k.ENGLISH]: `Engelska`,
    [k.ENTER_HERE]: `Skriv här`,
    [k.ENHANCEMENT]: `Annat`,
    [k.ENTER_NAME]: `Skriv namn`,
    [k.ENTER_NUMBER]: `Ange nummer`,
    [k.ENTRA_TENANT_ID]: `Entra Hyresgäst-ID`,
    [k.ENTRA_TENANT_ID_DESCRIPTION]: `Entra hyresgäst-ID är den unika identifieraren för din organisation i Entra. SSO (Single Sign-On) använder detta ID för att verifiera din organisation under inloggning, vilket gör att användarna sömlöst kan komma åt Ampliflow utan att behöva ange autentiseringsuppgifter igen.`,
    [k.ENVIRONMENT_ASPECTS_EDITOR_INFO]: `Miljöaspekter - Redigerare\nKan se alla miljöaspekter, lägga till nya miljöaspekter, redigera, ta bort, arkivera och avarkivera befintliga miljöaspekter.`,
    [k.ENVIRONMENT_ASPECTS_EDITOR]: `Miljöaspekter - Redigerare`,
    [k.ENVIRONMENT_ASPECTS_OWNER_INFO]: `Miljöaspekter - Ägare\nKan se alla miljöaspekter, lägga till eller importera nya miljöaspekter, redigera, ta bort, arkivera och avarkivera befintliga miljöaspekter.\nKan tilldela behörigheter för miljöaspekter.`,
    [k.ENVIRONMENT_ASPECTS_OWNER]: `Miljöaspekter - Ägare`,
    [k.ENVIRONMENT_ASPECTS_VIEWER_INFO]: `Miljöaspekter - Läsare\nKan se alla miljöaspekter.`,
    [k.ENVIRONMENT_ASPECTS_VIEWER]: `Miljöaspekter - Läsare`,
    [k.ENVIRONMENT]: `Miljö`,
    [k.ENVIRONMENTAL_AREA]: `Miljöområde`,
    [k.ENVIRONMENTAL_ASPECTS_NAME]: `Miljöaspekt`,
    [k.ENVIRONMENTAL_ASPECTS_FEATURE_DESCRIPTION]: `This feature enables management of company Environmental Aspects.`,
    [k.ENVIRONMENTAL_ASPECTS_PAGE_SUBTITLE]: `Här är alla miljöaspekter som finns tillgängliga i systemet`,
    [k.ENVIRONMENTAL_ASPECTS_PAGE_TITLE]: `Miljöaspekter`,
    [k.ENVIRONMENTAL_ASPECTS_RELATED_QUESTIONS]: `Miljöaspekt relaterade frågor`,
    [k.ENVIRONMENTAL_IMPACT]: `Miljöpåverkan neg/pos?`,
    [k.ENVIRONMENTAL_TARGET]: `Miljömål`,
    [k.EQUAL]: `Lika med`,
    [k.EQUIPMENT_DETAILS_SUBTITLE]: `Detaljer för vald utrustning`,
    [k.EQUIPMENT_DETAILS]: `Utrustningsdetaljer`,
    [k.EQUIPMENT_EDITOR_INFO]: `Utrustning - Redigerare\nKan se all utrustning, lägga till ny utrustning, redigera, ta bort, arkivera och avarkivera befintlig utrustning.`,
    [k.EQUIPMENT_EDITOR]: `Utrustning - Redigerare`,
    [k.EQUIPMENT_FEATURE_DESCRIPTION]: `Med den här funktionen aktiverad kan du hantera och hålla reda på din företagsutrustning.`,
    [k.EQUIPMENT_OWNER_INFO]: `Utrustning - Ägare\nKan se all utrustning, lägga till eller importera ny utrustning, redigera, ta bort, arkivera och avarkivera befintlig utrustning.\nKan tilldela behörigheter för utrustning.`,
    [k.EQUIPMENT_OWNER]: `Utrustning - Ägare`,
    [k.EQUIPMENT_REQUIRED_TO_COMPLETE_STEP]: `Utrustning som krävs för att slutföra steget`,
    [k.EQUIPMENT_SUBTITLE]: `Här är all utrustning som finns i systemet`,
    [k.EQUIPMENT_VIEWER_INFO]: `Utrustning - Läsare\nKan se all utrustning.`,
    [k.EQUIPMENT_VIEWER]: `Utrustning - Läsare`,
    [k.EQUIPMENT]: `Utrustning`,
    [k.EQUIPMENTS]: `Utrustning`,
    [k.ERROR_EMPTY_API_KEY]: `Fel: API-nyckeln kan inte vara tom`,
    [k.ERROR_POSITION_IN_USE_BY_COMPETENCIES]: `Positionen används av kompetenser`,
    [k.ERROR_POSITION_IN_USE_BY_USERS]: `Positionen används av användare`,
    [k.ERROR_POSITION_NOT_ARCHIVED]: `Positionen är inte arkiverad`,
    [k.ERROR]: `Fel`,
    [k.EST_DELIVERY]: `Ber. Leverans`,
    [k.ESTIMATED_PROBABILITY_REDUCTION]: `Estimerad sannolikhets reducering`,
    [k.EVALUATION_DATE]: `Datum för samtal`,
    [k.EVALUATION]: `Utvärdering`,
    [k.EVENT_DETAILS]: `Detaljer om evenemanget`,
    [k.EVERY_]: `varje`,
    [k.EVERY]: `Varje`,
    [k.EVERYONE]: `Alla användare`,
    [k.EXAMPLES_AVAILABILITY]: `Ex tillgänglighet`,
    [k.EXAMPLES_CONFIDENTIALITY]: `Ex konfidentialitet`,
    [k.EXAMPLES_DELIVERY_OF_PRODUCT]: `Ex produktleverans`,
    [k.EXAMPLES_DELIVERY_OF_SERVICES]: `Ex tjänsteleverans`,
    [k.EXAMPLES_DIRECT_FINANCIAL_CONSEQUENCES]: `Ex direkta ekonomiska konsekvenser`,
    [k.EXAMPLES_ENVIRONMENT]: `Ex miljö`,
    [k.EXAMPLES_HEALTH_SAFETY]: `Ex personskada`,
    [k.EXAMPLES_INDIRECT_FINANCIAL_IMPACT]: `Ex indirekt ekonomisk påverkan`,
    [k.EXAMPLES_INTEGRITY]: `Ex integritet`,
    [k.EXAMPLES_MEDIA_ATTENTION]: `Ex uppmärksamhet i media`,
    [k.EXAMPLES_PRODUCT_FUNCTIONALITY]: `Ex produktfunktionalitet`,
    [k.EXAMPLES_PRODUCT_SAFETY]: `Ex produktsäkerhet`,
    [k.EXPECTED_ABOVE_OR_EQUAL]: ` förväntas vara över eller lika med `,
    [k.EXPECTED_ABOVE]: ` förväntas vara över `,
    [k.EXPECTED_BELOW_OR_EQUAL]: ` förväntas vara under eller lika med `,
    [k.EXPECTED_BELOW]: ` förväntas vara under `,
    [k.BETA]: `Beta`,
    [k.EXPECTED_BETWEEN]: ` förväntas vara mellan `,
    [k.EXPIRED]: `Utgånget`,
    [k.EXPORT_TO_EXCEL]: `Exportera till Excel`,
    [k.EXTERNAL_ID]: `Externt ID`,
    [k.FAILED_MESSAGE]: `Misslyckad`,
    [k.FEATURES_AND_PERRMISSIONS]: `Funktioner och behörigheter`,
    [k.FEB]: `Feb`,
    [k.FEBRUARY]: `Februari`,
    [k.FILE]: `Fil`,
    [k.FILES_LINKS]: `Filer och länkar`,
    [k.FILTER_ALL_WHICH_CONTAINS]: `Filtrera alla som innehåller`,
    [k.FILTER_BY_DISCOVERED_IN_PROCESS]: `Filtrera efter process där avvikelsen upptäcktes`,
    [k.FILTER_BY_DUE_DATE]: `Förfaller`,
    [k.FILTER_BY_ID_UNCHECK_TO_SHOW_ALL]: `Filtrerar efter ID (avmarkera för att visa alla)`,
    [k.FILTER_BY_IMPACT]: `Filtrera efter påverkansgrad`,
    [k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT_DESCRIPTION]: `All förbättringsbeskrivning som innehåller dessa ord kommer att filtreras. \nAnvänd kommatecken för flera ord.`,
    [k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT]: `Filtrera där avvikelsens beskrivning innehåller en specifik text`,
    [k.FILTER_BY_IMPROVEMENT_FORM]: `Avvikelseformulär`,
    [k.FILTER_BY_IMPROVEMENT_IMPACT_MATRIX_GRADE_IS_HIGHER_THAN]: `Filtrera avvikelser där påverkansgrad är högre än`,
    [k.FILTER_BY_IMPROVEMENT_REPORTED_BY_MEMBER_IN_TEAM]: `Filtrera efter förbättring som rapporterats av medlem i teamet`,
    [k.FILTER_BY_ORIGINATED_IN_PROCESS]: `Filtrera efter process där avvikelsen uppstod`,
    [k.FILTER_BY_POSITION]: `Filtrera efter befattning`,
    [k.FILTER_BY_RESPONSIBLE]: `Filtrera efter ansvarig`,
    [k.FILTER_BY_ROLE]: `Filtrera efter roll`,
    [k.FILTER_BY_START_DATE]: `Startar`,
    [k.FILTER_BY_STATUS]: `Filtrera efter status`,
    [k.FILTER_BY_TEAMS]: `Filtrera efter team`,
    [k.FILTER_BY_TEMPLATES]: `Filtrera efter mallar`,
    [k.FILTER]: `Filter`,
    [k.FILTERABLE]: `Filtreringsbar`,
    [k.FINALIZE_NOTICE]: `Lås det här steget för att förhindra framtida ändringar och gå vidare till nästa steg`,
    [k.FINALIZE_QUESTION]: `Vill du fortfarande låsa detta steg? Detta kommer förhindra att någon ändrar detta i framtiden`,
    [k.FINALIZE]: `Lås`,
    [k.FINALIZED_AND_RUNNING]: `Låst och aktivt`,
    [k.FINALIZED_BY_MISTAKE]: `Låst av misstag`,
    [k.FINALIZED_BY]: `Låst av`,
    [k.FINALIZED_CHECKLISTS]: `Färdiga checklistor`,
    [k.FINALIZED_L]: `slutförd`,
    [k.FINALIZED_P]: `Slutförda`,
    [k.FINALIZED_TODAY]: `Slutförda idag`,
    [k.FINALIZED]: `Låst`,
    [k.FIRST_]: `första`,
    [k.FIRST_CHECKLIST]: `Första checklistan`,
    [k.FIRST]: `Första`,
    [k.FISCAL_YEAR_CONFIG_DESCRIPTION]: `Kontrollera datumintervallet som visas för KPI:er (påverkar inte beräkningar). Välj företagets startmånad för räkenskapsåret (standard: januari). Ändringar gäller omedelbart efter att de sparats för alla användare.`,
    [k.FISCAL_YEAR_CONFIG]: `Räkenskapsårskonfiguration`,
    [k.FIT_CONTENT]: `Anpassa till innehåll`,
    [k.FOR_IT_AND_DEVELOPERS]: `För IT & utvecklare`,
    [k.FOR_]: `för`,
    [k.FORM_ERRORS]: `Formulärfel`,
    [k.FORM_INFORMATION]: `Formulärinformation`,
    [k.FORM_NAME]: `Formulärnamn`,
    [k.FORM]: `Formulär`,
    [k.FORMULA_CANNOT_END_WITH_OPERATOR]: `Formel kan inte sluta med en operator`,
    [k.FORMULA_CANNOT_START_WITH_OPERATOR]: `Formel kan inte börja med en operator`,
    [k.FORMULA_EXPRESSION]: `Formeluttryck`,
    [k.FORMULA_ITEM]: `Formelobjekt`,
    [k.FORMULA_LIBRARY]: `Formelbibliotek`,
    [k.FORMULA_TOO_LONG]: `Formel är för lång`,
    [k.FORMULA_VARIABLES]: `Formelvariabler`,
    [k.FORMULA]: `Formel`,
    [k.FOURTH_]: `fjärde`,
    [k.FOURTH]: `Fjärde`,
    [k.FRIDAY]: `Fredag`,
    [k.FROM_]: `från`,
    [k.FROM_CLOUD_LIBRARY]: `Mallar från Molnet`,
    [k.FROM]: `Från`,
    [k.FUTURE_DATE]: `Framtida datum`,
    [k.GENERAL]: `Allmän`,
    [k.GENERATE_APP_PACKAGE]: `Generera app-paket`,
    [k.GENERATE_BARCODE]: `Generera Streckkod`,
    [k.GENERATE_PAYLOAD]: `Skapa payload`,
    [k.GEOGRAPHICALLY_LINKED_PLACEHOLDER]: `Land, region eller stad`,
    [k.GEOGRAPHICALLY_LINKED]: `Geografiskt relaterat`,
    [k.GO_TO]: `Gå till`,
    [k.GOAL_ARCHIVED]: `Mål arkiverat`,
    [k.GOAL_CREATED]: `Mål skapat`,
    [k.GOAL_DELETED]: `Mål raderat`,
    [k.GOAL_SAVED]: `Mål sparat`,
    [k.GOAL_UNARCHIVED]: `Mål återställt`,
    [k.GOAL]: `Mål`,
    [k.GOALS_EDITOR_INFO]: `Kan se/redigera/arkivera/ta bort mål och mätvärden.`,
    [k.GOALS_EDITOR]: `Mål - Redigerare`,
    [k.GOALS_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av företagets mål.`,
    [k.GOALS_OWNER_INFO]: `Kan se/redigera/arkivera/ta bort mål och mätvärden.\nKan tilldela behörigheter för mål och mätvärden.`,
    [k.GOALS_OWNER]: `Mål - Ägare`,
    [k.GOALS_PAGE_SUBTITLE]: `Spåra och hantera mål här`,
    [k.GOALS_PAGE_TITLE]: `Mål`,
    [k.GOALS_USER_INFO]: `Kan se mål och mätvärden de ansvarar för eller är involverade i.\nKan redigera, arkivera och avarkivera mål och mätvärden de ansvarar för.`,
    [k.GOALS_USER]: `Mål - Användare`,
    [k.GREEN]: `Grön`,
    [k.GROUP_BY]: `Gruppera efter`,
    [k.GUIDANCE]: `Vägledning`,
    [k.H_OURS]: `t`,
    [k.HAVE_BEEN_]: `har varit`,
    [k.HAVE_BEEN_COMPLETED]: `Har slutförts`,
    [k.HAVE_BEEN]: `Har varit`,
    [k.HEALTH_SAFETY]: `Personskada`,
    [k.HELP_AND_SUPPORT]: `Hjälp & Support`,
    [k.HIDE_LIST]: `Göm list`,
    [k.HIGHLIGTHED_RISK]: `Belyst risk`,
    [k.HISTORY]: `Historia`,
    [k.HOME_]: `startsidan`,
    [k.HOME_TITLE]: `AmpliFlow`,
    [k.HOME]: `Hemma`,
    [k.HOURS]: `Timmar`,
    [k.HOW_LONG_DOES_IT_TAKE_TO_COMPLETE_1_CHECKLIST]: `Hur lång tid tar det att genomföra mallen?`,
    [k.HOW_WE_ARE_AFFECTED_BY_THIS_LEGISLATION]: `Så påverkas vi av kravet`,
    [k.HOW_WE_MEET_THIS_LEGISLATION]: `Så uppfyller vi kravet`,
    [k.HOW_WOULD_YOU_LIKE_RUNS_TO_REPEAT]: `Hur vill du att checklistorna upprepas?`,
    [k.HYPERLINK]: `Hyperlänk`,
    [k.I_AGREE_TO_THE]: `Jag godkänner`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_AREA]: `Jag förstår konsekvenserna, ta bort området`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_POSITION]: `Jag förstår konsekvenserna, ta bort befattning`,
    [k.I_UNDERSTAND_THE_CONSEQUENCES_DELETE_TEAM]: `Jag förstår konsekvenserna, ta bort team`,
    [k.ICONS]: `Ikoner`,
    [k.ID_NUMBER]: `Id-nummer`,
    [k.ID]: `ID`,
    [k.IDENTIFIED_RISKS]: `Identifierade Risker`,
    [k.IDENTIFIED_TARGETS]: `Identifierade Mål`,
    [k.IF_YOU_CANCEL_YOU_WILL_LOOSE_THE_CHANGES_YOU_JUST_MADE]: `Om du avbryter kommer du att förlora de ändringar du just gjort`,
    [k.IF_YOU_WANT_TO_STOP_ALL_SCHEDULED_CHECKLISTS_YOU_SET_THE_NEW_END_DATE_BEFORE]: `Om du vill stoppa alla schemalagda checklistor ställer du in det nya slutdatumet före`,
    [k.IMPACT_CLASSIFICATION]: `Påverkansgradering`,
    [k.IMPACT_GRADE]: `Påverkansgrad`,
    [k.IMPACT_GRADING_PAGE_SUBTITLE]: `Här är alla påverkansgrader som finns tillgängliga i systemet`,
    [k.IMPACT_GRADING_PAGE_TITLE]: `Påverkansgradering`,
    [k.IMPACT_GRADINGS_EDITOR_INFO]: `Kan se alla påverkansgrader, lägga till nya påverkansgrader, redigera, ta bort, arkivera och avarkivera befintliga påverkansgrader.`,
    [k.IMPACT_GRADINGS_EDITOR]: `Påverkansgradering - Redigerare`,
    [k.IMPACT_GRADINGS_FEATURE_DESCRIPTION]: `This feature allows users to customize and manage the impact gradings. These gradings can be used in improvements and goals and operational risk analysis.`,
    [k.IMPACT_GRADINGS_OWNER_INFO]: `Kan se alla påverkansgrader, lägga till eller importera nya påverkansgrader, redigera, ta bort, arkivera och avarkivera befintliga påverkansgrader.\nKan tilldela behörigheter för påverkansgrader.`,
    [k.IMPACT_GRADINGS_OWNER]: `Påverkansgradering - Ägare`,
    [k.IMPACT_GRADINGS_VIEWER_INFO]: `Kan se alla påverkansgrader.`,
    [k.IMPACT_GRADINGS_VIEWER]: `Påverkansgradering - Läsare`,
    [k.IMPACT_SCORE1_LABEL]: `1 - Mycket Låg`,
    [k.IMPACT_SCORE2_LABEL]: `2 - Låg`,
    [k.IMPACT_SCORE3_LABEL]: `3 - Medel`,
    [k.IMPACT_SCORE4_LABEL]: `4 - Hög`,
    [k.IMPACT_SCORE5_LABEL]: `5 - Mycket Hög`,
    [k.IMPACT]: `Påverkan`,
    [k.IMPACTED_AREA]: `Påverkat område`,
    [k.IMPACTED_AREAS]: `Påverkat områden`,
    [k.IMPACTED_PROCESS_DISCOVERED]: `Påverkade process: Upptäckt`,
    [k.IMPACTED_PROCESS_ORIGINATED]: `Påverkade process: Ursprung`,
    [k.IMPACTED_PROCESSES]: `Påverkade processer`,
    [k.IMPORT_FROM_EXCEL_SUCCESS_MESSAGE_WITH_AMOUNT]: `Importerade {{amount}} poster!`,
    [k.IMPORT_TEMPLATE_FROM_CLOUD]: `Importera Mallar från Molnbiblioteket`,
    [k.IMPORT]: `Importera`,
    [k.IMPORTANT_ACTIVITIES]: `Viktiga Aktiviteter`,
    [k.IMPORTED]: `Importerad`,
    [k.IMPROVEMENT_CLOSED_WITH_REASON]: `Avvikelse stängd med anledning`,
    [k.IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER]: `Läggs till som involverat i alla avvikelser`,
    [k.IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER]: `Ampliflow erbjuder en informativ analysinstrumentpanel för förbättringar. Du kan analysera rapporterade förbättringar efter form, påverkan, påverkade områden, status och mer.`,
    [k.IMPROVEMENT_DASHBOARD_FEATURE_DESCRIPTION]: `Ampliflow erbjuder en informativ analysinstrumentpanel för förbättringar. Du kan analysera rapporterade förbättringar efter form, påverkan, påverkade områden, status och mer.`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACT]: `Avvikelser efter påverkansgrad`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_AREA]: `Avvikelser efter påverkat område`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_PROCESS]: `Avvikelser efter påverkat process`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPROVEMENT_FORM]: `Avvikelser efter typ`,
    [k.IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_STATUS]: `Avvikelser efter status`,
    [k.IMPROVEMENT_DASHBOARD_OWNER_INFO]: `Kan se avvikelsedashboard.\nKan tilldela behörigheter för avvikelsedashboard`,
    [k.IMPROVEMENT_DASHBOARD_OWNER]: `Avvikelsedashboard - Ägare`,
    [k.IMPROVEMENT_DASHBOARD_PAGE_LABEL]: `Avvikelsedashboard`,
    [k.IMPROVEMENT_DASHBOARD_PAGE_SUBHEADING]: `Analysera avvikelser - alla uppgifter i tidszonen UTC`,
    [k.IMPROVEMENT_DASHBOARD_VIEWER_INFO]: `Kan se avvikelsedashboard.`,
    [k.IMPROVEMENT_DASHBOARD_VIEWER]: `Avvikelsedashboard - Läsare`,
    [k.IMPROVEMENT_DASHBOARD]: `Avvikelsedashboard`,
    [k.IMPROVEMENT_DATA]: `Avvikelsedata`,
    [k.IMPROVEMENT_DETAILS_ACTIVITY_IN_STEP]: `Aktivitet i steg`,
    [k.IMPROVEMENT_DETAILS_IMPACTED_TEMPLATES]: `Påverkade mallar`,
    [k.IMPROVEMENT_DETAILS_REPORTED_FROM]: `Rapporterad från`,
    [k.IMPROVEMENT_DETAILS_SCHEDULE]: `Upprepande checklista`,
    [k.IMPROVEMENT_DETAILS_STEP]: `Steg`,
    [k.IMPROVEMENT_DISCOVERED_IN_T]: `Avvikelsen <strong>upptäcktes</strong> i`,
    [k.IMPROVEMENT_FORM_DETAILS]: `Information om avvikelse`,
    [k.IMPROVEMENT_FORM_FILTER]: `Avvikelsemallfilter`,
    [k.IMPROVEMENT_FORM]: `Avvikelsemall`,
    [k.IMPROVEMENT_IMPACT_FILTER]: `Påverkansfilter`,
    [k.IMPROVEMENT_KPI]: `Avvikelse KPI`,
    [k.IMPROVEMENT_LEFT_BY]: `Avvikelse rapporterad av`,
    [k.IMPROVEMENT_ORIGINATED_IN_T]: `Avvikelsen <strong>orsakades</strong> i`,
    [k.IMPROVEMENT_REGISTERED]: `Avvikelse registrerad!`,
    [k.IMPROVEMENT_STATUS_FILTER]: `Avvikelser efter status`,
    [k.IMPROVEMENT_STATUS]: `Status`,
    [k.IMPROVEMENT_STEPS]: `Avvikelse Steg`,
    [k.IMPROVEMENT]: `Avvikelse`,
    [k.IMPROVEMENTS_FORMS_PAGE_SUBTITLE]: `Alla dina avvikelser`,
    [k.IMPROVEMENTS_PAGE_LABEL]: `Här visas avvikelser som du har rätt att se.`,
    [k.IMPROVEMENTS_REPORTED_TO_AN_ACTIVITY]: `Avvikelser rapporterade mot aktiviteter`,
    [k.IMPROVEMENTS]: `Avvikelser`,
    [k.IN_]: `i`,
    [k.IN_PROGRESS]: `Pågående`,
    [k.IN_TOTAL_]: `totalt`,
    [k.INBOX]: `Inbox`,
    [k.INDIRECT_FINANCIAL_IMPACT_PERCENT]: `Indirekt ekonomisk påverkan %`,
    [k.INDIRECT_FINANCIAL_IMPACT]: `Indirekt ekonomisk påverkan`,
    [k.INDUSTRY_RELEVANT]: `Branschrelevant?`,
    [k.INDUSTRY]: `Bransch(er)`,
    [k.INFO]: `Info`,
    [k.INFORMATION_SECURITY_AVAILABILITY]: `Informationssäkerhet - Tillgänglighet`,
    [k.INFORMATION_SECURITY_CONFIDENTIALITY]: `Informationssäkerhet - Konfidentialitet`,
    [k.INFORMATION_SECURITY_INTEGRITY]: `Informationssäkerhet - Integritet`,
    [k.INFORMATION]: `Information`,
    [k.INPUT_FROM]: `Insats från`,
    [k.INPUTS]: `Insats`,
    [k.INTERNAL_ID]: `Internt ID`,
    [k.INVALID_BARCODE]: `Ogiltig Streckkod`,
    [k.INVALID_CREDENTIALS]: `Ogiltiga autentiseringsuppgifter`,
    [k.INVALID_FIELDS]: `Ogiltig inmatning i formulär`,
    [k.INVALID_FUTURE_USER_POSITION_DATES]: `Slutdatum kan inte vara i framtiden.`,
    [k.INVALID_OVERLAPPING_USER_POSITION_DATES]: `Datum överlappar med existerande befattning av samma typ.`,
    [k.INVALID_URL]: `Ogiltig URL`,
    [k.INVALID_USER_POSITION_DATES]: `Det går inte att tilldela positionen med ett startdatum efter slutdatumet.`,
    [k.INVALID_VARIABLE]: `Ogiltig variabel`,
    [k.INVALID]: `Ogiltig`,
    [k.INVOICING]: `Fakturering`,
    [k.INVOLVED_TEAMS]: `Delaktiga Team`,
    [k.INVOLVED_USERS]: `Delaktiga Användare`,
    [k.INVOLVED_USERS_HEADER_TOOLTIP]: `Om du ändrar användare här så kommer inte existerande avvikelser där involverade användare manuellt ändrats att uppdateras.`,
    [k.INVOLVED_USERS_TOOLTIP]: `När en ny avvikelse rapporteras, kommer utvalda användare att få ett e-postmeddelande.`,
    [k.INVOLVED_USERS]: `Delaktiga Användare`,
    [k.INVOLVED]: `Delaktiga`,
    [k.INVOLVEMENT]: `Involvering`,
    [k.IS_]: `är`,
    [k.IS_DEFAULT_IMPROVEMENT_STEP]: `Är detta huvudavvikelseformulär?`,
    [k.IS_KEY_ACTIVITY]: `Är en nyckelaktivitet`,
    [k.IS_PRIMARY_CONTACT]: `Är primär kontakt`,
    [k.IS_PRIMARY]: `Är primärt`,
    [k.IS_USER_ROLE]: `Användarroll`,
    [k.IT_AND_DEV]: `IT & Utveckling`,
    [k.IT_CONTAINS]: `Den innehåller`,
    [k.IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED]: `Det verkar som om inte alla aktiviteter som krävs har slutförts`,
    [k.IT_WILL_HAVE]: `Det kommer att ha`,
    [k.ITEM_CATALOG_SUBTITLE]: `Här är alla tillgängliga artiklar i systemet`,
    [k.ITEM_CATALOG]: `Artikelkatalog`,
    [k.ITEM_LABEL]: `Varumärke`,
    [k.ITEM_PLACEHOLDER]: `Platshållare för artikel`,
    [k.ITEM_TYPE_NOT_PROVIDED]: `Artikeltyp har inte angetts`,
    [k.ITEM_TYPE]: `Typ`,
    [k.ITEM_UNIT]: `Enhet`,
    [k.ITEM]: `Artikel`,
    [k.ITEMS_EDITOR_INFO]: `Kan se alla artiklar, lägga till nya artiklar, skapa leverantörer, redigera, ta bort, arkivera och avarkivera befintliga artiklar.`,
    [k.ITEMS_EDITOR]: `Artikelkatalog - Redigerare`,
    [k.ITEMS_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av företagsartiklar.`,
    [k.ITEMS_OWNER_INFO]: `Kan se alla artiklar, lägga till nya eller importera artiklar, skapa leverantörer, redigera, ta bort, arkivera och avarkivera befintliga artiklar.\nKan tilldela behörigheter för artiklar.`,
    [k.ITEMS_OWNER]: `Artikelkatalog - Ägare`,
    [k.ITEMS_VIEWER_INFO]: `Kan se alla artiklar.`,
    [k.ITEMS_VIEWER]: `Artikelkatalog - Läsare`,
    [k.ITEMS]: `Artiklar`,
    [k.ITS_EMPTY_HERE]: `Det är tomt här!`,
    [k.JAN]: `Jan`,
    [k.JANUARY]: `Januari`,
    [k.JSON_PAYLOAD_PREVIEW_DESCRIPTION]: `Webhooks är alltid knutna till den senaste versionen av mallen och steget, snarare än till en specifik version`,
    [k.JSON_PAYLOAD_PREVIEW]: `Förhandsgranskning av JSON-payload`,
    [k.JUL]: `Jul`,
    [k.JULY]: `July`,
    [k.JUN]: `Jun`,
    [k.JUNE]: `Juni`,
    [k.JUST_NOW]: `Just nu`,
    [k.KEY_ACTIVITIES_TOOLTIP]: `Nyckelaktiviteter visas även på överliggande process sida`,
    [k.KEY_ACTIVITIES]: `Nyckelaktiviteter`,
    [k.KPI_CANNOT_REFERENCE_ITSELF]: `KPI kan inte referera till sig själv`,
    [k.KPI_CONTRIBUTOR_INFO]: `Kan se alla KPI:er, redigera befintliga KPI:er.`,
    [k.KPI_CONTRIBUTOR]: `KPI - Bidragsgivare`,
    [k.KPI_CREATED]: `KPI skapad`,
    [k.KPI_DATA_SOURCE_ID_REQUIRED]: `Måste välja en KPI-källa`,
    [k.KPI_DETAILS_TIP1]: `I det här steget anger du namnet och en bra beskrivning för din KPI som hjälper andra att enkelt förstå varför KPI:n finns.`,
    [k.KPI_DETAILS]: `KPI Detaljer`,
    [k.KPI_EDITOR_INFO]: `Kan se alla KPI:er, lägga till nya KPI:er, redigera, arkivera och avarkivera befintliga KPI:er.`,
    [k.KPI_EDITOR]: `KPI - Redigerare`,
    [k.KPI_FEATURE_DESCRIPTION]: `Med den här funktionen aktiverad kan du hålla reda på dina KPI:er.`,
    [k.KPI_FORMULA_TIP1]: `Variabler är byggstenarna i din KPI. Se till att välja rätt variabler och dubbelkolla att de är korrekta och uppdaterade.`,
    [k.KPI_FORMULA_TIP2]: `Aritmetiska operationer låter dig kombinera variabler och utföra beräkningar för att få din KPI. Se till att använda rätt operationer och följ operationsordningen för att säkerställa korrekta resultat.`,
    [k.KPI_FORMULA_TIP3]: `Statiska siffror och parenteser låter dig justera KPI-formeln efter dina behov. Se till att använda dem korrekt och dubbelkolla din formel innan du sparar.`,
    [k.KPI_FORMULA]: `KPI Formel`,
    [k.KPI_MANUAL_DATA_SOURCE_INFO]: `Mata in värdena för denna manuella datakälla, per månad, per år.`,
    [k.KPI_NAME_REQUIRED]: `KPI Namn krävs`,
    [k.KPI_NAME]: `KPI Namn`,
    [k.KPI_OWNER_INFO]: `Kan se alla KPI:er, lägga till nya KPI:er, redigera, ta bort, arkivera och avarkivera befintliga KPI:er.\nKan tilldela behörigheter för KPI:er.`,
    [k.KPI_OWNER]: `KPI - Ägare`,
    [k.KPI_PAGE_SUBTITLE]: `Alla KPIer tillgängliga i systemet`,
    [k.KPI_PAGE_TITLE]: `KPIer`,
    [k.KPI_SAVED]: `KPI sparad`,
    [k.KPI_TARGETS_INFO]: `Målvärden för KPIet mot vald avvikelsetyp, uppdelat efter påverkansgrad. Tillagda värden kommer automatiskt att föras vidare till följande månad och år fram tills att ett nytt värde läggs till.`,
    [k.KPI_TARGETS]: `Målvärden`,
    [k.KPI_UNIT]: `KPI Enhet`,
    [k.KPI_UPDATED]: `KPI uppdaterad`,
    [k.KPI_VIEWER_INFO]: `Kan se alla KPI:er.`,
    [k.KPI_VIEWER]: `KPI - Läsare`,
    [k.KPI]: `KPI`,
    [k.LABEL_]: `etikett`,
    [k.LABEL]: `Etikett`,
    [k.LANGUAGE]: `Språk`,
    [k.LAST_]: `sista`,
    [k.LAST_DAY_]: `sista dagen`,
    [k.LAST_MODIFIED]: `Senast ändrad`,
    [k.LAST_MONTH]: `Förra månaden`,
    [k.LAST_WEEK]: `Förra veckan`,
    [k.LAST]: `Sista`,
    [k.LEAD_TIME_TARGET]: `Ledtidsmål`,
    [k.LEGISLATION_PAGE_SUBTITLE]: `Här är alla de rättsakter som finns i systemet`,
    [k.LEGISLATION_PAGE_TITLE]: `Lagstiftning`,
    [k.LEGISLATION_TITLE]: `Lagkrav`,
    [k.LEGISLATIONS_EDITOR_INFO]: `Kan se all lagstiftning, lägga till ny lagstiftning, redigera, ta bort, arkivera och avarkivera befintlig lagstiftning.`,
    [k.LEGISLATIONS_EDITOR]: `Lagstiftning - Redigerare`,
    [k.LEGISLATIONS_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av företagslagstiftning.`,
    [k.LEGISLATIONS_OWNER_INFO]: `Kan se all lagstiftning, lägga till eller importera ny lagstiftning, redigera, ta bort, arkivera och avarkivera befintlig lagstiftning.\nKan tilldela behörigheter för lagstiftning.`,
    [k.LEGISLATIONS_OWNER]: `Lagstiftning - Ägare`,
    [k.LEGISLATIONS_VIEWER_INFO]: `Kan se all lagstiftning.`,
    [k.LEGISLATIONS_VIEWER]: `Lagstiftning - Läsare`,
    [k.LETS_ADD_SOME_CONTACT]: `Låt oss lägga till lite kontakter`,
    [k.LEVEL_OF_EDUCATION]: `Utbildningsnivå`,
    [k.LEVEL_OF_INTEREST]: `Nivå av intresse`,
    [k.LINK_LABEL]: `Länk etikett`,
    [k.LINK_PLACEHOLDER]: `https://example.com`,
    [k.LINK_SUB_PROCESS]: `Länka Subprocess`,
    [k.LINK_TO_IN_DEPTH_INFORMATION]: `Länk till fördjupad information`,
    [k.LINK_TO_LEGISLATION]: `Länk till lagstiftning`,
    [k.LINK]: `Länk`,
    [k.LINKS]: `Länkar`,
    [k.LOAD_EARLIER]: `Läs in tidigare`,
    [k.LOADING]: `Laddar...`,
    [k.LOG_EXECUTED]: `Logg genomfört`,
    [k.LOGIN_]: `logga in`,
    [k.LOGIN_PLEASE_WAIT]: `Loggar in, vänligen vänta`,
    [k.LOGIN_QUICK_TIP_DESCRIPTION]: `Du kan sortera översiktsrapporten på status`,
    [k.LOGIN_QUICK_TIP]: `💡 Tips:`,
    [k.LOGIN_TO]: `Logga in på`,
    [k.LOGIN]: `Logga in`,
    [k.LOGOUT_ALL_USERS_DESCRIPTION]: `Med den här funktionen kan du logga ut alla användare i denna hyresgäst. Speciellt användbart för säkerhet. Alla användare måste logga in igen.`,
    [k.LOGOUT_ALL_USERS]: `Logga ut alla användare`,
    [k.LOGOUT_CONFIRMATION]: `Vill du logga ut?`,
    [k.M_INUTES]: `m`,
    [k.MAIN_TASK]: `Huvudsaklig arbetsuppgift`,
    [k.MAKE_PRIMARY]: `Gör till primär kontakt`,
    [k.MANAGE_CUSTOM_LISTS]: `Hantera Anpassade Listor`,
    [k.MANAGE_CUSTOMER_INDUSTRIES]: `Kundbranscher`,
    [k.MANAGE_PROCESS_CHARTS]: `Inställningar processkarta`,
    [k.MANAGE_PURCHASE_ORDER_LINE_STATUS_SUBTITLE]: `Här är alla inköpsorderradestatusar som finns tillgängliga i systemet`,
    [k.MANAGE_SSO]: `Hantera SSO`,
    [k.MANAGE_API_KEYS]: `Hantera API-nycklar`,
    [k.MANAGE_TYPE_UNIT_SUBTITLE]: `Här är alla artikeltyper och artiklar som finns tillgängliga i systemet`,
    [k.MANAGE_WEBHOOKS]: `Hantera Webhooks`,
    [k.MANAGED_BY_THESE_PROCESS]: `Hanteras i följande process-/er`,
    [k.MANAGEMENT]: `Ledning`,
    [k.MANAGER]: `Chef`,
    [k.MANAGING_CUSTOM_LISTS_DESCRIPTION]: `Genom att aktivera den här funktionen kan du skapa en anpassningsbar lista med olika egenskaper. Det är mer som en anpassad databas för ditt företag. Du kan till exempel skapa en anpassad lista för att hantera alla dina företagsfilialer eller dina kundkontrakt. Du kan till och med skapa listor som är beroende av andra listor.`,
    [k.MANAGING_CUSTOM_LISTS]: `Hantera Anpassade Listor`,
    [k.MANAGING_SSO_CONFIG]: `Hantera SSO Config`,
    [k.MANAGING_SSO_CONFIG_DESCRIPTION]: `Du kan konfigurera inställningar för enkel inloggning (SSO) på den dedikerade konfigurationssidan. För att komma åt den här sidan, klicka på knappen till höger eller välj fliken "SSO" högst upp på sidan.`,
    [k.MANAGING_WEBHOOKS_AND_EVENTS_DESCRIPTION]: `Webhooks tillåter Ampliflow att skicka realtidsdata till din angivna URL närhelst vissa händelser inträffar hos din hyresgäst. Detta möjliggör sömlös integration med externa system, automatisera arbetsflöden och omedelbart uppdatera dina applikationer med relevant information.`,
    [k.MANAGING_WEBHOOKS_AND_EVENTS]: `Hantera Webhooks & Events`,
    [k.MANUAL_DATA_SOURCE_DESCRIPTION]: `Du har angivit detta som en Manuell Datakälla.\nDetta innebär att den som ansvarar för denna KPI behöver mata in datavärden manuellt. Efter att du har sparat, öppna KPI:n för att lägga in data.\nKom ihåg, detta måste göras varje månad för varje år du följer upp.`,
    [k.MANUAL_VALUE]: `Manuellt värde`,
    [k.MANUAL]: `Manuell`,
    [k.MAPPER]: `Mapper`,
    [k.MAR]: `Mar`,
    [k.MARCH]: `Mars`,
    [k.MARK_AS_ANALYZED]: `Markera som analyserad`,
    [k.MARK_AS_COMPLETED]: `Markera som slutförd`,
    [k.MARK_AS_DONE]: `Markera som åtgärdad`,
    [k.MARK_AS_INCOMPLETE]: `Markera som ofullständig`,
    [k.MARK_AS_NOT_ANALYZED]: `Markera som ej analyserad`,
    [k.MARK_AS_NOT_DONE]: `Markera som ej åtgärdad`,
    [k.MARK_AS_NOT_REVIEWED]: `Markera som ej granskad`,
    [k.MARK_AS_REVIEWED]: `Markera som granskad`,
    [k.MARKED_AS_COMPLETE]: `Markerad som slutförd`,
    [k.MARKED_AS_INCOMPLETE]: `Markerad som ofullständig`,
    [k.MARKED_FOR_REMOVAL]: `Markerad för bortagning`,
    [k.MARKERS_OF_PROGRESS_LINE]: `Markörer för framstegslinje`,
    [k.MATCHING_CHECKLISTS_]: `matchande checklistor`,
    [k.MATCHING_VALUE_]: `matchande värde`,
    [k.MATCHING_VALUES_]: `matchande värden`,
    [k.MAY]: `Maj`,
    [k.ME]: `Jag`,
    [k.MEASUREMENT_ARCHIVED]: `Mätvärde arkiverat`,
    [k.MEASUREMENT_CREATED]: `Mätvärde skapat`,
    [k.MEASUREMENT_INDEX]: `Mätvärdeindex`,
    [k.MEASUREMENT_UNARCHIVED]: `Mätvärde återställt`,
    [k.MEASUREMENT]: `Mätvärde`,
    [k.MEASUREMENTS]: `Mätvärden`,
    [k.MEDIA_ATTENTION]: `Uppmärksamhet i media`,
    [k.MESSAGE]: `Meddelande`,
    [k.MIN_ONE]: `Minst 1`,
    [k.MICROSOFT_TEAMS_APP_FEATURE_DESCRIPTION]: `Den här funktionen möjliggör integration av Ampliflow med Microsoft Teams-appen.`,
    [k.MIN_UTES]: `Min.`,
    [k.MIN_ZERO]: `Minst 0`,
    [k.MINUTES]: `Minuter`,
    [k.MISSING_CLOSING_PARENTHESIS]: `Avslutande parentes saknas`,
    [k.MISSING_DATA_TO_CALCULATE]: `Uppgifter som saknas för att beräkna...`,
    [k.MISSING_ITEMS]: `Saknade artiklar`,
    [k.MISSING_OPENING_PARENTHESIS]: `Inledande parentes saknas`,
    [k.MISSING_OPERAND]: `Operand saknas`,
    [k.MISSING_OPERATOR]: `Operatör saknas`,
    [k.MISSING_PARENTHESIS]: `Parentes saknas`,
    [k.MODIFIED]: `Ändrad`,
    [k.MONDAY]: `Måndag`,
    [k.MONTH_S_]: `månad(er)`,
    [k.MONTH_S]: `Månad(er)`,
    [k.MONTHLY]: `Månadsvis`,
    [k.MONTHS_]: `månader`,
    [k.MORE]: `Mer`,
    [k.MOTIVATION]: `Motivation`,
    [k.MOVE_ON_TO_THE_NEXT_STEP]: `Vill du låsa det här steget och gå vidare till nästa?`,
    [k.MY_CHECKLISTS]: `Mina Checklistor`,
    [k.MY_IMPROVEMENTS]: `Mina Avvikelser`,
    [k.MY_TASKS]: `Mina Uppgifter`,
    [k.NAME_CHECKLIST]: `Ge din checklista ett unikt namn`,
    [k.NAME]: `Namn`,
    [k.NAV_BUTTON_REGISTER_IMPROVEMENT]: `Registrera Avvikelse`,
    [k.NAV_BUTTON_RUN_CHECKLIST]: `Starta Checklista`,
    [k.NAV_NODE_ADMINISTRATOR_SETTINGS]: `Hantera`,
    [k.NAV_NODE_ADMINISTRATOR]: `Administratör`,
    [k.NAV_NODE_ADVANCED_FEATURES]: `Avancerat`,
    [k.NAV_NODE_ANALYZE]: `Analysera`,
    [k.NAV_NODE_BULK_IMPORT]: `Massimport`,
    [k.NAV_NODE_CHECKLIST_TEMPLATE]: `Checklistmallar`,
    [k.NAV_NODE_CHECKLISTS_REPORT]: `Översikt Checklistor`,
    [k.NAV_NODE_CHECKLISTS_SEARCH]: `Sök Checklistor`,
    [k.NAV_NODE_CHECKLISTS]: `Checklistor`,
    [k.NAV_NODE_COMPETENCY_MATRIX]: `Kompetensmatris`,
    [k.NAV_NODE_CUSTOM_LISTS]: `Anpassade listor`,
    [k.NAV_NODE_CUSTOMERS]: `Kunder`,
    [k.NAV_NODE_DATA_MANAGEMENT]: `Datahantering`,
    [k.NAV_NODE_EQUIPMENT]: `Utrustning`,
    [k.NAV_NODE_GOALS]: `Mål`,
    [k.NAV_NODE_HR]: `HR`,
    [k.NAV_NODE_HUMAN_RESOURCES]: `Mänskliga resurser`,
    [k.NAV_NODE_IMPACT_GRADINGS]: `Påverkansgradering`,
    [k.NAV_NODE_IMPROVEMENT_DASHBOARD]: `Avvikelsedashboard`,
    [k.NAV_NODE_IMPROVEMENT_FORMS]: `Avvikelseformulär`,
    [k.NAV_NODE_IMPROVEMENT_SETUP]: `Avvikelseformulär`,
    [k.NAV_NODE_IMPROVEMENTS]: `Avvikelser`,
    [k.NAV_NODE_ITEMS]: `Artikelkatalog`,
    [k.NAV_NODE_KPI]: `KPI`,
    [k.NAV_NODE_LEGISLATIONS]: `Lagstiftning`,
    [k.NAV_NODE_LISTS]: `Listor`,
    [k.NAV_NODE_LOG_OUT]: `Logga ut`,
    [k.NAV_NODE_MANAGE_PURCHASE_ORDER_LINE_STATUS]: `Inställningar inköpsorder`,
    [k.NAV_NODE_MANAGE_TYPE_AND_UNIT]: `Inställningar artikelkatalog`,
    [k.NAV_NODE_MAP]: `Mallar`,
    [k.NAV_NODE_MARKETING_SALES]: `Marknad & Sälj`,
    [k.NAV_NODE_MICROSOFT_TEAMS_APP]: `Microsoft Teams App`,
    [k.NAV_NODE_OPERATIONAL_MANAGEMENT]: `Operativ Ledning`,
    [k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS]: `Riskhantering`,
    [k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS_GRAPH]: `Riskanalys`,
    [k.NAV_NODE_OTHER_RESOURCES]: `Övriga Resurser`,
    [k.NAV_NODE_PAGES]: `Sidor`,
    [k.NAV_NODE_PERFORMANCE_MANAGEMENT]: `Målstyrning`,
    [k.NAV_NODE_POSITIONS]: `Befattningar`,
    [k.NAV_NODE_PROCESSCHART]: `Processkarta`,
    [k.NAV_NODE_PROCUREMENT_AND_SALES]: `Inköp och försäljning`,
    [k.NAV_NODE_PURCHASE_ORDERS]: `Inköpsordrar`,
    [k.NAV_NODE_RECURRING_CHECKLIST]: `Upprepande checklistor`,
    [k.NAV_NODE_REPORTS]: `Rapporter`,
    [k.NAV_NODE_RISK_COMPLIANCE_MANAGEMENT]: `Risk- och efterlevnadshantering`,
    [k.NAV_NODE_RISK_MANAGEMENT]: `Riskhantering`,
    [k.NAV_NODE_SCHEDULING]: `Serier`,
    [k.NAV_NODE_SENIOR_MANAGEMENT]: `Ledning`,
    [k.NAV_NODE_SHARED_TEMPLATE_STEPS]: `Delade steg`,
    [k.NAV_NODE_SINGLE_SIGN_ON]: `Enkel inloggning (SSO)`,
    [k.NAV_NODE_SOURCING_PURCHASE]: `Inköp`,
    [k.NAV_NODE_STAKEHOLDERS]: `Intressentregister`,
    [k.NAV_NODE_SUPPLIERS]: `Leverantörer`,
    [k.NAV_NODE_TENANT_SETTINGS]: `Inställningar`,
    [k.NAV_NODE_TASKS]: `Uppgifter`,
    [k.NAV_NODE_TEAMS]: `Team`,
    [k.NAV_NODE_TEMPLATES]: `Mallar`,
    [k.NAV_NODE_TRACEABILITY_REPORT]: `Spårbarhetsrapport`,
    [k.NAV_NODE_USERS]: `Användare`,
    [k.NEED_ATTENTION]: `Behöver Uppmärksamhet`,
    [k.NEED_FOR_TRAINING]: `Behov av utbildning`,
    [k.NEED_REWORK]: `Behöver göras om`,
    [k.NEW_]: `ny`,
    [k.NEW_API_KEY]: `Ny API-nyckel`,
    [k.NEW_CUSTOM_LIST]: `Ny anpassad lista`,
    [k.NEW_CUSTOMER_CONTACT]: `Ny kundkontakt`,
    [k.NEW_CUSTOMER]: `Ny Kund`,
    [k.NEW_EQUIPMENT]: `Ny Utrustning`,
    [k.NEW_FILE]: `Ny fil`,
    [k.NEW_GOAL]: `Nytt Mål`,
    [k.NEW_IMPROVEMENT_FORM]: `Nytt Avvikelseformulär`,
    [k.NEW_IMPROVEMENT_FORM]: `Skapa nytt avvikelseformulär`,
    [k.NEW_IMPROVEMENT]: `Ny Avvikelse`,
    [k.NEW_ITEM]: `Ny Artikel`,
    [k.NEW_LINK]: `Ny länk`,
    [k.NEW_MEASUREMENT]: `Nytt Mätvärde`,
    [k.NEW_PAGE]: `Ny Sida`,
    [k.NEW_PASSWORD]: `Nytt lösenord`,
    [k.NEW_PURCHASE_ORDER]: `Ny Inköpsorder`,
    [k.NEW_RECURRING_CHECKLIST]: `Ny Upprepande Checklista`,
    [k.NEW_STATUS]: `Ny Status`,
    [k.NEW_STEP_VERSION_AVAILABLE]: `Nya stegversioner tillgängliga`,
    [k.NEW_STEP]: `Nytt Steg`,
    [k.NEW_SUB_GOAL]: `Nytt Delmål`,
    [k.NEW_SUPPLIER]: `Ny Leverantör`,
    [k.NEW_TASK_ADDED]: `Ny uppgift har lagts till`,
    [k.NEW_TASK]: `Ny Uppgift`,
    [k.NEW_TEAM]: `Nytt Team`,
    [k.NEW_TEMPLATE]: `Ny Mall`,
    [k.NEW_TYPE_UNIT]: `Ny Typ/Enhet`,
    [k.NEW_USER]: `Ny Användare`,
    [k.NEW_VERSION_AVAILABLE_UPDATING_IN_30_SECONDS]: `Ny version tillgänglig. Uppdaterar om 30 sekunder...`,
    [k.NEW_WEBHOOK]: `Ny webhook`,
    [k.NEW]: `Ny`,
    [k.NEXT_CALIBRATION_DATE]: `Nästa kalibreringsdatum`,
    [k.NEXT_CALIBRATION]: `Nästa kalibrering`,
    [k.NEXT_MONTH]: `Nästa månad`,
    [k.NEXT_WEEK]: `Nästa vecka`,
    [k.NEXT_YEAR]: `Nästa år`,
    [k.NEXT]: `Nästa`,
    [k.NO_ACTION_PLAN_ADDED_YET]: `Ingen handlingsplan har lagts till ännu`,
    [k.NO_ACTIONS_TAKEN]: `Inga åtgärder vidtagna`,
    [k.NO_ACTIVITES_IN_THIS_IMPROVEMENT_FORM_YET]: `Inga aktiviteter i denna förbättringsform ännu`,
    [k.NO_ACTIVITES_IN_THIS_STEP_YET]: `Inga aktiviteter i det här steget än`,
    [k.NO_AVAILABLE_CHECKLIST_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga checklista hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga checklistor hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_CUSTOMLIST_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga anpassade listor hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_CUSTOMLISTITEM_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga anpassade listor hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_GOALS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga mål hittades för ditt konto / användarroll.`,
    [k.NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga avvikelse hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_IMPROVEMENTS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga avvikelser hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga KPI:er hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_MEASUREMENTS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga mätvärden hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga mallar hittades för ditt konto / användarroll`,
    [k.NO_AVAILABLE_WORKSHEETS_FOUND_FOR_YOUR_USER_ROLE]: `Inga tillgängliga checklistor hittades för ditt konto / användarroll`,
    [k.NO_BIO_AVAILABLE]: `Ingen bio tillgänglig`,
    [k.NO_CHECKLIST_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW]: `Mallen innehåller inga uppgifter, du kan själv lägga till uppgifter som behöver göras i den här checklistan genom att klicka på knappen nedan.`,
    [k.NO_CHECKLIST_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY]: `Det finns inga uppgifter i mallen, lägg till dina egna här.`,
    [k.NO_CHECKLIST_OR_NOT_EXIST]: `Checklistan finns inte eller så saknar du behörighet.`,
    [k.NO_CHECKLIST_TO_SHOW]: `Inga checklistor att visa. Det finns inga aktiva checklistor där du är tilldelad steg. Om det inte stämmer bör du rappotera en avikelse`,
    [k.NO_COMMENT_PROVIDED]: `Ingen kommentar lämnas`,
    [k.NO_COMPLETED_COURSES]: `Inga avslutade kurser`,
    [k.NO_CONTACTS_AVAILABLE_FOR_THIS_CUSTOMER]: `Inga kontakter tillgängliga för den här kunden`,
    [k.NO_CONTACTS_AVAILABLE_FOR_THIS_SUPPLIER]: `Inga kontakter tillgängliga för denna leverantör`,
    [k.NO_CUSTOMERS_AVAILABLE]: `Inga kunder tillgängliga`,
    [k.NO_DESCRIPTION_PROVIDED]: `Ingen beskrivning tillgänglig`,
    [k.NO_DEVIATION_MESSAGE_SUBMITTED]: `Inget avvikelsemeddelande som skickats`,
    [k.NO_EQUIPMENT_AVAILABLE]: `Ingen utrustning tillgänglig`,
    [k.NO_EQUIPMENT_TO_SHOW]: `Det finns ingen utrustning att visa`,
    [k.NO_EQUIPMENT]: `Ingen utrustning`,
    [k.NO_GOAL_AVAILABLE]: `Inga mål tillgängliga.`,
    [k.NO_IMPROVEMENT_FORM_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_IMPROVEMENTS]: `Mallen innehåller inga uppgifter.\r\nAnvändare kommer kunna lägga till egna uppgifter här i avvikelser.`,
    [k.NO_IMPROVEMENT_FORMS_SUBTITLE]: `Tryck på knappen för att lägga till`,
    [k.NO_IMPROVEMENT_FORMS_TITLE]: `Inga avvikelsemallar har blivit tillagda`,
    [k.NO_IMPROVEMENT_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW]: `Mallen innehåller inga uppgifter.\r\nDu kan lägga till egna uppgifter här.`,
    [k.NO_IMPROVEMENT_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY]: `NMallen innehåller inga uppgifter för den här listan.`,
    [k.NO_IMPROVEMENTS_FOUND]: `Inga avvikelser hittades`,
    [k.NO_IMPROVEMENTS_TO_SHOW]: `Det finns inga avvikelser att visa`,
    [k.NO_ITEMS_AVAILABLE]: `Inga artiklar tillgängliga`,
    [k.NO_KPI_AVAILABLE]: `Inga KPIer tillgängliga`,
    [k.NO_KPI_UNIT_PROVIDED]: `Ingen KPI-enhet tillgänglig`,
    [k.NO_LABEL]: `Ingen ettiket`,
    [k.NO_MATCHES]: `Inga matchningar`,
    [k.NO_MEASUREMENTS_ADDED_YET]: `Inga mätvärden har lagts till ännu.`,
    [k.NO_MORE_RUNS_TO_LOAD]: `Inga fler checklistor att ladda`,
    [k.NO_NOTES_PROVIDED]: `Inga anteckningar tillgängliga`,
    [k.NO_ONE_ASSIGNED]: `Ingen tilldelad`,
    [k.NO_ONE_CAN_VIEW]: `Ingen kan se`,
    [k.NO_ONE_HAS_ACCESS_ALERT_MESSAGE]: `Just nu kan bara du och andra användare med administratörsrollen komma åt den här funktionen. Klicka på knappen "Redigera behörighetsinställningar" för att ge åtkomst till andra användare och team.`,
    [k.NO_ONE_HAS_ACCESS_TO_CUSTOM_LIST_ALERT_MESSAGE]: `Just nu kan bara du och andra användare med administratörsrollen komma åt den här anpassade listan. Klicka på knappen "Redigera behörighetsinställningar" för att ge åtkomst till andra användare och team.`,
    [k.NO_ONE_IS_HERE]: `Ingen är här`,
    [k.NO_PERMISSION_TO_EDIT_TEMPLATE]: `Du har inga behörigheter att redigera den här mallen`,
    [k.NO_PHOTO]: `[Inget foto]`,
    [k.NO_PO_LINE_STATUSES_AVAILABLE]: `No PO Line Statuses available`,
    [k.NO_PROCESS_AVAILABLE_RIGHT_NOW]: `Ingen process tillgänglig just nu.`,
    [k.NO_PROGRESS_TO_SHOW]: `Ingen framgång att visa`,
    [k.NO_PURCHASE_ORDERS_AVAILABLE]: `Inga inköpsorder tillgängliga`,
    [k.NO_RECURRING_CHECKLISTS_TO_SHOW]: `Inga upprepande checklistor att visa`,
    [k.NO_RECURRING_OR_NOT_EXIST]: `Upprepande checklistan finns inte eller så saknar du behörighet.`,
    [k.NO_RESULTS]: `Inga resultat`,
    [k.NO_RISK_UNPUBLISHED_PROCESS_STEP]: `Det går inte att lägga till risker för opublicerade processsteg`,
    [k.NO_RUNS_FINALIZED]: `Det finns inga slutförda steg för denna checklista ännu...`,
    [k.NO_SCHEDULED_COURSES]: `Inga planerade kurser`,
    [k.NO_STATUS]: `Ingen status`,
    [k.NO_STEP]: `Inga steg`,
    [k.NO_STEPS_TO_SHOW]: `Inga steg tilldelade...`,
    [k.NO_STEPS]: `Inga steg i den här mallen än`,
    [k.NO_SUB_GOALS_ADDED_YET]: `Inga delmål har lagts till ännu.`,
    [k.NO_SUMMARY_AVAILABLE]: `Ingen sammanfattning tillgänglig`,
    [k.NO_SUPPLIERS_AVAILABLE]: `Inga leverantörer tillgängliga`,
    [k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN]: `Inga uppgifter här än, lägg till egna här.`,
    [k.NO_TASKS_ADDED_YET_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN]: `Inga uppgifter har lagts till ännu.\r\nAnvändare kommer att kunna lägga till sina egna.`,
    [k.NO_TASKS_AVAILABLE]: `Inga uppgifter tillgängliga`,
    [k.NO_TEAM_AVAILABLE]: `Inga team tillgängliga`,
    [k.NO_TEAM_ROLES]: `Inga roller från team`,
    [k.NO_TEAM_TO_SHOW]: `Det finns inga team att visa`,
    [k.NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS]: `Mallen innehåller inga uppgifter.\r\nAnvändare kommer kunna lägga till egna uppgifter här i checklistor.`,
    [k.NO_TEMPLATES_ASSIGNED]: `Team är inte tilldelad någon mall`,
    [k.NO_TEMPLATES_SUBTITLE1]: `Oroa dig inte! Vi har massor av färdiga mallar i biblioteket.`,
    [k.NO_TEMPLATES_SUBTITLE2]: `Du kan importera dem med ett enda klick`,
    [k.NO_TEMPLATES_TITLE]: `Inga mallar finns tillgängliga här!`,
    [k.NO_TITLE]: `Ingen titel`,
    [k.NO_UNIT]: `Ingen enhet`,
    [k.NO_USERS_ASSIGNED_TO_THIS_GOAL]: `Ingen användare tilldelad till detta mål`,
    [k.NO_USERS_ASSIGNED_TO_THIS_MEASUREMENT]: `Ingen användare tilldelad till detta mätvärde`,
    [k.NO_USERS_ASSIGNED]: `Ingen användare tilldelade till team`,
    [k.NO_USERS_AVAILABLE]: `Ingen användare tillgänglig`,
    [k.NO_USERS_OR_TEAMS_HAVE_THIS_ROLE_ASSIGNED_TO_THEM_YET]: `Inga användare eller team har denna roll tilldelad till dem än.`,
    [k.NO_USERS_TO_SHOW]: `Inga användare att visa`,
    [k.NO_VALUE]: `Inget värde`,
    [k.NO_ZERO_DURATION]: `Varaktigheten kan inte vara noll`,
    [k.NO]: `Nej`,
    [k.NONE_ASSIGNED]: `Inga tilldelade`,
    [k.NONE_SELECTED_P]: `Inga valda`,
    [k.NONE_SELECTED]: `Ingen vald`,
    [k.NONE]: `Ingen`,
    [k.NOT_ACTIONABLE]: `Inte åtgärdbar`,
    [k.NOT_APPLICABLE]: `Ej tillämpbar`,
    [k.NOT_ARCHIVED]: `Ej arkiverad`,
    [k.NOT_AVAILABLE_MESSAGE]: `n/a`,
    [k.NOT_AVAILABLE]: `Inte tillgänglig`,
    [k.NOT_COMPLETED]: `Ej slutförd`,
    [k.NOT_FOUND]: `Hittades inte`,
    [k.NOT_OK]: `Inte OK`,
    [k.NOT_SPECIFIED]: `Ej angivet`,
    [k.NOTE]: `Antekning`,
    [k.NOTES]: `Anteckningar`,
    [k.NOTHING_FOUND]: `Ingenting hittades`,
    [k.NOTIFICATION]: `Anmälan`,
    [k.NOTIFY]: `Meddela`,
    [k.NOV]: `Nov`,
    [k.NOVEMBER]: `November`,
    [k.NUMBER_OF_DECIMAL_PLACES]: `Antal decimaler`,
    [k.NUMBER_OF_EMPLOYEES_DESCRIPTION]: `Antalet anställda i ditt företag på olika platser.`,
    [k.NUMBER_OF_EMPLOYEES_EXAMPLE]: `Exempel: Sverige 25, USA 40, Norge 2`,
    [k.NUMBER_OF_EMPLOYEES]: `Antal anställda`,
    [k.NUMBER_OF_IMPROVEMENTS_BROKEN_DOWN_BY_STATUS]: `Antal avvikelser uppdelade efter status`,
    [k.NUMBER_OF_RUNS]: `Antal checklistor`,
    [k.NUMBERS_SHOULD_BE_GREATER_THAN_ZERO]: `Siffrorna ska vara större än 0`,
    [k.OBSOLETE]: `Föråldrad`,
    [k.OCCURENCE]: `Förekomst`,
    [k.OCT]: `Okt`,
    [k.OCTOBER]: `Oktober`,
    [k.OF_THE_MONTH]: `av månaden`,
    [k.OF]: `av`,
    [k.OK]: `Okej`,
    [k.OLD_VERSION]: `Gammal version`,
    [k.ON_A_FIXED_DATE]: `På ett förbestämt datum`,
    [k.ON_THESE_DAYS]: `På dessa dag(ar)`,
    [k.ON_THESE_MONTHS]: `På dessa månad(er)`,
    [k.ON_TRACK_]: `enligt plan`,
    [k.ON_TRACK]: `Enligt plan`,
    [k.ON]: `På`,
    [k.OPEN_AMPLIFLOW_IN_BROWSER_TO_PASTE_FROM_CLIPBOARD]: `Öppna AmpliFlow i webbläsaren för att klistra in från urklipp`,
    [k.OPEN_IMPROVEMENTS_TABLE_HEADER]: `Öppna Avvikelser`,
    [k.OPEN]: `Öppen`,
    [k.OPENs]: `Öppna`,
    [k.OPERATIONAL_RISK_ANALYSIS_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av operativa risker och deras analys.`,
    [k.OPERATIONAL_RISK_ANALYSIS_GRAPH_FEATURE_DESCRIPTION]: `Denna funktion gör det möjligt för användare att se data från operationell riskanalys i ett interaktivt diagram.`,
    [k.OPERATIONAL_RISK_ANALYSIS_GRAPH]: `Graf för analys av operativa risker`,
    [k.OPERATIONAL_RISK_ANALYSIS_GRAPH_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av operativa risker och deras analys.`,
    [k.OPERATIONAL_RISK_ANALYSIS_PAGE_TITLE]: `Riskhantering`,
    [k.OPERATORS]: `Operatörer`,
    [k.OPTIONAL_PLACEHOLDER]: `Alternativt platshållare för inmatning`,
    [k.OPTIONAL]: `Valfri`,
    [k.OPTIONS]: `Alternativ`,
    [k.OR_ENTER_BARCODE_HERE]: `Eller ange streckkod här`,
    [k.OR]: `Eller`,
    [k.ORA_EDITOR_INFO]: `Kan se alla risker, lägga till nya risker, redigera risker, arkivera och avarkivera risker.`,
    [k.ORA_EDITOR]: `Riskhantering - Redigerare`,
    [k.ORA_OWNER_INFO]: `Kan se alla risker, lägga till nya eller importera risker, redigera risker, ta bort risker, arkivera och avarkivera risker.\nKan tilldela behörigheter för risker.`,
    [k.ORA_OWNER]: `Riskhantering - Ägare`,
    [k.ORA_VIEWER_INFO]: `Kan se alla risker.`,
    [k.ORA_VIEWER]: `Riskhantering - Läsare`,
    [k.ORIGIN]: `Ursprung`,
    [k.ORIGINATED_IN_PROCESS_FILTER]: `Orsakades i process filter`,
    [k.ORIGINATED_IN_T]: `<strong>Orsakades</strong> i`,
    [k.ORIGINATED_IN]: `Orsakades i`,
    [k.OTHER_NOT_SURE]: `Annat / Vet inte`,
    [k.OTHER_USERS]: `Andra användare`,
    [k.OTHER]: `Övrigt`,
    [k.OUR_KNOWLEDGE_GURU]: `Vår kunskapsguru`,
    [k.OUT_OF_]: `ut av`,
    [k.OUTPUT_TO]: `Utfall till`,
    [k.OUTPUTS]: `Utfall`,
    [k.OVER_TARGET_IS_GOOD]: `Övermål är bra`,
    [k.OVER_THRESHOLD]: `Över tröskel`,
    [k.OVERALL_TOTALS_FOR_THE_FORM]: `Totala totaler för formuläret`,
    [k.OVERVIEW]: `Översikt`,
    [k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS]: `Ägare för vald <strong>upptäckt i</strong> process`,
    [k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS]: `Ägare för vald <strong>orsakades i</strong> process`,
    [k.OWNER]: `Ägare`,
    [k.PAGE_]: ``,
    [k.PAGE_NOT_FOUND]: `Sidan hittades inte`,
    [k.PAGE]: `Sida`,
    [k.PAGES]: `Sidor`,
    [k.PARENT_GOAL]: `Föräldramål`,
    [k.PARENT_GOALS]: `Föräldramål`,
    [k.PARENTHESES]: `Parenteser`,
    [k.PART_OF_SCHEDULE]: `Del av schema`,
    [k.PASSWORD_PLACEHOLDER]: `************`,
    [k.PASSWORD]: `Lösenord`,
    [k.PASTE_FAILED]: `Klistra in misslyckades`,
    [k.PASTE_FROM_CLIPBOARD]: `Klistra-in från urklipp`,
    [k.PASTE]: `Klistra-in`,
    [k.PAUSE]: `Pausa`,
    [k.PAUSED_BY]: `Pausad av`,
    [k.PAUSED]: `Pausad`,
    [k.PAYLOAD]: `Payload`,
    [k.PEOPLE_TO_BE_TRAINED]: `Personer som ska utbildas`,
    [k.PERCENTAGE_OF_QUANTITY_TARGET]: `% av kvantitetsmålet`,
    [k.PERCENTAGE]: `Procent`,
    [k.PERFORMANCE_EVALUATION]: `Resultat utvärdering`,
    [k.PERMANENTLY_REMOVE]: `TA BORT DEN PERMANENT`,
    [k.PERMISSION_DESCRIPTION]: `Här är listan över användare och team som har olika typer av tillgång till denna funktion. Du kan se enskilda användare som cirklar med deras initialer och teamnamn i en pillerform. Du kan också ändra behörighetsinställningar genom att använda knappen på sidan.`,
    [k.PERMISSION_SETTINGS]: `Behörighetsinställningar`,
    [k.PERSONAL_AMBITION]: `Personlig ambition`,
    [k.PHONE_MOBILE]: `Telefon (Mobil)`,
    [k.PHONE]: `Telefonnr`,
    [k.PHOTO_PREVIEW]: `Foto förhandsvisning`,
    [k.PHOTO]: `Foto`,
    [k.PLACEHOLDER_]: `platshållare`,
    [k.PLACEHOLDER_FOR_INPUT_FIELD]: `Platshållare för inmatningsfält`,
    [k.PLACEHOLDER]: `Platshållare`,
    [k.PLANNED_FOR]: `Planerat för`,
    [k.PLANNED_SCHEDULE_FOR]: `Planerat schema för`,
    [k.PLANNER]: `Planner`,
    [k.PLEASE_CONFIRM]: `Vänligen bekräfta`,
    [k.PLEASE_FORWARD_LOGIN_DETAILS_TO_USER_AFTER_SAVING]: `Vänligen vidarebefordra inloggningsuppgifter till användaren efter att ha sparat`,
    [k.PLEASE_REMOVE_THE_POSITION_FROM_THE_COMPETENCIES_FIRST_AND_TRY_AGAIN]: `För att ta bort befattningen från kompetenserna först och försök igen.`,
    [k.PLEASE_REMOVE_THE_POSITION_FROM_THE_USERS_FIRST_AND_TRY_AGAIN]: `För att ta bort befattningen behöver du först ta bort den från användarna och sen försöka igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_CHECKLISTS_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från checklistorna först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_GOAL_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från målet först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_KPI_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från KPI:er först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_MEASUREMENT_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från mätvärden först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_PROCESS_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från processen först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_STEP_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från steget först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_SUBPROCESS_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från underprocessen först och försök igen.`,
    [k.PLEASE_REMOVE_THE_TEAM_FROM_THE_TEMPLATE_FIRST_AND_TRY_AGAIN]: `För att ta bort teamet från mallen först och försök igen.`,
    [k.POSITION_CANNOT_BE_DELETED_1]: `Befattningen kan inte tas bort eftersom den används av följande användare:`,
    [k.POSITION_CANNOT_BE_DELETED_2]: `Befattningen kan inte tas bort då den för närvarande används av dessa kompetenser:`,
    [k.POSITION_DELETED]: `Befattning raderad`,
    [k.POSITION_EDITOR_INFO]: `Kan se alla befattningar, lägga till nya befattningar, redigera befattningar, ta bort befattningar, arkivera och avarkivera befattningar.`,
    [k.POSITION_EDITOR]: `Befattning - Redigerare`,
    [k.POSITION_IS_USED_FOR_COMPETENCIES]: `Positionen används av dessa kompetenser:`,
    [k.POSITION_IS_USED_FOR_USERS]: `Positionen används av dessa användare:`,
    [k.POSITION_OWNER_INFO]: `Kan se alla befattningar, lägga till nya eller importera befattningar, redigera befattningar, ta bort befattningar, arkivera och avarkivera befattningar.\nKan tilldela behörigheter för befattningar.`,
    [k.POSITION_OWNER]: `Befattning - Ägare`,
    [k.POSITION_VIEWER_INFO]: `Kan se alla befattningar.`,
    [k.POSITION_VIEWER]: `Befattning - Läsare`,
    [k.POSITION_WILL_BE_REMOVED]: `Befattning kommer att tas bort`,
    [k.POSITION]: `Befattning`,
    [k.POSITIONS_PAGE_SUBTITLE]: `Här är alla Befattningar som finns tillgängliga i systemet`,
    [k.POSITIONS_PAGE_TITLE]: `Befattningar`,
    [k.POSITIONS]: `Befattningar`,
    [k.POSTAL_CODE]: `Postkod`,
    [k.POSTPONED]: `Vilande`,
    [k.POTENTIAL_CONSEQUENCES]: `Potentiella konsekvenser`,
    [k.PRESENT]: `Nuvarande`,
    [k.PREVIEW]: `Förhandsvisning`,
    [k.PREVIOUS_YEAR]: `Föregående år`,
    [k.PREWRITTEN_REASONS]: `Förslag`,
    [k.PRIMARY_CONTACT_PHONE]: `Telefonnr (Primär kontakt)`,
    [k.PRINT]: `Skriva ut`,
    [k.PRIORITY]: `Prioritet`,
    [k.PRIVACY_POLICY]: `Sekretesspolicy`,
    [k.PROBABILITY_REDUCTION]: `Sannolikhetsminskning`,
    [k.PROBABILITY]: `Sannolikhet`,
    [k.PROCESS_CHART]: `Processkarta`,
    [k.PROCESS_CHERT_FEATURE_DESCRIPTION]: `Visualisera och optimera dina processer med lätthet, vilket förbättrar operativ effektivitet.`,
    [k.PROCESS_STEP]: `Process Steg`,
    [k.PROCESS]: `Process`,
    [k.PROCESSES]: `Processer`,
    [k.PROFESSIONAL_EXPERIENCE]: `Yrkeserfarenhet`,
    [k.PROFILE_SAVED]: `Profil sparad!`,
    [k.PROFILE]: `Profil`,
    [k.PROGRESS_ARCHIVED]: `Framsteg arkiverat`,
    [k.PROGRESS_DATE]: `Framstegsdatum`,
    [k.PROGRESS_OVER_TIME]: `Framsteg över tid`,
    [k.PROGRESS_UNARCHIVED]: `Framsteg återställt`,
    [k.PROGRESS_VALUE_CREATED]: `Värde skapat`,
    [k.PROGRESS_VALUE_DELETED]: `Värde raderat`,
    [k.PROGRESS_VALUE]: `Värde`,
    [k.PROGRESS]: `Framsteg`,
    [k.PROMILLE]: `Promille`,
    [k.PROPERTIES]: `Egenskaper`,
    [k.PROPERTY_REQUIRED]: `Minst en egenskap är obligatoriskt`,
    [k.PROPERTY_WILL_BE_VISIBLE_IN_PARENT_LIST]: `Egenskapen kommer att vara synlig i listan över överordnade objekt`,
    [k.PUBLISH]: `Publicera`,
    [k.PUBLISHED_SHARED_STEPS]: `Publicerade delade steg`,
    [k.PUBLISHED_TEMPLATES]: `Publicerade mallar`,
    [k.PUBLISHED_WORKFLOWS]: `Publicerade mallar`,
    [k.PUBLISHED]: `Publicerad`,
    [k.PURCHASE_ORDER_ROW_STATUS_QUICK_INFORMATION]: `Du kan definiera unika namn för varje inköpsorderradstatus`,
    [k.PURCHASE_ORDERS_EDITOR_INFO]: `Kan se alla inköpsordrar, leverantörer och artiklar, lägga till nya inköpsordrar, skapa leverantörer och artiklar, redigera, ta bort, arkivera och avarkivera befintliga inköpsordrar.`,
    [k.PURCHASE_ORDERS_EDITOR]: `Inköpsordrar - Redigerare`,
    [k.PURCHASE_ORDERS_FEATURE_DESCRIPTION]: `Med den här funktionen aktiverad kan du hantera inköpsorder från ditt företag.`,
    [k.PURCHASE_ORDERS_OWNER_INFO]: `Kan se alla inköpsordrar, leverantörer och artiklar, lägga till nya inköpsordrar, skapa leverantörer och artiklar, redigera, ta bort, arkivera och avarkivera befintliga inköpsordrar.\nKan tilldela behörigheter för inköpsordrar.`,
    [k.PURCHASE_ORDERS_OWNER]: `Inköpsordrar - Ägare`,
    [k.PURCHASE_ORDERS_SUBTITLE]: `Här är alla inköpsorder som finns tillgängliga i systemet`,
    [k.PURCHASE_ORDERS_VIEWER_INFO]: `Kan se alla inköpsordrar, leverantörer och artiklar.`,
    [k.PURCHASE_ORDERS_VIEWER]: `Inköpsordrar - Läsare`,
    [k.PURPOSE]: `Syfte`,
    [k.QTY]: `Antal`,
    [k.QUALITY_DELIVERY_OF_PRODUCT]: `Kvalitet - Produktleverans`,
    [k.QUALITY_DELIVERY_OF_SERVICES]: `Kvalitet - Tjänsteleverans`,
    [k.QUALITY_PRODUCT_FUNCTIONALITY]: `Kvalitet - Produktfunktionalitet`,
    [k.QUALITY_PRODUCT_SAFETY]: `Produktsäkerhet`,
    [k.QUANTITY_TARGET]: `Mängdmål`,
    [k.QUARTER_S]: `Kvartal`,
    [k.QUARTERLY]: `Kvartalsvis`,
    [k.QUICK_INFORMATION]: `Snabbinformation`,
    [k.QUICK_RUN_NAME_PLACEHOLDER]: `Ex: Avstämning för Juli`,
    [k.RATING]: `Gradering`,
    [k.RE_OPEN]: `Återöppna`,
    [k.READ_LESS]: `Läs mindre...`,
    [k.READ_MORE]: `Läs mer...`,
    [k.READER]: `Läsare`,
    [k.READY_P]: `Aktiva`,
    [k.READY]: `Aktiv`,
    [k.REALISTIC]: `Realistisk?`,
    [k.RECORD]: `Poster`,
    [k.RECUR_EVERY]: `Upprepa varje`,
    [k.RECURRENCE_SHOULD_HAPPEN]: `Upprepningen ska vara`,
    [k.RECURRING_CHECKLIST_DELETE_DESCRIPTION]: `Att ta bort en upprepande checklista är den sista åtgärden du gör när något har gått fel. Om du vill spara referens till upprepande checklistan och dess checklistor bör du behålla den i 'arkiverat' tillstånd`,
    [k.RECURRING_CHECKLIST_DETAILS_SUBTITLE]: `Information om den upprepande checklistan`,
    [k.RECURRING_CHECKLIST_DETAILS]: `Information om upprepande checklistor`,
    [k.RECURRING_CHECKLIST_PAGE_SUBTITLE]: `Checklistor som upprepas med ett bestämt intervall. Expandera var och en för att se dess checklistor`,
    [k.RECURRING_CHECKLIST]: `Återkommande checklista`,
    [k.RED]: `Röd`,
    [k.REDIRECT]: `Omdirigera`,
    [k.REDIRECTING_TO_HOME_PAGE]: `Omdirigera till startsidan`,
    [k.REDUDANT_OPERATOR]: `Överflödig operatör`,
    [k.REF_PERSON]: `Ref. Person`,
    [k.REFERENCE_LINK]: `Referens länk`,
    [k.REFERENCE]: `Referens`,
    [k.REFRESH]: `Uppdatera`,
    [k.REGISTER_AND_REVIEW]: `Registrera och Granska`,
    [k.REGISTER_IMPROVEMENT_FOR]: `Registrera avvikelse för`,
    [k.REGISTER_IMPROVEMENT]: `Registrera avvikelse`,
    [k.REGISTER_NEW_IMPROVEMENT]: `Registrera Avvikelse`,
    [k.REGISTER]: `Registrera`,
    [k.REGISTERED]: `Registrerad`,
    [k.REGULAR_IMPROVEMENTS]: `Vanliga Avvikelser`,
    [k.REJECTED]: `Avvisade`,
    [k.RELEVANT_FOR_SUBPROCESS_ES]: `Relevant för delprocesser`,
    [k.REMEMBER_ME]: `Kom ihåg mig`,
    [k.REMOVE_FROM_TEMPLATE]: `Ta bort från mallen`,
    [k.REMOVE_SELF_STEP_INSTANCE_BODY1]: `Om du tar bort team `,
    [k.REMOVE_SELF_STEP_INSTANCE_BODY2]: ` kommer du inte längre vara del av detta steg.\n\nOm det här är det sista steget du är tilldelad  så kommer inte checklistan längre visas i checklistvyn.\n\nKontakta ansvarig mappare om du behöver bli tilldelad igen.`,
    [k.REMOVE_SELF_STEP_INSTANCE_TITLE]: `Ta bort dig själv?`,
    [k.REMOVE_SELF_STEP_INSTANCE_YES]: `Jag förstår konsekvenserna, ta bort mig`,
    [k.REMOVE]: `Ta bort`,
    [k.REMOVED_AS_INVOLVED_FROM]: `Tog bort som involverad från`,
    [k.REMOVED]: `Tog bort`,
    [k.REORDER]: `Ordna om`,
    [k.REPEAT_UNTIL]: `Upprepa tills`,
    [k.REPETITION_OF_OPERATORS]: `Upprepning av operatörer`,
    [k.REPLACE_FILE]: `Ersätt fil...`,
    [k.REPLACE]: `Ersätt`,
    [k.REPORT_NEW_IMPROVEMENT_OR_TRY_DIFFERENT_SEARCH_KEYWORDS]: `Rapportera ny avvikelse eller prova olika filter / sökord`,
    [k.REPORTED_BY]: `Rapporterade`,
    [k.REPORTED_FROM]: `Rapporterade`,
    [k.REPORTED_IMPROVEMENT]: `Rapporterad avvikelse`,
    [k.REPORTED_IMPROVEMENTS]: `Rapporterade avvikelser`,
    [k.REPORTED]: `Rapporterade`,
    [k.REPORTING_USERS_MANAGER]: `Rapporterande användares chef`,
    [k.REQUEST_TRANSLATION_MESSAGE]: `Hej! Jag skulle gärna vilja ha AmpliFlow på: `,
    [k.REQUEST_TRANSLATION]: `Saknas översättning?`,
    [k.REQUIRED]: `Obligatorisk`,
    [k.RESET_WONT_BE_SAVE_UNTIL_YOU_SAVE_SUB_PROCESS_PAGE]: `Återställningen kommer inte att sparas förrän du sparat subprocessen`,
    [k.RESET_ZOOM]: `Återställ Zoom`,
    [k.RESET]: `Återställa`,
    [k.RESOLVED_FULLY]: `Löst fullständigt`,
    [k.RESOLVED_PARTIALLY]: `Löst delvis`,
    [k.RESOURCES]: `Resurser`,
    [k.RESPONSE_DETAILS]: `Detaljer om svar`,
    [k.RESPONSIBLE_MANAGER]: `Ansvarig chef`,
    [k.RESPONSIBLE_USERS]: `Ansvariga Användare`,
    [k.RESPONSIBLE]: `Ansvarig`,
    [k.RESTORE]: `Återställ`,
    [k.RESTRICTED]: `Begränsad`,
    [k.RESULT]: `Resultat`,
    [k.RESULTS]: `Resultaten`,
    [k.RESUME]: `Återuppta`,
    [k.RETRY_MESSAGE]: `Försök igen`,
    [k.RETURN_TO]: `Återgå till`,
    [k.REVIEW_AND_SAVE_TIP1]: `Review your KPI and make any necessary changes. Once you are satisfied with your KPI, click on 'Save KPI' to save it and exit the wizard.`,
    [k.REVIEW_AND_SAVE]: `Granska och Spara`,
    [k.REVIEW_REPORTED_INFORMATION]: `Granska inrapporterad information`,
    [k.REVIEW]: `Granska`,
    [k.RISK_CLASSIFICATION_ENVIRONMENT]: `Riskklassificering miljö`,
    [k.RISK_NO]: `Risk nr`,
    [k.RISK_REDUCTION]: `Riskreducering`,
    [k.RISK_SCENARIO]: `Risk scenario`,
    [k.RISK_SCORE]: `Risktal`,
    [k.RISKS]: `Risker`,
    [k.ROLE_FROM_TEAM]: `Teamroll(er) från`,
    [k.ROLE]: `Roll`,
    [k.ROLES]: `Roller`,
    [k.RUN_EVERY]: `Starta varje`,
    [k.RUN_ID]: `Checkliste-ID`,
    [k.RUN_TEMPLATE_ON]: `Starta mall på`,
    [k.RUN]: `Starta`,
    [k.RUNS_DONE]: `Checklistor låsta`,
    [k.RUNS_OF_THE_TEMPLATE]: `Checklistor för mallen`,
    [k.SA_EDITOR_INFO]: `Kan se, lägga till och redigera alla utvärderingar.`,
    [k.SA_EDITOR]: `Medarbetarsamtal - Redigerare`,
    [k.SA_OWNER_INFO]: `Kan se, lägga till och redigera alla utvärderingar\nKan tilldela behörigheter för Medarbetarsamtal.`,
    [k.SA_OWNER]: `Medarbetarsamtal - Ägare`,
    [k.SA_USER_INFO]: `Kan se, lägga till, redigera och arkivera sina egna utvärderingar, och för de användare de är chef för.\nDe kan lägga till, redigera och arkivera utvärderingar där de är valda som utvärderare.`,
    [k.SA_USER]: `Medarbetarsamtal - Användare`,
    [k.SALES_]: `försäljning`,
    [k.SATURDAY]: `Lördag`,
    [k.SAVE_ACTIVITY]: `Spara aktivitet`,
    [k.SAVE_AND_CLOSE]: `Spara och stäng`,
    [k.SAVE_AND_PUBLISH]: `Publicera`,
    [k.SAVE_AS_DRAFT]: `Spara utkast`,
    [k.SAVE_DRAFT]: `Spara utkast`,
    [k.SAVE_FAILED]: `Spara misslyckades`,
    [k.SAVE]: `Spara`,
    [k.SAVED_VALUES]: `Sparade värden`,
    [k.SAVING]: `Spara`,
    [k.SCAN_BARCODE]: `Skanna Streckkod`,
    [k.SCAN_OR_ENTER_BARCODE]: `Skanna eller ange streckkod`,
    [k.SCHEDULE_]: `schema`,
    [k.SCHEDULE_COMMENT]: `Schemakommentar`,
    [k.SCHEDULE_ENDS]: `Schemat slutar`,
    [k.SCHEDULE_INFORMATION]: `Schemainformation`,
    [k.SCHEDULE_OPTIONAL_COMMENT_MESSAGE]: `Lämna eventuellt en kommentar för schemat vilket kan göra det enklare att identifiera den när du visar flera upprepande checklistor`,
    [k.SCHEDULE_PAUSED_BY]: `Schema pausat av`,
    [k.SCHEDULE_RUNS_ON]: `Schemat startas på`,
    [k.SCHEDULE_START]: `Schemastart`,
    [k.SCHEDULE_TYPE]: `Schematyp`,
    [k.SCHEDULE]: `Schema`,
    [k.SCHEDULED_]: `planerad`,
    [k.SCHEDULED_COURSES]: `Planerade Kurser`,
    [k.SCHEDULED]: `Planerad`,
    [k.SCOPE_OF_MANAGEMENT]: `Omfattning av ledningsystem`,
    [k.SCORE_EXPLANATION]: `Förklaring av Poäng`,
    [k.SEARCH_CHECKLIST_TEMPLATE]: `Sök bland checklistmallar`,
    [k.SEARCH_CHECKLIST_TITLE]: `Sök checklistor efter titel`,
    [k.SEARCH_HERE]: `Sök här`,
    [k.SEARCH_IMPROVEMENT_FORM]: `Sök bland avvikelsemallar`,
    [k.SEARCH_MESSAGE]: `Sök i meddelanden`,
    [k.SEARCH_NAME_AND_STATUS]: `Sök efter namn och status`,
    [k.SEARCH_NAME_OR_TEMPLATE]: `Checklistans eller mallens namn`,
    [k.SEARCH_TEXTFIELDS]: `Sök i textfält`,
    [k.SEARCH]: `Sök`,
    [k.SECOND_]: `andra`,
    [k.SECOND]: `Andra`,
    [k.SEE_ALL]: `Se alla`,
    [k.SELECT_A_SUPPLIER_FIRST]: `Välj en leverantör först!`,
    [k.SELECT_BASE_FOR_KPI]: `Välj vad du vill göra KPI för`,
    [k.SELECT_CHECKLIST_TEMPLATE]: `Välj mall för Checklistan`,
    [k.SELECT_FILE]: `Välj fil...`,
    [k.SELECT_FROM_LIBRARY]: `Välj från biblioteket`,
    [k.SELECT_IMPROVEMENT_FORM]: `Välj mall för Avvikelsen`,
    [k.SELECT_ITEM_TO_VIEW_THIS_FIELD]: `Välj ett objekt för att visa detta fält`,
    [k.SELECT_KPI_TYPE]: `Välj typ av KPI`,
    [k.SELECT_KPI]: `Välj KPI`,
    [k.SELECT_LATEST_VERSION]: `Välj senaste versionen`,
    [k.SELECT_STEPS_FOR_TEMPLATE]: `Skapa nytt steg eller lägg till existerande steg från biblioteket`,
    [k.SELECT_STEPS]: `Välj steg`,
    [k.SELECT_TEMPLATE]: `Välj mall`,
    [k.SELECT_USER_TO_SHOW_PROFILE]: `Välj användare för att visa deras profil`,
    [k.SELECT_USERS]: `Välj användare`,
    [k.SELECT]: `Välj`,
    [k.SELECTED_SCHEDULE_PREVIEW]: `Förhandsgranskning av markerat schema`,
    [k.SELECTED_WEEK_DAYS_]: `valda veckodagar`,
    [k.SEND_TEST_REQUEST]: `Skicka begäran om test`,
    [k.SEND]: `Skicka`,
    [k.SEP]: `Sep`,
    [k.SEPTEMBER]: `September`,
    [k.SET_RESPONSIBLE_TIP1]: `Lägg till användare och team som kommer att ansvara för denna KPI oavsett de allmänna KPI-behörigheterna. Du kan ta bort dem när som helst.`,
    [k.SET_RESPONSIBLE]: `Sätt Ansvarig`,
    [k.SET_TARGETS_TIP1]: `Ange målvärdena för varje månad. Ange om att överskrida målet anses vara bra eller dåligt.`,
    [k.SET_TARGETS_TIP2]: `När du anger ett värde för en månad, kommer det att upprepas som standard för kommande månader och år. Du kan alltid uppdatera värdet manuellt.`,
    [k.SET_TARGETS]: `Sätt Mål`,
    [k.SETTINGS]: `Inställningar`,
    [k.SHARED_STEP]: `Delat steg`,
    [k.SHARED_TEMPLATE_STEPS_ONCE_FEATURE_DESCRIPTION]: `Spara vanliga steg och lägg till dem i flera checklistmallar.`,
    [k.SHARED]: `Delade `,
    [k.SHIPPING_COUNTRY]: `Leveransland`,
    [k.SHIPPING_STATE_OR_PROVINCE]: `Leveransstat/provins`,
    [k.SHIPPING]: `Leverans`,
    [k.SHOW_ALL]: `Visa alla`,
    [k.SHOW_ARCHIVED]: `Visa arkiverade`,
    [k.SHOW_COMPLETED]: `Visa avslutade`,
    [k.SHOW_DRAFT]: `Visa utkast`,
    [k.SHOW_DRAFTS]: `Visa utkast`,
    [k.SHOW_IN_LIST]: `Visa i listvyn`,
    [k.SHOW_IN_PROCESS_CHART]: `Visa i processdiagrammet`,
    [k.SHOW_LIST]: `Visa lista`,
    [k.SHOW_PUBLISHED]: `Visa publicerad`,
    [k.SHOW_REFERENCED_PROPERTY_IN_PARENT_LIST]: `Visa den refererade egenskapen i listan över överordnade egenskaper`,
    [k.SHOW_SIDEBAR]: `Visa sidofältet`,
    [k.SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED]: `Visar alla utom arkiverade eller schemalagda`,
    [k.SHOWING_ALL_EXCEPT_SCHEDULED]: `Visar alla utom schemalagd`,
    [k.SHOWING_IMPROVEMENTS_FOR]: `Visar avvikelser för`,
    [k.SHOWING_RECORDS]: `Visar poster`,
    [k.SHOWING]: `Visning`,
    [k.SIGNIFICANT_ENVIRONMENTAL_ASPECT]: `Betydande miljöaspekt?`,
    [k.SINGLE_AD_HOC_CHECKLIST]: `Enkel ad hoc-checklista`,
    [k.SO_FAR]: `Hittills`,
    [k.SORTABLE]: `Sorterbar`,
    [k.SPECIFIC_CUSTOMER_S_WHO_REQUIRE_THIS]: `Specifika kund(er) som kräver detta`,
    [k.SPLIT]: `Split`,
    [k.SSO_PAGE_TITLE]: `SSO Hantera`,
    [k.SSO_FEATURE_DESCRIPTION]: `Genom att aktivera den här funktionen kan du aktivera SSO, så att användare kan logga in på AmpliFlow med sina befintliga referenser.`,
    [k.SSO]: `SSO`,
    [k.STAFF_APPRAISAL_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av personalutvärderingar.`,
    [k.STAFF_APPRAISAL_PAGE_SUBTITLE]: `Här är alla medarbetarsamtal som finns tillgängliga i systemet`,
    [k.STAFF_APPRAISAL_PAGE_TITLE]: `Medarbetarsamtal`,
    [k.STAKEHOLDERS_EDITOR_INFO]: `Kan se alla intressenter, lägga till nya intressenter, redigera, ta bort, arkivera och avarkivera befintliga intressenter.`,
    [k.STAKEHOLDERS_EDITOR]: `Intressenter - Redigerare`,
    [k.STAKEHOLDERS_FEATURE_DESCRIPTION]: `Med den här funktionen aktiverad kan du hantera identifierade intressenter för varje ledningsstandard som är relevant för ditt företag.`,
    [k.STAKEHOLDERS_OWNER_INFO]: `Kan se alla intressenter, lägga till eller importera nya intressenter, redigera, ta bort, arkivera och avarkivera befintliga intressenter.\nKan tilldela behörigheter för intressenter.`,
    [k.STAKEHOLDERS_OWNER]: `Intressenter - Ägare`,
    [k.STAKEHOLDERS_SUBTITLE]: `Identifierade intressenter för varje ledningssystem standard som är relevant för oss`,
    [k.STAKEHOLDERS_VIEWER_INFO]: `Kan se alla intressenter.`,
    [k.STAKEHOLDERS_VIEWER]: `Intressenter - Läsare`,
    [k.STAKEHOLDERS]: `Intressenter`,
    [k.STANDARD]: `Standard`,
    [k.START_CHECKLIST]: `Starta Checklista`,
    [k.START_DATE_GREATER_THAN_END_DATE]: `Startdatum är större än slutdatum`,
    [k.START_DATE_TIME]: `Start`,
    [k.START_DATE]: `Startdatum`,
    [k.START_FIRST_RUN_AT_HINT_MESSAGE]: `* Du kan ändra varje enskild checklistas startdag och tid efter att schemat är publicerat`,
    [k.START_FIRST_RUN_AT]: `Börja första checklistan vid`,
    [k.START_MY_FREE_TRAIL]: `Starta Min gratis provperiod`,
    [k.START_THIS]: `Starta`,
    [k.START_TYPING_TO_SELECT_OR_CREATE]: `Börja skriva för att välja / skapa`,
    [k.START_VALUE]: `Startvärde`,
    [k.START_YOUR_FREE_14DAY_TRAIL]: `Starta din kostnadsfria 14-dagars testperiod`,
    [k.START]: `Start`,
    [k.STARTED_AT]: `Startad`,
    [k.STARTED_BY]: `Startad av`,
    [k.STARTED]: `Startad`,
    [k.STARTING_]: `startar...`,
    [k.STARTING]: `Startar...`,
    [k.STARTS_AT]: `Startar den`,
    [k.STATE_OR_PROVINCE]: `Stat/Provins`,
    [k.STATIC_ACTION_PLAN_INDEX]: `Statisk handlingsplan index`,
    [k.STATIC_ACTION_PLAN_LABEL]: `Statisk handlingsplan etikett`,
    [k.STATIC_ACTION_PLAN]: `Statisk handlingsplan`,
    [k.STATIC_NUMBER_VALUE_IS_REQUIRED]: `Statiskt nummervärde krävs`,
    [k.STATIC_NUMBERS]: `Statiska siffror`,
    [k.STATUS_COMMENT]: `Statuskommentar`,
    [k.STATUS_LABEL]: `Status`,
    [k.STATUS_NAME]: `Statusnamn`,
    [k.STATUS]: `Status`,
    [k.STEP_CONFIGURATION]: `Stegkonfiguration`,
    [k.STEP_DELETED]: `Steg bortaget`,
    [k.STEP_DETAILS]: `Stegdetaljer`,
    [k.STEP_DRAFT_SAVED]: `Stegutkast sparat`,
    [k.STEP_FINALIZED_BY]: `Det här steget låstes av`,
    [k.STEP_HISTORY]: `Steghistorik`,
    [k.STEP_NAME_REQUIRED]: `Stegnamn krävs`,
    [k.STEP]: `Steg`,
    [k.STEPS_DONE]: `Låsta Steg`,
    [k.STEPS_REQUIRED]: `Minst ett steg krävs`,
    [k.STEPS_SUBTITLE]: `Delade steg är förbestämda steg som du kan återanvända i flera olika mallar`,
    [k.STEPS]: `Steg`,
    [k.STOP_EDITING]: `Stoppa redigeringen`,
    [k.STOP_MATCHING_CHECKLISTS_LINE1]: `Om du klickar på stopp stoppas alla matchande checklistor för söktermen / filtren direkt.`,
    [k.STOP_MATCHING_CHECKLISTS_LINE2]: `Är du verkligen säker på att du vill stoppa alla matchande checklistor för denna fråga?`,
    [k.STOP_MATCHING_CHECKLISTS_QUESTION]: `Sluta Matcha Checklistor?`,
    [k.STOP_MATCHING_CHECKLISTS]: `Stoppa matchande checklistor`,
    [k.STOP_MULTIPLE_CHECKLISTS_AT_ONCE]: `Stoppa flera checklistor samtidigt`,
    [k.STOP_MULTIPLE_CHECKLISTS_AT_ONCE_FEATURE_DESCRIPTION]: `Tillåt användare att stoppa flera checklistor samtidigt i checklistrapporten för bättre hantering av kritiska händelser som påverkar flera aktiva checklistor.`,
    [k.STOP]: `Stoppa`,
    [k.STOPPED_]: `stoppad`,
    [k.STOPPED_BY]: `Stoppad av`,
    [k.STOPPED_IN_THE_LAST_7_DAYS]: `Stoppad under de senaste 7 dagarna`,
    [k.STOPPED_P]: `Stoppade`,
    [k.STOPPED]: `Stoppad`,
    [k.SUB_GOAL_DELETED]: `Delmål utgår`,
    [k.SUB_GOAL]: `Delmål`,
    [k.SUB_GOALS]: `Delmål`,
    [k.SUB_PROCESS_MARKED_DELETE]: `Subprocess eller dess underliggande processer är markerade som bortagen och kan inte editeras`,
    [k.SUB_PROCESS_NOT_FOUND]: `Subprocessen kunde inte hittas`,
    [k.SUB_PROCESS]: `Subprocess`,
    [k.SUBJECT_AREA]: `Ämnesområde`,
    [k.SUBMIT_IMPOVEMENT]: `Skicka in avvikelse`,
    [k.SUBMIT_NOTICE_WITH_USER_TO_NOTIFY]: `Skicka in avvikelsen för att spara och skicka en avisering till `,
    [k.SUBMIT_NOTICE]: `Skicka in avvikelsen för att spara`,
    [k.SUBMIT]: `Skicka`,
    [k.SUCCESS_MESSAGE]: `Framgång`,
    [k.SUGGESTED_ENVIRONMENTAL_TARGET]: `Hanteras i följande process-/er`,
    [k.SUMMARY_OF_HOW_WE_MEET_THE_REQUIREMENT]: `Sammanfattning över hur vi uppfyller kravet`,
    [k.SUMMARY_OF_LEGISLATION]: `Summering av lagkrav`,
    [k.SUMMARY_OF_THE_REQUIREMENT_AND_OR_MOTIVATION_WHY_IT_IS_NOT_RELEVANT_TO_US]: `Sammanfattning av kravet och/eller motivation varför det inte är relevant för oss`,
    [k.SUMMARY]: `Sammanfattning`,
    [k.SUNDAY]: `Söndag`,
    [k.SUPPLIER_DETAILS_SUBTITLE]: `Visa detaljer och kontakter för den valda leverantören`,
    [k.SUPPLIER_DETAILS]: `Leverantörsinformation`,
    [k.SUPPLIER_ID]: `Leverantör ID`,
    [k.SUPPLIER_LABEL]: `Leverantörsetikett`,
    [k.SUPPLIER_PLACEHOLDER]: `Leverantörs platshållare`,
    [k.SUPPLIER]: `Leverantör`,
    [k.SUPPLIERS_EDITOR_INFO]: `Kan se alla leverantörer och kontakter, lägga till nya leverantörer och kontakter, redigera, ta bort, arkivera och avarkivera befintliga leverantörer och kontakter.`,
    [k.SUPPLIERS_EDITOR]: `Leverantörer - Redigerare`,
    [k.SUPPLIERS_FEATURE_DESCRIPTION]: `Med den här funktionen aktiverad kan du hålla reda på dina leverantörer och dina kontakter.`,
    [k.SUPPLIERS_OWNER_INFO]: `Kan se alla leverantörer och kontakter, lägga till eller importera nya leverantörer och kontakter, redigera, ta bort, arkivera och avarkivera befintliga leverantörer och kontakter.\nKan tilldela behörigheter för leverantörer och kontakter.`,
    [k.SUPPLIERS_OWNER]: `Leverantörer - Ägare`,
    [k.SUPPLIERS_SUBTITLE]: `Här är alla tillgängliga leverantörer i systemet`,
    [k.SUPPLIERS_VIEWER_INFO]: `Kan se alla leverantörer och kontakter.`,
    [k.SUPPLIERS_VIEWER]: `Leverantörer - Läsare`,
    [k.SWEDISH_NAME]: `Svenskt namn`,
    [k.SWEDISH]: `Svenska`,
    [k.SYSTEM_PROPERTIES_MESSAGE]: `Detta är nödvändiga systemegenskaper som inte kan ändras.`,
    [k.SYSTEM_PROPERTIES]: `Systemegenskaper`,
    [k.SYSTEM]: `System`,
    [k.TAKE_PICTURE]: `Ta bild`,
    [k.TARGET_PREFERENCE]: `Målinställning`,
    [k.TARGET_UNIT]: `Enhet`,
    [k.TARGET_URL]: `Mål-URL`,
    [k.TARGET_VALUE]: `Mål-värde`,
    [k.TARGET]: `Mål`,
    [k.TARGETS]: `Mål`,
    [k.TASK_COMPLETED]: `Uppgift slutförd`,
    [k.TASK_CREATED]: `Uppgift skapad`,
    [k.TASK_INDEX]: `Uppgiftsindex`,
    [k.TASK_LABEL]: `Uppgiftsetikett`,
    [k.TASK_LIST_INDEX]: `Uppgiftslista index`,
    [k.TASK_LIST_LABEL]: `Uppgiftslista etikett`,
    [k.TASK_LIST]: `Uppgiftslista`,
    [k.TASK_SUBTITLE]: `Uppgifter som är tillgängliga för dig`,
    [k.TASK]: `Uppgift`,
    [k.TASKS_ASSIGNED_TO_YOU]: `Uppgifter tilldelade dig`,
    [k.TASKS_FEATURE_DESCRIPTION]: `Denna funktion ger användarna tillgång till Uppgifter-sidan där de kan se och hantera uppgifter som tilldelats dem. Användare kommer att se uppgifter från Förbättringar, checklistor och mål.`,
    [k.TASKS]: `Uppgifter`,
    [k.TEAM_CAN_VIEW]: `Team som kan se`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_CHECKLISTS]: `Teamet kan inte raderas eftersom det för närvarande används av dessa checklistor:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_GOALS]: `Teamet kan inte raderas eftersom det för närvarande används av dessa mål:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_KPIS]: `Teamet kan inte raderas eftersom det för närvarande används av dessa KPI:er:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_MEASUREMENTS]: `Teamet kan inte raderas eftersom det för närvarande används av dessa mätningar:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_PROCESSES]: `Teamet kan inte raderas eftersom det för närvarande används av dessa processer:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_STEPS]: `Teamet kan inte raderas eftersom det för närvarande används av dessa steg:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_SUBPROCESSES]: `Teamet kan inte raderas eftersom det för närvarande används av dessa underprocesser:`,
    [k.TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_TEMPLATES]: `Teamet kan inte raderas eftersom det för närvarande används av dessa mallar:`,
    [k.TEAM_DELETED]: `Team raderat`,
    [k.TEAM_DETAILS_SUBTITLE]: `Visa detaljer för valda teamet`,
    [k.TEAM_DETAILS]: `Teamdetaljer`,
    [k.TEAM_IS_USED_BY_THESE_CHECKLISTS]: `Teamet används av dessa checklistor:`,
    [k.TEAM_IS_USED_BY_THESE_GOALS]: `Teamet används av dessa mål:`,
    [k.TEAM_IS_USED_BY_THESE_KPIS]: `Teamet används av dessa KPI:er:`,
    [k.TEAM_IS_USED_BY_THESE_MEASUREMENTS]: `Teamet används av dessa mätningar:`,
    [k.TEAM_IS_USED_BY_THESE_PROCESSES]: `Teamet används av dessa processer:`,
    [k.TEAM_IS_USED_BY_THESE_STEPS]: `Teamet används av dessa steg:`,
    [k.TEAM_IS_USED_BY_THESE_SUBPROCESSES]: `Teamet används av dessa underprocesser:`,
    [k.TEAM_IS_USED_BY_THESE_TEMPLATES]: `Teamet används av dessa mallar:`,
    [k.TEAM_REQUIRED]: `Minst ett team är obligatoriskt`,
    [k.TEAM_ROLES]: `Teamroller`,
    [k.TEAM_SUBTITLE]: `Här är alla team tillgängliga i systemet`,
    [k.TEAM]: `Team`,
    [k.TEAMS_EDITOR_INFO]: `Kan se alla lag, lägga till nya team, redigera, ta bort, arkivera och avarkivera befintliga team.`,
    [k.TEAMS_EDITOR]: `Team - Redigerare`,
    [k.TEAMS_OWNER_INFO]: `Kan se alla team, lägga till eller importera nya team, redigera, ta bort, arkivera och avarkivera befintliga team.\nKan tilldela behörigheter för team.\nKan tilldela behörigheter för team.`,
    [k.TEAMS_OWNER]: `Team - Ägare`,
    [k.TEAMS_VIEWER_INFO]: `Kan se alla icke-arkiverade team.`,
    [k.TEAMS_VIEWER]: `Team - Läsare`,
    [k.TEAMS_WHO_CAN_UPDATE_TARGETS]: `Team som kan uppdatera mål`,
    [k.TEAMS_WHO_WILL_WORK_ON_THIS_STEP]: `Team som kommer att arbeta med detta steg`,
    [k.TEAM_WILL_BE_REMOVED]: `Teamet kommer att tas bort`,
    [k.TEAMS]: `Team`,
    [k.TEMPLATE_DELETE_DESCRIPTION]: `Att ta bort en mall är den sista åtgärden du vidtar när något har gått fel, om du vill föra register över checklistan av någon anledning bör du hålla den i "arkiverad" tillstånd`,
    [k.TEMPLATE_DELETED]: `Mall raderad`,
    [k.TEMPLATE_DETAILS]: `Malldetaljer`,
    [k.TEMPLATE_FORM_ERRORS]: `Fel i mallen`,
    [k.TEMPLATE_INFORMATION]: `Mallinformation`,
    [k.TEMPLATE_LANGUAGE]: `Mallspråk`,
    [k.TEMPLATE_NAME_REQUIRED]: `Mallnamn krävs`,
    [k.TEMPLATE_NAME]: `Mallnamn`,
    [k.TEMPLATE_TO_SCHEDULE]: `Mall att schemalägga`,
    [k.TEMPLATE]: `Mall`,
    [k.TEMPLATES_SUBTITLE]: `Här är alla tillgängliga mallar i systemet`,
    [k.TEMPLATES]: `Mallar`,
    [k.TENANT_NAME_ALREADY_EXISTS]: `Adressen finns redan`,
    [k.TENANT_NAME_DESCRIPTION_NOTE]: `OBS: Om du ändrar denna adress slutar den aktuella adressen att fungera för alla användare omedelbart.`,
    [k.TENANT_NAME_DESCRIPTION]: `Webbplatsadressen är unik för din organisation. Detta är adressen du och dina medarbetare använder för att komma åt AmpliFlow.`,
    [k.TENANT_NAME]: `Webbplatsadress`,
    [k.TENANT_SETTINGS]: `Inställningar`,
    [k.TENANT_SETTINGS_PAGE_SUBTITLE]: `Konfigurationsalternativ för hyresgästen`,
    [k.TEST_REQUEST]: `Begäran om test`,
    [k.TEXT_FILTER]: `Textfilter`,
    [k.TEXT]: `Text`,
    [k.THAT_]: `som`,
    [k.THAT]: `Som`,
    [k.THE_]: `den`,
    [k.THE_USER_WONT_BE_NOTIFIED_ABOUT_THEIR_ACCOUNT_INFORMATION]: `Användaren kommer inte att meddelas om förändringar av deras konto`,
    [k.THERE_ARE_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_LEAVE]: `Det finns ändringar som inte sparats. Är du säker på att du vill lämna?`,
    [k.THERE_ARE_UNSAVED_CHANGES]: `Det finns ändringar som inte sparats`,
    [k.THERE_IS_NO_UNDO]: `Det finns inget att ångra för denna åtgärd!`,
    [k.THIRD_]: `tredje`,
    [k.THIRD]: `Tredje`,
    [k.THIS_ACTIVITY_HAS]: `Denna aktivitet har`,
    [k.THIS_IMPOVEMENT_IS_CLOSED]: `Denna avvikelse är stängd.`,
    [k.THIS_IS_CONTENT_OF_MOBILE_SIDEBAR]: `Detta är innehållet i mobila sidofältet`,
    [k.THIS_MONTH]: `Den här månaden`,
    [k.THIS_REPORT_WAS_GENERATED_BY]: `Den här rapporten har skapats via`,
    [k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP_YOU_DONT_HAVE_PERMISSION_TO_SEE]: `Det här steget blockeras av ett föregående steg som du inte har tillstånd att se`,
    [k.THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP]: `Detta steg blockeras av föregående steg`,
    [k.THIS_STEP_IS_BLOCKING_ALL_FOLLOWING_STEPS_UNTIL_IT_HAS_BEEN_FINALIZED]: `Detta steg blockerar alla följande steg tills det har slutförts`,
    [k.THIS_STEP_IS_COMPLETED]: `Detta steg är slutfört.`,
    [k.THIS_STEP_IS_FINALIZED]: `Detta steg är låst.`,
    [k.THIS_WEEK]: `Denna vecka`,
    [k.THRESHOLD_TYPE]: `Tröskeltyp`,
    [k.THRESHOLD]: `Tröskel`,
    [k.THURSDAY]: `Torsdag`,
    [k.TIME]: `Tid`,
    [k.TIMEFRAME_DESCRIPTION]: `Tidsperiod under vilket detta mätvärde måste uppnås.`,
    [k.TIMEFRAME]: `Tidsram`,
    [k.TIPS]: `Tips`,
    [k.TITLE]: `Titel`,
    [k.TO_]: `till`,
    [k.TO_ADD_NEW_ONE_]: `för att lägga till en ny.`,
    [k.TO_COMPLETE]: `att slutföra`,
    [k.TO_START_CLICK_ON_ADD_NEW_BUTTON]: `För att starta klicka på Lägg till ny knapp`,
    [k.TO]: `Till,`,
    [k.TODAY]: `I dag`,
    [k.TOMORROW]: `I morgon`,
    [k.TOS]: `TOS`,
    [k.TOTAL_IMPACT_RATING]: `Total påverkan`,
    [k.TOTAL_VALUES_FOR_ALL]: `Totala värden för alla`,
    [k.TOTAL]: `Total`,
    [k.TRAINING_ACTIVITY]: `Utbildning`,
    [k.TRAINING_PLAN_PAGE_SUBTITLE]: `Här är alla utbildningsplaner som finns tillgängliga i systemet`,
    [k.TRAINING_PLAN_PAGE_TITLE]: `Utbildningsplan`,
    [k.TRAINING_PLANS_EDITOR_INFO]: `Kan se alla utbildningsplaner, lägga till nya utbildningsplaner, redigera, ta bort, arkivera och avarkivera befintliga utbildningsplaner.`,
    [k.TRAINING_PLANS_EDITOR]: `Utbildningsplaner - Redigerare`,
    [k.TRAINING_PLAN_FEATURE_DESCRIPTION]: `Denna funktion möjliggör hantering av utbildningsplaner.`,
    [k.TRAINING_PLANS_OWNER_INFO]: `Kan se alla utbildningsplaner, lägga till eller importera nya utbildningsplaner, redigera, ta bort, arkivera och avarkivera befintliga utbildningsplaner.\nKan tilldela behörigheter för utbildningsplaner.`,
    [k.TRAINING_PLANS_OWNER]: `Utbildningsplaner - Ägare`,
    [k.TRAINING_PLANS_VIEWER_INFO]: `Kan se alla utbildningsplaner.`,
    [k.TRAINING_PLANS_VIEWER]: `Utbildningsplaner - Läsare`,
    [k.TRY_ALL_FEATURES_FOR_FREE_NO_CREDIT_CARD_REQUIRED]: `Prova alla funktioner gratis. Inget kreditkort krävs`,
    [k.TRY_DIFFERENT_FILTER_KEYWORDS]: `Prova olika filter / sökord`,
    [k.TUESDAY]: `Tisdag`,
    [k.TYPE_OF_INTEREST]: `Typ av intresse`,
    [k.TYPE_OF_LEGISLATION]: `Lagstiftningstyp`,
    [k.TYPE]: `Typ`,
    [k.UN_ARCHIVE_IMPACT_GRADING]: `Om du återställer en arkiverad påverkansgrad kommer alla förbättringar och risker som graderats att få sina graderingar återställda.`,
    [k.UN_ARCHIVE_POSITION]: ` Om du återställer befattningen så kommer den att kunna tilldelas användare igen.`,
    [k.UN_ARCHIVE]: `Återställ`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY1]: ` från team `,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY2]: ` är tilldelade aktiviteter i detta steg. Om du tar bort teamet försvinner dessa tilldelningar.`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_TITLE]: `Tilldelning kommer tas bort`,
    [k.UN_ASSIGN_USERS_STEP_INSTANCE_YES]: `Jag förstår konsekvenserna, ta bort team`,
    [k.UNARCHIVE_THIS_AREA]: `Återställ detta område?`,
    [k.UNARCHIVE_THIS_GOAL]: `Återställ detta mål?`,
    [k.UNARCHIVE_THIS_KPI]: `Återställ denna KPI?`,
    [k.UNARCHIVE_THIS_MEASUREMENT]: `Återställ detta mätvärde?`,
    [k.UNARCHIVE_THIS_POSITION]: `Återställ denna befattning?`,
    [k.UNARCHIVE_THIS_PROGRESS_VALUE]: `Återställ detta värde?`,
    [k.UNASSIGNED]: `Otilldelad`,
    [k.UNCATEGORIESED]: `Okategoriserat`,
    [k.UNDER_TARGET_IS_GOOD]: `Under mål är bra`,
    [k.UNDER_THRESHOLD]: `Under tröskel`,
    [k.UNDO]: `Ångra`,
    [k.UNFINALIZATION_OF_STEPS]: `Lås upp steg`,
    [k.UNFINALIZATION_OF_STEPS_FEATURE_DESCRIPTION]: `Tillåt användare att avsluta ett steg. En förklaring av orsaken kommer att krävas under återföringsprocessen.`,
    [k.UNFINALIZE_STEP_CONFIRMATION_MESSAGE]: `Ange orsaken till att du låser upp steget. Anledningen som skrivs här kommer att sparas i historiken för alla som har tillgång till checklistan.`,
    [k.UNFINALIZE]: `Lås upp`,
    [k.UNFINALIZED_STEP_WITH_REASON]: `Steg upplåst med anledning`,
    [k.UNIT]: `Enhet`,
    [k.UNKNOWN]: `Okänd`,
    [k.UNTIL]: `Till`,
    [k.UPCOMING_STEPS]: `Kommande steg`,
    [k.UPDATE_AVAILABLE]: `Uppdatering tillgänglig`,
    [k.UPDATE_END_DATE]: `Slutdatum för uppdatering`,
    [k.UPDATE_PROGRESS]: `Uppdatera framsteg`,
    [k.UPDATE_SUCCESSEDED]: `Uppdateringen lyckades`,
    [k.UPDATE_VALUE]: `Uppdatera värde`,
    [k.UPDATED_AT]: `Uppdaterad`,
    [k.UPDATED_BY]: `Uppdaterad Av`,
    [k.UPDATED_PROBABILITY]: `Uppdaterad sannolikhet`,
    [k.UPDATED_RISK_SCORE]: `Uppdaterat risktal`,
    [k.UPDATED]: `Uppdaterad`,
    [k.UPDATES]: `Uppdateringar`,
    [k.UPGRADE_CHECKLISTS_TITLE]: `Checklistor för uppgradering?`,
    [k.UPLOAD_PHOTO]: `Ladda upp foto`,
    [k.UPLOAD]: `Ladda upp`,
    [k.UPLOADED_FILES_FOR_THE_RUN]: `Uppladdade filer och foton`,
    [k.URL]: `Url`,
    [k.USE_SHIPPING_ADDRESS]: `Använd leveransadress`,
    [k.USE]: `Använd`,
    [k.USER_ALLOW_COMPLETE_OTHES_TASKS]: `Användaren kommer inte att kunna slutföra andra steg efter detta, om detta är markerat som blockerande.`,
    [k.USER_ALLOW_SELECT_MULTI]: `Användaren kan lägga till/välja flera värden`,
    [k.USERS_AND_TEAMS_WITH_ACCESS]: `Användare och team med åtkomst`,
    [k.USER_CAN_VIEW]: `Användaren kan se`,
    [k.USER_DEFINED]: `Användardefinierad`,
    [k.USER_DETAILS_SUBTITLE]: `Visa detaljer för de valda användarna`,
    [k.USER_DETAILS]: `Användarinformation`,
    [k.USER_EDITOR_INFO]: `Kan se alla användare, lägga till nya användare, redigera, ta bort, arkivera och avarkivera befintliga användare.`,
    [k.USER_EDITOR]: `Användare - Redigerare`,
    [k.USER_EMAIL]: `E-post för användare`,
    [k.USER_ID]: `Användar ID`,
    [k.USER_NAME]: `Användarnamn`,
    [k.USER_OWNER_INFO]: `Kan se alla användare, lägga till eller importera nya användare, redigera, ta bort, arkivera och avarkivera befintliga användare.`,
    [k.USER_OWNER]: `Användare - Ägare`,
    [k.USER_ROLES]: `Avändarroller`,
    [k.USER_TASKS_FEATURE_DESCRIPTION]: `Denna funktion gör det möjligt för användare att lägga till nya uppgifter till eventuella förbättringar, checklistor, anpassade listor och medarbetarsamtal som de har tillgång till som redan innehåller en uppgiftslista. Användardefinierade uppgifter kommer att innehålla funktioner som motsvarar de för de fördefinierade checklistuppgifterna, inklusive alternativ för att tilldela uppgifter till andra användare och rapportera förbättringar.`,
    [k.USER_TASKS]: `Användardefinierade uppgifter`,
    [k.USER_VIEWER_INFO]: `Kan se alla användare.`,
    [k.USER_VIEWER]: `Användare - Läsare`,
    [k.USER]: `Användare`,
    [k.USERS_ASSIGNED_TO_AN_ACTIVITY]: `Användare tilldelade till aktiviteter`,
    [k.USERS_CANNOT_BE_NOTIFIED_AND_NOT_NOTIFIED_AT_THE_SAME_TIME]: `Användare kan inte meddelas och inte meddelas samtidigt`,
    [k.USERS_IN_THE_SYSTEM]: `Användare i systemet`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_SUB_PROCESS]: `Användare ansvariga för de valda upptäckta i underprocessen`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS_STEP]: `Användare ansvariga för de valda upptäckta i processsteget`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS]: `Användare ansvariga för de valda upptäckta i processen`,
    [k.USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_SUB_PROCESS]: `Användare ansvariga för de valda upptäckta i underprocessen`,
    [k.USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS_STEP]: `Användare ansvariga för de valda upptäckta i processsteget`,
    [k.USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS]: `Användare ansvariga för de valda upptäckta i processen`,
    [k.USERS_SUBTITLE]: `Här är alla användare tillgängliga i systemet`,
    [k.USERS_WHO_CAN_UPDATE_TARGETS]: `Användare som kan uppdatera mål`,
    [k.USERS]: `Användare`,
    [k.VALIDATE]: `Validera`,
    [k.VALUE_IS_BETWEEN_THRESHOLDS_OF]: `Värdet är mellan trösklarna`,
    [k.VALUE_IS_BETWEEN]: `Värdet är mellan`,
    [k.VALUE_IS_OVER_THE_THRESHOLD_OF]: `Värdet är över tröskeln`,
    [k.VALUE_IS_UNDER_THE_THRESHOLD_OF]: `Värdet är under tröskeln`,
    [k.VALUE]: `Värde`,
    [k.VALUES_COMMA_SEPERATED_LIKE_A_B_C]: `Lista valbara alternativ separerade med komman (som "ett, två, tre")`,
    [k.VARIABLE_NAME_REQUIRED]: `Variabelnamn krävs`,
    [k.VARIABLES_WITH_MANUAL_DATA_SOURCE]: `Variabler med manuell datakälla`,
    [k.VARIABLES]: `Variabler`,
    [k.VAT_NUMBER]: `Momsregnr.`,
    [k.VERIFY_AND_CLOSE]: `Verifiera och stäng`,
    [k.VERIFY]: `Verifiera`,
    [k.VERIFYING]: `Verifieras`,
    [k.VERSION]: `Version`,
    [k.VIEW_ALL_PROGRESSES]: `Visa alla framsteg`,
    [k.VIEW_ALL]: `Visa alla`,
    [k.VIEW_AS_INFO]: `Visa hur andra användare eller team ser denna sida`,
    [k.VIEW_AS]: `Visa som`,
    [k.VIEW_BARCODE]: `Visa streckkod`,
    [k.VIEW_CHECKLISTS_THAT_CANT_BE_UPGRADED]: `Visa checklistor som inte kan uppgraderas`,
    [k.VIEW_GOAL]: `Visa mål`,
    [k.VIEW_KPI]: `Visa KPI`,
    [k.VIEW_MEASUREMENT]: `Visa mätvärde`,
    [k.VIEW_PHOTO]: `Visa Foto`,
    [k.VIEW_PURCHASE_ORDER_DETAILS_SUBTITLE]: `Visa detaljer för den valda inköpsordern`,
    [k.VIEW_PURCHASE_ORDER_DETAILS]: `Visa inköpsorderinformation`,
    [k.VIEW_SUB_GOAL]: `Visa delmål`,
    [k.VIEW]: `Visa`,
    [k.VIEWER]: `Läsare`,
    [k.VISIT]: `Besök`,
    [k.VISUAL_SCHEDULE]: `Visuellt schema`,
    [k.WAITING_MESSAGE]: `Väntar...`,
    [k.WANT_TO_CLOSE_THIS_IMPROVEMENT]: `Vill du stänga denna avvikelse?`,
    [k.WEBHOOK_ADDED_MESSAGE]: `Ny webhook har lagts till framgångsrikt`,
    [k.WEBHOOK_DELETED]: `Webhook raderad`,
    [k.WEBHOOK_DETAILS]: `Detaljer om webhook`,
    [k.WEBHOOK_DISABLED_MESSAGE]: `Webhook är avstängd`,
    [k.WEBHOOK_ENABLED_MESSAGE]: `Webhook är påslagen`,
    [k.WEBHOOK_ENABLED]: `Webhook Aktiverad`,
    [k.WEBHOOK_EVENT_PAGE_SUBTITLE]: `Här är alla webhook-händelser som finns tillgängliga i systemet`,
    [k.WEBHOOK_EVENT_PAGE_TITLE]: `Webhook-händelser`,
    [k.WEBHOOK_EVENTS]: `Webhook-händelser`,
    [k.WEBHOOK_FEATURE_DESCRIPTION]: `Aktivering av den här funktionen möjliggör integration av AmpliFlow med tredjepartssystem som använder webhooks.`,
    [k.WEBHOOK_PAGE_SUBTITLE]: `Här är alla webhooks som finns tillgängliga i systemet`,
    [k.WEBHOOK_PAGE_TITLE]: `Webhooks`,
    [k.WEBHOOK_TITLE]: `Webhook-titel`,
    [k.WEBHOOK_UPDATED_MESSAGE]: `Webhook uppdaterad framgångsrikt`,
    [k.WEBHOOKS_FOR_THIS_STEP]: `Webhooks för det här steget`,
    [k.WEBSITE]: `Webbplats`,
    [k.WEDNESDAY]: `Onsdag`,
    [k.WEEK_S]: `Vecka(or)`,
    [k.WEEKLY]: `Veckovis`,
    [k.WEEKS_]: `veckor`,
    [k.WELCOME_BACK]: `Välkommen Tillbaka`,
    [k.WELL_BEING]: `Välbefinnande`,
    [k.WHAT_IF]: `Vad ifall att...`,
    [k.WHAT_IS_THE_IMPACT_FOR]: `Vilka är konsekvenserna för`,
    [k.WHAT]: `Vad`,
    [k.WHEN_A_NEW_SUPPLIER_IS_CREATED_SELECTED_ITEMS_WILL_RESET_MESSAGE]: `När en ny leverantör skapas återställs valda artiklar`,
    [k.WHEN_YOU_UN_ARCHIVE_A_EQUIPMENT_IT_WILL_BE_RESTORED_AND_SHOWN_UP_FOR_USER]: `När du avarkiverar en utrustning kommer den att återställas och visa upp för användare`,
    [k.WHEN_YOU_UN_ARCHIVE_A_TEAM_IT_WILL_BE_RESTORED_AND_SHOW_UP_FOR_USERS]: `När du avarkiverar ett team kommer det att återställas och visas för användare`,
    [k.WHICH_ARE_SCHEDULED_AND_HAVE_NOT_STARTED_YET]: `vilka är schemalagda och inte har startat ännu`,
    [k.WHO]: `Vem`,
    [k.WHOLE_NUMBER]: `Heltal`,
    [k.WHY_GOAL_AT_RISK]: `Varför målet är i riskzonen`,
    [k.WHY_GOAL_BLOCKED]: `Varför målet är blockerat`,
    [k.WHY_MEASUREMENT_AT_RISK]: `Varför mätvärdet är i riskzonen`,
    [k.WHY_MEASUREMENT_BLOCKED]: `Varför mätvärdet är blockerat`,
    [k.WHY_NOT_ADD_SOME]: `Dags att lägga till några?`,
    [k.WHY]: `Varför`,
    [k.WILL_BE_CREATED_OR_UNARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS]: `kommer att skapas eller återställas med följande uppdelning av checklistestatus`,
    [k.WILL_BE_REMOVED_OR_ARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS]: `kommer att tas bort eller arkiveras med följande uppdelning av checklistestatus`,
    [k.WILL_MAKE_THE_SCHEDULE_CONTAIN]: `kommer göra att schemat innehåller`,
    [k.WORKDAY]: `arbetsdag-`,
    [k.WOULD_YOU_LIKE_TO_PROCEED_WITH_THE_UPGRADE]: `Vill du fortsätta med uppgraderingen?`,
    [k.WRITE_A_COMMENT_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Skriv en kommentar...`,
    [k.WRITE_A_DESCRIPTION_FOR_THIS_IMPROVEMENT_FORM]: `Skriv en beskrivning av detta förbättringsformulär...`,
    [k.WRITE_A_DESCRIPTION_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Skriv en beskrivning för det här steget...`,
    [k.WRITE_A_SUMMARY_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE]: `Skriv en sammanfattning för den här mallen...`,
    [k.WWW_AMPLIFLOW_COM]: `www.AmpliFlow.com`,
    [k.YEAR_S_]: `år`,
    [k.YEAR_S]: `År`,
    [k.YEAR]: `År`,
    [k.YEARLY]: `Årsvis`,
    [k.YELLOW]: `Gul`,
    [k.YES_CONTINUE]: `Ja, fortsätt`,
    [k.YES]: `Ja`,
    [k.YESTERDAY]: `I går`,
    [k.YOU_ARE_NOT_ABLE_TO_COPY_UNTIL_PUBLISH]: `Du kan bara kopiera publicerade mallar.`,
    [k.YOU_CAN_ADD_STATIC_OR_TASK_LIST_ACTION_PLAN]: `Du kan lägga till statisk information eller en uppgiftslista.`,
    [k.YOU_CAN_ADD_SUBGOALS_FOR_ANY_GOAL]: `Du kan lägga till delmål för alla mål.`,
    [k.YOU_CAN_ADD_TWO_TYPES_OF_ACTION_PLAN]: `Du kan lägga till två typer av handlingsplaner.`,
    [k.YOU_SHOULD_BE_PART_OF_THIS_TEAM_TO_SEE_THE_CHECKLISTS]: `Du bör vara en del av detta team för att se checklistan`,
    [k.YOUR_ROLES]: `Dina Roller`,
    [k.YOUR_TEAMS]: `Dina Team`,
};
