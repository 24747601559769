import "./Dropdown.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IWithDebounce, IInputProps } from "../IInputProps";
import {
    IGroupedValueLabelItem,
    IValueLabelItem,
} from "common/IValueLabelItem";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import {
    ActionMeta,
    GroupBase,
    MenuPlacement,
    OnChangeValue,
    StylesConfig,
} from "react-select";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";

export interface IDropdownProps<V = string, L = string, P = undefined>
    extends IWithDebounce {
    options?: IGroupedValueLabelItem<V, L, P>[];

    isClearable?: boolean;
    isSearchable?: boolean;

    filterIds?: string[];

    subSearchOnSpace?: boolean;

    matchCase?: boolean;

    icon?: React.ReactNode;

    bold?: boolean;
    closeMenuOnSelect?: boolean;

    styles?: StylesConfig<OptionType, boolean>;

    onPreviewSelectedOption?: (id: string) => void;

    formatOptionLabel?: (
        options: OptionType,
        labelMeta: FormatOptionLabelMeta<OptionType>,
    ) => React.ReactNode;

    formatGroupLabel?: (group: GroupBase<OptionType>) => React.ReactNode;

    filterOption?: (
        option: FilterOptionOption<any>,
        rawInput: string,
    ) => boolean;

    menuPlacement?: MenuPlacement;

    useCollapsibleGroups?: boolean;
    openedGroupsByDefault?: boolean;
}

type Props<V, L, P> = IDropdownProps<V, L, P> & IInputProps<V, P>;

const WAIT_INTERVAL = 2000;

const Dropdown = <V, L, P>(props: Props<V, L, P>) => {
    const {
        id,
        name,
        disabled,
        preview,
        invalid,
        options = [],
        parentId,
        placeholder,
        isMulti,
        icon,
        helperText,
        testId,
        wrapperClassName,
        hideIcon,
        bold,
        closeMenuOnSelect,
        isSearchable,
        isClearable,
        styles,
        filterIds,
        subSearchOnSpace,
        matchCase = false,
        error,
        withDebounce,
        useCollapsibleGroups,
        openedGroupsByDefault,
    } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const [selectedIds, setSelectedIds] = useState(props.selectedIds ?? []);

    useEffect(() => {
        if (props.selectedIds !== selectedIds) {
            setSelectedIds(props.selectedIds ?? []);
        }
    }, [props.selectedIds]);

    const [isChanged, setIsChanged] = useState(false);
    const timer = useRef<any>(null);

    useEffect(() => {
        if (isMulti) {
            if (!withDebounce) {
                triggerChange(false);

                return;
            }

            if (selectedIds.length > 0) {
                timer.current = setTimeout(() => {
                    triggerChange(true);
                }, WAIT_INTERVAL);

                return () => clearTimeout(timer.current);
            }
        }
    }, [selectedIds, withDebounce, isMulti]);

    const allOptions = useMemo(() => {
        return (Array.isArray(options) ? options : []).flatMap((x) =>
            x.options && x.options.length > 0 ? x.options : x,
        ) as IValueLabelItem<string, string>[];
    }, [options]);

    const selectedSingleOption = useMemo(() => {
        if (allOptions && Boolean(isMulti) === false) {
            if (value || value === 0) {
                return allOptions.find((x) => x.value === value);
            }
        }

        return null;
    }, [allOptions, isMulti, value]);

    const selectedManyOptions = useMemo(() => {
        if (allOptions && isMulti) {
            return allOptions.filter((x) => selectedIds?.includes(x.value));
        }

        return null;
    }, [allOptions, isMulti, selectedIds]);

    const triggerChange = (withDelay: boolean) => {
        if (isChanged && selectedIds.length > 0) {
            setIsChanged(false);

            props.onChange?.({
                id,
                name,
                value,
                selectedIds,
                withDelay,
                parentId,
            });
        }
    };

    const handleOnChange = (e: OnChangeValue<OptionType, boolean>) => {
        if (isMulti) {
            const selectedValues = e as Array<IValueLabelItem<string>>;

            let newSelectedIds: string[] = [];

            if (selectedValues.length > 0) {
                setIsChanged(true);

                newSelectedIds = selectedValues.map((x) => x.value);
            } else {
                clearTimeout(timer.current);

                props.onChange?.({
                    id,
                    name,
                    value,
                    selectedIds: newSelectedIds,
                    parentId,
                });
            }

            setSelectedIds(newSelectedIds);
        } else {
            const selectedValues = e as IValueLabelItem<V, any, P>;

            let newValue = "" as V;
            let newParam: P | undefined = undefined;

            if (selectedValues) {
                newValue = selectedValues.value;
                newParam = selectedValues.param;
            }

            setValue(newValue);

            props.onChange?.({
                id,
                name,
                value: newValue,
                parentId,
                param: newParam,
            });
        }
    };

    const handleOnBlur = () => {
        props.onBlur?.(id, name);

        if (isMulti) {
            clearTimeout(timer.current);

            triggerChange(false);
        }
    };

    const filterOptions = (
        option: FilterOptionOption<any>,
        rawInput: string,
    ) => {
        if (filterIds && filterIds.includes(option.value)) {
            return false;
        }

        if (rawInput) {
            const input = matchCase ? rawInput : rawInput.toLowerCase();
            const target = matchCase
                ? option.label
                : option.label.toLowerCase();

            if (subSearchOnSpace) {
                const splitRaw = input.split(" ");

                if (splitRaw.every((x) => target.includes(x)) === false) {
                    return false;
                }
            } else {
                if (target.includes(input) === false) {
                    return false;
                }
            }
        }

        return true;
    };

    const isDisabled = disabled || preview;

    const useFilterOption =
        (filterIds && filterIds.length > 0) || subSearchOnSpace;

    const defaultCloseMenuOnSelect = isMulti ? false : undefined;

    return (
        <div
            className={`dropdown--wrapper${
                wrapperClassName ? " " + wrapperClassName : ""
            }`}
        >
            {icon && !hideIcon && <div className="icon-field">{icon}</div>}

            <SelectDropdown
                testId={testId}
                inputId={id}
                name={name}
                invalid={invalid}
                value={isMulti ? selectedManyOptions : selectedSingleOption}
                isSearchable={isSearchable}
                isClearable={isClearable}
                placeholder={placeholder}
                isMulti={isMulti}
                options={options as IGroupedValueLabelItem<string>[]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                isDisabled={isDisabled}
                closeMenuOnSelect={
                    closeMenuOnSelect ?? defaultCloseMenuOnSelect
                }
                bold={bold}
                styles={styles}
                filterOption={
                    props.filterOption ?? useFilterOption
                        ? filterOptions
                        : undefined
                }
                formatGroupLabel={props.formatGroupLabel}
                formatOptionLabel={props.formatOptionLabel}
                menuPlacement={props.menuPlacement}
                useCollapsibleGroups={useCollapsibleGroups}
                openedGroupsByDefault={openedGroupsByDefault}
                onPreviewSelectedOption={props.onPreviewSelectedOption}
            />
            {helperText && (
                <div className="dropdown--helper-text">{helperText}</div>
            )}

            {error && (
                <div className="text-field--helper-text">
                    <ValidationLabel
                        errors={t(k[error as keyof typeof k]) ?? error}
                    />
                </div>
            )}
        </div>
    );
};

export default Dropdown;
