import { QueryClient } from "react-query";

import IActivityListDTO from "models/dto/IActivityListDTO";
import { STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY } from "components/checklists/api/hooks";
import { STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY } from "components/improvements/api/hooks";
import IAssignUserToActivityInstanceRequest from "http/requests/IAssignUserToActivityInstanceRequest";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { PUBLISHED_USERS } from "components/users/api/hooks";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";

function filterArray(array: string[], item: string) {
    var index = array.indexOf(item);
    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }

    return array;
}

function filterObjectArray(
    array: IValueLabelItemWithState[],
    object: IValueLabelItemWithState,
) {
    var index = array.findIndex((o) => o.value === object.value);
    if (index === -1) {
        array.push(object);
    } else {
        array.splice(index, 1);
    }
    return array;
}

function updateActivityInstanceValue(
    userId?: string,
    user?: IValueLabelItemWithState,
    activityInstanceId?: string,
    activityList?: IActivityListDTO,
): IActivityListDTO {
    if (activityInstanceId && activityList && userId && user) {
        return {
            ...activityList,
            activityInstances: {
                ...activityList.activityInstances,
                [activityInstanceId]: {
                    ...activityList.activityInstances[activityInstanceId],
                    assignedUserIds: filterArray(
                        activityList.activityInstances[activityInstanceId]
                            .assignedUserIds,
                        userId,
                    ),
                    assignedUsers: filterObjectArray(
                        activityList.activityInstances[activityInstanceId]
                            .assignedUsers,
                        user,
                    ),
                },
            },
        };
    }
    return {
        activityInstanceSetIdsByStepInstanceId:
            activityList?.activityInstanceSetIdsByStepInstanceId ?? {},
        activityInstancesIdsByActivityId:
            activityList?.activityInstancesIdsByActivityId ?? {},
        activityInstances: activityList?.activityInstances ?? {},
        activityInstanceSets: activityList?.activityInstanceSets ?? {
            ids: [],
            values: {},
        },
    };
}

function restoreActivityInstanceValue(
    userIds?: string[],
    users?: IValueLabelItemWithState[],
    activityInstanceId?: string,
    activityList?: IActivityListDTO,
): IActivityListDTO {
    if (activityInstanceId && activityList) {
        return {
            ...activityList,
            activityInstances: {
                ...activityList.activityInstances,
                [activityInstanceId]: {
                    ...activityList.activityInstances[activityInstanceId],
                    assignedUserIds: userIds ?? [],
                    assignedUsers: users ?? [],
                },
            },
        };
    }
    return {
        activityInstanceSetIdsByStepInstanceId:
            activityList?.activityInstanceSetIdsByStepInstanceId ?? {},
        activityInstancesIdsByActivityId:
            activityList?.activityInstancesIdsByActivityId ?? {},
        activityInstances: activityList?.activityInstances ?? {},
        activityInstanceSets: activityList?.activityInstanceSets ?? {
            ids: [],
            values: {},
        },
    };
}

type ActivityInstanceAssignUserOptimisticUpdateContextType = {
    prevIds?: string[];
    prevUsers?: IValueLabelItemWithState[];
};

export async function onMutationAssignUserMutate(
    queryClient: QueryClient,
    variables: IAssignUserToActivityInstanceRequest,
) {
    const activitiesQuery = variables.isImprovement
        ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
        : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

    await queryClient.invalidateQueries([activitiesQuery, variables.parentId]);

    const stepinstanceActivities = queryClient.getQueryData<IActivityListDTO>([
        activitiesQuery,
        variables.parentId,
    ]);

    const publishedUsers = queryClient.getQueryData<
        ITableSetWithValueLabelOptions<string, undefined, string>
    >([PUBLISHED_USERS]);

    const assignedUserIds =
        variables.userId && variables.id
            ? stepinstanceActivities?.activityInstances[variables.id]
                  .assignedUserIds
            : undefined;

    const assignedUsers =
        variables.userId && variables.id
            ? stepinstanceActivities?.activityInstances[variables.id]
                  .assignedUsers
            : undefined;

    const newAssignedUser = variables.userId
        ? {
              isArchived: false,
              isDeleted: false,
              value: variables.userId,
              label: publishedUsers?.values[variables.userId]?.label ?? "",
          }
        : undefined;

    queryClient.setQueryData<IActivityListDTO>(
        [activitiesQuery, variables.parentId],
        (prev) =>
            updateActivityInstanceValue(
                variables.userId,
                newAssignedUser,
                variables.id,
                prev,
            ),
    );

    const result: ActivityInstanceAssignUserOptimisticUpdateContextType = {
        prevIds: assignedUserIds,
        prevUsers: assignedUsers,
    };

    return result;
}

export function onMutationAssignUserError(
    queryClient: QueryClient,
    variables: IAssignUserToActivityInstanceRequest,
    context?: ActivityInstanceAssignUserOptimisticUpdateContextType,
) {
    if (context) {
        const activitiesQuery = variables.isImprovement
            ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
            : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

        queryClient.setQueryData<IActivityListDTO>(
            [activitiesQuery, variables.parentId],
            (prev) =>
                restoreActivityInstanceValue(
                    context.prevIds,
                    context.prevUsers,
                    variables.id,
                    prev,
                ),
        );
    }
}
