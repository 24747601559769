import "./HeaderTableCell.scss";

import classnames from "classnames";

import { TableCell } from "common/components/table";
import { IHeaderTableCellProps } from "./IHeaderTableCellProps";
import { ReportDetailsFilterMenu } from "../filters/ReportDetailsFilterMenu";

interface IProps extends IHeaderTableCellProps {}

export const HeaderTableCell = (props: IProps) => {
    const { column, customListFilters } = props;

    const { title } = column ?? {};

    const { level = 0 } = column?.expandInfo ?? {};

    const className = classnames({
        "report-header-table-cell": true,
        [`level level-${level}`]: level > 0,
    });

    return (
        <TableCell
            className={className}
            filterMenu={
                <ReportDetailsFilterMenu
                    column={column}
                    customListFilters={customListFilters}
                    onFilter={props.onFilter}
                    onExpand={props.onExpand}
                />
            }
        >
            <div className="table-cell__truncate" title={title}>
                {title}
            </div>
        </TableCell>
    );
};
