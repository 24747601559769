export enum EActivityType {
    Textfield = "Textfield",
    Checkbox = "Checkbox",
    Tasklist = "Tasklist",
    Photo = "Photo",
    MultiLineTextfield = "MultiLineTextfield",
    Dropdown = "Dropdown",
    CustomerDropdown = "CustomerDropdown",
    SupplierDropdown = "SupplierDropdown",
    ItemDropdown = "ItemDropdown",
    Barcode = "Barcode",
    DateTime = "DateTime",
    CustomList = "CustomList",
    GenerateBarcode = "GenerateBarcode",
    Hyperlink = "Hyperlink",
    UserDropdown = "UserDropdown",
    PositionDropdown = "PositionDropdown",
    CompetencyDropdown = "CompetencyDropdown",
}
