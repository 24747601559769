import React from "react";
import { LinkContainer } from "react-router-bootstrap";

const LinkWrapper: React.FC<React.PropsWithChildren<{ to?: string }>> = (
    props,
) => {
    if (props.to) {
        return <LinkContainer to={props.to}>{props.children}</LinkContainer>;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default LinkWrapper;
