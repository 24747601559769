import "./PropertyTemplate.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Textfield from "common/components/input-components/textfield/Textfield";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { DeleteIcon, UndoIcon } from "common/components/icons";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import CustomListPropertyPreview from "./components/CustomListPropertyPreview";
import { CustomListPropertyEnum } from "../../custom-list-page/api/CustomListPropertyEnum";
import BasicPropertyPreview from "./components/BasicPropertyPreview";
import { ICustomListPropertyTemplateValue } from "../../custom-list-page/api/ICustomListPropertyTemplateValue";
import {
    isSortablePropertiesDisabled,
    isFilterablePropertiesDisabled,
    isAllowMultipleDisabled,
} from "./Config";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useCustomListOptions } from "components/custom-list-page/api/hooks";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import IconWithMessage from "../iconsWithMessage/IconWithMessage";

export interface IPropertyTemplateProps {
    orderNumber: number | null;

    value: ICustomListPropertyTemplateValue | null;

    isDisabled: boolean;
    isBlur?: boolean;

    mode?: "readonly";
    previewPlaceholder?: React.ReactNode;

    hideMutualProperties?: boolean;
    isCustomListProperty?: boolean;

    errors: IValidationMessages;

    isAtLeastOnePropertyShouldBeVisibleInvalid: boolean;

    onDelete: (id: string) => void;

    onChange: (e: {
        isInitialization?: boolean;
        value: ICustomListPropertyTemplateValue;
    }) => void;
}

const PropertyRowNumber = ({ index, id }: { index: number; id?: string }) => {
    return (
        <label
            className="property-index"
            htmlFor={id ? `field-${id}` : undefined}
        >
            {index}.
        </label>
    );
};

const PropertyTemplate: React.FC<IPropertyTemplateProps> = (props) => {
    const {
        orderNumber,
        value,
        isDisabled,
        errors,
        mode,
        previewPlaceholder,
        hideMutualProperties,
        isCustomListProperty,
    } = props;

    const isReadonly = mode === "readonly";

    const { t } = useTranslation();

    const { data: customListOptions } = useCustomListOptions();

    const handleChangeLabel = (newLabel: string) => {
        if (value) {
            props.onChange({
                value: { ...value, label: newLabel },
            });
        }
    };

    const handleChangeCheckbox = (e: IInputChangeEvent<boolean>) => {
        if (e.name && value) {
            props.onChange({
                value: { ...value, [e.name]: e.value },
            });
        }
    };

    const handleDelete = () => {
        if (value) {
            props.onDelete(value.id);
        }
    };

    const handleChangeBasicProperty = (
        options: IValueLabelItem<string, string>[],
        isInitialization: boolean,
    ) => {
        if (value) {
            props.onChange({
                isInitialization,
                value: { ...value, options },
            });
        }
    };

    if (!value) {
        return null;
    }

    const propertyType = value.type ?? CustomListPropertyEnum.None;

    const customListOption =
        value.mutualProperty?.parentCustomListParentId && customListOptions
            ? customListOptions.values.values[
                  value.mutualProperty.parentCustomListParentId
              ]
            : undefined;

    const isShowMutualPropertyCheckbox =
        value.type === CustomListPropertyEnum.None &&
        mode === undefined &&
        (hideMutualProperties ?? false) === false &&
        (value.mutualProperty?.isNotParent ?? false) === false;

    return (
        <div
            className={
                mode === undefined
                    ? "property-template"
                    : "property-template border"
            }
        >
            <div className="property-template__label">
                {orderNumber === null ? undefined : (
                    <PropertyRowNumber index={orderNumber} id={value.id} />
                )}

                <Textfield
                    id={`field-${value.id}`}
                    value={value.label}
                    placeholder={t(k.ENTER_HERE)}
                    size="large"
                    bold
                    onChange={handleChangeLabel}
                    disabled={value.isDeleted || isDisabled}
                    testId={`custom-list--property--label--${value.id}`}
                    postInputContent={
                        <Checkbox
                            label={t(k.REQUIRED)}
                            name="isRequired"
                            value={value.isRequired ?? false}
                            disabled={value.isDeleted || isDisabled}
                            testId={`custom-list--property--label--required--${value.id}`}
                            onChange={handleChangeCheckbox}
                        />
                    }
                />

                {isReadonly ? undefined : (
                    <button
                        className="property-delete"
                        disabled={isDisabled}
                        onClick={handleDelete}
                    >
                        {value.isDeleted ? <UndoIcon /> : <DeleteIcon />}
                    </button>
                )}
            </div>

            {value.mutualProperty?.isNotParent && (
                <div className="property-template__label mutual-property">
                    {orderNumber === null ? undefined : (
                        <PropertyRowNumber index={orderNumber} />
                    )}

                    <label className="text-field">
                        <strong>{t(k.FROM)}: </strong>

                        {customListOption && (
                            <React.Fragment>
                                {customListOption.name}
                                <span> / </span>
                            </React.Fragment>
                        )}

                        {value.mutualProperty.parentLabel}
                    </label>
                </div>
            )}

            <div className="property-template__preview">
                {propertyType === CustomListPropertyEnum.None ? (
                    mode === undefined ? (
                        <CustomListPropertyPreview />
                    ) : (
                        <SelectDropdown
                            isDisabled
                            placeholder={previewPlaceholder}
                        />
                    )
                ) : (
                    <BasicPropertyPreview
                        value={value}
                        disabled={isDisabled}
                        errors={errors}
                        onChange={handleChangeBasicProperty}
                    />
                )}
            </div>

            <div className="property-template__config">
                <Checkbox
                    label={
                        <React.Fragment>
                            {t(k.SHOW_IN_LIST)}
                            <IconWithMessage
                                id={`${orderNumber}-show-in-list`}
                                message={t(k.DATA_WILL_BE_SHOWN_LIST)}
                            />
                        </React.Fragment>
                    }
                    name="showInListView"
                    value={value.showInListView ?? false}
                    disabled={value.isDeleted || isDisabled}
                    invalid={props.isAtLeastOnePropertyShouldBeVisibleInvalid}
                    testId={`custom-list--property--show-list--${value.id}`}
                    onChange={handleChangeCheckbox}
                />

                <Checkbox
                    label={
                        <React.Fragment>
                            {t(k.SORTABLE)}
                            <IconWithMessage
                                id={`${orderNumber}-sortable`}
                                message={t(k.DATA_WILL_BE_SORTABLE_LIST)}
                            />
                        </React.Fragment>
                    }
                    name="isSortable"
                    value={value.isSortable ?? false}
                    disabled={
                        value.isDeleted ||
                        isDisabled ||
                        isSortablePropertiesDisabled[propertyType]
                    }
                    testId={`custom-list--property--sortable--${value.id}`}
                    onChange={handleChangeCheckbox}
                />

                <Checkbox
                    label={
                        <React.Fragment>
                            {t(k.FILTERABLE)}
                            <IconWithMessage
                                id={`${orderNumber}-filterable`}
                                message={t(k.DATA_WILL_BE_FILTERABLE_LIST)}
                            />
                        </React.Fragment>
                    }
                    name="isFilterable"
                    value={value.isFilterable ?? false}
                    disabled={
                        value.isDeleted ||
                        isDisabled ||
                        isFilterablePropertiesDisabled[propertyType]
                    }
                    testId={`custom-list--filterable--${value.id}`}
                    onChange={handleChangeCheckbox}
                />

                {propertyType !== CustomListPropertyEnum.Tasklist && (
                    <Checkbox
                        label={
                            <React.Fragment>
                                {t(k.ALLOW_MULTI_VALUE)}
                                <IconWithMessage
                                    id={`${orderNumber}-multiple-input`}
                                    message={t(k.USER_ALLOW_SELECT_MULTI)}
                                />
                            </React.Fragment>
                        }
                        name="allowMultiple"
                        wrapperClassName="separate-line"
                        value={value.allowMultiple ?? false}
                        disabled={
                            value.isDeleted ||
                            isDisabled ||
                            isAllowMultipleDisabled[propertyType]
                        }
                        testId={`custom-list--multi--${value.id}`}
                        onChange={handleChangeCheckbox}
                    />
                )}

                {isShowMutualPropertyCheckbox && (
                    <Checkbox
                        label={
                            <React.Fragment>
                                {t(k.SHOW_REFERENCED_PROPERTY_IN_PARENT_LIST)}
                                <IconWithMessage
                                    id={`${orderNumber}-isMutualPropertyVisible`}
                                    message={t(
                                        k.PROPERTY_WILL_BE_VISIBLE_IN_PARENT_LIST,
                                    )}
                                />
                            </React.Fragment>
                        }
                        name="isMutualPropertyVisible"
                        wrapperClassName="separate-line"
                        value={value.isMutualPropertyVisible ?? false}
                        disabled={value.isDeleted || isDisabled}
                        testId={`custom-list--isMutualPropertyVisible--${value.id}`}
                        onChange={handleChangeCheckbox}
                    />
                )}

                {propertyType === CustomListPropertyEnum.Users &&
                    isCustomListProperty && (
                        <Checkbox
                            label={
                                <React.Fragment>
                                    {t(k.ACCESS_LIMITING)}
                                    <IconWithMessage
                                        id={`${orderNumber}-userAccessLimited`}
                                        message={t(
                                            k.ACCESS_LIMITING_DESCRIPTION,
                                        )}
                                    />
                                </React.Fragment>
                            }
                            name="userAccessLimited"
                            wrapperClassName="separate-line"
                            value={value.userAccessLimited ?? false}
                            disabled={value.isDeleted || isDisabled}
                            testId={`custom-list--userAccessLimited--${value.id}`}
                            onChange={handleChangeCheckbox}
                        />
                    )}
            </div>
        </div>
    );
};

export default PropertyTemplate;
