import React, { useMemo } from "react";

import {
    useTableContext,
    TableRowContainerContext,
    ITableRowContext,
} from "./Context";

const TableFooterComponent: React.FC<React.PropsWithChildren> = (props) => {
    const context = useTableContext();

    const contextValue = useMemo(() => {
        const result: ITableRowContext = {
            isFooter: true,
        };

        return result;
    }, []);

    return props.children ? (
        <TableRowContainerContext.Provider value={contextValue}>
            <tfoot>{props.children}</tfoot>
        </TableRowContainerContext.Provider>
    ) : null;
};

export default TableFooterComponent;
