import "./BarcodeScanAppend.scss";

import React from "react";
import { connect } from "react-redux";

import { BarcodeIcon } from "common/components/icons";
import { showScanner } from "store/barcode/actions";

interface IOwnProps {
    index?: number;

    onChange: (barcode: string, index?: number) => void;
}

interface IDispatchProps {
    onScan: () => Promise<string | undefined>;
}

type Props = IOwnProps & IDispatchProps;

const BarcodeScanAppend = (props: Props) => {
    const { index } = props;

    const handleBarcodeScan = async () => {
        const newValue = await props.onScan();

        if (newValue !== undefined) {
            props.onChange(newValue, index);
        }
    };

    return (
        <div
            className="barcode-scan-append__wrapper"
            onClick={handleBarcodeScan}
        >
            <BarcodeIcon />
        </div>
    );
};

const actions = {
    onScan: showScanner,
};

export default connect(null, actions)(BarcodeScanAppend);
