import "./MeasurementProgressList.scss";

import k from "i18n/keys";

import ModalContent from "common/components/modal-content/ModalContent";
import { useTranslation } from "react-i18next";
import { useProgressListByMeasurementId } from "./api/hooks";
import CustomModal from "common/components/modal-content/CustomModal";
import AccordionLeft from "common/components/accordion/AccordionLeft";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { useContext } from "react";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import Badge from "common/components/badges/Badge";

interface IProps {
    measurementId: string;
    onClose: () => void;
}

const MeasurementProgressList = (props: IProps) => {
    const { measurementId } = props;
    const { t, i18n } = useTranslation();

    const { data: list, isLoading: listLoading } =
        useProgressListByMeasurementId(measurementId);

    const modalManagerContext = useContext(ModalManagerContext);

    const handleEditProgressValue = (id?: string) => {
        if (id) {
            const payload: IShowModalPayload = {
                id,
                type: ModalTypes.measurement_progress_form,
                callerAddress: location.pathname,
                options: {
                    parentId: measurementId,
                },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    return (
        <CustomModal onBackgroundClick={props.onClose}>
            <ModalContent maxWidth="sm" className="measurement-progress-list">
                <ModalContent.Header
                    title={t(k.VIEW_ALL_PROGRESSES)}
                    onClose={props.onClose}
                />

                <ModalContent.Body className="measurement-progress-list--body">
                    {list?.map((item) => (
                        <div
                            className={`measurement-progress-list--item${
                                item.isArchived ? " archived" : ""
                            }`}
                            key={item.id}
                            onClick={() => handleEditProgressValue(item?.id)}
                        >
                            {item.isArchived && (
                                <div className="archived-badge">
                                    <Badge pill border variant="secondary">
                                        {t(k.ARCHIVED)}
                                    </Badge>
                                </div>
                            )}
                            <div className="item-row">
                                <div className="left-side-container">
                                    <p>
                                        {t(k.PROGRESS_VALUE)}:{" "}
                                        <span className="highlighted">
                                            {item.value.toFixed(2)}
                                        </span>
                                    </p>
                                    <p>
                                        {t(k.PROGRESS_DATE)}:{" "}
                                        <span className="highlighted">
                                            <FormattedDate
                                                date={item.date}
                                            ></FormattedDate>
                                        </span>
                                    </p>
                                </div>
                                <div className="right-side-container">
                                    <FormattedDate
                                        date={item.changedOn}
                                    ></FormattedDate>{" "}
                                    {t(k.BY_)}{" "}
                                    <div className="name">{item.changedBy}</div>
                                </div>
                            </div>
                            {item.comment && (
                                <div
                                    className="item-row item-notes"
                                    title={item.comment}
                                >
                                    {item.comment}
                                </div>
                            )}
                        </div>
                    ))}
                </ModalContent.Body>
            </ModalContent>
        </CustomModal>
    );
};

export default MeasurementProgressList;
