import React from "react";

import k from "i18n/keys";

import SelectTemplate from "components/common/selectTemplate/SelectTemplate";
import { TemplateType } from "models/enums/TemplateType";

import { getPublishedTemplates } from "components/templates/api/hooks";

interface IProps {
    customListItemId?: string;

    onSelect: (templateVersionId: string) => void;
    onClose: () => void;
}

const SelectImprovementTemplate = (props: React.PropsWithoutRef<IProps>) => {
    const { customListItemId } = props;

    const { data: publishedTemplates, isLoading } = getPublishedTemplates({
        type: TemplateType.Improvement,
        customListItemId,
    });

    return (
        <SelectTemplate
            publishedTemplates={publishedTemplates}
            isLoading={isLoading}
            headerKey={k.SELECT_IMPROVEMENT_FORM}
            searchKey={k.SEARCH_IMPROVEMENT_FORM}
            onSelect={props.onSelect}
            onClose={props.onClose}
        />
    );
};

export default SelectImprovementTemplate;
