import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { IDropdownGroupOption } from "../api/IDropdownOption";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { TFunction } from "i18next";
import { getLocaleString } from "i18n/components/GetLocaleString";

interface IProps {
    item: IDropdownGroupOption;
    noCheckbox?: boolean;
    t: TFunction<"translation", undefined>;
    onChange: (e: IInputChangeEvent<boolean>) => void;
}

const DropdownMakeList: React.FC<IProps> = (props) => {
    const { item, noCheckbox, t } = props;
    if (item.options) {
        return (
            <div className="filter-options--group">
                <div className="filter-options--group--label">{item.label}</div>
                {item.options.map((x) => (
                    <DropdownMakeList
                        key={x.value}
                        item={x}
                        t={t}
                        onChange={props.onChange}
                    />
                ))}
            </div>
        );
    }

    const handleOnClickNoCheckbox = () => {
        props.onChange({ name: item.value, value: true });
    };

    return noCheckbox ? (
        <div
            key={item.value}
            className="dropdown-menu--item"
            onClick={handleOnClickNoCheckbox}
        >
            {getLocaleString(t, item.label)}
        </div>
    ) : (
        <Checkbox
            key={item.value}
            label={getLocaleString(t, item.label)}
            name={item.value}
            value={item.selected ?? false}
            disabled={item.disabled}
            wrapperClassName="dropdown-menu--item"
            onChange={props.onChange}
        />
    );
};

export default DropdownMakeList;
