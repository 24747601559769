import { createRef, RefObject, useEffect, useState } from "react";

import { UseMutateAsyncFunction, UseMutationResult } from "react-query";

import { IValueLabelItem } from "common/IValueLabelItem";
import { InputValueType } from "common/components/activity-instance-card/ActivityInstanceCardById";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ITemplateVersionConfigurationUsersDto from "components/improvements/api/ITemplateVersionConfigurationUsersDto";
import ITemplateVersionConfigurationUsersRequest from "components/improvements/api/ITemplateVersionConfigurationUsersRequest";
import {
    useGetInvolvedUsersForTemplateByTemplateVersionIdMutation,
    useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation,
} from "components/improvements/api/hooks";
import { mergeStringArraysAndDistinct } from "components/improvements/api/utils";
import { useStepsByTemplateVersionId } from "components/steps/api/hooks";
import { useTemplateByVersionId } from "components/templates/api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import ITableSet from "http/ITableSet";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import IImprovementRequest from "http/requests/IImprovementRequest";
import { IChangedStepInstance } from "http/requests/IWorkflowRunRequest";
import IActivity from "models/IActivity";
import IAttachment from "models/IAttachment";
import IStep from "models/IStep";

import { INewImprovementDetailsProps } from "./NewImprovementListedContent";
import { INewImprovementData } from "./models/INewImprovementData";

export const useImprovementFormData = (
    props: INewImprovementDetailsProps,
): INewImprovementData => {
    const { id } = props;

    const [editMode, setEditMode] = useState(false);
    const [errors, setErrors] = useState<IValidationMessages>();
    const [editedSteps, setEditedSteps] =
        useState<IDictionary<IChangedStepInstance>>();
    const [usersAffected, setUsersAffected] = useState<IDictionary<string[]>>();

    const [involvedUsers, setInvolvedUsers] =
        useState<Array<IValueLabelItem<string, string>>>();

    const [usersToBeNotified, setUsersToBeNotified] =
        useState<Array<IValueLabelItem<string, string>>>();

    const [editedActivityValues, setEditedActivityValues] = useState<
        IDictionary<InputValueType>
    >({});

    const [editedImprovement, setEditedImprovement] =
        useState<IImprovementRequest>();

    const [stepRefs, setStepRefs] =
        useState<IDictionary<RefObject<HTMLDivElement>>>();

    const { data: improvementFormSteps, isLoading: isLoadingSteps } =
        useStepsByTemplateVersionId(id);

    const { data: improvementForm, isLoading: isLoadingImprovementForm } =
        useTemplateByVersionId(id);

    const {
        data: involvedUsersMutationData,
        mutateAsync: involvedUsersMutateAsync,
        isLoading: isLoadingInvolvedUsersMutation,
    } = useGetInvolvedUsersForTemplateByTemplateVersionIdMutation();

    const {
        data: notifyUsersMutationData,
        mutateAsync: notifyUsersMutateAsync,
        isLoading: isLoadingNotifyUsersMutation,
    } = useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation();

    const { data: publishedUsers } = usePublishedUserOptions();

    const improvementFormStepValues = Object.values(
        improvementFormSteps?.steps ?? {},
    );

    const initialSection =
        improvementFormStepValues.length > 0
            ? improvementFormStepValues[0].id
            : undefined;

    const steps: ITableSet<IStep> | undefined = improvementFormSteps?.steps
        ? {
              values: improvementFormSteps.steps,
              ids: Object.keys(improvementFormSteps.steps),
          }
        : undefined;

    const activities: ITableSet<IActivity> | undefined =
        improvementFormSteps && {
            values: improvementFormSteps.activities,
            ids: Object.keys(improvementFormSteps.activities),
        };

    const attachments: ITableSet<IAttachment> | undefined =
        improvementFormSteps && {
            values: improvementFormSteps.attachments,
            ids: Object.keys(improvementFormSteps.attachments),
        };

    const activityInputs: ITableSet<IActivityInputListItem> | undefined =
        improvementFormSteps && {
            values: improvementFormSteps.activityInputs,
            ids: Object.keys(improvementFormSteps.activityInputs),
        };

    useEffect(() => {
        if (steps?.ids) {
            setStepRefs(
                steps.ids.reduce(
                    (acc, item) => {
                        acc[item] = createRef();
                        return acc;
                    },
                    {} as { [key: string]: RefObject<HTMLDivElement> },
                ),
            );
        }
    }, [improvementFormSteps]);

    useEffect(() => {
        const fetchData = async (
            mutateAsync: UseMutateAsyncFunction<
                ExecutionResult<ITemplateVersionConfigurationUsersDto>,
                unknown,
                { request: ITemplateVersionConfigurationUsersRequest }
            >,
            shouldSetInvolvedUsers = false,
        ) => {
            if (editedImprovement?.formId) {
                let request = {
                    templateId: editedImprovement.formId,
                    improvementId: editedImprovement?.id,
                    impactedProcesses: editedImprovement?.impactedProcesses,
                    isCreate: true,
                };

                let data = await mutateAsync({ request });

                const allUsersArray = mergeStringArraysAndDistinct(
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.discoveredInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.originatedInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    [data.Data?.managerOfReportingUser ?? ""],
                    data.Data?.admins ?? [],
                    data.Data?.deviationManagers ?? [],
                    data.Data?.involvedUserSets?.flatMap((set) => set.users) ??
                        [],
                );

                const selectedUsers = publishedUsers?.options?.filter((user) =>
                    allUsersArray.includes(user.value),
                );

                if (shouldSetInvolvedUsers) {
                    setInvolvedUsers(selectedUsers);
                } else {
                    setUsersToBeNotified(selectedUsers);
                }
                return data;
            }
        };

        //improvement already has specific settings for involved users
        //no need to calculate the ones from template configuration
        if (editedImprovement?.involvedUsers !== undefined) {
            setInvolvedUsers(
                publishedUsers?.options?.filter((user) =>
                    Object.keys(
                        editedImprovement?.involvedUsers ?? {},
                    ).includes(user.value),
                ),
            );
        } else {
            if (involvedUsersMutationData === undefined) {
                fetchData(involvedUsersMutateAsync, true);
            }
        }
        fetchData(notifyUsersMutateAsync, false);
    }, [editedImprovement?.impactedProcesses, publishedUsers]);

    const isLoadingInvolvedUsers =
        isLoadingInvolvedUsersMutation || isLoadingNotifyUsersMutation;

    return {
        improvementForm,
        editedImprovement,
        setEditedImprovement,
        isLoadingImprovementForm,
        isLoadingSteps,
        editMode,
        setEditMode,
        editedActivityValues,
        setEditedActivityValues,
        errors,
        setErrors,
        editedSteps,
        setEditedSteps,
        usersAffected,
        setUsersAffected,
        usersToBeNotified,
        setUsersToBeNotified,
        involvedUsers,
        setInvolvedUsers,
        isLoadingInvolvedUsers,
        sectionRefs: stepRefs,
        steps,
        activities,
        activityIdsByStepId: improvementFormSteps?.activityIdsByStepId,
        attachments,
        attachmentIdsByActivityId:
            improvementFormSteps?.attachmentsIdsByActivityId,
        activityInputs,
        activityInputsIdsByActivityIds:
            improvementFormSteps?.activityInputsIdsByActivityId,
        selectedId: id,
        initialSection,
        filter: props.filter,
        onClose: props.onClose,
        onHaveChanges: props.onHaveChanges,
        onShowModal: props.onShowModal,
        checkCanSeeAll: props.checkCanSeeAll,
    };
};
