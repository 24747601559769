import "./MeasurementProgressForm.scss";

import k from "i18n/keys";

import { v4 as uuidv4 } from "uuid";

import ModalContent from "common/components/modal-content/ModalContent";
import { useTranslation } from "react-i18next";
import Button from "common/components/buttons/Button";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";

import { useEffect, useState } from "react";

import { IMeasurementProgressValueDTO } from "../measurement-progress-value/api/IMeasurementProgressValueDTO";
import InfoCard from "components/goals-page/components/InfoCard";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import {
    useArchiveMeasurementProgressValueMutation,
    useDeleteMeasurementProgressValueMutation,
    useMeasurementProgressValue,
    useSaveMeasurementProgressValueMutation,
} from "./api/hooks";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup } from "common/components/buttons";
import { IAppState } from "store/IAppState";
import InputWrapper from "common/components/input-components/InputWrapper";
import HistoryButton from "components/history/components/HistoryButton";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import { useMeasurementAccessRole } from "../api/hooks";
import { decomposeMeasurementAccessRole } from "../utils/MeasurementTools";

interface IProps {
    id?: string;
    isChanged: boolean;
    createNew?: boolean;
    measurementId?: string;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
    onSave?: (id: string) => void;
}

const MeasurementProgressForm = (props: IProps) => {
    const { createNew } = props;
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const canAccessGoals = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR ||
                roles.GOALS_OWNER ||
                roles.GOALS_EDITOR ||
                roles.GOALS_USER) ??
            false
        );
    });

    const canDelete = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (roles.ADMINISTRATOR || roles.GOALS_OWNER) ?? false;
    });

    const {
        data: measurementAccessRole,
        isLoading: isLoadingMeasurementAccessRole,
    } = useMeasurementAccessRole(
        canAccessGoals ? props.measurementId : undefined,
    );

    const { canEditInPreview: canEditProgress } =
        decomposeMeasurementAccessRole(measurementAccessRole);

    const saveMutation = useSaveMeasurementProgressValueMutation();
    const archiveMutation = useArchiveMeasurementProgressValueMutation();

    const deleteMutation = useDeleteMeasurementProgressValueMutation();

    const [progressValue, setProgressValue] =
        useState<IMeasurementProgressValueDTO>({
            id: uuidv4(),
            value: 0,
            comment: "",
            measurementId: props.measurementId,
            date: new Date(Date.now()),
        });

    const { data: progressValueData, refetch } = useMeasurementProgressValue(
        {
            measurementProgressId: props.id,
            measurementId: props.measurementId,
            date: progressValue.date,
        },
        canEditProgress,
    );

    useEffect(() => {
        if (progressValue.date) {
            refetch();
        }
    }, [progressValue.date]);

    useEffect(() => {
        if (progressValueData) {
            setProgressValue((prev) => {
                if (!props.id) {
                    return {
                        ...prev,
                        targetValue: progressValueData.targetValue,
                        calculatedValue: progressValueData.calculatedValue,
                        value: progressValueData.value,
                        absoluteValue:
                            progressValueData.value +
                            (progressValueData.calculatedValue ?? 0),
                    };
                } else {
                    return {
                        ...progressValueData,
                        absoluteValue:
                            progressValueData.value +
                            (progressValueData.calculatedValue ?? 0),
                    };
                }
            });
        }
    }, [progressValueData]);

    const handleSave = async () => {
        const response = await saveMutation.mutateAsync({
            isCreate: !!createNew,
            value: progressValue,
        });

        if (response.Succeeded) {
            props.onClose(true);
        }
    };

    const handleCancel = () => {
        props.onClose(false);
    };

    const handleOnChangeIncremetalProgress = (
        event: IInputChangeEvent<number>,
    ) => {
        const value = event.value;

        setProgressValue((prev) => ({
            ...prev,
            value: value,
            absoluteValue: (prev.calculatedValue ?? 0) + value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeAbsoluteProgress = (
        event: IInputChangeEvent<number>,
    ) => {
        const value = event.value;

        setProgressValue((prev) => ({
            ...prev,
            value: value - (prev.calculatedValue ?? 0),
            absoluteValue: value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeDate = (event: IInputChangeEvent<Date | undefined>) => {
        const date = event.value;

        setProgressValue((prev) => ({
            ...prev,
            date,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeComment = (
        event: IInputChangeEvent<string | undefined>,
    ) => {
        const comment = event.value;

        setProgressValue((prev) => ({
            ...prev,
            comment,
        }));
        props.onHaveChanges(true);
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const handleArchive = async () => {
        if (progressValue.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: progressValue.isArchived ? "blue" : "danger",
                yesLabel: progressValue.isArchived
                    ? t(k.UN_ARCHIVE)
                    : t(k.ARCHIVE),
                title: progressValue.isArchived
                    ? t(k.UNARCHIVE_THIS_PROGRESS_VALUE)
                    : t(k.ARCHIVE_THIS_PROGRESS_VALUE),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                await archiveMutation.mutateAsync({
                    id: progressValue.id,
                    isArchive: !progressValue.isArchived,
                });
            }
        }
    };

    const handleDelete = async () => {
        if (progressValue.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
                title: t(k.DELETE_THIS_PROGRESS_VALUE),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                const result = await deleteMutation.mutateAsync(
                    progressValue.id,
                );

                if (result.Succeeded) {
                    props.onClose(true);

                    toast.success(
                        t(k.PROGRESS_VALUE_DELETED),

                        {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            }
        }
    };

    return (
        <ModalContent>
            <ModalContent.Header
                title={createNew ? t(k.ADD_PROGRESS) : t(k.UPDATE_PROGRESS)}
                onClose={handleOnClose}
                actions={
                    !createNew &&
                    progressValue.id && (
                        <HistoryButton
                            id={progressValue.id}
                            type={HistoryOwnerEnum.MeasurementProgress}
                        />
                    )
                }
            />
            <ModalContent.Body className="measurement-progress-form--body">
                <InfoCard
                    label={t(k.UPDATE_VALUE)}
                    childrenClassName="update-value-card"
                >
                    <div className="update-value-card--row">
                        <InputWrapper
                            wrapperLabel={t(k.TARGET_VALUE)}
                            fullWidth
                            noMinWidth
                            boldLabel
                        >
                            <div
                                className="value--preview"
                                data-testid="measurement_progress_target_value_input"
                            >
                                {progressValue?.targetValue ?? 0}
                            </div>
                        </InputWrapper>

                        <InputWrapper
                            wrapperLabel={t(k.CURRENT_VALUE_AT_DATE)}
                            fullWidth
                            noMinWidth
                            boldLabel
                        >
                            <div
                                className="value--preview"
                                data-testid="measurement_progress_current_value_input"
                            >
                                {progressValue?.calculatedValue ?? 0}
                            </div>
                        </InputWrapper>

                        <InputComponent
                            inputType={EActivityType.Textfield}
                            wrapperLabel={t(k.ADD_INCREMENTAL_PROGRESS)}
                            fullWidth
                            noMinWidth
                            disabled={progressValue.isArchived}
                            boldLabel
                            type="number"
                            hideIcon
                            value={progressValue.value}
                            testId="measurement_progress_increment_value_input"
                            onChange={handleOnChangeIncremetalProgress}
                            placeholder={t(k.ENTER_HERE)}
                        />
                        <InputComponent
                            inputType={EActivityType.Textfield}
                            wrapperLabel={t(k.ADD_ABSOLUTE_PROGRESS)}
                            fullWidth
                            noMinWidth
                            disabled={progressValue.isArchived}
                            boldLabel
                            type="number"
                            hideIcon
                            value={progressValue.absoluteValue ?? 0}
                            testId="measurement_progress_absolute_value_input"
                            onChange={handleOnChangeAbsoluteProgress}
                            placeholder={t(k.ENTER_HERE)}
                        />
                    </div>
                    <InputComponent
                        inputType={EActivityType.Textfield}
                        wrapperLabel={t(k.NOTES)}
                        boldLabel
                        hideIcon
                        value={progressValue?.comment ?? ""}
                        multiline
                        disabled={progressValue.isArchived}
                        minRows={5}
                        testId="measurement_progress_comment_input"
                        placeholder={t(k.ENTER_HERE)}
                        onChange={handleOnChangeComment}
                    />
                    <InputComponent
                        testId="measurement_progress_date_input"
                        inputType={EActivityType.DateTime}
                        wrapperLabel={t(k.DATE_OF_ADDITION)}
                        boldLabel
                        disabled={progressValue.isArchived || !createNew}
                        value={progressValue?.date ?? new Date(Date.now())}
                        name="date-of-addition"
                        placeholder={t(k.DATE_OF_ADDITION)}
                        hideIcon
                        noMinWidth
                        shouldCloseOnSelect
                        onChange={handleOnChangeDate}
                    />
                </InfoCard>
            </ModalContent.Body>

            <ModalContent.Footer className="measurement-progress-form--footer">
                <Button onClick={handleCancel} variant="danger" transparent>
                    {t(k.CANCEL)}
                </Button>

                <ButtonGroup className="buttons">
                    {!createNew && (
                        <Button
                            onClick={handleArchive}
                            variant="bordered"
                            transparent
                        >
                            {progressValue.isArchived
                                ? t(k.UN_ARCHIVE)
                                : t(k.ARCHIVE)}
                        </Button>
                    )}
                    {progressValue.isArchived ? (
                        canDelete && (
                            <Button onClick={handleDelete} variant="danger">
                                {t(k.DELETE)}
                            </Button>
                        )
                    ) : (
                        <Button onClick={handleSave} variant="success">
                            {t(k.SAVE)}
                        </Button>
                    )}
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default MeasurementProgressForm;
