import { Reducer } from "redux";

import {
    WebCamActionTypes,
    SHOW_WEBCAM_MODAL,
    HIDE_WEBCAM_MODAL,
    RESET_CAPTURED_WEBCAM_PHOTO,
} from "./types";

export interface IWebCamState {
    readonly isVisible: boolean;
    readonly largeModalSize?: boolean;

    readonly photoCaptured: boolean;
    readonly data?: { content: string; fileName: string };
}

const initialState: IWebCamState = {
    isVisible: false,

    photoCaptured: false,
};

export const WebCamReducer: Reducer<IWebCamState, WebCamActionTypes> = (
    state = initialState,
    action,
): IWebCamState => {
    switch (action.type) {
        case SHOW_WEBCAM_MODAL: {
            return {
                ...state,
                isVisible: true,
                largeModalSize: action.payload,
                photoCaptured: false,
                data: undefined,
            };
        }

        case HIDE_WEBCAM_MODAL: {
            return {
                ...state,
                isVisible: false,
                largeModalSize: undefined,
                photoCaptured: true,
                data: action.payload,
            };
        }

        case RESET_CAPTURED_WEBCAM_PHOTO: {
            return {
                ...state,
                data: undefined,
            };
        }

        default:
            return state;
    }
};
