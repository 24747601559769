import { FC, useContext } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ImprovementDetailsContext, {
    ImprovementContextType,
} from "../../../improvementContext";
import ListItems, {
    EParentType,
} from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import ListItemsDropdown from "common/components/listed-content/components/SidePanel/List/components/ListItemsDropdown/ListItemsDropdown";
import ListedContent from "common/components/listed-content/ListedContent";

interface Props {}
const SidePanelList: FC<Props> = () => {
    const { mobileView } = useContext(ImprovementDetailsContext);
    const { t } = useTranslation();

    return (
        <ListedContent.SidePanel.List
            context={ImprovementDetailsContext}
            title={t(k.IMPROVEMENT_STEPS)}
        >
            {mobileView ? (
                <ListItemsDropdown<ImprovementContextType>
                    context={ImprovementDetailsContext}
                    parentType={EParentType.improvement}
                />
            ) : (
                <ListItems<ImprovementContextType>
                    context={ImprovementDetailsContext}
                    parentType={EParentType.improvement}
                />
            )}
        </ListedContent.SidePanel.List>
    );
};

export default SidePanelList;
