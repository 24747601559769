import "./BarcodeIcon.scss";

import React from "react";
import { BiBarcodeReader } from "react-icons/bi";

interface IProps {
    invalid?: boolean;
}

const BarcodeIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { invalid } = props;

    return (
        <BiBarcodeReader
            className={
                invalid ? "icon-barcode icon-barcode--invalid" : "icon-barcode"
            }
        />
    );
};

export default BarcodeIcon;
