import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import CustomListItemTasklist from "./CustomListItemTasklist";
import { IUserDefinedCustomListItemValueRequest } from "../../api/IUserDefinedCustomListItemValueRequest";
import {
    useDeleteCustomListItemMutation,
    useSaveUserDefinedCustomListItemValue,
} from "components/custom-list-item-page/api/hooks";

const CustomListItemTasklistSelector: React.FC<
    ISharedPropertyInputProps<string>
> = (props) => {
    const { options, customListItemId } = props;

    const { propertyCustomListId } = props.property;

    const [optionsState, setOptionsState] = useState<
        IValueLabelItemWithState[]
    >(options ?? []);

    useEffect(() => {
        if (options) {
            setOptionsState(options);
        }
    }, [options]);

    const saveMutation = useSaveUserDefinedCustomListItemValue();

    const deleteTaskMutation = useDeleteCustomListItemMutation();

    const handleAddTask = async (newTask?: IValueLabelItemWithState) => {
        if (newTask) {
            setOptionsState((prev) => prev.concat(newTask));

            var request: IUserDefinedCustomListItemValueRequest = {
                id: newTask.value,
                label: newTask.label,
                customListId: propertyCustomListId ?? "",
                ownerId: customListItemId ?? "",
            };

            await saveMutation.mutateAsync(request);

            props.onChange("", [], true);

            setOptionsState((prev) =>
                prev.map((item) => {
                    if (item.value === newTask.value) {
                        return {
                            ...item,
                            isLoading: false,
                            isUserDefined: true,
                        };
                    }

                    return item;
                }),
            );
        }
    };

    const handleChangeEditMode = (id: string) => {
        setOptionsState((prevItems) =>
            prevItems.map((item) => {
                if (item.value === id) {
                    return { ...item, isInEditMode: !item.isInEditMode };
                }
                return item;
            }),
        );
    };

    const handleChangeLabel = async (id: string, newLabel?: string) => {
        if (newLabel) {
            setOptionsState((prevItems) =>
                prevItems.map((item) => {
                    if (item.value === id) {
                        return {
                            ...item,
                            isInEditMode: false,
                            label: newLabel ?? "",
                            isLoading: true,
                        };
                    }
                    return item;
                }),
            );

            var request: IUserDefinedCustomListItemValueRequest = {
                id,
                label: newLabel,
                customListId: propertyCustomListId ?? "",
                ownerId: customListItemId ?? "",
                isEdit: true,
            };

            await saveMutation.mutateAsync(request);

            setOptionsState((prev) =>
                prev.map((item) => {
                    if (item.value === id) {
                        return {
                            ...item,
                            isLoading: false,
                        };
                    }

                    return item;
                }),
            );
        }

        props.onChange("", [], true);
    };

    const handleDeleteTask = async (id: string) => {
        const result = await deleteTaskMutation.mutateAsync({ id });

        if (result.Succeeded) {
            setOptionsState((prevItems) =>
                prevItems.filter((item) => item.value !== id),
            );
        } else {
            toast.dismiss();
            toast.error(result.Messages._error, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <CustomListItemTasklist
            {...props}
            options={optionsState}
            onTaskAddNew={handleAddTask}
            onChangeEditMode={handleChangeEditMode}
            onChangeLabel={handleChangeLabel}
            onDeleteTask={handleDeleteTask}
        />
    );
};

export default CustomListItemTasklistSelector;
