import React from "react";

function setPageTitle(title: string) {
    document.title = `AmpliFlow | ${title}`;
}

export const usePageTitle = (title: string) => {
    React.useEffect(() => {
        setPageTitle(title);
    }, [title]);
};

export interface IRedirectState {
    from?: string;
}
