import { Dispatch, SetStateAction } from "react";

import IValidationMessages from "common/viewModels/IValidationMessages";
import ITableSet from "http/ITableSet";
import k from "i18n/keys";
import IStep from "models/IStep";

export const handleOnChangeStepUtil = (
    step: IStep,
    setSteps?: Dispatch<SetStateAction<ITableSet<IStep>>>,
) => {
    setSteps?.((prev) => {
        return {
            ...prev,
            values: {
                ...prev.values,
                [step.id]: { ...step, isChanged: true },
            },
        };
    });
};

export const handleOnChangeTeamsUtil = (
    newTeamIds: string[],
    isTeamEveryoneSelected: boolean,
    step: IStep,
    setSteps?: Dispatch<SetStateAction<ITableSet<IStep>>>,
    setErrors?: Dispatch<SetStateAction<IValidationMessages | undefined>>,
) => {
    handleOnChangeStepUtil(
        {
            ...step,
            isTeamEveryone: isTeamEveryoneSelected,
            teamIds: newTeamIds,
        },
        setSteps,
    );

    const nameKey = `step.${step.id}.teams`;

    if (newTeamIds.length || isTeamEveryoneSelected) {
        setErrors?.((prev) => {
            return {
                ...prev,
                [nameKey]: undefined,
            };
        });
    } else {
        setErrors?.((prev) => {
            return {
                ...prev,
                [nameKey]: k.TEAM_REQUIRED,
            };
        });
    }
};
