import "./TextHighlightWrapper.scss";
import { useMemo } from "react";

interface IProps {
    textToReplace?: string;
    text?: string;
    ignoreCase?: boolean;
}

const TextHighlightWrapper = (props: IProps) => {
    const { textToReplace, text, ignoreCase } = props;
    const highlightedText = useMemo(() => {
        if (!textToReplace || !text) {
            return text;
        }

        const output = [];
        let index = 0;

        while (index < text.length) {
            const phraseIndex = ignoreCase
                ? text
                      .toLocaleLowerCase()
                      .indexOf(textToReplace.toLocaleLowerCase(), index)
                : text.indexOf(textToReplace, index);

            if (phraseIndex === -1) {
                const remainder = text.substring(index);
                output.push(remainder);
                break;
            }

            const textUntilMatch = text.substring(index, phraseIndex);

            if (textUntilMatch) {
                output.push(textUntilMatch);
            }

            output.push(
                <span
                    className="text-highlight-wrapper-match-search-filter"
                    key={phraseIndex}
                >
                    {text.substring(
                        phraseIndex,
                        phraseIndex + textToReplace.length,
                    )}
                </span>,
            );

            index = phraseIndex + textToReplace.length;
        }

        return <>{output}</>;
    }, [textToReplace, text]);

    return highlightedText;
};

export default TextHighlightWrapper;
