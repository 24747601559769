import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { useCustomListOptionById } from "components/custom-list-page/api/hooks";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import { useLocation } from "react-router-dom";
import CustomListItemTable from "components/custom-list-item-page/components/CustomListItemTable";
import InputWrapper from "common/components/input-components/InputWrapper";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import ValidationLabel from "components/common/validation/ValidationLabel";

const CustomListItemReferenceSelector: React.FC<
    ISharedPropertyInputProps<string>
> = (props) => {
    const {
        customListItemId,
        values,
        errors,
        disabled,
        preview,
        showArchivedOrDeleted,
    } = props;

    const {
        parentId,
        propertyCustomListId,
        mutualProperty,
        isRequired,
        label,
        allowMultiple,
    } = props.property;

    const modalManagerContext = useContext(ModalManagerContext);

    const { t } = useTranslation();

    const location = useLocation();

    const { data: customListOption } =
        useCustomListOptionById(propertyCustomListId);

    const handleSelectMany = (e: IInputChangeEvent<string>) => {
        if (allowMultiple) {
            const nextValues = e.selectedIds;

            props.onChange(parentId, nextValues ?? []);
        } else {
            const nextValue = e.value;

            props.onChange(parentId, nextValue ? [nextValue] : []);
        }
    };

    const handlePreview = (itemId: string) => {
        const payload: IShowModalPayload = {
            id: itemId,
            type: ModalTypes.custom_list,
            customList: customListOption
                ? { id: customListOption.id, path: customListOption.path }
                : undefined,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    return (
        <>
            {preview ? (
                <InputWrapper
                    htmlFor={`form.${parentId}`}
                    wrapperLabel={
                        <>
                            {label}
                            {isRequired && (
                                <React.Fragment>
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            )}
                        </>
                    }
                >
                    {propertyCustomListId && (
                        <CustomListItemTable
                            customListId={propertyCustomListId}
                            customListItemId={customListItemId ?? undefined}
                            mutualProperty={mutualProperty}
                            showEmpty
                            showAboveHeaderFilters
                            className="input-component--input table--wrapper"
                            onRowSelected={handlePreview}
                        />
                    )}
                </InputWrapper>
            ) : (
                <InputComponent
                    inputType={EActivityType.CustomList}
                    id={`form.${parentId}`}
                    customListId={propertyCustomListId}
                    customListItemId={customListItemId ?? undefined}
                    showArchivedOrDeleted={showArchivedOrDeleted ?? undefined}
                    mutualProperty={mutualProperty}
                    value={(values && values[0]) ?? ""}
                    selectedIds={values ?? undefined}
                    isClearable
                    isSearchable
                    bold
                    placeholder={t(k.SELECT)}
                    isMulti={allowMultiple}
                    invalid={Boolean(errors[parentId])}
                    onChange={handleSelectMany}
                    disabled={disabled}
                    helperText={<ValidationLabel errors={errors[parentId]} />}
                    hideIcon
                    wrapperLabel={
                        <>
                            {label}
                            {isRequired && (
                                <React.Fragment>
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            )}
                        </>
                    }
                />
            )}
        </>
    );
};

export default CustomListItemReferenceSelector;
