import { GroupBase } from "react-select";
import { OptionType } from "../SelectDropdownTypes";

const formatGroupLabel = <Option extends OptionType>(
    group: GroupBase<Option>,
) => {
    return (
        <div className="app-select-dropdown--group-heading">{group.label}</div>
    );
};

export default formatGroupLabel;
