import React from "react";
import { Modal as ModalDialog } from "react-bootstrap";

import { useModalContext } from "./Context";
import SidebarButton from "common/components/buttons/sidebar-button/SidebarButton";
import { IModalHeaderProps } from "../IModalComposition";

const Header: React.FC<React.PropsWithChildren<IModalHeaderProps>> = (
    props,
) => {
    const context = useModalContext();

    const { title } = props;

    return props.children ? (
        <ModalDialog.Header closeButton>
            {props.onSidebarClick && (
                <SidebarButton onClick={props.onSidebarClick} />
            )}
            <ModalDialog.Title>{title}</ModalDialog.Title>
            {props.children}
        </ModalDialog.Header>
    ) : null;
};

export default Header;
