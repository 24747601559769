import React from "react";

import { IFilterProps } from "../api/IFilterProps";
import { ActivityType } from "models/enums/ActivityType";
import ColumnFilteringComponent from "../custom-list/ColumnFilteringComponent";
import {
    useChecklistActivitySelectionOptions,
    useChecklistActivityTasklistOptions,
} from "./api/hooks";

interface IProps extends IFilterProps {}

export const ChecklistSelectionFilter = (props: IProps) => {
    const { value } = props;

    const propertyType = value?.type;

    const [isVisible, setIsVisible] = React.useState(false);

    const selectedActivityVersionId = isVisible
        ? props.columnKeyId ?? undefined
        : undefined;

    const selectionOptionsResult = useChecklistActivitySelectionOptions(
        propertyType == ActivityType.Selection
            ? selectedActivityVersionId
            : undefined,
    );

    const tasklistOptionsResult = useChecklistActivityTasklistOptions(
        propertyType == ActivityType.Tasklist
            ? selectedActivityVersionId
            : undefined,
    );

    const handleFilter = (newIds: string[]) => {
        if (propertyType !== undefined) {
            props.onFilter({
                type: propertyType,
                selectionFilter: newIds,
                isReset: newIds.length === 0,
            });
        }
    };

    const isLoading =
        selectionOptionsResult.isLoading || tasklistOptionsResult.isLoading;

    const menuOptions =
        selectionOptionsResult.data ?? tasklistOptionsResult.data;

    return (
        <ColumnFilteringComponent
            isLoading={isLoading}
            menuOptions={menuOptions}
            selectedIds={value?.selectionFilter}
            onShow={setIsVisible}
            onFilter={handleFilter}
        />
    );
};
