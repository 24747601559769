import { IInputProps } from "../IInputProps";
import { isUrlValid } from "common/components/validation/UrlValidation";
import Textfield from "./Textfield";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";

type Props = IInputProps<string>;

const HyperlinkField = <V,>(props: Props) => {
    const {
        id,
        name,
        required,
        disabled,
        value,
        error,
        parentId,
        invalid,
        placeholder,
    } = props;

    const handleChange = (e: IInputActionChangeEvent<string>) => {
        const value = e.value;
        const isValid = isUrlValid(value) || value.trim().length === 0;

        props.onChange?.({
            id,
            name,
            value,
            parentId,
            invalid: !isValid,
        });
    };

    return (
        <ActionTextField
            id={`form.${parentId}`}
            name={name}
            isLink
            editMode
            withDebounce
            value={value}
            invalid={invalid}
            required={required}
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
            error={
                <Textfield.Error>
                    {typeof error === "string"
                        ? error
                        : error?.map((x) => <div key={x}>{x}</div>)}
                </Textfield.Error>
            }
        />
    );
};

export default HyperlinkField;
