import React from "react";

import { AttachmentType } from "models/enums/AttachmentType";
import IAttachment from "models/IAttachment";
import { DownloadFileLink } from "components/common/download-file-link/DownloadFileLink";

interface IProps {
    attachment: IAttachment;
}

const AttachmentListItem: React.FC<IProps> = (props) => {
    const { attachment } = props;

    if (attachment.attachmentType === AttachmentType.File) {
        return (
            <div className="attachment" key={attachment.id}>
                <div className="icon-field">
                    <i className="icon-attach-1"></i>
                </div>
                {attachment.isNew ? (
                    attachment.name
                ) : (
                    <DownloadFileLink id={attachment.id} name={attachment.name}>
                        {attachment.name}
                    </DownloadFileLink>
                )}
            </div>
        );
    }

    return (
        <div className="link" key={attachment.id}>
            <a href={`${attachment.content}`} target="_blank">
                <i className="icon-link"></i>
                {attachment.name}
            </a>
        </div>
    );
};

export default AttachmentListItem;
