import { CloseButton } from "./components";

import ListedContent from "common/components/listed-content/ListedContent";

const SidePanelHeader = () => {
    return (
        <ListedContent.SidePanel.Header>
            <CloseButton />
        </ListedContent.SidePanel.Header>
    );
};

export default SidePanelHeader;
