import "./CreateFormula.scss";

import {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
    useState,
} from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { v4 as uuidv4 } from "uuid";

import RenderFormula from "./components/RenderFormula";
import { SortEnd } from "react-sortable-hoc";
import { arrayMoveManyTo } from "common/utils/arrayUtils";
import {
    EFormulaCategory,
    FormulaCategoryDefault,
    FormulaItemTypeDefault,
    IFormulaItem,
    convertToFormulaItemsDto,
    getFormulaItemTypeByValue,
    getUnsetItem,
    makeTableSet,
    splitString,
} from "../../utility/FormulaTools";
import ITableSet from "http/ITableSet";

import AddDataSourceModal from "./AddDataSourceModal";
import FormulaInputField from "./components/FormulaInputField";
import { setValidationErrors } from "../../utility/FormulaValidationTools";
import { IVariableDataSourceDto } from "../../api/IVariableDataSourceDto";
import { showConfirmNoThunk } from "store/confirms/actions";
import { useDispatch } from "react-redux";
import IValidationMessages from "common/viewModels/IValidationMessages";
import KpiContentWrapper from "../KpiContentWrapper";
import { useValidateFormulaMutation } from "../../api/hooks";

export const addUnsetItems = (items?: ITableSet<IFormulaItem>) => {
    const firstId = uuidv4();

    const result = {
        ids: [firstId],
        values: { [firstId]: getUnsetItem(firstId) },
    } as ITableSet<IFormulaItem>;

    if (items) {
        items.ids.forEach((id) => {
            const newId = uuidv4();
            result.ids.push(id, newId);
            result.values[id] = items.values[id];
            result.values[newId] = getUnsetItem(newId);
        });
    }

    return result;
};

interface IProps {
    stepRef: RefObject<HTMLDivElement>;
    dataId: string;
    kpiFormula: { formulaId: string; formulaItems: ITableSet<IFormulaItem> };
    setKpiFormula: Dispatch<
        SetStateAction<{
            formulaId: string;
            formulaItems: ITableSet<IFormulaItem>;
        }>
    >;
    kpiId?: string;
    allErrors?: IValidationMessages;
    errors?: IValidationMessages;
    onHaveChanges: (haveChanges: boolean) => void;
}

const CreateFormula = (props: IProps) => {
    const {
        kpiFormula: { formulaItems },
        stepRef,
        dataId,
        errors,
        allErrors,
        kpiId,
    } = props;

    const validateMutation = useValidateFormulaMutation();

    const [formulaItemId, setFormulaItemId] = useState<string>();

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [focusedInput, setFocusedInput] = useState<string | undefined>(
        undefined,
    );

    const handleFocusChange = (inputId?: string) => {
        setFocusedInput(inputId);
    };

    const [formulaErrors, setFormulaErrors] = useState<IValidationMessages>({});

    useEffect(() => {
        if (errors) {
            setFormulaErrors(errors);
        }
    }, [errors]);

    const handleValidateFormula = async () => {
        const result = await validateMutation.mutateAsync({
            value: {
                id: props.kpiFormula.formulaId,
                formulaItems: convertToFormulaItemsDto(formulaItems),
            },
        });

        setFormulaErrors(
            setValidationErrors(
                result.Messages,
                props.kpiFormula.formulaItems.ids,
                i18n,
            ),
        );
    };

    const handleOnAddDataSource = (id: string) => {
        setFormulaItemId(id);
    };

    const handleCloseAddDataSource = () => {
        setFormulaItemId(undefined);
    };
    const handleSaveDataSource = (
        formulaItemId: string,
        dataSource: IVariableDataSourceDto,
    ) => {
        let item = formulaItems.values[formulaItemId];

        if (item) {
            item.variable = {
                ...item?.variable,
                dataSource: { ...dataSource, isChanged: true },
            };

            props.setKpiFormula((prev) => {
                return {
                    ...prev,
                    formulaItems: {
                        ...prev.formulaItems,
                        values: {
                            ...prev.formulaItems.values,
                            [formulaItemId]: item,
                        },
                    },
                };
            });
        }
        setFormulaItemId(undefined);
    };

    const handleOnChange = (id: string, item: IFormulaItem) => {
        props.setKpiFormula((prev) => ({
            ...prev,
            formulaItems: {
                ...prev.formulaItems,
                values: {
                    ...prev.formulaItems.values,
                    [id]: item,
                },
            },
        }));

        props.onHaveChanges(true);
    };

    const handleOnFormulaMove = (sortEnd: SortEnd) => {
        const sortedItems = arrayMoveManyTo(
            formulaItems.ids,
            sortEnd.oldIndex,
            sortEnd.newIndex,
            2,
        );

        props.setKpiFormula((prev) => ({
            ...prev,
            formulaItems: {
                ids: sortedItems,
                values: prev.formulaItems.values,
            },
        }));

        props.onHaveChanges(true);
    };

    const handleOnChangeValue = (id: string, value: string) => {
        props.setKpiFormula((prev) => {
            return {
                ...prev,
                formulaItems: {
                    ...prev.formulaItems,
                    values: {
                        ...prev.formulaItems.values,
                        [id]: {
                            ...prev.formulaItems.values[id],
                            value,
                            type: getFormulaItemTypeByValue(value),
                        } as IFormulaItem,
                    },
                },
            };
        });

        props.onHaveChanges(true);
    };

    const handleOnInitItems = (items: IFormulaItem[]) => {
        const item = items[0];

        if (items.length === 1) {
            props.setKpiFormula((prev) => {
                const newUnsetItemOne = getUnsetItem();
                const newUnsetItemTwo = getUnsetItem();

                const newIds = [...prev.formulaItems.ids];

                const index = newIds.indexOf(item.id);

                newIds.splice(
                    index,
                    1,
                    ...[newUnsetItemOne.id, item.id, newUnsetItemTwo.id],
                );

                return {
                    ...prev,
                    formulaItems: {
                        ids: newIds,
                        values: {
                            ...prev.formulaItems.values,
                            [item.id]: item,
                            [newUnsetItemOne.id]: newUnsetItemOne,
                            [newUnsetItemTwo.id]: newUnsetItemTwo,
                        },
                    },
                };
            });
        } else {
            props.setKpiFormula((prev) => {
                const newItems = addUnsetItems(makeTableSet(items));

                const newIds = [...prev.formulaItems.ids];

                const index = newIds.indexOf(item.id);

                newIds.splice(index, 1, ...newItems.ids);
                return {
                    ...prev,
                    formulaItems: {
                        ids: newIds,
                        values: {
                            ...prev.formulaItems.values,
                            ...newItems.values,
                        },
                    },
                };
            });
        }

        props.onHaveChanges(true);
    };

    const handleOnRemove = (id: string) => {
        props.setKpiFormula((prev) => {
            const ids = prev.formulaItems.ids;
            const index = ids.indexOf(id);
            const nextId = ids[index + 1];

            if (index !== -1 && index + 1 < ids.length) {
                const updatedIds = ids.filter(
                    (x, i) => i !== index && i !== index + 1,
                );

                return {
                    ...prev,
                    formulaItems: {
                        ids: updatedIds,
                        values: {
                            ...prev.formulaItems.values,
                            [id]: undefined,
                            [nextId]: undefined,
                        },
                    },
                };
            }

            return prev;
        });

        props.onHaveChanges(true);
    };

    const handleOnRemoveInput = async (id: string) => {
        if (!!formulaItems.values[id]?.variable?.dataSource) {
            if (await showConfirmNoThunk(dispatch, t(k.ARE_YOU_SURE))) {
                handleOnRemove(id);
            }
        } else {
            handleOnRemove(id);
        }
    };

    const handleOnPasteFormula = (formula: string) => {
        const match = splitString(formula);

        if (match) {
            props.setKpiFormula((prev) => ({
                ...prev,
                formulaItems: addUnsetItems(makeTableSet(match)),
            }));

            props.onHaveChanges(true);
        }
    };

    const handleClearFormula = () => {
        const unsetItem = getUnsetItem();

        props.setKpiFormula((prev) => ({
            ...prev,
            formulaItems: {
                ids: [unsetItem.id],
                values: { [unsetItem.id]: unsetItem },
            },
        }));

        props.onHaveChanges(true);
    };

    const handleOnAddElement = (category: EFormulaCategory) => {
        const newItem = {
            id: uuidv4(),
            category,
            value: FormulaCategoryDefault[category],
            type: FormulaItemTypeDefault[category],
        };
        const newUnsetItemOne = getUnsetItem();

        props.setKpiFormula((prev) => ({
            ...prev,
            formulaItems: {
                ids: [...prev.formulaItems.ids, newItem.id, newUnsetItemOne.id],
                values: {
                    ...prev.formulaItems.values,
                    [newItem.id]: newItem,
                    [newUnsetItemOne.id]: newUnsetItemOne,
                },
            },
        }));

        setFocusedInput(newItem.id);

        props.onHaveChanges(true);
    };

    const handleOnRemoveElement = (id: string) => handleOnRemoveInput(id);

    return (
        <KpiContentWrapper
            stepRef={stepRef}
            dataId={dataId}
            title={t(k.FORMULA)}
        >
            <FormulaInputField
                allErrors={allErrors}
                errors={formulaErrors}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                onValidate={handleValidateFormula}
                onDeleteItem={handleOnRemoveElement}
                items={formulaItems}
                onChange={handleOnChange}
                onInitItems={handleOnInitItems}
                onPasteFormula={handleOnPasteFormula}
                onClearFormula={handleClearFormula}
            />

            <RenderFormula
                focusedInput={focusedInput ?? ""}
                items={formulaItems}
                errors={formulaErrors}
                onFormulaMove={handleOnFormulaMove}
                onChangeValue={handleOnChangeValue}
                onAddElement={handleOnAddElement}
                onRemoveElement={handleOnRemoveElement}
                onAddDataSource={handleOnAddDataSource}
            />

            {formulaItemId && (
                <AddDataSourceModal
                    kpiId={kpiId}
                    formulaItemId={formulaItemId}
                    data={
                        formulaItemId
                            ? formulaItems.values[formulaItemId]?.variable
                                  ?.dataSource
                            : undefined
                    }
                    onHide={handleCloseAddDataSource}
                    onSave={handleSaveDataSource}
                />
            )}
        </KpiContentWrapper>
    );
};

export default CreateFormula;
