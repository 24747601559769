import "./UserDropdown.scss";

import React from "react";

import Dropdown, { IDropdownProps } from "./Dropdown";
import { IInputProps } from "../IInputProps";
import { ESharedOptionsType, useSharedOptions } from "./api/hooks";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { useContext } from "react";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";

type Props = IDropdownProps & IInputProps<string>;

const UserDropdown = (props: Props) => {
    const modalManagerContext = useContext(ModalManagerContext);
    const { data: sharedOptions, refetch } = useSharedOptions(
        false,
        ESharedOptionsType.user,
        props.selectedIds,
    );

    React.useEffect(() => {
        refetch();
    }, []);

    const onPreviewSelectedOption = (userId: string) => {
        const payload: IShowModalPayload = {
            id: userId,
            type: ModalTypes.user_form,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    return (
        <Dropdown
            {...props}
            onPreviewSelectedOption={onPreviewSelectedOption}
            options={props.options ?? sharedOptions}
            icon={<UserIcon />}
        />
    );
};

export default UserDropdown;
