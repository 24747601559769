import {
    HIDE_IMAGE_PREVIEW,
    SHOW_IMAGE_PREVIEW,
    SHOW_IMAGE_PREVIEW_BY_ID,
    ImagesActionTypes,
} from "./types";

export const showImage = (value: string): ImagesActionTypes => {
    return {
        type: SHOW_IMAGE_PREVIEW,
        payload: value,
    };
};

export const showImageById = (imgId: string): ImagesActionTypes => {
    return {
        type: SHOW_IMAGE_PREVIEW_BY_ID,
        payload: imgId,
    };
};

export const hideImage = (): ImagesActionTypes => {
    return {
        type: HIDE_IMAGE_PREVIEW,
    };
};
