import { IValueLabelItem } from "common/IValueLabelItem";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IImpactGradingOption } from "components/impact-grading-page/api/IImpactGradingOption";
import ITableSet from "http/ITableSet";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import IActivity from "models/IActivity";
import * as yup from "yup";

import { improvementSchema } from "./ValidateImprovement";

// find all required inputs and create a schema for validation
export const createCustomValidationSchema = ({
    stepId,
    activities,
    activityIdsByStepId,
    activityInputs,
    activityInputsIdsByActivityIds,
    impactGradingsFeature,
    impactedProcessesFeature,
    impactAreas,
}: {
    stepId: string;
    activities?: ITableSet<IActivity>;
    activityIdsByStepId?: IDictionary<string[]>;
    activityInputs?: ITableSet<IActivityInputListItem>;
    activityInputsIdsByActivityIds?: IDictionary<string[]>;
    impactGradingsFeature?: boolean;
    impactedProcessesFeature?: boolean;
    impactAreas?: IImpactGradingOption[];
}) => {
    const activityIds = activityIdsByStepId && activityIdsByStepId[stepId];
    const hasNoImpactAreas = !impactGradingsFeature || !impactAreas?.length;

    if (activityIds) {
        //activities (of a specific step) that dont have multiple inputs and are required
        const requiredActivityIds = activityIds.filter(
            (id) => activities?.values[id]?.isRequired,
        );

        //activities (of a specific step) such as tasklist - have multiple inputs that might or might not be required
        const activityInputIds = activityIds.map(
            (id) =>
                activityInputsIdsByActivityIds &&
                activityInputsIdsByActivityIds[id],
        );

        //returns an  array of child ids for each activity with multiple inputs, makes a flat array and removes nullish values
        const filteredInputIds = activityInputIds.flat().filter((id) => !!id);

        //only required inputs from the activities that have multiple inputs
        const requiredActivityInputs = filteredInputIds.reduce(
            (acc: string[], id) => {
                if (id && activityInputs?.values[id]?.isRequired) acc.push(id);
                return acc;
            },
            [],
        );

        const allRequiredInputs = requiredActivityIds.concat(
            requiredActivityInputs,
        );

        //make an object with all the required ids as properties
        const additionalSchema = allRequiredInputs.reduce(
            (acc, input) => ({
                ...acc,
                [input]: yup.string().min(1, "Required").required("Required"),
            }),
            {},
        );

        return yup.object({
            ...improvementSchema({
                impactGradingsFeature,
                impactedProcessesFeature,
            }),
            ...additionalSchema,
        });
    }

    return yup.object({
        ...improvementSchema({
            impactGradingsFeature,
            impactedProcessesFeature,
            isEmptyImpactList: hasNoImpactAreas,
        }),
    });
};

export const getInvalidActivities = (
    errors: IValidationMessages,
    stepId: string,
    activities?: ITableSet<IActivity>,
    activityIdsByStepId?: IDictionary<string[]>,
    activityInputs?: ITableSet<IActivityInputListItem>,
    activityInputsIdsByActivityIds?: IDictionary<string[]>,
) => {
    const allActivityIds: string[] =
        (activityIdsByStepId && activityIdsByStepId[stepId]) ?? [];

    const result = allActivityIds.reduce<
        Array<IValueLabelItem<number, string, number>>
    >((map, id, parentIndex) => {
        const activityIds = activityInputsIdsByActivityIds?.[id] ?? [id];

        if (activityIds) {
            const len = activityIds.length;

            for (let i = 0; i < len; i++) {
                const activityId = activityIds[i];

                const item =
                    activityInputs?.values[activityId] ??
                    activities?.values[activityId];

                if (item && errors[item.id]) {
                    let result: IValueLabelItem<number, string, number>;

                    if (len === 1) {
                        result = {
                            value: parentIndex + 1,
                            label: item.label,
                        };
                    } else {
                        result = {
                            value: i + 1,
                            label: item.label,
                            param: parentIndex + 1,
                        };
                    }

                    map.push(result);
                }
            }
        }

        return map;
    }, []);

    return result;
};
