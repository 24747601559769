import React from "react";

import { IActivityProps } from "./IActivityProps";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityCustomListDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<CustomListIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.CUSTOM_LIST)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityCustomListDropdown;
