import React from "react";

import { IActivityProps } from "./IActivityProps";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { BarcodeIcon } from "common/components/icons";
import { getValue } from "common/utils/inputUtils";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";

const ActivityBarcodeField: React.FC<IActivityProps> = (props) => {
    const { activity, typeField, isEditMode } = props;

    const { t } = useTranslation();

    const handleChange = (event: any) => {
        const [name, value] = getValue(event);

        const args: IInputChangeEvent<string> = {
            id: name,
            name,
            value,
        };

        props.onChange(args);
    };

    const typeList: React.ReactElement = isEditMode ? (
        <div className="step-activity-options">
            <div className="step-activity-options--label">{t(k.OPTIONS)}</div>

            <div className="step-activity-options--field">
                <div className="icon">
                    <BarcodeIcon />
                </div>
                <select
                    name="acceptMultiple"
                    value={String(activity.acceptMultiple)}
                    className="activity-options"
                    onChange={handleChange}
                >
                    <option value={String(true)}>
                        {t(k.ACCEPT_MULTIPLE_BARCODES)}
                    </option>
                    <option value={String(false)}>
                        {t(k.ACCEPT_A_SINGLE_BARCODE)}
                    </option>
                </select>
            </div>
        </div>
    ) : (
        <React.Fragment />
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.BARCODES)}`,
    });
};

export default ActivityBarcodeField;
