import "./CalendarIcon.scss";

import React, { useMemo } from "react";
import { FiCalendar } from "react-icons/fi";

interface IProps {
    disabled?: boolean;

    variant?: "white";

    invalid?: boolean;
}

const CalendarIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { disabled, variant, invalid } = props;

    const className = useMemo(() => {
        const result = ["icon-calendar"];

        if (disabled) {
            result.push("icon-calendar--disabled");
        }

        if (variant) {
            result.push(`icon-calendar--${variant}`);
        }

        if (invalid) {
            result.push(`icon-calendar--invalid`);
        }

        return result.join(" ");
    }, [disabled, variant, invalid]);

    return <FiCalendar className={className} />;
};

export default CalendarIcon;
