import "./TeamsAppNavBar.scss";

import { Fragment, PropsWithChildren, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import AdressBarButton from "./components/AdressBarButton";

import Confirm from "../modals/confirm/Confirm";
import UsernameLogout from "./components/UsernameLogout";
import { ArrowRightIcon } from "../icons";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ITeamsAppLayoutProps } from "./TeamsAppLayout";

export interface INavBarProps extends ITeamsAppLayoutProps {
    navBar: IValueLabelItem<string, string>[];
}

const TeamsAppNavBar = (props: PropsWithChildren<INavBarProps>) => {
    const { profile, localeSwitch, navBar } = props;

    const { t } = useTranslation();

    const [isLogoutConfirmVisible, setIsLogoutConfirmVisible] = useState(false);

    const handleSignOutConfirmShow = () => {
        setIsLogoutConfirmVisible(true);
    };

    const handleSignOutConfirmClose = (value: boolean) => {
        setIsLogoutConfirmVisible(false);

        if (value) {
            props.onSignOut();
        }
    };

    return (
        <div className="teams-app--menu-bar">
            <div className="teams-app--menu-bar--address">
                {isLogoutConfirmVisible && (
                    <Confirm
                        confirmBody={t(k.LOGOUT_CONFIRMATION)}
                        onClose={handleSignOutConfirmClose}
                    />
                )}

                <AdressBarButton key="Dashboard" to="/">
                    {t(k.DASHBOARD)}
                </AdressBarButton>
                {navBar.map(({ value, label, param }) => (
                    <Fragment key={value}>
                        <ArrowRightIcon />

                        <AdressBarButton
                            to={
                                value === "custom-list-item"
                                    ? undefined
                                    : `/${value}`
                            }
                        >
                            {label}
                        </AdressBarButton>
                    </Fragment>
                ))}
                <div className="vertical-separator"></div>
            </div>
            <div className="teams-app--menu-bar--user">
                {localeSwitch}
                <UsernameLogout
                    username={profile?.username}
                    onLogout={handleSignOutConfirmShow}
                />
            </div>
        </div>
    );
};

export default TeamsAppNavBar;
