import { useEffect, useRef, useState } from "react";
import { ListedContentContextType } from "./ListedContentContext";
const MOBILE_VIEW_WIDTH = 992;

export const useListedContentData = (): ListedContentContextType => {
    const listedContentRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const listItemDropdownRef = useRef<HTMLDivElement>(null);
    const detailsRef = useRef<HTMLDivElement>(null);
    const sidePanelRef = useRef<HTMLDivElement>(null);

    const [mobileView, setMobileView] = useState<boolean>(
        window.innerWidth < MOBILE_VIEW_WIDTH,
    );

    const checkWindowWidth = () => {
        const isMobile = window.innerWidth < MOBILE_VIEW_WIDTH;
        setMobileView(isMobile);
    };

    useEffect(() => {
        window.addEventListener("resize", checkWindowWidth);
        return () => window.removeEventListener("resize", checkWindowWidth);
    }, []);

    return {
        listedContentRef,
        contentRef,
        listItemDropdownRef,
        detailsRef,
        mobileView,
        sidePanelRef,
    };
};
