import "./Details.scss";

import { Context, ReactNode, RefObject, useContext } from "react";

import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";

export interface IDetailsInputProps<C extends ListedContentContextType> {
    context: Context<C>;
    footRef?: RefObject<HTMLDivElement>;
    description?: ReactNode;
}

const Details = <C extends ListedContentContextType>(
    props: React.PropsWithChildren<IDetailsInputProps<C>>,
) => {
    const { footRef, description } = props;

    const { mobileView, detailsRef } = useContext(props.context);

    return (
        <>
            <div
                ref={detailsRef}
                className={
                    "listed-content__side-panel__details" +
                    (mobileView ? "" : " side-panel--sticky")
                }
            >
                <div className="details__content">{props.children}</div>
            </div>
            {description && (
                <div className="listed-content__side-panel__details__description">
                    <div className="description__content">{description}</div>
                </div>
            )}
            <div
                ref={footRef}
                className={
                    "listed-content__side-panel__details__foot" +
                    (mobileView ? "" : " side-panel--sticky")
                }
            >
                <div className="foot__content" />
            </div>
        </>
    );
};

export default Details;
