import { RefObject } from "react";

/**
+ * printElements takes an array of RefObjects and prints them in that order.
+ * It clones each node, adds a class to it, appends it to the body, and then removes it after printing.
+ *
+ * @param nodes - An array of RefObjects to be printed.
+ */

export const printElements = (
    nodes: (RefObject<HTMLDivElement> | undefined)[],
) => {
    document.body.classList.add("custom-print-mode");

    const clonedNodes = nodes
        .map((node) => node?.current?.cloneNode(true) as HTMLElement)
        .filter(Boolean);

    clonedNodes.forEach((node) => {
        node.classList.add("print-target");
        document.body.appendChild(node);
    });

    setTimeout(() => {
        window.print();

        document.body.classList.remove("custom-print-mode");
        clonedNodes.forEach((node) => {
            node.remove();
        });
    }, 300);
};
