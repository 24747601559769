import { Action } from "redux";

export const SHOW_WEBCAM_MODAL = "SHOW_WEBCAM_MODAL";

export const HIDE_WEBCAM_MODAL = "HIDE_WEBCAM_MODAL";

export const RESET_CAPTURED_WEBCAM_PHOTO = "RESET_CAPTURED_WEBCAM_PHOTO";

interface IWebCamShowModalAction extends Action<typeof SHOW_WEBCAM_MODAL> {
    payload?: boolean;
}

interface IWebCamHideModalAction extends Action<typeof HIDE_WEBCAM_MODAL> {
    payload?: { content: string; fileName: string };
}

interface IWebCamResetCapturedPhotoAction
    extends Action<typeof RESET_CAPTURED_WEBCAM_PHOTO> {}

export type WebCamActionTypes =
    | IWebCamShowModalAction
    | IWebCamHideModalAction
    | IWebCamResetCapturedPhotoAction;
