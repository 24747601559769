import "../RecurringChecklistForm.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";

import DatePicker from "common/components/date-picker/DatePicker";
import Textfield from "common/components/input-components/textfield/Textfield";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

interface IProps {
    isEndDate: boolean;
    endDate?: Date;
    numberOfRuns?: number;

    errors: IValidationMessages;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;

    onBlur?: (id?: string, name?: string) => void;
}

const RecurringChecklistEndAt: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { isEndDate, endDate, numberOfRuns, errors } = props;

    const { t } = useTranslation();

    const handleSelectEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === "1";

        if (isEndDate !== value) {
            props.onChange(value, "isEndDate");
        }
    };

    const handleChangeDate = (date: Date) => {
        if (date !== endDate) {
            props.onChange(date, "endDate");

            if (isEndDate === false) {
                props.onChange(true, "isEndDate");
            }
        }
    };

    const handleChangeAfter = (value: string) => {
        const val = parseInt(value);

        props.onChange(isNaN(val) ? undefined : val, "numberOfRuns");

        if (isEndDate) {
            props.onChange(false, "isEndDate");
        }
    };

    return (
        <div className="r-c--body--repeat">
            {t(k.REPEAT_UNTIL)}
            <div className="r-c--body--repeat--column">
                <input
                    type="radio"
                    value="1"
                    id="endsAt"
                    checked={isEndDate}
                    onChange={handleSelectEndDate}
                />
                <label htmlFor="endsAt">
                    <span>{t(k.A_FIXED_DATE)}</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleChangeDate}
                        dateFormat="P"
                        placeholder={t(k.SELECT)}
                        invalid={!!errors.endDate}
                        error={errors.endDate && t(errors.endDate)}
                    />
                </label>
            </div>

            <div className="r-c--body--repeat--column">
                <input
                    type="radio"
                    value=""
                    id="after"
                    checked={isEndDate ? false : true}
                    onChange={handleSelectEndDate}
                />
                <label htmlFor="after">
                    <span>{t(k.AFTER)}</span>
                    <Textfield
                        id="numberOfRuns"
                        value={String(numberOfRuns ?? "")}
                        onChange={handleChangeAfter}
                        onBlur={props.onBlur}
                        type="number"
                        isWidthMinimized={true}
                        invalid={Boolean(errors.numberOfRuns)}
                        helperText={
                            <ValidationLabel
                                errors={
                                    errors.numberOfRuns &&
                                    t(errors.numberOfRuns)
                                }
                            />
                        }
                    />
                </label>
                {t(k.CHECKLISTS_RUN)}
            </div>
        </div>
    );
};

export default RecurringChecklistEndAt;
