import React from "react";
import { useCustomListBasicListOptions } from "components/custom-list-item-page/api/hooks";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import CustomListItemTasklistSelector from "./CustomListItemTasklistSelector";

const CustomListItemTasklistSelectorById: React.FC<
    ISharedPropertyInputProps<string>
> = (props) => {
    const { customListItemId, showArchivedOrDeleted } = props;

    const values = props.values ?? [];

    const { propertyCustomListId, isRequired, label } = props.property;

    const { data: options, isLoading } = useCustomListBasicListOptions(
        propertyCustomListId,
        customListItemId ?? undefined,
        showArchivedOrDeleted ?? undefined,
    );

    return (
        <CustomListItemTasklistSelector
            {...props}
            isRequired={isRequired}
            label={label}
            values={values}
            options={options}
            isLoadingOptions={isLoading}
        />
    );
};

export default CustomListItemTasklistSelectorById;
