import { HistoryActionType } from "./HistoryActionType";
import { HistoryType } from "./HistoryType";
import IHistoryDTO from "./IHistoryDTO";
import k from "i18n/keys";
import IDictionary from "common/viewModels/IDictionary";
import { HistoryOwnerEnum } from "./HistoryOwnerEnum";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ItemCategoryType } from "components/item-types/api/ItemCategoryType";

const formatChange = (
    t: (key: string) => string,
    prev: string | undefined | null,
    next: string | undefined | null,
    extra?: string,
    prefix?: string,
): string => {
    let result: string[] = [];

    if (prefix) {
        result.push(prefix);
    }

    result.push(t(k.CHANGED).toLowerCase());

    if (prev) {
        result.push(
            t(k.FROM).toLowerCase() + ' "' + t(prev).replace(/"/g, "") + '"',
        );
    }

    result.push(
        t(k.TO_).toLowerCase() + ' "' + t(next ?? "").replace(/"/g, "") + '"',
    );

    if (extra) {
        result.push(extra);
    }

    return result.join(" ");
};

const formatAction = (
    t: (key: string) => string,
    action: string,
    item: IHistoryDTO,
    path?: string,
    name?: string,
    extra?: string,
): string => {
    let result: string[] = [];

    if (item.type !== HistoryType.IMPORTED) {
        result.push(
            '"' +
                t(
                    name ?? item.value ?? item.previousValue ?? k.NOT_FOUND,
                ).replace(/"/g, "") +
                '"',
        );

        result.push(t(action).toLowerCase());
    }

    if (path) {
        if (action == k.ADDED) {
            result.push(t(k.TO_).toLowerCase());
        }
        if (item.type === HistoryType.CLOSURE_REASON) {
            result.push(t(path.replace(/"/g, "")));
        } else {
            result.push(t(path));
        }
    }

    if (extra) {
        result.push(extra);
    }

    return result.join(" ");
};

const formatUnfinalize = (
    t: (key: string) => string,
    action: string,
    extra?: string,
    item?: IHistoryDTO,
) => {
    let result: string[] = [];

    if (item?.ownerType === HistoryOwnerEnum.Improvement) {
        result.push('"' + t(item.value ?? k.NOT_FOUND).replace(/"/g, "") + '"');
    }

    result.push(t(action));

    if (extra) {
        result.push('"' + extra.replace(/"/g, "") + '"');
    }

    return result.join(" ");
};

const getPermissionName = (info?: string, useSplit = true) => {
    if (useSplit && info) {
        const lastUnderscoreIndex = info.lastIndexOf("_");
        if (lastUnderscoreIndex > -1) {
            return info.substring(lastUnderscoreIndex + 1);
        }
    }
    return info;
};

const getAssignPermissionText = (
    t: (key: string) => string,
    info?: string,
    useSplit: boolean = true,
) => {
    const permissionName = getPermissionName(info, useSplit);
    return `${t(k.AS_)} ${t(permissionName ?? k.NOT_FOUND)}`;
};

const getUnassignPermissionText = (
    t: (key: string) => string,
    info?: string,
    useSplit: boolean = true,
) => {
    const permissionName = getPermissionName(info, useSplit);
    return `${t(k.FROM_)} ${t(permissionName ?? k.NOT_FOUND)}`;
};

const getPathToChangedItemInSteps = (
    item: IHistoryDTO,
    stepInstanceNames: IDictionary<string>,
    activityInstanceSetNames: IDictionary<string>,
    activityInstanceNames: IDictionary<string>,
    aiSetIdsByAiIds: IDictionary<string>,
): string => {
    const stepInstanceName = item.stepInstanceId
        ? stepInstanceNames[item.stepInstanceId]
        : "";

    if (!stepInstanceName) return "";

    const aisName =
        item.valueId || item.nameReferenceId
            ? activityInstanceSetNames[
                  aiSetIdsByAiIds[item.valueId ?? item.nameReferenceId ?? ""]
              ]
            : "";
    const aiName = item.nameReferenceId
        ? activityInstanceNames[item.nameReferenceId]
        : "";

    const result = [];

    if (stepInstanceName) {
        result.push('"' + stepInstanceName + '"');
    }

    if (aisName) {
        result.push('"' + aisName + '"');
    }

    if (aiName) {
        result.push('"' + aiName + '"');
    }

    return result.join(" > ");
};

const getPathToChangedInputAction = (
    t: (key: string) => string,
    item: IHistoryDTO,
    inputActionNames: IDictionary<
        IValueLabelItem<string, string | undefined, string | undefined>
    >,
): string => {
    const inputAction = inputActionNames[item.ownerId ?? ""];
    const inputActionName = inputAction?.label ?? "";
    const inputActionSetName = inputAction?.param;

    const result = [""];

    if (inputActionName) {
        result.push('"' + inputActionName + '"');
    }

    if (inputActionSetName) {
        result.push(t(k.IN_) + ' "' + inputActionSetName + '"');
    }

    return result.join(" ");
};

export function renderItemText(
    item: IHistoryDTO,
    t: (key: string) => string,
    stepInstanceNames: IDictionary<string>,
    activityInstanceSetNames: IDictionary<string>,
    activityInstanceNames: IDictionary<string>,
    aiSetIdsByAiIds: IDictionary<string>,
    type: HistoryOwnerEnum = HistoryOwnerEnum.None,
    targetValues: IDictionary<string[]>,
    manualValues: IDictionary<string[]>,

    teamNames?: Record<string, string | undefined>,
    userNames?: Record<
        string,
        IValueLabelItem<string, string, undefined> | undefined
    >,
    formulaItemNames?: Record<string, string>,
    kpiNames?: IValueLabelItem<string, string>[],
    processNames?: IValueLabelItem<string, string>[],
    improvementFormNames?: IValueLabelItem<string, string>[],
    unitNames?: IValueLabelItem<string, string, ItemCategoryType>[],
    inputActionNames?: IDictionary<
        IValueLabelItem<string, string | undefined, string | undefined>
    >,
): string {
    const pathFromStepInstance =
        item.stepInstanceId &&
        getPathToChangedItemInSteps(
            item,
            stepInstanceNames,
            activityInstanceSetNames,
            activityInstanceNames,
            aiSetIdsByAiIds,
        );

    const pathFromInputAction =
        inputActionNames &&
        getPathToChangedInputAction(t, item, inputActionNames);

    var addedToFormulaItemName = formulaItemNames?.[item.nameReferenceId ?? ""];

    const pathFromFormulaItem =
        item.nameReferenceId &&
        `${
            item.actionType == HistoryActionType.REMOVED
                ? t(k.FROM)
                : t(k.TO_).toLowerCase()
        } "${addedToFormulaItemName}"`;

    const pathToItem =
        type == HistoryOwnerEnum.Kpi
            ? pathFromFormulaItem
            : type == HistoryOwnerEnum.Task
              ? pathFromInputAction
              : pathFromStepInstance;

    switch (item.actionType) {
        case HistoryActionType.UPDATED: {
            if (item.value == undefined) return "";

            let extraTarget =
                item.type === HistoryType.TARGET_VALUE
                    ? `${t(k.FOR_)} ${t(
                          targetValues[item.valueId ?? ""]?.[0],
                      )} ${t(targetValues[item.valueId ?? ""]?.[1])}`
                    : undefined;
            let extraManualValue =
                item.type === HistoryType.MANUAL_VALUE
                    ? `${t(k.FOR_)} ${t(
                          manualValues[item.valueId ?? ""]?.[0],
                      )} ${t(manualValues[item.valueId ?? ""]?.[1])}`
                    : "";
            let extra = extraTarget ?? extraManualValue;

            let formulaItemName =
                item.type === HistoryType.FORMULA_ITEM_INDEX &&
                formulaItemNames?.[item.valueId ?? ""];

            let prefix =
                item.type === HistoryType.FORMULA_ITEM_INDEX
                    ? `${t(k.OF)} ${formulaItemName}`
                    : type == HistoryOwnerEnum.Task
                      ? pathToItem
                      : "";

            let value =
                item.type === HistoryType.TARGET_UNIT ||
                item.type === HistoryType.UNIT
                    ? unitNames?.find((x) => x.value == item.valueId)?.label
                    : item.value;

            let previousValue =
                item.type === HistoryType.TARGET_UNIT ||
                item.type === HistoryType.UNIT
                    ? unitNames?.find((x) => x.value == item.previousValue)
                          ?.label
                    : item.previousValue;

            return formatChange(t, previousValue, value, extra, prefix);
        }
        case HistoryActionType.ADDED: {
            if (type == HistoryOwnerEnum.Kpi) {
                let extraTarget =
                    item.type === HistoryType.TARGET_VALUE
                        ? `${t(k.FOR_)} ${t(
                              targetValues[item.valueId ?? ""]?.[0],
                          )} ${t(targetValues[item.valueId ?? ""]?.[1])}`
                        : undefined;
                let extraManualValue =
                    item.type === HistoryType.MANUAL_VALUE
                        ? `${t(k.FOR_)} ${t(
                              manualValues[item.valueId ?? ""]?.[0],
                          )} ${t(manualValues[item.valueId ?? ""]?.[1])}`
                        : "";
                let extra = extraTarget ?? extraManualValue;

                let kpiName =
                    item.type == HistoryType.DATASOURCE_KPI
                        ? kpiNames?.find((x) => x.value == item.valueId)?.label
                        : undefined;

                let improvemntFormName =
                    item.type === HistoryType.IMPROVEMENT_FORM_FILTER
                        ? improvementFormNames?.find(
                              (x) => x.value == item.valueId,
                          )?.label
                        : undefined;

                let processName =
                    item.type === HistoryType.DISCOVERED_IN ||
                    item.type === HistoryType.ORIGINATED_IN
                        ? processNames?.find((x) => x.value == item.valueId)
                              ?.label
                        : undefined;

                let unitName =
                    item.type === HistoryType.TARGET_UNIT ||
                    item.type === HistoryType.UNIT
                        ? unitNames?.find((x) => x.value == item.valueId)?.label
                        : undefined;
                let name =
                    kpiName ?? improvemntFormName ?? processName ?? unitName;

                return formatAction(t, k.ADDED, item, pathToItem, name, extra);
            }
            return formatAction(t, k.ADDED, item, pathToItem);
        }

        case HistoryActionType.REMOVED:
            if (type == HistoryOwnerEnum.Kpi) {
                let extraTarget =
                    item.type === HistoryType.TARGET_VALUE
                        ? `${t(k.FOR_)} ${t(
                              targetValues[item.valueId ?? ""]?.[0],
                          )} ${t(targetValues[item.valueId ?? ""]?.[1])}`
                        : undefined;

                let extraManualValue =
                    item.type === HistoryType.MANUAL_VALUE
                        ? `${t(k.FOR_)} ${t(
                              manualValues[item.valueId ?? ""]?.[0],
                          )} ${t(manualValues[item.valueId ?? ""]?.[1])}`
                        : "";
                let extra = extraTarget ?? extraManualValue;

                let kpiName =
                    item.type == HistoryType.DATASOURCE_KPI
                        ? kpiNames?.find((x) => x.value == item.valueId)?.label
                        : undefined;

                let improvemntFormName =
                    item.type === HistoryType.IMPROVEMENT_FORM_FILTER
                        ? improvementFormNames?.find(
                              (x) => x.value == item.valueId,
                          )?.label
                        : undefined;

                let processName =
                    item.type === HistoryType.DISCOVERED_IN ||
                    item.type === HistoryType.ORIGINATED_IN
                        ? processNames?.find((x) => x.value == item.valueId)
                              ?.label
                        : undefined;
                let unitName =
                    item.type === HistoryType.TARGET_UNIT ||
                    item.type === HistoryType.UNIT
                        ? unitNames?.find((x) => x.value == item.valueId)?.label
                        : undefined;

                let name =
                    kpiName ?? improvemntFormName ?? processName ?? unitName;

                return formatAction(
                    t,
                    k.REMOVED,
                    item,
                    pathToItem,
                    name,
                    extra,
                );
            }
            return formatAction(t, k.REMOVED, item, pathToItem);

        case HistoryActionType.DELETED:
            return formatAction(t, k.DELETED, item, pathToItem);

        case HistoryActionType.ASSIGNED:
            let nameAssign =
                item.type === HistoryType.TEAM
                    ? teamNames?.[item.valueId ?? ""]
                    : userNames?.[item.valueId ?? ""]?.label;

            switch (type) {
                case HistoryOwnerEnum.Kpi:
                    return formatAction(
                        t,
                        k.ASSIGNED_TO,
                        item,
                        k.KPI,
                        nameAssign,
                    );
                case HistoryOwnerEnum.Goal:
                    return formatAction(
                        t,
                        k.ASSIGNED_TO,
                        item,
                        k.GOAL,
                        nameAssign,
                    );
                case HistoryOwnerEnum.Measurement:
                    return formatAction(
                        t,
                        k.ASSIGNED_TO,
                        item,
                        k.MEASUREMENT,
                        nameAssign,
                    );
                case HistoryOwnerEnum.Task:
                    return formatAction(
                        t,
                        k.ASSIGNED_TO,
                        item,
                        pathToItem,
                        nameAssign,
                    );
                case HistoryOwnerEnum.CM:
                    return formatAction(
                        t,
                        k.ASSIGNED_TO,
                        item,
                        k.COMPETENCY_MATRIX,
                        nameAssign,
                    );
                case HistoryOwnerEnum.Improvement:
                    return formatAction(
                        t,
                        k.ADDED_AS_INVOLVED_TO,
                        item,
                        k.IMPROVEMENT,
                        nameAssign,
                    );
                case HistoryOwnerEnum.KpiPermissions:
                case HistoryOwnerEnum.GoalsPermissions:
                case HistoryOwnerEnum.ChecklistReportsPermissions:
                case HistoryOwnerEnum.ImprovementDashboardPermissions:
                case HistoryOwnerEnum.StakeholdersPermissions:
                case HistoryOwnerEnum.LegislationsPermissions:
                case HistoryOwnerEnum.ImpactGradingsPermissions:
                case HistoryOwnerEnum.CustomersPermissions:
                case HistoryOwnerEnum.CustomerRequirementsPermissions:
                case HistoryOwnerEnum.SuppliersPermissions:
                case HistoryOwnerEnum.PurchaseOrdersPermissions:
                case HistoryOwnerEnum.ItemsPermissions:
                case HistoryOwnerEnum.UsersPermissions:
                case HistoryOwnerEnum.PositionsPermissions:
                case HistoryOwnerEnum.TeamsPermissions:
                case HistoryOwnerEnum.CompetenciesPermissions:
                case HistoryOwnerEnum.CompetencyMatrixPermissions:
                case HistoryOwnerEnum.StaffAppraisalsPermissions:
                case HistoryOwnerEnum.TrainingPlansPermissions:
                case HistoryOwnerEnum.EquipmentsPermissions:
                case HistoryOwnerEnum.ORAPermissions:
                case HistoryOwnerEnum.EnvironmentalAspectsPermissions:
                case HistoryOwnerEnum.CustomListItemsPermissions:
                    return formatAction(
                        t,
                        k.ASSIGNED,
                        item,
                        getAssignPermissionText(
                            t,
                            item.additionalInformation,
                            type !==
                                HistoryOwnerEnum.CustomListItemsPermissions,
                        ),
                        nameAssign,
                    );
            }
            return formatAction(t, k.ASSIGNED_TO, item, pathToItem);

        case HistoryActionType.UNASSIGNED:
            let nameUnassign =
                item.type === HistoryType.TEAM
                    ? teamNames?.[item.valueId ?? item.previousValueId ?? ""]
                    : userNames?.[item.valueId ?? item.previousValueId ?? ""]
                          ?.label;

            switch (type) {
                case HistoryOwnerEnum.Kpi:
                case HistoryOwnerEnum.Goal:
                case HistoryOwnerEnum.Measurement:
                case HistoryOwnerEnum.Task:
                    return formatAction(
                        t,
                        k.UNASSIGNED,
                        item,
                        pathToItem,
                        nameUnassign,
                    );
                case HistoryOwnerEnum.Improvement:
                    return formatAction(
                        t,
                        k.REMOVED_AS_INVOLVED_FROM,
                        item,
                        k.IMPROVEMENT,
                        nameUnassign,
                    );
                case HistoryOwnerEnum.KpiPermissions:
                case HistoryOwnerEnum.GoalsPermissions:
                case HistoryOwnerEnum.ChecklistReportsPermissions:
                case HistoryOwnerEnum.ImprovementDashboardPermissions:
                case HistoryOwnerEnum.StakeholdersPermissions:
                case HistoryOwnerEnum.LegislationsPermissions:
                case HistoryOwnerEnum.ImpactGradingsPermissions:
                case HistoryOwnerEnum.CustomersPermissions:
                case HistoryOwnerEnum.CustomerRequirementsPermissions:
                case HistoryOwnerEnum.SuppliersPermissions:
                case HistoryOwnerEnum.PurchaseOrdersPermissions:
                case HistoryOwnerEnum.ItemsPermissions:
                case HistoryOwnerEnum.UsersPermissions:
                case HistoryOwnerEnum.PositionsPermissions:
                case HistoryOwnerEnum.TeamsPermissions:
                case HistoryOwnerEnum.CompetenciesPermissions:
                case HistoryOwnerEnum.CompetencyMatrixPermissions:
                case HistoryOwnerEnum.StaffAppraisalsPermissions:
                case HistoryOwnerEnum.TrainingPlansPermissions:
                case HistoryOwnerEnum.EquipmentsPermissions:
                case HistoryOwnerEnum.ORAPermissions:
                case HistoryOwnerEnum.EnvironmentalAspectsPermissions:
                case HistoryOwnerEnum.CustomListItemsPermissions:
                    return formatAction(
                        t,
                        k.UNASSIGNED,
                        item,
                        getUnassignPermissionText(
                            t,
                            item.additionalInformation,
                            type !==
                                HistoryOwnerEnum.CustomListItemsPermissions,
                        ),
                        nameUnassign,
                    );
            }

            return formatAction(t, k.UNASSIGNED, item, pathToItem);

        case HistoryActionType.FINALIZED:
            //unfortunately, we use finalize for checklist and complete for improvements (just for syntaxt, not code)
            if (type === HistoryOwnerEnum.Improvement) {
                return formatAction(t, k.MARKED_AS_COMPLETE, item);
            }
            return formatAction(t, k.FINALIZED, item, pathToItem);

        case HistoryActionType.UNFINALIZED:
            //unfortunately, we use finalize for checklist and complete for improvements (just for syntaxt, not code)
            if (type === HistoryOwnerEnum.Improvement) {
                return formatUnfinalize(
                    t,
                    k.MARKED_AS_INCOMPLETE,
                    undefined,
                    item,
                );
            }
            return formatUnfinalize(
                t,
                k.UNFINALIZED_STEP_WITH_REASON,
                item.additionalInformation,
            );

        default:
            if (!item.value) return "";

            return formatChange(t, item.previousValue, item.value, "");
    }
}
