import { Action } from "redux";
import { IShowConfirmArgs } from "./actions";

export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const HIDE_CONFIRM_MODAL = "HIDE_CONFIRM_MODAL";

export interface IConfirmShowAction extends Action<typeof SHOW_CONFIRM_MODAL> {
    payload: IShowConfirmArgs;
}

export interface IConfirmHideAction extends Action<typeof HIDE_CONFIRM_MODAL> {
    payload?: boolean;
}

export type ConfirmActionTypes = IConfirmShowAction | IConfirmHideAction;
