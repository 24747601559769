import { useMemo } from "react";
import ModalContentListedSidePanelDetails, {
    ISidePanelDetailsProps,
} from "./ModalContentListedSidePanelDetails";
import ModalContentListedSidePanelHead, {
    ISidePanelHeadProps,
} from "./ModalContentListedSidePanelHead";

interface IProps {
    className?: string;
    sidePanelRef?: React.RefObject<HTMLDivElement>;

    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

export type ListedSidePanelType = React.FC<React.PropsWithChildren<IProps>> & {
    Head: React.FC<ISidePanelHeadProps>;
    Details: React.FC<ISidePanelDetailsProps>;
};
const ModalContentListedSidePanel: ListedSidePanelType = (props) => {
    const { className, sidePanelRef } = props;

    const listedSidePanelClassName = useMemo(() => {
        const result = ["m-m--m-c--listed--side-panel"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, []);

    return (
        <div
            className={listedSidePanelClassName}
            ref={sidePanelRef}
            onScroll={props.onScroll}
        >
            {props.children}
        </div>
    );
};

ModalContentListedSidePanel.Head = ModalContentListedSidePanelHead;
ModalContentListedSidePanel.Details = ModalContentListedSidePanelDetails;

export default ModalContentListedSidePanel;
