export enum HistoryType {
    None,
    OTHER_NOT_SURE,
    DISCOVERED_IN,
    ORIGINATED_IN,
    REPORTED_IMPROVEMENT,
    TASK,
    TASK_LABEL,
    CHECKLIST_STARTED,
    FINALIZED,
    END_DATE,
    START_DATE,
    NAME,
    CHECKLIST_STOPPED,
    CHECKLIST_RESUMED,
    CHECKLIST_UNARCHIVED,
    CHECKLIST_ARCHIVED,
    IMPACTED_AREA,
    DESCRIPTION,
    IMPACT,
    STATUS,
    ACTIVITY,
    TEAM,
    USER,
    STEP,
    TARGET_UNIT,
    TARGET_PREFERENCE,
    OVER_THRESHOLD,
    UNDER_THRESHOLD,
    TARGET_VALUE,
    FORMULA_ITEM,
    FORMULA_ITEM_INDEX,
    FORMULA_ITEM_TYPE,
    FORMULA_ITEM_VALUE,
    FORMULA_ITEM_VARIABLE_NAME,
    DATASOURCE_TYPE,
    DATASOURCE_KPI,
    TEXT_FILTER,
    IMPROVEMENT_FORM_FILTER,
    IMPROVEMENT_IMPACT_FILTER,
    DISCOVERED_IN_PROCESS_FILTER,
    ORIGINATED_IN_PROCESS_FILTER,
    POSITION,
    KPI_CREATED,
    MANUAL_VALUE,
    DECIMAL_PLACES,
    IMPROVEMENT_STATUS_FILTER,
    FORMAT_NUMBER_TYPE,
    GOAL_CREATED,
    PARENT_GOAL,
    SUB_GOAL,
    MEASUREMENT,
    GOAL_ARCHIVED,
    GOAL_UNARCHIVED,
    GOAL,
    MEASUREMENT_ARCHIVED,
    MEASUREMENT_UNARCHIVED,
    PROGRESS,
    PROGRESS_ARCHIVED,
    PROGRESS_UNARCHIVED,
    START_VALUE,
    UNIT,
    TASK_LIST,
    TASK_LIST_LABEL,
    TASK_LIST_INDEX,
    TASK_INDEX,
    TASK_COMPLETED,
    MEASUREMENT_CREATED,
    VALUE,
    DATE,
    NOTES,
    PROGRESS_VALUE_CREATED,
    STATIC_ACTION_PLAN,
    STATIC_ACTION_PLAN_LABEL,
    STATIC_ACTION_PLAN_INDEX,
    TASK_CREATED,
    MEASUREMENT_INDEX,
    STATUS_COMMENT,
    GOAL_INDEX,
    IMPORTED,
    UNFINALIZED,
    CLOSURE_REASON,
    TEAM_CAN_VIEW,
    USER_CAN_VIEW,
    CM_CREATED,
    COMMENT,
    COMPETENCY,
}
