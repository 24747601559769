import { DayOfWeek } from "../../../models/enums/DayOfWeek";
import { ERecurringChecklistMonthOfYear } from "./enum/ERecurringChecklistMonthOfYear";
import { WorkflowScheduleInterval } from "./WorkflowScheduleIntervals";
import { WorkflowScheduleStatus } from "./WorkflowScheduleStatus";
import { IRecurringChecklistDuration } from "./IRecurringChecklistDuration";

export default interface IRecurringChecklist {
    id: string;
    name: string;

    intervalType: WorkflowScheduleInterval;
    intervalNumber?: number;

    numberOfRuns?: number;

    isStartDate: boolean;
    startDate?: Date;

    isEndDate: boolean;
    endDate?: Date;

    duration?: IRecurringChecklistDuration;

    status?: WorkflowScheduleStatus;

    isPaused?: boolean;
    isArchived?: boolean;

    templateId: string;

    isDayOfMonth?: boolean;
    dayOfMonth?: number;

    workdays?: DayOfWeek[];

    weekday?: DayOfWeek;

    recurringWeek?: ERecurringChecklistWeek;

    monthsOfYear?: ERecurringChecklistMonthOfYear[];
}

export interface IRecurringWeekly
    extends Omit<
        IRecurringChecklist,
        | "isDayOfMonth"
        | "dayOfMonth"
        | "weekday"
        | "recurringWeek"
        | "monthsOfYear"
    > {}

export interface IRecurringMonthly
    extends Omit<IRecurringChecklist, "workdays" | "monthsOfYear"> {}

export interface IRecurringYearly
    extends Omit<IRecurringChecklist, "workdays"> {}

export enum ERecurringChecklistWeek {
    First,
    Second,
    Third,
    Fourth,
    Last,
}
