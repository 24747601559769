import "./ProcessDropdownTree.scss";

import DropdownTreeSelect, { TreeNode, Mode } from "react-dropdown-tree-select";
import React from "react";
import isEqual from "lodash/isEqual";
import { ITreeListOption } from "common/ITreeListOption";

interface IProps {
    id?: string;
    data: ITreeListOption[];

    mode?: Mode;
    disabled?: boolean;
    showRemove?: boolean;

    onChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void;
}

const ProcessDropdownTree: React.FC<IProps> = (props) => {
    const { id, data, mode, disabled, showRemove } = props;

    const handleOnChange = (
        currentNode: TreeNode,
        selectedNodes: TreeNode[],
    ) => {
        props.onChange(currentNode, selectedNodes);
    };

    return (
        <DropdownTreeSelect
            id={id}
            data={data}
            disabled={disabled}
            keepTreeOnSearch
            mode={mode}
            showPartiallySelected
            className={
                showRemove
                    ? "process-dropdown-tree show-remove"
                    : "process-dropdown-tree"
            }
            onChange={handleOnChange}
        />
    );
};

export default React.memo(ProcessDropdownTree, (prev, next) => {
    return isEqual(prev.data, next.data);
});
