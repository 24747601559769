import "./ModalForm.scss";

import React, { useEffect, useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Modal from "common/components/modals/Modal";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import IItemTypeRowDTO from "components/item-types/api/IItemTypeRowDTO";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import IChanged from "common/IChanged";
import PageChangeDispatcher from "common/components/navigation-blocker/PageChangeDispatcher";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    ItemCategoryType,
    getItemCategoryTypeOptions,
} from "components/item-types/api/ItemCategoryType";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";
import { useSaveItemMutation } from "../api/hooks";
import InputWrapper from "common/components/input-components/InputWrapper";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { useDispatch } from "react-redux";

interface IProps {
    value?: IItemTypeRowDTO;
    disabledDropdown?: boolean;
    isCreate?: boolean;

    onSave: (value?: IItemTypeRowDTO) => void;
}

function getInitialState() {
    const contact: IItemTypeRowDTO = {
        id: uuidv4(),
        name: "",
        type: ItemCategoryType.Unit,
        isArchived: false,
    };

    return contact;
}

const ItemTypeModalForm: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { value, disabledDropdown } = props;

    const setHasPageChanges = useHasPageChanges();
    const dispatch = useDispatch();

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [item, setItem] = useState<IChanged<IItemTypeRowDTO>>({
        value: getInitialState(),
    });

    const [selectedType, setSelectedType] =
        useState<IValueLabelItem<number, string>>();

    const saveMutation = useSaveItemMutation();

    useEffect(() => {
        const newValue: IChanged<IItemTypeRowDTO> = {
            value: value ?? getInitialState(),
            isChanged: props.isCreate ?? false,
        };

        setItem(newValue);

        setSelectedType(
            itemCategoryTypeOptions.find(
                (x) => x.value === newValue.value.type,
            ),
        );
    }, [value]);

    const { t, i18n } = useTranslation();

    const handleChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const name = e.id;

            setItem((prev) => ({
                ...prev,
                isChanged: true,
                value: {
                    ...prev.value,
                    [name]: e.value,
                },
            }));
        }
    };

    const handleChangeType = (e: any) => {
        if (e) {
            const option = e as IValueLabelItem<number, string>;

            setSelectedType(option);

            setItem((prev) => ({
                ...prev,
                isChanged: true,
                value: {
                    ...prev.value,
                    type: option.value,
                },
            }));
        }
    };

    const handleCancel = async () => {
        if (item.isChanged) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE_UNSAVED_CHANGES)}</>,
                yesButtonVariant: "danger",
                title: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                handleHide();
            }
        } else {
            handleHide();
        }
    };

    const handleSave = async () => {
        const result = await saveMutation.mutateAsync({
            isCreate,
            value: item.value,
        });

        if (result.Succeeded) {
            handleHide(result.Data);
        } else {
            setErrors(result.Messages);
        }
    };

    const handleHide = (newValue?: IItemTypeRowDTO) => {
        setHasPageChanges(false);

        props.onSave(newValue);

        setErrors({});
    };

    const isCreate = props.isCreate ?? value === undefined;

    const itemCategoryTypeOptions = useMemo(
        () => getItemCategoryTypeOptions(),
        [i18n.language],
    );

    return (
        <React.Fragment>
            <PageChangeDispatcher hasChanges={item.isChanged} />

            <Modal show onHide={handleCancel}>
                <Modal.Title>{isCreate ? t(k.ADD_NEW) : t(k.EDIT)}</Modal.Title>

                <Modal.Body>
                    <div className="item-types-form--body">
                        <div>
                            <InputWrapper
                                wrapperLabel={t(k.CATEGORY)}
                                boldLabel
                                required
                            >
                                <SelectDropdown
                                    inputId="item-type.type"
                                    value={selectedType}
                                    isSearchable
                                    isDisabled={disabledDropdown}
                                    placeholder={t(k.SELECT)}
                                    options={itemCategoryTypeOptions}
                                    onChange={handleChangeType}
                                />
                            </InputWrapper>
                        </div>

                        <div>
                            <TextfieldComplex
                                hideIcon
                                id="name"
                                value={item.value.name ?? ""}
                                placeholder={t(k.ENTER_HERE)}
                                size="large"
                                bold
                                innerLabelContent={
                                    <React.Fragment>
                                        {t(k.NAME)}
                                        &nbsp;
                                        <span className="text--danger">*</span>
                                    </React.Fragment>
                                }
                                invalid={Boolean(errors?.Name)}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup className="item-types-form--footer">
                        <Button
                            variant="danger"
                            transparent
                            onClick={handleCancel}
                        >
                            {t(k.CANCEL)}
                        </Button>

                        <Button
                            variant="success"
                            onClick={handleSave}
                            disabled={item.isChanged !== true}
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ItemTypeModalForm;
