import "./SelectedUsersAvatarList.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Avatar from "../avatar/Avatar";

import { CloseIcon } from "../icons";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { IGetUser } from "components/users/api/IGetUsersDTO";
import classnames from "classnames";

interface IProps {
    noTooltipLabel?: boolean;
    size?: number;
    users: IGetUser[];
    usersAffected?: string[];
    testId?: string;
    maxShownAvatars?: number;
    maxInitials?: number;
    isLoading?: boolean;
    noUsersText?: string;
}

const SelectedUsersAvatarList: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        noTooltipLabel,
        users,
        size = 32,
        usersAffected,
        testId,
        maxShownAvatars = Infinity,
        maxInitials = 3,
        isLoading,
        noUsersText,
    } = props;

    const { t } = useTranslation();

    if (!users.length && !noUsersText) {
        return null;
    }

    const stacked = users.length > maxShownAvatars;

    const shownUsers = users.slice(0, maxShownAvatars);

    const showExtraAvatarNumber =
        maxShownAvatars - users.length < 0 ||
        shownUsers.length - users.length < 0;

    const className = classnames([
        "selected-users-avatar-list__wrapper no-print",
        stacked
            ? "selected-users-avatar-list__wrapper--stacked"
            : "selected-users-avatar-list__wrapper--not-stacked",
    ]);

    const noUsersTextClass = classnames([
        "selected-users-avatar-list__no-users-text",
    ]);

    if (noUsersText && users.length == 0) {
        return (
            <div className={className} data-testid={testId}>
                <div className={noUsersTextClass}>{noUsersText}</div>
            </div>
        );
    }

    return (
        <div className={className} data-testid={testId}>
            {isLoading ? (
                <AnimatedSpinner isVisible aligned="center" position="center" />
            ) : (
                <>
                    {shownUsers.map((user, index) => {
                        const affected = usersAffected?.includes(user.value);
                        return (
                            <div
                                key={user.value}
                                className={"selected-users-avatar-list"}
                                style={{
                                    marginLeft:
                                        index > 0
                                            ? stacked
                                                ? (size * -1 * 1) / 3
                                                : (size * 1) / 6
                                            : 0,
                                }}
                            >
                                <Avatar
                                    name={user.label}
                                    size={size}
                                    maxInitials={maxInitials}
                                    tooltipMessage={
                                        <div className="selected-users-avatar-list__tool-tip__wrapper">
                                            {user.isArchived && (
                                                <div>{t(k.DISABLED_USER)}</div>
                                            )}
                                            <div className="selected-users-avatar-list__tool-tip">
                                                {affected
                                                    ? t(k.MARKED_FOR_REMOVAL)
                                                    : noTooltipLabel
                                                      ? undefined
                                                      : t(k.ASSIGNED_TO)}{" "}
                                                <strong className="d-block">
                                                    {user.label}
                                                </strong>
                                            </div>
                                        </div>
                                    }
                                    className={
                                        user.isArchived
                                            ? "avatar--user-archived"
                                            : ""
                                    }
                                />
                                {affected && (
                                    <CloseIcon className="user-removed" />
                                )}
                            </div>
                        );
                    })}

                    {showExtraAvatarNumber && (
                        <div
                            className="selected-users-avatar-list"
                            style={{
                                marginLeft: stacked
                                    ? (size * -1 * 1) / 3
                                    : (size * 1) / 6,
                            }}
                        >
                            <Avatar
                                name=" "
                                initials={`+ ${users.length - maxShownAvatars}`}
                                size={size}
                                maxInitials={100}
                                tooltipMessage={users
                                    .slice(maxShownAvatars, Infinity)
                                    .map((user) => (
                                        <div key={user.value}>{user.label}</div>
                                    ))}
                                color={"#C4C4C4"}
                                tooltipPlacement={
                                    users.length - maxShownAvatars > 4
                                        ? "auto"
                                        : "top"
                                }
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SelectedUsersAvatarList;
