import ModalContent from "common/components/modal-content/ModalContent";

import { useContext } from "react";

import {
    IModalListItem,
    IUpdateModalPayload,
    ModalTypes,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import TemplateForm from "components/templates/components/TemplateListedContent/Form/TemplateForm";
import { makeCallId } from "../../utility/ModalCallId";

interface IProps {
    modalItem: IModalListItem;
}

const TemplateFormModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean, closePreview?: boolean) => {
        modalManagerContext.onCloseModal({
            callId,
            onSave,
            extraById: closePreview
                ? [
                      makeCallId({
                          type: ModalTypes.template,
                          id,
                          callerAddress: "",
                      }),
                  ]
                : undefined,
        });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    const handleOnUpdate = (payload: IUpdateModalPayload) => {
        modalManagerContext.onUpdateModal({
            callId,
            ...payload,
        });
    };

    return (
        <TemplateForm
            id={id}
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            createNew={options?.createNew}
            syncData={modalManagerContext.state.activeModal === callId}
            onHaveChanges={handleOnHaveChanges}
            onShowModal={modalManagerContext.onShowModal}
            onClose={handleOnClose}
            onUpdateModal={handleOnUpdate}
        />
    );
};

export default TemplateFormModal;
