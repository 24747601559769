import "./CompletedStepCard.scss";

import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    ClosureReasonEnum,
    getClosureReasonOptions,
} from "models/enums/ClosureReasonEnum";
import Button from "common/components/buttons/Button";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import CompletedByUser from "common/components/avatar/completed-by-user/CompletedByUser";
import CompletedAtTime from "common/components/completed-at-time/CompletedAtTime";
import Badge from "common/components/badges/Badge";
import { markIncompleteStepButtonText } from "components/improvement-forms/components/ImprovementForm/utils";

interface IProps {
    isLastStep?: boolean;
    closureReason?: ClosureReasonEnum;
    stepInstanceId?: string;
    isSaving: boolean;
    name?: string;

    onClick: (closureReason?: ClosureReasonEnum) => void;
}

const CompleteStepCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { isSaving, stepInstanceId, isLastStep, closureReason, name } = props;

    const { t, i18n } = useTranslation();
    const { stepInstances } = useContext(ImprovementsDetailsContext);

    const isSubmitDisabled = isSaving;

    const handleOnStepCompleteClick = () => {
        props.onClick(ClosureReasonEnum.None);
    };

    const reasonList = useMemo(
        () => getClosureReasonOptions(i18n),
        [i18n.language],
    );

    const stepInstance = stepInstances?.values[stepInstanceId ?? ""];

    const title = isLastStep
        ? k.THIS_IMPOVEMENT_IS_CLOSED
        : k.THIS_STEP_IS_COMPLETED;

    const buttonText = name
        ? markIncompleteStepButtonText[name]
        : k.MARK_AS_INCOMPLETE;

    return (
        <div className="completed-step-card">
            <div className="desc desc-small">
                <h3 className="title">{t(title)}</h3>
                <div className="details">
                    <CompletedByUser
                        completedByName={stepInstance?.finalizedBy}
                    />
                    <CompletedAtTime
                        completedAt={stepInstance?.completedAt}
                    ></CompletedAtTime>

                    {isLastStep && (
                        <Badge variant="dark" pill>
                            {
                                reasonList.find(
                                    (r) => r.param === closureReason,
                                )?.label
                            }
                        </Badge>
                    )}
                </div>
            </div>

            <Button
                disabled={isSubmitDisabled}
                isBusy={isSubmitDisabled}
                testId="markAsIncompleteBtn"
                onClick={handleOnStepCompleteClick}
                variant="bordered"
            >
                {t(buttonText)}
            </Button>
        </div>
    );
};

export default CompleteStepCard;
