import "./UserForm.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IUserRowDTO from "components/users/api/IUserRowDTO";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Button from "common/components/buttons/Button";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IValueParamItem } from "common/IValueParamItem";
import { useRolesByTeamIds } from "components/roles/api/hooks";
import { IAppState } from "store/IAppState";
import ModalContent from "common/components/modal-content/ModalContent";
import { buildConfirmDisableMessage } from "./messages";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import {
    useArchiveUserMutation,
    useSaveUserMutation,
    useUserById,
} from "../api/hooks";
import { IUserPositionItem } from "components/positions/api/IUserPositionItem";
import { PositionsListSort } from "common/components/positions/utility/PositionsListSort";
import UserDetails from "./UserDetails";
import UserEditForm from "./UserEditForm";
import buildConfirmActivateMessage from "./messages/ActivateMessage";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";

interface IProps {
    id?: string;
    createNew?: boolean;
    edit?: boolean;

    haveChanges?: boolean;

    onCloseModal: (onSave?: boolean, idToOpen?: string) => void;
    onHaveChanges: (haveChanges: boolean) => void;
}

export type SaveHandler = () => IUserPositionItem;

const UserForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { edit, createNew, haveChanges } = props;

    const canEdit = useSelector((appState: IAppState) => {
        const { ADMINISTRATOR, USER_EDITOR, USER_OWNER } =
            appState.authViewState.roles;

        const canEdit = ADMINISTRATOR || USER_EDITOR || USER_OWNER;

        return canEdit ?? false;
    });

    const dispatch = useDispatch();
    const saveUserMutation = useSaveUserMutation();
    const archiveMutation = useArchiveUserMutation();

    const { t } = useTranslation();

    const [item, setItem] = useState<IUserRowDTO>({
        id: uuidv4(),
        isArchived: false,
        userName: "",
        email: "",
        password: "",
        password2: "",
    });

    const [editMode, setEditMode] = useState(edit ?? false);

    const [errors, setErrors] = useState<IValidationMessages>({});

    const {
        data: user,
        isLoading,
        refetch,
    } = useUserById(createNew !== true ? props.id : undefined);

    const { data: currentTeamRoles } = useRolesByTeamIds(item.teamIds);

    const [childSaveHandlers, setChildSaveHandlers] = useState<
        Record<string, SaveHandler>
    >({});

    const registerChildSaveHandler = useCallback(
        (itemId: string, saveHandler: SaveHandler) => {
            setChildSaveHandlers((prev) => ({
                ...prev,
                [itemId]: saveHandler,
            }));
        },
        [],
    );

    const unregisterChildSaveHandler = useCallback((itemId: string) => {
        setChildSaveHandlers((prev) => {
            const newHandlers = { ...prev };
            delete newHandlers[itemId];
            return newHandlers;
        });
    }, []);

    useEffect(() => {
        if (createNew !== true && user) {
            setItem((prev) => ({ ...prev, ...user }));
        }
    }, [user]);

    const userRoles = useMemo(() => {
        const roles = item.userRoles;

        if (roles) {
            const list: IValueParamItem<string, boolean>[] = roles
                .map((x) => ({ value: x, param: false }))
                .concat({ value: "USER", param: true });

            return list;
        }

        return [{ value: "USER", param: true }];
    }, [item.userRoles]);

    const userTeams = useMemo(() => {
        const teams = item.teamIds;

        if (teams) {
            const list: IValueParamItem<string, boolean>[] = teams
                .map((x) => ({ value: x, param: false }))
                .concat({ value: "EVERYONE", param: true });

            return list;
        }

        return [{ value: "EVERYONE", param: true }];
    }, [item.teamIds]);

    const handleChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const name = e.id;

            setItem((prev) => ({
                ...prev,

                [name]: e.value,
            }));

            props.onHaveChanges(true);
        }
    };

    const handleSave = async () => {
        if (haveChanges || createNew) {
            const openPositions = item.userPositions?.filter(
                (x) => x.isEditMode === true,
            );

            const isAnyPositionOpen = openPositions && openPositions.length > 0;

            let list: IUserPositionItem[] = [];
            if (isAnyPositionOpen) {
                Object.values(childSaveHandlers).forEach((saveHandler) =>
                    list.push(saveHandler()),
                );
                list.forEach((x) => (x.isEditMode = false));
                list = PositionsListSort(list);
            }
            const { teamRoles, userPositions, ...rest } = item;

            const positions = userPositions?.filter((x) => x.isDelete !== true);
            const request = {
                ...rest,
                userPositions: (isAnyPositionOpen ? list : positions)?.map(
                    (x) => {
                        const { isEditMode, ...position } = x;

                        return {
                            ...position,
                            endDate: position.isCurrent
                                ? null
                                : position.endDate,
                        };
                    },
                ),
            };

            try {
                const result = await saveUserMutation.mutateAsync({
                    isCreate: createNew ?? false,
                    value: request,
                });

                if (createNew) {
                    const id = result;

                    props.onCloseModal(true, id);
                } else {
                    setEditMode(false);

                    setItem((prev) => ({
                        ...prev,
                        userPositions: positions,
                    }));

                    setErrors({});

                    refetch();
                    props.onHaveChanges(false);
                }
            } catch (error: any) {
                const errors = error.response.data.errors;

                setErrors(errors);

                scrollToTopErrorById(getIdsFromValidationMessages(errors));
            }
        } else if (props.id) {
            setEditMode(false);
        }
    };

    const handleManagerChange = (userId: string[]) => {
        setItem((prev) => ({
            ...prev,

            myManager: userId.length > 0 ? userId[0] : undefined,
        }));

        props.onHaveChanges(true);
    };

    const handleRolesChange = (roleIds: string[]) => {
        const filteredRoleIds = roleIds.filter((x) => x !== "USER");

        setItem((prev) => ({
            ...prev,

            userRoles: filteredRoleIds,
        }));

        props.onHaveChanges(true);
    };

    const handleTeamsChange = (teamIds: string[]) => {
        const filteredRoleIds = teamIds.filter((x) => x !== "EVERYONE");

        setItem((prev) => ({
            ...prev,

            teamIds: filteredRoleIds,
        }));

        props.onHaveChanges(true);
    };

    const handlePositionChange = (newItem: IUserPositionItem) => {
        const closedItem = { ...newItem, isEditMode: false };

        setItem((prev) => {
            if (prev.userPositions && prev.userPositions.length > 1) {
                const list = prev.userPositions?.map((x) =>
                    x.id === closedItem.id ? closedItem : x,
                );

                return {
                    ...prev,
                    userPositions: PositionsListSort(list),
                };
            } else {
                return {
                    ...prev,
                    userPositions: [closedItem],
                };
            }
        });

        setErrors((prev) => {
            const { [newItem.id]: value, ...rest } = prev;

            return rest;
        });

        props.onHaveChanges(true);
    };

    const handlePositionDelete = (itemId: string, undo?: boolean) => {
        const toDelete = item.userPositions?.find((x) => x.id === itemId);

        if (toDelete && toDelete.positionId && toDelete.startDate) {
            setItem((prev) => ({
                ...prev,
                userPositions: prev.userPositions?.map((x) =>
                    x.id === itemId
                        ? { ...x, isDelete: undo ? false : true }
                        : x,
                ),
            }));

            props.onHaveChanges(true);
        } else {
            setItem((prev) => ({
                ...prev,
                userPositions: prev.userPositions?.filter(
                    (x) => x.id !== itemId,
                ),
            }));
        }
    };

    const handlePositionAddNew = () => {
        const newItem = { id: uuidv4(), isEditMode: true };
        setItem((prev) => ({
            ...prev,
            userPositions: prev.userPositions
                ? prev.userPositions.concat(newItem)
                : [newItem],
        }));

        props.onHaveChanges(true);
    };

    const handlePositionEditMode = (id: string, newMode: boolean) => {
        if (item.userPositions) {
            const index = item.userPositions.findIndex((x) => x.id === id);

            if (
                index >= 0 &&
                item.userPositions[index].isEditMode !== newMode
            ) {
                setItem((prev) => ({
                    ...prev,
                    userPositions: prev.userPositions?.map((x) =>
                        x.id === id ? { ...x, isEditMode: newMode } : x,
                    ),
                }));

                if (newMode === false) {
                    setErrors((prev) => {
                        const { [id]: value, ...rest } = prev;

                        return rest;
                    });
                }
            }
        }
    };

    const buildConfirmArchiveMessage = async (archived: boolean) => {
        var confirmMsg = archived
            ? buildConfirmActivateMessage(
                  t(k.ACTIVATE_USER_TITLE),
                  t(k.ACTIVATE),
              )
            : buildConfirmDisableMessage(t(k.DISABLE_USER_TITLE), t(k.DISABLE));

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        return confirmOk;
    };

    const handleArchive = async () => {
        const confirmOk = await buildConfirmArchiveMessage(item.isArchived);

        if (confirmOk && props.id) {
            try {
                await archiveMutation.mutateAsync({
                    request: { id: props.id, isArchive: !item.isArchived },
                });
            } catch (error: any) {
                const errors = error.response.data.errors;

                setErrors(errors);

                scrollToTopErrorById(getIdsFromValidationMessages(errors));
            }
        }
    };

    const handleSetEditMode = () => {
        setEditMode(true);

        setItem((prev) => ({ ...prev, password: "", password2: "" }));

        setErrors({});
    };

    const handleCancel = async () => {
        if (haveChanges) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            if (await showConfirmNoThunk(dispatch, args)) {
                doCancel();
            }
        } else {
            doCancel();
        }
    };

    const doCancel = () => {
        if (createNew) {
            props.onCloseModal(true);
        } else {
            setEditMode(false);

            props.onHaveChanges(false);

            setItem((prev) => ({
                ...prev,
                ...user,
            }));
        }
    };

    const handleOnClose = () => props.onCloseModal();

    const teamRoles = currentTeamRoles && item.teamRoles;

    return (
        <ModalContent maxWidth="md">
            {editMode || createNew ? (
                <>
                    <ModalContent.Header
                        title={createNew ? t(k.ADD_USER) : t(k.EDIT_USER)}
                        className="user-form--header"
                        onClose={handleOnClose}
                    />
                    <ModalContent.Body className="user-form--body">
                        <UserEditForm
                            item={item}
                            isLoading={isLoading}
                            userRoles={userRoles}
                            userTeams={userTeams}
                            teamRoles={teamRoles}
                            errors={errors}
                            canEdit={canEdit ?? false}
                            handleChange={handleChange}
                            handleManagerChange={handleManagerChange}
                            handlePositionAddNew={handlePositionAddNew}
                            handlePositionChange={handlePositionChange}
                            handleRolesChange={handleRolesChange}
                            handlePositionDelete={handlePositionDelete}
                            handleTeamsChange={handleTeamsChange}
                            handlePositionEditMode={handlePositionEditMode}
                            onHaveChanges={props.onHaveChanges}
                            registerChildSaveHandler={registerChildSaveHandler}
                            unregisterChildSaveHandler={
                                unregisterChildSaveHandler
                            }
                        />
                    </ModalContent.Body>
                    <ModalContent.Footer className="user-form--footer">
                        <Button
                            variant="danger"
                            transparent
                            onClick={handleCancel}
                        >
                            {t(k.CANCEL)}
                        </Button>

                        <Button
                            variant="success"
                            testId="saveUserBtn"
                            onClick={handleSave}
                            disabled={isLoading}
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ModalContent.Footer>{" "}
                </>
            ) : (
                <>
                    <ModalContent.Header
                        title={t(k.USER_DETAILS)}
                        className="user-details--header"
                        onClose={handleOnClose}
                    />
                    <ModalContent.Body className="user-details--body">
                        <UserDetails item={item} userRoles={userRoles} />
                    </ModalContent.Body>
                    <ModalContent.Footer className="user-details--footer">
                        {canEdit && (
                            <>
                                <Button
                                    testId="editUserBtn"
                                    transparent
                                    disabled={item.isArchived}
                                    onClick={handleSetEditMode}
                                >
                                    {t(k.EDIT)}
                                </Button>
                                {item.isArchived ? (
                                    <Button
                                        testId="activateUserBtn"
                                        onClick={handleArchive}
                                        variant="dark-blue"
                                    >
                                        {t(k.ACTIVATE)}
                                    </Button>
                                ) : (
                                    <Button
                                        testId="disableUserBtn"
                                        onClick={handleArchive}
                                        variant="danger"
                                    >
                                        {t(k.DISABLE)}
                                    </Button>
                                )}
                            </>
                        )}
                    </ModalContent.Footer>
                </>
            )}
        </ModalContent>
    );
};

export default UserForm;
