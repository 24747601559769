import React from "react";

import SvFlag16 from "i18n/assets/sv16.png";
import GbFlag16 from "i18n/assets/gb16.png";

export const svFlag = (
    <img
        className="locale-menu--option-flag"
        src={SvFlag16}
        width={16}
        height={16}
    />
);

export const gbFlag = (
    <img
        className="locale-menu--option-flag"
        src={GbFlag16}
        width={16}
        height={16}
    />
);
