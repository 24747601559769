import "./WarningIcon.scss";

import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import classnames from "classnames";

interface IProps {
    disabled?: boolean;
    darkBackground?: boolean;
    style?: React.CSSProperties;
    inheritColor?: boolean;
}

const WarningIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const classes = classnames({
        "icon-warning": true,
        ["disabled"]: props.disabled,
        "dark-background": props.darkBackground,
        "inherit-color": props.inheritColor,
    });

    return (
        <HiOutlineExclamationCircle style={props.style} className={classes} />
    );
};

export default WarningIcon;
