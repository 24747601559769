import {
    ConfirmActionTypes,
    HIDE_CONFIRM_MODAL,
    SHOW_CONFIRM_MODAL,
} from "./types";
import { IConfirmState } from "./reducer";
import {
    ThunkActionReturnsPromise,
    ThunkActionReturnsPromiseDispatch,
} from "common/types";
import { globalStore } from "store";
import { ButtonVariantTypes } from "common/components/buttons/Button";
import { Dispatch } from "redux";

export interface IShowConfirmArgs {
    message: React.ReactNode;

    titleVariant?: "danger";
    yesButtonVariant?: ButtonVariantTypes;

    modalClass?: string;

    showCancel?: boolean;
    showOnlyClose?: boolean;

    title?: React.ReactNode;
    yesLabel?: React.ReactNode;
    noLabel?: React.ReactNode;
}

export const showConfirmDispatch = (
    dispatch: Dispatch<any>,
    args: React.ReactNode | IShowConfirmArgs,
) => {
    return (
        dispatch as ThunkActionReturnsPromiseDispatch<
            boolean | undefined,
            IConfirmState,
            ConfirmActionTypes
        >
    )(showConfirm(args));
};

export const showConfirm: ThunkActionReturnsPromise<
    boolean | undefined,
    IConfirmState,
    ConfirmActionTypes
> = (message: React.ReactNode | IShowConfirmArgs) => {
    const request = (payload: IShowConfirmArgs): ConfirmActionTypes => {
        return {
            type: SHOW_CONFIRM_MODAL,
            payload,
        };
    };

    return async (dispatch) => {
        let payload: IShowConfirmArgs;

        if (isIConfirm(message)) {
            payload = message;
        } else {
            payload = {
                message,
            };
        }

        dispatch(request(payload));

        return new Promise<boolean | undefined>((resolve) => {
            const handleStateChange = () => {
                const newState = globalStore.getState();

                if (newState.confirmViewState.isResolved) {
                    resolve(newState.confirmViewState.result);

                    unsubscribe();
                }
            };

            const unsubscribe = globalStore.subscribe(handleStateChange);
        });
    };
};

export const showConfirmNoThunk = (
    dispatch: Dispatch,
    message: React.ReactNode | IShowConfirmArgs,
) => {
    const request = (payload: IShowConfirmArgs): ConfirmActionTypes => {
        return {
            type: SHOW_CONFIRM_MODAL,
            payload,
        };
    };

    let payload: IShowConfirmArgs;

    if (isIConfirm(message)) {
        payload = message;
    } else {
        payload = {
            message,
        };
    }

    dispatch(request(payload));

    return new Promise<boolean | undefined>((resolve) => {
        const handleStateChange = () => {
            const newState = globalStore.getState();

            if (newState.confirmViewState.isResolved) {
                resolve(newState.confirmViewState.result);

                unsubscribe();
            }
        };

        const unsubscribe = globalStore.subscribe(handleStateChange);
    });
};

const isIConfirm = (
    tbd: React.ReactNode | IShowConfirmArgs,
): tbd is IShowConfirmArgs => {
    return (tbd as IShowConfirmArgs).message !== undefined;
};

export const hideConfirm = (confirmOk?: boolean): ConfirmActionTypes => {
    return {
        type: HIDE_CONFIRM_MODAL,
        payload: confirmOk,
    };
};
