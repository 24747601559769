import "./Preview.scss";

import React from "react";

import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

const CustomListPropertyPreview: React.FC = () => {
    return (
        <div className="template-property-preview">
            <CustomListIcon showBackground />

            <SelectDropdown
                isDisabled
                placeholder="User will select value from here"
            />
        </div>
    );
};

export default CustomListPropertyPreview;
