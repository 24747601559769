import React from "react";

import Badge from "../Badge";
import FormattedDate, {
    formatDate,
    formatDateWithTypeCheck,
} from "common/components/formattedDate/FormattedDate";

interface IProps {
    label: React.ReactNode;
    className?: string;
    completedAt?: Date;
}

const WorksheetStatusBadgeRenderer: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const { label, className, completedAt } = props;

    return (
        <Badge
            className={className}
            pill
            textEllipsis
            title={
                label +
                (completedAt
                    ? " " +
                      formatDateWithTypeCheck({
                          date: completedAt,
                          onlyDate: true,
                      })
                    : "")
            }
        >
            {label} <FormattedDate date={completedAt} onlyDate={true} />
        </Badge>
    );
};

export default WorksheetStatusBadgeRenderer;
