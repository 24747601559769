import "./TextfieldIcon.scss";

import React from "react";
import { FaAlignLeft } from "react-icons/fa";

interface IProps {
    isMultiline?: boolean;
    invalid?: boolean;
}

const TextfieldIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { isMultiline, invalid } = props;

    if (isMultiline) {
        return (
            <FaAlignLeft
                className={
                    invalid
                        ? "icon-textfield--invalid icon-textfield--multiline"
                        : "icon-textfield--multiline"
                }
            />
        );
    }

    return (
        <i
            className={
                invalid
                    ? "icon-textfield--invalid icon-textfield"
                    : "icon-textfield"
            }
        ></i>
    );
};

export default TextfieldIcon;
