import "./TableRowRetreats.scss";

interface IProps {
    retreats: boolean[];
    level: number;
    isLast?: boolean;
}

const TableRowRetreats = (props: IProps) => {
    const { retreats, level, isLast } = props;
    return (
        <>
            {retreats.map((containsStraightLine, index) => {
                if (index === level - 1) {
                    return isLast ? (
                        <div className="table-row-retreats" key={index}>
                            <div className="curved-line" />
                        </div>
                    ) : (
                        <div className="table-row-retreats" key={index}>
                            <div className="straight-line" />
                            <div className="curved-line" />
                        </div>
                    );
                }

                return containsStraightLine ? (
                    <div className="table-row-retreats" key={index}>
                        <div className="straight-line" />
                    </div>
                ) : (
                    <div className="table-row-retreats" key={index} />
                );
            })}
        </>
    );
};

export default TableRowRetreats;
