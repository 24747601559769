import "./KpiContentWrapper.scss";
import { RefObject } from "react";

interface IProps {
    stepRef: RefObject<HTMLDivElement>;
    dataId: string;
    title: string;
}

const KpiContentWrapper = (props: React.PropsWithChildren<IProps>) => {
    const { stepRef, dataId, title } = props;

    return (
        <div className="kpi-content-wrapper" ref={stepRef} data-id={dataId}>
            <div className="kpi-content-wrapper--title">{title}</div>
            {props.children}
        </div>
    );
};

export default KpiContentWrapper;
