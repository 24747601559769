import { checkboxColumn } from "react-datasheet-grid";

const extraFALSY = [
    "",
    "false",
    "no",
    "off",
    "disabled",
    "0",
    "n",
    "f",
    "unchecked",
    "undefined",
    "null",
    "wrong",
    "negative",
    "nej",
    "falskt",
];

checkboxColumn.pasteValue = ({ value }) =>
    !extraFALSY.includes(value.toLowerCase());

export const addCheckboxColumn = checkboxColumn;
