import "./ConfirmDeletePropertyModal.scss";

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Modal from "common/components/modals/Modal";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import Textfield from "common/components/input-components/textfield/Textfield";
import IChanged from "common/IChanged";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { removeNewLineText } from "components/process-chart/api/utils";

interface IProps {
    title?: string;

    onHide: (yes?: boolean) => void;
}

const ConfirmDeletePropertyModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLElement>(null);

    const [titleOrDefaultText] = useState(() => {
        const title = removeNewLineText(props.title ?? "");

        if (title === "") {
            return t(k.DELETE);
        }

        return title;
    });

    const [confirmText, setConfirmText] = useState<
        IChanged<string> & { match: boolean }
    >({
        value: "",
        match: false,
    });

    const handleChangeTitle = (newValue: string) => {
        setConfirmText((prev) => ({
            ...prev,
            isChanged: true,
            value: newValue,
            match:
                titleOrDefaultText.toUpperCase() ===
                newValue.trim().toUpperCase(),
        }));
    };

    const handleShow = () => {
        ref.current?.focus();
    };

    const handleHide = () => {
        props.onHide(undefined);
    };

    const onYesHandler = () => {
        props.onHide(true);
    };

    const invalid = confirmText.isChanged && confirmText.match === false;

    return (
        <Modal
            className="shared-properties__delete-modal"
            show
            variant="danger"
            onHide={handleHide}
            onShow={handleShow}
        >
            <Modal.Title>Remove this property?</Modal.Title>
            <Modal.Body>
                <p>
                    If you delete this property, it will be removed from all
                    existing and future appraisals.
                </p>

                <p>Are you sure you want to proceed?</p>

                <p>
                    Please type <strong>{titleOrDefaultText}</strong> to
                    confirm.
                </p>

                <Textfield
                    innerRef={ref}
                    invalid={invalid}
                    value={confirmText.value}
                    helperText={
                        <ValidationLabel
                            errors={
                                invalid
                                    ? "you have entered a wrong name"
                                    : undefined
                            }
                        />
                    }
                    onChange={handleChangeTitle}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        variant="gray"
                        transparent
                        noMinWidth
                        onClick={handleHide}
                    >
                        {t(k.CANCEL)}
                    </Button>

                    <Button
                        variant={confirmText.match ? "danger" : "gray"}
                        noMinWidth
                        disabled={confirmText.match === false}
                        onClick={onYesHandler}
                    >
                        I understand the consequences, delete the property
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeletePropertyModal;
