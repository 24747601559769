import "./TeamRoleIcon.scss";

import React from "react";
import Icon from "./assets/team-role-icon.svg";

const TeamRoleIcon: React.FC = (props) => {
    return <Icon className="team-role-icon" />;
};

export default TeamRoleIcon;
