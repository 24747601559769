import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import {
    allChecklistStatusesAsDictionary,
    ChecklistDynamicStatus,
} from "models/enums/ChecklistDynamicStatus";
import { IOption } from "common/IOption";
import WorksheetStatusBadgeRenderer from "../WorksheetStatusBadgeRenderer";

interface IProps {
    checklist?: IChecklistEntityDto;

    status?: ChecklistDynamicStatus;
    endDate?: Date;
    archivedAt?: Date;
    stoppedAt?: Date;
    completedAt?: Date;
}

const WorksheetStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { endDate, archivedAt, stoppedAt, completedAt, checklist } = props;

    const { t, i18n } = useTranslation();

    const status = props.status ?? checklist?.status;

    let dateToShow: Date | undefined;

    if (status === ChecklistDynamicStatus.Archived) {
        dateToShow = archivedAt ?? checklist?.archivedAt;
    }

    if (status === ChecklistDynamicStatus.Finalized) {
        dateToShow = completedAt ?? checklist?.completedAt;
    }

    if (status === ChecklistDynamicStatus.Behind) {
        dateToShow = endDate ?? checklist?.endDate;
    }

    if (status === ChecklistDynamicStatus.Stopped) {
        dateToShow = stoppedAt ?? checklist?.pausedAt;
    }

    const option = useMemo(() => {
        let result: IOption | undefined;

        const statusKey = status ?? ChecklistDynamicStatus.None;

        if (statusKey !== ChecklistDynamicStatus.None) {
            result = allChecklistStatusesAsDictionary[statusKey];
        }

        return result;
    }, [i18n.language, status]);

    return (
        <WorksheetStatusBadgeRenderer
            label={t(option?.label ?? k.NO_STATUS)}
            className={option?.param}
            completedAt={dateToShow}
        />
    );
};

export default WorksheetStatusBadge;
