import "./Modal.scss";

import React, { useMemo } from "react";
import { Modal as ModalDialog } from "react-bootstrap";

import { ModalContext } from "./components/Context";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Title from "./components/Title";
import { IModalComposition } from "./IModalComposition";
import Header from "./components/Header";

interface IProps {
    testId?: string;
    wrapperClassName?: string;
    className?: string;

    noFooter?: boolean;

    fullHeight?: boolean;
    size?: "sm" | "lg" | "xl" | "full";
    variant?: "danger" | "success";

    mode?: "above-all" | "under-all";
    show?: boolean;

    keyboard?: boolean;
    backdrop?: true | false | "static";

    onHide: () => void;
    onShow?: () => void;
}

const Modal: React.FC<React.PropsWithChildren<IProps>> & IModalComposition = (
    props,
) => {
    const {
        testId,
        className,
        show,
        mode,
        size,
        variant,
        noFooter,
        fullHeight,
        keyboard,
        backdrop,
    } = props;

    const contextValue = useMemo(() => {
        const result = {};

        return result;
    }, []);

    const dialogClassName = useMemo(() => {
        const result = ["app-modal"];

        if (className) {
            result.push(className);
        }

        if (noFooter) {
            result.push("app-modal--no-footer");
        }

        if (fullHeight) {
            result.push("app-modal--full-height");
        }

        if (size === "full") {
            result.push("width-full");
        }

        if (variant) {
            result.push(`app-modal--variant-${variant}`);
        }

        return result.join(" ");
    }, [className, noFooter, fullHeight, size, variant]);

    const dialogBackdropClassName = `app-modal__backdrop ${mode ?? ""}`;

    const wrapperClassName = `${props.wrapperClassName ?? ""} ${mode ?? ""}`;

    return show ? (
        <ModalDialog
            show
            onHide={props.onHide}
            onShow={props.onShow}
            dialogClassName={dialogClassName}
            backdropClassName={dialogBackdropClassName}
            size={size === "full" ? undefined : size}
            className={wrapperClassName}
            data-testid={testId}
            enforceFocus={mode === "under-all" ? false : undefined}
            keyboard={keyboard}
            backdrop={backdrop}
        >
            <ModalContext.Provider value={contextValue}>
                {props.children}
            </ModalContext.Provider>
        </ModalDialog>
    ) : null;
};

Modal.Header = Header;
Modal.Title = Title;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
