import { useMemo } from "react";

export interface IModalContentBodyProps {
    className?: string;
    noFooter?: boolean;
}

const ModalContentBody: React.FC<
    React.PropsWithChildren<IModalContentBodyProps>
> = (props) => {
    const className = useMemo(() => {
        const result = ["modal-manager--modal-content--body"];

        if (props.className) {
            result.push(props.className);
        }

        if (props.noFooter) {
            result.push("modal-manager--modal-content--no-footer");
        }

        return result.join(" ");
    }, [props.className, props.noFooter]);

    return <div className={className}>{props.children}</div>;
};

export default ModalContentBody;
