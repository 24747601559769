import "./PropertyButton.scss";

import React, { useRef, useState } from "react";
import Button from "common/components/buttons/Button";
import CloseSvg from "./assets/close.svg";
import AddSvg from "./assets/add.svg";
import PropertyList from "./components/PropertyList";
import { CustomListPropertyEnum } from "../../custom-list-page/api/CustomListPropertyEnum";
import ITableSet from "http/ITableSet";
import { ICustomListOptionDto } from "components/custom-list-page/api/ICustomListOptionDto";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface IProps {
    excludeCustomListParentId: string | null;
    properties: ITableSet<ICustomListOptionDto> | null;

    index: number;
    isDisabled: boolean;
    isBlur: boolean;

    invalid: boolean;

    showList: (index: number | undefined) => void;

    onSelectCustomListProperty: (
        id: string,
        label: string,
        index: number,
    ) => void;

    onSelectBasicProperty: (
        type: CustomListPropertyEnum,
        label: string,
        index: number,
    ) => void;
}

const PropertyButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { index, isDisabled, isBlur, invalid, excludeCustomListParentId } =
        props;

    const { t } = useTranslation();

    const [isListVisible, setIsListVisible] = useState(false);

    const sectionRef = useRef<HTMLDivElement>(null);

    const properties = React.useMemo(() => {
        let result: ITableSet<ICustomListOptionDto> | null = null;

        if (props.properties) {
            result = {
                ...props.properties,
                ids: props.properties.ids.filter(
                    (parentId) =>
                        excludeCustomListParentId === null ||
                        (excludeCustomListParentId &&
                            parentId !== excludeCustomListParentId),
                ),
            };
        }

        return result;
    }, [excludeCustomListParentId, props.properties]);

    const invokeShowList = (show: boolean) => {
        if (show && sectionRef.current) {
            setTimeout(
                () =>
                    sectionRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    }),
                50,
            );
        }

        props.showList(show ? index : undefined);
    };

    const handleShowList = () => {
        invokeShowList(!isListVisible);

        setIsListVisible((prev) => !prev);
    };

    const handleSelectCustomListProperty = (id: string, title: string) => {
        props.onSelectCustomListProperty(id, title, index);

        invokeShowList(false);

        setIsListVisible(false);
    };

    const handleSelectBasicProperty = (
        type: CustomListPropertyEnum,
        title: string,
    ) => {
        props.onSelectBasicProperty(type, title, index);

        invokeShowList(false);

        setIsListVisible(false);
    };

    return (
        <div
            className={
                isBlur
                    ? "property-button__wrapper no-opacity property-button__wrapper--blur"
                    : isListVisible
                      ? "property-button__wrapper no-opacity"
                      : "property-button__wrapper"
            }
            ref={sectionRef}
        >
            <div className={`property-button${invalid ? " invalid" : ""}`}>
                <hr />
                <Button
                    variant={invalid ? "danger" : "blue"}
                    transparent
                    disabled={isDisabled}
                    onClick={handleShowList}
                    icon={isListVisible ? <CloseSvg /> : <AddSvg />}
                    testId={`custom-list--add-property--btn--${index}`}
                >
                    {t(k.ADD_PROPERTY)}
                </Button>
                <hr />
            </div>

            {isListVisible && (
                <div className="property-button__list">
                    <PropertyList title="Add new property">
                        <PropertyList.BasicProperties
                            onSelect={handleSelectBasicProperty}
                        />
                    </PropertyList>

                    {properties && properties.ids.length > 0 && (
                        <PropertyList title="Custom List">
                            <PropertyList.CustomListProperties
                                properties={properties}
                                onSelect={handleSelectCustomListProperty}
                            />
                        </PropertyList>
                    )}
                </div>
            )}
        </div>
    );
};

export default PropertyButton;
