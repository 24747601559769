import "./GoalStatusBadgeWithTooltip.scss";

import { EGoalGrading } from "components/goals-page/api/EGoalGrading";
import GoalStatusBadge from "../goal-status-badge/GoalStatusBagde";
import IconWithMessage from "components/common/iconsWithMessage/IconWithMessage";

interface IProps {
    id: string;
    status?: EGoalGrading;
    message?: string;
    hideOnTrack?: boolean;
}

const GoalStatusBadgeWithTooltip = (props: IProps) => {
    const { status, message, id, hideOnTrack } = props;
    const showTooltip =
        (status === EGoalGrading.Blocked || status === EGoalGrading.AtRisk) &&
        !!message;

    return (
        <GoalStatusBadge
            status={status ?? EGoalGrading.OnTrack}
            hideOnTrack={hideOnTrack}
            className="goal-status-badge-with-tooltip"
            info={
                showTooltip && (
                    <IconWithMessage
                        id={id}
                        message={message}
                        iconType="info"
                        darkBackground={status === EGoalGrading.Blocked}
                    />
                )
            }
        />
    );
};
export default GoalStatusBadgeWithTooltip;
