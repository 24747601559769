import "./ResumeIcon.scss";

import React, { useMemo } from "react";

import Icon from "./assets/resume-icon.svg";

interface IProps {
    variant?: "white";
}

const ResumeIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { variant } = props;
    const className = useMemo(() => {
        const result = ["resume-icon"];
        if (variant) {
            result.push(`resume-icon--${variant}`);
        }
        return result.join(" ");
    }, [variant]);

    return <Icon className={className} />;
};

export default ResumeIcon;
