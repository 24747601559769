import "./WorksheetCreatedBy.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Avatar from "common/components/avatar/Avatar";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import { ApiKeyIcon, ScheduleIcon } from "../icons";
import Button from "../buttons/Button";

interface IProps {
    value: IChecklistEntityDto;

    onShowRecurrence: () => void;

    hideSchedule?: boolean;
}

const WorksheetCreatedBy: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { value, hideSchedule } = props;
    const { t } = useTranslation();

    if (
        (value.scheduleId ?? value.apiKeyId) &&
        (hideSchedule ?? false) === false
    ) {
        return (
            <div className="worksheet-createdby__wrapper">
                {value.apiKeyId ? (
                    <ApiKeyIcon title={"API"} />
                ) : (
                    <ScheduleIcon title={t(k.SCHEDULE)} />
                )}

                {value.apiKeyId ? (
                    <span className="d-flex align-items-center">
                        {value.apiKeyName}
                    </span>
                ) : (
                    <Button onClick={props.onShowRecurrence} variant="link">
                        {value.scheduleName}
                    </Button>
                )}
            </div>
        );
    }

    return value.createdByName ? (
        <div className="worksheet-createdby__wrapper">
            <Avatar name={value.createdByName} size={24} />

            <span className="d-flex align-items-center">
                {value.createdByName}
            </span>
        </div>
    ) : (
        <React.Fragment>{t(k.SYSTEM)}</React.Fragment>
    );
};

export default WorksheetCreatedBy;
