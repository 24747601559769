import "./HistoryIcon.scss";

import React from "react";
import { MdOutlineHistory } from "react-icons/md";
interface IProps {
    size?: number;
}

const HistoryIcon: React.FC<IProps> = (props) => {
    return (
        <MdOutlineHistory
            size={props.size}
            stroke="none"
            fill="currentColor"
            className="icon-history"
        />
    );
};

export default HistoryIcon;
