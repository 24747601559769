import { Reducer } from "redux";
import orm, { initialOrmState } from "../orm";
import { IORMState } from "../IORMState";

import {
    RECEIVE_DELETE_WORKFLOW,
    RECEIVE_GET_PUBLISHED_WORKFLOWS,
    RECEIVE_GET_WORKFLOW_ALL,
    RECEIVE_SAVE_WORKFLOW,
    WorkflowActionTypes,
    RECEIVE_GET_WORKFLOW_DRAFT,
    REQUEST_GET_WORKFLOW_DRAFT,
    RECEIVE_DELETE_WORKFLOW_DRAFT,
} from "./types";

import {
    WorkflowOrmModelType,
    LatestVersionWorkflowOrmModelType,
    SelectedWorkflowOrmModelType,
} from "../../models/orm/Workflow";
import IWorkflow from "../../models/IWorkflow";

export const WorkflowOrmReducer: Reducer = (
    state: IORMState = initialOrmState,
    action: WorkflowActionTypes,
): IORMState => {
    const session = orm.session(state);

    const Workflow = session.Workflow as WorkflowOrmModelType;
    const LatestVersionWorkflow =
        session.LatestVersionWorkflow as LatestVersionWorkflowOrmModelType;
    const SelectedWorkflow =
        session.SelectedWorkflow as SelectedWorkflowOrmModelType;

    switch (action.type) {
        case RECEIVE_GET_WORKFLOW_ALL: {
            Workflow.parseList(action.payload);

            break;
        }

        case RECEIVE_GET_PUBLISHED_WORKFLOWS: {
            Workflow.parseList(action.payload);

            break;
        }

        case REQUEST_GET_WORKFLOW_DRAFT: {
            LatestVersionWorkflow.all().delete();

            break;
        }

        case RECEIVE_GET_WORKFLOW_DRAFT: {
            LatestVersionWorkflow.parseDTO(action.payload);

            break;
        }

        case RECEIVE_SAVE_WORKFLOW: {
            const savedWorkflow = action.payload;

            if (savedWorkflow.isDraft === false) {
                Workflow.all()
                    .filter(
                        (x: IWorkflow) =>
                            x.id !== savedWorkflow.id &&
                            x.workflowVersionId ===
                                savedWorkflow.workflowVersionId,
                    )
                    .delete();
            }

            Workflow.parseDTO(savedWorkflow);

            break;
        }

        case RECEIVE_DELETE_WORKFLOW_DRAFT: {
            Workflow.all()
                .filter(
                    (x: IWorkflow) =>
                        x.workflowVersionId === action.workflowVersionId,
                )
                .delete();

            break;
        }

        case RECEIVE_DELETE_WORKFLOW: {
            Workflow.all()
                .filter(
                    (x: IWorkflow) =>
                        x.workflowVersionId === action.workflowVersionId,
                )
                .delete();

            break;
        }

        // case RECEIVE_ARCHIVE_WORKFLOW: {
        //     const isArchived = Boolean(action.payload.archivedAt);

        //     if (isArchived && action.hide) {
        //         Workflow.all()
        //             .filter(
        //                 (x: IWorkflow) =>
        //                     x.workflowVersionId === action.payload.id,
        //             )
        //             .delete();

        //         LatestVersionWorkflow.all()
        //             .filter(
        //                 (x: IWorkflow) =>
        //                     x.workflowVersionId === action.payload.id,
        //             )
        //             .delete();
        //     } else {
        //         const updatedValues = {
        //             isArchived,
        //             archivedAt: action.payload.archivedAt,
        //             archivedByName: action.payload.name,
        //         };

        //         Workflow.all()
        //             .filter(
        //                 (x: IWorkflow) =>
        //                     x.workflowVersionId === action.payload.id,
        //             )
        //             .update(updatedValues);

        //         LatestVersionWorkflow.all()
        //             .filter(
        //                 (x: IWorkflow) =>
        //                     x.workflowVersionId === action.payload.id,
        //             )
        //             .update(updatedValues);
        //     }

        //     break;
        // }
    }

    return session.state;
};
