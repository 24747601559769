export const arrayMoveTo = <T>(source: T[], from: number, to: number): T[] => {
    const item = source[from];

    const tempArray = [...source];
    tempArray.splice(from, 1);
    tempArray.splice(to, 0, item);

    return tempArray;
};

export const arrayMoveManyTo = <T>(
    source: T[],
    from: number,
    to: number,
    amount: number,
): T[] => {
    const tempArray = [...source];
    const items = tempArray.splice(from, amount);
    tempArray.splice(to, 0, ...items);

    return tempArray;
};

export function arrayMoveRelatively<T>(
    replacedId: string,
    source: T[],
    anchorAfterId?: string,
    anchorBeforeId?: string,
): T[] {
    // Make a shallow copy and sort items by their Index
    const replacedItemIndex = source.findIndex((item) => item === replacedId);

    // If the replaced item isn't found, return the original list
    if (
        replacedItemIndex === -1 ||
        (anchorAfterId === undefined && anchorBeforeId === undefined)
    )
        return source;

    // Remove the replaced item from its current position
    const replacedItem = source.splice(replacedItemIndex, 1)[0];

    // Determine the new index for the replaced item
    let newIndex = calculateNewIndex(source, anchorAfterId, anchorBeforeId);

    // Insert the replaced item at its new position
    source.splice(newIndex, 0, replacedItem);

    return source;
}

export function calculateNewIndex<T>(
    source: T[],
    anchorAfterId?: string,
    anchorBeforeId?: string,
): number {
    const beforeItemIndex =
        anchorBeforeId !== undefined
            ? source.findIndex((item) => item === anchorBeforeId)
            : -1;
    const afterItemIndex =
        anchorAfterId !== undefined
            ? source.findIndex((item) => item === anchorAfterId)
            : -1;

    // Determine where to insert the replaced item
    let insertIndex =
        afterItemIndex !== -1
            ? afterItemIndex
            : beforeItemIndex !== -1
              ? beforeItemIndex + 1
              : source.length;

    return insertIndex;
}

export type Infer<T> = T extends (infer U)[] ? U : T;
