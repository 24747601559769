import "./EditIcon.scss";

import React from "react";
import Icon from "./assets/edit-icon.svg";
import { useMemo } from "react";

interface IProps {
    disabled?: boolean;
    variant?: "white" | "blue" | "gray";
}

const EditIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { disabled, variant } = props;

    const className = useMemo(() => {
        const result = ["edit-icon"];
        if (disabled) {
            result.push("edit-icon--disabled");
        }
        if (variant) {
            result.push(`edit-icon--${variant}`);
        }
        return result.join(" ");
    }, [disabled, variant]);
    return <Icon className={className} />;
};

export default EditIcon;
