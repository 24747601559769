import { TableRow } from "common/components/table";
import KPITableCell from "./KPITableCell";
import { IKpiFormulaCalculatedValue } from "common/components/modal-manager-content/kpi-form/api/IKpiFormulaCalculations";

interface IProps {
    kpiUnit?: string;
    decimalPlaces?: number;
    monthlyValues: Array<IKpiFormulaCalculatedValue | undefined>;
    year: number;
    timeUnitKeys: string[];

    overIsGood: boolean;
    overThreshold?: number;
    underThreshold?: number;
}

const KPITableRow = (props: IProps) => {
    const {
        monthlyValues,
        year,
        timeUnitKeys,
        overIsGood,
        underThreshold,
        overThreshold,
        kpiUnit,
        decimalPlaces,
    } = props;

    return (
        <TableRow>
            <KPITableCell.FirstCellWithLegend
                year={year}
                kpiUnitName={kpiUnit}
            />

            {timeUnitKeys.map((timeUnitKey, i) => (
                <KPITableCell
                    key={i}
                    kpiUnit={kpiUnit}
                    decimalPlaces={decimalPlaces}
                    value={monthlyValues[i]}
                    year={year}
                    timeUnitKey={timeUnitKey}
                    overIsGood={overIsGood}
                    overThreshold={overThreshold}
                    underThreshold={underThreshold}
                />
            ))}
        </TableRow>
    );
};

export default KPITableRow;
