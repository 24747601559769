import React from "react";
import { connect } from "react-redux";

import { hideConfirm } from "../../../store/confirms/actions";
import { IConfirmState } from "../../../store/confirms/reducer";
import { confirmSelector } from "../../../store/confirms/selectors";
import { IAppState } from "../../../store/IAppState";
import Confirm from "./Confirm";

interface IStateProps {
    confirmState: IConfirmState;
}

interface IDispatchProps {
    hideConfirm: (confirmOk?: boolean) => void;
}

type Props = IStateProps & IDispatchProps;

const ModalConfirmManager: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { isVisible, confirm } = props.confirmState;

    return isVisible ? (
        <Confirm onHide={props.hideConfirm} settings={confirm} />
    ) : null;
};

const mapStateToProps = (state: IAppState): IStateProps => {
    const confirmState = confirmSelector(state);

    return {
        confirmState,
    };
};

const actions: IDispatchProps = {
    hideConfirm,
};

export default connect(mapStateToProps, actions)(ModalConfirmManager);
