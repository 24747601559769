import "./ActivityInstanceSetCard.scss";

import React from "react";

import IAttachment from "models/IAttachment";
import AttachmentList from "../attachment-list/AttachmentList";
import ImprovementSummary from "../deviation/ImprovementSummary";

import { ActivityType } from "models/enums/ActivityType";
import { RequiredStarIcon } from "../icons";

interface IProps {
    id: string;

    index: number;
    required?: boolean;

    title?: React.ReactNode;

    description?: string;

    isLoadingAttachments?: boolean;
    attachments?: IAttachment[];

    notCompletedImprovementsCount?: number;
    completedImprovementsCount?: number;

    activityPhoto?: React.ReactNode;

    type?: ActivityType;

    onShowImprovements?: (onlyNotCompleted: boolean) => void;
}

const ActivityInstanceSetCard: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        id,
        index,
        title,
        description,
        isLoadingAttachments,
        attachments,
        notCompletedImprovementsCount,
        completedImprovementsCount,
        activityPhoto,
        required,
        type,
    } = props;

    const totalImprovementsCount =
        (notCompletedImprovementsCount ?? 0) +
        (completedImprovementsCount ?? 0);

    const showImprovementStats = totalImprovementsCount > 0;

    const showInfo = activityPhoto || description;

    return (
        <div className="activity-instance--card">
            <h6 className="activity-instance--title">
                {index}. <strong>{title}</strong>
                {required && type !== ActivityType.Tasklist && (
                    <RequiredStarIcon />
                )}
            </h6>
            {showInfo && (
                <div className="activity-instance--card-info">
                    {activityPhoto && (
                        <div className="activity-instance--photo">
                            {activityPhoto}
                        </div>
                    )}

                    {description && (
                        <div className="box-description">{description}</div>
                    )}
                </div>
            )}
            {showImprovementStats && props.onShowImprovements && (
                <ImprovementSummary
                    notCompletedCount={notCompletedImprovementsCount}
                    completedCount={completedImprovementsCount}
                    onClick={props.onShowImprovements}
                />
            )}

            <AttachmentList
                isLoading={isLoadingAttachments}
                attachments={attachments}
            />

            {props.children}
        </div>
    );
};

export default ActivityInstanceSetCard;
