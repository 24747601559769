import axios from "axios";
import { useQuery } from "react-query";

import { IValueLabelItem } from "common/IValueLabelItem";
import ITemplateStepsListDTO from "models/dto/ITemplateStepsListDTO";
import IStepListDTO from "models/dto/IStepListDTO";
import IStepDataDTO from "models/dto/IStepDataDTO";
import IStep from "models/IStep";

const baseUrl = "/api/step";

export const useStepOptionsByChecklistTemplateVersion = (
    templateVersionId?: string,
) => {
    const url = `${baseUrl}/options-by-checklist-template-version/${templateVersionId}`;

    return useQuery(
        ["published-step-options-by-template-version-id", templateVersionId],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
        { enabled: Boolean(templateVersionId) },
    );
};

export const STEPS_BY_TEMPLATE_VERSION_ID = "steps-by-template-version-id";

export const useStepsByTemplateVersionId = (
    templateVersionId?: string,
    notifyOnChangeData?: boolean,
    enable?: boolean,
) => {
    return useQuery(
        [STEPS_BY_TEMPLATE_VERSION_ID, templateVersionId],
        async (context) => {
            const url = `${baseUrl}/by-template-version/${templateVersionId}`;

            const response = await axios.get<ITemplateStepsListDTO>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: enable && !!templateVersionId,
            notifyOnChangeProps: notifyOnChangeData ? ["data"] : undefined,
        },
    );
};

export const STEPS_DRAFT_BY_TEMPLATE_VERSION_ID =
    "steps-draft-by-template-version-id";

export const useDraftStepsByTemplateVersionId = (
    templateVersionId?: string,
    notifyOnChangeData?: boolean,
    enabled?: boolean,
) => {
    return useQuery(
        [STEPS_DRAFT_BY_TEMPLATE_VERSION_ID, templateVersionId],
        async (context) => {
            const url = `${baseUrl}/by-template-version/draft/${templateVersionId}`;

            const response = await axios.get<ITemplateStepsListDTO>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: !!templateVersionId && enabled,
            notifyOnChangeProps: notifyOnChangeData ? ["data"] : undefined,
        },
    );
};

export const useChecklistStepsByTemplateId = (templateId?: string) => {
    return useQuery(
        ["CHECKLIST_STEPS_BY_TEMPLATE_ID", templateId],
        async (context) => {
            const url = `${baseUrl}/by-template-id/${templateId}`;

            const response = await axios.get<IStepListDTO>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: !!templateId },
    );
};

export const SHARED_STEPS = "shared-steps";

export const useSharedSteps = () => {
    const url = `${baseUrl}/shared`;

    return useQuery(
        [SHARED_STEPS],
        async (context) => {
            const response = await axios.get<IStep[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {},
    );
};
export const useStepDataById = async (stepId: string) => {
    const url = `${baseUrl}/dataById/${stepId}`;

    const response = await axios.get<IStepDataDTO>(url);

    return response.data;
};
