import "./ImprovementBadge.scss";

import React from "react";
import { connect } from "react-redux";

import { IAppState } from "store/IAppState";
import { DeviationsIcon } from "common/components/icons";
import Tooltip from "common/components/tooltip/Tooltip";
import ImprovementsMessage from "./ImprovementsMessage";
import { IProcessNodeImprovements } from "components/process-chart/api/IProcessNodeImprovements";
import { ImprovementProcessChartImpactType } from "components/process-chart/api/improvements/ImprovementProcessChartImpactType";

export enum ImprovementBadgeColor {
    Red = "red",
    Gray = "gray",
    White = "white",
}
interface IProps {
    parentId: string;

    data?: IProcessNodeImprovements;

    impactType: ImprovementProcessChartImpactType;

    onShow: (impactType: ImprovementProcessChartImpactType) => void;

    badgeColor?: ImprovementBadgeColor;
}

interface IStateProps {
    showPublished: boolean;
}

type Props = IProps & IStateProps;

const ImprovementBadge: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const {
        parentId,
        showPublished,
        data,
        impactType,
        badgeColor = ImprovementBadgeColor.Red,
    } = props;

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        props.onShow(impactType);
    };

    if (data && data.total > 0) {
        return (
            <div className="pc-improvement-badge__wrapper">
                <Tooltip
                    id={`node-improvements-${
                        showPublished ? "published" : "draft"
                    }-${parentId}`}
                    message={
                        <ImprovementsMessage
                            impactType={impactType}
                            values={data}
                        />
                    }
                >
                    <div
                        className={`pc-improvement-badge__wrapper--content ${badgeColor}`}
                        onClick={handleClick}
                    >
                        <DeviationsIcon badgeColor={badgeColor} />

                        <span>{data.total}</span>
                    </div>
                </Tooltip>
            </div>
        );
    }

    return null;
};

const mapToProps = (appState: IAppState): IStateProps => {
    const { showPublished } = appState.processChartViewState;

    return {
        showPublished,
    };
};

export default connect(mapToProps)(ImprovementBadge);
