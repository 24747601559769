import { Action } from "redux";

import IWorkflowDTO from "../../models/dto/IWorkflowDTO";
import IWorkflowListDTO from "../../models/dto/IWorkflowListDTO";
import IArchiveResponse from "http/responses/IArchiveResponse";

// ActionTypes
export const SET_WORKFLOW_IS_LOADING = "SET_WORKFLOW_IS_LOADING";

export const REQUEST_GET_WORKFLOW_ALL = "REQUEST_GET_WORKFLOW_ALL";
export const RECEIVE_GET_WORKFLOW_ALL = "RECEIVE_GET_WORKFLOW_ALL";

export const REQUEST_GET_WORKFLOW_DRAFT = "REQUEST_GET_WORKFLOW_DRAFT";
export const RECEIVE_GET_WORKFLOW_DRAFT = "RECEIVE_GET_WORKFLOW_DRAFT";

export const REQUEST_GET_PUBLISHED_WORKFLOWS =
    "REQUEST_GET_PUBLISHED_WORKFLOWS";
export const RECEIVE_GET_PUBLISHED_WORKFLOWS =
    "RECEIVE_GET_PUBLISHED_WORKFLOWS";

export const RECEIVE_SAVE_WORKFLOW = "RECEIVE_SAVE_WORKFLOW";

export const REQUEST_DELETE_WORKFLOW = "REQUEST_DELETE_WORKFLOW";
export const RECEIVE_DELETE_WORKFLOW = "RECEIVE_DELETE_WORKFLOW";
export const FAILURE_DELETE_WORKFLOW = "FAILURE_DELETE_WORKFLOW";

export const REQUEST_DELETE_WORKFLOW_DRAFT = "REQUEST_DELETE_WORKFLOW_DRAFT";
export const RECEIVE_DELETE_WORKFLOW_DRAFT = "RECEIVE_DELETE_WORKFLOW_DRAFT";
export const FAILURE_DELETE_WORKFLOW_DRAFT = "FAILURE_DELETE_WORKFLOW_DRAFT";

// Actions

interface IWorkflowSetIsLoadingAction
    extends Action<typeof SET_WORKFLOW_IS_LOADING> {
    payload: boolean;
}

interface IWorkflowReceiveGetAllAction
    extends Action<typeof RECEIVE_GET_WORKFLOW_ALL> {
    payload: IWorkflowListDTO;
}

interface IWorkflowRequestGetAllAction
    extends Action<typeof REQUEST_GET_WORKFLOW_ALL> {}

interface IWorkflowReceiveDraftAction
    extends Action<typeof RECEIVE_GET_WORKFLOW_DRAFT> {
    payload: IWorkflowDTO;
}

interface IWorkflowRequestDraftAction
    extends Action<typeof REQUEST_GET_WORKFLOW_DRAFT> {}

interface IWorkflowReceiveSaveAction
    extends Action<typeof RECEIVE_SAVE_WORKFLOW> {
    payload: IWorkflowDTO;
}

interface IWorkflowRequestDeleteAction
    extends Action<typeof REQUEST_DELETE_WORKFLOW> {}

interface IWorkflowReceiveDeleteAction
    extends Action<typeof RECEIVE_DELETE_WORKFLOW> {
    workflowVersionId: string;
}

interface IWorkflowFailureDeleteAction
    extends Action<typeof FAILURE_DELETE_WORKFLOW> {}

interface IWorkflowRequestDeleteDraftAction
    extends Action<typeof REQUEST_DELETE_WORKFLOW_DRAFT> {}

interface IWorkflowReceiveDeleteDraftAction
    extends Action<typeof RECEIVE_DELETE_WORKFLOW_DRAFT> {
    workflowVersionId: string;
}

interface IWorkflowFailureDeleteDraftAction
    extends Action<typeof FAILURE_DELETE_WORKFLOW_DRAFT> {}

interface IWorkflowRequestGetPublishedWorkflowsAction
    extends Action<typeof REQUEST_GET_PUBLISHED_WORKFLOWS> {}

interface IWorkflowReceiveGetPublishedWorkflowsAction
    extends Action<typeof RECEIVE_GET_PUBLISHED_WORKFLOWS> {
    payload: IWorkflowListDTO;
}

export type WorkflowActionTypes =
    | IWorkflowSetIsLoadingAction
    | IWorkflowReceiveGetAllAction
    | IWorkflowRequestGetAllAction
    | IWorkflowReceiveDraftAction
    | IWorkflowRequestDraftAction
    | IWorkflowReceiveSaveAction
    | IWorkflowRequestDeleteAction
    | IWorkflowReceiveDeleteAction
    | IWorkflowFailureDeleteAction
    | IWorkflowRequestDeleteDraftAction
    | IWorkflowReceiveDeleteDraftAction
    | IWorkflowFailureDeleteDraftAction
    | IWorkflowRequestGetPublishedWorkflowsAction
    | IWorkflowReceiveGetPublishedWorkflowsAction;
