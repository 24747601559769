import React from "react";

import Dropdown, { IDropdownProps } from "./Dropdown";
import { ItemIcon } from "common/components/icons";
import { IInputProps } from "../IInputProps";
import { ESharedOptionsType, useSharedOptions } from "./api/hooks";

type Props = IDropdownProps & IInputProps<string>;

const ItemDropdown = (props: Props) => {
    const { data: sharedOptions, refetch } = useSharedOptions(
        false,
        ESharedOptionsType.item,
        props.selectedIds,
    );

    React.useEffect(() => {
        refetch();
    }, []);

    return (
        <Dropdown
            {...props}
            options={props.options ?? sharedOptions}
            icon={<ItemIcon />}
        />
    );
};

export default ItemDropdown;
