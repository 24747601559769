import "./StepInstanceFinalizeCard.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import FinalizeImg from "./assets/finalize.png";
import IncompleteImg from "./assets/incomplete.png";
import Avatar from "../avatar/Avatar";
import Button from "../buttons/Button";
import { IValueLabelItem } from "common/IValueLabelItem";
import ButtonGroup from "../buttons/ButtonGroup";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import FormattedDate from "../formattedDate/FormattedDate";
import { CgLockUnlock } from "react-icons/cg";

interface IProps {
    stepInstanceStatus: StepInstanceStatus;
    checklistStatus: ChecklistDynamicStatus;

    finalizedBy?: string;
    finalizedAt?: Date;

    hasResponse?: boolean;
    needsConfirmation?: boolean;
    isAnyArchivedOrDeleted?: boolean;

    requiredActivities?: Array<IValueLabelItem<number, string, number>>;

    onFinalizeClick: (confirmed: boolean) => void;
    onUnfinalizeClick?: () => void;
    onCancel: () => void;
}

const StepInstanceFinalizeCard: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        stepInstanceStatus,
        checklistStatus,
        needsConfirmation,
        requiredActivities,
        hasResponse,
        finalizedBy,
        finalizedAt,
    } = props;

    const { t } = useTranslation();

    const canUnfinalize = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.allowUnfinalize ??
            false
        );
    });

    const handleFinalize = () => {
        props.onFinalizeClick(false);
    };

    const handleUnfinalize = () => {
        props.onUnfinalizeClick?.();
    };

    const handleConfirmedFinalize = () => {
        props.onFinalizeClick(true);
    };

    const isStopped = checklistStatus === ChecklistDynamicStatus.Stopped;
    const isArchived = checklistStatus === ChecklistDynamicStatus.Archived;

    const disabled =
        isStopped ||
        isArchived ||
        stepInstanceStatus === StepInstanceStatus.Blocked;

    if (hasResponse) {
        if (requiredActivities && requiredActivities.length > 0) {
            return (
                <div className="finalize-card__wrapper finalize-card--invalid">
                    <div className="img">
                        <img
                            className="img-icon img-icon--incomplete"
                            src={IncompleteImg}
                            alt=""
                        />
                    </div>
                    <div className="desc desc-small">
                        {t(
                            k.IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED,
                        )}
                        .
                        <br />
                        {requiredActivities.length === 1 ? (
                            <React.Fragment>
                                {t(k.COMPLETE_THE)}{" "}
                                <span className="invalid">
                                    {t(k.ACTIVITY)}{" "}
                                    {requiredActivities[0].value}:{" "}
                                    {requiredActivities[0].label}
                                </span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {t(k.COMPLETE_THE_ACTIVITIES)}:
                                <ul className="invalid">
                                    {requiredActivities.map((x, i) => (
                                        <li key={i}>
                                            {x.param && (
                                                <React.Fragment>
                                                    {x.param}.
                                                </React.Fragment>
                                            )}
                                            {x.value}. {x.label}
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )}
                    </div>

                    <Button
                        testId="finalizeCardBtn"
                        disabled={disabled}
                        onClick={handleFinalize}
                    >
                        {t(k.FINALIZE)}
                    </Button>
                </div>
            );
        } else if (needsConfirmation) {
            return (
                <div className="finalize-card__wrapper">
                    <div className="img">
                        <img
                            className="img-icon img-icon--incomplete"
                            src={IncompleteImg}
                            alt=""
                        />
                    </div>
                    <div className="desc">
                        <div className="invalid">
                            {t(k.ACTIVITIES_EXISTS_THAT_ARE_NOT_DONE)}
                        </div>

                        {t(k.FINALIZE_QUESTION)}
                        <ButtonGroup>
                            <Button
                                testId="finalizeCardConfirmBtn"
                                disabled={disabled}
                                onClick={handleConfirmedFinalize}
                            >
                                {t(k.CONFIRM)}
                            </Button>
                            <Button
                                testId="finalizeCardCancelBtn"
                                disabled={disabled}
                                onClick={props.onCancel}
                                variant="gray"
                            >
                                {t(k.CANCEL)}
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            );
        }
    }

    if (stepInstanceStatus === StepInstanceStatus.Finalized) {
        return (
            <div className="finalize-card__wrapper finalize-card--finalized">
                <div className="img">
                    <img className="img-icon" src={FinalizeImg} alt="" />
                </div>
                <div className="desc desc-small">
                    <h3 className="title">{t(k.THIS_STEP_IS_FINALIZED)}</h3>
                    <div className="finalized-by__wrapper">
                        <Avatar
                            name={finalizedBy}
                            className="finalized-by--avatar no-print"
                        />
                        <span className="finalized-at">
                            <FormattedDate
                                shortMonthName
                                showMonthName
                                twelveHourTime
                                date={finalizedAt}
                            />
                        </span>
                    </div>
                </div>
                {canUnfinalize && (
                    <div className="unfinalize-btn">
                        <Button
                            transparent
                            size="small"
                            variant="dark-blue"
                            testId="unfinalizeCardBtn"
                            icon={<CgLockUnlock />}
                            disabled={disabled}
                            onClick={handleUnfinalize}
                        >
                            {t(k.UNFINALIZE)}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="finalize-card__wrapper">
            <div className="img">
                <img className="img-icon" src={FinalizeImg} alt="" />
            </div>
            <div className="desc desc-small">
                <h3 className="title">{t(k.FINALIZED)}?</h3>
                {t(k.FINALIZE_NOTICE)}
            </div>

            <Button
                testId="finalizeCardBtn"
                disabled={disabled}
                onClick={handleFinalize}
            >
                {t(k.FINALIZE)}
            </Button>
        </div>
    );
};

export default StepInstanceFinalizeCard;
