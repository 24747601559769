import axios from "axios";

import ITemplateFilter from "components/templates/api/ITemplateFilter";
import IPaginatedList from "http/IPaginatedList";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import ITemplateRowDTO from "components/templates/api/ITemplateRowDTO";
import { convertToDate } from "common/utils/time";

export class TemplateService {
    private readonly url = "/api/template";

    public async getAllFiltered(filter: ITemplateFilter) {
        const response = await axios.post<IPaginatedList<ITemplateRowDTO>>(
            `${this.url}/list`,
            filter,
        );

        return response.data;
    }

    public async archive(request: IArchiveRequest) {
        const response = await axios.post<IArchiveResponse>(
            `${this.url}/archive`,
            request,
        );

        const result = response.data;

        result.archivedAt = convertToDate(result.archivedAt);

        return result;
    }

    public async copy(workflowVersionId: string) {
        const response = await axios.post<ITemplateRowDTO>(
            `${this.url}/copy/${workflowVersionId}`,
        );

        return response.data;
    }
}
