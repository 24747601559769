import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { SortEnd } from "react-sortable-hoc";

import { Button } from "common/components/buttons";
import ListedContent from "common/components/listed-content/ListedContent";
import EditableListItems from "common/components/listed-content/components/SidePanel/List/components/EditableListItems/EditableListItems";
import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import ListItemsDropdown from "common/components/listed-content/components/SidePanel/List/components/ListItemsDropdown/ListItemsDropdown";
import { arrayMoveTo } from "common/utils/arrayUtils";
import TitleWithValidation from "components/common/validation/TitleWithValidation";
import AddSharedStep from "components/templates/components/AddSharedStep";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";
import IStep from "models/IStep";
import { IAppState } from "store/IAppState";
import { v4 as uuidv4 } from "uuid";

import TemplateFormContext from "../../../context/TemplateFormContext";

import "./SidePanelList.scss";

const stepsReq = "steps.required";

const SidePanelList: React.FC = (props) => {
    const {
        mobileView,
        setSteps,
        steps,
        sharedSteps,
        onHaveChanges,
        isArchived,
        isSaving,
        errors,
        setErrors,
        handleOnAddOrReplaceSharedStep,
    } = useContext(TemplateFormContext);

    const appFeatures = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.appFeatures,
    );

    const { t } = useTranslation();

    const handleOnSorted = (params: SortEnd) => {
        if (setSteps && steps) {
            const sortedIds = arrayMoveTo(
                steps.ids,
                params.oldIndex,
                params.newIndex,
            );

            setSteps((prev) => ({ ...prev, ids: sortedIds }));
            onHaveChanges(true);
        }
    };

    const handleOnChangeName = (stepId: string, value: string) => {
        if (setSteps && steps) {
            setSteps((prev) => {
                const step = prev.values[stepId];

                if (step) {
                    onHaveChanges(true);

                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [stepId]: {
                                ...step,
                                name: value,
                                isChanged: true,
                            },
                        },
                    };
                }

                return prev;
            });
        }
    };

    const handleOnAddStep = () => {
        if (setSteps && steps) {
            const newStep: IStep = {
                id: uuidv4(),
                stepVersionId: uuidv4(),
                name: t(k.NEW_STEP),
                description: "",
                isLocal: true,
                isDraft: true,
                index: steps.ids.length,
                teamIds: [],
                equipmentIds: [],
                hasPublishedVersion: false,
                hasNewVersion: false,
                isTeamEveryone: true,
                version: 0,
                isAdded: true,
            };

            setSteps((prev) => ({
                ...prev,
                ids: [...prev.ids, newStep.id],
                values: {
                    ...prev.values,
                    [newStep.id]: newStep,
                },
            }));

            if (errors?.[stepsReq]) {
                setErrors((prev) => ({
                    ...prev,
                    [stepsReq]: undefined,
                }));
            }

            onHaveChanges(true);
        }
    };

    const handleOnDeleteStep = (stepId: string) => {
        if (setSteps && steps) {
            setSteps((prev) => {
                const step = prev.values[stepId];

                if (step) {
                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [stepId]: {
                                ...step,
                                isDeleted: !step.isDeleted,
                            },
                        },
                    };
                }

                return prev;
            });
            onHaveChanges(true);
        }
    };

    const onAddSharedStep = (stepId: string) => {
        if (stepId !== "" && setSteps && steps && sharedSteps) {
            const selectedStep = sharedSteps.find((x) => x.id === stepId);

            if (
                selectedStep &&
                Object.values(steps.values).some(
                    (x) => x && x.stepVersionId === selectedStep.stepVersionId,
                ) === false
            ) {
                handleOnAddOrReplaceSharedStep(stepId);
            }
        }
    };

    const buttonsDisabled = isArchived || isSaving;

    return (
        <ListedContent.SidePanel.List
            context={TemplateFormContext}
            testId="template-step-list"
            title={
                <TitleWithValidation
                    className="side-panel__list__title"
                    title={t(k.STEPS)}
                    bold
                    required
                    errors={getLocaleString(t, errors?.[stepsReq])}
                    validationTestId="step-list-validation"
                />
            }
        >
            {mobileView ? (
                <ListItemsDropdown
                    context={TemplateFormContext}
                    parentType={EParentType.template}
                />
            ) : (
                <EditableListItems
                    context={TemplateFormContext}
                    isDisabled={buttonsDisabled}
                    onChangeName={handleOnChangeName}
                    onSorted={handleOnSorted}
                    onDeleteStep={handleOnDeleteStep}
                />
            )}
            <div className="side-panel-list__add-btn">
                {appFeatures?.sharedSteps && (
                    <AddSharedStep
                        publishedSteps={sharedSteps ?? []}
                        disabledButton={buttonsDisabled}
                        onAdd={onAddSharedStep}
                    />
                )}
                <Button
                    testId="add-step-btn"
                    id="btn-add-step"
                    icon={<FiPlusCircle size="22" />}
                    disabled={buttonsDisabled}
                    onClick={handleOnAddStep}
                >
                    {t(k.CREATE_NEW_STEP)}
                </Button>
            </div>
        </ListedContent.SidePanel.List>
    );
};

export default SidePanelList;
