import "./ImprovementsTable.scss";

import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Pagination from "common/components/pagination/Pagination";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "common/components/table";
import { DeviationSortBy } from "models/enums/DeviationSortBy";
import ISortOrder from "http/filters/ISortOrder";
import { IAppState } from "store/IAppState";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { useProcessChartItemNames } from "components/process-chart/pages/api/hooks";
import { ITableList } from "http/ITableList";
import IDeviation from "models/IDeviation";
import { castImprovementImpactedProcesses } from "components/improvements/api/hooks";
import ImprovementsCard from "../ImprovementsCard";
import ImprovementTableRow from "./ImprovementTableRow";
import IUiFilter from "http/filters/IUiFilter";

import IDeviationFilter from "http/filters/IDeviationFilter";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";

import IDeviationPageFilter from "http/filters/IDeviationPageFilter";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import TableImprovementStatusFilter from "./filters/TableImprovementStatusFilter";
import {
    getImpactGradingOptions,
    getImpactGradingTypeByValue,
} from "components/impact-grading-page/api/ImpactGradingEnum";
import { TableImprovementFormFilter } from "./filters/TableImprovementFormFilter";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { TableImprovementResponsibleFilter } from "./filters/TableImprovementResponsibleFilter";
import { TableImprovementInvolvedFilter } from "./filters/TableImprovementInvolvedFilter";

export const IMPROVEMENTS_TABLE_PAGE_SIZE = 10;
export const IMPROVEMENTS_DEFAULT_SORT: ISortOrder<DeviationSortBy> = {
    sortBy: DeviationSortBy.Updated,
    sortAsc: false,
};

interface IOwnProps {
    items: ITableList<IDeviation>;
    name: string;
    isLoading: boolean;

    currentFilter: IDeviationPageFilter;
    showTypeFilter?: DeviationSortBy[];

    onChangeTableFilters: (filter: Partial<IDeviationFilter>) => void;
    onRefetchItems: () => void;
    onPageChange?: () => void;
}

interface IStateProps {
    showImpactedProcesses: boolean;
}

type Props = IStateProps & IOwnProps;

const ImprovementsTable = (props: Props) => {
    const {
        items = {
            ids: [],
            values: {},
        },
        isLoading,

        currentFilter,
        showImpactedProcesses,
        showTypeFilter = [
            DeviationSortBy.Impact,
            DeviationSortBy.Status,
            DeviationSortBy.Form,
            DeviationSortBy.Responsible,
            DeviationSortBy.Involved,
        ],
        name,
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const { t, i18n } = useTranslation();

    const [currentFilterOpen, setCurrentFilterOpen] =
        useState<DeviationSortBy>();

    const processChartItemNameRequest = React.useMemo(() => {
        return castImprovementImpactedProcesses(items);
    }, [items]);

    const { data: processChartItemNames = {} } = useProcessChartItemNames(
        processChartItemNameRequest,
    );

    const { data: publishedUsers } = usePublishedUserOptions();

    const impactOptions = getImpactGradingOptions(i18n);

    const handleChangeUiFilters = async (
        filter?: IUiFilter<DeviationSortBy>,
    ) => {
        const uiFilter: IUiFilter<DeviationSortBy> = {
            ...currentFilter,

            ...filter,
        };

        props.onChangeTableFilters(uiFilter);
    };

    useEffect(() => {
        if (!modalManagerContext.state.haveOpenModals) {
            props.onRefetchItems();
        }
    }, [modalManagerContext.state.haveOpenModals]);

    const handleSort = (
        e: React.UIEvent,
        isBusy?: boolean,
        name?: string | number,
    ) => {
        if (isBusy) {
            return;
        }

        if (typeof name === "number") {
            const newSortAsc =
                currentFilter.sortBy === name ? !currentFilter.sortAsc : true;

            const newSort: ISortOrder<DeviationSortBy> = {
                sortBy: name,
                sortAsc: newSortAsc,
            };

            handleChangeUiFilters(newSort);
        }
    };

    const handleOnView = (deviationId: string) => {
        const payload: IShowModalPayload = {
            id: deviationId,
            callerAddress: location.pathname,
            type: ModalTypes.improvement,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handlePageChange = (pageNumber: number) => {
        props.onPageChange?.();

        handleChangeUiFilters({ page: pageNumber });
    };

    const handleOnChangeImpactFilter = (newIds: string[]) => {
        const newImpacts = newIds.map((id) => getImpactGradingTypeByValue[id]);

        props.onChangeTableFilters({ impacts: newImpacts });
    };

    const handleOnShow = (id: DeviationSortBy) => (showNext: boolean) => {
        setCurrentFilterOpen(showNext ? id : undefined);
    };

    const handleOnChangeStatusFilter = ({
        ...values
    }: {
        status: DynamicImprovementStatus[];
        closureReasons: ClosureReasonEnum[];
    }) => {
        props.onChangeTableFilters(values);
    };

    const handleOnChangeFormFilter = (newIds: string[]) => {
        props.onChangeTableFilters({ improvementForms: newIds });
    };

    const handleOnChangeResponsibleFilter = (newIds: string[]) => {
        props.onChangeTableFilters({ responsibleUserIds: newIds });
    };

    const handleOnChangeInvolvedFilter = (newIds: string[]) => {
        props.onChangeTableFilters({ involvedUserIds: newIds });
    };
    const hasFilters =
        currentFilter &&
        (Boolean(currentFilter.keywordSearch) ||
            (currentFilter.impacts?.length ?? 0) > 0 ||
            (currentFilter.status?.length ?? 0) > 0 ||
            (currentFilter.closureReasons?.length ?? 0) > 0 ||
            (currentFilter.improvementForms?.length ?? 0) > 0 ||
            (currentFilter.responsibleUserIds?.length ?? 0) > 0 ||
            (currentFilter.involvedUserIds?.length ?? 0) > 0);
    return (
        <>
            <div className="show-in-mobile improvements--mobile-cards">
                {items.ids.map((itemId) => {
                    const item = items.values[itemId];

                    if (item) {
                        return (
                            <ImprovementsCard
                                key={itemId}
                                value={item}
                                onClick={handleOnView}
                                data-impact={items.values[itemId]?.impact}
                            />
                        );
                    }
                })}
                <Pagination
                    isBusy={isLoading}
                    activePage={currentFilter.page ?? 1}
                    pageSize={IMPROVEMENTS_TABLE_PAGE_SIZE}
                    totalCount={items.totalCount}
                    onChange={handlePageChange}
                />
            </div>

            <Table
                className="improvements--table"
                wrapperClassName="hide-in-mobile"
                resizable
                name={name}
                resizingKey="name"
                pagination={
                    <Pagination
                        isBusy={isLoading}
                        activePage={currentFilter.page ?? 1}
                        pageSize={IMPROVEMENTS_TABLE_PAGE_SIZE}
                        totalCount={items.totalCount}
                        onChange={handlePageChange}
                    />
                }
                isLoading={isLoading}
                recordsCount={items.ids.length}
                hasFilters={hasFilters}
                emptyMessage={
                    <React.Fragment>
                        <h3>{t(k.ITS_EMPTY_HERE)}</h3>
                        <p>
                            {t(
                                k.NO_AVAILABLE_IMPROVEMENTS_FOUND_FOR_YOUR_USER_ROLE,
                            )}
                        </p>
                    </React.Fragment>
                }
                noResultsMessage={
                    <React.Fragment>
                        <h3>{t(k.NO_IMPROVEMENTS_FOUND)}</h3>
                        <p>
                            {t(
                                k.REPORT_NEW_IMPROVEMENT_OR_TRY_DIFFERENT_SEARCH_KEYWORDS,
                            )}
                            .
                        </p>
                    </React.Fragment>
                }
            >
                <TableHeader>
                    <TableRow>
                        <TableCell name={DeviationSortBy.Message} width="xl">
                            {t(k.MESSAGE)}
                        </TableCell>

                        <TableCell
                            name={DeviationSortBy.Form}
                            onClick={handleSort}
                            width="md"
                            sortingBy={currentFilter.sortBy}
                            sortingIsAsc={currentFilter.sortAsc}
                            filterMenu={
                                showTypeFilter.includes(
                                    DeviationSortBy.Form,
                                ) && (
                                    <TableImprovementFormFilter
                                        open={
                                            currentFilterOpen ===
                                            DeviationSortBy.Form
                                        }
                                        isLoading={isLoading}
                                        selectedIds={
                                            currentFilter?.improvementForms
                                        }
                                        onShow={handleOnShow(
                                            DeviationSortBy.Form,
                                        )}
                                        onChange={handleOnChangeFormFilter}
                                    />
                                )
                            }
                        >
                            {t(k.FORM)}
                        </TableCell>
                        <TableCell
                            name={DeviationSortBy.Impact}
                            onClick={handleSort}
                            width="sm"
                            sortingBy={currentFilter.sortBy}
                            sortingIsAsc={currentFilter.sortAsc}
                            zIndexOrderModifier={
                                currentFilterOpen === DeviationSortBy.Impact
                                    ? 100
                                    : undefined
                            }
                            filterMenu={
                                showTypeFilter.includes(
                                    DeviationSortBy.Impact,
                                ) && (
                                    <TableCell.Filtering
                                        isLoading={isLoading}
                                        menuOptions={impactOptions}
                                        onFilter={handleOnChangeImpactFilter}
                                        open={
                                            currentFilterOpen ===
                                            DeviationSortBy.Impact
                                        }
                                        onShow={handleOnShow(
                                            DeviationSortBy.Impact,
                                        )}
                                    />
                                )
                            }
                        >
                            {t(k.IMPACT)}
                        </TableCell>

                        <TableCell
                            name={DeviationSortBy.Status}
                            onClick={handleSort}
                            width="sm"
                            sortingBy={currentFilter.sortBy}
                            sortingIsAsc={currentFilter.sortAsc}
                            zIndexOrderModifier={
                                currentFilterOpen === DeviationSortBy.Status
                                    ? 100
                                    : undefined
                            }
                            filterMenu={
                                showTypeFilter.includes(
                                    DeviationSortBy.Status,
                                ) && (
                                    <TableImprovementStatusFilter
                                        currentStatus={currentFilter?.status}
                                        currentClosureReasons={
                                            currentFilter?.closureReasons
                                        }
                                        isOpen={
                                            currentFilterOpen ===
                                            DeviationSortBy.Status
                                        }
                                        onShow={handleOnShow(
                                            DeviationSortBy.Status,
                                        )}
                                        onChange={handleOnChangeStatusFilter}
                                    />
                                )
                            }
                        >
                            {t(k.STATUS)}
                        </TableCell>

                        {showImpactedProcesses && (
                            <TableCell
                                name={DeviationSortBy.Responsible}
                                width="md"
                                filterMenu={
                                    showTypeFilter.includes(
                                        DeviationSortBy.Responsible,
                                    ) && (
                                        <TableImprovementResponsibleFilter
                                            open={
                                                currentFilterOpen ===
                                                DeviationSortBy.Responsible
                                            }
                                            isLoading={isLoading}
                                            selectedIds={
                                                currentFilter?.responsibleUserIds
                                            }
                                            onShow={handleOnShow(
                                                DeviationSortBy.Responsible,
                                            )}
                                            onChange={
                                                handleOnChangeResponsibleFilter
                                            }
                                        />
                                    )
                                }
                            >
                                {t(k.RESPONSIBLE)}
                            </TableCell>
                        )}

                        {showImpactedProcesses && (
                            <TableCell
                                name={DeviationSortBy.Involved}
                                width="md"
                                filterMenu={
                                    showTypeFilter.includes(
                                        DeviationSortBy.Involved,
                                    ) && (
                                        <TableImprovementInvolvedFilter
                                            open={
                                                currentFilterOpen ===
                                                DeviationSortBy.Involved
                                            }
                                            isLoading={isLoading}
                                            selectedIds={
                                                currentFilter?.involvedUserIds
                                            }
                                            onShow={handleOnShow(
                                                DeviationSortBy.Involved,
                                            )}
                                            onChange={
                                                handleOnChangeInvolvedFilter
                                            }
                                        />
                                    )
                                }
                            >
                                {t(k.INVOLVED)}
                            </TableCell>
                        )}

                        {showImpactedProcesses && (
                            <TableCell width="md" name="DiscoveredIn">
                                {t(k.DISCOVERED_IN)}
                            </TableCell>
                        )}

                        {showImpactedProcesses && (
                            <TableCell width="md" name="OriginatedIn">
                                {t(k.ORIGINATED_IN)}
                            </TableCell>
                        )}

                        <TableCell
                            name={DeviationSortBy.Created}
                            onClick={handleSort}
                            width="sm"
                            sortingBy={currentFilter.sortBy}
                            sortingIsAsc={currentFilter.sortAsc}
                        >
                            {t(k.CREATED)}
                        </TableCell>

                        <TableCell
                            name={DeviationSortBy.Updated}
                            onClick={handleSort}
                            width="sm"
                            sortingBy={currentFilter.sortBy}
                            sortingIsAsc={currentFilter.sortAsc}
                        >
                            {t(k.UPDATED)}
                        </TableCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {items.ids.map((itemId) => {
                        const item = items.values[itemId];

                        if (item) {
                            return (
                                <ImprovementTableRow
                                    key={itemId}
                                    value={item}
                                    processChartItemNames={
                                        processChartItemNames
                                    }
                                    showImpactedProcesses={
                                        showImpactedProcesses
                                    }
                                    onSelectAction={handleOnView}
                                    data-impact={items.values[itemId]?.impact}
                                    publishedUsers={publishedUsers}
                                />
                            );
                        }
                    })}
                </TableBody>
            </Table>
        </>
    );
};

const mapToProps = (appState: IAppState): IStateProps => {
    const showImpactedProcesses =
        appState.authViewState.profile?.appFeatures?.processChart &&
        appState.authViewState.profile?.appFeatures
            ?.assignImpactedProcessesToImprovement;

    return {
        showImpactedProcesses: showImpactedProcesses ?? false,
    };
};

export default connect(mapToProps)(ImprovementsTable);
