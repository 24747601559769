import { useRef, useCallback, useEffect } from "react";

export const defaultTouchHoldDelay = 1000;

export type ReactMouseEventOrTouchEvent =
    | React.MouseEvent<HTMLElement>
    | React.TouchEvent<HTMLElement>;

type MouseOrTouchEvent = MouseEvent | TouchEvent;
export interface IPressHoldProps {
    delay?: number;
    cancelDistance?: number;
    onPressStart?: (e: ReactMouseEventOrTouchEvent) => void;
    onLongPress?: (e: ReactMouseEventOrTouchEvent) => void;
    onPressEnd?: (
        e: ReactMouseEventOrTouchEvent,
        wasLongPress?: boolean,
    ) => void;
    onCanceled?: () => void;
}

const usePressHold = ({
    delay = defaultTouchHoldDelay,
    cancelDistance = 5,
    onPressStart,
    onLongPress,
    onPressEnd,
    onCanceled,
}: IPressHoldProps) => {
    const isLongPress = useRef(false);
    const longPressTimeoutIdRef = useRef<number | null>(null);
    const startPos = useRef<{ x: number; y: number } | null>(null);

    const cancelLongPress = useCallback(() => {
        if (longPressTimeoutIdRef.current) {
            clearTimeout(longPressTimeoutIdRef.current);
            longPressTimeoutIdRef.current = null;
        }

        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("mousemove", handleMove);

        isLongPress.current = false;
    }, []);

    const handleMove = useCallback(
        (event: MouseOrTouchEvent) => {
            if (!startPos.current) return;

            const isTouch = "touches" in event;

            let touch: { x: number; y: number } = { x: 0, y: 0 };

            if (isTouch) {
                touch = {
                    x: event.touches[0].clientX,
                    y: event.touches[0].clientY,
                };
            } else {
                touch = { x: event.clientX, y: event.clientY };
            }

            const moveDistance = Math.sqrt(
                Math.pow(touch.x - startPos.current.x, 2) +
                    Math.pow(touch.y - startPos.current.y, 2),
            );

            if (moveDistance > cancelDistance) {
                cancelLongPress();
                onCanceled?.();
            }
        },
        [cancelLongPress],
    );

    const handlePressStart = useCallback(
        (e: ReactMouseEventOrTouchEvent) => {
            e.preventDefault();

            const isTouch = "touches" in e;

            if (isTouch) {
                const touch = e.touches[0];
                startPos.current = { x: touch.clientX, y: touch.clientY };
            } else {
                startPos.current = { x: e.clientX, y: e.clientY };
            }

            onPressStart?.(e);

            window.addEventListener(
                isTouch ? "touchmove" : "mousemove",
                handleMove,
            );

            longPressTimeoutIdRef.current = window.setTimeout(() => {
                window.removeEventListener(
                    isTouch ? "touchmove" : "mousemove",
                    handleMove,
                );

                onLongPress?.(e);

                isLongPress.current = true;
            }, delay);
        },
        [onPressStart, onLongPress, delay, handleMove],
    );

    const handlePressEnd = useCallback(
        (e: ReactMouseEventOrTouchEvent) => {
            onPressEnd?.(e, isLongPress.current);

            cancelLongPress();
        },
        [onPressEnd, cancelLongPress],
    );

    useEffect(() => {
        return () => {
            cancelLongPress();
        };
    }, [cancelLongPress]);

    return {
        handlePressStart,
        handlePressEnd,
    };
};

export default usePressHold;
