import "./Select.scss";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";

import { orderMultiselectOptions } from "./utils";

import { IValueParamItem } from "common/IValueParamItem";

import { IMultiselectOption } from "common/IMultiselectOption";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

interface IProps extends IInputWrapperProps {
    id: string;
    selectedUsers?: IValueParamItem<string, boolean>[] | string[];

    filterIds?: string[];

    placeholder?: string;
    multi?: boolean;

    testId?: string;
    wrapperInputTestId?: string;

    onChange: (roleIds: string[]) => void;
}

const SelectUsers: React.FC<IProps> = (props) => {
    const {
        id,
        htmlFor,
        selectedUsers,
        placeholder,
        multi,
        className,
        wrapperIcon: icon,
        wrapperLabel: label,
        testId,
        wrapperInputTestId: inputTestId,
        boldLabel,
        filterIds,
    } = props;

    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const { data: publishedUsers } = usePublishedUserOptions();

    const selectedOptions = useMemo(() => {
        if (publishedUsers && selectedUsers) {
            if (typeof selectedUsers[0] === "string") {
                const asString = selectedUsers as string[];
                const result = asString.reduce<IMultiselectOption[]>(
                    (acc, x) => {
                        const value = publishedUsers.values[x];
                        if (value) {
                            acc.push(value);
                        }

                        return acc;
                    },
                    [],
                );

                return result;
            } else {
                const asValueParam = selectedUsers as IValueParamItem<
                    string,
                    boolean
                >[];
                const result = asValueParam.reduce<IMultiselectOption[]>(
                    (acc, x) => {
                        const value = publishedUsers.values[x.value];

                        if (value) {
                            acc.push({
                                ...value,
                                isFixed: x.param,
                            });
                        }
                        return acc;
                    },
                    [],
                );

                return orderMultiselectOptions(result);
            }
        }
    }, [selectedUsers, publishedUsers, localeId]);

    const handleOnChange = (options: any) => {
        let newValues = [] as string[];

        if (options) {
            if (multi) {
                const selectedValues = options as IMultiselectOption[];

                newValues = selectedValues.map((x) => {
                    return x.value;
                });
            } else {
                const selectedValues = options as IMultiselectOption;

                newValues = [selectedValues.value];
            }
        }

        props.onChange(newValues);
    };

    const filterOptions = (
        option: FilterOptionOption<any>,
        rawInput: string,
    ) => {
        if (filterIds && filterIds.includes(option.value)) {
            return false;
        }

        if (rawInput) {
            const splitRaw = rawInput.toLowerCase().split(" ");

            if (
                splitRaw.every((x) =>
                    option.label.toLowerCase().includes(x),
                ) === false
            ) {
                return false;
            }
        }

        return true;
    };

    return (
        <InputWrapper
            htmlFor={htmlFor ?? id}
            className={`select-tusers${className ? " " + className : ""}`}
            wrapperIcon={icon}
            wrapperLabel={label ?? t(k.USERS)}
            boldLabel={boldLabel}
            wrapperInputTestId={inputTestId}
        >
            <SelectDropdown
                inputId={htmlFor ?? id}
                value={selectedOptions}
                isMulti={multi}
                isSearchable
                isClearable
                placeholder={placeholder ?? t(k.SELECT)}
                options={publishedUsers?.options}
                testId={testId}
                closeMenuOnSelect={multi !== true}
                filterOption={filterIds ? filterOptions : undefined}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};

export default SelectUsers;
