import "./SidePanelDetails.scss";

import { FC, useContext, useRef } from "react";
import ImprovementImpactBadge from "common/components/badges/improvement-impact-badge/ImprovementImpactBadge";
import NewImprovementContext from "../../../newImprovementContext";
import { Title } from "./components";
import ListedContent from "common/components/listed-content/ListedContent";
import ImpactBar from "components/improvements/components/ImprovementListedContent/components/SidePanel/SidePanelDetails/components/ImpactBar/ImpactBar";
import ResponsibleFor from "./components/ResponsibleFor/ResponsibleFor";
import InvolvedUsers from "./components/InvolvedUsers/InvolvedUsers";

interface Props {}

const SidePanelDetails: FC<Props> = () => {
    const {
        editedImprovement,
        improvementForm,
        mobileView,
        sidePanelRef,
        detailsRef,
        listedContentRef,
    } = useContext(NewImprovementContext);

    const stickyEndRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <ListedContent.SidePanel.Details
                context={NewImprovementContext}
                footRef={stickyEndRef}
                description={improvementForm?.description}
            >
                {editedImprovement && detailsRef && (
                    <>
                        <ImpactBar
                            impact={editedImprovement.impact}
                            startRef={detailsRef}
                            endRef={stickyEndRef}
                            scrollContainerRef={
                                mobileView ? listedContentRef : sidePanelRef
                            }
                        />
                        <ImprovementImpactBadge
                            impact={editedImprovement.impact}
                        />
                    </>
                )}

                <Title />
                <div className="details__content__sub-details">
                    <ResponsibleFor />
                    <InvolvedUsers />
                </div>
            </ListedContent.SidePanel.Details>
        </>
    );
};

export default SidePanelDetails;
