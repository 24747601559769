import IValidationMessages from "common/viewModels/IValidationMessages";
import { FormulaItemValidationErrorType } from "../api/FormulaItemValidationErrorType";

const detailsErrors = ["kpiName"];

const formulaErrors = [
    ...Object.values(FormulaItemValidationErrorType),
] as string[];

const setTargetErrors = ["DECIMAL_PLACES_INVALID_NUMBER"];

const setResponsible = [] as string[];

export const tabErrors = {
    detailsTab: { id: "1", errors: detailsErrors },
    formulaTab: { id: "2", errors: formulaErrors },
    setTargetTab: { id: "3", errors: setTargetErrors },
    setResponsibleTab: { id: "4", errors: setResponsible },
} as const;

export const getTabsErrors = (
    validationMessages: IValidationMessages,
): Record<string, IValidationMessages> => {
    const { _error, ...rest } = validationMessages;

    return Object.keys(rest).reduce(
        (convertedErrors, errorKey) => {
            const tabError = Object.values(tabErrors).find((tabError) =>
                tabError.errors.includes(errorKey),
            );

            if (tabError) {
                const newError = { [errorKey]: validationMessages[errorKey] };
                convertedErrors[tabError.id] = {
                    ...(convertedErrors[tabError.id] || {}),
                    ...newError,
                };
            }

            return convertedErrors;
        },
        {} as Record<string, IValidationMessages>,
    );
};
