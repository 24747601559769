import "./TeamRoleBadges.scss";
import React, { useMemo } from "react";

import RoleBadge from "components/common/badges/RoleBadge";
import { TRoles } from "components/roles/api/Roles";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ITableSet from "http/ITableSet";
import { IValueLabelItem } from "common/IValueLabelItem";

import { usePublishedRoles } from "components/roles/api/hooks";
import { usePublishedTeamOptions } from "components/teams/api/hooks";
import { ERoleSource } from "components/roles/api/RoleSource";
import Wrapper from "common/components/wrapper/Wrapper";

interface IProps {
    roles?: ITableSet<string[]>;
    wrapper?: boolean;
}

const TeamRoleBadges = ({ roles, wrapper = true }: IProps) => {
    const { t } = useTranslation();

    const { data: publishedRoles } = usePublishedRoles();

    const { data: publishedTeams } = usePublishedTeamOptions();

    const labeledRoles:
        | IValueLabelItem<string, string, string[] | undefined>[]
        | undefined = useMemo(() => {
        if (roles && publishedRoles) {
            const values = roles.ids.map((x) => {
                return {
                    value: x,
                    label: publishedRoles.values[x] ?? "",
                    param: roles.values[x],
                };
            });

            return values;
        }
    }, [roles, publishedRoles]);

    return (
        <Wrapper enabled={wrapper} className="team-role-badges">
            {labeledRoles?.map((item) => (
                <RoleBadge
                    key={item.value}
                    role={item.label as TRoles}
                    type={ERoleSource.Team}
                    info={
                        item.param ? (
                            <ul className="team-role-badges--tooltip">
                                <li>{t(k.ROLE_FROM_TEAM) + ":"}</li>
                                {item.param.map((x, i) => (
                                    <li key={i}>{publishedTeams?.values[x]}</li>
                                ))}
                            </ul>
                        ) : undefined
                    }
                />
            ))}
        </Wrapper>
    );
};

export default TeamRoleBadges;
