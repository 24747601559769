import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const ArchiveTemplateMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <p>{t(k.ARE_YOU_SURE_ARCHIVE_TEMPLATE)}</p>
            <div>
                <strong>{t(k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_CONTENT)}</strong>
                <br />
                <br />
                {t(k.ARE_YOU_SURE_ARCHIVE_TEMPLATE_INFO)}
            </div>
        </React.Fragment>
    );
};

export default ArchiveTemplateMessage;
