import "./UpdateAvailableIcon.scss";

import React from "react";
import { FiArrowUpCircle } from "react-icons/fi";

const UpdateAvailableIcon: React.FC = (props) => {
    return <FiArrowUpCircle className="icon-update-available" />;
};

export default UpdateAvailableIcon;
