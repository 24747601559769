import "./KpiProgressBar.scss";
import { ProgressBar as Bar } from "react-bootstrap";

interface IProps {
    targetValue?: number | null;
    calculatedValue?: number | null;
    overThreshold?: number;
    underThreshold?: number;
    overIsGood?: boolean;
    decimalPlaces?: number;
}

const KpiProgressBar = (props: IProps) => {
    const {
        targetValue,
        calculatedValue,
        overThreshold,
        underThreshold,
        overIsGood,
        decimalPlaces,
    } = props;

    const getBg = () => {
        if (
            calculatedValue !== undefined &&
            calculatedValue !== null &&
            targetValue !== undefined &&
            targetValue !== null &&
            overThreshold &&
            underThreshold
        ) {
            const overValue = (targetValue * overThreshold) / 100;
            const underValue = (targetValue * underThreshold) / 100;

            if (calculatedValue === 0 && targetValue === 0) {
                return "bg-green";
            }

            if (overThreshold === underThreshold) {
                if (overIsGood) {
                    return calculatedValue >= overValue ? "bg-green" : "bg-red";
                } else {
                    return calculatedValue > overValue ? "bg-red" : "bg-green";
                }
            }

            if (calculatedValue >= overValue) {
                return overIsGood ? "bg-green" : "bg-red";
            } else if (calculatedValue <= underValue) {
                return overIsGood ? "bg-red" : "bg-green";
            } else {
                return "bg-yellow";
            }
        }
    };

    const getNow = () => {
        if (
            targetValue === undefined ||
            targetValue === null ||
            calculatedValue === undefined ||
            calculatedValue === null
        ) {
            return 0;
        } else if (targetValue === 0) {
            return 100;
        } else {
            const now = (calculatedValue / targetValue) * 100;
            return now <= 0 ? 100 : now;
        }
    };

    const roundedCalculatedValue =
        calculatedValue !== null && calculatedValue !== undefined
            ? parseFloat(calculatedValue.toFixed(decimalPlaces ?? 0))
            : "-";

    const roundedTargetValue =
        targetValue !== null && targetValue !== undefined
            ? parseFloat(targetValue.toFixed(decimalPlaces ?? 0))
            : "-";

    return (
        <Bar
            now={getNow()}
            max={100}
            label={
                <div className="progress-bar--label">
                    {
                        <>
                            <div className="progress-bar--label--now">
                                {roundedCalculatedValue}
                            </div>
                            &nbsp;/ {roundedTargetValue}
                        </>
                    }
                </div>
            }
            className={`kpi-progress-bar ${getBg()}`}
        />
    );
};

export default KpiProgressBar;
