import "./SortableList.scss";

import React from "react";
import { FaBars } from "react-icons/fa";
import {
    SortableContainer,
    SortableElement,
    SortableElementProps,
    SortableHandle,
} from "react-sortable-hoc";

import PropertyTemplate, {
    IPropertyTemplateProps,
} from "components/common/custom-list-property-template/PropertyTemplate";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import IValidationMessages from "common/viewModels/IValidationMessages";

export const SortableTemplateList = SortableContainer<{
    children: React.ReactNode;
}>((props: { children: React.ReactNode }) => <div>{props.children}</div>);

interface ISortableTemplateListItemProps
    extends SortableElementProps,
        IPropertyTemplateProps {
    isAtLeastOnePropertyShouldBeVisibleInvalid: boolean;

    errors: IValidationMessages;
}

export const SortableTemplateListItem =
    SortableElement<ISortableTemplateListItemProps>(
        (props: ISortableTemplateListItemProps) => {
            const {
                isDisabled,
                isBlur,
                orderNumber,
                value,
                errors,
                hideMutualProperties,
                isCustomListProperty,
            } = props;

            const handleDelete = () => {
                if (isDisabled == false && value) {
                    props.onDelete(value.id);
                }
            };

            const handleChange = (e: {
                isInitialization?: boolean;
                value: ICustomListPropertyTemplateValue;
            }) => {
                if (isDisabled) {
                    return;
                }

                props.onChange(e);
            };

            return (
                <div
                    className={
                        isBlur
                            ? "sortable-template-list-item sortable-template-list-item__blur"
                            : "sortable-template-list-item"
                    }
                >
                    <div className="sortable-template-list-item__drag-handle">
                        <DragHandle />
                    </div>

                    <PropertyTemplate
                        isCustomListProperty={isCustomListProperty}
                        orderNumber={orderNumber}
                        value={value}
                        isDisabled={isDisabled}
                        errors={errors}
                        hideMutualProperties={hideMutualProperties}
                        isAtLeastOnePropertyShouldBeVisibleInvalid={
                            props.isAtLeastOnePropertyShouldBeVisibleInvalid
                        }
                        onChange={handleChange}
                        onDelete={handleDelete}
                    />
                </div>
            );
        },
    );

const DragHandle = SortableHandle(() => <FaBars />);
