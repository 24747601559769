import "./ButtonGroup.scss";

import React, { useMemo } from "react";

interface IProps {
    id?: string;
    spaceBetween?: boolean;

    className?: string;
}

const ButtonGroup: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const className = useMemo(() => {
        const result = ["btn-group"];

        if (props.spaceBetween) {
            result.push("btn-group--space-between");
        }

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [props.className, props.spaceBetween]);

    return (
        <div id={props.id} className={className}>
            {props.children}
        </div>
    );
};

export default ButtonGroup;
