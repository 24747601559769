import "./DeleteUndoButton.scss";

import React from "react";

import { DeleteIcon, UndoIcon } from "common/components/icons";
import IconButton from "../icon-button/IconButton";

interface IProps {
    testId?: string;
    isDeleted?: boolean;
    disabled?: boolean;

    background?: "dark";

    onDelete: () => void;
}

const DeleteUndoButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { testId, disabled, isDeleted, background } = props;
    const handleOnClick = (e: any) => {
        e.preventDefault();
        props.onDelete();
    };

    return (
        <IconButton
            onClick={handleOnClick}
            background={background}
            testId={testId}
            disabled={disabled}
        >
            {isDeleted ? <UndoIcon /> : <DeleteIcon />}
        </IconButton>
    );
};

export default DeleteUndoButton;
