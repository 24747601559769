import {
    allChecklistStatuses,
    ChecklistDynamicStatus,
} from "models/enums/ChecklistDynamicStatus";

export function getInitialSelectedStatuses() {
    const excludedValues = [
        ChecklistDynamicStatus.None,
        ChecklistDynamicStatus.Archived,
        ChecklistDynamicStatus.Scheduled,
    ];

    const result = allChecklistStatuses.filter(
        (x) => excludedValues.includes(x.id) === false,
    );

    return result.map((x) => x.id);
}
