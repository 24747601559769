import React from "react";

import { convertToDate } from "common/utils/time";

import "moment-duration-format";
import momentDurationFormatSetup from "moment-duration-format";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import moment from "moment";

interface IProps {
    startDate?: Date;
    endDate?: Date;
}

const FormattedDateRelative: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { t } = useTranslation();

    momentDurationFormatSetup(moment as any);

    if (props.startDate) {
        const start = moment(
            props.startDate instanceof Date
                ? props.startDate
                : convertToDate(props.startDate),
        );
        const end = props.endDate
            ? moment(
                  props.endDate instanceof Date
                      ? props.endDate
                      : convertToDate(props.endDate),
              )
            : moment(new Date(Date.now()));

        const duration = moment.duration(end.diff(start));

        return (
            <React.Fragment>
                {duration.format(
                    `Y [${t(k.YEAR_S_)}] M [${t(k.MONTH_S_)}]${
                        duration.asYears() >= 1 ? "" : ` D [${t(k.DAY_S_)}]`
                    } `,
                )}
            </React.Fragment>
        );
    }

    return null;
};

export default FormattedDateRelative;
