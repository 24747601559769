import "./Badge.scss";

import React, { useMemo } from "react";
import { VariantType } from "common/VariantType";

interface IProps {
    className?: string;

    variant?: VariantType;

    transparent?: boolean;

    border?: boolean;

    pill?: boolean;

    multiline?: boolean;

    clickable?: boolean;

    noMinWidth?: boolean;

    textEllipsis?: boolean;

    title?: string;

    noMargin?: boolean;

    testId?: string;

    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;

    leftSideContent?: React.ReactNode;
    rightSideContent?: React.ReactNode;
}

const baseBadgeClassName = "app-badge";

const Badge: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        pill,
        variant,
        multiline,
        noMinWidth,
        noMargin,
        transparent,
        border,
        testId,
        leftSideContent,
        rightSideContent,
        textEllipsis,
        title,
    } = props;

    const clickable = props.clickable ?? props.onClick !== undefined;

    const className = useMemo(() => {
        const result: string[] = [baseBadgeClassName];

        if (pill) {
            result.push(`${baseBadgeClassName}--pill`);
        }

        if (multiline) {
            result.push(`${baseBadgeClassName}--multiline`);
        }

        if (variant) {
            result.push(`${baseBadgeClassName}--${variant}`);
        }

        if (transparent) {
            result.push("transparent");
        }

        if (border) {
            result.push("border");
        }

        if (noMinWidth) {
            result.push(`${baseBadgeClassName}--no-min-width`);
        }

        if (noMargin) {
            result.push(`${baseBadgeClassName}--no-margin`);
        }

        if (clickable) {
            result.push("clickable-badge");
        }

        if (textEllipsis) {
            result.push("text-ellipsis");
        }

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [
        props.className,
        clickable,
        pill,
        variant,
        multiline,
        noMinWidth,
        noMargin,
        transparent,
        border,
        textEllipsis,
    ]);

    return (
        <div
            onClick={props.onClick}
            className={className}
            data-testid={testId}
            title={title}
        >
            {leftSideContent}
            {props.children}
            {rightSideContent}
        </div>
    );
};

export default Badge;
