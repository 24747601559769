import React, { useMemo } from "react";
import { useTableContext } from "./Context";

interface IProps {
    columnsCount: number;
}

const loadingRowsArray = Array.from(Array(10).keys());

const TableEmptyBodyComponent: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { columnsCount } = props;

    const context = useTableContext();

    const loadingCellsArray = useMemo(() => {
        if (columnsCount) {
            return Array.from(Array(columnsCount).keys());
        }
    }, [columnsCount]);

    if (loadingCellsArray === undefined) {
        return null;
    }

    return (
        <tbody>
            {loadingRowsArray.map((k) => (
                <tr key={k}>
                    {loadingCellsArray.map((ck, i) => {
                        const colSpan =
                            i === 0 && context?.canExpandRow ? 2 : undefined;

                        return <td key={ck} colSpan={colSpan} />;
                    })}
                </tr>
            ))}
        </tbody>
    );
};

export default TableEmptyBodyComponent;
