import "./ImpactBar.scss";

import { RefObject, useEffect, useState } from "react";

import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";

const PADDING = 6;

interface IProps {
    impact: ImpactGradingEnum;
    startRef: RefObject<HTMLDivElement>;
    endRef: RefObject<HTMLDivElement>;
    scrollContainerRef?: RefObject<HTMLDivElement>;
}

const ImpactBar = ({
    impact,
    startRef,
    endRef,
    scrollContainerRef,
}: IProps) => {
    const [barStyle, setBarStyle] = useState<React.CSSProperties>({});

    useEffect(() => {
        const updateBarPosition = () => {
            const startElement = startRef.current;
            const endElement = endRef.current;

            if (startElement && endElement) {
                const startRect = startElement.getBoundingClientRect();
                const endRect = endElement.getBoundingClientRect();

                const topPosition = PADDING;
                const bottomPosition = endRect.top - startRect.top;
                const height = bottomPosition - topPosition;

                setBarStyle({
                    top: `${topPosition}px`,
                    height: `${height}px`,
                });
            }
        };

        const scrollContainer = scrollContainerRef?.current;
        const startElement = startRef.current;
        const endElement = endRef.current;

        let startObserver: ResizeObserver;

        if (startElement && endElement && scrollContainer) {
            scrollContainer.addEventListener("scroll", updateBarPosition);
            window.addEventListener("resize", updateBarPosition);

            startObserver = new ResizeObserver(updateBarPosition);
            startObserver.observe(startElement);

            updateBarPosition();
        }
        return () => {
            scrollContainer?.removeEventListener("scroll", updateBarPosition);
            window.removeEventListener("resize", updateBarPosition);

            startObserver.disconnect();
        };
    }, [startRef, endRef, scrollContainerRef]);

    return <div className="impact-bar" style={barStyle} data-impact={impact} />;
};

export default ImpactBar;
