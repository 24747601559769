import "./IconBg.scss";

import React from "react";

const IconBg: React.FC<React.PropsWithChildren<{ show?: boolean }>> = (
    props,
) => {
    if (props.show) {
        return <span className="icon-bg">{props.children}</span>;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default IconBg;
