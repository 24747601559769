import { FC, useContext, useEffect, useState } from "react";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { useGetResponsibleUsersForTemplateByTemplateVersionIdMutation } from "components/improvements/api/hooks";
import ITemplateVersionConfigurationUsersRequest from "components/improvements/api/ITemplateVersionConfigurationUsersRequest";
import { IValueLabelItem } from "common/IValueLabelItem";
import ITemplateVersionConfigurationUsersDto from "components/improvements/api/ITemplateVersionConfigurationUsersDto";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import { mergeStringArraysAndDistinct } from "components/improvements/api/utils";
import SelectedUsersWithInfo from "common/components/selected-users-with-info/SelectedUsersWithInfo";
import ViewTemplateConfigurationUsersTooltipMessage from "common/components/messages/ViewTemplateConfigurationUsersTooltipMessage";

interface IProps {}

const ResponsibleFor: FC<IProps> = (props) => {
    const { editedImprovement, improvement } = useContext(
        ImprovementsDetailsContext,
    );

    const { data: publishedUsers } = usePublishedUserOptions();

    const { t } = useTranslation();

    const responsibleUsersMutation =
        useGetResponsibleUsersForTemplateByTemplateVersionIdMutation();

    const [users, setUsers] =
        useState<Array<IValueLabelItem<string, string>>>();

    const [info, setInfo] = useState<ITemplateVersionConfigurationUsersDto>();

    useEffect(() => {
        const fetchData = async () => {
            if (editedImprovement?.formId) {
                let request: ITemplateVersionConfigurationUsersRequest = {
                    templateId: editedImprovement.formId,
                    improvementId: improvement?.id,
                    impactedProcesses: editedImprovement?.impactedProcesses,
                };

                let data = await responsibleUsersMutation.mutateAsync({
                    request,
                });

                const allUsersArray = mergeStringArraysAndDistinct(
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.discoveredInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.originatedInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    [data.Data?.managerOfReportingUser ?? ""],
                    data.Data?.admins ?? [],
                    data.Data?.deviationManagers ?? [],
                );

                const selectedUsers = publishedUsers?.options?.filter((user) =>
                    allUsersArray.includes(user.value),
                );

                setUsers(selectedUsers);
                setInfo(data.Data);
                return data;
            }
        };

        fetchData();
    }, [editedImprovement?.impactedProcesses, publishedUsers]);

    let isLoading = responsibleUsersMutation.isLoading;

    return (
        <div>
            <SelectedUsersWithInfo
                users={users}
                labelPrefixTranslationKey={k.RESPONSIBLE}
                tooltipMessage={
                    <ViewTemplateConfigurationUsersTooltipMessage
                        allSelectedUsers={users}
                        titleTranslationKey={k.RESPONSIBLE_USERS}
                    />
                }
                isLoading={isLoading}
            />
        </div>
    );
};

export default ResponsibleFor;
