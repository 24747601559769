import React, { useMemo } from "react";
import { connect } from "react-redux";

import { IAppState } from "../../store/IAppState";
import Page404 from "./Page404";
import GoToLoginPage from "./GoToLoginPage";
import AnimatedSpinner from "../../components/common/spinners/AnimatedSpinner";
import { usePageTitle } from "routes/hooks";
import { TRoles } from "components/roles/api/Roles";

interface IOwnProps {
    component: any;
    title: string;
    roles?: TRoles[];
    allowAccessCheckForId?: boolean;
}

interface IStateProps {
    hasCheckedLocalToken: boolean;
    isAuthenticated: boolean;
    profileRoles: TRoles[];
}

type Props = IOwnProps & IStateProps;

const PrivateContent: React.FC<Props> = (props) => {
    const {
        component: Component,
        title,
        roles,
        allowAccessCheckForId,

        hasCheckedLocalToken,
        isAuthenticated,
        profileRoles,
    } = props;

    usePageTitle(title);

    const notAllowed = useMemo(() => {
        return (
            isAuthenticated &&
            roles &&
            profileRoles.some((roleName) => roles.includes(roleName)) ===
                false &&
            !allowAccessCheckForId
        );
    }, [isAuthenticated, roles, profileRoles, allowAccessCheckForId]);

    if (notAllowed) {
        return <Page404 />;
    }

    if (!hasCheckedLocalToken) {
        return <AnimatedSpinner isVisible={true} position="center" />;
    }

    return isAuthenticated ? <Component /> : <GoToLoginPage />;
};

const mapToState = (state: IAppState): IStateProps => {
    let profileRoles: TRoles[] = [];

    if (state.authViewState.profile && state.authViewState.profile.roles) {
        profileRoles = state.authViewState.profile.roles.map((x) => x.label);
    }

    return {
        hasCheckedLocalToken: state.authViewState.hasCheckedLocalToken,
        isAuthenticated: state.authViewState.isAuthenticated,
        profileRoles,
    };
};

export default connect(mapToState)(PrivateContent);
