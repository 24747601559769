import "./RecurringChecklistTimelinePreview.scss";

import React, { useContext, useEffect, useMemo } from "react";

import moment from "moment";

import {
    default as TimelineComponent,
    ReactCalendarGroupRendererProps,
    ReactCalendarItemRendererProps,
    TimelineGroup,
    TimelineItem,
    TimelineMarkers,
    TodayMarker,
} from "react-calendar-timeline";

import {
    buildExistingTimelineItems,
    useRecurringChecklistGroupsByTemplateVersionId,
    useRecurringChecklistItemsByTemplateVersionId,
} from "../api/hooks";
import { WorkflowScheduleInterval } from "../api/WorkflowScheduleIntervals";

import { useLocation } from "react-router-dom";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";

import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface ICustomGroup {
    name: string;
    draft?: boolean;
    selected?: boolean;
}

interface IProps {
    type: WorkflowScheduleInterval;

    selectedRecurringChecklistId?: string;
    templateVersionId: string | null;

    startDate?: Date | moment.Moment;

    firstGroup?: TimelineGroup<ICustomGroup>;
    firstGroupItems?: TimelineItem<{ tempItemMode?: "added" | "removed" }>[];

    onChangeRecurrence: (id: string) => void;
}

const todayDate = new Date(Date.now());

const RecurringChecklistTimelinePreview: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const {
        selectedRecurringChecklistId: selectedId,
        type,
        templateVersionId,
        startDate,
        firstGroup,
        firstGroupItems,
    } = props;

    const location = useLocation();

    const modalManagerContext = useContext(ModalManagerContext);

    const { data: recurrenceGroups = [] } =
        useRecurringChecklistGroupsByTemplateVersionId(
            templateVersionId ?? undefined,
        );

    const { data: recurrenceItems = {} } =
        useRecurringChecklistItemsByTemplateVersionId(
            templateVersionId ?? undefined,
        );

    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, []);

    const groups = useMemo(() => {
        const result = recurrenceGroups.reduce<TimelineGroup<ICustomGroup>[]>(
            (map, item) => {
                const { id, name, isPublished } = item;

                if (firstGroup === undefined || firstGroup.id !== id) {
                    const value: TimelineGroup<ICustomGroup> = {
                        id,
                        draft: isPublished ? false : true,
                        selected: id === selectedId,
                        name,
                        title: name,
                    };

                    map.push(value);
                }

                return map;
            },
            [],
        );

        if (firstGroup) {
            return [firstGroup].concat(result);
        }

        return result.sort(
            (a, b) => Number(b.selected ?? false) - Number(a.selected ?? false),
        );
    }, [selectedId, firstGroup, recurrenceGroups]);

    const items = useMemo(() => {
        const result = recurrenceGroups.reduce<TimelineItem<{}>[]>(
            (map, item) => {
                const { id } = item;

                const list = recurrenceItems[id];

                if (
                    list &&
                    (firstGroupItems === undefined ||
                        firstGroupItems.length === 0 ||
                        (firstGroupItems.length > 0 &&
                            firstGroupItems[0].group !== id))
                ) {
                    map = map.concat(buildExistingTimelineItems(id, list));
                }

                return map;
            },
            [],
        );

        return (firstGroupItems ?? []).concat(result);
    }, [firstGroupItems, recurrenceGroups, recurrenceItems]);

    const [defaultTimeStart, defaultTimeEnd] = useMemo(() => {
        let timeStart: moment.Moment;
        let timeEnd: moment.Moment;

        switch (type) {
            case WorkflowScheduleInterval.Yearly:
                timeStart = startDate
                    ? moment(startDate).add(-1, "month")
                    : moment().add(-1, "month");

                timeEnd = startDate
                    ? moment(startDate).add(13, "month")
                    : moment().add(13, "month");

                break;

            case WorkflowScheduleInterval.Monthly:
                timeStart = startDate
                    ? moment(startDate).add(-1, "month")
                    : moment().add(-1, "month");

                timeEnd = startDate
                    ? moment(startDate).add(7, "month")
                    : moment().add(7, "month");

                break;

            default:
                timeStart = startDate
                    ? moment(startDate).add(-2, "day")
                    : moment().add(-2, "day");

                timeEnd = startDate
                    ? moment(startDate).add(12, "day")
                    : moment().add(12, "day");

                break;
        }

        return [timeStart.toDate(), timeEnd.toDate()];
    }, [type, startDate]);

    const handleItemClick = (
        e: React.MouseEvent<HTMLDivElement> & {
            target: { dataset: { id: string } };
        },
    ) => {
        const item = items.find((x) => x.id === e.target.dataset.id);
        if (item && item.tempItemMode !== "added") {
            const payload: IShowModalPayload = {
                id: item.id.toString(),
                type: ModalTypes.checklist,
                callerAddress: location.pathname,
                options: { showAll: true },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    const itemRenderer = (
        itemProps: ReactCalendarItemRendererProps<
            TimelineItem<{ tempItemMode?: "added" | "removed" }>
        >,
    ) => {
        const { item, itemContext, getItemProps } = itemProps;

        const className =
            item.tempItemMode === "added"
                ? "rct-item added"
                : item.tempItemMode === "removed"
                  ? "rct-item removed"
                  : "rct-item";

        return (
            <div {...getItemProps(item)} className={className}>
                <div
                    className="rct-item-content"
                    style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                    }}
                    data-id={item.id}
                    onClick={
                        item.tempItemMode === "added"
                            ? undefined
                            : handleItemClick
                    }
                >
                    {item.title}
                </div>
            </div>
        );
    };

    const handleGroupClick = (
        e: React.MouseEvent<HTMLDivElement> & {
            target: { dataset: { id: string } };
        },
    ) => {
        const group = groups.find((x) => x.id === e.target.dataset.id);

        if (group) {
            if (!group.selected) {
                props.onChangeRecurrence(group.id as string);
            }
        }
    };

    const groupRenderer = (
        groupProps: ReactCalendarGroupRendererProps<
            TimelineGroup<ICustomGroup>
        >,
    ) => {
        const {
            group: { title, draft, selected, id },
        } = groupProps;

        const customGroup = draft
            ? "custom-group draft"
            : selected
              ? "custom-group selected"
              : "custom-group";

        return (
            <div
                className={customGroup}
                title={title as string}
                data-id={id}
                onClick={handleGroupClick}
            >
                {title}
            </div>
        );
    };

    const horizontalLineClassNamesForGroup = (
        group: TimelineGroup<ICustomGroup>,
    ) => {
        const { draft, selected } = group;

        const classes: string[] = [];

        if (draft) {
            classes.push("draft");
        }

        if (selected) {
            classes.push("selected");
        }

        return classes;
    };

    const lineHeight = 39;
    const sidebarWidth = 175;

    return (
        <TimelineComponent
            key={items.length === 0 ? undefined : templateVersionId}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
            horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
            groups={groups}
            items={items}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            lineHeight={lineHeight}
            sidebarWidth={sidebarWidth}
            canChangeGroup={false}
            canMove={false}
            canResize={false}
        >
            <TimelineMarkers>
                {todayDate && <TodayMarker date={todayDate} />}
            </TimelineMarkers>
        </TimelineComponent>
    );
};

export default RecurringChecklistTimelinePreview;
