import React from "react";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import Textfield from "common/components/input-components/textfield/Textfield";
import { isUrlValid } from "common/components/validation/UrlValidation";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";

const CustomListItemHyperlink: React.FC<ISharedPropertyInputProps<string>> = (
    props,
) => {
    const { values, errors, disabled, setErrors } = props;

    const { parentId, isRequired, label } = props.property;

    const { t } = useTranslation();

    const updateErrors = (newError?: string) => {
        if (setErrors) {
            setErrors((prev) => ({
                ...prev,
                [parentId]: newError,
            }));
        }
    };

    const handleChange = (e: IInputActionChangeEvent<string>) => {
        const value = e.value ?? "";
        props.onChange(parentId, [value]);

        if (isUrlValid(value) || value.trim().length === 0) {
            updateErrors(undefined);
        } else {
            updateErrors(t(k.INVALID));
        }
    };

    return (
        <ActionTextField
            id={`form.${parentId}`}
            isLink
            editMode
            label={label}
            value={values && values.length > 0 ? values[0] : ""}
            invalid={Boolean(errors[parentId])}
            required={isRequired}
            onChange={handleChange}
            disabled={disabled}
            error={
                errors[parentId] && (
                    <Textfield.Error>{errors[parentId]}</Textfield.Error>
                )
            }
        />
    );
};

export default CustomListItemHyperlink;
