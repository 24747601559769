import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { onPageStateChange } from "store/page-navigation/actions";
import { IAppState } from "store/IAppState";

export const useHasPageChanges = () => {
    const dispatch = useDispatch();

    const isChanged = useSelector(
        (appState: IAppState) => appState.pageNavigationViewState.hasPageChange,
    );

    return useCallback(
        (hasChanges: boolean) => {
            if (isChanged !== hasChanges) {
                dispatch(onPageStateChange(hasChanges));
            }
        },
        [isChanged],
    );
};
