import "../RecurringChecklistForm.scss";

import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import { ERecurringChecklistMonthOfYear } from "../api/enum/ERecurringChecklistMonthOfYear";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";

interface IProps {
    monthsOfYear?: ERecurringChecklistMonthOfYear[];
    errors: IValidationMessages;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;
}

const RecurringChecklistMonthOfYear = (props: IProps) => {
    const { monthsOfYear = [], errors } = props;

    const { t } = useTranslation();

    const handleChange = (e: IInputChangeEvent<boolean>) => {
        if (e.id) {
            const month = parseInt(e.id) as ERecurringChecklistMonthOfYear;

            if (monthsOfYear.includes(month) && !e.value) {
                props.onChange(
                    monthsOfYear.filter((x) => x !== month),
                    "monthsOfYear",
                );
            } else if (e.value) {
                props.onChange(monthsOfYear.concat(month), "monthsOfYear");
            }
        }
    };

    return (
        <div className="recurring-checklist--body--month-year">
            <div>
                <span>{t(k.ON_THESE_MONTHS)}</span>
                <ValidationLabel
                    errors={errors.monthsOfYear && t(errors.monthsOfYear)}
                />
            </div>

            <div className="r-c--body--month-year--list">
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.January)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.January,
                    )}
                    label={t(k.JAN)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.February)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.February,
                    )}
                    label={t(k.FEB)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.March)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.March,
                    )}
                    label={t(k.MAR)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.April)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.April,
                    )}
                    label={t(k.APR)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.May)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.May,
                    )}
                    label={t(k.MAY)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.June)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.June,
                    )}
                    label={t(k.JUN)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.July)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.July,
                    )}
                    label={t(k.JUL)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.August)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.August,
                    )}
                    label={t(k.AUG)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.September)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.September,
                    )}
                    label={t(k.SEP)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.October)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.October,
                    )}
                    label={t(k.OCT)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.November)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.November,
                    )}
                    label={t(k.NOV)}
                    onChange={handleChange}
                />
                <Checkbox
                    id={String(ERecurringChecklistMonthOfYear.December)}
                    value={monthsOfYear.includes(
                        ERecurringChecklistMonthOfYear.December,
                    )}
                    label={t(k.DEC)}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default RecurringChecklistMonthOfYear;
