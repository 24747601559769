import i18n from "i18next";
import React from "react";
import { Form } from "react-bootstrap";

import IAttachmentFormData from "../../../common/viewModels/IAttachmentFormData";
import IValidationMessages from "../../../common/viewModels/IValidationMessages";
import k from "../../../i18n/keys";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Modal from "common/components/modals/Modal";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";

interface IProps {
    isLoading: boolean;

    errors: IValidationMessages;

    onClose: (isSave: boolean, data: IAttachmentFormData) => void;
}

interface IState extends IAttachmentFormData {
    [key: string]: any;
}

class AttachmentLink extends React.Component<IProps, IState> {
    public state: IState = {
        name: "",
        content: "",
    };

    public handleClose = (isSave: boolean) => () => {
        const data: IAttachmentFormData = {
            ...this.state,
        };

        this.props.onClose(isSave, data);
    };

    public handleChange = (event: IInputChangeEvent<string>) => {
        const { name, value } = event;

        if (name) {
            this.setState({
                [name]: value,
            });
        }
    };

    public render() {
        const { isLoading, errors } = this.props;

        const { name, content } = this.state;

        return (
            <Modal show onHide={this.handleClose(false)}>
                <Modal.Title>{i18n.t(k.NEW_LINK)}</Modal.Title>
                <Modal.Body>
                    <Form>
                        <InputComponent
                            testId="attachment-link-name"
                            inputType={EActivityType.Textfield}
                            wrapperLabel={i18n.t(k.NAME)}
                            placeholder={i18n.t(k.ENTER_NAME)}
                            value={name}
                            name="name"
                            onChange={this.handleChange}
                            invalid={!!errors.Name}
                            hideIcon
                        />

                        <InputComponent
                            testId="attachment-link-content"
                            inputType={EActivityType.Textfield}
                            wrapperLabel={i18n.t(k.URL)}
                            placeholder={i18n.t(k.URL)}
                            value={content}
                            name="content"
                            onChange={this.handleChange}
                            invalid={!!errors.Content}
                            hideIcon
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            disabled={isLoading}
                            onClick={this.handleClose(true)}
                        >
                            {isLoading ? i18n.t(k.SAVING) : i18n.t(k.SAVE)}
                        </Button>

                        <Button
                            variant="gray"
                            onClick={this.handleClose(false)}
                        >
                            {i18n.t(k.CANCEL)}
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AttachmentLink;
