import { Dispatch } from "redux";

import { StepInstanceService } from "./services";
import {
    ThunkActionReturnsPromise,
    ThunkActionReturnsPromiseDispatch,
} from "common/types";
import {
    StepInstanceActionTypes,
    SET_LAST_FINALIZED_STEPINSTANCE_ID,
    TRY_FINALIZE_STEPINSTANCE_BY_ID,
    REQUEST_CHECK_REMOVE_TEAM,
    RECIEVE_CHECK_REMOVE_TEAM,
} from "./types";
import { IStepInstanceState } from "./reducer";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IWorkCommentRequest from "http/requests/IWorkCommentRequest";
import IChecklistComment from "models/IChecklistComment";
import IChecklistCommentFilter from "http/filters/IChecklistCommentFilter";
import ICheckRemoveTeamRequest from "http/requests/ICheckRemoveTeamRequest";
import ICheckRemoveTeamResponse from "http/responses/ICheckRemoveTeamResponse";

export const saveComment = async (data: IWorkCommentRequest) => {
    return await new StepInstanceService().createComment(data);
};

export const getComments: ThunkActionReturnsPromise<
    ExecutionResult<IChecklistComment[]>,
    IStepInstanceState,
    StepInstanceActionTypes
> = (filter: IChecklistCommentFilter) => {
    return async () => {
        const result = await new StepInstanceService().getComments(filter);

        return ExecutionResult.Result(result);
    };
};

export const setLastFinalizedStepInstanceId = (
    stepInstanceId: string,
): StepInstanceActionTypes => {
    return {
        type: SET_LAST_FINALIZED_STEPINSTANCE_ID,
        payload: stepInstanceId,
    };
};

export const tryFinalize = (
    stepInstanceId: string,
): StepInstanceActionTypes => {
    return {
        type: TRY_FINALIZE_STEPINSTANCE_BY_ID,
        payload: stepInstanceId,
    };
};

export const checkRemoveTeam = (removeTeamRequest: ICheckRemoveTeamRequest) => {
    const request = (): StepInstanceActionTypes => {
        return {
            type: REQUEST_CHECK_REMOVE_TEAM,
        };
    };

    const success = (
        data: ICheckRemoveTeamResponse,
    ): StepInstanceActionTypes => {
        return {
            type: RECIEVE_CHECK_REMOVE_TEAM,
            payload: data,
        };
    };

    return async (dispatch: Dispatch) => {
        dispatch(request());

        const data = await new StepInstanceService().checkRemoveTeam(
            removeTeamRequest,
        );

        dispatch(success(data));

        return data;
    };
};

export const checkRemoveTeamDispatch = (
    dispatch: Dispatch<any>,
    removeTeamRequest: ICheckRemoveTeamRequest,
) => {
    return (
        dispatch as ThunkActionReturnsPromiseDispatch<
            ICheckRemoveTeamResponse,
            IStepInstanceState,
            StepInstanceActionTypes
        >
    )(checkRemoveTeam(removeTeamRequest));
};
