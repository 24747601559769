import "./Select.scss";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { orderMultiselectOptions } from "./utils";
import { IMultiselectOption } from "common/IMultiselectOption";
import { IValueParamItem } from "common/IValueParamItem";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { usePublishedTeamOptions } from "components/teams/api/hooks";

interface IProps extends IInputWrapperProps {
    id: string;
    selectedTeams?: IValueParamItem<string, boolean>[] | string[];

    placeholder?: string;
    multi?: boolean;

    testId?: string;
    wrapperInputTestId?: string;

    onChange: (roleIds: string[]) => void;
}

const SelectTeams: React.FC<IProps> = (props) => {
    const {
        id,
        htmlFor,
        selectedTeams,
        placeholder,
        multi,
        className,
        wrapperIcon: icon,
        wrapperLabel: label,
        testId,
        wrapperInputTestId: inputTestId,
        boldLabel,
    } = props;

    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const { data: publishedTeams } = useSharedOptions(
        true,
        ESharedOptionsType.team,
        typeof selectedTeams?.[0] === "string"
            ? (selectedTeams as string[])
            : (selectedTeams as unknown as IValueParamItem[])
                  ?.filter((x) => x.value !== "EVERYONE")
                  .map((x) => x.value),
    );

    const selectedOptions = useMemo(() => {
        if (publishedTeams && selectedTeams) {
            if (typeof selectedTeams[0] === "string") {
                const asString = selectedTeams as string[];
                const result: IMultiselectOption[] = asString.map((x) => {
                    const team = publishedTeams.find((team) => x == team.value);
                    return {
                        value: x,
                        label: team?.label ?? t(k[x as keyof typeof k]),
                        isArchived: team?.isArchived,
                        isDeleted: team?.isDeleted,
                    };
                });

                return result;
            } else {
                const asValueParam = selectedTeams as IValueParamItem<
                    string,
                    boolean
                >[];
                const result: IMultiselectOption[] = asValueParam.map((x) => {
                    const team = publishedTeams.find(
                        (team) => x.value == team.value,
                    );
                    return {
                        value: x.value,
                        label: team?.label ?? t(k[x.value as keyof typeof k]),
                        isFixed: x.param,
                        isArchived: team?.isArchived,
                        isDeleted: team?.isDeleted,
                    };
                });

                return orderMultiselectOptions(result);
            }
        }
    }, [selectedTeams, publishedTeams, localeId]);

    const handleOnChange = (options: any) => {
        let newValues = [] as string[];

        if (options) {
            const selectedValues = options as IMultiselectOption[];
            newValues = selectedValues.map((x) => {
                return x.value;
            });
        }

        props.onChange(newValues);
    };

    return (
        <InputWrapper
            htmlFor={id}
            className={`select-teams${className ? " " + className : ""}`}
            wrapperIcon={icon}
            wrapperLabel={label ?? t(k.TEAMS)}
            boldLabel={boldLabel}
            wrapperInputTestId={inputTestId}
        >
            <SelectDropdown
                inputId={id}
                value={selectedOptions}
                isMulti={multi}
                isSearchable
                isClearable
                placeholder={placeholder ?? t(k.SELECT)}
                options={publishedTeams}
                testId={testId}
                closeMenuOnSelect={multi !== true}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};

export default SelectTeams;
