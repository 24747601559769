export const Roles = {
    ADMINISTRATOR: "ADMINISTRATOR",
    MAPPER: "MAPPER",
    PLANNER: "PLANNER",
    DEVIATION_MANAGER: "DEVIATION_MANAGER",
    USER: "USER",
    //Operational Risk Analysis
    ORA_OWNER: "ORA_OWNER",
    ORA_EDITOR: "ORA_EDITOR",
    ORA_VIEWER: "ORA_VIEWER",
    //Staff Appraislas
    SA_OWNER: "SA_OWNER",
    SA_EDITOR: "SA_EDITOR",
    SA_USER: "SA_USER",
    //Competency Matrix
    CM_OWNER: "CM_OWNER",
    CM_EDITOR: "CM_EDITOR",
    CM_VIEWER: "CM_VIEWER",
    CM_USER: "CM_USER",
    //Positions
    POSITION_OWNER: "POSITION_OWNER",
    POSITION_EDITOR: "POSITION_EDITOR",
    POSITION_VIEWER: "POSITION_VIEWER",
    //Users
    USER_OWNER: "USER_OWNER",
    USER_EDITOR: "USER_EDITOR",
    USER_VIEWER: "USER_VIEWER",
    //KPI
    KPI_OWNER: "KPI_OWNER",
    KPI_EDITOR: "KPI_EDITOR",
    KPI_CONTRIBUTOR: "KPI_CONTRIBUTOR",
    KPI_VIEWER: "KPI_VIEWER",
    //Goals
    GOALS_OWNER: "GOALS_OWNER",
    GOALS_EDITOR: "GOALS_EDITOR",
    GOALS_USER: "GOALS_USER",

    //Customers
    CUSTOMERS_OWNER: "CUSTOMERS_OWNER",
    CUSTOMERS_EDITOR: "CUSTOMERS_EDITOR",
    CUSTOMERS_VIEWER: "CUSTOMERS_VIEWER",

    //ImprovementDashboard
    IMPROVEMENT_DASHBOARD_OWNER: "IMPROVEMENT_DASHBOARD_OWNER",
    IMPROVEMENT_DASHBOARD_VIEWER: "IMPROVEMENT_DASHBOARD_VIEWER",

    // checklist report
    CHECKLIST_REPORT_OWNER: "CHECKLIST_REPORT_OWNER",
    CHECKLIST_REPORT_VIEWER: "CHECKLIST_REPORT_VIEWER",

    // stakeholders
    STAKEHOLDERS_OWNER: "STAKEHOLDERS_OWNER",
    STAKEHOLDERS_EDITOR: "STAKEHOLDERS_EDITOR",
    STAKEHOLDERS_VIEWER: "STAKEHOLDERS_VIEWER",

    // legislations
    LEGISLATIONS_OWNER: "LEGISLATIONS_OWNER",
    LEGISLATIONS_EDITOR: "LEGISLATIONS_EDITOR",
    LEGISLATIONS_VIEWER: "LEGISLATIONS_VIEWER",

    // impact gradings
    IMPACT_GRADINGS_OWNER: "IMPACT_GRADINGS_OWNER",
    IMPACT_GRADINGS_EDITOR: "IMPACT_GRADINGS_EDITOR",
    IMPACT_GRADINGS_VIEWER: "IMPACT_GRADINGS_VIEWER",

    // customer requirements
    CUSTOMER_REQUIREMENTS_OWNER: "CUSTOMER_REQUIREMENTS_OWNER",
    CUSTOMER_REQUIREMENTS_EDITOR: "CUSTOMER_REQUIREMENTS_EDITOR",
    CUSTOMER_REQUIREMENTS_VIEWER: "CUSTOMER_REQUIREMENTS_VIEWER",

    // suppliers
    SUPPLIERS_OWNER: "SUPPLIERS_OWNER",
    SUPPLIERS_EDITOR: "SUPPLIERS_EDITOR",
    SUPPLIERS_VIEWER: "SUPPLIERS_VIEWER",

    // purchase orders
    PURCHASE_ORDERS_OWNER: "PURCHASE_ORDERS_OWNER",
    PURCHASE_ORDERS_EDITOR: "PURCHASE_ORDERS_EDITOR",
    PURCHASE_ORDERS_VIEWER: "PURCHASE_ORDERS_VIEWER",

    // items
    ITEMS_OWNER: "ITEMS_OWNER",
    ITEMS_EDITOR: "ITEMS_EDITOR",
    ITEMS_VIEWER: "ITEMS_VIEWER",

    // teams
    TEAMS_OWNER: "TEAMS_OWNER",
    TEAMS_EDITOR: "TEAMS_EDITOR",
    TEAMS_VIEWER: "TEAMS_VIEWER",

    // training plans
    TRAINING_PLANS_OWNER: "TRAINING_PLANS_OWNER",
    TRAINING_PLANS_EDITOR: "TRAINING_PLANS_EDITOR",
    TRAINING_PLANS_VIEWER: "TRAINING_PLANS_VIEWER",

    // equipment
    EQUIPMENT_OWNER: "EQUIPMENT_OWNER",
    EQUIPMENT_EDITOR: "EQUIPMENT_EDITOR",
    EQUIPMENT_VIEWER: "EQUIPMENT_VIEWER",

    // environmental aspects
    ENVIRONMENT_ASPECTS_OWNER: "ENVIRONMENT_ASPECTS_OWNER",
    ENVIRONMENT_ASPECTS_EDITOR: "ENVIRONMENT_ASPECTS_EDITOR",
    ENVIRONMENT_ASPECTS_VIEWER: "ENVIRONMENT_ASPECTS_VIEWER",

    // competencies
    COMPETENCIES_OWNER: "COMPETENCIES_OWNER",
    COMPETENCIES_EDITOR: "COMPETENCIES_EDITOR",
    COMPETENCIES_VIEWER: "COMPETENCIES_VIEWER",
} as const;

const RolesList = Object.values(Roles);

export type TRoles = (typeof RolesList)[number];
