import "./MeasurementStatusBadgeWithTooltip.scss";

import IconWithMessage from "components/common/iconsWithMessage/IconWithMessage";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";
import MeasurementStatusBadge from "../measurement-status-badge/MeasurementStatusBagde";

interface IProps {
    id: string;
    status?: EMeasurementGrading;
    message?: string;
    hideOnTrack?: boolean;
}

const GoalStatusBadgeWithTooltip = (props: IProps) => {
    const { status, message, id, hideOnTrack } = props;
    const showTooltip =
        (status === EMeasurementGrading.Blocked ||
            status === EMeasurementGrading.AtRisk) &&
        !!message;

    return (
        <MeasurementStatusBadge
            status={status ?? EMeasurementGrading.OnTrack}
            hideOnTrack={hideOnTrack}
            className="measurement-status-badge-with-tooltip"
            info={
                showTooltip && (
                    <IconWithMessage
                        id={id}
                        message={message}
                        iconType="info"
                        darkBackground={status === EMeasurementGrading.Blocked}
                    />
                )
            }
        />
    );
};
export default GoalStatusBadgeWithTooltip;
