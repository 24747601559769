import React from "react";
import classNames from "classnames";
import { IValueLabelItem } from "common/IValueLabelItem";

import "./BadgeRow.scss";
import IValidationMessages from "common/viewModels/IValidationMessages";

interface IProps {
    options: IValueLabelItem[];
    selectedValue?: string;
    disabled?: boolean;
    onSelect: (value: string) => void;
    errors: IValidationMessages | undefined;
    testId?: string;
    variant?: "horizontal" | "vertical";
}

//BadgeRowButton component for reusability and readability
const BadgeRowButton: React.FC<{
    label: string;
    value: string;
    isSelected: boolean;
    disabled: boolean;
    testId?: string;
    onSelect: () => void;
}> = ({ label, value, isSelected, disabled, onSelect, testId }) => {
    const buttonClass = classNames("badge-row-button", `badge-${value}`, {
        "badge-active": isSelected,
        "badge-disabled": disabled,
    });

    return (
        <button
            className={buttonClass}
            onClick={!disabled ? onSelect : undefined}
            data-testid={testId}
            type="button"
        >
            {label}
        </button>
    );
};

//BadgeRow component that can work with any array of ValueLabel options
const BadgeRow: React.FC<IProps> = ({
    options,
    selectedValue,
    disabled = false,
    testId = "badge-row-button",
    onSelect,
    variant = "vertical",
}) => {
    const rowClass = classNames({
        "badge-row": true,
        "badge-row--vertical": variant == "vertical",
    });
    return (
        <div className={rowClass}>
            {options.map((option) => (
                <BadgeRowButton
                    key={option.value}
                    testId={testId}
                    label={option.label}
                    value={option.value}
                    isSelected={selectedValue === option.value}
                    disabled={disabled}
                    onSelect={() => onSelect(option.value)}
                />
            ))}
        </div>
    );
};

export default BadgeRow;
