import React, { useLayoutEffect } from "react";
import { CellProps } from "react-datasheet-grid";
import Select, { GroupBase, SelectInstance } from "react-select";

import {
    ISelectGroupedOption,
    SelectGroupedOptions,
} from "../../api/SelectGroupedOptions";
import { buildStyles } from "../../styles";

function selectGroupedManyComponent<IsSelectOneInGroup extends boolean>(
    props: CellProps<string[] | null, SelectGroupedOptions<IsSelectOneInGroup>>,
) {
    const { active, rowData, setRowData, focus, stopEditing, columnData } =
        props;

    const ref =
        React.useRef<
            SelectInstance<
                ISelectGroupedOption<IsSelectOneInGroup>,
                true,
                GroupBase<ISelectGroupedOption<IsSelectOneInGroup>>
            >
        >(null);

    useLayoutEffect(() => {
        if (focus) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }
    }, [focus]);

    const styles = React.useMemo(
        () =>
            buildStyles<ISelectGroupedOption<IsSelectOneInGroup>, true>({
                active,
                focus,
            }),
        [active, focus],
    );

    return (
        <Select
            ref={ref}
            isMulti
            styles={styles}
            isDisabled={columnData.disabled}
            value={columnData.choices
                .flatMap((x) => x.options ?? [])
                .filter(({ value }) => rowData?.includes(value))}
            menuPortalTarget={document.body}
            menuIsOpen={focus}
            closeMenuOnSelect={false}
            onChange={(choices) => {
                if (choices === null) return;

                const nextValues: string[] = [];

                const groupedChoicesByParentId = choices.reduce<{
                    parentIds: string[];
                    values: Record<string, string>;
                }>(
                    (acc, curr) => {
                        const parentId = curr.parentId;

                        if (parentId) {
                            if (!acc.values[parentId]) {
                                acc.parentIds.push(parentId);
                            }

                            acc.values[parentId] = curr.value;
                        }

                        return acc;
                    },
                    { parentIds: [], values: {} },
                );

                for (const parentId of groupedChoicesByParentId.parentIds) {
                    nextValues.push(groupedChoicesByParentId.values[parentId]);
                }

                setRowData(nextValues);
            }}
            onMenuClose={() => stopEditing({ nextRow: false })}
            options={columnData.choices}
        />
    );
}

export const SelectGroupedManyComponent = React.memo(
    selectGroupedManyComponent,
) as typeof selectGroupedManyComponent;
