import { AnchorHTMLAttributes, PropsWithChildren } from "react";

interface ILinkProps
    extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
    to: string;
}

const Link = ({
    to,
    children,
    target = "_blank",
    rel = "noopener noreferrer",
    ...rest
}: PropsWithChildren<ILinkProps>) => {
    return (
        <a
            href={to}
            target={target}
            rel={rel}
            onClick={(e) => {
                e.stopPropagation();
            }}
            {...rest}
        >
            {children}
        </a>
    );
};

export default Link;
