import "./TrashIcon.scss";

import React from "react";
import { FiTrash } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

interface IProps {
    dotIcon?: boolean;
}

const TrashIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    if (props.dotIcon) {
        return <TiDelete className="icon-trash" />;
    }

    return <FiTrash className="icon-trash" />;
};

export default TrashIcon;
