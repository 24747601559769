import "./PrintIcon.scss";

import React, { useMemo } from "react";

import Icon from "./assets/print-icon.svg";

interface IProps {
    variant?: "white";
}

const PrintIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { variant } = props;

    const className = useMemo(() => {
        const result = ["print-icon"];
        if (variant) {
            result.push(`print-icon--${variant}`);
        }
        return result.join(" ");
    }, [variant]);

    return <Icon className={className} />;
};

export default PrintIcon;
