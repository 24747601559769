import "./TextfieldComplex.scss";

import React, { useEffect, useMemo, useState } from "react";

import { TextfieldType } from "./types";
import { TextfieldHint } from "./components/TextfieldHint";
import { TextfieldError } from "./components/TextfieldError";
import { Spinner } from "react-bootstrap";

export interface ITextfieldProps {
    id?: string;
    name?: string;
    value: string;

    innerRef?: React.RefObject<HTMLElement>;

    wrapperClassName?: string;
    autoCompleteOff?: boolean;
    autoFocus?: boolean;
    background?: "dark";
    bold?: boolean;
    labelContent?: React.ReactNode;
    helperLabelContent?: React.ReactNode;
    helperText?: React.ReactNode;
    multiline?: boolean;
    minRows?: number;
    textAlign?: "center" | "right";
    variant?: "gray";
    fontSize?: "small";
    isLink?: boolean;
    preview?: boolean;
    invalid?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    type?: TextfieldType;
    size?: "large";
    isWidthMinimized?: boolean;
    placeholder?: string;
    isBusy?: boolean;
    preInputContent?: React.ReactNode;
    postInputContent?: React.ReactNode;

    testId?: string;

    maxValue?: number;
    minValue?: number;

    hideNumberArrows?: boolean;

    onBlur?: (id?: string, name?: string) => void;
    onEnter?: () => void;
    onChange?: (value: string, id?: string, name?: string) => void;
}

type FCTextfieldType = React.FC<ITextfieldProps> & {
    Hint: React.FC<React.PropsWithChildren>;
    Error: React.FC<React.PropsWithChildren>;
};

const TIMEOUT_MS = 400;

const Textfield: FCTextfieldType = (props) => {
    const {
        autoCompleteOff,
        autoFocus,
        background,
        bold,
        disabled,
        readOnly,
        fontSize,
        helperText,
        id,
        name,
        labelContent,
        helperLabelContent,
        invalid,
        isLink,
        placeholder,
        postInputContent,
        preInputContent,
        preview,
        textAlign,
        value,
        variant,
        wrapperClassName,
        innerRef,
        multiline,
        minRows,
        isWidthMinimized,
        testId,
        isBusy,
        maxValue,
        minValue,
        hideNumberArrows,
    } = props;

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (isBusy) {
            setShowSpinner(true);
        }

        // Show loader a bits longer to avoid loading flash
        if (!isBusy && showSpinner) {
            const timeout = setTimeout(() => {
                setShowSpinner(false);
            }, TIMEOUT_MS);

            // Don’t forget to clear the timeout
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isBusy, showSpinner]);

    const type = props.type ?? "text";

    const isLarge = props.size === "large";

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (props.onChange) {
            props.onChange(e.target.value, id, name);
        }
    };

    const handleOnBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (props.onBlur) {
            props.onBlur(id, name);
        }
    };

    const handleKeyPress = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        // https://www.w3.org/TR/uievents-key/#named-key-attribute-values

        if (e.key === "Enter") {
            if (props.onEnter) {
                props.onEnter();
            }
        }
    };

    const isDisabled = disabled || preview;

    const wrapperClass = useMemo(() => {
        const result: string[] = ["text-field"];

        if (invalid) {
            result.push("text-field--invalid");
        }

        if (bold) {
            result.push("text-field--bold");
        }

        if (multiline) {
            result.push("text-field--multiline");
        }

        if (wrapperClassName) {
            result.push(wrapperClassName);
        }

        if (isWidthMinimized && !multiline) {
            result.push("text-field--short-nr");
        }

        return result.join(" ");
    }, [invalid, bold, multiline, wrapperClassName, isWidthMinimized]);

    const classPreview = useMemo(() => {
        const result: string[] = ["input-field--preview"];

        if (preInputContent) {
            result.push("input-field--preview--pre-content");
        }

        if (postInputContent) {
            result.push("input-field--preview--post-content");
        }

        if (textAlign) {
            result.push(`input-field--preview--text-${textAlign}`);
        }

        if (variant) {
            result.push(`input-field--preview--${variant}`);
        }

        if (fontSize) {
            result.push(`input-field--preview--${fontSize}`);
        }

        if (isLink) {
            result.push("input-field--preview--link");
        }

        if (multiline) {
            result.push("input-field--preview--multiline");
        }

        return result.join(" ");
    }, [
        preInputContent,
        postInputContent,
        textAlign,
        variant,
        fontSize,
        isLink,
        multiline,
    ]);

    const classInputControl = useMemo(() => {
        const result: string[] = ["input-field"];

        if (multiline) {
            result.push(
                "input-field--multiline input-field--multiline__no-height",
            );
        }

        if (isLarge && !multiline) {
            result.push("input-field--large");
        }

        if (preInputContent) {
            result.push("input-field--pre-content");
        }

        if (postInputContent) {
            result.push("input-field--post-content");
        }

        if (textAlign) {
            result.push(`input-field--align-${textAlign}`);
        }

        if (variant) {
            result.push(`input-field--${variant}`);
        }

        if (hideNumberArrows) {
            result.push("input-field--hide-number-arrows");
        }

        return result.join(" ");
    }, [
        multiline,
        isLarge,
        preInputContent,
        postInputContent,
        textAlign,
        variant,
        hideNumberArrows,
    ]);

    const classBox = useMemo(() => {
        const result = ["text-field--box"];

        if (multiline) {
            result.push("grow-wrap");
        }

        if (isDisabled) {
            result.push("disabled");
        }

        if (background) {
            result.push(`background-${background}`);
        }

        return result.join(" ");
    }, [isDisabled, background]);

    return (
        <label className={wrapperClass}>
            {preview ? (
                <div className={classPreview} data-testid={testId}>
                    {preInputContent}
                    {isLink ? <a href={value}>{value}</a> : value}
                    {postInputContent}
                </div>
            ) : (
                <React.Fragment>
                    {labelContent && (
                        <div className="text-field--label">{labelContent}</div>
                    )}

                    {helperLabelContent && (
                        <div className="text-field--label__helper">
                            {helperLabelContent}
                        </div>
                    )}

                    <div className={classBox} data-replicated-value={value}>
                        {!!preInputContent && (
                            <div className="text-field--pre-content">
                                {preInputContent}
                            </div>
                        )}
                        {showSpinner && (
                            <Spinner
                                animation="border"
                                size="sm"
                                className="text-field--spinner"
                            />
                        )}
                        {multiline ? (
                            <textarea
                                id={id}
                                data-testid={testId}
                                ref={
                                    innerRef as React.RefObject<HTMLTextAreaElement>
                                }
                                disabled={isDisabled}
                                readOnly={readOnly}
                                autoFocus={autoFocus}
                                placeholder={placeholder}
                                className={classInputControl}
                                rows={postInputContent ? 1 : minRows ?? 2}
                                value={value}
                                onChange={
                                    props.onChange ? handleChange : undefined
                                }
                                onBlur={handleOnBlur}
                                onKeyPress={
                                    props.onEnter ? handleKeyPress : undefined
                                }
                            />
                        ) : (
                            <input
                                id={id}
                                data-testid={testId}
                                ref={
                                    innerRef as React.RefObject<HTMLInputElement>
                                }
                                disabled={isDisabled}
                                readOnly={readOnly}
                                autoFocus={autoFocus}
                                type={type}
                                placeholder={placeholder}
                                className={classInputControl}
                                value={value}
                                autoComplete={
                                    autoCompleteOff ? "off" : undefined
                                }
                                onChange={
                                    props.onChange ? handleChange : undefined
                                }
                                onBlur={handleOnBlur}
                                onKeyPress={
                                    props.onEnter ? handleKeyPress : undefined
                                }
                                max={maxValue}
                                min={minValue}
                            />
                        )}

                        {!!postInputContent && (
                            <div className="text-field--post-content">
                                {postInputContent}
                            </div>
                        )}
                    </div>

                    {helperText && (
                        <div className="text-field--helper-text">
                            {helperText}
                        </div>
                    )}
                </React.Fragment>
            )}
        </label>
    );
};

Textfield.Hint = TextfieldHint;
Textfield.Error = TextfieldError;

export default Textfield;
