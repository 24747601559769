import { components, ClearIndicatorProps, GroupBase } from "react-select";

import { ClearIcon } from "common/components/icons";
import { OptionType } from "../SelectDropdownTypes";

const ClearIndicator = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: ClearIndicatorProps<Option, IsMulti, Group>,
) => {
    return (
        <components.ClearIndicator {...props}>
            <ClearIcon />
        </components.ClearIndicator>
    );
};

export default ClearIndicator;
