import React, { ReactElement, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { objectEmpty } from "common/utils/objectUtils";

import EmptyTableSvg from "../assets/empty-table.svg";
import NoResultsSvg from "../assets/no-results.svg";
import useResizableColumns, {
    defaultMinColumnWidth,
} from "../useResizableColumns";
import { getLastRowCellsCount } from "../utils";
import { TableContext, ITableContextValue } from "./Context";
import TableEmptyBodyComponent from "./TableEmptyBodyComponent";
import TableHeader from "./TableHeaderComponent";

import "./index.scss";

interface IProps {
    isLoading?: boolean;
    defaultShowLoader?: boolean;
    name?: string;

    canExpandRow?: boolean;
    resizable?: boolean;
    resizingKey?: "index" | "name";

    className?: string;
    testId?: string;

    noWrapper?: boolean;
    wrapperClassName?: string;
    wrapperToolbar?: React.ReactNode;
    pagination?: React.ReactNode;

    recordsCount?: number;

    hasFilters?: boolean;
    noRows?: boolean;

    columnsCount?: number;
    emptyMessage?: React.ReactNode;
    emptyTableImage?: React.ReactNode | null;

    noResultsMessage?: React.ReactNode;
    noResultsImage?: React.ReactNode;

    isHeaderVerticalAlign?: boolean;

    onClick?: (event: React.MouseEvent<HTMLTableElement>) => void;
}

const TIMEOUT_MS = 700;

const TableComponent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        isLoading,
        defaultShowLoader,
        canExpandRow,
        recordsCount,
        emptyMessage,
        noResultsMessage,
        noRows,
        emptyTableImage,
        noResultsImage,
        isHeaderVerticalAlign,
        testId,
        name,
        resizable,
        resizingKey = "index",
    } = props;

    const isEmpty = (recordsCount ?? 0) <= 0;

    const hasFilters = props.hasFilters ?? false;

    const [showLoader, setShowLoader] = useState(defaultShowLoader);

    const [headerNames, setHeaderNames] = useState<
        Record<number, string | number>
    >({});

    const { columnWidths, startResizing, stopResizing, registerCellRef } =
        useResizableColumns(!!resizable, name, defaultMinColumnWidth);

    useEffect(() => {
        if (isLoading) {
            setShowLoader(true);
        }

        // Show loader a bits longer to avoid loading flash
        if (isLoading === false && showLoader) {
            const timeout = setTimeout(() => {
                setShowLoader(false);
            }, TIMEOUT_MS);

            // Don’t forget to clear the timeout
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isLoading, showLoader]);

    const registerHeaderName = (index: number, name: string | number) => {
        setHeaderNames((prev) => ({ ...prev, [index]: name }));
    };

    const columnsCount = useMemo(() => {
        if (props.columnsCount) {
            return props.columnsCount;
        }

        const tableHeader = React.Children.toArray(props.children).find(
            (child) => {
                const element = child as ReactElement;

                if (element.type === TableHeader) {
                    return element;
                }
            },
        ) as ReactElement | undefined;

        return getLastRowCellsCount(tableHeader?.props?.children);
    }, [props.columnsCount]);

    const contextValue = useMemo(() => {
        const result: ITableContextValue = {
            isLoading: showLoader,
            canExpandRow,
            columnsCount,
            isHeaderVerticalAlign,
            columnWidths: resizable ? columnWidths : undefined,
            resizingKey: resizable ? resizingKey : undefined,
            startResizing: resizable ? startResizing : undefined,
            stopResizing: resizable ? stopResizing : undefined,
            registerCellRef: resizable ? registerCellRef : undefined,
            columnsHeaders: resizable ? headerNames : undefined,
            registerColumnHeaderName: resizable
                ? registerHeaderName
                : undefined,
        };

        return result;
    }, [
        showLoader,
        canExpandRow,
        columnsCount,
        columnWidths,
        resizingKey,
        resizable,
        headerNames,
    ]);

    const className = classNames("app-table", props.className, {
        "table--loading": showLoader,
        "table--header-vert-align": isHeaderVerticalAlign,
    });

    const showEmptyRowsWhenLoading = isEmpty && showLoader;

    const showEmpty =
        isLoading === false &&
        isEmpty &&
        showLoader !== true &&
        hasFilters === false &&
        emptyMessage !== undefined;

    const showNoResults =
        isLoading === false &&
        isEmpty &&
        showLoader !== true &&
        hasFilters &&
        noResultsMessage !== undefined;

    const isInitializeResizable =
        resizable && !(columnWidths && !objectEmpty(columnWidths));

    return (
        <TableWrapper
            noWrapper={props.noWrapper}
            className={props.wrapperClassName}
            toolbar={props.wrapperToolbar}
            pagination={props.pagination}
        >
            <table
                className={className}
                style={
                    resizable && columnWidths && !objectEmpty(columnWidths)
                        ? { width: "max-content" }
                        : undefined
                }
                data-testid={testId}
                onClick={props.onClick}
            >
                <TableContext.Provider value={contextValue}>
                    {!(isLoading && isInitializeResizable) && props.children}

                    {noRows ? undefined : (
                        <React.Fragment>
                            {showEmptyRowsWhenLoading && (
                                <TableEmptyBodyComponent
                                    columnsCount={columnsCount}
                                />
                            )}

                            {showEmpty && (
                                <tbody>
                                    <tr>
                                        <td
                                            colSpan={
                                                canExpandRow
                                                    ? columnsCount + 1
                                                    : columnsCount
                                            }
                                            className="message-table-cell message-table-cell--empty"
                                        >
                                            <div className="empty-table--wrapper">
                                                {emptyTableImage
                                                    ? emptyTableImage
                                                    : emptyTableImage !==
                                                          null && (
                                                          <EmptyTableSvg />
                                                      )}

                                                <div className="empty-table-msg">
                                                    {emptyMessage}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                            {showNoResults && (
                                <tbody>
                                    <tr>
                                        <td
                                            colSpan={
                                                canExpandRow
                                                    ? columnsCount + 1
                                                    : columnsCount
                                            }
                                            className="message-table-cell message-table-cell--empty"
                                        >
                                            <div className="empty-table--wrapper">
                                                {noResultsImage ? (
                                                    noResultsImage
                                                ) : (
                                                    <NoResultsSvg />
                                                )}

                                                <div className="no-results-msg">
                                                    {noResultsMessage}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </React.Fragment>
                    )}
                </TableContext.Provider>
            </table>
        </TableWrapper>
    );
};

interface ITableWrapperProps {
    noWrapper?: boolean;
    toolbar?: React.ReactNode;
    className?: string;
    pagination?: React.ReactNode;
}

const TableWrapper: React.FC<React.PropsWithChildren<ITableWrapperProps>> = (
    props,
) => {
    const { noWrapper, toolbar, className, pagination } = props;

    if (noWrapper) {
        return (
            <React.Fragment>
                {toolbar}
                {props.children}
            </React.Fragment>
        );
    }

    return (
        <div
            className={`app-table--wrapper${className ? " " + className : ""}`}
        >
            <div className="app-table--container">
                {toolbar}
                {props.children}
            </div>

            {pagination && (
                <div className="app-table--pagination">{pagination}</div>
            )}
        </div>
    );
};

export default TableComponent;
