import axios from "axios";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { useMutation, useQuery, useQueryClient } from "react-query";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { IMeasurementProgressValueDTO } from "./IMeasurementProgressValueDTO";
import { IMeasurementProgressFilter } from "./IMeasurementProgressFilter";
import { measurementKeys } from "components/measurement/api/hooks";

const baseUrl = "/api/measurementProgressValue";

const measurementProgressKeys = {
    all: ["progressValues"] as const,
    getList: () => [...measurementProgressKeys.all] as const,
    progressListByMeasurementId: (measurementId?: string) =>
        [
            ...measurementProgressKeys.all,
            "progressList-byMeasurementId",
            measurementId,
        ] as const,
    calculatedMeasurementProgressValue: (filter?: IMeasurementProgressFilter) =>
        [
            ...measurementProgressKeys.all,
            "calculatedMeasurementProgressValue",
            filter,
        ] as const,
};

export const useSaveMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: {
            isCreate: boolean;
            value: IMeasurementProgressValueDTO;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/${value.id}`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(measurementProgressKeys.all);
                queryClient.invalidateQueries(
                    measurementKeys.measurementById(
                        variables.value.measurementId,
                    ),
                );
            },
        },
    );
};

export const useMeasurementProgressValue = (
    filter?: IMeasurementProgressFilter,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/calculated-progress`;
    return useQuery(
        measurementProgressKeys.calculatedMeasurementProgressValue(filter),
        async () => {
            const response = await axios.post<IMeasurementProgressValueDTO>(
                url,
                filter,
            );

            return response.data;
        },
        {
            enabled,
        },
    );
};

export const useProgressListByMeasurementId = (measurementId?: string) => {
    const url = `${baseUrl}/list/${measurementId}`;
    return useQuery(
        measurementProgressKeys.progressListByMeasurementId(measurementId),
        async () => {
            const response =
                await axios.get<IMeasurementProgressValueDTO[]>(url);

            return response.data;
        },
        {
            enabled: measurementId !== undefined && measurementId !== "",
        },
    );
};

export const useDeleteMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (id: string) => {
            try {
                const response = await axios.delete<string>(`${baseUrl}/${id}`);

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(measurementProgressKeys.all);
            },
        },
    );
};

export const useArchiveMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (request: IArchiveRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/archive`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(measurementProgressKeys.all);
            },
        },
    );
};
