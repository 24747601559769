import "./ErrorByIdListItemMessage.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import IValidationMessages from "common/viewModels/IValidationMessages";

interface IProps {
    errorById: { key: string; index: number };
    errorByIdName: string;

    label: React.ReactNode;

    errorKey: string;
    errors: IValidationMessages;

    onClick: (args: { key: string; name: string; index: number }) => void;
}

export const ErrorByIdListItemMessage = (props: IProps) => {
    const { errorById, errorByIdName, label, errorKey, errors } = props;

    const { t } = useTranslation();

    const handleClick = () => {
        props.onClick({
            key: errorById.key,
            name: errorByIdName,
            index: errorById.index,
        });
    };

    const errorMessage = errors[errorKey];

    const translationKeys = Array.isArray(errorMessage)
        ? errorMessage
        : [errorMessage];

    return (
        <React.Fragment>
            <span>{label}</span>:{" "}
            {translationKeys.map(
                (translationKey, i) =>
                    translationKey && (
                        <React.Fragment key={i}>
                            {i > 0 && ", "}

                            <strong
                                className="error-by-id-list-item-message__invalid-message"
                                onClick={handleClick}
                            >
                                {translationKey in k
                                    ? t(k[translationKey as keyof typeof k])
                                    : translationKey}
                            </strong>
                        </React.Fragment>
                    ),
            )}
        </React.Fragment>
    );
};
