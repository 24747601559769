import "./ImprovementStatusBadge.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import Badge from "../Badge";

import {
    DynamicImprovementStatus,
    dynamicImprovementStatusItems,
} from "models/enums/DynamicImprovementStatus";
import IconWithMessage from "components/common/iconsWithMessage/IconWithMessage";
import {
    ClosureReasonEnum,
    closureReasonItems,
} from "models/enums/ClosureReasonEnum";
import { getLocaleString } from "i18n/components/GetLocaleString";

interface ImprovementStatusBadgeProps {
    status?: DynamicImprovementStatus;
    closureReason?: ClosureReasonEnum;
}

const ImprovementStatusBadge: React.FC<ImprovementStatusBadgeProps> = (
    props,
) => {
    const { status, closureReason } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    const statusItem = dynamicImprovementStatusItems[status];

    const closureReasonItem = closureReason
        ? closureReasonItems[closureReason]
        : undefined;

    return (
        <Badge
            pill
            className={
                "improvement-status-badge improvement-status-badge--" +
                statusItem.param
            }
        >
            {t(statusItem.label)}
            {closureReasonItem ? (
                <IconWithMessage
                    id={closureReasonItem.param ?? ""}
                    message={getLocaleString(t, closureReasonItem.label)}
                    inheritColor
                />
            ) : null}
        </Badge>
    );
};

export default ImprovementStatusBadge;
