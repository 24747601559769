import "./HRIcon.scss";

import React from "react";

import Icon from "./assets/hr-icon.svg";

const HRIcon: React.FC = (props) => {
    return <Icon className="hr-icon" />;
};

export default HRIcon;
