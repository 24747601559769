import React, { useContext } from "react";
import ImprovementsDetailsContext from "../../../../../improvementContext";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { HistoryIcon } from "common/components/icons";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";

interface IButtonProps {}

const HistoryButton: React.FC<React.PropsWithChildren<IButtonProps>> = (
    props,
) => {
    const { selectedId } = useContext(ImprovementsDetailsContext);
    const modalManagerContext = useContext(ModalManagerContext);

    const handleShowHistory = () => {
        if (selectedId) {
            const payload: IShowModalPayload = {
                id: selectedId,
                type: ModalTypes.history,
                callerAddress: location.pathname,
                modalFor: {
                    id: selectedId,
                    type: ModalTypes.improvement,
                },
                options: {
                    historyOwnerType: HistoryOwnerEnum.Improvement,
                    showAll: true,
                },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    return (
        <IconButton
            onClick={handleShowHistory}
            background="dark"
            testId="historyBtn"
        >
            <HistoryIcon />
        </IconButton>
    );
};

export default HistoryButton;
