import { FC, useCallback, useContext } from "react";
import { usePublishedUserOptions } from "components/users/api/hooks";
import k from "i18n/keys";
import { useUpdateImprovementMutation } from "components/improvements/api/hooks";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import SelectedUsersWithInfo from "common/components/selected-users-with-info/SelectedUsersWithInfo";
import ViewTemplateConfigurationUsersTooltipMessage from "common/components/messages/ViewTemplateConfigurationUsersTooltipMessage";
import SelectUsersDropdown from "common/components/select-users/SelectUsersDropdown";
import IDictionary from "common/viewModels/IDictionary";
import IImprovementUpdateRequest from "http/requests/IImprovementUpdateRequest";
import IImprovementRequest from "http/requests/IImprovementRequest";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import IImprovementInvolvedUserRequest from "http/requests/IImprovementInvolvedUserRequest";

interface IProps {}

const InvolvedUsers: FC<IProps> = (props) => {
    const {
        editedImprovement,
        improvement,
        isLoadingInvolvedUsers,
        involvedUsers,
        setInvolvedUsers,
        usersToBeNotified,
        setEditedImprovement,
        onHaveChanges,
    } = useContext(ImprovementsDetailsContext);

    const impactedProcessesFeature = useSelector(
        (appState: IAppState) =>
            (appState.authViewState.profile?.appFeatures?.processChart &&
                appState.authViewState.profile?.appFeatures
                    ?.assignImpactedProcessesToImprovement) === true,
    );

    const { data: publishedUsers } = usePublishedUserOptions();

    const updateImprovementInfoMutatation = useUpdateImprovementMutation();

    const debouncedUpdateImprovementInfoMutatation = useCallback(
        debounce(updateImprovementInfoMutatation.mutate, 1000, {
            leading: false,
            trailing: true,
        }),
        [],
    );

    const handleChangeInvolvedUsers = (userId: string) => {
        if (!editedImprovement) {
            return;
        }
        //have not made any changed to the involved users yet,
        //so we need to initialize with the users from template configuration
        if (Boolean(improvement?.isInvolvedUsersChanged) == false) {
            if (involvedUsers && involvedUsers.length > 0) {
                let hasNotifyUsers =
                    usersToBeNotified && usersToBeNotified.length > 0;

                //users added from template configuration with their notification settings
                //here initialized with those settings, however they can be changed
                editedImprovement.involvedUsers = involvedUsers.reduce(
                    (acc, user) => {
                        acc[user.value] = {
                            shouldBeNotified: Boolean(
                                hasNotifyUsers &&
                                    usersToBeNotified?.some(
                                        (x) => x.value === user.value,
                                    ),
                            ),
                        };
                        return acc;
                    },
                    {} as IDictionary<IImprovementInvolvedUserRequest>,
                );
            } else {
                editedImprovement.involvedUsers = {};
            }
        } else if (!editedImprovement.involvedUsers) {
            editedImprovement.involvedUsers = {};
        }

        if (Object.keys(editedImprovement.involvedUsers).indexOf(userId) > -1) {
            delete editedImprovement.involvedUsers[userId];
            editedImprovement.isInvolvedUsersChanged = true;
        } else {
            //users added with this method are always to be notified
            editedImprovement.involvedUsers[userId] = {
                shouldBeNotified: true,
            };
            editedImprovement.isInvolvedUsersChanged = true;
        }

        setInvolvedUsers(
            publishedUsers?.options?.filter((user) =>
                Object.keys(editedImprovement?.involvedUsers ?? {}).includes(
                    user.value,
                ),
            ),
        );

        handleOnChangeInvolvedUsers(editedImprovement);
    };

    const handleOnChangeInvolvedUsers = (data: IImprovementRequest) => {
        setEditedImprovement({ ...data });

        const request: IImprovementUpdateRequest = {
            id: improvement?.id,
            impact: data.impact,
            impactList: data.impactList ?? null,
            deviationMessage: data.deviationMessage,
            involvedUsers: data.involvedUsers,
            isInvolvedUsersChanged: data.isInvolvedUsersChanged,
        };

        debouncedUpdateImprovementInfoMutatation(request);
    };

    return (
        <div>
            <SelectedUsersWithInfo
                users={involvedUsers}
                labelPrefixTranslationKey={k.INVOLVED}
                isLoading={isLoadingInvolvedUsers}
                tooltipMessage={
                    <ViewTemplateConfigurationUsersTooltipMessage
                        allSelectedUsers={involvedUsers}
                        titleTranslationKey={k.INVOLVED_USERS}
                    />
                }
                selectUsers={
                    impactedProcessesFeature && (
                        <SelectUsersDropdown
                            onSelect={handleChangeInvolvedUsers}
                            selectedUserIds={
                                involvedUsers?.map((x) => x.value) ?? []
                            }
                            users={publishedUsers?.options ?? []}
                            color="#fff"
                        />
                    )
                }
            />
        </div>
    );
};

export default InvolvedUsers;
