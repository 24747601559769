import "./StepsDone.scss";

import React from "react";

import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import IChecklistReportRow from "components/template-checklist-report/api/IChecklistReportRow";

interface IProps {
    value: IChecklistReportRow;
}

const StepsDone: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value } = props;

    return (
        <span
            className={
                value.status === ChecklistDynamicStatus.Finalized
                    ? "worksheet-steps-done"
                    : "worksheet-steps-done worksheet-steps-done--not-done"
            }
        >
            {value.finalizedSteps} / {value.totalSteps}
        </span>
    );
};

export default StepsDone;
