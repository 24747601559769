import "./SidePanelDetails.scss";

import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import ImprovementFormContext from "../../../context/ImprovementFormContext";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Badge from "common/components/badges/Badge";
import { getLocaleString } from "i18n/components/GetLocaleString";
import IconWithMessage from "components/common/iconsWithMessage/IconWithMessage";
import ListedContent from "common/components/listed-content/ListedContent";

const SidePanelDetails: React.FC = (props) => {
    const {
        improvementForm,
        setImprovementForm,
        steps,
        onHaveChanges,
        isArchived,
        errors,
        setErrors,
        createNew,
    } = useContext(ImprovementFormContext);

    const { t } = useTranslation();

    const handleOnChange = (e: IInputChangeEvent<string>) => {
        const name = e.name;
        if (name) {
            setImprovementForm((prev) =>
                prev ? { ...prev, [name]: e.value } : prev,
            );

            onHaveChanges(true);

            if (name === "name") {
                const nameKey = `form.${name}`;
                const nameError = errors?.[nameKey];

                if (e.value && nameError) {
                    setErrors((prev) => ({
                        ...prev,
                        [nameKey]: undefined,
                    }));
                } else if (e.value === "") {
                    setErrors((prev) => ({
                        ...prev,
                        [nameKey]: k.REQUIRED,
                    }));
                }
            }
        }
    };

    const isDraft =
        improvementForm?.lastPublishedVersion !== improvementForm?.version &&
        !createNew;

    return (
        <ListedContent.SidePanel.Details
            context={ImprovementFormContext}
            description={
                <InputComponent
                    testId="form-description-input"
                    inputType={EActivityType.MultiLineTextfield}
                    wrapperLabel={t(k.SUMMARY)}
                    boldLabel
                    id="form.description"
                    name="description"
                    value={improvementForm?.description ?? ""}
                    placeholder={t(
                        k.WRITE_A_SUMMARY_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE,
                    )}
                    disabled={isArchived}
                    hideIcon
                    onChange={handleOnChange}
                />
            }
            data-testid="form-details"
        >
            <div className="side-panel__details__title">
                {t(k.FORM_INFORMATION)}
                {errors && (
                    <IconWithMessage
                        id="form-error-info"
                        iconType="warning"
                        placement="bottom"
                        message={[
                            <div
                                className="form-error-title"
                                key="form-error-title"
                            >
                                {t(k.FORM_ERRORS)}
                            </div>,
                            ...Object.keys(errors).reduce<React.ReactNode[]>(
                                (acc, key) => {
                                    const item = errors[key];
                                    if (item) {
                                        const keys = key.split(".");

                                        let errorSource = "";

                                        if (keys.length === 1) {
                                            errorSource = keys[0];
                                        } else if (keys.length > 1) {
                                            const sourceType = keys[0];
                                            const id = keys[1];
                                            const type = keys[2];

                                            if (sourceType === "step") {
                                                const step = steps?.values[id];
                                                if (step?.name) {
                                                    errorSource =
                                                        t(k.STEP) +
                                                        ' "' +
                                                        getLocaleString(
                                                            t,
                                                            step.name,
                                                        ) +
                                                        '": ';
                                                }
                                            }

                                            if (sourceType === "form") {
                                                if (id === "name")
                                                    errorSource =
                                                        t(k.FORM) +
                                                        " " +
                                                        t(k.NAME) +
                                                        ": ";
                                            }

                                            if (sourceType === "activity") {
                                                if (type === "label") {
                                                    errorSource =
                                                        t(k.ACTIVITY) +
                                                        " " +
                                                        t(k.LABEL) +
                                                        ": ";
                                                }
                                            }
                                        }

                                        acc.push(
                                            <div key={key}>
                                                {errorSource +
                                                    getLocaleString(t, item)}
                                            </div>,
                                        );
                                    }
                                    return acc;
                                },
                                [],
                            ),
                        ]}
                    />
                )}
                {isDraft && <Badge variant="warning"> {t(k.DRAFT)}</Badge>}
            </div>

            <InputComponent
                testId="form-name-input"
                wrapperLabelTestId="form-name-label"
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.FORM_NAME)}
                boldLabel
                required
                id="form.name"
                name="name"
                value={improvementForm?.name ?? ""}
                placeholder={t(k.ENTER_HERE)}
                disabled={isArchived}
                hideIcon
                onChange={handleOnChange}
                invalid={!!errors?.[`form.name`]}
                titleErrors={getLocaleString(t, errors?.[`form.name`])}
            />
        </ListedContent.SidePanel.Details>
    );
};

export default SidePanelDetails;
