import { components, DropdownIndicatorProps, GroupBase } from "react-select";

import AccordionButton from "common/components/buttons/accordion-button/AccordionButton";
import { OptionType } from "../SelectDropdownTypes";

const DropdownIndicator = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
    return (
        <components.DropdownIndicator {...props}>
            <AccordionButton isOpen={props.selectProps.menuIsOpen} />
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;
