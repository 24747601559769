import Checkbox from "common/components/input-components/checkbox/Checkbox";
import UserMultiselect from "components/common/multiselect-dropdowns/UserMultiselect";
import { SelectedDynamicValueOption } from "components/improvement-forms/components/ImprovementForm/components/Content/components/ConfigurationStep/hooks/useSelectedDynamicRowValuesInitialization";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import DeleteUndoButton from "common/components/buttons/delete-undo-button/DeleteUndoButton";

interface ConfigurationTableDynamicRowProps {
    id: string;
    selectedRowValue?: SelectedDynamicValueOption;
    disabled?: boolean;
    onClickNotify?: (id: string, value: boolean) => void;
    onChangeUsers?: (id: string, users: string[]) => void;
    onDeleteRow?: (id: string) => void;
}
const DynamicRow: FC<ConfigurationTableDynamicRowProps> = (props) => {
    const { id, selectedRowValue, disabled, onClickNotify } = props;

    const handleChangeUsers = (users: string[]) => {
        props.onChangeUsers?.(id, users);
    };

    const handleDeleteRow = () => {
        props.onDeleteRow?.(id);
    };

    const { t } = useTranslation();

    return (
        <tr key={id}>
            <td>
                <UserMultiselect
                    boldLabel={false}
                    label={t(k.SELECT_USERS)}
                    defaultValues={selectedRowValue?.users}
                    onChange={handleChangeUsers}
                />
            </td>
            <td>
                <Checkbox
                    label=""
                    value={selectedRowValue?.shouldBeNotified}
                    onChange={(e) => onClickNotify?.(id, Boolean(e.value))}
                    disabled={disabled}
                />
            </td>
            <td>
                <DeleteUndoButton
                    testId="delete-configuration-row-btn"
                    isDeleted={selectedRowValue?.isDeleted}
                    onDelete={handleDeleteRow}
                />
            </td>
        </tr>
    );
};

export default DynamicRow;
