import { attr, fk, many, Model, ModelWithFields, TableState } from "redux-orm";

import IActivityDTO from "../dto/IActivityDTO";
import IActivity from "../IActivity";

class Activity extends Model<IActivity> {
    public static modelName = "Activity";

    public static fields = {
        id: attr(),
        label: attr(),
        name: attr(),
        placeholder: attr(),
        type: attr(),
        index: attr(),
        step: fk("Step", "activities"),
    };

    public static parseDTO(data: IActivityDTO): ModelWithFields<IActivity> {
        return this.upsert(data);
    }
}

export type ActivityOrmModelType = typeof Activity;

export type ActivityOrmState = TableState<IActivity>;

export default Activity;
