import "./CustomModal.scss";

import { CSSProperties, useMemo } from "react";

interface IProps {
    containerClassName?: string;

    noBackdrop?: boolean;

    onBackgroundClick: () => void;
}

const CustomModal: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { containerClassName, noBackdrop = false } = props;

    const containerClass = useMemo(() => {
        const result = ["custom-modal--item"];

        if (containerClassName) {
            result.push(containerClassName);
        }

        return result.join(" ");
    }, [containerClassName]);

    return (
        <div className="custom-modal">
            {!noBackdrop && (
                <div
                    className="custom-modal--item--backdrop"
                    onClick={() => props.onBackgroundClick()}
                />
            )}
            <div className={containerClass}>{props.children}</div>
        </div>
    );
};

export default CustomModal;
