import YearRow from "./components/YearRow/YearRow";
import PreviousYearButton from "./components/PrevYearButton/PreviousYearButton";
import NextYearButton from "./components/NextYearButton/NextYearButton";
import { MonthlyValue } from "./api/MonthlyValue";
import DataYearRow from "./components/DataYearRow/DataYearRow";
import { useEffect } from "react";

interface IYearlyTableRowProps {
    idsByYear?: string[];
    values: Record<string, MonthlyValue | undefined>;
    year: number;

    allowNegativeNumbers?: boolean;
    fillCellsBetweenValues?: boolean;
    disabled?: boolean;

    onChangeYear: (nextYear: number) => void;
    onChange: (param: { id: string; value: string }) => void;
}

const YearlyTable = (props: IYearlyTableRowProps) => {
    const {
        year,
        idsByYear,
        values,
        allowNegativeNumbers,
        fillCellsBetweenValues,
        disabled = false,
    } = props;

    useEffect(() => {
        // initialize year
        if (year && idsByYear == undefined) {
            props.onChangeYear(year);
        }
    }, []);

    const handleChangeValue = (value: string, id?: string) => {
        if (id) {
            const item = values[id];

            if (item) {
                props.onChange({
                    id,
                    value,
                });
            }
        }
    };

    const handleOnYearBack = () => {
        props.onChangeYear(year - 1);
    };

    const handleOnYearForward = () => {
        props.onChangeYear(year + 1);
    };

    return (
        <YearRow
            year={year}
            previous={<PreviousYearButton onClick={handleOnYearBack} />}
            next={<NextYearButton onClick={handleOnYearForward} />}
            children={
                <DataYearRow
                    ids={idsByYear ?? []}
                    values={values}
                    allowNegativeNumbers={allowNegativeNumbers}
                    fillCellsBetweenValues={fillCellsBetweenValues}
                    onChange={handleChangeValue}
                    disabled={disabled}
                />
            }
        />
    );
};

export default YearlyTable;
