import { Configuration } from "@azure/msal-browser";
import { isDevelopmentEnvironment, isOnTestAmpliflowHost } from "config";

type EnvironmentTypes = "test" | "dev" | "production";

type SettingsType = {
    clientId: string;
    domain: string;
};

const clientIds: Record<EnvironmentTypes, string> = {
    dev: "857425fc-fda2-49c7-999a-ea931fd16c1c",
    test: "031e948d-1e64-4495-a175-48b71eb6909d",
    production: "22d6dc80-6ce4-4724-8d7d-4f4cb8757280",
};

const domains: Record<EnvironmentTypes, string> = {
    dev: window.location.host,
    test: "test-sso.ampliflow.com",
    production: "sso.ampliflow.com",
};

function getSettings() {
    const environment = isDevelopmentEnvironment()
        ? "dev"
        : isOnTestAmpliflowHost()
          ? "test"
          : "production";

    const clientId = clientIds[environment];
    const domain = domains[environment];

    const settings: SettingsType = {
        clientId,
        domain,
    };

    return settings;
}

function getMsalConfig(settings: SettingsType) {
    const { clientId } = settings;

    const redirectUri = `${window.location.origin}/login`;

    const msalConfig: Configuration = {
        auth: {
            clientId,
            authority: "https://login.microsoftonline.com/common",
            redirectUri,
            knownAuthorities: ["login.microsoftonline.com"],
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
    };

    return msalConfig;
}

function getLoginRequest(settings: SettingsType) {
    const { clientId, domain } = settings;

    const scopes = [`api://${domain}/${clientId}/auth`];

    return {
        scopes,
    };
}

const defaultSettings = getSettings();

export const msalConfig = getMsalConfig(defaultSettings);
export const loginRequest = getLoginRequest(defaultSettings);
