import CustomListItemForm from "components/custom-list-item-page/components/CustomListItemForm";

import { useContext } from "react";

import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const CustomListItemModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options, customList },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <CustomListItemForm
            id={id}
            createNew={options?.createNew}
            defaultCustomListId={customList?.id}
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            syncData={modalManagerContext.state.activeModal === callId}
            onShowModal={modalManagerContext.onShowModal}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
        />
    );
};

export default CustomListItemModal;
