import "./SharedPropertiesForm.scss";

import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { SortEnd } from "react-sortable-hoc";

import PropertyButton from "components/common/custom-list-property-button/PropertyButton";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import ITableSet from "http/ITableSet";
import { arrayMoveTo } from "common/utils/arrayUtils";
import {
    isPropertyByDefaultShownInList,
    isAllowMultipleDisabled,
} from "components/common/custom-list-property-template/Config";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import {
    SortableTemplateList,
    SortableTemplateListItem,
} from "./components/SortableList";
import { useCustomListOptions } from "components/custom-list-page/api/hooks";
import ConfirmDeletePropertyModal from "./components/ConfirmDeletePropertyModal";

interface IProps {
    excludeCustomListParentId: string | null;

    hideMutualProperties?: boolean;
    isCustomList?: boolean;

    title?: React.ReactNode;

    disabled: boolean;

    selectedProperties: ITableSet<ICustomListPropertyTemplateValue> | null;

    errors: IValidationMessages;

    isAtLeastOnePropertyShouldBeVisibleInvalid: boolean;
    isAtLeastOnePropertyRequiredInvalid: boolean;

    showConfirmOnDelete?: boolean;

    onChange: (
        values: ICustomListPropertyTemplateValue[],
        isInitialization: boolean,
    ) => void;
}

type PropertiesStateType = ITableSet<ICustomListPropertyTemplateValue> & {
    isInitialization: boolean;
};

const SharedPropertiesForm: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        title,
        excludeCustomListParentId,
        disabled,
        errors,
        hideMutualProperties,
        showConfirmOnDelete,
        isCustomList,
    } = props;

    const { t } = useTranslation();

    const [selectedPropertyButtonIndex, setSelectedPropertyButtonIndex] =
        useState<number>();

    const [selectedToDeletePropertyId, setSelectedToDeletePropertyId] =
        useState<string>();

    const { data: propertiesList } = useCustomListOptions();

    const propertyOptions = propertiesList?.values;

    const [properties, setProperties] = useState<PropertiesStateType>(
        props.selectedProperties
            ? { ...props.selectedProperties, isInitialization: true }
            : {
                  ids: [],
                  values: {},
                  isInitialization: true,
              },
    );

    React.useEffect(() => {
        const values = properties.ids
            .map((id) => properties.values[id])
            .filter((x) => x) as ICustomListPropertyTemplateValue[];

        props.onChange(
            values.filter((x) => (x.isDeleted ?? false) === false),
            properties.isInitialization,
        );
    }, [properties]);

    const handleSelectCustomListProperty = (
        propertyCustomListId: string,
        label: string,
        index: number,
    ) => {
        const id = uuidv4();

        const item: ICustomListPropertyTemplateValue = {
            id,
            parentId: id,
            type: CustomListPropertyEnum.None,
            label,
            propertyCustomListId,
            allowMultiple: true,
            options: null,

            isMutualPropertyVisible: true,
        };

        addProperty(item, index);
    };

    const addProperty = (
        item: ICustomListPropertyTemplateValue,
        index: number,
    ) => {
        setProperties((prev) => ({
            ...prev,

            isInitialization: false,

            ids: prev.ids
                .slice(0, index + 1)
                .concat([item.id])
                .concat(prev.ids.slice(index + 1)),

            values: {
                ...prev.values,
                [item.id]: item,
            },
        }));
    };

    const handleSelectBasicProperty = (
        type: CustomListPropertyEnum,
        label: string,
        index: number,
    ) => {
        const id = uuidv4();

        const item: ICustomListPropertyTemplateValue = {
            id,
            parentId: id,
            type,
            label,
            showInListView: isPropertyByDefaultShownInList[type],
            options: null,
        };

        switch (type) {
            case CustomListPropertyEnum.Tasklist:
            case CustomListPropertyEnum.Customers:
            case CustomListPropertyEnum.Suppliers:
            case CustomListPropertyEnum.Items:
            case CustomListPropertyEnum.Users:
            case CustomListPropertyEnum.Positions:
            case CustomListPropertyEnum.Competencies:
                item.allowMultiple = true;

                break;

            default:
                item.allowMultiple = isAllowMultipleDisabled[type]
                    ? false
                    : true;

                break;
        }

        addProperty(item, index);
    };

    const handleChangeProperty = (e: {
        isInitialization?: boolean;
        value: ICustomListPropertyTemplateValue;
    }) => {
        setProperties((prev) => ({
            ...prev,

            isInitialization: e.isInitialization ?? false,

            values: {
                ...prev.values,
                [e.value.id]: e.value,
            },
        }));
    };

    const handleDeleteProperty = async (id: string) => {
        if (showConfirmOnDelete ?? false) {
            if (properties.values[id]?.isDeleted) {
                onPropertyDelete(id);
            } else {
                setSelectedToDeletePropertyId(id);
            }
        } else {
            onPropertyDelete(id);
        }
    };

    const onDeleteConfirmed = (yes?: boolean) => {
        const deletedId = selectedToDeletePropertyId;

        setSelectedToDeletePropertyId(undefined);

        if (yes && deletedId) {
            onPropertyDelete(deletedId);
        }
    };

    const onPropertyDelete = (id: string) => {
        setProperties((prev) => {
            const valuesById = prev.values[id];

            if (props.selectedProperties?.ids.includes(id) && valuesById) {
                return {
                    ...prev,

                    isInitialization: false,

                    values: {
                        ...prev.values,
                        [id]: {
                            ...valuesById,
                            isDeleted: !valuesById.isDeleted,
                        },
                    },
                };
            } else {
                delete prev.values[id];

                return {
                    ...prev,
                    isInitialization: false,
                    ids: prev.ids.filter((x) => x !== id),
                };
            }
        });
    };

    const handleTemplateListSorted = (params: SortEnd) => {
        setProperties((prev) => ({
            ...prev,

            isInitialization: false,

            ids: arrayMoveTo(prev.ids, params.oldIndex, params.newIndex),
        }));
    };

    return (
        <React.Fragment>
            {selectedToDeletePropertyId && (
                <ConfirmDeletePropertyModal
                    title={properties.values[selectedToDeletePropertyId]?.label}
                    onHide={onDeleteConfirmed}
                />
            )}
            <label className="properties-form__title text-field">
                {title ?? t(k.PROPERTIES)}
            </label>

            <SortableTemplateList
                useDragHandle
                onSortEnd={handleTemplateListSorted}
            >
                {properties.ids.length === 0 && (
                    <PropertyButton
                        excludeCustomListParentId={excludeCustomListParentId}
                        index={0}
                        isDisabled={
                            disabled ||
                            (selectedPropertyButtonIndex !== undefined &&
                                selectedPropertyButtonIndex !== 0)
                        }
                        isBlur={
                            selectedPropertyButtonIndex !== undefined &&
                            selectedPropertyButtonIndex !== 0
                        }
                        properties={propertyOptions ?? null}
                        showList={setSelectedPropertyButtonIndex}
                        invalid={props.isAtLeastOnePropertyRequiredInvalid}
                        onSelectCustomListProperty={
                            handleSelectCustomListProperty
                        }
                        onSelectBasicProperty={handleSelectBasicProperty}
                    />
                )}

                {properties.ids.map((id, index) => (
                    <React.Fragment key={id}>
                        <SortableTemplateListItem
                            isCustomListProperty={isCustomList}
                            index={index}
                            disabled={
                                disabled ||
                                selectedPropertyButtonIndex !== undefined
                            }
                            orderNumber={index + 1}
                            hideMutualProperties={hideMutualProperties}
                            isDisabled={
                                disabled ||
                                selectedPropertyButtonIndex !== undefined
                            }
                            isBlur={selectedPropertyButtonIndex !== undefined}
                            value={properties.values[id] ?? null}
                            errors={errors}
                            isAtLeastOnePropertyShouldBeVisibleInvalid={
                                props.isAtLeastOnePropertyShouldBeVisibleInvalid
                            }
                            onChange={handleChangeProperty}
                            onDelete={handleDeleteProperty}
                        />

                        <PropertyButton
                            excludeCustomListParentId={
                                excludeCustomListParentId
                            }
                            index={index}
                            isDisabled={
                                disabled ||
                                (selectedPropertyButtonIndex !== undefined &&
                                    selectedPropertyButtonIndex !== index)
                            }
                            isBlur={
                                selectedPropertyButtonIndex !== undefined &&
                                selectedPropertyButtonIndex !== index
                            }
                            properties={propertyOptions ?? null}
                            showList={setSelectedPropertyButtonIndex}
                            invalid={props.isAtLeastOnePropertyRequiredInvalid}
                            onSelectCustomListProperty={
                                handleSelectCustomListProperty
                            }
                            onSelectBasicProperty={handleSelectBasicProperty}
                        />
                    </React.Fragment>
                ))}
            </SortableTemplateList>
        </React.Fragment>
    );
};

export default SharedPropertiesForm;
