import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IStepInstance from "models/IStepInstance";
import { IAppState } from "store/IAppState";
import StepInstanceFinalizeCard from "../StepInstanceFinalizeCard";
import { IValueLabelItem } from "common/IValueLabelItem";
import { setLastFinalizedStepInstanceId } from "store/stepInstances/actions";
import IFinalizeStepInstanceRequest from "http/requests/IFinalizeStepInstanceRequest";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";
import {
    CHECKLIST_LIST,
    STEPINSTANCES_BY_CHECKLIST_ID_KEY,
    useChecklistById,
    useUnfinalizeStepMutation,
} from "components/checklists/api/hooks";
import { useQueryClient } from "react-query";
import { convertToDate } from "common/utils/time";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { ModalTypes } from "common/components/modal-manager/api/IModalManager";
import ITableSet from "http/ITableSet";
import IDictionary from "common/viewModels/IDictionary";
import IActivityInstance from "models/IActivityInstance";
import UnfinalizeConfirmationModal from "common/components/unfinalize-confirmation-modal/UnfinalizeConfirmationModal";
import { scrollToTopErrorById } from "components/common/validation/ScrollToError";
import { useFinalizeStepInstanceMutation } from "../hooks/hooks";
import { toast } from "react-toastify";

interface IOwnProps {
    stepInstance: IStepInstance;

    checklistStatus: ChecklistDynamicStatus;
    activityInstanceSetIdsByStepInstanceId?: string[];
    activityInstancesIdsByActivityInstanceSetId?: IDictionary<string[]>;
    activityInstances?: ITableSet<IActivityInstance>;

    onFinalizeInvalidActivityInstances: (invalidIds: string[]) => void;
}

interface IStateProps {
    tryFinalizedStepInstance: boolean;
}

interface IDispatchProps {
    setLastFinalizedStepInstanceId: (stepInstanceId: string) => void;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

const StepInstanceFinalizeCardById: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const {
        stepInstance,
        checklistStatus,
        tryFinalizedStepInstance,
        activityInstanceSetIdsByStepInstanceId,
        activityInstancesIdsByActivityInstanceSetId,
        activityInstances,
    } = props;

    if (stepInstance === undefined) {
        return null;
    }
    const queryClient = useQueryClient();

    const { t } = useTranslation();

    const modalManagerContext = useContext(ModalManagerContext);

    const callId = `${ModalTypes.checklist}_${stepInstance.workflowRunId}`;

    const checkIfAdmin =
        modalManagerContext.state.modalList[callId]?.options?.showAll ?? false;

    const { refetch: refetchChecklistById } = useChecklistById(
        false,
        checkIfAdmin,
        stepInstance.workflowRunId,
    );

    const unfinalizeMutation = useUnfinalizeStepMutation();
    const finalizeMutation = useFinalizeStepInstanceMutation();

    useEffect(() => {
        if (tryFinalizedStepInstance) {
            handleOnFinalizeClick(false);
        }
    }, [tryFinalizedStepInstance]);

    const isFinalized = stepInstance.status === StepInstanceStatus.Finalized;

    const [hasResponse, setHasResponse] = useState(false);
    const [needsConfirmation, setNeedsConfirmation] = useState(false);
    const [isAnyArchivedOrDeleted, setIsAnyArchivedOrDeleted] = useState(false);
    const [requiredActivities, setRequiredActivities] =
        useState<Array<IValueLabelItem<number, string, number>>>();
    const [unfinalizeMessage, setUnfinalizeMessage] = useState<string>("");
    const [showUnfinalizeModal, setShowUnfinalizeModal] =
        useState<boolean>(false);

    const calculateRequiredActivities = (
        invalidActivityInstanceIds: string[],
    ) => {
        const result = activityInstanceSetIdsByStepInstanceId?.reduce<
            Array<IValueLabelItem<number, string, number>>
        >((map, checklistActivityId, parentIndex) => {
            const activityInstanceIds =
                activityInstancesIdsByActivityInstanceSetId
                    ? activityInstancesIdsByActivityInstanceSetId[
                          checklistActivityId
                      ]
                    : undefined;

            if (activityInstanceIds && activityInstances) {
                const len = activityInstanceIds.length ?? 0;

                for (let i = 0; i < len; i++) {
                    const item =
                        activityInstances.values[activityInstanceIds[i]];

                    if (item && invalidActivityInstanceIds.includes(item.id)) {
                        let result: IValueLabelItem<number, string, number>;

                        if (len === 1) {
                            result = {
                                value: parentIndex + 1,
                                label: item.label,
                            };
                        } else {
                            result = {
                                value: i + 1,
                                label: item.label,
                                param: parentIndex + 1,
                            };
                        }

                        map?.push(result);
                    }
                }
            }

            return map;
        }, []);

        return result;
    };

    const handleOnChangeUnfinalizeMessage = (message: string) => {
        setUnfinalizeMessage(message);
    };

    const handleOnHideUnfinalizeModal = () => {
        setShowUnfinalizeModal(false);
    };

    const handleOnShowUnfinalizeModal = () => {
        setShowUnfinalizeModal(true);
    };

    const handleOnUnfinalize = async () => {
        if (stepInstance) {
            const data: IFinalizeStepInstanceRequest = {
                stepInstanceId: stepInstance.id,
                message: unfinalizeMessage,
                confirmed: true,
            };

            const result = await unfinalizeMutation.mutateAsync({
                request: data,
            });

            if (result.Succeeded) {
                refetchChecklistById();
                updateStepInstance(result.Data?.data ?? []);
            }
        }
        handleOnHideUnfinalizeModal();
    };

    const handleOnFinalizeClick = async (confirmed: boolean) => {
        if (stepInstance) {
            const data: IFinalizeStepInstanceRequest = {
                stepInstanceId: stepInstance.id,
                confirmed,
            };

            const result = await finalizeMutation.mutateAsync(data);

            props.onFinalizeInvalidActivityInstances(
                result.invalidActivityInstanceIds,
            );

            refetchChecklistById();

            if (result.succeeded) {
                await queryClient.invalidateQueries([CHECKLIST_LIST]);
                props.setLastFinalizedStepInstanceId(stepInstance.id);

                updateStepInstance(result.data ?? []);
            } else {
                setHasResponse(true);

                setNeedsConfirmation(result.requiresConfirmation);
                setIsAnyArchivedOrDeleted(result.isAnyArchivedOrDeleted);

                setRequiredActivities(
                    calculateRequiredActivities(
                        result.invalidActivityInstanceIds,
                    ),
                );
                scrollToTopErrorById(result.invalidActivityInstanceIds);
            }
        }
    };

    const updateStepInstance = (data: IStepInstance[]) => {
        for (const value of data) {
            queryClient.setQueryData<ITableSet<IStepInstance>>(
                [STEPINSTANCES_BY_CHECKLIST_ID_KEY, stepInstance.workflowRunId],

                (old) =>
                    old
                        ? {
                              ids: old.ids,
                              values: {
                                  ...old.values,
                                  [value.id]: {
                                      ...value,
                                      completedAt: convertToDate(
                                          value.completedAt,
                                      ),
                                  },
                              },
                          }
                        : {
                              ids: [],
                              values: {},
                          },
            );
        }
    };

    const handleCancelClick = () => {
        setHasResponse(false);
    };

    return (
        <div className={isFinalized ? undefined : "no-print"}>
            <UnfinalizeConfirmationModal
                showModal={showUnfinalizeModal}
                message={unfinalizeMessage}
                stepName={stepInstance.name}
                onHide={handleOnHideUnfinalizeModal}
                onConfirm={handleOnUnfinalize}
                onChangeMessage={handleOnChangeUnfinalizeMessage}
            />
            <StepInstanceFinalizeCard
                stepInstanceStatus={
                    stepInstance.status ?? StepInstanceStatus.None
                }
                checklistStatus={checklistStatus}
                finalizedBy={stepInstance.finalizedBy}
                finalizedAt={stepInstance.completedAt}
                hasResponse={hasResponse}
                needsConfirmation={needsConfirmation}
                isAnyArchivedOrDeleted={isAnyArchivedOrDeleted}
                requiredActivities={requiredActivities}
                onFinalizeClick={handleOnFinalizeClick}
                onUnfinalizeClick={handleOnShowUnfinalizeModal}
                onCancel={handleCancelClick}
            />
        </div>
    );
};

const mapToProps = (appState: IAppState, ownProps: IOwnProps): IStateProps => {
    let tryFinalizedStepInstance = false;

    if (
        appState.stepInstanceViewState.tryFinalizedStepInstanceId ===
        ownProps.stepInstance.id
    ) {
        tryFinalizedStepInstance = true;
    }

    return {
        tryFinalizedStepInstance,
    };
};

const actions = {
    setLastFinalizedStepInstanceId,
};

export default connect(mapToProps, actions)(StepInstanceFinalizeCardById);
