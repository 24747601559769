import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface IProps {
    targetId: string;
    retryDelay?: number;
}

const PortalOpener: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { targetId, retryDelay = 200, children } = props;

    const targetElementRef = useRef<HTMLElement | null>(null);

    const [isPortalReady, setIsPortalReady] = useState<boolean>(false);

    useEffect(() => {
        const tryFindTargetElement = () => {
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElementRef.current = targetElement;
                setIsPortalReady(true);
            } else {
                const timeoutId = setTimeout(tryFindTargetElement, retryDelay);
                return () => clearTimeout(timeoutId);
            }
        };

        tryFindTargetElement();
    }, [targetId, retryDelay]);

    if (isPortalReady && targetElementRef.current) {
        return createPortal(children, targetElementRef.current);
    }

    return null;
};

export default PortalOpener;
