import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import Textfield from "common/components/input-components/textfield/Textfield";
import IValidationMessages from "common/viewModels/IValidationMessages";
import InputWrapper from "common/components/input-components/InputWrapper";
import CustomListItemReferenceSelector from "./inputs/CustomListItemReferenceSelector";
import CustomListItemPhotoSelector from "./inputs/CustomListItemPhotoSelector";
import CustomListItemBarcodeSelector from "./inputs/CustomListItemBarcodeSelector";
import CustomListItemDropdownSelector from "./inputs/CustomListItemDropdownSelector";
import CustomListItemDateTimeSelector from "./inputs/CustomListItemDateTimeSelector";
import CustomListItemTasklistSelectorById from "./inputs/CustomListItemTasklistSelectorById";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import CustomListItemHyperlink from "./inputs/CustomListItemHyperlink";

export interface ISharedPropertyInputProps<T> {
    values: T[] | null;
    customListItemId: string | null;
    showArchivedOrDeleted: boolean | null;
    property: ICustomListPropertyTemplateValue;
    errors: IValidationMessages;
    disabled: boolean;
    preview?: boolean;
    isRequired?: boolean;
    label?: string;
    options?: IValueLabelItemWithState[];

    isLoadingOptions?: boolean;

    cannotEditAccessLimited?: boolean;
    userId?: string;

    setErrors?: Dispatch<SetStateAction<IValidationMessages>>;
    onChange: (parentId: string, values: T[], newOptionAdded?: boolean) => void;
    onTaskAddNew?: (newTask: IValueLabelItemWithState) => void;
    onChangeEditMode?: (id: string) => void;
    onChangeLabel?: (id: string, newLabel?: string) => void;
    onDeleteTask?: (id: string) => void;
}

type ComponentType = React.FC<ISharedPropertyInputProps<string>> & {
    DateTime: React.FC<ISharedPropertyInputProps<Date>>;
};

const SharedPropertyInput: ComponentType = (props) => {
    const { t } = useTranslation();

    const { values, errors, disabled } = props;

    const { parentId, type, isRequired, label } = props.property;

    const isReference =
        type === CustomListPropertyEnum.Selection ||
        type === CustomListPropertyEnum.Customers ||
        type === CustomListPropertyEnum.Suppliers ||
        type === CustomListPropertyEnum.Items ||
        type === CustomListPropertyEnum.Users ||
        type === CustomListPropertyEnum.Positions ||
        type === CustomListPropertyEnum.Competencies;

    const isBarcode =
        type === CustomListPropertyEnum.Barcodes ||
        type === CustomListPropertyEnum.GenerateBarcode;

    const handleChangeText = (value: string) => {
        props.onChange(parentId, [value]);
    };

    return (
        <React.Fragment>
            {type === CustomListPropertyEnum.None && (
                <CustomListItemReferenceSelector {...props} />
            )}

            {type === CustomListPropertyEnum.Value && (
                <InputWrapper
                    htmlFor={`form.${parentId}`}
                    wrapperLabel={
                        <>
                            {label}

                            {isRequired && (
                                <React.Fragment>
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            )}
                        </>
                    }
                >
                    <Textfield
                        id={`form.${parentId}`}
                        value={values && values.length > 0 ? values[0] : ""}
                        invalid={Boolean(errors[parentId])}
                        placeholder={t(k.ENTER_HERE)}
                        size="large"
                        disabled={disabled}
                        onChange={handleChangeText}
                        helperText={
                            errors[parentId] && (
                                <Textfield.Error>
                                    {errors[parentId]}
                                </Textfield.Error>
                            )
                        }
                    />
                </InputWrapper>
            )}

            {type === CustomListPropertyEnum.Text && (
                <InputWrapper
                    htmlFor={`form.${parentId}`}
                    wrapperLabel={
                        <>
                            {label}
                            {isRequired && (
                                <React.Fragment>
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            )}
                        </>
                    }
                >
                    <Textfield
                        id={`form.${parentId}`}
                        value={values && values.length > 0 ? values[0] : ""}
                        invalid={Boolean(errors[parentId])}
                        placeholder={t(k.ENTER_HERE)}
                        size="large"
                        disabled={disabled}
                        onChange={handleChangeText}
                        helperText={
                            errors[parentId] && (
                                <Textfield.Error>
                                    {errors[parentId]}
                                </Textfield.Error>
                            )
                        }
                        multiline={true}
                        minRows={3}
                    />
                </InputWrapper>
            )}

            {type === CustomListPropertyEnum.Hyperlink && (
                <CustomListItemHyperlink {...props} />
            )}

            {type === CustomListPropertyEnum.Tasklist && (
                <CustomListItemTasklistSelectorById {...props} />
            )}

            {type === CustomListPropertyEnum.Photo && (
                <CustomListItemPhotoSelector {...props} />
            )}

            {isBarcode && (
                <CustomListItemBarcodeSelector
                    {...props}
                    isGenerate={type === CustomListPropertyEnum.GenerateBarcode}
                />
            )}

            {isReference && <CustomListItemDropdownSelector {...props} />}
        </React.Fragment>
    );
};

SharedPropertyInput.DateTime = CustomListItemDateTimeSelector;

export default SharedPropertyInput;
