import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { IRedirectState } from "routes/hooks";
import { IAppState } from "store/IAppState";

const GoToLoginPage: React.FC = () => {
    const location = useLocation();

    const serverAuthFailed = useSelector(
        (appState: IAppState) => appState.authViewState.serverAuthFailed,
    );

    const from: IRedirectState = {
        from: location.pathname + location.search,
    };

    return <Navigate to="/login" state={serverAuthFailed ? undefined : from} />;
};

export default GoToLoginPage;
