import "./AIButton.scss";

import React from "react";
import classnames from "classnames";
import { PiShootingStarDuotone } from "react-icons/pi";
import TooltipWrapper from "common/components/tooltip/TooltipWrapper";

interface IProps {
    className?: string;
    disabled?: boolean;
    error?: string;

    onClick?: () => void;
}

const AIButton = (props: IProps) => {
    const { error, disabled } = props;
    const className = classnames("ai-button__wrapper", props.className, {
        "ai-button--not-clickable": !props.onClick || disabled,
        "ai-button--error": !!error,
    });

    const handleClick = (e: React.UIEvent) => {
        e.stopPropagation();

        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <TooltipWrapper id={error ?? ""} message={error} showTooltip={!!error}>
            <div
                className={className}
                onClick={disabled ? undefined : handleClick}
            >
                <PiShootingStarDuotone />
            </div>
        </TooltipWrapper>
    );
};

export default AIButton;
