import "./FilterIcon.scss";

import React from "react";
import FilterSvg from "assets/filter.svg";

const FilterIcon = () => (
    <div className="filter-icon--wrapper">
        <FilterSvg />
    </div>
);

export default FilterIcon;
