import React, { useState } from "react";

import InputComponent from "../input-components/InputComponent";
import { EActivityType } from "../input-components/EActivityType";
import TaskSection from "common/components/task/TaskSection";
import {
    IInputChangeEvent,
    IInputProps,
} from "common/components/input-components/IInputProps";
import { ActivityType } from "models/enums/ActivityType";
import DeleteInputValueConfirmModal from "../input-components/DeleteInputValueConfirmModal";
import IconButton from "../buttons/icon-button/IconButton";
import TrashIcon from "../icons/icon-trash/TrashIcon";

interface IProps extends IInputProps<string> {
    className?: string;
}

const FormTask = (props: IProps) => {
    const { id, disabled, label, className } = props;

    const [confirmVisible, setConfirmVisible] = useState(false);

    const removePhoto = () => {
        if (props.onChange) {
            const e: IInputChangeEvent<string> = {
                id,
                value: "",
            };

            props.onChange(e);
        }
    };

    const handleUndo = () => {
        setConfirmVisible(true);
    };

    const handleUndoConfirmClose = (confirmValue: boolean) => {
        setConfirmVisible(false);

        if (confirmValue) {
            removePhoto();
        }
    };

    return (
        <React.Fragment>
            {confirmVisible && (
                <DeleteInputValueConfirmModal
                    type={ActivityType.Photo}
                    onClose={handleUndoConfirmClose}
                />
            )}

            <TaskSection
                className={className}
                title={<label htmlFor={id}>{label}</label>}
            >
                <InputComponent
                    {...props}
                    inputType={EActivityType.Photo}
                    hideIcon
                    disabled={disabled}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    clearControl={
                        props.preview || disabled ? undefined : (
                            <IconButton circle={true} onClick={handleUndo}>
                                <TrashIcon dotIcon={true} />
                            </IconButton>
                        )
                    }
                />
            </TaskSection>
        </React.Fragment>
    );
};

export default FormTask;
