import "./StepCard.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IStep from "models/IStep";
import { getLocaleString } from "i18n/components/GetLocaleString";

interface IProps {
    step: IStep;

    index?: number;

    activityList?: React.ReactNode;

    teamList?: React.ReactNode;
    equipmentList?: React.ReactNode;
    stepRef?: React.RefObject<HTMLDivElement>;
    isImprovement?: boolean;
}

const StepCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        step,
        index,
        activityList,

        teamList,
        equipmentList,
        isImprovement,
    } = props;
    const { t } = useTranslation();

    const title =
        isImprovement && index === 1
            ? t(k.REGISTER)
            : getLocaleString(t, step.name);
    const description = isImprovement ? undefined : step.description;

    return (
        <div
            className="step-card--wrapper"
            ref={props.stepRef}
            data-id={step.id}
        >
            <div className="step-card--header">
                <div className="step-card--header-title">
                    <h3>
                        {index}. {title}
                    </h3>
                </div>
                <div className="step-card--header-details">
                    {teamList && (
                        <div className="step-card--header-details-teams">
                            {teamList}
                        </div>
                    )}
                    {equipmentList && (
                        <div className="step-card--header-details-equipments">
                            {equipmentList}
                        </div>
                    )}
                    {description && (
                        <div className="step-card--header-details-description">
                            {description}
                        </div>
                    )}
                </div>
            </div>
            <div className="step-card--body">{activityList}</div>
        </div>
    );
};

export default StepCard;
