import "./ActionAndDate.scss";

import FormattedDate from "../formattedDate/FormattedDate";

interface IProps {
    action: string;
    date: Date;
}

const ActionAt = ({ action, date }: IProps) => {
    return (
        <div className="action-and-date">
            {action}
            <div className="action-and-date__date">
                <FormattedDate date={date} />
            </div>
        </div>
    );
};

export default ActionAt;
