import "./DateByUser.scss";

import FormattedDate from "../formattedDate/FormattedDate";
import ActionBy from "../action-by/ActionBy";
import { ReactNode } from "react";

interface IDateByUserProps {
    date: Date;
    user: ReactNode;
    title?: string;
}

const DateByUser = ({ date, user, title }: IDateByUserProps) => {
    return (
        <ActionBy
            action={<FormattedDate date={date} splitDateTime useFlexWrapper />}
            by={
                <div
                    className="date-by-user__user"
                    title={
                        title ?? (typeof user === "string" ? user : undefined)
                    }
                >
                    {user}
                </div>
            }
        />
    );
};

export default DateByUser;
