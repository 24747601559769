import "./AddSharedStep.scss";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Button from "common/components/buttons/Button";
import IStep from "models/IStep";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    publishedSteps: IStep[];

    disabledButton?: boolean;

    onAdd: (value: string) => void;
}

const AddSharedStep: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { publishedSteps } = props;
    const options = publishedSteps.map((x) => {
        const iValue = {
            value: x!.id,
            label: x!.name,
        } as IValueLabelItem<string, string>;
        return iValue;
    });
    const [value, setValue] = useState<IValueLabelItem<string, string> | null>(
        null,
    );

    const { t } = useTranslation();

    const handleChange = (e: any) => {
        if (e) {
            const newValue = e as IValueLabelItem<string, string>;
            setValue(newValue);
        } else {
            setValue(null);
        }
    };

    const handleAdd = () => {
        if (value) {
            props.onAdd(value.value);
            setValue(null);
        }
    };

    const buttonDisabled = value === null || props.disabledButton;

    return (
        <div className="add-shared-step-container">
            <SelectDropdown
                testId="add-shared-step-dropdown"
                value={value}
                isClearable
                isSearchable
                bold
                placeholder={t(k.SELECT_FROM_LIBRARY)}
                options={options}
                isDisabled={props.disabledButton}
                onChange={handleChange}
            />

            <Button
                testId="add-shared-step-btn"
                variant="success"
                className="add-shared-step-btn ml-12"
                transparent
                disabled={buttonDisabled}
                noMinWidth
                onClick={handleAdd}
            >
                {t(k.USE)}
            </Button>
        </div>
    );
};

export default AddSharedStep;
