import ModalContent from "common/components/modal-content/ModalContent";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Button from "common/components/buttons/Button";

interface IProps {
    message?: string;
    onClose: () => void;
}

const NotAvailableModal = (props: IProps) => {
    const { message } = props;
    const { t } = useTranslation();

    return (
        <ModalContent width="content" height="content" position="mid-top">
            <ModalContent.Header title={t(k.ERROR)} onClose={props.onClose} />
            <ModalContent.Body>
                {message ?? t(k.NOT_AVAILABLE)}
            </ModalContent.Body>
            <ModalContent.Footer>
                <Button onClick={props.onClose}>{t(k.CLOSE)}</Button>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default NotAvailableModal;
