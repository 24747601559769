import { FC } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Avatar from "common/components/avatar/Avatar";
import "./CompletedByUser.scss";

interface IProps {
    completedByName?: string;
}

const CompletedBy: FC<IProps> = (props) => {
    const { completedByName } = props;

    const { t } = useTranslation();

    return completedByName ? (
        <div className="completed-by">
            <span className="completed-by--label">{t(k.COMPLETED_BY)}:</span>
            <div className="completed-by--wrapper">
                <Avatar name={completedByName} className="mr-2" />

                <span className="d-flex align-items-center">
                    {completedByName}
                </span>
            </div>
        </div>
    ) : null;
};

export default CompletedBy;
