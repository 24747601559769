import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IActivityProps } from "./IActivityProps";
import ActivityPhoto from "common/components/input-components-steps/ActivityPhoto";

const ActivityPhotoField: React.FC<IActivityProps> = (props) => {
    const { typeField } = props;

    const { t } = useTranslation();

    return typeField({
        typeList: <ActivityPhoto id="" value="" disabled={true} />,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.PHOTO)}`,
    });
};

export default ActivityPhotoField;
