import "./CustomerForm.scss";

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import ICustomerRowDTO from "components/customer-page/api/ICustomerRowDTO";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Textarea from "common/components/input-components/textarea/Textarea";
import Button from "common/components/buttons/Button";
import IChanged from "common/IChanged";
import IValidationMessages from "common/viewModels/IValidationMessages";
import FormCategory from "common/components/forms/FormCategory";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { IAppState } from "store/IAppState";
import ModalContent from "common/components/modal-content/ModalContent";
import { useCustomerById, useSaveCustomerMutation } from "../api/hooks";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";

interface IProps {
    id?: string;
    haveChanges?: boolean;

    onClose: (onSave?: boolean, navigateTo?: string) => void;
    onHaveChanges: (haveChanges: boolean) => void;
}

const CustomerForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, haveChanges } = props;

    const saveMutation = useSaveCustomerMutation();

    const [item, setItem] = useState<
        IChanged<ICustomerRowDTO> & { useShipping?: boolean }
    >({
        value: {
            id: uuidv4(),
            customerId: "",
            vatNumber: "",
            businessName: "",
            country: "",
            province: "",
            address: "",
            city: "",
            postalCode: "",
            phone: "",
            website: "",
            email: "",
            notes: "",
            isArchived: false,
        },
        useShipping: false,
    });

    const haveShippingAddress =
        Boolean(item.value.shippingCountry) ||
        Boolean(item.value.shippingProvince) ||
        Boolean(item.value.shippingAddress) ||
        Boolean(item.value.shippingCity) ||
        Boolean(item.value.shippingPostalCode) ||
        Boolean(item.value.shippingPhone);

    const canEdit = useSelector((appState: IAppState) => {
        const { ADMINISTRATOR, CUSTOMERS_OWNER, CUSTOMERS_EDITOR } =
            appState.authViewState.roles;

        const canEdit = ADMINISTRATOR || CUSTOMERS_OWNER || CUSTOMERS_EDITOR;

        return canEdit;
    });

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [navigateAfterSave, setNavigateAfterSave] = useState<string>();

    const { t } = useTranslation();

    const { data: customerData, isLoading, refetch } = useCustomerById(id);

    const setData = async () => {
        if (customerData) {
            const visit =
                Boolean(customerData.shippingAddress) ??
                Boolean(customerData.shippingCity) ??
                Boolean(customerData.shippingPostalCode) ??
                Boolean(customerData.shippingPhone);

            setItem({
                value: customerData,
                useShipping: visit,
            });
        }
    };

    useEffect(() => {
        if (customerData) {
            setData();
        }
    }, [customerData]);

    useEffect(() => {
        if (navigateAfterSave && !haveChanges) {
            props.onClose(true, navigateAfterSave);
        }
    }, [haveChanges, navigateAfterSave]);

    const handleChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const name = e.id;

            setItem((prev) => ({
                ...prev,
                isChanged: true,
                value: {
                    ...prev.value,
                    [name]: e.value,
                },
            }));
            props.onHaveChanges(true);
        }
    };

    const handleSave = async () => {
        if (haveChanges || props.id === undefined) {
            const result = await saveMutation.mutateAsync({
                isCreate: props.id === undefined,
                value: item.value,
            });

            if (result.Succeeded) {
                props.onHaveChanges(false);
                const id = result.Data;
                refetch();
                setNavigateAfterSave(id ? `/customers/${id}` : "/customers");
            } else {
                setErrors(result.Messages);
                scrollToTopErrorById(
                    getIdsFromValidationMessages(result.Messages),
                );
            }
        } else if (props.id) {
            setNavigateAfterSave(`/customers/${props.id}`);
            refetch();
        }
    };

    const handleOnUseShipping = (e: IInputChangeEvent<boolean>) => {
        setItem((prev) => ({ ...prev, useShipping: e.value }));
    };

    const handleCancel = () => {
        props.onClose();
    };
    const preview = !canEdit || isLoading;

    return (
        <>
            <ModalContent.Header
                onClose={props.onClose}
                title={
                    <div className="customer-form--title">
                        {id ? `${t(k.EDIT_CUSTOMER)}` : `${t(k.NEW_CUSTOMER)}`}
                    </div>
                }
            />

            <ModalContent.Body>
                {isLoading ? (
                    <AnimatedSpinner
                        isVisible={isLoading}
                        position="center"
                        aligned="center"
                    />
                ) : (
                    <div className="customer-form">
                        <div className="customer-form--body">
                            <FormCategory title={t(k.CUSTOMER)}>
                                <TextfieldComplex
                                    hideIcon
                                    id="businessName"
                                    value={item.value.businessName ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={
                                        <React.Fragment>
                                            {t(k.BUSINESS_NAME)}
                                            &nbsp;
                                            <span className="text--danger">
                                                *
                                            </span>
                                        </React.Fragment>
                                    }
                                    invalid={Boolean(errors.businessName)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />

                                <div className="forms--category--row">
                                    <TextfieldComplex
                                        hideIcon
                                        id="vatNumber"
                                        value={item.value.vatNumber ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.VAT_NUMBER)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="customerId"
                                        value={item.value.customerId ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.CUSTOMER_ID)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                </div>
                            </FormCategory>

                            <FormCategory
                                title={
                                    <>
                                        {item.useShipping
                                            ? t(k.VISIT)
                                            : t(k.ADDRESS)}{" "}
                                        <Checkbox<boolean>
                                            wrapperClassName="customer-form--use-visit"
                                            label={t(k.USE_SHIPPING_ADDRESS)}
                                            value={item.useShipping ?? false}
                                            disabled={haveShippingAddress}
                                            onChange={handleOnUseShipping}
                                            preview={preview}
                                        />
                                    </>
                                }
                            >
                                <TextfieldComplex
                                    hideIcon
                                    id="country"
                                    value={item.value.country ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.COUNTRY)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <TextfieldComplex
                                    hideIcon
                                    id="province"
                                    value={item.value.province ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.STATE_OR_PROVINCE)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <TextfieldComplex
                                    hideIcon
                                    id="address"
                                    value={item.value.address ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={
                                        item.useShipping
                                            ? t(k.ADDRESS_VISIT)
                                            : t(k.ADDRESS_SHIP_VISIT)
                                    }
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <div className="forms--category--row">
                                    <TextfieldComplex
                                        hideIcon
                                        id="postalCode"
                                        value={item.value.postalCode ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.POSTAL_CODE)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="city"
                                        value={item.value.city ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.CITY)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="phone"
                                        value={item.value.phone ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.PHONE)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                </div>
                            </FormCategory>

                            {item.useShipping && (
                                <FormCategory title={t(k.SHIPPING)}>
                                    <TextfieldComplex
                                        hideIcon
                                        id="shippingCountry"
                                        value={item.value.shippingCountry ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(
                                            k.SHIPPING_COUNTRY,
                                        )}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="shippingProvince"
                                        value={
                                            item.value.shippingProvince ?? ""
                                        }
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(
                                            k.SHIPPING_STATE_OR_PROVINCE,
                                        )}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="shippingAddress"
                                        value={item.value.shippingAddress ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.ADDRESS_SHIP)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <div className="forms--category--row">
                                        <TextfieldComplex
                                            hideIcon
                                            id="shippingPostalCode"
                                            value={
                                                item.value.shippingPostalCode ??
                                                ""
                                            }
                                            placeholder={t(k.ENTER_HERE)}
                                            size="large"
                                            bold
                                            innerLabelContent={t(k.POSTAL_CODE)}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            preview={preview}
                                        />
                                        <TextfieldComplex
                                            hideIcon
                                            id="shippingCity"
                                            value={
                                                item.value.shippingCity ?? ""
                                            }
                                            placeholder={t(k.ENTER_HERE)}
                                            size="large"
                                            bold
                                            innerLabelContent={t(k.CITY)}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            preview={preview}
                                        />
                                        <TextfieldComplex
                                            hideIcon
                                            id="shippingPhone"
                                            value={
                                                item.value.shippingPhone ?? ""
                                            }
                                            placeholder={t(k.ENTER_HERE)}
                                            size="large"
                                            bold
                                            innerLabelContent={t(k.PHONE)}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            preview={preview}
                                        />
                                    </div>
                                </FormCategory>
                            )}

                            <FormCategory title={t(k.INVOICING)}>
                                <TextfieldComplex
                                    hideIcon
                                    id="invoiceCountry"
                                    value={item.value.invoiceCountry ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.BILLING_COUNTRY)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <TextfieldComplex
                                    hideIcon
                                    id="invoiceProvince"
                                    value={item.value.invoiceProvince ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(
                                        k.BILLING_STATE_OR_PROVINCE,
                                    )}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <TextfieldComplex
                                    hideIcon
                                    id="invoiceAddress"
                                    value={item.value.invoiceAddress ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.ADDRESS_BILING)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <div className="forms--category--row">
                                    <TextfieldComplex
                                        hideIcon
                                        id="invoicePostalCode"
                                        value={
                                            item.value.invoicePostalCode ?? ""
                                        }
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.POSTAL_CODE)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="invoiceCity"
                                        value={item.value.invoiceCity ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.CITY)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                    <TextfieldComplex
                                        hideIcon
                                        id="invoicePhone"
                                        value={item.value.invoicePhone ?? ""}
                                        placeholder={t(k.ENTER_HERE)}
                                        size="large"
                                        bold
                                        innerLabelContent={t(k.PHONE)}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        preview={preview}
                                    />
                                </div>
                                <TextfieldComplex
                                    hideIcon
                                    type="email"
                                    id="invoiceEmail"
                                    value={item.value.invoiceEmail ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={
                                        t(k.INVOICING) + " " + t(k.EMAIL)
                                    }
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                            </FormCategory>

                            <FormCategory title={t(k.OTHER)}>
                                <TextfieldComplex
                                    hideIcon
                                    type="email"
                                    id="email"
                                    value={item.value.email ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.EMAIL)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />

                                <TextfieldComplex
                                    hideIcon
                                    id="website"
                                    value={item.value.website ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    size="large"
                                    bold
                                    innerLabelContent={t(k.WEBSITE)}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                                <Textarea
                                    hideIcon
                                    id="notes"
                                    value={item.value.notes ?? ""}
                                    placeholder={t(k.ENTER_HERE)}
                                    innerLabelContent={t(k.NOTES)}
                                    bold
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    preview={preview}
                                />
                            </FormCategory>
                        </div>
                    </div>
                )}
            </ModalContent.Body>
            <ModalContent.Footer className="customer-form--footer">
                <Button
                    testId="customerCancelEditBtn"
                    variant="danger"
                    transparent
                    onClick={handleCancel}
                >
                    {t(k.CANCEL)}
                </Button>
                <Button
                    testId="customerSaveEditBtn"
                    variant="success"
                    onClick={handleSave}
                    disabled={preview || isLoading}
                >
                    {t(k.SAVE)}
                </Button>
            </ModalContent.Footer>
        </>
    );
};

export default CustomerForm;
