import "./TextfieldHint.scss";

import React from "react";
import Form from "react-bootstrap/Form";

export const TextfieldHint: React.FC<React.PropsWithChildren> = (props) => (
    <Form.Control.Feedback type="valid" className="textfield-hint">
        {props.children}
    </Form.Control.Feedback>
);
