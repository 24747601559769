import "./TagsContainer.scss";

import { PropsWithChildren } from "react";

import classNames from "classnames";

interface ITagContainerProps {
    overflow?: boolean;
    vertical?: boolean;
}

const TagsContainer = ({
    children,
    overflow,
    vertical,
}: PropsWithChildren<ITagContainerProps>) => {
    const className = classNames("table-cell__tags-container", {
        overflow: overflow,
        vertical: vertical,
    });
    return <div className={className}>{children}</div>;
};

export default TagsContainer;
