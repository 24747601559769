import "./InputComponent.scss";

import { useMemo } from "react";

import TitleWithValidation from "components/common/validation/TitleWithValidation";

export interface IInputWrapperProps {
    id?: string;
    htmlFor?: string;
    wrapperLabel?: React.ReactNode;
    wrapperLabelTestId?: string;
    labelClassName?: string;
    wrapperIcon?: React.ReactNode;
    noIconResize?: boolean;
    required?: boolean;
    noMinWidth?: boolean;
    fullWidth?: boolean;
    boldLabel?: boolean;
    wrapperInputTestId?: string;
    iconInLine?: boolean;
    className?: string;
    inputClassName?: string;
    titleErrors?: React.ReactNode;
    inputDirection?: "horizontal" | "vertical";
}

const InputWrapper: React.FC<React.PropsWithChildren<IInputWrapperProps>> = (
    props,
) => {
    const {
        id,
        htmlFor,
        wrapperLabel,
        wrapperLabelTestId,
        wrapperIcon,
        noIconResize,
        required,
        noMinWidth,
        fullWidth,
        boldLabel,
        wrapperInputTestId,
        iconInLine,
        className,
        titleErrors,
        inputDirection = "horizontal",
    } = props;

    const wrapperClassName = useMemo(() => {
        const result = ["input-component"];

        if (className) {
            result.push(className);
        }

        if (noMinWidth) {
            result.push("no-min-width");
        }

        if (fullWidth) {
            result.push("full-width");
        }

        if (boldLabel) {
            result.push("bold-label");
        }

        if (iconInLine) {
            result.push("icon-in-line");
        }

        if (inputDirection === "vertical") {
            result.push(`input--${inputDirection}`);
        }

        return result.join(" ");
    }, [className, noMinWidth, fullWidth, boldLabel, iconInLine]);

    const iconClassName = useMemo(() => {
        const result = ["input-component--icon"];

        if (noIconResize) {
            result.push("no-resize");
        }

        return result.join(" ");
    }, [noIconResize]);

    const inputClassName = useMemo(() => {
        const result = ["input-component--input"];

        if (props.inputClassName) {
            result.push(props.inputClassName);
        }

        return result.join(" ");
    }, [props.inputClassName]);

    const labelClassName = useMemo(() => {
        const result = ["input-component--label"];

        if (props.labelClassName) {
            result.push(props.labelClassName);
        }

        return result.join(" ");
    }, [props.labelClassName]);

    return (
        <div className={wrapperClassName} id={id}>
            {wrapperIcon && <div className={iconClassName}>{wrapperIcon}</div>}

            {wrapperLabel && (
                <TitleWithValidation
                    htmlFor={htmlFor}
                    testId={wrapperLabelTestId}
                    className={labelClassName}
                    title={wrapperLabel}
                    bold={boldLabel}
                    size="title-sm"
                    required={required}
                    errors={titleErrors}
                />
            )}
            <div className={inputClassName} data-testid={wrapperInputTestId}>
                {props.children}
            </div>
        </div>
    );
};

export default InputWrapper;
