import "./DeleteIcon.scss";

import React from "react";

import Icon from "./assets/delete-icon.svg";

const DeleteIcon: React.FC = (props) => {
    return <Icon className="delete-icon" />;
};

export default DeleteIcon;
