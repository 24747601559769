import axios from "axios";

import IWorkflowDTO from "../../models/dto/IWorkflowDTO";
import IWorkflowListDTO from "../../models/dto/IWorkflowListDTO";
import IWorkflowRequest from "../../http/requests/IWorkflowRequest";
import IWorkflowUpgradeResponse from "http/responses/IWorkflowUpgradeResponse";
import { IValueLabelItem } from "common/IValueLabelItem";

export class WorkflowService {
    private readonly url = "/api/template";

    public async getAll() {
        const response = await axios.get<IWorkflowListDTO>(this.url);

        return response.data;
    }

    public async getPublishedTemplateList() {
        const url = `${this.url}/published-list`;

        const response =
            await axios.get<Array<IValueLabelItem<string, string>>>(url);

        return response.data;
    }

    public async getDraftById(id: string): Promise<IWorkflowDTO> {
        const url = `${this.url}/draft/${id}`;

        const response = await axios.get<IWorkflowDTO>(url);

        return response.data;
    }

    public async add(item: IWorkflowRequest): Promise<IWorkflowDTO> {
        const response = await axios.post<IWorkflowDTO>(this.url, item);

        return response.data;
    }

    public async edit(item: IWorkflowRequest): Promise<IWorkflowDTO> {
        const response = await axios.put<IWorkflowDTO>(this.url, item);

        return response.data;
    }

    public async delete(workflowVersionId: string): Promise<void> {
        await axios.delete(`${this.url}/${workflowVersionId}`);
    }

    public async resetDraft(workflowVersionId: string): Promise<void> {
        await axios.post(`${this.url}/reset-draft/${workflowVersionId}`);
    }

    public async getUpgradeInfo(
        workflowVersionId: string,
    ): Promise<IWorkflowUpgradeResponse> {
        const url = `${this.url}/upgrade-workflows/${workflowVersionId}`;

        const response = await axios.get<IWorkflowUpgradeResponse>(url);

        return response.data;
    }
}
