import RecurringChecklistForm from "components/recurring-checklist/RecurringChecklistForm";
import { useContext } from "react";
import { ModalTypes, IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const RecurringChecklistModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, options, modalFor },
    } = props;

    const id = modalFor?.id ?? props.modalItem.id;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnPublish = () => {
        if (options && options.createNew && modalFor) {
            const extraCallId = `${modalFor.type}_${modalFor.id}`;
            modalManagerContext.onCloseModal({
                callId,
                extraById: [extraCallId, ModalTypes.select_checklist_template],
                onSave: true,
            });
        }
    };

    const handleOnChangeRecurrence = (newId: string) => {
        modalManagerContext.onChangeRecurrence({ callId, newId });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <RecurringChecklistForm
            id={id}
            createNew={options?.createNew}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
            onPublishNew={handleOnPublish}
            onChangeRecurrence={handleOnChangeRecurrence}
        />
    );
};

export default RecurringChecklistModal;
