import { FC, useContext, useEffect, useState } from "react";
import ChecklistDetailsContext from "../../../../../../checklistContext";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Button from "common/components/buttons/Button";
import IWorkflowRunRequest, {
    IChangedStepInstance,
} from "http/requests/IWorkflowRunRequest";
import { useUpdateChecklistMutation } from "components/checklists/api/hooks";

interface Props {}

const SaveButton: FC<Props> = (props) => {
    const {
        editedChecklistValues,
        setEditedChecklistValues,
        onHaveChanges,
        errors,
        setUsersAffected,
        setEditedSteps,
        checkCanSeeAll: checkIfAdmin,
        selectedChecklistId,
        editedSteps,
        setEditMode,
        checklist,
        setNumberOfRemovedSteps,
    } = useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const { mutateAsync: saveChecklistValueMutatation } =
        useUpdateChecklistMutation();

    const isDisabled = Object.keys(errors || {}).length > 0;

    const handleSaveEdit = async () => {
        if (!errors) {
            if ((editedChecklistValues || editedSteps) && selectedChecklistId) {
                const {
                    title: name,
                    startDate,
                    endDate,
                } = { ...checklist, ...editedChecklistValues };

                const newValues: IWorkflowRunRequest = {
                    id: selectedChecklistId,
                    name,
                    startDate,
                    endDate,
                    checkIfAdmin,
                    changedStepInstances: editedSteps
                        ? Object.values(editedSteps)
                        : undefined,
                };

                const removedSteps = editedSteps
                    ? Object.values(editedSteps).reduce(
                          (
                              accumulator: number,
                              value: IChangedStepInstance,
                          ) => {
                              return value.removeEditor
                                  ? accumulator + 1
                                  : accumulator;
                          },
                          0,
                      )
                    : 0;

                setNumberOfRemovedSteps(removedSteps);
                saveChecklistValueMutatation({
                    request: newValues,
                });
            }
            setEditMode(false);
            setEditedChecklistValues(undefined);
            setEditedSteps(undefined);
            setUsersAffected(undefined);
            onHaveChanges(false);
        }
    };

    return (
        <Button
            variant="success"
            size="small"
            title={errors ? t(k.INVALID_FIELDS) : undefined}
            disabled={isDisabled}
            onClick={handleSaveEdit}
        >
            {t(k.SAVE)}
        </Button>
    );
};

export default SaveButton;
