import { createTextColumn } from "react-datasheet-grid";

export const addDateTimeColumn = {
    ...createTextColumn<string | null>({ placeholder: "YYYY-MM-DD hh:mm:ss" }),
    cellClassName: ({ rowData }: { rowData: string | null }) => {
        if (rowData && isNaN(Date.parse(rowData))) {
            return "dsg-cell--cell_invalid";
        }
    },
};
