import { FC, useContext } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { Button } from "common/components/buttons";
import { ArrowLeftIcon } from "common/components/icons";

import TemplateFormContext from "../../../context/TemplateFormContext";

import Menu from "./Menu";

import ListedContent from "common/components/listed-content/ListedContent";

interface ISidePanelHeaderInputProps {}
const SidePanelHeader: FC<ISidePanelHeaderInputProps> = () => {
    const { t } = useTranslation();

    const { handleOnSave, handleOnClose, isSaving } =
        useContext(TemplateFormContext);

    const handleOnSaveDraft = () => {
        handleOnSave(true);
    };

    const handleOnSaveAndPublish = async () => {
        handleOnSave(false);
    };

    return (
        <ListedContent.SidePanel.Header>
            <Button
                testId="close-btn"
                variant="white"
                transparent
                noMinWidth
                icon={<ArrowLeftIcon className="icon-color-white" />}
                onClick={handleOnClose}
            >
                {t(k.CLOSE)}
            </Button>

            <div className="side-panel-header__buttons">
                <Button
                    testId="save-draft-btn"
                    variant="white"
                    transparent
                    noMinWidth
                    disabled={isSaving}
                    onClick={handleOnSaveDraft}
                >
                    {t(k.SAVE_DRAFT)}
                </Button>
                <Button
                    testId="save-publish-btn"
                    variant="success"
                    noMinWidth
                    onClick={handleOnSaveAndPublish}
                    disabled={isSaving}
                >
                    {t(k.PUBLISH)}
                </Button>
                <Menu />
            </div>
        </ListedContent.SidePanel.Header>
    );
};

export default SidePanelHeader;
