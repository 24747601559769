import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";
import SelectButton, {
    ISelectButtonOptions,
} from "common/components/buttons/select-button/SelectButton";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";

interface IProps {
    testId: string;
    item: IValueLabelItem<string, string | null, "user" | "team">;
    existingItems?: (string | null)[];
    pickList?: IValueLabelItem<string, string>[];

    onChange: (
        option: IValueLabelItem<string, string | null, "user" | "team">,
    ) => void;

    onRemove: (id: string) => void;
}

export const SelectPermissionRow = (props: IProps) => {
    const { testId, item, existingItems, pickList } = props;

    const { t, i18n } = useTranslation();

    const selectOptions = useMemo<ISelectButtonOptions[]>(
        () => [
            {
                id: "user",
                label: t(k.USER),
                buttonProps: { variant: "dark-blue", noMinWidth: true },
            },
            {
                id: "team",
                label: t(k.TEAM),
                buttonProps: { variant: "dark-blue", noMinWidth: true },
            },
        ],
        [i18n.language],
    );

    const handleOnSelect = (value: string, multi?: boolean | undefined) => {
        props.onChange({
            value: item.value,
            label: item.label,
            param: value as "user" | "team",
        });
    };

    const handleOnChange = (options: any) => {
        if (options) {
            const selectedValue = options as IValueLabelItem<string, string>;
            props.onChange({
                value: item.value,
                label: selectedValue.value,
                param: item.param,
            });
        }
    };

    const handleOnRemove = () => {
        props.onRemove(item.value);
    };

    const filterOption = (
        option: FilterOptionOption<any>,
        rawInput: string,
    ) => {
        if (existingItems && existingItems.includes(option.value)) {
            return false;
        }

        if (rawInput) {
            const splitRaw = rawInput.toLowerCase().split(" ");

            if (
                splitRaw.every((x) =>
                    option.label.toLowerCase().includes(x),
                ) === false
            ) {
                return false;
            }
        }

        return true;
    };

    return (
        <div
            className="p-s--select-new--item"
            data-testid={`${testId}-new-selected-${item.label}`}
        >
            <SelectButton
                buttons={selectOptions}
                selected={[item.param as string]}
                dataTestId={`${testId}-new-selected-${item.label}-type`}
                onSelect={handleOnSelect}
            />
            <SelectDropdown
                value={
                    item.label
                        ? pickList?.filter((x) => x.value === item.label)
                        : undefined
                }
                options={pickList}
                filterOption={filterOption}
                testId={`${testId}-new-selected-${item.label}-select-input`}
                onChange={handleOnChange}
                isSearchable
            />
            <IconButton
                onClick={handleOnRemove}
                testId={`${testId}-new-selected-remove-btn`}
            >
                <TrashIcon />
            </IconButton>
        </div>
    );
};
