import "./RadioButton.scss";
import React, { PropsWithChildren, useMemo } from "react";
import { IInputProps } from "../IInputProps";

export interface IRadioButtonProps {
    bold?: boolean;
    variant?: "success" | "disabled" | "third-state";
    radioSize?: "small" | "medium" | "large";
}

type Props<V> = IRadioButtonProps & IInputProps<V>;

const RadioButton = <V,>(props: PropsWithChildren<Props<V>>) => {
    const {
        id,
        name,
        radioSize,
        disabled,
        preview,
        invalid,
        label,
        value,
        parentId,
        bold,
        variant,
        wrapperClassName,
    } = props;

    const getSizeDimensions = {
        small: "14px",
        medium: "16px",
        large: "18px",
    };

    const handleChange = () => {
        if (props.onChange) {
            const isChecked = !value;

            props.onChange({
                id,
                name,
                parentId,
                value: isChecked as V,
            });
        }
    };

    const isDisabled = disabled || preview;

    const className = useMemo(() => {
        const result: string[] = ["radio-button-cn"];

        if (value) {
            result.push("checked");
        }

        if (invalid) {
            result.push("radio-button--invalid");
        }

        if (isDisabled) {
            result.push("radio-button--disabled");
        }

        if (bold) {
            result.push("radio-button--bold");
        }

        if (wrapperClassName) {
            result.push(wrapperClassName);
        }

        return result.join(" ");
    }, [value, invalid, isDisabled, bold]);

    const checkClassName = useMemo(() => {
        const result: string[] = ["radio-button-checkmark"];

        if (variant) {
            result.push(variant);
        }

        return result.join(" ");
    }, [variant]);

    const parentSizeValue = getSizeDimensions[radioSize ?? "medium"]; // Default parent size is 20px

    const parentStyle = {
        "--parent-size": parentSizeValue,
    } as React.CSSProperties;

    return (
        <label className={className}>
            <span className={checkClassName} style={parentStyle}></span>
            {label && <span className="radio-button-text">{label}</span>}
            <input
                id={id}
                type="radio"
                name={name}
                disabled={isDisabled}
                checked={value as boolean}
                onChange={handleChange}
            />
        </label>
    );
};

export default RadioButton;
