import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { useNavigate } from "react-router-dom";
import IChecklistReportFilter from "components/template-checklist-report/api/IChecklistReportFilter";
import { IAppState } from "store/IAppState";
import HeaderFilters from "./HeaderFilters";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import IChanged from "common/IChanged";
import ReportTable from "./ReportTable";
import ReportTableWrapper from "./ReportTableWrapper";
import IDictionary from "common/viewModels/IDictionary";
import { IValueLabelItem } from "common/IValueLabelItem";
import { IShowConfirmArgs, showConfirm } from "store/confirms/actions";
import DeleteMessage from "../messages/DeleteMessage";

import { IAvailableAppFeatureProperties } from "models/dto/IProfileDTO";
import {
    useOnlyStoppableChecklistsTotalCount,
    usePauseAllMutation,
    useSearchTemplateListQuery,
} from "../../api/hooks";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { ITemplateChecklistReportFilterState } from "../../api/ITemplateChecklistReportFilterState";

interface IOwnProps {
    selectedTemplateVersionId?: string;
    filterState?: ITemplateChecklistReportFilterState;
    customListItemIds?: string[];
    noRedirectOnTemplateChange?: boolean;
    enableSync?: boolean;
}

interface IStateProps {
    isAdministrator: boolean;
    appFeatures?: IAvailableAppFeatureProperties;
}

interface IDispatchProps {
    showConfirm: (
        message: React.ReactNode | IShowConfirmArgs,
    ) => Promise<boolean | undefined>;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

const getInitialStatus = (state?: ITemplateChecklistReportFilterState) => {
    let result: ChecklistDynamicStatus[] | undefined;

    if (state) {
        result = [];

        if (state.done) {
            result.push(ChecklistDynamicStatus.Finalized);
        }

        if (state.active) {
            result.push(ChecklistDynamicStatus.OnTrack);
            result.push(ChecklistDynamicStatus.Behind);
        }
    }

    return result;
};

const ChecklistsTable: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const {
        isAdministrator,
        appFeatures,
        selectedTemplateVersionId,
        customListItemIds,
        filterState,
        enableSync,
    } = props;

    const { t } = useTranslation();

    const modalManagerContext = useContext(ModalManagerContext);

    usePublishedUserOptions();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [currentFilter, setCurrentFilter] = useState<
        IChanged<IChecklistReportFilter>
    >(() => {
        const templateVersionIds = selectedTemplateVersionId
            ? [selectedTemplateVersionId]
            : undefined;

        const keywordSearch = filterState?.search?.trim();

        const initialValue: IChanged<IChecklistReportFilter> = {
            value: {
                templateVersionIds,
                keywordSearch,
                status: getInitialStatus(filterState),
                splitSearchResult: selectedTemplateVersionId ? true : false,
                customListItemIds,
            },
        };

        initialValue.isChanged =
            selectedTemplateVersionId !== undefined ||
            Boolean(keywordSearch) ||
            (initialValue.value.status?.length ?? 0) > 0;

        return initialValue;
    });

    const pauseAllMutation = usePauseAllMutation();

    const [templates, setTemplates] = useState<IDictionary<string>>();

    const shouldUseSearchTemplateList =
        (currentFilter.value.splitSearchResult ?? false) &&
        (currentFilter.value.templateVersionIds?.length ?? 0) === 0;

    const { data: searchTemplateVersionIdList } = useSearchTemplateListQuery(
        { enabled: shouldUseSearchTemplateList, syncData: enableSync },
        currentFilter.value,
    );

    const templateVersionIdList = shouldUseSearchTemplateList
        ? searchTemplateVersionIdList ?? []
        : currentFilter.value.templateVersionIds ?? [];

    const { data: totalCountToStop } = useOnlyStoppableChecklistsTotalCount(
        currentFilter.value,
        templateVersionIdList,
    );

    const handleStop = async (newFilter: IChecklistReportFilter) => {
        const confirmMsg: IShowConfirmArgs = {
            message: <DeleteMessage />,
            yesButtonVariant: "danger",
            yesLabel: (
                <React.Fragment>
                    {t(k.STOP)} ({totalCountToStop})
                </React.Fragment>
            ),
            title: t(k.STOP_MATCHING_CHECKLISTS_QUESTION),
        };

        const confirmOk = await props.showConfirm(confirmMsg);

        if (confirmOk) {
            await pauseAllMutation.mutateAsync(newFilter);

            queryClient.setQueryData(
                [
                    "useTotalChecklistsCountToPause",
                    currentFilter.value,
                    templateVersionIdList,
                ],
                undefined,
            );

            queryClient.invalidateQueries("useChecklistReportItems");
        }
    };

    const handleFetchTemplates = (
        options: Array<IValueLabelItem<string, string>>,
    ) => {
        setTemplates(
            options.reduce<typeof templates>((map, currValue) => {
                if (map) {
                    map[currValue.value] = currValue.label;
                }

                return map;
            }, {}),
        );
    };

    const handleOnBarcodeSearch = (barcode: string) => {
        setCurrentFilter((prev) => ({
            ...prev,
            isChanged: true,
            value: {
                ...prev.value,
                keywordSearch: barcode,
            },
        }));
    };

    const handleShowWorkDetails = (checklistId: string) => {
        const payload: IShowModalPayload = {
            id: checklistId,
            type: ModalTypes.checklist,
            callerAddress: location.pathname,
            options: { showAll: true },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowRecurring = (recurringId: string) => {
        const payload: IShowModalPayload = {
            id: recurringId,
            type: ModalTypes.recurrence,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowImprovements = async (checklistId: string) => {
        const payload: IShowModalPayload = {
            modalFor: { type: ModalTypes.checklist, id: checklistId },
            type: ModalTypes.multiple_improvements,
            callerAddress: location.pathname,
            options: { impFilter: { checklist: true } },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnChangeTemplates = (newValues: string[]) => {
        const selectedId = newValues.length === 1 ? newValues[0] : undefined;

        if (selectedId) {
            navigate(`/checklists-report/${selectedId}`);
        } else {
            navigate("/checklists-report");
        }
    };

    return (
        <React.Fragment>
            <HeaderFilters
                canStopMultipleChecklists={appFeatures?.stopMultipleChecklists}
                totalCountToStop={totalCountToStop}
                value={currentFilter.value}
                onChange={setCurrentFilter}
                onChangeTemplates={
                    props.noRedirectOnTemplateChange
                        ? undefined
                        : handleOnChangeTemplates
                }
                isPausingAll={pauseAllMutation.isLoading}
                onPauseAll={handleStop}
                onFetchTemplateList={handleFetchTemplates}
            />

            {currentFilter.value.splitSearchResult &&
            templateVersionIdList.length > 0 ? (
                templateVersionIdList.map((templateVersionId, index) => (
                    <ReportTableWrapper
                        key={templateVersionId}
                        templateName={templates && templates[templateVersionId]}
                    >
                        <ReportTable
                            templateVersionId={templateVersionId}
                            isAdministrator={isAdministrator}
                            filter={currentFilter}
                            syncData={enableSync}
                            reverseOrderNumber={
                                templateVersionIdList.length - index
                            }
                            onBarcodeSearch={handleOnBarcodeSearch}
                            onShowChecklistDetails={handleShowWorkDetails}
                            onShowRecurrence={handleShowRecurring}
                            onShowImprovements={handleShowImprovements}
                        />
                    </ReportTableWrapper>
                ))
            ) : (
                <ReportTable
                    isAdministrator={isAdministrator}
                    filter={currentFilter}
                    syncData={enableSync}
                    reverseOrderNumber={0}
                    onShowChecklistDetails={handleShowWorkDetails}
                    onShowRecurrence={handleShowRecurring}
                    onShowImprovements={handleShowImprovements}
                />
            )}
        </React.Fragment>
    );
};

const mapToProps = (appState: IAppState): IStateProps => {
    const { roles, profile } = appState.authViewState;

    return {
        isAdministrator: roles.ADMINISTRATOR ?? false,
        appFeatures: profile?.appFeatures,
    };
};

const actions = {
    showConfirm,
};

export default connect(mapToProps, actions)(ChecklistsTable);
