import "./ModalContent.scss";

import React from "react";
import {
    Footer,
    FooterProps,
    Header,
    HeaderProps,
    Body,
    BodyProps,
    Listed,
    ListedType,
    BackdropProps,
    Backdrop,
} from "./component";

import classNames from "classnames";

import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { usePortalLoaded } from "../portals/hooks";

interface IProps {
    id?: string;
    className?: string;
    width?: "content" | "full";
    maxWidth?: "sm" | "md" | "lg" | "xl" | "full";
    height?: "content" | "full";
    position?: "top" | "mid-top" | "center";
    isPortal?: boolean;
}

type ModalContentType = React.FC<React.PropsWithChildren<IProps>> & {
    Header: React.FC<React.PropsWithChildren<HeaderProps>>;
    Footer: React.FC<React.PropsWithChildren<FooterProps>>;
    Body: React.FC<React.PropsWithChildren<BodyProps>>;
    Backdrop: React.FC<React.PropsWithChildren<BackdropProps>>;
    Listed: ListedType;
};

const ModalContent: ModalContentType = (props) => {
    const {
        id,
        width = "full",
        maxWidth = "lg",
        height = "full",
        position = "top",
        className,
        isPortal,
    } = props;

    const connected =
        isPortal && id
            ? usePortalLoaded({ targetId: id, callerId: id + "_caller" })
            : true;

    let contentClassName = classNames(
        "modal-manager--modal-content",
        className,
        {
            ["width-" + width]: width,
            ["max-width-" + maxWidth]: maxWidth,
            ["height-" + height]: height,
            ["position-" + position]: position,
        },
    );

    return (
        <>
            {!connected && (
                <AnimatedSpinner isVisible aligned="center" position="center" />
            )}
            <div className={contentClassName} id={id}>
                {(connected || !isPortal) && props.children}
            </div>
        </>
    );
};

ModalContent.Header = Header;
ModalContent.Footer = Footer;
ModalContent.Body = Body;
ModalContent.Backdrop = Backdrop;
ModalContent.Listed = Listed;

export default ModalContent;
