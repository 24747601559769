import { Dispatch } from "redux";

import IRegisterRequest from "http/requests/IRegisterRequest";
import ITokenRequest from "http/requests/ITokenRequest";
import ExecutionResult from "../../common/viewModels/ExecutionResult";
import { IProfileDTO } from "../../models/dto/IProfileDTO";
import {
    AuthService,
    AuthenticateResultEnum,
    ILoginResponseWithProfile,
} from "./service";
import {
    AuthActionTypes,
    CHECK_LOCAL_TOKEN,
    RECEIVE_LOGIN,
    REQUEST_LOGOUT,
} from "./types";
import { LocaleId } from "AppLocale";
import ILocaleRequest from "http/requests/ILocaleRequest";
import { IMsalLoginRequest } from "components/auth/api/IMsalLoginRequest";

export const updateLocale = (localeId: LocaleId) => {
    const success = (profile?: IProfileDTO): AuthActionTypes => {
        return {
            type: CHECK_LOCAL_TOKEN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch): Promise<void> => {
        const request: ILocaleRequest = { localeId };

        const profile = new AuthService().updateLocale(request);

        if (profile) {
            dispatch(success(profile));
        }
    };
};

export const updateLocalProfileUsername = (userName: string) => {
    const success = (profile?: IProfileDTO): AuthActionTypes => {
        return {
            type: CHECK_LOCAL_TOKEN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch): Promise<void> => {
        const profile = new AuthService().updateLocalProfileUsername(userName);

        if (profile) {
            dispatch(success(profile));
        }
    };
};

export const syncProfile = () => {
    const success = (profile?: IProfileDTO): AuthActionTypes => {
        return {
            type: CHECK_LOCAL_TOKEN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch) => {
        const service = new AuthService();

        const localProfile = service.getLocalProfileAndSetJWT();

        if (localProfile) {
            try {
                const profile = await service.getProfileFromServer();

                if (
                    (profile.appFeatures?.version ?? 0) ===
                    (localProfile.appFeatures?.version ?? 0)
                ) {
                    dispatch(success(profile));
                } else {
                    dispatch(logout({ force: true }));
                }
            } catch {
                dispatch(success(undefined));
            }
        } else {
            dispatch(success(undefined));
        }
    };
};

export interface ILogoutProps {
    force?: boolean;
    serverAuthFailed?: boolean;
}

export const logout = (props?: ILogoutProps): AuthActionTypes => {
    new AuthService().logout();

    return {
        type: REQUEST_LOGOUT,
        payload: props,
    };
};

export const loginAfterRegistration = (userId: string) => {
    const success = (profile: IProfileDTO): AuthActionTypes => {
        return {
            type: RECEIVE_LOGIN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch): Promise<ExecutionResult> => {
        try {
            const rememberMe: boolean = true;

            const profile = await new AuthService().loginAfterRegistration(
                rememberMe,
                userId,
            );

            dispatch(success(profile));
        } catch (error) {
            return ExecutionResult.Failed(error);
        }

        return ExecutionResult.Success();
    };
};

export const validateEmail = (email: string) => {
    return async () => {
        try {
            await new AuthService().validateEmail(email);

            return ExecutionResult.Success();
        } catch (error) {
            return ExecutionResult.Failed(error);
        }
    };
};

export const validateCompanyName = (companyName: string) => {
    return async (): Promise<ExecutionResult<string>> => {
        try {
            const subDomain = await new AuthService().validateCompanyName(
                companyName,
            );

            return ExecutionResult.Result(subDomain);
        } catch (error) {
            return ExecutionResult.Failed(error);
        }
    };
};

export const register = (request: IRegisterRequest) => {
    return async (): Promise<ExecutionResult<string>> => {
        try {
            const newHost = await new AuthService().register(request);

            return ExecutionResult.Result(newHost);
        } catch (error) {
            return ExecutionResult.Failed(error);
        }
    };
};

export const loginNoThunk = (
    tokenRequest: ITokenRequest,
    rememberMe: boolean,
) => {
    const success = (profile: IProfileDTO): AuthActionTypes => {
        return {
            type: RECEIVE_LOGIN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch) => {
        const response = await new AuthService().login(
            tokenRequest,
            rememberMe,
        );

        const profile = response.data;

        if (profile) {
            dispatch(success(profile));
        }

        return response;
    };
};

export const loginMsalNoThunk = (
    accessToken: string,
    request: IMsalLoginRequest,
) => {
    const success = (profile: IProfileDTO): AuthActionTypes => {
        return {
            type: RECEIVE_LOGIN,
            payload: profile,
        };
    };

    return async (dispatch: Dispatch) => {
        try {
            const response = await new AuthService().msalLogin(
                accessToken,
                request,
            );

            const profile = response.data;

            if (profile) {
                dispatch(success(profile));
            }

            return response;
        } catch (error: any) {
            const status = error.response.status;

            if (status === 401) {
                const result: ILoginResponseWithProfile = {
                    status: AuthenticateResultEnum.HttpErrorCode401,
                };

                return result;
            }
        }
    };
};
