import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { IValueLabelItem } from "common/IValueLabelItem";
import IDictionary from "common/viewModels/IDictionary";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import ITeamFilter from "./ITeamFilter";
import ITeamRowDTO from "./ITeamRowDTO";
import { ITableList } from "http/ITableList";
import ITeamDTO from "./ITeamDTO";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { ITeamInUseDTO } from "./ITeamInUseDTO";

const baseUrl = "/api/team";
const LIST_QUERY_KEY = "teams-list";
const TEAMS_BY_IDS = "teams-by-ids";
const PUBLISHED_TEAMS = "teams-published";
const PROFILE_TEAMS = "profile-teams";
export const TEAMS_BY_USER_ID = "teams-by-user-id";
const TEAM_BY_ID = "team-by-id";
const TEAM_STEPS_BY_ID = "team-steps-by-id";
const TEAM_TEMPLATES_BY_ID = "team-templates-by-id";
export const USERS_BY_TEAM_ID = "users-by-team-id";

export const useTeamList = (filter: ITeamFilter) => {
    const url = `${baseUrl}/list`;

    return useQuery([LIST_QUERY_KEY, filter], async (context) => {
        const response = await axios.post<ITableList<ITeamRowDTO>>(
            url,
            filter,
            {
                signal: context.signal,
            },
        );

        return response.data;
    });
};

export const useTeamListByIds = (filter: string[]) => {
    const url = `${baseUrl}/teams-by-ids`;

    return useQuery([TEAMS_BY_IDS, filter], async (context) => {
        if (filter.length > 0) {
            const response = await axios.post<ITeamDTO[]>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        }
    });
};

export const usePublishedTeamOptions = (enabled: boolean = true) => {
    const url = `${baseUrl}/published`;

    return useQuery(
        [PUBLISHED_TEAMS],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            const result: ITableSetWithValueLabelOptions<string> = {
                ids: response.data.map((x) => x.value),
                values: response.data.reduce<IDictionary<string>>(
                    (map, val) => {
                        map[val.value] = val.label;

                        return map;
                    },
                    {},
                ),

                options: response.data,
            };

            return result;
        },
        { enabled: Boolean(enabled) },
    );
};

export const useProfileTeamsOptions = () => {
    const url = `${baseUrl}/profile-teams`;

    return useQuery([PROFILE_TEAMS], async (context) => {
        const response = await axios.get<IValueLabelItem<string, string>[]>(
            url,
            {
                signal: context.signal,
            },
        );

        return response.data;
    });
};

export const useTeamsByUserId = (userId: string) => {
    const url = `${baseUrl}/user-teams/${userId}`;

    return useQuery([TEAMS_BY_IDS, userId], async (context) => {
        const response = await axios.post<ITeamDTO[]>(url, {
            signal: context.signal,
        });

        return response.data;
    });
};

export const useUsersByTeamId = (teamId?: string, enabled?: boolean) => {
    const url = `${baseUrl}/users/${teamId}`;
    return useQuery(
        [USERS_BY_TEAM_ID, teamId],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                { signal: context.signal },
            );

            return response.data;
        },
        { enabled: Boolean(teamId) && enabled },
    );
};

export async function importTeamMutation(data: ITeamRowDTO[]) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}

export const useArchiveTeamMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (request: IArchiveRequest) => {
            const response = await axios.post<string>(
                `${baseUrl}/archive`,
                request,
            );

            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([LIST_QUERY_KEY]);
                queryClient.invalidateQueries([TEAM_BY_ID]);
            },
        },
    );
};

export const useTeamById = (id?: string) => {
    const url = `${baseUrl}/${id}`;
    return useQuery(
        [TEAM_BY_ID, id],
        async () => {
            const response = await axios.get<ITeamRowDTO>(url);

            return response.data;
        },
        {
            enabled: !!id,
        },
    );
};

export const useDeleteTeamMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { id: string }) => {
            const response = await axios.delete(`${baseUrl}/${variables.id}`);

            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([LIST_QUERY_KEY]);
                queryClient.invalidateQueries([TEAM_BY_ID]);
            },
        },
    );
};

export const useTeamDependenciesByIdMutation = () => {
    return useMutation(async (id: string) => {
        const response = await axios.get<ITeamInUseDTO>(
            `${baseUrl}/dependencies/${id}`,
        );

        return response.data;
    });
};

export const useTeamSteps = (id?: string) => {
    const url = `${baseUrl}/steps/${id}`;

    return useQuery(
        [TEAM_STEPS_BY_ID, id],
        async () => {
            const response =
                await axios.get<Array<IValueLabelItem<string, string>>>(url);

            return response.data;
        },
        {
            enabled: !!id,
        },
    );
};

export const useTeamTemplates = (id?: string) => {
    const url = `${baseUrl}/workflows/${id}`;

    return useQuery(
        [TEAM_TEMPLATES_BY_ID, id],
        async () => {
            const response =
                await axios.get<Array<IValueLabelItem<string, string>>>(url);

            return response.data;
        },
        {
            enabled: !!id,
        },
    );
};

export const useSaveTeamMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (variables: { isCreate: boolean; value: ITeamRowDTO }) => {
            const { isCreate, value } = variables;

            if (isCreate) {
                const response = await axios.post<string>(baseUrl, value);

                return response.data;
            } else {
                const response = await axios.put<string>(baseUrl, value);

                return response.data;
            }
        },
        {
            onSuccess() {
                queryClient.invalidateQueries([TEAM_BY_ID]);
            },
        },
    );
};
