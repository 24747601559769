import React from "react";
import { useLocation } from "react-router-dom";

import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import Button from "common/components/buttons/Button";

interface IProps {
    isArchived: boolean;
    customListItemId: string;
    templateVersionId?: string;

    size?: "small" | "large";
    noMinWidth?: boolean;

    className?: string;
}

const StartChecklistButton: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        isArchived,
        customListItemId,
        templateVersionId,
        className,
        size,
        noMinWidth,
    } = props;

    const location = useLocation();

    const modalManagerContext = React.useContext(ModalManagerContext);

    const handleStartChecklist = (e: React.SyntheticEvent) => {
        e.stopPropagation();

        const payload: IShowModalPayload = {
            type: ModalTypes.select_checklist_template,
            callerAddress: location.pathname,
            options: {
                sourceCustomListItemId: customListItemId,
            },
        };

        if (templateVersionId) {
            payload.id = templateVersionId;
            payload.type = ModalTypes.template;
        }

        modalManagerContext.onShowModal(payload);
    };

    return isArchived ? null : (
        <Button
            className={className}
            size={size}
            noMinWidth={noMinWidth}
            onClick={handleStartChecklist}
        >
            {props.children}
        </Button>
    );
};

export default StartChecklistButton;
