import React from "react";
import { ReactMouseOrTouchEvent } from "../useResizableColumns";

interface IsLoading {
    isLoading?: boolean;
}

interface ICanRowExpand {
    canExpandRow?: boolean;
}

interface IColumnsCount {
    columnsCount?: number;
}

interface IColumnsHeader {
    columnsHeaders?: Record<number, string | number>;
    registerColumnHeaderName?: (index: number, name: string | number) => void;
}

interface ICellWidths {
    columnWidths?: {
        [key: number | string]: { width: number; minWidth?: number };
    };
    resizingKey?: "index" | "name";
    startResizing?: (
        event: ReactMouseOrTouchEvent,
        columnKey: number | string,
    ) => void;
    stopResizing?: () => void;
    registerCellRef?: (
        cell: HTMLTableCellElement,
        key: number | string,
        minWidth?: number,
    ) => void;
}

export interface ITableContextValue
    extends IsLoading,
        ICanRowExpand,
        IColumnsCount,
        ICellWidths,
        IColumnsHeader {
    isHeaderVerticalAlign?: boolean;
}

export interface ITableRowContext extends ITableContextValue {
    isHeader?: boolean;
    isFooter?: boolean;
}

function createContext<T>() {
    return React.createContext<T | undefined>(undefined);
}

function useContext<T>(value: React.Context<T>, errorMsg?: string) {
    const result = React.useContext(value);

    if (!result) {
        throw new Error(errorMsg);
    }

    return result;
}

export const TableContext = createContext<ITableContextValue>();

export const TableRowContainerContext = createContext<ITableRowContext>();

export const TableCellContainerContext = createContext<ITableRowContext>();

export const useTableContext = () => {
    return useContext(
        TableContext,
        "This component must be used within a <Table> component",
    );
};

export const useTableRowContainerContext = () => {
    return useContext(
        TableRowContainerContext,
        "This component must be used within a <TableHeader>, <TableBody> or <TableFooter> component",
    );
};

export const useTableCellContainerContext = () => {
    return useContext(
        TableCellContainerContext,
        "This component must be used within a <TableRow> component",
    );
};
