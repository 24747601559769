import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const DeleteRecurringChecklistMessage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <p>{t(k.RECURRING_CHECKLIST_DELETE_DESCRIPTION)} </p>
            <Alert variant={"danger"}>
                <Alert.Heading>{t(k.THERE_IS_NO_UNDO)}</Alert.Heading>
                {t(k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RECURRING_CHECKLIST)}
            </Alert>
            <p>
                {t(k.DELETING_A_RECURRENCE_WILL1)}{" "}
                <strong>{t(k.PERMANENTLY_REMOVE)}</strong>{" "}
                {t(k.DELETING_A_RECURRENCE_WILL2)}:
            </p>
            <ul>
                <li>{t(k.SAVED_VALUES)}</li>
                <li>{t(k.COMMENTS)}</li>
                <li>{t(k.NAV_NODE_IMPROVEMENTS)}</li>
                <li>{t(k.UPLOADED_FILES_FOR_THE_RUN)}</li>
            </ul>
        </React.Fragment>
    );
};

export default DeleteRecurringChecklistMessage;
