import { useContext } from "react";

import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import KpiForm from "common/components/modal-manager-content/kpi-form/KpiForm";

interface IProps {
    modalItem: IModalListItem;
}

const KpiFormModal = (props: React.PropsWithoutRef<IProps>) => {
    const {
        modalItem: { id, callId, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <KpiForm
            id={id}
            isCreate={options?.createNew}
            isChanged={modalManagerContext.state.haveChangesList[callId]}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
        />
    );
};

export default KpiFormModal;
