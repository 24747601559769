import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const ArchiveWorksheetMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <p>{t(k.ARE_YOU_SURE_ARCHIVE_RUN)}</p>
            <div>
                <strong>{t(k.ARE_YOU_SURE_ARCHIVE_RUN_CONTENT)}</strong>
                <br />
                {t(k.ARE_YOU_SURE_ARCHIVE_RUN_INFO)}
            </div>
        </React.Fragment>
    );
};

export default ArchiveWorksheetMessage;
