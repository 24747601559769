import React, { useMemo } from "react";
import { useDropdownToggle } from "react-overlays";

import AccordionButton from "../../accordion-button/AccordionButton";
import DefaultMenuIcon from "./DefaultMenuIcon";

interface IProps {
    id?: string;
    testId?: string;
    disabled?: boolean;
    className?: string;

    label?: React.ReactNode;
    icon?: React.ReactNode;
}

const Toggle: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { id, testId, disabled, label, icon } = props;

    const [toggleProps, { show, toggle }] = useDropdownToggle();

    const handleToggle = (e: any) => {
        toggle(!show, e);
    };

    const className = useMemo(() => {
        const result: string[] = [];

        if (props.className) {
            result.push(props.className);
        }

        if (show) {
            result.push("toggle--active");
        }

        if (disabled) {
            result.push("toggle--disabled");
        }

        return result.join(" ");
    }, [props.className, show, disabled]);

    return (
        <div
            {...toggleProps}
            data-testid={testId}
            className={className}
            onClick={disabled ? undefined : handleToggle}
        >
            {id && <input className="hidden" type="checkbox" id={id} />}

            {label}

            {icon ? (
                icon
            ) : label ? (
                <AccordionButton isOpen={show} />
            ) : (
                <DefaultMenuIcon />
            )}
        </div>
    );
};

export default Toggle;
