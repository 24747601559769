import "./SortableList.scss";

import React from "react";
import {
    SortableContainerProps,
    SortEnd,
    SortEvent,
    SortStart,
} from "react-sortable-hoc";
import { Container, ISortableItemProps, SortableItem } from "./SortableList";

interface IProps<K> extends SortableContainerProps {
    itemIds: string[];

    listId?: K;

    className?: string;

    itemClassName?: string;

    disabled?: boolean;

    hideOrder?: boolean;

    render: (
        item: string,
        index: number,
    ) => {
        render: React.ReactNode;
        props: ISortableItemProps | undefined;
    };

    onSorted?: (params: SortEnd, listId?: K) => void;
}

const SortableListExposedItemProps = <T, K extends keyof T>(
    props: IProps<K>,
) => {
    const {
        itemIds,
        className,
        itemClassName,
        useDragHandle = false,
        disabled = false,
        hideOrder = false,
        listId,
    } = props;

    const handleOnSortStart = (params: SortStart, event: SortEvent) => {
        if (event instanceof MouseEvent) {
            document.body.classList.add("grabbing-sort-item");
        }
    };

    const handleOnSortEnd = (params: SortEnd) => {
        props.onSorted?.(params, listId);

        document.body.classList.remove("grabbing-sort-item");
    };

    return (
        <Container
            {...(props as SortableContainerProps)}
            onSortStart={handleOnSortStart}
            onSortEnd={handleOnSortEnd}
            useDragHandle={useDragHandle}
            useWindowAsScrollContainer
            className={className}
        >
            {itemIds.map((item, index) => {
                const result = props.render(item, index);

                return (
                    <SortableItem
                        key={item}
                        index={index}
                        orderNumber={hideOrder ? undefined : index + 1}
                        className={itemClassName}
                        useHandle={useDragHandle}
                        itemDisabled={disabled}
                        {...result.props}
                    >
                        {result.render}
                    </SortableItem>
                );
            })}
        </Container>
    );
};

export default SortableListExposedItemProps;
