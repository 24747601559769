import "./ImpactedProcessesCard.scss";

import React from "react";
import { TreeNode } from "react-dropdown-tree-select";
import { Trans, useTranslation } from "react-i18next";

import k from "i18n/keys";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IImpactedProcessValue } from "../api/IImpactedProcessValue";
import { ITreeListOption } from "common/ITreeListOption";
import InputWrapper from "common/components/input-components/InputWrapper";
import ProcessDropdownTree from "common/components/input-components/dropdown-tree/ProcessDropdownTree";
import { ESelectedProcessType } from "common/components/input-components/dropdown-tree/ESelectedProcessType";

interface IProps {
    errors?: IValidationMessages;

    discoveredOptions?: ITreeListOption[];
    originatedOptions?: ITreeListOption[];

    disabled?: boolean;

    onChange?: (
        name: "originated" | "discovered",
        data: IImpactedProcessValue,
    ) => void;
}

const ImpactedProcessesCard = (props: IProps) => {
    const { errors, discoveredOptions, originatedOptions, disabled } = props;

    const { t } = useTranslation();

    const handleChangeDiscovered = (currentNode: TreeNode) => {
        handleChange("discovered", currentNode);
    };

    const handleChangeOriginated = (currentNode: TreeNode) => {
        handleChange("originated", currentNode);
    };

    const handleChange = (
        name: "originated" | "discovered",
        currentNode: TreeNode,
    ) => {
        if (props.onChange) {
            if (currentNode.value) {
                const nodeType = currentNode._depth as ESelectedProcessType;

                switch (nodeType) {
                    case ESelectedProcessType.process:
                        props.onChange(name, {
                            isNotSure: false,
                            processId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.sub_process:
                        props.onChange(name, {
                            isNotSure: false,
                            subProcessId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.process_step:
                        props.onChange(name, {
                            isNotSure: false,
                            processStepId: currentNode.value,
                        });
                        break;
                }
            } else {
                props.onChange(name, {
                    isNotSure: true,
                });
            }
        }
    };

    const invalid = Boolean(
        errors?.discovered ||
            errors?.originated ||
            errors?.["impactedProcesses.discovered"] ||
            errors?.["impactedProcesses.originated"],
    );

    return (
        <>
            <div
                className={
                    invalid
                        ? "activity-instance--card impacted-processes-card impacted-processes-card__invalid"
                        : "activity-instance--card impacted-processes-card"
                }
            >
                <h6 className="activity-instance--title">
                    <strong>{t(k.CHOOSE_IMPACTED_PROCESSES)}</strong>
                </h6>

                <InputWrapper
                    id={"impactedProcesses.discovered"}
                    htmlFor="deviationDiscovered"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.DISCOVERED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    <ProcessDropdownTree
                        mode="radioSelect"
                        data={discoveredOptions ?? []}
                        disabled={disabled}
                        onChange={handleChangeDiscovered}
                    />
                </InputWrapper>

                <InputWrapper
                    id={"impactedProcesses.originated"}
                    htmlFor="deviationOriginated"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.ORIGINATED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    <ProcessDropdownTree
                        mode="radioSelect"
                        data={originatedOptions ?? []}
                        disabled={disabled}
                        onChange={handleChangeOriginated}
                    />
                </InputWrapper>
            </div>
        </>
    );
};

export default ImpactedProcessesCard;
