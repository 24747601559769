import { ESharedOptionsType } from "common/components/input-components/dropdown/api/hooks";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { ActivityType } from "models/enums/ActivityType";

export const isCheckboxListFilterType: Partial<Record<ActivityType, true>> = {
    [ActivityType.CustomList]: true,
    [ActivityType.Tasklist]: true,
    [ActivityType.Selection]: true,
    [ActivityType.Items]: true,
    [ActivityType.Suppliers]: true,
    [ActivityType.Customers]: true,
    [ActivityType.Users]: true,
    [ActivityType.Positions]: true,
    [ActivityType.Competencies]: true,
};

export const sharedOptionTypeByPropertyType: {
    [key in CustomListPropertyEnum]?: ESharedOptionsType;
} = {
    [CustomListPropertyEnum.Items]: ESharedOptionsType.item,
    [CustomListPropertyEnum.Suppliers]: ESharedOptionsType.supplier,
    [CustomListPropertyEnum.Customers]: ESharedOptionsType.customer,
    [CustomListPropertyEnum.Users]: ESharedOptionsType.user,
    [CustomListPropertyEnum.Positions]: ESharedOptionsType.position,
    [CustomListPropertyEnum.Competencies]: ESharedOptionsType.competency,
};

export const sharedOptionTypeByChecklistActivityType: {
    [key in CustomListPropertyEnum]?: ESharedOptionsType;
} = {
    [ActivityType.Items]: ESharedOptionsType.item,
    [ActivityType.Suppliers]: ESharedOptionsType.supplier,
    [ActivityType.Customers]: ESharedOptionsType.customer,
    [ActivityType.Users]: ESharedOptionsType.user,
    [ActivityType.Positions]: ESharedOptionsType.position,
    [ActivityType.Competencies]: ESharedOptionsType.competency,
};
