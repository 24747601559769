import React from "react";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";
import { IActivityProps } from "./IActivityProps";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityPositionDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<PositionIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.POSITIONS)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityPositionDropdown;
