import "./Form.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";
import k from "i18n/keys";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import ICustomerContactRowDTO from "components/customer-page/api/ICustomerContactRowDTO";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import IChanged from "common/IChanged";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import ModalContent from "common/components/modal-content/ModalContent";
import {
    useArchiveCustomerContactMutation,
    useCustomerContactById,
    useDeleteCustomerContactMutation,
    useSaveCustomerContactMutation,
} from "components/customer-page/api/hooks";
import ArchiveContactMessage from "../messages/ArchiveContactMessage";
import { showConfirmNoThunk } from "store/confirms/actions";
import DeleteContactMessage from "../messages/DeleteContactMessage";
import UnarchiveContactMessage from "../messages/UnarchiveContactMessage";

interface IProps {
    id?: string;
    customerId?: string;
    createNew?: boolean;
    haveChanges?: boolean;

    onClose: (onSave?: boolean) => void;
    onHaveChanges: (haveChanges: boolean) => void;
}

const Form: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const saveContactMutation = useSaveCustomerContactMutation();
    const deleteContactMutation = useDeleteCustomerContactMutation();
    const archiveContactMutation = useArchiveCustomerContactMutation();

    const [errors, setErrors] = useState<IValidationMessages>({});
    const [editMode, setEditMode] = useState(props.createNew);
    const canEdit = useSelector((appState: IAppState) => {
        const { ADMINISTRATOR, CUSTOMERS_EDITOR, CUSTOMERS_OWNER } =
            appState.authViewState.roles;

        const canEdit = ADMINISTRATOR || CUSTOMERS_EDITOR || CUSTOMERS_OWNER;

        return canEdit;
    });

    const {
        data: customerContactData,
        isLoading,
        refetch,
    } = useCustomerContactById(props.id);

    const [item, setItem] = useState<IChanged<ICustomerContactRowDTO>>({
        value: {
            id: uuidv4(),
            customerId: String(props.customerId),
            name: "",
            title: "",
            phone: "",
            phoneMobile: "",
            email: "",
            isPrimary: false,
            isArchived: false,
        },
    });

    useEffect(() => {
        if (customerContactData) {
            setItem({
                value: customerContactData,
            });
        }
    }, [customerContactData]);

    const handleChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const name = e.id;

            setItem((prev) => ({
                ...prev,
                isChanged: true,
                value: {
                    ...prev.value,
                    [name]: e.value,
                },
            }));
            props.onHaveChanges(true);
        }
    };
    const handleEdit = () => {
        setEditMode(true);
    };

    const handleArchive = async () => {
        if (item.value.id) {
            let confirmOk = undefined;
            if (item.value.isArchived) {
                confirmOk = await showConfirmNoThunk(
                    dispatch,
                    <UnarchiveContactMessage />,
                );
            } else {
                confirmOk = await showConfirmNoThunk(
                    dispatch,
                    <ArchiveContactMessage />,
                );
            }
            if (confirmOk) {
                await archiveContactMutation.mutateAsync({
                    request: {
                        id: item.value.id,
                        isArchive: !item.value.isArchived,
                    },
                });
            }
        }
    };

    const handleDelete = async () => {
        if (item.value.id) {
            const confirmOk = await showConfirmNoThunk(
                dispatch,
                <DeleteContactMessage />,
            );
            if (confirmOk) {
                const result = await deleteContactMutation.mutateAsync({
                    id: item.value.id,
                });

                if (result) {
                    props.onHaveChanges(false);
                    refetch();
                    props.onClose(true);
                }
            }
        }
    };

    const handleChangeIsPrimary = (e: IInputChangeEvent<boolean>) => {
        setItem((prev) => ({
            ...prev,
            isChanged: true,
            value: {
                ...prev.value,
                isPrimary: e.value,
            },
        }));
        props.onHaveChanges(true);
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleSave = async () => {
        if (props.haveChanges) {
            const result = await saveContactMutation.mutateAsync({
                isCreate: Boolean(props.createNew),
                value: item.value,
            });

            if (result.Succeeded) {
                props.onHaveChanges(false);
                props.onClose(true);
            } else {
                setErrors(result.Messages);
            }
        }
    };

    const preview = canEdit && !isLoading;

    return (
        <>
            <ModalContent.Header
                onClose={props.onClose}
                title={
                    <div className="customer-form--title">
                        {props.createNew
                            ? t(k.NEW_CUSTOMER_CONTACT)
                            : editMode
                              ? t(k.EDIT_CUSTOMER_CONTACT)
                              : t(k.CONTACT_DETAILS)}
                    </div>
                }
            />
            <ModalContent.Body className="customer-contacts-form">
                <div className="customer-contacts-form--body">
                    <div>
                        <TextfieldComplex
                            hideIcon
                            id="name"
                            value={item.value.name ?? ""}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            bold
                            innerLabelContent={
                                <React.Fragment>
                                    {t(k.NAME)}
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            }
                            invalid={Boolean(errors.name)}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div>
                        <TextfieldComplex
                            hideIcon
                            id="title"
                            value={item.value.title ?? ""}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            bold
                            innerLabelContent={t(k.TITLE)}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div>
                        <TextfieldComplex
                            hideIcon
                            id="phone"
                            value={item.value.phone ?? ""}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            bold
                            innerLabelContent={t(k.PHONE)}
                            onChange={handleChange}
                            disabled={!editMode}
                        />

                        <TextfieldComplex
                            hideIcon
                            id="phoneMobile"
                            value={item.value.phoneMobile ?? ""}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            bold
                            innerLabelContent={t(k.PHONE_MOBILE)}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div>
                        <TextfieldComplex
                            hideIcon
                            type="email"
                            id="email"
                            value={item.value.email ?? ""}
                            placeholder={t(k.ENTER_HERE)}
                            size="large"
                            bold
                            innerLabelContent={t(k.EMAIL)}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div>
                        <Checkbox
                            value={item.value.isPrimary}
                            label={t(k.IS_PRIMARY_CONTACT)}
                            bold
                            onChange={handleChangeIsPrimary}
                            disabled={!editMode}
                        />
                    </div>
                </div>
            </ModalContent.Body>
            <ModalContent.Footer className="customer-contacts-form--footer">
                <Button variant="danger" transparent onClick={handleCancel}>
                    {t(k.CANCEL)}
                </Button>

                {canEdit && (
                    <ButtonGroup>
                        {!item.value.isArchived ? (
                            <>
                                {editMode ? (
                                    <Button
                                        variant="success"
                                        onClick={handleSave}
                                        disabled={!item.isChanged}
                                        testId="save-contact-button"
                                    >
                                        {t(k.SAVE)}
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="bordered"
                                            onClick={handleArchive}
                                            disabled={!preview}
                                            testId="archive-contact-button"
                                        >
                                            {t(k.ARCHIVE)}
                                        </Button>
                                        <Button
                                            transparent
                                            onClick={handleEdit}
                                            disabled={!canEdit}
                                            testId="edit-contact-button"
                                        >
                                            {t(k.EDIT)}
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="bordered"
                                    transparent
                                    onClick={handleArchive}
                                    disabled={!preview}
                                    testId="unarchive-contact-button"
                                >
                                    {t(k.UN_ARCHIVE)}
                                </Button>

                                <Button
                                    variant="danger"
                                    onClick={handleDelete}
                                    disabled={!canEdit}
                                    testId="delete-contact-button"
                                >
                                    {t(k.DELETE)}
                                </Button>
                            </>
                        )}
                    </ButtonGroup>
                )}
            </ModalContent.Footer>
        </>
    );
};

export default Form;
