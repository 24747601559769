import React from "react";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";

import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";

import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IShowConfirmArgs, showConfirmDispatch } from "store/confirms/actions";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";

const CustomListItemPhotoSelector: React.FC<
    ISharedPropertyInputProps<string>
> = (props) => {
    const { values, errors, disabled } = props;

    const { parentId, isRequired, label } = props.property;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleChange = (e: IInputChangeEvent<string>) => {
        props.onChange(parentId, [e.value]);
    };

    const handleUndo = async () => {
        const args: IShowConfirmArgs = {
            message: t(k.ARE_YOU_SURE),
        };
        if (await showConfirmDispatch(dispatch, args)) {
            props.onChange(parentId, []);
        }
    };

    return (
        <InputComponent
            inputType={EActivityType.Photo}
            id={`form.${parentId}`}
            value={values && values.length > 0 ? values[0] : ""}
            invalid={Boolean(errors[parentId])}
            wrapperLabel={label}
            required={isRequired}
            disabled={disabled}
            clearControl={
                <IconButton circle={true} onClick={handleUndo}>
                    <TrashIcon dotIcon={true} />
                </IconButton>
            }
            errors={errors[parentId]}
            onChange={handleChange}
        />
    );
};

export default CustomListItemPhotoSelector;
