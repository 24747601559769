import { NIL as NIL_UUID } from "uuid";
import { Column } from "react-datasheet-grid";

import { SelectComponent } from "./components/SelectComponent";
import { SelectOptions } from "../api/SelectOptions";
import { IValueLabelItem } from "common/IValueLabelItem";

export const selectColumn = (
    options: SelectOptions,
): Column<IValueLabelItem | null, SelectOptions> => ({
    component: SelectComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) => {
        return rowData?.label ?? null;
    },
    cellClassName: ({ rowData }) => {
        if (rowData?.value === NIL_UUID) {
            return "dsg-cell--cell_invalid";
        }
    },
    pasteValue: ({ value }) => {
        const pastedValueToLower = value.trim().toLowerCase();

        if (pastedValueToLower === "") {
            return null;
        }

        const foundChoice = options.choices.find((choice) => {
            const choiceLabelToLower = choice.label.toLowerCase();
            const choiceParamToLower = choice.param
                ? String(choice.param).toLowerCase() ?? ""
                : "";

            if (
                choiceLabelToLower === pastedValueToLower ||
                (choiceParamToLower !== "" &&
                    choiceParamToLower === pastedValueToLower)
            ) {
                return true;
            }

            if (options.mode === "try-paste") {
                if (pastedValueToLower.startsWith(choiceLabelToLower)) {
                    return true;
                }

                const firstNumber = parseInt(choiceLabelToLower);

                if (
                    !isNaN(firstNumber) &&
                    pastedValueToLower.startsWith(String(firstNumber))
                ) {
                    return true;
                }
            }
        });

        if (foundChoice) {
            return foundChoice;
        }

        const notFoundChoice: IValueLabelItem = {
            value: NIL_UUID,
            label: value,
        };

        return notFoundChoice;
    },
});
