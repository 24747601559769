export enum EBarcodeFormats {
    EAN8 = "EAN8",
    EAN12 = "EAN12",
    EAN13 = "EAN13",
    EAN14 = "EAN14",
}
export const formatNumbers = {
    [EBarcodeFormats.EAN8]: 8,
    [EBarcodeFormats.EAN12]: 12,
    [EBarcodeFormats.EAN13]: 13,
    [EBarcodeFormats.EAN14]: 14,
};

export const ValidateBarcode = (
    value: string,
    format = [EBarcodeFormats.EAN13],
) => {
    const regEx = new RegExp(
        `^(${format.reduce((prev, cur, index) => {
            return prev + `${index > 0 ? "|" : ""}\\d{${formatNumbers[cur]}}`;
        }, "")})$`,
        "g",
    );

    if (regEx.test(value)) {
        const checkSum = value.split("").reduce((p, v, i) => {
            return i % 2 == 0 ? p + 1 * parseInt(v) : p + 3 * parseInt(v);
        }, 0);

        if (checkSum % 10 !== 0) {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
