import "./MakeToastMessage.scss";

const MakeToastMessage = (title: string, description?: string) => (
    <div className="make-toast-message">
        <div className="make-toast-message--title">{title}</div>
        {description && (
            <div className="make-toast-message--description">{description}</div>
        )}
    </div>
);

export default MakeToastMessage;
