import React from "react";

import Dropdown, { IDropdownProps } from "./Dropdown";
import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import {
    useCustomListItemOptions,
    useCustomListItemOptionsByActivityId,
    useCustomListItemOptionsByActivityInstanceId,
} from "components/custom-list-item-page/api/hooks";
import { IInputProps } from "../IInputProps";
import { IMutualCustomListPropertyDto } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { IGroupedValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

export interface ICustomListDropdownProps<V, L, P>
    extends IDropdownProps<V, L, P> {
    mutualProperty?: IMutualCustomListPropertyDto;
    showArchivedOrDeleted?: boolean;
    mode?: "activity";
}

type Props<V, L, P> = ICustomListDropdownProps<V, L, P> & IInputProps<V>;

const CustomListDropdown = <V, L, P>(props: Props<V, L, P>) => {
    const {
        isNew,
        id,
        customListId,
        customListItemId,
        mutualProperty,
        mode,
        showArchivedOrDeleted,
    } = props;

    const activityId = isNew ? id : undefined;
    const activityInstanceId = isNew ? undefined : id;

    const modalManagerContext = React.useContext(ModalManagerContext);

    const isActivityMode = mode === "activity";

    const { data: optionsByActivityId } = useCustomListItemOptionsByActivityId(
        isActivityMode ? activityId : undefined,
    );

    const { data: optionsByActivityInstanceId } =
        useCustomListItemOptionsByActivityInstanceId(
            isActivityMode ? activityInstanceId : undefined,
        );

    const customListItemOptionsResult = useCustomListItemOptions(
        isActivityMode ? undefined : customListId,
        customListItemId,
        showArchivedOrDeleted,
        mutualProperty?.isNotParent,
    );

    const handlePreviewCustomList = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.custom_list,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const options = (customListId
        ? customListItemOptionsResult.data
        : isNew
          ? optionsByActivityId
          : optionsByActivityInstanceId) as
        | IValueLabelItemWithState<any, V>[]
        | undefined as IGroupedValueLabelItem<V>[];

    const isMulti = props.isMulti ?? true;

    return (
        <Dropdown
            {...props}
            options={options}
            isMulti={isMulti}
            icon={<CustomListIcon className="customlist--icon" />}
            onPreviewSelectedOption={handlePreviewCustomList}
        />
    );
};

export default CustomListDropdown;
