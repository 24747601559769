export enum DayOfWeek {
    Sunday,
    Sun = Sunday,
    Monday,
    Mon = Monday,
    Tuesday,
    Tue = Tuesday,
    Wednesday,
    Wed = Wednesday,
    Thursday,
    Thu = Thursday,
    Friday,
    Fri = Friday,
    Saturday,
    Sat = Saturday,
}
