import "./ModalBarcodePreviewManager.scss";

import React, { useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IAppState } from "store/IAppState";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Modal from "common/components/modals/Modal";
import CopyCommand, {
    ICopyCommand,
} from "common/components/copy-command/CopyCommand";
import Barcode from "common/components/barcode/Barcode";
import { hidePreview } from "store/barcode/actions";
import { printElements } from "common/utils/printUtils";

interface IStateProps {
    show?: boolean;
    value?: string;
    title?: React.ReactNode;
    hideBarcodeLabel?: boolean;
}

interface IDispatchProps {
    hide: () => void;
}

type Props = IStateProps & IDispatchProps;

const ModalBarcodePreviewManager: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { show, value, title, hideBarcodeLabel } = props;

    const { t } = useTranslation();

    const printBarcodeRef = useRef<HTMLDivElement>(null);

    const copyRef = useRef<ICopyCommand>(null);

    const handleHide = () => {
        props.hide();
    };

    const handleCopy = (e: any) => {
        copyRef.current?.copy();

        e.target.focus();
    };

    const handlePrint = () => {
        printElements([printBarcodeRef]);
    };

    return show ? (
        <Modal show onHide={handleHide} className="barcode-print">
            <Modal.Title>{t(k.VIEW_BARCODE)}</Modal.Title>

            <Modal.Body>
                <div className="barcode-print--wrapper" ref={printBarcodeRef}>
                    {value ? (
                        <Barcode
                            value={value}
                            hideBarcodeLabel={hideBarcodeLabel}
                        />
                    ) : null}

                    {title && <p>{title}</p>}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    {value && <CopyCommand text={value} ref={copyRef} />}

                    <Button variant="bordered" onClick={handleCopy}>
                        {t(k.COPY_MESSAGE)}
                    </Button>

                    <Button variant="success" onClick={handlePrint}>
                        {t(k.PRINT)}
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    ) : null;
};

const mapStateToProps = (state: IAppState): IStateProps => {
    const { isPreviewVisible: isVisible, previewRequest: preview } =
        state.barcodeViewState;

    const { value, title, hideBarcodeLabel } = preview ?? {};

    return {
        show: isVisible,
        value,
        title,
        hideBarcodeLabel,
    };
};

const actions: IDispatchProps = {
    hide: hidePreview,
};

export default connect(mapStateToProps, actions)(ModalBarcodePreviewManager);
