import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

const DeleteDeviationMessage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div>
                <p>{t(k.DELETE_IMPROVEMENT_PERMANENTLY_MESSAGE)}</p>

                {t(k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_IMPROVEMENT)}
            </div>
        </React.Fragment>
    );
};

export default DeleteDeviationMessage;
