import { Button } from "common/components/buttons";
import "./AddFormulaElements.scss";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { EFormulaCategory } from "../../../utility/FormulaTools";

interface IProps {
    onAddElement?: (type: EFormulaCategory) => void;
}

const AddFormulaElements = (props: IProps) => {
    const { t } = useTranslation();

    const { onAddElement } = props;

    const handleAddVariable = () =>
        onAddElement && onAddElement(EFormulaCategory.Variable);
    const handleAddOperator = () =>
        onAddElement && onAddElement(EFormulaCategory.Operator);
    const handleAddStaticNumber = () =>
        onAddElement && onAddElement(EFormulaCategory.StaticNumber);
    const handleAddParenthesis = () =>
        onAddElement && onAddElement(EFormulaCategory.Parenthesis);

    return (
        <div className="add-formula-elements">
            <div className="add-formula-elements--item add-formula-elements--title">
                {t(k.ADD_ELEMENTS)}
            </div>
            <Button
                transparent
                size="small"
                className="add-formula-elements--item"
                onClick={handleAddVariable}
            >
                {t(k.VARIABLES)}
            </Button>
            <Button
                transparent
                size="small"
                className="add-formula-elements--item"
                onClick={handleAddOperator}
            >
                {t(k.OPERATORS)}
            </Button>
            <Button
                transparent
                size="small"
                className="add-formula-elements--item"
                onClick={handleAddStaticNumber}
            >
                {t(k.STATIC_NUMBERS)}
            </Button>
            <Button
                transparent
                size="small"
                className="add-formula-elements--item"
                onClick={handleAddParenthesis}
            >
                {t(k.PARENTHESES)}
            </Button>
        </div>
    );
};

export default AddFormulaElements;
