import "./ImprovementsCard.scss";
import IDeviation from "models/IDeviation";
import ImprovementImpactBadge from "common/components/badges/improvement-impact-badge/ImprovementImpactBadge";
import moment from "moment";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { convertToDate } from "common/utils/time";
import ImprovementStatusBadge from "common/components/badges/improvement-status-badge/ImprovementStatusBadge";

interface IProps {
    value: IDeviation;
    onClick: (id: string) => void;
}

const ImprovementsCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value } = props;

    const { t } = useTranslation();

    const handleOnClick = () => {
        props.onClick(value.id);
    };
    return (
        <div className="improvement-card" onClick={handleOnClick}>
            <div className="improvement-card--badges">
                <ImprovementImpactBadge impact={value.impact} />
                <ImprovementStatusBadge status={value.dynamicStatus} />
            </div>
            <div className="improvement-card--message">{value.message}</div>
            <div className="improvement-card--form">{value.formName}</div>
            <div className="improvement-card--date">
                {moment(convertToDate(value.updatedAt)).fromNow()} {t(k.BY_)}{" "}
                {value.updatedBy?.label}
            </div>
        </div>
    );
};

export default ImprovementsCard;
