import "./DefaultToast.scss";

import { CloseButtonProps, ToastOptions, toast } from "react-toastify";
import { CloseIcon } from "../icons";

export interface ToastOptionsExtended extends ToastOptions {}

export const DefaultToastOptions = (options: ToastOptions) => {
    return {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: CloseButton,
        ...options,
    };
};

interface CloseButtonPropsExtended extends CloseButtonProps {
    testId?: string;
}

const CloseButton = (props: CloseButtonPropsExtended) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.closeToast(e);
    };

    return (
        <button
            type="button"
            title="close"
            onClick={onClick}
            data-testid={props.testId ?? "toast-close-btn"}
            className="toast-close-button"
        >
            <CloseIcon />
        </button>
    );
};
