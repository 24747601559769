import k from "i18n/keys";

import {
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,
} from "date-fns";
import { useTranslation } from "react-i18next";

interface IProps {
    date?: Date | string;
}

const FormattedDueDate = (props: IProps) => {
    const { date } = props;

    const { t, i18n } = useTranslation();

    if (!date) {
        return null;
    }

    const parsedDate = typeof date === "string" ? new Date(date) : date;

    const now = new Date();
    const diffInDays = differenceInDays(parsedDate, now);
    const diffInWeeks = differenceInWeeks(parsedDate, now);
    const diffInMonths = differenceInMonths(parsedDate, now);

    let dueMessage = "";

    if (diffInDays < 0) {
        dueMessage = t(k.EXPIRED);
    } else if (diffInDays === 0) {
        dueMessage = t(k.DUE) + " " + t(k.TODAY);
    } else if (diffInDays < 7) {
        dueMessage =
            t(k.DUE_IN) + " " + diffInDays.toString() + " " + t(k.DAYS_);
    } else if (diffInDays >= 7 && diffInDays <= 60) {
        dueMessage =
            t(k.DUE_IN) + " " + diffInWeeks.toString() + " " + t(k.WEEKS_);
    } else if (diffInDays >= 60) {
        dueMessage =
            t(k.DUE_IN) + " " + diffInMonths.toString() + " " + t(k.MONTHS_);
    }

    return <span>{dueMessage}</span>;
};

export default FormattedDueDate;
