import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import { EPages } from "components/roles/api/EPages";

export const ePageToHistoryOwnerMap: { [key in EPages]: HistoryOwnerEnum } = {
    ORA: HistoryOwnerEnum.ORAPermissions,
    SA: HistoryOwnerEnum.StaffAppraisalsPermissions,
    CM: HistoryOwnerEnum.CompetencyMatrixPermissions,
    POSITION: HistoryOwnerEnum.PositionsPermissions,
    USER: HistoryOwnerEnum.UsersPermissions,
    KPI: HistoryOwnerEnum.KpiPermissions,
    CUSTOMERS: HistoryOwnerEnum.CustomersPermissions,
    IMPROVEMENT_DASHBOARD: HistoryOwnerEnum.ImprovementDashboardPermissions,
    CHECKLIST_REPORT: HistoryOwnerEnum.ChecklistReportsPermissions,
    STAKEHOLDERS: HistoryOwnerEnum.StakeholdersPermissions,
    LEGISLATIONS: HistoryOwnerEnum.LegislationsPermissions,
    IMPACT_GRADINGS: HistoryOwnerEnum.ImpactGradingsPermissions,
    CUSTOMER_REQUIREMENTS: HistoryOwnerEnum.CustomerRequirementsPermissions,
    SUPPLIERS: HistoryOwnerEnum.SuppliersPermissions,
    PURCHASE_ORDERS: HistoryOwnerEnum.PurchaseOrdersPermissions,
    ITEMS: HistoryOwnerEnum.ItemsPermissions,
    TEAMS: HistoryOwnerEnum.TeamsPermissions,
    TRAINING_PLANS: HistoryOwnerEnum.TrainingPlansPermissions,
    EQUIPMENT: HistoryOwnerEnum.EquipmentsPermissions,
    ENVIRONMENT_ASPECTS: HistoryOwnerEnum.EnvironmentalAspectsPermissions,
    COMPETENCIES: HistoryOwnerEnum.CompetenciesPermissions,
    GOALS: HistoryOwnerEnum.GoalsPermissions,
    CUSTOM_LIST: HistoryOwnerEnum.CustomListItemsPermissions,
};
