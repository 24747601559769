import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import {
    HubConnection,
    HubConnectionBuilder,
    IHttpConnectionOptions,
    IStreamResult,
    LogLevel,
} from "@microsoft/signalr";
import { isDevelopmentEnvironment } from "config";

type EventHandler = {
    eventName: string;
    handler: (...args: any[]) => void;
};

const localUrl = "http://localhost:5000";

export type SignalROptions = Omit<IHttpConnectionOptions, "accessTokenFactory">;

const getDefaultOptions = (): SignalROptions => ({
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
});

const useSignalRHub = (
    url: string,
    token?: string,
    disabled?: boolean,
    eventHandlers: EventHandler[] = [],
    options?: SignalROptions,
) => {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {
        if (disabled) {
            return;
        }
        const isDevelopment = isDevelopmentEnvironment();
        const connectionUrl = isDevelopment ? localUrl + url : url;

        const newConnection = new HubConnectionBuilder()
            .withUrl(connectionUrl, {
                ...(options ?? getDefaultOptions()),
                accessTokenFactory: () => token ?? "",
            })
            .configureLogging(LogLevel.None)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                if (isDevelopment) {
                    console.log("SignalR Connected.");
                }
                setConnection(newConnection);
            } catch (err) {
                if (isDevelopment) {
                    console.error("SignalR Connection failed: ", err);
                }
            }
        };

        startConnection();

        return () => {
            newConnection.stop().then(() => {
                if (isDevelopment) {
                    console.log("SignalR Disconnected.");
                }
            });
        };
    }, [url]);

    useEffect(() => {
        if (connection) {
            eventHandlers.forEach(({ eventName, handler }) => {
                connection.on(eventName, handler);
            });

            return () => {
                eventHandlers.forEach(({ eventName, handler }) => {
                    connection.off(eventName, handler);
                });
            };
        }
    }, [connection, eventHandlers]);

    const invoke = (methodName: string, ...args: any[]) => {
        if (connection) {
            return connection.invoke(methodName, ...args);
        }
    };

    const stream = <T = any> (methodName: string, ...args: any[]): IStreamResult<T> | undefined => {
        if (connection) {
            return connection.stream(methodName, ...args);
        }
    };

    return { connection, invoke, stream };
};

export default useSignalRHub;
