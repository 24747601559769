import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import {
    BarcodeIcon,
    CalendarIcon,
    CameraIcon,
    CustomerIcon,
    DropdownIcon,
    ItemIcon,
    LinkIcon,
    SupplierIcon,
    TaskCheckboxIcon,
    TextfieldIcon,
} from "common/components/icons";
import PropertyListItem from "./PropertyListItem";
import { IAvailableAppFeatureProperties } from "models/dto/IProfileDTO";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { IAppState } from "store/IAppState";
import { connect } from "react-redux";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";

export interface IBasicPropertyListProps {
    onSelect: (type: CustomListPropertyEnum, title: string) => void;
}

interface IStateProps {
    appFeatures?: IAvailableAppFeatureProperties;
}

type Props = IStateProps & IBasicPropertyListProps;

const BasicPropertyList: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { appFeatures } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PropertyListItem
                type={CustomListPropertyEnum.Value}
                icon={<TextfieldIcon />}
                title={t(k.ADD_TEXTFIELD)}
                subTitle="Single line of text"
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={CustomListPropertyEnum.Text}
                icon={<TextfieldIcon isMultiline />}
                title={t(k.ADD_MULTI_LINE_TEXTFIELD)}
                subTitle="Multi line of text"
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={CustomListPropertyEnum.Tasklist}
                icon={<TaskCheckboxIcon />}
                title={t(k.ADD_CHECKBOX)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={CustomListPropertyEnum.DateTime}
                icon={<CalendarIcon />}
                title={t(k.ADD_DATE_TIME)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={CustomListPropertyEnum.Photo}
                icon={<CameraIcon />}
                title={t(k.PHOTO)}
                subTitle=""
                onSelect={props.onSelect}
            />
            <PropertyListItem
                type={CustomListPropertyEnum.Selection}
                icon={<DropdownIcon />}
                title={t(k.ADD_DROPDOWN)}
                subTitle=""
                onSelect={props.onSelect}
            />

            {appFeatures?.customers && (
                <PropertyListItem
                    type={CustomListPropertyEnum.Customers}
                    icon={<CustomerIcon />}
                    title={t(k.ADD_CUSTOMER_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.suppliers && (
                <PropertyListItem
                    type={CustomListPropertyEnum.Suppliers}
                    icon={<SupplierIcon />}
                    title={t(k.ADD_SUPPLIER_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.itemCatalog && (
                <PropertyListItem
                    type={CustomListPropertyEnum.Items}
                    icon={<ItemIcon />}
                    title={t(k.ADD_ITEM_DROPDOWN)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.barcodeActivity && (
                <PropertyListItem
                    type={CustomListPropertyEnum.Barcodes}
                    icon={<BarcodeIcon />}
                    title={t(k.BARCODES)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            {appFeatures?.generateBarcodeActivity && (
                <PropertyListItem
                    type={CustomListPropertyEnum.GenerateBarcode}
                    icon={<BarcodeIcon />}
                    title={t(k.GENERATE_BARCODE)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}

            <PropertyListItem
                type={CustomListPropertyEnum.Hyperlink}
                icon={<LinkIcon />}
                title={t(k.HYPERLINK)}
                subTitle=""
                onSelect={props.onSelect}
            />

            <PropertyListItem
                type={CustomListPropertyEnum.Users}
                icon={<UserIcon />}
                title={t(k.USERS)}
                subTitle=""
                onSelect={props.onSelect}
            />

            <PropertyListItem
                type={CustomListPropertyEnum.Positions}
                icon={<PositionIcon />}
                title={t(k.POSITIONS)}
                subTitle=""
                onSelect={props.onSelect}
            />

            {appFeatures?.competencies && (
                <PropertyListItem
                    type={CustomListPropertyEnum.Competencies}
                    icon={<CompetencyIcon />}
                    title={t(k.COMPETENCIES)}
                    subTitle=""
                    onSelect={props.onSelect}
                />
            )}
        </React.Fragment>
    );
};

const mapToState = (state: IAppState): IStateProps => {
    const { profile } = state.authViewState;

    return {
        appFeatures: profile?.appFeatures,
    };
};

export default connect(mapToState)(BasicPropertyList);
