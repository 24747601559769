import { IValueLabelItem } from "common/IValueLabelItem";
import i18n from "i18next";

import k from "i18n/keys";
export enum TemplateResponsibleType {
    OriginatedInProcessOwner,
    DiscoveredInProcessOwner,
    ManagerOfReportingUser,
    User,
}

export const TemplateResponsibleTypeOrder = [
    TemplateResponsibleType.OriginatedInProcessOwner,
    TemplateResponsibleType.DiscoveredInProcessOwner,
    TemplateResponsibleType.ManagerOfReportingUser,
];

export const getTemplateResponsibleTypeOptions = (
    i18next: typeof i18n,
): IValueLabelItem<string, string, TemplateResponsibleType>[] => [
    {
        value: String(TemplateResponsibleType.OriginatedInProcessOwner),
        label: k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS,
        param: TemplateResponsibleType.OriginatedInProcessOwner,
    },
    {
        value: String(TemplateResponsibleType.DiscoveredInProcessOwner),
        label: k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS,
        param: TemplateResponsibleType.DiscoveredInProcessOwner,
    },
    {
        value: String(TemplateResponsibleType.ManagerOfReportingUser),
        label: k.REPORTING_USERS_MANAGER,
        param: TemplateResponsibleType.ManagerOfReportingUser,
    },
];
