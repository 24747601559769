import React from "react";

import Badge from "common/components/badges/Badge";
import { VariantType } from "../../../common/VariantType";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { Roles, TRoles } from "components/roles/api/Roles";

import Tooltip from "common/components/tooltip/Tooltip";
import { ERoleSource } from "components/roles/api/RoleSource";

interface IProps {
    role?: TRoles;
    info?: React.ReactNode;
    className?: string;
    type?: ERoleSource;
}

const RoleBadge: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { role, className, info, type } = props;

    const { t } = useTranslation();

    if (role === undefined) {
        return null;
    }

    const roleKey = role as keyof typeof k;

    const label = k[roleKey] ? t(k[roleKey]) : role;

    const variant: VariantType =
        role === Roles.ADMINISTRATOR ? "success" : "primary";

    if (info) {
        return (
            <Tooltip id={role} message={info}>
                <Badge
                    pill
                    variant={variant}
                    transparent={type == ERoleSource.Team}
                    className={className}
                >
                    {label}
                </Badge>
            </Tooltip>
        );
    }

    return (
        <Badge
            pill
            variant={variant}
            transparent={type == ERoleSource.Team}
            className={className}
        >
            {label}
        </Badge>
    );
};

export default RoleBadge;
