import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import {
    getImpactGradingOptions,
    ImpactGradingEnum,
} from "components/impact-grading-page/api/ImpactGradingEnum";

interface IProps extends IInputWrapperProps {
    closeMenuOnSelect?: boolean;
    selectedValues?: ImpactGradingEnum[];

    onChange: (values: ImpactGradingEnum[]) => void;
}

export const ImpactMultiselect = (props: IProps) => {
    const { closeMenuOnSelect, selectedValues } = props;

    const { t, i18n } = useTranslation();

    const allOptions = React.useMemo(
        () => getImpactGradingOptions(i18n),
        [i18n.language],
    );
    const [selectedOptions, setSelectedOptions] = React.useState<
        IValueLabelItem<string, string, ImpactGradingEnum>[]
    >([]);

    useEffect(() => {
        if (selectedValues) {
            const newSelectedOptions = allOptions?.filter((x) =>
                x.param ? selectedValues.includes(x.param) : false,
            );
            setSelectedOptions(newSelectedOptions ?? []);
        }
    }, [selectedValues]);

    const handleOnChange = (options: any) => {
        const newOptions = options as IValueLabelItem<
            string,
            string,
            ImpactGradingEnum
        >[];

        setSelectedOptions(newOptions);

        const newImpacts = newOptions.map(
            (x) => x.param,
        ) as ImpactGradingEnum[];

        props.onChange(newImpacts);
    };

    return (
        <InputWrapper
            {...props}
            wrapperLabel={props.wrapperLabel ?? t(k.IMPACT)}
        >
            <SelectDropdown
                value={selectedOptions}
                isMulti
                isSearchable
                isClearable
                placeholder={t(k.ALL)}
                options={allOptions}
                testId="form.ImpactDropdownInput"
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};
