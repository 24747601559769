import React from "react";
import { connect } from "react-redux";

import ModalImagePreview from "common/components/images/ModalImagePreview";
import { hideImage } from "store/images/actions";
import { IAppState } from "store/IAppState";

interface IStateProps {
    isVisible: boolean;
    value?: string;
    imgId?: string;
}

interface IDispatchProps {
    hidePreview: () => void;
}

type Props = IStateProps & IDispatchProps;

const ImagePreviewManager: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { isVisible, value, imgId } = props;

    return isVisible ? (
        <ModalImagePreview
            value={value}
            imgId={imgId}
            onHide={props.hidePreview}
        />
    ) : null;
};

const mapToProps = (state: IAppState): IStateProps => {
    const { isVisible, value, imgId } = state.imageViewState;

    return {
        isVisible,
        value,
        imgId,
    };
};

const actions: IDispatchProps = {
    hidePreview: hideImage,
};

export default connect(mapToProps, actions)(ImagePreviewManager);
