import "./CommentListWithEditor.scss";

import React from "react";

import CommentEditor from "./CommentEditor";
import { IPaginationSettings } from "../pagination/IPaginationSettings";
import CommentList from "./CommentList";
import IChecklistComment from "models/IChecklistComment";

interface IProps {
    userName: string;

    comments?: IChecklistComment[];

    pagination: IPaginationSettings;

    commentEditorInvalid?: boolean;

    onChange: (done: boolean, value: string) => void;

    onChangePage: (pageNumber: number) => void;
}

const CommentListWithEditor: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { userName, comments, pagination, commentEditorInvalid } = props;

    return (
        <div className="comment-list-with-editor">
            <CommentList
                comments={comments}
                pagination={pagination}
                onChangePage={props.onChangePage}
            />

            <div className="comments-add-new">
                <CommentEditor
                    invalid={commentEditorInvalid}
                    userName={userName}
                    onChange={props.onChange}
                />
            </div>
        </div>
    );
};

export default CommentListWithEditor;
