import "./PropertyList.scss";

import React from "react";
import BasicPropertiesList, {
    IBasicPropertyListProps,
} from "./BasicPropertiesList";
import CustomListPropertyList, {
    ICustomListPropertyListProps,
} from "./CustomListPropertyList";
import BasicActivityList, {
    IBasicActivityListProps,
} from "./BasicActivityList";

interface IProps {
    title: React.ReactNode;
}

type PropertyListType = React.FC<React.PropsWithChildren<IProps>> & {
    BasicProperties: React.FC<IBasicPropertyListProps>;
} & {
    BasicActivities: React.FC<IBasicActivityListProps>;
} & {
    CustomListProperties: React.FC<ICustomListPropertyListProps>;
};

const PropertyList: PropertyListType = (props) => {
    const { title } = props;

    return (
        <div className="property-list">
            <div className="property-list__title">{title}</div>
            <div className="property-list__items">{props.children}</div>
        </div>
    );
};

PropertyList.BasicActivities = BasicActivityList;
PropertyList.BasicProperties = BasicPropertiesList;
PropertyList.CustomListProperties = CustomListPropertyList;

export default PropertyList;
