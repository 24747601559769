import { useContext } from "react";

import {
    EModalCallBackTypes,
    IActiveTransactionsPayload,
    IModalListItem,
    ModalTypes,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import GoalForm from "components/goals-page/components/GoalForm";
import { ETransactionStatus } from "models/enums/TransactionStatus";

interface IProps {
    modalItem: IModalListItem<EModalCallBackTypes.Id>;
}

const GoalFormModal = (props: React.PropsWithoutRef<IProps>) => {
    const {
        modalItem: { id, callId, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    const handleOnUpdateModal = (newId: string) => {
        modalManagerContext.onUpdateModal({
            callId,
            newId,
            newType: ModalTypes.goal_form,
            onSave: true,
        });
    };

    const handleOnUpdateActiveTransactions = (
        payload: IActiveTransactionsPayload,
    ) => {
        modalManagerContext.onUpdateActiveTransactions({
            ...payload,
            callId,
        });
    };

    return (
        <GoalForm
            id={id}
            parentId={options?.parentId}
            edit={options?.edit}
            createNew={options?.createNew}
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            isSubGoal={
                !!props.modalItem.callbacks?.onSave ||
                !!props.modalItem.options?.parentId
            }
            serverFilters={options?.goalServerFilter}
            clientFilters={options?.goalClientFilter}
            onHaveChanges={handleOnHaveChanges}
            onClose={handleOnClose}
            onSave={props.modalItem.callbacks?.onSave}
            syncData={modalManagerContext.state.activeModal === callId}
            onUpdateModal={handleOnUpdateModal}
            onUpdateActiveTransactions={handleOnUpdateActiveTransactions}
        />
    );
};

export default GoalFormModal;
