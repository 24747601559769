import "./SupplierIcon.scss";

import React from "react";
import { AiFillGold } from "react-icons/ai";

const SupplierIcon: React.FC = () => {
    return <AiFillGold className="icon-supplier" />;
};

export default SupplierIcon;
