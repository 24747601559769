import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    PropsWithChildren,
} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./MultiRange.scss";

interface IProps {
    min?: number;
    max?: number;
    values: { min: number; max: number };
    stepSizeRange?: number;
    stepSizeInput?: number;
    highIsGood?: boolean;
    onChange: (value: { min?: number; max?: number }) => void;
}

const MultiRangeSlider = (props: PropsWithChildren<IProps>) => {
    const {
        min = 0,
        max = 100,
        values: { min: minVal, max: maxVal },
        highIsGood = true,
        stepSizeRange = 1,
        stepSizeInput = 0.1,
    } = props;

    const [manualMinValue, setManualMinValue] = useState<number | undefined>();
    const [manualMaxValue, setManualMaxValue] = useState<number | undefined>();

    const minValRef = useRef<HTMLInputElement>(null);
    const maxValRef = useRef<HTMLInputElement>(null);

    const rangeStartRef = useRef<HTMLDivElement>(null);
    const rangeMiddleRef = useRef<HTMLDivElement>(null);

    const leftValue = useRef<HTMLDivElement>(null);
    const rightValue = useRef<HTMLDivElement>(null);

    // Convert to percentage
    const getPercent: (value: number) => number = useCallback(
        (value: number) => ((value - min) / (max - min)) * 100,
        [min, max],
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal); // Preceding with '+' converts the value from type string to type number

        if (rangeMiddleRef.current) {
            rangeMiddleRef.current.style.left = `${minPercent - 0.5}%`;
            rangeMiddleRef.current.style.width = `${
                maxPercent - minPercent + 1
            }%`;
        }

        if (rangeStartRef.current) {
            rangeStartRef.current.style.width = `${minPercent}%`;
        }

        if (leftValue.current) {
            leftValue.current.style.left = `calc(${minPercent}% - 40px)`;
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (rangeMiddleRef.current) {
            rangeMiddleRef.current.style.width = `${
                maxPercent - minPercent + 1
            }%`;
        }

        if (rightValue.current) {
            rightValue.current.style.right = `calc(${
                100 - maxPercent
            }% - 40px)`;
        }
    }, [maxVal, getPercent]);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(+event.target.value, maxVal);

        props.onChange({ min: value });
        event.target.value = value.toString();
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(+event.target.value, minVal);

        props.onChange({ max: value });
        event.target.value = value.toString();
    };

    const handleOnEditMinValueFocus = () => {
        setManualMinValue(minVal);
    };

    const handleOnEditMaxValueFocus = () => {
        setManualMaxValue(maxVal);
    };

    const handleOnEditMinValueChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const truncated = Math.trunc(+event.target.value * 10) / 10;

        const value = Math.min(truncated, maxVal);

        setManualMinValue(value);
    };

    const handleOnEditMaxValueChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const truncated = Math.trunc(+event.target.value * 10) / 10;

        const value = Math.max(truncated, minVal);

        setManualMaxValue(value);
    };

    const handleOnEditMinValueBlur = () => {
        props.onChange({ min: manualMinValue });
        setManualMinValue(undefined);
    };

    const handleOnEditMaxValueBlur = () => {
        props.onChange({ max: manualMaxValue });
        setManualMaxValue(undefined);
    };

    const handleOnEditMinValueKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === "Enter") {
            event.currentTarget.blur();
        }
    };

    const handleOnEditMaxValueKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === "Enter") {
            event.currentTarget.blur();
        }
    };

    return (
        <div className="multi-range">
            <div className="multi-range--min-max">
                <span>{min}%</span>
                <span>{max}%</span>
            </div>
            <div className="multi-range--container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={stepSizeRange}
                    value={minVal}
                    ref={minValRef}
                    onChange={handleMinChange}
                    className="multi-range--thumb thumb--min"
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={stepSizeRange}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={handleMaxChange}
                    className="multi-range--thumb thumb--max"
                />

                <div className="multi-range--slider">
                    <div
                        className={`slider--range range--end${
                            highIsGood ? " green-track" : " red-track"
                        }`}
                    />
                    <div
                        ref={rangeStartRef}
                        className={`slider--range range--start${
                            highIsGood ? " red-track" : " green-track"
                        }`}
                    />
                    <div
                        ref={rangeMiddleRef}
                        className="slider--range range--middle"
                    />
                    <div className="value-container">
                        <div ref={leftValue} className="slider--left-value">
                            <input
                                className="slider--left-value--input"
                                type="number"
                                min={min}
                                max={max}
                                step={stepSizeInput}
                                value={manualMinValue ?? minVal}
                                onChange={handleOnEditMinValueChange}
                                onFocus={handleOnEditMinValueFocus}
                                onBlur={handleOnEditMinValueBlur}
                                onKeyDown={handleOnEditMinValueKeyDown}
                            />
                        </div>
                        <div ref={rightValue} className="slider--right-value">
                            <input
                                className="slider--right-value--input"
                                type="number"
                                min={min}
                                max={max}
                                step={stepSizeInput}
                                value={manualMaxValue ?? maxVal}
                                onChange={handleOnEditMaxValueChange}
                                onFocus={handleOnEditMaxValueFocus}
                                onBlur={handleOnEditMaxValueBlur}
                                onKeyDown={handleOnEditMaxValueKeyDown}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MultiRangeSlider.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
