import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IShowConfirmArgs } from "store/confirms/actions";

const ActivateMessage: React.FC = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {t(k.ACTIVATE_USER_LINE_1)}
            <br />
        </React.Fragment>
    );
};

const buildConfirmActivateMessage = (
    title: React.ReactNode,
    yesLabel: string,
) => {
    const result: IShowConfirmArgs = {
        message: <ActivateMessage />,
        title,
        yesButtonVariant: "dark-blue",
        yesLabel,
    };

    return result;
};

export default buildConfirmActivateMessage;
