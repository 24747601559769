import "./UserMultiselect.scss";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IMultiselectDropdownProps } from "./IMultiselectDropdownProps";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";

import { IGetUser } from "components/users/api/IGetUsersDTO";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import classnames from "classnames";

interface IOwnProps extends IMultiselectDropdownProps {
    defaultValues?: string[];
    className?: string;

    testId?: string;
    canPreviewUsers?: boolean;
}

const UserMultiselect = (props: IOwnProps) => {
    const {
        defaultValues,
        label,
        uniqueId,
        menuPlacement,
        testId,
        placeholder,
        className,
        canPreviewUsers,
        boldLabel,
    } = props;

    const { t } = useTranslation();
    const modalManagerContext = useContext(ModalManagerContext);
    const [selectedOptions, setSelectedOptions] = useState<IGetUser[]>([]);

    const { data: usersData } = useSharedOptions(
        true,
        ESharedOptionsType.user,
        defaultValues,
    );

    const onPreviewSelectedOption = (userId: string) => {
        const payload: IShowModalPayload = {
            id: userId,
            type: ModalTypes.user_form,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    useEffect(() => {
        if (defaultValues && usersData) {
            const newSelectedOptions = usersData.filter((x) => {
                return defaultValues.includes(x.value);
            });

            setSelectedOptions(newSelectedOptions);
        }
    }, [defaultValues, usersData]);

    const handleOnChange = (options: any) => {
        let newValues: Array<IValueLabelItem<string, string>> = [];

        if (options) {
            const selectedValues = options as Array<
                IValueLabelItem<string, string>
            >;

            newValues = selectedValues;
        }

        setSelectedOptions(newValues);

        props.onChange(
            newValues.map((x) => x.value),
            newValues,
        );
    };

    const controlLabel = label ?? t(k.USERS);

    const controlId = uniqueId || "form.users";

    const labelClasses = classnames("user-multiselect--label", {
        "user-multiselect--label--font-weight-400": !boldLabel,
        "user-multiselect--label--font-weight-600": boldLabel,
    });

    return (
        <div className={className ?? `user-multiselect`}>
            {controlLabel && (
                <label className={labelClasses} htmlFor={controlId}>
                    {controlLabel}
                </label>
            )}

            <SelectDropdown
                id="userMultiselectDropdown"
                inputId={controlId}
                value={selectedOptions}
                isMulti
                isSearchable
                isClearable
                placeholder={placeholder}
                options={usersData}
                closeMenuOnSelect={false}
                onChange={handleOnChange}
                menuPlacement={menuPlacement}
                testId={testId}
                onPreviewSelectedOption={
                    canPreviewUsers ? onPreviewSelectedOption : undefined
                }
            />
        </div>
    );
};

export default UserMultiselect;
