import "../ModalContent.scss";
import { PropsWithChildren } from "react";

export interface IModalContentBackdropProps {
    onClickBackdrop: () => void;
}

const ModalContentBackdrop: React.FC<
    PropsWithChildren<IModalContentBackdropProps>
> = (props) => {
    return (
        <div className="modal-manager--modal-content--backdrop--wrapper">
            <div
                className="modal-manager--modal-content--backdrop"
                onClick={props.onClickBackdrop}
            />
            {props.children}
        </div>
    );
};

export default ModalContentBackdrop;
