import React from "react";
import { createTextColumn, CellProps } from "react-datasheet-grid";
import { Column } from "react-datasheet-grid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { Button } from "common/components/buttons";
import { useGenerateBarcodeCode } from "components/common/shared-properties/api/hooks";

const column = createTextColumn<string | null>({
    /* options */
});

const defaultComponent = () => null;

function generateBarcodeComponent(props: CellProps<string | null>) {
    const { active, rowData, setRowData } = props;

    const { t } = useTranslation();

    const { refetch, isRefetching } = useGenerateBarcodeCode(false);

    const handleClick = async () => {
        const { data: barcode } = await refetch();

        if (barcode) {
            setRowData(barcode);
        }
    };

    const TextComponent = column.component ?? defaultComponent;

    return rowData ? (
        <TextComponent
            {...props}
            columnData={column.columnData ?? props.columnData}
        />
    ) : (
        <Button
            size="small"
            onClick={handleClick}
            isBusy={active && isRefetching}
        >
            {t(k.GENERATE_BARCODE)}
        </Button>
    );
}

const GenerateBarcodeComponent = React.memo(
    generateBarcodeComponent,
) as typeof generateBarcodeComponent;

export const generateBarcodeColumn = (): Column<string | null> => ({
    component: GenerateBarcodeComponent,
    deleteValue: () => null,
    copyValue: ({ rowData }) => rowData,
    pasteValue: ({ value }) => value,
    isCellEmpty: ({ rowData }) => rowData === null || rowData === undefined,
});
