import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "axios";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import { EPages } from "components/roles/api/EPages";
import ITableSet from "http/ITableSet";

import { CustomListRoleEnum } from "./CustomListRoleEnum";
import { IAssignCustomListRoleToUserOrTeamRequest } from "./IAssignCustomListRoleToUserOrTeamRequest";
import {
    IAssignRoleToUserOrTeamRequest,
    IAssignRoleToUserOrTeamRequestItem,
} from "./IAssignRoleToUserOrTeamRequest";
import { IUserAndTeamRolesByRoleIdResponse } from "./IUserAndTeamRolesByRoleIdResponse";

const baseUrl = "/api/role";

export const PERMISSION_ROLES = "permission-roles";

export const usePermissionRoles = (roles: EPages) => {
    const url = `${baseUrl}/permission-roles/${roles}`;

    return useQuery(
        [PERMISSION_ROLES, roles],
        async (context) => {
            const response = await axios.get<ITableSet<string>>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: roles !== EPages.CUSTOM_LIST },
    );
};

export const USER_TEAM_ROLES_BY_ROLE_IDS = "user-team-roles-by-role-ids";

export const useUserAndTeamRolesByRoleIds = (roleIds?: string[]) => {
    const url = `${baseUrl}/user-team-roles-by-role-ids`;

    return useQuery(
        [USER_TEAM_ROLES_BY_ROLE_IDS, roleIds],
        async (context) => {
            const notUndefinedValues = roleIds?.filter((x) => x);

            const response = await axios.post<
                IDictionary<IUserAndTeamRolesByRoleIdResponse>
            >(url, notUndefinedValues, { signal: context.signal });

            return response.data;
        },
        { enabled: roleIds !== undefined && roleIds.length > 0 },
    );
};

export const USER_TEAM_ROLES_BY_CUSTOMLIST_ID =
    "user-team-roles-by-customlist-id";

export const useUserAndTeamRolesByCustomListId = (id: string) => {
    const url = `${baseUrl}/user-team-roles-by-customlist-id/${id}`;

    return useQuery([USER_TEAM_ROLES_BY_CUSTOMLIST_ID, id], async (context) => {
        const response = await axios.get<
            Record<
                keyof typeof CustomListRoleEnum,
                IUserAndTeamRolesByRoleIdResponse
            >
        >(url, { signal: context.signal });

        return response.data;
    });
};

export const useSaveRolePermissionsMutation = () => {
    const url = `${baseUrl}/assign-role-to-user-or-team`;
    const queryClient = useQueryClient();
    return useMutation(
        async (request: Record<string, IAssignRoleToUserOrTeamRequest>) => {
            try {
                const response = await axios.put(url, request);

                return ExecutionResult.Result(response.data);
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([USER_TEAM_ROLES_BY_ROLE_IDS]);
            },
        },
    );
};

export const useAssignCustomListRolePermissionsMutation = () => {
    const queryClient = useQueryClient();
    const url = `${baseUrl}/assign-customlist-role-to-user-or-team`;

    return useMutation(
        async (request: IAssignCustomListRoleToUserOrTeamRequest) => {
            try {
                const response = await axios.put(url, request);

                return ExecutionResult.Result(response.data);
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    USER_TEAM_ROLES_BY_CUSTOMLIST_ID,
                ]);
            },
        },
    );
};
