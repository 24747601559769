import ModalContent from "common/components/modal-content/ModalContent";
import CustomerForm from "../CustomerForm";

interface IProps {
    id?: string;
    haveChanges?: boolean;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean, navigateTo?: string) => void;
}

const CustomerFormModalById: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { id, haveChanges } = props;

    return (
        <ModalContent maxWidth="md">
            <CustomerForm
                id={id}
                onClose={props.onClose}
                haveChanges={haveChanges}
                onHaveChanges={props.onHaveChanges}
            />
        </ModalContent>
    );
};

export default CustomerFormModalById;
