import { useContext } from "react";

import ImprovementsDetailsContext from "../../../../../improvementContext";

const Title = () => {
    const { improvement } = useContext(ImprovementsDetailsContext);

    return (
        <div
            className="side-panel__details__title"
            data-testid="improvement-side-panel-details--title"
        >
            {improvement?.templateName}
        </div>
    );
};

export default Title;
