import axios from "axios";

import { IProfileDTO } from "../../models/dto/IProfileDTO";
import ITokenRequest from "http/requests/ITokenRequest";
import IRegisterRequest from "http/requests/IRegisterRequest";
import ILocaleRequest from "http/requests/ILocaleRequest";
import { IMsalLoginRequest } from "components/auth/api/IMsalLoginRequest";

export enum AuthenticateResultEnum {
    IsValid,

    UserInvalidCredentials,
    UserWithEmailNotFound,
    UserIsArchived,

    // sso token
    SsoTokenTenantIdMissingOrInvalid,

    // app sso settings azure tenant id
    AppSettingsAzureTenantIdMissing,
    AppSettingsAzureTenantIdInvalid,

    // teams
    TeamsSsoTenantNotFoundOrFeatureDisabled,
    TeamsSsoInvalidSubDomain,

    // http errors
    HttpErrorCode401,
}

export interface ILoginResponseWithProfile {
    status: AuthenticateResultEnum;

    errors?: Record<string, string>;

    data?: IProfileDTO;

    email?: string;
}

export class AuthService {
    private readonly url = "/api/auth";

    public getLocalProfileAndSetJWT() {
        const profile = this.getSavedProfile();

        if (profile && profile.token) {
            this.setAxiosAuthorization(profile.token);

            return profile;
        }
    }

    public async getProfileFromServer() {
        const response = await axios.get<IProfileDTO>(`${this.url}/profile`);

        return this.saveProfile(
            response.data,
            this.isSessionSavedInLocalStorage(),
        );
    }

    public async loginAfterRegistration(rememberMe: boolean, userId: string) {
        const response = await axios.post<IProfileDTO>(
            `${this.url}/register-complete/${userId}`,
        );

        return this.tryLogin(rememberMe, response.data);
    }

    public async register(request: IRegisterRequest) {
        const response = await axios.post<string>(
            `${this.url}/register`,
            request,
        );

        return response.data;
    }

    public async validateEmail(email: string) {
        return await axios.post(`${this.url}/validate-email`, `=${email}`);
    }

    public async validateCompanyName(companyName: string) {
        const result = await axios.post<string>(
            `${this.url}/validate-company`,
            `=${companyName}`,
        );

        return result.data;
    }

    public async login(request: ITokenRequest, rememberMe: boolean) {
        delete axios.defaults.headers.common.Authorization;

        const response = await axios.post<ILoginResponseWithProfile>(
            `${this.url}/login`,
            request,
        );

        const profile = response.data.data;

        if (profile) {
            this.tryLogin(rememberMe, profile);
        }

        return response.data;
    }

    public async msalLogin(accessToken: string, request: IMsalLoginRequest) {
        delete axios.defaults.headers.common.Authorization;

        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };

        const response = await axios.post<ILoginResponseWithProfile>(
            `${this.url}/login-msal`,
            request,
            config,
        );

        const profile = response.data.data;

        if (profile) {
            this.tryLogin(true, profile);
        }

        return response.data;
    }

    public logout() {
        delete axios.defaults.headers.common.Authorization;

        sessionStorage.removeItem("profile");

        localStorage.removeItem("profile");
    }

    public updateLocale(request: ILocaleRequest) {
        axios.put(`${this.url}/locale`, request);

        const profile = this.getSavedProfile();

        if (profile) {
            profile.localeId = request.localeId;

            return this.saveProfile(
                profile,
                this.isSessionSavedInLocalStorage(),
            );
        }
    }

    public updateLocalProfileUsername(username: string) {
        const profile = this.getSavedProfile();

        if (profile) {
            profile.username = username;

            return this.saveProfile(
                profile,
                this.isSessionSavedInLocalStorage(),
            );
        }
    }

    private tryLogin(rememberMe: boolean, profile: IProfileDTO) {
        this.logout();

        return this.saveProfile(profile, rememberMe);
    }

    private isSessionSavedInLocalStorage() {
        return localStorage.getItem("profile") !== null;
    }

    private setAxiosAuthorization(token: string) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    private getSavedProfile() {
        const savedProfile =
            sessionStorage.getItem("profile") ??
            localStorage.getItem("profile");

        if (savedProfile) {
            const profile: IProfileDTO = JSON.parse(savedProfile);

            return profile;
        }
    }

    private saveProfile(profile: IProfileDTO, rememberMe: boolean) {
        if (profile && profile.token) {
            this.saveTenantId(profile.tenantId);

            this.setAxiosAuthorization(profile.token);

            const profileAsString = JSON.stringify(profile);

            sessionStorage.setItem("profile", profileAsString);

            if (rememberMe) {
                localStorage.setItem("profile", profileAsString);
            }
        }

        return profile;
    }

    private saveTenantId(tenantId: string) {
        sessionStorage.setItem("tenantId", tenantId);
    }

    public getTenantId() {
        const result = sessionStorage.getItem("tenantId");

        return result ?? undefined;
    }
}
