import "./PanelWithErrors.scss";

import React from "react";
import { MdOutlineClose } from "react-icons/md";
import IconButton from "common/components/buttons/icon-button/IconButton";

interface IProps {
    children: React.ReactNode;
    errors: React.ReactNode;
}

export interface IShowErrorPanel {
    show: () => void;
}

export const PanelWithErrors = React.forwardRef<IShowErrorPanel, IProps>(
    (props, ref) => {
        const [showErrors, setShowErrors] = React.useState(false);

        const handleHideErrors = () => {
            setShowErrors(false);
        };

        React.useImperativeHandle(ref, () => ({
            show() {
                setShowErrors(true);
            },
        }));

        return (
            <div
                className={
                    showErrors
                        ? "panel-with-errors panel-with-errors--with-errors"
                        : "panel-with-errors"
                }
            >
                <div className="content">{props.children}</div>

                {showErrors && (
                    <div className="errors">
                        <IconButton
                            className="errors--hide"
                            onClick={handleHideErrors}
                        >
                            <MdOutlineClose />
                        </IconButton>

                        {props.errors}
                    </div>
                )}
            </div>
        );
    },
);
