import "./ClearIcon.scss";

import React from "react";
import { FiX } from "react-icons/fi";

const ClearIcon: React.FC = (props) => {
    return <FiX className="icon-clear" />;
};

export default ClearIcon;
