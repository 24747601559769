import { FC } from "react";
import "./Content.scss";

export interface ContentProps {}
export interface IContentInputProps {
    contentRef?: React.RefObject<HTMLDivElement>;
    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

const Content: FC<React.PropsWithChildren<IContentInputProps>> = (props) => {
    return (
        <div
            className="listed-content__content"
            ref={props.contentRef}
            onScroll={props.onScroll}
        >
            {props.children}
        </div>
    );
};

export default Content;
