import "./DropdownMenu.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDropdownMenu } from "react-overlays";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import Button from "common/components/buttons/Button";

import { SearchIcon } from "common/components/icons";

import { IDropdownGroupOption } from "./api/IDropdownOption";

import useAutofocus from "common/utils/hooks/useAutofocus";
import { IInputChangeEvent } from "../input-components/IInputProps";
import DropdownMakeList from "./components/DropdownMakeList";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";

interface IProps {
    isReset: boolean;
    isLoading?: boolean;
    isSearchable?: boolean;
    closeOnSelect?: boolean;

    options?: IDropdownGroupOption[];

    singleSelect?: boolean;

    onChange: (id: string) => void;
}

const DropdownMenu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        isReset,
        isLoading,
        isSearchable,
        singleSelect,
        closeOnSelect = singleSelect,
    } = props;

    const { t } = useTranslation();

    const dropdownMenuOptions = useDropdownMenu({
        flip: true,
        offset: [0, 8],
    });

    const [filteredOptions, setFilteredOptions] =
        useState<IDropdownGroupOption[]>();

    const options = filteredOptions ?? props.options ?? [];

    const searchInputRef = useRef<HTMLInputElement>(null);

    useAutofocus(searchInputRef, dropdownMenuOptions[1].show);

    const [searchKeyword, setSearchKeyword] = useState("");

    const handleReset = () => {
        setSearchKeyword("");

        setFilteredOptions(undefined);
    };

    const handleOnChange = (e: IInputChangeEvent<boolean>) => {
        const { name } = e;
        if (name) {
            props.onChange(name);

            if (dropdownMenuOptions[1].toggle) {
                dropdownMenuOptions[1].toggle(!closeOnSelect, e as any);
            }
        }
    };

    const handleChangeSearchKeyword = (e: IInputActionChangeEvent<string>) => {
        const value = e.value;

        if (value) {
            setSearchKeyword(value);
        } else {
            handleReset();
        }
    };

    const filterOptions = (options: IDropdownGroupOption[]) => {
        return options.reduce((acc, x) => {
            if (x.options) {
                const filtered = filterOptions(x.options);
                if (filtered.length > 0) {
                    acc.push({
                        ...x,
                        options: filtered,
                    });
                }
            } else if (
                x.label.toLowerCase().includes(searchKeyword.toLowerCase())
            ) {
                acc.push(x);
            }

            return acc;
        }, [] as IDropdownGroupOption[]);
    };

    useEffect(() => {
        if (searchKeyword.length > 0) {
            setFilteredOptions(filterOptions(props.options ?? []));
        }
    }, [searchKeyword, props.options]);

    useEffect(() => {
        if (isReset) {
            handleReset();
        }
    }, [isReset]);

    return dropdownMenuOptions[1].show ? (
        <div {...dropdownMenuOptions[0]} className={"dropdown-menu__menu"}>
            <AnimatedSpinner isVisible={isLoading} position="center" />

            {isSearchable && (
                <ActionTextField
                    id="dropdown-menu--search"
                    className="dropdown-menu--search"
                    value={searchKeyword}
                    noMinWidth
                    placeholder={t(k.SEARCH)}
                    autoFocus
                    postInputContent={<SearchIcon />}
                    inputRef={searchInputRef}
                    onChange={handleChangeSearchKeyword}
                />
            )}

            {options.length > 0 && (
                <div className="filter-options">
                    {options.map((x) => (
                        <DropdownMakeList
                            key={x.value}
                            item={x}
                            noCheckbox={singleSelect}
                            t={t}
                            onChange={handleOnChange}
                        />
                    ))}
                </div>
            )}

            {filteredOptions !== undefined && (
                <Button variant="danger" onClick={handleReset}>
                    {t(k.RESET)}
                </Button>
            )}
        </div>
    ) : null;
};

export default DropdownMenu;
