import { useRef } from "react";
import {
    IPressHoldProps,
    ReactMouseEventOrTouchEvent,
    defaultTouchHoldDelay,
} from "./usePressHold";

interface IPressHoldIndicatorProps extends IPressHoldProps {}

const pressHoldIndicator = (props: IPressHoldIndicatorProps) => {
    const indicatorRef = useRef<HTMLElement | null>(null);

    const createIndicator = (x: number, y: number) => {
        removeIndicator();

        indicatorRef.current = document.createElement("div");

        const indicator = indicatorRef.current;

        Object.assign(indicatorRef.current.style, {
            position: "fixed",
            left: `${x}px`,
            top: `${y}px`,
            width: "30px",
            height: "30px",
            backgroundColor: "#cdced6",
            border: "2px solid #abadba",
            borderRadius: "50%",
            pointerEvents: "none",
            zIndex: "1000000",
            transform: "translate(-50%, -50%)",
            transition: `transform ${
                (props.delay ?? defaultTouchHoldDelay) - 200
            }ms, background-color 0.2s`,
        });

        indicator.style.transform += " scale(0)";
        document.body.appendChild(indicator);

        window.setTimeout(() => {
            if (indicator) {
                indicator.style.transform = "translate(-50%, -50%) scale(1)";
            }
        }, 0);
    };

    const removeIndicator = () => {
        const indicator = indicatorRef.current;

        if (indicator) {
            document.body.removeChild(indicator);
            indicatorRef.current = null;
        }
    };

    const onPressStart = (e: ReactMouseEventOrTouchEvent) => {
        const position = "touches" in e ? e.touches[0] : e;

        createIndicator(position.clientX, position.clientY);

        props.onPressStart?.(e);
    };

    const onLongPress = (e: ReactMouseEventOrTouchEvent) => {
        const indicator = indicatorRef.current;
        if (indicator) {
            Object.assign(indicator.style, {
                backgroundColor: "#93c5fd",
                border: "none",
            });

            window.setTimeout(() => {
                indicator.style.backgroundColor = "transparent";
            }, 200);
        }
        props.onLongPress?.(e);
    };

    const onPressEnd = (
        e: ReactMouseEventOrTouchEvent,
        wasLongPress?: boolean,
    ) => {
        removeIndicator();
        props.onPressEnd?.(e, wasLongPress);
    };

    const onCanceled = () => {
        removeIndicator();
        props.onCanceled?.();
    };

    return {
        ...props,
        onPressStart,
        onLongPress,
        onPressEnd,
        onCanceled,
    };
};

export default pressHoldIndicator;
