import "./Preview.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import {
    CalendarIcon,
    CameraIcon,
    CustomerIcon,
    IconBg,
    ItemIcon,
    LinkIcon,
    SupplierIcon,
    TextfieldIcon,
} from "common/components/icons";
import Textfield from "common/components/input-components/textfield/Textfield";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import Button from "common/components/buttons/Button";
import ManageBasicList from "./ManageBasicList";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IValueLabelItem } from "common/IValueLabelItem";
import UserIcon from "common/components/icons/icon-user/UserIcon";
import PositionIcon from "common/components/icons/icon-position/PositionIcon";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";

export interface IBasicPropertyPreviewProps {
    disabled: boolean;

    value: ICustomListPropertyTemplateValue;

    errors: IValidationMessages;

    onChange: (
        options: IValueLabelItem<string, string>[],
        isInitialization: boolean,
    ) => void;
}

const BasicPropertyPreview: React.FC<IBasicPropertyPreviewProps> = (props) => {
    const { value, disabled, errors } = props;

    const { t } = useTranslation();

    const type = value.type ?? CustomListPropertyEnum.None;

    if (type === CustomListPropertyEnum.None) {
        return null;
    }

    return (
        <React.Fragment>
            {type === CustomListPropertyEnum.Value && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <TextfieldIcon />
                    </IconBg>

                    <Textfield
                        value=""
                        placeholder="User will enter value here"
                        disabled
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Text && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <TextfieldIcon isMultiline />
                    </IconBg>

                    <Textfield
                        value=""
                        placeholder="User will enter text here"
                        disabled
                        multiline
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Tasklist && (
                <div className="template-property-preview">
                    <ManageBasicList
                        disabled={disabled}
                        value={value}
                        label={t(k.TASKS)}
                        errors={errors}
                        onChange={props.onChange}
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.DateTime && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <CalendarIcon />
                    </IconBg>

                    <Textfield
                        value=""
                        placeholder="User will enter date here"
                        disabled
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Photo && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <CameraIcon />
                    </IconBg>

                    <Textfield
                        value=""
                        placeholder="User will upload photo here"
                        disabled
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Selection && (
                <div className="template-property-preview">
                    <ManageBasicList
                        disabled={disabled}
                        value={value}
                        label={t(k.OPTIONS)}
                        errors={errors}
                        onChange={props.onChange}
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Customers && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <CustomerIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select customers from here"
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Suppliers && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <SupplierIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select suppliers from here"
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Items && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <ItemIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select items from here"
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Barcodes && (
                <div className="template-property-preview">
                    <Button size="small" transparent disabled>
                        {t(k.SCAN_BARCODE)}
                    </Button>

                    <Textfield
                        value=""
                        placeholder={t(k.OR_ENTER_BARCODE_HERE)}
                        disabled
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.GenerateBarcode && (
                <div className="template-property-preview">
                    <Button size="small" transparent disabled>
                        {t(k.GENERATE_BARCODE)}
                    </Button>
                </div>
            )}
            {type === CustomListPropertyEnum.Hyperlink && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <LinkIcon />
                    </IconBg>

                    <Textfield
                        value=""
                        placeholder="User will enter link here"
                        disabled
                    />
                </div>
            )}
            {type === CustomListPropertyEnum.Users && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <UserIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select user from here"
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Positions && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <PositionIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select position from here"
                    />
                </div>
            )}

            {type === CustomListPropertyEnum.Competencies && (
                <div className="template-property-preview">
                    <IconBg show={true}>
                        <CompetencyIcon />
                    </IconBg>

                    <SelectDropdown
                        isDisabled
                        placeholder="User will select competency from here"
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default BasicPropertyPreview;
