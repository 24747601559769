import CustomModal from "common/components/modal-content/CustomModal";
import React, { useState } from "react";

import SelectImprovementTemplate from "../SelectImprovementTemplate";
import { ImportGridModal } from "./ImportGridModal";

interface IProps {
    onHide: (amount?: number) => void;
}

export const SelectImprovementImportTemplate = (props: IProps) => {
    const [templateVersionId, setTemplateVersionId] = useState<string>();

    const handleSelectTemplate = (templateVersionId: string) => {
        setTemplateVersionId(templateVersionId);
    };

    return (
        <React.Fragment>
            {templateVersionId ? (
                <ImportGridModal
                    templateVersionId={templateVersionId}
                    onHide={props.onHide}
                />
            ) : (
                <CustomModal onBackgroundClick={props.onHide}>
                    <SelectImprovementTemplate
                        onClose={props.onHide}
                        onSelect={handleSelectTemplate}
                    />
                </CustomModal>
            )}
        </React.Fragment>
    );
};
