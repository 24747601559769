import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IProcessNodeImprovements } from "components/process-chart/api/IProcessNodeImprovements";
import { ImprovementProcessChartImpactType } from "components/process-chart/api/improvements/ImprovementProcessChartImpactType";
import "./ImprovementMessage.scss";
interface IProps {
    values?: IProcessNodeImprovements | null;
    impactType: ImprovementProcessChartImpactType;
    className?: string;
    forPCModal?: boolean;
}

const ImprovementsMessage: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { values, impactType, className, forPCModal } = props;

    const { t } = useTranslation();

    if (values && values.total > 0) {
        return (
            <>
                {impactType != ImprovementProcessChartImpactType.None && (
                    <p
                        className={`improvement-message--title ${
                            forPCModal ? "pc-modal" : ""
                        }`}
                    >
                        {impactType ==
                        ImprovementProcessChartImpactType.Discovered
                            ? t(k.DISCOVERED_IN)
                            : t(k.ORIGINATED_IN)}
                    </p>
                )}
                <div className={className}>
                    {values.registered > 0 && (
                        <p className="left">
                            {values.registered} {t(k.REGISTERED)}
                        </p>
                    )}

                    {values.analysing > 0 && (
                        <p className="left">
                            {values.analysing} {t(k.ANALYSING)}
                        </p>
                    )}

                    {values.acting > 0 && (
                        <p className="left">
                            {values.acting} {t(k.ACTING)}
                        </p>
                    )}
                    {values.verifying > 0 && (
                        <p className="left">
                            {values.verifying} {t(k.VERIFYING)}
                        </p>
                    )}
                </div>
            </>
        );
    }

    return null;
};

export default ImprovementsMessage;
