import { EPages } from "./EPages";
import { Roles, TRoles } from "./Roles";

type PermisionOption = {
    allowSetPermissionsRoles: TRoles[];
    allRoles: TRoles[];
    editRoles?: TRoles[];
};

export const Permissions: Readonly<Record<EPages, PermisionOption>> = {
    [EPages.ORA]: {
        allowSetPermissionsRoles: [Roles.ORA_OWNER],
        allRoles: [Roles.ORA_OWNER, Roles.ORA_EDITOR, Roles.ORA_VIEWER],
    },

    [EPages.SA]: {
        allowSetPermissionsRoles: [Roles.SA_OWNER],
        allRoles: [Roles.SA_OWNER, Roles.SA_EDITOR, Roles.SA_USER],
    },

    [EPages.CM]: {
        allowSetPermissionsRoles: [Roles.CM_OWNER],
        allRoles: [
            Roles.CM_OWNER,
            Roles.CM_EDITOR,
            Roles.CM_VIEWER,
            Roles.CM_USER,
        ],
    },

    [EPages.POSITION]: {
        allowSetPermissionsRoles: [Roles.POSITION_OWNER],
        allRoles: [
            Roles.POSITION_OWNER,
            Roles.POSITION_EDITOR,
            Roles.POSITION_VIEWER,
        ],
    },

    [EPages.USER]: {
        allowSetPermissionsRoles: [Roles.USER_OWNER],
        allRoles: [Roles.USER_OWNER, Roles.USER_EDITOR, Roles.USER_VIEWER],
    },

    [EPages.KPI]: {
        allowSetPermissionsRoles: [Roles.KPI_OWNER],
        allRoles: [
            Roles.KPI_OWNER,
            Roles.KPI_EDITOR,
            Roles.KPI_CONTRIBUTOR,
            Roles.KPI_VIEWER,
        ],
    },

    [EPages.CUSTOMERS]: {
        allowSetPermissionsRoles: [Roles.CUSTOMERS_OWNER],
        allRoles: [
            Roles.CUSTOMERS_OWNER,
            Roles.CUSTOMERS_EDITOR,
            Roles.CUSTOMERS_VIEWER,
        ],
    },

    [EPages.IMPROVEMENT_DASHBOARD]: {
        allowSetPermissionsRoles: [Roles.IMPROVEMENT_DASHBOARD_OWNER],
        allRoles: [
            Roles.IMPROVEMENT_DASHBOARD_OWNER,
            Roles.IMPROVEMENT_DASHBOARD_VIEWER,
        ],
    },

    [EPages.CHECKLIST_REPORT]: {
        allowSetPermissionsRoles: [Roles.CHECKLIST_REPORT_OWNER],
        allRoles: [Roles.CHECKLIST_REPORT_OWNER, Roles.CHECKLIST_REPORT_VIEWER],
    },

    [EPages.STAKEHOLDERS]: {
        allowSetPermissionsRoles: [Roles.STAKEHOLDERS_OWNER],
        allRoles: [
            Roles.STAKEHOLDERS_OWNER,
            Roles.STAKEHOLDERS_EDITOR,
            Roles.STAKEHOLDERS_VIEWER,
        ],
    },

    [EPages.LEGISLATIONS]: {
        allowSetPermissionsRoles: [Roles.LEGISLATIONS_OWNER],
        allRoles: [
            Roles.LEGISLATIONS_OWNER,
            Roles.LEGISLATIONS_EDITOR,
            Roles.LEGISLATIONS_VIEWER,
        ],
    },

    [EPages.IMPACT_GRADINGS]: {
        allowSetPermissionsRoles: [Roles.IMPACT_GRADINGS_OWNER],
        allRoles: [
            Roles.IMPACT_GRADINGS_OWNER,
            Roles.IMPACT_GRADINGS_EDITOR,
            Roles.IMPACT_GRADINGS_VIEWER,
        ],
    },

    [EPages.CUSTOMER_REQUIREMENTS]: {
        allowSetPermissionsRoles: [Roles.CUSTOMER_REQUIREMENTS_OWNER],
        allRoles: [
            Roles.CUSTOMER_REQUIREMENTS_OWNER,
            Roles.CUSTOMER_REQUIREMENTS_EDITOR,
            Roles.CUSTOMER_REQUIREMENTS_VIEWER,
        ],
    },

    [EPages.SUPPLIERS]: {
        allowSetPermissionsRoles: [Roles.SUPPLIERS_OWNER],
        allRoles: [
            Roles.SUPPLIERS_OWNER,
            Roles.SUPPLIERS_EDITOR,
            Roles.SUPPLIERS_VIEWER,
        ],
        editRoles: [Roles.SUPPLIERS_OWNER, Roles.SUPPLIERS_EDITOR],
    },

    [EPages.PURCHASE_ORDERS]: {
        allowSetPermissionsRoles: [Roles.PURCHASE_ORDERS_OWNER],
        allRoles: [
            Roles.PURCHASE_ORDERS_OWNER,
            Roles.PURCHASE_ORDERS_EDITOR,
            Roles.PURCHASE_ORDERS_VIEWER,
        ],
        editRoles: [Roles.PURCHASE_ORDERS_OWNER, Roles.PURCHASE_ORDERS_EDITOR],
    },

    [EPages.ITEMS]: {
        allowSetPermissionsRoles: [Roles.ITEMS_OWNER],
        allRoles: [Roles.ITEMS_OWNER, Roles.ITEMS_EDITOR, Roles.ITEMS_VIEWER],
    },

    [EPages.TEAMS]: {
        allowSetPermissionsRoles: [Roles.TEAMS_OWNER],
        allRoles: [Roles.TEAMS_OWNER, Roles.TEAMS_EDITOR, Roles.TEAMS_VIEWER],
    },

    [EPages.TRAINING_PLANS]: {
        allowSetPermissionsRoles: [Roles.TRAINING_PLANS_OWNER],
        allRoles: [
            Roles.TRAINING_PLANS_OWNER,
            Roles.TRAINING_PLANS_EDITOR,
            Roles.TRAINING_PLANS_VIEWER,
        ],
    },

    [EPages.EQUIPMENT]: {
        allowSetPermissionsRoles: [Roles.EQUIPMENT_OWNER],
        allRoles: [
            Roles.EQUIPMENT_OWNER,
            Roles.EQUIPMENT_EDITOR,
            Roles.EQUIPMENT_VIEWER,
        ],
        editRoles: [Roles.EQUIPMENT_OWNER, Roles.EQUIPMENT_EDITOR],
    },

    [EPages.ENVIRONMENT_ASPECTS]: {
        allowSetPermissionsRoles: [Roles.ENVIRONMENT_ASPECTS_OWNER],
        allRoles: [
            Roles.ENVIRONMENT_ASPECTS_OWNER,
            Roles.ENVIRONMENT_ASPECTS_EDITOR,
            Roles.ENVIRONMENT_ASPECTS_VIEWER,
        ],
    },

    [EPages.COMPETENCIES]: {
        allowSetPermissionsRoles: [Roles.COMPETENCIES_OWNER],
        allRoles: [
            Roles.COMPETENCIES_OWNER,
            Roles.COMPETENCIES_EDITOR,
            Roles.COMPETENCIES_VIEWER,
        ],
    },

    [EPages.GOALS]: {
        allowSetPermissionsRoles: [Roles.GOALS_OWNER],
        allRoles: [Roles.GOALS_OWNER, Roles.GOALS_EDITOR, Roles.GOALS_USER],
    },

    [EPages.CUSTOM_LIST]: {
        allowSetPermissionsRoles: [],
        allRoles: [],
    },
};
