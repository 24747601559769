import "./ArrowLeftIcon.scss";
import React, { useMemo } from "react";
import Icon from "./assets/arrow-left-icon.svg";

interface IProps {
    className?: string;
}

const ArrowLeftIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const className = useMemo(() => {
        const result = ["arrow-left-icon"];
        if (props.className) {
            result.push(props.className);
        }
        return result.join(" ");
    }, [props.className]);
    return <Icon className={className} />;
};

export default ArrowLeftIcon;
