import "./InfoCard.scss";

interface IProps {
    label: string;
    className?: string;
    childrenClassName?: string;
    rightSideLabelContent?: React.ReactNode;
}
const InfoCard = (props: React.PropsWithChildren<IProps>) => {
    const childrenClassName = "children " + (props.childrenClassName ?? "");
    const className = "info-card " + (props.className ?? "");
    return (
        <div className={className}>
            <div className="label">
                {props.label}
                {props.rightSideLabelContent}
            </div>
            <div className={childrenClassName}>{props.children}</div>
        </div>
    );
};

export default InfoCard;
