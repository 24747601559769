import "./ActionTextField.scss";

import { RefObject, useEffect, useRef, useState, useCallback } from "react";

import { IWithDebounce } from "common/components/input-components/IInputProps";
import { TextfieldType } from "common/components/input-components/textfield/types";
import { IInputActionChangeEvent } from "../../IInputActionProps";

import InputActionWrapper, {
    IInputActionWrapperProps,
} from "../InputActionWrapper";

export interface IActionTextFieldProps extends IInputActionWrapperProps {
    id: string;

    inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement> | null;

    parentId?: string;

    testId?: string;
    boxTestId?: string;

    name?: string;

    invalid?: boolean;
    disabled?: boolean;
    editMode?: boolean;
    editable?: boolean;

    value: string;

    multiline?: boolean;
    fillHeight?: boolean;

    inputFieldType?: TextfieldType;
    isLink?: boolean;

    autoCompleteOff?: boolean;

    autoFocus?: boolean;

    minRows?: number;

    parentSize?: { width: number; height: number };

    placeholder?: string;
    title?: string;

    className?: string;

    darkBackground?: boolean;

    variant?: "title";

    noPadding?: boolean;

    inputSize?: "large";

    useWrapper?: boolean;

    validationRegEx?: RegExp;

    preInputContent?: React.ReactNode;
    postInputContent?: React.ReactNode;

    onChange?: (e: IInputActionChangeEvent<string>) => void;

    onBlur?: (id: string, name?: string) => void;

    onFocus?: (id: string, name?: string) => void;
    onResetDebounce?: (resetDebounce: (value?: string) => void) => void;
}

type Props = IActionTextFieldProps & IWithDebounce;

const WAIT_INTERVAL = 2000;

const ActionTextField = (props: Props) => {
    const {
        autoCompleteOff,
        autoFocus,
        disabled,
        fillHeight,
        id,
        testId,
        boxTestId,
        invalid,
        minRows = 2,
        multiline,
        parentId,
        placeholder,
        className: wrapperClassName,
        name,
        title,
        withDebounce = false,
        debounceTimeout = WAIT_INTERVAL,
        inputFieldType = "text",
        darkBackground,
        editMode = true,
        editable = true,
        variant,
        noPadding,
        inputSize,
        preInputContent,
        postInputContent,
        isLink,
        validationRegEx,
    } = props;

    const changeEvent =
        useRef<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>();

    const [value, setValue, triggerChange, resetDebounce] = useWithDebounce(
        withDebounce,
        props.value,
        debounceTimeout,
        (value, withDelay) => {
            props.onChange?.({
                id,
                name,
                value,
                withDelay,
                parentId,
                target: changeEvent.current?.target,
            });

            changeEvent.current = undefined;
        },
    );

    useEffect(() => {
        if (resetDebounce) {
            props.onResetDebounce?.(resetDebounce);
        }
    }, [resetDebounce, props.onResetDebounce]);

    const [isFocused, setIsFocused] = useState(false);

    const [textareaRef] = textAreaOptions({ isTextArea: multiline, props });

    const inputRef =
        (props.inputRef as RefObject<HTMLInputElement>) ??
        useRef<HTMLTextAreaElement>(null);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newValue = e.target.value;

        if (validateChange(e)) {
            if (withDebounce) {
                setValue?.(newValue);
                changeEvent.current = e;
            } else {
                props.onChange?.({
                    id,
                    name,
                    value: newValue,
                    withDelay: false,
                    parentId,
                    target: e.target,
                });
            }
        }
    };

    const validateChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (validationRegEx) {
            const newValue = e.target.value;
            const target = e.target;

            const numMatch = newValue.match(validationRegEx);

            const matchedNumber =
                numMatch && numMatch.length === 1 && numMatch[0];

            if (
                (matchedNumber && matchedNumber !== value) ||
                newValue.length === 0
            ) {
                return true;
            } else if (target) {
                const lengthDiff = newValue.length - value.length;

                const startPost = Math.max(
                    (target.selectionStart ?? 0) - lengthDiff,
                    0,
                );
                const endPost = Math.max(
                    (target.selectionEnd ?? 0) - lengthDiff,
                    0,
                );

                target.value = value;
                target.setSelectionRange(startPost, endPost);

                return false;
            }
        } else {
            return true;
        }
    };

    const handleOnFocus = () => {
        setIsFocused(true);

        if (props.onFocus) {
            props.onFocus(id, name);
        }
    };

    const handleOnFocusInput = () => {
        if (!disabled) {
            handleOnFocus();
            setTimeout(() => inputRef?.current?.focus(), 0);
        }
    };

    const handleOnBlur = () => {
        setIsFocused(false);

        triggerChange?.(false);

        if (props.onBlur) {
            props.onBlur(id, name);
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.currentTarget.blur();
        }
    };

    function getClassName() {
        let result = "action-text-field";

        if (multiline) {
            result += " action-text-field--multiline";

            if (fillHeight) {
                result += " fill-height";
            }
        }

        if (wrapperClassName) {
            result += " " + wrapperClassName;
        }

        if (noPadding) {
            result += " action-text-field--no-padding";
        }

        if (variant) {
            result += " action-text-field--variant-" + variant;
        }

        if (inputSize) {
            result += ` action-text-field--input-size-${inputSize}`;
        }

        return result;
    }

    function getBoxClassName() {
        let result = "action-text-field--box";

        if (invalid) {
            result += " invalid";
        }

        if (darkBackground) {
            result += " background-dark";
        }

        if (isFocused || editMode) {
            result += " input-focused";
        }

        if (disabled) {
            result += " disabled";
        } else if (editable) {
            result += " editable";
        }

        return result;
    }

    const className = getClassName();
    const boxClassName = getBoxClassName();

    return (
        <InputActionWrapper fullWidth {...props}>
            <div className={className}>
                <div className={boxClassName} data-testid={boxTestId}>
                    {preInputContent}
                    {multiline ? (
                        <>
                            <div className="action-text-field--input print-field">
                                {value}
                            </div>

                            <textarea
                                ref={textareaRef}
                                id={id}
                                data-testid={testId}
                                className="action-text-field--input"
                                value={value}
                                placeholder={placeholder}
                                disabled={disabled || !editable}
                                rows={minRows}
                                title={title}
                                autoComplete={
                                    autoCompleteOff ? "off" : undefined
                                }
                                autoFocus={autoFocus}
                                onChange={handleChange}
                                onFocus={handleOnFocus}
                                onBlur={handleOnBlur}
                            />
                        </>
                    ) : isLink && value && !isFocused ? (
                        <div
                            className="action-text-field--link-container"
                            onClick={handleOnFocusInput}
                        >
                            <a
                                href={value}
                                target="_blank"
                                className={`action-text-field--link-container--link`}
                            >
                                {value}
                            </a>
                        </div>
                    ) : (
                        <input
                            type={inputFieldType}
                            id={id}
                            ref={inputRef}
                            data-testid={testId}
                            className="action-text-field--input"
                            value={value}
                            placeholder={placeholder}
                            disabled={disabled || !editable}
                            title={title}
                            autoComplete={autoCompleteOff ? "off" : undefined}
                            autoFocus={autoFocus}
                            onChange={handleChange}
                            onBlur={handleOnBlur}
                            onFocus={handleOnFocus}
                            onKeyUp={handleKeyUp}
                        />
                    )}
                    {postInputContent}
                </div>
            </div>
        </InputActionWrapper>
    );
};

export default ActionTextField;

type withDebounceReturn<V> = [
    V,
    (value: V) => void,
    (withDelay?: boolean) => void,
    (value?: V) => void,
];
type withouDebounceReturn<V> = [V, undefined, undefined];

const useWithDebounce = <V, D extends boolean>(
    useDebounce: D,
    value: V,
    debounceTimeout: number,
    onChange: (value: V, withDelay?: boolean) => void,
) => {
    if (useDebounce !== true) {
        return [value, undefined, undefined] as withouDebounceReturn<V>;
    }

    const [debounceValue, setDebounceValue] = useState(value);

    const [isChanged, setIsChanged] = useState(false);

    const timer = useRef<any>(null);

    useEffect(() => {
        if (value !== debounceValue) {
            setDebounceValue(value);
        }
    }, [value]);

    useEffect(() => {
        timer.current = setTimeout(() => {
            triggerChange(true);
        }, debounceTimeout);

        return () => clearTimeout(timer.current);
    }, [debounceValue]);

    const resetDebounce = (resetValue?: V) => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }

        setDebounceValue(resetValue ?? value);
    };

    const triggerChange = (withDelay?: boolean) => {
        if (!withDelay) {
            clearTimeout(timer.current);
        }

        if (isChanged) {
            setIsChanged(false);

            onChange(debounceValue, withDelay);
        }
    };

    const handleChange = (value: V) => {
        setIsChanged(true);
        setDebounceValue(value);
    };

    return [
        debounceValue,
        handleChange,
        triggerChange,
        resetDebounce,
    ] as withDebounceReturn<V>;
};

type inputReturn = [undefined];

type textAreaReturn = [RefObject<HTMLTextAreaElement>];

const textAreaOptions = <ITA extends boolean>({
    isTextArea,
    props,
}: {
    isTextArea?: ITA;
    props: Props;
}) => {
    if (isTextArea !== true) {
        return [undefined] as inputReturn;
    }

    const { inputRef, multiline, parentSize, value } = props;

    const textareaRef =
        (inputRef as RefObject<HTMLTextAreaElement>) ??
        useRef<HTMLTextAreaElement>(null);

    const textareaSizeUpdate = () => {
        if (textareaRef.current) {
            const ref = textareaRef.current;

            ref.style.height = "auto";
            ref.style.height = ref.scrollHeight + "px";
        }
    };

    useEffect(() => {
        if (textareaRef.current && multiline) {
            const ref = textareaRef.current;
            ref.setAttribute(
                "style",
                "height:" + ref.scrollHeight + "px;" + "overflow-y:hidden;",
            );

            ref.addEventListener("input", textareaSizeUpdate, false);
            textareaSizeUpdate();
        }
        return () => {
            textareaRef.current?.removeEventListener(
                "input",
                textareaSizeUpdate,
                false,
            );
        };
    }, [textareaRef, multiline]);

    useEffect(() => {
        if (value && multiline) {
            textareaSizeUpdate();
        }
    }, [value, multiline, parentSize]); // parentSize needed for resizing after save

    return [textareaRef] as textAreaReturn;
};
