import "./Tooltip.scss";

import React from "react";
import classnames from "classnames";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import * as Popper from "@popperjs/core";

export type TooltipPlacement = Popper.Placement;

export interface TooltipProps {
    id: string;
    placement?: TooltipPlacement;
    message: React.ReactNode;
    textEllipsis?: boolean;

    variant?: "inline" | "no-flex";
    alignSelf?: "center";
    tooltipClassName?: string;

    truncate?: boolean;
}

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = (props) => {
    const { id, message, alignSelf, tooltipClassName, truncate, textEllipsis } =
        props;

    const placement = props.placement ?? "top";

    const isInline = props.variant === "inline";

    const noFlex = props.variant === "no-flex";

    const defaultClassName = isInline
        ? "d-inline-flex"
        : noFlex
          ? ""
          : "d-flex";

    const classes = classnames({
        [defaultClassName]: true,
        "tooltip__align-self--center": alignSelf === "center",
        "tooltip__text-ellipsis": textEllipsis,
    });

    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <BootstrapTooltip
                    id={`tooltip-${id}`}
                    className={
                        (tooltipClassName ?? "") +
                        (truncate ? " truncate-text" : "")
                    }
                >
                    {message}
                </BootstrapTooltip>
            }
        >
            <div className={classes}>{props.children}</div>
        </OverlayTrigger>
    );
};

export default Tooltip;
