import axios from "axios";
import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";

import ICustomListItemFilter from "./ICustomListItemFilter";
import ICustomListItemRowDTO from "./ICustomListItemRowDTO";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { CustomListItemSortBy } from "./CustomListItemSortBy";
import { convertToDateStrict } from "common/utils/time";
import IDictionary from "common/viewModels/IDictionary";
import { ICustomListItemsTableList } from "./ICustomListItemsTableList";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { ICustomListItemOptionFilter } from "./ICustomListItemOptionFilter";
import { IUserDefinedCustomListItemValueRequest } from "components/common/shared-properties/api/IUserDefinedCustomListItemValueRequest";

const baseUrl = "/api/custom-list-item";

const LIST_QUERY_KEY = "custom-list-item-list";

export const useCustomListItemList = (
    enabled: boolean,
    filter: ICustomListItemFilter,
) => {
    const url = `${baseUrl}/list`;

    const queryClient = useQueryClient();

    return useQuery(
        [LIST_QUERY_KEY, filter],
        async (context) => {
            const response = await axios.post<ICustomListItemsTableList>(
                url,
                {
                    ...filter,

                    sortBy: filter.customSortBy
                        ? CustomListItemSortBy.None
                        : filter.sortBy,
                },
                {
                    signal: context.signal,
                },
            );

            const result = response.data;

            for (const id of result.ids) {
                const value = result.values[id];
                if (value) {
                    convertDates(value.dateValues);
                }
            }

            return result;
        },
        {
            enabled: Boolean(filter.customListId) && enabled,

            onSuccess: (result) => {
                addedCustomListItemsState.removeAll(queryClient);

                for (const id of result.ids) {
                    const value = result.values[id];

                    if (value) {
                        cachedCustomListItemsState.save(queryClient, value);
                    }
                }
            },
        },
    );
};

const CACHED_CUSTOM_LIST_ITEM_BY_ID = "cached-custom-list-item-by-id";

const cachedCustomListItemsState: {
    items: IDictionary<ICustomListItemRowDTO>;

    contains(id: string): boolean;
    archiveById(queryClient: QueryClient, id: string, value: boolean): void;
    save(queryClient: QueryClient, value: ICustomListItemRowDTO): void;
} = {
    items: {},

    contains: function (id: string) {
        return this.items[id] !== undefined;
    },

    archiveById: function (
        queryClient: QueryClient,
        id: string,
        value: boolean,
    ) {
        if (this.contains(id)) {
            const item = this.items[id];

            this.save(queryClient, { ...item, isArchived: value });
        }
    },

    save: function (queryClient: QueryClient, value: ICustomListItemRowDTO) {
        this.items[value.id] = value;

        queryClient.invalidateQueries([
            CACHED_CUSTOM_LIST_ITEM_BY_ID,
            value.id,
        ]);
    },
};

export const useCachedCustomListItemById = (id: string) => {
    return useQuery<ICustomListItemRowDTO | undefined>(
        [CACHED_CUSTOM_LIST_ITEM_BY_ID, id],
        () => cachedCustomListItemsState.items[id],
    );
};

const CACHED_ADDED_CUSTOM_LIST_ITEM_IDS = "cached-added-custom-list-item_ids";

const addedCustomListItemsState: {
    items: string[];

    addId(queryClient: QueryClient, id: string): void;
    removeAll(queryClient: QueryClient): void;
} = {
    items: [],

    addId: function (queryClient: QueryClient, id: string) {
        this.items = [id].concat(this.items);

        queryClient.invalidateQueries([CACHED_ADDED_CUSTOM_LIST_ITEM_IDS]);
    },

    removeAll: function (queryClient: QueryClient) {
        this.items = [];

        queryClient.invalidateQueries([CACHED_ADDED_CUSTOM_LIST_ITEM_IDS]);
    },
};

export const useCachedAddedCustomListItemIds = () => {
    return useQuery<string[] | undefined>(
        [CACHED_ADDED_CUSTOM_LIST_ITEM_IDS],
        () => addedCustomListItemsState.items,
    );
};

function convertDates(dates: IDictionary<Date[]>) {
    for (const dateId in dates) {
        const dateValues = dates[dateId];

        if (dateValues) {
            for (let i = 0; i < dateValues.length; i++) {
                dateValues[i] = convertToDateStrict(dateValues[i]);
            }
        }
    }
}

const CUSTOM_LIST_ITEM_NAMES_BY_IDS = "CUSTOM_LIST_ITEM_NAMES_BY_IDS";

export const useCustomListItemNamesByIds = (
    ids: Array<{ key: string; value?: Date }> | undefined,
) => {
    const url = `${baseUrl}/names-by-ids`;

    return useQuery(
        [CUSTOM_LIST_ITEM_NAMES_BY_IDS, ids],

        async (context) => {
            const data = ids
                ?.map((id) => {
                    return `ids=${id.key}`;
                })
                .join("&");

            const response = await axios.post<
                Record<string, IValueLabelItemWithState | undefined>
            >(url, data, {
                signal: context.signal,
            });

            return response.data;
        },

        {
            enabled: Boolean(ids && ids.length > 0),
            keepPreviousData: true,
        },
    );
};

async function fetchOptions<T>(url: string, signal?: AbortSignal) {
    const response = await axios.get<T>(url, {
        signal,
    });

    return response.data;
}

const CUSTOM_LIST_ITEM_BY_ID = "custom-list-item-by-id";

export const useCustomListItemById = (customListItemId?: string) => {
    const url = `${baseUrl}/id/${customListItemId}`;

    return useQuery(
        [CUSTOM_LIST_ITEM_BY_ID, customListItemId],

        async (context) => {
            const result = await fetchOptions<ICustomListItemRowDTO>(
                url,
                context.signal,
            );

            convertDates(result.dateValues);

            return result;
        },

        {
            enabled: Boolean(customListItemId),
        },
    );
};

export const CUSTOM_LIST_ITEM_OPTIONS = "custom-list-item-options";

export async function fetchCustomListItemOptions(
    args: {
        customListId?: string;
        customListItemId?: string;
        showAll?: boolean;
        isChildMutualProperty?: boolean;
    },
    isBasicList?: boolean,
    signal?: AbortSignal,
) {
    const { customListId, customListItemId, showAll, isChildMutualProperty } =
        args;

    const filter: ICustomListItemOptionFilter = {
        customListId: customListId ?? "",
        customListItemId,
        showAll,
        isChildMutualProperty,
    };

    // a basic list is a Tasklist or Selection list

    const url = isBasicList
        ? `${baseUrl}/basic-list-options`
        : `${baseUrl}/options`;

    const response = await axios.get<IValueLabelItemWithState[]>(url, {
        params: filter,
        signal,
    });

    return { args, data: response.data };
}

export const useCustomListBasicListOptions = (
    customListId?: string,
    customListItemId?: string,
    showAll?: boolean,
) => {
    return useQuery(
        [CUSTOM_LIST_ITEM_OPTIONS, customListId, customListItemId],

        async (context) => {
            const result = await fetchCustomListItemOptions(
                {
                    customListId,
                    customListItemId,
                    showAll,
                },
                true,
                context.signal,
            );

            return result.data;
        },

        {
            enabled: Boolean(customListId),
        },
    );
};

export const useCustomListItemOptions = (
    customListId?: string,
    customListItemId?: string,
    showAll?: boolean,
    isChildMutualProperty?: boolean,
) => {
    return useQuery(
        [CUSTOM_LIST_ITEM_OPTIONS, customListId, customListItemId],

        async (context) => {
            const result = await fetchCustomListItemOptions(
                {
                    customListId,
                    customListItemId,
                    showAll,
                    isChildMutualProperty,
                },
                false,
                context.signal,
            );

            return result.data;
        },

        {
            enabled: Boolean(customListId),
        },
    );
};

export const useCustomListItemOptionsByActivityId = (activityId?: string) => {
    const url = `${baseUrl}/options-by-activity-id/${activityId}`;

    return useQuery(
        ["custom-list-item-options-by-activity-id", activityId],

        async (context) =>
            fetchOptions<IValueLabelItemWithState[]>(url, context.signal),

        {
            enabled: Boolean(activityId),
        },
    );
};

export const useCustomListItemOptionsByActivityInstanceId = (
    activityInstanceId?: string,
) => {
    const url = `${baseUrl}/options-by-activityinstance-id/${activityInstanceId}`;

    return useQuery(
        ["custom-list-item-options-by-activityinstance-id", activityInstanceId],

        async (context) =>
            fetchOptions<IValueLabelItemWithState[]>(url, context.signal),

        {
            enabled: Boolean(activityInstanceId),
        },
    );
};

export const useDeleteCustomListItemMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { id: string }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.id}`);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                if (
                    cachedCustomListItemsState.contains(variables.id) === false
                ) {
                    queryClient.invalidateQueries(
                        CUSTOM_LIST_ITEM_NAMES_BY_IDS,
                    );
                }
            },
        },
    );
};

function getCustomListItemValueOrDefault(value?: ICustomListItemRowDTO) {
    if (value) {
        return value;
    }

    const result: ICustomListItemRowDTO = {
        id: "",
        customListId: "",
        values: {},
        dateValues: {},
        notCustomListNames: {},
        tasklistOptionsByIds: {},
    };

    return result;
}

export const useArchiveCustomListItemMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),
        {
            onMutate: async (variables) => {
                await queryClient.cancelQueries([
                    CUSTOM_LIST_ITEM_BY_ID,
                    variables.request.id,
                ]);

                const wasArchived: boolean | undefined =
                    queryClient.getQueryData<ICustomListItemRowDTO>([
                        CUSTOM_LIST_ITEM_BY_ID,
                        variables.request.id,
                    ])?.isArchived;

                queryClient.setQueryData<ICustomListItemRowDTO>(
                    [CUSTOM_LIST_ITEM_BY_ID, variables.request.id],
                    (prev) =>
                        getCustomListItemValueOrDefault(
                            prev
                                ? {
                                      ...prev,
                                      isArchived: variables.request.isArchive,
                                  }
                                : undefined,
                        ),
                );

                if (cachedCustomListItemsState.contains(variables.request.id)) {
                    await queryClient.cancelQueries([LIST_QUERY_KEY]);

                    cachedCustomListItemsState.archiveById(
                        queryClient,
                        variables.request.id,
                        variables.request.isArchive,
                    );
                }

                return {
                    prevValue: wasArchived,
                };
            },
            onSettled: (
                data,
                error,
                variables,
                context: { prevValue?: boolean } | undefined,
            ) => {
                const isArchived = error
                    ? context?.prevValue
                    : Boolean(data?.data.archivedAt);

                queryClient.setQueryData<ICustomListItemRowDTO>(
                    [CUSTOM_LIST_ITEM_BY_ID, variables.request.id],
                    (prev) =>
                        getCustomListItemValueOrDefault(
                            prev
                                ? {
                                      ...prev,
                                      isArchived: isArchived ?? false,
                                  }
                                : undefined,
                        ),
                );

                if (cachedCustomListItemsState.contains(variables.request.id)) {
                    cachedCustomListItemsState.archiveById(
                        queryClient,
                        variables.request.id,
                        isArchived ?? false,
                    );
                } else if (data) {
                    queryClient.invalidateQueries(
                        CUSTOM_LIST_ITEM_NAMES_BY_IDS,
                    );
                }
            },
        },
    );
};

export const useSaveCustomListItemMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: {
            isCreate: boolean;
            value: ICustomListItemRowDTO;
        }) => {
            try {
                let result: ICustomListItemRowDTO;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<ICustomListItemRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<ICustomListItemRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<ICustomListItemRowDTO>(result);
            } catch (error) {
                return ExecutionResult.Failed<ICustomListItemRowDTO>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                const value = data.Data;

                if (value) {
                    convertDates(value.dateValues);

                    if (variables.isCreate) {
                        cachedCustomListItemsState.save(queryClient, value);

                        addedCustomListItemsState.addId(queryClient, value.id);
                    } else {
                        queryClient.setQueryData<ICustomListItemRowDTO>(
                            [CUSTOM_LIST_ITEM_BY_ID, value.id],
                            value,
                        );

                        if (cachedCustomListItemsState.contains(value.id)) {
                            cachedCustomListItemsState.save(queryClient, value);
                        } else {
                            queryClient.invalidateQueries(
                                CUSTOM_LIST_ITEM_NAMES_BY_IDS,
                            );
                        }
                    }
                }
            },
        },
    );
};

export const useSaveUserDefinedCustomListItemValue = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (request: IUserDefinedCustomListItemValueRequest) => {
            try {
                if (request.isEdit) {
                    const url = `${baseUrl}/update-user-defined-custom-list-item-value-label`;

                    await axios.put(url, request);
                } else {
                    const url = `${baseUrl}/add-user-defined-custom-list-item-value`;

                    await axios.post(url, request);
                }

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                if (data.Succeeded) {
                    queryClient.invalidateQueries([
                        CUSTOM_LIST_ITEM_OPTIONS,
                        variables.customListId,
                    ]);
                }
            },
        },
    );
};

export async function importCustomListItemsMutation(request: {
    customListId: string;
    items: ICustomListItemRowDTO[];
}) {
    const url = `${baseUrl}/import/${request.customListId}`;

    try {
        const response = await axios.post<number>(url, request.items);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
