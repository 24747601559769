import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { FC } from "react";
import { Trans } from "react-i18next";
import Toggle from "react-toggle";

interface ConfigurationTableStaticRowProps {
    description: string;
    index: number;
    selectedRowValues:
        | Record<
              string,
              Record<
                  string,
                  {
                      value?: boolean;
                      disabled?: boolean;
                  }
              >
          >
        | undefined;
    optionalColumns?: {
        name: string;
        type: "radio" | "checkbox";
    }[];
    disabled?: boolean;
    onClick?: (row: string, column: string, value: boolean) => void;
}
const StaticRow: FC<ConfigurationTableStaticRowProps> = (props) => {
    const {
        description,
        index,
        optionalColumns,
        selectedRowValues,
        disabled,
        onClick,
    } = props;

    return (
        <tr key={description}>
            <td>
                <Trans i18nKey={description} />
            </td>
            {optionalColumns?.map((column) => (
                <td key={`${column.name}-${index}`}>
                    {column.type === "radio" ? (
                        <Toggle
                            id={column.name}
                            checked={Boolean(
                                selectedRowValues &&
                                    selectedRowValues?.[column.name]?.[
                                        description
                                    ]?.value,
                            )}
                            onChange={(e) =>
                                onClick?.(
                                    description,
                                    column.name,
                                    e.target?.checked,
                                )
                            }
                            disabled={
                                disabled ||
                                Boolean(
                                    selectedRowValues &&
                                        selectedRowValues?.[column.name]?.[
                                            description
                                        ]?.disabled,
                                )
                            }
                        />
                    ) : (
                        <Checkbox
                            label=""
                            value={Boolean(
                                selectedRowValues &&
                                    selectedRowValues?.[column.name]?.[
                                        description
                                    ]?.value,
                            )}
                            onChange={(e) =>
                                onClick?.(
                                    description,
                                    column.name,
                                    Boolean(e.value),
                                )
                            }
                            disabled={
                                disabled ||
                                Boolean(
                                    selectedRowValues &&
                                        selectedRowValues?.[column.name]?.[
                                            description
                                        ]?.disabled,
                                )
                            }
                        />
                    )}
                </td>
            ))}
        </tr>
    );
};

export default StaticRow;
