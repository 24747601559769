import k from "i18n/keys";
import i18n, { TFunction } from "i18next";
import { EMeasurementGrading } from "../api/EMeasurementGrading";
import { IMeasurementDTO } from "../api/IMeasurementDTO";
import { IMeasurementProgressValueDTO } from "../measurement-progress-value/api/IMeasurementProgressValueDTO";
import { MonthsKeys } from "i18n/keySets/MonthKeys";
import { Months } from "models/enums/Months";
import { DataPerYear } from "../components/MeasurementChart";
import { Quarters } from "models/enums/Quarters";
import { QuartersKeys } from "i18n/keySets/QuarterKeys";
import { IGroupedValueLabelItem } from "common/IValueLabelItem";
import IMeasurementActionSetDTO from "../api/IMeasurementActionSetDTO";
import ITableSet from "http/ITableSet";
import { IInputActionDTO } from "components/common/actions/api/IInputActionDTO";
import IDictionary from "common/viewModels/IDictionary";
import ITableSetWithParentLink from "http/ITableSetWithParentLink";
import { MeasurementAccessRoleEnum } from "../api/EMeasurementAccessRole";
import { Dispatch, SetStateAction } from "react";
import IValidationMessages from "common/viewModels/IValidationMessages";

export enum EDisplayProgressType {
    Monthly,
    Quarterly,
}

export const progressTypes = {
    [EDisplayProgressType.Monthly]: k.MONTHLY,
    [EDisplayProgressType.Quarterly]: k.QUARTERLY,
};

export const progressTypeOptions = (i18next: typeof i18n) => [
    {
        label: i18next.t(progressTypes[EDisplayProgressType.Monthly]),
        value: EDisplayProgressType.Monthly,
    },
    {
        label: i18next.t(progressTypes[EDisplayProgressType.Quarterly]),
        value: EDisplayProgressType.Quarterly,
    },
];

export const validateMeasurement = (measurement: IMeasurementDTO) => {
    const errors: IValidationMessages = {};

    if (!measurement.name) {
        errors[`measurement.${measurement.id}.name`] = k.REQUIRED;
    }

    if (
        !!measurement.startDate &&
        !!measurement.endDate &&
        measurement.startDate > measurement.endDate
    ) {
        errors[`measurement.${measurement.id}.startDate`] =
            k.START_DATE_GREATER_THAN_END_DATE;
    }

    return errors;
};

export const measurementStatusLocales = {
    [EMeasurementGrading.OnTrack]: k.ON_TRACK,
    [EMeasurementGrading.Behind]: k.BEHIND,
    [EMeasurementGrading.AtRisk]: k.AT_RISK,
    [EMeasurementGrading.Blocked]: k.BLOCKED,
    [EMeasurementGrading.Completed]: k.COMPLETED,
    [EMeasurementGrading.Draft]: k.DRAFT,
};

export const measurementStatusOptions = (i18next: typeof i18n) =>
    [
        {
            value: EMeasurementGrading.OnTrack,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.OnTrack],
            ),
        },
        {
            value: EMeasurementGrading.Behind,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Behind],
            ),
        },
        {
            value: EMeasurementGrading.AtRisk,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.AtRisk],
            ),
        },
        {
            value: EMeasurementGrading.Blocked,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Blocked],
            ),
        },
        {
            value: EMeasurementGrading.Completed,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Completed],
            ),
        },
        {
            value: EMeasurementGrading.Draft,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Draft],
            ),
        },
    ] as IGroupedValueLabelItem<EMeasurementGrading, string>[];

export const decomposeMeasurementAccessRole = (
    accessRole?: MeasurementAccessRoleEnum,
) => {
    let canView = undefined;
    let canEditInPreview = undefined;
    let canEdit = undefined;
    let canCreate = undefined;
    let canEverything = undefined;

    switch (accessRole) {
        case MeasurementAccessRoleEnum.CanEverything:
            canEverything = true;
        case MeasurementAccessRoleEnum.CanCreate:
            canCreate = true;
        case MeasurementAccessRoleEnum.CanEdit:
            canEdit = true;
        case MeasurementAccessRoleEnum.CanEditInPreview:
            canEditInPreview = true;
        case MeasurementAccessRoleEnum.CanView:
            canView = true;
            break;
    }

    return { canView, canEditInPreview, canEdit, canCreate, canEverything };
};

export const getMeasurementProgress = (measurement?: IMeasurementDTO) => {
    if (!measurement) {
        return 0;
    }

    const progress =
        measurement.endValue !== 0
            ? ((measurement?.currentValue ?? measurement?.startValue ?? 0) /
                  measurement.endValue) *
              100
            : 0;
    return parseFloat(progress.toFixed(2));
};

export const getProgressByYearAndMonth = (
    data: IMeasurementProgressValueDTO[],
): Record<number, DataPerYear> => {
    const dataPerYearAndMonth: Record<number, DataPerYear> = {};

    data.forEach((item) => {
        if (item.date) {
            const month = new Date(item.date).getMonth();
            const year = new Date(item.date).getFullYear();

            const monthKey = k[MonthsKeys[month as Months] as keyof typeof k];
            const months = Object.values(MonthsKeys);

            if (!dataPerYearAndMonth[year]) {
                dataPerYearAndMonth[year] = {
                    x: months,
                    y: new Array(12).fill(0),
                };
            }

            const monthIndex = months.indexOf(monthKey);

            if (monthIndex !== -1) {
                dataPerYearAndMonth[year].y[monthIndex] += item.value;
            }
        }
    });

    return dataPerYearAndMonth;
};

export const getProgressByYearAndQuarter = (
    data: IMeasurementProgressValueDTO[],
): Record<number, DataPerYear> => {
    const dataPerYearAndQuarter: Record<number, DataPerYear> = {};

    data.forEach((item) => {
        if (item.date) {
            const month = new Date(item.date).getMonth();
            const year = new Date(item.date).getFullYear();

            let quarter;
            if (month >= 0 && month <= 2) {
                quarter = QuartersKeys[Quarters.Q1];
            } else if (month >= 3 && month <= 5) {
                quarter = QuartersKeys[Quarters.Q2];
            } else if (month >= 6 && month <= 8) {
                quarter = QuartersKeys[Quarters.Q3];
            } else if (month >= 9 && month <= 11) {
                quarter = QuartersKeys[Quarters.Q4];
            }

            const quarters = Object.values(QuartersKeys);

            if (quarter) {
                if (!dataPerYearAndQuarter[year]) {
                    dataPerYearAndQuarter[year] = {
                        x: quarters,
                        y: new Array(4).fill(0),
                    };
                }

                const quarterIndex = quarters.indexOf(quarter);

                if (quarterIndex !== -1) {
                    dataPerYearAndQuarter[year].y[quarterIndex] += item.value;
                }
            }
        }
    });

    return dataPerYearAndQuarter;
};

export const getProgressLineData = (
    dataPerYearAndMonth: Record<number, DataPerYear>,
    t: TFunction<"translation", undefined>,
) => {
    const data = { x: [] as string[], y: [] as number[] };

    let sum = 0;

    Object.keys(dataPerYearAndMonth).forEach((year) => {
        data.x.push(
            ...dataPerYearAndMonth[parseInt(year)].x.map(
                (month) => `${t(month)} ${year}`,
            ),
        );
        data.y.push(
            ...dataPerYearAndMonth[parseInt(year)].y.map((y) => {
                sum += y;
                return sum;
            }),
        );
    });

    return data;
};

export const convertToTableSet = (
    inputActionSets: IMeasurementActionSetDTO[],
) => {
    return inputActionSets.reduce<ITableSet<IMeasurementActionSetDTO>>(
        (acc, inputActionSet) => {
            acc.ids.push(inputActionSet.id);
            acc.values[inputActionSet.id] = inputActionSet;

            return acc;
        },
        { ids: [], values: {} },
    );
};

export const convertToTableSetWithParentLink = (
    inputActionsByInputActionSetId: IDictionary<IInputActionDTO[]>,
) => {
    return Object.keys(inputActionsByInputActionSetId).reduce<
        ITableSetWithParentLink<
            IInputActionDTO,
            "inputActionIdsByInputActionSetId"
        >
    >(
        (acc, inputActionSetId) => {
            inputActionsByInputActionSetId[inputActionSetId].forEach(
                (inputAction) => {
                    acc.values[inputAction.id] = inputAction;
                    if (
                        !acc.parents?.inputActionIdsByInputActionSetId?.[
                            inputActionSetId
                        ]
                    ) {
                        acc.parents!.inputActionIdsByInputActionSetId![
                            inputActionSetId
                        ] = [];
                    }
                    acc.parents?.inputActionIdsByInputActionSetId?.[
                        inputActionSetId
                    ]?.push(inputAction.id);

                    acc.ids.push(inputAction.id);
                },
            );

            return acc;
        },
        {
            ids: [],
            values: {},
            parents: { inputActionIdsByInputActionSetId: {} },
        },
    );
};
