import { CognitGPTPromptType } from "common/utils/cognitGPTUtils";
import IOperationalRiskAnalysisRowDTO from "../api/IOperationalRiskAnalysisRowDTO";

export const riskKeyByPromptType: Record<
    CognitGPTPromptType,
    keyof IOperationalRiskAnalysisRowDTO | undefined
> = {
    [CognitGPTPromptType.ORA_RiskScenario]: "riskScenario",

    [CognitGPTPromptType.ORA_RiskScenario_RewriteExisting]: "riskScenario",
    [CognitGPTPromptType.ORA_PotentialConsequences]: "potentialConsequences",
    [CognitGPTPromptType.ORA_PotentialConsequences_RewriteExisting]:
        "potentialConsequences",
    [CognitGPTPromptType.ORA_RiskReduction]: "riskReduction",
    [CognitGPTPromptType.ORA_RiskReduction_RewriteExisting]: "riskReduction",
    [CognitGPTPromptType.None]: undefined,
};
