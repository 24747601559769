import { IOption } from "../../common/IOption";
import k from "../../i18n/keys";

export enum ChecklistDynamicStatus {
    None,
    Behind,
    OnTrack,
    Finalized,
    Blocked,
    /* 
    this is in future
     */
    Scheduled,
    Stopped,
    Archived,
}

export function getColorsWithStatusKey(): Record<number, string> {
    return {
        [ChecklistDynamicStatus.Behind]: "#ff8282",
        [ChecklistDynamicStatus.OnTrack]: "#396aff",
        [ChecklistDynamicStatus.Finalized]: "#0db691",
        [ChecklistDynamicStatus.Blocked]: "#d3f2f2",
        [ChecklistDynamicStatus.Scheduled]: "#d9e3fb",
        [ChecklistDynamicStatus.Stopped]: "#ff2929",
        [ChecklistDynamicStatus.Archived]: "#d9e3fb",
    };
}

export const allChecklistStatuses: ReadonlyArray<IOption> = [
    {
        id: ChecklistDynamicStatus.None,
        label: k.ALL,
        variant: "light",
    },
    {
        id: ChecklistDynamicStatus.Behind,
        label: k.BEHIND,
        variant: "danger",
        param: "bg--red",
    },
    {
        id: ChecklistDynamicStatus.OnTrack,
        label: k.ON_TRACK,
        variant: "primary",
        param: "bg--blue",
    },
    {
        id: ChecklistDynamicStatus.Finalized,
        label: k.FINALIZED,
        variant: "success",
        param: "bg--green",
    },
    {
        id: ChecklistDynamicStatus.Blocked,
        label: k.BLOCKED,
        variant: "warning",
        param: "bg--blocked",
    },
    {
        id: ChecklistDynamicStatus.Scheduled,
        label: k.SCHEDULED,
        variant: "info",
        param: "bg--gray",
    },
    {
        id: ChecklistDynamicStatus.Stopped,
        label: k.STOPPED,
        variant: "info",
        param: "bg--danger",
    },
    {
        id: ChecklistDynamicStatus.Archived,
        label: k.ARCHIVED,
        variant: "secondary",
        param: "bg--gray",
    },
];

function castChecklistStatusesToDictionary() {
    return allChecklistStatuses.reduce<Record<number, IOption>>((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
    }, {});
}

export const allChecklistStatusesAsDictionary: Readonly<
    Record<number, IOption>
> = castChecklistStatusesToDictionary();
