import "./MarketingSalesIcon.scss";

import React from "react";
import Icon from "./assets/marketing-sales-icon.svg";

const MarketingSalesIcon: React.FC = (props) => {
    return <Icon className="marketing-sales-icon" />;
};

export default MarketingSalesIcon;
