import { FC } from "react";
import {
    EditButton,
    PrintButton,
    StopResumeButton,
    Menu,
    CloseButton,
    HistoryButton,
} from "./components";

import ListedContent from "common/components/listed-content/ListedContent";

interface ISidePanelHeaderInputProps {}
const SidePanelHeader: FC<ISidePanelHeaderInputProps> = () => {
    return (
        <ListedContent.SidePanel.Header>
            <CloseButton />
            <div className="side-panel-header__buttons">
                <EditButton />
                <PrintButton />
                <StopResumeButton />
                <HistoryButton />
                <Menu />
            </div>
        </ListedContent.SidePanel.Header>
    );
};

export default SidePanelHeader;
