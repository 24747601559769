import "./RecurringChecklistForm.scss";

import React, { useMemo } from "react";

import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Button from "common/components/buttons/Button";
import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";
import { WorkflowScheduleInterval as WSI } from "components/recurring-checklist/api/WorkflowScheduleIntervals";
import RecurringChecklistAdd from "./components/RecurringChecklistAdd";
import RecurringChecklistEditView from "./components/RecurringChecklistEditView";
import { WorkflowScheduleStatus } from "./api/WorkflowScheduleStatus";
import { useRecurringChecklistById } from "./api/hooks";
import { useTemplateOptionById } from "components/templates/api/hooks";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";

import ModalContent from "common/components/modal-content/ModalContent";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";

interface IProps {
    id?: string;
    createNew?: boolean;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
    onPublishNew: () => void;
    onChangeRecurrence: (id: string) => void;
}

const RecurringChecklistForm: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { id, createNew } = props;

    const isCreateNew = createNew ?? false;

    const { t } = useTranslation();

    const { data: recurringChecklist, isLoading: isRecurringChecklistLoading } =
        useRecurringChecklistById(isCreateNew ? undefined : id);

    const { data: template, isLoading: isTemplateLoading } =
        useTemplateOptionById(
            isCreateNew ? id : recurringChecklist?.templateId,
            isCreateNew,
        );

    const recurringData = useMemo(() => {
        if (isCreateNew) {
            const newValue: IRecurringChecklist = {
                id: uuidv4(),
                name: "",
                templateId: id ?? "",
                intervalType: WSI.Weekly,
                intervalNumber: 1,
                isStartDate: true,
                isEndDate: true,
                isDayOfMonth: false,
                duration: { days: 0, hours: 12, minutes: 0 },
            };

            return newValue;
        } else {
            return recurringChecklist;
        }
    }, [isCreateNew, id, recurringChecklist]);

    const handleClose = () => {
        props.onClose();
    };

    const isAddNewOrEditDraft =
        isCreateNew || recurringData?.status === WorkflowScheduleStatus.Draft;

    if (isRecurringChecklistLoading || isTemplateLoading) {
        return (
            <AnimatedSpinner
                isVisible={true}
                position="center"
                aligned="center"
            />
        );
    } else if (recurringData === undefined || template === undefined) {
        return (
            <NotAvailableModal
                onClose={props.onClose}
                message={t(k.NO_RECURRING_OR_NOT_EXIST)}
            />
        );
    }

    return (
        <>
            {isAddNewOrEditDraft && (
                <RecurringChecklistAdd
                    recurringData={recurringData}
                    template={template}
                    onHaveChanges={props.onHaveChanges}
                    onClose={props.onClose}
                    onPublish={props.onPublishNew}
                    onChangeRecurrence={props.onChangeRecurrence}
                />
            )}

            {recurringChecklist?.status ===
                WorkflowScheduleStatus.Published && (
                <RecurringChecklistEditView
                    recurringData={recurringData}
                    template={template}
                    onHaveChanges={props.onHaveChanges}
                    onClose={props.onClose}
                    onChangeRecurrence={props.onChangeRecurrence}
                />
            )}
        </>
    );
};

export default RecurringChecklistForm;
