import "../RecurringChecklistForm.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import IRecurringChecklist from "components/recurring-checklist/api/IRecurringChecklist";

import DatePicker from "common/components/date-picker/DatePicker";
import Textfield from "common/components/input-components/textfield/Textfield";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { IRecurringChecklistDuration } from "../api/IRecurringChecklistDuration";

interface IProps {
    startDate?: Date;

    duration?: IRecurringChecklistDuration;

    isStartDate: boolean;

    errors: IValidationMessages;

    onChange: <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => void;

    onBlur?: (id?: string, name?: string) => void;
}

const RecurringChecklistStartAt: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { startDate, duration, isStartDate, errors } = props;

    const { t } = useTranslation();

    const handleChangeDate = (date: Date) => {
        if (date !== startDate) {
            props.onChange(date, "startDate");
        }
    };

    const handleStartAtDueOn = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === "1";

        if (isStartDate !== value) {
            props.onChange(value, "isStartDate");
        }
    };

    const handleChangeDuration = (value: string, id?: string) => {
        if (id) {
            const val = parseInt(value);

            const durationNext: IRecurringChecklistDuration = {
                ...duration,

                [id]: isNaN(val) ? undefined : val,
            };

            props.onChange(durationNext, "duration");
        }
    };

    const handleOnBlur = (id?: string, name?: string) => {
        if (props.onBlur) {
            props.onBlur(`duration.${id}`);
        }
    };

    return (
        <div className="r-c--body--first">
            <div className="r-c--body--first--column">
                <div className="r-c--body--first--row">
                    {t(k.FIRST_CHECKLIST)}
                    <div className="r-c--body--first--starts-due">
                        <input
                            type="radio"
                            value="1"
                            id="startsAt"
                            checked={isStartDate}
                            onChange={handleStartAtDueOn}
                        />
                        <label htmlFor="startsAt">{t(k.STARTS_AT)}</label>
                        <input
                            type="radio"
                            value=""
                            id="dueOn"
                            checked={isStartDate ? false : true}
                            onChange={handleStartAtDueOn}
                        />
                        <label htmlFor="dueOn">
                            <span>{t(k.DUE_ON)}</span>
                        </label>
                    </div>
                </div>
                <DatePicker
                    value={startDate}
                    onChange={handleChangeDate}
                    showTimeSelect
                    timeIntervals={15}
                    placeholder={isStartDate ? t(k.START_DATE) : t(k.DUE_DATE)}
                    invalid={!!errors.startDate}
                    error={errors.startDate && t(errors.startDate)}
                />
            </div>
            <div className="r-c--body--first--column">
                <div className="text-field">
                    {t(k.DURATION)}
                    <div className="text-field--helper-text">
                        <ValidationLabel
                            errors={errors.duration && t(errors.duration)}
                        />
                    </div>
                </div>

                <div className="r-c--body--first--dur">
                    <Textfield
                        id="days"
                        value={String(duration?.days ?? "")}
                        onChange={handleChangeDuration}
                        onBlur={handleOnBlur}
                        placeholder={t(k.D_AYS)}
                        type="number"
                        isWidthMinimized={true}
                        postInputContent={t(k.DAYS)}
                        invalid={
                            !!errors["duration.days"] ||
                            !!errors.days ||
                            !!errors.duration
                        }
                        helperText={
                            <ValidationLabel
                                errors={
                                    (errors["duration.days"] &&
                                        t(errors["duration.days"])) ||
                                    (errors.days && t(errors.days))
                                }
                            />
                        }
                    />
                    <Textfield
                        id="hours"
                        value={String(duration?.hours ?? "")}
                        onChange={handleChangeDuration}
                        onBlur={handleOnBlur}
                        placeholder={t(k.H_OURS)}
                        type="number"
                        isWidthMinimized={true}
                        postInputContent={t(k.HOURS)}
                        invalid={
                            !!errors["duration.hours"] ||
                            !!errors.hours ||
                            !!errors.duration
                        }
                        helperText={
                            <ValidationLabel
                                errors={
                                    (errors["duration.hours"] &&
                                        t(errors["duration.hours"])) ||
                                    (errors.hours && t(errors.hours))
                                }
                            />
                        }
                    />
                    <Textfield
                        id="minutes"
                        value={String(duration?.minutes ?? "")}
                        onChange={handleChangeDuration}
                        onBlur={handleOnBlur}
                        placeholder={t(k.M_INUTES)}
                        type="number"
                        isWidthMinimized={true}
                        postInputContent={t(k.MIN_UTES)}
                        invalid={
                            !!errors["duration.minutes"] ||
                            !!errors.minutes ||
                            !!errors.duration
                        }
                        helperText={
                            <ValidationLabel
                                errors={
                                    (errors["duration.minutes"] &&
                                        t(errors["duration.minutes"])) ||
                                    (errors.minutes && t(errors.minutes))
                                }
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default RecurringChecklistStartAt;
