import {
    AppLocale,
    LocaleId,
    formatDecimalWithLocale,
    parseDecimalWithLocale,
} from "AppLocale";
import { FormulaItemType } from "../api/FormulaItemType";

import { IFormulaItemDto } from "../api/IFormulaItemDto";

import { IVariableDto } from "../api/IVariableDto";
import ITableSet from "http/ITableSet";
import k from "i18n/keys";

import { v4 as uuidv4 } from "uuid";

export enum EFormulaCategory {
    Unset = "unset",
    Variable = "variable",
    StaticNumber = "staticNumber",
    Operator = "operator",
    Parenthesis = "parenthesis",
}

export const FormulaTypeLocale = {
    [EFormulaCategory.Unset]: "",
    [EFormulaCategory.Variable]: k.VARIABLES,
    [EFormulaCategory.StaticNumber]: k.STATIC_NUMBERS,
    [EFormulaCategory.Operator]: k.OPERATORS,
    [EFormulaCategory.Parenthesis]: k.PARENTHESES,
};

export const FormulaCategoryDefault = {
    [EFormulaCategory.Unset]: "",
    [EFormulaCategory.Variable]: "x",
    [EFormulaCategory.StaticNumber]: "1",
    [EFormulaCategory.Operator]: "+",
    [EFormulaCategory.Parenthesis]: "(",
};

export const FormulaItemTypeDefault = {
    [EFormulaCategory.Unset]: FormulaItemType.None,
    [EFormulaCategory.Variable]: FormulaItemType.Variable,
    [EFormulaCategory.StaticNumber]: FormulaItemType.Constant,
    [EFormulaCategory.Operator]: FormulaItemType.Plus,
    [EFormulaCategory.Parenthesis]: FormulaItemType.OpenParenthesis,
};
export interface IFormulaItem {
    id: string;
    index?: number;
    value: string;
    category: EFormulaCategory;
    type: FormulaItemType;
    variable?: IVariableDto;
}
/* 
Åsna-Pär-51+Märta*(Kurt--34-aNNa)-12/69+åÄö

--51.15-Åsna-Pär-51+Märta*(Kurt--34.69-aNNa)+acf+-13*-12.96/69+åÄö /Test string
[-,-51.15,-,Åsna-Pär,-,51,+,Märta,*,(,Kurt,-,-34.69,-,aNNa,),+,acf,+,-13,*,-12.96,/,69,+,åÄö] /Expected result
*/
export const validCharactersRegEx = /^[a-zA-ZåäöÅÄÖ0-9 \-+*/%^()]$/g;
export const validDecimalSeparatorRegEx = () =>
    AppLocale.locale.code === LocaleId.Sv ? /,/g : /\./g;

export const formulaVariableRegEx = /^([a-zA-ZåäöÅÄÖ]+[a-zA-ZåäöÅÄÖ \-]*)$/g;
export const formulaOperatorRegEx = /^[+\-*/%^]$/g;

const formulaNumberDotRegEx = /^(-|-?[0-9]+(\.[0-9]*)?)$/g;
const formulaNumberCommaRegEx = /^(-|-?[0-9]+(\,[0-9]*)?)$/g;
export const formulaNumberRegEx = () =>
    AppLocale.locale.code === LocaleId.Sv
        ? formulaNumberCommaRegEx
        : formulaNumberDotRegEx;

export const formulaParenthesisRegEx = /^[()]$/g;

export const formulaEndsWithSpacesRegEx = / +$/g;
export const formulaEndsWithManySpacesRegEx = / {2,}$/g;

export const formulaEndsWithDashesRegEx = /-+$/g;

export const validVariableRegEx =
    /([a-zA-ZåäöÅÄÖ]+(?:[ \-]*[a-zA-ZåäöÅÄÖ]+)*)/g;
export const validOperatorRegEx = /-(?![0-9])|[+*/%^]/g;

export const validNumberRegEx = ({
    strict = false,
    decimalSeperator = AppLocale.locale.code === LocaleId.Sv ? "," : ".",
}: {
    strict?: boolean;
    decimalSeperator?: string;
} = {}) =>
    new RegExp(
        `${strict ? "^" : ""}-?[0-9]+(\\${decimalSeperator}[0-9]+)?${
            strict ? "$" : ""
        }`,
        "g",
    );

export const validParenthesisRegEx = /[()]/g;

export const splitString = (s: string) => {
    const operators = ["+", "*", "/", "%", "^", "("];
    const results = [];
    let buffer = "";

    for (let i = 0; i < s.length; i++) {
        const currentChar = s[i];
        const nextChar = s[i + 1];
        const prevChar = s[i - 1];

        if (operators.includes(currentChar) || currentChar === ")") {
            if (buffer) {
                results.push(buffer.trim());
                buffer = "";
            }
            results.push(currentChar);
        } else if (currentChar === "-") {
            if (
                nextChar &&
                !isNaN(parseDecimalWithLocale(nextChar)) &&
                (results.length === 0 ||
                    operators.includes(prevChar) ||
                    prevChar === "-")
            ) {
                if (buffer) {
                    results.push(buffer.trim());
                    buffer = "";
                }
                buffer += currentChar;
            } else {
                if (buffer) {
                    results.push(buffer.trim());
                    buffer = "";
                }
                results.push(currentChar);
            }
        } else if (
            currentChar.match(validDecimalSeparatorRegEx()) &&
            prevChar &&
            prevChar.match(validNumberRegEx())
        ) {
            buffer += currentChar;
        } else if (currentChar.match(validCharactersRegEx)) {
            buffer += currentChar;
        }
    }
    if (buffer) {
        results.push(buffer.trim());
    }

    return results;
};

export const getCategoryByValue = (value?: string) => {
    if (!value) {
        return EFormulaCategory.Unset;
    }

    if (value.match(validVariableRegEx)) {
        return EFormulaCategory.Variable;
    }

    if (value.match(validOperatorRegEx)) {
        return EFormulaCategory.Operator;
    }

    if (value.match(validNumberRegEx())) {
        return EFormulaCategory.StaticNumber;
    }

    if (value.match(validParenthesisRegEx)) {
        return EFormulaCategory.Parenthesis;
    }

    return EFormulaCategory.Unset;
};

export const createFormulaItems = (items: string[]) => {
    return items.reduce((acc, x) => {
        if (x.match(validVariableRegEx)) {
            acc.push({
                id: uuidv4(),
                value: x,
                category: EFormulaCategory.Variable,
                type: FormulaItemType.Variable,
            });

            return acc;
        }

        if (x.match(validOperatorRegEx)) {
            acc.push({
                id: uuidv4(),
                value: x,
                category: EFormulaCategory.Operator,
                type: getFormulaItemType(x, EFormulaCategory.Operator),
            });

            return acc;
        }

        if (x.match(validNumberRegEx())) {
            acc.push({
                id: uuidv4(),
                value: x,
                category: EFormulaCategory.StaticNumber,
                type: FormulaItemType.Constant,
            });

            return acc;
        }

        if (x.match(validParenthesisRegEx)) {
            acc.push({
                id: uuidv4(),
                value: x,
                category: EFormulaCategory.Parenthesis,
                type: getFormulaItemType(x, EFormulaCategory.Parenthesis),
            });

            return acc;
        }

        return acc;
    }, [] as IFormulaItem[]);
};

const getFormulaItemValueAndCategory = (
    type: FormulaItemType,
    value?: number,
    variableName?: string,
) => {
    switch (type) {
        case FormulaItemType.Variable:
            return {
                value: variableName ?? "x",
                category: EFormulaCategory.Variable,
            };
        case FormulaItemType.Constant:
            return {
                value:
                    (value !== undefined && formatDecimalWithLocale(value)) ||
                    "1",
                category: EFormulaCategory.StaticNumber,
            };
        case FormulaItemType.OpenParenthesis:
            return {
                value: "(",
                category: EFormulaCategory.Parenthesis,
            };
        case FormulaItemType.ClosedParenthesis:
            return {
                value: ")",
                category: EFormulaCategory.Parenthesis,
            };
        case FormulaItemType.Plus:
            return {
                value: "+",
                category: EFormulaCategory.Operator,
            };
        case FormulaItemType.Minus:
            return {
                value: "-",
                category: EFormulaCategory.Operator,
            };
        case FormulaItemType.Multiply:
            return {
                value: "*",
                category: EFormulaCategory.Operator,
            };
        case FormulaItemType.Divide:
            return {
                value: "/",
                category: EFormulaCategory.Operator,
            };
        case FormulaItemType.Modulo:
            return {
                value: "%",
                category: EFormulaCategory.Operator,
            };
        case FormulaItemType.Power:
            return {
                value: "^",
                category: EFormulaCategory.Operator,
            };
        default:
            return {
                value: "",
                category: EFormulaCategory.Unset,
            };
    }
};

export const getFormulaItemType = (
    value: string,
    category?: EFormulaCategory,
) => {
    if (!category) {
        return FormulaItemType.None;
    }
    switch (category) {
        case EFormulaCategory.Variable:
            return FormulaItemType.Variable;
        case EFormulaCategory.StaticNumber:
            return FormulaItemType.Constant;
        case EFormulaCategory.Parenthesis:
            switch (value) {
                case "(":
                    return FormulaItemType.OpenParenthesis;
                case ")":
                    return FormulaItemType.ClosedParenthesis;
                default:
                    return FormulaItemType.None;
            }
        case EFormulaCategory.Operator:
            switch (value) {
                case "+":
                    return FormulaItemType.Plus;
                case "-":
                    return FormulaItemType.Minus;
                case "*":
                    return FormulaItemType.Multiply;
                case "/":
                    return FormulaItemType.Divide;
                case "%":
                    return FormulaItemType.Modulo;
                case "^":
                    return FormulaItemType.Power;
                default:
                    return FormulaItemType.None;
            }
        default:
            return FormulaItemType.None;
    }
};

export const getFormulaItemTypeByValue = (value: string) => {
    if (!value) {
        return FormulaItemType.None;
    }

    if (value.match(validVariableRegEx)) {
        return FormulaItemType.Variable;
    }

    if (value.match(validNumberRegEx())) {
        return FormulaItemType.Constant;
    }

    if (value === "(") {
        return FormulaItemType.OpenParenthesis;
    }

    if (value === ")") {
        return FormulaItemType.ClosedParenthesis;
    }

    if (value === "+") {
        return FormulaItemType.Plus;
    }

    if (value === "-") {
        return FormulaItemType.Minus;
    }

    if (value === "*") {
        return FormulaItemType.Multiply;
    }

    if (value === "/") {
        return FormulaItemType.Divide;
    }

    if (value === "%") {
        return FormulaItemType.Modulo;
    }

    if (value === "^") {
        return FormulaItemType.Power;
    }

    return FormulaItemType.None;
};

export const convertToFormulaItems = (items?: IFormulaItemDto[]) => {
    if (!items) {
        return { ids: [], values: {} };
    }
    return items.reduce<ITableSet<IFormulaItem>>(
        (acc, x) => {
            const { value, category } = getFormulaItemValueAndCategory(
                x.type ?? FormulaItemType.None,
                x.value,
                x.variable?.name,
            );
            acc.ids.push(x.id);
            acc.values[x.id] = {
                id: x.id,
                index: x.index,
                value: value,
                category: category,
                type: x.type,
                variable: x.variable,
            };

            return acc;
        },
        { ids: [], values: {} },
    );
};

const getItemDto = (item: IFormulaItem, index: number): IFormulaItemDto => {
    switch (item.category) {
        case EFormulaCategory.Variable:
            return {
                ...item,
                index: index,
                variable: {
                    ...item.variable,
                    name: item.value,
                },
                value: 0,
            };
        case EFormulaCategory.StaticNumber:
            return {
                ...item,
                index: index,
                value: parseDecimalWithLocale(item.value),
            };
        case EFormulaCategory.Parenthesis:
        case EFormulaCategory.Operator:
        default:
            return {
                ...item,
                index: index,
                value: 0,
            };
    }
};

export const convertToFormulaItemsDto = (items: ITableSet<IFormulaItem>) => {
    const filteredIds = items.ids.filter(
        (id) => items.values[id]?.category !== EFormulaCategory.Unset,
    );

    const filteredItems = filteredIds.map((id, index) => {
        const item = items.values[id];
        if (item) {
            return getItemDto(item, index);
        }
    }) as IFormulaItemDto[];

    return filteredItems;
};

export const makeTableSet = (
    items?: string[] | IFormulaItem[] | null,
): ITableSet<IFormulaItem> => {
    if (!items || items.length === 0) {
        return { ids: [], values: {} };
    }

    const formulaItems =
        typeof items[0] === "string"
            ? createFormulaItems(items as string[])
            : (items as IFormulaItem[]);

    return formulaItems.reduce<ITableSet<IFormulaItem>>(
        (acc, item) => {
            acc.ids.push(item.id);
            acc.values[item.id] = item;

            return acc;
        },
        { ids: [], values: {} },
    );
};

export const getUnsetItem = (id?: string): IFormulaItem => ({
    id: id ?? uuidv4(),
    category: EFormulaCategory.Unset,
    type: FormulaItemType.None,
    value: "",
});

export const convertFormulaDtoArrayToString = (
    items: IFormulaItemDto[],
    seperator = "",
) => {
    return items
        .reduce((acc, x) => {
            if (x.type) {
                const temp = getFormulaItemValueAndCategory(
                    x.type,
                    x.value,
                    x.variable?.name,
                );
                acc.push(
                    x.type === FormulaItemType.Variable
                        ? `"${temp.value}"`
                        : temp.value,
                );
            }
            return acc;
        }, [] as string[])
        .join(seperator);
};
