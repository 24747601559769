import { ESharedOptionsType } from "common/components/input-components/dropdown/api/hooks";
import { CustomListPropertyEnum } from "../../custom-list-page/api/CustomListPropertyEnum";

export const showFilterCustomListTypes: CustomListPropertyEnum[] = [
    CustomListPropertyEnum.None,
    CustomListPropertyEnum.Tasklist,
    CustomListPropertyEnum.Selection,
    CustomListPropertyEnum.Items,
    CustomListPropertyEnum.Suppliers,
    CustomListPropertyEnum.Customers,
    CustomListPropertyEnum.Users,
    CustomListPropertyEnum.Positions,
    CustomListPropertyEnum.Competencies,
];

export const sharedOptionTypeByPropertyType: {
    [key in CustomListPropertyEnum]?: ESharedOptionsType;
} = {
    [CustomListPropertyEnum.Customers]: ESharedOptionsType.customer,
    [CustomListPropertyEnum.Items]: ESharedOptionsType.item,
    [CustomListPropertyEnum.Suppliers]: ESharedOptionsType.supplier,
    [CustomListPropertyEnum.Users]: ESharedOptionsType.user,
    [CustomListPropertyEnum.Positions]: ESharedOptionsType.position,
    [CustomListPropertyEnum.Competencies]: ESharedOptionsType.competency,
};
