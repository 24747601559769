import "./ResourcesIcon.scss";

import React from "react";

import Icon from "./assets/resources-icon.svg";

const ResourcesIcon: React.FC = (props) => {
    return <Icon className="resources-icon" />;
};

export default ResourcesIcon;
