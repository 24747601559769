import "./TeamsList.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Badge from "../badges/Badge";
import { TeamIcon } from "../icons";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    teams?: IValueLabelItemWithState<string, string, any>[];

    isTeamEveryone?: boolean;

    icon?: React.ReactNode | null;
    haveWordWrap?: boolean;
    showTitle?: boolean;
    fullWidth?: boolean;

    testId?: string;
}

const TeamsList: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        teams,
        isTeamEveryone,
        icon,
        testId,
        haveWordWrap,
        showTitle,
        fullWidth,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`teams-list__wrapper ${fullWidth ? "full-width" : ""}`}>
            {icon !== null && (icon ?? <TeamIcon />)}

            <div
                className={`teams-list--badges ${
                    fullWidth ? "full-width" : ""
                }`}
                data-testid={testId}
            >
                {isTeamEveryone && (
                    <Badge pill variant="info" textEllipsis={haveWordWrap}>
                        {t(k.EVERYONE)}
                    </Badge>
                )}

                {teams?.map((team) => (
                    <Badge
                        key={team.value}
                        pill
                        variant="info"
                        title={showTitle ? team.label : undefined}
                        textEllipsis={haveWordWrap}
                    >
                        {team.label}
                        {team.isArchived ? (
                            team.isDeleted ? (
                                <>&nbsp; ({t(k.DELETED)})</>
                            ) : (
                                <>&nbsp; ({t(k.ARCHIVED)})</>
                            )
                        ) : null}
                    </Badge>
                ))}
            </div>
        </div>
    );
};

export default TeamsList;
