import { IModalFor, IModalListItem, IModalOptions } from "../api/IModalManager";
import {
    ModalOptionsObjectify,
    ModalOptionsStringify,
} from "./ModalOptionsUtils";

export const getModalParams = (search: string) => {
    const query = new URLSearchParams(search);

    const type = query.get("modal") ?? undefined;
    const id = query.get("id") ?? undefined;

    const modalFor = parseModalFor(query.get("for") ?? undefined);

    const getOptions = query.get("options");
    const options: IModalOptions | undefined = getOptions
        ? ModalOptionsObjectify(getOptions)
        : undefined;

    return { type, id, options, modalFor };
};

export const makeModalParams = (modalItem: IModalListItem) => {
    const { type: modalType, id, modalFor, options, customList } = modalItem;
    let result = `?modal=${customList ? customList.path : modalType}`;

    if (id) {
        result += `&id=${id}`;
    }

    if (modalFor) {
        result += `&for=${modalFor.type},${modalFor.id}`;
    }

    if (options) {
        result += "&options=" + ModalOptionsStringify(options);
    }

    return result;
};

const parseModalFor = (modalFor?: string): IModalFor | undefined => {
    if (modalFor) {
        const [type, id] = modalFor.split(",");

        return { type, id };
    }
};
