import { useContext } from "react";
import ChecklistDetailsContext from "../../../../../checklistContext";
import { useTemplateByChecklistId } from "components/templates/api/hooks";

const Description = () => {
    const { selectedChecklistId } = useContext(ChecklistDetailsContext);

    const { data: template } = useTemplateByChecklistId(selectedChecklistId);

    return template?.description ? <>{template?.description}</> : null;
};

export default Description;
