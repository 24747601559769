import { createRef, RefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    useCanEditChecklist,
    useChecklistById,
    useStepInstancesActivitiesByChecklistId,
    useStepInstancesByChecklistId,
} from "../../api/hooks";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { IAppState } from "store/IAppState";
import IEditValues from "common/components/listed-content/models/IEditValues";
import { IChecklistDetailsProps } from "./ChecklistListedContent";
import { IChecklistData } from "./models/IChecklistData";
import IDictionary from "common/viewModels/IDictionary";
import IActivityInstance from "models/IActivityInstance";
import { IChangedStepInstance } from "http/requests/IWorkflowRunRequest";
import ITableSet from "http/ITableSet";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";

const SyncIntervalMs = 5000;

export const useChecklistData = (
    props: IChecklistDetailsProps,
): IChecklistData => {
    const {
        id,
        checkCanSeeAll,
        syncData,
        initialStepInstanceId,
        onClose,
        onHaveChanges,
        onShowModal,
    } = props;

    const roles = useSelector((appState: IAppState) => {
        return appState.authViewState.roles;
    });

    const profile = useSelector((appState: IAppState) => {
        return appState.authViewState.profile;
    });

    const lastFinalizedStepInstanceId = useSelector((appState: IAppState) => {
        return appState.stepInstanceViewState.lastFinalizedStepInstanceId;
    });

    const [editMode, setEditMode] = useState(false);

    const [editedChecklistValues, setEditedChecklistValues] =
        useState<IEditValues>();

    const [errors, setErrors] = useState<IValidationMessages>();

    const [editedSteps, setEditedSteps] =
        useState<IDictionary<IChangedStepInstance>>();

    const [usersAffected, setUsersAffected] = useState<IDictionary<string[]>>();

    const [isChecklistInvisible, setIsChecklistInvisible] = useState(false);

    const [stepRefs, setStepRefs] =
        useState<IDictionary<RefObject<HTMLDivElement>>>();

    const [numberOfRemovedSteps, setNumberOfRemovedSteps] = useState(0);

    const { data: checklist, isLoading: isLoadingChecklist } = useChecklistById(
        true,
        checkCanSeeAll,
        id,
        syncData ? SyncIntervalMs : undefined,
    );

    const { data: canEdit } = useCanEditChecklist(id);

    const isChecklistFinalized =
        checklist?.status === ChecklistDynamicStatus.Finalized;

    const { data: stepInstances, isLoading: isLoadingStepInstances } =
        useStepInstancesByChecklistId(
            syncData,
            checkCanSeeAll,
            id,
            isChecklistFinalized ? undefined : SyncIntervalMs,
        );

    const {
        data: activities,
        isLoading: isLoadingActivityInstances,
        refetch: refetchActivities,
    } = useStepInstancesActivitiesByChecklistId(
        syncData,
        checkCanSeeAll,
        id,
        isChecklistFinalized ? undefined : SyncIntervalMs,
    );

    const [activityInstances, setActivityInstances] = useState<
        ITableSet<IActivityInstance> | undefined
    >();

    const [activityInstanceIdsBySet, setActivityInstanceIdsBySet] = useState<
        IDictionary<string[]> | undefined
    >();

    const {
        activityInstances: ai,
        activityInstancesIdsByActivityId: idsBySet,
    } = activities ?? {};

    useEffect(() => {
        if (!activities) return;

        let stillNotSavedValues;
        let stillNotSavedIds;
        if (activityInstances) {
            //before updating with server data
            //get all the new unsaved instances
            const temp = filter(activityInstances.values, {
                edit: true,
            });

            stillNotSavedValues = mapValues(keyBy(temp, "id"));
            stillNotSavedIds = Object.keys(stillNotSavedValues);

            let instancesIdsBySet;
            let ids: IDictionary<string[]> = {};
            let merged: IDictionary<string[]> = {};

            if (stillNotSavedIds.length > 0) {
                instancesIdsBySet = mapValues(
                    keyBy(Object.values(stillNotSavedValues), "id"),
                    "activityInstanceSetId",
                );
                ids = Object.entries(instancesIdsBySet).reduce(
                    (acc: IDictionary<string[]>, [k, v]) => {
                        acc[v] = acc[v] ? [...acc[v], k] : [k];
                        return acc;
                    },
                    {},
                );

                merged = ids;

                if (idsBySet) {
                    const allKeys = new Set([
                        ...Object.keys(idsBySet ?? {}),
                        ...Object.keys(ids ?? {}),
                    ]);

                    merged = Array.from(allKeys).reduce(
                        (acc: IDictionary<string[]>, k) => {
                            acc[k] = [
                                ...new Set([
                                    ...(idsBySet[k] ?? []),
                                    ...(ids[k] ?? []),
                                ]),
                            ];
                            return acc;
                        },
                        {},
                    );
                }
                setActivityInstances({
                    values: {
                        ...ai,
                        ...(stillNotSavedValues ?? {}),
                    },
                    ids: [
                        ...Object.keys(ai ?? {}),
                        ...(stillNotSavedIds ?? []),
                    ],
                });
                setActivityInstanceIdsBySet(merged);
            } else {
                setActivityInstances({
                    values: {
                        ...activities?.activityInstances,
                    },
                    ids: [...Object.keys(activities.activityInstances)],
                });
                setActivityInstanceIdsBySet(idsBySet);
            }
        } else {
            setActivityInstances({
                values: {
                    ...activities?.activityInstances,
                },
                ids: [...Object.keys(activities.activityInstances)],
            });
            setActivityInstanceIdsBySet(idsBySet);
        }
    }, [activities]);

    useEffect(() => {
        if (
            numberOfRemovedSteps > 0 &&
            (!stepInstances || stepInstances?.ids.length == 0)
        ) {
            onClose(true);
        } else if (
            stepInstances &&
            (stepRefs === undefined ||
                stepInstances.ids.length != Object.keys(stepRefs).length)
        ) {
            setStepRefs(
                stepInstances.ids.reduce<{
                    [key: string]: React.RefObject<HTMLDivElement>;
                }>((prev, cur) => {
                    return { ...prev, [cur]: createRef() };
                }, {}),
            );
        }
    }, [stepInstances, numberOfRemovedSteps]);

    const initialSection =
        initialStepInstanceId ?? (stepInstances && stepInstances.ids[0]);

    const isLoading =
        isLoadingChecklist &&
        isLoadingStepInstances &&
        isLoadingActivityInstances;

    return {
        checklist,
        editMode,
        setEditMode,
        editedChecklistValues,
        setEditedChecklistValues,
        errors,
        setErrors,
        editedSteps,
        setEditedSteps,
        usersAffected,
        setUsersAffected,

        isLoading,
        isLoadingChecklist,
        isLoadingActivityInstances,

        isChecklistInvisible,
        setIsChecklistInvisible,

        numberOfRemovedSteps,
        setNumberOfRemovedSteps,

        sectionRefs: stepRefs,

        canEdit,
        selectedChecklistId: id,
        checkCanSeeAll,

        stepInstances,
        activityInstanceSets: activities?.activityInstanceSets,
        activityInstances,
        setActivityInstances,
        activityInstanceSetIdsByStepInstance:
            activities?.activityInstanceSetIdsByStepInstanceId,
        activityInstanceIdsBySet,
        setActivityInstanceIdsBySet,

        initialSection,

        roles,
        profile,
        lastFinalizedStepInstanceId,

        onClose,
        onHaveChanges,
        onShowModal,
        refetchActivities,
    };
};
