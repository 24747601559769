import "./AttachmentList.scss";

import React from "react";

import IAttachment from "models/IAttachment";
import AttachmentListItem from "./AttachmentListItem";

interface IProps {
    isLoading?: boolean;

    attachments?: IAttachment[];
}

const AttachmentList: React.FC<IProps> = (props) => {
    const { isLoading, attachments } = props;

    if (isLoading || attachments?.length) {
        return (
            <div
                className={`attachment-list__wrapper ${
                    isLoading ? "is-loading" : ""
                } no-print`}
            >
                {attachments?.map((attachment) => (
                    <AttachmentListItem
                        key={attachment.id}
                        attachment={attachment}
                    />
                ))}
            </div>
        );
    }

    return null;
};

export default AttachmentList;
