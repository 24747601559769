import "./OpenImprovements.scss";

import React from "react";
import { DeviationsIcon } from "common/components/icons";

interface IProps {
    value: number;
}

const OpenImprovements: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className="open-improvements">
            <DeviationsIcon />
            {props.value}
        </div>
    );
};

export default OpenImprovements;
