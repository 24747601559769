import axios from "axios";
import IFinalizeStepInstanceRequest from "http/requests/IFinalizeStepInstanceRequest";
import IFinalizeStepInstanceDTO from "models/dto/IFinalizeStepInstanceDTO";
import { useMutation } from "react-query";

const baseUrl = "/api/stepinstance";

export const useFinalizeStepInstanceMutation = () => {
    return useMutation(async (value: IFinalizeStepInstanceRequest) => {
        const response = await axios.post<IFinalizeStepInstanceDTO>(
            `${baseUrl}/finalize`,
            value,
        );

        return response.data;
    });
};
