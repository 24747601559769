import React from "react";

import { IInputProps } from "../IInputProps";
import TextfieldComplex, {
    ITextfieldComplexProps,
} from "../textfield/TextfieldComplex";

type Props<V> = ITextfieldComplexProps & IInputProps<string>;

const Textarea = <V,>(props: Props<V>) => {
    return <TextfieldComplex {...props} multiline />;
};

export default Textarea;
