export enum Quarters {
    Q1,
    Q2,
    Q3,
    Q4,
}

export const QuartersOrder = [
    Quarters.Q1,
    Quarters.Q2,
    Quarters.Q3,
    Quarters.Q4,
];
