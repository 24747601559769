import React, { useEffect } from "react";

import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import { IInputWrapperProps } from "../InputWrapper";

import ImprovementStatusDropdownTree from "./ImprovementStatusDropdownTree";

interface IProps extends IInputWrapperProps {
    id?: string;
    disabled?: boolean;
    showRemove?: boolean;

    selectedStatuses: DynamicImprovementStatus[] | null;
    selectedClosureReasons: ClosureReasonEnum[] | null;

    onChange: (
        nextStatuses: DynamicImprovementStatus[],
        nextClosureReasons: ClosureReasonEnum[],
    ) => void;
}

const ImprovementStatusTreeWrapper: React.FC<IProps> = (props) => {
    const [selectedStatuses, setSelectedStatues] = React.useState<
        DynamicImprovementStatus[] | null
    >(null);
    const [selectedClosureReasons, setSelectedClosureReasons] = React.useState<
        ClosureReasonEnum[] | null
    >(null);

    const [hasSelectedItems, setHasSelectedItems] =
        React.useState<boolean>(false);

    const handleOnChange = (
        nextStatuses: DynamicImprovementStatus[],
        nextClosureReasons: ClosureReasonEnum[],
    ) => {
        setSelectedClosureReasons(nextClosureReasons);
        setSelectedStatues(nextStatuses);

        props.onChange(nextStatuses, nextClosureReasons);
    };

    const prevDefaultStatusValues = React.useRef<
        DynamicImprovementStatus[] | null
    >(null);

    const prevDefaultClosureReasonValues = React.useRef<
        ClosureReasonEnum[] | null
    >(null);

    useEffect(() => {
        if (
            props.selectedStatuses &&
            prevDefaultStatusValues.current === null
        ) {
            setSelectedStatues(props.selectedStatuses ?? []);
        }
        if (
            props.selectedClosureReasons &&
            prevDefaultClosureReasonValues.current === null
        ) {
            setSelectedClosureReasons(props.selectedClosureReasons ?? []);
        }
        prevDefaultStatusValues.current = props.selectedStatuses;
        prevDefaultClosureReasonValues.current = props.selectedClosureReasons;
    }, [props.selectedStatuses, props.selectedClosureReasons]);

    useEffect(() => {
        setHasSelectedItems(
            (selectedClosureReasons != null &&
                selectedClosureReasons.length > 0) ||
                (selectedStatuses != null && selectedStatuses.length > 0),
        );
    }, [selectedClosureReasons, selectedStatuses]);

    return (
        <ImprovementStatusDropdownTree
            {...props}
            selectedClosureReasons={selectedClosureReasons}
            selectedStatuses={selectedStatuses}
            hasSelectedItems={hasSelectedItems}
            onChange={handleOnChange}
        />
    );
};

export default ImprovementStatusTreeWrapper;
