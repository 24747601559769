import "./RenderFormula.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { Button } from "common/components/buttons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { IoAdd, IoClose, IoRemove } from "react-icons/io5";
import { CgMathDivide } from "react-icons/cg";

import { BsPercent } from "react-icons/bs";

import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";

import {
    IFormulaItem,
    formulaNumberRegEx,
    formulaVariableRegEx,
} from "../../../utility/FormulaTools";

import { EditIcon } from "common/components/icons";

import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { IValueLabelItem } from "common/IValueLabelItem";
import { useMemo } from "react";
import { ActionMeta, OnChangeValue, StylesConfig } from "react-select";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";

interface IFormulaItemProps {
    item: IFormulaItem;
    onAddDataSource?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    onChangeValue?: (e: IInputChangeEvent<string>) => void;
    onSelectOperator?: (selected: string) => void;
}

export const FormulaVariable = (
    props: React.PropsWithChildren<IFormulaItemProps>,
) => {
    const { item } = props;
    const { t } = useTranslation();

    const handleOnChangeValue = (e: IInputChangeEvent<string>) => {
        const inputValue = e.value;
        const filteredValue =
            inputValue.match(formulaVariableRegEx)?.join(" ") ?? "";
        e.value = filteredValue;
        props.onChangeValue?.(e);
    };

    return (
        <>
            <InputComponent
                inputType={EActivityType.Textfield}
                type="text"
                hideIcon
                noMinWidth
                className="render-formula--item"
                value={item.value}
                onChange={handleOnChangeValue}
            />
            <Button
                icon={item.variable?.dataSource ? <EditIcon /> : <IoAdd />}
                size="small"
                noMinWidth
                variant="success"
                className="render-formula--item--add-data-source"
                transparent
                onClick={props.onAddDataSource}
            >
                {t(k.DATA_SOURCE)}
            </Button>
        </>
    );
};

export const FormulaNumber = (
    props: React.PropsWithChildren<IFormulaItemProps>,
) => {
    const { item } = props;

    const handleOnChangeNumber = (e: IInputActionChangeEvent<string>) => {
        const { value, target } = e;

        const numMatch = value.match(formulaNumberRegEx());

        const matchedNumber = numMatch && numMatch.length === 1 && numMatch[0];

        if (
            (matchedNumber && matchedNumber !== item.value) ||
            value.length === 0
        ) {
            props.onChangeValue?.(e);
        } else if (target) {
            const lengthDiff = value.length - item.value.length;

            const startPost = Math.max(
                (target.selectionStart ?? 0) - lengthDiff,
                0,
            );
            const endPost = Math.max(
                (target.selectionEnd ?? 0) - lengthDiff,
                0,
            );

            target.value = item.value;
            target.setSelectionRange(startPost, endPost);
        }
    };

    return (
        <>
            <ActionTextField
                id={item.id}
                noMinWidth
                value={item.value}
                onChange={handleOnChangeNumber}
            />
        </>
    );
};

export enum EOperatorType {
    Add = "+",
    Subtract = "-",
    Multiply = "*",
    Divide = "/",
    Power = "^",
    Modulo = "%",
}

const operatorIcons: { [key in EOperatorType]: React.ReactNode } = {
    [EOperatorType.Add]: <IoAdd />,
    [EOperatorType.Subtract]: <IoRemove />,
    [EOperatorType.Multiply]: <IoClose />,
    [EOperatorType.Divide]: <CgMathDivide />,
    [EOperatorType.Power]: <>{"^"}</>,
    [EOperatorType.Modulo]: <BsPercent />,
};

const operatorOptions: IValueLabelItem<string, JSX.Element>[] = [
    {
        value: EOperatorType.Add,
        label: <>{operatorIcons[EOperatorType.Add]}</>,
    },
    {
        value: EOperatorType.Subtract,
        label: <>{operatorIcons[EOperatorType.Subtract]}</>,
    },
    {
        value: EOperatorType.Multiply,
        label: <>{operatorIcons[EOperatorType.Multiply]}</>,
    },
    {
        value: EOperatorType.Divide,
        label: <>{operatorIcons[EOperatorType.Divide]}</>,
    },
    {
        value: EOperatorType.Power,
        label: <>{operatorIcons[EOperatorType.Power]}</>,
    },
    {
        value: EOperatorType.Modulo,
        label: <>{operatorIcons[EOperatorType.Modulo]}</>,
    },
];

export const singleSelectDropdownStyle: StylesConfig<OptionType, boolean> = {
    control: (styles) => ({
        ...styles,
        border: "none !important",
        backgroundColor: "transparent !important",
        padding: "0 !important",
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: "0 !important",
        minHeight: "38px",
        minWidth: "38px",
    }),
    menuPortal: (styles) => {
        return { ...styles, zIndex: 19000 };
    },
};

export const FormulaOperator = (
    props: React.PropsWithChildren<IFormulaItemProps>,
) => {
    const { item } = props;

    const selectedValue = useMemo(
        () => operatorOptions.filter((x) => x.value === item.value),
        [item],
    );

    const handleOnSelect = (
        value: OnChangeValue<OptionType, boolean>,
        actionMeta: ActionMeta<OptionType>,
    ) => {
        if (value) {
            if ("value" in value && value.value) {
                props.onSelectOperator?.(value.value as string);
            }
        }
    };

    return (
        <div className="render-formula--item--operator">
            <SelectDropdown
                menuPortalTarget={document.body}
                value={selectedValue}
                options={operatorOptions}
                onChange={handleOnSelect}
                styles={singleSelectDropdownStyle}
            />
        </div>
    );
};

export enum EParenthesisType {
    Open = "(",
    Close = ")",
}

const parenthesisIcons: { [key in EParenthesisType]: React.ReactNode } = {
    [EParenthesisType.Open]: <>{"("}</>,
    [EParenthesisType.Close]: <>{")"}</>,
};

const parenthesisOptions: IValueLabelItem<string, JSX.Element>[] = [
    {
        value: EParenthesisType.Open,
        label: <>{parenthesisIcons[EParenthesisType.Open]}</>,
    },
    {
        value: EParenthesisType.Close,
        label: <>{parenthesisIcons[EParenthesisType.Close]}</>,
    },
];

export const FormulaParenthesis = (
    props: React.PropsWithChildren<IFormulaItemProps>,
) => {
    const { item } = props;

    const selectedValue = useMemo(
        () => parenthesisOptions.filter((x) => x.value === item.value),
        [item],
    );

    const handleOnSelect = (
        value: OnChangeValue<OptionType, boolean>,
        actionMeta: ActionMeta<OptionType>,
    ) => {
        if (value) {
            if ("value" in value && value.value) {
                props.onSelectOperator?.(value.value as string);
            }
        }
    };

    return (
        <div className="render-formula--item--parenthesis">
            <SelectDropdown
                menuPortalTarget={document.body}
                value={selectedValue}
                options={parenthesisOptions}
                onChange={handleOnSelect}
                styles={singleSelectDropdownStyle}
            />
        </div>
    );
};
