import { IValueLabelItem } from "common/IValueLabelItem";
import i18n from "i18next";
import k from "i18n/keys";
import {
    ClosureReasonEnum,
    closureReasonsStatusStringsWithoutNone,
} from "./ClosureReasonEnum";
import { IDropdownTreeData } from "common/components/input-components/dropdown-tree/custom-version/IDropdownTreeData";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import { ITreeListOption } from "common/ITreeListOption";

export enum DynamicImprovementStatus {
    None,
    Registered,
    Analysing,
    Acting,
    Verifying,
    Closed,
}

export const dynamicImprovementStatusItems: Record<
    DynamicImprovementStatus,
    IValueLabelItem<DynamicImprovementStatus, string, string>
> = {
    [DynamicImprovementStatus.None]: {
        value: DynamicImprovementStatus.None,
        label: "None",
        param: "none",
    },
    [DynamicImprovementStatus.Registered]: {
        value: DynamicImprovementStatus.Registered,
        label: k.REGISTERED,
        param: "registered",
    },
    [DynamicImprovementStatus.Analysing]: {
        value: DynamicImprovementStatus.Analysing,
        label: k.ANALYSING,
        param: "analysing",
    },
    [DynamicImprovementStatus.Acting]: {
        value: DynamicImprovementStatus.Acting,
        label: k.ACTING,
        param: "acting",
    },
    [DynamicImprovementStatus.Verifying]: {
        value: DynamicImprovementStatus.Verifying,
        label: k.VERIFYING,
        param: "verifying",
    },
    [DynamicImprovementStatus.Closed]: {
        value: DynamicImprovementStatus.Closed,
        label: k.CLOSED,
        param: "closed",
    },
};

export const dynamicImprovementStatusOptions: Array<
    IValueLabelItem<DynamicImprovementStatus, string, string>
> = [
    dynamicImprovementStatusItems[DynamicImprovementStatus.None],
    dynamicImprovementStatusItems[DynamicImprovementStatus.Registered],
    dynamicImprovementStatusItems[DynamicImprovementStatus.Analysing],
    dynamicImprovementStatusItems[DynamicImprovementStatus.Acting],
    dynamicImprovementStatusItems[DynamicImprovementStatus.Verifying],
    dynamicImprovementStatusItems[DynamicImprovementStatus.Closed],
];

export const getDynamicStatusOptions = (
    i18next: typeof i18n,
): IValueLabelItem<string, string, DynamicImprovementStatus>[] => [
    {
        value: String(DynamicImprovementStatus.Registered),
        label: i18next.t(k.REGISTERED),
        param: DynamicImprovementStatus.Registered,
    },
    {
        value: String(DynamicImprovementStatus.Analysing),
        label: i18next.t(k.ANALYSING),
        param: DynamicImprovementStatus.Analysing,
    },
    {
        value: String(DynamicImprovementStatus.Acting),
        label: i18next.t(k.ACTING),
        param: DynamicImprovementStatus.Acting,
    },
    {
        value: String(DynamicImprovementStatus.Verifying),
        label: i18next.t(k.VERIFYING),
        param: DynamicImprovementStatus.Verifying,
    },
    {
        value: String(DynamicImprovementStatus.Closed),
        label: i18next.t(k.CLOSED),
        param: DynamicImprovementStatus.Closed,
    },
];

export const getTreeDynamicStatusOptions = (
    i18next: typeof i18n,
): ITreeListOption[] => [
    {
        value: String(DynamicImprovementStatus.Registered),
        label: i18next.t(k.REGISTERED),
        param: DynamicImprovementStatus.Registered,
        checked: false,
        children: [],
        isArchived: false,
        isDeleted: false,
    },
    {
        value: String(DynamicImprovementStatus.Analysing),
        label: i18next.t(k.ANALYSING),
        param: DynamicImprovementStatus.Analysing,
        checked: false,
        children: [],
        isArchived: false,
        isDeleted: false,
    },
    {
        value: String(DynamicImprovementStatus.Acting),
        label: i18next.t(k.ACTING),
        param: DynamicImprovementStatus.Acting,
        checked: false,
        children: [],
        isArchived: false,
        isDeleted: false,
    },
    {
        value: String(DynamicImprovementStatus.Verifying),
        label: i18next.t(k.VERIFYING),
        param: DynamicImprovementStatus.Verifying,
        checked: false,
        children: [],
        isArchived: false,
        isDeleted: false,
    },
    {
        value: String(DynamicImprovementStatus.Closed),
        label: i18next.t(k.CLOSED),
        param: DynamicImprovementStatus.Closed,
        checked: false,
        children: [
            {
                value: String(ClosureReasonEnum.ResolvedFully),
                label: i18next.t(k.RESOLVED_FULLY),
                param: ClosureReasonEnum.ResolvedFully,
                checked: false,
                children: [],
                isArchived: false,
                isDeleted: false,
            },
            {
                value: String(ClosureReasonEnum.ResolvedPartially),
                label: i18next.t(k.RESOLVED_PARTIALLY),
                param: ClosureReasonEnum.ResolvedPartially,
                checked: false,
                children: [],
                isArchived: false,
                isDeleted: false,
            },
            {
                value: String(ClosureReasonEnum.NoActionsTaken),
                label: i18next.t(k.NO_ACTIONS_TAKEN),
                param: ClosureReasonEnum.NoActionsTaken,
                checked: false,
                children: [],
                isArchived: false,
                isDeleted: false,
            },
            {
                value: String(ClosureReasonEnum.NotActionable),
                label: i18next.t(k.NOT_ACTIONABLE),
                param: ClosureReasonEnum.NotActionable,
                checked: false,
                children: [],
                isArchived: false,
                isDeleted: false,
            },
            {
                value: String(ClosureReasonEnum.Postponed),
                label: i18next.t(k.POSTPONED),
                param: ClosureReasonEnum.Postponed,
                checked: false,
                children: [],
                isArchived: false,
                isDeleted: false,
            },
        ],
        isArchived: false,
        isDeleted: false,
    },
];

export const getTreeDynamicStatusTableSetOptions = (
    i18next: typeof i18n,
    statuses?: DynamicImprovementStatus[],
    closureReasons?: ClosureReasonEnum[],
): ITableSetWithOptions<IDropdownTreeData, string> => ({
    ids: [
        ...dynamicImprovementStatusStringsWithoutNone,
        ...closureReasonsStatusStringsWithoutNone,
    ],
    values: {
        [DynamicImprovementStatus[DynamicImprovementStatus.Registered]]: {
            value: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Registered],
            ),
            label: i18next.t(k.REGISTERED),
            param: DynamicImprovementStatus.Registered,
            checked:
                statuses?.includes(DynamicImprovementStatus.Registered) ??
                false,
            childIds: [],
            isArchived: false,
            isDeleted: false,
        },
        [DynamicImprovementStatus[DynamicImprovementStatus.Analysing]]: {
            value: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Analysing],
            ),
            label: i18next.t(k.ANALYSING),
            param: DynamicImprovementStatus.Analysing,
            checked:
                statuses?.includes(DynamicImprovementStatus.Analysing) ?? false,
            childIds: [],
            isArchived: false,
            isDeleted: false,
        },
        [DynamicImprovementStatus[DynamicImprovementStatus.Acting]]: {
            value: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Acting],
            ),
            label: i18next.t(k.ACTING),
            param: DynamicImprovementStatus.Acting,
            checked:
                statuses?.includes(DynamicImprovementStatus.Acting) ?? false,
            childIds: [],
            isArchived: false,
            isDeleted: false,
        },
        [DynamicImprovementStatus[DynamicImprovementStatus.Verifying]]: {
            value: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Verifying],
            ),
            label: i18next.t(k.VERIFYING),
            param: DynamicImprovementStatus.Verifying,
            checked:
                statuses?.includes(DynamicImprovementStatus.Verifying) ?? false,
            childIds: [],
            isArchived: false,
            isDeleted: false,
        },
        [DynamicImprovementStatus[DynamicImprovementStatus.Closed]]: {
            value: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            label: i18next.t(k.CLOSED),
            param: DynamicImprovementStatus.Closed,
            checked:
                statuses?.includes(DynamicImprovementStatus.Closed) ?? false,
            childIds: [
                ClosureReasonEnum[ClosureReasonEnum.ResolvedFully],
                ClosureReasonEnum[ClosureReasonEnum.ResolvedPartially],
                ClosureReasonEnum[ClosureReasonEnum.NoActionsTaken],
                ClosureReasonEnum[ClosureReasonEnum.NotActionable],
                ClosureReasonEnum[ClosureReasonEnum.Postponed],
            ],
            isArchived: false,
            isDeleted: false,
        },
        [ClosureReasonEnum[ClosureReasonEnum.ResolvedFully]]: {
            value: String(ClosureReasonEnum[ClosureReasonEnum.ResolvedFully]),
            label: i18next.t(k.RESOLVED_FULLY),
            param: ClosureReasonEnum.ResolvedFully,
            checked:
                closureReasons?.includes(ClosureReasonEnum.ResolvedFully) ??
                false,
            childIds: [],
            parentId: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            isArchived: false,
            isDeleted: false,
        },
        [ClosureReasonEnum[ClosureReasonEnum.ResolvedPartially]]: {
            value: String(
                ClosureReasonEnum[ClosureReasonEnum.ResolvedPartially],
            ),
            label: i18next.t(k.RESOLVED_PARTIALLY),
            param: ClosureReasonEnum.ResolvedPartially,
            checked:
                closureReasons?.includes(ClosureReasonEnum.ResolvedPartially) ??
                false,
            childIds: [],
            parentId: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            isArchived: false,
            isDeleted: false,
        },
        [ClosureReasonEnum[ClosureReasonEnum.NoActionsTaken]]: {
            value: String(ClosureReasonEnum[ClosureReasonEnum.NoActionsTaken]),
            label: i18next.t(k.NO_ACTIONS_TAKEN),
            param: ClosureReasonEnum.NoActionsTaken,
            checked:
                closureReasons?.includes(ClosureReasonEnum.NoActionsTaken) ??
                false,
            childIds: [],
            parentId: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            isArchived: false,
            isDeleted: false,
        },
        [ClosureReasonEnum[ClosureReasonEnum.NotActionable]]: {
            value: String(ClosureReasonEnum[ClosureReasonEnum.NotActionable]),
            label: i18next.t(k.NOT_ACTIONABLE),
            param: ClosureReasonEnum.NotActionable,
            checked:
                closureReasons?.includes(ClosureReasonEnum.NotActionable) ??
                false,
            childIds: [],
            parentId: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            isArchived: false,
            isDeleted: false,
        },
        [ClosureReasonEnum[ClosureReasonEnum.Postponed]]: {
            value: String(ClosureReasonEnum[ClosureReasonEnum.Postponed]),
            label: i18next.t(k.POSTPONED),
            param: ClosureReasonEnum.Postponed,
            checked:
                closureReasons?.includes(ClosureReasonEnum.Postponed) ?? false,
            childIds: [],
            parentId: String(
                DynamicImprovementStatus[DynamicImprovementStatus.Closed],
            ),
            isArchived: false,
            isDeleted: false,
        },
    },
    options: [...dynamicImprovementStatusStringsWithoutNone],
});

export const dynamicImprovementStatusStrings: Set<string> = new Set(
    Object.keys(DynamicImprovementStatus).filter((key) => isNaN(Number(key))),
);

export const dynamicImprovementStatusStringsWithoutNone: Set<string> = new Set(
    Array.from(dynamicImprovementStatusStrings).filter(
        (status) => status !== "None",
    ),
);
