import "./NextYearButton.scss";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { ArrowRightIcon } from "common/components/icons";

const NextYearButton = (props: { onClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <div
            className="next-year-button"
            title={t(k.PREVIOUS_YEAR)}
            onClick={props.onClick}
        >
            <ArrowRightIcon />
        </div>
    );
};

export default NextYearButton;
