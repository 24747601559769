import { ThunkActionReturnsPromise } from "../../common/types";
import ExecutionResult from "../../common/viewModels/ExecutionResult";
import IAttachmentFormData from "../../common/viewModels/IAttachmentFormData";
import { AttachmentType } from "../../models/enums/AttachmentType";
import IAttachmentRequest from "../../http/requests/IAttachmentRequest";
import { globalStore } from "../index";
import { IAttachmentState } from "./reducer";
import { AttachmentService } from "./service";
import {
    AttachmentActionTypes,
    HIDE_ATTACHMENT_FORM,
    SET_ATTACHMENT_IS_LOADING,
    SHOW_ATTACHMENT_FORM,
} from "./types";

export const setIsLoading = (isLoading: boolean): AttachmentActionTypes => {
    return {
        type: SET_ATTACHMENT_IS_LOADING,
        payload: isLoading,
    };
};

export const showAttachmentForm: ThunkActionReturnsPromise<
    IAttachmentFormData | undefined,
    IAttachmentState,
    AttachmentActionTypes
> = (type: AttachmentType) => {
    const request = (): AttachmentActionTypes => {
        return {
            type: SHOW_ATTACHMENT_FORM,
            payload: type,
        };
    };

    return async (dispatch) => {
        dispatch(request());

        return new Promise<IAttachmentFormData | undefined>((resolve) => {
            const handleStateChange = () => {
                const newState = globalStore.getState();

                if (newState.attachmentViewState.formResolved) {
                    resolve(newState.attachmentViewState.formData);

                    unsubscribe();
                }
            };

            const unsubscribe = globalStore.subscribe(handleStateChange);
        });
    };
};

export const hideAttachmentForm = (
    data?: IAttachmentFormData,
): AttachmentActionTypes => {
    return {
        type: HIDE_ATTACHMENT_FORM,
        payload: data,
    };
};

export const validate: ThunkActionReturnsPromise<
    ExecutionResult,
    IAttachmentState,
    AttachmentActionTypes
> = (attachmentRequest: IAttachmentRequest) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));

        try {
            await new AttachmentService().validate(attachmentRequest);

            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));

            return ExecutionResult.Failed(error);
        }

        return ExecutionResult.Success();
    };
};
