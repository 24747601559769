import React, { FC } from "react";
import "./Header.scss";
export interface IHeaderInputProps {}

const Header: FC<React.PropsWithChildren<IHeaderInputProps>> = (props) => {
    return (
        <div className="listed-content__side-panel__header side-panel--sticky">
            {props.children}
        </div>
    );
};

export default Header;
