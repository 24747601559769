import React, { useMemo } from "react";

export interface ISidePanelDetailsProps {
    className?: string;

    title?: string;
    description?: string;

    header?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
}

const ModalContentListedSidePanelDetails: React.FC<
    React.PropsWithChildren<ISidePanelDetailsProps>
> = (props) => {
    const { className, title, description, header, body, footer } = props;

    const detailsClassName = useMemo(() => {
        const result = ["m-m--m-c--listed--side-panel--details"];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [className]);
    return (
        <div className={detailsClassName}>
            {props.children ?? (
                <>
                    {header}
                    {Boolean(title) && <h2 className="title">{title}</h2>}
                    {body}
                    {Boolean(description) && (
                        <div className="description">{description}</div>
                    )}
                    {footer}
                </>
            )}
        </div>
    );
};

export default ModalContentListedSidePanelDetails;
