import { useContext } from "react";

import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import ModalContent from "common/components/modal-content/ModalContent";
import TableForm from "components/customer-page/components/contacts/TableForm";

interface IProps {
    modalItem: IModalListItem;
}

const ContactsFormModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <ModalContent maxWidth="sm">
            <TableForm
                id={id}
                haveChanges={modalManagerContext.state.haveChangesList[callId]}
                customerId={options?.customerId}
                createNew={options?.createNew}
                onHaveChanges={handleOnHaveChanges}
                onClose={handleOnClose}
            />
        </ModalContent>
    );
};

export default ContactsFormModal;
