import "./ImprovementStatusDropdownTree.scss";
import classnames from "classnames";

import DropdownTreeSelect, { TreeNode, Mode } from "react-dropdown-tree-select";
import React, { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { ITreeListOption } from "common/ITreeListOption";
import {
    DynamicImprovementStatus,
    getTreeDynamicStatusOptions,
} from "models/enums/DynamicImprovementStatus";
import { useTranslation } from "react-i18next";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import InputWrapper, { IInputWrapperProps } from "../InputWrapper";
import k from "i18n/keys";
import { SelectedImprovementStatusType } from "./SelectedImprovementStatusType";

interface IProps extends IInputWrapperProps {
    id?: string;
    mode?: Mode;
    disabled?: boolean;
    showRemove?: boolean;

    selectedStatuses: DynamicImprovementStatus[] | null;
    selectedClosureReasons: ClosureReasonEnum[] | null;
    hasSelectedItems: boolean;

    onChange: (
        nextStatuses: DynamicImprovementStatus[],
        nextClosureReasons: ClosureReasonEnum[],
    ) => void;
}

const ImprovementStatusDropdownTree: React.FC<IProps> = (props) => {
    const { id, mode, disabled, showRemove, hasSelectedItems } = props;

    const { t, i18n } = useTranslation();

    const allOptions = useMemo(
        () => getTreeDynamicStatusOptions(i18n),
        [i18n.language],
    );

    const [selectedOptions, setSelectedOptions] =
        useState<ITreeListOption[]>(allOptions);

    useEffect(() => {
        if (props.selectedStatuses || props.selectedClosureReasons) {
            const updateCheckedClosureReason = (item: ITreeListOption) => {
                if (props.selectedClosureReasons?.includes(item.param)) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            };
            const updateCheckedStatus = (item: ITreeListOption) => {
                if (props.selectedStatuses?.includes(item.param)) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                // update children if present
                if (item.children && item.children.length > 0) {
                    if (item.checked) {
                        item.children.forEach((child) => {
                            child.checked = true;
                        });
                    } else {
                        item.children.forEach(updateCheckedClosureReason);
                    }
                }
            };
            // Create a new array with updated 'checked' property
            let updatedArray = allOptions.map((item) => ({ ...item }));
            updatedArray.forEach(updateCheckedStatus);

            setSelectedOptions(updatedArray);
        }
    }, [props.selectedClosureReasons, props.selectedStatuses]);

    const handleOnChange = (
        currentNode: TreeNode,
        selectedNodes: TreeNode[],
    ) => {
        let nextStatuses = selectedNodes.map((x) => {
            let status = null;
            if (x.value) {
                const nodeType = x._depth as SelectedImprovementStatusType;
                if (nodeType == SelectedImprovementStatusType.status) {
                    status = x.param;
                }
            }
            return status;
        });
        let nextClosureReasons = selectedNodes.map((x) => {
            let closureReason = null;
            if (x.value) {
                const nodeType = x._depth as SelectedImprovementStatusType;
                if (nodeType == SelectedImprovementStatusType.closureReason) {
                    closureReason = x.param;
                }
            }
            return closureReason;
        });

        nextClosureReasons = nextClosureReasons.filter((x) => x != null);
        nextStatuses = nextStatuses.filter((x) => x != null);

        props.onChange(nextStatuses, nextClosureReasons);
    };

    const optionalClasses = classnames({
        "improvement-status-dropdown-tree": true,
        "show-remove": showRemove,
        "has-selected-items": hasSelectedItems,
    });

    return (
        <InputWrapper
            {...props}
            wrapperLabel={props.wrapperLabel ?? t(k.STATUS)}
        >
            <DropdownTreeSelect
                id={id}
                data={selectedOptions}
                disabled={disabled}
                keepTreeOnSearch
                mode={mode}
                showPartiallySelected
                className={optionalClasses}
                onChange={handleOnChange}
                data-testid="form.ImprovementStatusDropdownInput"
                texts={{
                    placeholder: t(k.ALL),
                }}
            />
        </InputWrapper>
    );
};

export default React.memo(ImprovementStatusDropdownTree, (prev, next) => {
    return (
        isEqual(prev.selectedStatuses, next.selectedStatuses) &&
        isEqual(prev.selectedClosureReasons, next.selectedClosureReasons) &&
        isEqual(prev.hasSelectedItems, next.hasSelectedItems)
    );
});
