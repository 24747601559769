import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { ISubscription } from "@microsoft/signalr";
import { ICognitGPTRequest } from "common/api/ICognitGPTRequest";
import { ICognitGPTResponse } from "common/api/ICognitGPTResponse";
import { CognitGPTPromptType } from "common/utils/cognitGPTUtils";
import IDictionary from "common/viewModels/IDictionary";
import { IAppState } from "store/IAppState";

import useSignalRHub from "../useSignalRHub";

export const COGNIT_GPT_HUB_URL = "/gptHub";

export const COGNIT_RECEIVE_ERROR_EVENT = "ReceiveError";
export const COGNIT_RECEIVE_MESSAGE_EVENT = "ReceiveMessage";

export const COGNIT_STREAM_GPT_MESSAGES = "StreamGPTMessages";

const useCognitGPTHub = (canUseAI: boolean) => {
    const [gptErrors, setGptErrors] = useState<
        Record<CognitGPTPromptType, string | undefined>
    >({} as Record<CognitGPTPromptType, string | undefined>);

    const [gptLoading, setGptLoading] = useState<
        CognitGPTPromptType | undefined
    >();
    const [subscription, setSubscription] = useState<
        ISubscription<ICognitGPTResponse> | undefined
    >();

    const [response, setResponse] = useState<ICognitGPTResponse>({
        text: "",
        promptType: CognitGPTPromptType.None,
    });

    const sendMessage = async (request: ICognitGPTRequest) => {
        setGptLoading(request.promptType);
        setGptErrors((prev) => {
            return {
                ...prev,
                [request.promptType]: undefined,
            };
        });

        setResponse({ text: "", promptType: request.promptType });

        try {
            const newSubscription = await stream<ICognitGPTResponse>(
                COGNIT_STREAM_GPT_MESSAGES,
                request,
            )?.subscribe({
                next: (item) => {
                    setResponse((prev) => ({
                        ...prev,
                        text: prev.text + item.text,
                    }));
                },
                complete: () => {
                    setGptLoading(undefined);
                    setSubscription(undefined);
                },
                error: (err) => {
                    setGptErrors((prev) => ({
                        ...prev,
                        [request.promptType]: err?.message,
                    }));
                    setGptLoading(undefined);
                    setSubscription(undefined);
                },
            });

            setSubscription(newSubscription);
        } catch (error: any) {
            if (gptLoading) {
                setGptErrors((prev) => {
                    return {
                        ...prev,
                        [gptLoading]: error?.message,
                    };
                });
                setGptLoading(undefined);
                setSubscription(undefined);
            }
        }
    };

    const cancelStream = useCallback(() => {
        if (subscription) {
            subscription.dispose();
            setSubscription(undefined);
            setGptLoading(undefined);
        }
    }, [subscription]);

    useEffect(() => {
        return () => {
            if (subscription) {
                subscription.dispose();
            }
        };
    }, [subscription]);

    const onGenerateText = async (
        promptType: CognitGPTPromptType,
        promptAdditionalInfo?: IDictionary<string>,
    ) => {
        if (!gptLoading) {
            await sendMessage({
                promptType,
                promptAdditionalInfo,
            });
        }
    };

    const token = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.token,
    );

    const { stream } = useSignalRHub(COGNIT_GPT_HUB_URL, token, !canUseAI);

    return {
        response,
        gptErrors,
        gptLoading,
        onGenerateText,
        cancelStream,
    };
};

export default useCognitGPTHub;
