import React from "react";
import "./ProgressCircle.scss";

interface ProgressCircleProps {
    now?: number;
    max?: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = (props) => {
    const { max = 100, now = 0 } = props;
    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const offset = (1 - now / max) * circumference;

    const strokeWidth = 10;

    return (
        <svg
            className="progress-circle"
            width={(radius + strokeWidth) * 2}
            height={(radius + strokeWidth) * 2}
        >
            <circle
                className="progress-circle-background"
                cx={radius + strokeWidth}
                cy={radius + strokeWidth}
                r={radius}
                strokeWidth={strokeWidth}
            />
            <circle
                className="progress-circle-progress"
                cx={radius + strokeWidth}
                cy={radius + strokeWidth}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={offset}
                transform={`rotate(90 ${radius + strokeWidth} ${
                    radius + strokeWidth
                })`}
            />
        </svg>
    );
};

export default ProgressCircle;
