import React from "react";

import { PiWarningOctagon } from "react-icons/pi";

import classNames from "classnames";

import { InfoIcon } from "../icons";

import "./Alert.scss";

export interface IAlertProps {
    variant?: "info" | "warning";
    testId?: string;
}

const iconByVariant = {
    info: <InfoIcon />,
    warning: <PiWarningOctagon size={18} />,
};

export const Alert: React.FC<React.PropsWithChildren<IAlertProps>> = (
    props,
) => {
    const { variant = "info", testId } = props;

    const className = classNames("alert", {
        [`alert--${variant}`]: variant,
    });

    return (
        <div className={className} data-testid={testId}>
            <div className="alert-icon">{iconByVariant[variant]}</div>

            <div className="alert-message">{props.children}</div>
        </div>
    );
};
