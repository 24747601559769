import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";
import { FC, useContext } from "react";
import { useLocation } from "react-router-dom";
import ChecklistDetailsContext from "../../../../../checklistContext";
import PausedMessage from "components/checklists/components/PausedMessage";

interface Props {}

const PausedBy: FC<Props> = () => {
    const { checklist, onShowModal } = useContext(ChecklistDetailsContext);
    const location = useLocation();

    const handlePreviewRecurrence = () => {
        if (checklist && checklist.scheduleId) {
            const payload: IShowModalPayload = {
                id: checklist.scheduleId,
                type: ModalTypes.recurrence,
                callerAddress: location.pathname,
            };

            onShowModal(payload);
        }
    };

    return checklist ? (
        <PausedMessage
            checklist={checklist}
            onShowRecurrence={handlePreviewRecurrence}
        />
    ) : null;
};

export default PausedBy;
