import { SetStateAction } from "react";
import ITableSet from "./ITableSet";

export default interface ITableSetWithParentLink<V, P = undefined>
    extends ITableSet<V> {
    parents?: {
        [key in P extends string ? P : keyof P]?: Record<string, string[]>;
    };
}

export const createTableSetParentLink = <V, P>(
    key: P extends string ? P : keyof P,
): ITableSetWithParentLink<V, P> => ({
    values: {},
    ids: [],
    parents: {
        [key]: {},
    } as Record<P extends string ? P : keyof P, Record<string, string[]>>,
});

export const setStateTableSetWithParentLink = <PI, P extends keyof PI, V>(
    setStateItems: (
        value: SetStateAction<ITableSetWithParentLink<V, PI>>,
    ) => void,
    items: Record<string, V | undefined>,
    parentKey: PI extends string ? PI : P,
    parentsAndIds: Record<string, string[]>,
) => {
    setStateItems((prev) => ({
        ...prev,
        ids: [...prev.ids, ...Object.values(parentsAndIds).flat()],
        values: {
            ...prev.values,
            ...items,
        },
        parents: {
            ...prev.parents,
            [parentKey]: {
                ...prev.parents?.[parentKey],
                ...parentsAndIds,
            },
        },
    }));
};
