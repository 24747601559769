import { IModalManagerState } from "../ModalManager";
import { IIdModalListItems, IModalListItem } from "../api/IModalManager";

export const getActiveModal = (
    modalList: IIdModalListItems,
    order: string[],
): IModalListItem | undefined => {
    for (let i = order.length - 1; i >= 0; i--) {
        const modal = modalList[order[i]];
        if (modal && !modal.hidden) {
            return modal;
        }
    }

    return undefined;
};
