import "./KpiCalculatedValuesTable.scss";

import { useTranslation } from "react-i18next";

import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "common/components/table";
import { MonthsOrder } from "models/enums/Months";
import { MonthsKeys } from "i18n/keySets/MonthKeys";
import KPITableRow from "./KPITableRow";
import { IKpiFormulaCalculations } from "common/components/modal-manager-content/kpi-form/api/IKpiFormulaCalculations";

interface IProps {
    className?: string;
    isLoading?: boolean;

    calculatedValues?: IKpiFormulaCalculations;
}

const currentMonth = new Date().getMonth();

const monthKeys = () => MonthsOrder.map((x) => MonthsKeys[x]);

export const KpiCalculatedValuesTable = (props: IProps) => {
    const { className, calculatedValues, isLoading } = props;

    const { t } = useTranslation();

    return (
        <Table className={className} isLoading={isLoading}>
            <TableHeader>
                <TableRow>
                    <TableCell emptyCell />
                    {MonthsOrder.map((x, i) => (
                        <TableCell key={x}>
                            <div
                                className={`kpi-table-form--table--header ${
                                    i === currentMonth ? "current-month" : ""
                                }`}
                            >
                                {t(MonthsKeys[x])}
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {calculatedValues?.years.map((year) => (
                    <KPITableRow
                        key={year}
                        year={year}
                        decimalPlaces={calculatedValues.decimalPlaces}
                        kpiUnit={calculatedValues.kpiUnitName}
                        monthlyValues={
                            calculatedValues.valuesByYear[year] ?? []
                        }
                        timeUnitKeys={monthKeys()}
                        overIsGood={calculatedValues.overIsGood}
                        overThreshold={calculatedValues.overThreshold}
                        underThreshold={calculatedValues.underThreshold}
                    />
                ))}
            </TableBody>
        </Table>
    );
};
