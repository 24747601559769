import React from "react";
import { formatRelative } from "date-fns";

import { convertToDate } from "common/utils/time";
import { AppLocale } from "AppLocale";

interface IProps {
    date?: Date;
}

const FormattedDateSinceToday: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const date =
        props.date instanceof Date ? props.date : convertToDate(props.date);

    if (date) {
        return (
            <React.Fragment>
                {formatRelative(date, new Date(), AppLocale)}
            </React.Fragment>
        );
    }

    return null;
};

export default FormattedDateSinceToday;
