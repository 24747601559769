import i18n from "i18next";

import k from "i18n/keys";
import { IOption } from "common/IOption";

export enum WorkflowScheduleStatus {
    Draft,
    Published,

    Paused,
    Archived,
}

const getAllStatuses = (): IOption[] => {
    const allStatuses: IOption[] = [
        {
            id: WorkflowScheduleStatus.Draft,
            label: i18n.t(k.DRAFT),
            variant: "secondary",
        },
        {
            id: WorkflowScheduleStatus.Published,
            label: i18n.t(k.PUBLISHED),
            variant: "primary",
        },
        {
            id: WorkflowScheduleStatus.Paused,
            label: i18n.t(k.PAUSED),
            variant: "info",
        },
        {
            id: WorkflowScheduleStatus.Archived,
            label: i18n.t(k.ARCHIVED),
            variant: "secondary",
        },
    ];

    return allStatuses;
};

export const getWorkflowScheduleStatuses = (): IOption[] => {
    return getAllStatuses();
};
