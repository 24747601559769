export interface IModalContentFooterProps {
    className?: string;
}

const ModalContentFooter: React.FC<
    React.PropsWithChildren<IModalContentFooterProps>
> = (props) => {
    const { className } = props;
    return (
        <div
            className={`modal-manager--modal-content--footer${
                className ? " " + className : ""
            }`}
        >
            {props.children}
        </div>
    );
};

export default ModalContentFooter;
