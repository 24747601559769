import { combineReducers, Store } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { AllEntitiesReducer } from "./allEntitiesCombinedReducer";
import { AttachmentReducer } from "./attachments/reducer";
import { AuthReducer } from "./auth/reducer";
import { ConfirmReducer } from "./confirms/reducer";
import { IAppState } from "./IAppState";
import { NotificationReducer } from "./notifications/reducer";
import { QRSearchReducer } from "./qrSearch/reducer";
import { StepReducer } from "./steps/reducer";
import { WorkflowReducer } from "./workflows/reducer";
import { WebCamReducer } from "./webcam/reducer";
import { StepInstanceReducer } from "./stepInstances/reducer";
import { PageNavigationReducer } from "./page-navigation/reducer";
import { UiSettingsReducer } from "./uiSettings/reducer";
import { ImagesReducer } from "./images/reducer";
import { BarcodeReducer } from "./barcode/reducer";
import { ProcessChartReducer } from "../components/process-chart/redux/reducer";
import { isDevelopmentEnvironment } from "config";

let globalStore: Store<IAppState>;

export default function configureAppStore(): Store<IAppState, any> {
    const reducers = {
        ormEntities: AllEntitiesReducer,
        authViewState: AuthReducer,
        uiSettingsViewState: UiSettingsReducer,
        notificationViewState: NotificationReducer,
        confirmViewState: ConfirmReducer,
        webCamViewState: WebCamReducer,
        stepViewState: StepReducer,
        stepInstanceViewState: StepInstanceReducer,
        workflowViewState: WorkflowReducer,
        attachmentViewState: AttachmentReducer,
        qrSearchViewState: QRSearchReducer,
        pageNavigationViewState: PageNavigationReducer,
        imageViewState: ImagesReducer,
        barcodeViewState: BarcodeReducer,
        processChartViewState: ProcessChartReducer,
    };

    // In development, use the browser's Redux dev tools extension if installed
    const isDevelopment = isDevelopmentEnvironment();

    const rootReducer = combineReducers<IAppState>({
        ...reducers,
    });

    // Create a configure store function of type `IAppState`
    globalStore = configureStore({
        reducer: rootReducer,
        devTools: isDevelopment,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });

    return globalStore;
}

export { globalStore };
