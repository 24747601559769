import IValidationMessages from "common/viewModels/IValidationMessages";

export const getIdsFromValidationMessages = (
    errors: IValidationMessages,
    idsIn: "keys" | "values" | "both" = "keys",
) => {
    const { _error, ...rest } = errors;
    const keys = Object.keys(rest);

    if (idsIn === "keys") {
        return keys;
    }

    const values: string[] = [];

    keys.forEach((key) => {
        const value = rest[key];
        if (Array.isArray(value)) {
            values.push(...value);
        } else if (value) {
            values.push(value);
        }
    });

    return idsIn === "values" ? values : [...keys, ...values];
};

export const scrollToTopErrorById = (
    ids: string[],
    block: "start" | "end" | "nearest" | "center" = "center",
    inline: "start" | "end" | "nearest" | "center" = "start",
) => {
    const elements = ids
        .map((key) => document.getElementById(key))
        .filter((element) => element !== null) as HTMLElement[];

    if (elements.length > 0) {
        const topElement = elements.reduce((prev, current) =>
            prev.getBoundingClientRect().top <
            current.getBoundingClientRect().top
                ? prev
                : current,
        );

        topElement?.scrollIntoView({ behavior: "smooth", block, inline });
    }
};
