import axios from "axios";
import { useQuery } from "react-query";

import { IValueLabelItem } from "common/IValueLabelItem";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IEquipmentRowDTO from "./IEquipmentRowDTO";

const baseUrl = "/api/equipment";

export const useEquipmentOptions = (disabled: boolean = false) => {
    const url = `${baseUrl}/options`;

    return useQuery(
        ["equipment-options"],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
        { enabled: Boolean(disabled) === false },
    );
};

export async function importEquipmentMutation(data: IEquipmentRowDTO[]) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
