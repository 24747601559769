import axios from "axios";

import { IChecklistReportExpandableColumnRequest } from "./IChecklistReportExpandableColumnRequest";
import { IChecklistReportExpandableColumnResponse } from "./IChecklistReportExpandableColumnResponse";

const baseUrl = "/api/workflowRun";

export async function fetchExpandedColumnValuesAsync(
    request: IChecklistReportExpandableColumnRequest,
) {
    const url = `${baseUrl}/expanded-columns`;

    const response = await axios.post<IChecklistReportExpandableColumnResponse>(
        url,
        request,
    );

    return response.data;
}
