import "./MeasurementsSortableList.scss";

import k from "i18n/keys";

import ProgressBar, {
    Position,
} from "common/components/progress-bar/ProgressBar";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import { usePublishedUserOptionsExtended } from "components/users/api/hooks";
import { useTranslation } from "react-i18next";
import Button from "common/components/buttons/Button";
import InfoCard from "components/goals-page/components/InfoCard";

import { IMeasurementDTO } from "../api/IMeasurementDTO";
import { FiPlus } from "react-icons/fi";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import SortableList from "components/common/sortableLists/SortableList";
import ITableSet from "http/ITableSet";
import { SortEnd } from "react-sortable-hoc";

import MeasurementCard from "./MeasurementCard";

interface IProps {
    measurements?: ITableSet<IMeasurementDTO>;
    editMode?: boolean;
    canCreate?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    onSorted?: (params: SortEnd, listId?: string) => void;
    onEditMeasurement?: (id: string, openInEditMode?: boolean) => void;
    onAddMeasurement?: () => void;
}

const MeasurementsSortableList = (props: IProps) => {
    const { measurements, editMode, canCreate, isLoading, disabled } = props;

    const { data: users, isLoading: isLoadingMeasurementsUsers } =
        usePublishedUserOptionsExtended();

    const { t } = useTranslation();

    return (
        <InfoCard
            label={t(k.MEASUREMENTS)}
            childrenClassName="measurements-sortable-list"
        >
            {isLoading || isLoadingMeasurementsUsers ? (
                <AnimatedSpinner isVisible aligned="center" position="center" />
            ) : measurements && measurements.ids?.length > 0 ? (
                <>
                    <SortableList
                        distance={5}
                        hideOrder
                        disabled={disabled}
                        axis="xy"
                        className="measurements-sortable-list--cards"
                        itemIds={measurements.ids}
                        onSorted={props.onSorted}
                        onSortStart={(props, event) => {
                            event.preventDefault();
                        }}
                        render={(itemId) => {
                            const item = measurements.values[itemId];

                            if (!item) {
                                return null;
                            }

                            const handleEditMeasurement = () => {
                                props.onEditMeasurement?.(
                                    item.id,
                                    item.isLocal,
                                );
                            };

                            return (
                                <MeasurementCard
                                    measurement={item}
                                    usersData={users}
                                    onClick={handleEditMeasurement}
                                />
                            );
                        }}
                    />
                    {editMode && canCreate && !disabled && (
                        <div className="add-measurement">
                            <Button
                                icon={<FiPlus />}
                                transparent
                                onClick={props.onAddMeasurement}
                            >
                                {t(k.ADD_MEASUREMENT)}
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className="empty-measurement">
                    <div className="title">
                        {t(k.NO_MEASUREMENTS_ADDED_YET)}
                    </div>
                    {editMode && canCreate && !disabled && (
                        <Button
                            icon={<FiPlus />}
                            transparent
                            onClick={props.onAddMeasurement}
                        >
                            {t(k.ADD_MEASUREMENT)}
                        </Button>
                    )}
                </div>
            )}
        </InfoCard>
    );
};

export default MeasurementsSortableList;
