import "./CustomListItemTasklist.scss";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import Editable from "common/components/activity-instance-card/components/Editable/Editable";
import Button from "common/components/buttons/Button";
import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";

const CustomListItemTasklist = (props: ISharedPropertyInputProps<string>) => {
    const { errors, disabled, options, isLoadingOptions } = props;

    const values = props.values ?? [];

    const { parentId, isRequired, label } = props.property;

    const [optionsState, setOptionsState] = useState<
        IValueLabelItemWithState[]
    >(options ?? []);

    const [newTask, setNewTask] = useState({
        label: "",
        isDeleted: false,
        isArchived: false,
        value: uuidv4(),
        isEditMode: false,
        isCreate: false,
        isLoading: false,
    });

    useEffect(() => {
        if (options) {
            setOptionsState(options);
        }
    }, [options]);

    const { t } = useTranslation();

    const handleChange = (e: IInputChangeEvent<string[]>) => {
        props.onChange(parentId, e.value);
    };

    const handleOnLabelChange = (id: string, newLabel?: string) => {
        if (newLabel) {
            if (id === newTask.value) {
                props.onTaskAddNew?.({
                    ...newTask,
                    label: newLabel,
                    isLoading: true,
                });

                setNewTask({
                    label: "",
                    isDeleted: false,
                    isArchived: false,
                    value: "",
                    isEditMode: false,
                    isCreate: false,
                    isLoading: false,
                });
            } else {
                props.onChangeLabel?.(id, newLabel);
            }
        }
    };

    const handleAddActivityInstance = () => {
        setNewTask((prev) => ({
            ...prev,
            isEditMode: true,
            value: uuidv4(),
            isCreate: true,
        }));
    };

    const onCancelAddTask = () => {
        setNewTask({
            label: "",
            isDeleted: false,
            isArchived: false,
            value: uuidv4(),
            isEditMode: false,
            isCreate: false,
            isLoading: false,
        });
    };

    const handleOnEditClick = (id: string) => {
        props.onChangeEditMode?.(id);
    };

    const userDefinedTasks = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.userDefinedTasks ===
            true
        );
    });

    const showEditable =
        userDefinedTasks &&
        newTask &&
        newTask.value !== "" &&
        newTask.isEditMode;

    const addTaskButtonDisabled = disabled || showEditable;

    const addTaskButtonVisible = userDefinedTasks;

    return (
        <>
            <InputComponent
                inputType={EActivityType.Tasklist}
                id={`form.${parentId}`}
                value={values}
                tasklist={optionsState}
                wrapperLabel={label}
                required={isRequired}
                boldLabel
                disabled={disabled}
                helperText={
                    errors[parentId] && (
                        <ValidationLabel errors={errors[parentId]} />
                    )
                }
                onChange={handleChange}
                isLoading={isLoadingOptions}
                onLabelChange={handleOnLabelChange}
                onEditTaskClick={handleOnEditClick}
                onDeleteTask={props.onDeleteTask}
                show={!showEditable}
                inputDirection="vertical"
                afterInput={
                    <>
                        {showEditable && (
                            <Editable
                                id={newTask.value}
                                value={newTask.value}
                                onLabelChange={handleOnLabelChange}
                                onCancelAddActivityInstance={onCancelAddTask}
                                withCancel={newTask.isCreate}
                            />
                        )}
                        {addTaskButtonVisible && (
                            <div className="custom-list-tasklist--button">
                                <Button
                                    transparent
                                    size="small"
                                    onClick={handleAddActivityInstance}
                                    icon={<FiPlusCircle size="22" />}
                                    disabled={addTaskButtonDisabled}
                                    testId="custom-list-addTaskBtn"
                                >
                                    {t(k.ADD_TASK)}
                                </Button>
                            </div>
                        )}
                    </>
                }
            />
        </>
    );
};

export default CustomListItemTasklist;
