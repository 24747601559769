import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";
import ArchiveWorksheetMessage from "common/components/messages/ArchiveWorksheetMessage";
import {
    getChecklistDetailsMenuActions,
    MenuCommands,
} from "common/components/toggle-state/MenuCommands";

import { showConfirmNoThunk } from "store/confirms/actions";
import ChecklistDetailsContext from "../../../../../checklistContext";
import UnarchiveWorksheetMessage from "common/components/messages/UnarchiveWorksheetMessage";
import DeleteWorksheetMessage from "common/components/messages/DeleteWorksheetMessage";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import { KebabIcon } from "common/components/icons";
import {
    useArchiveChecklistMutation,
    useDeleteChecklistMutation,
} from "components/checklists/api/hooks";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { getLocaleString } from "i18n/components/GetLocaleString";

interface IProps {}

const Menu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { checklist, selectedChecklistId, roles, editMode, onClose } =
        useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const canStop = roles?.ADMINISTRATOR || roles?.PLANNER || false;

    const handleClose = () => {
        onClose();
    };

    const mutateArchiveById = useArchiveChecklistMutation();
    const mutateDeleteById = useDeleteChecklistMutation();

    const onArchive = async (isArchive: boolean) => {
        if (selectedChecklistId) {
            const result = await mutateArchiveById.mutateAsync({
                request: {
                    id: selectedChecklistId,
                    isArchive: isArchive,
                    isArchivedVisible: true,
                },
            });
            if (result.Succeeded && result.Data?.isNotVisible) {
                handleClose();
            } else if (result.Invalid && result.Messages._error) {
                const message = getLocaleString(t, result.Messages._error);

                toast.dismiss();
                toast.error(
                    <>
                        {message.map((x, i) => (
                            <div key={i}>{x}</div>
                        ))}
                    </>,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        }
    };
    const onDelete = async (id: string) => {
        if (selectedChecklistId) {
            const result = await mutateDeleteById.mutateAsync({
                id: selectedChecklistId,
            });
        }
    };
    const handleMenuOnSelect = (selectedId: string) => {
        switch (selectedId as MenuCommands) {
            case MenuCommands.Archive:
                handleArchiveStep();
                break;
            case MenuCommands.UndoArchive:
                handleUnArchiveStep();
                break;
            case MenuCommands.Delete:
                handleDeleteStep();
                break;
        }
    };

    const handleArchiveStep = async () => {
        if (
            (await showConfirmNoThunk(dispatch, <ArchiveWorksheetMessage />)) &&
            selectedChecklistId
        ) {
            await onArchive(true);
        }
    };

    const handleUnArchiveStep = async () => {
        if (
            (await showConfirmNoThunk(
                dispatch,
                <UnarchiveWorksheetMessage />,
            )) &&
            selectedChecklistId
        ) {
            await onArchive(false);
        }
    };

    const handleDeleteStep = async () => {
        if (
            (await showConfirmNoThunk(dispatch, <DeleteWorksheetMessage />)) &&
            selectedChecklistId
        ) {
            await onDelete(selectedChecklistId);

            handleClose();
        }
    };

    const menuOptions: IMenuOption[] = useMemo(() => {
        if (checklist) {
            return getChecklistDetailsMenuActions(
                checklist.status,
                canStop,
                roles?.ADMINISTRATOR ?? false,
                editMode,
            );
        }
        return [];
    }, [checklist?.status, canStop, roles?.ADMINISTRATOR, editMode]);

    return (
        <MenuButton
            icon={<KebabIcon variant="white" />}
            background="dark"
            onSelect={handleMenuOnSelect}
            options={menuOptions}
            testId={"side-panel--header--menu-button"}
        />
    );
};

export default Menu;
