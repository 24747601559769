import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";

import SelectUsersDropdown from "../SelectUsersDropdown";
import IStepAvailableUsersDTO from "models/dto/IStepAvailableUsersDTO";
import {
    onMutationAssignUserError,
    onMutationAssignUserMutate,
} from "../api/hooks";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { assignUserToActivityInstanceAsync } from "common/components/activity-instance-card/api/mutations";
import IAssignUserToActivityInstanceRequest from "http/requests/IAssignUserToActivityInstanceRequest";

interface IProps {
    checklistId?: string;
    improvementId?: string;

    checkIfAdmin: boolean;

    activityInstanceId: string;

    selectedUserIds: string[];

    availableUsers?: IStepAvailableUsersDTO;

    disabled?: boolean;
    isImprovement?: boolean;
    shouldPreventClickEventPropagation?: boolean;
}

const SelectUsersByActivityInstanceId = (props: IProps) => {
    const {
        checklistId,
        improvementId,
        selectedUserIds,
        availableUsers,
        disabled,
        isImprovement,
        shouldPreventClickEventPropagation,
    } = props;

    const { data: publishedUsers } = usePublishedUserOptions();

    const queryClient = useQueryClient();

    const assignUserMutation = useMutation(assignUserToActivityInstanceAsync, {
        onMutate: (variables) => {
            return onMutationAssignUserMutate(queryClient, variables);
        },
        onError: (_, variables, context) => {
            onMutationAssignUserError(queryClient, variables, context);
        },
    });

    const parentId = checklistId ?? improvementId;

    const users = useMemo(() => {
        if (availableUsers?.isTeamEveryone || isImprovement) {
            return publishedUsers?.options;
        }

        return publishedUsers?.options.filter((x) =>
            availableUsers?.userIds?.includes(x.value),
        );
    }, [publishedUsers, availableUsers]);

    const handleSelect = async (userId: string) => {
        const request: IAssignUserToActivityInstanceRequest = {
            id: props.activityInstanceId,
            parentId: parentId,
            userId,
            isImprovement,
        };

        assignUserMutation.mutate(request);
    };

    return users ? (
        <SelectUsersDropdown
            className="no-print"
            onSelect={handleSelect}
            users={users}
            selectedUserIds={selectedUserIds}
            disabled={disabled}
            shouldPreventClickEventPropagation={
                shouldPreventClickEventPropagation
            }
        />
    ) : null;
};

export default SelectUsersByActivityInstanceId;
