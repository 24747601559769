import "./MenuNode.scss";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MenuNodeDivider from "./MenuNodeDivider";
import MenuNodeLink from "./MenuNodeLink";
import AccordionButton from "common/components/buttons/accordion-button/AccordionButton";
import IMenuNodeIsActive from "./IMenuNodeIsActive";
import { IMenuOption } from "../utils/MenuPaths";

interface IProps extends IMenuNodeIsActive {
    expanded?: boolean;
    nodeOptionClassName?: string;

    icon?: React.ReactNode;

    label?: React.ReactNode;

    bottomMargin?: boolean;

    onCommandClick?: () => void;
}

type MenuNodeType = React.FC<React.PropsWithChildren<IProps>> & {
    Divider: typeof MenuNodeDivider;
    Link: typeof MenuNodeLink;
};

interface IHeaderContentProps {
    isOpen: boolean;

    icon?: React.ReactNode;
    label?: React.ReactNode;
    menuOptions?: IMenuOption<string | undefined>[];

    onCommandClick?: () => void;
}

const HeaderContent: React.FC<React.PropsWithChildren<IHeaderContentProps>> = (
    props,
) => {
    const { isOpen, icon, label, menuOptions } = props;

    return (
        <React.Fragment>
            {icon && <span className="menu-node--icon">{icon}</span>}

            {label && <span className="menu-node--label">{label}</span>}

            {props.children && (
                <span
                    className="menu-node--command"
                    onClick={props.onCommandClick}
                >
                    {props.children}
                </span>
            )}

            {menuOptions && <AccordionButton isOpen={isOpen} />}
        </React.Fragment>
    );
};

const MenuNode: MenuNodeType = (props) => {
    const {
        expanded,
        bottomMargin,
        icon,
        label,
        to,
        exact,
        paths,
        menuOptions,
        className,
        nodeOptionClassName,
    } = props;

    const { t } = useTranslation();

    const [optionsVisible, setOptionsVisible] = useState(false);

    const toggleMenuNode = () => {
        if (expanded) {
            setOptionsVisible((x) => !x);
        }
    };

    const isNodeWithoutOptions =
        menuOptions === undefined && props.onSelect !== undefined;

    const headerIsMenuNode = menuOptions || isNodeWithoutOptions;

    const wrapperClassName = useMemo(() => {
        const result = ["menu-node"];

        if (!expanded) {
            result.push("menu-node--not-expanded");
        }

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [expanded, className]);

    if (menuOptions && menuOptions.length === 0) {
        return null;
    }

    return (
        <div className={wrapperClassName}>
            {headerIsMenuNode ? (
                <MenuNode.Link
                    className={
                        menuOptions
                            ? "menu-node--header has-options menu-node--header-can-press"
                            : "menu-node--header menu-node--header-can-press"
                    }
                    to={to}
                    exact={exact}
                    paths={paths}
                    menuOptions={menuOptions}
                    onSelect={menuOptions ? toggleMenuNode : props.onSelect}
                >
                    <HeaderContent
                        isOpen={optionsVisible}
                        icon={icon}
                        label={label}
                        menuOptions={menuOptions}
                        onCommandClick={props.onCommandClick}
                    >
                        {props.children}
                    </HeaderContent>
                </MenuNode.Link>
            ) : (
                <div className="menu-node--header">
                    <HeaderContent
                        isOpen={optionsVisible}
                        icon={icon}
                        label={label}
                        menuOptions={menuOptions}
                        onCommandClick={props.onCommandClick}
                    >
                        {props.children}
                    </HeaderContent>
                </div>
            )}

            {isNodeWithoutOptions && (
                <div className="menu-node--options menu-node--no-options menu-node--options-hidden">
                    <strong className="menu-node--submenu-title">
                        {label}
                    </strong>
                </div>
            )}

            {menuOptions && (
                <div
                    className={
                        optionsVisible
                            ? `menu-node--options${
                                  nodeOptionClassName
                                      ? ` ${nodeOptionClassName}`
                                      : ""
                              }`
                            : `menu-node--options menu-node--options-hidden${
                                  nodeOptionClassName
                                      ? ` ${nodeOptionClassName}`
                                      : ""
                              }`
                    }
                >
                    <strong className="menu-node--submenu-title">
                        {label}
                    </strong>

                    {menuOptions.map((x) => (
                        <MenuNode.Link
                            key={x.value}
                            to={x.linkTo ?? x.value}
                            onSelect={props.onSelect}
                        >
                            {x.noTranslation ? x.label : t(x.label)}
                        </MenuNode.Link>
                    ))}
                </div>
            )}

            {bottomMargin && (
                <div className="menu-node--bottom-margin">&nbsp;</div>
            )}
        </div>
    );
};

MenuNode.Link = MenuNodeLink;
MenuNode.Divider = MenuNodeDivider;

export default MenuNode;
