import "../RecurringChecklistForm.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import TabButton from "common/components/tab/TabButton";
import { WorkflowScheduleInterval as WSI } from "components/recurring-checklist/api/WorkflowScheduleIntervals";

interface IProps {
    value: WSI | undefined;

    onSelect: (id: WSI) => void;
}

const RecurringChecklistIntervalField = (props: IProps) => {
    const { value: value } = props;

    const { t } = useTranslation();

    return (
        <div className="recurring-checklist--body--interval">
            {t(k.RECURRENCE_SHOULD_HAPPEN)}
            <TabButton<WSI>
                id={WSI.Weekly}
                selected={value === WSI.Weekly}
                label={t(k.WEEKLY)}
                onClick={props.onSelect}
            />
            <TabButton<WSI>
                id={WSI.Monthly}
                selected={value === WSI.Monthly}
                label={t(k.MONTHLY)}
                onClick={props.onSelect}
            />
            <TabButton<WSI>
                id={WSI.Yearly}
                selected={value === WSI.Yearly}
                label={t(k.YEARLY)}
                onClick={props.onSelect}
            />
        </div>
    );
};

export default RecurringChecklistIntervalField;
