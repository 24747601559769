import "./IconButton.scss";

import React, { RefObject } from "react";

import classNames from "classnames";

import { VariantType } from "common/VariantType";

interface IProps {
    disabled?: boolean;
    circle?: boolean;
    background?: "dark";
    tooltip?: string;
    id?: string;
    testId?: string;
    className?: string;
    variant?: VariantType;
    border?: boolean;

    buttonRef?: RefObject<HTMLDivElement>;

    onClick?: (e: React.UIEvent) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const IconButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        disabled,
        circle,
        children,
        background,
        tooltip,
        id,
        testId,
        variant,
        border,
        buttonRef,
    } = props;

    const className = classNames(props.className, {
        "icon-button__circle-wrapper": circle,
        "icon-button__wrapper": !circle,
        "icon-button__disabled": disabled,
        [`icon-button__background-${background}`]: background,
        [`icon-button__${variant}`]: variant,
        "icon-button__border": border,
    });

    const handleClick = (e: React.UIEvent) => {
        e.preventDefault();

        if (props.onClick) {
            props.onClick(e);
        }
    };

    return (
        <div
            ref={buttonRef}
            id={id}
            data-testid={testId}
            className={className}
            onClick={disabled ? undefined : handleClick}
            title={tooltip}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {children}
        </div>
    );
};

export default IconButton;
