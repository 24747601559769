import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LocaleId } from "AppLocale";
import ModalContent from "common/components/modal-content/ModalContent";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import History from "components/history/History";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { useCompetencyOptions } from "components/competency-page/api/hooks";

interface IProps {
    modalItem: IModalListItem;
}

const HistoryModal: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        modalItem: { callId, modalFor, options },
    } = props;

    const id = props.modalItem.id ?? modalFor?.id;

    const modalManagerContext = useContext(ModalManagerContext);

    const { i18n } = useTranslation();

    const { data: impactAreas } = useImpactGradingOptions(
        i18n.language as LocaleId,
    );

    const { data: competencies } = useCompetencyOptions(
        i18n.language as LocaleId,
    );

    const handleOnClose = () => {
        modalManagerContext.onCloseModal({ callId });
    };

    return (
        <ModalContent maxWidth="md">
            <History
                id={id}
                onClose={handleOnClose}
                type={options?.historyOwnerType}
                showAll={options?.showAll}
                impactAreas={
                    options?.historyOwnerType ===
                        HistoryOwnerEnum.Improvement ||
                    options?.historyOwnerType === HistoryOwnerEnum.Goal
                        ? impactAreas
                        : []
                }
                competencies={
                    options?.historyOwnerType === HistoryOwnerEnum.CM
                        ? competencies
                        : {}
                }
            />
        </ModalContent>
    );
};

export default HistoryModal;
