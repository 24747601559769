import "./KpiDetails.scss";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import k from "i18n/keys";

import { FormulaItemType } from "../kpi-form/api/FormulaItemType";
import {
    useArchiveKpiMutation,
    useCanAccessKpi,
    useCanUpdateKpi,
    useDataSourceFiltersByKpiId,
    useDeleteKpiMutation,
    useKpiById,
    useKpiFormulaCalculationsById,
} from "../kpi-form/api/hooks";
import HistoryButton from "../kpi-form/components/history-button/HistoryButton";
import {
    DEFAULT_MAX_THRESHOLD,
    DEFAULT_MIN_THRESHOLD,
} from "../kpi-form/components/targets/SetTargets";
import { convertFormulaDtoArrayToString } from "../kpi-form/utility/FormulaTools";
import DetailsFilters from "./components/DetailsFilters/DetailsFilters";
import KpiChart from "./components/KpiChart";
import ManualDataSources from "./components/ManualDataSources/ManualDataSources";
import ArchiveMessage from "./components/messages/ArchiveMessage";
import DeleteMessage from "./components/messages/DeleteMessage";
import UnarchiveMessage from "./components/messages/UnarchiveMessage";
import Badge from "common/components/badges/Badge";
import TeamBadgeList from "common/components/badges/team-badge/TeamBadgeList";
import { Button, ButtonGroup } from "common/components/buttons";
import InputWrapper from "common/components/input-components/InputWrapper";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import ModalContent from "common/components/modal-content/ModalContent";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import useComponentActions from "common/components/react-custom-hooks/useComponentActions";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import TeamsList from "common/components/team/TeamsList";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import IKpiFilter from "components/kpi-page/api/IKpiFilter";
import { KpiCalculatedValuesTable } from "components/kpi-page/components/table-cell-form/components/table/KpiCalculatedValuesTable";
import { useTeamListByIds } from "components/teams/api/hooks";
import { useUserListByIds } from "components/users/api/hooks";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    id?: string;

    isChanged: boolean;
    filter?: IKpiFilter;

    syncData?: boolean;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

const KpiDetails = (props: IProps) => {
    const { id, filter, syncData } = props;

    const dispatch = useDispatch();

    const { registerAction, performAction: handleScroll } =
        useComponentActions();

    const { t } = useTranslation();

    const initialFilter = {
        years: [],
        keywordSearch: "",
    };

    const [currentFilter, setCurrentFilter] = useState<IKpiFilter>(
        filter ?? initialFilter,
    );

    const [currentlyEditedIds, setCurrentlyEditedIds] = useState<string[]>([]);

    const { data: canAccess, isLoading: isLoadingCanAccess } =
        useCanAccessKpi(id);

    const { data: canUpdate } = useCanUpdateKpi(id);

    const canArchive = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR || roles.KPI_OWNER || roles.KPI_EDITOR) ??
            false
        );
    });

    const canDelete = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (roles.ADMINISTRATOR || roles.KPI_OWNER) ?? false;
    });

    const modalManagerContext = useContext(ModalManagerContext);

    const { data: kpiData, isLoading: isLoadingKpi } = useKpiById(
        syncData ? id : undefined,
    );

    const { data: calculatedData, isLoading: isLoadingCalculations } =
        useKpiFormulaCalculationsById(syncData ?? true, id, currentFilter);

    const { data: teamsData, isLoading: isLoadingTeams } = useSharedOptions(
        true,
        ESharedOptionsType.team,
        kpiData?.responsibles?.teamIds ?? [],
    );

    const teams = kpiData?.responsibles?.teamIds?.reduce(
        (acc: any[], id: string) => {
            const team = teamsData?.find((x) => x.value === id);
            if (team) {
                acc.push(team);
            }
            return acc;
        },
        [],
    );

    const { data: usersData, isLoading: isLoadingUsers } = useUserListByIds(
        kpiData?.responsibles?.userIds ?? [],
    );
    const archiveMutation = useArchiveKpiMutation();
    const deleteMutation = useDeleteKpiMutation();

    const handleOnEdit = () => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.kpi_form,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnClose = () => {
        if (currentlyEditedIds.length > 0) {
            handleScroll(currentlyEditedIds[0]);
        }
        props.onClose();
    };

    const handleArchive = async () => {
        if (kpiData?.id) {
            const isArchive = kpiData?.isArchived ? false : true;

            const confirmMsg: IShowConfirmArgs = {
                message: !isArchive ? <UnarchiveMessage /> : <ArchiveMessage />,
                yesButtonVariant: !isArchive ? "blue" : "danger",
                yesLabel: !isArchive ? t(k.UN_ARCHIVE) : t(k.ARCHIVE),
                title: !isArchive
                    ? t(k.UNARCHIVE_THIS_KPI)
                    : t(k.ARCHIVE_THIS_KPI),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                archiveMutation.mutate({
                    request: { id: kpiData.id, isArchive },
                });
            }
        }
    };

    const handleDelete = async () => {
        const confirmMsg: IShowConfirmArgs = {
            message: <DeleteMessage />,
            yesButtonVariant: "danger",
            yesLabel: t(k.DELETE),
            title: t(k.DELETE_THIS_KPI),
        };

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        if (confirmOk && kpiData?.id) {
            const result = await deleteMutation.mutateAsync({
                id: kpiData.id,
            });

            if (result.Succeeded) {
                props.onClose(true);
            } else {
                toast.dismiss();
                toast.error(result.Messages._error, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const handleUpdateFilter = (filter: IKpiFilter) => {
        setCurrentFilter(filter);
    };

    useEffect(() => {
        props.onHaveChanges(currentlyEditedIds.length > 0);
    }, [currentlyEditedIds]);

    const handleHasChanges = (
        manualDataSourceId: string,
        hasChanges: boolean,
    ) => {
        setCurrentlyEditedIds((prevState) => {
            const index = prevState.indexOf(manualDataSourceId);

            if (index === -1) {
                // id is not in the array, so add it
                return [...prevState, manualDataSourceId];
            } else if (hasChanges === false) {
                // on save / cancel remove the id from the array
                return [
                    ...prevState.slice(0, index),
                    ...prevState.slice(index + 1),
                ];
            } else {
                return prevState;
            }
        });
    };

    const isLoadingData = isLoadingKpi || isLoadingCalculations;

    const isLoading = isLoadingData && (isLoadingTeams || isLoadingUsers);

    if (isLoadingCanAccess) {
        return <AnimatedSpinner isVisible aligned="center" position="center" />;
    }

    if (!canAccess) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(k.NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    return (
        <ModalContent className="kpi-details" maxWidth="full">
            <ModalContent.Header
                title={t(k.VIEW_KPI)}
                className="user-form--header"
                onClose={handleOnClose}
                actions={<HistoryButton kpiId={id} />}
            />
            <ModalContent.Body className="kpi-details--body">
                {isLoading || id === undefined ? (
                    <AnimatedSpinner
                        isVisible={isLoading}
                        position="center"
                        aligned="center"
                    />
                ) : (
                    <div className="kpi-details--body--container">
                        <div className="kpi-details--body--details">
                            <InputWrapper
                                className="kpi-details--name"
                                wrapperLabel={t(k.KPI_NAME)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {kpiData?.details?.name}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--description"
                                wrapperLabel={t(k.KPI_DESCRIPTION)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {kpiData?.details?.description ??
                                    t(k.NO_DESCRIPTION_PROVIDED)}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--teams"
                                wrapperLabel={t(k.TEAMS_WHO_CAN_UPDATE_TARGETS)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {teams && teams.length > 0 ? (
                                    //<TeamBadgeList teams={teams} />
                                    <TeamsList teams={teams} icon={null} />
                                ) : (
                                    t(k.NO_TEAM_TO_SHOW)
                                )}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--users"
                                wrapperLabel={t(k.USERS_WHO_CAN_UPDATE_TARGETS)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {usersData && usersData.length > 0 ? (
                                    <SelectedUsersAvatarList
                                        maxShownAvatars={5}
                                        size={32}
                                        users={usersData}
                                    />
                                ) : (
                                    t(k.NO_USERS_ASSIGNED)
                                )}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--unit"
                                wrapperLabel={t(k.KPI_UNIT)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {kpiData
                                    ? (kpiData.formats?.unit?.name ?? (
                                          <span className="text--muted">
                                              {t(k.NOT_AVAILABLE_MESSAGE)}
                                          </span>
                                      ))
                                    : undefined}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--target"
                                wrapperLabel={t(k.TARGET_PREFERENCE)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {kpiData?.targets?.overIsGood
                                    ? t(k.OVER_TARGET_IS_GOOD)
                                    : t(k.UNDER_TARGET_IS_GOOD)}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--formula"
                                wrapperLabel={t(k.FORMULA)}
                                boldLabel
                                inputClassName="kpi-details--body--details--item"
                            >
                                {kpiData?.formula?.formulaItems &&
                                    convertFormulaDtoArrayToString(
                                        kpiData?.formula.formulaItems,
                                        " ",
                                    )}
                            </InputWrapper>
                            <InputWrapper
                                className="kpi-details--threshold"
                                wrapperLabel={t(k.THRESHOLD)}
                                boldLabel
                                inputClassName="kpi-details--body--details--thresholds"
                            >
                                <div className="badge-container">
                                    {kpiData?.targets?.overIsGood ? (
                                        <Badge variant="success">
                                            {t(k.GREEN)}
                                        </Badge>
                                    ) : (
                                        <Badge variant="danger">
                                            {t(k.RED)}
                                        </Badge>
                                    )}

                                    <div className="badge-description">
                                        {t(k.ABOVE_OR_EQUAL_TO)}{" "}
                                        {kpiData?.targets?.overThreshold}
                                    </div>
                                </div>
                                <div className="badge-container">
                                    <Badge variant="warning">
                                        {t(k.YELLOW)}
                                    </Badge>
                                    <div className="badge-description">
                                        {t(k.BETWEEN)}{" "}
                                        {kpiData?.targets?.overThreshold}{" "}
                                        {t(k.AND_)}{" "}
                                        {kpiData?.targets?.underThreshold}
                                    </div>
                                </div>
                                <div className="badge-container">
                                    {kpiData?.targets?.overIsGood ? (
                                        <Badge variant="danger">
                                            {t(k.RED)}
                                        </Badge>
                                    ) : (
                                        <Badge variant="success">
                                            {t(k.GREEN)}
                                        </Badge>
                                    )}
                                    <div className="badge-description">
                                        {t(k.BELOW_OR_EQUAL_TO)}{" "}
                                        {kpiData?.targets?.underThreshold}
                                    </div>
                                </div>
                            </InputWrapper>
                        </div>
                        <DetailsFilters
                            value={currentFilter}
                            onChange={handleUpdateFilter}
                        />
                        {isLoadingData ? (
                            <AnimatedSpinner
                                isVisible={isLoadingData}
                                position="center"
                                aligned="center"
                            />
                        ) : (
                            <>
                                <div>
                                    <KpiCalculatedValuesTable
                                        className="kpi-details--body--values"
                                        isLoading={isLoadingCalculations}
                                        calculatedValues={calculatedData}
                                    />
                                </div>
                                {calculatedData && (
                                    <div className="kpi-details--body--chart">
                                        <KpiChart
                                            values={calculatedData}
                                            underThreshold={
                                                kpiData?.targets
                                                    ?.underThreshold ??
                                                DEFAULT_MIN_THRESHOLD
                                            }
                                            overThreshold={
                                                kpiData?.targets
                                                    ?.overThreshold ??
                                                DEFAULT_MAX_THRESHOLD
                                            }
                                            overTargetIsGood={
                                                kpiData?.targets?.overIsGood ??
                                                true
                                            }
                                        />
                                    </div>
                                )}

                                <div className="kpi-details--body--table">
                                    <ManualDataSources
                                        formulaItems={
                                            kpiData?.formula?.formulaItems?.filter(
                                                (x) =>
                                                    x.type ===
                                                    FormulaItemType.Variable,
                                            ) ?? []
                                        }
                                        kpiId={id}
                                        canEdit={
                                            canUpdate && !kpiData?.isArchived
                                        }
                                        currentlyEditedIds={currentlyEditedIds}
                                        onHaveChanges={handleHasChanges}
                                        registerAction={registerAction}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </ModalContent.Body>
            <ModalContent.Footer className="kpi-details--footer">
                <Button variant="bordered" onClick={handleOnClose}>
                    {t(k.CLOSE)}
                </Button>
                <ButtonGroup>
                    {kpiData?.isArchived ? (
                        <ButtonGroup>
                            <Button
                                testId="unarchive-kpi-btn"
                                onClick={handleArchive}
                                variant="bordered"
                                disabled={!canArchive || isLoadingKpi}
                            >
                                {t(k.UN_ARCHIVE)}
                            </Button>
                            <Button
                                testId="delete-kpi-btn"
                                onClick={handleDelete}
                                variant="danger"
                                disabled={!canDelete || isLoadingKpi}
                            >
                                {t(k.DELETE)}
                            </Button>
                        </ButtonGroup>
                    ) : (
                        <Button
                            testId="archive-kpi-btn"
                            onClick={handleArchive}
                            variant="bordered"
                            disabled={!canArchive || isLoadingKpi}
                        >
                            {t(k.ARCHIVE)}
                        </Button>
                    )}

                    <Button
                        testId="editKpiBtn"
                        onClick={handleOnEdit}
                        disabled={
                            !canUpdate || isLoadingKpi || kpiData?.isArchived
                        }
                    >
                        {t(k.EDIT)}
                    </Button>
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default KpiDetails;
