import "./UserRoleBadges.scss";

import React, { useMemo } from "react";

import RoleBadge from "components/common/badges/RoleBadge";

import { TRoles } from "components/roles/api/Roles";
import { IValueLabelItem } from "common/IValueLabelItem";
import { usePublishedRoles } from "components/roles/api/hooks";
import { ERoleSource } from "components/roles/api/RoleSource";
import Wrapper from "common/components/wrapper/Wrapper";

interface IProps {
    roles?: string[];
    wrapper?: boolean;
}

const UserRoleBadges = ({ roles, wrapper = true }: IProps) => {
    const { data: publishedRoles } = usePublishedRoles();

    const labeledRoles = useMemo(() => {
        if (roles && publishedRoles) {
            const values = roles.reduce<IValueLabelItem<string, string>[]>(
                (acc, x) => {
                    const label = publishedRoles.values[x];

                    if (label) {
                        acc.push({
                            value: x,
                            label,
                        });
                    }

                    return acc;
                },
                [],
            );

            return values;
        }
    }, [roles, publishedRoles]);

    return (
        <Wrapper enabled={wrapper} className="user-role-badges">
            {labeledRoles?.map((item) => (
                <RoleBadge
                    key={item.value}
                    role={item.label as TRoles}
                    type={ERoleSource.User}
                />
            ))}
        </Wrapper>
    );
};

export default UserRoleBadges;
