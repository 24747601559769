import "./ActionBy.scss";

import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface IActionByProps {
    action: ReactNode;
    by: ReactNode;
    className?: string;
}

const ActionBy = ({ action, by }: IActionByProps) => {
    const { t } = useTranslation();
    return (
        <div className="action-by">
            <div className="action-by__action">{action}</div>
            <div className="action-by__by">
                {t(k.BY_)}
                {by}
            </div>
        </div>
    );
};

export default ActionBy;
