import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import { createContext } from "react";
import { INewImprovementData } from "./models/INewImprovementData";

export type NewImprovementContextType = INewImprovementData &
    ListedContentContextType;

const initialContextValue: NewImprovementContextType = {
    editMode: false,
    setEditMode: () => null,
    setEditedImprovement: () => null,
    editedActivityValues: {},
    setEditedActivityValues: () => {
        null;
    },
    onClose: () => null,
    onHaveChanges: () => null,
    errors: {},
    setErrors: () => null,
    onShowModal: () => null,
    setUsersAffected: () => null,
    setUsersToBeNotified: () => null,
    setInvolvedUsers: () => null,
    setEditedSteps: () => null,
    lastFinalizedStepInstanceId: "",
    mobileView: false,
    checkCanSeeAll: false,
};

const NewImprovementContext =
    createContext<NewImprovementContextType>(initialContextValue);

export default NewImprovementContext;
