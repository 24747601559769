import "./MobileOverflow.scss";

import React, { useEffect } from "react";

interface IProps {
    active?: boolean;
}

const BODY_CLASSNAME = "mobile-body-overflow-hidden";

const MobileOverflow: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { active } = props;

    useEffect(() => {
        if (active) {
            document.body.classList.add(BODY_CLASSNAME);
        }

        return () => {
            document.body.classList.remove(BODY_CLASSNAME);
        };
    }, [active]);

    return null;
};

export default MobileOverflow;
