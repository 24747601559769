import "./ModalImagePreview.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import Modal from "common/components/modals/Modal";
import Button from "common/components/buttons/Button";
import ImageComponent from "./ImageComponent";

interface IProps {
    value?: string;
    imgId?: string;

    onHide: () => void;
}

const ModalImagePreview: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { value, imgId } = props;

    const { t } = useTranslation();

    const handleHide = () => {
        props.onHide();
    };

    if (!value && !imgId) {
        return null;
    }

    return (
        <Modal
            size="xl"
            className="modal-image-preview"
            show
            onHide={handleHide}
        >
            <Modal.Title>{t(k.PREVIEW)}</Modal.Title>
            <Modal.Body>
                <ImageComponent value={value} imgId={imgId} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="gray" onClick={handleHide}>
                    {t(k.CANCEL)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalImagePreview;
