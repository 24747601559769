import "./ScheduleIcon.scss";

import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";

import classnames from "classnames";

interface IProps {
    isActive?: boolean;
    className?: string;
    title?: string;
}

const ScheduleIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const className = classnames("icon-schedule", props.className, {
        "icon-schedule--active": props.isActive,
    });

    return <FaRegCalendarAlt title={props.title} className={className} />;
};

export default ScheduleIcon;
