import axios from "axios";

import IAttachmentRequest from "http/requests/IAttachmentRequest";

export class AttachmentService {
    private readonly url = "/api/attachment";

    public async validate(data: IAttachmentRequest) {
        const url = `${this.url}/validate`;

        await axios.post(url, data);
    }
}
