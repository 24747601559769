export enum FormulaItemValidationErrorType {
    INVALID_VARIABLE = "INVALID_VARIABLE",
    DATA_SOURCE_REQUIRED = "DATA_SOURCE_REQUIRED",
    VARIABLE_NAME_REQUIRED = "VARIABLE_NAME_REQUIRED",
    KPI_DATA_SOURCE_ID_REQUIRED = "KPI_DATA_SOURCE_ID_REQUIRED",
    MISSING_OPENING_PARENTHESIS = "MISSING_OPENING_PARENTHESIS",
    STATIC_NUMBER_VALUE_IS_REQUIRED = "STATIC_NUMBER_VALUE_IS_REQUIRED",
    MISSING_ITEMS = "MISSING_ITEMS",
    MISSING_OPERAND = "MISSING_OPERAND",
    MISSING_OPERATOR = "MISSING_OPERATOR",
    FORMULA_CANNOT_START_WITH_OPERATOR = "FORMULA_CANNOT_START_WITH_OPERATOR",
    FORMULA_CANNOT_END_WITH_OPERATOR = "FORMULA_CANNOT_END_WITH_OPERATOR",
    MISSING_CLOSING_PARENTHESIS = "MISSING_CLOSING_PARENTHESIS",
    KPI_CANNOT_REFERENCE_ITSELF = "KPI_CANNOT_REFERENCE_ITSELF",
}
