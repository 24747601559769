import { Context, PropsWithChildren, useContext } from "react";

import { SortEnd } from "react-sortable-hoc";

import ICurrentSectionData from "common/components/current-scrolled-section/models/ICurrentSectionData";
import IStepCardData from "common/components/step-card/model/IStepCardData";
import SortableListExposedItemProps from "components/common/sortableLists/SortableListExposedItemProps";
import k from "i18n/keys";

import EditableStepListItem from "./EditableStepListItem";

import "./EditableListItems.scss";

interface IProps<T extends IStepCardData & ICurrentSectionData> {
    context: Context<T>;
    isDisabled?: boolean;
    onChangeName: (stepId: string, value: string) => void;
    onSorted: (params: SortEnd) => void;
    onDeleteStep: (stepId: string) => void;
    className?: string;
}

const EditableListItems = <T extends IStepCardData & ICurrentSectionData>(
    props: PropsWithChildren<IProps<T>>,
) => {
    const { isDisabled } = props;
    const {
        steps,
        sectionRefs,
        scrollToTarget,
        currentSection,
        errors,
        setErrors,
    } = useContext(props.context);

    const handleOnSelect = (id: string) => {
        if (sectionRefs && scrollToTarget) {
            scrollToTarget(id, sectionRefs);
        }
    };

    const handleOnChangeName = (stepId: string, value: string) => {
        props.onChangeName(stepId, value);

        const nameKey = `step.${stepId}.name`;
        const nameError = errors?.[nameKey];

        if (value.length && nameError) {
            setErrors((prev) => ({
                ...prev,
                [nameKey]: undefined,
            }));
        } else if (value === "") {
            setErrors((prev) => ({
                ...prev,
                [nameKey]: k.STEP_NAME_REQUIRED,
            }));
        }
    };

    return (
        <SortableListExposedItemProps
            itemIds={steps?.ids ?? []}
            useDragHandle
            itemClassName="editable-list-items"
            className={`side-panel__list__steps-list${
                props.className ? ` ${props.className}` : ""
            }`}
            lockAxis="y"
            disabled={isDisabled}
            onSorted={props.onSorted}
            render={(id, index) => {
                const step = steps?.values?.[id];

                return {
                    render: (
                        <EditableStepListItem
                            step={step}
                            isDisabled={isDisabled}
                            errors={errors}
                            index={index + 1}
                            onChange={handleOnChangeName}
                            onDelete={props.onDeleteStep}
                        />
                    ),
                    props: {
                        className: `${
                            id === currentSection
                                ? "editable-list-items selected"
                                : "editable-list-items"
                        }`,
                        onClick: () => handleOnSelect(id),
                    },
                };
            }}
        />
    );
};

export default EditableListItems;
