import { attr, many, Model, ModelWithFields, TableState } from "redux-orm";

import IWorkflowDTO from "../dto/IWorkflowDTO";
import IWorkflowListDTO from "../dto/IWorkflowListDTO";
import IWorkflow from "../IWorkflow";
import { convertToDate } from "../../common/utils/time";

class WorkflowBase extends Model<IWorkflow> {
    public static fields = {
        id: attr(),
        name: attr(),
        status: attr(),
        description: attr(),
        index: attr(),
    };

    public static parseList(data: IWorkflowListDTO): void {
        data.workflows.map((x) => this.parseDTO(x));
    }

    public static parseDTO(data: IWorkflowDTO): ModelWithFields<IWorkflow> {
        data.archivedAt = convertToDate(data.archivedAt);

        return this.upsert(data);
    }
}

export class Workflow extends WorkflowBase {
    public static modelName = "Workflow";

    public static fields = {
        ...WorkflowBase.fields,
        steps: many("Step", "workflows"),
    };
}

export class LatestVersionWorkflow extends WorkflowBase {
    public static modelName = "LatestVersionWorkflow";
}

export class SelectedWorkflow extends WorkflowBase {
    public static modelName = "SelectedWorkflow";
}

export type WorkflowOrmModelType = typeof Workflow;
export type LatestVersionWorkflowOrmModelType = typeof LatestVersionWorkflow;
export type SelectedWorkflowOrmModelType = typeof SelectedWorkflow;

export type WorkflowOrmState = TableState<IWorkflow>;

export default Workflow;
