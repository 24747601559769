import React from "react";
import IValidationMessages from "./IValidationMessages";

export default class ExecutionResult<T = any> {
    public static Failed<T>(error: any): Promise<ExecutionResult<T>> {
        const result = new ExecutionResult(error);

        return Promise.resolve(result);
    }

    public static FailedWithMessages<T>(
        errorMessages: Array<{ key: string; message: React.ReactNode }>,
    ): Promise<ExecutionResult<T>> {
        const errors = errorMessages.reduce<{
            [key: string]: React.ReactNode[];
        }>((acc, val) => {
            if (acc[val.key]) {
                acc[val.key].push(val.message);
            } else {
                acc[val.key] = [val.message];
            }

            return acc;
        }, {});

        const error: {
            response: {
                data: { errors: { [key: string]: React.ReactNode[] } };
            };
        } = {
            response: { data: { errors } },
        };

        const result = new ExecutionResult(error);

        return Promise.resolve(result);
    }

    public static Success<T>(): Promise<ExecutionResult<T>> {
        const result = new ExecutionResult();

        return Promise.resolve(result);
    }

    public static Result<T>(data?: T): Promise<ExecutionResult<T>> {
        const result = new ExecutionResult();

        result.Data = data;

        return Promise.resolve(result);
    }

    public Data?: T;

    public readonly Succeeded: boolean = true;
    public readonly Invalid: boolean = false;

    public readonly Messages: IValidationMessages = {};

    public readonly ErrorsById: Record<string, string[]> = {};

    /**
     *
     */
    constructor(error: any = null) {
        if (error) {
            this.Succeeded = false;
            this.Invalid = true;

            const {
                response: { data },
            } = error;

            const globalError: string[] | undefined | null =
                data._error || (data.errors ? data.errors._error : undefined);

            const messages: IValidationMessages = {
                ...data.errors,
                _error:
                    (globalError ?? []).length > 0 ? globalError : undefined,
            };

            this.Messages = messages;

            this.ErrorsById = data.errorsById ?? {};
        }
    }
}
