import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { UndoIcon } from "common/components/icons";

interface IProps {
    testId: string;
    id: string;
    label: string;
    type: string;
    markedRemove?: boolean;

    onRemove: (id: string, remove: boolean) => void;
}

export const SelectedPermission: React.FC<IProps> = (props) => {
    const { testId, id, label, type, markedRemove } = props;

    const handleOnRemove = () => {
        props.onRemove(id, !markedRemove);
    };

    return (
        <div
            data-testid={`${testId}-selected-${id}`}
            className={`p-s--selected--item${
                markedRemove ? " marked_remove" : ""
            }`}
        >
            <div className="p-s--selected--info">
                <div className="p-s--selected--label">{label}</div>
                <div className="p-s--selected--type">{type}</div>
            </div>
            <IconButton
                onClick={handleOnRemove}
                testId={`${testId}-selected-remove-btn`}
            >
                {markedRemove ? <UndoIcon /> : <TrashIcon />}
            </IconButton>
        </div>
    );
};
