import { useContext } from "react";

import StepCardById from "common/components/step-card/StepCardById";

import TemplatePreviewContext from "components/templates/components/TemplateListedContent/Preview/context/TemplatePreviewContext";

const Content: React.FC = (props) => {
    const { steps, activities, sectionRefs, sourceCustomListItemId } =
        useContext(TemplatePreviewContext);

    if (
        steps === undefined ||
        activities === undefined ||
        sectionRefs === undefined
    ) {
        return null;
    }

    return (
        <>
            {steps.ids.map((x, index) => {
                return (
                    <StepCardById
                        context={TemplatePreviewContext}
                        key={x}
                        id={x}
                        index={index + 1}
                        sourceCustomListItemId={sourceCustomListItemId}
                    />
                );
            })}
        </>
    );
};

export default Content;
