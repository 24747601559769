import "./RecurringChecklistEditStatusButtons.scss";

import React from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { FaFileArchive, FaPlay, FaStop } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { showConfirm } from "store/confirms/actions";
import Button from "common/components/buttons/Button";
import {
    useArchiveRecurringChecklistMutation,
    useDeleteRecurringChecklistMutation,
    usePauseRecurringChecklistMutation,
} from "../api/hooks";
import DeleteRecurringChecklistMessage from "common/components/messages/DeleteRecurringChecklistMessage";

interface IOwnProps {
    workflowScheduleId: string;
    templateVersionId: string | null;

    isPaused?: boolean;

    isArchived?: boolean;

    size?: "small";

    onClose: () => void;
}

interface IDispatchProps {
    showConfirm: (message: React.ReactNode) => Promise<boolean | undefined>;
}

type Props = IOwnProps & IDispatchProps;

const RecurringChecklistEditStatusButtons: React.FC<
    React.PropsWithChildren<Props>
> = (props) => {
    const { t } = useTranslation();

    const { size, isPaused, isArchived, templateVersionId } = props;

    const pauseRecurringChecklist = usePauseRecurringChecklistMutation();

    const archiveRecurringChecklist = useArchiveRecurringChecklistMutation();

    const deleteRecurringChecklist = useDeleteRecurringChecklistMutation();

    const handleUnarchive = async (e: any) => {
        e.stopPropagation();

        const confirmOk = await props.showConfirm(
            <React.Fragment>
                <p>{t(k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE)}</p>
                <Alert variant={"info"}>
                    {t(k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_CONTENT)}
                    <br />
                    {t(k.ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_INFO)}
                </Alert>
            </React.Fragment>,
        );

        if (confirmOk) {
            await archiveRecurringChecklist.mutateAsync({
                id: props.workflowScheduleId,
                isArchive: false,
            });
        }
    };

    const handleArchive = async (e: any) => {
        e.stopPropagation();

        const confirmOk = await props.showConfirm(
            <React.Fragment>
                <p>{t(k.ARE_YOU_SURE_ARCHIVE_SCHEDULE)}</p>
                <Alert variant={"warning"}>
                    <strong>
                        {t(k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_CONTENT)}
                    </strong>
                    <br />
                    {t(k.ARE_YOU_SURE_ARCHIVE_SCHEDULE_INFO)}
                </Alert>
            </React.Fragment>,
        );

        if (confirmOk) {
            await archiveRecurringChecklist.mutateAsync({
                id: props.workflowScheduleId,
                isArchive: true,
            });
        }
    };

    const handleDelete = async (e: any) => {
        e.stopPropagation();

        if (templateVersionId) {
            const confirmOk = await props.showConfirm(
                <DeleteRecurringChecklistMessage />,
            );

            if (confirmOk) {
                const isDeleted = await deleteRecurringChecklist.mutateAsync({
                    id: props.workflowScheduleId,
                    templateVersionId,
                });

                if (isDeleted.Succeeded) {
                    props.onClose();
                }
            }
        }
    };

    const handleResume = async (e: any) => {
        e.stopPropagation();

        const confirmOk = await props.showConfirm(
            <React.Fragment>
                <React.Fragment>
                    <p>{t(k.ARE_YOU_SURE_RESUME_SCHEDULE)}</p>
                    <Alert variant={"success"}>
                        <strong>
                            {t(k.ARE_YOU_SURE_RESUME_SCHEDULE_CONTENT)}
                        </strong>
                        <br />
                        {t(k.ARE_YOU_SURE_RESUME_SCHEDULE_INFO)}
                    </Alert>
                </React.Fragment>
            </React.Fragment>,
        );

        if (confirmOk) {
            await pauseRecurringChecklist.mutateAsync({
                id: props.workflowScheduleId,
                isPause: false,
            });
        }
    };

    const handleStop = async (e: any) => {
        e.stopPropagation();

        const confirmOk = await props.showConfirm(
            <React.Fragment>
                <p>{t(k.ARE_YOU_SURE_STOP_SCHEDULE)}</p>
                <Alert variant={"info"}>
                    <strong>{t(k.ARE_YOU_SURE_STOP_SCHEDULE_CONTENT)}</strong>
                    <br />
                    {t(k.ARE_YOU_SURE_STOP_SCHEDULE_INFO)}
                </Alert>
            </React.Fragment>,
        );

        if (confirmOk) {
            await pauseRecurringChecklist.mutateAsync({
                id: props.workflowScheduleId,
                isPause: true,
            });
        }
    };

    return (
        <>
            {isPaused ? (
                isArchived ? (
                    <>
                        <Button
                            variant="gray"
                            onClick={handleUnarchive}
                            size={size}
                        >
                            {t(k.UN_ARCHIVE)}
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleDelete}
                            size={size}
                        >
                            {t(k.DELETE)}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            variant="gray"
                            onClick={handleResume}
                            className="schedule-pause-button"
                            size={size}
                        >
                            <FaPlay />
                            {t(k.RESUME)}
                        </Button>
                        <Button
                            variant="gray"
                            onClick={handleArchive}
                            className="schedule-pause-button"
                            size={size}
                        >
                            <FaFileArchive />
                            {t(k.ARCHIVE)}
                        </Button>
                    </>
                )
            ) : (
                <Button
                    variant="gray"
                    onClick={handleStop}
                    className="schedule-pause-button"
                    size={size}
                >
                    <FaStop />
                    {t(k.STOP)}
                </Button>
            )}
        </>
    );
};

const actions = {
    showConfirm,
};

export default connect(null, actions)(RecurringChecklistEditStatusButtons);
