import React from "react";
import { components, GroupBase, MultiValueGenericProps } from "react-select";
import classNames from "classnames";
import { OptionType } from "../SelectDropdownTypes";

export const MultiValueLabelWithClick = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: MultiValueGenericProps<Option, IsMulti, Group>,
) => {
    const handlePreventDefault = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClick = () => {
        const data = props.data as OptionType | undefined;

        if (
            data?.value &&
            props.selectProps.onPreview &&
            props.data?.param !== false
        ) {
            props.selectProps.onPreview(data.value as string);
        }
    };

    const isPreviewable =
        !!props.selectProps.onPreview && props.data?.param !== false;

    const innerPropsClassName = classNames(props.innerProps?.className, {
        "preview-value": isPreviewable,
        "pointer-events-all": isPreviewable,
        "preview-link": isPreviewable,
    });

    return (
        <components.MultiValueLabel
            {...props}
            innerProps={
                {
                    ...props.innerProps,
                    onMouseDown: isPreviewable
                        ? handlePreventDefault
                        : undefined,
                    onClick: isPreviewable ? handleClick : undefined,
                    className: innerPropsClassName,
                } as JSX.IntrinsicElements["div"]
            }
        />
    );
};
