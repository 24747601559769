import React from "react";

import { useTranslation } from "react-i18next";
import { FiCopy } from "react-icons/fi";
import { useDispatch } from "react-redux";

import Badge from "common/components/badges/Badge";
import IconButton from "common/components/buttons/icon-button/IconButton";
import IValidationMessages from "common/viewModels/IValidationMessages";
import IconWithMessage from "components/common/iconsWithMessage/IconWithMessage";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";
import IStep from "models/IStep";
import { showConfirmNoThunk } from "store/confirms/actions";

import "./EditableStepHeaderTitle.scss";

interface IProps {
    step: IStep;
    index: number;
    isArchived?: boolean;
    errors?: IValidationMessages;

    onCopyStep?: (stepId: string) => void;
}

const EditableStepHeaderTitle = (props: React.PropsWithChildren<IProps>) => {
    const { step, index, isArchived, errors, onCopyStep } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isDisabled = step.isDeleted || isArchived || step.isLocal !== true;

    const error =
        errors?.["step." + step.id + ".activities"] ||
        errors?.["step." + step.id + ".name"];

    const stepName = step.name
        ? _improvementStepTitles(step.name, true, false)
        : step.name;

    const handleOnCopyStep = async () => {
        if (await showConfirmNoThunk(dispatch, t(k.COPY_STEP_MESSAGE))) {
            onCopyStep?.(step.id);
        }
    };

    const canCopyStep = step.isLocal !== false && onCopyStep !== undefined;

    return (
        <>
            <div
                className="editable-step--header--title"
                data-testid="step-title"
            >
                {index && index + ". "}
                {stepName && getLocaleString(t, stepName)}

                {step.isLocal !== true && (
                    <Badge pill variant="primary" testId="shared-step-badge">
                        {t(k.SHARED_STEP)}
                    </Badge>
                )}
                {step.isDeleted && (
                    <Badge pill variant="danger" testId="deleted-step-badge">
                        {t(k.DELETED)}
                    </Badge>
                )}
                {canCopyStep && (
                    <IconButton
                        className="editable-step--header-title--copy"
                        testId="copy-step-btn"
                        disabled={isDisabled}
                        onClick={handleOnCopyStep}
                    >
                        <FiCopy />
                    </IconButton>
                )}

                {error && (
                    <IconWithMessage
                        iconType="warning"
                        id={"step." + step.id + ".header.title"}
                        message={error}
                    />
                )}
            </div>
        </>
    );
};

export default EditableStepHeaderTitle;
