import { useContext } from "react";
import { IModalListItem, ModalTypes } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

import { ChecklistReportWrapper } from "common/components/modal-manager-content/checklist-report-wrapper/ChecklistReportWrapper";

interface IProps {
    modalItem: IModalListItem;
}

const ChecklistReportModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, modalFor, options },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = () => {
        modalManagerContext.onCloseModal({ callId });
    };

    const enableSync = modalManagerContext.state.activeModal === callId;

    return (
        <ChecklistReportWrapper
            checklistReportState={options?.checklistReportState}
            customListItemIds={
                modalFor?.type === ModalTypes.custom_list
                    ? [modalFor.id]
                    : undefined
            }
            enableSync={enableSync}
            onClose={handleOnClose}
        />
    );
};

export default ChecklistReportModal;
