import axios from "axios";
import { useQuery } from "react-query";
import IDictionary from "common/viewModels/IDictionary";

const baseUrl = "/api/custom-list-item";

export const useCheckIfCanRunChecklists = (customListItemIds: string[]) => {
    return useQuery(
        ["check-custom-list-item-can-run-checklists", customListItemIds],
        async (context) => {
            const url = `${baseUrl}/can-run-checklists`;

            const data = customListItemIds

                .map((id) => {
                    return `ids=${id}`;
                })
                .join("&");

            const response = await axios.post<IDictionary<string[]>>(
                url,
                data,
                {
                    signal: context.signal,
                },
            );

            return {
                anyResult: Object.keys(response.data).length > 0,
                values: response.data,
            };
        },
        { enabled: customListItemIds.length > 0 },
    );
};
