import "./Positions.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import FormattedDate from "../formattedDate/FormattedDate";
import FormattedDateRelative from "../formattedDate/FormattedDateRelative";
import { languageToLocale } from "AppLocale";

interface IProps {
    startDate?: Date;
    endDate?: Date;
}

const PositionsDateView = (props: React.PropsWithChildren<IProps>) => {
    const { startDate, endDate } = props;

    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const formating = new Intl.DateTimeFormat(languageToLocale(localeId), {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    return (
        <div className="positions-date-view">
            {formating.format(startDate)}
            {" - "}
            {endDate ? formating.format(endDate) : t(k.PRESENT)}
            {" • "}
            <FormattedDateRelative startDate={startDate} endDate={endDate} />
        </div>
    );
};

export default PositionsDateView;
