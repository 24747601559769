import React from "react";

import { IActivityProps } from "./IActivityProps";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { DropdownIcon } from "common/components/icons";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import ValidationLabel from "../validation/ValidationLabel";

const ActivitySelectionField: React.FC<IActivityProps> = (props) => {
    const { activity, onChange, typeField, isEditMode, errors } = props;

    const { t } = useTranslation();

    const typeList: React.ReactElement = (
        <div className="step-activity-options">
            {isEditMode && (
                <div className="step-activity-options--label">
                    {t(k.OPTIONS)}
                </div>
            )}
            <div className="step-activity-options--field">
                <div className="icon">
                    <DropdownIcon />
                </div>
                <TextfieldComplex
                    hideIcon
                    multiline
                    id={`Activity.${activity.id}.Options`}
                    testId="placeholder"
                    name={"placeholder"}
                    onChange={onChange}
                    placeholder={t(k.VALUES_COMMA_SEPERATED_LIKE_A_B_C)}
                    value={activity.placeholder ?? ""}
                    disabled={!isEditMode}
                    invalid={Boolean(
                        errors?.[`Activity.${activity.id}.Options`],
                    )}
                    helperText={
                        <ValidationLabel
                            errors={errors?.[`Activity.${activity.id}.Options`]}
                        />
                    }
                />
            </div>
        </div>
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.ADD_DROPDOWN)}`,
    });
};

export default ActivitySelectionField;
