import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import NewImprovementContext from "../../newImprovementContext";
import StepCardById from "common/components/step-card/StepCardById";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import SubmitImprovement from "common/components/submit-improvement-card/SubmitImprovement";

import { ActivityType } from "models/enums/ActivityType";
import RegistrationStep from "components/improvements/components/RegistrationStep/RegistrationStep";

const Content: React.FC = () => {
    const {
        steps,
        activities,
        editedActivityValues,
        setEditedActivityValues,
        filter,
        onClose,
        onHaveChanges,
        setErrors,
    } = useContext(NewImprovementContext);

    const { t } = useTranslation();

    if (steps === undefined || steps.ids.length <= 0) {
        return null;
    }

    const handleOnChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const { id, value, selectedIds } = e;

            const activity = activities?.values[id];
            let isCompleted: boolean | undefined;

            if (
                activity?.type === ActivityType.Barcodes ||
                activity?.type === ActivityType.GenerateBarcode
            ) {
                isCompleted = e.invalid ? undefined : true;
            }

            setEditedActivityValues((state) => ({
                ...state,

                [id]: {
                    value,
                    selectedIds,
                    completed: isCompleted,
                    type: activity?.type,
                },
            }));

            onHaveChanges(true);

            if (e.invalid) {
                updateErrors(id, t(k.INVALID));
            } else {
                updateErrors(id, undefined);
            }
        }
    };

    const updateErrors = (id: string, newError?: string) => {
        setErrors((prev) => {
            if (prev) {
                if (newError) {
                    return {
                        ...prev,
                        [id]: newError,
                    };
                } else if (prev[id]) {
                    const { [id]: deletedError, ...result } = prev;
                    return result;
                }
            }
            return prev;
        });
    };

    const stepId = steps.ids[0];

    return (
        <>
            <StepCardById
                context={NewImprovementContext}
                key={stepId}
                id={stepId}
                index={1}
                registrationStep={
                    <RegistrationStep
                        editable
                        context={NewImprovementContext}
                        createNew
                    />
                }
                onChange={handleOnChange}
                enableInputs={true}
                isImprovement={true}
            />

            <SubmitImprovement onClose={onClose} />
        </>
    );
};

export default Content;
