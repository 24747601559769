import TeamForm from "components/teams/components/TeamForm";
import { useContext } from "react";
import { IModalListItem } from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";

interface IProps {
    modalItem: IModalListItem;
}

const TeamFormModal: React.FC<IProps> = (props) => {
    const { id, callId, options } = props.modalItem;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };
    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <TeamForm
            id={id}
            createNew={options?.createNew}
            onHaveChanges={handleOnHaveChanges}
            onCloseModal={handleOnClose}
        />
    );
};

export default TeamFormModal;
