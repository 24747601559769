import "./UndoButton.scss";

import React from "react";
import { MdReplay } from "react-icons/md";

interface IProps {
    onClick?: () => void;
}

const UndoButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className="undo-button__wrapper no-print" onClick={props.onClick}>
            <MdReplay />
        </div>
    );
};

export default UndoButton;
