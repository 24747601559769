import "./PhotoIcon.scss";

import React from "react";
import { MdPhotoCamera } from "react-icons/md";

interface IProps {
    disabled?: boolean;
}

const PhotoIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <MdPhotoCamera
            className={
                props.disabled ? "icon-photo icon-disabled" : "icon-photo"
            }
        />
    );
};

export default PhotoIcon;
