import { TFunction } from "i18next";
import React from "react";
import { toast, ToastOptions } from "react-toastify";
import k from "i18n/keys";
// Define the possible types for the message
type ToastMessage = string | string[] | React.ReactNode;

// Toast options can be configured if needed
const toastOptions: ToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const showErrorToast = (
    t: TFunction<"translation", undefined>,
    message?: ToastMessage,
) => {
    let processedMessage: React.ReactNode;

    if (Array.isArray(message) && message.length > 1) {
        // If message is an array, map over and create a list
        processedMessage = (
            <ul>
                {message.map((msg, index) => (
                    <li key={index}>{msg}</li>
                ))}
            </ul>
        );
    } else if (
        typeof message === "string" ||
        (Array.isArray(message) && message.length === 1)
    ) {
        // If message is a string, wrap it in a div
        processedMessage = <div>{message}</div>;
    } else {
        // If message is a React node, use it as is
        processedMessage = React.isValidElement(message) ? message : t(k.ERROR);
    }

    // Display the processed message in a toast
    toast.error(processedMessage, toastOptions);
};

export default showErrorToast;
