import { Action } from "redux";

export const RECEIVE_PAGE_CHANGE = "RECEIVE_PAGE_CHANGE";

export const RECEIVE_DEVIATION_CHANGE = "RECEIVE_DEVIATION_CHANGE";

interface IReceivePageChange extends Action<typeof RECEIVE_PAGE_CHANGE> {
    payload?: boolean;
}

interface IReceiveDeviationChange
    extends Action<typeof RECEIVE_DEVIATION_CHANGE> {
    payload?: boolean;
}

export type PageNavigationActionTypes =
    | IReceivePageChange
    | IReceiveDeviationChange;
