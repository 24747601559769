import "./AddDataSourceModal.scss";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import Modal from "common/components/modals/Modal";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Button from "common/components/buttons/Button";
import InputComponent from "common/components/input-components/InputComponent";
import { EActivityType } from "common/components/input-components/EActivityType";
import RadioButton from "common/components/input-components/radioButton/RadioButton";
import { ImprovementFormMultiselect } from "components/common/multiselect-dropdowns/ImprovementFormMultiselect";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { useDispatch, useSelector } from "react-redux";
import { ImpactMultiselect } from "components/common/multiselect-dropdowns/ImpactMultiselect";
import { IVariableDataSourceDto } from "../../api/IVariableDataSourceDto";
import { VariableDataSourceType } from "../../api/VariableDataSourceType";
import QuickTips from "common/components/quick-tip/QuickTips";
import { ProcessChartProcessMultiselect } from "components/common/multiselect-dropdowns/ProcessChartProcessMultiselect";
import { KpiMultiselect } from "components/common/multiselect-dropdowns/KpiMultiselect";
import { IDataSourceFilterDto } from "../../api/IDataSourceFilterDto";
import InputWrapper from "common/components/input-components/InputWrapper";
import ImprovementStatusTreeWrapper from "common/components/input-components/dropdown-tree/ImprovementStatusTreeWrapper";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { IAppState } from "store/IAppState";

interface IProps {
    kpiId?: string;
    formulaItemId?: string;
    data?: IVariableDataSourceDto;
    onHide: (confirm?: boolean) => void;
    onSave: (formulaItemId: string, data: IVariableDataSourceDto) => void;
}

const initialDataSource = {
    id: uuidv4(),
    name: "",
    dataSourceId: uuidv4(),
    type: VariableDataSourceType.Improvement,
    filterId: uuidv4(),
    filter: {
        id: uuidv4(),
    },
};

const AddDataSourceModal = (props: IProps) => {
    const { formulaItemId, data, kpiId } = props;

    const dispatch = useDispatch();
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const processFeatureEnabled = useSelector(
        (appState: IAppState) =>
            appState.authViewState.profile?.appFeatures?.processChart &&
            appState.authViewState.profile?.appFeatures
                ?.assignImpactedProcessesToImprovement,
    );

    const [value, setValue] = useState<IVariableDataSourceDto>(
        data ?? initialDataSource,
    );

    useEffect(() => {
        if (data) {
            setValue({
                ...data,
            });
        } else {
            setValue(initialDataSource);
        }
    }, [data]);

    const { t } = useTranslation();

    const onCancel = async () => {
        if (hasChanges) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            if (await showConfirmNoThunk(dispatch, args)) {
                props.onHide();
                setHasChanges(false);
            }
        } else {
            props.onHide();
            setHasChanges(false);
        }
    };

    const onChangeFilterText = (e: IInputChangeEvent<string>) => {
        setValue((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                containsText: e.value,
            },
        }));
        setHasChanges(true);
    };

    const handleOnChooseImprovementDataSourceType = () => {
        setValue((prev) => ({
            ...prev,
            type: VariableDataSourceType.Improvement,
        }));
    };
    const handleOnChooseAnotherKPIType = () => {
        setValue((prev) => ({
            ...prev,
            type: VariableDataSourceType.Kpi,
        }));
    };
    const handleOnChooseManualType = () => {
        setValue((prev) => ({
            ...prev,
            type: VariableDataSourceType.Manual,
        }));
    };

    const handleOnSave = () => {
        if (formulaItemId) {
            props.onSave(formulaItemId, value);
            setHasChanges(false);
            props.onHide();
        }
    };

    const handleUpdateFilters = (
        filterKey: keyof IDataSourceFilterDto,
        data: any,
    ) => {
        setValue((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                [filterKey]: data,
            },
        }));

        setHasChanges(true);
    };

    const handleUpdateImprovementStatusTree = (
        statuses: DynamicImprovementStatus[],
        closureReasons: ClosureReasonEnum[],
    ) => {
        handleUpdateFilters("improvementStatuses", statuses);
        handleUpdateFilters("closureReasons", closureReasons);
    };

    const handleChangeDiscoveredImpactedProcess = (
        showOrphaned: boolean,
        values: string[],
    ) => {
        setValue((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                discoveredInIds: values,
                otherNotSureDiscoveredImpactedProcesses: showOrphaned,
            },
        }));

        setHasChanges(true);
    };

    const handleChangeOriginatedImpactedProcess = (
        showOrphaned: boolean,
        values: string[],
    ) => {
        setValue((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                originatedInIds: values,
                otherNotSureOriginatedImpactedProcesses: showOrphaned,
            },
        }));

        setHasChanges(true);
    };

    const handleUpdateKpiDataSourceId = (data: string) => {
        setValue((prev) => ({
            ...prev,
            kpiId: data,
        }));

        setHasChanges(true);
    };

    return (
        <Modal
            show={!!formulaItemId}
            testId="add-data-source-modal"
            onHide={onCancel}
            className="add-data-source-modal"
            size="lg"
            fullHeight
        >
            <Modal.Title>
                {data ? t(k.EDIT_DATA_SOURCE) : t(k.ADD_DATA_SOURCE)}
            </Modal.Title>
            <Modal.Body className="add-data-source-modal--body">
                <div className="add-data-source-modal--container">
                    <InputWrapper
                        wrapperLabel={t(k.DATA_SOURCE)}
                        boldLabel
                        fullWidth
                    >
                        <div className="add-data-source-modal--radio-buttons">
                            <RadioButton
                                radioSize="large"
                                value={
                                    value?.type ===
                                    VariableDataSourceType.Improvement
                                }
                                label={t(k.IMPROVEMENT_DATA)}
                                onChange={
                                    handleOnChooseImprovementDataSourceType
                                }
                            />
                            <RadioButton
                                radioSize="large"
                                value={
                                    value?.type === VariableDataSourceType.Kpi
                                }
                                label={t(k.ANOTHER_KPI)}
                                onChange={handleOnChooseAnotherKPIType}
                            />
                            <RadioButton
                                radioSize="large"
                                value={
                                    value?.type ===
                                    VariableDataSourceType.Manual
                                }
                                label={t(k.MANUAL)}
                                onChange={handleOnChooseManualType}
                            />
                        </div>
                    </InputWrapper>
                    {value?.type === VariableDataSourceType.Manual && (
                        <div className="manual">
                            {t(k.MANUAL_DATA_SOURCE_DESCRIPTION)}
                        </div>
                    )}
                    {value?.type === VariableDataSourceType.Improvement && (
                        <>
                            <ImprovementFormMultiselect
                                wrapperLabel={t(k.FILTER_BY_IMPROVEMENT_FORM)}
                                boldLabel
                                fullWidth
                                closeMenuOnSelect
                                defaultSelectedIds={
                                    data?.filter?.improvementFormIds
                                }
                                selectedIds={
                                    value?.filter?.improvementFormIds
                                        ? value?.filter?.improvementFormIds
                                        : []
                                }
                                onChange={(data) =>
                                    handleUpdateFilters(
                                        "improvementFormIds",
                                        data,
                                    )
                                }
                            />

                            <ImpactMultiselect
                                wrapperLabel={t(k.FILTER_BY_IMPACT)}
                                boldLabel
                                fullWidth
                                selectedValues={
                                    value?.filter?.improvementImpacts
                                        ? value?.filter?.improvementImpacts
                                        : []
                                }
                                onChange={(data) =>
                                    handleUpdateFilters(
                                        "improvementImpacts",
                                        data,
                                    )
                                }
                            />

                            <ImprovementStatusTreeWrapper
                                selectedClosureReasons={
                                    value.filter?.closureReasons ?? null
                                }
                                selectedStatuses={
                                    value?.filter?.improvementStatuses ?? null
                                }
                                onChange={(statuses, closureReasons) =>
                                    handleUpdateImprovementStatusTree(
                                        statuses,
                                        closureReasons,
                                    )
                                }
                                wrapperLabel={t(k.FILTER_BY_STATUS)}
                                showRemove
                                boldLabel
                                fullWidth
                            />

                            {processFeatureEnabled && (
                                <>
                                    <ProcessChartProcessMultiselect
                                        fullWidth
                                        boldLabel
                                        label={t(
                                            k.FILTER_BY_DISCOVERED_IN_PROCESS,
                                        )}
                                        showOrphaned={
                                            value.filter
                                                ?.otherNotSureDiscoveredImpactedProcesses
                                        }
                                        selectedIds={
                                            value?.filter?.discoveredInIds
                                                ? value?.filter?.discoveredInIds
                                                : []
                                        }
                                        onChange={
                                            handleChangeDiscoveredImpactedProcess
                                        }
                                    />

                                    <ProcessChartProcessMultiselect
                                        fullWidth
                                        boldLabel
                                        label={t(
                                            k.FILTER_BY_ORIGINATED_IN_PROCESS,
                                        )}
                                        showOrphaned={
                                            value.filter
                                                ?.otherNotSureOriginatedImpactedProcesses
                                        }
                                        selectedIds={
                                            value?.filter?.originatedInIds
                                                ? value?.filter?.originatedInIds
                                                : []
                                        }
                                        onChange={
                                            handleChangeOriginatedImpactedProcess
                                        }
                                    />
                                </>
                            )}
                            <InputComponent
                                wrapperLabel={t(
                                    k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT,
                                )}
                                boldLabel
                                placeholder={t(k.ENTER_HERE)}
                                inputType={EActivityType.Textfield}
                                fullWidth
                                hideIcon
                                size="large"
                                value={value.filter?.containsText ?? ""}
                                helperText={
                                    <div className="filter-description">
                                        {t(
                                            k.FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT_DESCRIPTION,
                                        )}
                                    </div>
                                }
                                onChange={onChangeFilterText}
                            />
                        </>
                    )}
                    {value?.type === VariableDataSourceType.Kpi && (
                        <KpiMultiselect
                            excludeId={kpiId}
                            boldLabel
                            label={t(k.SELECT_KPI)}
                            isSingle
                            selectedIds={value?.kpiId ? [value?.kpiId] : []}
                            onChange={(data) =>
                                handleUpdateKpiDataSourceId(data[0])
                            }
                        />
                    )}
                </div>

                <div className="add-data-source-modal--tips">
                    <QuickTips tips={[k.DATA_SOURCE_TIP1]} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup spaceBetween={true}>
                    <Button
                        testId="cancel-button"
                        variant="danger"
                        transparent
                        onClick={onCancel}
                    >
                        {t(k.CANCEL)}
                    </Button>

                    <Button testId="save-button" onClick={handleOnSave}>
                        {t(k.SAVE)}
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default AddDataSourceModal;
