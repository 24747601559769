import k from "i18n/keys";

import React from "react";
import { useTranslation } from "react-i18next";
import { IActivityProps } from "./IActivityProps";
import { CustomerIcon } from "common/components/icons";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityCustomerDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<CustomerIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.ADD_CUSTOMER_DROPDOWN)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityCustomerDropdown;
