import { ActivityType } from "models/enums/ActivityType";
import Checkbox from "./checkbox/Checkbox";
import Dropdown from "./dropdown/Dropdown";
import Photofield from "./photofield/Photofield";
import Textarea from "./textarea/Textarea";
import TextfieldComplex from "./textfield/TextfieldComplex";
import { IInputChangeEvent, IInputProps } from "./IInputProps";
import CustomerDropdown from "./dropdown/CustomerDropdown";
import SupplierDropdown from "./dropdown/SupplierDropdown";
import ItemDropdown from "./dropdown/ItemDropdown";
import BarcodeField from "./barcode/BarcodeField";
import Datetime from "./datetime/Datetime";
import CustomListDropdown from "./dropdown/CustomListDropdown";
import GenerateBarcodeField from "./barcode/GenerateBarcodeField";
import HyperlinkField from "./textfield/HyperlinkField";
import UserDropdown from "./dropdown/UserDropdown";
import PositionDropdown from "./dropdown/PositionDropdown";
import CompetencyDropdown from "./dropdown/CompetencyDropdown";

interface IProps<V> extends IInputProps<V> {
    type: ActivityType;

    completed?: boolean;
}

const componentsLookup: Record<ActivityType, any> = {
    [ActivityType.Value]: TextfieldComplex,
    [ActivityType.Text]: Textarea,
    [ActivityType.Photo]: Photofield,
    [ActivityType.Selection]: Dropdown,
    [ActivityType.Customers]: CustomerDropdown,
    [ActivityType.Suppliers]: SupplierDropdown,
    [ActivityType.Items]: ItemDropdown,
    [ActivityType.Barcodes]: BarcodeField,
    [ActivityType.GenerateBarcode]: GenerateBarcodeField,
    [ActivityType.DateTime]: Datetime,
    [ActivityType.Tasklist]: Checkbox,
    [ActivityType.CustomList]: CustomListDropdown,
    [ActivityType.Hyperlink]: HyperlinkField,
    [ActivityType.Users]: UserDropdown,
    [ActivityType.Positions]: PositionDropdown,
    [ActivityType.Competencies]: CompetencyDropdown,
};

const ActivityInstanceInput = <V,>(props: IProps<V>) => {
    const { type, ...otherProps } = props;

    const { completed, value } = otherProps;

    const handleCheckboxChange = (e: IInputChangeEvent<V>) => {
        if (props.onChange) {
            const newValue = (e.value ? "1" : "") as V;

            props.onChange({
                ...e,
                value: newValue,
                selectedIds: undefined,
            });
        }
    };

    if (type === ActivityType.CustomList) {
        return (
            <CustomListDropdown {...otherProps} mode="activity" withDebounce />
        );
    }

    if (type === ActivityType.Tasklist) {
        const isChecked = completed ? true : value === "1";

        return (
            <Checkbox
                {...otherProps}
                value={isChecked as V}
                selectedIds={undefined}
                withDebounce
                onChange={handleCheckboxChange}
            />
        );
    }

    const Component = componentsLookup[type];

    return Component ? <Component {...otherProps} withDebounce /> : null;
};

export default ActivityInstanceInput;
