import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { isOnTestAmpliflowHost } from "config";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

let appInsightsInstrumentationKey = "";

if (window.location.host.indexOf("ampliflow.com") > -1) {
    console.log("AI initialize");
    appInsightsInstrumentationKey = "1da7d3e9-838f-46d7-b0f0-22926b5a4a18";
} else {
    console.log("AI Dev initialize");
    appInsightsInstrumentationKey = "afbadc21-ead1-4e14-8237-3d146f081a72";
}

if (isOnTestAmpliflowHost()) {
    console.log("AI Test initialize");
    appInsightsInstrumentationKey = "742e5ae9-5e53-416c-9f94-e0def48ca767";
}

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: appInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export { reactPlugin, appInsights };
