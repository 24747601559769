import { AppLocale } from "AppLocale";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";

import { useRunChecklist } from "components/checklists/api/hooks";
import {
    useCanEditTemplate,
    useTemplateByVersionId,
} from "components/templates/api/hooks";
import { format } from "date-fns";
import { IQuickRunRequest } from "http/requests/IQuickRunRequest";
import IActivity from "models/IActivity";
import IStep from "models/IStep";

import { createRef, RefObject, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import IDictionary from "common/viewModels/IDictionary";
import ITemplatePreviewData from "./ITemplatePreviewData";
import IAttachment from "models/IAttachment";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ITableSet from "http/ITableSet";
import { useStepsByTemplateVersionId } from "components/steps/api/hooks";
import { ITemplatePreviewStepActivity } from "components/templates/components/ITemplatePreviewStepActivity";
import { ITemplatePreviewProps } from "../TemplatePreview";

export interface ITemplateSteps {
    step: IStep;
    activities: ITemplatePreviewStepActivity[];
}

export const useTemplatePreviewData = (
    props: ITemplatePreviewProps,
): ITemplatePreviewData => {
    const { id, sourceCustomListItemId, syncData } = props;

    const location = useLocation();

    const runChecklist = useRunChecklist();

    const [isSaving, setIsSaving] = useState(false);

    const [errors, setErrors] = useState<IValidationMessages>();

    const [stepRefs, setStepRefs] =
        useState<IDictionary<RefObject<HTMLDivElement>>>();

    const { data: canEditTemplate } = useCanEditTemplate(id);

    const { data: template, isLoading: isLoadingTemplate } =
        useTemplateByVersionId(id, syncData);

    const { data: stepsList, isLoading: isLoadingStepsList } =
        useStepsByTemplateVersionId(id, true, syncData);

    const steps: ITableSet<IStep> | undefined = stepsList &&
        template && {
            values: stepsList.steps,
            ids: template.stepIds,
        };

    const activities: ITableSet<IActivity> | undefined = stepsList && {
        values: stepsList.activities,
        ids: Object.keys(stepsList.activities),
    };

    const attachments: ITableSet<IAttachment> | undefined = stepsList && {
        values: stepsList.attachments,
        ids: Object.keys(stepsList.attachments),
    };

    const activityInputs: ITableSet<IActivityInputListItem> | undefined =
        stepsList && {
            values: stepsList.activityInputs,
            ids: Object.keys(stepsList.activityInputs),
        };

    useEffect(() => {
        if (steps?.ids) {
            setStepRefs(
                steps.ids.reduce<{
                    [key: string]: React.RefObject<HTMLDivElement>;
                }>((prev, cur) => {
                    return { ...prev, [cur]: createRef() };
                }, {}),
            );
        }
    }, [steps?.ids]);

    const handleClickEdit = () => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.template_form,
            callerAddress: location.pathname,
        };

        props.onShowModal(payload);
    };

    const onQuickRun = async (request: IQuickRunRequest) => {
        if (id) {
            return await runChecklist.mutateAsync({
                request,
            });
        }
    };

    const handleRunChecklist = async () => {
        if (id) {
            const quickRunRequest: IQuickRunRequest = {
                versionId: id,
                comment: format(new Date(), "P", AppLocale),
                sourceCustomListItemId,
            };

            setIsSaving(true);

            const result = await onQuickRun(quickRunRequest);

            setIsSaving(false);

            if (result?.Succeeded) {
                const checklistId = result.Data;

                if (checklistId) {
                    const payload: IShowModalPayload = {
                        id: checklistId,
                        type: ModalTypes.checklist,
                        callerAddress: location.pathname,
                        options: { showAll: true }, //checkCanSeeAll
                    };
                    props.onShowModal(payload);
                }

                props.onClose(true);
            }
        }
    };

    const handleCreateRecurrence = () => {
        if (id) {
            const payload: IShowModalPayload = {
                modalFor: {
                    type: ModalTypes.template,
                    id,
                },
                type: ModalTypes.recurrence,
                callerAddress: location.pathname,
                options: { createNew: true },
            };
            props.onShowModal(payload);
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const initialSection = steps && steps.ids[0];

    const isLoading = isLoadingTemplate && isLoadingStepsList;

    return {
        isLoading,
        isLoadingTemplate,
        isLoadingSteps: isLoadingStepsList,
        isSaving,
        canEditTemplate,
        errors,
        setErrors,
        steps,
        activities,
        activityIdsByStepId: stepsList?.activityIdsByStepId,
        attachments,
        attachmentIdsByActivityId: stepsList?.attachmentsIdsByActivityId,
        activityInputs,
        activityInputsIdsByActivityIds:
            stepsList?.activityInputsIdsByActivityId,
        template,
        sectionRefs: stepRefs,
        initialSection,
        handleOnClose,
        handleClickEdit,
        handleRunChecklist,
        handleCreateRecurrence,
    };
};
