import "./ActivityDeleteButton.scss";

import React from "react";

import { DeleteIcon, UndoIcon } from "common/components/icons";

interface IProps {
    testId?: string;
    isDeleted?: boolean;

    onDelete: () => void;
}

const ActivityDeleteButton: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const handleDelete = (e: any) => {
        e.preventDefault();
        props.onDelete();
    };
    return (
        <button
            data-testid={props.testId}
            className="step-activity-btn"
            onClick={handleDelete}
        >
            {props.isDeleted ? <UndoIcon /> : <DeleteIcon />}
        </button>
    );
};

export default ActivityDeleteButton;
