import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";

import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import { ActivityType } from "models/enums/ActivityType";
import { Context, PropsWithChildren, useContext } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ActivityInstanceCard from "../activity-instance-card/ActivityInstanceCard";
import { InputValueType } from "../activity-instance-card/ActivityInstanceCardById";
import ActivityInstanceSetCard from "../activity-instance-card/ActivityInstanceSetCard";
import ICurrentSectionData from "../current-scrolled-section/models/ICurrentSectionData";
import EquipmentsList from "../equipment/EquipmentsList";
import ImagePreview from "../images/image-preview/ImagePreview";
import { IInputChangeEvent } from "../input-components/IInputProps";
import TeamsListById from "../team/TeamsListById";
import IStepCardData from "./model/IStepCardData";
import StepCard from "./StepCard";
import IAttachment from "models/IAttachment";
import IActivityInputListItem from "http/requests/IActivityInputListItem";

interface IProps<
    T extends IStepCardData &
        ICurrentSectionData & {
            errors?: IValidationMessages;
            editedActivityValues?: IDictionary<InputValueType>;
        },
> {
    context: Context<T>;
    id: string;
    index: number;

    sourceCustomListItemId?: string;

    enableInputs?: boolean;
    isImprovement?: boolean;
    registrationStep?: JSX.Element;

    onChange?: (e: IInputChangeEvent<string>) => void;
}

const StepCardById = <
    T extends IStepCardData &
        ICurrentSectionData & {
            errors?: IValidationMessages;
            editedActivityValues?: IDictionary<InputValueType>;
        },
>(
    props: PropsWithChildren<IProps<T>>,
) => {
    const {
        context,
        id,
        index,
        sourceCustomListItemId,
        isImprovement,
        registrationStep,
        enableInputs,
    } = props;

    const {
        steps,
        activities,
        activityIdsByStepId,
        attachments,
        attachmentIdsByActivityId,
        activityInputs,
        activityInputsIdsByActivityIds,
        errors,
        sectionRefs,
        editedActivityValues = {},
        profile,
    } = useContext(context);

    const { t } = useTranslation();

    const step = steps?.values[id];

    const noAddedTasksTranslation = isImprovement
        ? k.NO_IMPROVEMENT_FORM_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_IMPROVEMENTS
        : k.NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS;

    const handleOnUndo = (activityId: string) => () => {
        if (props.onChange) {
            props.onChange({ id: activityId, value: "" });
        }
    };

    return step ? (
        <StepCard
            key={id}
            step={step}
            index={index}
            isImprovement={isImprovement}
            activityList={
                <>
                    {registrationStep}

                    {activities && activityIdsByStepId
                        ? activityIdsByStepId[id]?.reduce<JSX.Element[]>(
                              (list, activityId, activityIndex) => {
                                  const activity =
                                      activities.values[activityId];

                                  if (activity === undefined) {
                                      return list;
                                  }

                                  const activityAttachments =
                                      attachments && attachmentIdsByActivityId
                                          ? attachmentIdsByActivityId[
                                                activityId
                                            ]?.reduce<IAttachment[]>(
                                                (acc, x) => {
                                                    const attachment =
                                                        attachments.values[x];
                                                    if (attachment) {
                                                        acc.push(attachment);
                                                    }
                                                    return acc;
                                                },
                                                [],
                                            )
                                          : undefined;

                                  const inputs =
                                      activityInputs &&
                                      activityInputsIdsByActivityIds
                                          ? activityInputsIdsByActivityIds[
                                                activityId
                                            ]?.reduce<IActivityInputListItem[]>(
                                                (acc, x) => {
                                                    const input =
                                                        activityInputs.values[
                                                            x
                                                        ];
                                                    if (input) {
                                                        acc.push(input);
                                                    }
                                                    return acc;
                                                },
                                                [],
                                            )
                                          : undefined;

                                  const editedActivity =
                                      editedActivityValues[activity.id];

                                  let selectedIds =
                                      editedActivity?.selectedIds ??
                                      activity.selectedIds;

                                  if (
                                      activity.type ===
                                          ActivityType.CustomList &&
                                      !enableInputs &&
                                      index === 1 &&
                                      sourceCustomListItemId
                                  ) {
                                      selectedIds = [sourceCustomListItemId];
                                  }

                                  list.push(
                                      <ActivityInstanceSetCard
                                          key={activityId}
                                          id={activityId}
                                          type={activity.type}
                                          required={activity.isRequired}
                                          index={activityIndex + 1}
                                          title={activity.label}
                                          description={activity.description}
                                          attachments={activityAttachments}
                                          activityPhoto={
                                              activity.hasImage && (
                                                  <ImagePreview
                                                      imgId={
                                                          activity.uploadedFileId
                                                      }
                                                  />
                                              )
                                          }
                                      >
                                          {inputs?.length ? (
                                              inputs.map((act, inputIndex) => (
                                                  <ActivityInstanceCard
                                                      key={act.id}
                                                      id={act.id}
                                                      isFirst={inputIndex === 0}
                                                      value={
                                                          editedActivityValues[
                                                              act.id
                                                          ]?.value ??
                                                          act.value ??
                                                          ""
                                                      }
                                                      type={activity.type}
                                                      disabled={!enableInputs}
                                                      preview={!enableInputs}
                                                      label={act.label}
                                                      required={act.isRequired}
                                                      options={activity.options}
                                                      onChange={props.onChange}
                                                      onUndo={handleOnUndo(
                                                          act.id,
                                                      )}
                                                      originType={
                                                          isImprovement
                                                              ? TaskOriginType.Improvement
                                                              : TaskOriginType.None
                                                      }
                                                      invalid={Boolean(
                                                          errors &&
                                                              errors[act.id],
                                                      )}
                                                      error={
                                                          errors &&
                                                          errors[act.id]
                                                      }
                                                  />
                                              ))
                                          ) : profile?.appFeatures
                                                ?.userDefinedTasks &&
                                            activity.type ===
                                                ActivityType.Tasklist ? (
                                              <p className="activity-instance--card--empty">
                                                  {t(noAddedTasksTranslation)}
                                              </p>
                                          ) : (
                                              <ActivityInstanceCard
                                                  key={activity.id}
                                                  id={activity.id}
                                                  isNew={true}
                                                  multiSelectEnabled={
                                                      activity.multiSelectEnabled
                                                  }
                                                  isFirst={activityIndex === 0}
                                                  value={
                                                      editedActivity?.value ??
                                                      activity.value ??
                                                      ""
                                                  }
                                                  completed={
                                                      editedActivity?.completed
                                                  }
                                                  selectedIds={selectedIds}
                                                  type={activity.type}
                                                  disabled={!enableInputs}
                                                  label={activity.label}
                                                  required={activity.isRequired}
                                                  placeholder={
                                                      activity.placeholder
                                                  }
                                                  options={activity.options}
                                                  onChange={props.onChange}
                                                  onUndo={handleOnUndo(
                                                      activity.id,
                                                  )}
                                                  originType={
                                                      isImprovement
                                                          ? TaskOriginType.Improvement
                                                          : TaskOriginType.None
                                                  }
                                                  invalid={Boolean(
                                                      errors &&
                                                          errors[activity.id],
                                                  )}
                                                  error={
                                                      errors &&
                                                      errors[activity.id]
                                                  }
                                              />
                                          )}
                                      </ActivityInstanceSetCard>,
                                  );

                                  return list;
                              },
                              [],
                          )
                        : null}
                </>
            }
            teamList={
                isImprovement ? undefined : (
                    <TeamsListById
                        isTeamEveryone={step.isTeamEveryone}
                        teamIds={step.teamIds}
                    />
                )
            }
            equipmentList={<EquipmentsList equipmentIds={step.equipmentIds} />}
            stepRef={sectionRefs && sectionRefs[id]}
        />
    ) : null;
};

export default StepCardById;
