import React from "react";
import { useSelector } from "react-redux";

import { ELayoutEnvironment } from "store/uiSettings/reducer";
import { IAppState } from "store/IAppState";

interface IProps {
    id: string;
    name: string;
    children: React.ReactNode;
}

export const DownloadFileLink = (props: IProps) => {
    const { id, name } = props;

    const layoutEnvironment = useSelector(
        (appState: IAppState) => appState.uiSettingsViewState.layoutEnvironment,
    );

    const tenantId = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.tenantId,
    );

    if (tenantId && layoutEnvironment === ELayoutEnvironment.TEAMS) {
        const encodedAttachmentName = encodeURIComponent(name);

        const tenantHref = `/api/attachment/${id}/${encodedAttachmentName}?tenantId=${tenantId}`;

        return (
            <a download={name} href={tenantHref}>
                {props.children}
            </a>
        );
    }

    return (
        <a download={name} href={`/api/attachment/${id}`}>
            {props.children}
        </a>
    );
};
