import "./CommentEditor.scss";

import React, { useState, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Button from "../buttons/Button";
import Avatar from "../avatar/Avatar";

interface IProps {
    invalid?: boolean;
    userName: string;

    onChange: (done: boolean, value: string) => void;
}

const CommentEditor: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { userName, invalid } = props;

    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);

    const [isMultiline, setIsMultiline] = useState(false);

    const handleSendPress = (e: any) => {
        e.stopPropagation();

        props.onChange(true, ref?.current?.innerText ?? "");

        if (ref && ref.current) {
            ref.current.innerHTML = "";
            ref.current.focus();

            setIsMultiline(false);
        }
    };

    const handleEditorChange = (e: any) => {
        e.stopPropagation();

        let text = "";
        let html = "";

        if (ref && ref.current) {
            html = ref.current.innerHTML;
            text = ref.current.innerText ?? "";

            if (text.length === 1 && text === "\n") {
                ref.current.innerHTML = "";
            }
        }

        props.onChange(false, text);

        setIsMultiline(
            html.includes("<div>") && text.includes("\n") && text.length > 1,
        );
    };

    const handleWrapperClick = () => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const editorClassName = useMemo(() => {
        const result: string[] = ["comment-editor__textarea"];

        if (isMultiline) {
            result.push("multiline");
        }

        if (invalid) {
            result.push("comment-editor--invalid");
        }

        return result.join(" ");
    }, [isMultiline, invalid]);

    return (
        <div className="comment-editor">
            <Avatar name={userName} />

            <div className={editorClassName} onClick={handleWrapperClick}>
                <div
                    ref={ref}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    placeholder={t(k.ADD_COMMENT)}
                    onInput={handleEditorChange}
                ></div>

                <Button variant="gray" onClick={handleSendPress}>
                    {t(k.SEND)}
                </Button>
            </div>
        </div>
    );
};

export default CommentEditor;
