import React from "react";

import { IActivityProps } from "./IActivityProps";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { TextfieldIcon } from "common/components/icons";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";

const ActivityTextField: React.FC<IActivityProps> = (props) => {
    const { activity, onChange, typeField, isEditMode } = props;

    const { t } = useTranslation();

    const typeList: React.ReactElement = (
        <div className="step-activity-options">
            {isEditMode && (
                <div className="step-activity-options--label">
                    {t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
                </div>
            )}
            <div className="step-activity-options--field">
                <div className="icon">
                    <TextfieldIcon />
                </div>
                <TextfieldComplex
                    hideIcon
                    size="large"
                    id="placeholder"
                    name="placeholder"
                    testId="placeholder"
                    onChange={onChange}
                    placeholder={t(k.ENTER_HERE)}
                    value={activity.placeholder ?? ""}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.ADD_TEXTFIELD)}`,
    });
};

export default ActivityTextField;
