import "./HeaderFilters.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineStop } from "react-icons/ai";
import { useSelector } from "react-redux";

import k from "i18n/keys";

import ToggleView from "./ToggleView";
import IChanged from "common/IChanged";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import { FilterIcon, SearchIcon } from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import { DEFAULT_DELAY_INTERVAL_MS } from "common/utils/time";
import TemplateMultiselect from "components/common/multiselect-dropdowns/TemplateMultiselect";
import BarcodeScanAppend from "components/template-checklist-report-search/components/BarcodeScanAppend";
import IChecklistReportFilter from "components/template-checklist-report/api/IChecklistReportFilter";
import { IAppState } from "store/IAppState";

interface IProps {
    canStopMultipleChecklists?: boolean;

    value: IChecklistReportFilter;

    totalCountToStop?: number;

    onChange: (value: IChanged<IChecklistReportFilter>) => void;
    onChangeTemplates?: (selectedIds: string[]) => void;

    isPausingAll: boolean;

    onPauseAll: (value: IChecklistReportFilter) => void;

    onFetchTemplateList: (
        options: Array<IValueLabelItem<string, string>>,
    ) => void;
}

const HeaderFilters: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { canStopMultipleChecklists, totalCountToStop, isPausingAll } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState(props.value);

    const barcodeFeatureEnabled = useSelector(
        (state: IAppState) =>
            state.authViewState.profile?.appFeatures?.barcodeActivity,
    );

    useEffect(() => {
        setValue((prev) =>
            prev.keywordSearch === props.value.keywordSearch
                ? prev
                : {
                      ...value,
                      keywordSearch: props.value.keywordSearch,
                  },
        );
    }, [props.value.keywordSearch]);

    useEffect(() => {
        if (value === props.value) {
            return;
        }

        const timer = setTimeout(() => {
            const isChanged =
                Boolean(value.keywordSearch) ||
                (value.templateVersionIds?.length ?? 0) > 0;

            props.onChange({ value, isChanged });
        }, DEFAULT_DELAY_INTERVAL_MS);

        return () => clearTimeout(timer);
    }, [value]);

    const handleOnChangeKeywordFilter = (e: IInputChangeEvent<string>) => {
        setValue((prev) => ({
            ...prev,
            keywordSearch: e.value,
            splitSearchResult:
                getNextSplitSearchResult(
                    prev.templateVersionIds ?? [],
                    e.value,
                ) ?? prev.splitSearchResult,
        }));
    };

    const getNextSplitSearchResult = (
        templates: string[],
        keyword?: string,
    ) => {
        if (value.splitSearchResult && templates.length === 0) {
            return false;
        } else if (
            value.splitSearchResult === false &&
            Boolean(keyword) === false &&
            templates.length > 0
        ) {
            return true;
        }
    };

    const handleScanBarcode = (barcode: string) => {
        setValue((prev) => ({
            ...prev,
            keywordSearch: barcode,
        }));
    };

    const handleOnChangeTemplates = (newValues: string[]) => {
        if (props.onChangeTemplates) {
            props.onChangeTemplates(newValues);
        }

        setValue((prev) => ({
            ...prev,
            templateVersionIds: newValues,
            splitSearchResult:
                getNextSplitSearchResult(newValues, prev.keywordSearch) ??
                prev.splitSearchResult,
        }));
    };

    const handleChangeSplitSearchResult = (split: boolean) => {
        setValue((prev) => ({
            ...prev,
            splitSearchResult: split,
        }));
    };

    const handlePauseAll = () => {
        props.onPauseAll(value);
    };

    const noKeywordSearchOrTemplateNotSelected =
        Boolean(props.value.keywordSearch) === false &&
        (props.value.templateVersionIds?.length ?? 0) === 0;

    const stopChecklistIsHidden =
        isPausingAll ||
        (totalCountToStop ?? 0) === 0 ||
        noKeywordSearchOrTemplateNotSelected;

    return (
        <div className="template-checklists-report__header-filters">
            {canStopMultipleChecklists && stopChecklistIsHidden === false && (
                <div className="stop-checklists-btn">
                    <Button
                        size="small"
                        variant="danger"
                        onClick={handlePauseAll}
                    >
                        <AiOutlineStop />
                        {t(k.STOP_MATCHING_CHECKLISTS)}

                        {totalCountToStop ? (
                            <strong>({totalCountToStop})</strong>
                        ) : undefined}
                    </Button>
                </div>
            )}

            <InputComponent
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.SEARCH)}
                wrapperIcon={<SearchIcon />}
                id="filter.search"
                value={value.keywordSearch ?? ""}
                placeholder={t(k.SEARCH)}
                onChange={handleOnChangeKeywordFilter}
                hideIcon={true}
                size="large"
                autoCompleteOff
                postInputContent={
                    barcodeFeatureEnabled && (
                        <BarcodeScanAppend onChange={handleScanBarcode} />
                    )
                }
                autoFocus={true}
            />

            <InputWrapper
                wrapperLabel={t(k.FILTER_BY_TEMPLATES)}
                wrapperIcon={<FilterIcon />}
            >
                <TemplateMultiselect
                    values={value.templateVersionIds}
                    onChange={handleOnChangeTemplates}
                    onFetch={props.onFetchTemplateList}
                />
            </InputWrapper>

            <ToggleView
                disabled={noKeywordSearchOrTemplateNotSelected}
                split={value.splitSearchResult ?? false}
                onChange={handleChangeSplitSearchResult}
            />
        </div>
    );
};

export default HeaderFilters;
