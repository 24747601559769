import { FC, useContext, useEffect, useState } from "react";
import { usePublishedUserOptions } from "components/users/api/hooks";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import NewImprovementContext from "components/improvements/components/NewImprovementListedContent/newImprovementContext";
import {
    useGetInvolvedUsersForTemplateByTemplateVersionIdMutation,
    useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation,
} from "components/improvements/api/hooks";
import ITemplateVersionConfigurationUsersRequest from "components/improvements/api/ITemplateVersionConfigurationUsersRequest";
import { IValueLabelItem } from "common/IValueLabelItem";
import ITemplateVersionConfigurationUsersDto from "components/improvements/api/ITemplateVersionConfigurationUsersDto";
import { mergeStringArraysAndDistinct } from "components/improvements/api/utils";
import SelectedUsersWithInfo from "common/components/selected-users-with-info/SelectedUsersWithInfo";
import ViewTemplateConfigurationUsersTooltipMessage from "common/components/messages/ViewTemplateConfigurationUsersTooltipMessage";
import SelectUsersDropdown from "common/components/select-users/SelectUsersDropdown";
import { UseMutationResult } from "react-query";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import { useSelector } from "react-redux";
import { IAppState } from "store/IAppState";
import IImprovementInvolvedUserRequest from "http/requests/IImprovementInvolvedUserRequest";

interface IProps {}

const InvolvedUsers: FC<IProps> = (props) => {
    const {
        editedImprovement,
        setEditedImprovement,
        onHaveChanges,
        involvedUsers,
        setInvolvedUsers,
        usersToBeNotified,
        setUsersToBeNotified,
        isLoadingInvolvedUsers,
    } = useContext(NewImprovementContext);
    const impactedProcessesFeature = useSelector(
        (appState: IAppState) =>
            (appState.authViewState.profile?.appFeatures?.processChart &&
                appState.authViewState.profile?.appFeatures
                    ?.assignImpactedProcessesToImprovement) === true,
    );

    const { data: publishedUsers } = usePublishedUserOptions();

    const { t } = useTranslation();

    const involvedUsersMutation =
        useGetInvolvedUsersForTemplateByTemplateVersionIdMutation();

    const notifyUsersMutation =
        useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation();

    const handleChangeInvolvedUsers = (userId: string) => {
        if (!editedImprovement) {
            return;
        }

        //have not made any changed to the involved users yet
        if (Boolean(editedImprovement.isInvolvedUsersChanged) == false) {
            if (involvedUsers && involvedUsers.length > 0) {
                let hasNotifyUsers =
                    usersToBeNotified && usersToBeNotified.length > 0;

                //users added from template configuration with their notification settings
                //here initialized with those settings, however they can be changed
                editedImprovement.involvedUsers = involvedUsers.reduce(
                    (acc, user) => {
                        acc[user.value] = {
                            shouldBeNotified: Boolean(
                                hasNotifyUsers &&
                                    usersToBeNotified?.some(
                                        (x) => x.value === user.value,
                                    ),
                            ),
                        };
                        return acc;
                    },
                    {} as IDictionary<IImprovementInvolvedUserRequest>,
                );
            } else {
                editedImprovement.involvedUsers = {};
            }
        }
        if (editedImprovement.involvedUsers == undefined) {
            editedImprovement.involvedUsers = {};
        }

        if (Object.keys(editedImprovement.involvedUsers).indexOf(userId) > -1) {
            delete editedImprovement.involvedUsers[userId];
            editedImprovement.isInvolvedUsersChanged = true;
        } else {
            //users added with this method are always to be notified
            editedImprovement.involvedUsers[userId] = {
                shouldBeNotified: true,
            };
            editedImprovement.isInvolvedUsersChanged = true;
        }

        setInvolvedUsers(
            publishedUsers?.options?.filter((user) =>
                Object.keys(editedImprovement?.involvedUsers ?? {}).includes(
                    user.value,
                ),
            ),
        );

        setEditedImprovement({ ...editedImprovement });
        onHaveChanges(true);
    };

    return (
        <div>
            <SelectedUsersWithInfo
                users={involvedUsers}
                labelPrefixTranslationKey={k.INVOLVED}
                tooltipMessage={
                    <ViewTemplateConfigurationUsersTooltipMessage
                        allSelectedUsers={involvedUsers}
                        titleTranslationKey={k.INVOLVED_USERS}
                    />
                }
                isLoading={isLoadingInvolvedUsers}
                selectUsers={
                    impactedProcessesFeature && (
                        <SelectUsersDropdown
                            onSelect={handleChangeInvolvedUsers}
                            selectedUserIds={
                                involvedUsers?.map((x) => x.value) ?? []
                            }
                            users={publishedUsers?.options ?? []}
                            color="#fff"
                        />
                    )
                }
            />
        </div>
    );
};

export default InvolvedUsers;
