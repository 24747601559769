import "./CommentButton.scss";

import React from "react";
import { RiChat3Line } from "react-icons/ri";

interface IProps {
    count?: number;

    onClick: () => void;
}

const CommentButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { count = 0 } = props;

    // if ((count ?? 0) === 0) {
    //     return null;
    // }

    const handleClick = (e: any) => {
        e.stopPropagation();

        props.onClick();
    };

    return (
        <div className="comment-button__wrapper no-print" onClick={handleClick}>
            <RiChat3Line />

            {count > 0 && <span>{count}</span>}
        </div>
    );
};

export default CommentButton;
