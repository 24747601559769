import React, { useState } from "react";
import DatePicker from "react-datepicker";

import { FilterDropdown } from "common/components/table/components/filtering/filter-dropdown/FilterDropdown";

export interface ITableColumnDateFilterProps {
    testId?: string;

    date?: Date;

    noWrapper?: boolean;

    onFilter: (value?: Date) => void;

    onShow?: (newState: boolean) => void;
}

const TableCellDateFilterWrapper = (props: {
    noWrapper?: boolean;
    children?: React.ReactNode;
}) => {
    return props.noWrapper ? (
        <React.Fragment>{props.children}</React.Fragment>
    ) : (
        <div className="app-table--cell-filter">{props.children}</div>
    );
};

export const TableColumnDateFilter = (props: ITableColumnDateFilterProps) => {
    const { date, testId, noWrapper } = props;

    const [isOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(date !== undefined);
    const [isEdited, setIsEdited] = useState(false);

    const [filterValue, setFilterValue] = useState<Date | null>(
        date ?? new Date(),
    );

    React.useEffect(() => {
        if (!isOpen) {
            setFilterValue(date ?? null);
        }
    }, [isOpen]);

    const handleReset = () => {
        setFilterValue(null);

        setIsEdited(false);

        setIsActive(false);

        props.onFilter(undefined);
    };

    const tryHandleReset = (value: Date | null) => {
        if (!value) {
            setIsEdited(false);
        }
    };

    const handleFilter = (isReset?: boolean) => {
        if (isReset) {
            handleReset();
        } else {
            const isEmpty = filterValue === null;

            setIsEdited(!isEmpty);

            setIsActive(!isEmpty);

            props.onFilter(filterValue ?? undefined);
        }
    };

    const handleChange = (value: Date | null) => {
        setFilterValue(value);

        setIsEdited(true);

        tryHandleReset(value);
    };

    const handleToggle = (nextOpen: boolean) => {
        setIsOpen(nextOpen);

        props.onShow?.(nextOpen);
    };

    return (
        <TableCellDateFilterWrapper noWrapper={noWrapper}>
            <FilterDropdown
                isEdited={isEdited}
                isActive={isActive}
                testId={testId}
                onFilter={handleFilter}
                onToggle={handleToggle}
            >
                <DatePicker
                    selected={filterValue}
                    onChange={handleChange}
                    inline
                />
            </FilterDropdown>
        </TableCellDateFilterWrapper>
    );
};
