import "./KpiDetailsForm.scss";

import { EActivityType } from "common/components/input-components/EActivityType";
import InputComponent from "common/components/input-components/InputComponent";
import React, { Dispatch, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import { IKpiDetailsDto } from "../../api/IKpiDetailsDto";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import KpiContentWrapper from "../KpiContentWrapper";

interface IProps {
    stepRef: RefObject<HTMLDivElement>;
    dataId: string;
    kpiDetails: IKpiDetailsDto;
    setKpiDetails: Dispatch<SetStateAction<IKpiDetailsDto>>;

    errors?: IValidationMessages;
    onHaveChanges: (haveChanges: boolean) => void;
}

const KpiDetailsForm: React.FC<IProps> = (props) => {
    const { stepRef, dataId, kpiDetails, setKpiDetails, errors } = props;
    const { t } = useTranslation();

    const handleChangeName = (e: IInputChangeEvent<string>) => {
        setKpiDetails((prev) => ({
            ...prev,
            name: e.value,
        }));

        props.onHaveChanges(true);
    };

    const handleChangeDescription = (e: IInputChangeEvent<string>) => {
        setKpiDetails((prev) => ({
            ...prev,
            description: e.value,
        }));

        props.onHaveChanges(true);
    };

    return (
        <KpiContentWrapper
            stepRef={stepRef}
            dataId={dataId}
            title={t(k.DETAILS)}
        >
            <InputComponent
                id="kpiName"
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.NAME)}
                required
                boldLabel
                hideIcon
                value={kpiDetails?.name ?? ""}
                onChange={handleChangeName}
                testId="kpi-details-form--name"
                placeholder={t(k.ENTER_HERE)}
                helperText={
                    !!errors?.["kpiName"] && (
                        <ValidationLabel errors={t(k.KPI_NAME_REQUIRED)} />
                    )
                }
            />
            <InputComponent
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.DESCRIPTION)}
                boldLabel
                hideIcon
                value={kpiDetails?.description ?? ""}
                onChange={handleChangeDescription}
                multiline
                minRows={5}
                testId="kpi-details-form--description"
                placeholder={t(k.ENTER_HERE)}
            />
        </KpiContentWrapper>
    );
};

export default KpiDetailsForm;
