import { IValueLabelItem } from "common/IValueLabelItem";
import { Reducer } from "redux";
import {
    CHANGE_LAYOUT_ENVIRONMENT,
    CHANGE_MENU_IS_EXPANDED,
    UiSettingsActionTypes,
} from "./types";

export enum ELayoutEnvironment {
    BROWSER = 1,
    TEAMS = 2,
}

export const layoutEnvironmentData = {
    [ELayoutEnvironment.BROWSER]: undefined,
    [ELayoutEnvironment.TEAMS]: "t",
};

export interface IUiSettingsState {
    isMenuExpanded: boolean;
    layoutEnvironment: ELayoutEnvironment;
}

const initialState: IUiSettingsState = {
    isMenuExpanded: true,
    layoutEnvironment: ELayoutEnvironment.BROWSER,
};

export const UiSettingsReducer: Reducer<
    IUiSettingsState,
    UiSettingsActionTypes
> = (state = initialState, action): IUiSettingsState => {
    switch (action.type) {
        case CHANGE_MENU_IS_EXPANDED: {
            return {
                ...state,
                isMenuExpanded: action.payload,
            };
        }
        case CHANGE_LAYOUT_ENVIRONMENT: {
            return {
                ...state,
                layoutEnvironment: action.payload,
            };
        }

        default:
            return state;
    }
};
