import { useContext } from "react";

import {
    EModalCallBackTypes,
    IActiveTransactionsPayload,
    IModalListItem,
    ModalTypes,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import MeasurementForm from "components/measurement/MeasurementForm";
import { ETransactionStatus } from "models/enums/TransactionStatus";

interface IProps {
    modalItem: IModalListItem<EModalCallBackTypes.Id>;
}

const MeasurementFormModal = (props: React.PropsWithoutRef<IProps>) => {
    const {
        modalItem: {
            id,
            callId,
            options,
            transactionStatus: activeTransactions,
            hidden,
        },
    } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    const handleOnUpdateModal = (newId: string) => {
        modalManagerContext.onUpdateModal({
            callId,
            newId,
            newType: ModalTypes.measurement_form,
            newOptions: { edit: true },
            onSave: true,
        });
    };

    const handleOnUpdateActiveTransactions = (
        payload: IActiveTransactionsPayload,
    ) => {
        modalManagerContext.onUpdateActiveTransactions({
            ...payload,
            callId,
        });
    };

    return (
        <MeasurementForm
            id={id}
            goalId={options?.parentId}
            createNew={options?.createNew}
            haveChanges={modalManagerContext.state.haveChangesList[callId]}
            syncData={modalManagerContext.state.activeModal === callId}
            filter={options?.goalServerFilter}
            onHaveChanges={handleOnHaveChanges}
            onUpdateModal={handleOnUpdateModal}
            onClose={handleOnClose}
            onSave={props.modalItem.callbacks?.onSave}
            onUpdateActiveTransactions={handleOnUpdateActiveTransactions}
        />
    );
};

export default MeasurementFormModal;
