import { MonthsOrder } from "models/enums/Months";
import { IManualDataSourceDto } from "../../kpi-form/api/IManualDataSourceDto";
import { IManualDataSourceValueDto } from "../../kpi-form/api/IManualDataSourceValueDto";
import { v4 as uuidv4 } from "uuid";

export const newManualDataSourceYear = (param?: {
    nextYear: number;
    values: Record<string, IManualDataSourceValueDto | undefined>;

    valueIds: Record<number, string[] | undefined> | undefined;
}) => {
    const { nextYear, valueIds, values } = param ?? {
        nextYear: new Date().getFullYear(),
    };

    let manualDataSourceValues: Record<string, IManualDataSourceValueDto> = {};

    let manualValueIds = [] as string[];

    const existingManualValues = valueIds?.[nextYear];

    let manualValues: IManualDataSourceValueDto[] | undefined;

    if (existingManualValues) {
        const existingIds = existingManualValues;

        if (existingIds && existingIds.length === MonthsOrder.length) {
            manualValues = [];

            for (const id of existingIds) {
                const item = values?.[id];

                if (item) {
                    manualValues.push(item);
                }
            }

            if (manualValues.length === MonthsOrder.length) {
                manualValueIds = existingIds;
            } else {
                manualValues = undefined;

                manualValueIds = [];
            }
        }
    } else {
        manualValues = [];

        const ids: string[] = [];

        for (const month of MonthsOrder) {
            const item: IManualDataSourceValueDto = {
                id: uuidv4(),
                date: new Date(nextYear, month, 1),
            };

            manualValues.push(item);

            ids.push(item.id);
        }

        manualValueIds = ids;
    }

    if (manualValues) {
        for (const item of manualValues) {
            manualDataSourceValues[item.id] = item;
        }
    }

    return {
        year: nextYear,
        manualDataSourceValues,
        manualValueIds,
    };
};
