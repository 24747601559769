import "./CommentListItem.scss";

import React from "react";

import Avatar from "../avatar/Avatar";
import FormattedDateSinceToday from "../formattedDate/FormattedDateSinceToday";
import IChecklistComment from "models/IChecklistComment";

type Props = IChecklistComment;

const CommentListItem: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { createdBy, message, createdAt } = props;

    return (
        <div className="comments-item">
            <div className="comments-meta">
                <div className="comments-user">
                    <Avatar name={createdBy} className="logo" />
                    {createdBy}
                </div>
                <div className="comments-time">
                    <FormattedDateSinceToday date={createdAt} />
                </div>
            </div>
            <div className="comments-message">{message}</div>
        </div>
    );
};

export default CommentListItem;
