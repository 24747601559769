import React, { useEffect } from "react";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import { TableCell } from "common/components/table";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import {
    useCustomListBasicListOptions,
    useCustomListItemOptions,
} from "../api/hooks";

interface IProps {
    property: ICustomListPropertyTemplateValue;

    sharedOptionType?: ESharedOptionsType;

    onFilter: (value: ICustomListPropertyTemplateValue, ids: string[]) => void;
}

const CustomListItemFilterMenu: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { property, sharedOptionType } = props;

    const [selectedPropertyCustomListId, setSelectedPropertyCustomListId] =
        React.useState<string>();

    const isBasicList =
        property.type === CustomListPropertyEnum.Tasklist ||
        property.type === CustomListPropertyEnum.Selection;

    const basicCustomListResult = useCustomListBasicListOptions(
        isBasicList ? selectedPropertyCustomListId : undefined,
    );

    const notBasicCustomListResult = useCustomListItemOptions(
        isBasicList ? undefined : selectedPropertyCustomListId,
    );

    const menuOptions = isBasicList
        ? basicCustomListResult.data
        : notBasicCustomListResult.data;

    const isLoading = isBasicList
        ? basicCustomListResult.isLoading
        : notBasicCustomListResult.isLoading;

    const { data: sharedOptions, refetch } = useSharedOptions(
        false,
        sharedOptionType,
    );

    useEffect(() => {
        if (sharedOptionType) {
            refetch();
        }
    }, [sharedOptionType]);

    const handleShow = (nextShow: boolean) => {
        setSelectedPropertyCustomListId(
            nextShow ? property.propertyCustomListId : undefined,
        );
    };

    const handleFilter = (newIds: string[]) => {
        props.onFilter(property, newIds);
    };

    return (
        <TableCell.Filtering
            isLoading={isLoading}
            menuOptions={menuOptions ?? sharedOptions}
            onShow={handleShow}
            onFilter={handleFilter}
        />
    );
};

export default CustomListItemFilterMenu;
