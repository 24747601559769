import "./FilterMenuCommands.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { Button, ButtonGroup } from "common/components/buttons";

interface IFilterMenuCommandsProps {
    isActive: boolean;
    isEdited: boolean;
    testId?: string;

    className?: string;

    description?: React.ReactNode;

    onCancel: (e: React.SyntheticEvent) => void;

    onFilter: (e: React.SyntheticEvent, isReset?: boolean) => void;
}

export const FilterMenuCommands = (props: IFilterMenuCommandsProps) => {
    const { isActive, isEdited, testId, className, description } = props;

    const disabled = !isEdited;

    const { t } = useTranslation();

    const handleReset = (e: React.SyntheticEvent) => {
        props.onFilter(e, true);
    };

    const handleFilter = (e: React.SyntheticEvent) => {
        props.onFilter(e);
    };

    return (
        <div
            className={
                className
                    ? `filter-menu-commands ${className}`
                    : "filter-menu-commands"
            }
        >
            {description}

            <ButtonGroup>
                <Button
                    variant="gray-link"
                    size="small"
                    testId={testId ? `${testId}-CancelBtn` : undefined}
                    onClick={props.onCancel}
                >
                    {t(k.CANCEL)}
                </Button>

                <div>
                    <Button
                        disabled={disabled && !isActive}
                        variant="danger"
                        transparent
                        size="small"
                        testId={testId ? `${testId}-ResetBtn` : undefined}
                        onClick={handleReset}
                    >
                        {t(k.RESET)}
                    </Button>

                    <Button
                        variant="blue"
                        size="small"
                        disabled={disabled}
                        testId={testId ? `${testId}-ApplyBtn` : undefined}
                        onClick={handleFilter}
                    >
                        {t(k.APPLY)}
                    </Button>
                </div>
            </ButtonGroup>
        </div>
    );
};
