import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { useDispatch } from "react-redux";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

import {
    ModalTypes,
    ICloseModalPayload,
    IModalHaveChangesPayload,
    IModalListItem,
    IOnChangeRecurrencePayload,
    IShowModalPayload,
    TModalTypes,
    IUpdateModalPayload,
    IActiveTransactionsPayload,
    KeyForModalType,
} from "./api/IModalManager";

import ModalRenderer from "./components/ModalRenderer";

import { useNavigate, useLocation } from "react-router-dom";
import ModalManagerChangeNavBlocker from "./components/ModalManagerChangeNavBlocker";
import {
    getModalParams,
    makeModalParams,
} from "./utility/ModalManagerSearchParams";

import checkIfDuplicate from "./utility/CheckIfDuplicate";
import { makeCallId } from "./utility/ModalCallId";
import { useCustomListOptions } from "components/custom-list-page/api/hooks";
import IDictionary from "common/viewModels/IDictionary";
import { ETransactionStatus } from "models/enums/TransactionStatus";
import { toast } from "react-toastify";
import { Button, ButtonGroup } from "../buttons";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { getActiveModal } from "./utility/GetActiveModal";
import { ModalManagerContext, initialState } from "./ModalManagerContext";

const ModalManager: React.FC<React.PropsWithChildren> = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState(initialState);
    const {
        modalList,
        order,
        activeModal,
        haveChangesList,
        initialCaller,
        modalTypes,
        navigateAfterClose,
    } = state;

    const { data: customLists, isFetched: customListFetched } =
        useCustomListOptions();
    const customListsValues = customLists?.values.values;

    useEffect(() => {
        if (customListsValues) {
            const types = customLists.values.ids.reduce((prev, cur) => {
                const path = customListsValues[cur]?.path;
                if (path) {
                    return { ...prev, [path]: ModalTypes.custom_list };
                }

                return prev;
            }, {} as IDictionary<TModalTypes>);

            setState((prev) => ({
                ...prev,
                modalTypes: { ...prev.modalTypes, ...types },
            }));
        }
    }, [customListsValues]);

    useEffect(() => {
        if (initialCaller) {
            const { type, id, options, modalFor } = getModalParams(
                location.search,
            );

            if (order.length > 0 && modalList) {
                if (activeModal) {
                    const modal = modalList[activeModal];

                    if (
                        modal &&
                        (type === undefined ||
                            modal.type !== type ||
                            modal.id !== id ||
                            modal.modalFor !== modalFor ||
                            JSON.stringify(modal.options) !==
                                JSON.stringify(options))
                    ) {
                        const address = initialCaller + makeModalParams(modal);

                        doNavigate(address);
                    }
                } else {
                    doNavigate(navigateAfterClose ?? initialCaller);
                }
            } else {
                doNavigate(navigateAfterClose ?? initialCaller);

                resetInitialState();
            }
        }
    }, [modalList, navigateAfterClose]);

    const doNavigate = (address: string) => {
        if (location.pathname + location.search !== address) {
            navigate(address);
        }
    };

    useEffect(() => {
        if (customListFetched) {
            const { type, id, options, modalFor } = getModalParams(
                location.search,
            );

            const visibleOrder = order.filter((x) => !modalList[x]?.hidden);

            if (visibleOrder.length > 0) {
                if (type) {
                    const index = visibleOrder.indexOf(
                        makeCallId({ type, id, modalFor } as IShowModalPayload),
                    );

                    if (index !== -1 && index !== visibleOrder.length - 1) {
                        const [first, ...rest] = visibleOrder.slice(index + 1);

                        const payload: ICloseModalPayload = {
                            callId: first,
                            extraById: rest,
                        };

                        onCloseModal(payload);
                    } else if (index === -1) {
                        let payload: IShowModalPayload = {
                            id,
                            modalFor,
                            callerAddress: location.pathname,
                            type: modalTypes[type],
                            options,
                        };

                        if (
                            modalTypes[type] === ModalTypes.custom_list &&
                            customListsValues
                        ) {
                            const id = Object.values(customListsValues).find(
                                (x) => x?.path === type,
                            )?.id;

                            if (id) {
                                payload = {
                                    ...payload,
                                    customList: { id, path: type },
                                };
                            }
                        }

                        if (
                            payload.type &&
                            isOnRightPageElseNavigate(payload)
                        ) {
                            onShowModal(payload);
                        }
                    }
                } else if (location.pathname === initialCaller) {
                    const [first, ...rest] = visibleOrder;

                    const modal = modalList[first];

                    if (modal && !modal.hidden) {
                        const payload: ICloseModalPayload = {
                            callId: first,
                            extraById: rest,
                        };

                        onCloseModal(payload);
                    }
                }
            } else if (type) {
                let payload: IShowModalPayload = {
                    id,
                    modalFor,
                    callerAddress: location.pathname,
                    type: modalTypes[type],
                    options,
                };

                if (
                    modalTypes[type] === ModalTypes.custom_list &&
                    customListsValues
                ) {
                    const item = Object.values(customListsValues).find(
                        (x) => x?.path === type,
                    );

                    if (item && item.id) {
                        payload = {
                            ...payload,
                            customList: { id: item.id, path: type },
                        };
                    }
                }

                if (payload.type && isOnRightPageElseNavigate(payload)) {
                    onShowModal(payload);
                }
            }
        }

        if (state.ignoreNextNavigation) {
            setState((prev) => ({ ...prev, ignoreNextNavigation: false }));
        }
    }, [location, modalTypes]);

    useEffect(() => {
        const length = Object.values(modalList).length;

        if (length) {
            const allModalsHidden = Object.values(modalList).every(
                (x) => x?.hidden,
            );

            setState((prev) => ({ ...prev, haveOpenModals: !allModalsHidden }));
        } else {
            setState((prev) => ({ ...prev, haveOpenModals: false }));
        }
    }, [modalList]);

    const resetInitialState = () => {
        setState((prev) => ({
            ...prev,
            modalList: {},
            initialCaller: undefined,
            order: [],
            activeModal: undefined,
            haveChangesList: {},
            haveOpenModals: false,
            navigateAfterClose: undefined,
        }));
    };

    const isOnRightPageElseNavigate = (payload: IShowModalPayload) => {
        const { type, options } = payload;
        const parentId = options?.parentId;
        switch (type) {
            case ModalTypes.process_step:
                if (parentId) {
                    if (
                        location.pathname.startsWith("/subprocess/" + parentId)
                    ) {
                        return true;
                    } else {
                        const address =
                            "/subprocess/" + parentId + location.search;
                        navigate(address);
                        return false;
                    }
                } else {
                    return false;
                }
        }
        return true;
    };

    const onCloseModal = (payload: ICloseModalPayload) => {
        setState((prev) => {
            const ids = [payload.callId, ...(payload.extraById ?? [])];

            const newOrder = prev.order.filter((x) => !ids.includes(x));

            const { modals, changes } = ids.reduce(
                (map, cur) => {
                    const { [cur]: v1, ...modals } = map.modals;
                    const { [cur]: v2, ...changes } = map.changes;
                    return { modals, changes };
                },
                {
                    modals: prev.modalList,
                    changes: prev.haveChangesList,
                },
            );

            return {
                ...prev,
                modalList: modals,
                order: newOrder,
                activeModal: getActiveModal(prev.modalList, newOrder)?.callId,
                haveChangesList: changes,
                navigateAfterClose: payload.navigateTo,
                ignoreNextNavigation: true,
            };
        });
    };

    const onCloseModalWithActiveTransaction = (payload: ICloseModalPayload) => {
        setState((prev) => {
            const callId = payload.callId;

            const haveActiveTransactions =
                prev.modalList[callId]?.transactionStatus ===
                ETransactionStatus.Pending;

            const modal = prev.modalList[callId];

            const allModalsHidden = Object.values(prev.modalList).every(
                (x) => x?.hidden,
            );

            if (haveActiveTransactions && modal) {
                const newActiveModalOrder = prev.order.filter(
                    (x) => x !== callId,
                );

                return {
                    ...prev,
                    modalList: {
                        ...prev.modalList,
                        [callId]: {
                            ...modal,
                            hidden: true,
                        },
                    },
                    activeModal: getActiveModal(
                        prev.modalList,
                        newActiveModalOrder,
                    )?.callId,
                    ignoreNextNavigation: true,
                    haveOpenModals: !allModalsHidden,
                };
            } else {
                const ids = [callId, ...(payload.extraById ?? [])];

                const newOrder = prev.order.filter((x) => !ids.includes(x));

                const { modals, changes } = ids.reduce(
                    (map, cur) => {
                        const { [cur]: v1, ...modals } = map.modals;
                        const { [cur]: v2, ...changes } = map.changes;
                        return { modals, changes };
                    },
                    {
                        modals: prev.modalList,
                        changes: prev.haveChangesList,
                    },
                );

                return {
                    ...prev,
                    modalList: modals,
                    order: newOrder,
                    activeModal: getActiveModal(prev.modalList, newOrder)
                        ?.callId,
                    haveChangesList: changes,
                    navigateAfterClose: payload.navigateTo,
                    ignoreNextNavigation: true,
                    haveOpenModals: !allModalsHidden,
                };
            }
        });
    };

    const handleOnCloseModal = async (payload: ICloseModalPayload) => {
        if (payload.onSave) {
            onCloseModalWithActiveTransaction(payload);
        } else if (haveChangesList && haveChangesList[payload.callId]) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            if (await showConfirmNoThunk(dispatch, args)) {
                onCloseModalWithActiveTransaction(payload);
            }
        } else {
            onCloseModalWithActiveTransaction(payload);
        }
    };

    const handleOnBackgroundClick = () => {
        if (activeModal) {
            handleOnCloseModal({ callId: activeModal });
        }
    };

    const handleOnHaveChanges = (payload: IModalHaveChangesPayload) => {
        if (
            haveChangesList &&
            haveChangesList[payload.callId] !== payload.haveChanges
        ) {
            onModalHaveChanges(payload);
        }
    };

    const onShowModal = (payload: IShowModalPayload) => {
        const callId = makeCallId(payload);

        const newModal: IModalListItem = {
            ...payload,
            callId,
        };

        setState(
            (prev) =>
                checkIfDuplicate(prev, callId) ?? {
                    ...prev,
                    modalList: {
                        ...prev.modalList,
                        [callId]: newModal,
                    },
                    order: prev.order ? prev.order.concat(callId) : [callId],
                    activeModal: callId,
                    haveChangesList: {
                        ...prev.haveChangesList,
                        [callId]: false,
                    },
                    initialCaller:
                        prev.initialCaller === undefined
                            ? payload.callerAddress
                            : prev.initialCaller,
                    haveOpenModals: true,
                    ignoreNextNavigation: true,
                },
        );
    };

    const onModalHaveChanges = (payload: IModalHaveChangesPayload) => {
        setState((prev) => ({
            ...prev,
            haveChangesList: {
                ...prev.haveChangesList,
                [payload.callId]: payload.haveChanges,
            },
        }));
    };

    const handleOnUpdateModal = async (payload: IUpdateModalPayload) => {
        if (payload.callId) {
            if (state.haveChangesList[payload.callId] && !payload.onSave) {
                const args: IShowConfirmArgs = {
                    message: t(k.THERE_ARE_UNSAVED_CHANGES),
                };

                if (await showConfirmNoThunk(dispatch, args)) {
                    onUpdateModal(payload);
                }
            } else {
                onUpdateModal(payload);
            }
        }
    };

    const onUpdateModal = (payload: IUpdateModalPayload) => {
        if (payload.callId) {
            const { callId, newId, newType, newOptions } = payload;

            setState((prev) => {
                const { [callId]: value, ...modals } = prev.modalList;

                const newCallId = `${newType}_${newId}`;

                const newModal: IModalListItem = {
                    type: newType,
                    callId: newCallId,
                    id: newId,
                    options: newOptions,
                };

                return {
                    ...prev,
                    modalList: { ...modals, [newCallId]: newModal },
                    order: prev.order.map((x) =>
                        x === callId ? newCallId : x,
                    ),
                    activeModal: newCallId,
                    ignoreNextNavigation: true,
                };
            });
        }
    };

    const onChangeRecurrence = (payload: IOnChangeRecurrencePayload) => {
        setState((prev) => {
            const { [payload.callId]: value, ...modals } = prev.modalList;

            const newCallId = `${ModalTypes.recurrence}_${payload.newId}`;

            const newModal: IModalListItem = {
                type: ModalTypes.recurrence,
                callId: newCallId,
                id: payload.newId,
            };

            return {
                ...prev,
                modalList: { ...modals, [newCallId]: newModal },
                order: prev.order.map((x) =>
                    x === payload.callId ? newCallId : x,
                ),
                activeModal: newCallId,
            };
        });
    };

    const handleOnChangeRecurrence = async (
        payload: IOnChangeRecurrencePayload,
    ) => {
        if (haveChangesList && haveChangesList[payload.callId]) {
            const args: IShowConfirmArgs = {
                message: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            if (await showConfirmNoThunk(dispatch, args)) {
                onModalHaveChanges({
                    callId: payload.callId,
                    haveChanges: false,
                });

                onChangeRecurrence(payload);
            }
        } else {
            onChangeRecurrence(payload);
        }
    };

    const handleOnUpdateHidden = (callId: string, hidden = false) => {
        setState((prev) => {
            const modal = prev.modalList[callId];

            if (modal) {
                if (prev.order[prev.order.length - 1] !== callId) {
                    const index = prev.order.indexOf(callId);
                    if (index > -1) {
                        prev.order.splice(index, 1);
                    }
                    prev.order.push(callId);
                }

                return {
                    ...prev,
                    modalList: {
                        ...prev.modalList,
                        [callId]: {
                            ...modal,
                            hidden,
                        },
                    },
                    activeModal: hidden ? prev.activeModal : callId,
                };
            }
            return prev;
        });
    };

    const handleOnUpdateActiveTransactions = (
        payload: IActiveTransactionsPayload,
    ) => {
        const callId = payload.callId;
        if (callId) {
            const modal = modalList[callId];

            if (modal) {
                switch (payload.transactionStatus) {
                    case ETransactionStatus.Idle:
                        break;

                    case ETransactionStatus.Pending:
                    case ETransactionStatus.Error:
                        setState((prev) => ({
                            ...prev,
                            modalList: {
                                ...prev.modalList,
                                [callId]: {
                                    ...modal,
                                    transactionStatus:
                                        payload.transactionStatus,
                                },
                            },
                        }));

                        if (
                            payload.transactionStatus ===
                                ETransactionStatus.Error &&
                            modal.hidden
                        ) {
                            toast.error(
                                <>
                                    <div className="modal-manager--transaction-error-toast--title">
                                        {t(k.AN_ERROR_OCCURED) +
                                            " " +
                                            getLocaleString(
                                                t,
                                                KeyForModalType[
                                                    modal.type as TModalTypes
                                                ],
                                            ) +
                                            ": "}
                                    </div>
                                    {payload.error?.modalTitle}
                                    <ButtonGroup spaceBetween>
                                        <Button
                                            size="small"
                                            transparent
                                            onClick={() => {
                                                onCloseModal({
                                                    callId,
                                                });
                                                toast.dismiss(callId);
                                            }}
                                        >
                                            {t(k.DISMISS)}
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                handleOnUpdateHidden(callId);
                                                toast.dismiss(callId);
                                            }}
                                        >
                                            {t(k.OPEN)}
                                        </Button>
                                    </ButtonGroup>
                                </>,
                                {
                                    toastId: payload.callId,
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: false,
                                    closeButton: false,
                                    closeOnClick: false,
                                    bodyClassName:
                                        "modal-manager--transaction-error-toast--body",
                                    className:
                                        "modal-manager--transaction-error-toast",
                                },
                            );
                        }

                        break;

                    case ETransactionStatus.Success:
                        setState((prev) => {
                            if (modal.hidden) {
                                const newOrder = prev.order.filter(
                                    (x) => x !== callId,
                                );

                                const { [callId]: v1, ...modals } =
                                    prev.modalList;
                                const { [callId]: v2, ...changes } =
                                    prev.haveChangesList;

                                return {
                                    ...prev,
                                    order: newOrder,
                                    modalList: modals,
                                    haveChangesList: changes,
                                    activeModal: getActiveModal(
                                        prev.modalList,
                                        newOrder,
                                    )?.callId,
                                };
                            } else {
                                return {
                                    ...prev,
                                    modalList: {
                                        ...prev.modalList,
                                        [callId]: {
                                            ...modal,
                                            transactionStatus: undefined,
                                        },
                                    },
                                };
                            }
                        });

                        break;
                }
            }
        }
    };

    return (
        <ModalManagerContext.Provider
            value={{
                state,
                onShowModal,
                onCloseModal: handleOnCloseModal,
                onCloseModalNoCheck: onCloseModal,
                onModalHaveChanges: handleOnHaveChanges,
                onUpdateModal: handleOnUpdateModal,
                onChangeRecurrence: handleOnChangeRecurrence,
                onUpdateActiveTransactions: handleOnUpdateActiveTransactions,
                resetInitialState,
            }}
        >
            <ModalManagerChangeNavBlocker
                haveChangesList={haveChangesList}
                ignoreNextNavigation={state.ignoreNextNavigation}
            />
            <ModalRenderer
                onBackgroundClick={handleOnBackgroundClick}
                modalList={modalList}
                order={order}
                haveOpenModals={state.haveOpenModals}
            />
            {props.children}
        </ModalManagerContext.Provider>
    );
};

export default ModalManager;
