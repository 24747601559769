import { FC, useContext } from "react";
import {
    ModalTypes,
    IShowModalPayload,
} from "common/components/modal-manager/api/IModalManager";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import ImprovementsDetailsContext from "../../../../../improvementContext";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import "./ReportedFrom.scss";
import Button from "common/components/buttons/Button";
import { useLocation } from "react-router-dom";

interface IProps {}

const ReportedFrom: FC<IProps> = () => {
    const { improvement } = useContext(ImprovementsDetailsContext);

    const { onShowModal } = useContext(ModalManagerContext);

    const location = useLocation();

    const { t } = useTranslation();

    const handlePreviewChecklist = () => {
        if (improvement && improvement.reportedFrom?.value) {
            const payload: IShowModalPayload = {
                id: improvement.reportedFrom?.value,
                type: ModalTypes.checklist,
                callerAddress: location.pathname,
            };
            onShowModal(payload);
        }
    };
    return improvement?.reportedFrom ? (
        <div className="reported-from">
            <span className="reported-from__label">{t(k.REPORTED_FROM)}</span>
            <div>
                <Button
                    onClick={handlePreviewChecklist}
                    variant="link"
                    className="reported-from__link"
                >
                    {improvement.reportedFrom?.label}
                </Button>
            </div>
        </div>
    ) : null;
};

export default ReportedFrom;
