import "./PermissionSettings.scss";

import { SetStateAction, useMemo } from "react";
import { FiPlus } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";
import IDictionary from "common/viewModels/IDictionary";
import Tooltip from "../../tooltip/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { SelectPermissionRow } from "./SelectPermissionRow";
import { SelectedPermission } from "./SelectedPermission";
import { ISelectedPermissionAssignee } from "../api/ISelectedPermissionAssignee";
import TooltipWrapper from "common/components/tooltip/TooltipWrapper";

interface IProps {
    testId: string;
    roleId: string;
    title: string;
    info?: React.ReactNode;
    disabled?: boolean;
    disabledInfo?: string;

    selected: ISelectedPermissionAssignee;
    setSelected: (
        value: SetStateAction<IDictionary<ISelectedPermissionAssignee>>,
    ) => void;

    newSelected?: ITableSetWithValueLabelOptions<
        string | null,
        "user" | "team"
    >;
    changeNewSelected: (
        newItem: IValueLabelItem<string, string | null, "user" | "team">,
        roleId: string,
    ) => void;

    teamOptions?: IValueLabelItem<string, string>[];
    userOptions?: IValueLabelItem<string, string>[];
}

const PermissionSettings = (props: IProps) => {
    const {
        testId,
        roleId,
        title,
        info,
        selected,
        newSelected,
        userOptions,
        teamOptions,
        disabled,
        disabledInfo,
    } = props;

    const { t } = useTranslation();

    const hasPageChanges = useHasPageChanges();

    const handleOnChange = (
        item: IValueLabelItem<string, string | null, "user" | "team">,
    ) => {
        props.changeNewSelected(item, roleId);
    };

    const handleAddNew = () => {
        const newId = uuidv4();

        const newItem = {
            value: newId,
            label: null,
            param: "user" as "user" | "team",
        };

        props.changeNewSelected(newItem, roleId);

        hasPageChanges(true);
    };

    const handleOnRemove = (id: string) => {
        props.changeNewSelected({ value: id, label: null }, roleId);
    };

    const handleOnRemoveSelected = (removeId: string, remove: boolean) => {
        props.setSelected((prev) =>
            prev
                ? {
                      ...prev,
                      [roleId]: {
                          ...prev[roleId],
                          itemList: prev[roleId].itemList.map((x) =>
                              x.value === removeId
                                  ? { ...x, markedRemove: remove }
                                  : x,
                          ),
                      },
                  }
                : prev,
        );

        hasPageChanges(true);
    };

    const handleOnChangeEveryone = () => {
        props.setSelected((prev) =>
            prev
                ? {
                      ...prev,
                      [roleId]: {
                          ...prev[roleId],
                          isEveryone: !prev[roleId].isEveryone,
                      },
                  }
                : prev,
        );

        hasPageChanges(true);
    };

    const alreadySelectedUsers = useMemo(() => {
        let result: string[] = [];

        if (selected.itemList) {
            const selectedUsers = selected.itemList.filter(
                (x) => x.param === "user",
            );

            if (selectedUsers.length > 0) {
                result = result.concat(selectedUsers.map((x) => x.value));
            }
        }

        if (newSelected && newSelected.ids && newSelected.ids.length > 0) {
            const newSelectedUsers = newSelected.options.filter(
                (x) => x.param === "user" && x.label !== null,
            ) as IValueLabelItem<string, string, "user">[];

            if (newSelectedUsers.length > 0) {
                result = result.concat(newSelectedUsers.map((x) => x.label));
            }
        }

        if (
            selected.isEveryone !== null ||
            (newSelected &&
                newSelected.options.some((x) => x.label === "isEveryone"))
        ) {
            result = result.concat("isEveryone");
        }

        return result;
    }, [selected, newSelected]);

    const alreadySelectedTeams = useMemo(() => {
        let result: string[] = [];

        if (selected.itemList) {
            const selectedTeams = selected.itemList.filter(
                (x) => x.param === "team",
            );

            if (selectedTeams.length > 0) {
                result = result.concat(selectedTeams.map((x) => x.value));
            }
        }

        if (newSelected && newSelected.ids && newSelected.ids.length > 0) {
            const newSelectedTeams = newSelected.options.filter(
                (x) => x.param === "team" && x.label !== null,
            ) as IValueLabelItem<string, string, "team">[];

            if (newSelectedTeams.length > 0) {
                result = result.concat(newSelectedTeams.map((x) => x.label));
            }
        }

        if (
            selected.isEveryone !== null ||
            (newSelected &&
                newSelected.options.some((x) => x.label === "isEveryone"))
        ) {
            result = result.concat("isEveryone");
        }

        return result;
    }, [selected, newSelected]);

    return (
        <div className="permission-settings" data-testid={testId}>
            <div className="p-s--title">
                {title}
                {info ? (
                    <div className="p-s--title--info">
                        <Tooltip
                            id="role-info"
                            message={info}
                            tooltipClassName="permission-settings-role-info-tooltip"
                            placement="right"
                        >
                            <AiOutlineInfoCircle />
                        </Tooltip>
                    </div>
                ) : undefined}
            </div>

            <div className="p-s--selected">
                {selected.isEveryone !== null && (
                    <SelectedPermission
                        testId={`${testId}-is-everyone`}
                        id="isEveryone"
                        label={t(k.EVERYONE)}
                        type={t(k.ALL_USERS_IN_SYSTEM)}
                        markedRemove={selected.isEveryone === false}
                        onRemove={handleOnChangeEveryone}
                    />
                )}
                {selected.itemList.map((x) => (
                    <SelectedPermission
                        key={x.value}
                        testId={testId}
                        id={x.value}
                        label={x.label}
                        type={x.param === "team" ? t(k.TEAM) : t(k.USER)}
                        markedRemove={x.markedRemove}
                        onRemove={handleOnRemoveSelected}
                    />
                ))}
            </div>

            {newSelected && newSelected.ids.length > 0 && (
                <div className="p-s--select-new">
                    {newSelected.ids.map((x) => {
                        const item = newSelected.values[x];

                        if (item) {
                            return (
                                <SelectPermissionRow
                                    key={x}
                                    testId={testId}
                                    item={{ value: x, ...item }}
                                    existingItems={
                                        item.param === "team"
                                            ? alreadySelectedTeams
                                            : alreadySelectedUsers
                                    }
                                    pickList={(selected.isEveryone === null
                                        ? [
                                              {
                                                  value: "isEveryone",
                                                  label: t(k.EVERYONE),
                                              },
                                          ]
                                        : []
                                    ).concat(
                                        (item.param === "team"
                                            ? teamOptions
                                            : userOptions) ?? [],
                                    )}
                                    onChange={handleOnChange}
                                    onRemove={handleOnRemove}
                                />
                            );
                        }
                    })}
                </div>
            )}

            <div className="p-s--add-new">
                <div className="p-s--add-new--assignee">
                    <span className="p-s--add-new--total">{t(k.TOTAL)}</span>
                    <span data-testid={`${testId}-total`}>
                        {(selected.itemList.length ?? 0) +
                            (newSelected ? newSelected.ids.length : 0)}
                    </span>
                    <span>{t(k.ASSIGNEE)}</span>
                </div>
                <TooltipWrapper
                    id={testId}
                    message={disabledInfo}
                    showTooltip={disabled}
                >
                    <Button
                        transparent
                        disabled={disabled}
                        size="small"
                        onClick={handleAddNew}
                        icon={<FiPlus />}
                        testId={`${testId}-add-btn`}
                    >
                        {t(k.ADD_NEW)}
                    </Button>
                </TooltipWrapper>
            </div>
        </div>
    );
};

export default PermissionSettings;
