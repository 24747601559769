import React from "react";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";
import { IActivityProps } from "./IActivityProps";
import CompetencyIcon from "common/components/icons/icon-competency/CompetencyIcon";
import ActivityDropdownField from "./ActivityDropDownField";

const ActivityCompetencyDropdown: React.FC<IActivityProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActivityDropdownField
            {...props}
            icon={<CompetencyIcon />}
            label={t(k.PLACEHOLDER_FOR_INPUT_FIELD)}
            placeholder={t(k.ENTER_HERE)}
            typeLabel={t(k.COMPETENCIES)}
            hasMultiSelectOptions
        />
    );
};

export default ActivityCompetencyDropdown;
