import "./ReportTableWrapper.scss";

import React from "react";

import AccordionLeft from "common/components/accordion/AccordionLeft";

interface IProps {
    templateName?: string;
}

const ReportTableWrapper: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { templateName } = props;

    return (
        <div className="report-table__wrapper">
            <AccordionLeft
                open={true}
                header={
                    <React.Fragment>
                        Checklist Template: <strong>{templateName}</strong>
                    </React.Fragment>
                }
            >
                {props.children}
            </AccordionLeft>
        </div>
    );
};

export default ReportTableWrapper;
