import React, { useContext } from "react";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { HistoryIcon } from "common/components/icons";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import "./HistoryButton.scss";

interface IButtonProps {
    kpiId?: string;
}

const HistoryButton: React.FC<React.PropsWithChildren<IButtonProps>> = (
    props,
) => {
    const { kpiId } = props;
    const modalManagerContext = useContext(ModalManagerContext);

    const handleShowHistory = () => {
        if (kpiId) {
            const payload: IShowModalPayload = {
                id: kpiId,
                type: ModalTypes.history,
                callerAddress: location.pathname,
                modalFor: {
                    id: kpiId,
                    type: ModalTypes.kpi_details,
                },
                options: {
                    historyOwnerType: HistoryOwnerEnum.Kpi,
                    showAll: true,
                },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    return (
        <IconButton
            onClick={handleShowHistory}
            background="dark"
            testId="historyBtn"
            className="history"
        >
            <HistoryIcon size={24} />
        </IconButton>
    );
};

export default HistoryButton;
