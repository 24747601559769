import "./FilterToggle.scss";

import React, { PropsWithRef } from "react";
import { useDropdownToggle } from "react-overlays";

import FilterSvg from "assets/filter.svg";

interface IFilterToggleProps {
    testId?: string;
    isActive: boolean;
}

export const FilterToggle = (props: PropsWithRef<IFilterToggleProps>) => {
    const [toggleProps, { show, toggle }] = useDropdownToggle();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        toggle(!show, e);
    };

    return (
        <div
            {...toggleProps}
            className="filter-toggle"
            data-testid={props.testId ? `${props.testId}Toggle` : undefined}
            onClick={handleClick}
        >
            <FilterSvg className={props.isActive ? "active" : undefined} />
        </div>
    );
};
