import IDictionary from "common/viewModels/IDictionary";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import * as yup from "yup";
import { ObjectShape } from "yup";

export const deviationMessageSchema = yup
    .string()
    .min(1, "REQUIRED")
    .required("REQUIRED");

export const impactListSchema = (isEmptyImpactList?: boolean) =>
    yup
        .mixed<IDictionary<ImpactGradingEnum>>()
        .nullable()
        .test("impactList-validation", "REQUIRED", (value, ctx) => {
            if (isEmptyImpactList) {
                return true;
            }
            if (!value || typeof value !== "object") {
                return ctx.createError({ message: "REQUIRED" });
            }

            const values = Object.values(value);

            const isInvalid =
                values.length === 0 ||
                values.includes(Number(ImpactGradingEnum.None));

            if (isInvalid) {
                return ctx.createError({ message: "REQUIRED" });
            }

            return true;
        });

export const impactSchema = yup
    .number()
    .min(1, "REQUIRED")
    .required("REQUIRED");

const impactedProcessValueSchema = yup
    .object({
        isNotSure: yup.boolean().required(),
        processId: yup.string().nullable(),
        subProcessId: yup.string().nullable(),
        processStepId: yup.string().nullable(),
        isDeleted: yup.boolean().nullable(),
        title: yup.string().nullable(),
    })
    .test(
        "id-or-isNotSure",
        "Must either be not sure or have at least one ID",
        (value) =>
            value.isNotSure ||
            !!value.processId ||
            !!value.subProcessId ||
            !!value.processStepId,
    )
    .required();

export const impactedProcessesDtoSchema = yup
    .object({
        id: yup.string().required(),
        improvementId: yup.string().required(),
        originated: impactedProcessValueSchema,
        discovered: impactedProcessValueSchema,
    })
    .required("REQUIRED");

export interface IImprovementSchema {
    impactGradingsFeature?: boolean;
    impactedProcessesFeature?: boolean;
    isEmptyImpactList?: boolean;
}

export const improvementSchema = ({
    impactGradingsFeature,
    impactedProcessesFeature,
    isEmptyImpactList,
}: IImprovementSchema) => {
    const schema: ObjectShape = {
        deviationMessage: deviationMessageSchema,
        impact: impactSchema,
    };

    if (impactGradingsFeature) {
        schema["impactList"] = impactListSchema(isEmptyImpactList);
    }

    if (impactedProcessesFeature) {
        schema["impactedProcesses"] = impactedProcessesDtoSchema;
    }

    return schema;
};

export const improvementSchemas: Record<
    | "deviationMessage"
    | "impact"
    | "impactList"
    | "impactedProcesses"
    | "impactedProcesses.discovered"
    | "impactedProcesses.originated",
    yup.Schema<any>
> = {
    deviationMessage: deviationMessageSchema,
    impact: impactSchema,
    impactList: impactListSchema(),
    impactedProcesses: impactedProcessesDtoSchema,
    "impactedProcesses.discovered": impactedProcessValueSchema,
    "impactedProcesses.originated": impactedProcessValueSchema,
};
