import ModalContent from "common/components/modal-content/ModalContent";
import { PropsWithRef, useContext } from "react";
import {
    IModalListItem,
    IUpdateModalPayload,
    ModalTypes,
} from "../../api/IModalManager";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { makeCallId } from "../../utility/ModalCallId";
import ImprovementForm from "components/improvement-forms/components/ImprovementForm/ImprovementForm";

interface IProps {
    modalItem: IModalListItem;
}

const ImprovementFormModal = (props: PropsWithRef<IProps>) => {
    const { callId, id, options } = props.modalItem;
    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean, closePreview?: boolean) => {
        modalManagerContext.onCloseModal({
            callId,
            onSave,
            extraById: closePreview
                ? [
                      makeCallId({
                          type: ModalTypes.template,
                          id,
                          callerAddress: "",
                      }),
                  ]
                : undefined,
        });
    };

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    const handleOnUpdate = (payload: IUpdateModalPayload) => {
        modalManagerContext.onUpdateModal({
            callId,
            ...payload,
        });
    };

    return (
        <ModalContent>
            <ImprovementForm
                id={id}
                haveChanges={modalManagerContext.state.haveChangesList[callId]}
                createNew={options?.createNew}
                syncData={modalManagerContext.state.activeModal === callId}
                onHaveChanges={handleOnHaveChanges}
                onShowModal={modalManagerContext.onShowModal}
                onClose={handleOnClose}
                onUpdateModal={handleOnUpdate}
            />
        </ModalContent>
    );
};

export default ImprovementFormModal;
