import "./Positions.scss";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { IUserPositionItem } from "components/positions/api/IUserPositionItem";
import PositionsItem from "./PositionsItem";
import { GoDotFill } from "react-icons/go";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IPositionOption } from "components/positions/api/IPositionOption";
import PositionModal from "components/common/modals/PositionModal";
import { SaveHandler } from "components/users/components/UserForm";
import { usePositionOptions } from "components/positions/api/hooks";
import { LocaleId } from "AppLocale";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "../input-components/dropdown/api/hooks";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    userPositions?: IUserPositionItem[];
    canEdit?: boolean;
    errors?: IValidationMessages;
    onSave?: (item: IUserPositionItem) => void;
    onDelete?: (id: string, undo?: boolean) => void;
    onChangeEditMode?: (id: string, newMode: boolean) => void;
    onHaveChanges?: (haveChanges: boolean) => void;

    registerChildSaveHandler?: (
        itemId: string,
        saveHandler: SaveHandler,
    ) => void;
    unregisterChildSaveHandler?: (itemId: string) => void;
}

const PositionsList = (props: React.PropsWithChildren<IProps>) => {
    const { userPositions, canEdit, errors } = props;

    const { t } = useTranslation();

    const positionIds = userPositions?.reduce<string[]>((prev, cur) => {
        if (cur.positionId) {
            prev.push(cur.positionId);
        }
        return prev;
    }, []);

    const { data: positionOptions } = useSharedOptions(
        true,
        ESharedOptionsType.position,
        positionIds,
    );

    const [showPositionModal, setShowPositionModal] = useState<
        IValueLabelItemWithState<string> | undefined
    >();

    const handleOnShowPositionModal = (
        position: IValueLabelItemWithState<string>,
    ) => {
        setShowPositionModal(position);
    };

    const handleOnClosePositionModal = () => {
        setShowPositionModal(undefined);
    };

    return (
        <div className="positions-list">
            {userPositions && userPositions.length > 0
                ? userPositions.map((x, i) => (
                      <div className="positions-list--item" key={x.id}>
                          <div
                              className={`dot${
                                  (x.isCurrent ? " current" : "") +
                                  (i === 0 ? " first" : "") +
                                  (i === userPositions.length - 1
                                      ? " last"
                                      : "")
                              }`}
                          >
                              <div className="line" />
                              <GoDotFill />
                          </div>
                          <PositionsItem
                              item={x}
                              positionOptions={positionOptions}
                              canEdit={canEdit}
                              error={errors && errors[x.id]}
                              onSave={props.onSave}
                              onDelete={props.onDelete}
                              onChangeEditMode={props.onChangeEditMode}
                              onShowPosition={handleOnShowPositionModal}
                              onHaveChanges={props.onHaveChanges}
                              registerChildSaveHandler={
                                  props.registerChildSaveHandler
                              }
                              unregisterChildSaveHandler={
                                  props.unregisterChildSaveHandler
                              }
                          />
                      </div>
                  ))
                : t(k.NONE_ASSIGNED)}

            {showPositionModal && (
                <PositionModal
                    position={showPositionModal}
                    onClose={handleOnClosePositionModal}
                />
            )}
        </div>
    );
};

export default PositionsList;
