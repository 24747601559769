import { setLocale } from "yup";

const SetValidationLocale = () =>
    setLocale({
        mixed: { required: "REQUIRED" },
        string: { url: "INVALID_URL" },
        number: { min: "MIN_ONE" },
    });

export default SetValidationLocale;
