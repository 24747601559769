import { FiEye } from "react-icons/fi";
import "./PreviewIcon.scss";

interface IProps {
    disabled?: boolean;
}

const PreviewIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <FiEye
            className={
                props.disabled ? "icon-preview icon-disabled" : "icon-preview"
            }
        />
    );
};

export default PreviewIcon;
