import "./CameraIcon.scss";

import React from "react";
import { AiOutlineCamera } from "react-icons/ai";

const CameraIcon: React.FC = () => {
    return <AiOutlineCamera className="icon-camera" />;
};

export default CameraIcon;
