import React from "react";

import { ActivityType } from "models/enums/ActivityType";
import { IActivityProps } from "./IActivityProps";
import ActivityCheckField from "./ActivityCheckField";
import ActivityTextField from "./ActivityTextField";
import ActivityMultiLineTextField from "./ActivityMultiLineTextField";
import ActivitySelectionField from "./ActivitySelectionField";
import ActivityCustomerDropdown from "./ActivityCustomerDropdown";
import ActivitySupplierDropdown from "./ActivitySupplierDropdown";
import ActivityItemDropdown from "./ActivityItemDropdown";
import ActivityBarcodeField from "./ActivityBarcodeField";
import ActivityPhotoField from "./ActivityPhotoField";
import ActivityDatetimeField from "./ActivityDatetimeField";
import ActivityCustomListDropdown from "./ActivityCustomListDropdown";
import ActivityGenerateBarcodeField from "./ActivityGenerateBarcodeField";
import ActivityHyperlinkField from "./ActivityHyperlinkField";
import ActivityUserDropdown from "./ActivityUserDropdown";
import ActivityPositionDropdown from "./ActivityPositionDropdown";
import ActivityCompetencyDropdown from "./ActivityCompetencyDropdown";

const componentsLookup: Record<ActivityType, React.FC<IActivityProps>> = {
    [ActivityType.Value]: ActivityTextField,
    [ActivityType.Text]: ActivityMultiLineTextField,
    [ActivityType.Tasklist]: ActivityCheckField,
    [ActivityType.Photo]: ActivityPhotoField,
    [ActivityType.Selection]: ActivitySelectionField,
    [ActivityType.Customers]: ActivityCustomerDropdown,
    [ActivityType.Suppliers]: ActivitySupplierDropdown,
    [ActivityType.Items]: ActivityItemDropdown,
    [ActivityType.Barcodes]: ActivityBarcodeField,
    [ActivityType.GenerateBarcode]: ActivityGenerateBarcodeField,
    [ActivityType.DateTime]: ActivityDatetimeField,
    [ActivityType.CustomList]: ActivityCustomListDropdown,
    [ActivityType.Hyperlink]: ActivityHyperlinkField,
    [ActivityType.Users]: ActivityUserDropdown,
    [ActivityType.Positions]: ActivityPositionDropdown,
    [ActivityType.Competencies]: ActivityCompetencyDropdown,
};

const ActivityFieldByType: React.FC<IActivityProps> = (props) => {
    const { activity } = props;

    const Component = componentsLookup[activity.type];

    return <Component {...props} />;
};

export default ActivityFieldByType;
