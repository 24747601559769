import { createContext } from "react";

import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";
import IImprovementFormData from "./IImprovementFormData";

export type ImprovementFormContextType = IImprovementFormData &
    ListedContentContextType;

const initialContextValue: ImprovementFormContextType = {
    isLoading: false,
    isSaving: false,
    mobileView: false,

    setErrors: () => {
        throw new Error("setErrors method not implemented");
    },
    setImprovementForm: () => {
        throw new Error("setImprovementForm method not implemented");
    },
    setConfiguration: () => {
        throw new Error("setConfiguration method not implemented");
    },

    onHaveChanges: () => {
        throw new Error("onHaveChanges method not implemented");
    },

    handleOnSave: () => {
        throw new Error("handleOnSave method not implemented");
    },
    handleOnClose: () => {
        throw new Error("handleOnClose method not implemented");
    },
    handleOnDelete: () => {
        throw new Error("handleOnDelete method not implemented");
    },
    handleOnArchive: () => {
        throw new Error("handleOnArchive method not implemented");
    },
    handleOnReset: () => {
        throw new Error("handleOnReset method not implemented");
    },
    handleOnCopy: () => {
        throw new Error("handleOnCopy method not implemented");
    },
};

const ImprovementFormContext =
    createContext<ImprovementFormContextType>(initialContextValue);

export default ImprovementFormContext;
