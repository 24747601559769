import ModalContent from "common/components/modal-content/ModalContent";
import ImprovementsPage from "components/improvements/ImprovementsPage";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import IImprovementFilter from "components/improvements/api/IImprovementFIlter";
import "./ListMultipleImprovementsById.scss";

interface IProps {
    id?: string;
    name?: string;
    showImprovementsForDetails?: IImprovementFilter;
    onClose: () => void;
}

const ListingMultipleImprovementsById: React.FC<
    React.PropsWithChildren<IProps>
> = (props) => {
    const { id, name, showImprovementsForDetails } = props;
    const { t } = useTranslation();

    return (
        <ModalContent className="modal-manager--modal-content--multiple-improvements">
            <ModalContent.Header
                onClose={props.onClose}
                title={
                    <div className="listing-multiple-improvements--title">
                        {showImprovementsForDetails?.kpi
                            ? name
                            : `${t(k.SHOWING_IMPROVEMENTS_FOR)} ${name}`}
                    </div>
                }
            />
            <ModalContent.Body>
                <ImprovementsPage
                    showImprovementsForId={id ?? ""}
                    showImprovementsForDetails={showImprovementsForDetails}
                />
            </ModalContent.Body>
        </ModalContent>
    );
};

export default ListingMultipleImprovementsById;
