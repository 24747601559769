export enum ActivityType {
    Value,
    Tasklist,
    Photo,
    Text,
    Selection,
    Customers,
    Suppliers,
    Items,
    Barcodes,
    DateTime,
    CustomList,
    GenerateBarcode,
    Hyperlink,
    Users,
    Positions,
    Competencies,
}

export const activityTypeStrings: { [key in ActivityType]: string } = {
    [ActivityType.Value]: "Value",
    [ActivityType.Tasklist]: "Tasklist",
    [ActivityType.Photo]: "Photo",
    [ActivityType.Text]: "Text",
    [ActivityType.Selection]: "Selection",
    [ActivityType.Customers]: "Customers",
    [ActivityType.Suppliers]: "Suppliers",
    [ActivityType.Items]: "Items",
    [ActivityType.Barcodes]: "Barcodes",
    [ActivityType.DateTime]: "Date time",
    [ActivityType.CustomList]: "CustomList",
    [ActivityType.GenerateBarcode]: "GenerateBarcode",
    [ActivityType.Hyperlink]: "Hyperlink",
    [ActivityType.Users]: "Users",
    [ActivityType.Positions]: "Positions",
    [ActivityType.Competencies]: "Competencies",
};
