import { RefObject, useEffect } from "react";

function useAutofocus<T extends HTMLElement>(
    ref: RefObject<T>,
    shouldFocus: boolean,
) {
    useEffect(() => {
        if (shouldFocus && ref.current) {
            ref.current.focus();
        }
    }, [shouldFocus, ref]);
}

export default useAutofocus;
