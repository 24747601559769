import "./DeleteAndUndoButton.scss";

import React from "react";

import { DeleteIcon, UndoIcon } from "common/components/icons";
import IconButton from "common/components/buttons/icon-button/IconButton";

interface IProps {
    testId?: string;
    isDeleted?: boolean;

    onClick: () => void;
}

const DeleteAndUndoButton: React.FC<React.PropsWithChildren<IProps>> = ({
    testId,
    isDeleted,
    onClick,
}) => {
    const handleDelete = (e: any) => {
        e.preventDefault();
        onClick();
    };
    return (
        <IconButton
            variant={isDeleted ? undefined : "danger"}
            testId={testId}
            onClick={handleDelete}
        >
            {isDeleted ? <UndoIcon /> : <DeleteIcon />}
        </IconButton>
    );
};

export default DeleteAndUndoButton;
