import "./TextfieldComplex.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";

import { IWithDebounce, IInputProps } from "../IInputProps";
import { TextfieldIcon } from "common/components/icons";
import { TextfieldType } from "./types";
import { set } from "lodash";

const IMPROVEMENT_DESCRIPTION_MIN_ROWS = 5;
const N0_POST_INPUT_MIN_ROWS = 2;
const HAS_POST_INPUT_MIN_ROWS = 1;

export interface ITextfieldComplexProps extends IWithDebounce {
    fillHeight?: boolean;

    alignItem?: "center";

    bold?: boolean;

    innerLabelContent?: React.ReactNode;

    helperText?: React.ReactNode;

    type?: TextfieldType;

    autoCompleteOff?: boolean;

    autoFocus?: boolean;

    background?: "dark";

    textAlign?: "center" | "right";

    variant?: "gray";

    fontSize?: "small";

    isLink?: boolean;

    minRows?: number;

    parentSize?: { width: number; height: number };

    title?: string;

    children?: React.ReactNode;

    onEnter?: () => void;
}

type Props<V> = ITextfieldComplexProps & IInputProps<V>;

const WAIT_INTERVAL = 2000;

const TextfieldComplex = <V,>(props: Props<V>) => {
    const {
        alignItem,
        autoCompleteOff,
        autoFocus,
        background,
        bold,
        disabled,
        fillHeight,
        fontSize,
        helperText,
        hideIcon,
        id,
        testId,
        innerLabelContent,
        invalid,
        minRows,
        multiline,
        parentId,
        parentSize,
        placeholder,
        postInputContent,
        preInputContent,
        preview,
        textAlign,
        variant,
        wrapperClassName,
        name,
        title,
        withDebounce,
    } = props;

    const type = props.type ?? "text";

    const isLarge = props.size === "large";

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const [isChanged, setIsChanged] = useState(false);

    const timer = useRef<any>(null);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (textareaRef.current && multiline) {
            const ref = textareaRef.current;
            ref.setAttribute(
                "style",
                "height:" + ref.scrollHeight + "px;" + "overflow-y:hidden;",
            );

            ref.addEventListener("input", textareaSizeUpdate, false);
            textareaSizeUpdate();
        }
        return () => {
            textareaRef.current?.removeEventListener(
                "input",
                textareaSizeUpdate,
                false,
            );
        };
    }, [textareaRef]);

    useEffect(() => {
        if (value && multiline) {
            textareaSizeUpdate();
        }
    }, [value, multiline, parentSize, preview]); // parentSize needed for resizing after save

    useEffect(() => {
        if (disabled || preview) {
            return;
        }

        if (!withDebounce) {
            triggerChange(false);

            return;
        }

        timer.current = setTimeout(() => {
            triggerChange(true);
        }, WAIT_INTERVAL);

        return () => clearTimeout(timer.current);
    }, [disabled, preview, withDebounce, value]);

    const textareaSizeUpdate = () => {
        if (textareaRef.current) {
            const ref = textareaRef.current;

            ref.style.height = "auto";
            ref.style.height = ref.scrollHeight + "px";
        }
    };

    const triggerChange = (withDelay: boolean) => {
        if (isChanged) {
            setIsChanged(false);

            props.onChange?.({
                id,
                name,
                value,
                withDelay,
                parentId,
            });
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setIsChanged(true);

        const numberValue = Number(e.target.value);

        if (type === "number" && !isNaN(numberValue)) {
            setValue(numberValue as unknown as V);
        } else {
            setValue(e.target.value as V);
        }
    };

    const handleOnBlur = () => {
        if (props.onBlur) {
            props.onBlur(id, name);
        }

        clearTimeout(timer.current);

        triggerChange(false);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // https://www.w3.org/TR/uievents-key/#named-key-attribute-values

        if (e.key === "Enter") {
            clearTimeout(timer.current);

            triggerChange(false);

            if (props.onEnter) {
                props.onEnter();
            }
        }
    };

    const isDisabled = disabled || preview;

    const wrapperClass = useMemo(() => {
        const result: string[] = ["text-field"];

        if (invalid) {
            result.push("text-field--invalid");
        }

        if (bold) {
            result.push("text-field--bold");
        }
        if (multiline) {
            result.push("text-field--multiline");

            if (fillHeight) {
                result.push("fill-height");
            }

            if (alignItem) {
                result.push(`align-${alignItem}`);
            }
        }

        if (wrapperClassName) {
            result.push(wrapperClassName);
        }

        return result.join(" ");
    }, [invalid, bold, multiline, fillHeight, alignItem, wrapperClassName]);

    const classPreview = useMemo(() => {
        const result: string[] = ["input-field--preview"];

        if (bold) {
            result.push("bold");
        }

        if (preInputContent) {
            result.push("input-field--preview--pre-content");
        }

        if (postInputContent) {
            result.push("input-field--preview--post-content");
        }

        if (textAlign) {
            result.push(`input-field--preview--text-${textAlign}`);
        }

        if (variant) {
            result.push(`input-field--preview--${variant}`);
        }

        if (fontSize) {
            result.push(`input-field--preview--${fontSize}`);
        }

        if (multiline) {
            result.push("input-field--preview--multiline");
        }

        return result.join(" ");
    }, [
        bold,
        preInputContent,
        postInputContent,
        variant,
        fontSize,
        textAlign,
        multiline,
    ]);

    const classBox = useMemo(() => {
        const result = ["text-field--box"];

        if (isDisabled) {
            result.push("disabled");
        }

        if (background) {
            result.push(`background-${background}`);
        }

        return result.join(" ");
    }, [isDisabled, background]);

    const classInputControl = useMemo(() => {
        const result: string[] = ["input-field"];

        if (multiline) {
            result.push("input-field--multiline");
        }

        if (isLarge && !multiline) {
            result.push("input-field--large");
        }

        if (preInputContent) {
            result.push("input-field--pre-content");
        }

        if (postInputContent) {
            result.push("input-field--post-content");
        }

        if (textAlign) {
            result.push(`input-field--text-${textAlign}`);
        }

        if (variant) {
            result.push(`input-field--${variant}`);
        }

        if (fontSize) {
            result.push(`input-field--${fontSize}`);
        }

        return result.join(" ");
    }, [
        multiline,
        preInputContent,
        postInputContent,
        isLarge,
        variant,
        fontSize,
        textAlign,
    ]);

    const minRowNumber =
        id === "deviation.description"
            ? IMPROVEMENT_DESCRIPTION_MIN_ROWS
            : postInputContent
              ? HAS_POST_INPUT_MIN_ROWS
              : minRows ?? N0_POST_INPUT_MIN_ROWS;

    return (
        <label className={wrapperClass}>
            {preview ? (
                <div className={classPreview}>
                    {preInputContent}
                    {value as string}
                    {postInputContent}
                </div>
            ) : (
                <React.Fragment>
                    {innerLabelContent && (
                        <div className="text-field--label">
                            {innerLabelContent}
                        </div>
                    )}

                    <div className={classBox}>
                        {hideIcon ? undefined : (
                            <div className="icon-field">
                                <TextfieldIcon
                                    isMultiline={Boolean(multiline)}
                                    invalid={invalid}
                                />
                            </div>
                        )}
                        {!!preInputContent && (
                            <div className="text-field--pre-content">
                                {preInputContent}
                            </div>
                        )}

                        {multiline ? (
                            <React.Fragment>
                                <div
                                    className={`${classInputControl} print-field`}
                                >
                                    {value as string}
                                </div>
                                <textarea
                                    id={id}
                                    data-testid={testId}
                                    disabled={isDisabled}
                                    placeholder={placeholder}
                                    rows={minRowNumber}
                                    className={classInputControl}
                                    value={value as string}
                                    onChange={handleChange}
                                    onBlur={handleOnBlur}
                                    ref={textareaRef}
                                    title={title ?? placeholder}
                                />
                            </React.Fragment>
                        ) : (
                            <input
                                id={id}
                                data-testid={testId}
                                disabled={isDisabled}
                                type={type}
                                placeholder={placeholder}
                                className={classInputControl}
                                value={
                                    value as V extends number ? number : string
                                }
                                autoComplete={
                                    autoCompleteOff ? "off" : undefined
                                }
                                onChange={handleChange}
                                onBlur={handleOnBlur}
                                onKeyDown={handleKeyPress}
                                title={title ?? placeholder}
                                autoFocus={autoFocus}
                            />
                        )}

                        {!!postInputContent && (
                            <div className="text-field--post-content">
                                {postInputContent}
                            </div>
                        )}
                    </div>

                    {helperText && (
                        <div className="text-field--helper-text">
                            {helperText}
                        </div>
                    )}
                </React.Fragment>
            )}

            {props.children}
        </label>
    );
};

export default TextfieldComplex;
